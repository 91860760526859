import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { EventWithDataHandler } from 'redux-form';

import { Query, QueryState } from '+app/shared/store/query/query.state';
import { isQueryError, isQueryPending, isQuerySuccess } from '+app/utils/query.util';
import { isResponseError } from '+shared/network';
import { ResponseError } from '+shared/network/network.interface';
import { Button, ButtonSize, ButtonTheme, DefaultParagraph } from '@sonnen/shared-web';
import { AccountPasswordChangeActions } from '../';
import { AccountToast, AccountToastType } from '../../components';
import { AccountPasswordChangeFormValues } from './AccountPasswordChange.types';
import { AccountPasswordChangeForm } from './AccountPasswordChangeForm/AccountPasswordChangeForm.component';

import './AccountPasswordChange.mobile.component.scss';

const NOTIFICATION_TIMEOUT = 3000;

interface AccountPasswordChangeWebProps {
  actions: AccountPasswordChangeActions;
  formValues: AccountPasswordChangeFormValues;
  query: Query;
  queryStatus: QueryState;
  isDirty: boolean;
  onBlur?: EventWithDataHandler<React.FocusEvent<any>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleReset: () => void;
}

const getErrorMessage = (error?: ResponseError) => error?.extra?.errors?.length && 
  error.extra.errors.some(({ code }: { code: string }) => code === 'invalid') 
    ? I18n.t(T.general.errors.password.invalidCurrentPassword)
    : I18n.t(T.general.messages.apiError);

export const AccountPasswordChangeMobile: React.FC<AccountPasswordChangeWebProps> = ({
  actions,
  formValues,
  isDirty,
  handleSubmit,
  handleReset,
  onBlur,
  query,
  queryStatus,
}) => {
  const [isSuccessNotificationVisible, setSuccessNotificationVisibility] = React.useState<boolean>(false);
  const [isErrorNotificationVisible, setErrorNotificationVisibility] = React.useState<boolean>(false);

  let notificationTimeout: number | undefined;

  React.useEffect(() => {
    if (isQuerySuccess(queryStatus)) {
      setSuccessNotificationVisibility(true);
      handleReset();

      notificationTimeout = window.setTimeout(() => {
        setSuccessNotificationVisibility(false);
        actions.resetChangePasswordQuery();
      }, NOTIFICATION_TIMEOUT);
    }

    if (isQueryError(queryStatus)) {
      setErrorNotificationVisibility(true);
      handleReset();

      notificationTimeout = window.setTimeout(() => {
        setErrorNotificationVisibility(false);
        actions.resetChangePasswordQuery();
      }, NOTIFICATION_TIMEOUT);
    }

    return () => {
      clearTimeout(notificationTimeout);
    };
  }, [queryStatus]);

  const errorMessage = getErrorMessage(isResponseError(query.error) ? query.error : undefined); 

  return (
    <div className={'m-c-account-password-change'}>
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--sm-6'}>
          <DefaultParagraph>
            <Translate value={T.myAccountPages.password.description} />
          </DefaultParagraph>
        </div>
      </div>
      <AccountPasswordChangeForm
        formValues={formValues}
        handleSubmit={handleSubmit}
        onBlur={onBlur}
      >
        <div className={'m-c-account-password-change__buttons'}>
          <div className={'m-c-account-password-change__button'}>
            <Button
              label={I18n.t(T.general.buttons.undoChanges)}
              size={ButtonSize.NORMAL}
              theme={ButtonTheme.OUTLINE_PRIMARY}
              isDisabled={!isDirty || isQueryPending(queryStatus)}
              isBlock={true}
              onClick={handleReset}
            />
          </div>
          <div className={'m-c-account-password-change__button'}>
            <Button
              label={I18n.t(T.general.buttons.saveChanges)}
              size={ButtonSize.NORMAL}
              theme={ButtonTheme.PRIMARY}
              isDisabled={!isDirty || isQueryPending(queryStatus)}
              isBlock={true}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </AccountPasswordChangeForm>
      {(isSuccessNotificationVisible || isErrorNotificationVisible) && (
        <div className={'m-c-account-password-change__toasts'}>
          <AccountToast type={AccountToastType.SUCCESS} isVisible={isSuccessNotificationVisible}/>
          <AccountToast
            type={AccountToastType.FAILURE}
            isVisible={isErrorNotificationVisible}
            messageOverride={errorMessage}
          />
        </div>
      )}
    </div>
  );
};
