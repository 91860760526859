import { AreaChartView, DataContainer, PointAccessor, TooltipEvent, XYPoint } from '@kanva/charts';
import { View } from '@kanva/core';
import { BASE_TICK_COUNT, EnergyFlowSeriesKey, formatPercentage, SeriesKey, TimeHelper } from '@sonnen/shared-web';
import * as React from 'react';

import { analysisPointAccessor, getXYFromAreaChartSeries, resolveAreaXPosition } from '+analysis/helpers/analysis.helper';
import { MeasurementMethod } from '+app/shared/store/live/types/siteLiveData.interface';
import { getKiloPowerObj } from '+legacy/helpers/numbers';
import { KanvaHelper } from '../../../kanva/kanva.helper';
import {
  KanvaContainers,
  KanvaContainerType,
  KanvaExtensions,
  KanvaExtensionType,
  KanvaTooltipValues,
} from '../../../kanva/kanva.types';
import { AnalysisDayChartContext } from './AnalysisDayChartProvider.context';
import { AnalysisDayChartProviderHelper } from './AnalysisDayChartProvider.helper';
import { AnalysisDayChartProviderProps } from './AnalysisDayChartProvider.types';

export const AnalysisDayChartProvider: React.FC<AnalysisDayChartProviderProps> = ({
  children,
  areaChartSeries,
  selectedDate,
  measurementsMethod,
}) => {
  const [chartView, setChartView] = React.useState<View<any>>();
  const [containers, setContainers] = React.useState<KanvaContainers>();
  const [currentScale, setScale] = React.useState({x: 1, y: 1});
  const [currentXPosition, setCurrentXPosition] = React.useState(0);
  const [extensions, setExtensions] = React.useState<KanvaExtensions>({});
  const [isChartZoomed, setZoomState] = React.useState<boolean>(false);
  const [isResizeListener, setResizeListenerState] = React.useState<boolean>(false);
  const [lastConsumptionXYPos, setLastConsumptionXYPos] = React.useState<XYPoint>({x: 0, y: 0 });
  const [lastProductionXYPos, setLastProductionXYPos] = React.useState<XYPoint>({x: 0, y: 0 });
  const [primarySeries, setPrimarySeries] = React.useState<SeriesKey>();
  const [tooltipEvent, setTooltipEvent] = React.useState<TooltipEvent | undefined>();
  const [tooltipValues, setTooltipValues] = React.useState<KanvaTooltipValues>();

  const handleLastXYPositions = () => {
    if (!chartView || !containers) { return; }
    const consumptionPos = getXYFromAreaChartSeries(chartView, areaChartSeries, EnergyFlowSeriesKey.CONSUMPTION_POWER);
    const productionPos = getXYFromAreaChartSeries(chartView, areaChartSeries, EnergyFlowSeriesKey.PRODUCTION_POWER);

    if (consumptionPos) { setLastConsumptionXYPos(consumptionPos); }
    if (productionPos) { setLastProductionXYPos(productionPos); }
  };

  const handleTooltipPosition = () => {
    const defaultContainer = containers?.default;
    const percentageContainer = containers?.percentage;
    const tooltipExtension = extensions?.tooltip;

    if (!defaultContainer || !percentageContainer || !chartView || !tooltipExtension || !primarySeries) { return; }

    const position = resolveAreaXPosition(primarySeries)(defaultContainer, selectedDate) || 1;
    const { absoluteX } = (chartView as AreaChartView).getCanvasPositionForPoint({ x: position, y: 0 });

    KanvaHelper.handleTooltipPositionChange(absoluteX)(chartView, tooltipExtension);
    setCurrentXPosition(absoluteX);

    const lastSeriesValues = {
      ...KanvaHelper.getLastSeriesValues(defaultContainer),
      ...KanvaHelper.getLastSeriesValues(percentageContainer),
    };

    setTooltipValues(lastSeriesValues);
  };

  const handleMount = (view: View<any>) => {
    if (view && !chartView) {
      setChartView(view);
    }
  };

  React.useEffect(() => {
    const tooltipExtension = KanvaHelper.createTooltipExtension(tooltipEvent, setTooltipEvent);
    const transformExtension = KanvaHelper.createTransformExtension(currentScale, setZoomState, setScale);
    const mappedDataSeries = AnalysisDayChartProviderHelper.prepareDataSeries(areaChartSeries);
    const dataContainers = {
      [KanvaContainerType.DEFAULT]: new DataContainer<XYPoint>()
        .setXAxisParameters({
          labelAccessor: TimeHelper.formatUnixToMinutes,
          roundTo: 5,
          tickCount: BASE_TICK_COUNT,
        })
        .setYAxisParameters({
          labelAccessor: y => {
            const { value, unit } = getKiloPowerObj(y, 'W', 2);
            return `${value}${unit}`;
          },
          roundTo: 25,
          tickCount: BASE_TICK_COUNT,
        })
        .setXBoundsExtension([0, 24 * 60])
        .setPointAccessor(analysisPointAccessor as PointAccessor<unknown>),
      [KanvaContainerType.PERCENTAGE]: new DataContainer<XYPoint>()
        .setYBoundsExtension([0, 100])
        .setXBoundsExtension([0, 24 * 60])
        .setYAxisParameters({
          labelAccessor: formatPercentage,
          roundTo: 5,
          tickCount: BASE_TICK_COUNT,
        })
        .setPointAccessor(analysisPointAccessor as PointAccessor<unknown>),
    };

    dataContainers.default.addExtension(transformExtension, tooltipExtension);
    dataContainers.percentage.addExtension(transformExtension, tooltipExtension);
    dataContainers.default.setData(mappedDataSeries.default);
    dataContainers.percentage.setData(mappedDataSeries.percentage);

    setExtensions({
      ...extensions,
      [KanvaExtensionType.TOOLTIP]: tooltipExtension,
      [KanvaExtensionType.TRANSFORM]: transformExtension,
    });
    setContainers(dataContainers);

    return () => {
      window.removeEventListener('resize', handleLastXYPositions);

      if (containers && extensions.transform) {
        containers.default.removeExtension(extensions.transform);
        if (containers.percentage) {
          containers.percentage.removeExtension(extensions.transform);
        }
      }
      if (containers && extensions.tooltip) {
        containers.default.removeExtension(extensions.tooltip);
        if (containers.percentage) {
          containers.percentage.removeExtension(extensions.tooltip);
        }
      }
    };
  }, []);

  React.useEffect(() => {
    if (!containers?.default || !containers?.percentage) { return; }

    const mappedDataSeries = AnalysisDayChartProviderHelper.prepareDataSeries(areaChartSeries);

    containers.default.setData(mappedDataSeries.default);
    containers.percentage.setData(mappedDataSeries.percentage);

    handleTooltipPosition();
  }, [areaChartSeries]);

  React.useEffect(() => {
    const primarySeries = measurementsMethod === MeasurementMethod.METER_GRID
      ? EnergyFlowSeriesKey.GRID_PURCHASE
      : EnergyFlowSeriesKey.PRODUCTION_POWER;
    setPrimarySeries(primarySeries);
  }, [measurementsMethod]);

  React.useEffect(() => {
    handleTooltipPosition();
  }, [chartView, containers]);

  React.useEffect(() => {
    const transformExtension = extensions?.transform;

    if (transformExtension && containers?.default) {
      transformExtension.setScale({ x: 1, y: 1 });
    }
  }, [TimeHelper.getUnixFromDate(selectedDate)]);

  React.useEffect(() => {
    const defaultContainer = containers?.default;
    if (!defaultContainer || !tooltipEvent || !Boolean(tooltipEvent?.snap.x)) { return; }

    KanvaHelper.handleTooltipValues(defaultContainer, tooltipEvent, setTooltipValues);
    setCurrentXPosition(tooltipEvent.snap.x);
  }, [tooltipEvent]);

  React.useEffect(() => {
    handleLastXYPositions();

    if (!isResizeListener && (chartView && areaChartSeries)) {
      window.addEventListener('resize', () => handleLastXYPositions());
      setResizeListenerState(true);
    }
  }, [currentScale, isChartZoomed, chartView, areaChartSeries]);

  return (
    <AnalysisDayChartContext.Provider value={{
      chartView,
      containers,
      currentXPosition,
      extensions,
      isChartZoomed,
      lastXYSeriesPosition: {
        [EnergyFlowSeriesKey.CONSUMPTION_POWER]: lastConsumptionXYPos,
        [EnergyFlowSeriesKey.PRODUCTION_POWER]: lastProductionXYPos,
      },
      primarySeries,
      tooltipEvent,
      tooltipValues,
      scale: currentScale,
      handleMount,
    }}>
      {children}
    </AnalysisDayChartContext.Provider>
  );
};
