import { isActive } from '+app/router/router.helper';
import { Route } from '+app/router/router.types';
import { Nav, NavItem, NavListItem } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './NavHorizontal.component.scss';

interface Props {
  hasIcon?: boolean;
  labelType?: 'mobile' | 'web';
  routes: Route[];
}

export const NavHorizontal: React.FC<Props> = ({
  hasIcon = true,
  labelType = 'mobile',
  routes,
}) => (
  <div className={'c-nav-horizontal'}>
    <div className={'c-nav-horizontal__inner'}>
      <nav className={'c-nav-horizontal__nav'}>
        <Nav isHorizontal={true}>
          {routes.map((route) => (
            <NavListItem key={`nav-horizontal-routes-${route.name}`}>
              <NavItem
                icon={route.icon && hasIcon && <route.icon />}
                action={route.path}
                isThemeActiveUnderline={true}
                isThemeDense={true}
                isThemeFaded={true}
                isActive={isActive(route)}
              >
                <div className={'c-nav-horizontal__label'}>
                  {I18n.t(route.label[labelType] || route.label.web || '')}
                </div>
              </NavItem>
            </NavListItem>
          ))}
        </Nav>
      </nav>
    </div>
  </div>
);
