import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { EventWithDataHandler, Field } from 'redux-form';

import { Form } from '+legacy/components/Form';
import { FormPasswordProps } from '+legacy/components/Form/elements';
import { StringComplexityChecker } from '+shared/components';
import { passwordRegexConstraints } from '+shared/helpers';
import { AccountPasswordChangeFormValues } from '../AccountPasswordChange.types';

import './AccountPasswordChangeForm.component.scss';

interface AccountPasswordChangeFormProps {
  formValues: AccountPasswordChangeFormValues;
  onBlur?: EventWithDataHandler<React.FocusEvent<any>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

export const AccountPasswordChangeForm: React.FC<AccountPasswordChangeFormProps> = ({
  formValues,
  handleSubmit,
  onBlur,
  children,
}) => {

  return (
    <Form onSubmit={handleSubmit}>
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--sm-6 o-grid__column--no-gap'}>
          <Form.Group>
            <Field<FormPasswordProps>
              dataTestId={'account-password-change-form-previous-password'}
              label={I18n.t(T.general.labels.currentPassword)}
              name={'previousPassword'}
              component={Form.Password}
              onBlur={onBlur}
            />
          </Form.Group>
          <Form.Group>
            <div className={'c-account-password-change-form__complexity-checker'}>
              <StringComplexityChecker
                rules={passwordRegexConstraints}
                subject={formValues.password}
              />
            </div>
            <Field<FormPasswordProps>
              dataTestId={'account-password-change-form-new-password'}
              label={I18n.t(T.general.labels.newPassword)}
              name={'password'}
              component={Form.Password}
              onBlur={onBlur}
            />
            <Field<FormPasswordProps>
              dataTestId={'account-password-change-form-new-password-confirmation'}
              label={I18n.t(T.general.labels.confirmPassword)}
              name={'passwordConfirmation'}
              component={Form.Password}
              onBlur={onBlur}
            />
          </Form.Group>
        </div>
      </div>
      {children}
    </Form>
  );
};
