import { T } from '@sonnen/shared-i18n/customer';
import { Icon, Link } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { LinkedTranslation } from '+app/shared/components';
import {
  GuideGridStretched,
  GuideInfoCard,
  GuideInfoCardList,
} from '+guide/components';

import './GuideSetupStepBNetzARegistrationActive.component.scss';

export const GuideSetupStepBNetzARegistrationActive: React.FC = () => (
  <GuideGridStretched>
    <GuideInfoCard
      header={I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.information.headline)}
      headerIcon={<Icon.Info />}
    >
      {I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.information.description)}
    </GuideInfoCard>
    <GuideInfoCard
      header={I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.headline)}
      headerIcon={<Icon.Contract />}
      isIconBig={true}
    >
      <>
        <p>
          {I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.label)}
        </p>
        <p className={'c-guide-setup-step-bnetza-registration-active__case-title'}>
          {I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.caseOne)}
        </p>
        <GuideInfoCardList>
          {I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.powerOfAttorney)}
          {I18n.t(
            T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.carriesRegistration,
          )}
          {I18n.t(
            T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.sendRegistration,
          )}
        </GuideInfoCardList>
        <p className={'c-guide-setup-step-bnetza-registration-active__case-title'}>
          {I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.caseTwo)}
        </p>
        <GuideInfoCardList>
          {I18n.t(
            T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.oneMonthRegistration,
            )}
          <LinkedTranslation
            sentence={I18n.t(
              T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.federalNetworkAgency,
            )}
            links={[
              <Link
                key={'BNetzA Registration'}
                href={'https://www.marktstammdatenregister.de/MaStR'}
                label={
                  I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.link,
                )}
              />,
            ]}
          />
          {I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.threeSteps)}
          {I18n.t(
            T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.afterRegistering,
          )}
          {I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.yourToDo.description.keepRecords)}
        </GuideInfoCardList>
      </>
    </GuideInfoCard>
    <GuideInfoCard
      header={I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.backgroundInformation.headline)}
      headerIcon={<Icon.Info />}
    >
      {I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.backgroundInformation.description)}
    </GuideInfoCard>
  </GuideGridStretched>
);
