import { BatteryHelper } from '+battery/store/battery.helpers';
import { Battery } from '+battery/store/types/battery.interface';
import { getFormattedDate } from '+legacy/helpers/dates';
import { SpecsItem } from '+shared/components';
import { T } from '@sonnen/shared-i18n/customer';
import { Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './BatterySpecsInstallation.component.scss';

interface Props {
  batteryData: Battery | undefined;
}

export const BatterySpecsInstallation: React.SFC<Props> = ({ batteryData }) => {
  const installationLocation = BatteryHelper.getInstallationLocation(batteryData);

  if (!batteryData) {
    return <Loader />;
  }

  return (
    <div className={'c-battery-specs-installation'}>
      {batteryData.installationDate && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-installation-date'}
          title={I18n.t(T.batterySystem.details.sections.installation.parts.date)}
          value={getFormattedDate(batteryData.installationDate)}
        />
      )}
      {installationLocation && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-installation-location'}
          title={I18n.t(T.batterySystem.details.sections.installation.parts.location)}
          value={installationLocation}
        />
      )}
    </div>
  );
};
