import { T } from '@sonnen/shared-i18n/customer';
import { IconType } from '@sonnen/shared-web/src/components/Icon/models';

export interface CarouselSlide {
  icon: IconType;
  unit: string;
  description: string;
  energyConverterFactor: number;
}

export const slides: CarouselSlide[] = [
  {
    icon: 'Shower',
    unit: T.analysis.slides.shower.unit,
    description: T.analysis.slides.shower.description,
    energyConverterFactor: 3000,
  },
  {
    icon: 'Car',
    unit: T.analysis.slides.car.unit,
    description: T.analysis.slides.car.description,
    energyConverterFactor: 174,
  },
  {
    icon: 'AirConditioning',
    unit: T.analysis.slides.ac.unit,
    description: T.analysis.slides.ac.description,
    energyConverterFactor: 2000,
  },
  {
    icon: 'Pot',
    unit: T.analysis.slides.cooking.unit,
    description: T.analysis.slides.cooking.description,
    energyConverterFactor: 2000,
  },
  {
    icon: 'Tree',
    unit: T.analysis.slides.emission.unit,
    description: T.analysis.slides.emission.description,
    energyConverterFactor: 1818,
  },
  {
    icon: 'Smartphone',
    unit: T.analysis.slides.smartphone.unit,
    description: T.analysis.slides.smartphone.description,
    energyConverterFactor: 11.4,
  },
  {
    icon: 'WashingMachine',
    unit: T.analysis.slides.washer.unit,
    description: T.analysis.slides.washer.description,
    energyConverterFactor: 681,
  },
  {
    icon: 'Coffee',
    unit: T.analysis.slides.coffee.unit,
    description: T.analysis.slides.coffee.description,
    energyConverterFactor: 25,
  },
  {
    icon: 'Laptop',
    unit: T.analysis.slides.laptop.unit,
    description: T.analysis.slides.laptop.description,
    energyConverterFactor: 40,
  },
  {
    icon: 'Lamp',
    unit: T.analysis.slides.lamp.unit,
    description: T.analysis.slides.lamp.description,
    energyConverterFactor: 264,
  },
];
