import * as React from 'react';

import { Image } from '+shared/components';

import './ImageTutorial.component.scss';

export type ImageTutorialItemProps = {
  title: string;
  imageSrc: {
    default: string,
    retina?: string,
  };
  alt?: string;
};

interface Props {
  list: ImageTutorialItemProps[];
}

export const ImageTutorial: React.FC<Props> = ({ list }) => (
  <ol className={'c-image-tutorial'}>
    {list.map(({
      title,
      imageSrc,
      alt,
    }) => (
      <li
        className={'c-image-tutorial__item'}
        key={title}
      >
        <figure className={'c-image-tutorial__figure'}>
          <figcaption className={'c-image-tutorial__title'}>
            {title}
          </figcaption>
          <div className={'c-image-tutorial__image'}>
            <Image
              src={imageSrc.default}
              srcRetina={imageSrc.retina}
              alt={alt}
            />
          </div>
        </figure>
      </li>
    ))}
  </ol>
);
