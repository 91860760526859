import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './DefaultNavTag.component.scss';

interface Props {
  tag: string | undefined;
}

export const DefaultNavTag: React.FC<Props> = ({ tag }) => tag ? (
  <span className={'c-default-nav-tag'}>
    {I18n.t(tag)}
  </span>
) : null;
