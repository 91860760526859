import * as React from 'react';

import './ContractParagraph.component.scss';

interface Props {
  dataTestId?: string;
}

export const ContractParagraph: React.FC<Props> = ({ dataTestId, children }) => (
  <p data-test-id={dataTestId}
    className={'c-contract-paragraph'}
  >
    {children}
  </p>
);
