import { getBatteryTimezone } from '+battery/store/battery.selectors';
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '+app/store/store.interface';
import { ContractActions } from '+contract/store/contract.actions';
import {
  getContractDataQueryErrorCount,
  getContractDataQueryStatus,
  getContractStatisticsQueryErrorCount,
  getContractStatisticsQueryStatus,
  getCurrentSiteContractData,
  getSonnenFlatStatisticsData,
  hasContractStatisticsQueryFailed,
  hasContractTypeSonnenFlatHome,
  hasContractTypeSonnenFlatX,
} from '+contract/store/contract.selectors';
import { mapActions } from '+utils/redux';
import { QueryData, QueryTypes } from '../query/query.state';
import { getActiveSite, isSiteWithContract } from '../site/site.selectors';

const getContractQuery = (state: StoreState): QueryData => ({
  status: getContractDataQueryStatus(state),
  errorCount: getContractDataQueryErrorCount(state),
});

const getContractStatisticsQuery = (state: StoreState): QueryData => ({
  status: getContractStatisticsQueryStatus(state),
  errorCount: getContractStatisticsQueryErrorCount(state),
});

const mapStateToProps = (state: StoreState) => ({
  activeSite: getActiveSite(state),
  // NOTE: batteryTimezone is needed to refetch statistics if battery data was missing and was fetched later
  batteryTimezone: getBatteryTimezone(state),
  contract: getCurrentSiteContractData(state),
  hasSonnenFlatContract: hasContractTypeSonnenFlatHome(state),
  isSiteWithContract: isSiteWithContract(state),
  sonnenFlatStatistics: getSonnenFlatStatisticsData(state),
  hasContractStatisticsQueryFailed: hasContractStatisticsQueryFailed(state),
  [QueryTypes.CONTRACT_QUERY]: getContractQuery(state),
  [QueryTypes.CONTRACT_STATISTICS_QUERY]: getContractStatisticsQuery(state),
});

const mapDispatchToProps = mapActions({
  getContract: ContractActions.getContractData,
  getSonnenFlatStatistics: ContractActions.getSonnenFlatStatistics,
});

type WrapperComponentProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

export type ContractHoCProps = Pick<WrapperComponentProps,
  | 'contract'
  | 'sonnenFlatStatistics'
  | QueryTypes.CONTRACT_QUERY
  | QueryTypes.CONTRACT_STATISTICS_QUERY
>;

export function withContract <EnhancedComponentProps extends object>(
  EnhancedComponent: React.ComponentType<(EnhancedComponentProps | object) & ContractHoCProps>,
) {
  class WrapperComponent extends React.PureComponent<WrapperComponentProps> {
    shouldGetContract() {
      const { activeSite, isSiteWithContract, contract, contractQuery } = this.props;

      if (!activeSite || !isSiteWithContract || contractQuery.status.pending) {
        return false;
      }

      const siteContractsIds = activeSite.contracts;

      if (
        !contract ||
        (siteContractsIds.length && siteContractsIds[0] !== contract.id)
      ) {
        return true;
      }
      return false;
    }

    shouldGetStatistics(overwritePendintRequest?: boolean) {
      const { contract, hasSonnenFlatContract, contractStatisticsQuery } = this.props;

      if (
        contract
        && (hasSonnenFlatContract || hasContractTypeSonnenFlatX)
        && (!contractStatisticsQuery.status.pending || overwritePendintRequest)
      ) {
        return true;
      }

      return false;
    }

    componentDidMount() {
      const { actions } = this.props;
      if (this.shouldGetContract()) {
        actions.getContract();
      }
      if (this.shouldGetStatistics()) {
        actions.getSonnenFlatStatistics();
      }
    }

    componentDidUpdate(prevProps: WrapperComponentProps) {
      const { actions, batteryTimezone, contract, sonnenFlatStatistics, hasContractStatisticsQueryFailed } = this.props;

      const hasContractChanged = !isEqual(contract, prevProps.contract);
      const hasTimezoneChanged = batteryTimezone !== prevProps.batteryTimezone;

      if (
        (hasContractChanged || hasTimezoneChanged || (!sonnenFlatStatistics && !hasContractStatisticsQueryFailed)) &&
        this.shouldGetStatistics(hasTimezoneChanged)
      ) {
        actions.getSonnenFlatStatistics();
      }
    }

    render() {
      const {
        activeSite,
        contract,
        hasSonnenFlatContract,
        isSiteWithContract,
        sonnenFlatStatistics,
        contractQuery,
        contractStatisticsQuery,
        actions,
        dispatch,
        ...enhancedComponentProps
      } = this.props;

      const contractHocProps: ContractHoCProps = {
        contract,
        sonnenFlatStatistics,
        contractQuery,
        contractStatisticsQuery,
      };

      return <EnhancedComponent { ...contractHocProps }  {...enhancedComponentProps as EnhancedComponentProps } />;
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WrapperComponent);
}
