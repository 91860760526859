import { createAction } from './createAction.util';

export const createRoutine = <
  Payload extends object,
  SuccessPayload extends object = any, // TODO handle better the routine payloads
  >(type: string) => {
  const state = {
    TRIGGER: `${type}/TRIGGER`,
    trigger: (payload: Payload = {} as Payload) => createAction(`${type}/TRIGGER`, { payload }),
    REQUEST: `${type}/REQUEST`,
    request: (payload: Payload = {} as Payload) => createAction(`${type}/REQUEST`, { payload }),
    SUCCESS: `${type}/SUCCESS`,
    success: (payload: SuccessPayload = {} as SuccessPayload) => createAction(`${type}/SUCCESS`, { payload }),
    FAILURE: `${type}/FAILURE`,
    failure: (payload: Payload = {} as Payload) => createAction(`${type}/FAILURE`, { payload }),
    FULFILL: `${type}/FULFILL`,
    fulfill: (payload: Payload = {} as Payload) => createAction(`${type}/FULFILL`, { payload }),
  };

  return { ...state, getSuccessAndFailureTypes: (): string[] => ([state.SUCCESS, state.FAILURE]) };
};
