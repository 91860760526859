import * as React from 'react';
import { connect } from 'react-redux';
import { EventWithDataHandler, getFormValues, InjectedFormProps, reduxForm, reset, submit } from 'redux-form';

import { getChangePasswordQuery, getChangePasswordQueryStatus } from '+account/store/account.selectors';
import { PlatformSelector } from '+app/shared/components';
import { FormNames } from '+app/shared/store/redux-form/formNames';
import { StoreState } from '+app/store/store.interface';
import { QueryActions } from '+shared/store/query/query.actions';
import { getCurrentUserId } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';
import { AccountActions } from '../../store/account.actions';
import { CHANGE_USER_PASSWORD_QUERY } from '../../store/account.state';
import { AccountPasswordChangeHelper } from './AccountPasswordChange.helper';
import { AccountPasswordChangeMobile } from './AccountPasswordChange.mobile.component';
import { AccountPasswordChangeFormValues } from './AccountPasswordChange.types';
import { AccountPasswordChangeWeb } from './AccountPasswordChange.web.component';

const initialValues: AccountPasswordChangeFormValues = {
  password: '',
  previousPassword: '',
  passwordConfirmation: '',
};

const mapStateToProps = (state: StoreState) => ({
  currentUserId: getCurrentUserId(state),
  changePasswordQueryStatus: getChangePasswordQueryStatus(state),
  changePasswordQuery: getChangePasswordQuery(state),
  formValues: getFormValues(FormNames.CHANGE_PASSWORD_FORM)(state) as AccountPasswordChangeFormValues,
});

const mapDispatchToProps = mapActions({
  changeUserPassword: AccountActions.changeUserPassword,
  resetChangePasswordQuery: () => QueryActions.init(CHANGE_USER_PASSWORD_QUERY),
  submit,
  reset,
});

export type AccountPasswordChangeActions = ReturnType<typeof mapDispatchToProps>['actions'];

interface AccountPasswordChangeOwnProps extends InjectedFormProps<AccountPasswordChangeFormValues> {
  currentUserId?: string;
}

type AccountPasswordChangeProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & AccountPasswordChangeOwnProps
  ;

class AccountPasswordChangeComponent extends React.PureComponent<AccountPasswordChangeProps> {
  hideAlertTimeout?: number;
  switchAlertButtonsDelayTimeout?: number;

  constructor(props: AccountPasswordChangeProps) {
    super(props);
  }

  onBlur: EventWithDataHandler<React.FocusEvent<any>> = (e) => e && e.preventDefault();

  handleFormReset = (): void => {
    this.props.reset();
  };

  handleFormSubmit = () => {
    const userData = {
      previousPassword: this.props.formValues.previousPassword,
      password: this.props.formValues.password,
      passwordConfirmation: this.props.formValues.passwordConfirmation,
    };
  
    this.props.actions.changeUserPassword(userData, this.props.currentUserId);
  };

  render() {
    const { 
      actions,
      changePasswordQuery,
      changePasswordQueryStatus,
      dirty,
      formValues = initialValues,
      handleSubmit,
    } = this.props;

    return (
      <PlatformSelector
        web={<AccountPasswordChangeWeb
          actions={actions}
          formValues={formValues}
          query={changePasswordQuery}
          queryStatus={changePasswordQueryStatus}
          isDirty={dirty}
          handleSubmit={handleSubmit(this.handleFormSubmit)}
          handleReset={this.handleFormReset}
          onBlur={this.onBlur}
        />}
        mobile={<AccountPasswordChangeMobile
          actions={actions}
          formValues={formValues}
          query={changePasswordQuery}
          queryStatus={changePasswordQueryStatus}
          isDirty={dirty}
          handleSubmit={handleSubmit(this.handleFormSubmit)}
          handleReset={this.handleFormReset}
          onBlur={this.onBlur}
        />}
      />
    );
  }
}

export const AccountPasswordChange = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FormNames.CHANGE_PASSWORD_FORM,
  validate: AccountPasswordChangeHelper.validate,
  warn: AccountPasswordChangeHelper.warn,
  initialValues,
})(AccountPasswordChangeComponent)) as any; // NOTE: Redux-Form type not match. Consider fix that
