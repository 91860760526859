import { Offer, OfferProductStatus } from '+app/+guide/store/types';
import { getPaths } from '+app/router/router.helper';
import { insertIf } from '+app/utils/array.util';
import { GuideActions } from '+guide/store/+guide.actions';
import { getGuideOfferCollection } from '+guide/store/+guide.selectors';
import { getGuideLeadId } from '+guide/store/+lead.selectors';
import { FlatDocumentType } from '+guide/store/types/document.interface';
import { combineSagas, dataGuard, mapPathToParams, NO_MATCHING_PATH, processQuery } from '+utils/saga';
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { GUIDE_ACCEPTANCE_ACTIONS, GuideAcceptanceActions } from './+acceptance.actions';
import { getProductForStatusChange } from './+acceptance.helper';
import { DocumentAcceptance } from './+acceptance.interface';
import { GuideAcceptanceRepository } from './+acceptance.repository';
import {
  ACCEPT_DOCUMENTS_QUERY,
  ACCEPT_OFFER_QUERY,
  GET_ENERGY_PROVIDERS_QUERY,
  GET_UTILITY_DATA_QUERY,
  SET_PAYMENT_DATA_QUERY,
  SET_PV_SYSTEM_KEYS_QUERY,
  SET_UTILITY_DATA_QUERY,
} from './+acceptance.state';

function* setPaymentDataSaga({ paymentData }: ReturnType<typeof GuideAcceptanceActions.setPaymentData>): SagaIterator {
  const leadId: string = yield select(getGuideLeadId);
  const [offerId] = yield mapPathToParams(getPaths().GUIDE_ACCEPTANCE_ID);

  yield processQuery(
    SET_PAYMENT_DATA_QUERY,
    GuideAcceptanceRepository.setPaymentData,
    {
      onFailure: () => {
        throw new Error('Could not set payment data');
      },
    },
  )({ leadId, offerId, paymentData });
}

function* getEnergyProviders({ searchQuery }: AnyAction): SagaIterator {
  yield processQuery(
    GET_ENERGY_PROVIDERS_QUERY,
    GuideAcceptanceRepository.getEnergyProviders,
    {
      onSuccess: res => dataGuard(GuideAcceptanceActions.setEnergyProviders)(res!.elements),
      onFailure: () => {
        throw new Error('Could not get energy providers');
      },
    },
  )(searchQuery);
}

function* acceptDocuments(action: AnyAction): SagaIterator {
  const leadId: string = yield select(getGuideLeadId);
  const params = yield mapPathToParams(
    getPaths().GUIDE_ACCEPTANCE_ID,
  );
  if (params === NO_MATCHING_PATH) {
    return;
  }
  const [offerId] = params;

  // flat offer is accepted by default while accepting these documents
  // terms_and_conditions_and_cancellation is a combination of terms_and_conditions and cancellation_policy in API
  // tslint:disable-next-line: naming-convention
  const { terms_and_conditions_and_cancellation, ...otherFieldNames } = action.formValues;

  const acceptedDocuments: string[] = [
    'flat_offer',
    ...Object.entries(otherFieldNames)
      .filter(([key, value]: any) => !!value && Object.values(DocumentAcceptance).includes(key))
      .map(([key]) => key),
    ...insertIf(terms_and_conditions_and_cancellation, 'terms_and_conditions'),
    ...insertIf(terms_and_conditions_and_cancellation, 'cancellation_policy'),
  ];

  yield processQuery(
    ACCEPT_DOCUMENTS_QUERY,
    GuideAcceptanceRepository.acceptDocuments,
    {
      onSuccess: res => dataGuard(GuideAcceptanceActions.acceptOffer)(res!.element),
      onFailure: error => {
        throw new Error(`Could not accept the offer - ${error}`);
      },
    },
  )({ leadId, offerId, acceptedDocuments });
}

function* acceptOffer(): SagaIterator {
  const leadId: string = yield select(getGuideLeadId);
  const [offerId] = yield mapPathToParams(getPaths().GUIDE_ACCEPTANCE_ID);
  const offers: Offer[] = yield select(getGuideOfferCollection);

  const offer = offers.find(offer => offer.id === offerId);
  if (!offer) { return; }

  const product = getProductForStatusChange(offer);
  if (!product) { return; }

  yield processQuery(
    ACCEPT_OFFER_QUERY,
    GuideAcceptanceRepository.patchLeadOfferProduct,
    {
      onSuccess: () => put(GuideActions.setOfferAcceptedStatus(offerId)),
      onFailure: error => {
        throw new Error(`Could not accept the offer - ${error}`);
      },
    },
  )({ leadId, offerId, productId: product.productId, status: OfferProductStatus.ACCEPTED });
}

function* openOfferDocument(): SagaIterator {
  const [offerId] = yield mapPathToParams(getPaths().GUIDE_ACCEPTANCE_ID);

  yield put(GuideActions.startDocumentPolling(
    offerId,
    FlatDocumentType.FLAT_OFFER,
  ));
}

function* sendUtilityData(action: AnyAction): SagaIterator {
  const leadId: string = yield select(getGuideLeadId);
  const [offerId] = yield mapPathToParams(getPaths().GUIDE_ACCEPTANCE_ID);
  const offers: Offer[] = yield select(getGuideOfferCollection);

  const offer = offers.find(offer => offer.id === offerId);
  if (!offer) { return; }

  yield processQuery(
    SET_UTILITY_DATA_QUERY,
    GuideAcceptanceRepository.patchUtilityChange,
    {
      onSuccess: res => dataGuard(GuideAcceptanceActions.setUtilityDataSuccess)(res!.element),
      onFailure: error => {
        throw new Error(`Could not patch utility data - ${error}`);
      },
    },
  )({ values: action.values, leadId, offerId: offer.id });
}

function* getUtilityData(): SagaIterator {
  const leadId: string = yield select(getGuideLeadId);

  yield processQuery(
    GET_UTILITY_DATA_QUERY,
    GuideAcceptanceRepository.getUtilityData,
    {
      onSuccess: res => dataGuard(GuideAcceptanceActions.setFetchedUtilityData)(res!.element),
      onFailure: error => {
        throw new Error(`Could not fetch utility data - ${error}`);
      },
    },
  )(leadId);
}

function* sendPvSystemKeys(action: AnyAction): SagaIterator {
  const leadId: string = yield select(getGuideLeadId);

  yield processQuery(
    SET_PV_SYSTEM_KEYS_QUERY,
    GuideAcceptanceRepository.patchPvSystemKeys,
    {
      onSuccess: res => dataGuard(GuideAcceptanceActions.setPvSystemKeysSuccess)(res!.element),
    },
  )({ values: action.values, leadId, configuration: action.configuration });
}

export const sagas = combineSagas(
  takeEvery(GUIDE_ACCEPTANCE_ACTIONS.SET_PAYMENT_DATA, setPaymentDataSaga),
  takeEvery(GUIDE_ACCEPTANCE_ACTIONS.ACCEPT_DOCUMENTS, acceptDocuments),
  takeEvery(GUIDE_ACCEPTANCE_ACTIONS.ACCEPT_OFFER, acceptOffer),
  takeEvery(GUIDE_ACCEPTANCE_ACTIONS.OPEN_FLAT_OFFER_DOCUMENT, openOfferDocument),
  takeLatest(GUIDE_ACCEPTANCE_ACTIONS.GET_ENERGY_PROVIDERS, getEnergyProviders),
  takeLatest(GUIDE_ACCEPTANCE_ACTIONS.SET_UTILITY_DATA, sendUtilityData),
  takeLatest(GUIDE_ACCEPTANCE_ACTIONS.GET_UTILITY_DATA, getUtilityData),
  takeLatest(GUIDE_ACCEPTANCE_ACTIONS.SET_PV_SYSTEM_KEYS, sendPvSystemKeys),
);
