import * as React from 'react';

import { PlatformSelector } from '+app/shared/components';
import { AnalysisEnergyFlowChartMobile } from './AnalysisEnergyFlowChart.mobile.component';
import { AnalysisEnergyFlowChartWeb } from './AnalysisEnergyFlowChart.web.component';

export const AnalysisEnergyFlowChart: React.FC = () => (
  <PlatformSelector
    web={<AnalysisEnergyFlowChartWeb />}
    mobile={<AnalysisEnergyFlowChartMobile />}
  />
);
