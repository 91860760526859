import {
  AnalysisChartCrosshair,
  AnalysisLiveBubbles,
  AnalysisTooltip,
  AnalysisTooltipMeasurementItem, EnergyFlowSeriesKey,
  factorizeTooltipDate,
  MediaQuery,
  prepareTooltipData,
  TimeUnit,
} from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';

import { LINE_CHART_HEIGHT } from '+analysis/components/AnalysisAreaChart/AnalysisAreaChart.mobile.layout';
import { BatteryHelper } from '+app/+dashboard/+battery/store/battery.helpers';
import { MeasurementMethod } from '+app/shared/store/live/types/siteLiveData.interface';
import { AnalysisAreaChart } from '../';
import { useAnalysisDayChart } from '../../containers/AnalysisKanvaProvider/AnalysisDayChartProvider';
import { useAnalysis } from '../../containers/AnalysisProvider/AnalysisProvider.context';
// TODO: move this CHART_HEIGHT const to some common place, some util/helper file
import { CHART_HEIGHT, TOP_DISTANCE } from '../../helpers/analysis.helper';
// TODO: move these options to some common file
import { desktopScaleOptions, getVisibleLiveBubblesData, mobileScaleOptions } from '../../helpers/analysis.helper';
import { chartTooltipStyles, getVisibleDataSeriesKeys, seriesKeyTranslationMap } from '../../helpers/tooltip.helper';
import { KanvaHelper } from '../../kanva/kanva.helper';
import { areLiveBubblesDisabled } from '../../store/analysis.helpers';
import { AnalysisTooltipChartWrapper } from '../AnalysisTooltipChartWrapper';

import './AnalysisEnergyFlowChart.web.component.scss';

export const AnalysisEnergyFlowChartWeb: React.FC = () => {
  const {
    commonData: {
      areaChartSeries,
      batteryData,
      measurementsMethod,
      selectedDate,
      selectedDataSeriesKeys,
      siteLiveData,
      statisticsSelectedDate,
      summerTimeChange,
    },
    query: {
      measurementsQueryStatus,
    },
  } = useAnalysis();
  const {
    chartView,
    currentXPosition,
    extensions,
    lastXYSeriesPosition,
    primarySeries,
    tooltipEvent,
    tooltipValues,
  } = useAnalysisDayChart();
  const [isTooltipActive, toggleTooltipActivity] = React.useState<boolean>(false);

  // TODO: move this const definition somewhere else, do we need to depend on chart_height variable here?
  const visibleDataSeriesKeys = getVisibleDataSeriesKeys(
    selectedDate,
    tooltipValues,
    selectedDataSeriesKeys,
  );
  const desktopAreaChartHeight = selectedDataSeriesKeys.includes('batteryCare' as EnergyFlowSeriesKey)
    ? CHART_HEIGHT + LINE_CHART_HEIGHT
    : CHART_HEIGHT;

  const tooltipExtension = extensions?.tooltip;
  const isEaton = BatteryHelper.isEaton(batteryData);
  const isMK1 = measurementsMethod === MeasurementMethod.METER_GRID;

  const liveBubblesData = getVisibleLiveBubblesData(
    lastXYSeriesPosition,
    siteLiveData,
    selectedDate,
    isEaton,
    isMK1,
    measurementsQueryStatus.pending,
    chartView,
  );

  return (
    <Media query={MediaQuery.UP_SM}>
      {(isDesktop: boolean) => isDesktop ? (
        <AnalysisTooltipChartWrapper
          className={'c-analysis-energy-flow-chart'}
          tooltipEvent={tooltipEvent}
          toggleTooltipActivity={toggleTooltipActivity}
          isNoDataDisplayed={!visibleDataSeriesKeys.length}
        >
          <AnalysisTooltip
            type={'vertical'}
            isVisible={isTooltipActive && measurementsQueryStatus.success}
            header={factorizeTooltipDate(
              TimeUnit.DAY,
              KanvaHelper.getSeriesXFromTooltipValues(primarySeries, tooltipValues),
              selectedDate,
              statisticsSelectedDate.date,
            )}
            x={currentXPosition}
          >
            {prepareTooltipData(
              tooltipValues,
              seriesKeyTranslationMap,
              visibleDataSeriesKeys,
              chartTooltipStyles,
            ).map(props => (
              <AnalysisTooltipMeasurementItem
                key={props.seriesKey}
                inline={true}
                {...props}
              />
            ))}
          </AnalysisTooltip>
          <div className={'c-analysis-energy-flow-chart__overflow-container'}>
            <AnalysisAreaChart
              chartHeight={desktopAreaChartHeight + 50}
              scaleOptions={desktopScaleOptions}
            />
            {!areLiveBubblesDisabled(areaChartSeries, summerTimeChange) && (
              <AnalysisLiveBubbles
                data={liveBubblesData}
              />
            )}
          </div>
          {/*
            * @TODO: Handle isVisible flag in a simpler way.
            * Ideally, it should be always visible when there is no mouse present
            * and interactions happen on touch. Right now we just check resolution.
            */}
          <Media query={MediaQuery.UP_MD}>
            {(isDesktop: boolean) => isDesktop ? (
              <AnalysisChartCrosshair
                crosshairPosition={currentXPosition}
                height={desktopAreaChartHeight + 50}
                isVisible={isTooltipActive && measurementsQueryStatus.success}
              />
            ) : (
              <AnalysisChartCrosshair
                crosshairPosition={currentXPosition}
                height={desktopAreaChartHeight + 50}
                isVisible={true}
              />
            )}
          </Media>
        </AnalysisTooltipChartWrapper>
      ) : (
        <AnalysisTooltipChartWrapper
          className={'c-analysis-energy-flow-chart__wrapper'}
          tooltipEvent={tooltipEvent}
          chartView={chartView}
          tooltipExtension={tooltipExtension}
          isNoDataDisplayed={!visibleDataSeriesKeys.length}
        >
          <div className={'c-analysis-energy-flow-chart__tooltip'}>
            <AnalysisTooltip
              type={'horizontal'}
              isVisible={measurementsQueryStatus.success}
              header={factorizeTooltipDate(
                TimeUnit.DAY,
                KanvaHelper.getSeriesXFromTooltipValues(primarySeries, tooltipValues),
                selectedDate,
                statisticsSelectedDate.date,
              )}
            >
              {prepareTooltipData(
                tooltipValues,
                seriesKeyTranslationMap,
                visibleDataSeriesKeys,
                chartTooltipStyles,
              ).map(props => (
                <AnalysisTooltipMeasurementItem
                  key={props.seriesKey}
                  {...props}
                />
              ))}
            </AnalysisTooltip>
          </div>
          <div className={'c-analysis-energy-flow-chart'}>
            <AnalysisAreaChart
              chartHeight={desktopAreaChartHeight}
              scaleOptions={mobileScaleOptions}
            />
            <AnalysisChartCrosshair
              crosshairPosition={currentXPosition}
              height={desktopAreaChartHeight + TOP_DISTANCE}
              isVisible={measurementsQueryStatus.success}
            />
            {!areLiveBubblesDisabled(areaChartSeries, summerTimeChange) && (
              <AnalysisLiveBubbles
                data={liveBubblesData}
              />
            )}
          </div>
        </AnalysisTooltipChartWrapper>
        )}
      </Media>
  );
};
