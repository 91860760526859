import { RouteComponentProps, StaticContext } from 'react-router';

import { getLastUrlFragment } from '+app/router/router.helper';
import {
  GuideDiscoveryStepDistribution,
  GuideDiscoveryStepOnSiteVisit,
  GuideDiscoveryStepPrepareForVisit,
} from '../+discovery/containers';
import { GuidePurchaseStepOrderConfirmationCommon } from '../+purchase/components';
import {
  GuidePurchaseStepAcceptOffer,
  GuidePurchaseStepCessionDoc,
  GuidePurchaseStepFlatOrderConfirmation,
  GuidePurchaseStepHardwareOrderConfirmation,
} from '../+purchase/containers';
import {
  GuideSetupStepBNetzARegistration,
  GuideSetupStepBNetzARegistrationPartner,
  GuideSetupStepDsoMeterInstallation,
  GuideSetupStepDsoRegistration,
  GuideSetupStepHardwareCommissioning,
  GuideSetupStepTaxOfficeRegistration,
  // GuideSetupStepWarrantyAcceptance,
} from '../+setup/containers';
import { getVisibleSteps } from './steps.helper';
import { getStepsAccess } from './stepsAccess.config';

// @TODO Add Warranty Step to Setup whenever available
export enum StepName {
  DISTRIBUTION_TO_PARTNER = 'distribution-to-partner',
  ON_SITE_VISIT = 'on-site-visit',
  PREPARE_FOR_VISIT = 'prepare-for-visit',
  ACCEPT_OFFER = 'accept-offer',
  FLAT_ORDER_CONFIRMATION = 'flat-order-confirmation',
  HARDWARE_ORDER_CONFIRMATION = 'hardware-order-confirmation',
  COMMON_ORDER_CONFIRMATION = 'common-order-confirmation',
  TAX_OFFICE_REGISTRATION = 'tax-office-registration',
  BNETZA_REGISTRATION = 'bnetza-registration',
  DSO_REGISTRATION = 'dso-registration',
  HARDWARE_COMMISSIONING = 'hardware-commissioning',
  DSO_METER_INSTALLATION = 'dso-meter-installation',
  BNETZA_REGISTRATION_PARTNER = 'bnetza-registration-partner',
  CESSION_DOC = 'cession-doc',
}

export enum PHASES {
  DISCOVERY = 'DISCOVERY',
  PURCHASE = 'PURCHASE',
  SETUP = 'SETUP',
  ENJOY = 'ENJOY',
}

export enum TABS {
  YOUR_TODO = 0,
  OUR_TODO = 1,
}

// @TODO Should we move it to router config?
// @TODO Find another way to provide conditions for isStepActive
export const getSteps = (): Steps => ({
  // Discovery phase
  [StepName.DISTRIBUTION_TO_PARTNER]: {
    phase: PHASES.DISCOVERY,
    tab: TABS.OUR_TODO,
    component: GuideDiscoveryStepDistribution,
    name: StepName.DISTRIBUTION_TO_PARTNER,
  },
  [StepName.ON_SITE_VISIT]: {
    phase: PHASES.DISCOVERY,
    tab: TABS.OUR_TODO,
    component: GuideDiscoveryStepOnSiteVisit,
    name: StepName.ON_SITE_VISIT,
  },
  [StepName.PREPARE_FOR_VISIT]: {
    phase: PHASES.DISCOVERY,
    tab: TABS.YOUR_TODO,
    component: GuideDiscoveryStepPrepareForVisit,
    name: StepName.PREPARE_FOR_VISIT,
  },
  // Purchase phase
  [StepName.ACCEPT_OFFER]: {
    phase: PHASES.PURCHASE,
    tab: TABS.YOUR_TODO,
    component: GuidePurchaseStepAcceptOffer,
    name: StepName.ACCEPT_OFFER,
  },
  [StepName.CESSION_DOC]: {
    phase: PHASES.PURCHASE,
    tab: TABS.YOUR_TODO,
    component: GuidePurchaseStepCessionDoc,
    name: StepName.CESSION_DOC,
  },
  [StepName.FLAT_ORDER_CONFIRMATION]: {
    phase: PHASES.PURCHASE,
    tab: TABS.OUR_TODO,
    component: GuidePurchaseStepFlatOrderConfirmation,
    name: StepName.FLAT_ORDER_CONFIRMATION,
  },
  [StepName.HARDWARE_ORDER_CONFIRMATION]: {
    phase: PHASES.PURCHASE,
    tab: TABS.OUR_TODO,
    component: GuidePurchaseStepHardwareOrderConfirmation,
    name: StepName.HARDWARE_ORDER_CONFIRMATION,
  },
  [StepName.COMMON_ORDER_CONFIRMATION]: {
    phase: PHASES.PURCHASE,
    tab: TABS.OUR_TODO,
    component: GuidePurchaseStepOrderConfirmationCommon,
    name: StepName.COMMON_ORDER_CONFIRMATION,
  },
  // Setup phase
  [StepName.TAX_OFFICE_REGISTRATION]: {
    phase: PHASES.SETUP,
    tab: TABS.YOUR_TODO,
    component: GuideSetupStepTaxOfficeRegistration,
    name: StepName.TAX_OFFICE_REGISTRATION,
  },
  [StepName.BNETZA_REGISTRATION]: {
    phase: PHASES.SETUP,
    tab: TABS.YOUR_TODO,
    component: GuideSetupStepBNetzARegistration,
    name: StepName.BNETZA_REGISTRATION,
  },
  [StepName.DSO_REGISTRATION]: {
    phase: PHASES.SETUP,
    tab: TABS.OUR_TODO,
    component: GuideSetupStepDsoRegistration,
    name: StepName.DSO_REGISTRATION,
  },
  [StepName.HARDWARE_COMMISSIONING]: {
    phase: PHASES.SETUP,
    tab: TABS.OUR_TODO,
    component: GuideSetupStepHardwareCommissioning,
    name: StepName.HARDWARE_COMMISSIONING,
  },
  [StepName.DSO_METER_INSTALLATION]: {
    phase: PHASES.SETUP,
    tab: TABS.OUR_TODO,
    component: GuideSetupStepDsoMeterInstallation,
    name: StepName.DSO_METER_INSTALLATION,
  },
  [StepName.BNETZA_REGISTRATION_PARTNER]: {
    phase: PHASES.SETUP,
    tab: TABS.OUR_TODO,
    component: GuideSetupStepBNetzARegistrationPartner,
    name: StepName.BNETZA_REGISTRATION_PARTNER,
  },
});

export type Steps = {
  [K in StepName]: Step;
};

export interface Step {
  phase: PHASES;
  tab: TABS;
  component: RouteComponent;
  name: StepName;
}

export type RouteComponent = React.ComponentClass<any, any> |
  React.FunctionComponent<any> |
  React.ComponentClass<RouteComponentProps<any, StaticContext, any>, any> |
  React.FunctionComponent<RouteComponentProps<any, StaticContext, any>> |
  undefined;

export const getStepsFromPhase = (
  phase: PHASES,
  tab: TABS,
) => {
  const stepAccess = getStepsAccess();
  const steps = getSteps();
  const visibleSteps = getVisibleSteps(steps, stepAccess);

  return Object.values(visibleSteps)
    .filter(step => step.phase === phase && step.tab === tab);
};

export const getTabFromStep = (path: string) => {
  const steps = getSteps();
  const stepName = getLastUrlFragment(path);
  const foundObject = Object.keys(steps).find(key => steps[key].name === stepName);
  return foundObject && steps[foundObject].tab;
};
