import { T } from '@sonnen/shared-i18n/customer';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

import { Configuration, ConfigurationResponsePvSystem } from '+app/+guide/store/types/configuration.interface';
import { UtilityDataPvSystemKeys } from '+guide/+acceptance';

export const PV_SYSTEM_KEY_REGEX = /^[A-Z0-9]{32}$/;

export const pvSystemKeysFormInitial: UtilityDataPvSystemKeys = {
  pvSystemKeyOne: '',
  pvSystemKeyTwo: '',
};

export enum SystemKeyNumber {
  PV_SYSTEM_ONE = '0',
  PV_SYSTEM_TWO = '1',
}

const getConfigurationSubsystems = (configuration: Configuration): ConfigurationResponsePvSystem[] | undefined =>
  configuration.photovoltaicSystem.subSystems;

const isCommissioningDateOlderThan60Days = (commissioningDate?: string): boolean =>
  moment(commissioningDate).diff(moment(), 'days') <= -60;

const isCommissioningDateInFuture = (commissioningDate?: string): boolean =>
  moment(commissioningDate).diff(moment(), 'days') > 0;

export const isCommissioningDateOptional = (configuration: Configuration, commissioningDate?: string): boolean => {
  return !!getConfigurationSubsystems(configuration)
    ? !isCommissioningDateOlderThan60Days(commissioningDate) || isCommissioningDateInFuture(commissioningDate)
    : true;
};

const isCommissioningDateFormFieldOptional =
  (configuration: Configuration, systemKeyNumber: SystemKeyNumber): boolean => {
    const commissioningDate = getConfigurationSubsystems(configuration)
      ? getConfigurationSubsystems(configuration)?.[systemKeyNumber].commissioningDate
      : configuration.photovoltaicSystem.commissioningDate;

    return isCommissioningDateOptional(configuration, commissioningDate);
  };

export const UtilityDataPvSystemKeysSchema = (configuration: Configuration) => Yup.object().shape({
  pvSystemKeyOne: Yup
    .string()
    .matches(PV_SYSTEM_KEY_REGEX, () => I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.validation.format))
    .test(
      'pvSystemKeyOne',
      I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.validation.differentPvSystemKeys),
      function (pvSystemKeyOne: string) {
        const pvSystemKeyTwo: string = this.parent.pvSystemKeyTwo;

        if (!pvSystemKeyOne || !pvSystemKeyTwo) {
          return true;
        }

        return pvSystemKeyOne !== pvSystemKeyTwo;
      })
    // 'fakeCommissioningDateField' is a fake form field, which we rely on to provide some additional validation
    // based on commissioning date, which is an outer parameter (it's not a form field).
    // The reason for this is that unfortunately passing context into Yup doesn't work with Formik.
    .when('fakeCommissioningDateField', {
      is: () => !isCommissioningDateFormFieldOptional(configuration, SystemKeyNumber.PV_SYSTEM_ONE),
      then: Yup.string()
        .required(() => I18n.t(T.customerGuidance.acceptance.requiredField)),
    }),
  pvSystemKeyTwo: getConfigurationSubsystems(configuration)
    ? Yup
      .string()
      .matches(
        PV_SYSTEM_KEY_REGEX,
        () => I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.validation.format),
      )
      .test(
        'pvSystemKeyTwo',
        I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.validation.differentPvSystemKeys),
        function (pvSystemKeyTwo: string) {
          const pvSystemKeyOne: string = this.parent.pvSystemKeyOne;

          if (!pvSystemKeyOne || !pvSystemKeyTwo) {
            return true;
          }

          return pvSystemKeyOne !== pvSystemKeyTwo;
        })
      .when('fakeCommissioningDateField', {
        is: () => !isCommissioningDateFormFieldOptional(configuration, SystemKeyNumber.PV_SYSTEM_TWO),
        then: Yup.string()
          .required(() => I18n.t(T.customerGuidance.acceptance.requiredField)),
      })
    : Yup.string(),
});

export const getPvSystemName = (systemKeyNumber: SystemKeyNumber, configuration: Configuration): string | undefined =>
  getConfigurationSubsystems(configuration)
    ? configuration?.photovoltaicSystem?.subSystems?.[systemKeyNumber].systemName
    : undefined;

export const getPvSystemCommissioningDate =
  (systemKeyNumber: SystemKeyNumber, configuration: Configuration): string | undefined =>
    getConfigurationSubsystems(configuration)
      ? getConfigurationSubsystems(configuration)?.[systemKeyNumber].commissioningDate
      : configuration.photovoltaicSystem.commissioningDate;
