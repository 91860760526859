import { T } from '@sonnen/shared-i18n/customer';
import { breakpointDown, Carousel, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { hasInstalledBattery } from '+app/+dashboard/+battery/store/battery.selectors';
import { StoreState } from '+app/store/store.interface';
import { Reporter } from '+utils/reporter.util';
import {
  ContractCardHardwareInstallation,
  ContractCardSonnenFlatXEnergyActivated,
  ContractCardSupplyByEnergy,
} from '..';
import { ContractCardOrdered, ContractFlatStepsCounter } from '../../components';
import { getCurrentSiteContractData } from '../../store/contract.selectors';
import { getContractActiveSteps, getContractStepsCount, getContractTotalStepsCount } from '../../store/helpers/contract.helpers';

import './ContractFlatStatuses.component.scss';

const mapStateToProps = (store: StoreState) => ({
  hasInstalledBattery: hasInstalledBattery(store),
  contract: getCurrentSiteContractData(store),
});

type Props = ReturnType<typeof mapStateToProps>;

export const ContractFlatStatusesComponent: React.FC<Props> = ({ contract, hasInstalledBattery }) => {
  if (!contract) {
    const errorMessage = 'Rendered ContractFlatStatusesComponent without required contract data';
    Reporter.reportError({name: 'render null', message: errorMessage});
    console.error(errorMessage); // TODO make sure console.error is removed from production build

    return null;
  }

  const activeSteps = getContractActiveSteps(contract);
  const stepsCount = getContractStepsCount(contract, hasInstalledBattery);
  const totalStepsCount = getContractTotalStepsCount(contract);

  const breakpointsConfig = React.useMemo(() => [
    {
      breakpoint: breakpointDown('XLG'),
      settings: {
        slidesToShow: totalStepsCount,
        slidesToScroll: 0,
      },
    },
    {
      breakpoint: breakpointDown('MD'),
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ], [totalStepsCount]);

  return (
    <div className={'c-contract-flat-statuses'}>
      <PageSubheadline mediumGap={true}>
        {I18n.t(T.yourFlat.sonnenFlatX.contractStatus)}
      </PageSubheadline>
      <ContractFlatStepsCounter max={totalStepsCount} count={stepsCount} />
      <Carousel
        infinite={false}
        centerMode={false}
        arrowsOnDesktop={false}
        slidesToShow={totalStepsCount}
        slidesToScroll={0}
        dots={false}
        responsive={breakpointsConfig}
      >
        {activeSteps.contractOrdered && <ContractCardOrdered />}
        {activeSteps.hardwareInstallation && <ContractCardHardwareInstallation />}
        {activeSteps.supplyByEnergy && <ContractCardSupplyByEnergy />}
        {activeSteps.energyActivated && <ContractCardSonnenFlatXEnergyActivated />}
      </Carousel>
    </div>
  );
};

export const ContractFlatStatuses = connect(mapStateToProps)(
  ContractFlatStatusesComponent,
);
