import { FormCheckbox } from '@sonnen/shared-web';
import { isEmpty } from 'lodash';
import * as React from 'react';

import { FormElement } from '../Form.component';

export interface FormCheckboxProps {
  name: string;
  label: React.ReactNode;
  required?: boolean;
}

export const Checkbox: FormElement<FormCheckboxProps> = ({
  label,
  required,
  meta: {
    error,
  },
  input: {
    name,
    value,
    ...rest
  },
}) => {
  const [isTouched, setIsTouched] = React.useState(false);
  const showError: boolean = isTouched && !isEmpty(error);

  return (
    <FormCheckbox
      name={name}
      label={label}
      checked={value}
      isError={showError}
      isRequired={required}
      {...rest}
      onClick={(checked) => setIsTouched(!checked)}
    />
  );
};
