import { Icon } from '@sonnen/shared-web';
import * as React from 'react';

import './InfoBox.component.scss';

interface Props {
  title?: string;
  description: React.ReactNode;
  isIcon?: boolean;
}

export const InfoBox: React.FC<Props> = ({
  description,
  isIcon = true,
  title,
}) => (
  <div className={'c-info-box'}>
    {isIcon && (
      <Icon.Info className={'c-info-box__icon'} />
    )}
    <div className={'c-info-box__content'}>
      {title && (
        <p className={'c-info-box__title'}>
          {title}
        </p>
      )}
      <div className={'c-info-box__description'}>
        {description}
      </div>
    </div>
  </div>
);
