import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { EventWithDataHandler } from 'redux-form';

import { FormError } from '+legacy/helpers/error';
import { Form, FormElement } from '../../Form.component';
import { PasswordStrengthTooltip } from './PasswordStrengthTooltip.component';

import './Password.component.scss';

export interface FormPasswordProps {
  label: string;
  passwordCheck?: boolean;
  onBlur?: EventWithDataHandler<React.FocusEvent<any>>;
  dataTestId?: string;
}

export const Password: FormElement<FormPasswordProps> = ({
  input,
  label = I18n.t(T.general.labels.password),
  meta: {
    touched,
    active,
    error,
    warning,
  },
  passwordCheck = false,
  dataTestId,
  onBlur,
}) => {
  const [isMasked, setMasked] = React.useState(true);

  const changeModePress = (evt: React.KeyboardEvent) => {
    if (evt.keyCode === 13) {
      changeMode();
    }
  };

  const changeMode = () => {
    setMasked(!isMasked);
  };
  const inputType = isMasked ? 'password' : 'text';

  const showPasswordIconClasses = classNames('c-password__icon', {
    'is-active': !isMasked,
  });

  const inputLabelClasses = classNames('c-form__label c-form__label--floating', {
    'is-active': active || input.value,
  });

  return (
    <div className={'c-form__item'}>
      <PasswordStrengthTooltip
        passwordStrength={warning || 0}
        disabled={!passwordCheck}
      >
        <input
          data-test-id={dataTestId}
          className={'c-form__input'}
          {...input}
          type={inputType}
          onBlur={onBlur}
        />
        <label
          className={inputLabelClasses}
          htmlFor={input.name}
        >
          {label}
        </label>
      </PasswordStrengthTooltip>
      {input.value && (
        <button
          className={'c-password__icon-button'}
          onKeyDown={changeModePress}
          onClick={changeMode}
          role={'checkbox'}
          tabIndex={-1}
          type={'button'}
        >
          <Icon.Eye
            className={showPasswordIconClasses}
          />
        </button>
      )}
      {touched && error && error.length && (
        <Form.Info>
          {error.map((errorElement: FormError) => (
            <Form.Alert 
              dataTestId={'account-password-change-form-error-messages'} 
              key={errorElement.id}>
              {errorElement.label}
            </Form.Alert>
          ))}
        </Form.Info>
      )}
    </div>
  );
};
