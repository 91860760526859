import { WebStorage } from 'redux-persist';
import { sendAction } from './mobile.integration';
import { IntegrationActions } from './store/mobile.actions';

const STORAGE_GET_TIMEOUT = 10000;

export class MobileStorage implements WebStorage {
  private storage: any;

  async getItem(key: string): Promise<string> {
    const storage = await this.getStorage();
    return storage[key];
  }

  async removeItem(key: string): Promise<void> {
    const storage = await this.getStorage();
    delete storage[key];
    sendAction(IntegrationActions.removeStorageItem(key));
  }

  async setItem(key: string, item: string): Promise<string> {
    const storage = await this.getStorage();
    storage[key] = String(item);
    sendAction(IntegrationActions.setStorageItem(key, storage[key]));
    return storage[key];
  }

  setStorage(storage: Storage) {
    this.storage = storage;
  }

  private getStorage(): Promise<void> {
    if (this.storage !== undefined) {
      return Promise.resolve(this.storage);
    }
    return new Promise(((resolve, reject) => {
      const start = Date.now();
      const checkStoragePresence = () => setTimeout(() => {
        if (this.storage) {
          resolve(this.storage);
          return;
        }
        if (Date.now() - start > STORAGE_GET_TIMEOUT) {
          reject();
          return;
        }
        checkStoragePresence();
      }, 100);
      checkStoragePresence();
    }));
  }
}
