import * as React from 'react';

interface Props {
  dataTestId?: string;
}

export const Alert: React.FC<Props> = ({ children, dataTestId }) => (
  <p 
    data-test-id={dataTestId} 
    className={'c-form__alert'}>
    {children}
  </p>
);
