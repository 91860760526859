import { T } from '@sonnen/shared-i18n/customer';
import { Icon, Media } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './GuideVatInfo.component.scss';

interface Props {
  rate: string;
}

export const GuideVatInfo: React.FC<Props> = ({ rate }) => (
  <div className={'c-guide-vat-info'}>
    <Media
      side={<Icon.Info className={'c-guide-vat-info__icon'} />}
      content={(
        <p className={'c-guide-vat-info__content'}>
          {I18n.t(T.customerGuidance.taxInfo, { rate })}
        </p>
      )}
    />
  </div>
);
