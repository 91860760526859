import * as React from 'react';

import { Route } from '+app/router/router.types';
import { DefaultNav } from '../DefaultNav';

import './HeaderNav.component.scss';

interface Props {
  routes: Route[];
}

export const HeaderNav: React.FC<Props> = ({ routes }) => (
  <nav className={'c-header-nav'}>
    <DefaultNav
      routes={routes}
      isThemeNoIcon={true}
    />
  </nav>
);
