import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { Battery } from '+app/+dashboard/+battery/store/types/battery.interface';
import { DemoBubble } from '+app/+demo/components';
import { SiteLive } from '+app/shared/store/live/types/siteLiveData.interface';
import { BatteryCardStatus } from '+dashboard/+battery/components';

import './DemoBatteryCardStatus.component.scss';

interface Props {
  liveData: SiteLive;
  batteryData: Battery;
}

export const DemoBatteryCardStatus: React.FC<Props> = ({ batteryData, liveData }) => (
  <div className={'c-demo-battery-card-status'}>
    <BatteryCardStatus
      batteryData={batteryData}
      liveData={liveData}
    />
    <div className={'c-demo-battery-card-status__bubble'}>
      <DemoBubble
        dataTestId={'demo-battery-status-bubble'}
        side={'bottom'}
        icon={Icon.SonnenBattery}
        headline={I18n.t(T.demo.battery.bubble.headline)}
        buttonLabel={I18n.t(T.demo.battery.bubble.buttonLabel)}
      >
        {I18n.t(T.demo.battery.bubble.text)}
      </DemoBubble>
    </div>
  </div>
);
