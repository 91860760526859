import { useJsonApi } from '+app/shared/network';
import { CONFIG } from '+config';
import { SiteData } from './types/site.interface';

const getSites = (id: string) =>
  useJsonApi()
    .getList<SiteData>(`${CONFIG.API.USER_DATA}/users/${id}/sites?include=battery_systems`)
    .send()
    .then(res => ({
      data: res.elements,
      included: res.raw.included,
    }));

export const SiteRepository = {
  getSites,
};
