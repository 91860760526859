import * as React from 'react';

import { CommunityGraph, CommunityHeader } from '+legacy/pages/Community/components';
import { DemoCommunityMapSectionMobile } from '../';

import './DemoCommunity.mobile.component.scss';

export const DemoCommunityMobile: React.FC = () => (
  <div className={'m-c-demo-community'}>
    <CommunityHeader/>
    <DemoCommunityMapSectionMobile />
    <CommunityGraph />
  </div>
);
