import { history } from '+router/store/index';
import { Icon, LinkButton } from '@sonnen/shared-web';
import * as React from 'react';

import './AccountProfileCardLink.component.scss';

interface Props {
  path: string;
}

export const AccountProfileCardLink: React.FC<Props> = ({
  path,
  children,
}) => (
  <LinkButton onClick={() => history.push(path)}>
    {children}
    <Icon.Angle className={'c-account-profile-card-link__button-icon'}/>
  </LinkButton>
);
