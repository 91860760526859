import { FeatureProvider } from '@sonnen/shared-web';
import { AppConfiguration, getAppConfig } from './app.config';
import { EnvConfiguration, getEnvConfig } from './env.config';
import { Env, getEnvironment } from './environment';

type Configuration = EnvConfiguration & AppConfiguration;

const getConfig = (environment: Env): Configuration => ({
  ...getAppConfig(environment),
  ...getEnvConfig(environment),
});

FeatureProvider.setEnvironmentAccessor(getEnvironment);

export const CONFIG = getConfig(getEnvironment());
