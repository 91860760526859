import { StoreState } from '+app/store/store.interface';
import { getStatus } from '+utils/query.util';
import { createSelector } from 'reselect';
import { getOfferFlatProduct } from './+guide.helper';
import { GET_CESSION_DOCUMENT, GET_CESSION_STATUS } from './+guide.state';
import { OfferProductStatus } from './types';

export const guideFlatAcceptanceForm = 'guideFlatAcceptance';

export const getGuideState = (state: StoreState) => state.guide;

/**
 * AUTH
 */

export const getGuideAuthState = createSelector(
  getGuideState,
  state => state.auth,
);

export const getGuideAuthToken = createSelector(
  getGuideAuthState,
  state => state.token,
);

export const getGuideVerifiedAuthToken = createSelector(
  getGuideAuthState,
  state => state.verifiedToken,
);

export const getGuidePersistedVerifiedAuthToken = createSelector(
  getGuideAuthState,
  state => (leadId: string) => state.persistedTokens[leadId],
);

export const isVerifyingIdentity = createSelector(
  getGuideAuthState,
  state => state.verifyingIdentity,
);

export const getAuthError = createSelector(
  getGuideAuthState,
  state => state.error,
);

/**
 * COMMON
 */

export const getGuideCommonState = createSelector(
  getGuideState,
  state => state.common,
);

export const getPhaseActiveTab = createSelector(
  getGuideCommonState,
  state => state.phaseActiveTab,
);

export const getGuideOfferCollection = createSelector(
  getGuideCommonState,
  state => state.offers,
);

export const getGuideAcceptedOffer = createSelector(
  getGuideOfferCollection,
  offers => offers.find(offer =>
    getOfferFlatProduct(offer)!.status === OfferProductStatus.ACCEPTED,
  ),
);

export const getGuideAcceptedOrConfirmedOffer = createSelector(
  getGuideOfferCollection,
  offers => offers.find(offer =>
    getOfferFlatProduct(offer)!.status === OfferProductStatus.ACCEPTED || OfferProductStatus.CONFIRMED,
  ),
);

export const getDocumentUrl = createSelector(
  getGuideCommonState,
  state => state.documentUrl,
);

export const getGuideOfferHardwareOfferCollection = createSelector(
  getGuideCommonState,
  state => state.hardwareOffers,
);

export const getImpactAnalysisList = createSelector(
  getGuideCommonState,
  state => state.impactAnalysisList,
);

export const getCessionDocUrl = createSelector(
  getGuideCommonState,
  state => state.cessionDocUrl,
);

export const getCessionDocUrlQuery = createSelector(
  getGuideCommonState,
  state => state[GET_CESSION_DOCUMENT],
);

export const getCessionDocUrlQueryStatus = createSelector(
  getCessionDocUrlQuery,
  query => getStatus(query),
);

export const getCessionStatus = createSelector(
  getGuideCommonState,
  state => state.cessionStatus,
);

export const getCessionTimestamps = createSelector(
  getGuideCommonState,
  state => state.cessionTimestamps,
);

export const getCessionStatusQuery = createSelector(
  getGuideCommonState,
  state => state[GET_CESSION_STATUS],
);

export const getCessionStatusQueryStatus = createSelector(
  getCessionStatusQuery,
  query => getStatus(query),
);

export const getCessionFailed = createSelector(
  getGuideCommonState,
  state => state.cessionFailed,
);

export const getGuideCessionDocumentStatus = createSelector(
  getGuideCommonState,
  common => common.cessionDocumentStatus,
);

/**
 * FORM DATA
 */

export const getFormState = (state: StoreState) => state.form;

export const getGuideFlatAcceptanceForm = createSelector(
  getFormState,
  state => state[guideFlatAcceptanceForm],
);

export const getGuideFlatAcceptanceFormFields = createSelector(
  getGuideFlatAcceptanceForm,
  state => state.values,
);
