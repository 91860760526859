import { BatteryHelper } from '+battery/store/battery.helpers';
import { Battery } from '+battery/store/types/battery.interface';
import { SpecsItem } from '+shared/components';
import { T } from '@sonnen/shared-i18n/customer';
import { Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './BatterySpecsInstaller.component.scss';

interface Props {
  batteryData: Battery | undefined;
}

export const BatterySpecsInstaller: React.SFC<Props> = ({ batteryData }) => {
  const installerAddress = BatteryHelper.getInstallerAddress(batteryData);

  if (!batteryData) {
    return <Loader />;
  }

  return (
    <div className={'c-battery-spec-installer'}>
      {batteryData.installerName && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-installer-installer-name'}
          title={I18n.t(T.batterySystem.details.sections.installer.parts.name)}
          value={batteryData.installerName}
        />
      )}
      {batteryData.installerCompany && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-installer-company'}
          title={I18n.t(T.batterySystem.details.sections.installer.parts.company)}
          value={batteryData.installerCompany}
        />
      )}
      {installerAddress && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-installer-installer-address'}
          title={I18n.t(T.batterySystem.details.sections.installer.parts.address)}
          value={installerAddress}
        />
      )}
      {batteryData.installerPhone && (
        <SpecsItem
          dataTestId={'sonnen-batterie-specs-installer-installer-phone'}
          title={I18n.t(T.batterySystem.details.sections.installer.parts.phoneNumber)}
          value={batteryData.installerPhone}
        />
      )}
      {batteryData.installerEmail && (
        <SpecsItem 
          dataTestId={'sonnen-batterie-specs-installer-installer-email'}
          title={I18n.t(T.batterySystem.details.sections.installer.parts.email)}
          value={batteryData.installerEmail}
        />
      )}
    </div>
  );
};
