import { T } from '@sonnen/shared-i18n/customer';
import { BoldParagraph } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { FormInputRadio } from '+app/shared/components';
import { formFields, UtilityDataForm } from '../../containers';
import { UtilityChangePeriod } from './GuideAcceptanceUtilityMoveHouse.helper';

import './GuideAcceptanceUtilityMoveHouse.component.scss';

interface Props {
  form: FormikProps<UtilityDataForm>;
}

export const GuideAcceptanceUtilityMoveHouse = ({ form }: Props) => (
  <>
    <div className={'c-guide-acceptance-utility-move-house'}>
      <div className={'c-guide-acceptance-utility-move-house__text'}>
        <BoldParagraph>
          {I18n.t(T.customerGuidance.acceptance.utilityData.moveHouses.question)}
        </BoldParagraph>
      </div>

      <div className={'c-guide-acceptance-utility-move-house__radio-btn'}>
        <FormInputRadio
          form={form}
          label={I18n.t(T.customerGuidance.acceptance.utilityData.moveHouses.never)}
          name={formFields.IS_MOVING_HOUSES}
          value={UtilityChangePeriod.NOT_MOVED}
        />
      </div>
      <div className={'c-guide-acceptance-utility-move-house__radio-btn'}>
        <FormInputRadio
          form={form}
          label={I18n.t(T.customerGuidance.acceptance.utilityData.moveHouses.lastFourWeeks)}
          name={formFields.IS_MOVING_HOUSES}
          value={UtilityChangePeriod.MOVED_WITHIN_FOUR_WEEKS}
        />
      </div>
      <div className={'c-guide-acceptance-utility-move-house__radio-btn'}>
        <FormInputRadio
          form={form}
          label={I18n.t(T.customerGuidance.acceptance.utilityData.moveHouses.nextSixMonths)}
          name={formFields.IS_MOVING_HOUSES}
          value={UtilityChangePeriod.WILL_MOVE_IN_SIX_MONTHS}
        />
      </div>
    </div>
  </>
);
