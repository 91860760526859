import { Data, MergedData } from '@coolio/json-api';

export enum HouseType {
  OLD = 'old',
  NEW = 'new',
}

interface EnergyProviderAttributes {
  name: string;
  region: string;
}

export type EnergyProvidersData = Data<EnergyProviderAttributes>;
export type EnergyProvider = MergedData<EnergyProvidersData>;
