interface PMValidationConstraint {
  errorCode?: string;
  rule: string;
  message: string;
  params?: Record<PropertyKey, string>;
}

export interface PMPropertyValidationError {
  property: string;
  constraints: PMValidationConstraint[];
}

export interface PMValidationError extends Error {
  statusCode: number;
  statusText: string;
  source: 'ValidationFilter';
  errors: PMPropertyValidationError[];
}

export enum PMErrorCodes {
  COMISSIONING_DATE_RANGE = '0001',
}
