import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { ExtraProcessQueryParams, RepositoryFunction } from '+utils/saga/processQuery.util';
import { QueryActions } from './query.actions';

type QueryPayload<Input, Response> = {
  queryName: string;
  queryFunction: RepositoryFunction<Input, Response>;
  extraParams: ExtraProcessQueryParams<Response>;
  input: Input;
};

export function * query<Input, Response>({
  queryName,
  queryFunction,
  extraParams: { onSuccess, onFailure },
  input,
}: QueryPayload<Input, Response>): SagaIterator {
  yield put(QueryActions.pending(queryName));
  try {
    const response: Response = yield call(queryFunction, input);
    if (!!onSuccess) { yield onSuccess(response); }
    yield put(QueryActions.success(queryName, response));
  } catch (error) {
    if (!!onFailure) { yield onFailure(error); }
    yield put(QueryActions.failure(queryName, error));
  }
}
