import { StoreState } from '+app/store/store.interface';
import { getStatus } from '+utils/query.util';
import { createSelector } from 'reselect';
import {
  ACCEPT_DOCUMENTS_QUERY,
  ACCEPT_OFFER_QUERY,
  GET_UTILITY_DATA_QUERY,
  SET_PAYMENT_DATA_QUERY,
  SET_PV_SYSTEM_KEYS_QUERY,
  SET_UTILITY_DATA_QUERY,
} from './+acceptance.state';

export const getAcceptanceState = (state: StoreState) => state.guide.acceptance;

const getPaymentData = createSelector(
  getAcceptanceState,
  state => state[SET_PAYMENT_DATA_QUERY],
);

export const getPaymentDataStatus = createSelector(
  getPaymentData,
  query => getStatus(query),
);

const getAcceptOffer = createSelector(
  getAcceptanceState,
  state => state[ACCEPT_OFFER_QUERY],
);

export const getAcceptOfferStatus = createSelector(
  getAcceptOffer,
  query => getStatus(query),
);

export const getEnergyProviders = createSelector(
  getAcceptanceState,
  state => state.energyProviders,
);

const getUtilityDataQuery = createSelector(
  getAcceptanceState,
  state => state[SET_UTILITY_DATA_QUERY],
);

export const getUtilityDataQueryStatus = createSelector(
  getUtilityDataQuery,
  query => getStatus(query),
);

export const getUtilityData = createSelector(
  getAcceptanceState,
  state => state.utilityData,
);

export const getFetchedUtilityDataQuery = createSelector(
  getAcceptanceState,
  state => state[GET_UTILITY_DATA_QUERY],
);

export const getFetchedUtilityDataQueryStatus = createSelector(
  getFetchedUtilityDataQuery,
  query => getStatus(query),
);

export const getAcceptDocumentsQuery = createSelector(
  getAcceptanceState,
  state => state[ACCEPT_DOCUMENTS_QUERY],
);

export const getAcceptDocumentsQueryStatus = createSelector(
  getAcceptDocumentsQuery,
  query => getStatus(query),
);

export const getPvSystemKeysQuery = createSelector(
  getAcceptanceState,
  state => state[SET_PV_SYSTEM_KEYS_QUERY],
);

export const getPvSystemKeysQueryStatus = createSelector(
  getPvSystemKeysQuery,
  query => getStatus(query),
);
