import { T } from '@sonnen/shared-i18n/customer';
import * as IBAN from 'iban';
import * as Yup from 'yup';

export const initialValues = { firstName: '', lastName: '', iban: '', bic: '', sepa: false, vat: '', taxStatus: '' };

export const acceptanceSchema = (vatRequired: boolean) => Yup.object({
  firstName: Yup.string()
    .required(T.customerGuidance.acceptance.bankData.errors.required),
  lastName: Yup.string()
    .required(T.customerGuidance.acceptance.bankData.errors.required),
  iban: Yup.string()
    .required(T.customerGuidance.acceptance.bankData.errors.required)
    .matches(/^[a-zA-Z0-9 ]*$/, T.customerGuidance.acceptance.bankData.errors.ibanFormat)
    .test(
      'iban-sum', T.customerGuidance.acceptance.bankData.errors.ibanFormat,
      iban => IBAN.isValid(iban),
    ),
  bic: Yup.string()
    .required(T.customerGuidance.acceptance.bankData.errors.required),
  sepa: Yup.bool()
    .test(
      'consent',
      T.customerGuidance.acceptance.bankData.errors.sepa,
      value => value === true,
    ),
  // @TODO: TEMPORARY DISABLED
  // vat: Yup.string()
  //   .matches(/[0-9\/]/g, {
  //     message: T.customerGuidance.acceptance.bankData.errors.vatFormat,
  //   })
  //   .when('taxStatus', {
  //     is: () => vatRequired,
  //     then: Yup.string()
  //       .required(T.customerGuidance.acceptance.bankData.errors.required),
  //   }),
  // taxStatus: Yup.string(),
});
