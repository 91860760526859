import {
  BNetzARegistrationCompletedData,
  TaxOfficeRegistrationCompletedData,
} from '+app/+guide/store/types';
import { useJsonApi } from '+app/shared/network';
import { CONFIG } from '+config';

const BASE_URL = `${CONFIG.API.SALES}/leads`;

const setTaxOfficeRegistration = (leadId: string, checked: boolean) =>
  useJsonApi()
    .post<any, TaxOfficeRegistrationCompletedData>(`${BASE_URL}/${leadId}/status`)
    .ofType('status')
    .withAttributes({
      name: 'taxOfficeRegistrationCompleted',
      is_set: !checked,
    })
    .send();

const setBNetzARegistration = (leadId: string, checked: boolean) =>
  useJsonApi()
    .post<any, BNetzARegistrationCompletedData>(`${BASE_URL}/${leadId}/status`)
    .ofType('status')
    .withAttributes({
      name: 'bNetzARegistrationCompleted',
      is_set: !checked,
    })
    .send();

export const GuideSetupRepository = {
  setTaxOfficeRegistration,
  setBNetzARegistration,
};
