import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { StateAlert } from '+shared/components';
import { useLocaleDateFormat } from '+shared/hooks/useLocaleDateFormat';
import { InfographicCardConnectionStatusType } from '../../containers/Infographic/Infographic.types';

type Props = {
  status: InfographicCardConnectionStatusType,
  timestamp: string | undefined,
};

export const InfographicHeaterCardStatus: React.FC<Props> = ({
  status,
  timestamp,
}) => {
  const measurementsTime = useLocaleDateFormat(timestamp);

  switch (status) {
    case InfographicCardConnectionStatusType.DATA_MISSING:
      return (
        <StateAlert
          small={true}
          icon={<Icon.Warning />}
          variant={'warning'}
        >
          {I18n.t(T.dashboard.infographic.statuses.cannotFetchData)}
        </StateAlert>
      );
    case InfographicCardConnectionStatusType.OFFLINE:
      return (
        <StateAlert
          variant={'warning'}
          small={true}
          icon={<Icon.Offline />}
        >
          {I18n.t(T.dashboard.infographic.statuses.offline,
            { date: measurementsTime },
          )}
        </StateAlert>
      );
    default:
      return (
        <StateAlert
            icon={<Icon.Checkmark />}
            small={true}
          >
            {I18n.t(T.dashboard.infographic.statuses.everythingOk)}
          </StateAlert>
      );
  }
};
