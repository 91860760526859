import { RouteName } from '+router/router.types';
import { T } from '@sonnen/shared-i18n/customer';
import { CountryCode } from '@sonnen/shared-web';

import { getPaths, isOnRoute } from '+app/router/router.helper';
import { CONFIG } from '+config';
import { LegalDocumentsHelper } from '+shared/helpers/legalDocuments.helper';
import { LegalDocuments } from '+shared/store/legal/types/legalDocuments.interface';
import { isDefined } from '+utils/type.util';

export interface FooterNavElement {
  path: string;
  label: string;
  isExternal: boolean;
  isAccessible: boolean;
}

export const getFooterNav = (
  isLoggedIn: boolean,
  isVerifyingIdentityInGuide: boolean,
  lang: string | undefined,
  country: CountryCode | undefined,
  legalDocuments: LegalDocuments | undefined,
  path: string,
): FooterNavElement[] => {
  const privacyPolicy = LegalDocumentsHelper.getPrivacyPolicy(legalDocuments, lang, country);
  const termsOfUse = LegalDocumentsHelper.getTermsAndConditions(legalDocuments, lang, country);

  const isOnGuideRoute = isOnRoute(RouteName.GUIDE, path);
  const isDocumentAccessible = isLoggedIn || (isOnGuideRoute && !isVerifyingIdentityInGuide);

  const routes = [
    {
      label: T.general.documents.termsOfUse.label,
      path: isOnGuideRoute ? T.general.documents.termsOfUse.link : termsOfUse?.url || '',
      isExternal: true,
      isAccessible: isOnGuideRoute ? isDocumentAccessible : isDocumentAccessible && !!termsOfUse?.url,
    },
    {
      label: T.general.documents.imprint.label,
      path: T.general.documents.imprint.link,
      isExternal: true,
      isAccessible: true,
    },
    {
      label: T.general.documents.privacyPolicy.label,
      path: isOnGuideRoute ? T.general.documents.privacyPolicy.link : privacyPolicy?.url || '',
      isExternal: true,
      isAccessible: isOnGuideRoute ? isDocumentAccessible : isDocumentAccessible && !!privacyPolicy?.url,
    },
    {
      label: 'Developer Options',
      path: getPaths().DEVELOPER,
      isExternal: false,
      isAccessible: CONFIG.IS_DEV,
    },
  ];

  return routes.filter(isDefined).filter(route => route.isAccessible);
};
