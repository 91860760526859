import {
  accountNavConfig,
  accountNavMobileConfig,
  guideNavConfig,
  guideNavMobileConfig,
  mainNavMobileConfig,
  mainNavWebConfig,
} from '+app/router/configs';
import { getNavRoutes } from '+app/router/router.helper';
import { FlatRoutes, Routes } from '+app/router/router.types';

export type RoutesByType = Record<string, Record<'desktop' | 'mobile', Routes>>;

export const getRoutesByType = (availableFlatRoutes: FlatRoutes): RoutesByType => {
  return {
    dashboard: {
      desktop: getNavRoutes(mainNavWebConfig, availableFlatRoutes),
      mobile: getNavRoutes(mainNavMobileConfig, availableFlatRoutes),
    },
    account: {
      desktop: getNavRoutes(accountNavConfig, availableFlatRoutes),
      mobile: getNavRoutes(accountNavMobileConfig, availableFlatRoutes),
    },
    guide: {
      desktop: getNavRoutes(guideNavConfig, availableFlatRoutes),
      mobile: getNavRoutes(guideNavMobileConfig, availableFlatRoutes),
    },
  };
};
