import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';
import { EventWithDataHandler } from 'redux-form';

import { AccountFormAlert } from '+account/components';
import { Page } from '+app/shared/containers/Page';
import { Query, QueryState } from '+app/shared/store/query/query.state';
import { isQueryError, isQueryPending, isQuerySuccess } from '+app/utils/query.util';
import { Portal } from '+shared/components';
import { PortalExit } from '+shared/helpers';
import { isResponseError } from '+shared/network';
import { DefaultParagraph } from '@sonnen/shared-web';
import { AccountPasswordChangeActions } from './AccountPasswordChange.component';
import { AccountPasswordChangeFormValues } from './AccountPasswordChange.types';
import { AccountPasswordChangeForm } from './AccountPasswordChangeForm/AccountPasswordChangeForm.component';

interface AccountPasswordChangeWebProps {
  actions: AccountPasswordChangeActions;
  formValues: AccountPasswordChangeFormValues;
  query: Query;
  queryStatus: QueryState;
  isDirty: boolean;
  onBlur?: EventWithDataHandler<React.FocusEvent<any>>;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleReset: () => void;
}

export const AccountPasswordChangeWeb: React.FC<AccountPasswordChangeWebProps> = ({
  actions,
  formValues,
  isDirty,
  handleSubmit,
  handleReset,
  onBlur,
  query,
  queryStatus,
}) => {
  const [isAlertVisible, setAlertVisibility] = React.useState(false);
  let hideAlertTimeout: number | undefined;
  let resetQueryTimeout: number | undefined;

  const handleAlertVisibility = () => {
    if (isQueryPending(queryStatus) || isQueryError(queryStatus || !isAlertVisible)) {
      return setAlertVisibility(true);
    } else if (isQuerySuccess(queryStatus)) {
      hideAlertTimeout = window.setTimeout(() => {
        setAlertVisibility(false);
      }, 1500);
  
      resetQueryTimeout = window.setTimeout(() => {
        actions.resetChangePasswordQuery();
      }, 2000);
    } else {
      setAlertVisibility(false);
    }
  };

  React.useEffect(() => {
    actions.resetChangePasswordQuery();

    return () => {
      clearTimeout(hideAlertTimeout);
      clearTimeout(resetQueryTimeout);
    };
  }, []);

  React.useEffect(() => {
    if (isDirty) {
      return setAlertVisibility(true);
    }

    handleAlertVisibility();
  }, [isDirty]);

  React.useEffect(() => {
    if (isQueryPending(queryStatus) || isQuerySuccess(queryStatus) || isQueryError(queryStatus)) {
      setAlertVisibility(true);
    }

    if (isQuerySuccess(queryStatus)) {
      handleAlertVisibility();
      handleReset();
    }
  }, [queryStatus]);

  return (
    <Page options={{
      isHeadlineSmallGap: true,
    }}>
      <div className={'c-account-password-change'}>
        <div className={'o-grid'}>
          <div className={'o-grid__column o-grid__column--sm-6'}>
            <DefaultParagraph>
              <Translate value={T.myAccountPages.password.description} />
            </DefaultParagraph>
          </div>
        </div>
        <AccountPasswordChangeForm
          formValues={formValues}
          handleSubmit={handleSubmit}
          onBlur={onBlur}
        >
          <Portal target={PortalExit.LAYOUT_CONTENT_TOP}>
            <AccountFormAlert
              isSubmitting={isQueryPending(queryStatus)}
              isSuccess={isQuerySuccess(queryStatus)}
              isFailure={isQueryError(queryStatus)}
              isVisible={isAlertVisible}
              isDisabled={isQuerySuccess(queryStatus)}
              error={isResponseError(query.error) ? query.error : undefined}
              handleReset={handleReset}
              handleSubmit={handleSubmit}
            />
          </Portal>
        </AccountPasswordChangeForm>
      </div>
    </Page>
  );
};
