import * as React from 'react';
import { CommunityData } from '../../../features/mapLegacy';
import { CommunityMap, CommunityMapError, CommunityMapLoader } from '../components';
import { CommunityMapSectionProps } from '../containers/CommunityMapSection.component';

import './CommunityMapSection.mobile.component.scss';

type Props = Pick<CommunityMapSectionProps, 'userName' | 'userGeolocation' | 'recentMembersCount'>;

export const CommunityMapSectionMobile: React.FC<Props> = ({
  userName,
  userGeolocation,
  recentMembersCount,
}) => (
  <>
    <div className={'m-c-community-map-section__wrapper'}>
      <CommunityData>
        {({ mapData, loading, error, loadCommunityData }) => {
          if (loading) {
            return (
              <CommunityMapLoader
                className={'m-c-community-map-section__container m-c-community-map-section__container--error'}
              />
            );
          }

          if (error && !!loadCommunityData) {
            return (
              <CommunityMapError
                className={'m-c-community-map-section__container m-c-community-map-section__container--error'}
                onClick={loadCommunityData}
              />
            );
          }

          return (
            <div className={'m-c-community-map-section__container'}>
              <CommunityMap
                userName={userName}
                userGeolocation={userGeolocation as [number | null]}
                mapData={mapData}
                recentMembersCount={recentMembersCount}
              />
            </div>
          );
        }}
      </CommunityData>
    </div>
  </>
);
