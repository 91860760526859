import { DocumentStatus } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { filter, flow, negate, orderBy } from 'lodash/fp';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  getPrimaryFlatConfigurationDocumentKey,
  getPrimaryFlatConfigurationDocumentStatus,
  getPrimaryFlatOfferDocumentKey,
  getPrimaryFlatOfferDocumentStatus,
  getPrimaryImpactAnalysisDocumentKey,
  getPrimaryImpactAnalysisDocumentStatus,
} from '+app/+guide/+purchase/store/+offer.selectors';
import { getPaths } from '+app/router/router.helper';
import { identityVerifierInterceptor } from '+app/shared/network';
import { Jwt } from '+app/utils/jwt.util';
import { isAuthenticated } from '+auth/store/auth.selectors';
import { ROUTER_ACTIONS } from '+router/store/router.actions';
import { authorizationTokenProvider } from '+shared/network/authorizationTokenProvider';
import { QueryActions } from '+shared/store/query/query.actions';
import {
  combineSagas,
  dataGuard,
  handleDataPolling,
  mapPathToQueryParams,
  NO_MATCHING_PATH,
  processQuery,
} from '+utils/saga';
import { GUIDE_ACTIONS, GuideActions } from './+guide.actions';
import { getOfferFlatProduct, GUIDE_ROUTES } from './+guide.helper';
import { GuideRepository } from './+guide.repository';
import {
  getGuideAuthToken,
  getGuideOfferCollection,
} from './+guide.selectors';
import {
  GET_CESSION_DOCUMENT,
  GET_CESSION_DOCUMENT_FILE_QUERY,
  GET_CESSION_STATUS,
  GET_FLAT_OFFER_FILE_QUERY,
  GET_FLAT_OFFER_QUERY,
  GET_HARDWARE_OFFER_FILE_QUERY,
  GET_HARDWARE_OFFER_QUERY,
  GET_IMPACT_ANALYSIS_LIST_QUERY,
  GET_LEAD_COLLECTION_QUERY,
  GET_LEAD_QUERY,
  GET_OFFERS_QUERY,
  GET_ORDER_CONFIRMATION_FILE_QUERY,
  GET_PARTNER_QUERY,
  GUIDE_SYNC_QUERY, PATCH_RECALCULATION_OFFER_QUERY,
  POST_OFFER_DOCUMENT_QUERY,
} from './+guide.state';
import { getGuideLeadId } from './+lead.selectors';
import {
  CessionDocumentStatusAttributes,
  FlatDocumentType,
  ImpactAnalysis,
  Offer,
  OfferProductStatus,
  OfferStatus,
} from './types';

import { sagas as acceptanceSagas } from '+guide/+acceptance/store/+acceptance.sagas';
import { sagas as overviewSagas } from '+guide/+overview/store/+overview.sagas';
import { sagas as setupSagas } from '+guide/+setup/store/+setup.sagas';

function* syncData(): SagaIterator {
  const params = yield mapPathToQueryParams(...GUIDE_ROUTES);
  const isLoggedIn = yield select(isAuthenticated);
  const isGuideRoute = !(params === NO_MATCHING_PATH);
  let leadId = yield select(getGuideLeadId);

  if (!isLoggedIn && isGuideRoute) {
    if (!params.token) {
      return yield put(push(getPaths().ROOT));
    }

    let token: string | undefined;
    token = yield select(getGuideAuthToken);

    if (token && leadId) {
      return;
    }

    yield put(GuideActions.setAuthToken(params.token!));

    token = yield select(getGuideAuthToken);
    leadId = Jwt.decode<{ leadId: string }>(token!).leadId;
  }
  if (leadId && !isLoggedIn) {
    yield put(QueryActions.pending(GUIDE_SYNC_QUERY));
    try {
      yield call(getLeadDetails, leadId);
      yield call(getPartnerDetails, leadId);
      yield call(getOffers, leadId);
      yield call(getImpactAnalysisList, leadId);

      const offers: Offer[] = yield select(getGuideOfferCollection);
      yield all(offers.map(({ id }) => call(getHardwareOffers, leadId, id)));
    } catch (e) {
      yield put(QueryActions.failure(GUIDE_SYNC_QUERY, e));
      return;
    }
    yield put(QueryActions.success(GUIDE_SYNC_QUERY));
  }
}

function* getGuideData(): SagaIterator {
  const isLoggedIn = yield select(isAuthenticated);
  const leadId = yield select(getGuideLeadId);

  if (leadId && isLoggedIn) {
    yield put(QueryActions.pending(GUIDE_SYNC_QUERY));
    try {
      yield call(getPartnerDetails, leadId);
      yield call(getLeadDetails, leadId);
      yield call(getOffers, leadId);
      const offers: Offer[] = yield select(getGuideOfferCollection);
      yield all(offers.map(({ id }) => call(getHardwareOffers, leadId, id)));
      yield call(getImpactAnalysisList, leadId);
    } catch (e) {
      yield put(QueryActions.failure(GUIDE_SYNC_QUERY, e));
      return;
    }
    yield put(QueryActions.success(GUIDE_SYNC_QUERY));
  }
}

/**
 * DATA FETCHING SAGAS
 */

// @TODO: Move to shared
export function* getLeadCollection(): SagaIterator {
  yield processQuery(
    GET_LEAD_COLLECTION_QUERY,
    GuideRepository.getLeadCollection,
    {
      onSuccess: res => dataGuard(GuideActions.setLeadId)(res!.elements),
      // onFailure: () => { throw new Error('Could not fetch lead collection.'); },
    },
  )({});
}

function* getLeadDetails(leadId: string): SagaIterator {
  yield processQuery(
    GET_LEAD_QUERY,
    GuideRepository.getLead,
    {
      onSuccess: res => dataGuard(GuideActions.setLead)(res!.element),
      // @TODO: Come up with something better, maybe create extendable errors with certain name and description
      onFailure: () => { throw new Error('Could not fetch personal details.'); },
    },
  )(leadId);
}

function* getPartnerDetails(leadId: string): SagaIterator {
  yield processQuery(
    GET_PARTNER_QUERY,
    GuideRepository.getLeadPartner,
    {
      onSuccess: res => dataGuard(GuideActions.setPartner)(res!.element),
    },
  )(leadId);
}

function* getOffers(leadId: string): SagaIterator {
  yield processQuery(
    GET_OFFERS_QUERY,
    async () => {
      const offers = flow(
        // we look for all the products within offer, which are not hardware
        // so both flat X and direct and check for them not to be in status draft
        filter(negate(offer => getOfferFlatProduct(offer)?.status === OfferProductStatus.DRAFT
          || [OfferStatus.DRAFT, OfferStatus.BLOCKED].includes(offer.status)
          || !getOfferFlatProduct(offer),
        )),
        orderBy('createdAt', 'desc'),
      )((await GuideRepository.getOfferCollection(leadId)).elements) as Offer[];

      return Promise.all(offers.map(async offer => {
        const configuration = (await GuideRepository.getConfiguration(leadId, offer.configuration as any)).element;

        return {
          ...offer,
          configuration,
        };
      }));
    },
    {
      onSuccess: res => dataGuard(GuideActions.setOffers)(res),
      onFailure: () => { throw new Error('Could not fetch offer details.'); },
    },
  )({});
}

function* getImpactAnalysisList(leadId: string): SagaIterator {
  yield processQuery(
    GET_IMPACT_ANALYSIS_LIST_QUERY,
    async () => {
      const impactAnalysisList = flow(
        filter((impactAnalysis: ImpactAnalysis) => impactAnalysis.status === 'sent'),
        orderBy('createdAt', 'desc'),
      )((await GuideRepository.getImpactAnalysisCollection(leadId)).elements) as ImpactAnalysis[];

      return Promise.all(impactAnalysisList.map(async impactAnalysis => {
        const configuration = (await GuideRepository.getConfiguration(
          leadId,
          impactAnalysis.configurations as any,
        )).element;

        return {
          ...impactAnalysis,
          configurations: configuration,
        };
      }));
    },
    {
      onSuccess: res => dataGuard(GuideActions.setImpactAnalysisList)(res),
      onFailure: () => { throw new Error('Could not fetch impact analysis list.'); },
    },
  )({});
}

function* getHardwareOffers(leadId: string, offerId: string): SagaIterator {
  yield processQuery(
    GET_HARDWARE_OFFER_QUERY,
    () => GuideRepository.getHardwareOfferCollection(leadId, offerId),
    {
      onSuccess: res => put(GuideActions.setHardwareOffers(offerId, res!.elements)),
      onFailure: () => { throw new Error('Could not fetch hardware offer details.'); },
    },
  )({});
}

function* getFlatOffers(leadId: string, offerId: string): SagaIterator {
  yield processQuery(
    GET_FLAT_OFFER_QUERY,
    () => GuideRepository.getOffer(leadId, offerId),
    {
      onSuccess: res => put(GuideActions.setFlatOffers(offerId, res!.element.flatOffers!)),
      onFailure: () => { throw new Error('Could not fetch flat offer details.'); },
    },
  )({});
}

function* getFlatConfigurations(leadId: string, offerId: string): SagaIterator {
  yield processQuery(
    GET_FLAT_OFFER_QUERY,
    () => GuideRepository.getOffer(leadId, offerId),
    {
      onSuccess: res => put(GuideActions.setFlatConfigurations(offerId, res!.element.flatConfigurations!)),
      onFailure: () => { throw new Error('Could not fetch flat configuration details.'); },
    },
  )({});
}

function* getHardwareOfferFile({
  offerId,
  documentId,
}: ReturnType<typeof GuideActions.getHardwareOfferFile>) {
  const leadId: string = yield select(getGuideLeadId);

  yield processQuery(
    GET_HARDWARE_OFFER_FILE_QUERY,
    () => GuideRepository
      .getHardwareOfferFile(leadId, offerId, documentId),
    {
      onSuccess: res => call(setDocumentUrl, res!),
      onFailure: () => { throw new Error('Could not fetch hardware offer document.'); },
    },
  )({});
}

function* handleFlatOfferPolling({
  offerId,
  flatOfferType,
}: ReturnType<typeof GuideActions.startDocumentPolling>) {
  const leadId: string = yield select(getGuideLeadId);
  const pollingInterval = 3000;

  if (flatOfferType === FlatDocumentType.IMPACT_ANALYSIS) {
    yield call(getImpactAnalysisList, leadId);

    while (true) {
      const documentStatus: DocumentStatus = (yield select(getPrimaryImpactAnalysisDocumentStatus))({
        impactAnalysisId: offerId,
      });

      if (documentStatus === DocumentStatus.CREATED) {
        const documentId = (yield select(getPrimaryImpactAnalysisDocumentKey))({
          impactAnalysisId: offerId,
        });

        yield processQuery(
          GET_FLAT_OFFER_FILE_QUERY,
          () => GuideRepository
            .getImpactAnalysisFile(leadId, offerId, documentId),
          {
            onSuccess: res => call(setDocumentUrl, res!),
            onFailure: () => { throw new Error('Could not fetch impact analysis document.'); },
          },
        )({});
        break;
      }
      if (documentStatus === DocumentStatus.FAILED) { break; }

      const { stopped } = yield race({
        wait: delay(pollingInterval),
        stopped: take(GUIDE_ACTIONS.STOP_DOCUMENT_POLLING),
      });

      if (!stopped) {
        yield call(getImpactAnalysisList, leadId);
      } else {
        break;
      }
    }

  } else if (flatOfferType === FlatDocumentType.FLAT_ORDER_CONFIRMATION) {
    yield put({ type: GUIDE_ACTIONS.SET_ORDER_CONFIRMATION_STATUS, status: DocumentStatus.PENDING });
    try {
      yield processQuery(
        GET_ORDER_CONFIRMATION_FILE_QUERY,
        () => GuideRepository
          .getOrderConfirmationDocument(leadId),
        {
          onSuccess: res => call(setDocumentUrl, res!),
          onFailure: () => {
            throw new Error('Could not fetch order confirmation document.');
          },
        },
      )({});
    } catch (e) {
      yield put({ type: GUIDE_ACTIONS.SET_ORDER_CONFIRMATION_STATUS, status: DocumentStatus.FAILED });
      yield put({ type: GUIDE_ACTIONS.STOP_DOCUMENT_POLLING });
      return;
    }
    yield put({ type: GUIDE_ACTIONS.SET_ORDER_CONFIRMATION_STATUS, status: DocumentStatus.CREATED });
    yield take(GUIDE_ACTIONS.STOP_DOCUMENT_POLLING);

  } else if (flatOfferType === FlatDocumentType.CESSION) {
    yield put({ type: GUIDE_ACTIONS.SET_CESSION_DOCUMENT_STATUS, status: DocumentStatus.PENDING });
    yield processQuery(
      GET_CESSION_DOCUMENT_FILE_QUERY,
      () => GuideRepository
        .getSignedCessionDocument(leadId),
      {
        onSuccess: res => call(setDocumentUrl, res!),
        onFailure: () => all([
          put({ type: GUIDE_ACTIONS.SET_CESSION_DOCUMENT_STATUS, status: DocumentStatus.FAILED }),
          put({ type: GUIDE_ACTIONS.STOP_DOCUMENT_POLLING }),
        ]),
      },
    )({});
    yield put({ type: GUIDE_ACTIONS.SET_CESSION_DOCUMENT_STATUS, status: DocumentStatus.CREATED });
    yield take(GUIDE_ACTIONS.STOP_DOCUMENT_POLLING);
  } else {
    yield call(getFlatOffers, leadId, offerId);
    yield call(getFlatConfigurations, leadId, offerId);

    while (true) {
      const getPrimaryFlatDocumentStatus = flatOfferType === FlatDocumentType.FLAT_OFFER
        ? getPrimaryFlatOfferDocumentStatus
        : getPrimaryFlatConfigurationDocumentStatus;

      const documentStatus: DocumentStatus = (yield select(getPrimaryFlatDocumentStatus))({ offerId });

      if (documentStatus === DocumentStatus.CREATED) {
        const flatDocumentId = flatOfferType === FlatDocumentType.FLAT_OFFER ?
          (yield select(getPrimaryFlatOfferDocumentKey))({ offerId })
          : (yield select(getPrimaryFlatConfigurationDocumentKey))({ offerId });

        yield processQuery(
          GET_FLAT_OFFER_FILE_QUERY,
          () => GuideRepository
            .getFlatOfferFile(flatOfferType)(leadId, offerId, flatDocumentId),
          {
            onSuccess: res => call(setDocumentUrl, res!),
            onFailure: () => { throw new Error('Could not fetch flat offer document.'); },
          },
        )({});
        break;
      }
      if (documentStatus === DocumentStatus.FAILED) { break; }

      const { stopped } = yield race({
        wait: delay(pollingInterval),
        stopped: take(GUIDE_ACTIONS.STOP_DOCUMENT_POLLING),
      });

      if (!stopped) {
        yield call(getFlatOffers, leadId, offerId);
        yield call(getFlatConfigurations, leadId, offerId);
      } else {
        break;
      }
    }
  }
}

function* setDocumentUrl(url: string): SagaIterator {
  yield put(GuideActions.setDocumentUrl(url));
}

// @TODO: MAKE DOCUMENTS HANDLING MORE GENERIC TO INCORPORATE FUTURE DOCUMENTS
// - regenerating
// - polling
// - selectors
function* regenerateDocument({
  leadId,
  resourceId,
  resourceType,
  documentType,
}: any): SagaIterator {
  if (resourceType === 'offer') {
    yield processQuery(
      POST_OFFER_DOCUMENT_QUERY,
      () => GuideRepository
        .postOfferDocument(leadId, resourceId, documentType),
      {
        onSuccess: () => put(GuideActions.startDocumentPolling(resourceId, documentType)),
        onFailure: () => { throw new Error('Could not regenerate offer document'); },
      },
    )({});
  }
}

function* recalculateOffer({ offerId, configurationId }: any): SagaIterator {
    yield processQuery(
      PATCH_RECALCULATION_OFFER_QUERY,
      () => GuideRepository.patchForRecalculateExpiredOffer(offerId, configurationId),
      {
        onSuccess: () => {
          return call(getOffers, offerId);
        },
        onFailure: () => { throw new Error('Could not fetch flat configuration details.'); },
      },
    )({});
}

function* getCessionDocument(): SagaIterator {
  const leadId = yield select(getGuideLeadId);

  yield processQuery(
    GET_CESSION_DOCUMENT,
    GuideRepository.getCessionDoc,
    {
      onSuccess: res => dataGuard(GuideActions.setCessionDocument)(get(res, 'meta.url', '')),
      onFailure: () => dataGuard(GuideActions.setCessionFailureStatus)(true),
    },
  )(leadId);
}

function* getCessionStatus(): SagaIterator {
  const leadId = yield select(getGuideLeadId);

  yield processQuery(
    GET_CESSION_STATUS,
    GuideRepository.getCessionDocStatus,
    {
      onSuccess: res => dataGuard(GuideActions.setCessionStatus)
        (get(res, 'meta', {}) as CessionDocumentStatusAttributes),
      onFailure: () => dataGuard(GuideActions.setCessionFailureStatus)(true),
    },
  )(leadId);
}

const startCessionStatusPolling = () =>
  handleDataPolling({
    fetchAction: GUIDE_ACTIONS.GET_CESSION_STATUS,
    endAction: GUIDE_ACTIONS.STOP_CESSION_STATUS_POLLING,
    interval: 3000,
  });

const startCessionDocumentPolling = () =>
  handleDataPolling({
    fetchAction: GUIDE_ACTIONS.GET_CESSION_DOCUMENT,
    endAction: GUIDE_ACTIONS.STOP_CESSION_DOCUMENT_POLLING,
    interval: 3000,
  });

function* logout(): SagaIterator {
  const token = authorizationTokenProvider.getToken();

  if (!token) {
    return;
  }

  yield put(push(getPaths().GUIDE));
  yield call(GuideRepository.revokeToken);
  const { leadId } = Jwt.decode<{ leadId: string }>(token);
  yield put(GuideActions.revokeToken(leadId));
  yield call(syncData);
}

export const sagas = combineSagas(
  takeEvery(ROUTER_ACTIONS.IS_READY, syncData),
  takeLatest(GUIDE_ACTIONS.START_DOCUMENT_POLLING, handleFlatOfferPolling),
  takeLatest(GUIDE_ACTIONS.GET_HARDWARE_OFFER_FILE, getHardwareOfferFile),
  takeLatest(GUIDE_ACTIONS.VERIFY_IDENTITY, identityVerifierInterceptor.saga),
  takeLatest(GUIDE_ACTIONS.LOGOUT, logout),
  takeLatest(GUIDE_ACTIONS.REGENERATE_DOCUMENT, regenerateDocument),
  takeLatest(GUIDE_ACTIONS.GET_GUIDE_DATA, getGuideData),
  takeLatest(GUIDE_ACTIONS.GET_CESSION_DOCUMENT, getCessionDocument),
  takeLatest(GUIDE_ACTIONS.GET_CESSION_STATUS, getCessionStatus),
  takeLatest(GUIDE_ACTIONS.START_CESSION_DOCUMENT_POLLING, startCessionDocumentPolling),
  takeLatest(GUIDE_ACTIONS.START_CESSION_STATUS_POLLING, startCessionStatusPolling),
  takeLatest(GUIDE_ACTIONS.RECALCULATE_OFFER, recalculateOffer),
  acceptanceSagas,
  overviewSagas,
  setupSagas,
);
