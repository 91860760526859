import {
  colorSuccess,
  colorWarning,
  colorConsumed,
  colorConsumedArea,
} from './AnnualConsumptionChart.scss';

export const STYLES = {
  colors: {
    grid: '#d8d8d8',
    consumedFlatQuotaLine: colorConsumed,
    consumedFlatQuotaArea: colorConsumedArea,
    expectedConsumptionLine: colorSuccess,
    expectedConsumptionExceededLine: colorWarning,
  },
  gridThickness: 1,
  plotLineThickness: 2,
};

export default {
  STYLES,
};
