import { IconType } from '@sonnen/shared-web/src/components/Icon/models';

export enum ContractStatus {
  TODO = 'todo',
  ACTIVE = 'active',
  DONE = 'done',
}

export interface ContractCardProps {
  icon: IconType;
  title: string;
  subtitle: string;
  infoValue?: string | null;
  infoAttribute?: string;
  status: ContractStatus;
}
