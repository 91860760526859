import { T } from '@sonnen/shared-i18n/customer';
import { 
  AnalysisPieChart,
  autonomyStyle,
  selfConsumptionStyle,
  SharedChartColors,
  StatisticsSeriesKey,
} from '@sonnen/shared-web';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';

import { useAnalysis } from '../../containers/AnalysisProvider/AnalysisProvider.context';
import {
  getPieChartLegendItems,
  getPieChartValueProperties,
} from './AnalysisHistory.helper';

import './AnalysisHistory.mobile.component.scss';

export const AnalysisHistoryMobile: React.FC = () => {
  const [pieChartValues, setPieChartValues] = useState({
    fontSize: 23,
    consumptionValue: '0',
    productionValue: '0',
  });
  const {
    commonData: {
      hasBattery,
      pieChartSeries,
    },
    query: {
      statisticsQueryStatus,
    },
  } = useAnalysis();

  const autonomyLegendItems = getPieChartLegendItems(pieChartSeries.autonomy, autonomyStyle);
  const selfConsumptionLegendItems = getPieChartLegendItems(pieChartSeries.selfConsumption, selfConsumptionStyle);

  useEffect(() => {
    setPieChartValueProperties();
  }, [pieChartSeries]);

  const setPieChartValueProperties = () => {
    const { autonomy, selfConsumption } = pieChartSeries;
    const pieChartValues = getPieChartValueProperties(selfConsumption, autonomy);

    setPieChartValues({
      fontSize: pieChartValues.fontSize,
      consumptionValue: pieChartValues.consumption,
      productionValue: pieChartValues.production,
    });
  };

  return hasBattery ? (
    <div className={'m-c-analysis-history'}>
      <div className={'m-c-analysis-history__item'}>
        <AnalysisPieChart
          title={I18n.t(T.history.chart.labels.consumption)}
          unit={'kWh'}
          displayedSeriesKey={StatisticsSeriesKey.CONSUMED_ENERGY}
          series={pieChartSeries.autonomy}
          style={autonomyStyle}
          isPending={statisticsQueryStatus.pending}
          value={pieChartValues.consumptionValue}
          valueColor={SharedChartColors.CONSUMPTION}
          valueFontSize={pieChartValues.fontSize}
          legendItems={autonomyLegendItems}
        />
      </div>
      <div className={'m-c-analysis-history__item'}>
        <AnalysisPieChart
          title={I18n.t(T.history.chart.labels.production)}
          unit={'kWh'}
          displayedSeriesKey={StatisticsSeriesKey.PRODUCED_ENERGY}
          series={pieChartSeries.selfConsumption}
          style={selfConsumptionStyle}
          isPending={statisticsQueryStatus.pending}
          value={pieChartValues.productionValue}
          valueColor={SharedChartColors.GOLD}
          valueFontSize={pieChartValues.fontSize}
          legendItems={selfConsumptionLegendItems}
        />
      </div>
    </div>
  ) : null;
};
