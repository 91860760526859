import { CountryCode } from '@sonnen/shared-web';
import * as countries from 'i18n-iso-countries';

import { provideLocale } from '+legacy/helpers/i18n.helper';

countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
countries.registerLocale(require('i18n-iso-countries/langs/de.json'));
countries.registerLocale(require('i18n-iso-countries/langs/it.json'));

export const EU_COUNTRY_CODES = [
  CountryCode.AT,
  CountryCode.BE,
  CountryCode.BG,
  CountryCode.CY,
  CountryCode.CZ,
  CountryCode.DE,
  CountryCode.DK,
  CountryCode.EE,
  CountryCode.ES,
  CountryCode.FI,
  CountryCode.FR,
  CountryCode.GB,
  CountryCode.GR,
  CountryCode.HR,
  CountryCode.HU,
  CountryCode.IE,
  CountryCode.IT,
  CountryCode.LT,
  CountryCode.LU,
  CountryCode.LV,
  CountryCode.MT,
  CountryCode.NL,
  CountryCode.PL,
  CountryCode.PT,
  CountryCode.RO,
  CountryCode.SE,
  CountryCode.SI,
  CountryCode.SK,
];

const getCountryName = (countryCode: string) =>
  countries.getName(countryCode, provideLocale().split('-')[0]);

const isEuropeanCountry = (countryCode: CountryCode) => EU_COUNTRY_CODES.includes(countryCode);

export const CountryHelper = {
  getCountryName,
  isEuropeanCountry,
};
