import * as React from 'react';

import { formatSiteAddress } from '+legacy/helpers/strings';
import { Site } from '+shared/store/site/types/site.interface';
import { NESTED_NAV_ANIMATION_DURATION, useNestedNav } from '../../NestedNav';
import { NestedNavList } from '../../NestedNav/NestedNavList';
import { NestedNavListItem } from '../../NestedNav/NestedNavListItem';
import { NestedNavSlideContent } from '../../NestedNav/NestedNavSlideContent';

export interface MobileSideNavNestedSitesListProps {
  activeSite: Site | undefined;
  allSites: Site[] | undefined;
  setActiveSite: (siteId: string) => void;
}

const renderSitesList = (
  activeSite: Site | undefined,
  allSites: Site[],
  changeSite: (id: string) => void,
) => (
  <NestedNavList>
    {allSites.map(site => (
      <NestedNavListItem
        key={site.id}
        icon={'House'}
        title={formatSiteAddress(site)}
        sideContentType={'radial'}
        isActive={!!(activeSite && activeSite.id === site.id)}
        theme={'light'}
        onClick={() => changeSite(site.id)}
      />
    ))}
  </NestedNavList>
);

export const MobileSideNavNestedSitesList: React.FC<MobileSideNavNestedSitesListProps> = ({
  activeSite,
  allSites,
  setActiveSite,
}) => {
  const { goBack } = useNestedNav();
  
  const changeSite = (id: string) => {
    setActiveSite(id);
    setTimeout(() => {
      goBack();
    }, NESTED_NAV_ANIMATION_DURATION / 2);
  };

  return (
    <NestedNavSlideContent>
      {allSites && (
        renderSitesList(activeSite, allSites, changeSite)
      )}
    </NestedNavSlideContent>
  );
};
