import { T } from '@sonnen/shared-i18n/customer';

import profitSharing from '+images/guide/profitSharing.svg';
import sonnenFlat from '+images/guide/sonnenFlat.svg';
import usageAllowance from '+images/guide/usageAllowance.svg';

export const sections = [
  {
    headline: T.customerGuidance.productInformations.electricityCosts.headline,
    description: T.customerGuidance.productInformations.electricityCosts.description,
    image: sonnenFlat,
  },
  {
    headline: T.customerGuidance.productInformations.usageAllowance.headline,
    description: T.customerGuidance.productInformations.usageAllowance.description,
    image: profitSharing,
  },
  {
    headline: T.customerGuidance.productInformations.profitSharing.headline,
    description: T.customerGuidance.productInformations.profitSharing.description,
    image: usageAllowance,
  },
];
