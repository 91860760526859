// NOTE: This component should be deleted after unifying all notifications
import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';

import './AnalysisSummerTimeNotfication.component.scss';

export enum AnalysisSummerTimeNotficationType {
  START = 'start',
  END = 'end',
  NONE = 'none',
}

interface AnalysisSummerTimeNotfication {
  type: AnalysisSummerTimeNotficationType;
}

export const AnalysisSummerTimeNotfication: React.FC<AnalysisSummerTimeNotfication> = ({ type }) => (
  <>
    { type !== AnalysisSummerTimeNotficationType.NONE ? (
      <div className={'c-analysis-summer-time-notification'}>
        <div className={'c-analysis-summer-time-notification__icon-wrapper'}>
          <Icon.Info className={'c-analysis-summer-time-notification__icon'} />
        </div>
        <span className={'c-analysis-summer-time-notification__text'}>
          { type === AnalysisSummerTimeNotficationType.START ? (
            <Translate
              value={T.analysis.daylightSavingTime.start}
              dangerousHTML={true}
            /> ) : (
            <Translate
              value={T.analysis.daylightSavingTime.end}
              dangerousHTML={true}
            />
          )}
        </span>
      </div>
    ) : null }
  </>
);
