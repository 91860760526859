import { Data, MergedData } from '@coolio/json-api';

export enum LegalDocumentType {
  PP_TERMS = 'tn_c_partner_portal',
  CP_TERMS = 'tn_c_customer_portal',
  PARTNER_DECLARATION_CUSTOMER_DATA = 'partner_declaration_customer_data',
  PRIVACY_POLICY = 'privacy_policy',
}

export interface LegalDocumentsAttributes {
  accepted: boolean;
  kind: LegalDocumentType;
  version: string;
  language: string;
  country: string;
  url: string;
}

export type LegalDocumentsData = Data<LegalDocumentsAttributes>;
// TODO make a non-array LegalDocumentsData
export type LegalDocuments = MergedData<LegalDocumentsData[]>;
