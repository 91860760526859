import { T } from '@sonnen/shared-i18n/customer';
import { OnInputChangeEvent } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { AccountActions } from '+account/store/account.actions';
import {
  getAccountProfile,
  isSetProfileError,
  isSetProfilePending,
  isSetProfileSuccess,
} from '+account/store/account.selectors';
import { UPDATE_ACCOUNT_PROFILE_QUERY } from '+account/store/account.state';
import {
  ProfileFormAttributes,
  ProfileFormErrors,
  ProfileFormFields,
} from '+account/store/types/profile.interface';
import { PortalExit } from '+app/shared/helpers';
import { StoreState } from '+app/store/store.interface';
import { ImageUploader, Portal } from '+components';
import {
  AccountCommunityDetails,
  AccountFormAlert,
  AccountProfileCard,
} from '+dashboard/+account/components';
import { Form } from '+legacy/components';
import { QueryActions } from '+query/query.actions';
import { getCurrentUserId } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';
import {
  getRequestReadyImage,
  isAccountProfileAvailable,
  isAvatarChanged,
  isFormValid,
  validateField,
} from './AccountCommunityProfileForm.helper';

import './AccountCommunityProfileForm.component.scss';

const mapStateToProps = (store: StoreState) => ({
  userId: getCurrentUserId(store),
  accountProfile: getAccountProfile(store),
  isFormSubmitting: isSetProfilePending(store),
  isFormSuccess: isSetProfileSuccess(store),
  isFormFailure: isSetProfileError(store),
});

const mapDispatchToProps = mapActions({
  updateProfile: AccountActions.updateAccountProfileData,
  resetUpdateProfileQuery: QueryActions.init,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

interface State {
  imageUploaderPreview: string | null;
  formValues: ProfileFormAttributes;
  formErrors: ProfileFormErrors;
  APIValuesSet: boolean;
  isFormAlertVisible: boolean;
  shouldImageUploaderReset: boolean;
}

class AccountCommunityProfileFormComponent extends React.PureComponent<Props, State> {
  hideAlertTimeout?: number;
  switchAlertButtonsDelayTimeout?: number;
  initialState: State;

  constructor(props: Props) {
    super(props);

    this.initialState = {
      imageUploaderPreview: null,
      formValues: {
        nickname: '',
        description: '',
      },
      formErrors: {
        nickname: undefined,
        description: undefined,
      },
      APIValuesSet: false,
      isFormAlertVisible: false,
      shouldImageUploaderReset: false,
    };
    this.state = { ...this.initialState };
  }

  componentDidMount() {
    this.props.actions.resetUpdateProfileQuery(UPDATE_ACCOUNT_PROFILE_QUERY);

    this.setValuesFromAPI();
  }

  componentDidUpdate(prevProps: Props) {
    const isAccountProfileUpdated = (this.props.accountProfile !== prevProps.accountProfile) &&
      (this.props.accountProfile !== this.state.formValues);

    if (isAccountProfileUpdated) {
      this.setValuesFromAPI();
    }

    if (this.props.isFormSuccess !== prevProps.isFormSuccess) {
      this.hideFormAlert();
    }

    if (this.state.shouldImageUploaderReset) {
      this.setState({ shouldImageUploaderReset: false });
    }
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  clearTimeouts = () => {
    clearTimeout(this.hideAlertTimeout);
    clearTimeout(this.switchAlertButtonsDelayTimeout);
  };

  setValuesFromAPI = () => {
    if (!isAccountProfileAvailable(this.props.accountProfile) || this.state.APIValuesSet) {
      return;
    }

    const { nickname, description, image } = this.props.accountProfile;

    this.setState(prevState => ({
      imageUploaderPreview: image as string | null,
      formValues: {
        ...prevState.formValues,
        nickname,
        description,
      },

      APIValuesSet: true,
    }));
  };

  onAvatarChange = (image: string | null) => {
    this.setState((prevState: State) => ({
      imageUploaderPreview: image,
      formValues: {
        ...prevState.formValues,
        image: getRequestReadyImage(image),
      },
      isFormAlertVisible: isAvatarChanged(image, this.props.accountProfile),
      shouldImageUploaderReset: false,
    }));
  };

  onFieldChange = (event: OnInputChangeEvent) => {
    this.setState({
      formValues: {
        ...this.state.formValues,
        [event.target.name]: event.target.value,
      },
      formErrors: {
        ...this.state.formErrors,
        [event.target.name]: validateField(event.target.name, event.target.value),
      },
      isFormAlertVisible: true,
    });
  };

  handleFormReset = () => {
    if (!isAccountProfileAvailable(this.props.accountProfile)) {
      return;
    }

    this.setState({
      ...this.initialState,
      imageUploaderPreview: this.props.accountProfile.image as string | null,
      formValues: {
        nickname: this.props.accountProfile.nickname || I18n.t(T.communityProfile.defaultNickname),
        description: this.props.accountProfile.description,
      },
      shouldImageUploaderReset: true,
    });
  };

  handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    this.props.actions.updateProfile(this.state.formValues);
  };

  hideFormAlert = () => {
    if (!this.props.isFormSuccess) {
      return;
    }

    this.hideAlertTimeout = window.setTimeout(() => {
      this.setState({
        isFormAlertVisible: false,
      });
    }, 1500);

    this.switchAlertButtonsDelayTimeout = window.setTimeout(() => {
      this.props.actions.resetUpdateProfileQuery(UPDATE_ACCOUNT_PROFILE_QUERY);
    }, 2000);
  };

  render() {
    const {
      isFormSubmitting,
      isFormSuccess,
      isFormFailure,
    } = this.props;
    const {
      formValues,
      formErrors,
      isFormAlertVisible,
      shouldImageUploaderReset,
    } = this.state;

    const { address, products } = this.props.accountProfile;

    return (
      <Form onSubmit={this.handleFormSubmit}>
        <div className={'o-grid o-grid--sm-reverse'}>
          <div className={'o-grid__column o-grid__column--sm-6 o-grid__column--no-gap-sm'}>
            <div className={'c-account-community-profile-form__card'}>
              <p className={'c-account-community-profile-form__card-title'}>
                {I18n.t(T.communityProfile.profileCard.title)}
              </p>
              <AccountProfileCard
                name={formValues.nickname || I18n.t(T.communityProfile.defaultNickname)}
                location={address}
                description={formValues.description}
                products={products}
                // @TODO: Enable tags when needed
                // tags={}
                header={(
                  <ImageUploader
                    name={ProfileFormFields.IMAGE}
                    image={this.state.imageUploaderPreview}
                    shouldReset={shouldImageUploaderReset}
                    onImageChange={this.onAvatarChange}
                  />
                )}
              />
            </div>
          </div>
          <div className={'o-grid__column o-grid__column--sm-6 o-grid__column--no-gap'}>
            <AccountCommunityDetails
              values={formValues}
              errors={formErrors}
              onChange={this.onFieldChange}
            />
          </div>
        </div>
        <Portal target={PortalExit.LAYOUT_CONTENT_TOP}>
          {/* // Refactor Form Alert component into Form wrapper when changing to Formik */}
          <AccountFormAlert
            isSubmitting={isFormSubmitting}
            isSuccess={isFormSuccess}
            isFailure={isFormFailure}
            isVisible={isFormAlertVisible}
            isDisabled={!isFormValid(this.state.formErrors) || isFormSuccess}
            handleReset={this.handleFormReset}
            handleSubmit={this.handleFormSubmit}
          />
        </Portal>
      </Form>
    );
  }
}

export const AccountCommunityProfileForm = connect(mapStateToProps, mapDispatchToProps)
  (AccountCommunityProfileFormComponent);
