export enum QueryStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  FAILURE = 'FAILURE',
  INTERRUPTED = 'INTERRUPTED',
}

export interface QueryState {
  success: boolean;
  error: boolean;
  notReady: boolean;
  pending: boolean;
  interrupted: boolean;
}

export interface Query<T = any> {
  status?: QueryStatus;
  response?: T;
  error?: Error;
  errorCount?: number;
}

export enum QueryTypes {
  BATTERY_QUERY = 'batteryQuery',
  CONTRACT_QUERY = 'contractQuery',
  CONTRACT_STATISTICS_QUERY = 'contractStatisticsQuery',
  SITE_LIVE_QUERY = 'siteLiveQuery',
  CHARGER_LIVE_QUERY = 'chargerLiveQuery',
}

export interface QueryData {
  status: QueryState;
  errorCount: number;
}
