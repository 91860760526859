import { StoreState } from '+app/store/store.interface';
import { getStatus } from '+app/utils/query.util';
import { QueryStatus } from '+query/query.state';
import { createSelector } from 'reselect';
import { CHANGE_USER_PASSWORD_QUERY, SET_USER_DATA_QUERY, UPDATE_ACCOUNT_PROFILE_QUERY } from './account.state';

export const getAccountState = (state: StoreState) => state.account;

export const getAccountProfile = createSelector(
  getAccountState,
  state => state.profile,
);

export const getProfilePrivacy = createSelector(
  getAccountState,
  state => state.profile && state.profile.privacy,
);

export const isSetProfilePending = createSelector(
  getAccountState,
  state => state[UPDATE_ACCOUNT_PROFILE_QUERY] &&
    state[UPDATE_ACCOUNT_PROFILE_QUERY].status === QueryStatus.PENDING,
);

export const isSetProfileSuccess = createSelector(
  getAccountState,
  state => !!state[UPDATE_ACCOUNT_PROFILE_QUERY] &&
    state[UPDATE_ACCOUNT_PROFILE_QUERY].status === QueryStatus.SUCCESS,
);

export const isSetProfileError = createSelector(
  getAccountState,
  state => !!state[UPDATE_ACCOUNT_PROFILE_QUERY] &&
    !!state[UPDATE_ACCOUNT_PROFILE_QUERY].errorCount &&
    state[UPDATE_ACCOUNT_PROFILE_QUERY].errorCount as number >= 1,
);

export const isSetUserDataPending = createSelector(
  getAccountState,
  state => state[SET_USER_DATA_QUERY] &&
    state[SET_USER_DATA_QUERY].status === QueryStatus.PENDING,
);

export const isSetUserDataSuccess = createSelector(
  getAccountState,
  state => !!state[SET_USER_DATA_QUERY] &&
    state[SET_USER_DATA_QUERY].status === QueryStatus.SUCCESS,
);

export const isSetUserDataError = createSelector(
  getAccountState,
  state => !!state[SET_USER_DATA_QUERY] &&
    !!state[SET_USER_DATA_QUERY].errorCount &&
    state[SET_USER_DATA_QUERY].errorCount as number >= 1,
);

export const getChangePasswordQueryStatus = createSelector(
  getAccountState,
  state => getStatus(state[CHANGE_USER_PASSWORD_QUERY]),
);

export const getChangePasswordQuery = createSelector(
  getAccountState,
  state => state[CHANGE_USER_PASSWORD_QUERY],
);

export const isChangeUserPasswordPending = createSelector(
  getAccountState,
  state => state[CHANGE_USER_PASSWORD_QUERY] &&
    state[CHANGE_USER_PASSWORD_QUERY].status === QueryStatus.PENDING,
);

export const isChangeUserPasswordSuccess = createSelector(
  getAccountState,
  state => !!state[CHANGE_USER_PASSWORD_QUERY] &&
    state[CHANGE_USER_PASSWORD_QUERY].status === QueryStatus.SUCCESS,
);

export const isChangeUserPasswordError = createSelector(
  getAccountState,
  state => !!state[CHANGE_USER_PASSWORD_QUERY] &&
    !!state[CHANGE_USER_PASSWORD_QUERY].errorCount &&
    state[CHANGE_USER_PASSWORD_QUERY].errorCount as number >= 1,
);
