import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';

import './CommunityMembers.component.scss';

interface Props {
  recentMembersCount?: number;
  dataTestId?: string;
}

export const CommunityMembers: React.FC<Props> = ({
  recentMembersCount = 0,
  dataTestId,
}) => (
  <div className={'p-community-members'}>
    <div className={'p-community-members__inner'}>
      <Icon.People className={'p-community-members__icon'} />
      <div>
        <div className={'p-community-members__value'}>
          <span 
            data-test-id={dataTestId}
            className={'p-community-members__amount'}>
            {recentMembersCount}
          </span>
          <Translate
            className={'p-community-members__unit'}
            value={T.sonnenCommunity.map.recentMembersWidget.newMembers}
          />
        </div>
        <div className={'p-community-members__range'}>
          <Translate value={T.sonnenCommunity.map.recentMembersWidget.range} />
        </div>
      </div>
    </div>
  </div>
);
