import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';

import {
  Alert,
  Checkbox,
  Group,
  Info,
  Item,
  Password,
  Select,
  Textarea,
} from './elements';

import './Form.component.scss';

interface Props {
  onSubmit: any;
}

export type  FormElement<P> = React.FC<WrappedFieldProps & P>;

export class Form extends React.PureComponent<Props> {
  static Alert = Alert;
  static Checkbox = Checkbox;
  static Group = Group;
  static Info = Info;
  static Item = Item;
  static Password = Password;
  static Select = Select;
  static Textarea = Textarea;

  render() {
    const { children, onSubmit } = this.props;

    return (
      <form
        onSubmit={onSubmit}
        className={'c-form'}
      >
        {children}
      </form>
    );
  }
}
