import * as React from 'react';
import { connect } from 'react-redux';

import { isAuthenticated } from '+app/+auth/store/auth.selectors';
import { DashboardLayout } from '+app/+dashboard/containers';
import { isVerifyingIdentity } from '+app/+guide/store/+guide.selectors';
import { RouterBuilder } from '+app/router/factory';
import { getPaths, getSubRoutes } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { useRouterState } from '+app/router/RouterProvider.component';
import { NotificationProvider } from '+app/shared/containers';
import { StoreState } from '+app/store/store.interface';
import { Modal } from '@sonnen/shared-web';
import { GuideVerifyIdentity } from '../GuideVerifyIdentity';

const mapStateToProps = (state: StoreState) => ({
  isUserInGuide: !isAuthenticated(state),
  isVerifyingIdentity: isVerifyingIdentity(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  ;

export const GuideComponent: React.FC<Props> = ({
  isUserInGuide,
  isVerifyingIdentity,
}) => {
  const { availableFlatRoutes } = useRouterState();

  return isUserInGuide ? (
    <>
      <NotificationProvider>
        <DashboardLayout>
          <RouterBuilder
            routes={getSubRoutes(RouteName.GUIDE, availableFlatRoutes)}
            redirectTo={getPaths().GUIDE_OVERVIEW}
          />
        </DashboardLayout>
      </NotificationProvider>
      <Modal isOpen={isVerifyingIdentity} size={'small'}>
        <GuideVerifyIdentity />
      </Modal>
    </>
  ) : (
    <RouterBuilder
      routes={getSubRoutes(RouteName.GUIDE, availableFlatRoutes)}
      redirectTo={getPaths().ROOT}
    />
  );
};

export const Guide = connect(mapStateToProps)(GuideComponent);
