import * as classNames from 'classnames';
import * as React from 'react';

import '../InfographicIcon.util.scss';

interface Props {
  x: number;
  y: number;
  isActive?: boolean;
}

/* tslint:disable:max-line-length */
export const InfographicIconChargerStation: React.FC<Props> = ({ x, y, isActive }) => (
  <svg
    className={classNames('u-infographic-icon u-infographic-icon__hover', { 'is-active': isActive })}
    x={x}
    y={y}
    viewBox={'0 0 46 106'}
    width={'28'}
    height={'65'}
  >
    <path
      className={'u-infographic-icon__fill'}
      d={'M30.6,61.1c5.2,0,11.1-5.1,11.1-10.9c-0.1-11.7-0.1-23.4,0-35.1c0.1-5.7-5-10.3-10.2-10.5c-5.9-0.3-11.8-0.2-17.7,0C8.5,4.8,3.9,9.8,4,14.8c0.2,5.8,0,11.7,0,17.5c0,5.8-0.1,11.6,0,17.4c0,1.6,0.4,3.3,0.9,4.8c1.2,3.4,5.6,6.7,9.5,6.6c1.5,0,2.9,0,4.4,0v38.5h8.6V61.1C28.5,61.1,29.6,61.1,30.6,61.1z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M30.7,61.2c5.3,0,11.3-5.1,11.2-11c-0.1-11.8-0.1-23.7,0-35.5C42.1,8.9,37,4.3,31.6,4c-6-0.3-12-0.2-18,0c-5.3,0.1-10,5.2-9.9,10.3c0.2,5.9,0,11.8,0,17.8c0,5.9-0.1,11.7,0,17.6c0,1.6,0.4,3.3,0.9,4.9c1.2,3.5,5.6,6.8,9.6,6.7c1.5,0,3,0,4.4,0v37.4h-0.9H6.9c-0.6,0-1,0.5-1,1c0,0.6,0.5,1,1,1H19h10.3h10.3c0.6,0,1-0.5,1-1c0-0.6-0.5-1-1-1h-9.3h-2.1h-0.7V61.2C28.6,61.2,29.7,61.2,30.7,61.2z M21,61.2c1.5,0,3,0,4.4,0v37.4H21V61.2z M5.8,50.5c0-12.2,0-24.3,0-36.5c0-4,4.2-8.1,8.4-8.2c5.6-0.1,11.1-0.2,16.7,0c4.3,0.1,9.4,3.9,9.1,9.2c-0.3,5.8-0.1,11.5-0.1,17.3c0,5.9,0,11.7,0,17.6c0,3.9-2.2,6.5-5.5,8.1c-1.5,0.7-3.4,1-5.1,1c-4.9,0.1-9.8,0.1-14.7,0C10.1,59.2,5.8,55,5.8,50.5z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M25.4,42c2.2-0.2,4.4-1.4,5.8-3.2c1.1-1.3,1.5-2.8,1.4-4.2c0-0.3-0.1-0.6-0.1-0.9c-0.1-0.6-0.2-1.3-0.4-1.9c-0.6-1.6-1.2-3.2-1.8-4.8c-1.1-2.9-2.2-5.8-3.1-8.8C26.6,16,25,14.1,22.9,13c-1.2-0.6-2.4-0.9-3.8-0.9c-1.1,0-2.2,0.2-3.3,0.6c-3.3,1.2-5.6,5.1-4.7,7.8c2.1,6.3,4,11.7,5.8,16.8C18,40.5,21.5,42.4,25.4,42z M18.9,37c-0.8-2.1-1.6-4.3-2.3-6.4c-0.3-0.8-0.6-1.6-0.8-2.4c-0.9-2.6-1.7-4.8-2.4-7c-0.5-1.4-0.4-2.7,0.2-3.9c0.7-1.4,2.2-2.5,4.3-3.1c2.7-0.8,6.1,0.8,7.1,3.4c1.3,3.4,2.5,7,3.7,10.4l0.6,1.8c0.4,1.2,0.8,2.4,1.1,3.3c0.1,0.4,0.2,0.7,0.3,0.9l0,0c0,3.5-1.9,5.6-5.4,6.2c-0.3,0.1-0.6,0.1-0.9,0.1C22,40.3,19.6,38.9,18.9,37z'}
    />
  </svg>
);
