import { T } from '@sonnen/shared-i18n/customer';
import * as moment from 'moment';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { StepName } from '+app/+guide/helpers/steps.config';
import { getPath } from '+app/router/router.helper';
import { Partner } from '+guide/store/types/partner.interface';
import { RouteName } from '+router/router.types';
import { formatDate } from '+utils/format.util';
import { GuideNotificationsActionProps } from './GuideNotifications.component';

import letter from './letter.svg';
import rocket from './rocket.svg';
import tools from './tools.svg';

export enum NotificationType {
  IA_AVAILABLE = 'ia_available',
  HW_ORDER_CONFIRMED = 'hw_order_confirmed',
  FLAT_ORDER_CONFIRMED = 'flat_order_confirmed',
  OFFER_AVAILABLE = 'offer_available',
  PARTNER_AVAILABLE = 'partner_available',
  DSO_REGISTRATION_DONE = 'dso_registration_done',
  HARDWARE_COMMISSIONING_DONE = 'hardware_commissioning_done',
  DSO_METER_INSTALLATION = 'dso_meter_installation',
}

export interface NotificationProps {
  onClick: () => void;
  label: string;
  title: string;
  description: string;
  icon: React.ReactNode;
  date: Date | string;
}

const getTimeInterval = (date: Date | string) => {
  if (!moment(date).isValid()) {
    return '';
  }
  const diff = moment().diff(date, 'days');
  return diff > 25 ? formatDate(date) : moment(date).fromNow();
};

export const factorizeNotifications = (
  notificationList: Array<{type: NotificationType, date: Date | string, isFirstNotification?: boolean}>,
  actions: GuideNotificationsActionProps['actions'],
  partner?: Partner,
) => {
  const activeNotifications: NotificationProps[] = [];

  // @TODO Remove overview.notification keys from shared-i18n
  notificationList
    .sort((a, b) => {
      return +new Date(b.date) - +new Date(a.date);
    })
    .map(notification => {
      switch (notification.type) {
        case NotificationType.OFFER_AVAILABLE:
          activeNotifications.push({
            onClick: () => {
              actions.push(getPath(RouteName.GUIDE_PURCHASE_STEP, [StepName.ACCEPT_OFFER]));
            },
            label: I18n.t(T.customerGuidance.notifications.labels.shakeHands),
            title: notification.isFirstNotification ?
              I18n.t(T.customerGuidance.notifications.offerReceived.title)
              : I18n.t(T.customerGuidance.notifications.offerReceived.titleNewOffer),
            description: I18n.t(T.customerGuidance.notifications.offerReceived.description, {
              partnerCompanyName: partner ? partner.name : null,
            }),
            icon: <img src={letter} alt={'Mail icon'} />,
            date: getTimeInterval(notification.date),
          });
          break;
        case NotificationType.IA_AVAILABLE:
          activeNotifications.push({
            onClick: () => {
              actions.push(getPath(RouteName.GUIDE_PURCHASE_STEP, [StepName.ACCEPT_OFFER]));
            },
            label: I18n.t(T.customerGuidance.notifications.labels.shakeHands),
            title: notification.isFirstNotification ?
              I18n.t(T.customerGuidance.notifications.impactAnalysisReceived.title)
              : I18n.t(T.customerGuidance.notifications.impactAnalysisReceived.titleNewIa),
            description: I18n.t(T.customerGuidance.notifications.impactAnalysisReceived.description),
            icon: <img src={letter} alt={'Mail icon'} />,
            date: getTimeInterval(notification.date),
          });
          break;
        case NotificationType.FLAT_ORDER_CONFIRMED:
          activeNotifications.push({
            onClick: () => {
              actions.push(getPath(RouteName.GUIDE_PURCHASE_STEP, [StepName.FLAT_ORDER_CONFIRMATION]));
            },
            label: I18n.t(T.customerGuidance.notifications.labels.shakeHands),
            title: I18n.t(T.customerGuidance.notifications.flatOrderConfirmation.title),
            description: I18n.t(T.customerGuidance.notifications.flatOrderConfirmation.description),
            icon: <img src={letter} alt={'Mail icon'} />,
            date: getTimeInterval(notification.date),
          });
          break;
        case NotificationType.HW_ORDER_CONFIRMED:
          activeNotifications.push({
            onClick: () => {
              actions.push(getPath(RouteName.GUIDE_PURCHASE_STEP, [StepName.HARDWARE_ORDER_CONFIRMATION]));
            },
            label: I18n.t(T.customerGuidance.notifications.labels.shakeHands),
            title: I18n.t(T.customerGuidance.notifications.hardwareOrderConfirmation.title),
            description: I18n.t(T.customerGuidance.notifications.hardwareOrderConfirmation.description, {
              partnerCompanyName: partner ? partner.name : null,
            }),
            icon: <img src={letter} alt={'Mail icon'} />,
            date: getTimeInterval(notification.date),
          });
          break;
        case NotificationType.PARTNER_AVAILABLE:
          activeNotifications.push({
            onClick: () => {
              actions.push(getPath(RouteName.GUIDE_DISCOVERY_STEP, [StepName.DISTRIBUTION_TO_PARTNER]));
            },
            label: I18n.t(T.customerGuidance.notifications.labels.discover),
            title: I18n.t(T.customerGuidance.notifications.distributionToPartner.title),
            description: I18n.t(T.customerGuidance.notifications.distributionToPartner.description),
            icon: <img src={rocket} alt={'Rocket icon'} />,
            date: getTimeInterval(notification.date),
          });
          break;
        case NotificationType.DSO_REGISTRATION_DONE:
          activeNotifications.push({
            onClick: () => {
              actions.push(getPath(RouteName.GUIDE_SETUP_STEP, [StepName.DSO_REGISTRATION]));
            },
            label: I18n.t(T.customerGuidance.notifications.labels.setup),
            title: I18n.t(T.customerGuidance.notifications.dsoRegistrationDone.title),
            description: I18n.t(T.customerGuidance.notifications.dsoRegistrationDone.description),
            icon: <img src={tools} alt={'Tools icon'} />,
            date: getTimeInterval(notification.date),
          });
          break;
        case NotificationType.HARDWARE_COMMISSIONING_DONE:
          activeNotifications.push({
            onClick: () => {
              actions.push(getPath(RouteName.GUIDE_SETUP_STEP, [StepName.HARDWARE_COMMISSIONING]));
            },
            label: I18n.t(T.customerGuidance.notifications.labels.setup),
            title: I18n.t(T.customerGuidance.notifications.hardwareCommissioningDone.title),
            description: I18n.t(T.customerGuidance.notifications.hardwareCommissioningDone.description),
            icon: <img src={tools} alt={'Tools icon'} />,
            date: getTimeInterval(notification.date),
          });
          break;
        case NotificationType.DSO_METER_INSTALLATION:
          activeNotifications.push({
            onClick: () => {
              actions.push(getPath(RouteName.GUIDE_SETUP_STEP, [StepName.DSO_METER_INSTALLATION]));
            },
            label: I18n.t(T.customerGuidance.notifications.labels.setup),
            title: I18n.t(T.customerGuidance.notifications.dsoMeterInstallationDone.title),
            description: I18n.t(T.customerGuidance.notifications.dsoMeterInstallationDone.description),
            icon: <img src={tools} alt={'Tools icon'} />,
            date: getTimeInterval(notification.date),
          });
          break;
        default:
          break;
      }
    });

  return activeNotifications;
};
