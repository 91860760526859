import { Bubble, Icon } from '@sonnen/shared-web';
import { breakpointUp } from '@sonnen/shared-web';
import * as React from 'react';

import './InfoTooltip.component.scss';

export const InfoTooltip: React.FC = ({ children }) => (
  <span className={'c-info-tooltip'}>
    <Icon.Info className={'c-info-tooltip__icon'}/>
    <div className={'c-info-tooltip__content'}>
      <Bubble side={window.innerWidth >= breakpointUp('SM') ? 'bottom' : 'bottom-right'}>
        {children}
      </Bubble>
    </div>
  </span>
);
