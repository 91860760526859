import { createSelector } from 'reselect';

import { StoreState } from '+app/store/store.interface';

export const getLayoutState = (store: StoreState) => store.layout;

export const isMobileNavOpen = createSelector(
  getLayoutState,
  state => state.isMobileNavOpen,
);

export const isSiteSwitcherOpen = createSelector(
  getLayoutState,
  state => state.isSiteSwitcherOpen,
);
