import { useHttp, useJsonApi } from '+app/shared/network';
import { CONFIG } from '+config';
import { BatteryRegistrationAttributes } from './types/batteryRegistration.interface';
import { CustomerNoRegistrationAttributes } from './types/customerNoRegistration.interface';
import { EmailRegistrationAttributes } from './types/emailRegistration.interface';
import { RegisteredUserAttributes } from './types/registeredUser.interface';
import { RegistrationResponseData } from './types/registrationResponse.interface';
import { ResetPasswordAttributes } from './types/resetPassword.interface';
import { ResetPasswordRequestAttributes } from './types/resetPasswordRequest.interface';
import { ErrorsResponseData, UserData } from './types/user.interface';

// TODO: general - think about how to merge account and user store logic which is basically the same thing

export enum RequestDataType {
  USERS = 'users',
  INVITATIONS = 'invitations',
}

const registerByEmail = (data: EmailRegistrationAttributes) =>
  useJsonApi().post<RegistrationResponseData>(`${CONFIG.API.USER_DATA}/users/registration/email`)
    .ofType(RequestDataType.INVITATIONS)
    .withAttributes(data)
    .send()
    .then(response => response.element);

const registerByBatteryData = (data: BatteryRegistrationAttributes) =>
  useJsonApi().post<RegistrationResponseData>(`${CONFIG.API.USER_DATA}/users/registration/battery`)
    .ofType(RequestDataType.INVITATIONS)
    .withAttributes(data)
    .send()
    .then(response => response.element);

const registerByCustomerNo = (data: CustomerNoRegistrationAttributes) =>
  useJsonApi().post<RegistrationResponseData>(`${CONFIG.API.USER_DATA}/users/registration/customer-number`)
    .ofType(RequestDataType.INVITATIONS)
    .withAttributes(data)
    .send()
    .then(response => response.element);

const registerNewAccount = (data: RegisteredUserAttributes) =>
  useJsonApi().put<UserData>(`${CONFIG.API.USER_DATA}/users/registration`)
    .ofType(RequestDataType.USERS)
    .withAttributes(data)
    .send()
    .then(response => response.element);

const requestPasswordReset = (data: ResetPasswordRequestAttributes) =>
  useHttp().post<ErrorsResponseData>(
    `${CONFIG.API.USER_DATA}/users/password`,
    {
      body: {
        data: {
          type: RequestDataType.USERS,
          attributes: data,
        },
      },
    },
  );

const resetPassword = (data: ResetPasswordAttributes) =>
  useHttp().put<ErrorsResponseData>(
    `${CONFIG.API.USER_DATA}/users/password`,
    {
      body: {
        data: {
          type: RequestDataType.USERS,
          attributes: data,
        },
      },
    },
  );

const getUser = () => useJsonApi().get<UserData>(`${CONFIG.API.USER_DATA}/users/me`)
  .send()
  .then(response => response.element);

export const UserRepository = {
  registerByEmail,
  registerByBatteryData,
  registerByCustomerNo,
  registerNewAccount,
  requestPasswordReset,
  resetPassword,
  getUser,
};
