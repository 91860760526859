import { createReducer } from '+utils/redux';
import { NOTIFICATION_ACTIONS, NotificationActions } from './notification.actions';
import { NotificationHelper } from './notification.helper';
import { initialState, NotificationState } from './notification.state';

export const reducer = createReducer<NotificationState, NotificationActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case NOTIFICATION_ACTIONS.SET_SYSTEM_COMPONENT_NOTIFICATIONS:
        return {
          ...state,
          systemNotifications: {
            ...state.systemNotifications,
            componentNotifications: action.systemNotifications,
          },
        };
      case NOTIFICATION_ACTIONS.SET_SYSTEM_CASE_NOTIFICATIONS:
        return {
          ...state,
          systemNotifications: {
            ...state.systemNotifications,
            caseNotifications: action.systemNotifications,
          },
        };
      case NOTIFICATION_ACTIONS.SET_NOTIFICATIONS:
        return {
          ...state,
          notifications: action.notifications,
        };
      case NOTIFICATION_ACTIONS.ADD_NOTIFICATION:
        const withAddedNotification = NotificationHelper.addUniqueNotification(action.notification);
        return {
          ...state,
          notifications: withAddedNotification(state.notifications),
        };
      case NOTIFICATION_ACTIONS.REMOVE_NOTIFICATION:
        const withoutRemovedNotification = NotificationHelper.removeNotificationById(action.id);
        return {
          ...state,
          notifications: withoutRemovedNotification(state.notifications),
        };
      case NOTIFICATION_ACTIONS.CLEAR_NOTIFICATIONS:
        return {
          ...state,
          notifications: [],
        };
      default:
        return state;
    }
  },
);
