import { Query } from '../query/query.state';
import { ChargerLive } from './types/chargerLiveData.interface';
import { SiteLive } from './types/siteLiveData.interface';

export type LiveDataPollingType = 'charger' | 'site';

export enum LiveStateStatus {
  ACTIVE,
  INACTIVE,
  TRANSITIONING,
}

export const GET_SITE_LIVE_DATA_QUERY = 'getSiteLiveDataQuery';
export const GET_CHARGER_LIVE_DATA_QUERY = 'getChargerLiveDataQuery';

export interface LiveState {
  siteLiveData: SiteLive | undefined;
  siteLiveDataPollingTickCount: number;
  chargerLiveData: ChargerLive | undefined;
  activePollings: LiveDataPollingType[];
  liveStateStatus: LiveStateStatus;
  [GET_SITE_LIVE_DATA_QUERY]: Query<SiteLive>;
  [GET_CHARGER_LIVE_DATA_QUERY]: Query<ChargerLive>;
}

export const initialState: LiveState = {
  siteLiveData: undefined,
  siteLiveDataPollingTickCount: 0,
  chargerLiveData: undefined,
  activePollings: [],
  liveStateStatus: LiveStateStatus.ACTIVE,
  [GET_SITE_LIVE_DATA_QUERY]: {},
  [GET_CHARGER_LIVE_DATA_QUERY]: {},
};
