import { MediaQuery } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';

import './GuideTableRow.component.scss';

interface Props {
  content: React.ReactNode;
  secondaryContent?: React.ReactNode;
  tertiaryContent?: React.ReactNode;
  side?: React.ReactNode;
  isCompactOnMobiles?: boolean;
}

export const GuideTableRow: React.FC<Props> = ({
  content,
  secondaryContent,
  tertiaryContent,
  side,
  isCompactOnMobiles,
}) => (
  <div className={'c-guide-table-row'}>
    <div className={'c-guide-table-row__cell'}>
      {content}
    </div>
    <Media query={MediaQuery.DOWN_SM}>
      {(isMobile: boolean) => !(isMobile && isCompactOnMobiles) ? (
        <>
          {secondaryContent && (
            <div className={'c-guide-table-row__cell c-guide-table-row__cell--narrow'}>
              {secondaryContent}
            </div>
          )}
          {tertiaryContent && (
            <div className={'c-guide-table-row__cell'}>
              {tertiaryContent}
            </div>
          )}
        </>
      ) : null }
    </Media>
    {side && (
      <div className={'c-guide-table-row__cell c-guide-table-row__cell--narrow'}>
        {side}
      </div>
    )}
  </div>
);
