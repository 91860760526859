import * as React from 'react';

import './GuidePreviewStep.component.scss';

interface Props {
  title: string;
  subtitle: string;
  icon: string;
}

export const GuidePreviewStep: React.FC<Props> = ({title, subtitle, icon}) => (
  <div className={'c-guide-preview-step'}>
    <div className={'c-guide-preview-step__image'}>
      <img src={icon} />
    </div>
    <div className={'c-guide-preview-step__title'}>
      {title}
    </div>
    <div className={'c-guide-preview-step__subtitle'}>
      {subtitle}
    </div>
  </div>
);
