import { Loader } from '@sonnen/shared-web';
import { DefaultParagraph } from '@sonnen/shared-web';
import classNames from 'classnames';
import * as React from 'react';
import { AppLoaderProps } from './AppLoader.component';

import './AppLoader.web.component.scss';

export const AppLoaderWeb: React.SFC<AppLoaderProps> = ({ hint }) => (
  <section className={'c-app-loader'}>
    <span className={classNames('c-app-loader__icon', {
      [hint.icon || '']: hint.icon,
    })} />
    <h2 className={'c-app-loader__headline'}>
      {hint.headline}
    </h2>
    <DefaultParagraph>
      {hint.description}
    </DefaultParagraph>
    <div className={'c-app-loader__loader'}>
      <Loader />
    </div>
  </section>
);
