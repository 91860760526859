import { OtherUnit } from '@sonnen/shared-web';
import * as moment from 'moment';
import {
  compose, has,
  ifElse, map,
  objOf, path,
} from 'ramda';
import { ErrorStatus } from '../../shared/utils.types';

export const handleReceivedFrequencies = compose(
  objOf('frequencies'),
  map(([timestamp, value]) => ({
    value: Number(value),
    timestamp,
    unit: OtherUnit.HERTZ,
  })),
  Object.entries,
);

export const handleReceivedErrors = compose(
  objOf('status'),
  (status = '') => (ErrorStatus[status] || ErrorStatus.GENERAL_ERROR),
  path<string>(['status']),
);

export const handleFrequencyResponse = ifElse(
  has('error'),
  handleReceivedErrors,
  handleReceivedFrequencies,
);

export function setFrequencyState<T>(data: T) {
  const status = path<string>(['status'], data) || ErrorStatus.NONE;
  const freqs = path<[]>(['frequencies'], data) || [];

  return ifElse(
    ({ frequencies }) => {
      const currentFreq = frequencies.length ? frequencies[frequencies.length - 1] : freqs[freqs.length - 1];
      const oneMinuteAgo = moment().subtract(1, 'minute');
      const lastFrequency = moment(currentFreq && currentFreq.timestamp);

      return (currentFreq && lastFrequency.isAfter(oneMinuteAgo)) || !status;
    },
    ({ frequencies, ...state }) => ({
      ...state,
      frequencies: [
        ...(freqs.length ? frequencies.slice(1) : frequencies),
        ...freqs,
      ],
      status: ErrorStatus.NONE,
      loading: false,
    }),
    state => ({ ...state, status, loading: false }),
  );
}
