import { Data, MergedData } from '@coolio/json-api';

import { Resolution } from '+analysis/store/types/resolution.interface';

export type StatisticsV2FilterResolution =
  | Resolution.RES_15_MINUTES
  | Resolution.RES_1_HOUR
  | Resolution.RES_1_DAY
  | Resolution.RES_1_MONTH
  | Resolution.RES_1_YEAR
  ;

export enum FieldName {
  PRODUCED = 'produced_energy',
  CONSUMED = 'consumed_energy',
  BATTERY_CHARGED = 'battery_charged_energy',
  BATTERY_DISCHARGED = 'battery_discharged_energy',
  GRID_FEEDIN = 'grid_feedin_energy',
  GRID_PURCHASE = 'grid_purchase_energy',
  DIRECT_USAGE = 'direct_usage_energy',
}

enum FieldUnit {
  NUMBER = 'integer',
  KWH = 'kwh',
  WH = 'wh',
}

export interface Field {
  name: FieldName;
  unit: FieldUnit;
  index: number;
}

type Timestamp = string; // ie: '2020-07-01T00:00:00+02:00'
type Values = Record<Timestamp, number[]>;

export interface StatisticsV2Attributes {
  endAt: Timestamp;
  latestAvailableMeasurement: Timestamp;
  startAt: Timestamp;
  resolution: StatisticsV2FilterResolution;
  fields: Field[];
  values: Values;
}

export type StatisticsV2Data = Data<StatisticsV2Attributes>;
export type StatisticsV2 = MergedData<StatisticsV2Data>;

/* PAYLOAD */
export interface StatisticsV2Filters {
  start: Timestamp;
  end: Timestamp;
  resolution: StatisticsV2FilterResolution;
}
