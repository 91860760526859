import { T } from '@sonnen/shared-i18n/customer';
import { Card, DefaultParagraph, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { CommunityGraphParagraphs } from '../';

import { FrequencyChart } from '../../../../features/frequency';

import './CommunityGraph.web.component.scss';

export const CommunityGraphWeb: React.SFC = () => (
  <div className={'c-community-graph__card'}>
    <Card
      title={I18n.t(T.sonnenCommunity.gridBalancing.title)}
      smallTitleGap={true}
    >
      <DefaultParagraph className={'p-community__paragraph'}>
        {I18n.t(T.sonnenCommunity.gridBalancing.subtitle)}
      </DefaultParagraph>
      <div className={'o-grid'}>
        <section className={'o-grid__column o-grid__column--md-8 o-grid__column--no-gap-md'}>
          <FrequencyChart className={'c-community-graph__chart'} />
        </section>
        <section className={'o-grid__column o-grid__column--md-4 o-grid__column--no-gap'}>
          <PageSubheadline noBorder={true} smallGap={true}>
            {I18n.t(T.sonnenCommunity.gridBalancing.description.title)}
          </PageSubheadline>
          <CommunityGraphParagraphs />
        </section>
      </div>
    </Card>
  </div>
);
