import { storeProvider } from '+app/store/store.provider';
import { Jwt } from '+app/utils/jwt.util';
import { getGuideAuthToken, getGuidePersistedVerifiedAuthToken, getGuideVerifiedAuthToken } from '../../+guide/store/+guide.selectors';

const decode = (token: string) => Jwt.decode<{ leadId: string }>(token);

class AuthorizationTokenProvider {
  getToken(): string | undefined {
    const state = storeProvider.getState();
    const invitationToken = getGuideAuthToken(state);
    const decodedInvitationToken = invitationToken && decode(invitationToken);
    const authToken =
      (decodedInvitationToken && getGuidePersistedVerifiedAuthToken(state)(decodedInvitationToken.leadId))
      || getGuideVerifiedAuthToken(state);

    if (
      authToken
      && decodedInvitationToken
      && (decodedInvitationToken.leadId === decode(authToken).leadId)
    ) {
      return authToken;
    }

    return invitationToken;
  }
}

export const authorizationTokenProvider = new AuthorizationTokenProvider();
