import { LiveEnergyFlowProps } from '+app/shared/components';
import { LiveStateHelper } from '+live-state/helpers/LiveState.helpers';
import { LIVE_DATA_MOCK } from '+mocks/liveState.mock';
import { SITES_MOCK } from '+mocks/sites.mock';
import { Site } from '+shared/store/site/types/site.interface';

const site: Site = SITES_MOCK[0];

export const getMockedProps = (): LiveEnergyFlowProps => {
  const { ui } = LiveStateHelper.findStateFromValue({
    production: LIVE_DATA_MOCK.productionPower,
    consumption: LIVE_DATA_MOCK.consumptionPower,
    batteryDischarge: LiveStateHelper.getBatteryValue(LIVE_DATA_MOCK),
    gridOutput: LiveStateHelper.getGridValue(LIVE_DATA_MOCK),
    vppActive: true,
  });

  return {
    hasExplanations: true,
    isAppOnline: true,
    liveData: LIVE_DATA_MOCK,
    site,
    energyFlows: ui,
  };
};
