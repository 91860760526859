import { QUERY_ACTIONS, QueryActions } from '+app/shared/store/query/query.actions';
import { queryReducer } from '+query/query.reducer';
import { createReducer } from '+utils/redux';
import { DOCUMENTS_ACTIONS, DocumentsActions } from './documents.actions';
import {
  DocumentsState,
  DOWNLOAD_INVOICE_QUERY,
  DOWNLOAD_SYSTEM_DOCUMENT_QUERY,
  FETCH_INVOICES_QUERY,
  FETCH_MORE_INVOICES_QUERY,
  FETCH_OPPORTUNITIES_QUERY,
  FETCH_SYSTEM_DOCUMENTS_QUERY,
  initialState,
} from './documents.state';

const documentsQueryReducer = (
  state: DocumentsState,
  action: DocumentsActions | QueryActions) => queryReducer(state, action, [
    DOWNLOAD_INVOICE_QUERY,
    DOWNLOAD_SYSTEM_DOCUMENT_QUERY,
    FETCH_INVOICES_QUERY,
    FETCH_MORE_INVOICES_QUERY,
    FETCH_OPPORTUNITIES_QUERY,
    FETCH_SYSTEM_DOCUMENTS_QUERY,
  ], {
    // NOTE: this allows saving entire response in store, to use json api pagination links
    suppressResponse: false,
  },
);

export const reducer = createReducer<DocumentsState, DocumentsActions | QueryActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case DOCUMENTS_ACTIONS.SET_INVOICES:
        return {
          ...state,
          invoices: action.invoices,
        };
      case DOCUMENTS_ACTIONS.SET_MORE_INVOICES:
        return {
          ...state,
          invoices: state.invoices.concat(action.invoices),
        };
      case DOCUMENTS_ACTIONS.SET_OPPORTUNITIES:
        return {
          ...state,
          opportunities: action.opportunities,
        };
      case DOCUMENTS_ACTIONS.SET_SYSTEM_DOCUMENTS:
        return {
          ...state,
          systemDocuments: action.systemDocuments,
        };
      case DOCUMENTS_ACTIONS.CLEAR_INVOICES_STATE:
        return {
          ...state,
          invoices: initialState.invoices,
          fetchInvoicesQuery: initialState[FETCH_INVOICES_QUERY],
          fetchMoreInvoicesQuery: initialState[FETCH_MORE_INVOICES_QUERY],
        };
      // NOTE: we need to clear FETCH_MORE query whenever initial fetch is called
      case QUERY_ACTIONS.SUCCESS:
        return {
          ...documentsQueryReducer(state, action),
          ...action.queryName === FETCH_INVOICES_QUERY
            && { [FETCH_MORE_INVOICES_QUERY]: initialState[FETCH_MORE_INVOICES_QUERY] },
        };
      default:
        return documentsQueryReducer(state, action);
    }
  },
);
