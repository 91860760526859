import { ActionsUnion, createAction } from '+utils/redux';
import { LegalDocuments } from './types/legalDocuments.interface';

export enum LEGAL_ACTIONS {
  GET_LEGAL_DOCUMENTS = '[Legal] GET_LEGAL_DOCUMENTS',
  SET_LEGAL_DOCUMENTS_DATA = '[Legal] SET_LEGAL_DOCUMENTS_DATA',
}

export const LegalActions = {
  getLegalDocuments: () => createAction(LEGAL_ACTIONS.GET_LEGAL_DOCUMENTS),
  setLegalDocumentsData: (legalDocuments: LegalDocuments) =>
    createAction(LEGAL_ACTIONS.SET_LEGAL_DOCUMENTS_DATA, { legalDocuments }),
};

export type LegalActions = ActionsUnion<typeof LegalActions>;
