import { Contract } from '+contract/store/types/contract.interface';
import { getFormattedDate } from '+legacy/helpers/dates';
import { T } from '@sonnen/shared-i18n/customer';
import { Icon, InfoBanner } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

interface Props {
  contract: Contract;
}

export const ContractWrongDeliveryDateInfo: React.FC<Props> = ({
  contract,
}) => {
  const message = contract.deliveryStartAt
    ? 'wrongDeliveryDate'
    : 'deliveryDateNotPresent';

  return (
    <InfoBanner
      icon={<Icon.Truck />}
      title={I18n.t(T.yourFlat.notifications[message].title)}
      subtitle={(
        <Translate
          value={T.yourFlat.notifications[message].subtitle}
          date={getFormattedDate(
            contract.deliveryStartAt || contract.orderedAt || undefined,
            T.general.date.long,
          )}
          dangerousHTML={true}
        />
      )}
      isThemeCard={true}
    />
  );
};
