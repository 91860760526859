import { ActionsUnion, createAction } from '+app/utils/redux';

export enum DASHBOARD_ACTIONS {
  TOGGLE_MODAL_BACKUP_BUFFER = '[+Dashboard] TOGGLE_MODAL_BACKUP_BUFFER',
}

export const DashboardActions = {
  toggleModalBackupBuffer: () => createAction(
    DASHBOARD_ACTIONS.TOGGLE_MODAL_BACKUP_BUFFER,
  ),
};

export type DashboardActions = ActionsUnion<typeof DashboardActions>;
