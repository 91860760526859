import { Notification } from './types/notification.interface';
import { SystemNotificationsState } from './types/systemNotification';

export interface NotificationState {
  notifications: Notification[];
  systemNotifications: SystemNotificationsState;
}

export const initialState: NotificationState = {
  notifications: [],
  systemNotifications: {
    componentNotifications: [],
    caseNotifications: [],
  },
};
