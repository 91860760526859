import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

// TODO: Add more validators for reuse it in any case
export interface Validators {
  minLength?: number;
  maxLength?: number;
  lettersOnly?: boolean;
}

export interface RegexConstraint {
  label: string;
  regex: RegExp;
  dataTestId?: string;
}

export const validate = (
  value: string,
  validators: Validators = {},
  name?: string,
) => {
  if (!value) { return undefined; }

  const fieldName = name || I18n.t(T.general.errors.validation.field);
  const { minLength, maxLength, lettersOnly } = validators;

  switch (true) {
    case value.trim() === '':
      return I18n.t(T.general.errors.validation.whiteSpaces);
    case !!minLength && `${value.trim()}`.length < minLength:
      return I18n.t(T.general.errors.validation.minLength, { minLength });
    case !!maxLength && `${value.trim()}`.length > maxLength:
      return I18n.t(T.general.errors.validation.maxLength, { maxLength });
    case !!lettersOnly && !(/(\w\D)+/).test(value):
      return I18n.t(T.general.errors.validation.letter, { fieldName });
    default:
      return undefined;
  }
};

// @TODO: Change to some helper maybe.
export const passwordRegexConstraints: RegexConstraint[] = [
  {
    label: T.general.passwordRules.characterLimit,
    regex: /^.{10,}$/,
    dataTestId: 'password-regex-min-10-characters',
  },
  {
    label: T.general.passwordRules.number,
    regex: /[0-9]+/,
    dataTestId: 'password-regex-min-1-number',
  },
  {
    label: T.general.passwordRules.specialCharacter,
    regex: /[~`!@#$%^&*()\-_+={}[\]|\\/:;"\'<>,.?]/,
    dataTestId: 'password-regex-special-character',
  },
];

export const isPasswordValid = (password: string) =>
  passwordRegexConstraints.every(rule => rule.regex.test(password));
