import { T } from '@sonnen/shared-i18n/customer';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import Select, { Option, OptionValues } from 'react-select';
import { ArrowRenderer } from '..';

import '../selects.scss';

interface Props {
  classValue?: string;
  dataTestId?: string;
  options: Array<Record<string, any>>;
  placeholder?: string;
  props?: Record<string, any>;
  value: any;
  onChange: (arg?: any) => void;
}

export const SimpleSelect: React.SFC<Props> = ({
  options,
  onChange,
  placeholder = I18n.t(T.general.placeholders.defaultSelect),
  value = null,
  classValue = '',
  dataTestId,
  ...props
}) => {
  const optionRenderer = (props: Option<OptionValues>) => (
    <div data-test-id={dataTestId && `${dataTestId}-option`}>
      {props.label}
    </div>
  );
  const valueRenderer = (props: Option<OptionValues>) => (
    <div data-test-id={dataTestId && `${dataTestId}-value`}>
      {props.label}
    </div>
  );

  return <Select
    className={classNames('c-select c-select--simple', classValue)}
    options={options}
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    searchable={false}
    clearable={false}
    arrowRenderer={ArrowRenderer}
    optionRenderer={optionRenderer}
    valueRenderer={valueRenderer}
    {...props}
  />;
};
