interface PointCreatorArgs {
  properties?: object;
  lat: number;
  lng: number;
}

export const geoJsonPointCreator =
  ({ properties = {}, lat, lng }: PointCreatorArgs): GeoJSON.Feature<GeoJSON.Point> => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [lng, lat],
    },
    properties,
  });

type GeoJSONTransformators = typeof geoJsonPointCreator;

export const createGeoJson = (featureCreator: GeoJSONTransformators) =>
  (data: PointCreatorArgs[]): GeoJSON.FeatureCollection => ({
    type: 'FeatureCollection',
    features: data.map(featureCreator),
  });
