import * as R from 'ramda';

const getMeanDiffs = (numbersArray: number[]) => {
  const meanValue = R.mean(numbersArray);
  return R.map((value: number) => value - meanValue)(numbersArray);
};
const diffMultiplication = R.compose(
  R.sum,
  (lists: any[]) => R.zipWith(R.multiply, lists[0], lists[1]) as any, // TODO temporary solution for typings
  R.map(getMeanDiffs),
);

const squareX = (meanX: number) => R.compose(
  R.sum,
  R.map((val: number) => ((val - meanX) ** 2)),
);

export const linearRegression = (xValues: number[], yValues: number[]) => (xValue: number) => {
  const meanX = R.mean(xValues);
  const meanY = R.mean(yValues);

  const diff = diffMultiplication([xValues, yValues]);

  const sqX = squareX(meanX)(xValues);

  const a = diff / sqX;
  const b = meanY - (a * meanX);

  return b + (a * xValue);
};
