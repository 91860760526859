import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, ButtonTheme, DefaultParagraph, Icon, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { getPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { history } from '+app/router/store';

import communityPeopleImg from '+images/community/sonnenCommunity-world-people.svg';

import './OverviewCommunity.component.scss';

interface Props {
  externalLink?: string;
  redirectTo?: RouteName;
  buttonLabel: string;
}

export const OverviewCommunity: React.FC<Props> = ({
  redirectTo,
  externalLink,
  buttonLabel,
}) => (
  <section className={'c-overview-community'}>
    <div className={'c-overview-community__grid o-grid o-grid--centered o-grid--md-reverse'}>
      <div className={'o-grid__column o-grid__column--md-8 o-grid__column o-grid__column--no-gap-md'}>
        <div className={'c-overview-community__image'}>
          <img src={communityPeopleImg} alt={'sonnenCommunity'} />
        </div>
      </div>
      <div className={'o-grid__column o-grid__column--md-4 o-grid__column--no-gap'}>
        <div className={'overview-community__text'}>
          <PageSubheadline smallGap={true}>
            <Icon.Community className={'c-overview-community__headline-icon'} />
            {I18n.t(T.dashboard.community.headline)}
          </PageSubheadline>
          <DefaultParagraph>
            {I18n.t(T.dashboard.community.description)}
          </DefaultParagraph>
          {(redirectTo || externalLink) && (
            <div className={'c-overview-community__button'}>
              <Button
                dataTestId={'dashboard-sonnen-community-overview-more-button'}
                onClick={() => redirectTo
                  ? history.push(getPath(redirectTo))
                  : externalLink && window.open(externalLink, '_blank')
                }
                label={buttonLabel}
                size={ButtonSize.SECONDARY}
                theme={ButtonTheme.OUTLINE}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  </section>
);
