import { getGuideLeadPartner } from '+app/+guide/store/+lead.selectors';
import { PartnerStatus } from '+app/+guide/store/types/partner.interface';
import { StoreState } from '+app/store/store.interface';
import { T } from '@sonnen/shared-i18n/customer';
import { Link, PageHeadline } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import './GuideContact.component.scss';

const mapStateToProps = (state: StoreState) => ({
  partner: getGuideLeadPartner(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & RouteComponentProps
  ;

export const GuideContactComponent: React.FC<Props> = ({ partner }) => (
  <>
    <PageHeadline>
      {I18n.t(T.customerGuidance.contact.headline)}
    </PageHeadline>
    <div className={'o-grid'}>
      {partner && partner.status === PartnerStatus.ACCEPTED && (
        <div className={'o-grid__column o-grid__column--md-6'}>
          <div className={'c-guide-contact__card'}>
            <div className={'c-guide-contact__card-title'}>
              {partner.name}
            </div>
            <div className={'c-guide-contact__card-content'}>
              <p>{`${I18n.t(T.customerGuidance.contact.tel)}: ${partner.phone}`}</p>
              {partner.fax && <p>{`${I18n.t(T.customerGuidance.contact.fax)}: ${partner.fax}`}</p>}
              <p>
                {`${I18n.t(T.customerGuidance.contact.email)}: `}
                <Link href={`mailto:${partner.email}`} label={partner.email} />
              </p>
              <p>
                <Link href={partner.website} label={partner.website} />
              </p>
              <hr className={'c-guide-contact__separator'} />
              {partner.street}
              <br />
              {`${partner.zipCode} ${partner.city}`}
            </div>
          </div>
        </div>
      )}
      <div className={'o-grid__column o-grid__column--md-6'}>
        <div className={'c-guide-contact__card'}>
          <div className={'c-guide-contact__card-title'}>
            {I18n.t(T.customerGuidance.contact.sonnen.name)}
          </div>
          <div className={'c-guide-contact__card-content'}>
            <p className={'c-guide-contact__headline'}>
              {I18n.t(T.customerGuidance.contact.sonnen.title)}
            </p>
            <p>{`${I18n.t(T.customerGuidance.contact.tel)}: ${I18n.t(T.customerGuidance.contact.sonnen.tel)}`}</p>
            <p>{`${I18n.t(T.customerGuidance.contact.fax)}: ${I18n.t(T.customerGuidance.contact.sonnen.fax)}`}</p>
            <p>
              {`${I18n.t(T.customerGuidance.contact.email)}: `}
              <Link
                href={I18n.t(T.customerGuidance.contact.sonnen.mail)}
                label={I18n.t(T.customerGuidance.contact.sonnen.mail)}
              />
            </p>
            <p>
              <Link
                href={I18n.t(T.customerGuidance.contact.sonnen.web)}
                label={I18n.t(T.customerGuidance.contact.sonnen.web)}
              />
            </p>
            <hr className={'c-guide-contact__separator'} />
            {I18n.t(T.customerGuidance.contact.sonnen.address1)}
            <br />
            {I18n.t(T.customerGuidance.contact.sonnen.address2)}
            <hr className={'c-guide-contact__separator'} />
            <p className={'c-guide-contact__headline'}>
              {I18n.t(T.customerGuidance.contact.sonnen.openingHours)}
            </p>
            <p>
              <span className={'c-guide-contact__day'}>
                {I18n.t(T.customerGuidance.contact.sonnen.mondayFriday)}
              </span>
              {I18n.t(T.customerGuidance.contact.sonnen.mondayFridayHours)}
            </p>
            <p>
              <span className={'c-guide-contact__day'}>
                {I18n.t(T.customerGuidance.contact.sonnen.saturday)}
              </span>
              {I18n.t(T.customerGuidance.contact.sonnen.saturdayHours)}
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
);

export const GuideContact = withRouter(connect(mapStateToProps)(GuideContactComponent));
