import { CountryFeatureName } from '+config/countryFlags';
import { DocumentsActions } from '+documents/store/documents.actions';
import { CountryCode, useCountryFeature, useFeature } from '@sonnen/shared-web';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { isAuthenticated } from '+app/+auth/store/auth.selectors';
import { getBatterySystemData } from '+app/+dashboard/+battery/store';
import { ContractActions } from '+app/+dashboard/+contract/store';
import { CONFIG } from '+config';
import { FeatureName } from '+config/featureFlags';
import { getLeadCollection } from '+guide/store/+guide.sagas';
import { APP_ACTIONS } from '+legacy/core/app/app.actions';
import { shouldRecreate } from '+mobile/mobile.integration';
import { ProductMigrationActions } from '+product-migration/store/productMigration.actions';
import { combineSagas } from '+utils/saga';
import { NotificationActions } from '../notification/notification.actions';
import { SystemNotificationType } from '../notification/types/systemNotification';
import { getSites } from '../site/site.sagas';
import { getUser } from '../user/user.sagas';
import { isUserCountry } from '../user/user.selectors';
import { SYNC_ACTIONS, SyncActions } from './sync.actions';

const guideInCustomerPortalFeature = useFeature(FeatureName.GUIDE_PAGES_IN_CP);

export const syncSagas = combineSagas(
  takeEvery(APP_ACTIONS.SET_IS_READY, isReady),
  takeLatest(SYNC_ACTIONS.SYNC_START, syncData),
);

function* syncData(): SagaIterator {
  yield call(getUser);
  yield call(getSites);

  const isGermanUser = yield select(isUserCountry(CountryCode.DE));
  const checkSystemDocuments = useCountryFeature(CountryFeatureName.SYSTEM_DOCUMENTS).isEnabled;

  if (guideInCustomerPortalFeature.isEnabled && isGermanUser) {
    yield call(getLeadCollection);
  }

  if (checkSystemDocuments) {
    yield put(DocumentsActions.fetchOpportunities());
  }

  if (CONFIG.IS_MOBILE) {
    yield call(getBatterySystemData);
  }

  yield put(ContractActions.getContractData());
  yield put(NotificationActions.getSystemNotifications(SystemNotificationType.COMPONENT));
  yield put(ProductMigrationActions.getActiveCampaigns());
  yield put(SyncActions.syncEnd());
}

function* isReady(): SagaIterator {
  const isLoggedIn = yield select(isAuthenticated);
  return (isLoggedIn && !shouldRecreate())
    ? yield put(SyncActions.syncStart())
    : yield put(SyncActions.syncEnd());
}
