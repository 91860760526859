import { Data, MergedData, Relationship } from '@coolio/json-api';

export type ProfileTag =
  | 'autarkist'
  | 'sunrider'
  | 'energysaver'
  | 'worldchanger'
  | 'energypioneer'
  | 'energysharer'
  ;

export enum ProfilePrivacy {
  BASIC = 'basic',
  FULL = 'full',
}

export interface ProfileRequestImage {
  encoded_image: string | null;
  image_format: 'png' | 'jpg';
}

export type Product =
  | 'batteries'
  | 'readings'
  | 'chargers'
  | 'contracts'
  | 'smartmeters';

export interface ProfileAddress {
  city: string;
  postalCode: string;
  country: string;
}

export interface ProfileFormAttributes {
  nickname?: string;
  description?: string;
  image?: string | null | ProfileRequestImage;
  tags?: ProfileTag[];
  privacy?: ProfilePrivacy;
}
export interface ProfileAttributes extends ProfileFormAttributes {
  address: ProfileAddress;
  products: Product[];
}

export type ProfileError = string | Record<string, any> | undefined;

export type ProfileRelationships = {
  user?: Relationship,
};

export type ProfileData = Data<ProfileAttributes, ProfileRelationships>;
export type Profile = MergedData<ProfileData>;

export enum ProfileFormFields {
  NICKNAME = 'nickname',
  DESCRIPTION = 'description',
  IMAGE = 'image',
}

export interface ProfileFormErrors {
  [ProfileFormFields.NICKNAME]: string | undefined;
  [ProfileFormFields.DESCRIPTION]: string | undefined;
}
