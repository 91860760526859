import { AuthActions } from '+app/+auth/store/auth.actions';
import { AnalysisActions } from '+app/+dashboard/+analysis/store/analysis.actions';
import { BatteryActions } from '+app/+dashboard/+battery/store';
import { BatteryAssetStatus } from '+app/+dashboard/+battery/store/types/battery.interface';
import { ContractActions } from '+app/+dashboard/+contract/store';
import { DocumentsActions } from '+documents/store/documents.actions';
import { MeterActions } from '+shared/store/meter/meter.actions';
import { combineSagas } from '+utils/saga/combineSagas.util';
import { dataGuard } from '+utils/saga/dataGuard.util';
import { processQuery } from '+utils/saga/processQuery.util';
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { LiveActions } from '../live/live.actions';
import { SyncActions } from '../sync/sync.actions';
import { getCurrentUserId } from '../user/user.selectors';
import { SITE_ACTIONS, SiteActions } from './site.actions';
import { SiteHelper } from './site.helpers';
import { SiteRepository } from './site.repository';
import { getAllSites, isOldSitesDataStructure } from './site.selectors';
import { GET_SITES_QUERY } from './site.state';
import { Site, SiteOption } from './types/site.interface';

export function* getSites(): SagaIterator {
  const allSites: Site[] | undefined = yield select(getAllSites);
  const userId: string | undefined = yield select(getCurrentUserId);
  const needMigrateSites = yield select(isOldSitesDataStructure);

  if (!userId || SiteHelper.hasSites(allSites) && !needMigrateSites) { return; }

  yield processQuery(
    GET_SITES_QUERY,
    SiteRepository.getSites,
    {
      onSuccess: res => all([
        call(() => saveSites(res?.data, res?.included)),
      ]),
    },
  )(userId);
}

function* saveSites(allSites: Site[] | undefined, batteryList: BatteryAssetStatus[] | undefined): SagaIterator {
  if (!allSites) { return; }

  const onlySiteOptionsToCheck = [SiteOption.BATTERIES, SiteOption.READINGS];
  const availableSites =
    SiteHelper.filterOutSitesWithOnlyDiscardedBatteries(allSites, batteryList, onlySiteOptionsToCheck);

  yield dataGuard(SiteActions.setSites)(availableSites);
  yield dataGuard(SiteActions.setActiveSite)(availableSites?.length ? availableSites[0].id : undefined);
}

export function* switchSite({ siteId }: AnyAction): SagaIterator {
  yield put(SiteActions.setActiveSite(siteId));
  yield call(clearSiteAssets);
  yield put(SyncActions.syncStart());
}

function* clearSiteAssets(): SagaIterator {
  yield all([
    put(LiveActions.clearLiveData()),
    put(AnalysisActions.clearAnalysis()),
    put(BatteryActions.clearSiteBatteryState()),
    put(ContractActions.clearContract()),
    put(AuthActions.clearReverseChannelToken()),
    put(MeterActions.clearMeterData()),
    put(DocumentsActions.clearInvoicesState()),
  ]);
}

export const siteSagas = combineSagas(
  takeEvery(SITE_ACTIONS.GET_SITES, getSites),
  takeEvery(SITE_ACTIONS.SWITCH_SITE, switchSite),
);
