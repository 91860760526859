import { Accordion, DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';

import './AccordionList.component.scss';

export type AccordionListItemProps = {
  title: string;
  content: string;
  onToggle?: () => void;
  isOpen?: boolean;
};

interface Props {
  list: AccordionListItemProps[];
}

export const AccordionList: React.FC<Props> = ({
  list,
}) => (
  <ul className={'c-accordion-list'}>
    {list.map(({ title, content, onToggle, isOpen}, i) => (
      <li key={title}>
        <Accordion
          isThemeNoTopBorder={i === 0}
          isThemeNoBottomBorder={i === list.length - 1}
          header={(
            <p className={'c-accordion-list__title'}>
              {title}
            </p>
          )}
          onToggle={onToggle}
          isOpen={isOpen}
        >
          <DefaultParagraph>
            <div className={'c-accordion-list__text'}>
              {content}
            </div>
          </DefaultParagraph>
        </Accordion>
      </li>
    ))}
  </ul>
);
