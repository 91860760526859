import { EnergyUnit, FormatNumberOptions, NumberUnit, OtherUnit } from '@sonnen/shared-web';
import { isNil, isString } from 'lodash';
import numbro from 'numbro';

export const roundToNextMultipleOfFive = (val: number) => Math.ceil( val / 5) * 5;

export const isValidNumber = (value?: number | string | null) => {
  if (isString(value)) {
    value = numbro.unformat(value);
  }
  return !isNil(value) && !Number.isNaN(+value) && value >= 0;
};

const formatNumberValue = (
  precision: number,
  numberUnit: NumberUnit,
  fixed: boolean,
) => (value: number) => {
  const valueInUnit = value * numberUnit;

  return numbro(valueInUnit).format({
    thousandSeparated: true,
    mantissa: precision,
    trimMantissa: !fixed,
  }).replace(/ +/g, '\u00A0');
};

const formatStringValue = (
  precision: number,
  numberUnit: NumberUnit,
  fixed: boolean,
) => (value: string) => {
  const numberValue = numbro.unformat(value.replace(/\u00A0/g, ' '));

  if (isValidNumber(value)) {
    const valueInUnit = numberValue * numberUnit;

    return numbro(valueInUnit).format({
      thousandSeparated: true,
      mantissa: precision,
      trimMantissa: !fixed,
    }).replace(/ +/g, '\u00A0');
  }
  return '-';
};

export const formatNumber = ({
  precision = 1,
  numberUnit = NumberUnit.ONE,
  fixed = true,
}: FormatNumberOptions = {}) => (value: string | number) => {
    if (typeof value === 'number') {
      return formatNumberValue(precision, numberUnit, fixed)(value);
    }

    return formatStringValue(precision, numberUnit, fixed)(value);
  };

export const formatNumberWithUnit = (
  precision: number,
  unit: EnergyUnit | OtherUnit,
  numberUnit?: NumberUnit,
) => (value: number) => `${formatNumber({ precision, numberUnit })(value)} ${unit}`;

export const formatNumberRangeWithUnit = (unit: EnergyUnit | OtherUnit) => (value: [number, number]) =>
  `${formatNumber({ precision: 3 })(value[0])} - ${formatNumber({ precision: 3 })(value[1])} ${unit}`;
