import { Query } from '../query/query.state';
import { BatteryRegistrationAttributes } from './types/batteryRegistration.interface';
import { CustomerNoRegistrationAttributes } from './types/customerNoRegistration.interface';
import { RegistrationResponseAttributes } from './types/registrationResponse.interface';
import { ResetPasswordAttributes } from './types/resetPassword.interface';
import { User, UserAttributes } from './types/user.interface';
import { OnboardingState } from './user.actions';

export const GET_USER_QUERY = 'getUserQuery';
export const REGISTER_BY_EMAIL_QUERY = 'registerByEmail';
export const REQUEST_PASSWORD_RESET_QUERY = 'requestPasswordResetQuery';
export const RESET_PASSWORD_QUERY = 'resetPasswordQuery';
export const REGISTER_BY_BATTERY_DATA_QUERY = 'registerByBatteryDataQuery';
export const REGISTER_BY_CUSTOMER_NO_QUERY = 'registerByCustomerNoQuery';

export interface UserState {
  token?: string;
  // TODO: why do we have user and personalDetails seperately?
  // this information is doubled, think about unifying this logic
  currentUser?: User;
  personalDetails: {
    firstName: string;
    lastName: string;
    email: string;
  };
  onboarding: {
    state: string;
    email?: string;
  };
  isPasswordSetupSuccessful: boolean;
  [GET_USER_QUERY]: Query<UserAttributes>;
  [REGISTER_BY_EMAIL_QUERY]: Query<RegistrationResponseAttributes>;
  [REQUEST_PASSWORD_RESET_QUERY]: Query<{}>;
  [RESET_PASSWORD_QUERY]: Query<ResetPasswordAttributes>;
  [REGISTER_BY_BATTERY_DATA_QUERY]: Query<BatteryRegistrationAttributes>;
  [REGISTER_BY_CUSTOMER_NO_QUERY]: Query<CustomerNoRegistrationAttributes>;
}

export const initialState: UserState = {
  token: '',
  personalDetails: {
    firstName: '',
    lastName: '',
    email: '',
  },
  onboarding: {
    state: OnboardingState.PERSONAL_DETAILS,
  },
  isPasswordSetupSuccessful: false,
  [GET_USER_QUERY]: {},
  [REGISTER_BY_EMAIL_QUERY]: {},
  [REQUEST_PASSWORD_RESET_QUERY]: {},
  [RESET_PASSWORD_QUERY]: {},
  [REGISTER_BY_BATTERY_DATA_QUERY]: {},
  [REGISTER_BY_CUSTOMER_NO_QUERY]: {},
};
