import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { formatSiteAddress } from '+legacy/helpers/strings';
import { NestedNav } from '../NestedNav';
import { MobileSideNavNestedSitesList, MobileSideNavNestedSitesListProps } from './MobileSideNavNestedSitesList';

interface MobileSideNavNestedSitesProps extends MobileSideNavNestedSitesListProps {}

export const MobileSideNavNestedSites: React.FC<MobileSideNavNestedSitesProps> = ({
  activeSite,
  allSites,
  setActiveSite,
}) => (
  <NestedNav
    icon={'House'}
    title={activeSite ? formatSiteAddress(activeSite) : I18n.t(T.general.navigation.settings)}
    slides={[
      {
        icon: 'House',
        title: I18n.t(T.general.navigation.sites),
        content: <MobileSideNavNestedSitesList
          activeSite={activeSite}
          allSites={allSites}
          setActiveSite={setActiveSite}
        />,
      },
    ]}
  />
);
