export enum GuidePhaseStatus {
  PREVIEW = 'preview',
  ACTIVE = 'active',
  DONE = 'done',
}

export const GuidePhaseNames = {
  DISCOVERY: 'discovery',
  PURCHASE: 'purchase',
  SETUP: 'setup',
  ENJOY: 'enjoy',
};

export interface GuidePhaseChildren {
  preview?: React.ReactNode;
  active?: React.ReactNode;
  done?: React.ReactNode;
}
