import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { InfographicCardConnectionStatusType } from '../../containers';
import { InfographicValue } from '../InfographicValue';
import { InfographicBatteryCardStatusType } from './InfographicBatteryCard.helpers';

type Props = {
  batteryStatus: InfographicCardConnectionStatusType | InfographicBatteryCardStatusType,
  batteryUSOC: number,
};

export const InfographicBatteryCardValue: React.FC<Props> = ({
  batteryStatus,
  batteryUSOC,
}) => {
  const isOffline = batteryStatus === InfographicCardConnectionStatusType.OFFLINE;
  const isNoDataError = batteryStatus === InfographicCardConnectionStatusType.DATA_MISSING;
  const chargeLevel = (batteryStatus === InfographicCardConnectionStatusType.OK
    || batteryStatus === InfographicBatteryCardStatusType.DELAYED)
      ? batteryUSOC
      : '-';

  return (
    <>
      {isOffline ? (
        <div className={'c-infographic-battery-card__text'}>
          <DefaultParagraph>
            {I18n.t(T.dashboard.infographic.battery.offlineMessage)}
          </DefaultParagraph>
        </div>
      ) : (
        <InfographicValue
          title={I18n.t(T.dashboard.infographic.battery.headline)}
          icon={'SonnenBattery'}
          value={chargeLevel}
          unit={isNoDataError ? '' : '%'}
          isThemeInactive={isNoDataError}
        />
      )}
    </>
  );
};
