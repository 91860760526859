import { Icon } from '@sonnen/shared-web';
import * as React from 'react';

import { Route } from '+app/router/router.types';
import {
  HeaderDropdown,
  HeaderNav,
  UserBadge,
} from '+components';

export interface DashboardUserDetailsPersonalData {
  name: string;
  email: string;
  customerNumber: string;
}

interface Props {
  details: DashboardUserDetailsPersonalData;
  routes: Route[];
}

export const DashboardUserDetails: React.FC<Props> = ({
  details,
  routes,
}) => (
  <HeaderDropdown
    dataTestId={'user-details-dropdown'}
    label={details.name}
    icon={<Icon.Account />}
    hasArrow={true}
  >
    <UserBadge
      title={details.name}
      subtitle={details.email}
      customerNumber={details && details.customerNumber}
    />
    <HeaderNav
      routes={routes || []}
    />
  </HeaderDropdown>
);
