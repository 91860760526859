import { useCountryFeature } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';

import { getRouterLocationPath } from '+app/router/store/router.selectors';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { isImpersonated } from '+auth/store/auth.selectors';
import {
  getBatteryInstallationDate,
  isBackupDeviceInstalled,
  isEatonBattery,
  isEmergencyModeActive,
} from '+battery/store/battery.selectors';
import { CountryFeatureName } from '+config/countryFlags';
import { FeatureName } from '+config/featureFlags';
import { getSiteLiveDataQueryConsecutiveErrorCount, isSiteLiveDataFailover } from '+shared/store/live/live.selectors';
import { NotificationActions } from '+shared/store/notification/notification.actions';
import {
  createAnalysisApiErrorNotification,
  createBatteryApiErrorNotification,
  createBatteryDataMissingNotification,
  createBatteryInitializationNotification,
  createBatteryOfflineNotification,
  createContractApiErrorNotification,
  createEmergencyModeOnNotification,
  createFailoverNotification,
  createImpersonateNotification,
  createLiveStateApiErrorNotification,
  createSitesApiErrorNotification,
} from '+shared/store/notification/notification.factory';
import { NotificationHelper } from '+shared/store/notification/notification.helper';
import {
  getNotifications,
  isAnalysisMeasurementsApiErrorNotification,
  isAnalysisStatisticsApiErrorNotification,
  isBatteryApiErrorNotification,
  isBatteryDataMissingNotification,
  isBatteryOfflineNotification,
  isContractDataApiErrorNotification,
  isContractStatisticsApiErrorNotification,
} from '+shared/store/notification/notification.selectors';
import { Notification } from '+shared/store/notification/types/notification.interface';
import { isOldSitesDataStructure } from '+shared/store/site/site.selectors';
import { NotificationContext } from './NotificationContext';

export interface ConditionsProps {
  batteryInstallationDate: string;
  isAnalysisMeasurementsApiErrorNotification: boolean;
  isAnalysisStatisticsApiErrorNotification: boolean;
  isBatteryApiErrorNotification: boolean;
  isBatteryDataMissingNotification: boolean;
  isBatteryOfflineNotification: boolean;
  isBetaPhase: boolean;
  isContractDataApiErrorNotification: boolean;
  isContractStatisticsApiErrorNotification: boolean;
  isEmergencyModeActive: boolean;
  isImpersonated: boolean;
  isSitesApiErrorNotification: boolean;
  isLiveDataFailover: boolean;
  liveDataErrorCount: number;
}

const mapStateToProps = (state: StoreState) => ({
  activeNotifications: getNotifications(state),
  currentPath: getRouterLocationPath(state),
  conditionsProps: {
    batteryInstallationDate: getBatteryInstallationDate(state),
    isAnalysisMeasurementsApiErrorNotification: isAnalysisMeasurementsApiErrorNotification(state),
    isAnalysisStatisticsApiErrorNotification: isAnalysisStatisticsApiErrorNotification(state),
    isContractDataApiErrorNotification: isContractDataApiErrorNotification(state),
    isContractStatisticsApiErrorNotification: isContractStatisticsApiErrorNotification(state),
    isEmergencyModeActive: isBackupDeviceInstalled(state) && isEmergencyModeActive(state)
      && useCountryFeature(CountryFeatureName.EMERGENCY_MODE).isEnabled,
    isImpersonated: isImpersonated(state),
    isBetaPhase: isEatonBattery(state),
    isBatteryApiErrorNotification: isBatteryApiErrorNotification(state),
    isBatteryDataMissingNotification: isBatteryDataMissingNotification(state),
    isBatteryOfflineNotification: isBatteryOfflineNotification(state),
    isSitesApiErrorNotification: isOldSitesDataStructure(state),
    isLiveDataFailover: isSiteLiveDataFailover(state),
    liveDataErrorCount: getSiteLiveDataQueryConsecutiveErrorCount(state),
  } as ConditionsProps,
});

const mapDispatchToProps = mapActions({
  setNotifications: NotificationActions.setNotifications,
  removeNotification: NotificationActions.removeNotification,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

class NotificationProviderComponent extends React.PureComponent<Props> {
  // @NOTE dynamicNotifications rely on app current state
  private dynamicNotifications: Notification[] = [
    createAnalysisApiErrorNotification.measurements(),
    createAnalysisApiErrorNotification.statistics(),
    createBatteryApiErrorNotification(),
    createBatteryDataMissingNotification(),
    createBatteryInitializationNotification(),
    createBatteryOfflineNotification(),
    createContractApiErrorNotification.data(),
    createContractApiErrorNotification.statistics(),
    createEmergencyModeOnNotification(),
    createFailoverNotification(),
    // TODO use this notification, after notification stypes are unified
    // createGuideGoToActivationBanner(),
    createImpersonateNotification(),
    createSitesApiErrorNotification(),
    createLiveStateApiErrorNotification(),
  ];

  componentDidMount() {
    this.checkAndSetActiveNotifications();
  }

  componentDidUpdate({
    conditionsProps: prevConditionsProps,
    activeNotifications: prevActiveNotifications,
  }: Props) {
    const {
      conditionsProps,
      activeNotifications,
    } = this.props;
    const changedNotifications =
      NotificationHelper.hasChangedNotifications(activeNotifications, prevActiveNotifications);
    const changedConditions = NotificationHelper.hasChangedConditions(conditionsProps, prevConditionsProps);

    if (changedNotifications) {
      this.checkAndSetActiveNotifications();
    }

    if (changedConditions) {
      this.checkAndSetActiveNotifications();
    }
  }

  checkAndSetActiveNotifications() {
    const {
      activeNotifications,
      conditionsProps,
      actions: { setNotifications },
    } = this.props;

    setNotifications(this.getActiveNotifications(activeNotifications, conditionsProps));
  }

  getActiveNotifications(notifications: Notification[], props: ConditionsProps) {
    const allNotifications = NotificationHelper.mergeNotificationsLists(
      this.dynamicNotifications,
      notifications,
    );

    return allNotifications.reduce<Notification[]>((res, notification) =>
      !notification.conditionFunction || notification.conditionFunction(props)
        ? [...res, notification]
        : res,
      []);
  }

  render() {
    const {
      activeNotifications,
      currentPath,
      children,
    } = this.props;

    return (
      <NotificationContext.Provider value={{
        notifications: NotificationHelper.getVisibleNotifications(activeNotifications, currentPath),
      }}>
        {children}
      </NotificationContext.Provider>
    );
  }
}

export const NotificationProvider = connect(mapStateToProps, mapDispatchToProps)(NotificationProviderComponent);
