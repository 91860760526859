import { isMobileNavOpen } from '+app/shared/store/layout/layout.selectors';
import { createSelector } from 'reselect';
import { AppState } from './app.state';

export const getAppState = (store: any): AppState => store.app;

export const isPullToRefreshEnabled = createSelector(
  getAppState,
  state => state.isPullToRefreshEnabled && !isMobileNavOpen,
);

export const isModalOpen = createSelector(
  getAppState,
  app => !!app.modalsOpen.length,
);

export const isScrollDisabled = createSelector(
  isModalOpen,
  isMobileNavOpen,
  (isModalOpen, isMobileNavOpen) => isModalOpen || isMobileNavOpen,
);

export const isOnline = createSelector(
  getAppState,
  state => state.isOnline,
);

export const isResetPasswordSuccessful = createSelector(
  getAppState,
  state => state.isResetPasswordSuccess,
);

export const isHelpAndSupportSubmitSuccessful = createSelector(
  getAppState,
  state => state.isHelpAndSupportSubmitSuccess,
);

export const isResendSuccessful = createSelector(
  getAppState,
  state => state.resendSuccess,
);

export const isForgotPasswordSuccessful = createSelector(
  getAppState,
  state => state.isForgotPasswordSuccess,
);

export const getForgotPasswordStatus = createSelector(
  getAppState,
  state => state.forgotPasswordStatus,
);
