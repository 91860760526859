import { useJsonApi } from '+app/shared/network';
import { CONFIG } from '+config';
import { SystemNotificationAttributes, SystemNotificationData, SystemNotificationsData, SystemNotificationType } from './types/systemNotification';

const getSystemNotifications = (notificationType: SystemNotificationType) =>
  useJsonApi()
    .getList<SystemNotificationsData>(`${CONFIG.API.USER_DATA}/notifications`)
    .filter('type', notificationType)
    .filter('read', false) // NOTE: Remove that if read notifications were also needed
    .send()
    .then(res => res && res.elements);

const updateSystemNotification = (
  { id, attributes }:
  { id: string, attributes: Pick<SystemNotificationAttributes, 'read'> },
) =>
  useJsonApi().put<SystemNotificationData>(`${CONFIG.API.USER_DATA}/notifications/${id}`)
    .ofType('notifications')
    .withAttributes(attributes)
    .send()
    .then(res => res);

export const NotificationsRepository = {
  getSystemNotifications,
  updateSystemNotification,
};
