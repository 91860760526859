import { T } from '@sonnen/shared-i18n/customer';
import { PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { CheckList, CheckListItem } from '+shared/components';

import './ContractBenefitList.component.scss';

interface Props {
  benefitsList: string;
  dataTestId?: string;
}

export const ContractBenefitList: React.FC<Props> = ({
  benefitsList,
  dataTestId,
}) => {
  const sonnenflatBenefits = Object.values(I18n.t(benefitsList));

  return (
    <div className={'c-contract-benefit-list'}>
      <PageSubheadline mediumGap={true}>
        <Translate value={T.yourFlat.sideSection.title} />
      </PageSubheadline>
      <div className={'c-contract-benefit-list__checklist'}>
        <CheckList>
          {sonnenflatBenefits.map(item => (
            <CheckListItem 
              dataTestId={`${dataTestId}-benefits-list-item`} 
              label={item} 
              key={item} 
              />
          ))}
        </CheckList>
      </div>
    </div>
  );
};
