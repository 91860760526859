import { TimeHelper } from '@sonnen/shared-web';
import {
  batteryNeutral,
  EnergyFlowState,
  EnergyFlowStateNames,
  gridNeutral,
  houseNeutral,
  InputProps,
  pvNeutral,
  STATE_LIST,
} from './state-map';

import { isDateInRange } from '+legacy/helpers/dates';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';

// TODO check if both functions can be removed and written in place of use. Both have very limited usage.
const shouldShowLastMeasurement = (timestamp: number | string | undefined) =>
  !!timestamp && isDateInRange(timestamp, 120 * 1000);

const isLiveDataDelayed = (latestLiveDate: string | undefined, delay: number = 60000) =>
    latestLiveDate && TimeHelper.isDelayed(new Date(latestLiveDate), delay);

const DEFAULT_STATE: EnergyFlowState = {
  name: EnergyFlowStateNames.EMPTY,
  conditions: [
    pvNeutral,
    houseNeutral,
    batteryNeutral,
    gridNeutral,
  ],
  ui: {},
};

const findStateFromValue = (input: InputProps): EnergyFlowState => STATE_LIST.find(state =>
    state.conditions.reduce<boolean>((acc, next) => acc && next(input), true),
  ) || DEFAULT_STATE;

const getSiteLiveDataWithDefaultValues = (live: SiteLive) => ({
  ...live,
  consumptionPowerKw: live.consumptionPowerKw || 0,
  productionPowerKw: live.productionPowerKw || 0,
  batteryDischargingKw: live.batteryDischargingKw || 0,
  batteryChargingKw: live.batteryChargingKw || 0,
  gridPurchaseKw: live.gridPurchaseKw || 0,
  gridFeedinKw: live.gridFeedinKw || 0,
});

const getBatteryValue = (live: SiteLive) => {
  const l = getSiteLiveDataWithDefaultValues(live);

  if (l.batteryCharging > l.batteryDischarging) {
    return (-1 * l.batteryCharging);
  }
  if (l.batteryDischarging > l.batteryCharging) {
    return l.batteryDischarging;
  }
  return 0;
};

const getGridValue = (live: SiteLive) => {
  const l = getSiteLiveDataWithDefaultValues(live);

  if (l.gridFeedin > l.gridPurchase) {
    return l.gridFeedin;
  }
  if (l.gridPurchase > l.gridFeedin) {
    return (-1 * l.gridPurchase);
  }
  return 0;
};

export const LiveStateHelper = {
  shouldShowLastMeasurement,
  isLiveDataDelayed,
  findStateFromValue,
  getBatteryValue,
  getGridValue,
};
