export const worldMapStyles = {
  sliced: {
    weight: 0.75,
    fill: true,
    fillColor: '#f8f8f8',
    fillOpacity: 1,
    stroke: true,
    color: '#e4e5e8',
  },
};

export const batteryStyles = {
  sliced: (properties, zoom) => ({
    radius: zoom / 4,
    stroke: false,
    fill: true,
    fillColor: '#74D0E6',
    fillOpacity: 1,
  }),
};
