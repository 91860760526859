import * as React from 'react';

import { PlatformSelector } from '+components';
import { AnalysisEnergyFlowMobile } from './AnalysisEnergyFlow.mobile.component';
import { AnalysisEnergyFlowWeb } from './AnalysisEnergyFlow.web.component';

export const AnalysisEnergyFlow: React.FC = () => (
  <>
    <PlatformSelector
      web={<AnalysisEnergyFlowWeb />}
      mobile={<AnalysisEnergyFlowMobile />}
    />
  </>
);
