import { T } from '@sonnen/shared-i18n/customer';
import {
  AccessoriesChartSeries,
  AccessoriesSeriesKey,
  BarChartSeries,
  EnergyFlowSeriesKey,
  PieChartSeries,
  Point,
  StatisticsSeriesKey,
  TimeHelper,
  useFeature,
} from '@sonnen/shared-web';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { BATTERY_ACTIONS, BatteryActions } from '+battery/store';
import { BatteryHelper } from '+battery/store/battery.helpers';
import { authorizeReverseChannel } from '+battery/store/battery.sagas';
import {
  getBatteryCellCareHistory,
  getBatteryCountryCode,
  getBatteryData,
  getBatteryTimezone,
  isBackupDeviceInstalled,
  isEatonBattery,
} from '+battery/store/battery.selectors';
import { BatteryStatusHistory } from '+battery/store/types';
import { FeatureName } from '+config/featureFlags';
import { QueryActions } from '+query/query.actions';
import { SummerTimeChange, SummerTimeHelper } from '+shared/helpers/summerTime.helper';
import { getSiteLiveData } from '+shared/store/live/live.selectors';
import { MeasurementMethod, SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { SiteHelper } from '+shared/store/site/site.helpers';
import { getActiveSite, getActiveSiteId } from '+shared/store/site/site.selectors';
import { SiteOption } from '+shared/store/site/types/site.interface';
import { getUserCountryCode } from '+shared/store/user/user.selectors';
import { DateUtil } from '+utils/date.util';
import { saveDataAsFile } from '+utils/file.util';
import { Reporter } from '+utils/reporter.util';
import { combineSagas, dataGuard, processQuery } from '+utils/saga';
import { ANALYSIS_ACTIONS, AnalysisActions } from './analysis.actions';
import {
  aggregateMeasurement,
  areLiveBubblesDisabled,
  createTransform,
  DataSeries,
  factorizeBarChartSeries,
  getDirectUsagePower,
  getFactorizedDataSeriesKeys,
  getLastDataPoint,
  getLiveSeriesPoint,
  getMatchingCacheData,
  hasHeaterPowerMeasurements,
  hasMeasurements,
  mapBatteryCareToAreaChartSeries,
  mapStatisticsToDataSeries,
  prepareBackupBoxSeries,
  transformForecastData,
  updateForecastSeries,
} from './analysis.helpers';
import { AnalysisRepository } from './analysis.repository';
import {
  getAreaChartConsumption,
  getAreaChartProduction,
  getAreaChartSeries,
  getEnergyFlowMeasurementsCache,
  getEventsState,
  getForecastConsumptionState,
  getForecastProductionState,
  getMeasurementsState,
  getPreviousMeasurementMethod,
  getSelectedDataSeriesKeys,
  getSelectedDate,
  getStatisticsCache,
  getStatisticsSelectedDate,
  getStatisticsState,
  getSummerTimeChange,
} from './analysis.selectors';
import {
  DOWNLOAD_MEASUREMENTS_CSV_QUERY,
  DOWNLOAD_STATISTICS_CSV_QUERY,
  GET_EVENTS_QUERY,
  GET_FORECAST_CONSUMPTION_QUERY,
  GET_FORECAST_PRODUCTION_QUERY,
  GET_MEASUREMENTS_QUERY,
  GET_STATISTICS_QUERY,
} from './analysis.state';
import { AreaChartSeries } from './types/dataSeries.interface';
import {
  FORECAST_VALUE_CONSUMPTION,
  FORECAST_VALUE_PRODUCTION,
  SiteForecastConsumptionResponse,
  SiteForecastProductionResponse,
} from './types/forecast.interface';
import { SiteEvent } from './types/siteEvents.interface';
import { SiteMeasurements } from './types/siteMeasurements.interface';
import { StatisticsV2FilterResolution } from './types/statisticsV2.interface';

/**
 * ANALYSIS SAGAS
 */

export const analysisSagas = combineSagas(
  takeLatest(ANALYSIS_ACTIONS.GET_MEASUREMENTS, measurementsWatcher),
  takeLatest(ANALYSIS_ACTIONS.GET_EVENTS, eventsWatcher),
  takeLatest(ANALYSIS_ACTIONS.GET_STATISTICS, statisticsWatcher),
  takeLatest(ANALYSIS_ACTIONS.UPDATE_LIVE_AREA_CHART_SERIES, updateAreaChartSeries),
  takeLatest(ANALYSIS_ACTIONS.SET_MEASUREMENTS, updateEnergyFlowMeasurementsCache),
  takeLatest(ANALYSIS_ACTIONS.SET_MEASUREMENTS, setHeaterPowerSeries),
  takeLatest(ANALYSIS_ACTIONS.GET_FORECAST_PRODUCTION, getForecastProduction),
  takeLatest(ANALYSIS_ACTIONS.GET_FORECAST_CONSUMPTION, getForecastConsumption),
  takeLatest(ANALYSIS_ACTIONS.SET_FORECAST_PRODUCTION, setForecastProductionSeries),
  takeLatest(ANALYSIS_ACTIONS.SET_FORECAST_CONSUMPTION, setForecastConsumptionSeries),
  takeLatest(ANALYSIS_ACTIONS.SET_SELECTED_DATE, setForecastSeries),
  takeLatest(BATTERY_ACTIONS.SET_BATTERY_CELL_CARE_HISTORY, setCurrentDataSeries),
  takeLatest(ANALYSIS_ACTIONS.GET_BATTERY_MEASUREMENTS_CSV, getSiteMeasurementsCSV),
  takeLatest(ANALYSIS_ACTIONS.GET_BATTERY_STATISTICS_CSV, getSiteStatisticsCSV),
);

function * setDataSeriesKeys(): SagaIterator {
  const measurements: SiteMeasurements = yield select(getMeasurementsState);
  const previousDataSeriesKeys = yield select(getSelectedDataSeriesKeys);
  const previousMeasurementMethod: MeasurementMethod | undefined = yield select(getPreviousMeasurementMethod);
  const currentMeasurementMethod = measurements && measurements.measurementMethod;

  const dataSeriesKeys: EnergyFlowSeriesKey[] = getFactorizedDataSeriesKeys(
    previousDataSeriesKeys,
    previousMeasurementMethod,
    currentMeasurementMethod,
  );

  yield put(AnalysisActions.setDataSeriesKeys(dataSeriesKeys));
}

const getForecastStartDate = (liveData: SiteLive | undefined): number => {
  if (liveData && moment(liveData.timestamp).isSame(moment(), 'day')) {
    return moment(liveData.timestamp).unix();
  }
  return moment().unix();
};

function * setForecastProductionSeries({
  forecastProduction,
}: ReturnType<typeof AnalysisActions.setForecastProduction>): SagaIterator {
  if (!forecastProduction) { return; }

  const batteryTimezone: string = yield select(getBatteryTimezone);
  const selectedDate: moment.Moment = yield select(getSelectedDate);
  const liveData: SiteLive | undefined = yield select(getSiteLiveData);
  const isEaton: boolean = yield select(isEatonBattery);
  const productionPower: Point[] = yield select(getAreaChartProduction);
  const lastDataPoint = getLastDataPoint(productionPower);
  const lastLivePoint = getForecastStartDate(liveData);

  const dataSeries = {
    [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: transformForecastData({
      date: selectedDate,
      lastDataPointTimestamp: !isEaton && liveData
        ? lastLivePoint
        : lastDataPoint.x,
      lastDataPointPower: !isEaton && liveData?.productionPower
        ? liveData.productionPower
        : lastDataPoint.y!,
      forecasts: forecastProduction,
      forecastDataKey: FORECAST_VALUE_PRODUCTION,
      batteryTimezone,
    }),
  } as Partial<AreaChartSeries>;

  yield put(AnalysisActions.setAreaChartSeries(dataSeries));
}

function * setForecastConsumptionSeries({
  forecastConsumption,
}: ReturnType<typeof AnalysisActions.setForecastConsumption>): SagaIterator {
  if (!forecastConsumption) { return; }

  const batteryTimezone: string = yield select(getBatteryTimezone);
  const selectedDate: moment.Moment = yield select(getSelectedDate);
  const liveData: SiteLive | undefined = yield select(getSiteLiveData);
  const isEaton: boolean = yield select(isEatonBattery);
  const consumptionPower: Point[] = yield select(getAreaChartConsumption);
  const lastDataPoint = getLastDataPoint(consumptionPower);
  const lastLivePoint = getForecastStartDate(liveData);

  const dataSeries = {
    [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: transformForecastData({
      date: selectedDate,
      lastDataPointTimestamp: !isEaton && liveData
        ? lastLivePoint
        : lastDataPoint.x,
      lastDataPointPower: !isEaton && liveData?.consumptionPower
        ? liveData.consumptionPower
        : lastDataPoint.y!,
      forecasts: forecastConsumption,
      forecastDataKey: FORECAST_VALUE_CONSUMPTION,
      batteryTimezone,
    }),
  } as Partial<AreaChartSeries>;

  yield put(AnalysisActions.setAreaChartSeries(dataSeries));
}

function * setForecastSeries(): SagaIterator {
  const selectedDate = yield select(getSelectedDate);

  yield put(BatteryActions.setBatteryCellCareHistory([]));
  yield put(AnalysisActions.setAreaChartSeries({
    [EnergyFlowSeriesKey.PRODUCTION_POWER]: [],
    [EnergyFlowSeriesKey.CONSUMPTION_POWER]: [],
    [EnergyFlowSeriesKey.BATTERY_USOC]: [],
    [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: [],
    [EnergyFlowSeriesKey.GRID_FEEDIN]: [],
    [EnergyFlowSeriesKey.GRID_PURCHASE]: [],
    batteryCare: [],
  }));

  if (selectedDate.isBefore(moment(), 'day')) {

    yield put(AnalysisActions.setAreaChartSeries({
      [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: [],
      [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: [],
    }));
    return;
  }

  const consumption = yield select(getForecastConsumptionState);
  const production = yield select(getForecastProductionState);

  yield all([
    put(AnalysisActions.setForecastConsumption(consumption)),
    put(AnalysisActions.setForecastProduction(production)),
  ]);
}

function * setCurrentDataSeries(): SagaIterator {
  const selectedDate: moment.Moment = yield select(getSelectedDate);
  const measurements: SiteMeasurements = yield select(getMeasurementsState);
  const batteryCareHistory: BatteryStatusHistory[] = yield select(getBatteryCellCareHistory);
  const isEaton: boolean = !!(yield select(isEatonBattery));
  const summerTimeChange: SummerTimeChange | undefined = yield select(getSummerTimeChange);

  if (!measurements) { return; }

  // TODO check if we could only clear measurements areaChartSeries instead of this saga when future day selected
  const isFutureDate = moment(selectedDate).isAfter(moment(), 'day');

  const transform = isFutureDate
    ? () => []
    : createTransform(selectedDate, measurements, summerTimeChange, isEaton);

  const dataSeries = {
    [EnergyFlowSeriesKey.PRODUCTION_POWER]: transform(measurements.productionPower),
    [EnergyFlowSeriesKey.CONSUMPTION_POWER]: transform(measurements.consumptionPower),
    [EnergyFlowSeriesKey.BATTERY_USOC]: transform(measurements.batteryUsoc),
    [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: transform(measurements.directUsagePower),
    [EnergyFlowSeriesKey.GRID_FEEDIN]: transform(measurements.gridFeedin),
    [EnergyFlowSeriesKey.GRID_PURCHASE]: transform(measurements.gridPurchase),
    batteryCare: mapBatteryCareToAreaChartSeries(batteryCareHistory, selectedDate),
  } as Partial<AreaChartSeries>;

  yield put(AnalysisActions.setAreaChartSeries(dataSeries));
}

function * updateAreaChartSeries(action: AnyAction): SagaIterator {
  const areaChartSeries: AreaChartSeries = yield select(getAreaChartSeries);
  const liveData: SiteLive = action.siteLiveData; // TODO: Add type to action above
  const selectedDate: moment.Moment = yield select(getSelectedDate);
  const isEaton: boolean = !!(yield select(isEatonBattery));
  const batteryTimezone = yield select(getBatteryTimezone);
  const timestamp = DateUtil.getDateInTimezone(liveData.timestamp, batteryTimezone).unix();
  const summerTimeChange: SummerTimeChange | undefined = yield select(getSummerTimeChange);

  if (areLiveBubblesDisabled(areaChartSeries, summerTimeChange)) { return; }

  if (!areaChartSeries
    || isEaton
    || !action
    || !timestamp
    || !selectedDate
    || (selectedDate && !TimeHelper.isToday(selectedDate))
  ) { return; }

  const liveSeriesPointDate = summerTimeChange && (summerTimeChange as SummerTimeChange).isSummerTimeStartDate
    ? moment.unix(timestamp).add(1, 'hour').unix()
    // TODO: Consider time change during time switch from summer to winter
    : timestamp;

  const upToDateAreaChartSeries: AreaChartSeries = {
    ...areaChartSeries,
    [EnergyFlowSeriesKey.CONSUMPTION_POWER]: [
      ...areaChartSeries.consumptionPower,
      getLiveSeriesPoint(
        liveSeriesPointDate,
        liveData.consumptionPower || 0,
      ),
    ],
    [EnergyFlowSeriesKey.PRODUCTION_POWER]: [
      ...areaChartSeries.productionPower,
      getLiveSeriesPoint(
        liveSeriesPointDate,
        liveData.productionPower || 0,
      ),
    ],
    [EnergyFlowSeriesKey.BATTERY_USOC]: [
      ...areaChartSeries.batteryUsoc,
      getLiveSeriesPoint(
        liveSeriesPointDate,
        liveData.batteryUsoc || 0,
      ),
    ],
    [EnergyFlowSeriesKey.GRID_FEEDIN]: [
      ...areaChartSeries.gridFeedin,
      getLiveSeriesPoint(
        liveSeriesPointDate,
        liveData.gridFeedin || 0,
      ),
    ],
    [EnergyFlowSeriesKey.GRID_PURCHASE]: [
      ...areaChartSeries.gridPurchase,
      getLiveSeriesPoint(
        liveSeriesPointDate,
        liveData.gridPurchase || 0,
      ),
    ],
    [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: [
      ...areaChartSeries.directUsagePower,
      getLiveSeriesPoint(
        liveSeriesPointDate,
        getDirectUsagePower(liveData.productionPower || 0, liveData.consumptionPower || 0),
      ),
    ],
    [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: updateForecastSeries({
      forecastSeries: areaChartSeries[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER],
      liveDataTimestamp: liveSeriesPointDate,
      liveDataPower: liveData.consumptionPower,
      batteryTimezone,
    }),
    [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: updateForecastSeries({
      forecastSeries: areaChartSeries[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER],
      liveDataTimestamp: liveSeriesPointDate,
      liveDataPower: liveData.productionPower,
      batteryTimezone,
    }),
  };

  yield put(AnalysisActions.setAreaChartSeries(upToDateAreaChartSeries));
}

function * setBarChartSeries(dataSeries: DataSeries): SagaIterator {
  const statisticsSelectedDate = getStatisticsSelectedDate(yield select());
  const { consumption, production } = dataSeries;

  if (!statisticsSelectedDate) {
    return;
  }

  const barChartDataSeries: BarChartSeries = {
    [StatisticsSeriesKey.PRODUCED_ENERGY]: factorizeBarChartSeries(production, statisticsSelectedDate),
    [StatisticsSeriesKey.CONSUMED_ENERGY]: factorizeBarChartSeries(consumption, statisticsSelectedDate),
  };

  yield put(AnalysisActions.setBarChartSeries(barChartDataSeries));
}

function * setPieChartSeries(dataSeries: DataSeries): SagaIterator {
  const { consumption, production, gridPurchase, gridFeedin } = dataSeries;

  const pieChartDataSeries: PieChartSeries = {
    autonomy: {
      consumedEnergy: (aggregateMeasurement(consumption) - aggregateMeasurement(gridPurchase)) / 1000,
      gridPurchaseEnergy: aggregateMeasurement(gridPurchase) / 1000,
    },
    selfConsumption: {
      producedEnergy: (aggregateMeasurement(production) - aggregateMeasurement(gridFeedin)) / 1000,
      gridFeedinEnergy: aggregateMeasurement(gridFeedin) / 1000,
    },
  };

  yield put(AnalysisActions.setPieChartSeries(pieChartDataSeries));
}

/**
 * MEASUREMENTS SAGAS
 */

function * measurementsWatcher(): SagaIterator {
  yield call(getEnergyFlowMeasurements);
  yield call(setDataSeriesKeys);
  yield call(setCurrentDataSeries);
}

function * eventsWatcher(): SagaIterator {
  yield call(getBackupBoxEvents);
  yield call(setBackupBoxSeries);
}

function* getForecastProduction(): SagaIterator {
  if (useFeature(FeatureName.ANALYSIS_FORECAST).isDisabled) { return; }

  const token = yield call(authorizeReverseChannel);

  // TODO add error handling
  if (!token) { return; }

  yield processQuery(
    GET_FORECAST_PRODUCTION_QUERY,
    AnalysisRepository.getSiteForecastProduction,
    {
      onSuccess: (res: SiteForecastProductionResponse | undefined) =>
        dataGuard(AnalysisActions.setForecastProduction)(res && res.data),
    },
  )({ token });
}

function* getForecastConsumption(): SagaIterator {
  if (useFeature(FeatureName.ANALYSIS_FORECAST).isDisabled) { return; }

  const token = yield call(authorizeReverseChannel);

  // TODO add error handling
  if (!token) { return; }

  yield processQuery(
    GET_FORECAST_CONSUMPTION_QUERY,
    AnalysisRepository.getSiteForecastConsumption,
    {
      onSuccess: (res: SiteForecastConsumptionResponse | undefined) =>
        dataGuard(AnalysisActions.setForecastConsumption)(res && res.data),
    },
  )({ token });
}

function * getEnergyFlowMeasurements(): SagaIterator {
  const siteId: string = yield select(getActiveSiteId);
  const selectedDate: moment.Moment = yield select(getSelectedDate);
  const measurementsCache = yield select(getEnergyFlowMeasurementsCache);
  const batteryCountryCode = yield select(getBatteryCountryCode);
  const userCountryCode = yield select(getUserCountryCode);
  const batteryTimezone = yield select(getBatteryTimezone);

  if (selectedDate.isAfter(moment(), 'day')) { return; }

  // TODO: change selectedDate to timezone agnostic
  const timezoneAgnosticDate = selectedDate ? moment(selectedDate).format('YYYY-MM-DD').split('T')[0] : undefined;
  const summerTimeChange: SummerTimeChange =
    SummerTimeHelper.getSummerTimeChange(batteryCountryCode || userCountryCode, timezoneAgnosticDate);

  yield put(AnalysisActions.setSummerTime(summerTimeChange));

  // TODO: use getMatchingCacheData function
  const matchingMeasurement = measurementsCache.find(
    (measure: {start: string}) =>
      DateUtil.getDateInTimezone(measure.start, batteryTimezone)
      .isSame(selectedDate, 'day'),
  );

  if (matchingMeasurement) {
    yield put(AnalysisActions.setMeasurements(matchingMeasurement));
  } else if (siteId && selectedDate) {
    yield processQuery(
      GET_MEASUREMENTS_QUERY,
      AnalysisRepository.getSiteMeasurements,
      {
        onSuccess: res => dataGuard(AnalysisActions.setMeasurements)(res!),
      },
    )({
      id: siteId,
      filters: {
        start: selectedDate.startOf('day').format(),
        end: selectedDate.endOf('day').format(),
      },
    });
  }
  return;
}

/**
 * STATISTICS SAGAS
 */

function * statisticsWatcher(): SagaIterator {
  yield call(getStatistics);
  const statistics = getStatisticsState(yield select());
  let dataSeries: DataSeries;

  try {
    dataSeries = mapStatisticsToDataSeries(statistics);
  } catch (err) {
    return;
  }

  yield call(setBarChartSeries, dataSeries);
  yield call(setPieChartSeries, dataSeries);
  yield call(updateStatisticsCache);
}

function * getStatistics(): SagaIterator {
  const activeSite = getActiveSite(yield select());
  const siteId = activeSite?.id;
  const statisticsSelectedDate = getStatisticsSelectedDate(yield select());
  const statisticsCache = getStatisticsCache(yield select());
  const batteryTimezone = getBatteryTimezone(yield select());

  if (!siteId
    || !statisticsSelectedDate
    || (activeSite && !SiteHelper.hasOption(activeSite!, SiteOption.BATTERIES))
  ) {
    // @TODO add error handling
    return;
  }

  const { period, date, resolution } = (statisticsSelectedDate);
  const { start, end } = BatteryHelper.getDateRangeInBatteryTimezone(date, batteryTimezone, { period });
  const matchingStatistics = getMatchingCacheData(
    statisticsCache,
    date,
    resolution as StatisticsV2FilterResolution, // TODO: remove resolution from store and calculate from dates
  );

  matchingStatistics
    ? yield put(AnalysisActions.setStatistics(matchingStatistics))
    : yield processQuery(
      GET_STATISTICS_QUERY,
      AnalysisRepository.getStatisticsV2,
      {
        onSuccess: res => dataGuard(AnalysisActions.setStatistics)(res!),
      },
    )({
      id: siteId,
      filters: {
        start,
        end,
        // TODO: remove resolution from store and calculate from dates
        resolution: resolution as StatisticsV2FilterResolution,
      },
    });
}

/**
 * EVENTS SAGAS
 */

function * getBackupBoxEvents(): SagaIterator {
  const backupBoxInstalled = yield select(isBackupDeviceInstalled);
  if (!backupBoxInstalled) {
    return;
  }

  const activeSiteId = yield select(getActiveSiteId);
  const startDate = yield select(getSelectedDate);
  const isBatteryData: boolean = !!(yield select(getBatteryData));

  if (!activeSiteId
    || !startDate
    || !isBatteryData
  ) {
    // @TODO add better error handling
    // QueryActions.failure(GET_EVENTS_QUERY, new Error(I18n.t(T.analysis.events.status.error)));
    return;
  }

  yield processQuery(
    GET_EVENTS_QUERY,
    AnalysisRepository.getSiteEvents,
    {
      onSuccess: res => dataGuard(AnalysisActions.setEvents)(res!),
      onFailure: () =>
        put(QueryActions.failure(GET_EVENTS_QUERY, new Error(I18n.t(T.analysis.events.status.error)))),
    },
  )({
    id: activeSiteId,
    date: startDate,
  });
  return;
}

function * setHeaterPowerSeries(): SagaIterator {
  const selectedDate: moment.Moment = yield select(getSelectedDate);
  const measurements: SiteMeasurements = yield select(getMeasurementsState);
  const isEaton: boolean = !!(yield select(isEatonBattery));
  const summerTimeChange: SummerTimeChange | undefined = yield select(getSummerTimeChange);

  if (!hasMeasurements(measurements)) { return; }

  const transform = createTransform(selectedDate, measurements, summerTimeChange, isEaton);

  let heaterPower: Point[] = [];
  let heaterTemperature: Point[] = [];

  if (hasHeaterPowerMeasurements(measurements)) {
    heaterPower = transform(measurements.heaterPower);
    heaterPower = heaterPower
      .map(({ x, y }) => ({
        x,
        y: y || 0,
      }));

    heaterTemperature = transform(measurements.heaterWaterTemp);
  }

  const dataSeries = {
    [AccessoriesSeriesKey.HEATER_POWER]: heaterPower || [],
    [AccessoriesSeriesKey.HEATER_TEMPERATURE]: heaterTemperature || [],
  } as Partial<AccessoriesChartSeries>;

  yield put(AnalysisActions.setHeaterPowerSeries(dataSeries));
}

function * setBackupBoxSeries(): SagaIterator {
  const selectedDate: Date = yield select(getSelectedDate);
  const events: SiteEvent[] = yield select(getEventsState);

  const backupBox = prepareBackupBoxSeries({
    events,
    date: selectedDate,
  });

  const dataSeries = {
    [AccessoriesSeriesKey.BACKUP_BOX]: backupBox || [],
  } as Partial<AccessoriesChartSeries>;

  yield put(AnalysisActions.setBackupBoxSeries(dataSeries));
}

function * updateEnergyFlowMeasurementsCache({ measurements }: AnyAction): SagaIterator {
  const selectedDate: moment.Moment = yield select(getSelectedDate);
  const measurementsCache: SiteMeasurements[] = yield select(getEnergyFlowMeasurementsCache);

  if (TimeHelper.isToday(selectedDate)
    || !measurements
    || measurementsCache.find(cached => cached.id === measurements.id)
  ) {
    return;
  }

  const newCache = [measurements, ...measurementsCache];

  yield put(AnalysisActions.setMeasurementsCache(newCache.slice(0, 10)));
}

function * updateStatisticsCache(): SagaIterator {
  const statistics = getStatisticsState(yield select());
  const statisticsCache = getStatisticsCache(yield select());

  if (!statistics) { return; }

  if (!TimeHelper.isToday(new Date(statistics.startAt))) {
    statisticsCache.unshift(statistics);
  }

  yield put(AnalysisActions.setStatisticsCache(statisticsCache.slice(0, 10)));
}

export function* getSiteMeasurementsCSV(
  { start, end, resolution }: ReturnType<typeof AnalysisActions.getSiteMeasurementsCSV>,
): SagaIterator {
  const siteId = yield select(getActiveSiteId);

  yield processQuery(
    DOWNLOAD_MEASUREMENTS_CSV_QUERY,
    AnalysisRepository.getSiteMeasurementsCSV,
    {
      onSuccess: data => call(saveDataAsFile, data, 'measurements.csv', 'text/csv'),
      onFailure: e => call(Reporter.log, 'Couldn\'t save measurements as csv', {
        error: e,
        filters: { start, end, resolution },
      }),
    },
  )({ id: siteId, filters: { start, end, resolution } });
}

export function* getSiteStatisticsCSV(
  { start, end, resolution }: ReturnType<typeof AnalysisActions.getSiteStatisticsCSV>,
): SagaIterator {
  const siteId = yield select(getActiveSiteId);

  yield processQuery(
    DOWNLOAD_STATISTICS_CSV_QUERY,
    AnalysisRepository.getSiteStatisticsCSV,
    {
      onSuccess: data => call(saveDataAsFile, data, 'statistics.csv', 'text/csv'),
      onFailure: e => call(Reporter.log, 'Couldn\'t save statistics as csv', {
        error: e,
        filters: { start, end, resolution },
      }),
    },
  )({ id: siteId, filters: { start, end, resolution } });
}
