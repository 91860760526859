import { T } from '@sonnen/shared-i18n/customer';
import { Button } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { GraphicBanner } from '+app/shared/components/GraphicBanner';
import migrationBanner from '+images/migration/migration-banner.png';
import { useAnalytics } from '+shared/helpers/analytics';
import { ButtonToEventMap, MigrationBannerButtonKey, MigrationBannerHelper } from './MigrationBanner.helper';
import { useBannerShownGtmEvent } from './MigrationBanner.hooks';
import { MigrationBannerSpec } from './MigrationBannerSpec.types';

import './MigrationBanner.component.scss';

interface Props {
  buttons: MigrationBannerButtonKey[];
  buttonToEventMap?: ButtonToEventMap;
}

export const MigrationBanner: React.FC<Props> = ({ buttons , buttonToEventMap}) => {
  const { sendEvent } = useAnalytics();
  const btns = MigrationBannerHelper.getButtonsWithGtmEvents(sendEvent)(buttons, buttonToEventMap);

  useBannerShownGtmEvent(sendEvent);

  return (
    <GraphicBanner
      imageUrl={migrationBanner}
      isContentOutsideOnLowRes={true}
    >
      <div
        className={'c-migration-banner__content'}
        data-test={MigrationBannerSpec.MIGRATION_BANNER_CONTENT}
      >
        <div className={'c-migration-banner__label'}>
          {I18n.t(T.yourFlat.sonnenFlatXMigration.banner.upperLabel)}
        </div>
        <div className={'c-migration-banner__title'}>
          {I18n.t(T.yourFlat.sonnenFlatXMigration.banner.title)}
        </div>
        <div className={'c-migration-banner__description'}>
          {I18n.t(T.yourFlat.sonnenFlatXMigration.banner.description)}
        </div>
        <div className={'c-migration-banner__description'}>
          {I18n.t(T.yourFlat.sonnenFlatXMigration.banner.additionalDescription)}
        </div>
        {btns && (
          <div className={'c-migration-banner__buttons'}>
            {btns.map(({ label, size, theme, onClick }) => (
              <div
                key={`migration-banner-${label}`}
                className={'c-migration-banner__button'}
                data-test={MigrationBannerSpec.MIGRATION_BANNER_BUTTON}
              >
                <Button
                  label={I18n.t(label)}
                  size={size}
                  theme={theme}
                  isBlock={true}
                  onClick={onClick}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </GraphicBanner>
  );
};
