import { T } from '@sonnen/shared-i18n/customer';
import { Icon, LinkButton } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { StepName } from '+app/+guide/helpers/steps.config';
import { getPath, getPaths } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { history } from '+router/store/index';

import './GuideAcceptanceBackButton.component.scss';

export const GuideAcceptanceBackButton: React.FC = () => {
  const paths = getPaths();

  return (
    <div className={'c-guide-acceptance-back-button'}>
      <LinkButton
        icon={<Icon.Angle />}
        onClick={() => history.push(getPath(RouteName.GUIDE_PURCHASE_STEP, [StepName.ACCEPT_OFFER]))}
      >
        {I18n.t(T.customerGuidance.acceptance.buttonGoBack)}
      </LinkButton>
    </div>
  );
};
