import { T } from '@sonnen/shared-i18n/customer';
import { ExpandableSection } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { ImageTutorial } from '+shared/components';
import { useToggleWithGtmEvent } from '+shared/hooks/useToggleWithGtmEvent';
import { CessionImageTutorialHelper } from './CessionImageTutorial.helper';

import './CessionImageTutorial.component.scss';

export const CessionImageTutorial: React.FC = () => {
  const { isExpanded, toggle } = useToggleWithGtmEvent({
    expandEventData: CessionImageTutorialHelper.expandEventData,
  });

  return (
    <div className={'c-cession-image-tutorial'}>
      <p className={'c-cession-image-tutorial__title'}>
        {I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.headline)}
      </p>
      <ExpandableSection
        buttonLabel={I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.showMore)}
        buttonLabelWhenExpanded={I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.showLess)}
        buttonOnLeftSide={true}
        maxHeight={1000}
        controlledToggle={toggle}
        isExpanded={isExpanded}
      >
        <div className={'c-cession-image-tutorial__content'}>
          <ImageTutorial
            list={CessionImageTutorialHelper.factorizeCessionTutorial()}
          />
        </div>
      </ExpandableSection>
    </div>
  );
};
