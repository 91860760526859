import { T } from '@sonnen/shared-i18n/customer';
import {
  breakpointUp,
  DefaultParagraph,
  Tabs,
  useWindowSize,
} from '@sonnen/shared-web';
import { replace } from 'connected-react-router';
import { isNil } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import { GuideSetupActions } from '+app/+guide/+setup/store';
import {
  getActiveSetupModal,
  getSetupModalState,
  getSetupStepQuery,
} from '+app/+guide/+setup/store/+setup.selectors';
import { GuideActions } from '+app/+guide/store/+guide.actions';
import { getPhaseActiveTab } from '+app/+guide/store/+guide.selectors';
import { QueryStatus } from '+app/shared/store/query/query.state';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { PhaseContext } from '+guide/components';
import { getStepsFromPhase, getTabFromStep, PHASES, TABS } from '+guide/helpers/steps.config';
import { GuideSetupModalConfirmation } from '../GuideSetupModalConfirmation';
import { guideSetupModalContent } from './GuideSetupActive.helper';

import './GuideSetupActive.component.scss';

const mapStateToProps = (state: StoreState) => ({
  phaseActiveTab: getPhaseActiveTab(state),
  isSetupModalOpen: getSetupModalState(state),
  setupStepStatus: getSetupStepQuery(state),
  activeModal: getActiveSetupModal(state),

});

const mapDispatchToProps = mapActions({
  goTo: replace,
  setActiveTab: GuideActions.setPhaseActiveTab,
  closeModal: GuideSetupActions.closeModal,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const GuideSetupActiveComponent: React.FC<Props> = ({
  actions,
  activeModal,
  isSetupModalOpen,
  phaseActiveTab,
  setupStepStatus,
}) => {
  const phaseStepPath = React.useContext(PhaseContext);
  const stepsYourTodo = getStepsFromPhase(PHASES.SETUP, TABS.YOUR_TODO);
  const stepsOurTodo = getStepsFromPhase(PHASES.SETUP, TABS.OUR_TODO);

   // Reload
  React.useEffect(() => {
    const phaseActiveTab = getTabFromStep(window.location.pathname);
    if (!isNil(phaseActiveTab)) {
      actions.setActiveTab(phaseActiveTab);
    }

    return () => {
      actions.setActiveTab(0);
    };
  }, []);

  React.useEffect(() => {
    const phaseActiveTab = getTabFromStep(window.location.pathname);
    if (!isNil(phaseActiveTab)) {
      actions.setActiveTab(phaseActiveTab);
    }
  }, [window.location.pathname]);

  return (
    <>
      <div className={'c-guide-setup-active__subheadline'}>
        <DefaultParagraph>
          {I18n.t(T.customerGuidance.setup.active.description)}
        </DefaultParagraph>
      </div>
      <div className={'c-guide-setup-active__tabs'}>
        <Tabs
          triggers={[
            {
              label: I18n.t(T.customerGuidance.setup.active.tabs.myTodo),
              onClick: () => {
                actions.setActiveTab(0);
                actions.goTo(phaseStepPath(), { preserveScroll: true });
              },
            },
            {
              label: I18n.t(T.customerGuidance.setup.active.tabs.OurTodo),
              onClick: () => {
                actions.setActiveTab(1);
                actions.goTo(phaseStepPath(), { preserveScroll: true });
              },
            },
          ]}
          activeTab={phaseActiveTab}
          isThemeInline={useWindowSize().innerWidth >= breakpointUp('SM') ? true : false}
        >
          <div className={'c-guide-setup-active__tab'}>
            <p className={'c-guide-setup-active__description'}>
              <DefaultParagraph>
                {I18n.t(T.customerGuidance.setup.active.tabs.headlines.myTodo)}
              </DefaultParagraph>
            </p>
            {/* @TODO: Uncomment when we will be getting warranty update. */}
            {/* <p className={'c-guide-setup-active__section-header'}>
              {I18n.t(T.customerGuidance.setup.active.sectionHeadline.automatic)}
            </p>
            <GuideSetupStepWarrantyAcceptance />
            <p className={'c-guide-setup-active__section-header'}>
              {I18n.t(T.customerGuidance.setup.active.sectionHeadline.manual)}
            </p> */}
            {
              Object.keys(stepsYourTodo).map(key => {
                const step = stepsYourTodo[key];
                const StepComponent = step.component;
                return <StepComponent key={`${step.phase}-${step.tab}-${key}`}/>;
              })
            }
          </div>
          <div className={'c-guide-setup-active__tab'}>
            <p className={'c-guide-setup-active__description'}>
              <DefaultParagraph>
                {I18n.t(T.customerGuidance.setup.active.tabs.headlines.ourTodo)}
              </DefaultParagraph>
            </p>
            {
              Object.keys(stepsOurTodo).map(key => {
                const step = stepsOurTodo[key];
                const StepComponent = step.component;
                return <StepComponent key={`${step.phase}-${step.tab}-${key}`}/>;
              })
            }
          </div>
        </Tabs>
        {setupStepStatus && (
          <GuideSetupModalConfirmation
            isOpen={isSetupModalOpen}
            isLoading={setupStepStatus === QueryStatus.PENDING}
            icon={guideSetupModalContent(setupStepStatus, activeModal).icon}
            title={guideSetupModalContent(setupStepStatus, activeModal).title}
            subtitle={guideSetupModalContent(setupStepStatus, activeModal).subtitle}
            buttonLabel={guideSetupModalContent(setupStepStatus, activeModal).buttonLabel}
            onButtonClick={actions.closeModal}
          />
        )}
      </div>
    </>
  );
};

export const GuideSetupActive =
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GuideSetupActiveComponent));
