import { SiteElement } from '+app/shared/store/site/types/site.interface';
import { InfographicProps } from '+dashboard/+infographic';
import { BATTERY_DATA_MOCK } from './battery.mock';
import { CHARGER_DATA_MOCK } from './charger.mock';
import { CONTRACT_FLAT_DATA_MOCK, CONTRACT_FLAT_HOME_MOCK } from './contract.mock';
import { LIVE_DATA_MOCK } from './liveState.mock';
import { QUERY_DATA_MOCK } from './query.mock';

export const INFOGRAPHIC_DATA_MOCK: InfographicProps = {
  siteLiveData: LIVE_DATA_MOCK,
  siteElements: [SiteElement.BATTERY, SiteElement.HEATER],
  siteLiveQuery: QUERY_DATA_MOCK,
  batteryData: BATTERY_DATA_MOCK,
  batteryQuery: QUERY_DATA_MOCK,
  contract: CONTRACT_FLAT_HOME_MOCK,
  contractQuery: QUERY_DATA_MOCK,
  sonnenFlatStatistics: CONTRACT_FLAT_DATA_MOCK,
  contractStatisticsQuery: QUERY_DATA_MOCK,
  chargerData: CHARGER_DATA_MOCK,
  chargerLiveQuery: QUERY_DATA_MOCK,
};
