import classNames from 'classnames';
import { easeLinear } from 'd3-ease';
import { ScaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { curveLinear, line } from 'd3-shape';
import 'd3-transition';
import { equals } from 'ramda';
import * as React from 'react';
import { Y_AXIS_MARGIN } from '../../../frequency.constants';

interface Props {
  className: string;
  clipId: string;
  data: number[];
  xScale: ScaleLinear<number, number>;
  yScale: ScaleLinear<number, number>;
  gradient?: string[];
  reposition: boolean;
}

export class Line extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    gradient: null,
    xScale: null,
    yScale: null,
    reposition: false,
  };

  private groupRef: Element | null = null;

  componentDidMount() {
    const {
      data,
      xScale,
      yScale,
    } = this.props;

    const lineCalc = line<number>()
      .curve(curveLinear)
      .x((_, i) => xScale(i))
      .y(d => yScale(d));

    select(this.groupRef).select('path')
      .attr('d', () => `${lineCalc(data) || ''} l0,0.001`)
      .on('start', () => this.updateGraph());
  }

  componentDidUpdate(prevProps: Props) {
    const { yScale: { domain: oldDomain }, reposition } = prevProps;
    const { yScale: { domain } } = this.props;
    const [, maxValue] = domain();
    const [, oldMaxValue] = oldDomain();

    const domainChanged = reposition || (maxValue !== oldMaxValue);

    this.updateGraph(domainChanged, prevProps.data);
  }

  setGroupRef = (ref: Element | null) => {
    this.groupRef = ref;
  };

  updateGraph = (domainChanged: boolean = false, oldData?: number[]) => {
    const { data, xScale, yScale } = this.props;

    if (equals(data, oldData)) {
      return;
    }

    const lineCalc = line<number>()
      .curve(curveLinear)
      .x((_, i) => xScale(i))
      .y(d => yScale(d));

    const gPath = select(this.groupRef).select('g');
    const path = gPath.select('path');

    gPath
      .transition()
      .duration(0)
      .attr('transform', null);

    path
      .transition()
      .duration(domainChanged ? 500 : 0)
      .attr('d', () => `${lineCalc(oldData || data) || ''} l0,0.001`)
      .transition()
      .duration(0)
      .attr('d', () => `${lineCalc(data) || ''} l0,0.001`);

    gPath
      .transition()
      .duration(1500)
      .ease(easeLinear)
      .attr('transform', `translate(${xScale(-1)},0)`);
  };

  render() {
    const {
      clipId,
      gradient,
      className,
    } = this.props;
    const pathClass = classNames('c-frequency-chart__plot-line', className);
    const gradientId = `grad-${className}`;

    return (
      <g
        ref={this.setGroupRef}
        clipPath={`url(#${clipId})`}
        transform={`translate(0, ${Y_AXIS_MARGIN})`}
      >
        {(gradient) && (
          <defs>
            <linearGradient id={gradientId}>
              <stop
                offset="0%"
                style={{ stopColor: gradient[0], stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: gradient[1], stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
        )}
        <g>
          <path
            className={pathClass}
            stroke={`url(#${gradientId})`}
          />
        </g>
      </g>
    );
  }
}
