import { GoogleTagManagerScript } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { UnregisterCallback } from 'history';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import { StoreState } from '+app/store/store.interface';
import { CONFIG } from '+config';
import { isPullToRefreshEnabled } from '+legacy/core/app/app.selectors';
import { Router } from '+router/Router.component';
import { history } from '+router/store/index';
import {
  AnalyticsTracker,
  PlatformSelector,
  PullToRefresh,
} from '+shared/components';
import { SubscribeNewsChannel } from '+shared/containers/SubscribeNewsChannel';
import { GoogleAnalytics } from '+shared/helpers/analytics';
import { withNetworkCheck } from '+shared/network/network.hoc';
import { LayoutActions } from '+shared/store/layout/layout.actions';
import { SyncActions } from '+shared/store/sync/sync.actions';
import { getCurrentUserId } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';
import { Reporter } from '+utils/reporter.util';
import { ErrorPage } from './+statics';

import './App.component.scss';

const GTM_DATA_LAYER = 'dataLayer';

interface State {
  hasError: boolean;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const mapStateToProps = (state: StoreState) => ({
  userId: getCurrentUserId(state),
  locale: state.i18n.locale,
  isPullToRefreshEnabled: isPullToRefreshEnabled(state),
});

const mapDispatchToProps = mapActions({
  toggleMobileNav: LayoutActions.toggleMobileNav,
  triggerSync: SyncActions.syncStart,
});

class AppComponent extends React.Component<Props, State> {
  readonly state: State = { hasError: false };

  private historyListenerCallback: UnregisterCallback | undefined;

  componentDidMount() {
    const { actions, locale } = this.props;

    Reporter.init();
    moment.locale(locale);

    this.historyListenerCallback = history.listen(() => {
      actions.toggleMobileNav(false);
    });
  }

  componentDidUpdate() {
    // const { userId } = this.props;

    if (CONFIG.GOOGLE_ANALYTICS_TRACKING_ID) {
      GoogleAnalytics.init(CONFIG.GOOGLE_ANALYTICS_TRACKING_ID, {
        // TODO: Enable user tracking when we have final approval for it
        // gaOptions: { userId },
      });
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: !!error });
    Reporter.reportError(error, errorInfo);
  }

  componentWillUnmount() {
    if (this.historyListenerCallback) {
      this.historyListenerCallback();
    }
  }

  render() {
    const { actions, isPullToRefreshEnabled } = this.props;
    const AppContent = this.state.hasError
      ? ErrorPage
      : Router;

    return (
      <div className={classNames({ 'mobile-app': CONFIG.IS_MOBILE })}>
        <GoogleTagManagerScript
          dataLayer={GTM_DATA_LAYER}
          environment={CONFIG.GOOGLE_TAG_MANAGER}
        />
        <AnalyticsTracker dataLayerName={GTM_DATA_LAYER}>
          <SubscribeNewsChannel />
          <PlatformSelector
            web={<AppContent />}
            mobile={(
              <PullToRefresh
                isEnabled={isPullToRefreshEnabled}
                onRefresh={actions.triggerSync}
              >
                <AppContent />
              </PullToRefresh>
            )}
          />
        </AnalyticsTracker>
      </div>
    );
  }
}

export const App = compose(
  withRouter,
  withNetworkCheck,
  connect(mapStateToProps, mapDispatchToProps),
)(AppComponent) as React.ComponentType<any>;
