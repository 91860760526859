import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import { NotificationCategory } from '+shared/store/notification/types/notification.interface';

import './Toast.component.scss';

type ToastSpacing = 'default' | 'high';

interface Props {
  type?: NotificationCategory;
  icon?: React.ReactNode; // TODO: Update to IconType
  button?: {
    label: React.ReactNode,
    onClick?: () => void,
  };
  spacing?: ToastSpacing;
  hasCloseButton?: boolean;
}

export const Toast: React.FC<Props> = ({
  type = NotificationCategory.INFO,
  icon,
  button,
  spacing,
  hasCloseButton,
  children,
}) => {
  const [visible, setVisibility] = React.useState(true);

  return visible ? (
    <div className={classNames('c-toast', `c-toast--${type}`, {
      'c-toast--high': spacing === 'high',
    })}>
      <div className={'c-toast__content'}>
        {icon && (
          <span className={'c-toast__icon'}>
            {icon}
          </span>
        )}
        {children}
      </div>
      {button && (
        <div className={'c-toast__side'}>
          <button
            className={'c-toast__button'}
            onClick={button.onClick}
            type={'button'}
          >
            {button.label}
          </button>
        </div>
      )}
      {hasCloseButton && (
        <div className={'c-toast__side'}>
          <button
            className={'c-toast__button c-toast__button--round'}
            onClick={() => setVisibility(false)}
            type={'button'}
          >
            <Icon.Close className={'c-toast__close-icon'}/>
          </button>
        </div>
      )}
    </div>
  ) : null;
};
