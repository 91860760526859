import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, Icon } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GuideAccordion } from '+app/+guide';
import {
  isFlatOfferAccepted,
  isHardwareOfferAvailable,
} from '+app/+guide/+purchase/store/+purchase.selectors';
import { GuideGridStretched } from '+app/+guide/components';
import { GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StepName } from '+app/+guide/helpers/steps.config';
import {
  getGuideAcceptedOffer,
  getGuideOfferCollection,
  getGuideOfferHardwareOfferCollection,
  getImpactAnalysisList,
} from '+app/+guide/store/+guide.selectors';
import {
  getGuideLeadFullName,
  getLeadStatus,
} from '+app/+guide/store/+lead.selectors';
import { LeadStatusName, Proposal } from '+app/+guide/store/types';
import { getPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { Portal } from '+app/shared/components';
import { PortalExit } from '+app/shared/helpers';
import { LayoutActions } from '+app/shared/store/layout/layout.actions';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import {
  GuidePurchaseFlatTermsModal,
  GuidePurchaseInfoBanner,
  GuidePurchaseProposal,
} from '+guide/+purchase/components';
import { getAcceptOfferStepStatus } from '+guide/+purchase/store/+purchase.selectors';
import { getFormattedDate } from '+legacy/helpers/dates';
import { prepareProposal } from './GuidePurchaseStepAcceptOffer.helper';

import './GuidePurchaseStepAcceptOffer.component.scss';

const mapStateToProps = (state: StoreState) => ({
  acceptedOffer: getGuideAcceptedOffer(state),
  isFlatOfferAccepted: isFlatOfferAccepted(state),
  offerAcceptedStatus: getLeadStatus(LeadStatusName.FLAT_OFFER_ACCEPTED)(state),
  offers: getGuideOfferCollection(state),
  hardwareOffers: getGuideOfferHardwareOfferCollection(state),
  stepStatus: getAcceptOfferStepStatus(state),
  leadFullName: getGuideLeadFullName(state),
  isHardwareAvailable: isHardwareOfferAvailable(state),
  impactAnalysisList: getImpactAnalysisList(state),
});

const mapDispatchToProps = mapActions({
  toggleScroll: LayoutActions.toggleLayoutScrolling,
  goToAcceptance: (offerId: string) => push(getPath(RouteName.GUIDE_ACCEPTANCE_ID, [offerId])),
});

type Props =
  & RouteComponentProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

const GuidePurchaseStepAcceptOfferComponent: React.FC<Props> = ({
  offers,
  offerAcceptedStatus,
  hardwareOffers,
  stepStatus,
  actions,
  acceptedOffer,
  impactAnalysisList,
  isFlatOfferAccepted,
}) => {
  const content = {
    [GuideStepStatus.PREVIEW]: {
      headline: I18n.t(T.customerGuidance.purchase.step.acceptOffer.header),
      tag: I18n.t(T.customerGuidance.tag.text),
      status: 'open',
    },
    [GuideStepStatus.ACTIVE]: {
      headline: I18n.t(T.customerGuidance.purchase.step.acceptOffer.header),
      tag: I18n.t(T.customerGuidance.tag.text),
      status: 'open',
    },
    [GuideStepStatus.DONE]: {
      headline: I18n.t(T.customerGuidance.purchase.step.acceptOffer.header),
      tag: I18n.t(T.customerGuidance.tag.done),
      status: 'done',
      description: offerAcceptedStatus
        && offerAcceptedStatus.createdAt
        && String(getFormattedDate(offerAcceptedStatus.createdAt)),
    },
  };
  const [isModalTermsOpen, toggleModalTerms] = React.useState(false);

  const proposals: Proposal[] = acceptedOffer
    ? prepareProposal([acceptedOffer], impactAnalysisList)
    : prepareProposal(offers, impactAnalysisList);

  const handleToggleTermsModal = () => {
    actions.toggleScroll(!isModalTermsOpen);
    toggleModalTerms(!isModalTermsOpen);
  };

  return (
    <GuideAccordion
      isAlwaysOpen={!isFlatOfferAccepted}
      status={stepStatus}
      anchor={StepName.ACCEPT_OFFER}
      title={content[stepStatus].headline}
      tag={content[stepStatus].tag}
      tagTheme={content[stepStatus].status}
      description={content[stepStatus].description}
    >
      {{
        [GuideStepStatus.PREVIEW]: (
          <GuidePurchaseInfoBanner
            icon={<Icon.DocumentUfo />}
            title={I18n.t(T.customerGuidance.purchase.step.acceptOffer.preview.banner.title)}
            subtitle={I18n.t(T.customerGuidance.purchase.step.acceptOffer.preview.banner.subtitle)}
          />
        ),
        [GuideStepStatus.ACTIVE]: (
          <>
            <div className={'c-guide-purchase-step-accept-offer__description'}>
              <DefaultParagraph>
                <p>{I18n.t(T.customerGuidance.purchase.step.acceptOffer.active.headline)}</p>
                <p>{I18n.t(T.customerGuidance.purchase.step.acceptOffer.active.description)}</p>
              </DefaultParagraph>
            </div>
            <GuideGridStretched
              isThemeOneThird={true}
            >
              {proposals.map((proposal, index) => (
                <GuidePurchaseProposal
                  index={index + 1}
                  proposal={proposal}
                  hardwareOffers={proposal.offer && hardwareOffers[proposal.offer.id]}
                  onCheckOffer={proposal.offer ? () => actions.goToAcceptance(proposal.offer.id) : undefined}
                  handleToggleTermsModal={handleToggleTermsModal}
                  key={proposal.id}
                />
              ))}
            </GuideGridStretched>
            <Portal target={PortalExit.ROOT}>
              <GuidePurchaseFlatTermsModal
                isOpen={isModalTermsOpen}
                toggle={handleToggleTermsModal}
              />
            </Portal>
          </>
        ),
        [GuideStepStatus.DONE]: (
          <>
            <GuideGridStretched
              isThemeOneThird={true}
            >
              {acceptedOffer && (
                <GuidePurchaseProposal
                  index={1}
                  proposal={proposals[0]}
                  hardwareOffers={hardwareOffers![acceptedOffer.id]}
                  handleToggleTermsModal={handleToggleTermsModal}
                />
              )}
            </GuideGridStretched>
            <Portal target={PortalExit.ROOT}>
              <GuidePurchaseFlatTermsModal
                isOpen={isModalTermsOpen}
                toggle={handleToggleTermsModal}
              />
            </Portal>
          </>
        ),
      }}
    </GuideAccordion>
  );
};

export const GuidePurchaseStepAcceptOffer =
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GuidePurchaseStepAcceptOfferComponent));
