import { createReducer } from '+app/utils/redux';
import { NEWS_CHANNEL_ACTIONS, NewsChannelActions } from './newsChannel.actions';
import { initialState, NewsChannelState } from './newsChannel.state';

export const reducer = createReducer<NewsChannelState, NewsChannelActions>(
  (state = initialState, action): NewsChannelState => {
    switch (action.type) {
      case NEWS_CHANNEL_ACTIONS.SET_NEWS_LIST:
        return {
          newsList: action.newsList,
        };
      default:
        return state;

    }
  },
);
