import { CONFIG } from '+config';
import { filterPrivateData } from '+utils/privacy.util';
import * as Sentry from '@sentry/browser';
import { Severity } from '@sentry/browser';
import { isObject } from 'lodash';
import { Middleware } from 'redux';

const init = () => {
  if (CONFIG.DEVELOPMENT_MODE) {
    return;
  }
  Sentry.init({
    dsn: CONFIG.SENTRY_DSN,
    environment: CONFIG.ENVIRONMENT,
    release: CONFIG.VERSION,
  });
  window.addEventListener('unhandledrejection', event => {
    reportError(event.reason, { event: 'Unhandled promise rejection' });
    event.preventDefault();
  });
  window.addEventListener('error', event => {
    const { error, colno, lineno, message, filename } = event;
    reportError(error, { colno, lineno, message, filename });
    event.preventDefault();
  });
};

const setUserData = (user: Sentry.User) => {
  Sentry.configureScope(scope => {
    scope.setUser(user);
  });
};

const log = (...args: [any, ...any[]]) => {
  if (CONFIG.DEVELOPMENT_MODE) {
    console.log(...args);
  }
  Sentry.addBreadcrumb({
    level: Severity.Log,
    type: 'default',
    category: 'log',
    data: args.length <= 1
      ? args[0]
      : args.map(arg => isObject(arg) ? filterPrivateData(arg) : arg),
  });
};

const reduxMiddleware: Middleware = store => next => action => {
  Sentry.addBreadcrumb({
    level: Severity.Info,
    type: 'default',
    category: 'redux',
    data: filterPrivateData(action),
  });
  try {
    next(action);
  } catch (error) {
    reportError(error, {
      action,
      state: store.getState(),
    });
    throw error;
  }
};

interface SentryError extends Error {
  extra?: object;
}

const reportError = (err: SentryError, extra?: object) => {
  if (!err) {
    return;
  }
  const extraScope = { ...extra, ...err.extra, runsInContainerApp: CONFIG.IS_MOBILE  };
  console.error(err, extraScope);
  if (!CONFIG.DEVELOPMENT_MODE) {
    Sentry.withScope(scope => {
      if (extraScope) {
        Object.keys(extraScope).forEach(key => scope.setExtra(key, filterPrivateData(extraScope[key])));
      }
      Sentry.captureException(err);
    });
  }
};

export const Reporter = {
  init,
  log,
  setUserData,
  reportError,
  reduxMiddleware,
};
