import { CONFIG } from '+config';
import * as React from 'react';

interface Props {
  web: React.ReactNode;
  mobile?: React.ReactNode;
}

export const PlatformSelector: React.SFC<Props> = ({
  web: webComponent,
  mobile: mobileComponent,
}) => (
  <>
    { CONFIG.IS_MOBILE && mobileComponent
      ? mobileComponent
      : webComponent }
  </>
);
