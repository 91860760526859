import { isSiteSwitcherOpen } from '+app/shared/store/layout/layout.selectors';
import { StoreState } from '+app/store/store.interface';
import { getActiveSite, getAllSitesOrdered } from '+shared/store/site/site.selectors';
import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { SiteSwitcherButton } from '../SiteSwitcherButton';
import { SiteSwitcherList } from '../SiteSwitcherList';
import { SiteSwitcherSingleSite } from '../SiteSwitcherSingleSite';

import './SiteSwitcher.component.scss';

const mapStateToProps = (state: StoreState) => ({
  allSites: getAllSitesOrdered(state),
  activeSite: getActiveSite(state),
  isSiteSwitcherOpen: isSiteSwitcherOpen(state),
});

type Props = ReturnType<typeof mapStateToProps>;

// @TODO: Utilize <Drawer /> here?

export const SiteSwitcherComponent: React.FC<Props> = ({ allSites = [], activeSite, isSiteSwitcherOpen }) => {
  const listWrapperClasses = classNames('c-site-switcher__list-wrapper', {
    'is-open': isSiteSwitcherOpen,
  });

  return (
    <div className={'c-site-switcher'}>
      {(allSites.length > 1) ? (
        <>
          <SiteSwitcherButton
            site={activeSite}
            isHighlighted={true}
          />
          <div className={listWrapperClasses}>
            <SiteSwitcherList />
          </div>
        </>
      ) : (
        <SiteSwitcherSingleSite />
      )}
    </div>
  );
};

// TODO: Consider to refactor it to the dumb component!
export const SiteSwitcher = connect(mapStateToProps)(SiteSwitcherComponent);
