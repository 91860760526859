import { Icon, ScrollableContainer } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './Popup.component.scss';

// @TODO: Simplify - maybe <ScrollableContainer /> does not need to be rendered conditionally after all...

export type PopupProps = {
  isOpen: boolean;
  onClose: () => void;
};

interface State {
  shouldRenderScrollableContainer: boolean;
}

export class Popup extends React.PureComponent<PopupProps, State> {
  container?: HTMLElement;
  
  constructor(props: PopupProps) {
    super(props);

    this.state = {
      shouldRenderScrollableContainer: true,
    };
  }

  componentDidMount() {
    this.setScrollableContainerState();
    window.addEventListener('resize', this.setScrollableContainerState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScrollableContainerState);
  }

  getPopupHeight = () => {
    if (!this.container) { return 'initial'; }
    
    return window.getComputedStyle(this.container).getPropertyValue('--popup-height').trim();
  };

  setScrollableContainerState = () => {
    const height = this.getPopupHeight();

    this.setState({
      shouldRenderScrollableContainer: (height !== 'auto' && height !== 'initial'),
    });
  };
  
  setContainerRef = (el: HTMLElement | null): void => {
    if (el) {
      this.container = el;
    }
  };

  render() {
    const {
      isOpen,
      onClose,
      children,
    } = this.props;
    const {
      shouldRenderScrollableContainer,
    } = this.state;

    return(
      <div
        className={classNames('c-popup', {
          'is-open': isOpen,
        })}
        ref={this.setContainerRef}
      >
        <div className={'c-popup__content'}>
          {shouldRenderScrollableContainer ? (
            <ScrollableContainer height={'100%'}>
              <div className={'c-popup__content-inner'}>
                {children}
              </div>
            </ScrollableContainer>
          ) : (
            <div className={'c-popup__content-inner'}>
              {children}
            </div>
          )}
          <button
            className={'c-popup__close-button'}
            onClick={onClose}
            type={'button'}
          >
            <Icon.Close />
          </button>
        </div>
      </div>
    );
  }
}
