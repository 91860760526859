import { MeasurementMethod } from '+app/shared/store/live/types/siteLiveData.interface';
import { Data, MergedData, RelationshipArray } from '@coolio/json-api';

export enum SiteOption {
  CHARGERS = 'chargers',
  BATTERIES = 'batteries',
  CONTRACTS = 'contracts',
  READINGS = 'readings',
  SMARTMETERS = 'smartmeters',
}

export enum SiteElement {
  BATTERY = 'battery',
  BACKUP = 'backup',
  CONTRACT = 'contract',
  CHARGER = 'charger',
  HEATER = 'heater',
  PROTECT = 'protect',
}

export interface SiteAttributes {
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  state?: string;
  countryCode?: string;
  deliveryStartAt?: string;
  sonnenCommunityMember?: boolean;
  options?: SiteOption[];
  measurementMethod?: MeasurementMethod;
}

export type SiteRelationships = {
  chargers: RelationshipArray;
  contracts: RelationshipArray;
  batterySystems: RelationshipArray;
};

export type SiteData = Data<SiteAttributes, SiteRelationships>;

export type Site = MergedData<SiteData>;
