import { T } from '@sonnen/shared-i18n/customer';
import { Icon, ModalDocumentDownload } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { GuideDocumentEntry, GuideGridStretched, GuideInfoCard, GuideInfoCardText } from '+app/+guide/components';
import { GuideAccordion } from '+app/+guide/containers';
import { factorizeStepDescription, GuideStepResponsibility, GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StepName } from '+app/+guide/helpers/steps.config';
import { GuideActions } from '+app/+guide/store/+guide.actions';
import { getDocumentUrl } from '+app/+guide/store/+guide.selectors';
import { getLeadStatus } from '+app/+guide/store/+lead.selectors';
import { FlatDocumentType, LeadStatusName } from '+app/+guide/store/types';
import { Portal } from '+app/shared/components';
import { PortalExit } from '+app/shared/helpers';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { getFormattedDate } from '+legacy/helpers/dates';
import { getFlatOrderConfirmationDocumentStatus, getFlatOrderConfirmationStepStatus } from '../../store/+purchase.selectors';

import './GuidePurchaseStepFlatOrderConfirmation.component.scss';

const mapStateToProps = (state: StoreState) => ({
  orderConfirmedStatus: getLeadStatus(LeadStatusName.FLAT_ORDER_CONFIRMED)(state),
  orderConfirmationDocumentStatus: getFlatOrderConfirmationDocumentStatus(state),
  stepStatus: getFlatOrderConfirmationStepStatus(state),
  documentUrl: getDocumentUrl(state),
});

const mapDispatchToProps = mapActions({
  startDocumentPolling: GuideActions.startDocumentPolling,
  stopDocumentOfferPolling: GuideActions.stopDocumentPolling,
});

type Props =
  & RouteComponentProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const GuidePurchaseStepFlatOrderConfirmationComponent: React.FC<Props> = ({
  stepStatus,
  orderConfirmedStatus,
  orderConfirmationDocumentStatus,
  actions,
  documentUrl,
}) => {
  const formattedDate = getFormattedDate(orderConfirmedStatus?.createdAt);
  const content = {
    [GuideStepStatus.PREVIEW]: {
      headline: I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.headline.active),
      tag: I18n.t(T.customerGuidance.toDoBy, {name: GuideStepResponsibility.SONNEN}),
      status: 'open',
    },
    [GuideStepStatus.ACTIVE]: {
      headline: I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.headline.active),
      tag: I18n.t(T.customerGuidance.toDoBy, {name: GuideStepResponsibility.SONNEN}),
      status: 'open',
    },
    [GuideStepStatus.DONE]: {
      headline: I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.headline.done),
      tag: I18n.t(T.customerGuidance.tag.done),
      status: 'done',
      description: !isNil(formattedDate)
        ? factorizeStepDescription(
            formattedDate, I18n.t(T.customerGuidance.byName, {name: GuideStepResponsibility.SONNEN}),
          )
        : I18n.t(T.customerGuidance.byName, {name: GuideStepResponsibility.SONNEN}),
    },
  };
  const [isDownloadModalOpen, toggleDownloadModal] = React.useState(false);
  const onDownloadClick = (id: string, documentType: FlatDocumentType) => {
    toggleDownloadModal(true);
    actions.startDocumentPolling(
      id,
      documentType,
    );
  };
  const onDownloadModalClose = () => {
    actions.stopDocumentOfferPolling();
    toggleDownloadModal(false);
  };

  return (
    <GuideAccordion
      status={stepStatus}
      anchor={StepName.FLAT_ORDER_CONFIRMATION}
      title={content[stepStatus].headline}
      tag={content[stepStatus].tag}
      tagTheme={content[stepStatus].status}
      responsibility={content[stepStatus].responsibility}
      date={content[stepStatus].date}
    >
      {{
        [GuideStepStatus.PREVIEW]: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.preview.headline)}
              headerIcon={<Icon.Info />}
            >
              <GuideInfoCardText
                contentText={I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.preview.description)}
              />
            </GuideInfoCard>
          </GuideGridStretched>
        ),
        [GuideStepStatus.ACTIVE]: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.whatHappensNext.headline)}
              headerIcon={<Icon.Info />}
            >
              <GuideInfoCardText
                subtitle={I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.whatHappensNext.title)}
                contentText={I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.whatHappensNext.description)}
              />
            </GuideInfoCard>
          </GuideGridStretched>
        ),
        [GuideStepStatus.DONE]: (
          <>
            <GuideGridStretched>
              <GuideInfoCard
                header={I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.done.headline)}
                headerIcon={<Icon.Confetti />}
              >
                <GuideInfoCardText
                  subtitle={I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.done.title)}
                  contentText={I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.done.description)}
                />
                  <hr className={'c-guide-purchase-step-flat-order-confirmation__separator'} />
                  <GuideDocumentEntry
                    name={I18n.t(T.customerGuidance.documents.orderConfirmation)}
                    onDownload={() => onDownloadClick('order-confirmation', FlatDocumentType.FLAT_ORDER_CONFIRMATION)}
                  />
              </GuideInfoCard>
            </GuideGridStretched>
            {orderConfirmationDocumentStatus && (
              <Portal target={PortalExit.ROOT}>
                <ModalDocumentDownload
                  isOpen={isDownloadModalOpen}
                  status={orderConfirmationDocumentStatus}
                  onModalClose={onDownloadModalClose}
                  loadingText={I18n.t(T.customerGuidance.documents.statusMessage.pending)}
                  errorText={I18n.t(T.customerGuidance.documents.statusMessage.failed)}
                  linkText={I18n.t(T.customerGuidance.documents.statusMessage.created.clickHere)}
                  createdText={I18n.t(T.customerGuidance.documents.statusMessage.created.toDownloadDocument)}
                  fileUrl={documentUrl!}
                />
              </Portal>
            )}
          </>
        ),
      }}
    </GuideAccordion>
  );
};

export const GuidePurchaseStepFlatOrderConfirmation = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(GuidePurchaseStepFlatOrderConfirmationComponent) as React.ComponentType<any>;
