import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './DemoAnalysisHistoryHeader.component.scss';

export const DemoAnalysisHistoryHeader: React.FC = () => (
  <div className="c-demo-analysis-history-header">
    <span className="c-demo-analysis-history-header__date-label">
      {I18n.t(T.demo.analysis.history.label)}
    </span>
    <span className="c-demo-analysis-history-header__date">
      {I18n.t(T.demo.analysis.history.date)}
    </span>
  </div>
);
