import { select } from 'd3-selection';
import * as React from 'react';

interface Props {
  height: number;
  y: number;
  balanceValue: number;
  clipId: string;
}

export class EnergyRay extends React.PureComponent<Props> {
  static defaultProps = {
    height: 0,
    y: 0,
    balanceValue: 50,
  };

  static rayWidth = 18;

  groupRef: Element | null = null;

  componentDidMount() {
    this.animateEnergyDots();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.y !== this.props.y) {
      this.animateEnergyDots();
    }
  }

  animateEnergyDots = () => {
    const { y, balanceValue } = this.props;

    let translateTarget;
    if (y < balanceValue) {
      translateTarget = -120;
    } else if (y >= balanceValue) {
      translateTarget = -200;
    } else {
      translateTarget = -160;
    }

    select(this.groupRef)
      .attr('to', `-9 ${translateTarget}`);
  };

  setGroupRef = (ref: Element | null) => {
    this.groupRef = ref;
  };

  render() {
    const { clipId, height } = this.props;
    return (
      <g
        clipPath={`url(#${clipId})`}
        className="c-frequency-chart__energy-ray"
      >
        <defs>
          <pattern id="circles" viewBox="-2,0,24,0.8" width="100%" height="2%">
            <circle className="c-frequency-chart__energy-dot" cx="0" cy="0" r="2" />
            <circle className="c-frequency-chart__energy-dot" cx="10" cy="-5" r="2" />
            <circle className="c-frequency-chart__energy-dot" cx="20" cy="0" r="2" />
          </pattern>
        </defs>
        <rect
          fill="url(#circles)"
          width={EnergyRay.rayWidth}
          height={height * 2}
          transform={`translate(${EnergyRay.rayWidth / -2}, ${height / -2})`}
        >
          <animateTransform
            ref={this.setGroupRef}
            attributeName="transform"
            attributeType="XML"
            type="translate"
            from="-9 -160"
            to="-9 -200"
            dur="1.5s"
            repeatCount="indefinite"
          />
        </rect>
      </g>
    );
  }
}
