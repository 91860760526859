import { Action } from 'history';
import { get } from 'lodash';
import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';

interface Props {
  actions?: Action[];
}

export const ScrollToTop: React.FC<Props> = ({ actions = ['POP', 'PUSH', 'REPLACE'] }) => {
  const RenderComponent: React.FC<RouteComponentProps> = (({ history: { action, location } }) => {
    React.useEffect(() => {
      if (actions.indexOf(action) && !get(location, 'state.preserveScroll')) {
        window.scrollTo(0, 0);
      }
    }, []);
    return null;
  });
  return <Route render={routeProps => <RenderComponent {...routeProps} />} />;
};
