import * as React from 'react';
import GraphLoader from '../../../../components/loaders/GraphLoader/GraphLoader';

interface Props {
  className: string;
}

export const CommunityMapLoader: React.FC<Props> = ({ className }) => (
  <div className={className}>
    <GraphLoader />
  </div>
);
