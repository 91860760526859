import { T } from '@sonnen/shared-i18n/customer';
import { Button, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GuideAccordion } from '+app/+guide';
import { GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StepName } from '+app/+guide/helpers/steps.config';
import { getCessionDocUrl, getCessionFailed, getCessionStatus } from '+app/+guide/store/+guide.selectors';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { getCessionDocStepStatus } from '+guide/+purchase/store/+purchase.selectors';
import { GuideGridStretched, GuideInfoCard } from '+guide/components';
import { GuideActions } from '+guide/store/+guide.actions';
import { CessionStatus } from '+guide/store/types';
import { Portal } from '+shared/components';
import { PortalExit } from '+shared/helpers';
import { GuidePurchaseStepCessionDocModal } from '../../components';

import './GuidePurchaseStepCessionDoc.component.scss';

const mapStateToProps = (state: StoreState) => ({
  stepStatus: getCessionDocStepStatus(state),
  cessionStatus: getCessionStatus(state),
  cessionDocUrl: getCessionDocUrl(state),
  cessionFailed: getCessionFailed(state),
});

const mapDispatchToProps = mapActions({
  getCessionStatus: GuideActions.getCessionStatus,
  fetchCessionDocUrl: GuideActions.getCessionDocument,
});

type Props =
  & RouteComponentProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

const GuidePurchaseStepCessionDocComponent: React.FC<Props> = ({
  cessionFailed,
  cessionDocUrl,
  cessionStatus,
  stepStatus,
  actions,
}) => {
  React.useEffect(() => {
    if (!cessionStatus && !cessionFailed) {
      actions.getCessionStatus();
    }
  }, []);

  const content = {
    [GuideStepStatus.ACTIVE]: {
      headline: I18n.t(T.customerGuidance.purchase.step.cessionDoc.header),
      tag: I18n.t(T.customerGuidance.tag.text),
      theme: 'open',
    },
    [GuideStepStatus.DONE]: {
      headline: I18n.t(T.customerGuidance.purchase.step.cessionDoc.header),
      tag: I18n.t(T.customerGuidance.tag.done),
      theme: 'done',
    },
    [GuideStepStatus.ERROR]: {
      headline: I18n.t(T.customerGuidance.purchase.step.cessionDoc.header),
      tag: I18n.t(T.customerGuidance.tag.error),
      theme: 'error',
      description: I18n.t(T.customerGuidance.purchase.step.cessionDoc.error.description),
    },
  };
  const isStatusRejected = cessionStatus === CessionStatus.REJECTED;
  const shouldEnableCessionSigning = !isStatusRejected;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const closeModal = () => setIsModalOpen(false);
  const openModal = () => {
    if (!cessionDocUrl) {
      actions.fetchCessionDocUrl();
    }
    setIsModalOpen(true);
  };

  return (
    <>
      <GuideAccordion
        status={stepStatus}
        anchor={StepName.CESSION_DOC}
        title={content[stepStatus].headline}
        tag={content[stepStatus].tag}
        tagTheme={content[stepStatus].theme}
        description={content[stepStatus].description}
      >
        {{
          [GuideStepStatus.ACTIVE]: (
            <>
              {shouldEnableCessionSigning && (
                <div className={'c-guide-purchase-step-cession-doc__button'}>
                  <GuideGridStretched>
                    <span />
                    <Button
                      label={I18n.t(T.customerGuidance.purchase.step.cessionDoc.active.buttonLabel)}
                      onClick={openModal}
                      isBlock={true}
                    />
                    <span />
                  </GuideGridStretched>
                </div>
              )}
              <GuideGridStretched>
                <GuideInfoCard
                  header={I18n.t(T.customerGuidance.purchase.step.cessionDoc.active.information.headline)}
                  headerIcon={<Icon.Info />}
                >
                  {I18n.t(T.customerGuidance.purchase.step.cessionDoc.active.information.description)}
                </GuideInfoCard>
                <GuideInfoCard
                  header={I18n.t(T.customerGuidance.purchase.step.cessionDoc.active.yourToDo.headline)}
                  headerIcon={<Icon.Contract />}
                  isIconBig={true}
                >
                  {isStatusRejected
                    ? I18n.t(T.customerGuidance.purchase.step.cessionDoc.active.yourToDo.descriptionForRejected)
                    : I18n.t(T.customerGuidance.purchase.step.cessionDoc.active.yourToDo.description)
                  }
                </GuideInfoCard>
                <GuideInfoCard
                  header={I18n.t(
                    T.customerGuidance.purchase.step.cessionDoc.active.backgroundInformation.headline,
                  )}
                  headerIcon={<Icon.Info />}
                >
                  {I18n.t(T.customerGuidance.purchase.step.cessionDoc.active.backgroundInformation.description)}
                </GuideInfoCard>
              </GuideGridStretched>
            </>
          ),
          [GuideStepStatus.DONE]: (
            <GuideGridStretched>
              <GuideInfoCard
                header={I18n.t(T.customerGuidance.purchase.step.cessionDoc.done.information.headline)}
                headerIcon={<Icon.Info />}
              >
                {I18n.t(T.customerGuidance.purchase.step.cessionDoc.done.information.description)}
              </GuideInfoCard>
            </GuideGridStretched>
          ),
          [GuideStepStatus.ERROR]: (
            <GuideGridStretched>
              <GuideInfoCard
                header={I18n.t(T.customerGuidance.purchase.step.cessionDoc.error.information.headline)}
                headerIcon={<Icon.Info />}
              >
                {I18n.t(T.customerGuidance.purchase.step.cessionDoc.error.information.description)}
              </GuideInfoCard>
            </GuideGridStretched>
          ),
        }}
      </GuideAccordion>
      <Portal target={PortalExit.ROOT}>
        <GuidePurchaseStepCessionDocModal
          isError={cessionFailed}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      </Portal>
    </>
  );
};

export const GuidePurchaseStepCessionDoc =
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GuidePurchaseStepCessionDocComponent));
