import { stringToCamelCase } from '+app/shared/helpers/convertCases';
import { HttpResponse } from '@coolio/http';
import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

export interface ApiError {
  code: string;
  source: {
    pointer: string;
  };
  pointer: string;
  status: number;
  title: string;
}

export interface FormError {
  label: string;
  id: number;
  isError: boolean;
}

export interface ApiErrorResponse {
  errors: ApiError[];
}

export interface FormError {
  label: string;
  id: number;
  isError: boolean;
}

let id = 0;

export const errorBuilder = (label: string): FormError[] => {
  id += 1;

  return [
    {
      label,
      id,
      isError: true,
    },
  ];
};

export const getErrorSource = (pointer: string) =>
  pointer.slice(pointer.lastIndexOf('/') + 1);

export const extractApiErrors = (response: HttpResponse): Promise<ApiError[]> =>
  response.parsedBody().then(body => body.errors || []);

export const createSubmissionErrorsObject = (errors: ApiError[]) => errors.reduce((object, error) => {
  const errorSource = getErrorSource(error.source.pointer);
  object[stringToCamelCase(errorSource)] = errorBuilder(
    I18n.t(`${T.general.errors._all}.${errorSource}.${error.code}`),
  );
  return object;
}, {});
