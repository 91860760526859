import { history } from '+app/router/store';
import * as React from 'react';

// Heavy memoization to handle url out of component lifecycle
export const useUrlHistory = () => {
  const currentUrlRef = React.useRef(window.location.href);
  const previousUrlRef = React.useRef(document.referrer || window.location.href);

  React.useEffect(() => {
    const unsubscribe = history.listen(() => {
      const newUrl = window.location.href;

      if (newUrl !== currentUrlRef.current) {
        previousUrlRef.current = String(currentUrlRef.current);
        currentUrlRef.current = newUrl;
      }
    });

    return unsubscribe;
  }, []);

  return React.useCallback(() => {
    return {
      previousUrl: previousUrlRef.current,
      currentUrl: currentUrlRef.current,
    };
  }, []);
};
