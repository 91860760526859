import * as React from 'react';

import './EFCircleGridImageGrid.compontent.scss';

// tslint:disable:max-line-length
export const EFCircleGridImageGrid: React.FC = () => (
  <svg xmlSpace={'preserve'} width="108" height="37" viewBox="0 0 108 37"
       className={'c-ef-circle__image c-ef-circle__image-grid'}>
    <g fill="none" fillRule="evenodd" opacity=".6">
      <g fill="#C5C6C9" fillRule="nonzero" stroke="#C5C6C9" strokeWidth=".5">
        <path d="M83.052 9.208a.122.122 0 0 1-.08-.03l-8.834-7.683-8.776 7.682a.121.121 0 0 1-.158-.024.125.125 0 0 1 .001-.162l8.85-7.752a.121.121 0 0 1 .16 0l8.917 7.752a.125.125 0 0 1-.08.217z" />
        <path d="M78 4.827v-2.69c0-.076.062-.137.137-.137h2.819c.076 0 .137.061.137.137v5.307L78 4.827zm.275-.063l2.543 2.182V2.274h-2.543v2.49zM65.153 36.725V9.012c0-.08.055-.145.123-.145h17.836c.067 0 .122.065.122.145v27.713H65.153zm.246-.222h17.59V9.231h-17.59v27.272z" />
        <path d="M72.743 17.809h-4.506a.183.183 0 0 1-.182-.183v-4.539c0-.101.082-.183.182-.183h4.506c.1 0 .182.082.182.183v4.539a.183.183 0 0 1-.182.183zm-4.324-.367h4.142V13.27H68.42v4.172zM72.743 25.356h-4.506a.183.183 0 0 1-.182-.183v-4.537c0-.101.082-.183.182-.183h4.506c.1 0 .182.082.182.183v4.537a.183.183 0 0 1-.182.183zm-4.324-.367h4.142v-4.17H68.42v4.17zM72.743 32.905h-4.506a.183.183 0 0 1-.182-.183v-4.539c0-.101.082-.183.182-.183h4.506c.1 0 .182.082.182.183v4.539a.183.183 0 0 1-.182.183zm-4.324-.367h4.142v-4.172H68.42v4.172zM80.202 17.809h-4.505a.183.183 0 0 1-.182-.183v-4.539c0-.101.082-.183.182-.183h4.505c.1 0 .181.082.181.183v4.539a.183.183 0 0 1-.181.183zm-4.323-.367h4.14V13.27h-4.14v4.172zM80.202 25.356h-4.505a.183.183 0 0 1-.182-.183v-4.537c0-.101.082-.183.182-.183h4.505c.1 0 .181.082.181.183v4.537a.183.183 0 0 1-.181.183zm-4.323-.367h4.14v-4.17h-4.14v4.17zM75.515 36.687v-8.504c0-.101.082-.183.182-.183h4.505c.1 0 .181.082.181.183v8.504h-4.868zm.364-.184h4.14v-8.137h-4.14v8.137z" />
      </g>
      <path fill="#C5C6C9" fillRule="nonzero" d="M47.497 36.836L42.798 13.83H47.6a.577.577 0 0 0 .579-.574.577.577 0 0 0-.58-.574h-5.033l-1.643-8.053h7.838a.577.577 0 0 0 .58-.574.58.58 0 0 0-.58-.578h-8.074l-.61-2.992a.577.577 0 0 0-.568-.46h-6.798a.577.577 0 0 0-.567.456l-.63 2.992h-8.275a.577.577 0 0 0-.58.574c0 .316.26.574.58.574h8.035l-1.694 8.057h-5.172a.577.577 0 0 0-.579.574c0 .317.26.574.58.574h4.93l-4.832 23.011h22.99zm-2.292-5.413L36.85 23.73l5.71-5.256 2.644 12.95zM36 22.945l-5.534-5.093L36 12.654l5.534 5.198L36 22.945zm6.25-6.003l-5.407-5.081 3.667-3.445 1.74 8.526zM33.185 1.176h5.849l1.205 5.912L36 11.068l-4.088-3.839 1.272-6.053zm-1.552 7.377l3.525 3.308-5.254 4.936 1.729-8.244zm-2.1 10.006l5.617 5.17-8.31 7.651 2.694-12.821zm-3.84 18.277l.74-3.53a.59.59 0 0 0 .1-.075L36 24.514l9.465 8.717c.04.036.082.067.126.09l.717 3.515H25.692z" />
      <path stroke="#C5C6C9" d="M25.692 4.212C18.241 10 13.352 10.186 6.23 10.627M27.353 13.496C19.902 19.284 13.968 17.8 7.43 16.207" />
      <path stroke="#C5C6C9" strokeLinecap="round" d="M65.175 15.347c-7.452 5.788-16.196 1.99-20.282-2.077M65.175 13.496C57.723 19.284 48.979 8.066 44.893 4" />
      <path stroke="#C5C6C9" strokeWidth=".7" d="M.35 36.35v.3h107.3v-.3H.35z" />
    </g>
  </svg>
);
