import { T } from '@sonnen/shared-i18n/customer';
import { Link } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { InfoBox } from '../InfoBox';

interface Props {
  url: string;
  onLinkClick?: () => void;
}

export const CessionInfo: React.FC<Props> = ({
  url,
  onLinkClick,
}) => (
  <InfoBox
    title={I18n.t(T.customerGuidance.acceptance.cessionDoc.linkHeadline)}
    description={(
      <>
        {`${I18n.t(T.customerGuidance.acceptance.cessionDoc.linkMessage)} `}
        <span
          onClick={onLinkClick}
        >
          <Link
            href={url}
            label={I18n.t(T.customerGuidance.acceptance.cessionDoc.linkLabel)}
          />
        </span>
      </>
    )}
  />
);
