import * as React from 'react';

import './EFCircleGridImageWindmills.compontent.scss';

// tslint:disable:max-line-length
export const EFCircleGridImageWindmills: React.FC = () => (
  <svg xmlSpace={'preserve'} width="140" height="46" viewBox="0 0 140 46"
       className={'c-ef-circle__image c-ef-circle__image-windmills'}>>
    <g fill="none" fillRule="evenodd" stroke="#C5C6C9" opacity=".6">
      <g fill="#C5C6C9" fillRule="nonzero" strokeWidth=".5">
        <path d="M68.285 19.714a2.416 2.416 0 0 1-2.278-1.607 2.399 2.399 0 0 1 .766-2.673 2.425 2.425 0 0 1 2.787-.17 2.4 2.4 0 0 1 1.086 2.562 2.416 2.416 0 0 1-2.361 1.888zm0-4.334a1.934 1.934 0 0 0-1.929 1.793 1.931 1.931 0 0 0 1.656 2.045 1.937 1.937 0 0 0 2.17-1.495 1.93 1.93 0 0 0-1.902-2.343h.005z" />
        <path d="M65.84 44.94l1.53-25.595.36.005-1.53 25.595zM68.83 19.35l.36-.006 1.689 25.594-.36.007z" />
        <path d="M54.858 26.232a.467.467 0 0 1-.457-.252c-.24-.416.207-.957 1.125-1.835.798-.76 5.109-4.625 6.749-5.556 1.589-.904 3.702-1.347 3.791-1.366l.096.478c-.02 0-2.125.448-3.65 1.314-1.524.867-5.608 4.495-6.654 5.486-1.005.958-1.05 1.197-1.041 1.252.12.057.77-.139 1.591-.388 1.443-.44 6.446-2.53 7.886-3.49.988-.66 2.183-2.058 2.695-2.656l.113-.132.366.311-.113.132c-.568.663-1.75 2.046-2.794 2.743-1.58 1.053-6.667 3.143-8.01 3.55-.876.255-1.378.409-1.693.409zM66.895 15.557l-.057-.163c-.289-.823-.89-2.537-.962-3.786-.115-1.889.642-7.317.962-8.679C67.2 1.41 67.297 1 67.775 1c.481 0 .734.665 1.025 1.893.24 1.07 1.428 6.723 1.406 8.617-.017 1.821-.7 3.86-.721 3.946l-.455-.153c0-.019.686-2.053.702-3.798.017-1.745-1.062-7.092-1.394-8.49-.317-1.345-.51-1.508-.56-1.527-.113.072-.27.73-.481 1.566-.351 1.47-1.06 6.816-.962 8.537.075 1.182.683 2.918.943 3.657l.057.163-.44.146zM82.268 24.978a6.406 6.406 0 0 1-1.53-.295c-1.064-.292-6.61-1.984-8.258-2.903-1.596-.89-3.065-2.465-3.125-2.532l.35-.326c.018.017 1.482 1.587 3.01 2.441 1.53.855 6.76 2.475 8.151 2.858 1.337.369 1.572.28 1.613.24 0-.132-.514-.587-1.151-1.161-1.128-1.013-5.496-4.2-7.064-4.933-1.077-.505-2.9-.807-3.676-.934l-.17-.028.079-.479.17.029c.864.143 2.664.442 3.802.974 1.721.806 6.137 4.069 7.18 5.012 1.167 1.048 1.48 1.33 1.249 1.747-.106.206-.315.29-.63.29z" />
      </g>
      <g fill="#C5C6C9" fillRule="nonzero" strokeWidth=".5">
        <path d="M33.33 24.886a1.912 1.912 0 1 1-1.2-3.405 1.919 1.919 0 0 1 2.21-.134 1.912 1.912 0 0 1 .862 2.037 1.918 1.918 0 0 1-1.872 1.502zm0-3.448a1.535 1.535 0 0 0-.216 3.053 1.535 1.535 0 1 0 .213-3.053h.003z" />
        <path d="M31.183 44.952l1.42-20.36.287.004-1.421 20.36zM33.761 24.597l.286-.005 1.546 20.359-.286.005z" />
        <path d="M22.68 30.07a.37.37 0 0 1-.362-.2c-.19-.33.164-.76.892-1.46.633-.603 4.052-3.678 5.353-4.418 1.26-.72 2.936-1.072 3.007-1.088l.076.381c-.015 0-1.686.356-2.895 1.045-1.209.69-4.448 3.576-5.278 4.364-.797.762-.833.952-.825.996.095.046.61-.11 1.262-.309 1.144-.35 5.112-2.012 6.254-2.775.784-.526 1.732-1.638 2.138-2.114l.09-.104.29.247-.09.105c-.45.527-1.389 1.628-2.216 2.182-1.253.837-5.288 2.5-6.353 2.823-.694.204-1.093.326-1.343.326zM32.227 21.58l-.045-.13c-.23-.655-.706-2.018-.763-3.012-.092-1.502.509-5.82.763-6.903.288-1.21.364-1.535.743-1.535.382 0 .582.53.813 1.506.19.851 1.132 5.348 1.115 6.854-.013 1.449-.555 3.07-.572 3.14l-.36-.122c0-.016.543-1.634.556-3.022.014-1.388-.842-5.641-1.106-6.753-.251-1.07-.404-1.2-.444-1.215-.09.058-.213.581-.381 1.246-.279 1.169-.841 5.422-.763 6.79.06.941.542 2.321.748 2.91l.045.13-.349.115zM44.42 29.073a5.067 5.067 0 0 1-1.213-.234c-.845-.232-5.244-1.578-6.55-2.31-1.266-.708-2.431-1.96-2.479-2.014l.278-.259c.014.014 1.175 1.262 2.388 1.942 1.212.68 5.36 1.969 6.464 2.273 1.06.294 1.247.223 1.279.19 0-.104-.408-.466-.913-.923-.895-.805-4.36-3.34-5.602-3.923-.855-.402-2.3-.642-2.916-.743l-.135-.023.063-.38.135.022c.685.114 2.113.352 3.015.775 1.365.642 4.868 3.237 5.695 3.987.925.834 1.173 1.058.99 1.39-.084.163-.25.23-.5.23z" />
      </g>
      <path strokeWidth=".7" d="M.35 45.35v.3h139.3v-.3H.35z" />
    </g>
  </svg>
);
