import { User } from '+app/shared/store/user/types/user.interface';
import { CountryCode } from '@sonnen/shared-web';

export const CURRENT_USER_MOCK: User = {
  id: '123456789',
  type: 'user',
  self: '',
  customerNumber: 'DE19206454',
  academicTitle: undefined,
  accountBillingCountryCode: null,
  accountDso: '9900027000002',
  firstName: 'Demo',
  lastName: 'User',
  description: '12345678901234567890123456789012345678901234567890123456789012345678901234567890123456789012345678901234567890123456789',
  email: 'portal.sonnen@gmail.com',
  unconfirmedEmail: undefined,
  phone: undefined,
  mobile: '43535345655',
  street: 'Am Riedbach 1',
  postalCode: '87435',
  city: 'Kempten',
  state: undefined,
  countryCode: CountryCode.DE,
  latitude: '45.68323',
  longitude: '9.67009',
  language: 'en',
  timeZone: 'Europe/Berlin',
  newsletter: true,
  privacyPolicy: 'DE-de-1.0',
  termsOfService: 'DE-de-1.0',
  partnerMonitoringAccepted: true,
  salesforceUserId: '0059E000009NgjfQAC',
  salesforceContactId: '0039E000014Xp1WQAS',
  roles: [],
  defaultSite: '1e95983b-5b8d-4abb-8cbb-65f979d86588',
};
