import * as React from 'react';

import { Battery } from '+battery/store/types/battery.interface';
import {
  createSonnenFlatDataProvider,
  isFreeUsageAllowanceValueMissing,
} from '+contract/store/helpers/contract.helpers';
import { Contract } from '+contract/store/types/contract.interface';
import { InfographicWrapper } from '+dashboard/+infographic/components';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { QueryData, QueryTypes } from '+shared/store/query/query.state';
import { SiteElement } from '+shared/store/site/types/site.interface';
import { StatisticsData } from '+shared/types/statisticsData.interface';
import { InfographicHelper } from './Infographic.helper';
import { InfographicCardElement, InfographicChargerData, InfographicProps } from './Infographic.types';

interface InfographicData {
  siteLiveData?: SiteLive;
  chargerData?: InfographicChargerData;
  batteryData?: Battery;
  contractData: {
    contract?: Contract;
    sonnenFlatStatistics?: StatisticsData;
    isNoQuota: boolean;
  };
  query: Record<QueryTypes, QueryData>;
}

export interface InfographicContextState {
  activeCard: InfographicCardElement;
  cards: InfographicCardElement[];
  data: InfographicData;
  actions?: InfographicProps['actions'];
  setActiveCard: (activeCard: InfographicCardElement) => void;
  goToPrevCard: () => void;
  goToNextCard: () => void;
  isActive: (card: InfographicCardElement) => boolean;
  isAvailable: (card: InfographicCardElement) => boolean;
}

export const InfographicContext = React.createContext<InfographicContextState>({} as InfographicContextState);

export const Infographic: React.FC<InfographicProps> = ({
  siteElements,
  siteLiveData,
  siteLiveQuery,

  batteryData,
  batteryQuery,

  contract,
  sonnenFlatStatistics,
  contractQuery,
  contractStatisticsQuery,

  chargerData,
  chargerLiveQuery,

  actions,
}) => {
  const [cards, setCards] = React.useState<InfographicCardElement[]>([]);
  const [activeCard, setActiveCard] = React.useState(InfographicCardElement.PV);

  React.useEffect(() => {
    const availableCards = InfographicHelper.getAvailableCards(siteElements);

    setCards(availableCards);
  }, [siteElements, batteryData]);

  React.useEffect(() => {
    if (!activeCard || !isAvailable(activeCard)) {
      goToNextCard();
    }
  }, [cards]);

  const goToPrevCard = () => {
    const activeCardIndex = cards.indexOf(activeCard);
    const prevCardName = activeCardIndex <= 0
      ? cards[cards.length - 1]
      : cards[activeCardIndex - 1];
    setActiveCard(prevCardName);
  };

  const goToNextCard = () => {
    const activeCardIndex = cards.indexOf(activeCard);
    const nextCardName = activeCardIndex === cards.length - 1
      ? cards[0]
      : cards[activeCardIndex + 1];
    setActiveCard(nextCardName);
  };

  const isActive = (card: InfographicCardElement) => card === activeCard;

  const isAvailable = (card: InfographicCardElement) => cards.includes(card);

  const isNoQuota = (): boolean => {
    if (contract) {
      const sonnenFlatContractDataProvider = createSonnenFlatDataProvider({ contract, sonnenFlatStatistics });

      return isFreeUsageAllowanceValueMissing(
        sonnenFlatContractDataProvider.getFreeUsageAllowance(),
        siteElements.includes(SiteElement.BATTERY),
        contractQuery.status,
      );
    }

    return false;
  };

  return (
    <InfographicContext.Provider
      value={{
        cards,
        activeCard,
        data: {
          siteLiveData,
          batteryData,
          contractData: {
            contract,
            sonnenFlatStatistics,
            isNoQuota: isNoQuota(),
          },
          chargerData,
          query: {
            siteLiveQuery,
            batteryQuery,
            contractQuery,
            contractStatisticsQuery,
            chargerLiveQuery,
          },
        },
        actions,
        setActiveCard,
        goToPrevCard,
        goToNextCard,
        isActive,
        isAvailable,
      }}
    >
      <InfographicWrapper />
    </InfographicContext.Provider>
  );
};

export const useInfographic = () => React.useContext(InfographicContext);
