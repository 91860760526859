import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { QueryData } from '+app/shared/store/query/query.state';
import { DashboardInfoBannerInaccurateMeasurements } from '+dashboard/components';
import { NewsSection } from '+shared/containers/NewsSection';
import { ContractFlatInfo, ContractFlatStatistics, ContractWrongDeliveryDateInfo } from '../../components';
import { isContractActive, SonnenFlatDataProvider } from '../../store/helpers/contract.helpers';
import { Contract } from '../../store/types/contract.interface';

import './ContractFlatHomeOverview.component.scss';

export type Props = {
  batteryTimezone: string,
  contract: Contract;
  contractDataProvider: SonnenFlatDataProvider;
  contractQuery: QueryData;
  isContractStatusDone: boolean,
  isSiteWithBattery: boolean,
  shouldDisplayMeasurementsBanner: boolean;
};

export const ContractFlatHomeOverview: React.FC<Props> = ({
  batteryTimezone,
  contract,
  contractDataProvider,
  contractQuery,
  isContractStatusDone,
  isSiteWithBattery,
  shouldDisplayMeasurementsBanner,
}) => {
  const expertViewLegendGreenLabel = I18n.t(T.yourFlat.expertView.chart.legend.greenFlatHome);

  return (
    <div className={'c-contract-flat-home-overview'}>
      <NewsSection />
      {shouldDisplayMeasurementsBanner && (
        <DashboardInfoBannerInaccurateMeasurements />
      )}
      {isContractActive(contract) ? (
        <ContractFlatStatistics
          batteryTimezone={batteryTimezone}
          contractQueryStatus={contractQuery.status}
          dataProvider={contractDataProvider}
          expertViewLegendGreenLabel={expertViewLegendGreenLabel}
          isContractStatusDone={isContractStatusDone}
          isSiteWithBattery={isSiteWithBattery}
        />
      ) : (
        <div className={'c-contract-flat-home-overview__banner'}>
          <ContractWrongDeliveryDateInfo contract={contract}/>
        </div>
      )}
      <ContractFlatInfo
        dataTestId={'contract-flat-home-overview'}
        benefitList={I18n.t(T.yourFlat.sideSection.captions)}
        contractDetailsList={contractDataProvider.getContractDetails()}
        withBenefits={true}
      />
    </div>
  );
};
