import { T } from '@sonnen/shared-i18n/customer';
import { CountryCode } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { compose } from 'redux';

import { Page } from '+app/shared/containers/Page';
import { StoreState } from '+app/store/store.interface';
import { NewsSection } from '+shared/containers/NewsSection';
import { ContractHoCProps, withContract } from '+shared/store/contract/withContract.hoc';
import { ContractBenefitList, ContractDetails, ContractTermination } from '../../components';
import { hasSonnenEnergyItTariff } from '../../store/contract.selectors';
import { getEnergyProductDetailsItems } from '../../store/helpers/contract.helpers';

const mapStateToProps = (state: StoreState) => ({
  hasSonnenEnergyItTariff: hasSonnenEnergyItTariff(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ContractHoCProps
;

export const ContractEnergyOverviewComponent: React.FC<Props> = ({
  contract,
  hasSonnenEnergyItTariff,
}) => {
  const benefitsList = hasSonnenEnergyItTariff
    ? T.yourFlat.sideSection.sonnenEnergyIT.captions
    : T.yourFlat.sideSection.sonnenFlatIT.captions;
  const energyProductDetailsItems = getEnergyProductDetailsItems(
    hasSonnenEnergyItTariff
      ? I18n.t(T.yourFlat.tableValues.sonnenEnergyIT)
      : I18n.t(T.yourFlat.tableValues.sonnenFlatIT),
    contract!.contractNumber, // energyProductDetailsItems is only called when contract != undefined
    contract!.orderedAt,
    'contract-energy-overview',
  );

  return (
    <Page headerTestId={'contract-energy-overview-header'}>
      <NewsSection />
      <div className={'o-grid'}>
        <section className={'o-grid__column o-grid__column--sm-6 o-grid__column--no-gap-sm'}>
          <ContractDetails
            contractDetailsList={contract && energyProductDetailsItems}
          />
        </section>
        <section className={'o-grid__column o-grid__column--sm-6 o-grid__column--no-gap'}>
          {/* TODO: add proper translations key if needed, if the translation is the same
          remove this prop from component*/}
          <ContractBenefitList
            dataTestId={'contract-energy-overview'}
            benefitsList={benefitsList}
          />
        </section>
      </div>
      {contract.shippingCountryCode === CountryCode.DE && (
        <ContractTermination isTerminationPending={contract.terminationRequested} />
      )}
    </Page>
  );
};

export const ContractEnergyOverview = compose<Props>(
  withContract,
  connect(mapStateToProps),
)(ContractEnergyOverviewComponent);
