export enum InfographicSpec {
  INFOGRAPHIC_ICON_BACKUPBOX = 'infographic-icon-backupbox',
  INFOGRAPHIC_ICON_BATTERY = 'infographic-icon-battery',
  INFOGRAPHIC_ICON_CHARGER = 'infographic-icon-charger',
  INFOGRAPHIC_ICON_COMMUNITY = 'infographic-icon-community',
  INFOGRAPHIC_ICON_CONSUMPTION = 'infographic-icon-consumption',
  INFOGRAPHIC_ICON_CONTRACT = 'infographic-icon-contract',
  INFOGRAPHIC_ICON_HEATER = 'infographic-icon-heater',
  INFOGRAPHIC_ICON_HOUSE = 'infographic-icon-house',
  INFOGRAPHIC_ICON_PV = 'infographic-icon-pv',
}
