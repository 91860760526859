import classNames from 'classnames';
import * as React from 'react';
import * as uuid from 'uuid';
import { Form, FormElement } from '../Form.component';

export interface FormItemProps {
  dataTestId?: string;
  label: string;
  floatingLabel: boolean;
  type?: string;
  autocomplete?: string;
  disabled?: boolean;
  suffix?: string;
  placeholder?: string;
  loading?: boolean;
}

export const Item: FormElement<FormItemProps> = ({
  dataTestId,
  label,
  input,
  suffix,
  type = 'text',
  meta: {
    active,
    touched,
    error,
  },
  floatingLabel = false,
  disabled = false,
  autocomplete = 'off',
  placeholder = '',
  loading,
}) => {
  const classes = {
    input: classNames('c-form__input', {
      'c-form__input--disabled': disabled || loading,
    }),
    label: classNames('c-form__label', {
      'c-form__label--floating': floatingLabel,
      'is-active': active || input.value || loading,
    }),
  };

  return (
    <div className="c-form__item">
      <input
        data-test-id={dataTestId}
        {...input}
        type={type}
        id={input.name}
        className={classes.input}
        disabled={disabled || loading}
        autoComplete={autocomplete}
        placeholder={placeholder}
      />
      {suffix && (
        <span className="c-form__item-suffix">
          {suffix}
        </span>
      )}
      <label
        className={classes.label}
        htmlFor={input.name}
      >
        {label}
      </label>
      {(touched && error) && (
        <Form.Info>
          {error.map((errorElement: any) => (
            <Form.Alert key={uuid.v4()}>
              {errorElement.label}
            </Form.Alert>
          ))}
        </Form.Info>
      )}
    </div>
  );
};
