import { GuideActions } from '+app/+guide/store/+guide.actions';
import { getPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { mapActions } from '+app/utils/redux';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';

const mapDispatchToProps = mapActions({
  logout: GuideActions.logout,
  goToRoot: () => push(getPath(RouteName.GUIDE)),
});

type Props = ReturnType<typeof mapDispatchToProps>;

const GuideLogoutComponent: React.FC<Props> = ({ actions }) => {
  React.useEffect(() => {
    actions.logout();
  }, []);

  return null;
};

export const GuideLogout = connect(undefined, mapDispatchToProps)(GuideLogoutComponent);
