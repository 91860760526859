import { T } from '@sonnen/shared-i18n/customer';
import { CountryCode, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import { Page } from '+app/shared/containers/Page';
import { StoreState } from '+app/store/store.interface';
import { TableList } from '+components';
import { factorizeContractTariffOverview } from '+contract/containers/ContractTariffOverview';
import { getCurrentSiteContractData } from '+contract/store/contract.selectors';
import { CheckList, CheckListItem } from '+shared/components';
import { NewsSection } from '+shared/containers/NewsSection';
import { ContractTermination } from '../../components';

const mapStateToProps = (state: StoreState) => ({
  contract: getCurrentSiteContractData(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const ContractTariffOverviewComponent: React.FC<Props> = ({ contract }) => {

  const isSonnenFlat = () => (
    contract && contract.contractType.toLowerCase() === 'sonnenflat'
  );

  const getProductDetailsKey = () => {
    if (!contract) {
      return;
    }

    const contractType = contract.contractType.toLowerCase();
    const tariffType = contract.tariffType?.toLowerCase();

    if (tariffType
      && Object.keys(I18n.t(T.yourTariff.details._all)).includes(contractType)
      && Object.keys(I18n.t(T.yourTariff.details[contractType]._all)).includes(tariffType)
    ) {
      return T.yourTariff.details[contractType][tariffType];
    }

    if (contractType === 'energy') {
      return T.yourTariff.details.energy;
    }

    if (isSonnenFlat()) {
      return T.yourTariff.details.sonnenflat.home;
    }

    return T.yourTariff.details.sonnenstrom.solar;
  };

  return (
    <Page headerTestId={'contract-tariff-overview-header'}>
      <NewsSection />
      {(contract) && (
        <div className={'o-grid'}>
          <section className={'o-grid__column o-grid__column--sm o-grid__column--no-gap-sm'}>
            <PageSubheadline mediumGap={true}>
              <Translate value={T.yourTariff.headlines.product} />
            </PageSubheadline>
            <TableList list={factorizeContractTariffOverview(contract)} />
          </section>
          <section className={'o-grid__column o-grid__column--sm o-grid__column--no-gap'}>
            {(contract.contractType.toLowerCase() !== 'energy') && (
              <>
                <PageSubheadline mediumGap={true}>
                  <Translate value={T.yourTariff.headlines.productDetails} />
                </PageSubheadline>
                <CheckList>
                  {Object.values(I18n.t(getProductDetailsKey())).map(item => (
                    <CheckListItem
                      dataTestId={'contract-tariff-overview-benefits-list-item'}
                      key={item}
                      label={item}
                    />
                  ))}
                </CheckList>
              </>
            )}
          </section>
        </div>
      )}
      {contract?.shippingCountryCode === CountryCode.DE && (
        <ContractTermination isTerminationPending={contract.terminationRequested} />
      )}
    </Page>
  );
};

export const ContractTariffOverview = connect(mapStateToProps)(ContractTariffOverviewComponent);
