import { storeProvider } from '+app/store/store.provider';
import { isFlatDirectConfiguration } from '../+acceptance/store/+acceptance.helper';
import { getAcceptedOrConfirmedOfferConfiguration, isFlatOfferAccepted, isHardwareOrderConfirmed } from '../+purchase/store/+purchase.selectors';
import { getCessionStatus } from '../store/+guide.selectors';
import { CessionStatus } from '../store/types';
import { StepName } from './steps.config';

export type StepsAccess = Record<StepName, boolean>;

export const getStepsAccess = (): StepsAccess => {
  const state = storeProvider.getState();

  const hardwareOrderConfirmed = isHardwareOrderConfirmed(state);
  const flatOfferAccepted = isFlatOfferAccepted(state);
  const cessionStatus = getCessionStatus(state);
  const acceptedOrConfirmedOfferConfiguration = getAcceptedOrConfirmedOfferConfiguration(state);
  const isConfigPostEeg = isFlatDirectConfiguration(acceptedOrConfirmedOfferConfiguration);
  const isCessionStepEnabled = flatOfferAccepted && cessionStatus !== CessionStatus.NEW;

  return {
    [StepName.DISTRIBUTION_TO_PARTNER]: true,
    [StepName.ON_SITE_VISIT]: true,
    [StepName.PREPARE_FOR_VISIT]: true,
    [StepName.ACCEPT_OFFER]: true,
    [StepName.CESSION_DOC]: Boolean(isCessionStepEnabled && !isConfigPostEeg),
    [StepName.FLAT_ORDER_CONFIRMATION]: Boolean(hardwareOrderConfirmed || flatOfferAccepted),
    [StepName.HARDWARE_ORDER_CONFIRMATION]: Boolean(hardwareOrderConfirmed),
    [StepName.COMMON_ORDER_CONFIRMATION]: Boolean(!hardwareOrderConfirmed && !flatOfferAccepted),
    [StepName.TAX_OFFICE_REGISTRATION]: true,
    [StepName.BNETZA_REGISTRATION]: true,
    [StepName.DSO_REGISTRATION]: true,
    [StepName.HARDWARE_COMMISSIONING]: true,
    [StepName.DSO_METER_INSTALLATION]: true,
    [StepName.BNETZA_REGISTRATION_PARTNER]: true,
  };
};
