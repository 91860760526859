import { getGuideCommonState, getGuideOfferCollection } from '+guide/store/+guide.selectors';
import { ImpactAnalysis, Offer } from '+guide/store/types';
import { findElementById } from '+utils/array.util';
import { last, memoize } from 'lodash';
import { createSelector } from 'reselect';

export const getOfferById = createSelector(
  getGuideOfferCollection,
  offers => memoize((params: { offerId: string }) => findElementById(offers, params.offerId)),
);

export const getPrimaryFlatOfferDocument = (offer?: Offer) => offer ? last(offer.flatOffers) : undefined;

export const getPrimaryFlatOfferDocumentKey = createSelector(
  getOfferById,
  (offerById) => (params: { offerId: string }) => {
    const offerDocument = getPrimaryFlatOfferDocument(offerById(params));
    return offerDocument && offerDocument.id;
  },
);

export const getPrimaryFlatConfigurationDocument = (offer?: Offer) =>
  offer ? last(offer.flatConfigurations) : undefined;

export const getPrimaryFlatConfigurationDocumentKey = createSelector(
  getOfferById,
  (offerById) => (params: { offerId: string }) => {
    const offerDocument = getPrimaryFlatConfigurationDocument(offerById(params));
    return offerDocument && offerDocument.id;
  },
);

export const getPrimaryFlatOfferDocumentStatus = createSelector(
  getOfferById,
  (offerById) => (params: { offerId: string }) => {
    const offerDocument = getPrimaryFlatOfferDocument(offerById(params));
    return offerDocument && offerDocument.status;
  },
);

export const getPrimaryFlatConfigurationDocumentStatus = createSelector(
  getOfferById,
  (offerById) => (params: { offerId: string }) => {
    const configurationDocument = getPrimaryFlatConfigurationDocument(offerById(params));
    return configurationDocument && configurationDocument.status;
  },
);

export const getImpactAnalysisById = createSelector(
  getGuideCommonState,
  state => memoize((params: {
    impactAnalysisId: string,
  }) => findElementById(state.impactAnalysisList, params.impactAnalysisId)),
);

const getPrimaryImpactAnalysisDocument = (impactAnalysis?: ImpactAnalysis) =>
  impactAnalysis ? last(impactAnalysis.documents) : undefined;

export const getPrimaryImpactAnalysisDocumentStatus = createSelector(
  getImpactAnalysisById,
  impactAnalysisById => (params: { impactAnalysisId: string }) => {
    const document = getPrimaryImpactAnalysisDocument(impactAnalysisById(params));
    return document && document.status;
  },
);

export const getPrimaryImpactAnalysisDocumentKey = createSelector(
  getImpactAnalysisById,
  impactAnalysisById => (params: { impactAnalysisId: string }) => {
    const document = getPrimaryImpactAnalysisDocument(impactAnalysisById(params));
    return document && document.id;
  },
);

export const getOfferConfiguration = (offer: Offer | undefined) =>
  offer && offer.configuration;

export const getOfferDates = createSelector(
  getGuideOfferCollection,
  offers => offers.map(offer => offer.updatedAt).sort((a, b) => {
    return +new Date(b) - +new Date(a);
  }),
);
