export enum Env {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  DEMO = 'demo',
  PRODUCTION = 'production',
  LOCAL = 'local',
}

export const getFilteredEnvironment = (env: Env) => {
  switch (env) {
    case Env.LOCAL:
    case Env.DEVELOPMENT:
    case Env.STAGING:
    case Env.DEMO:
    case Env.PRODUCTION:
      return env;
    default:
      throw new Error(`Invalid environment ${env}`);
  }
};

export const getEnvironment = () => getFilteredEnvironment(process.env.CONFIG as Env || Env.PRODUCTION);

export const isMobile = process.env.IS_MOBILE === 'true' || navigator.userAgent === 'sonnen CustomerPortal Mobile';
