import { T } from '@sonnen/shared-i18n/customer';
import { Icon, Link } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GuideSetupActions } from '+app/+guide/+setup/store';
import { getSetupTaxOfficeRegistrationDate, getSetupTaxOfficeRegistrationStatus } from '+app/+guide/+setup/store/+setup.selectors';
import { GuideGridStretched, GuideInfoCard, GuideInfoCardList } from '+app/+guide/components';
import { GuideAccordion } from '+app/+guide/containers';
import { GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StepName } from '+app/+guide/helpers/steps.config';
import { LinkedTranslation } from '+app/shared/components';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { getFormattedDate } from '+legacy/helpers/dates';

const mapStateToProps = (state: StoreState) => ({
  stepStatus: getSetupTaxOfficeRegistrationStatus(state),
  stepDate: getSetupTaxOfficeRegistrationDate(state),
});

const mapDispatchToProps = mapActions({
  checkTaxRegistration: GuideSetupActions.checkTaxOfficeRegistration,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteComponentProps
;

export const GuideSetupStepTaxOfficeRegistrationComponent: React.FC<Props> = ({
  stepStatus,
  stepDate,
  actions,
}) => {
  const isStepChecked = stepStatus === GuideStepStatus.DONE;

  const content = {
    [GuideStepStatus.ACTIVE]: {
      headline: I18n.t(T.customerGuidance.setup.step.taxRegistration.active.headline),
      tag: I18n.t(T.customerGuidance.tag.text),
      status: 'open',
    },
    [GuideStepStatus.DONE]: {
      headline: I18n.t(T.customerGuidance.setup.step.taxRegistration.done.headline),
      tag: I18n.t(T.customerGuidance.tag.done),
      description: stepDate && getFormattedDate(stepDate),
      status: 'done',
    },
  };

  return (
    <GuideAccordion
      status={stepStatus}
      anchor={StepName.TAX_OFFICE_REGISTRATION}
      title={content[stepStatus].headline}
      tag={content[stepStatus].tag}
      tagTheme={content[stepStatus].status}
      date={content[stepStatus].date}
      isChecked={isStepChecked}
      onCheckboxToggle={() => {
        actions.checkTaxRegistration(isStepChecked);
      }}
    >
      {{
        [GuideStepStatus.ACTIVE]: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.taxRegistration.active.information.headline)}
              headerIcon={<Icon.Info />}
            >
              {I18n.t(T.customerGuidance.setup.step.taxRegistration.active.information.description)}
            </GuideInfoCard>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.taxRegistration.active.yourToDo.headline)}
              headerIcon={<Icon.Contract />}
              isIconBig={true}
            >
              <GuideInfoCardList>
                {I18n.t(T.customerGuidance.setup.step.taxRegistration.active.yourToDo.description.taxStatus)}
                {I18n.t(T.customerGuidance.setup.step.taxRegistration.active.yourToDo.description.register)}
                {I18n.t(T.customerGuidance.setup.step.taxRegistration.active.yourToDo.description.receive)}
              </GuideInfoCardList>
            </GuideInfoCard>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.taxRegistration.active.backgroundInformation.headline)}
              headerIcon={<Icon.Info />}
            >
              <LinkedTranslation
                  sentence={
                    I18n.t(T.customerGuidance.setup.step.taxRegistration.active.backgroundInformation.description)
                  }
                  links={[
                    <Link
                      key={'Tax handling'}
                      href={'https://sonnen.de/blog/eigenverbrauch-umsatzsteuer-ertragssteuer-die-steuerliche-behandlung-einer-photovoltaikanlage-mit/'}
                      label={
                        I18n.t(T.customerGuidance.setup.step.taxRegistration.active.backgroundInformation.taxHandling)
                      }
                    />,
                    <Link
                      key={'Faq'}
                      href={'https://sonnen.de/haeufig-gestellte-fragen-zum-thema-steuern-sonnenflat/'}
                      label={I18n.t(T.customerGuidance.setup.step.taxRegistration.active.backgroundInformation.faq)}
                    />,
                  ]}
                />
            </GuideInfoCard>
          </GuideGridStretched>
        ),
        [GuideStepStatus.DONE]: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.taxRegistration.done.information.headline)}
              headerIcon={<Icon.Info />}
            >
              {I18n.t(T.customerGuidance.setup.step.taxRegistration.done.information.description)}
            </GuideInfoCard>
          </GuideGridStretched>
        ),
      }}
    </GuideAccordion>
  );
};

export const GuideSetupStepTaxOfficeRegistration =
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GuideSetupStepTaxOfficeRegistrationComponent));
