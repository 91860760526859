import { getPaths } from '+app/router/router.helper';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, ButtonTheme } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './GuideNextStepButton.component.scss';

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & {
    to: 'discovery' | 'purchase' | 'setup' | 'usage';
  }
;

const mapDispatchToProps = mapActions({
  goToDiscovery: () => push(getPaths().GUIDE_DISCOVERY),
  goToPurchase: () => push(getPaths().GUIDE_PURCHASE),
  goToSetup: () => push(getPaths().GUIDE_SETUP),
  goToUsage: () => push(getPaths().GUIDE_USAGE),
});

export const GuideNextStepButtonComponent: React.FC<Props> = ({ to, actions }) => {
  const steps = {
    discovery: {
      link: actions.goToDiscovery,
      label: I18n.t(T.customerGuidance.nextStepButton.toPlanning),
    },
    purchase: {
      link: actions.goToPurchase,
      label: I18n.t(T.customerGuidance.nextStepButton.toPurchase),
    },
    setup: {
      link: actions.goToSetup,
      label: I18n.t(T.customerGuidance.nextStepButton.toSetup),
    },
    usage: {
      link: actions.goToUsage,
      label: I18n.t(T.customerGuidance.nextStepButton.toUsage),
    },
  };

  return (
    <>
      <div className={'c-guide-next-step-button__title'}>
        {
          I18n.t(T.customerGuidance.nextStepButton.title)
          + `\n${I18n.t(T.customerGuidance.nextStepButton.headline)}`
        }
      </div>
      <div className={'c-guide-next-step-button__label'}>
          <Button
            label={steps[to].label}
            onClick={steps[to].link}
            size={ButtonSize.SECONDARY}
            theme={ButtonTheme.OUTLINE}
            />
      </div>
    </>
  );
};

export const GuideNextStepButton = connect(null, mapDispatchToProps)(GuideNextStepButtonComponent);
