// TODO: transfer this component to +shared,
// as the community map logic is tranfered to the new app structure +app
import { Product } from '+account/store/types/profile.interface';
import { Product as ProductComponent } from '+components';
import { isNil } from 'lodash';
import * as React from 'react';
import * as uuid from 'uuid';
import { mapProductKeyToAccessory } from './AccountProfileCardAccessories.helper';

interface Props {
  accessoriesList: Product[] | undefined;
}

export const AccountProfileCardAccessories: React.FC<Props> = ({
  accessoriesList,
}) => {
  if (!accessoriesList || !accessoriesList.length) {
    return null;
  }

  return (
    <>
      {accessoriesList
        .map(el => mapProductKeyToAccessory(el))
        .filter(el => !isNil(el))
        .map(accessory => (
          <ProductComponent
            title={accessory.title}
            icon={accessory.icon}
            key={uuid.v4()}
          />
      ))}
    </>
  );
};
