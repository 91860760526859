import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

import { UtilityDataMovingDetailsForm } from '../../containers';
import { UtilityChangePeriod } from '../GuideAcceptanceUtilityMoveHouse/GuideAcceptanceUtilityMoveHouse.helper';

export const movingDetailsFormInitial: UtilityDataMovingDetailsForm = {
  movingDate: undefined,
  houseType: undefined,
};

export const calculateMinMovingDate = () => {
  const date = new Date();
  const weeksOffset = 4;
  date.setHours(0, 0, 0, 0); // clear hours, mins, secs and milis
  date.setDate(date.getDate() - weeksOffset * 7);

  return date;
};

export const UtilityMovingDetailsSchema = Yup.object().shape({
  movingDate: Yup.date()
    .when('isMovingHouses', {
      is: UtilityChangePeriod.NOT_MOVED,
      otherwise: Yup.date()
        .min(calculateMinMovingDate(), () => I18n.t(T.customerGuidance.acceptance.errors.tooEarlyDate))
        .required(() => I18n.t(T.customerGuidance.acceptance.requiredField)),
    }),
});
