import { useFeature } from '@sonnen/shared-web';
import { SagaIterator } from 'redux-saga';
import { delay, put, select, takeEvery } from 'redux-saga/effects';

import { getElectricUnits } from '+app/+dashboard/+battery/store/battery.selectors';
import { FeatureName } from '+config/featureFlags';
import { APP_ACTIONS, ToggleOnlineState } from '+legacy/core/app/app.actions';
import { SITE_ACTIONS } from '+shared/store/site';
import { combineSagas, dataGuard, processQuery } from '+utils/saga';
import { NOTIFICATION_ACTIONS, NotificationActions } from './notification.actions';
import { createOfflineNotification, NotificationsIds } from './notification.factory';
import { NotificationHelper } from './notification.helper';
import { NotificationsRepository } from './notification.repository';
import { getSystemCaseNotifications, getSystemComponentNotifications } from './notification.selectors';
import {
  GET_SYSTEM_NOTIFICATIONS_QUERY,
  SystemNotification,
  SystemNotificationType,
  UPDATE_SYSTEM_NOTIFICATION_QUERY,
} from './types/systemNotification';

const HIDE_TIMEOUT = 15 * 1000;

function * handleOnlineState(action: ToggleOnlineState): SagaIterator {
  return !action.status
    ? yield put(NotificationActions.addNotification(createOfflineNotification()))
    : yield put(NotificationActions.removeNotification(NotificationsIds.OFFLINE));
}

function * handleNotificationsClear(): SagaIterator {
  yield put(NotificationActions.clearNotifications());
}

function * handleNotificationTimeout(action: ReturnType<typeof NotificationActions.addNotification>): SagaIterator {
  if (action.notification.isPermanent) { return; }

  yield delay(HIDE_TIMEOUT);
  yield put(NotificationActions.removeNotification(action.notification.id));
}

function * getSystemNotifications(type: SystemNotificationType): SagaIterator {
  if (useFeature(FeatureName.SYSTEM_NOTIFICATIONS).isDisabled) { return; }

  yield processQuery(
    GET_SYSTEM_NOTIFICATIONS_QUERY,
    NotificationsRepository.getSystemNotifications,
    {
      onSuccess: res => dataGuard(type === SystemNotificationType.CASE
        ? NotificationActions.setSystemCaseNotifications
        : NotificationActions.setSystemComponentNotifications,  
      )(res!),
    },
  )(type);
}

function * updateSystemNotification(type: SystemNotificationType): SagaIterator {
  if (useFeature(FeatureName.SYSTEM_NOTIFICATIONS).isDisabled) { return; }
  
  const batteryElectricUnits = yield select(getElectricUnits);
  const activeNotification: SystemNotification | undefined = type === SystemNotificationType.CASE
    ? NotificationHelper.getActiveSystemCaseNotification(yield select(getSystemCaseNotifications))
    : NotificationHelper.getActiveSystemComponentNotification(
        yield select(getSystemComponentNotifications),
        batteryElectricUnits,
      );

  if (!activeNotification) { return; }

  yield processQuery(
    UPDATE_SYSTEM_NOTIFICATION_QUERY,
    NotificationsRepository.updateSystemNotification,
    {
      onSuccess: () => dataGuard(NotificationActions.getSystemNotifications)(type),
    },
  )({
    id: (activeNotification as SystemNotification).id,
    attributes: { read: true },
  });
}

export const notificationSagas = combineSagas(
  takeEvery(APP_ACTIONS.TOGGLE_ONLINE_STATE, handleOnlineState),
  takeEvery(SITE_ACTIONS.SET_ACTIVE_SITE, handleNotificationsClear),
  takeEvery(NOTIFICATION_ACTIONS.ADD_NOTIFICATION, handleNotificationTimeout),
  takeEvery(
    NOTIFICATION_ACTIONS.GET_SYSTEM_COMPONENT_NOTIFICATIONS,
    getSystemNotifications,
    SystemNotificationType.COMPONENT,
  ),
  takeEvery(
    NOTIFICATION_ACTIONS.GET_SYSTEM_CASE_NOTIFICATIONS,
    getSystemNotifications,
    SystemNotificationType.CASE,
  ),
  takeEvery(
    NOTIFICATION_ACTIONS.UPDATE_SYSTEM_COMPONENT_NOTIFICATION,
    updateSystemNotification,
    SystemNotificationType.COMPONENT,
  ),
  takeEvery(
    NOTIFICATION_ACTIONS.UPDATE_SYSTEM_CASE_NOTIFICATION,
    updateSystemNotification,
    SystemNotificationType.CASE,
  ),
);
