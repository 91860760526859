import { TimeHelper, TimeUnit } from '@sonnen/shared-web';
import { Moment } from 'moment';

import { BatteryHelper } from '+app/+dashboard/+battery/store/battery.helpers';
import { Battery } from '+app/+dashboard/+battery/store/types/battery.interface';
import { QueryState } from '+app/shared/store/query/query.state';

const isDelayMessageVisible = ({
  batteryData,
  hasMeasurements,
  timestamp,
  measurementsQueryStatus,
  selectedDate,
}: {
  batteryData: Battery,
  hasMeasurements: boolean,
  timestamp: string | undefined,
  measurementsQueryStatus: QueryState,
  selectedDate: Moment,
}): boolean => {
  const isTodaySelected = TimeHelper.isCurrent(TimeUnit.DAY)(selectedDate);

  return Boolean(isTodaySelected
    && measurementsQueryStatus.success
    && hasMeasurements
    && timestamp && BatteryHelper.isDataDelayed(new Date(timestamp), batteryData && batteryData.controllerType));
};

export const AnalysisProviderHelper = {
  isDelayMessageVisible,
};
