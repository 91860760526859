import * as React from 'react';

import { AnalysisBarChartProvider } from './AnalysisBarChartProvider';
import { AnalysisDayChartProvider } from './AnalysisDayChartProvider';
import { AnalysisKanvaProviderProps } from './AnalysisKanvaProvider.types';

export const AnalysisKanvaProvider: React.FC<AnalysisKanvaProviderProps> = (props) => (
  <AnalysisDayChartProvider {...props}>
    <AnalysisBarChartProvider {...props}>
      {props.children}
    </AnalysisBarChartProvider>
  </AnalysisDayChartProvider>
);
