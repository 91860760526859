import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as uuid from 'uuid';

import { AnalysisActions } from '+analysis/store/analysis.actions';
import { getMobileAppState } from '+app/+mobile/store/mobile.selectors';
import { getRouteNameFromPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { getRouterLocationPath } from '+app/router/store/router.selectors';
import { AUTH_ACTIONS } from '+auth/store/auth.actions';
import { CONFIG } from '+config';
import { MobileAppState } from '+mobile';
import { NotificationActions } from '+shared/store/notification/notification.actions';
import { getActiveSiteId, getChargerId } from '+shared/store/site/site.selectors';
import { combineSagas, dataGuard, handleDataPolling, processQuery } from '+utils/saga';
import { LIVE_ACTIONS, LiveActions } from './live.actions';
import { LiveRepository } from './live.repository';
import {
  getChargerLiveDataQueryErrorCount,
  getSiteLiveDataQueryConsecutiveErrorCount,
} from './live.selectors';
import { GET_CHARGER_LIVE_DATA_QUERY, GET_SITE_LIVE_DATA_QUERY, LiveDataPollingType } from './live.state';

type StartPollingAaction = ReturnType<typeof LiveActions.startLiveDataPolling>;

const ERROR_SITE_NOTIFICATION_ID = uuid.v4();
const ERROR_CHARGER_NOTIFICATION_ID = uuid.v4();

const DEFAULT_POLLING_INTERVAL = 15000;
const POLLING_INTERVAL_CONFIG: Partial<Record<RouteName, number>> = {
  [RouteName.DASHBOARD]: 60000,
  [RouteName.LIVE_STATE]: 5000,
  [RouteName.ANALYSIS]: 10000,
  [RouteName.BATTERY_OVERVIEW]: 60000,
};

const POLLING_ACTIONS: Record<LiveDataPollingType, string> = {
  site: LIVE_ACTIONS.GET_SITE_LIVE_DATA,
  charger: LIVE_ACTIONS.GET_CHARGER_LIVE_DATA,
};

function* startLiveDataPolling({ pollingType }: StartPollingAaction) {
  const activePath = yield select(getRouterLocationPath);
  const mobileAppState: MobileAppState = yield select(getMobileAppState);
  const activeRoute = getRouteNameFromPath(activePath);

  if (CONFIG.IS_MOBILE && mobileAppState !== MobileAppState.ACTIVE) {
    return;
  }

  yield handleDataPolling({
    fetchAction: POLLING_ACTIONS[pollingType],
    endAction: [LIVE_ACTIONS.STOP_LIVE_DATA_POLLING, AUTH_ACTIONS.LOGOUT],
    interval: activeRoute && POLLING_INTERVAL_CONFIG[activeRoute] || DEFAULT_POLLING_INTERVAL,
  });
}

export function* getSiteLiveData(action?: AnyAction): SagaIterator {
  const siteId: string | undefined = yield select(getActiveSiteId);

  if (!siteId) { return; }

  const errorCount: number = yield select(getSiteLiveDataQueryConsecutiveErrorCount);

  yield processQuery(
    GET_SITE_LIVE_DATA_QUERY,
    LiveRepository.getSiteLiveData, {
      onSuccess: res => errorCount
        ? all([
            dataGuard(LiveActions.setSiteLiveData)(res!),
            put(AnalysisActions.updateLiveAreaChartSeries(res!)),
            put(NotificationActions.removeNotification(ERROR_SITE_NOTIFICATION_ID)),
          ])
        : all([
            dataGuard(LiveActions.setSiteLiveData)(res!),
            put(AnalysisActions.updateLiveAreaChartSeries(res!)),
          ]),
    },
  )(siteId);
}

export function* getChargerLiveData(action?: AnyAction): SagaIterator {
  const chargerId: string | undefined = yield select(getChargerId);

  if (!chargerId) { return; }

  const errorCount: number = yield select(getChargerLiveDataQueryErrorCount);

  yield processQuery(
    GET_CHARGER_LIVE_DATA_QUERY,
    LiveRepository.getChargerLiveData, {
      onSuccess: res => errorCount
        ? all([
            dataGuard(LiveActions.setChargerLiveData)(res!),
            put(NotificationActions.removeNotification(ERROR_CHARGER_NOTIFICATION_ID)),
          ])
        : dataGuard(LiveActions.setChargerLiveData)(res!),
    },
  )(chargerId);
}

export const liveSagas = combineSagas(
  takeEvery(LIVE_ACTIONS.START_LIVE_DATA_POLLING, startLiveDataPolling),
  takeLatest(LIVE_ACTIONS.GET_SITE_LIVE_DATA, getSiteLiveData),
  takeLatest(LIVE_ACTIONS.GET_CHARGER_LIVE_DATA, getChargerLiveData),
);
