import { createReducer } from '+utils/redux';
import { GUIDE_DISCOVERY_ACTIONS, GuideDiscoveryActions } from './+discovery.actions';
import { GuideDiscoveryModalType, GuideDiscoveryState, initialState } from './+discovery.state';

export const reducer = createReducer<GuideDiscoveryState, GuideDiscoveryActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case GUIDE_DISCOVERY_ACTIONS.TOGGLE_MODAL:
        return {
          ...state,
          activeModal: action.checked ? GuideDiscoveryModalType.UNCHECKED : GuideDiscoveryModalType.CHECKED,
        };
      case GUIDE_DISCOVERY_ACTIONS.CLOSE_MODAL:
        return {
          ...state,
          activeModal: null,
        };
      default:
        return state;
    }
  },
  {
    persist: {
      key: 'discovery',
    },
  },
);
