import { LineChartViewStyle } from '@kanva/charts';
import { Paint, rgba, TextBaseline } from '@kanva/core';
import { AccessoriesSeriesKey, AnalysisChartColors, CHART_FONT, SharedChartColors } from '@sonnen/shared-web';

export const textPaint = new Paint()
  .setFillStyle(rgba(SharedChartColors.DARK, .25))
  .setFont({
    ...CHART_FONT,
    fontSize: 10,
  })
  .setTextBaseline(TextBaseline.MIDDLE);

export const lineChartStyle: LineChartViewStyle = {
  foreground: {
    paint: new Paint().setFillStyle(AnalysisChartColors[AccessoriesSeriesKey.HEATER_POWER]),
    width: 8,
    radius: 8,
  },
  background: {
    paint: new Paint().setFillStyle(rgba(SharedChartColors.DARK, .05)),
    width: 4,
    radius: 4,
  },
  minChunkLength: { domain: .1, px: 1 },
};
