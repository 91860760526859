import { T } from '@sonnen/shared-i18n/customer';
import { BoldParagraph, breakpointUp, Bubble, Icon, LinkButton, Modal, ModalLayout } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import { get } from 'lodash';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { FormFieldObserver } from '+app/+guide/components/FormFieldObserver';
import { GuideControlledCheckbox } from '+app/+guide/components/GuideControlledCheckbox';
import { UtilityData } from '+app/+guide/store/types';
import { FormInputSubscriptionPayload } from '+app/shared/hooks/useDispatchInputEvent';
import { FormInput } from '+shared/components';
import { formFields, UtilityDataForm } from '../../containers';
import { GuideAcceptancePrefilledLine } from '../GuideAcceptancePrefilledLine';
import { UtilityChangePeriod } from '../GuideAcceptanceUtilityMoveHouse';

import meterWide from './meter-id-wide.png';
import meter from './meter-id.png';

import './GuideAcceptanceUtilityMeterId.component.scss';
interface Props {
  form: FormikProps<UtilityDataForm>;
  toggleScroll: (arg: boolean) => void;
  utilityData: UtilityData;
}

export const GuideAcceptanceUtilityMeterId = ({
  form, toggleScroll, utilityData,
}: Props) => {
  const [isModalVisible, toggleModalVisibility] = React.useState<boolean>(false);
  const [isMeterIdEditMode, setMeterIdEditMode] = React.useState<boolean>(!utilityData!.meterId);
  const [isTooltipActive, toggleTooltipActivity] = React.useState<boolean>(false);

  React.useEffect(() => {
    form.setFieldValue(formFields.METER_ID, get(utilityData, 'meterId', ''));
  }, [utilityData]);

  React.useEffect(() => {
    if (form.values.isMovingHouses === UtilityChangePeriod.NOT_MOVED) {
      form.setFieldValue(formFields.IS_METER_ID_NOT_KNOWN, false);
    }
  }, [form.values.isMovingHouses]);

  const onCheckboxChange = (form: FormikProps<UtilityDataForm>) =>
    (payload: FormInputSubscriptionPayload<UtilityDataForm>) => {
      form.setFieldTouched(formFields.METER_ID, true);
      if (payload.value) {
        form.setFieldValue(formFields.METER_ID, '');
        setMeterIdEditMode(true);
      }
      if (!payload.value && utilityData.meterId) {
        form.setFieldValue(formFields.METER_ID, utilityData.meterId);
        setMeterIdEditMode(false);
      }
    };

  const onMeterIdBlur = (form: FormikProps<UtilityDataForm>) =>
    () => form.setFieldTouched(formFields.IS_METER_ID_NOT_KNOWN, true);

  return (
    <div className={'c-guide-acceptance-utility-meter-id'}>
      <div className={'c-guide-acceptance-utility-meter-id__text'}>
        <BoldParagraph>
          {I18n.t(T.customerGuidance.acceptance.utilityData.meterId.header)}
        </BoldParagraph>
      </div>

      <div className={'c-guide-acceptance-utility-meter-id__link-wrapper'}>
        <p className={'c-guide-acceptance-utility-meter-id__link-question'}>
          {I18n.t(T.customerGuidance.acceptance.utilityData.meterId.modal.meterQuestion)}
        </p>
        <LinkButton
          icon={<Icon.Info />}
          iconClass={'c-guide-acceptance-utility-meter-id__link-icon'}
          onClick={() => {
            toggleModalVisibility(true);
            toggleScroll(true);
          }}>
          {I18n.t(T.customerGuidance.acceptance.utilityData.meterId.modal.meterInstruction)}
        </LinkButton>
      </div>

      <div className={'c-guide-acceptance-utility-meter-id__input'}>
        {isMeterIdEditMode ?
          (
            <FormFieldObserver<UtilityDataForm> onBlur={onMeterIdBlur(form)}>
              <FormInput
                form={form}
                placeholder={I18n.t(T.customerGuidance.acceptance.utilityData.meterId.placeholder)}
                name={formFields.METER_ID}
                type={'text'}
                hasNoGap={true}
                id={'guide-acceptance-utility-meter-id'}
                disabled={form.values.isMeterIdNotKnown}
              />
            </FormFieldObserver>
          ) : (
            <GuideAcceptancePrefilledLine
              onClick={() => setMeterIdEditMode(true)}
              value={utilityData!.meterId}
            />
          )
        }
      </div>
      {form.values.isMovingHouses !== UtilityChangePeriod.NOT_MOVED &&
        <div className={'c-guide-acceptance-utility-meter-id__checkbox-wrapper'}>
          <FormFieldObserver<UtilityDataForm> onChange={onCheckboxChange(form)}>
            <div className={'c-guide-acceptance-utility-meter-id__input'}>
              <GuideControlledCheckbox
                form={form}
                name={formFields.IS_METER_ID_NOT_KNOWN}
                label={I18n.t(T.customerGuidance.acceptance.utilityData.meterId.checkbox)}
              />
            </div>
          </FormFieldObserver>
          <div
            onMouseOver={() => toggleTooltipActivity(true)}
            onMouseLeave={() => toggleTooltipActivity(false)}
            className={'c-guide-acceptance-utility-meter-id__tooltip'}
          >
            <Icon.Info className={'c-guide-acceptance-utility-meter-id__icon-info'} />
            {isTooltipActive &&
              <div className={'c-guide-acceptance-utility-meter-id__bubble-wrapper'} >
                <Bubble
                  side={window.innerWidth >= breakpointUp('SM') ? 'top' : 'top-right'}
                  isThemeDense={false}
                >
                  <Translate
                    value={I18n.t(T.customerGuidance.acceptance.utilityData.meterId.checkboxInfo)}
                    dangerousHTML={true}
                  />
                </Bubble>
              </div>
            }
          </div>
        </div>
      }
      <Modal
        size={'small'}
        isOpen={isModalVisible}
        onClose={() => {
          toggleModalVisibility(false);
          toggleScroll(false);
        }}
      >
        <ModalLayout
          title={I18n.t(T.customerGuidance.acceptance.utilityData.meterId.modal.title)}
        >
          <BoldParagraph>
            {I18n.t(T.customerGuidance.acceptance.utilityData.meterId.modal.imgTitle)}
          </BoldParagraph>
          <div className={'c-guide-acceptance-utility-meter-id__meters'}>
            <img src={meterWide} alt={'meter id wide'} className={'c-guide-acceptance-utility-meter-id__meter-img'} />
            <img src={meter} alt={'meter id'} className={'c-guide-acceptance-utility-meter-id__meter-img'} />
          </div>
          <BoldParagraph>
            {I18n.t(T.customerGuidance.acceptance.utilityData.meterId.modal.question)}
          </BoldParagraph>
          <p className={'c-guide-acceptance-utility-meter-id__info'}>
            <span className={'c-guide-acceptance-utility-meter-id__info--bold'}>
              {I18n.t(T.customerGuidance.acceptance.utilityData.meterId.modal.boldHint)}
            </span>{' '}
            {I18n.t(T.customerGuidance.acceptance.utilityData.meterId.modal.regularHint)}
          </p>
        </ModalLayout>
      </Modal>
    </div>
  );
};
