import { T } from '@sonnen/shared-i18n/customer';
import { BatteryCardModal, BatteryInverterState } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { BatteryActions } from '+app/+dashboard/+battery/store';
import { getBackupBufferProcessingValue, getBatteryData } from '+app/+dashboard/+battery/store/battery.selectors';
import { hasContractTypeSonnenFlatHome, hasContractTypeSonnenFlatX } from '+app/+dashboard/+contract/store/contract.selectors';
import { DashboardActions, isModalBackupBufferVisible } from '+app/+dashboard/store';
import { InfoBanner } from '+app/shared/components';
import { getSiteLiveData } from '+app/shared/store/live/live.selectors';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { NotificationActions } from '+shared/store/notification/notification.actions';
import { NotificationsIds } from '+shared/store/notification/notification.factory';

const mapStateToProps = (state: StoreState) => ({
  backupBufferProcessingValue: getBackupBufferProcessingValue(state),
  batteryData: getBatteryData(state),
  isModalVisible: isModalBackupBufferVisible(state),
  isSonnenFlatHomeContract: hasContractTypeSonnenFlatHome(state),
  isSonnenFlatXContract: hasContractTypeSonnenFlatX(state),
  siteLiveData: getSiteLiveData(state),
});

const mapDispatchToProps = mapActions({
  toggleModal: DashboardActions.toggleModalBackupBuffer,
  setBackupBuffer: BatteryActions.setBackupBuffer,
  removeNotification: NotificationActions.removeNotification,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const DashboardModalBackupBufferComponent: React.FC<Props> = ({
  backupBufferProcessingValue,
  batteryData,
  isModalVisible,
  isSonnenFlatHomeContract,
  isSonnenFlatXContract,
  siteLiveData,
  actions: { toggleModal, removeNotification, setBackupBuffer },
}) => {
  const sliderInitialValue = backupBufferProcessingValue || batteryData.backupPowerBuffer || 0;
  const [sliderBackupLevel, setSliderBackupLevel] = React.useState([sliderInitialValue]);

  const isSonnenFlatXBannerVisible = isSonnenFlatXContract && sliderBackupLevel[0] > 20;

  const setSliderToInitialValue = () => setSliderBackupLevel([sliderInitialValue]);

  const handleSliderUpdate = (backupLevel: number[]) => setSliderBackupLevel(backupLevel);

  const handleClose = () => {
    toggleModal();
    setSliderToInitialValue();
  };

  const handleSubmit = (backupLevel: number) => {
    toggleModal();
    removeNotification(NotificationsIds.BACKUP_BUFFER_SETUP_FAILED);
    setBackupBuffer(
      backupLevel,
    );
  };

  const renderInfoBanner = () => {
    switch (true) {
      case isSonnenFlatHomeContract:
        return (
          <InfoBanner
            message={I18n.t(T.batterySystem.backupBuffer.modalInfoBanner.flatHome.title)}
            longMessage={I18n.t(T.batterySystem.backupBuffer.modalInfoBanner.flatHome.description)}
          />
        );
      case isSonnenFlatXBannerVisible:
        return (
          <InfoBanner
            message={I18n.t(T.batterySystem.backupBuffer.modalInfoBanner.flatX.title)}
            longMessage={I18n.t(T.batterySystem.backupBuffer.modalInfoBanner.flatX.description)}
            isWarning={true}
          />
        );
      default:
        return null;
    }
  };

  React.useEffect(() => {
    setSliderToInitialValue();
  }, [backupBufferProcessingValue, batteryData]);

  return (
    <BatteryCardModal
      isOpen={isModalVisible}
      batteryBackupLevel={backupBufferProcessingValue || batteryData.backupPowerBuffer || 0}
      batteryChargeLevel={siteLiveData && siteLiveData.batteryUsoc || 0}
      batteryStatus={BatteryInverterState.CHARGING}
      cancelLabel={I18n.t(T.batterySystem.backupBuffer.modalCancel)}
      confirmLabel={I18n.t(T.batterySystem.backupBuffer.modalConfirm)}
      description={I18n.t(T.batterySystem.backupBuffer.modalDescriptionIntro)}
      expandableDescription={I18n.t(T.batterySystem.backupBuffer.modalDescriptionExpandable)}
      headline={I18n.t(T.batterySystem.backupBuffer.modalHeadline)}
      hasBackupDevice={true}
      hideBtnLabel={I18n.t(T.batterySystem.backupBuffer.modalReadLess)}
      infoBanner={renderInfoBanner()}
      isWarningIcon={isSonnenFlatXBannerVisible}
      readMoreBtnLabel={I18n.t(T.batterySystem.backupBuffer.modalReadMore)}
      sliderBackupLevel={sliderBackupLevel}
      sliderLabel={I18n.t(T.batterySystem.backupBuffer.modalSliderHeader)}
      handleClose={handleClose}
      handleSliderUpdate={handleSliderUpdate}
      handleSubmit={handleSubmit}
    />
  );
};

export const DashboardModalBackupBuffer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardModalBackupBufferComponent);
