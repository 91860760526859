import { ACCEPT_OFFER_QUERY } from '+app/+guide/+acceptance/store';
import { StoreState } from '+app/store/store.interface';
import { getStatus } from '+app/utils/query.util';
import { createSelector } from 'reselect';
import {
  CREATE_CESSION_QUERY,
  CREATE_PRODUCT_CONFIGURATION_QUERY,
  GET_CESSION_QUERY,
  GET_DSO_LIST_QUERY,
  GET_PRODUCT_CONFIGURATION_QUERY,
} from './productMigration.state';

const getProductMigrationState = (state: StoreState) => state.productMigration;

export const getProductMigrationStatus = createSelector(
  getProductMigrationState,
  state => state.status,
);

export const getProductConfiguration = createSelector(
  getProductMigrationState,
  state => state.configuration,
);

export const getProductConfigurationId = createSelector(
  getProductMigrationState,
  state => state.configurationId,
);

export const getDsoOperators = createSelector(
  getProductMigrationState,
  state => state.dsoOperators,
);

export const getCessionUrl = createSelector(
  getProductMigrationState,
  state => state.cessionUrl,
);

export const getCessionStatus = createSelector(
  getProductMigrationState,
  state => state.cessionStatus,
);

export const getAcceptOfferQuery = createSelector(
  getProductMigrationState,
  state => state[ACCEPT_OFFER_QUERY],
);

export const getAcceptOfferQueryStatus = createSelector(
  getAcceptOfferQuery,
  query => getStatus(query),
);

export const getCreateConfigurationQuery = createSelector(
  getProductMigrationState,
  state => state[CREATE_PRODUCT_CONFIGURATION_QUERY],
);

export const getCreateConfigurationQueryStatus = createSelector(
  getCreateConfigurationQuery,
  query => getStatus(query),
);

export const getGetConfigurationQuery = createSelector(
  getProductMigrationState,
  state => state[GET_PRODUCT_CONFIGURATION_QUERY],
);

export const getGetConfigurationQueryStatus = createSelector(
  getGetConfigurationQuery,
  query => getStatus(query),
);

export const getDsoListQuery = createSelector(
  getProductMigrationState,
  state => state[GET_DSO_LIST_QUERY],
);

export const getDsoListQueryStatus = createSelector(
  getDsoListQuery,
  query => getStatus(query),
);

export const getGetCessionQuery = createSelector(
  getProductMigrationState,
  state => state[GET_CESSION_QUERY],
);

export const getGetCessionQueryStatus = createSelector(
  getGetCessionQuery,
  query => getStatus(query),
);

export const getCreateCessionQuery = createSelector(
  getProductMigrationState,
  state => state[CREATE_CESSION_QUERY],
);

export const getCreateCessionQueryStatus = createSelector(
  getCreateCessionQuery,
  query => getStatus(query),
);

export const getConfigurationPowerPlant = createSelector(
  getProductConfiguration,
  configuration => configuration?.powerPlant,
);

export const getConfigurationPhotovoltaicSystem = createSelector(
  getProductConfiguration,
  configuration => configuration?.photovoltaicSystem,
);

export const getGuaranteedVppBonusGrossPerYear = createSelector(
  getConfigurationPowerPlant,
  powerPlant => powerPlant?.guaranteedVppBonusGrossPerYear,
);

export const getConfigurationSystemKey = createSelector(
  getConfigurationPhotovoltaicSystem,
  photovoltaicSystem => photovoltaicSystem?.systemKey?.value,
);

export const getCreateCessionErrorStatus = createSelector(
  getProductMigrationState,
  state => state.errors.createCessionError,
);

export const isMigrationHomeXCampaignActive = createSelector(
  getProductMigrationState,
  state => !!state.campaigns?.activeCampaigns.filter(({ benefit }) => benefit.name === 'migration-home-x').length,
);

export const getActiveCampaignsData = createSelector(
  getProductMigrationState,
  ({ campaigns }) => campaigns?.activeCampaigns,
);

export const getProductMigrationGlobalStatusReset = createSelector(
  getProductMigrationState,
  state => state.globalStatusReset,
);
