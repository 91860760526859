import { LAYOUT_ACTIONS } from '+app/shared/store/layout/layout.actions';
import { AuthActions } from '+auth/store/auth.actions';
import { GuideMiddleware } from '+guide/store/+guide.middleware';
import { initializeI18n } from '+legacy-helpers/i18n.helper';
import { APP_ACTIONS } from '+legacy/core/app/app.actions';
import * as Mobile from '+mobile';
import { history } from '+router/store/index';
import { Reporter } from '+utils/reporter.util';
import { LOCATION_CHANGE, LocationChangeAction, routerMiddleware } from 'connected-react-router';
import { AnyAction, applyMiddleware, compose, createStore, Reducer } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { StoreState } from './store.interface';
import { storeProvider } from './store.provider';
import { initialState } from './store.root';

const IS_DEV = process.env.NODE_ENV === 'development';

const composeEnhancers = IS_DEV
  ? ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)
  : compose;

export const configureStore = (reducer: Reducer, saga: any) => {
  const sagaMiddleware = createSagaMiddleware();
  const mobileMiddleware = Mobile.mobileIntegrationMiddleware({
    [LOCATION_CHANGE]: (action: LocationChangeAction) => ({
      type: '[Integration] LOCATION_CHANGE',
      payload: action.payload,
      location: new URL(action.payload.location.pathname || '', window.location.href).href,
    }),
    [LAYOUT_ACTIONS.TOGGLE_MOBILE_NAVIGATION]: Mobile.noTransform,
    [APP_ACTIONS.SET_IS_READY]: () => ({ type: '[Web] IS_READY' }),
    [Mobile.INTEGRATION_ACTIONS.WAIT_UNTIL_NOTIFIED]: Mobile.noTransform,
    [Mobile.INTEGRATION_ACTIONS.OVERRIDE_MOBILE_URL]: action => action,
  });

  const store = {
    ...createStore<StoreState, AnyAction, unknown, unknown>(
      reducer,
      initialState,
      composeEnhancers(applyMiddleware(
        Reporter.reduxMiddleware,
        GuideMiddleware.persistToken,
        routerMiddleware(history),
        sagaMiddleware,
        mobileMiddleware,
      )),
    ),
    runSaga: saga && sagaMiddleware.run(saga),
  };

  store.runSaga.toPromise().catch(Reporter.reportError);

  storeProvider.initialize(store);
  Mobile.initializeMobileIntegration();

  const persistor = persistStore(store, {}, async () => {
    await initializeI18n(store);
    store.dispatch(AuthActions.rehydrated());
  });
  storeProvider.setPersistor(persistor);

  if (IS_DEV && (module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot.accept('./store.root', () => {
      const { rootReducer } = require('./store.root.tsx');
      store.replaceReducer(rootReducer);
    });
  }
  return { store, persistor };
};
