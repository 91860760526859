import * as React from 'react';

import './ConfigurationLine.scss';

export interface ConfigurationLineProps {
  title: string;
  value: string;
  helperLabel?: string;
}

export const ConfigurationLine: React.FC<ConfigurationLineProps> = ({
  title,
  value,
  helperLabel,
}) => {

  return (
    <>
      <div className="c-configuration-line-row">
        <div className="c-configuration-line-row__title">
          {title}
        </div>
        <p className="c-configuration-line-row__value">
          {value}
        </p>
      </div>
      {helperLabel && (
        <p className="c-configuration-line-row__title-helper">{helperLabel}</p>
      )}
    </>
  );
};
