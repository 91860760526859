import { PlatformSelector } from '+app/shared/components';
import * as React from 'react';
import { DemoCommunityMobile } from './DemoCommunity.mobile.component';
import { DemoCommunityWeb } from './DemoCommunity.web.component';

export const DemoCommunity: React.FC = () => (
  <PlatformSelector
    web={<DemoCommunityWeb />}
    mobile={<DemoCommunityMobile />}
  />
);
