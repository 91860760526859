import { T } from '@sonnen/shared-i18n/customer';

import { FormWizardHeader, Wizard, WizardStep } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router';

import { GuideAcceptanceCessionSign } from '../../containers/GuideAcceptanceCessionSign';
import { GuideAcceptanceFinalStep } from '../../containers/GuideAcceptanceFinalStep';
import { GuideAcceptanceFlatOffer } from '../../containers/GuideAcceptanceFlatOffer';
import { GuideAcceptanceInput } from '../../containers/GuideAcceptanceInput';
import { GuideAcceptancePersonalDetails } from '../../containers/GuideAcceptancePersonalDetails';
import { GuideAcceptanceUtility } from '../../containers/GuideAcceptanceUtility';
import { GuideAcceptanceCompletion } from '../GuideAcceptanceCompletion';

const FlatOffer = withRouter(GuideAcceptanceFlatOffer);
const RoutedWizard = withRouter(Wizard);

// TODO: when SW is unblocked, add a prop `isHidden` to WizardStep
// and filter these in Wizard instead of this long helper
export const getGuideAcceptanceWizard = (isPostEeg: boolean, isFlatXOffer?: boolean) => isPostEeg ? (
  <RoutedWizard
    header={<FormWizardHeader className={'c-guide-acceptance-stepper__header'} />}
  >
    <WizardStep
      name={I18n.t(T.customerGuidance.acceptance.stepper.details)}
      canAccess={true}
    >
      <GuideAcceptancePersonalDetails />
    </WizardStep>
    <WizardStep
      name={I18n.t(T.customerGuidance.acceptance.stepper.utilityData)}
      canAccess={true}
    >
      <GuideAcceptanceUtility />
    </WizardStep>
    <WizardStep
      name={I18n.t(T.customerGuidance.acceptance.stepper.bankData)}
      canAccess={true}
    >
      <GuideAcceptanceInput />
    </WizardStep>
    <WizardStep
      name={isFlatXOffer
        ? I18n.t(T.customerGuidance.acceptance.stepper.sonnenFlat)
        : I18n.t(T.customerGuidance.acceptance.stepper.sonnenFlatDirect)
      }
      canAccess={true}
    >
      <FlatOffer />
    </WizardStep>
    <WizardStep
      name={I18n.t(T.customerGuidance.acceptance.stepper.conclude)}
      canAccess={true}
    >
      {/* for post EEG there's always a success page */}
      <GuideAcceptanceCompletion />
    </WizardStep>
  </RoutedWizard>
) : (
  <RoutedWizard
    header={<FormWizardHeader className={'c-guide-acceptance-stepper__header'} />}
  >
    <WizardStep
      name={I18n.t(T.customerGuidance.acceptance.stepper.details)}
      canAccess={true}
    >
      <GuideAcceptancePersonalDetails />
    </WizardStep>
    <WizardStep
      name={I18n.t(T.customerGuidance.acceptance.stepper.utilityData)}
      canAccess={true}
    >
      <GuideAcceptanceUtility />
    </WizardStep>
    <WizardStep
      name={I18n.t(T.customerGuidance.acceptance.stepper.bankData)}
      canAccess={true}
    >
      <GuideAcceptanceInput />
    </WizardStep>
    <WizardStep
      name={isFlatXOffer
        ? I18n.t(T.customerGuidance.acceptance.stepper.sonnenFlat)
        : I18n.t(T.customerGuidance.acceptance.stepper.sonnenFlatDirect)
      }
      canAccess={true}
    >
      <FlatOffer />
    </WizardStep>
    <WizardStep
      name={I18n.t(T.customerGuidance.acceptance.stepper.cessionDoc)}
      canAccess={true}
    >
      <GuideAcceptanceCessionSign />
    </WizardStep>
    <WizardStep
      name={I18n.t(T.customerGuidance.acceptance.stepper.conclude)}
      canAccess={true}
    >
      <GuideAcceptanceFinalStep />
    </WizardStep>
  </RoutedWizard>
);
