import { getLanguageCode, Locale } from '+legacy/helpers/i18n.helper';
import * as moment from 'moment';
import * as React from 'react';

const localeDateFormat = {
  [Locale.EN_GB]: 'Do MMMM, H:mm',
  [Locale.EN_US]: 'Do MMMM, H:mm',
  [Locale.DE]: 'Do MMMM, H:mm [Uhr]',
  [Locale.IT]: 'Do MMMM, H:mm',
};

export const useLocaleDateFormat = (timestamp: string | undefined) => {
  const [measurementsTime, setTime] = React.useState('');

  React.useEffect(() => {
    const setMeasurementsTime = async () => {
      const languageCode = await getLanguageCode();
      const format = localeDateFormat[languageCode] || localeDateFormat[Locale.EN_US];

      setTime(moment(timestamp).format(format));
    };

    setMeasurementsTime();
  }, [timestamp]);

  return measurementsTime;
};
