import * as React from 'react';

import './GuidePurchaseOfferCard.component.scss';

interface Props {
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
}

export const GuidePurchaseOfferCard: React.FC<Props> = ({
  header,
  children,
  footer,
}) => (
  <div className={'c-guide-purchase-offer-card'}>
    {header && (
      <div className={'c-guide-purchase-offer-card__header'}>{header}</div>
    )}
    <div className={'c-guide-purchase-offer-card__body'}>{children}</div>
    {footer && (
      <div className={'c-guide-purchase-offer-card__footer'}>{footer}</div>
    )}
  </div>
);
