import {
  ChartView,
  DataContainerTooltipExtension,
  DataContainerTransformExtension,
  TooltipEvent,
  TooltipEventHandler,
} from '@kanva/charts';
import { View } from '@kanva/core';
import { T } from '@sonnen/shared-i18n/customer';
import {
  AccessoriesChartSeries,
  AnalysisChartCrosshair,
  DefaultParagraph,
  factorizeTooltipDate,
  Icon,
  MediaQuery,
  TimeUnit,
  Tooltip,
  useCountryFeature,
} from '@sonnen/shared-web';
import { isNil } from 'lodash';
import { Moment } from 'moment';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { SelectedDate } from '+analysis/store/types/selectedDate.interface';
import { QueryState } from '+app/shared/store/query/query.state';
import { CountryFeatureName } from '+config/countryFlags';
import { desktopScaleOptions, mobileScaleOptions } from '../../helpers/analysis.helper';
import { AnalysisAccessoriesTooltipHorizontal } from '../AnalysisAccessoriesTooltipHorizontal';
import { AnalysisLineChart } from '../AnalysisLineChart';
import { AnalysisTooltipChartWrapper } from '../AnalysisTooltipChartWrapper';
import {
  ACCESSORIES_CHARTS_WRAPPER_HEIGHT,
  ACCESSORIES_SINGLE_CHART_WRAPPER_HEIGHT,
  calculateAccessoriesActivityRanges,
  getRawTimestampFromX,
} from './AnalysisAccessoriesChart.helper';

import './AnalysisAccessoriesChart.component.scss';

interface State {
  isTooltipActive: boolean;
  tooltipEvent: TooltipEvent | undefined;
  chartView: View<any> | undefined;
}

interface Props {
  hasBackupBox: boolean;
  hasHeater: boolean;
  selectedDate: Moment;
  statisticsSelectedDate: SelectedDate;
  queryStatus: QueryState;
  chartSeries: AccessoriesChartSeries;
  transformExtension: DataContainerTransformExtension | undefined;
  isChartZoomed: boolean;
  isChartFullyLoaded: boolean;
}

const renderAccessoriesTitle = () => (
  <div className={'c-analysis-accessories-chart__title'}>
    {I18n.t(T.analysis.headlines.accessories)}
    <Tooltip trigger={(
      <div className={'c-analysis-accessories-chart__tip-icon'}>
        <Icon.QuestionMark />
      </div>
    )}>
      <DefaultParagraph>
        {I18n.t(T.analysis.accessories.tooltip)}
      </DefaultParagraph>
    </Tooltip>
  </div>
);

export class AnalysisAccessoriesChart extends React.PureComponent<Props, State> {
  private tooltipExtension: DataContainerTooltipExtension;

  constructor(props: Props) {
    super(props);

    this.state = {
      isTooltipActive: false,
      tooltipEvent: undefined,
      chartView: undefined,
    };

    this.tooltipExtension = new DataContainerTooltipExtension({
      onTooltipEvent: this.handleTooltipEvent,
    });
  }

  handleTooltipEvent: TooltipEventHandler = (event) => {
    if (this.state.tooltipEvent === event) {
      return;
    }

    if (!Object.values(event.match.values).every(point => point && isNil(point.y))) {
      this.setState({ tooltipEvent: event });
    }
  };

  handleTooltipPositionChange = (x: number) => (view: View<any> | undefined) => {
    if (this.tooltipExtension && view) {
      this.setState({ chartView: view });
      this.tooltipExtension.simulateAbsoluteCanvasPosition(view as ChartView<any, any>, { x, y: 0 });
    }
  };

  render() {
    const {
      hasBackupBox,
      hasHeater,
      selectedDate,
      queryStatus,
      chartSeries,
      transformExtension,
      isChartZoomed,
      isChartFullyLoaded,
    } = this.props;
    const {
      isTooltipActive,
      tooltipEvent,
      chartView,
    } = this.state;

    const x = tooltipEvent && tooltipEvent.match.x;
    const rawTimestamp = getRawTimestampFromX(chartSeries, x);
    const shouldRenderChart: boolean = hasHeater || (
      hasBackupBox
      && useCountryFeature(CountryFeatureName.ANALYSIS_BACKUP_BOX_CHART).isEnabled
    );

    return (
      <>
        {shouldRenderChart && (
          <Media query={MediaQuery.UP_SM}>
            {(isDesktop: boolean) => isDesktop ? (
              <AnalysisTooltipChartWrapper
                className={'c-analysis-accessories-chart'}
                tooltipEvent={tooltipEvent}
                toggleTooltipActivity={(isTooltipActive: boolean) => this.setState({ isTooltipActive })}
              >
                {renderAccessoriesTitle()}
                <AnalysisAccessoriesTooltipHorizontal
                  isVisible={isTooltipActive && isChartFullyLoaded}
                  header={factorizeTooltipDate(
                    TimeUnit.DAY,
                    tooltipEvent && tooltipEvent.match.primary.x,
                    selectedDate,
                  )}
                  data={tooltipEvent && tooltipEvent.match.values}
                  activityRanges={calculateAccessoriesActivityRanges(chartSeries)}
                  x={tooltipEvent && tooltipEvent.snap.x || 0}
                  rawTimestamp={rawTimestamp}
                  hasBackupBox={hasBackupBox}
                  hasHeater={hasHeater}
                />
                <AnalysisLineChart
                  key={`area-${queryStatus.success}`}
                  selectedDate={selectedDate}
                  dataSeries={chartSeries}
                  queryStatus={queryStatus}
                  tooltipExtension={this.tooltipExtension}
                  transformExtension={transformExtension}
                  scaleOptions={desktopScaleOptions}
                  onMount={this.handleTooltipPositionChange}
                  hasBackupBox={hasBackupBox}
                  hasHeater={hasHeater}
                  isChartZoomed={isChartZoomed}
                />
                {/* @TODO: Handle isVisible flag in a simpler way. */}
                <Media query={MediaQuery.UP_MD}>
                  {(isDesktop: boolean) => isDesktop ? (
                    <AnalysisChartCrosshair
                      crosshairPosition={tooltipEvent?.snap.x || 0}
                      height={hasHeater && hasBackupBox
                        ? ACCESSORIES_CHARTS_WRAPPER_HEIGHT
                        : ACCESSORIES_SINGLE_CHART_WRAPPER_HEIGHT
                      }
                      isVisible={isTooltipActive && isChartFullyLoaded}
                    />
                  ) : (
                    <AnalysisChartCrosshair
                      crosshairPosition={tooltipEvent?.snap.x || 0}
                      height={hasHeater && hasBackupBox
                        ? ACCESSORIES_CHARTS_WRAPPER_HEIGHT
                        : ACCESSORIES_SINGLE_CHART_WRAPPER_HEIGHT
                      }
                      isVisible={true}
                    />
                  )}
                </Media>
              </AnalysisTooltipChartWrapper>
            ) : (
              <>
                {renderAccessoriesTitle()}
                <AnalysisTooltipChartWrapper
                  className={'c-analysis-accessories-chart'}
                  tooltipEvent={tooltipEvent}
                  chartView={chartView}
                  tooltipExtension={this.tooltipExtension}
                >
                  <div className={'c-analysis-accessories-chart__tooltip'}>
                    <AnalysisAccessoriesTooltipHorizontal
                      isVisible={isChartFullyLoaded}
                      header={factorizeTooltipDate(
                        TimeUnit.DAY,
                        tooltipEvent && tooltipEvent.match.primary.x,
                        selectedDate,
                      )}
                      activityRanges={calculateAccessoriesActivityRanges(chartSeries)}
                      data={tooltipEvent && tooltipEvent.match.values}
                      x={tooltipEvent && tooltipEvent.snap.x || 0}
                      rawTimestamp={rawTimestamp}
                      hasBackupBox={hasBackupBox}
                      hasHeater={hasHeater}
                    />
                  </div>
                  <AnalysisLineChart
                    key={`area-${queryStatus.success}`}
                    selectedDate={selectedDate}
                    dataSeries={chartSeries}
                    queryStatus={queryStatus}
                    tooltipExtension={this.tooltipExtension}
                    transformExtension={transformExtension}
                    scaleOptions={mobileScaleOptions}
                    onMount={this.handleTooltipPositionChange}
                    hasBackupBox={hasBackupBox}
                    hasHeater={hasHeater}
                    isChartZoomed={isChartZoomed}
                  />
                  <AnalysisChartCrosshair
                    crosshairPosition={tooltipEvent?.snap.x || 0}
                    height={hasHeater && hasBackupBox
                      ? ACCESSORIES_CHARTS_WRAPPER_HEIGHT
                      : ACCESSORIES_SINGLE_CHART_WRAPPER_HEIGHT
                    }
                    isVisible={isChartFullyLoaded}
                  />
                </AnalysisTooltipChartWrapper>
              </>
            )}
          </Media>
        )}
      </>
    );
  }
}
