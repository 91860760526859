import { defaultLineSeriesStyle } from '+analysis/components/AnalysisAreaChart/AnalysisAreaChart.styles';
import { AreaChartViewStyle, DataDisplayType, LineChartViewStyle } from '@kanva/charts';
import { Paint, rgba } from '@kanva/core';
import { AnalysisChartColors, EnergyFlowSeriesKey, SharedChartColors } from '@sonnen/shared-web';

// TODO Remove duplicated chart styles

export const Views = {
  X_AXIS: 'xAxis',
  Y_AXIS_LEFT: 'yAxisLeft',
  Y_AXIS_RIGHT: 'yAxisRight',
  LINE_WRAPPER: 'lineWrapper',
  // BATTERY_CARE_LINE: 'batteryCareLine',
};

export const AreaSeriesStyle: Record<EnergyFlowSeriesKey, AreaChartViewStyle> = {
  [EnergyFlowSeriesKey.CONSUMPTION_POWER]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(rgba(AnalysisChartColors[EnergyFlowSeriesKey.CONSUMPTION_POWER], .75))
      .setLineWidth(1)
      .setStrokeStyle(SharedChartColors.BLUE_DARK),
  },
  [EnergyFlowSeriesKey.GRID_PURCHASE]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(rgba(AnalysisChartColors[EnergyFlowSeriesKey.GRID_PURCHASE], .75)),
  },
  [EnergyFlowSeriesKey.PRODUCTION_POWER]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(rgba(AnalysisChartColors[EnergyFlowSeriesKey.PRODUCTION_POWER], .75))
      .setLineWidth(1)
      .setStrokeStyle(SharedChartColors.GOLD),
  },
  [EnergyFlowSeriesKey.GRID_FEEDIN]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(rgba(AnalysisChartColors[EnergyFlowSeriesKey.GRID_FEEDIN], .75)),
  },
  [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: {
    type: DataDisplayType.AREA,
    paint: new Paint()
      .setFillStyle(rgba(AnalysisChartColors[EnergyFlowSeriesKey.DIRECT_USAGE_POWER], 1)),
  },
  [EnergyFlowSeriesKey.BATTERY_USOC]: {
    type: DataDisplayType.LINE,
    paint: new Paint()
      .setLineWidth(2)
      .setStrokeStyle(AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_USOC]),
  },
  [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: {
    type: DataDisplayType.LINE,
    paint: new Paint()
      .setLineWidth(1)
      // TODO add FORECAST colors and move SharedChartColors from @shared-web to projects
      // in example as some config props
      .setStrokeStyle(SharedChartColors.PRODUCTION),
  },
  [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: {
    type: DataDisplayType.LINE,
    paint: new Paint()
      .setLineWidth(1)
      // TODO add FORECAST colors and move SharedChartColors from @shared-web to projects
      // in example as some config props
      .setStrokeStyle(SharedChartColors.CONSUMPTION),
  },
  [EnergyFlowSeriesKey.BATTERY_CHARGING]: {
    type: DataDisplayType.LINE,
    paint: new Paint()
      .setLineWidth(2)
      .setStrokeStyle(AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_USOC]),
  },
  [EnergyFlowSeriesKey.BATTERY_DISCHARGING]: {
    type: DataDisplayType.LINE,
    paint: new Paint()
      .setLineWidth(2)
      .setStrokeStyle(AnalysisChartColors[EnergyFlowSeriesKey.BATTERY_USOC]),
  },
};

export const BATTERY_CARE_LINE_COLOR = '#97DBC9';

export const lineSeriesStyle: LineChartViewStyle = {
  ...defaultLineSeriesStyle,
  foreground: {
    ...defaultLineSeriesStyle.foreground,
    paint: new Paint().setFillStyle(BATTERY_CARE_LINE_COLOR),
  },
};
