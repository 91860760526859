import * as React from 'react';

import { AnalysisProviderActions, AnalysisProviderCommonData, AnalysisProviderQueries } from './AnalysisProvider.types';

interface AnalysisContextCommonData extends AnalysisProviderCommonData {
  isDelayMessageVisible: boolean;
}

export interface AnalysisContextProps {
  commonData: AnalysisContextCommonData;
  query: AnalysisProviderQueries;
  actions: AnalysisProviderActions;
}

export const AnalysisContext = React.createContext<AnalysisContextProps>({
  commonData: {},
  query: {},
  actions: {},
} as AnalysisContextProps);

export const useAnalysis = () => React.useContext(AnalysisContext);
