import { T } from '@sonnen/shared-i18n/customer';
import { breakpointUp, Tabs, useWindowSize } from '@sonnen/shared-web';
import { replace } from 'connected-react-router';
import { isNil } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import { StoreState } from '+app/store/store.interface';
import { PhaseContext } from '+guide/components';
import { getStepsFromPhase, PHASES, TABS } from '+guide/helpers/steps.config';
import { getTabFromStep } from '+guide/helpers/steps.config';
import { GuideActions } from '+guide/store/+guide.actions';
import { getCessionStatus, getPhaseActiveTab } from '+guide/store/+guide.selectors';
import { mapActions } from '+utils/redux';
import { isFlatOfferAccepted, isHardwareOrderConfirmed } from '../../store/+purchase.selectors';

const mapStateToProps = (state: StoreState) => ({
  phaseActiveTab: getPhaseActiveTab(state),
  flatOfferAccepted: isFlatOfferAccepted(state),
  hardwareOrderConfirmed: isHardwareOrderConfirmed(state),
  cessionStatus: getCessionStatus(state),
});

const mapDispatchToProps = mapActions({
  goTo: replace,
  setActiveTab: GuideActions.setPhaseActiveTab,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const GuidePurchaseTabsComponent: React.FC<Props> = ({
  phaseActiveTab,
  actions,
}) => {
  const phaseStepPath = React.useContext(PhaseContext);
  const stepsYourTodo = getStepsFromPhase(
    PHASES.PURCHASE,
    TABS.YOUR_TODO,
  );
  const stepsOurTodo = getStepsFromPhase(
    PHASES.PURCHASE,
    TABS.OUR_TODO,
  );

  // Reload
  React.useEffect(() => {
    const phaseActiveTab = getTabFromStep(window.location.pathname);
    if (!isNil(phaseActiveTab)) {
      actions.setActiveTab(phaseActiveTab);
    }

    return () => {
      actions.setActiveTab(0);
    };
  }, []);

  React.useEffect(() => {
    const phaseActiveTab = getTabFromStep(window.location.pathname);
    if (!isNil(phaseActiveTab)) {
      actions.setActiveTab(phaseActiveTab);
    }
  }, [window.location.pathname]);

  // @TODO: Change translations or...
  // @TODO: ...create <GuideStepTabs /> component
  return (
    <Tabs
      triggers={[
        {
          label: I18n.t(T.customerGuidance.setup.active.tabs.myTodo),
          onClick: () => {
            actions.setActiveTab(0);
            actions.goTo(phaseStepPath(), { preserveScroll: true });
          },
        },
        {
          label: I18n.t(T.customerGuidance.setup.active.tabs.OurTodo),
          onClick: () => {
            actions.setActiveTab(1);
            actions.goTo(phaseStepPath(), { preserveScroll: true });
          },
        },
      ]}
      activeTab={phaseActiveTab}
      isThemeInline={useWindowSize().innerWidth >= breakpointUp('SM') ? true : false}
    >
      <>
        {
          Object.keys(stepsYourTodo).map(key => {
            const step = stepsYourTodo[key];
            const StepComponent = step.component;
            return <StepComponent key={`${step.phase}-${step.tab}-${key}`}/>;
          })
        }
      </>
      <>
        {
          Object.keys(stepsOurTodo).map(key => {
            const step = stepsOurTodo[key];
            const StepComponent = step.component;
            return <StepComponent key={`${step.phase}-${step.tab}-${key}`}/>;
          })
        }
      </>
    </Tabs>
  );
};

export const GuidePurchaseTabs = withRouter(connect(mapStateToProps, mapDispatchToProps)(GuidePurchaseTabsComponent));
