import { CONFIG } from '+config';
import { useJsonApi } from '+shared/network';
import { UserAttributes, UserData } from '+shared/store/user/types/user.interface';
import { ProfileData, ProfileFormAttributes } from './types/profile.interface';
import { UserChangePasswordAttributes } from './types/userChangePassword.interface';

const getAccountProfileData = (id: string) =>
  useJsonApi().get<ProfileData>(`${CONFIG.API.USER_DATA}/users/${id}/profile`)
    .send()
    .then(response => response.element);

const setAccountProfileData = (
  { id, attributes }:
  { id: string, attributes: ProfileFormAttributes },
  ) => useJsonApi().put<ProfileData>(`${CONFIG.API.USER_DATA}/users/${id}/profile`)
    .ofType('profiles')
    .withAttributes(attributes)
    .send();

const setUserData = (
  { id, attributes }:
  { id: string, attributes: UserAttributes },
) => useJsonApi().put<UserData>(`${CONFIG.API.USER_DATA}/users/${id}`)
    .ofType('user')
    .withAttributes(attributes)
    .send()
    .then(response => response.element);

const changeUserPassword = (
  { id, attributes }:
  { id: string, attributes: UserChangePasswordAttributes },
) => useJsonApi().put<UserData>(`${CONFIG.API.USER_DATA}/users/${id}/password`)
    .ofType('user')
    .withAttributes(attributes)
    .send();

export const AccountRepository = {
  getAccountProfileData,
  setAccountProfileData,
  setUserData,
  changeUserPassword,
};
