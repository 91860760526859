import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, DS } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { Site } from '+shared/store/site/types/site.interface';
import { EFCircle } from '../EFCircle';
import { EFCircleExplanationTooltip } from '../EFCircleExplanationTooltip';
import { LiveEnergyFlowHelper } from '../LiveEnergyFlow.helper';

export interface EFCircleConsumptionProps {
  isActive: (predicate: boolean | undefined) => boolean;
  isAppOnline: boolean;
  liveData: SiteLive | undefined;
  site: Site | undefined;
  isExplanationActive: boolean | undefined;
  isBatteryToConsumptionActive: boolean | undefined;
  isGridToConsumptionActive: boolean | undefined;
  isProductionToConsumptionActive: boolean | undefined;
}

export const EFCircleConsumption: React.FC<EFCircleConsumptionProps> = ({
  isActive,
  isAppOnline,
  liveData,
  site,
  isExplanationActive,
  isBatteryToConsumptionActive,
  isGridToConsumptionActive,
  isProductionToConsumptionActive,
}) => {
  const isMember = !!site && LiveEnergyFlowHelper.isCommunityMember(site);
  const value = !!liveData && liveData.consumptionPower || 0;

  return (
    <EFCircle
      dataTestId={'live-state-circle-consumption'}
      type={'consumption'}
      icon={<DS.Icon.House />}
      label={I18n.t(T.dashboard.energyFlowChart.consumption)}
      key={value}
      value={value}
      isOnline={isAppOnline && liveData?.online}
      isBlured={isExplanationActive}
      tooltip={isExplanationActive ? (
        <EFCircleExplanationTooltip
          dataTestId={'live-state-energy-flow-explanation-tooltip-consumption'}
          position={'left'}
        >
          <DefaultParagraph>
            {isMember
              ? I18n.t(T.dashboard.liveStateChart.consumption.tooltipConsumptionCommunity)
              : I18n.t(T.dashboard.liveStateChart.consumption.tooltipConsumptionGrid)
            }
          </DefaultParagraph>
        </EFCircleExplanationTooltip>
        ) :
        null
      }
      isActive={isActive(
        isBatteryToConsumptionActive
        || isGridToConsumptionActive
        || isProductionToConsumptionActive,
      )}
    />
  );
};
