import { RouteName, RouterNavConfig } from '../router.types';

export const guideNavConfig: RouterNavConfig = [
  RouteName.GUIDE_OVERVIEW,
  {
    main: RouteName.GUIDE_PHASES,
    subRoutes: [
      RouteName.GUIDE_DISCOVERY,
      RouteName.GUIDE_PURCHASE,
      RouteName.GUIDE_SETUP,
      RouteName.GUIDE_USAGE,
    ],
  },
  RouteName.GUIDE_PRODUCT_INFO,
  RouteName.GUIDE_DOCUMENTS,
  RouteName.GUIDE_CONTACT,
];

export const guideNavHeaderConfig: RouterNavConfig = [
  RouteName.GUIDE_LOGOUT,
];

export const guideNavMobileConfig: RouterNavConfig = [
  ...guideNavConfig,
  {
    main: RouteName.GUIDE_MY_ACCOUNT,
    subRoutes: [
      RouteName.GUIDE_LOGOUT,
    ],
  },
];
