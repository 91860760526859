import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';
import { Props } from './AuthLogIn.component';

export class AuthLogInWeb extends React.PureComponent<Props> {
  componentDidMount() {
    this.props.redirectToAuth();
  }

  render() {
    return (
      <Translate value={T.onboardPages.logIn.redirectingInfo} />
    );
  }
}
