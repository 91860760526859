import { T } from '@sonnen/shared-i18n/customer';
import { Icon, InfoBanner } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

export const ContractNoQuotaInfo: React.FC = () => (
  <InfoBanner
    icon={<Icon.Ufo />}
    title={I18n.t(T.yourFlat.notifications.noQuota.title)}
    subtitle={(
      <Translate
        value={T.yourFlat.notifications.noQuota.subtitle}
        dangerousHTML={true}
      />
    )}
    isThemeCard={true}
  />
);
