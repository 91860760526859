import classNames from 'classnames';
import * as React from 'react';

import { SearchableSelect } from '../../selects';
import { FormElement } from '../Form.component';

export interface FormSelectProps {
  label: string;
  options: Array<{
    value: string | number | boolean;
    label: string | number | boolean;
  }>;
  placeholder?: string;
  floatingLabel?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  dataTestId?: string;
}

export const Select: FormElement<FormSelectProps> = ({
  input,
  label,
  placeholder,
  options,
  disabled,
  defaultValue,
  dataTestId,
}) => {
  const selectClasses = classNames('c-form__select', {
    'c-form__select--disabled': disabled,
  });

  if (input.value === '' && defaultValue) {
    input.value = defaultValue;
  }

  return (
    <div 
      data-test-id={dataTestId} 
      className="c-form__item">
      <label
        className="c-form__label"
        htmlFor={input.name}
      >
        {label}
      </label>
      <SearchableSelect
        dataTestId={dataTestId}
        value={input.value}
        options={options}
        onChange={input.onChange}
        placeholder={placeholder}
        classValue={selectClasses}
        disabled={disabled}
        simpleValue={true}
      />
    </div>
  );
};

Select.defaultProps = {
  disabled: false,
};
