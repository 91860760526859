import { Icon, LinkButton, LinkButtonIconPosition } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import { useCollapsibleContent } from '+shared/hooks/useCollapsibleContent';
import './CollapsibleArea.component.scss';

interface CollapsibleAreaProps {
  children: React.ReactNode;
  label: string;
  dataTestId?: string;
}

export const CollapsibleArea: React.FC<CollapsibleAreaProps> = ({ children, label, dataTestId }) => {
  const { contentHeight, contentRef, isOpen, toggle, resizeObserver } = useCollapsibleContent();

  return (
    <div
      ref={resizeObserver.ref}
      className={'c-collapsible-area'}
      style={{ '--collapsible-area-content-height': contentHeight } as React.CSSProperties}
    >
      <div className={'c-collapsible-area__trigger'}>
        <LinkButton
          icon={<Icon.Angle />}
          iconClass={classNames('c-collapsible-area__icon', {
            'is-rotated': isOpen,
          })}
          iconPosition={LinkButtonIconPosition.AFTER}
          onClick={toggle}
          dataTestId={dataTestId}
        >
          {label}
        </LinkButton>
      </div>
      <div className={'c-collapsible-area__content-wrapper'}>
        <div className={'c-collapsible-area__content'} ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  );
};
