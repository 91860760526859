import { BatteryHelper } from '+battery/store/battery.helpers';
import { Battery } from '+battery/store/types/battery.interface';
import { SpecsItem } from '+components';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { T } from '@sonnen/shared-i18n/customer';
import { Card, CardNotification, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { BatteryIcon } from '../BatteryIcon';

interface Props {
  liveData: SiteLive;
  batteryData: Battery;
}

export const BatteryCardBackupBox: React.FC<Props> = ({ liveData, batteryData }) => {
  const isBackupActive = liveData.backupActive;
  const cardTitle = batteryData.backupDeviceType
    ? I18n.t(T.batterySystem.overview.backupBox.title[batteryData.backupDeviceType])
    : undefined;

  return (
    <Card
      title={cardTitle}
      smallTitleGap={true}
      footerContent={(
        <CardNotification
          dataTestId={'sonnen-batterie-overview-backup-box-online'}
          type={'success'}
          icon={<Icon.Checkmark />}
        >
          {I18n.t(T.batterySystem.notifications.connected)}
        </CardNotification>
      )}
    >
      <SpecsItem
        dataTestId={'sonnen-batterie-overview-backup-box-status'}
        title={I18n.t(T.batterySystem.overview.backupBox.status)}
        value={(
          <BatteryIcon
            icon={<Icon.BackupBox />}
            label={isBackupActive
              ? I18n.t(T.batterySystem.statuses.on)
              : I18n.t(T.batterySystem.statuses.off)}
            isActive={isBackupActive}
          />
        )}
      />
      <SpecsItem
        dataTestId={'sonnen-batterie-overview-backup-box-output-power'}
        title={I18n.t(T.batterySystem.overview.backupBox.outputPower)}
        value={BatteryHelper.getBackupNominalPower(batteryData)}
      />
      <SpecsItem
        dataTestId={'sonnen-batterie-overview-backup-box-power-outage'}
        title={I18n.t(T.batterySystem.overview.backupBox.powerOutage)}
        value={BatteryHelper.getLastPowerOutageDate(batteryData)}
      />
      <SpecsItem
        dataTestId={'sonnen-batterie-overview-backup-box-backup'}
        title={I18n.t(T.batterySystem.overview.backupBox.backup)}
        value={BatteryHelper.getBackupLevel(batteryData)}
      />
    </Card>
  );
};
