import { StoreState } from '+app/store/store.interface';
import { Locale } from '+legacy/helpers/i18n.helper';
import { COMMUNITY_MOCK, CURRENT_USER_MOCK } from '+mocks/index';

const isDemo = window.location.pathname.startsWith('/demo');

const isDemoUserCountry = (...locales: Locale[]) => (state: StoreState) =>
  isDemo && locales.includes(state.i18n.locale as Locale);

const getDemoUserName = () => `${CURRENT_USER_MOCK.firstName}, ${CURRENT_USER_MOCK.lastName}`;

const getDemoUserGeolocation = () => [Number(CURRENT_USER_MOCK.latitude), Number(CURRENT_USER_MOCK.longitude)];

const getRecentMembersCount = () => COMMUNITY_MOCK.recentMembersCount;

export const DemoHelper = {
  isDemo,
  isDemoUserCountry,
  getDemoUserName,
  getDemoUserGeolocation,
  getRecentMembersCount,
};
