import * as classNames from 'classnames';
import * as React from 'react';

import './StateAlert.component.scss';

export interface StateAlertProps {
  icon?: React.ReactNode;
  small?: boolean;
  variant?: 'success' | 'warning' | 'info';
  dataTestId?: string;
}

export const StateAlert: React.FC<StateAlertProps> = ({
  children,
  icon,
  small,
  variant = 'success',
  dataTestId,
}) => {
  return (
    <div
      data-test-id={dataTestId} 
      className={classNames(`c-state-alert c-state-alert--${variant}`, {
      'c-state-alert--small': small,
    })}>
      {icon && (
        <span className={'c-state-alert__icon'}>
          {icon}
        </span>
      )}
      {children}
    </div>
  );
};
