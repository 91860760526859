import { DS, Icon } from '@sonnen/shared-web';
import * as React from 'react';

import './AnalysisYearPicker.component.scss';

export type YearPickerProps = {
  min?: number;
  max?: number;
  year: number;
  onChange: (year: number) => void;
};

export const AnalysisYearPicker: React.VFC<YearPickerProps> = ({
  year,
  onChange,
  min = 0,
  max =  new Date().getFullYear(),
}) => {
  const decrement = () => {
    const newValue = year - 1;

    if (newValue >= min) {
      onChange(newValue);
    }
  };

  const increment = () => {
    const newValue = year + 1;

    if (newValue <= max) {
      onChange(newValue);
    }
  };

  return (
    <div className={'c-year-picker'}>
      <button type={'button'} onClick={decrement}>
        <div className={'c-year-picker__subtract-icon'}>
          <DS.Icon.Angle />
        </div>
      </button>
      <div className={'c-year-picker__year-container'}>
        <Icon.CalendarWithGrid className={'c-year-picker__calendar-icon'} />
        <span className={'c-year-picker__year'}>
          {year}
        </span>
      </div>
      <button type={'button'} onClick={increment}>
        <div className={'c-year-picker__add-icon'}>
          <DS.Icon.Angle />
        </div>
      </button>
    </div>
  );
};
