import { Document, Proposal } from '+app/+guide/store/types';
import { GuidePurchaseHardwareOffer } from '+guide/+purchase/components';
import * as React from 'react';
import { GuidePurchaseFlatOffer } from '../../containers/GuidePurchaseFlatOffer';

export const GuidePurchaseProposal: React.FC<{
  proposal: Proposal,
  hardwareOffers?: Document[],
  onCheckOffer?: () => void;
  index: number,
  handleToggleTermsModal: () => void;
}> = ({
  proposal,
  onCheckOffer,
  handleToggleTermsModal,
  hardwareOffers,
}) => {

  return (
    <div
      className={'c-guide-purchase-step-accept-offer__offer-group'}
      key={`offer-${proposal.id}`}
    >
      {hardwareOffers && !!hardwareOffers.length && proposal.offer && (
        <GuidePurchaseHardwareOffer
          hardwareOffers={hardwareOffers}
          offer={proposal.offer}
        />
      )}
      <GuidePurchaseFlatOffer
        proposal={proposal}
        handleToggleTermsModal={handleToggleTermsModal}
        onCheckOffer={onCheckOffer}
      />
    </div>
  );
};
