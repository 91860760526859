import { first, intersection } from 'lodash';
import { find, isEqual, unionBy, xor } from 'lodash/fp';

import { BatteryUnits } from '+app/+dashboard/+battery/store/types/battery.interface';
import { CONFIG } from '+config';
import { isOnRoute } from '+router/router.helper';
import { ConditionsProps } from '+shared/containers/NotificationProvider';
import {
  Notification,
  NotificationCategory,
  NotificationId,
  NotificationPayload,
} from './types/notification.interface';
import { SystemNotification, SystemNotifications } from './types/systemNotification';

/** Utils */
const areNotificationsEqual = (n1: Notification) => (n2: Notification): boolean => n1.id === n2.id;

const addUniqueNotification = (notification: Notification) =>
  (collection: Notification[]): Notification[] => !find(areNotificationsEqual(notification))(collection)
      ? [...collection, notification]
      : [...collection];

const removeNotificationById = (id: NotificationId) => (collection: Notification[]): Notification[] =>
  collection.filter(n => n.id !== id);

const mapNotificationToId = (notifications: Notification[]): NotificationId[] =>
  notifications.map(({ id }) => id);

const mergeNotificationsLists = (n1: Notification[], n2: Notification[]) => unionBy(n => n.id, n1, n2);

const hasChangedNotifications = (notfications: Notification[], nextNotifications: Notification[]): boolean =>
  !!xor(mapNotificationToId(notfications), mapNotificationToId(nextNotifications)).length;

const hasChangedConditions = (conditionsProps: ConditionsProps, nextConditionsProps: ConditionsProps): boolean =>
  !isEqual(conditionsProps, nextConditionsProps);

const filterByPath = (notifications: Notification[], path: string ): Notification[] =>
  // TODO the same filters as in FirebaseHelper.filterNewsByPath - move to a common util
  notifications.filter(({ includeInRoutes, excludeInRoutes }) => {
    const hasNoRoutes = !includeInRoutes?.length && !excludeInRoutes?.length;
    const isCurrentRouteExcluded = excludeInRoutes?.length && !excludeInRoutes.some(route => isOnRoute(route, path));
    const isCurrentRouteIncluded = includeInRoutes?.length && includeInRoutes.some(route => isOnRoute(route, path));

    if (hasNoRoutes
      || isCurrentRouteExcluded
      || isCurrentRouteIncluded
    ) {
      return true;
    }
    return false;
  });

const filterByPlatform = (notifications: Notification[]): Notification[] => notifications.filter(notification => {
  switch (notification.hideOnPlatform) {
    case 'web':
      return CONFIG.IS_MOBILE;
    case 'mobile':
      return !CONFIG.IS_MOBILE;
    default:
      return true;
  }
});

const getVisibleNotifications = (notifications: Notification[], path: string) =>
  filterByPath(filterByPlatform(notifications), path);

/** Notification creation helpers */
const createNotification =
  (type: NotificationCategory) =>
  (id: string, payload: NotificationPayload): Notification =>
    ({
      id,
      ...payload,
      content: {
        type,
        ...payload.content,
      },
    });

const createInfoNotification = (id: string, payload: NotificationPayload): Notification =>
  createNotification(NotificationCategory.INFO)(id, payload);

const createWarningNotification = (id: string, payload: NotificationPayload): Notification =>
  createNotification(NotificationCategory.WARNING)(id, payload);

const createDangerNotification = (id: string, payload: NotificationPayload): Notification =>
  createNotification(NotificationCategory.INFO)(id, payload);

const getActiveSystemCaseNotification = (notifications: SystemNotifications | undefined) => first(notifications);

const getActiveSystemComponentNotification = (
  systemNotifications: SystemNotifications | undefined,
  electricUnits: BatteryUnits | undefined,
): SystemNotification | undefined => {
  if (!systemNotifications || !electricUnits) { return undefined; }

  const electricUnitsIds = electricUnits.map(unit => unit.id);
  const electricUnitsReferenceIds = systemNotifications.map(notification => notification.notifiable);
  const matchedIds = intersection(electricUnitsIds, electricUnitsReferenceIds);

  return systemNotifications.find(notification => matchedIds.includes(notification.notifiable));
};

export const NotificationHelper = {
  addUniqueNotification,
  areNotificationsEqual,
  createDangerNotification,
  createInfoNotification,
  createWarningNotification,
  getVisibleNotifications,
  mapNotificationToId,
  mergeNotificationsLists,
  hasChangedConditions,
  hasChangedNotifications,
  removeNotificationById,
  getActiveSystemCaseNotification,
  getActiveSystemComponentNotification,
};
