import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, Link } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { InfographicCardLayout } from '../InfographicCardLayout/InfographicCardLayout.component';

import './InfographicCommunityCard.component.scss';

interface Props {
  isLoading: boolean;
}

export const InfographicCommunityCard: React.FC<Props> = ({ isLoading = false }) => (
  <div className={'c-infographic-community-card'}>
    <InfographicCardLayout
      headerDataTestId={'dashboard-infographic-card-sonnen-community-header'} 
      title={I18n.t(T.dashboard.infographic.community.title)}
      isLoading={isLoading}
    >
      <div className={'c-infographic-community-card__text'}>
        <DefaultParagraph>
          {I18n.t(T.dashboard.infographic.community.message)}
          {<br />}
          <Link
            dataTestId={'dashboard-infographic-sonnen-community-card-link'} 
            href={I18n.t(T.dashboard.infographic.community.messageLink)}
            label={I18n.t(T.dashboard.infographic.community.messageLinkLabel)}
          />
        </DefaultParagraph>
      </div>
    </InfographicCardLayout>
  </div>
);
