import { T } from '@sonnen/shared-i18n/customer';
import { MediaQuery, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { DemoAnalysisHistoryCard } from '../DemoAnalysisHistoryCard';

import './DemoAnalysisHistory.component.scss';

export const DemoAnalysisHistoryWeb: React.FC = () => (
  <div className="c-demo-analysis-history">
    <PageSubheadline mediumGap={true}>
      {I18n.t(T.analysis.headlines.history)}
    </PageSubheadline>
    <Media query={MediaQuery.UP_SM}>
      {
        (isDesktop: boolean) => isDesktop ? (
          <DemoAnalysisHistoryCard
            isHeaderVisible={true}
          />
        ) : (
          <DemoAnalysisHistoryCard />
        )
      }
    </Media>
  </div>
);
