import { T } from '@sonnen/shared-i18n/customer';
import { Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { AppLoaderProps } from './AppLoader.component';

import './AppLoader.mobile.component.scss';

export const AppLoaderMobile: React.FC<AppLoaderProps> = () => (
  <div className={'m-c-app-loader'}>
    <div className={'m-c-app-loader__loader'}>
      <Loader />
    </div>
    {I18n.t(T.general.loader.mobile)}
  </div>
);
