import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '+app/store/store.interface';
import { getDistributionToPartnerStepStatus } from '+guide/+discovery/store/+discovery.selectors';
import { getDonePhases, getPreviewPhases } from '+guide/+overview/store/+overview.selectors';
import { isFlatOfferAccepted, isFlatOfferAvailable } from '+guide/+purchase/store/+purchase.selectors';
import { getSetupProgress } from '+guide/+setup/store/+setup.selectors';
import { getGuideOffersQueryStatus, isSonnenLead } from '+guide/store/+lead.selectors';
import { getPath, getPaths } from '+router/router.helper';
import { RouteName } from '+router/router.types';
import { ProgressCard } from '+shared/components';
import { factorizePhaseList } from './PhasesOverview.helper';

import './PhasesOverview.component.scss';

const mapStateToProps = (state: StoreState) => ({
  donePhases: getDonePhases(state),
  previewPhases: getPreviewPhases(state),
  partnerStepStatus: getDistributionToPartnerStepStatus(state),
  setupProgress: getSetupProgress(state),
  isOfferAccepted: isFlatOfferAccepted(state),
  isOfferAvailable: isFlatOfferAvailable(state),
  isSonnenLead: isSonnenLead(state),
  guideOffersQueryStatus: getGuideOffersQueryStatus(state),
});

const mapDispatchToProps = {
  goToAcceptStep: () => push(getPath(RouteName.GUIDE_PURCHASE_STEP, ['accept-offer'])),
  goToPurchase: () => push(getPaths().GUIDE_PURCHASE),
  goToSetup: () => push(getPaths().GUIDE_SETUP),
  goToUsage: () => push(getPaths().GUIDE_USAGE),
  goToDiscovery: () => push(getPaths().GUIDE_DISCOVERY),
};

export type PhasesOverviewProps =
  & typeof mapDispatchToProps
  & ReturnType<typeof mapStateToProps>;

export const PhasesOverviewComponent: React.FC<PhasesOverviewProps> = ({
  donePhases,
  previewPhases,
  partnerStepStatus,
  setupProgress,
  isOfferAccepted,
  isOfferAvailable,
  isSonnenLead,
  goToAcceptStep,
  goToPurchase,
  goToSetup,
  goToUsage,
  goToDiscovery,
  guideOffersQueryStatus,
}) => {
  const phases = factorizePhaseList({
    donePhases,
    previewPhases,
    partnerStepStatus,
    setupProgress,
    isOfferAvailable,
    isOfferAccepted,
    isSonnenLead,
    goToAcceptStep,
    goToPurchase,
    goToSetup,
    goToUsage,
    goToDiscovery,
    guideOffersQueryStatus,
  });

  return (
    <div className={'c-phases-overview'}>
      {phases.map((item, index) => (
        <div
          className={'c-phases-overview__item'}
          key={item.title}
        >
          <ProgressCard
            onClick={item.onClick}
            icon={item.icon}
            title={`${index + 1}. ${item.title}`}
            description={item.description}
            progress={item.progress}
            progressPercentage={item.progressPercentage}
            isCtaVisible={item.isCtaVisible}
            ctaLabel={item.ctaLabel}
            isLoading={guideOffersQueryStatus.pending}
          />
        </div>
      ))}
    </div>
  );
};

export const PhasesOverview = connect(mapStateToProps, mapDispatchToProps)(PhasesOverviewComponent);
