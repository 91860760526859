import { Data, MergedData } from '@coolio/json-api';

export type CessionContractAddress = {
  street: string,
  postalCode: string,
  city: string,
  countryCode: string,
};

export type CessionSignedBy = {
  contactId: string,
  date: string,
};

export type CessionContract = {
  signedBy: CessionSignedBy
  existingContractNumber: string;
  batteryAsset: string;
  tnc: {
    privacyTncAccepted: boolean;
    cancellationTncAccepted: boolean;
    productTncAccepted: boolean;
  };
  supply: {
    address: CessionContractAddress;
    data: {
      meterId: string;
    };
  };
  billing: {
    address: CessionContractAddress;
  };
};

export type CessionContractAttributes = {};

export type CessionContractData = Data<CessionContractAttributes>;

export enum CessionSignServiceStatusType {
  NEW = 'new',
  UNSIGNED = 'unsigned',
  SIGNED = 'signed',
  REJECTED = 'rejected',
  PENDING = 'pending',
}

export interface CessionSignServiceDocAttributes {
  url: string;
}

export type CessionSignServiceDocData = Data<CessionSignServiceDocAttributes>;
export type CessionSignServiceDoc = MergedData<CessionSignServiceDocData>;

export interface CessionSignServiceStatusAttributes {
  status: CessionSignServiceStatusType;
  contractStatus: string;
  accountId: string;
  dsoName: string;
}

export type CessionSignServiceStatusData = Data<CessionSignServiceStatusAttributes>;
export type CessionSignServiceStatus = MergedData<CessionSignServiceStatusData>;
