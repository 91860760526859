import { ActionsUnion, createAction } from '+utils/redux';
import { Notification } from './types/notification.interface';
import { SystemNotifications, SystemNotificationType } from './types/systemNotification';

export enum NOTIFICATION_ACTIONS {
  GET_SYSTEM_CASE_NOTIFICATIONS = '[Notifications] GET_SYSTEM_CASE_NOTIFICATIONS',
  SET_SYSTEM_CASE_NOTIFICATIONS = '[Notifications] SET_SYSTEM_CASE_NOTIFICATIONS',
  UPDATE_SYSTEM_CASE_NOTIFICATION = '[Notifications] UPDATE_SYSTEM_CASE_NOTIFICATION',

  GET_SYSTEM_COMPONENT_NOTIFICATIONS = '[Notifications] GET_SYSTEM_COMPONENT_NOTIFICATIONS',
  SET_SYSTEM_COMPONENT_NOTIFICATIONS = '[Notifications] SET_SYSTEM_COMPONENT_NOTIFICATIONS',
  UPDATE_SYSTEM_COMPONENT_NOTIFICATION = '[Notifications] UPDATE_SYSTEM_COMPONENT_NOTIFICATION',

  ADD_NOTIFICATION = '[Notification] ADD_NOTIFICATION',
  SET_NOTIFICATIONS = '[Notification] SET_NOTIFICATIONS',
  REMOVE_NOTIFICATION = '[Notification] REMOVE_NOTIFICATION',
  CLEAR_NOTIFICATIONS = '[Notification] CLEAR_NOTIFICATIONS',
}

export const NotificationActions = {
  // API (system)notifications actions
  getSystemNotifications: (type?: SystemNotificationType) => type === SystemNotificationType.CASE
    ? createAction(NOTIFICATION_ACTIONS.GET_SYSTEM_CASE_NOTIFICATIONS)
    : createAction(NOTIFICATION_ACTIONS.GET_SYSTEM_COMPONENT_NOTIFICATIONS),

  setSystemCaseNotifications: (systemNotifications: SystemNotifications) =>
    createAction(NOTIFICATION_ACTIONS.SET_SYSTEM_CASE_NOTIFICATIONS, { systemNotifications }),

  setSystemComponentNotifications: (systemNotifications: SystemNotifications) =>
    createAction(NOTIFICATION_ACTIONS.SET_SYSTEM_COMPONENT_NOTIFICATIONS, { systemNotifications }),

  updateSystemNotification: (type: SystemNotificationType) => type === SystemNotificationType.CASE
    ? createAction(NOTIFICATION_ACTIONS.UPDATE_SYSTEM_CASE_NOTIFICATION)
    : createAction(NOTIFICATION_ACTIONS.UPDATE_SYSTEM_COMPONENT_NOTIFICATION),

  // Notifications actions
  setNotifications: (notifications: Notification[]) =>
    createAction(NOTIFICATION_ACTIONS.SET_NOTIFICATIONS, { notifications }),

  addNotification: (notification: Notification) =>
    createAction(NOTIFICATION_ACTIONS.ADD_NOTIFICATION, { notification }),

  removeNotification: (id: string) =>
    createAction(NOTIFICATION_ACTIONS.REMOVE_NOTIFICATION, { id }),

  clearNotifications: () =>
    createAction(NOTIFICATION_ACTIONS.CLEAR_NOTIFICATIONS),
};

export type NotificationActions = ActionsUnion<typeof NotificationActions>;
