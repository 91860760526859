import { isPlainObject, reduce } from 'lodash';

type Iterator = (val: any, key: string, result: object) => any;

export const deepMap = (obj: object, iterator: Iterator) => {
  if (!isPlainObject(obj)) {
    return obj;
  }
  return reduce(obj, (result: object, val: any, key: string) => {
    result[key] = isPlainObject(val) 
      ? deepMap(val, iterator) 
      : iterator(val, key, obj);
    return result;
  }, {});
};
