import { HELP_ACTIONS, HelpActions } from '+help/store/help.actions';
import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { GET_TAXATION_DATA_QUERY, HelpState, initialState, SEND_SUPPORT_MESSAGE_QUERY } from './help.state';

export const helpReducer = createReducer<HelpState, HelpActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case HELP_ACTIONS.SET_TAXATION_DATA: {
        return {
          ...state,
          taxationData: action.data,
        };
      }
      default:
        return queryReducer(state, action, [GET_TAXATION_DATA_QUERY, SEND_SUPPORT_MESSAGE_QUERY]);
    }
  },
);
