import { ROUTER_ACTIONS } from '+app/router/store/router.actions';
import { StoreState } from '+app/store/store.interface';
import { history } from '+router/store/index';
import { QueryParamsHelper, RouterHelper } from '@sonnen/shared-web';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { GUIDE_ROUTES } from './+guide.helper';
import { getGuideAuthToken } from './+guide.selectors';

const persistToken: Middleware = (api: MiddlewareAPI<Dispatch, StoreState>) => next => action => {
  if (action.type !== ROUTER_ACTIONS.LOCATION_CHANGE) {
    return next(action);
  }

  if ((action.payload.action !== 'PUSH') && (action.payload.action !== 'REPLACE')) {
    return next(action);
  }

  if (!RouterHelper.matchPath(GUIDE_ROUTES)(action.payload.location.pathname)) {
    return next(action);
  }

  const token = getGuideAuthToken(api.getState());
  const location: Location = action.payload.location;
  const params: Record<string, string> = QueryParamsHelper.getQueryParams(location.search);

  if (!!params.token || !token) {
    return next(action);
  }

  history.replace({
    ...action.payload.location,
    search: QueryParamsHelper.createQueryString({ ...params, token }),
  });
};

export const GuideMiddleware = {
  persistToken,
};
