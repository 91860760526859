import { ImageScaleType, MATCH_PARENT } from '@kanva/core';
import { ImageView, Kanva } from '@kanva/react';
import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, ButtonTheme, Icon, Loader } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import placeholderImg from '+images/community/profiles/1.jpg';

import './ImageUploader.component.scss';

interface Props {
  name: string;
  isDisabled?: boolean;
  image?: string | null;
  placeholder?: React.ReactNode;
  shouldReset?: boolean;
  onImageChange: (image: string | null) => void;
}

interface State {
  file: Record<string, any>;
  imagePreviewUrl: string | ArrayBuffer | null;
  imageBase64: string;
  isLoading: boolean;
}

export class ImageUploader extends React.PureComponent<Props, State> {
  private canvasRef: HTMLCanvasElement | null;
  private initialState: State;

  constructor(props: Props) {
    super(props);
    this.canvasRef = null;
    this.initialState = {
      file: {},
      imagePreviewUrl: null,
      imageBase64: '',
      isLoading: false,
    };

    this.state = { ...this.initialState };
  }

  componentDidUpdate() {
    if (this.state.imagePreviewUrl !== null && this.props.shouldReset) {
      this.setState({ ...this.initialState });
    } else if (this.state.imagePreviewUrl !== null) {
      // NOTE: It might need some time to draw the canvas first.
      // Consider add listener to Kanva to be sure if image is drawn
      setTimeout(() => {
        this.props.onImageChange(this.getBase64FromCanvas());
      }, 100);
    }
  }

  setImageToCanvas = (event: React.FormEvent<HTMLInputElement> | any) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    const reader = new FileReader();
    const file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file,
        imagePreviewUrl: reader.result,
        isLoading: false,
      });
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  getBase64FromCanvas = (): string | null => {
    if (!this.canvasRef || !this.state.file) {
      return null;
    }

    return this.canvasRef.toDataURL();
  };

  removeImageFromCanvas = () => {
    this.setState({
      ...this.initialState,
    });
    this.props.onImageChange(null);
  };

  renderPreview = () => {
    switch (true) {
      case this.state.imagePreviewUrl !== null:
        return this.renderUploadedImage();
      case this.props.image !== null:
        return this.renderInitialImage();
      default:
        return this.renderPlaceholder();
    }
  };

  renderUploadedImage = () => (
    <Kanva
      className={'c-image-uploader__canvas'}
      canvasRef={ref => this.canvasRef = ref}
    >
      <ImageView
        source={this.state.imagePreviewUrl as string}
        scaleType={ImageScaleType.FIT_CENTER}
        layoutParams={{ width: MATCH_PARENT, height: MATCH_PARENT }}
      />
    </Kanva>
  );

  renderInitialImage = () => (
    <div className={'c-image-uploader__image'}>
      <img src={this.props.image as string} />
    </div>
  );

  renderPlaceholder = () => (
    <div className={'c-image-uploader__placeholder'}>
      {this.state.isLoading ? (
        <Loader />
      ) : (
        this.props.placeholder ||
        <img src={placeholderImg} />
      )}
    </div>
  );

  render() {
    const { image, name, isDisabled } = this.props;
    const { imagePreviewUrl } = this.state;

    return (
      <div className={'c-image-uploader'}>
        <div className={'c-image-uploader__preview'}>
          {this.renderPreview()}
        </div>
        {imagePreviewUrl === null && image === null ? (
          <div className={'c-image-uploader__button-wrapper'}>
            <input
              type={'file'}
              accept={'.jpg,.jpeg,.png'}
              name={name}
              className={'c-image-uploader__input'}
              disabled={isDisabled}
              onChange={this.setImageToCanvas}
            />
            <Button
              label={(
                <>
                  <Icon.Plus className={'c-image-uploader__button-icon'}/>
                  {I18n.t(T.communityProfile.profileCard.addImage)}
                </>
              )}
              type={'button'}
              size={ButtonSize.PILL}
              theme={ButtonTheme.PRIMARY}
              onClick={this.removeImageFromCanvas}
            />
          </div>
        ) : (
          <div className={'c-image-uploader__button-wrapper'}>
            <Button
              label={(
                <>
                  <Icon.Close className={'c-image-uploader__button-icon'}/>
                  {I18n.t(T.communityProfile.profileCard.removeImage)}
                </>
              )}
              type={'button'}
              size={ButtonSize.PILL}
              theme={ButtonTheme.PRIMARY}
              onClick={this.removeImageFromCanvas}
            />
          </div>
        )}
      </div>
    );
  }
}
