import { GTMEventCategory, SendEvent } from '+shared/helpers/analytics';
import * as React from 'react';
import { useHistory } from 'react-router';

export const useBannerShownGtmEvent = (sendEvent: SendEvent): void => {
  const { location } = useHistory();

  React.useEffect(() => {
    sendEvent(`ga-product-migration--banner-shown-on-${location.pathname}`, {
      category: GTMEventCategory.PRODUCT_MIGRATION,
      action: `pm-banner-shown`,
    });
  }, []);
};
