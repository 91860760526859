import { Consumption } from '+legacy/components/charts/AnnualConsumptionChart/AnnualConsumptionChart.types';
import { T } from '@sonnen/shared-i18n/customer';
import { insertIf, Measurement } from '@sonnen/shared-web/index';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';

import {
  getBillingPeriodUTCTimestampsInTimezone,
  getEnergyProductDetailsItems,
  getUsageAllowancePeriod,
  mapStatisticsToEnergyUsage,
} from '+contract/store/helpers/contract.helpers';
import { Contract } from '+contract/store/types/contract.interface';
import { getDeliveryStatus, getFormattedDate } from '+legacy/helpers/dates';
import { StatisticsData } from '+shared/types/statisticsData.interface';
import { DateUtil, TimePeriod } from '+utils/date.util';
import { roundToNextMultipleOfFive } from '+utils/number.util';
import { toLowerCaseFirstLetter } from '+utils/toLowerCaseFirstLetter.util';

export const getBillingPeriodForContractTypeSonnenFlatHome = (
  batteryTimezone: string,
  contract: Contract,
): TimePeriod => {
  return {
    start: moment.tz(contract.startAt, batteryTimezone),
    end: moment.tz(contract.endAt, batteryTimezone),
  };
};

export const getUsageAllowanceForSonnenFlatHomeForCurrentPeriod = (contract: Contract) =>
  contract.amountOfFreeEnergy;

export const getPercentageUsageForContractTypeSonnenFlatHome = (
  freeUsageAllowance: number | null,
  statistics: StatisticsData | undefined,
) => {
  if (!freeUsageAllowance) { return 0; }

  const consumedEnergy = statistics?.total?.consumedEnergy;
  const guardedConsumptionMeasurement = (consumedEnergy && consumedEnergy[0]) || 0;
  const totallyConsumedEnergy = guardedConsumptionMeasurement / 1000;

  return roundToNextMultipleOfFive(Math.min(
    ((totallyConsumedEnergy / freeUsageAllowance) * 100),
    100,
  ));
};

export const getProductDetailsListForContractTypeSonnenFlatHome = (
  contract: Contract,
  freeUsageAllowance: number | null,
) => {
  const { start, end } = getUsageAllowancePeriod(contract, moment(contract.usageAllowanceActivatedOn).year());
  const shouldRenderFractionAmountOfFreeEnergy = DateUtil.isDateWithinThisYear(start);
  const billingPeriodLabel = `(${getFormattedDate(start)} - ${getFormattedDate(end)})`;

  return [
    ...getEnergyProductDetailsItems(
      I18n.t(T.yourFlat.tableValues[contract.contractType]) || toLowerCaseFirstLetter(contract.contractType),
      contract.contractNumber,
      contract.orderedAt,
      'contract-flat-home-overview',
    ),
    {
      label: I18n.t(T.yourFlat.tableLabels.fifth),
      value: getDeliveryStatus(
        contract.deliveryStartAt || null,
        T.yourFlat.tableValues.active,
        T.yourFlat.tableValues.inactive,
        T.yourFlat.tableValues.notDelivered,
      ),
      dataTestId: 'contract-flat-home-overview-delivery-start-at', 
    },
    {
      label: `${I18n.t(T.yourFlat.tableLabels.second)} ${I18n.t(T.yourFlat.tableLabels.fullYear)}`,
      value: contract.amountOfFreeEnergy
        ? `${contract.amountOfFreeEnergy} kWh` // TODO: format with getKiloPowerObj
        : '-',
        dataTestId: 'contract-flat-home-overview-amount-of-free-energy', 
    },
    ...insertIf(shouldRenderFractionAmountOfFreeEnergy, {
      label: `${I18n.t(T.yourFlat.tableLabels.second)} ${billingPeriodLabel}`,
      value: freeUsageAllowance
        ? `${freeUsageAllowance} kWh` // TODO: format with getKiloPowerObj
        : '-',
        dataTestId: 'contract-flat-home-overview-free-usage-allowance', 
    }),
  ];
};

export const getContractSonnenFlatHomeUsageByDay = (
  billingPeriod: TimePeriod,
  consumptionDaily: Measurement<number>,
): Consumption => {
  const { start } = getBillingPeriodUTCTimestampsInTimezone(billingPeriod);

  return mapStatisticsToEnergyUsage(consumptionDaily, start);
};
