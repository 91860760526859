export enum NetworkCode {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  UNPROCESSABLE_ENTITY = 422,
  FAILED_DEPENDENCY = 424,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
  TIMEOUT = 504,
}

export enum ContentType {
  JSON = 'application/json',
  VND_JSON = 'application/vnd.api+json',
  URL_ENCODED = 'application/x-www-form-urlencoded',
}

export class ResponseError extends Error {
  constructor(public readonly response: Response, public readonly extra?: { [key: string]: any }) {
    super(`${response.url}: ${response.status} ${response.statusText}`);
  }
}
