import { select } from 'd3-selection';
import * as React from 'react';
import {
  AXIS_DOMAIN_MARGIN, X_AXIS_MARGIN,
  Y_AXIS_MARGIN,
} from '../../../frequency.constants';

interface Props {
  height: number;
  width: number;
}

export class AxisX extends React.PureComponent<Props> {
  static defaultProps = {
    height: 0,
    width: 0,
  };

  groupRef: Element | null = null;

  componentDidMount() {
    this.updateGraph();
  }

  componentDidUpdate() {
    this.updateGraph();
  }

  updateGraph = () => {
    const { height, width } = this.props;

    select(this.groupRef)
      .select('line')
      .attr('transform', `translate(${X_AXIS_MARGIN},${Y_AXIS_MARGIN})`)
      .attr('y1', height - Y_AXIS_MARGIN)
      .attr('y2', height - Y_AXIS_MARGIN)
      .attr('x1', AXIS_DOMAIN_MARGIN)
      .attr('x2', width - X_AXIS_MARGIN);
  };

  setGroupRef = (ref: Element | null) => {
    this.groupRef = ref;
  };

  render() {
    return (
      <g
        className="c-frequency-chart__axis"
        ref={this.setGroupRef}
      >
        <line className="c-frequency-chart__axis-line" />
      </g>
    );
  }
}
