import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, ButtonTheme, Icon } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { history } from '+app/router/store';

import { getGuideAcceptedOffer } from '+app/+guide/store/+guide.selectors';
import { StoreState } from '+app/store/store.interface';
import './GuideActivationBanner.component.scss';

type Props = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: StoreState) => ({
  offerAccepted: !isNil(getGuideAcceptedOffer(state)),
});

// TODO remove this custom banner after all other notifications receive final UI.
const GuideActivationBannerComponent = ({ offerAccepted }: Props) => offerAccepted ? (
  <div className={'c-guide-activate-banner'}>
    <div className={'c-guide-activate-banner__title'}>
      <span>
        {I18n.t(T.general.messages.guideActivateBannerTitle)}
      </span>
    </div>
    <div className={'c-guide-activate-banner__button'}>
      <Button
        label={
          <>
            {I18n.t(T.action.activateAccount)}
            <Icon.Arrow className={'c-guide-activate-banner__button-arrow'} />
          </>
        }
        theme={ButtonTheme.OUTLINE}
        size={ButtonSize.NORMAL}
        onClick={() => history.push(getPath(RouteName.LOGIN))} // TODO make sure this is the right route
      />
    </div>
  </div>
) : null;

export const GuideActivationBanner = connect(mapStateToProps)(GuideActivationBannerComponent);
