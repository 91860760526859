import { TimePeriod } from '+utils/date.util';
import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { TooltipIcon } from '+app/shared/components/TooltipIcon';
import { ContractBillingPeriod } from '../ContractBillingPeriod';

import './ContractBillingSection.component.scss';

export type ContractBillingSectionProps = {
  billingPeriod: TimePeriod;
};

export const ContractBillingSection: React.FC<ContractBillingSectionProps> = ({ billingPeriod }) => {
  return (
    <div className={'c-contract-billing-section'}>
      <ContractBillingPeriod
        start={billingPeriod.start}
        end={billingPeriod.end}
      />
      <div className={'c-contract-billing-section__additional-info-side'}>
        <TooltipIcon icon={'QuestionMark'}>
          <DefaultParagraph>
            {I18n.t(T.yourFlat.tooltipMessage)}
          </DefaultParagraph>
        </TooltipIcon>
      </div>
    </div>
  );
};
