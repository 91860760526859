import { ActionsUnion, createAction } from '+utils/redux';

export enum GUIDE_DISCOVERY_ACTIONS {
  TOGGLE_MODAL = '[+Discovery] TOGGLE_MODAL',
  CLOSE_MODAL = '[+Discovery] CLOSE_MODAL',
}

export const GuideDiscoveryActions = {
  toggleModal: (checked: boolean) => createAction(
    GUIDE_DISCOVERY_ACTIONS.TOGGLE_MODAL,
    { checked },
  ),

  closeModal: () => createAction(
    GUIDE_DISCOVERY_ACTIONS.CLOSE_MODAL,
  ),
};

export type GuideDiscoveryActions = ActionsUnion<typeof GuideDiscoveryActions>;
