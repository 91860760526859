import { Icon } from '@sonnen/shared-web';
import * as React from 'react';

// TODO: add other accessories types when the full list is known
export enum Accessories {
  BATTERIES = 'batteries',
  CONTRACTS = 'contracts',
  CHARGERS = 'chargers',
}

const accessoriesConfig = {
  [Accessories.BATTERIES]: {
    title: 'sonnenBatterie',
    icon: <Icon.SonnenBattery />,
  },
  [Accessories.CONTRACTS]: {
    title: 'Contract',
    icon: <Icon.Contract />,
  },
  [Accessories.CHARGERS]: {
    title: 'sonnenCharger',
    icon: <Icon.Charger />,
  },
};

export const mapProductKeyToAccessory = (productKey: string) => accessoriesConfig[productKey]
  ? accessoriesConfig[productKey]
  : undefined;
