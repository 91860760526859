import { getGuideSyncQueryStatus } from '+app/+guide/store/+lead.selectors';
import { T } from '@sonnen/shared-i18n/customer';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  DefaultParagraph,
  Loadable,
  Loader,
  MediaCard,
} from '@sonnen/shared-web';
import { WizardContext } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getGuideLead } from '+app/+guide/store/+lead.selectors';
import { getPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { QueryActions } from '+app/shared/store/query/query.actions';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { Lead } from '+guide/store/types';
import { RouterGuideAcceptanceRouteParams } from '+router/router.types';
import { history } from '+router/store';
import { GuideAcceptanceBackButton, GuideAcceptancePersonalDataTable } from '../../components';
import { GuideAcceptanceActions } from '../../store';

import './GuideAcceptancePersonalDetails.component.scss';

const mapDispatchToProps = mapActions({
  getEnergyProviders: GuideAcceptanceActions.getEnergyProviders,
  clearQuery: QueryActions.init,
});

const mapStateToProps = (state: StoreState) => ({
  lead: getGuideLead(state),
  leadDetailsQueryStatus: getGuideSyncQueryStatus(state),
});

type Props =
  & RouteComponentProps<RouterGuideAcceptanceRouteParams>
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

interface ButtonsProps {
  onConfirm: () => void;
  onReject: () => void;
}

/**
 * @TODO: Move all of this to respective dedicated components
 */

const GuideAcceptancePersonalDetailsContent: React.FC<{ lead: Lead }> = ({ lead }) => (
  <>
    <DefaultParagraph className={'c-guide-acceptance-personal-details__paragraph'}>
      {I18n.t(T.customerGuidance.acceptance.personalDetails.headline)}
    </DefaultParagraph>
    <GuideAcceptancePersonalDataTable lead={lead} />
  </>
);

const GuideAcceptancePersonalDetailsButtons: React.FC<ButtonsProps> = ({ onConfirm, onReject }) => (
  <div className={'c-guide-acceptance-personal-details__buttons'}>
    <div className={'c-guide-acceptance-personal-details__button'}>
      <Button
        label={I18n.t(T.customerGuidance.acceptance.personalDetails.confirm)}
        size={ButtonSize.SECONDARY}
        onClick={onConfirm}
      />
    </div>
    <div className={'c-guide-acceptance-personal-details__button'}>
      <Button
        label={I18n.t(T.customerGuidance.acceptance.personalDetails.notCorrect)}
        size={ButtonSize.SECONDARY}
        theme={ButtonTheme.LIGHT}
        onClick={onReject}
      />
    </div>
  </div>
);

export const GuideAcceptancePersonalDetailsComponent: React.FC<Props> = ({
  actions,
  lead,
  match,
  leadDetailsQueryStatus,
}) => {
  const isLoading = leadDetailsQueryStatus.pending;
  const wizard = React.useContext(WizardContext);

  React.useEffect(() => {
    actions.getEnergyProviders();
  }, []);

  return (
    <div className={'c-guide-acceptance-personal-details'}>
      <MediaCard
        footerContent={!isLoading &&
          <GuideAcceptancePersonalDetailsButtons
            onConfirm={wizard.next}
            onReject={() => history.push(getPath(RouteName.GUIDE_ACCEPTANCE_ID_WRONG_DETAILS, [match.params.offerId]))}
          />
        }
      >
        <Loadable
          transition={'fade'}
          predicate={isLoading}
          loader={<Loader className={'c-guide-acceptance-personal-details__loader'} />}
        >
          <GuideAcceptancePersonalDetailsContent lead={lead!} />
        </Loadable>
      </MediaCard>
      <GuideAcceptanceBackButton />
    </div>
  );
};

export const GuideAcceptancePersonalDetails = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GuideAcceptancePersonalDetailsComponent),
);
