import { Configuration, EnergyProvider, Lead, UtilityData } from '+guide/store/types';
import { Query } from '+shared/store/query/query.state';

export const GET_ENERGY_PROVIDERS_QUERY = 'getEnergyProvidersQuery';
export const ACCEPT_DOCUMENTS_QUERY = 'acceptDocumentsQuery';
export const SET_PAYMENT_DATA_QUERY = 'setPaymentDataQuery';
export const SET_UTILITY_DATA_QUERY = 'setUtilityDataQuery';
export const GET_UTILITY_DATA_QUERY = 'getUtilityDataQuery';
export const ACCEPT_OFFER_QUERY = 'acceptOfferQuery';
export const SET_PV_SYSTEM_KEYS_QUERY = 'setPvSystemKeysQuery';

export interface GuideAcceptanceState {
  energyProviders: EnergyProvider[];
  utilityData?: UtilityData;
  [GET_ENERGY_PROVIDERS_QUERY]: Query<Lead>;
  [SET_PAYMENT_DATA_QUERY]: Query<Lead>;
  [SET_UTILITY_DATA_QUERY]: Query<Lead>;
  [GET_UTILITY_DATA_QUERY]: Query<Lead>;
  [ACCEPT_OFFER_QUERY]: Query<Lead>;
  [ACCEPT_DOCUMENTS_QUERY]: Query<Lead>;
  [SET_PV_SYSTEM_KEYS_QUERY]: Query<Configuration>;
}

export const initialState: GuideAcceptanceState = {
  energyProviders: [],
  utilityData: undefined,
  [GET_ENERGY_PROVIDERS_QUERY]: {},
  [SET_PAYMENT_DATA_QUERY]: {},
  [SET_UTILITY_DATA_QUERY]: {},
  [GET_UTILITY_DATA_QUERY]: {},
  [ACCEPT_OFFER_QUERY]: {},
  [ACCEPT_DOCUMENTS_QUERY]: {},
  [SET_PV_SYSTEM_KEYS_QUERY]: {},
};
