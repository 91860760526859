import { Tag } from '+app/shared/components';
import { T } from '@sonnen/shared-i18n/customer';
import * as moment from 'moment';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

export const createExpirationTag = (expirationDate: Date): JSX.Element => {

  const now = moment();
  const duration = moment.duration(now.diff(moment(expirationDate)));
  const differenceInDays = Math.abs(Math.floor(duration.asDays()));

  if (now.isBefore(moment(expirationDate))) {
    return (
      <Tag
        text={getExpiresInText()}
        theme={'sent'}
      />
    );
  } else {
    return (
      <Tag
        text={getExpiredSinceText()}
        theme={'expired'}
      />
    );
  }

  function getExpiresInText(): string {
    if (differenceInDays === 1) {
      return I18n.t(T.customerGuidance.purchase.flatOffer.expiresToday);
    }
    return I18n.t(T.customerGuidance.purchase.flatOffer.expiresInDays, {differenceInDays});
  }

  function getExpiredSinceText(): string {
    if (differenceInDays === 0) {
      return I18n.t(T.customerGuidance.purchase.flatOffer.expiredSinceToday);
    }
    return I18n.t(T.customerGuidance.purchase.flatOffer.expiredSinceDays, {differenceInDays});
  }

};
