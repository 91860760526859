import { IconType } from '@sonnen/shared-web/src/components/Icon/models/Icon.model';
import { last } from 'lodash';
import * as React from 'react';

import { PortalExit } from '+app/shared/helpers';
import { Portal } from '+shared/components/Portal';
import { NestedNavListItem } from './NestedNavListItem';
import { NestedNavSlider } from './NestedNavSlider';
import { NestedNavSpec } from './spec/NestedNavSpec.types';

export const NESTED_NAV_ANIMATION_DURATION = 500;

export interface NestedNavContent {
  icon: IconType;
  title: string;
  content: React.ReactNode;
}

export interface NestedNavAnimating {
  slidingIn: number | undefined;
  slidingOut: number | undefined;
}

export interface NestedNavProps {
  icon: IconType;
  title: string;
  subtitle?: string;
  slides: NestedNavContent[] | [];
}

export interface NestedNavContext {
  stack: number[];
  animating: NestedNavAnimating;
  slides: NestedNavContent[];
  push: (index: number) => void;
  goBack: () => void;
}

const NestedNavContext = React.createContext<NestedNavContext>({
  stack: [],
} as unknown as NestedNavContext);

export const NestedNav: React.FC<NestedNavProps> = ({
  icon,
  title,
  subtitle,
  slides,
}) => {
  const [stack, setStack] = React.useState<number[]>([]);
  const [activeIndex, setActiveIndex] = React.useState<number | undefined>();
  const [animating, setAnimating] = React.useState<NestedNavAnimating>({
    slidingIn: undefined,
    slidingOut: undefined,
  });
  const [isNestedNavOpen, toggleNestedNav] = React.useState(false);

  const push = (index: number) => {
    if (index < 0 || index > slides.length - 1) { return; }

    setStack([...stack, index]);
    setAnimating({
      slidingIn: index,
      slidingOut: undefined,
    });
  };

  const goBack = () => {
    setStack(stack.slice(0, stack.length - 1));
    setAnimating({
      slidingIn: undefined,
      slidingOut: activeIndex,
    });
  };

  const getLastFromStack = () => last(stack);

  const isEmptyStack = () => stack.length === 0;

  const openNav = () => {
    if (!isNestedNavOpen) { 
      push(0);
      toggleNestedNav(true);
    }
  };

  const closeNav = () => {
    setTimeout(() => {
      toggleNestedNav(false);
    }, NESTED_NAV_ANIMATION_DURATION);
  };

  React.useEffect(() => {
    if (isEmptyStack()) {
      closeNav();
    }

    setActiveIndex(getLastFromStack());
  }, [stack]);

  return (
    <NestedNavContext.Provider
      value={{
        stack,
        animating,
        slides,
        push,
        goBack,
      }}
    >
      <NestedNavListItem
        icon={icon}
        title={title}
        subtitle={subtitle}
        theme={'dark'}
        onClick={openNav}
        data-test={NestedNavSpec.NESTED_NAV_OPEN_BTN}
      />
      <Portal target={PortalExit.ROOT}>
        {isNestedNavOpen && (
          <NestedNavSlider />
        )}
      </Portal>
    </NestedNavContext.Provider>
  );
};

export const useNestedNav = () => React.useContext(NestedNavContext);
