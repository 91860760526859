import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { BATTERY_ACTIONS, BatteryActions } from './battery.actions';
import {
  BatteryState,
  GET_BATTERY_CELL_CARE_HISTORY_QUERY,
  GET_BATTERY_DATA_QUERY,
  GET_BATTERY_DOCUMENTS_QUERY,
  GET_BATTERY_ELECTRIC_UNITS_QUERY,
  initialState,
  SET_BACKUP_BUFFER_QUERY,
} from './battery.state';

export const reducer = createReducer<BatteryState, BatteryActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case BATTERY_ACTIONS.SET_DATA:
        return {
          ...state,
          data: action.data,
        };
      case BATTERY_ACTIONS.GET_DOCUMENTS:
        return {
          ...state,
          documentsLanguage: action.language,
        };
      case BATTERY_ACTIONS.SET_DOCUMENTS:
        return {
          ...state,
          documents: action.documents,
        };
      case BATTERY_ACTIONS.SET_BATTERY_ELECTRIC_UNITS:
        return {
          ...state,
          electricUnits: action.data,
        };
      case BATTERY_ACTIONS.ADD_BACKUP_BUFFER_PROCESSING:
        return {
          ...state,
          backupBuffer: {
            ...state.backupBuffer,
            [action.batteryId]: {
              processingValue: action.processingValue,
              processingStartAt: new Date(),
            },
          },
        };
      case BATTERY_ACTIONS.DELETE_BACKUP_BUFFER_PROCESSING:
        return {
          ...state,
          backupBuffer: Object.keys(state.backupBuffer)
            .filter(processingBatteryId => processingBatteryId !== action.batteryId),
        };
      case BATTERY_ACTIONS.SET_BATTERY_CELL_CARE_HISTORY:
        return {
          ...state,
          cellCareHistory: action.batteryStatusHistory,
        };
      case BATTERY_ACTIONS.CLEAR_SITE_BATTERY_STATE:
        return {
          ...initialState,
          backupBuffer: {
            ...state.backupBuffer,
          },
        };
      default:
        return queryReducer(state, action, [
          GET_BATTERY_DATA_QUERY,
          GET_BATTERY_ELECTRIC_UNITS_QUERY,
          GET_BATTERY_DOCUMENTS_QUERY,
          GET_BATTERY_CELL_CARE_HISTORY_QUERY,
          SET_BACKUP_BUFFER_QUERY,
        ]);
    }
  },
  {
    persist: {
      key: 'battery',
    },
  },
);
