import { PlatformSelector } from '+components';
import * as React from 'react';
import { ErrorPageMobile } from './ErrorPage.mobile.component';
import { ErrorPageWeb } from './ErrorPage.web.component';

import './ErrorPage.component.scss';

export const ErrorPage: React.FC = () => (
  <PlatformSelector
    mobile={<ErrorPageMobile />}
    web={<ErrorPageWeb />}
  />
);
