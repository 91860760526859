import { T } from '@sonnen/shared-i18n/customer';
import { StatusType } from '@sonnen/shared-web';
import { I18n } from 'react-redux-i18n';

import { isOnRoute } from '+app/router/router.helper';
import { Locale } from '+legacy/helpers/i18n.helper';
import { LevelValue, News, NewsMessages } from '+shared/store/news-channel/types/news.interface';

const getNewsStatusType = (level: LevelValue): StatusType => ({
  1: StatusType.DANGER,
  2: StatusType.WARNING,
  3: StatusType.INFO,
}[level]);

const getNewsStatusLabel = (level: LevelValue): string => ({
  1: I18n.t(T.newsChannel.level.failure),
  2: I18n.t(T.newsChannel.level.warning),
  3: I18n.t(T.newsChannel.level.info),
}[level]);

const hasMessageLang = (message: NewsMessages, lang: Locale): boolean =>
  Boolean(message[lang]?.short);

const getTranslatedContent = (message: NewsMessages, lang: Locale): string =>
  hasMessageLang(message, lang)
    ? message[lang]!.long
    : message[Locale.EN_GB]!.long;

const getTranslatedHeadline = (message: NewsMessages, lang: Locale): string =>
  hasMessageLang(message, lang)
    ? message[lang]!.short
    : message[Locale.EN_GB]!.short;

const filterVisibleNews = (hiddenNewsIds: string[]) => (newsList: News[]) =>
  newsList.filter(news => !hiddenNewsIds.includes(news.id));

const filterNewsByPath = (path: string) => (newsList: News[]) =>
// TODO the same filters as in NotificationHelper.filterByPath - move to a common util
newsList.filter(({ includeInRoutes, excludeInRoutes }) => {
  const hasNoRoutes = !includeInRoutes.length && !excludeInRoutes.length;
  const isCurrentRouteExcluded = excludeInRoutes.length && !excludeInRoutes.some(route => isOnRoute(route, path));
  const isCurrentRouteIncluded = includeInRoutes.length && includeInRoutes.some(route => isOnRoute(route, path));

  if (hasNoRoutes
    || isCurrentRouteExcluded
    || isCurrentRouteIncluded
  ) {
    return true;
  }
  return false;
});

export const NewsSectionHelper = {
  filterVisibleNews,
  filterNewsByPath,
  getTranslatedContent,
  getTranslatedHeadline,
  getNewsStatusLabel,
  getNewsStatusType,
};
