import { FormikHelpers } from 'formik';

import { ActionsUnion, createAction } from '+app/utils/redux';
import { ProductMigrationCampaigns } from '+product-migration/store/types/productMigrationCampaigns.interface';
import { CreateConfigurationFormValues } from '../components/ProductMigrationCollectingDataForm/ProductMigrationCollectingDataForm.helper';
import { ProductMigrationTermsAndConditionsForm } from '../components/ProductMigrationTermsAndConditions/ProductMigrationTermsAndConditions.helper';
import { DsoListData } from './types/dsoOperators.interface';
import { ProductConfiguration, ProductMigrationGlobalStatusReset } from './types/productConfiguration.interface';
import { CessionSignServiceStatusType } from './types/productMigrationCession.interface';
import { ProductMigrationStatusType } from './types/productMigrationStatus.interface';

export enum PRODUCT_MIGRATION_ACTIONS {
  CREATE_CONFIGURATION = '[ProductMigration] CREATE_CONFIGURATION',
  GET_CONFIGURATION = '[ProductMigration] GET_CONFIGURATION',
  SET_CONFIGURATION = '[ProductMigration] SET_CONFIGURATION',
  SET_CONFIGURATION_ID = '[ProductMigration] SET_CONFIGURATION_ID',
  SET_CONFIGURATION_BASE = '[ProductMigration] SET_CONFIGURATION_BASE',
  GET_STATUS = '[ProductMigration] GET_STATUS',
  SET_STATUS = '[ProductMigration] SET_STATUS',
  // TODO find better naming
  GET_DSO_OPERATORS = '[ProductMigration] GET_DSO_OPERATORS',
  SET_DSO_OPERATORS = '[ProductMigration] SET_DSO_OPERATORS',
  CREATE_CESSION = '[ProductMigration] CREATE_CESSION',
  GET_CESSION = '[ProductMigration] GET_CESSION',
  SET_CESSION = '[ProductMigration] SET_CESSION',
  GET_CESSION_STATUS = '[ProductMigration] GET_CESSION_STATUS',
  SET_CESSION_STATUS = '[ProductMigration] SET_CESSION_STATUS',
  ACCEPT_OFFER = '[ProductMigration] ACCEPT_OFFER',
  CLEAR_PRODUCT_MIGRATION = '[ProductMigration] CLEAR_PRODUCT_MIGRATION',
  SET_CREATE_CESSION_ERROR_STATUS = '[ProductMigration] SET_CREATE_CESSION_ERROR_STATUS',
  GET_ACTIVE_CAMPAIGNS = '[ProductMigration] GET_ACTIVE_CAMPAIGNS',
  SET_ACTIVE_CAMPAIGNS = '[ProductMigration] SET_ACTIVE_CAMPAIGNS',
  GET_GLOBAL_STATUS_RESET = '[ProductMigration] GET_GLOBAL_STATUS_RESET',
  SET_GLOBAL_STATUS_RESET = '[ProductMigation] SET_GLOBAL_STATUS_RESET',
  CLEAR_GLOBAL_STATUS_RESET = '[ProductMigation] CLEAR_GLOBAL_STATUS_RESET',
}

export const ProductMigrationActions = {
  createConfiguration: (
    formValues: CreateConfigurationFormValues,
    actions: FormikHelpers<CreateConfigurationFormValues>,
  ) => createAction(PRODUCT_MIGRATION_ACTIONS.CREATE_CONFIGURATION, { formValues, actions }),
  getConfiguration: () =>
    createAction(PRODUCT_MIGRATION_ACTIONS.GET_CONFIGURATION),
  setConfiguration: (configuration: ProductConfiguration) =>
    createAction(PRODUCT_MIGRATION_ACTIONS.SET_CONFIGURATION, { configuration }),
  setConfigurationId: (configurationId: string) =>
    createAction(PRODUCT_MIGRATION_ACTIONS.SET_CONFIGURATION_ID, { configurationId }),
  getStatus: () =>
    createAction(PRODUCT_MIGRATION_ACTIONS.GET_STATUS),
  setStatus: (status: ProductMigrationStatusType) =>
    createAction(PRODUCT_MIGRATION_ACTIONS.SET_STATUS, { status }),
  getDsoOperators: () =>
    createAction(PRODUCT_MIGRATION_ACTIONS.GET_DSO_OPERATORS),
  setDsoOperators: (dsoOperators: DsoListData) =>
    createAction(PRODUCT_MIGRATION_ACTIONS.SET_DSO_OPERATORS, { dsoOperators }),
  createCession: () =>
    createAction(PRODUCT_MIGRATION_ACTIONS.CREATE_CESSION),
  getCession: () =>
    createAction(PRODUCT_MIGRATION_ACTIONS.GET_CESSION),
  setCession: (cessionUrl: string) =>
    createAction(PRODUCT_MIGRATION_ACTIONS.SET_CESSION, { cessionUrl }),
  getCessionStatus: () =>
    createAction(PRODUCT_MIGRATION_ACTIONS.GET_CESSION_STATUS),
  setCessionStatus: (cessionStatus: CessionSignServiceStatusType) =>
    createAction(PRODUCT_MIGRATION_ACTIONS.SET_CESSION_STATUS, { cessionStatus }),
  acceptOffer: (offerTnC: ProductMigrationTermsAndConditionsForm) =>
    createAction(PRODUCT_MIGRATION_ACTIONS.ACCEPT_OFFER, { offerTnC }),
  clearProductMigration: () =>
    createAction(PRODUCT_MIGRATION_ACTIONS.CLEAR_PRODUCT_MIGRATION),
  setCreateCessionErrorStatus: (status: boolean) =>
    createAction(PRODUCT_MIGRATION_ACTIONS.SET_CREATE_CESSION_ERROR_STATUS, { status }),
  getActiveCampaigns: () =>
    createAction(PRODUCT_MIGRATION_ACTIONS.GET_ACTIVE_CAMPAIGNS),
  setActiveCampaigns: (campaigns: ProductMigrationCampaigns) =>
    createAction(PRODUCT_MIGRATION_ACTIONS.SET_ACTIVE_CAMPAIGNS, { campaigns }),
  getGlobalStatusReset: () =>
    createAction(PRODUCT_MIGRATION_ACTIONS.GET_GLOBAL_STATUS_RESET),
  setGlobalStatusReset: (globalStatusReset: ProductMigrationGlobalStatusReset) =>
    createAction(PRODUCT_MIGRATION_ACTIONS.SET_GLOBAL_STATUS_RESET, { globalStatusReset }),
  clearGlobalStatusReset: () =>
    createAction(PRODUCT_MIGRATION_ACTIONS.CLEAR_GLOBAL_STATUS_RESET),
};

export type ProductMigrationActions = ActionsUnion<typeof ProductMigrationActions>;
