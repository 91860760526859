import * as React from 'react';

import { StoreState } from '+app/store/store.interface';
import { Reporter } from '+app/utils/reporter.util';
import { CONFIG } from '+config';
import { MobileAppState } from '+mobile';
import { createFirebaseClient, FirebaseClient } from '+shared/store/news-channel/firebase.client';
import { News } from '+shared/store/news-channel/types/news.interface';
import { connect } from 'react-redux';
import { NewsProvider } from './NewsProvider.component';

const mapStateToProps = (state: StoreState) => ({
  mobileAppState: state.mobile.appState,
});

type Props = ReturnType<typeof mapStateToProps>;
type State = { allNews: News[] };

export class SubscribeNewsChannelComponent extends React.PureComponent<Props, State> {
  firebase: ReturnType<typeof createFirebaseClient>;
  unsubscribeNews?: () => void;

  state: State = {
    allNews: [],
  };

  constructor(props: Props) {
    super(props);

    this.firebase = FirebaseClient.firebase;

    window.addEventListener('beforeunload', () => {
      if (this.unsubscribeNews) {
        this.unsubscribeNews();
      }
    });
  }

  componentDidMount() {
    this.subscribeNews();
  }

  componentWillUnmount() {
    if (this.unsubscribeNews) {
      this.unsubscribeNews();
    }
  }

  componentDidUpdate({ mobileAppState: oldMobileAppState }: Props) {
    const { mobileAppState } = this.props;
    if (CONFIG.IS_MOBILE && oldMobileAppState !== mobileAppState) {
      if (mobileAppState === MobileAppState.ACTIVE) {
        this.subscribeNews();
      } else if (this.unsubscribeNews) {
        this.unsubscribeNews();
      }
    }
  }

  subscribeNews = () => {
    this.unsubscribeNews = this.firebase.subscribeCollection('news')
      .where('expiryDateTimestamp', '>=', new Date())
      .where('portals', 'array-contains', 'CP')
      .orderBy('expiryDateTimestamp')
      .orderBy('levelValue')
      .onSnapshot(
        querySnapshot => {
          const newsList: News[] = [];

          querySnapshot.forEach(doc => {
            newsList.push(doc.data() as News);
          });

          this.setState({ allNews: newsList });
        },
        error => Reporter.reportError(error),
      );
    };

  render() {
    return (
      <NewsProvider allNews={this.state.allNews} />
    );
  }
}
export const SubscribeNewsChannel = connect(mapStateToProps)(SubscribeNewsChannelComponent);
