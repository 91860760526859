import { T } from '@sonnen/shared-i18n/customer';
import { DS, EnergyUnit, useFeature } from '@sonnen/shared-web';
import { } from '@sonnen/shared-web';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getCurrentUserDsoId } from '+app/shared/store/user/user.selectors';
import { StoreState } from '+app/store/store.interface';
import { FeatureName } from '+config/featureFlags';
import { getFormattedDate } from '+legacy/helpers/dates';
import { Reporter } from '+utils/reporter.util';
import { ContractFlatXCard } from '../../components/ContractFlatXCard';
import { getUsageAllowanceFlatXData } from '../../components/ContractFlatXCard/helpers';
import { getCurrentSiteContractData, getUsageAllowanceActivationDate } from '../../store/contract.selectors';
import { getUsageAllowancePeriod } from '../../store/helpers/contract.helpers';
import { getUsageAllowanceForSonnenFlatX } from '../../store/helpers/contractFlatX.helpers';

const mapStateToProps = (state: StoreState) => ({
  usageAllowanceActivationDate: getUsageAllowanceActivationDate(state),
  contract: getCurrentSiteContractData(state),
  dsoId: getCurrentUserDsoId(state),
});

type Props = ReturnType<typeof mapStateToProps>;

export const ContractFlatXCardUsageAllowanceComponent: React.FC<Props> = ({
  contract,
  dsoId,
  usageAllowanceActivationDate,
}) => {
  if (!contract) {
    const errorMessage = 'Rendered ContractFlatXCardUsageAllowanceComponent without required contract data';
    Reporter.reportError({name: 'render null', message: errorMessage});
    console.error(errorMessage); // TODO make sure console.error is removed from production build

    return null;
  }

  const formattedDate = getFormattedDate(usageAllowanceActivationDate);
  const activationDate = formattedDate || I18n.t(T.yourFlat.sonnenFlatX.activationDateInProgress);

  const usageAllowanceForFullYear = contract.amountOfFreeEnergy;
  const usageAllowanceForPeriod = getUsageAllowanceForSonnenFlatX(
    contract,
    moment(contract.usageAllowanceActivatedOn).year(),
  ) || 0;

  const { start, end } = getUsageAllowancePeriod(contract, moment(contract.usageAllowanceActivatedOn).year());
  const datePeriod = `${getFormattedDate(start, T.general.date.dayMonth)} - ${getFormattedDate(end)}`;

  const fullYearValue = (contract.usageAllowanceActivatedOn && usageAllowanceForFullYear) ? `${usageAllowanceForFullYear} ${EnergyUnit.KWH}` : null;
  const periodValue = contract.usageAllowanceActivatedOn ? `${usageAllowanceForPeriod} ${EnergyUnit.KWH}` : null;

  const usageAllowanceData = getUsageAllowanceFlatXData(contract, dsoId);

  const hideFirstAllowanceYear = useFeature(FeatureName.HIDE_FIRST_ALLOWANCE_YEAR).isEnabled
    || useFeature(FeatureName.USAGE_ALLOWANCE_FROM_SALESFORCE).isEnabled;

  const data = [
    {
      attribute: I18n.t(T.yourFlat.sonnenFlatX.activationDate),
      value: activationDate,
    },
    {
      attribute: hideFirstAllowanceYear
        ? new Date().getFullYear().toString()
        : I18n.t(T.yourFlat.sonnenFlatX.fullYearDate),
      value: fullYearValue,
    },
    {
      attribute: datePeriod,
      value: hideFirstAllowanceYear ? null : periodValue,
    },
  ].filter(item => item.value);

  return (
    <ContractFlatXCard
      title={I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.title)}
      subtitle={usageAllowanceData.subtitle}
      data={data}
      icon={DS.Icon.ThunderPlus}
      status={usageAllowanceData.status}
      additionalInfo={usageAllowanceData.additionalInfo}
      dataTestId={'contract-flat-x-card-usage-allowance'}
    />
  );
};

export const ContractFlatXCardUsageAllowance = connect(mapStateToProps)(
  ContractFlatXCardUsageAllowanceComponent,
);
