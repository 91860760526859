import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';
import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';

const LocateUserControl = ({ onClick }) => (
  <button
    data-test-id={'sonnen-community-map-locate-button'}
    className="c-community-map__locate leaflet-bar leaflet-control leaflet-control-custom"
    type="button"
    aria-label={I18n.t(T.dashboard.map.locateMe)}
    onClick={onClick}
  >
    <Icon.Position className={'c-community-map__locate-icon'} />
  </button>
);

LocateUserControl.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default LocateUserControl;
