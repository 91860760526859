import { Loader } from '@sonnen/shared-web';
import * as React from 'react';

import { PlatformSelector } from '+app/shared/components';
import { PageMobile } from './Page.mobile.component';
import { PageWeb } from './Page.web.component';

import './Page.component.scss';

interface PageOptions {
  // TODO: Delete the flag - small gap should be default and larger gaps should be created elsewhere. @adi
  isHeadlineBorderOnMobile?: boolean;
  isHeadlineSmallGap?: boolean;
  isHeadlineSideContent?: React.ReactNode;
}

export interface PageProps {
  meta?: string;
  title?: string;
  options?: PageOptions;
  headerTestId?: string;
  showLoader?: boolean;
}

export const Page: React.FC<PageProps> = ({
  children,
  meta,
  options,
  title,
  headerTestId,
  showLoader = false,
}) => (
  <PlatformSelector
    web={(
      <PageWeb
        headerTestId={headerTestId}
        meta={meta}
        options={options}
        title={title}
      >
        {showLoader
          ? <div className={'c-page-loader-wrapper'}><Loader /></div>
          : children
        }
      </PageWeb>
    )}
    mobile={(
      <PageMobile
        options={options}
        title={title}
      >
        {showLoader
          ? <div className={'c-page-loader-wrapper'}><Loader/></div>
          : children
        }
      </PageMobile>
    )}
  />
);
