import { CountryCode } from '@sonnen/shared-web';
import { compose, find, isNil, map, pathEq, pick, values } from 'lodash/fp';
import { createSelector } from 'reselect';

import { StoreState } from '+app/store/store.interface';
import { getStatus } from '+app/utils/query.util';
import { ApiError, getErrorSource } from '+legacy/helpers/error';
import { Locale } from '+legacy/helpers/i18n.helper';
import { User } from './types/user.interface';
import {
  REGISTER_BY_BATTERY_DATA_QUERY,
  REGISTER_BY_CUSTOMER_NO_QUERY,
  REGISTER_BY_EMAIL_QUERY,
  REQUEST_PASSWORD_RESET_QUERY,
  RESET_PASSWORD_QUERY,
} from './user.state';

export const getUserState = (state: StoreState) => state.user;
export const getCurrentUserState = (state: StoreState): User | undefined => state.user.currentUser;
export const getCurrentLanguage = (state: StoreState) => state.i18n;

export const getCurrentUserId = createSelector(
  getCurrentUserState,
  user => user && user.id,
 );

export const getCurrentUserName = createSelector(
  getCurrentUserState,
  user => (user && `${user.firstName} ${user.lastName}`),
);

export const getCurrentUserFirstName = createSelector(
  getCurrentUserState,
  user => user && user.firstName,
);

export const getCurrentUserCity = createSelector(
  getCurrentUserState,
  user => user && user.city,
);

export const getCurrentUserNameWithCity = createSelector(
  getCurrentUserState,
  (user): string => {
    if (!user) { return ''; }

    return user.city
      ? `${user.firstName}, ${user.city}`
      : user.firstName;
  },
);

export const getCurrentUserGeolocation = createSelector(
  getCurrentUserState,
  compose(
    map((value: number) => !!value ? Number(value) : null),
    values,
    pick(['latitude', 'longitude']),
  ),
);

export const getCurrentUserSalesforceContactId = createSelector(
  getCurrentUserState,
  user => user && user.salesforceContactId,
);

export const getCurrentUserCustomerNumber = createSelector(
  getCurrentUserState,
  user => user && user.customerNumber,
);

export const getUserCountryCode = createSelector(
  getCurrentUserState,
  user => user && user.countryCode,
);

export const isUserCountry = (...countryCodes: CountryCode[]) => createSelector(
  getCurrentUserState,
  user => user ? countryCodes.includes(user.countryCode as CountryCode) : false,
);

export const isUserLanguage = (...languages: Locale[]) => createSelector(
  getCurrentLanguage,
  i18n => !!i18n && languages.includes(i18n.locale as Locale),
);

export const hasCurrentUserServicePartnerPermission = createSelector(
  getCurrentUserState,
  user => !!user && user.partnerMonitoringAccepted,
);

// ------------------------------------
// SignUp selectors
// ------------------------------------
export const getOnboardingState = createSelector(
  getUserState,
  user => user.onboarding.state,
);

export const getOnboardingEmail = createSelector(
  getUserState,
  user => user.onboarding.email,
);

export const isEmailSourcePointer = compose(
  (source: string) => /email/.test(source),
  getErrorSource,
  (error?: ApiError) => error && error.source && error.source.pointer || '',
);

export const isCodeInvalidBlank = compose(
  (code: string) => /invalid|blank/ig.test(code),
  (error?: ApiError) => error && error.code || '',
);

export const isInvalidValueErrorStatus = pathEq(['status'], 422);
export const isCodeUnknown = pathEq(['code'], 'unknown');

export const isStatusSubmissionError = (error: ApiError) =>
  isInvalidValueErrorStatus(error) && isCodeInvalidBlank(error);

export const isStatusVerification = (error: ApiError) =>
  isInvalidValueErrorStatus(error) && isCodeUnknown(error) && isEmailSourcePointer(error);

export const getErrorCode = compose(
  find<string>((element) => !isNil(element)),
  map((err: ApiError) => err.code),
);

export const getRequestPasswordResetQueryStatus = createSelector(
  getUserState,
  user => getStatus(user[REQUEST_PASSWORD_RESET_QUERY]),
);

export const getResetPasswordQueryStatus = createSelector(
  getUserState,
  user => getStatus(user[RESET_PASSWORD_QUERY]),
);

export const getResetPasswordQueryError = createSelector(
  getUserState,
  user => user[RESET_PASSWORD_QUERY].error,
);

export const getVerifyEmailQueryStatus = createSelector(
  getUserState,
  user => getStatus(user[REGISTER_BY_EMAIL_QUERY]),
);

export const getRegisterByBatteryDataQueryStatus = createSelector(
  getUserState,
  user => getStatus(user[REGISTER_BY_BATTERY_DATA_QUERY]),
);

export const getRegisterByCustomerNoQueryStatus = createSelector(
  getUserState,
  user => getStatus(user[REGISTER_BY_CUSTOMER_NO_QUERY]),
);

export const getRegisterByBatteryDataQueryError = createSelector(
  getUserState,
  user => user[REGISTER_BY_BATTERY_DATA_QUERY].error,
);

export const getRegisterByCustomerNoQueryError = createSelector(
  getUserState,
  user => user[REGISTER_BY_CUSTOMER_NO_QUERY].error,
);

export const getCurrentUserDsoId = createSelector(
  getCurrentUserState,
  user => user && user.accountDso,
);

export const getCurrentUserBillingCountryCode = createSelector(
  getCurrentUserState,
  user => user && user.accountBillingCountryCode,
);
