import { DataContainerTooltipExtension, TooltipEvent } from '@kanva/charts';
import { View } from '@kanva/core';
import { MediaQuery } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';

import { handleTooltipTouchEvent } from '../../store/analysis.helpers';

interface Props {
  // TODO: get rid of this className prop
  className?: string;
  tooltipEvent?: TooltipEvent;
  toggleTooltipActivity?: (tooltipActive: boolean) => void;
  chartView?: View;
  tooltipExtension?: DataContainerTooltipExtension;
  isNoDataDisplayed?: boolean;
}

export const AnalysisTooltipChartWrapper: React.FC<Props> = ({
  children,
  className,
  tooltipEvent,
  toggleTooltipActivity,
  chartView,
  tooltipExtension,
  isNoDataDisplayed = false,
}) => {
  const [currentXPosition, setXPosition] = React.useState<number>(0);

  const setTooltipState = (
    tooltipEvent: TooltipEvent | undefined,
  ) => {
    if (!toggleTooltipActivity) {
      return;
    }

    if (!tooltipEvent || tooltipEvent.snap.x < 0 || isNoDataDisplayed) {
      toggleTooltipActivity(false);
      return;
    }

    toggleTooltipActivity(true);
    setXPosition(tooltipEvent.snap.x);
  };

  React.useEffect(() => {
    if (tooltipEvent && tooltipEvent.snap.x !== currentXPosition) {
      setTooltipState(tooltipEvent);
    }
  }, [tooltipEvent]);

  return (
    <Media query={MediaQuery.UP_SM}>
      {(isDesktop: boolean) => isDesktop ? (
        <div
          className={className}
          onMouseEnter={() => setTooltipState(tooltipEvent)}
          onMouseLeave={() => setTooltipState(undefined)}
          onTouchStart={() => setTooltipState(tooltipEvent)}
          onTouchEnd={() => setTooltipState(undefined)}
        >
          {children}
        </div>
      ) : (
        <div
          className={className}
          onTouchMove={e => handleTooltipTouchEvent(e, chartView, tooltipExtension, tooltipEvent)}
        >
          {children}
        </div>
      )}
    </Media>
  );
};
