import { Data, MergedData } from '@coolio/json-api';

export interface CommunityAttributes {
  numberOfRecentNewMembers: number;
}

interface CommunityContentHubArticleContent {
  components: [{
    articles: [{
      headline: {
        heading: string;
        level: string;
        type: string;
      }
      publishingDate: string;
      published: boolean;
      paragraph: {
        content: string;
        type: string;
      };
      image: {
        image: {
          file: {
            uri: string;
            src: string;
            name: string;
            mime: string;
            size: string;
            alt: string;
            title: string;
            width: string;
            height: string;
            uuid: string;
          }
          type: string;
        }
        target: string;
        url: string;
      }
      target: string;
      url: string;
      type: string;
    }];
    bgColor: string | null;
    button: string | null;
    modifier: string | null;
    type: string;
  }];
  type: string;
}
export interface CommunityContentHubAttributes {
  entityType: string;
  bundle: string;
  title: string;
  metatagsRaw: {};
  pageHeadTop: string;
  pageHeadBottom: string;
  pageBodyBottom: string;
  country: string;
  home: string;
  sitemap: boolean;
  canonicalUrl: string;
  changed: string;
  language: string;
  languageEnabled: string[];
  languageSwitch: {};
  bodyClass: string;
  landingPage: boolean;
  content: CommunityContentHubArticleContent;
  __type: string;
}

export type CommunityContentHubArticle = {
  title: string;
  description: string;
  date: string;
  imageSrc: string;
  imageAlt: string;
  link: string;
};

export type CommunityData = Data<CommunityAttributes>;
export type Community = MergedData<CommunityData>;

export enum CommunityMapCardTypes {
  LOGGED_USER = 'loggedUser',
  COMMUNITY_MEMBER = 'communityMember',
}
