import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, DS } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

// TODO unify helpers
import { LiveStateHelper } from '+live-state/helpers/LiveState.helpers';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { EFCircle } from '../EFCircle';
import { EFCircleExplanationTooltip } from '../EFCircleExplanationTooltip';
import { LiveEnergyFlowHelper } from '../LiveEnergyFlow.helper';

export interface EFCircleBatteryProps {
  liveData: SiteLive | undefined;
  isActive: (predicate: boolean | undefined) => boolean;
  isAppOnline: boolean;
  isExplanationActive: boolean | undefined;
  isBatteryToConsumptionActive: boolean | undefined;
  isBatteryToGridActive: boolean | undefined;
  isBatteryInvalid: boolean | undefined;
  isGridToBatteryActive: boolean | undefined;
  isGridToConsumptionActive: boolean | undefined;
  isProductionToBatteryActive: boolean | undefined;
  isGridServiceActive: boolean | undefined;
}

export const EFCircleBattery: React.FC<EFCircleBatteryProps> = ({
  isActive,
  isAppOnline,
  isExplanationActive,
  liveData,
  isBatteryToConsumptionActive,
  isBatteryToGridActive,
  isBatteryInvalid,
  isGridToBatteryActive,
  isGridToConsumptionActive,
  isProductionToBatteryActive,
  isGridServiceActive,
}) => {
  const isStandby = !!liveData && liveData.batteryInverterState === 'standby';
  const isVpp = isGridServiceActive && isActive(isGridToBatteryActive || isBatteryToGridActive);
  const value = !!liveData ? Math.abs(LiveStateHelper.getBatteryValue(liveData)) : 0;

  return (
    <EFCircle
      dataTestId={'live-state-circle-sonnen-batterie'}
      type={'battery'}
      icon={<DS.Icon.Battery />}
      label={I18n.t(T.dashboard.energyFlowChart.sonnenBatterie)}
      key={value}
      value={value}
      hasValueErrorIcon={isBatteryInvalid}
      valueSub={LiveEnergyFlowHelper.getBatteryUsoc(liveData)}
      isOnline={isAppOnline && liveData?.online}
      isActive={isActive(
        isBatteryToConsumptionActive
        || isBatteryToGridActive
        || isGridToBatteryActive
        || isGridToConsumptionActive
        || isProductionToBatteryActive
        || isStandby,
      )}
      isAnimated={isVpp}
      isBlured={isExplanationActive}
      tooltip={isExplanationActive ? (
        <EFCircleExplanationTooltip
          dataTestId={'live-state-energy-flow-explanation-tooltip-sonnen-batterie'}
          position={'right'}
          >
          <DefaultParagraph>
            {isVpp
              ? I18n.t(T.dashboard.liveStateChart.battery.tooltipBatteryVpp)
              : I18n.t(T.dashboard.liveStateChart.battery.tooltipBatteryGrid)
            }
          </DefaultParagraph>
        </EFCircleExplanationTooltip>
      ) :
        null
      }
    />
  );
};
