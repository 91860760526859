import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './ValidationBadge.component.scss';

interface Props {
  label: string;
  isValid: boolean;
  dataTestId?: string;
}

export const ValidationBadge: React.FC<Props> = ({
  label,
  isValid,
  dataTestId,
}) => (
  <div 
    data-test-id={dataTestId}
    className={classNames('c-validation-badge', {
    'is-active': isValid,
  })}>
    <Icon.Checkmark className={'c-validation-badge__icon'} />
    <span className={'c-validation-badge__label'}>
      {label}
    </span>
  </div>
);
