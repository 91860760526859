import { DashboardUserDetailsPersonalData } from '+app/+dashboard/components';
import { Lead } from '+app/+guide/store/types';
import { guideNavHeaderConfig, mainNavWebHeaderConfig } from '+app/router/configs';
import { getNavRoutes } from '+app/router/router.helper';
import { FlatRoutes, Route } from '+app/router/router.types';
import { User } from '+app/shared/store/user/types/user.interface';
import { trimString } from '+legacy/helpers/strings';

export const factorizeUserDetails = (details: User | Lead | undefined): DashboardUserDetailsPersonalData => {
  return {
    name: details ? trimString`${details.firstName} ${details.lastName}` : '',
    email: details ? details.email : '',
    customerNumber: details ? details.customerNumber : '',
  };
};

export const getUserDetailsRoutes = (availableFlatRoutes: FlatRoutes, isUserInGuidance?: boolean): Route[] => {
  return isUserInGuidance
    ? getNavRoutes(guideNavHeaderConfig, availableFlatRoutes)
    : getNavRoutes(mainNavWebHeaderConfig, availableFlatRoutes);
};
