import { easeLinear } from 'd3-ease';
import { select } from 'd3-selection';
import 'd3-transition';
import * as React from 'react';
import { AXIS_DOMAIN_MARGIN, X_AXIS_MARGIN } from '../../../frequency.constants';

interface Props {
  clipId: string;
  x: number;
  height: number;
  reposition: boolean;
}

export class Clip extends React.PureComponent<Props> {
  static defaultProps = {
    x: 0,
    height: 0,
    reposition: false,
  };

  static margin: number = X_AXIS_MARGIN + AXIS_DOMAIN_MARGIN;

  clipRef: Element | null = null;

  componentDidMount() {
    this.updateGraph();
  }

  componentDidUpdate() {
    this.updateGraph();
  }

  updateGraph = () => {
    const { x, reposition } = this.props;
    const rect = select(this.clipRef).select('rect');

    rect
      .transition()
      .duration(0)
      .attr('width', 0);

    rect
      .transition()
      .duration(reposition ? 5000 : 0)
      .ease(easeLinear)
      .attr('width', x - Clip.margin);
  };

  setClipRef = (ref: Element | null) => {
    this.clipRef = ref;
  };

  render() {
    const { clipId, height } = this.props;

    return (
      <defs>
        <clipPath
          id={clipId}
          ref={this.setClipRef}
        >
          <rect
            transform={`translate(${Clip.margin},0)`}
            className="clip-rect"
            height={height}
            width={0}
          />
        </clipPath>
      </defs>
    );
  }
}
