import { EnergyUnit, TimeUnit } from '@sonnen/shared-web';

import { ValueSource } from '+product-migration/utils/productMigration.util';
import { CurrencyUnit, OtherUnit, PeakPowerUnit } from '+utils/unit.util';

export type GuaranteedVppBonus = ConfigurationMeasurement<CurrencyUnit>;

export enum ConfigurationMarket {
  DE = 'DE',
}

export type ConfigurationMeasurementUnit =
  | EnergyUnit
  | PeakPowerUnit
  | CurrencyUnit
  | OtherUnit
  | TimeUnit
  | boolean
  | string
  ;

// TODO replace with ProductEngineDataObject as it removes an unwanted case of `unit: "EUR"`
export interface ConfigurationMeasurement<T extends ConfigurationMeasurementUnit, U = number | string> {
  value: U;
  unit?: T;
  currency?: T;
  source?: string;
}

export type ProductEngineDataObject<
  T extends ConfigurationMeasurementUnit,
  U = number | string,
> = T extends CurrencyUnit
  ? {
    value: U;
    currency: T;
    source?: ValueSource;
  }
  : {
    value: U;
    unit: T;
    source?: ValueSource;
  };

export interface ConfigurationAddress {
  street: string;
  postalCode: string;
  city: string;
  countryCode: string;
}

export interface ConfigurationGeometryPoint {
  type: 'Point';
  coordinates: [number, number];
}
