import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, DefaultParagraph, Loader, MediaCard, WizardContext } from '@sonnen/shared-web';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getOfferById, getOfferConfiguration } from '+app/+guide/+purchase/store/+offer.selectors';
import { RouterGuideAcceptanceRouteParams } from '+app/router/router.types';
import { StoreState } from '+app/store/store.interface';
import { LayoutActions } from '+shared/store/layout/layout.actions';
import { QueryActions } from '+shared/store/query/query.actions';
import { mapActions } from '+utils/redux';
import { compose } from 'redux';
import {
  GuideAcceptanceBackButton,
  GuideAcceptanceDesiredDeliveryDate,
  GuideAcceptanceUtilityMeterId,
  GuideAcceptanceUtilityMoveHouse,
  GuideAcceptanceUtilityMovingDetails,
  GuideAcceptanceUtilityTermination,
} from '../../components';
import { UtilityChangePeriod } from '../../components/GuideAcceptanceUtilityMoveHouse/GuideAcceptanceUtilityMoveHouse.helper';
import { GuideAcceptanceUtilityPvSystemKeys } from '../../components/GuideAcceptanceUtilityPvSystemKeys';
import { GuideAcceptanceActions } from '../../store/+acceptance.actions';
import { getPvSystemKeysQueryStatus, getUtilityData, getUtilityDataQueryStatus } from '../../store/+acceptance.selectors';
import { SET_UTILITY_DATA_QUERY } from '../../store/+acceptance.state';
import { GuideAcceptanceOldProvider } from '../GuideAcceptanceOldProvider';
import { UtilityDataForm, UtilityDataFormInitial, UtilityDataSchema } from './GuideAcceptanceUtility.helper';

import './GuideAcceptanceUtility.component.scss';

const mapDispatchToProps = mapActions({
  setUtilityData: GuideAcceptanceActions.setUtilityData,
  clearStepStatus: () => QueryActions.init(SET_UTILITY_DATA_QUERY),
  toggleScroll: LayoutActions.toggleLayoutScrolling,
  getUtilityData: GuideAcceptanceActions.getUtilityData,
  setPvSystemKeys: GuideAcceptanceActions.setPvSystemKeys,
});

const mapStateToProps = (
  state: StoreState,
  { match: { params } }: RouteComponentProps<RouterGuideAcceptanceRouteParams>,
) => ({
  utilityDataQueryStatus: getUtilityDataQueryStatus(state),
  utilityData: getUtilityData(state),
  configuration: compose(getOfferConfiguration, getOfferById(state))(params),
  pvSystemKeysQueryStatus: getPvSystemKeysQueryStatus(state),
});

type Props =
  & RouteComponentProps<RouterGuideAcceptanceRouteParams>
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const GuideAcceptanceUtilityComponent: React.FC<Props> = ({
  actions,
  utilityDataQueryStatus,
  utilityData,
  configuration,
  pvSystemKeysQueryStatus,
}) => {
  const [isPvSystemKeysRequest, setIsPvSystemKeysRequest] = React.useState<boolean>(false);
  const wizard = React.useContext(WizardContext);

  React.useEffect(() => {
    actions.getUtilityData();
  }, []);

  const onSubmit = (values: UtilityDataForm) => {
    actions.setUtilityData(values);
    if (configuration && (configuration.photovoltaicSystem.subSystems || values.pvSystemKeyOne)) {
      actions.setPvSystemKeys(values, configuration);
      setIsPvSystemKeysRequest(true);
    }
  };

  React.useEffect(() => {
    if (wizard.next
      && utilityDataQueryStatus.success
      && (!isPvSystemKeysRequest || (pvSystemKeysQueryStatus.success && isPvSystemKeysRequest))) {
      wizard.next();
      actions.clearStepStatus();
      setIsPvSystemKeysRequest(false);
    }
  }, [utilityDataQueryStatus, pvSystemKeysQueryStatus, isPvSystemKeysRequest]);

  return configuration ? (
    <Formik
      initialValues={UtilityDataFormInitial}
      onSubmit={async vals => onSubmit(vals)}
      validationSchema={UtilityDataSchema(configuration)}
      validateOnBlur={true}
      validateOnChange={true}
      render={(form) => (
        <Form>
          <div className={'c-guide-acceptance-utility'}>
            <MediaCard
              footerContent={(
                <div className={'c-guide-acceptance-utility__button'}>
                  <Button
                    label={I18n.t(T.customerGuidance.acceptance.utilityData.buttonLabel)}
                    size={ButtonSize.SECONDARY}
                    type={'submit'}
                  />
                </div>
              )}
            >
              <DefaultParagraph>
                {I18n.t(T.customerGuidance.acceptance.utilityData.description)}
              </DefaultParagraph>

              <GuideAcceptanceUtilityMoveHouse form={form} />

              {form.values.isMovingHouses === UtilityChangePeriod.NOT_MOVED ?
                <>
                  <GuideAcceptanceDesiredDeliveryDate form={form} configuration={configuration} />
                  <GuideAcceptanceUtilityTermination form={form} />
                  <GuideAcceptanceOldProvider form={form} />
                </>
                :
                <>
                  <GuideAcceptanceUtilityMovingDetails form={form} />
                </>
              }

              {utilityData &&
                <GuideAcceptanceUtilityMeterId
                  form={form}
                  toggleScroll={actions.toggleScroll}
                  utilityData={utilityData}
                />
              }

              <GuideAcceptanceUtilityPvSystemKeys
                form={form}
                configuration={configuration}
                isMultiplePv={!!configuration.photovoltaicSystem.subSystems}
              />

              {utilityDataQueryStatus.error &&
                <div className={'c-guide-acceptance-utility__error-wrapper'}>
                  <span className={'c-guide-acceptance-utility__error'}>
                    {I18n.t(T.general.errors.api.other)}
                  </span>
                </div>
              }
            </MediaCard>
            <GuideAcceptanceBackButton />
          </div>
          {utilityDataQueryStatus.pending || pvSystemKeysQueryStatus.pending ?
            <Loader className={'c-guide-acceptance-utility__loader'} />
            : null
          }
        </Form>
      )}
    />
  ) : null;
};

export const GuideAcceptanceUtility = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GuideAcceptanceUtilityComponent),
);
