import { Query } from '../query/query.state';
import { LegalDocuments } from './types/legalDocuments.interface';

export const GET_LEGAL_DOCUMENTS_QUERY = 'getLegalDocumentsQuery';

export interface LegalState {
  legalDocuments: LegalDocuments | undefined;
  [GET_LEGAL_DOCUMENTS_QUERY]: Query<LegalDocuments>;
}

export const initialState: LegalState = {
  legalDocuments: undefined,
  [GET_LEGAL_DOCUMENTS_QUERY]: {},
};
