import { T } from '@sonnen/shared-i18n/customer';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Modal,
} from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './GuideAcceptanceConfirmationModal.component.scss';

interface OwnProps {
  onCancel: () => void;
  onConfirm: () => void;
}

export const GuideAcceptanceConfirmationModal: React.FC<OwnProps> = ({ onCancel, onConfirm }) => (
  <Modal
    className={'c-guide-acceptance-confirmation-modal__container'}
    onClose={onCancel}
    isOpen={true}
    size={'auto'}
  >
    <div className={'c-guide-acceptance-confirmation-modal'}>
      <div className={'c-guide-acceptance-confirmation-modal__content'}>
        <h3 className={'c-guide-acceptance-confirmation-modal__title'}>
          {I18n.t(T.customerGuidance.acceptance.confirmationModal.title)}
        </h3>
        <p className={'c-guide-acceptance-confirmation-modal__description'}>
          {I18n.t(T.customerGuidance.acceptance.confirmationModal.description)}
        </p>
      </div>
      <div className={'c-guide-acceptance-confirmation-modal__footer'}>
        <Button
          className={'c-guide-acceptance-confirmation-modal__button'}
          isNotElevatedOnHover={true}
          label={I18n.t(T.customerGuidance.acceptance.confirmationModal.confirm)}
          onClick={onConfirm}
          size={ButtonSize.SECONDARY}
          theme={ButtonTheme.LIGHT}
        />
        <Button
          label={I18n.t(T.customerGuidance.acceptance.confirmationModal.decline)}
          onClick={onCancel}
          size={ButtonSize.SECONDARY}
        />
      </div>
    </div>
  </Modal>
);
