import { T } from '@sonnen/shared-i18n/customer';
import { Card, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { Document } from '+battery/store/types/battery.interface';
import { BatteryDownloadsItem } from '../BatteryDownloadsItem';
import { BatteryDownloadsTableRow } from '../BatteryDownloadsTableRow';

import './BatteryDownloadsList.component.scss';

interface Props {
  list: any[];
}

const renderList = (list: Document[]) => (
  <div className={'c-battery-downloads-list'}>
    <div className={'c-battery-downloads-list__header'}>
      <BatteryDownloadsTableRow
        content={I18n.t(T.batterySystem.downloads.table.title)}
        secondaryContent={I18n.t(T.batterySystem.downloads.table.fileName)}
        download={I18n.t(T.batterySystem.downloads.table.download)}
      />
    </div>
    <Card>
      <ul>
        {list.map((item: Document, index: number) => (
          <li
            className={'c-battery-downloads-list__list-item'}
            key={item.id}
          >
            <BatteryDownloadsItem
              item={item}
              key={index}
            />
          </li>
        ))}
      </ul>
    </Card>
  </div>
);

export const BatteryDownloadsList: React.FC<Props> = ({ list }) => (
  <>
    {
      (list && !!list.length)
        ? renderList(list)
        : <PageSubheadline noBorder={true}>
            {I18n.t(T.general.errors.general.noResults)}
          </PageSubheadline>
    }
  </>
);
