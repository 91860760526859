import { T } from '@sonnen/shared-i18n/customer';
import { Link } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { LinkedTranslation } from '+app/shared/components';
import { DocumentAcceptance, DocumentAcceptanceLinks } from '../../store';

interface Props {
  name: string;
  providedLinks: DocumentAcceptanceLinks;
}

const GuideAcceptanceFlatTnCAndCancellation = ({
  termsAndConditions,
}: DocumentAcceptanceLinks[DocumentAcceptance.TERMS_AND_CONDITIONS_AND_CANCELLATION]) => (
  <LinkedTranslation
    sentence={I18n.t(T.customerGuidance.acceptance.checks.flatTNCAndCancellation.sentence)}
    links={[
      <Link
        key={'AGB of sonnen eServices GmbH'}
        href={termsAndConditions}
        label={I18n.t(T.customerGuidance.acceptance.checks.flatTNCAndCancellation.flatTnC)}
      />,
      <Link
        key={'Widerrufsrecht of sonnen eServices GmbH'}
        href={'https://api.sonnen.de/salesforce/services/apexrest/GetDocument' +
          '/v2?type=Cancellation+terms+eServices&language=de&country=DE'}
        label={I18n.t(T.customerGuidance.acceptance.checks.flatTNCAndCancellation.cancellationTerms)}
      />,
      <Link
        key={'Widerrufsformular'}
        href={'https://api.sonnen.de/salesforce/services/apexrest/GetDocument' +
          '/v2?type=Cancellation+form+eServices&language=de&country=DE'}
        label={I18n.t(T.customerGuidance.acceptance.checks.flatTNCAndCancellation.cancellationForm)}
      />,
    ]}
  />
);

const GuideAcceptanceFlatPrivacy = () => (
  <LinkedTranslation
    sentence={I18n.t(T.customerGuidance.acceptance.checks.flatPrivacy.sentence)}
    links={[
      <Link
        key={'Datenschutz und Datennutzungsbestimmungen of sonnen eServices GmbH'}
        href={'https://api.sonnen.de/salesforce/services/apexrest/GetDocument' +
          '/v2?type=Privacy+Policy&language=de&country=DE'}
        label={I18n.t(T.customerGuidance.acceptance.checks.flatPrivacy.link)}
      />,
    ]}
  />
);

const GuideAcceptanceFlatAssignment = () => (
  <LinkedTranslation
    sentence={I18n.t(T.customerGuidance.acceptance.checks.flatAssignment.sentence)}
    links={[
      <Link
        key={'Assignment notice'}
        href={
          'https://sales-solution-public.s3.eu-central-1.amazonaws.com/Abtretungserklaerung_12.2019.pdf'
        }
        label={I18n.t(T.customerGuidance.acceptance.checks.flatAssignment.link)}
      />,
    ]}
  />
);

const GuideAcceptanceMarketingMailing = () => (
  <LinkedTranslation
    sentence={I18n.t(T.customerGuidance.acceptance.checks.marketingMailing.sentence)}
    links={[]}
  />
);

export const GuideAcceptanceFlatTerm: React.FC<Props> = ({ name, providedLinks }) => {
  switch (name) {
    case DocumentAcceptance.ASSIGNMENT_NOTICE:
      return GuideAcceptanceFlatAssignment();
    case DocumentAcceptance.TERMS_AND_CONDITIONS_AND_CANCELLATION:
      return GuideAcceptanceFlatTnCAndCancellation(providedLinks[name]);
    case DocumentAcceptance.PRIVACY_POLICY:
      return GuideAcceptanceFlatPrivacy();
    case DocumentAcceptance.MARKETING_MAILING:
      return GuideAcceptanceMarketingMailing();
    default:
      return <></>;
  }
};
