import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

import { AccordionListItemProps } from '+components';
import { useToggleWithGtmEvent } from '+shared/hooks/useToggleWithGtmEvent';

export enum AccordionListToggleHandlers {
  SOFTWARE_DOWNLOAD = 'faqSoftwareDownloadToggleHandler',
  SIGN_LATER = 'faqSignLaterToggleHandler',
  DISSIMILAR_SIGNATURE = 'faqDissimilarSignatureToggleHandler',
}

const factorizeCessionAccordionList = (
  toggleHandlers: Record<AccordionListToggleHandlers, ReturnType<typeof useToggleWithGtmEvent>>,
): AccordionListItemProps[] => ([
  {
    title: I18n.t(T.customerGuidance.acceptance.cessionDoc.faq.item1.title),
    content: I18n.t(T.customerGuidance.acceptance.cessionDoc.faq.item1.text),
    onToggle: toggleHandlers.faqSoftwareDownloadToggleHandler.toggle,
    isOpen: toggleHandlers.faqSoftwareDownloadToggleHandler.isExpanded,
  },
  {
    title: I18n.t(T.customerGuidance.acceptance.cessionDoc.faq.item2.title),
    content: I18n.t(T.customerGuidance.acceptance.cessionDoc.faq.item2.text),
    onToggle: toggleHandlers.faqSignLaterToggleHandler.toggle,
    isOpen: toggleHandlers.faqSignLaterToggleHandler.isExpanded,
  },
  {
    title: I18n.t(T.customerGuidance.acceptance.cessionDoc.faq.item3.title),
    content: I18n.t(T.customerGuidance.acceptance.cessionDoc.faq.item3.text),
    onToggle: toggleHandlers.faqDissimilarSignatureToggleHandler.toggle,
    isOpen: toggleHandlers.faqDissimilarSignatureToggleHandler.isExpanded,
  },
]);

export const CessionAccordionListHelper = {
  factorizeCessionAccordionList,
};
