import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

import { UtilityChangePeriod } from '../../components/GuideAcceptanceUtilityMoveHouse/GuideAcceptanceUtilityMoveHouse.helper';
import { UtilityDataOldProvider } from '../GuideAcceptanceUtility';

export const oldProviderInitial: UtilityDataOldProvider = {
  providerId: '',
};

export const UtilityOldProviderSchema = Yup.object().shape({
  providerId: Yup.string()
    .when('isMovingHouses', {
      is: UtilityChangePeriod.NOT_MOVED,
      then: Yup.string().required(() => I18n.t(T.customerGuidance.acceptance.requiredField)),
    }),
});
