
import { Query } from '+query/query.state';
import { DsoListData } from './types/dsoOperators.interface';
import { ProductConfiguration, ProductMigrationGlobalStatusReset } from './types/productConfiguration.interface';
import { ProductMigrationCampaigns } from './types/productMigrationCampaigns.interface';
import { CessionSignServiceStatusType } from './types/productMigrationCession.interface';
import { ProductMigrationStatus, ProductMigrationStatusType } from './types/productMigrationStatus.interface';

export const GET_PRODUCT_MIGRATION_STATUS_QUERY = 'getProductMigrationStatusQuery';
export const UPDATE_PRODUCT_MIGRATION_STATUS_QUERY = 'updateProductMigrationStatusQuery';
export const GET_PRODUCT_CONFIGURATION_QUERY = 'getConfigurationQuery';
export const CREATE_PRODUCT_CONFIGURATION_QUERY = 'createProductConfigurationQuery';
export const GET_DSO_LIST_QUERY = 'getDsoListQuery';
export const CREATE_CESSION_QUERY = 'createCessionQuery';
export const GET_CESSION_QUERY = 'getCessionQuery';
export const GET_CESSION_STATUS_QUERY = 'getCessionStatusQuery';
export const ACCEPT_OFFER_QUERY = 'acceptOfferQuery';
export const GET_ACTIVE_CAMPAIGNS_QUERY = 'getActiveCampaignsQuery';

type ProductMigrationErrors = {
  createCessionError: boolean;
};

export interface ProductMigrationState {
  status: ProductMigrationStatusType | undefined;
  dsoOperators: DsoListData;
  configuration: ProductConfiguration | undefined;
  configurationId: string | undefined;
  cessionStatus?: CessionSignServiceStatusType;
  cessionUrl?: string;
  campaigns?: ProductMigrationCampaigns;
  errors: ProductMigrationErrors;
  globalStatusReset: ProductMigrationGlobalStatusReset;
  [ACCEPT_OFFER_QUERY]: Query;
  [CREATE_CESSION_QUERY]: Query;
  [GET_CESSION_STATUS_QUERY]: Query;
  [GET_CESSION_QUERY]: Query;
  [GET_DSO_LIST_QUERY]: Query<ProductConfiguration>;
  [GET_PRODUCT_CONFIGURATION_QUERY]: Query<ProductConfiguration>;
  [GET_PRODUCT_MIGRATION_STATUS_QUERY]: Query<ProductMigrationStatus>;
  [CREATE_PRODUCT_CONFIGURATION_QUERY]: Query<ProductConfiguration>;
  [UPDATE_PRODUCT_MIGRATION_STATUS_QUERY]: Query<ProductMigrationStatus>;
  [GET_ACTIVE_CAMPAIGNS_QUERY]: Query<ProductMigrationCampaigns>;
}

export const initialState: ProductMigrationState = {
  status: undefined,
  dsoOperators: [],
  configuration: undefined,
  configurationId: undefined,
  cessionStatus: undefined,
  cessionUrl: undefined,
  errors: {
    createCessionError: false,
  },
  globalStatusReset: {
    isActive: false,
    timestamp: 0,
  },
  [GET_PRODUCT_MIGRATION_STATUS_QUERY]: {},
  [UPDATE_PRODUCT_MIGRATION_STATUS_QUERY]: {},
  [CREATE_PRODUCT_CONFIGURATION_QUERY]: {},
  [GET_PRODUCT_CONFIGURATION_QUERY]: {},
  [GET_DSO_LIST_QUERY]: {},
  [CREATE_CESSION_QUERY]: {},
  [GET_CESSION_QUERY]: {},
  [GET_CESSION_STATUS_QUERY]: {},
  [ACCEPT_OFFER_QUERY]: {},
  [GET_ACTIVE_CAMPAIGNS_QUERY]: {},
};
