import { Query } from '+shared/store/query/query.state';
import { User } from '+shared/store/user/types/user.interface';
import { Profile } from './types/profile.interface';

export const GET_ACCOUNT_PROFILE_QUERY = 'getAccountProfileQuery';
export const UPDATE_ACCOUNT_PROFILE_QUERY = 'updateAccountProfileQuery';
export const SET_USER_DATA_QUERY = 'setUserDataQuery';
export const CHANGE_USER_PASSWORD_QUERY = 'changeUserPasswordQuery';

export interface AccountState {
  profile: Profile;
  [GET_ACCOUNT_PROFILE_QUERY]: Query<Profile>;
  [UPDATE_ACCOUNT_PROFILE_QUERY]: Query<boolean>;
  [SET_USER_DATA_QUERY]: Query<User>;
  [CHANGE_USER_PASSWORD_QUERY]: Query<User>;
}

export const initialState: AccountState = {
  profile: {
    self: '',
    id: '',
    type: '',
    nickname: '',
    description: '',
    image: null,
    tags: [],
    privacy: undefined,
    address: {
      city: '',
      postalCode: '',
      country: '',
    },
    products: [],
  },
  [GET_ACCOUNT_PROFILE_QUERY]: {},
  [UPDATE_ACCOUNT_PROFILE_QUERY]: {},
  [SET_USER_DATA_QUERY]: {},
  [CHANGE_USER_PASSWORD_QUERY]: {},
};
