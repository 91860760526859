import classNames from 'classnames';
import * as React from 'react';

import { Form, FormElement } from '../../Form.component';

import './Textarea.component.scss';

export interface FormTextareaProps {
  label: string;
  placeholder: string;
  floatingLabel?: boolean;
  apiError?: string;
  dataTestId?: string;
}

export const Textarea: FormElement<FormTextareaProps> = ({
  label,
  placeholder,
  input,
  meta: {
    active,
    touched,
    error,
  },
  apiError,
  dataTestId,
}) => {
  const textareaClasses = classNames('c-textarea', {
    'is-active': active || input.value,
  });

  return (
    <div className="c-form__item">
      <textarea
        data-test-id={dataTestId}
        {...input}
        name="description"
        className={textareaClasses}
        id={input.name}
        placeholder={placeholder}
      />
      <label
        className="c-form__label"
        htmlFor={input.name}
      >
        {label}
      </label>
      {(touched && error) && (
        <Form.Info>
          {error.map((errorElement: any) => (
            <Form.Alert 
              dataTestId={'help-and-support-form-question-description-error-messages'} 
              key={errorElement.id}>
              {errorElement.label}
            </Form.Alert>
          ))}
        </Form.Info>
      )}
      {apiError && apiError.length && (
        <Form.Info>
          <Form.Alert 
            dataTestId={'help-and-support-form-question-description-error-messages'} >
            {apiError}
          </Form.Alert>
        </Form.Info>
      )}
    </div>
  );
};
