import * as moment from 'moment';
import { createSelector } from 'reselect';

import { StoreState } from '+app/store/store.interface';
import { getStatus } from '+utils/query.util';
import { BatteryHelper } from './battery.helpers';
import { BackupBufferProcessing, BatteryState, GET_BATTERY_CELL_CARE_HISTORY_QUERY, GET_BATTERY_DATA_QUERY } from './battery.state';
import { BackupDeviceType, BatteryControllerType, BatteryStatus } from './types/battery.interface';

export const getBatteryState = (state: StoreState): BatteryState => state.battery;

export const getBatteryData = createSelector(
  getBatteryState,
  (state: BatteryState) => state.data,
);

export const getDocuments = createSelector(
  getBatteryState,
  (state: BatteryState) => state.documents,
);

export const getElectricUnits = createSelector(
  getBatteryState,
  (state: BatteryState) => state.electricUnits,
);

export const getBatteryId = createSelector(
  getBatteryData,
  ({ id }) => id,
);

export const hasInstalledBattery = createSelector(
  getBatteryData,
  ({ id, assetStatus }) => !!id && assetStatus === BatteryStatus.INSTALLED,
);

export const getBatterySerialNumber = createSelector(
  getBatteryData,
  ({ serialNumber }) => serialNumber,
);

export const getBatteryInstallationDate = createSelector(
  getBatteryData,
  (batteryData) => batteryData ? batteryData.installationDate : undefined,
);

export const getBatteryControllerType = createSelector(
  getBatteryData,
  ({ controllerType }) => controllerType,
);

export const getBatteryCountryCode = createSelector(
  getBatteryData,
  ({ installationCountryCode }) => installationCountryCode,
);

export const getBatteryTimezone = createSelector(
  getBatteryData,
  ({ timeZone }) => timeZone || moment.tz.guess(),
);

export const isBackupDeviceInstalled = createSelector(
  getBatteryData,
  ({ backupDeviceType }) => Boolean(backupDeviceType && backupDeviceType !== BackupDeviceType.NONE),
);

export const isBackupBoxInstalled = createSelector(
  getBatteryData,
  ({ backupDeviceType }) => Boolean(backupDeviceType && backupDeviceType === BackupDeviceType.BACKUPBOX),
);

export const isProtectBoxInstalled = createSelector(
  getBatteryData,
  ({ backupDeviceType }) => Boolean(backupDeviceType && backupDeviceType === BackupDeviceType.PROTECT),
);

export const getBackupBufferProcessing = createSelector(
  [getBatteryData, getBatteryState],
  (batteryData, state): BackupBufferProcessing | undefined => batteryData.id
    ? state.backupBuffer[batteryData.id]
    : undefined,
);

export const getBackupBufferProcessingValue = createSelector(
  getBackupBufferProcessing,
  backupBufferProcessing => backupBufferProcessing?.processingValue,
);

export const isBackupBufferProcessingInProgress = createSelector(
  getBackupBufferProcessing,
  backupBufferProcessing => Boolean(backupBufferProcessing),
);

export const isHeaterInstalled = createSelector(
  getBatteryData,
  ({ heaterConnectionStatus }) => Boolean(heaterConnectionStatus),
);

export const hasArticleNumber = createSelector(
  getBatteryData,
  ({ articleNumber }) => Boolean(articleNumber),
);

export const isEatonBattery = createSelector(
  getBatteryData,
  data => (data && data.controllerType) === BatteryControllerType.EATON,
);

export const isSpreeBattery = createSelector(
  getBatteryData,
  data => (data && data.controllerType) === BatteryControllerType.SPREE,
);

export const isEmergencyModeActive = createSelector(
  getBackupBufferProcessing,
  getBatteryData,
  (backup, battery) =>
    backup && backup.processingValue === BatteryHelper.EMERGENCY_MODE_BACKUP_VALUES.max
    || battery && battery.backupPowerBuffer === BatteryHelper.EMERGENCY_MODE_BACKUP_VALUES.max,
);

// TODO: Remove that one and use getBatteryQueryStatus & getBatteryQueryErrorCount instead
export const getBatteryDataQuery = createSelector(
  getBatteryState,
  battery => battery.getBatteryDataQuery,
);

export const getBatteryQueryStatus = createSelector(
  getBatteryState,
  state => getStatus(state[GET_BATTERY_DATA_QUERY]),
);

export const isBatteryQueryPending = createSelector(
  getBatteryQueryStatus,
  ({ pending }) => pending,
);

export const getBatteryQueryErrorCount = createSelector(
  getBatteryState,
  state => state[GET_BATTERY_DATA_QUERY].errorCount || 0,
);

export const getDocumentsLanguage = createSelector(
  getBatteryState,
  state => state.documentsLanguage,
);

export const getBatteryCellCareHistory = createSelector(
  getBatteryState,
  state => state.cellCareHistory,
);

export const getBatteryCellCareHistoryQueryStatus = createSelector(
  getBatteryState,
  state => getStatus(state[GET_BATTERY_CELL_CARE_HISTORY_QUERY]),
);
