import { ViewProps, Visibility } from '@kanva/core';
import { Kanva } from '@kanva/react';
import { Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './FullWidthKanva.component.scss';

export type KanvaCanvasRef = (canvas: HTMLCanvasElement | null) => void;

interface Props {
  children: React.ReactElement<ViewProps> | Array<React.ReactElement<ViewProps>>;
  className?: ClassValue;
  height?: number;
  debug?: boolean;
  isLoading?: boolean;
  canvasRef?: KanvaCanvasRef;
  visibleLineGraphs?: Visibility[];
}

export const FullWidthKanva: React.SFC<Props> = ({
  children,
  className,
  height = 500,
  isLoading,
  canvasRef,
  debug,
  visibleLineGraphs,
}) => (
  <div
    className={classNames('c-full-width-kanva', className)}
    style={{ '--height': height } as React.CSSProperties }
  >
    <Kanva
      className={classNames(['c-full-width-kanva__canvas', {
        'c-analysis-day-chart__canvas--with-single-line-chart': visibleLineGraphs?.length === 1,
      }])}
      enablePointerEvents={true}
      canvasRef={canvasRef}
      debug={debug}
    >
      {children}
    </Kanva>
    <div className={classNames('c-full-width-kanva__loader-container', {
      'is-active': isLoading,
    })}>
      <Loader className={'c-full-width-kanva__loader'} />
    </div>
  </div>
);
