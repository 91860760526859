import { ContractEnergyDocument } from '+contract/store/types/energy.interface';
import { T } from '@sonnen/shared-i18n/customer';
import { Icon, Loader, SideInfo } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { ContractNotificationParagraph } from '../ContractNotificationParagraph';

import './ContractEnergyDocumentsList.component.scss';

interface Props {
  files: ContractEnergyDocument[] | undefined;
  hasContractFilesQueryFailed: boolean;
  isLoading: boolean;
}

export const ContractEnergyDocumentsList: React.FC<Props> = ({
  files,
  hasContractFilesQueryFailed,
  isLoading,
}) => (
  <div className={'c-contract-energy-documents-list'}>
    <SideInfo>
      <div className={'c-contract-energy-documents-list__header'}>
        <p className={'c-contract-energy-documents-list__title'}>
          {I18n.t(T.sonnenEnergy.documents.sideSectionTitle)}
        </p>
      </div>
      {(!isNil(files) && files.length) ? (
        <div className={'c-contract-energy-documents-list__documents'}>
          {files.map(({
            title,
            link,
            info,
          }) => (
            <div
              key={link}
              className={'c-contract-energy-documents-list__document'}
            >
              <a
                href={link}
                rel={'noopener noreferrer'}
                className={'c-contract-energy-documents-list__document-link'}
              >
                <Icon.Download
                  className={'c-contract-energy-documents-list__document-icon'}
                  hasHoverAnimation={true}
                />
                {title}
              </a>
              <p className={'c-contract-energy-documents-list__document-info'}>
                {info}
              </p>
            </div>
          ))}
        </div>
      ) : isLoading 
        ? <Loader /> 
        : (
          <ContractNotificationParagraph
            text={!hasContractFilesQueryFailed
              ? I18n.t(T.sonnenEnergy.notifications.noContractDocuments)
              : I18n.t(T.sonnenEnergy.notifications.fetchDocumentsError)
            }
          />
        )}
    </SideInfo>
  </div>
);
