import { InfographicChargerData } from '+dashboard/+infographic';

export const CHARGER_DATA_MOCK: InfographicChargerData = {
  id: 'chargerFakeId',
  chargingSpeed: 0,
  distanceCharged: 0,
  departureAt: '',
  isOnline: true,
  isCarConnected: true,
  isCharging: false,
  isSmartmodeAvailable: true,
  isSmartmodeCharging: false,
  isPowermodeCharging: false,
  isReadyForCharging: false,
  hasCharger: true,
};
