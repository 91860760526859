import * as React from 'react';

import { ContractBenefitList, ContractDetails } from '../../components';
import { ContractDetailsConfig } from '../../store/helpers/contract.helpers';

interface Props {
  benefitList: string;
  contractDetailsList: ContractDetailsConfig[] | undefined;
  withBenefits: boolean;
  dataTestId?: string;
}

export const ContractFlatInfo: React.FC<Props> = ({
  benefitList,
  contractDetailsList,
  withBenefits,
  dataTestId,
}) => (
  <div className={'o-grid'}>
    <section className={'o-grid__column o-grid__column--sm o-grid__column--no-gap-sm'}>
      <ContractDetails contractDetailsList={contractDetailsList} />
    </section>
    <section className={'o-grid__column o-grid__column--sm o-grid__column--no-gap'}>
      {withBenefits && 
        <ContractBenefitList 
          dataTestId={dataTestId} 
          benefitsList={benefitList} 
        />
      }
    </section>
  </div>
);
