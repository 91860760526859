import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

import { insertIf } from '+app/utils/array.util';
import { Configuration, OfferTaxRegulation } from '+guide/store/types';
import { ConfigurationHelper } from '+shared/helpers/configuration.helper';

export const factorizeConfDetails = (offerTaxRegulation: OfferTaxRegulation, {
   market,
   powerPlant,
   excessTariffGrossPerKwh,
   initialAdvancePaymentGrossPerMonth,
   cashbackTariffGrossPerKwh,
   cashbackTariffNetPerKwh,
   valueAddedTaxPerKwh,
}: Configuration) => {
  const currency = ConfigurationHelper.getCurrencyForMarket(market);
  const isSmallEnterprise = offerTaxRegulation === OfferTaxRegulation.SMALL_ENTERPRISE;

  const { 
    guaranteedVppBonusGranted, 
    guaranteedVppBonusGrossPerYear, 
    guaranteedVppBonusNetPerYear, 
    expectedCashbackNetPerYear, 
    expectedCashbackGrossPerYear, 
  } = powerPlant;

  const vppBonusStandardTaxation = guaranteedVppBonusGranted && guaranteedVppBonusGrossPerYear
    ? `${I18n.t(T.customerGuidance.purchase.flatOffer.minimum)} 
      ${ConfigurationHelper.formatMeasurementPrice(guaranteedVppBonusGrossPerYear, currency)}/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`
    : '';

  const vppBonusSmallEnterpriseTaxation = guaranteedVppBonusGranted && guaranteedVppBonusNetPerYear
    ? `${I18n.t(T.customerGuidance.purchase.flatOffer.minimum)} 
      ${ConfigurationHelper.formatMeasurementPrice(guaranteedVppBonusNetPerYear, currency)}/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`
    : '';

  const expectedCashbackPerYear = isSmallEnterprise 
    ? expectedCashbackNetPerYear 
    : expectedCashbackGrossPerYear;
  const cashbackTariffPerKwh = isSmallEnterprise 
    ? cashbackTariffNetPerKwh 
    : cashbackTariffGrossPerKwh;
  const vppBonus = isSmallEnterprise
    ? vppBonusSmallEnterpriseTaxation
    : vppBonusStandardTaxation;

  return [
    // Monthly pre-payment
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.monthlyPrepayment)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice(initialAdvancePaymentGrossPerMonth, currency)}/${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.month)}`,
    },
    // Excess energy
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.excessEnergy)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(powerPlant.expectedExcessEnergyPerYear)}/${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.year)}`,
      isLight: true,
    },
    // Excess tariff
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.excessTariff)}:`,
      value: ConfigurationHelper.formatPricePerKwh(excessTariffGrossPerKwh, currency, { asMinorUnit: true }),
      isLight: true,
      helperLabel: isSmallEnterprise ? I18n.t(T.customerGuidance.purchase.flatOffer.excessTariffHelper) : '',
    },
    // Expected cashback
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.expectedCashback)}:`,
      value: `+${ConfigurationHelper.formatMeasurementPrice(expectedCashbackPerYear, currency)}/${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.year)}`,
    },
    // Expected unused free usage allowance
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.expectedUnusedFreeUsageAllowance)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(powerPlant.expectedUnusedUsageAllowancePerYear)}/${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.year)}`,
      isLight: true,
    },
    // Cashback rate
    {
      name: `${I18n.t(T.customerGuidance.acceptance.flatOfferDetails.cashbackRate)}:`,
      value: ConfigurationHelper.formatPricePerKwh(cashbackTariffPerKwh, currency, { asMinorUnit: true }),
      isLight: true,
      helperLabel: isSmallEnterprise ? I18n.t(T.customerGuidance.purchase.flatOffer.cashbackRateHelper) : '',
    },
    ...insertIf(isSmallEnterprise, {
      name: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedVatOnUsedUsageAllowance)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice(powerPlant.expectedVatOnUsedUsageAllowancePerYear, currency)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
    }),
    // prognosed used usage allowance
    ...insertIf(isSmallEnterprise, {
      name: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedUsedUsageAllowance)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(powerPlant.expectedUsedUsageAllowancePerYear)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
      isLight: true,
    }),
    // vat in ct/kWh on used usage allowance
    ...insertIf(isSmallEnterprise, {
      name: `${I18n.t(T.customerGuidance.purchase.flatOffer.vatPerKwhOnUsedUsageAllowance)}:`,
      value: ConfigurationHelper.formatPricePerKwh(valueAddedTaxPerKwh, currency, { asMinorUnit: true }),
      isLight: true,
    }),
    // VPP bonus
    ...insertIf(
      !!powerPlant.guaranteedVppBonusGranted,
      {
        name: I18n.t(T.customerGuidance.purchase.flatOffer.vppBonus),
        value: vppBonus,
        helperLabel: isSmallEnterprise ? I18n.t(T.customerGuidance.purchase.flatOffer.zeroPercentVatHelper) : '',
      },
    ),
  ];
};
