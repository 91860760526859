import { MediaQuery } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';

import './BatteryDownloadsTableRow.component.scss';

interface Props {
  content: React.ReactNode;
  secondaryContent?: React.ReactNode;
  download: React.ReactNode;
  dataTestId?: string;
}

export const BatteryDownloadsTableRow: React.FC<Props> = ({
  content,
  secondaryContent,
  download,
  dataTestId,
}) => (
  <div
    data-test-id={`${dataTestId}-row`}
    className={'c-battery-downloads-table-row'}
    >
    <div
      data-test-id={`${dataTestId}-title`}
      className={'c-battery-downloads-table-row__content'}
    >
      {content}
    </div>
    <Media query={MediaQuery.UP_SM}>
      {secondaryContent && (
        <div
          data-test-id={`${dataTestId}-filename`}
          className={'c-battery-downloads-table-row__secondary-content'}>
          {secondaryContent}
        </div>
      )}
    </Media>
    <div className={'c-battery-downloads-table-row__download'}>
      {download}
    </div>
  </div>
);
