import { ActionsUnion, createAction } from '+utils/redux';
import { Battery, BatteryStatusHistory, BatteryUnits } from './types/battery.interface';
import { Document } from './types/battery.interface';

export enum BATTERY_ACTIONS {
  GET_DATA = '[Battery] GET_DATA',
  SET_DATA = '[Battery] SET_DATA',
  GET_DOCUMENTS = '[+Battery] GET_DOCUMENTS',
  SET_DOCUMENTS = '[+Battery] SET_DOCUMENTS',
  GET_BATTERY_ELECTRIC_UNITS = '[Battery] GET_BATTERY_ELECTRIC_UNITS',
  GET_BATTERY_CELL_CARE_HISTORY = '[Battery] GET_BATTERY_CELL_CARE_HISTORY',
  SET_BATTERY_CELL_CARE_HISTORY = '[Battery] SET_BATTERY_CELL_CARE_HISTORY',
  SET_BATTERY_ELECTRIC_UNITS = '[Battery] SET_BATTERY_ELECTRIC_UNITS',
  SET_BACKUP_BUFFER = '[Battery] SET_BACKUP_BUFFER',
  ADD_BACKUP_BUFFER_PROCESSING = '[Battery] ADD_BACKUP_BUFFER_PROCESSING',
  DELETE_BACKUP_BUFFER_PROCESSING = '[Battery] DELETE_BACKUP_BUFFER_PROCESSING',
  WATCH_BACKUP_BUFFER = '[Battery] WATCH_BACKUP_BUFFER',
  CLEAR_SITE_BATTERY_STATE = '[Battery] CLEAR_SITE_BATTERY_STATE',
}

export const BatteryActions = {
  getData: () =>
    createAction(BATTERY_ACTIONS.GET_DATA),
  setData: (data: Battery) =>
    createAction(BATTERY_ACTIONS.SET_DATA, { data }),
  getDocuments: (articleNumber: string | undefined, language: string) =>
    createAction(BATTERY_ACTIONS.GET_DOCUMENTS, { articleNumber, language }),
  setDocuments: (documents: Document[]) =>
    createAction(BATTERY_ACTIONS.SET_DOCUMENTS, { documents }),
  getBatteryElectricUnits: () =>
    createAction(BATTERY_ACTIONS.GET_BATTERY_ELECTRIC_UNITS),
  getBatteryCellCareHistory: () =>
    createAction(BATTERY_ACTIONS.GET_BATTERY_CELL_CARE_HISTORY),
  setBatteryCellCareHistory: (batteryStatusHistory: BatteryStatusHistory[]) =>
    createAction(BATTERY_ACTIONS.SET_BATTERY_CELL_CARE_HISTORY, { batteryStatusHistory }),
  setBatteryElectricUnits: (data: BatteryUnits) =>
    createAction(BATTERY_ACTIONS.SET_BATTERY_ELECTRIC_UNITS, { data }),
  setBackupBuffer: (value: number) =>
    createAction(BATTERY_ACTIONS.SET_BACKUP_BUFFER, { value }),
  addBackupBufferProcessing: (batteryId: string, processingValue: number) =>
    createAction(BATTERY_ACTIONS.ADD_BACKUP_BUFFER_PROCESSING, { batteryId, processingValue }),
  deleteBackupBufferProcessing: (batteryId: string) =>
    createAction(BATTERY_ACTIONS.DELETE_BACKUP_BUFFER_PROCESSING, { batteryId }),
  watchBackupBuffer: () =>
    createAction(BATTERY_ACTIONS.WATCH_BACKUP_BUFFER),
  clearSiteBatteryState: () =>
    createAction(BATTERY_ACTIONS.CLEAR_SITE_BATTERY_STATE),
};

export type BatteryActions = ActionsUnion<typeof BatteryActions>;
