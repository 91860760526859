import * as variables from '@sonnen/shared-web/_styles.scss';

export type Breakpoint = keyof typeof breakpointMap;

const breakpointMap = {
  XXS: variables.breakpointXxs,
  XS: variables.breakpointXs,
  SM: variables.breakpointSm,
  MD: variables.breakpointMd,
  LG: variables.breakpointLg,
  XLG: variables.breakpointXlg,
  XXLG: variables.breakpointXxlg,
  NAV: variables.breakpointNav,
};

export const breakpointUp = (breakpoint: Breakpoint) =>
  parseInt(breakpointMap[breakpoint], 10);

export const breakpointDown = (breakpoint: Breakpoint) =>
  breakpointMap[breakpoint] - 1;
