import { Loader } from '@sonnen/shared-web';
import * as React from 'react';

import './AuthTransitionGate.component.scss';

interface Props {
  // @TODO
}

export const AuthTransitionGate: React.FC<Props> = ({}) => (
  <div className={'c-auth-transition-gate'}>
    <div className={'c-auth-transition-gate__logo'} />
    <Loader className={'c-auth-transition-gate__loader'} />
  </div>
);
