import { T } from '@sonnen/shared-i18n/customer';
import {
  BoldParagraph,
  Bubble,
  Icon,
} from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { FormFieldObserver } from '+app/+guide/components/FormFieldObserver';
import { GuideControlledCheckbox } from '+app/+guide/components/GuideControlledCheckbox';
import { Configuration } from '+app/+guide/store/types';
import { FormInputDate } from '+shared/components';
import { FormInputSubscriptionPayload } from '+shared/hooks/useDispatchInputEvent';
import { formFields, UtilityDataForm } from '../../containers';
import { calculateMinDeliveryDate } from './GuideAcceptanceDesiredDeliveryDate.helper';

import './GuideAcceptanceDesiredDeliveryDate.component.scss';

interface Props {
  form: FormikProps<UtilityDataForm>;
  configuration: Configuration | undefined;
}

export const GuideAcceptanceDesiredDeliveryDate = ({ form, configuration }: Props) => {
  const [isTooltipActive, toggleTooltipActivity] = React.useState<boolean>(false);
  const { errors: { isDateChosen: dateError } } = form;

  const onCheckboxChange = (form: FormikProps<UtilityDataForm>) =>
    (payload: FormInputSubscriptionPayload<UtilityDataForm>) => {
      if (payload.value) {
        form.setFieldValue(formFields.DESIRED_DELIVERY_START_DATE, '');
      }
    };

  const onDateChange = (form: FormikProps<UtilityDataForm>) =>
    (payload: FormInputSubscriptionPayload<UtilityDataForm>) => {
      if (payload.value) {
        form.setFieldValue(formFields.DESIRED_DELIVERY_START_CLOSEST, false);
      }
      form.setFieldTouched(formFields.DESIRED_DELIVERY_START_DATE);
    };

  return (
    <div className={'c-guide-acceptance-desired-delivery-date'}>
      <div className={'c-guide-acceptance-desired-delivery-date__header'}>
        <BoldParagraph className={'c-guide-acceptance-desired-delivery-date__header-text'}>
          {I18n.t(T.customerGuidance.acceptance.utilityData.desiredDate.header)}
        </BoldParagraph>

        <div
          onMouseOver={() => toggleTooltipActivity(true)}
          onMouseLeave={() => toggleTooltipActivity(false)}
          className={'c-guide-acceptance-desired-delivery-date__tooltip'}
        >
          <Icon.Info className={'c-guide-acceptance-desired-delivery-date__icon'} />
          {isTooltipActive &&
            <div className={'c-guide-acceptance-desired-delivery-date__bubble-wrapper'} >
              <Bubble
                side={'top'}
                isThemeDense={false}
              >
                {I18n.t(T.customerGuidance.acceptance.utilityData.desiredDate.tooltip)}
              </Bubble>
            </div>
          }
        </div>
      </div>

      {dateError &&
        <p className={'c-guide-acceptance-desired-delivery-date__error'}>
          {I18n.t(T.customerGuidance.acceptance.utilityData.desiredDate.dateError)}
        </p>
      }

      <FormFieldObserver<UtilityDataForm> onChange={onCheckboxChange(form)}>
        <div className={'c-guide-acceptance-desired-delivery-date__input'}>
          <GuideControlledCheckbox
            form={form}
            name={formFields.DESIRED_DELIVERY_START_CLOSEST}
            label={I18n.t(T.customerGuidance.acceptance.utilityData.desiredDate.checkbox)}
          />
        </div>
      </FormFieldObserver>

      <FormFieldObserver<UtilityDataForm> onChange={onDateChange(form)}>
        <div className={'c-guide-acceptance-desired-delivery-date__input'}>
          <FormInputDate
            minDate={calculateMinDeliveryDate(configuration)}
            form={form}
            name={formFields.DESIRED_DELIVERY_START_DATE}
            id={'guide-acceptance-desired-delivery-date'}
          />
        </div>
      </FormFieldObserver>
    </div>
  );
};
