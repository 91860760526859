import {
  AccessoriesChartSeries,
  BarChartSeries,
  EnergyFlowSeriesKey,
  PieChartSeries,
} from '@sonnen/shared-web';
import { Moment } from 'moment';

import { MeasurementsV2FilterResolution } from '+analysis/store/types/measurementsV2.interface';
import { StatisticsV2FilterResolution } from '+analysis/store/types/statisticsV2.interface';
import { SummerTimeChange } from '+app/shared/helpers/summerTime.helper';
import { SiteLive } from '+app/shared/store/live/types/siteLiveData.interface';
import { ActionsUnion, createAction } from '+utils/redux';
import { AreaChartSeries } from './types/dataSeries.interface';
import { SiteForecastConsumption, SiteForecastProduction } from './types/forecast.interface';
import { SelectedDate } from './types/selectedDate.interface';
import { SiteEvent } from './types/siteEvents.interface';
import { SiteMeasurements } from './types/siteMeasurements.interface';
import { StatisticsV2 } from './types/statisticsV2.interface';

export enum ANALYSIS_ACTIONS {
  // Measurements
  GET_MEASUREMENTS = '[Analysis] GET_MEASUREMENTS',
  SET_MEASUREMENTS = '[Analysis] SET_MEASUREMENTS',
  GET_BATTERY_MEASUREMENTS_CSV = '[Analysis] GET_BATTERY_MEASUREMENTS_CSV',

  // Statistics
  GET_STATISTICS = '[Analysis] GET_STATISTICS',
  SET_STATISTICS = '[Analysis] SET_STATISTICS',
  GET_BATTERY_STATISTICS_CSV = '[Analysis] GET_BATTERY_STATISTICS_CSV',

  // Cache
  SET_MEASUREMENTS_CACHE = '[Analysis] SET_MEASUREMENTS_CACHE',
  SET_STATISTICS_CACHE = '[Analysis] SET_STATISTICS_CACHE',
  CLEAR_CACHE = '[Analysis] CLEAR_CACHE',

  // Forecast
  GET_FORECAST_PRODUCTION = '[Analysis] GET_FORECAST_PRODUCTION',
  SET_FORECAST_PRODUCTION = '[Analysis] SET_FORECAST_PRODUCTION',
  GET_FORECAST_CONSUMPTION = '[Analysis] GET_FORECAST_CONSUMPTION',
  SET_FORECAST_CONSUMPTION = '[Analysis] SET_FORECAST_CONSUMPTION',
  CLEAR_FORECAST_DATA = '[Analysis] CLEAR_FORECAST_DATA',

  // Accesories events
  GET_EVENTS = '[Analysis] GET_EVENTS',
  SET_EVENTS = '[Analysis] SET_EVENTS',

  // Area chart
  SET_AREA_CHART_SERIES = '[Analysis] SET_AREA_CHART_SERIES',
  UPDATE_LIVE_AREA_CHART_SERIES = '[Analysis] UPDATE_LIVE_AREA_CHART_SERIES',
  SET_DATA_SERIES_KEYS = '[Analysis] SET_DATA_SERIES_KEYS',
  TOGGLE_AREA_CHART_SERIES = '[Analysis] TOGGLE_AREA_CHART_SERIES',

  // Accesories charts
  SET_BACKUP_BOX_SERIES = '[Analysis] SET_BACKUP_BOX_SERIES',
  SET_HEATER_POWER_SERIES = '[Analysis] SET_HEATER_POWER_SERIES',

  // Pie & Bar charts
  SET_BAR_CHART_SERIES = '[Analysis] SET_BAR_CHART_SERIES',
  SET_PIE_CHART_SERIES = '[Analysis] SET_PIE_CHART_SERIES',

  // Other
  SET_SELECTED_DATE = '[Analysis] SET_SELECTED_DATE',
  SET_STATISTICS_SELECTED_DATE = '[Analysis] SET_STATISTICS_SELECTED_DATE',
  SET_SUMMER_TIME = '[Analysis] SET_SUMMER_TIME',
  CLEAR_ANALYSIS = '[Analysis] CLEAR_ANALYSIS',
}

export const AnalysisActions = {
  // Measurements
  getMeasurements: () =>
    createAction(ANALYSIS_ACTIONS.GET_MEASUREMENTS),
  setMeasurements: (measurements: SiteMeasurements) =>
    createAction(ANALYSIS_ACTIONS.SET_MEASUREMENTS, { measurements }),
  getSiteMeasurementsCSV: (start: string, end: string, resolution: MeasurementsV2FilterResolution) =>
    createAction(ANALYSIS_ACTIONS.GET_BATTERY_MEASUREMENTS_CSV, { start, end, resolution }),

  // Statistics
  getStatistics: () =>
    createAction(ANALYSIS_ACTIONS.GET_STATISTICS),
  setStatistics: (statistics: StatisticsV2) =>
    createAction(ANALYSIS_ACTIONS.SET_STATISTICS, { statistics }),
  getSiteStatisticsCSV: (start: string, end: string, resolution: StatisticsV2FilterResolution) =>
    createAction(ANALYSIS_ACTIONS.GET_BATTERY_STATISTICS_CSV, { start, end, resolution }),

  // Cache
  setMeasurementsCache: (measurementsCache: SiteMeasurements[]) =>
    createAction(ANALYSIS_ACTIONS.SET_MEASUREMENTS_CACHE, { measurementsCache }),
  setStatisticsCache: (statisticsCache: StatisticsV2[]) =>
    createAction(ANALYSIS_ACTIONS.SET_STATISTICS_CACHE, { statisticsCache }),

  // Forecast
  getForecastProduction: () =>
    createAction(ANALYSIS_ACTIONS.GET_FORECAST_PRODUCTION),
  setForecastProduction: (forecastProduction: SiteForecastProduction[]) =>
    createAction(ANALYSIS_ACTIONS.SET_FORECAST_PRODUCTION, { forecastProduction }),
  getForecastConsumption: () =>
    createAction(ANALYSIS_ACTIONS.GET_FORECAST_CONSUMPTION),
  setForecastConsumption: (forecastConsumption: SiteForecastConsumption[]) =>
    createAction(ANALYSIS_ACTIONS.SET_FORECAST_CONSUMPTION, { forecastConsumption }),
  clearForecastData: () =>
    createAction(ANALYSIS_ACTIONS.CLEAR_FORECAST_DATA),

  // Accesories events
  getEvents: () =>
    createAction(ANALYSIS_ACTIONS.GET_EVENTS),
  setEvents: (events: SiteEvent[]) =>
    createAction(ANALYSIS_ACTIONS.SET_EVENTS, { events }),

  // Area chart
  setAreaChartSeries: (areaChartSeries: Partial<AreaChartSeries>) =>
    createAction(ANALYSIS_ACTIONS.SET_AREA_CHART_SERIES, { areaChartSeries }),
  updateLiveAreaChartSeries: (siteLiveData: SiteLive) =>
    createAction(ANALYSIS_ACTIONS.UPDATE_LIVE_AREA_CHART_SERIES, { siteLiveData }),
  toggleAreaChartSeries: (dataSeriesKey: EnergyFlowSeriesKey) =>
    createAction(ANALYSIS_ACTIONS.TOGGLE_AREA_CHART_SERIES, { dataSeriesKey }),
  setDataSeriesKeys: (dataSeriesKeys: EnergyFlowSeriesKey[]) =>
    createAction(ANALYSIS_ACTIONS.SET_DATA_SERIES_KEYS, { dataSeriesKeys }),

  // Accesories charts
  setBackupBoxSeries: (backupBoxSeries: Partial<AccessoriesChartSeries>) =>
    createAction(ANALYSIS_ACTIONS.SET_BACKUP_BOX_SERIES, { backupBoxSeries }),
  setHeaterPowerSeries: (heaterPowerSeries: Partial<AccessoriesChartSeries>) =>
    createAction(ANALYSIS_ACTIONS.SET_HEATER_POWER_SERIES, { heaterPowerSeries }),

  // Pie & Bar charts
  setBarChartSeries: (barChartSeries: BarChartSeries) =>
    createAction(ANALYSIS_ACTIONS.SET_BAR_CHART_SERIES, { barChartSeries }),
  setPieChartSeries: (pieChartSeries: PieChartSeries) =>
    createAction(ANALYSIS_ACTIONS.SET_PIE_CHART_SERIES, { pieChartSeries }),

  // Other
  setSelectedDate: (date: Moment) =>
    createAction(ANALYSIS_ACTIONS.SET_SELECTED_DATE, { date }),
  setSummerTime: (summerTimeChange: SummerTimeChange) =>
    createAction(ANALYSIS_ACTIONS.SET_SUMMER_TIME, { summerTimeChange} ),
  setStatisticsSelectedDate: (statisticsSelectedDate: SelectedDate) =>
    createAction(ANALYSIS_ACTIONS.SET_STATISTICS_SELECTED_DATE, { statisticsSelectedDate }),
  clearAnalysis: () =>
    createAction(ANALYSIS_ACTIONS.CLEAR_ANALYSIS),
};

export type AnalysisActions = ActionsUnion<typeof AnalysisActions>;
