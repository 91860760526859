import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';

import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux/mapActions.util';
import { AuthActions } from '+auth/store/auth.actions';
import { getLastAccessedAuthUrl, hasLoggedInOnce, isAuthenticated } from '+auth/store/auth.selectors';
import { updateUrlLanguageQuery } from '+legacy/helpers/i18n.helper';
import { AuthorizedRouting, UnauthorizedRouting } from './components';
import { getRoutesConfig } from './router.config';
import { getRouteFromPath } from './router.helper';
import { RouterProvider } from './RouterProvider.component';

const mapStateToProps = (state: StoreState) => ({
  isLoading: state.sync.isLoading,
  isReady: state.app.isReady,
  lang: state.i18n.locale,
  userLoggedIn: isAuthenticated(state),
  userLoggedInOnce: hasLoggedInOnce(state),
  lastAccessedAuthUrl: getLastAccessedAuthUrl(state),
});

const mapDispatchToProps = mapActions({
  setLastAccessedAuthUrl: AuthActions.setLastAccessedAuthUrl,
});

export type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteComponentProps
  ;

class RouterComponent extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Props) {
    this.setScrollPosition(prevProps);
    const { search } = this.props.location;
    
    if (!search.includes('lang=')) {
      updateUrlLanguageQuery(this.props.lang);
    }
  }

  setScrollPosition = (prevProps: Props) => {
    const { location, isReady } = this.props;
    const currentRoute = !isReady
      ? null
      : getRouteFromPath(location.pathname, getRoutesConfig());

    if (prevProps.location.pathname !== location.pathname
        && (currentRoute && !currentRoute.preventScrollToTop)) {
      window.scrollTo(0, 0);
    }
  };

  render() {
    const {
      isReady,
      userLoggedIn,
    } = this.props;

    return (
      <RouterProvider>
        <>
          {!isReady
            ? null
            : userLoggedIn
              ? <AuthorizedRouting userLoggedIn={userLoggedIn} />
              : <UnauthorizedRouting {...this.props} />
          }
        </>
      </RouterProvider>
    );
  }
}

export const Router = compose<any>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(RouterComponent);
