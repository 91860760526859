import * as classNames from 'classnames';
import * as React from 'react';
import { EFCircleGridImageGrid } from '../EFCircleGridImageGrid';
import { EFCircleGridImageWindmills } from '../EFCircleGridImageWindmills';

import './EFAnimations.component.scss';

type Props = {
  isAnimationActive?: boolean,
};

export const EFAnimations: React.FC<Props> = ({
  isAnimationActive,
}) => {

  const classes = {
    animations: classNames('c-ef-circle-animations', {
      'is-animated': isAnimationActive,
    }),
    windmills: classNames('c-ef-circle-animations__animation c-ef-circle-animations__animation--windmills', {
      'is-animated': isAnimationActive,
    }),
    grid: classNames('c-ef-circle-animations__animation c-ef-circle-animations__animation--grid', {
      'is-animated': isAnimationActive,
    }),
  };

  return (
    <div className={classes.animations}>
      <div className={classes.windmills}>
        <EFCircleGridImageWindmills />
      </div>
      <div className={classes.grid}>
        <EFCircleGridImageGrid />
      </div>
    </div>
  );
};
