import classNames from 'classnames';
import * as React from 'react';

import './EFCircleExplanationTooltip.component.scss';

interface Props {
  children: React.ReactNode;
  position?: string;
  dataTestId?: string;
}

// @TODO: Use bubble component instead
export const EFCircleExplanationTooltip: React.FC<Props> = ({ children, position, dataTestId }) => (
  <div 
    data-test-id={dataTestId}
    className={classNames('c-ef-explanation-tooltip', `c-ef-explanation-tooltip--${position}`)}
  >
    {children}
  </div>
);
