import { AnyAction } from 'redux';

import { RouteName } from '+app/router/router.types';
import { ConditionsProps } from '+app/shared/containers/NotificationProvider';

export type NotificationId = string;

export enum NotificationCategory {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

export enum NotificationIconType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
  OFFLINE = 'offline',
}

type I18nLiteral = string | [string, Record<string, string>];

export interface NotificationBannerContent {
  description: React.ReactNode | [string, Record<string, string>];
  title: I18nLiteral;
  type: NotificationCategory;
  icon?: React.ReactNode;
  hasCloseButton?: boolean;
  actionButton?: {
    label: string;
    onClick: () => void;
  };
}

export interface NotificationPayloadContent {
  description: I18nLiteral;
  title: I18nLiteral;
  icon?: React.ReactNode;
  hasCloseButton?: boolean;
  isWarning?: boolean;
  actionButton?: {
    label: string;
    actions?: AnyAction[] | undefined;
  };
}

export interface NotificationPayload extends Record<string, any> {
  content: NotificationPayloadContent;
  conditionFunction?: (args: ConditionsProps) => boolean;
  isPermanent?: boolean;
  actions?: AnyAction[];
  actionLabel?: string;
  includeInRoutes?: RouteName[];
  excludeInRoutes?: RouteName[];
  hideOnPlatform?: 'web' | 'mobile';
}

export interface Notification extends NotificationPayload {
  id: string;
  content: NotificationPayloadContent & { type: NotificationCategory; };
}
