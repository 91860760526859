import { T } from '@sonnen/shared-i18n/customer';
import { Icon, LinkButton, LinkButtonIconPosition } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './GuidePreviewNav.component.scss';

interface Props {
  prevPhase?: () => void;
  nextPhase?: () => void;
}

export const GuidePreviewNav: React.FC<Props> = ({prevPhase, nextPhase}) => (
  <div className={classNames('c-guide-preview-nav', {
    'c-guide-preview-nav--align-right': !prevPhase,
  })}>
    {prevPhase && (
      <div className={'c-guide-preview-nav__button--left'}>
        <LinkButton
          icon={<Icon.Angle />}
          onClick={prevPhase}
        >
          {I18n.t(T.customerGuidance.previewNav.previous)}
        </LinkButton>
      </div>
    )}
    {nextPhase && (
      <div className={'c-guide-preview-nav__button--right'}>
        <LinkButton
          icon={<Icon.Angle />}
          onClick={nextPhase}
          iconPosition={LinkButtonIconPosition.AFTER}
        >
          {I18n.t(T.customerGuidance.previewNav.next)}
        </LinkButton>
      </div>
    )}
  </div>
);
