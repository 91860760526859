import { T } from '@sonnen/shared-i18n/customer';

// @TODO Remove T.customerGuidance.purchase.flatTermsModal.impactAnalysis.headline
// @TODO Remove T.customerGuidance.purchase.flatTermsModal.impactAnalysis.description
export const definitions = [
  {
    headline: T.customerGuidance.purchase.flatTermsModal.sonnenFlat.headline,
    description: T.customerGuidance.purchase.flatTermsModal.sonnenFlat.description,
  },
  {
    headline: T.customerGuidance.purchase.flatTermsModal.usageAllowance.headline,
    description: T.customerGuidance.purchase.flatTermsModal.usageAllowance.description,
  },
  {
    headline: T.customerGuidance.purchase.flatTermsModal.basicTariff.headline,
    description: T.customerGuidance.purchase.flatTermsModal.basicTariff.description,
  },
  {
    headline: T.customerGuidance.purchase.flatTermsModal.monthlyPrepayment.headline,
    description: T.customerGuidance.purchase.flatTermsModal.monthlyPrepayment.description,
  },
  {
    headline: T.customerGuidance.purchase.flatTermsModal.yearlyPrepayment.headline,
    description: T.customerGuidance.purchase.flatTermsModal.yearlyPrepayment.description,
  },
  {
    headline: T.customerGuidance.purchase.flatTermsModal.excessEnergy.headline,
    description: T.customerGuidance.purchase.flatTermsModal.excessEnergy.description,
  },
  {
    headline: T.customerGuidance.purchase.flatTermsModal.excessTariff.headline,
    description: T.customerGuidance.purchase.flatTermsModal.excessTariff.description,
  },
  {
    headline: T.customerGuidance.purchase.flatTermsModal.expectedCashback.headline,
    description: T.customerGuidance.purchase.flatTermsModal.expectedCashback.description,
  },
  {
    headline: T.customerGuidance.purchase.flatTermsModal.expectedUnusedUsageAllowance.headline,
    description: T.customerGuidance.purchase.flatTermsModal.expectedUnusedUsageAllowance.description,
  },
  {
    headline: T.customerGuidance.purchase.flatTermsModal.cashbackRate.headline,
    description: T.customerGuidance.purchase.flatTermsModal.cashbackRate.description,
  },
  {
    headline: T.customerGuidance.purchase.flatTermsModal.vppBonus.headline,
    description: T.customerGuidance.purchase.flatTermsModal.vppBonus.description,
  },
];
