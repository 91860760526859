import { T } from '@sonnen/shared-i18n/customer';
import { ExpandableSection, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { GuidePurchaseHardwareOfferFooter } from '+guide/+purchase/containers';
import { Document, Offer } from '+guide/store/types';
import { ConfigurationHelper } from '+shared/helpers/configuration.helper';
import { GuidePurchaseOfferCard } from '../GuidePurchaseOfferCard';
import { GuidePurchaseProduct } from '../GuidePurchaseProduct';
import { pvCommissioningInTheFuture } from './GuidePurchaseHardwareOffer.helper';

import './GuidePurchaseHardwareOffer.component.scss';

interface Props {
  offer: Offer;
  hardwareOffers: Document[];
}

export const GuidePurchaseHardwareOffer: React.FC<Props> = ({ offer, hardwareOffers }) => (
  <div className={'c-guide-purchase-hardware-offer'}>
    <GuidePurchaseOfferCard
      header={'Partner Logo'}
    >
      <p className={'c-guide-purchase-hardware-offer__label'}>
        {I18n.t(T.customerGuidance.purchase.hardwareOffer.label)}
      </p>
      <div className={'c-guide-purchase-hardware-offer__products'}>
        {pvCommissioningInTheFuture(offer.configuration) && (
          <div className={'c-guide-purchase-hardware-offer__product'}>
            <GuidePurchaseProduct
              title={I18n.t(T.customerGuidance.purchase.pvSystem)}
              icon={<Icon.PV />}
              subtitle={`| ${I18n.t(T.customerGuidance.purchase.power)}: ${ConfigurationHelper.formatMeasurementPeakPower(offer.configuration.photovoltaicSystem.peakPower)}`}
            />
          </div>
        )}
        <div className={'c-guide-purchase-hardware-offer__product'}>
          <GuidePurchaseProduct
            title={'sonnenBatterie'}
            icon={<Icon.SonnenBattery />}
            subtitle={`| ${I18n.t(T.customerGuidance.purchase.capacity)}: ${ConfigurationHelper.formatMeasurementEnergy(offer.configuration.battery.capacityGross)}`}
          />
        </div>
        <ExpandableSection maxHeight={1500}>
          <GuidePurchaseHardwareOfferFooter
            offerId={offer.id}
            hardwareOffers={hardwareOffers}
          />
        </ExpandableSection>
      </div>
    </GuidePurchaseOfferCard>
  </div>
);
