import * as React from 'react';

import { InfographicSpec } from '../../spec/infographicSpec.types';
import '../InfographicIcon.util.scss';
import './InfographicIconHouse.component.scss';

interface Props {
  x: number;
  y: number;
}

/* tslint:disable:max-line-length */
export const InfographicIconHouse: React.FC<Props> = ({ x, y }) => (
  <svg
    x={x}
    y={y}
    viewBox={'0 0 550 262'}
    width={'550'}
    height={'262'}
    className={'c-infographic-icon-house'}
    data-test={InfographicSpec.INFOGRAPHIC_ICON_HOUSE}
  >
    <rect
      x={'266.9'}
      y={'70'}
      className={'c-infographic-icon-house__inside'}
      width={'270.5'}
      height={'166.5'}
    />
    <polygon
      className={'c-infographic-icon-house__border'}
      points={'266.9,236.5 537.3,236.5 537.3,70 266.9,70 '}
    />
    <path
      className={'c-infographic-icon-house__roof-shadow'}
      d={'M267,72v25c55.4-13.3,145.4-20,270-20v-5H267z'}
    />
    <path
      className={'c-infographic-icon-house__roof'}
      d={'M254.3,71.9c0.2,0.5,1.6,0.7,4.3,0.7h285.9c2.7,0,4.3-0.3,4.7-0.9c0.5-0.6-0.2-2.1-2.1-4.7l-35.5-54.8h-219l-36.3,55.5C254.8,70.1,254.1,71.4,254.3,71.9z'}
    />
  </svg>
);
