import * as React from 'react';
import { SendEvent, SendPageView } from './analytics.helper';

interface AnalyticsContext {
  sendEvent: SendEvent;
  sendPageView: SendPageView;
}

export const AnalyticsContext = React.createContext<AnalyticsContext>({
  sendEvent: () => void 0,
  sendPageView: () => void 0,
});
