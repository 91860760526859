import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { NestedNav } from '../NestedNav';
import { MobileSideNavNestedAccountAbout } from './MobileSideNavNestedAccountAbout';
import { MobileSideNavNestedAccountList } from './MobileSideNavNestedAccountList';
import { MobileSideNavNestedAccountPassword } from './MobileSideNavNestedAccountPassword';
import { MobileSideNavNestedAccountSettings } from './MobileSideNavNestedAccountSettings';

interface MobileSideNavNestedAccountProps {
  username: string | undefined;
}

export const MobileSideNavNestedAccount: React.FC<MobileSideNavNestedAccountProps> = ({ username }) => (
  <NestedNav
    icon={'Account'}
    title={username || I18n.t(T.general.navigation.myAccount)}
    subtitle={username && I18n.t(T.general.navigation.myAccount)}
    slides={[
      {
        icon: 'Account',
        title: I18n.t(T.general.navigation.myAccount),
        content: <MobileSideNavNestedAccountList />,
      },
      {
        icon: 'AboutMe',
        title: I18n.t(T.general.navigation.aboutMe),
        content: <MobileSideNavNestedAccountAbout />,
      },
      {
        icon: 'Padlock',
        title: I18n.t(T.general.navigation.changePassword),
        content: <MobileSideNavNestedAccountPassword />,
      },
      {
        icon: 'Settings',
        title: I18n.t(T.general.navigation.settings),
        content: <MobileSideNavNestedAccountSettings />,
      },
    ]}
  />
);
