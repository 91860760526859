import { T } from '@sonnen/shared-i18n/customer';
import { isNil } from 'lodash';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

import { Configuration } from '+app/+guide/store/types';
import { UtilityDataDesiredDeliveryForm } from '../../containers';
import { isFlatDirectConfiguration } from '../../store/+acceptance.helper';
import { UtilityChangePeriod } from '../GuideAcceptanceUtilityMoveHouse/GuideAcceptanceUtilityMoveHouse.helper';

export const desiredDeliveryFormInitial: UtilityDataDesiredDeliveryForm = {
  desiredDeliveryStartClosest: true,
  desiredDeliveryStartDate: null,
  isDateChosen: false,
};

export const calculateMinDeliveryDate = (configuration?: Configuration) => {
  const isFlatDirect = isFlatDirectConfiguration(configuration);

  const date = new Date();
  const daysOffset = 25;

  date.setHours(0, 0, 0, 0); // @NOTE: clear hours, mins, secs and milis
  date.setDate(date.getDate() + daysOffset);
  if (!isFlatDirect) {
    return date;
  }

  const flatDirectMinDate = new Date(2021, 1, 1);
  const maxDate = (date > flatDirectMinDate) ? date : flatDirectMinDate;

  return maxDate;
};

export const UtilityDataDesiredDeliveryDateSchema = Yup.object().shape({
  desiredDeliveryStartDate: Yup
    .mixed()
    .when('desiredDeliveryStartClosest', {
      is: false,
      then: Yup
        .date().min(calculateMinDeliveryDate(), () => I18n.t(T.customerGuidance.acceptance.errors.tooEarlyDate)),
    }),
  isDateChosen: Yup
    .boolean()
    .when('isMovingHouses', {
      is: UtilityChangePeriod.NOT_MOVED,
      then: Yup.boolean()
        .when(['desiredDeliveryStartClosest', 'desiredDeliveryStartDate'], {
          is: (desiredDeliveryStartClosest, desiredDeliveryStartDate) => {
            const isDesiredDeliveryStartDateEmpty = isNaN(desiredDeliveryStartDate) || isNil(desiredDeliveryStartDate);

            return desiredDeliveryStartClosest === false && isDesiredDeliveryStartDateEmpty;
          },
          then: Yup
            .boolean()
            .oneOf([true], I18n.t(T.customerGuidance.acceptance.requiredField)),
        }),
    }),
});
