import * as classNames from 'classnames';
import * as React from 'react';

import './BatteryIcon.component.scss';

interface Props {
  icon: React.ReactNode;
  label: string;
  isActive?: boolean;
}

export const BatteryIcon: React.SFC<Props> = ({ icon, label, isActive }) => (
  <div className={'c-battery-icon'}>
    <div className={'c-battery-icon__circle'}>
      <div className={'c-battery-icon__icon'}>
        {icon}
      </div>
    </div>
    <span className={classNames('c-battery-icon__status', {'c-battery-icon__status--light-green': isActive})} />
    <p className={'c-battery-icon__label'}>{label}</p>
  </div>
);
