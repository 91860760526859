import { Env, isMobile } from './environment';

interface AuthConfiguration {
  AUTH_URL: string;
  AUTH_CLIENT_ID: string;
  SALESFORCE_TECH_URL: string;
  SALESFORCE_CLIENT_ID: string;
}

interface ApiConfiguration {
  USER_DATA: string;
  USER_DATA_V2: string;
  MY_READING: string;
  FREQUENCY: string;
  DOCUMENTS: string;
  // TODO: Remove after rollback to new sonnenCommunity features
  CORE: string;
  SALES: string;
  SALES_LS: string;
  PRODUCTION_FORECAST: string;
  CONSUMPTION_FORECAST: string;
  PRODUCT_MIGRATION: string;
  CONTENT_HUB: string;
}

interface FirebaseConfiguration {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

interface GoogleTagManagerConfiguration {
  id: string;
  auth: string;
  preview: string;
}

export interface EnvConfiguration {
  ENVIRONMENT: string;
  IS_DEV: boolean;
  API: ApiConfiguration;
  AUTH: AuthConfiguration;
  // TODO: remove this config variable and GoggleTagScript when new logic tested
  GOOGLE_TAG_MANAGER_ID: string | null;
  GOOGLE_TAG_MANAGER: GoogleTagManagerConfiguration;
  GOOGLE_ANALYTICS_TRACKING_ID: string | null;
  FIREBASE: FirebaseConfiguration;
}

const getPlatformSpecificValue = <T extends Record<'MOBILE' | 'DESKTOP', any>>(object: T) =>
  isMobile ? object.MOBILE : object.DESKTOP;

export const getEnvConfig = (env: Env): EnvConfiguration => {
  switch (env) {
    case Env.DEVELOPMENT:
      return {
        ENVIRONMENT: env,
        IS_DEV: true,
        API: {
          USER_DATA: 'https://api.integration.sonnen.de/v1',
          USER_DATA_V2: 'https://api.integration.sonnen.de/v2',
          FREQUENCY: 'https://frequencies.sonnen.de/v1',
          DOCUMENTS: 'https://documents.sonnen.de/v1',
          // TODO: Remove after rollback to new sonnenCommunity features
          CORE: 'https://core-api.sonnenbatterie.de',
          SALES: 'https://api-dev.sales.staging.sonnen.de/api/v1',
          SALES_LS: 'https://api-dev.sales.staging.sonnen.de/api/rc',
          PRODUCTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/production',
          CONSUMPTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/consumption',
          PRODUCT_MIGRATION: 'https://api-dev.product-migration.sales.staging.sonnen.de/api',
          MY_READING: 'https://zgy0xplfv1.execute-api.eu-central-1.amazonaws.com/dev/meter-reading',
          CONTENT_HUB: 'https://develop.api.sonnen.reinblau.dev/v1',
        },
        AUTH: {
          AUTH_URL: 'https://account.integration.sonnen.de',
          AUTH_CLIENT_ID: getPlatformSpecificValue({
            MOBILE: '6a0c465ace5a0e3e184fe6cdfeff384ce855d09d60cc75d6755f11594e9b558b',
            DESKTOP: 'e132b5e28ca483042f5219f999cbdaa3be2470b34fdbf1b47c552f9a92e75c8f',
          }),
          SALESFORCE_TECH_URL: 'https://sonnen--extern2.sandbox.my.salesforce.com',
          SALESFORCE_CLIENT_ID: '3MVG9sSN_PMn8tjSodyD28jSJp5xDSUQiuM9Ma8oUj_M9jERv4Cf4TvUXzjU9Vqj81B6f9z6NW1mZsa5HfYz7',
        },
        GOOGLE_ANALYTICS_TRACKING_ID: 'UA-25992474-40',
        GOOGLE_TAG_MANAGER_ID: null,
        GOOGLE_TAG_MANAGER: {
          id: 'GTM-WPFMNML',
          auth: '6svc6vKNO9JaebrLoasEGw',
          preview: 'env-1',
        },
        FIREBASE: {
          apiKey: 'AIzaSyDePKl0CYjVDl8p52aWO0lcaWZE3PXrs-M',
          authDomain: 'sonnenportal-dev.firebaseapp.com',
          databaseURL: 'https://sonnenportal-dev.firebaseio.com',
          projectId: 'sonnenportal-dev',
          storageBucket: 'sonnenportal-dev.appspot.com',
          messagingSenderId: '215679461555',
          appId: '1:215679461555:web:a2375cc12273c091b428c6',
        },
      };
    case Env.LOCAL:
      return {
        ENVIRONMENT: env,
        IS_DEV: true,
        API: {
          USER_DATA: 'http://localhost:4000/v1',
          USER_DATA_V2: 'http://localhost:4000/v2',
          FREQUENCY: 'https://frequencies.sonnen.de/v1',
          DOCUMENTS: 'https://documents.sonnen.de/v1',
          // TODO: Remove after rollback to new sonnenCommunity features
          CORE: 'https://core-api.sonnenbatterie.de',
          SALES: 'https://api-dev.sales.staging.sonnen.de/api/v1',
          SALES_LS: 'https://api-dev.sales.staging.sonnen.de/api/rc',
          PRODUCTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/production',
          CONSUMPTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/consumption',
          PRODUCT_MIGRATION: 'https://api-dev.product-migration.sales.staging.sonnen.de/api',
          MY_READING: 'https://zgy0xplfv1.execute-api.eu-central-1.amazonaws.com/dev/meter-reading',
          CONTENT_HUB: 'https://develop.api.sonnen.reinblau.dev/v1',
        },
        AUTH: {
          AUTH_URL: 'http://localhost:4000',
          AUTH_CLIENT_ID: getPlatformSpecificValue({
            MOBILE: '6a0c465ace5a0e3e184fe6cdfeff384ce855d09d60cc75d6755f11594e9b558b',
            DESKTOP: 'e132b5e28ca483042f5219f999cbdaa3be2470b34fdbf1b47c552f9a92e75c8f',
          }),
          SALESFORCE_TECH_URL: 'https://sonnen--extern2.sandbox.my.salesforce.com',
          SALESFORCE_CLIENT_ID: '3MVG9sSN_PMn8tjSodyD28jSJp5xDSUQiuM9Ma8oUj_M9jERv4Cf4TvUXzjU9Vqj81B6f9z6NW1mZsa5HfYz7',
        },
        GOOGLE_ANALYTICS_TRACKING_ID: 'UA-25992474-40',
        GOOGLE_TAG_MANAGER_ID: null,
        GOOGLE_TAG_MANAGER: {
          id: 'GTM-WPFMNML',
          auth: '6svc6vKNO9JaebrLoasEGw',
          preview: 'env-1',
        },
        FIREBASE: {
          apiKey: 'AIzaSyDePKl0CYjVDl8p52aWO0lcaWZE3PXrs-M',
          authDomain: 'sonnenportal-dev.firebaseapp.com',
          databaseURL: 'https://sonnenportal-dev.firebaseio.com',
          projectId: 'sonnenportal-dev',
          storageBucket: 'sonnenportal-dev.appspot.com',
          messagingSenderId: '215679461555',
          appId: '1:215679461555:web:a2375cc12273c091b428c6',
        },
      };
    case Env.STAGING:
      return {
        ENVIRONMENT: env,
        IS_DEV: false,
        API: {
          USER_DATA: 'https://api.staging.sonnen.de/v1',
          USER_DATA_V2: 'https://api.staging.sonnen.de/v2',
          FREQUENCY: 'https://frequencies.sonnen.de/v1',
          DOCUMENTS: 'https://documents.sonnen.de/v1',
          // TODO: Remove after rollback to new sonnenCommunity features
          CORE: 'https://core-api.sonnenbatterie.de',
          SALES: 'https://api.sales.staging.sonnen.de/api/v1',
          SALES_LS: 'https://api.sales.staging.sonnen.de/api/rc',
          PRODUCTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/production',
          CONSUMPTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/consumption',
          PRODUCT_MIGRATION: 'https://api.product-migration.sales.staging.sonnen.de/api',
          MY_READING: 'https://apigw.staging.sonnen.de/meter-reading',
          CONTENT_HUB: 'https://develop.api.sonnen.reinblau.dev/v1',
        },
        AUTH: {
          AUTH_URL: 'https://account.staging.sonnen.de',
          AUTH_CLIENT_ID: getPlatformSpecificValue({
            MOBILE: '4ODK4VfuYi3WbGRqWJAZSB1Vlk9kMGQs8am711N3-Eo',
            DESKTOP: '728e20a3007838d648d62a2e602957696ed3d8ad9ae0dde50ec45ca569ae7c25',
          }),
          SALESFORCE_TECH_URL: 'https://sonnen--stageuat.sandbox.my.salesforce.com',
          SALESFORCE_CLIENT_ID: '3MVG9Lu3LaaTCEgJ1gvddPIi1Txr5tMHIRzdV1QEYOoSFyO_2KF.VMh5jy6Q61Ec.Kx.Esaj1fQETSikmg19q',
        },
        GOOGLE_ANALYTICS_TRACKING_ID: 'UA-25992474-40',
        GOOGLE_TAG_MANAGER_ID: null,
        GOOGLE_TAG_MANAGER: {
          id: 'GTM-5GBLFKL',
          auth: 'Dcp3f7zQae91f1aImfiAHw',
          preview: 'env-17',
        },
        FIREBASE: {
          apiKey: 'AIzaSyDePKl0CYjVDl8p52aWO0lcaWZE3PXrs-M',
          authDomain: 'sonnenportal-dev.firebaseapp.com',
          databaseURL: 'https://sonnenportal-dev.firebaseio.com',
          projectId: 'sonnenportal-dev',
          storageBucket: 'sonnenportal-dev.appspot.com',
          messagingSenderId: '215679461555',
          appId: '1:215679461555:web:a2375cc12273c091b428c6',
        },
      };
    case Env.DEMO:
      return {
        ENVIRONMENT: env,
        IS_DEV: false,
        API: {
          USER_DATA: 'https://api.staging.sonnen.de/v1',
          USER_DATA_V2: 'https://api.staging.sonnen.de/v2',
          FREQUENCY: 'https://frequencies.sonnen.de/v1',
          DOCUMENTS: 'https://documents.sonnen.de/v1',
          // TODO: Remove after rollback to new sonnenCommunity features
          CORE: 'https://core-api.sonnenbatterie.de',
          SALES: 'https://api-demo.sales.staging.sonnen.de/api/v1',
          SALES_LS: 'https://api-demo.sales.staging.sonnen.de/api/rc',
          PRODUCTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/production',
          CONSUMPTION_FORECAST: 'https://apigw.staging.sonnen.de/forecast/consumption',
          PRODUCT_MIGRATION: 'https://api-dev.product-migration.sales.staging.sonnen.de/api',
          MY_READING: 'https://apigw.staging.sonnen.de/meter-reading',
          CONTENT_HUB: 'https://develop.api.sonnen.reinblau.dev/v1',
        },
        AUTH: {
          AUTH_URL: 'https://account.staging.sonnen.de',
          AUTH_CLIENT_ID: getPlatformSpecificValue({
            MOBILE: '6a0c465ace5a0e3e184fe6cdfeff384ce855d09d60cc75d6755f11594e9b558b',
            DESKTOP: 'e132b5e28ca483042f5219f999cbdaa3be2470b34fdbf1b47c552f9a92e75c8f',
          }),
          SALESFORCE_TECH_URL: 'https://sonnen--external.sandbox.my.salesforce.com',
          SALESFORCE_CLIENT_ID: '3MVG9lcxCTdG2VbtWAnBLcHTY.4_qll7_ezHx.0doqoQSR0z95qrKasbCJufPizdI9KBTAgXUR_LwmXOcnjLe',
        },
        GOOGLE_ANALYTICS_TRACKING_ID: null,
        GOOGLE_TAG_MANAGER_ID: null,
        GOOGLE_TAG_MANAGER: {
          id: 'GTM-5GBLFKL',
          auth: 'gtPGtp2vO1ICSv5W1h3XJg',
          preview: 'env-2',
        },
        FIREBASE: {
          apiKey: 'AIzaSyDePKl0CYjVDl8p52aWO0lcaWZE3PXrs-M',
          authDomain: 'sonnenportal-dev.firebaseapp.com',
          databaseURL: 'https://sonnenportal-dev.firebaseio.com',
          projectId: 'sonnenportal-dev',
          storageBucket: 'sonnenportal-dev.appspot.com',
          messagingSenderId: '215679461555',
          appId: '1:215679461555:web:a2375cc12273c091b428c6',
        },
      };
    case Env.PRODUCTION:
    default:
      return {
        ENVIRONMENT: env,
        IS_DEV: false,
        API: {
          USER_DATA: 'https://my-api.sonnen.de/v1',
          USER_DATA_V2: 'https://my-api.sonnen.de/v2',
          FREQUENCY: 'https://frequencies.sonnen.de/v1',
          DOCUMENTS: 'https://documents.sonnen.de/v1',
          // TODO: Remove after rollback to new sonnenCommunity features
          CORE: 'https://core-api.sonnenbatterie.de',
          SALES: 'https://api.sales.sonnen.de/api/v1',
          SALES_LS: 'https://api.sales.sonnen.de/api/rc',
          PRODUCTION_FORECAST: 'https://apigw.sonnen.de/forecast/production',
          CONSUMPTION_FORECAST: 'https://apigw.sonnen.de/forecast/consumption',
          PRODUCT_MIGRATION: 'https://api.product-migration.sales.sonnen.de/api',
          MY_READING: 'https://apigw.sonnen.de/meter-reading',
          CONTENT_HUB: 'https://develop.api.sonnen.reinblau.dev/v1',
        },
        AUTH: {
          AUTH_URL: 'https://account.sonnen.de',
          AUTH_CLIENT_ID: getPlatformSpecificValue({
            MOBILE: '83c9c75dd80fcd6a8710d12572b79878a547e2c1c78655befc2d716d4c6b2eba',
            DESKTOP: 'a80a9abfd8c2847370340c96a04d2af7c691cce4c7cac58d8e12012f5da9280c',
          }),
          SALESFORCE_TECH_URL: 'https://sonnen.my.salesforce.com',
          SALESFORCE_CLIENT_ID: '3MVG9Rd3qC6oMalVMVGU7MyHfOBxhBIXLyWeI5nPxDktnWBABTuOJPVK3YHOieLpiDlJvQxIMawvzMfxZ_E6w',
        },
        GOOGLE_ANALYTICS_TRACKING_ID: 'UA-25992474-21',
        GOOGLE_TAG_MANAGER_ID: 'GTM-5GBLFKL',
        GOOGLE_TAG_MANAGER: {
          id: 'GTM-5GBLFKL',
          auth: 'gtPGtp2vO1ICSv5W1h3XJg',
          preview: 'env-2',
        },
        FIREBASE: {
          apiKey: 'AIzaSyApNzzuJlabXzCg9CQRUaYw8PR2zGbgVKc',
          authDomain: 'sonnenportal-production.firebaseapp.com',
          databaseURL: 'https://sonnenportal-production.firebaseio.com',
          projectId: 'sonnenportal-production',
          storageBucket: 'sonnenportal-production.appspot.com',
          messagingSenderId: '445947885271',
          appId: '1:445947885271:web:3235b7a224b7eb4cf0e292',
        },
      };
  }
};
