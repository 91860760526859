import {
  ConsumptionUserReading,
  FeedInUserReading,
} from '+user-readings/components/UserReadingsForm/types/UserReadingsForm.interface';
import { Query } from '../query/query.state';
import { MeterInstallation } from './types/meterInstallation.interface';

export const GET_METER_INSTALLATION_QUERY = 'getMeterInstallationQuery';
export const SEND_CONSUMPTION_USER_READINGS_QUERY =
  'sendConsumptionUserReadings';
export const SEND_FEEDIN_USER_READINGS_QUERY = 'sendFeedInUserReadings';

export interface MeterState {
  meterInstallationData: MeterInstallation | undefined;
  [GET_METER_INSTALLATION_QUERY]: Query<MeterInstallation>;
  [SEND_CONSUMPTION_USER_READINGS_QUERY]: Query<ConsumptionUserReading>;
  [SEND_FEEDIN_USER_READINGS_QUERY]: Query<FeedInUserReading>;
}

export const initialState: MeterState = {
  meterInstallationData: undefined,
  [GET_METER_INSTALLATION_QUERY]: {},
  [SEND_CONSUMPTION_USER_READINGS_QUERY]: {},
  [SEND_FEEDIN_USER_READINGS_QUERY]: {},
};
