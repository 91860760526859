import { Icon } from '@sonnen/shared-web';
import * as React from 'react';

import { GuideTableRow } from '../GuideTableRow';

import './GuideDocumentEntry.component.scss';

interface Props {
  name: React.ReactNode;
  date?: React.ReactNode;
  author?: React.ReactNode;
  onDownload: () => void;
}

export const GuideDocumentEntry: React.FC<Props> = ({
  name,
  date,
  author,
  onDownload,
}) => (
  <div className={'c-guide-document-entry'}>
    <GuideTableRow
      content={(
        <div className={'c-guide-document-entry__text c-guide-document-entry__text--em'}>
          {name}
        </div>
      )}
      secondaryContent={(
        <div className={'c-guide-document-entry__text'}>
          {date}
        </div>
      )}
      tertiaryContent={(
        <div className={'c-guide-document-entry__text'}>
          {author}
        </div>
      )}
      side={(
        <div className={'c-guide-document-entry__side'}>
          <button onClick={onDownload}>
            <Icon.Eye className={'c-guide-document-entry__button-icon'} />
          </button>
        </div>
      )}
      isCompactOnMobiles={true}
    />
  </div>
);
