import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './Checkmark.component.scss';

interface CheckmarkProps {
  size?: number;
  isActive?: boolean;
  hasIcon?: boolean;
}

export const Checkmark: React.FC<CheckmarkProps> = ({
  size = 32,
  isActive = true,
  hasIcon = true,
}) => (
  <div
    className={classNames('c-checkmark', {
      'c-checkmark--active': isActive,
    })}
    style={{
      '--checkmark-size': size,
    } as React.CSSProperties}
  >
    {hasIcon && (
      <Icon.Checkmark className={'c-checkmark__icon'} />
    )}
  </div>
);
