import { T } from '@sonnen/shared-i18n/customer';
import { CountryCode, TimeHelper } from '@sonnen/shared-web';
import { head, isNil } from 'lodash/fp';
import * as moment from 'moment-timezone';
import { I18n } from 'react-redux-i18n';

import { countryOptions } from '+app/constants/countryOptions.constant';
import { BackupDeviceType, Battery, BatteryControllerType } from '+battery/store/types/battery.interface';
import { getFormattedDate } from '+legacy/helpers/dates';
import { getKiloPowerObj } from '+legacy/helpers/numbers';
import { trimString } from '+legacy/helpers/strings';
import { getFormattedTemperature } from '+legacy/helpers/temperature';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { DateUtil } from '+utils/date.util';
import { BatteryState } from './battery.state';

const EMERGENCY_MODE_COUNTRIES = [
  CountryCode.US,
];

const EMERGENCY_MODE_BACKUP_VALUES = {
  default: 10,
  max: 100,
};

const DEFAULT_BATTERY_PRODUCT_NAME = 'sonnenBatterie';

const isAccessoryAvailableInBatteryCountry = ({ installationCountryCode, hardwareVersion }: Battery) => {
  // @INFO Background in SON-23752 ticket
  switch (installationCountryCode) {
    case CountryCode.US:
      return false;
    case CountryCode.AU:
    case CountryCode.NZ:
      if (hardwareVersion === '10.0') {
        return false;
      }
      return true;
    default:
      return true;
  }
};

const isHeaterConnected = (batteryData: Battery | undefined) =>
  batteryData && batteryData.heaterConnectionStatus;

const isBackupBoxConnected = (batteryData: Battery | undefined) =>
  batteryData
  && batteryData.backupDeviceType
  && batteryData.backupDeviceType !== BackupDeviceType.NONE
  && !isNil(batteryData.backupPowerBuffer)
  ;

const getHeaterWaterTemperature = (liveData: SiteLive | undefined) =>
  liveData?.online && liveData.heaterWaterTemp
    ? getFormattedTemperature(liveData.heaterWaterTemp)
    : '-';

const getHeaterPower = (liveData: SiteLive | undefined) => {
  const power = liveData && getKiloPowerObj(liveData.heaterPower);
  return power ? `${power.value} ${power.unit}` : '0 W';
};

const getInverterPower = (batteryData: Battery | undefined) => {
  const power = batteryData && getKiloPowerObj(batteryData.batteryInverterDesignPower);
  return power ? `${power.value} ${power.unit}` : '0 W';
};

const getHeaterMaxWaterTemperature = (batteryData: Battery) =>
  batteryData.heaterMaxTemperature
    ? getFormattedTemperature(batteryData.heaterMaxTemperature)
    : '-';

const getBackupNominalPower = (batteryData: Battery) => {
  const power = getKiloPowerObj(batteryData.backupNominalPower);
  return batteryData.backupNominalPower ? `${power.value} ${power.unit}` : '0 W';
};

const getBackupLevel = (batteryData: Battery) =>
  batteryData.backupPowerBuffer
    ? `${batteryData.backupPowerBuffer}%`
    : '0%';

const getLastPowerOutageDate = (batteryData: Battery) =>
  batteryData.lastPowerOutageAt
    ? getFormattedDate(moment(batteryData.lastPowerOutageAt).format('YYYY-MM-DD'))
    : '-';

const getWarrantyPeriod = (batteryData: Battery | undefined) => {
  if (
    !batteryData ||
    !batteryData.warrantyPeriod ||
    batteryData.warrantyPeriod.match(/ibp_missing|expired|null/ig)
  ) {
    return;
  }

  const match = batteryData.warrantyPeriod.split(/_/)[0].match(/\d+/);

  return trimString`
    ${match ? match[0] : ''}
    ${I18n.t(T.batterySystem.details.sections.specs.parts.guaranteeYears)}
  `;
};

const getInstallationLocation = (batteryData: Battery | undefined) => {
  if (!batteryData) { return; }

  const country = head(countryOptions.filter(
    c => c.value === batteryData.installationCountryCode,
  ));
  const countryName = country ? country.label : '';

  return trimString`
    ${batteryData.installationStreet || ''},
    ${batteryData.installationPostalCode || ''}
    ${batteryData.installationCity || ''},
    ${countryName}
  `;
};

const getInstallerAddress = (batteryData: Battery | undefined) =>
  batteryData && (
    batteryData.installerStreet
    || batteryData.installerPostalCode
    || batteryData.installerCity
    || batteryData.installerCountry
  ) ? trimString`
      ${batteryData.installerStreet || ''},
      ${batteryData.installerPostalCode || ''}
      ${batteryData.installerCity || ''},
      ${batteryData.installerCountry || ''}`
    : undefined;

const isDataDelayed = (date: Date, batteryType?: string, minutesThreshold?: number) => {
  const batteryThreshold: number = minutesThreshold || batteryType === BatteryControllerType.EATON
    ? 30
    : 5;

  return TimeHelper.isDelayed(date, TimeHelper.getMillisecondsFromMinutes(batteryThreshold));
};

const isEaton = (batteryData: Battery | undefined) =>
  !!(batteryData && batteryData.controllerType === BatteryControllerType.EATON);

const isSpree = (batteryData: Battery | undefined) =>
  !!(batteryData && batteryData.controllerType === BatteryControllerType.SPREE);

const concatLanguageCode = (value: string) =>
  (value[0] && value[1]) ? `${value[0]}${value[1]}` : '';

const getArticleNumber = (batteryData: Battery | undefined) =>
  batteryData && batteryData.articleNumber;

const languagesOptions = [
  {
    value: 'en',
    label: T.general.language.english,
  },
  {
    value: 'de',
    label: T.general.language.german,
  },
  {
    value: 'it',
    label: T.general.language.italian,
  },
];

const isBackupBufferSet = (batteryId: string, batteryState: BatteryState) => {
  const batteryBackupBuffer = batteryState.backupBuffer && batteryState.backupBuffer[batteryId];

  if (isNil(batteryBackupBuffer)) {
    return true;
  }

  const { processingValue } = batteryBackupBuffer;
  const currentValue = batteryState.data.backupPowerBuffer;

  return processingValue === currentValue;
};

const hasProcessingTimeExceededDuration = (processingStart: Date | undefined, maxDuration: number) =>
  moment.duration(moment().diff(processingStart)).asSeconds() >= maxDuration;

export type DateRangeInBatteryTimezone = { period: 'day' | 'month' | 'year' };

const getBatteryProductName = (battery: Battery | undefined) =>
  battery?.product?.quoteNameDe || DEFAULT_BATTERY_PRODUCT_NAME;

const getDateRangeInBatteryTimezone = (
  selectedDate: moment.MomentInput,
  batteryTimezone: string,
  options: DateRangeInBatteryTimezone,
): { start: string, end: string } => {
  const dateInProperTimezone = options.period === 'day'
    ? DateUtil.getDateInTimezone(selectedDate, batteryTimezone)
    : moment(selectedDate);

  const startDate = moment(dateInProperTimezone).startOf(options.period);
  const startTimezone = moment(DateUtil.getDateInTimezone(startDate, batteryTimezone)).format('Z');

  const endDate = moment(dateInProperTimezone).endOf(options.period);
  const endTimezone = moment(DateUtil.getDateInTimezone(endDate, batteryTimezone)).format('Z');

  const start = startDate.format('YYYY-MM-DDTHH:mm:ss') + startTimezone;
  const end = endDate.format('YYYY-MM-DDTHH:mm:ss') + endTimezone;

  return { start, end };
};

// @NOTE sonnenModule 2500 and LFP2 are the same cell type
const isCellTypeSonnenModule2500 = (cellType?: string | null) => cellType === 'sonnenModule 2500';

const getBatteryModuleType = (batteryData: Battery | undefined) => {
  if (!batteryData?.batteryModuleType) {
    return null;
  }
  if (batteryData.batteryModuleType === 'LFP1') {
    return 'sonnenModule 1';
  }
  if (batteryData.batteryModuleType === 'LFP2') {
    return 'sonnenModule 2';
  }
  if (batteryData.batteryModuleType === 'LFP4') {
    return 'sonnenModule 3';
  }
  if (batteryData.batteryModuleType === 'LFP5') {
    return 'sonnenModule 4';
  }
  return null;
};

export const BatteryHelper = {
  isHeaterConnected,
  isBackupBoxConnected,
  isBackupBufferSet,
  isEaton,
  isSpree,
  isDataDelayed,
  EMERGENCY_MODE_BACKUP_VALUES,
  EMERGENCY_MODE_COUNTRIES,
  getHeaterPower,
  getHeaterWaterTemperature,
  getHeaterMaxWaterTemperature,
  getBackupNominalPower,
  getBackupLevel,
  getInverterPower,
  getLastPowerOutageDate,
  getWarrantyPeriod,
  getInstallationLocation,
  getInstallerAddress,
  concatLanguageCode,
  getArticleNumber,
  languagesOptions,
  hasProcessingTimeExceededDuration,
  getDateRangeInBatteryTimezone,
  getBatteryProductName,
  isCellTypeSonnenModule2500,
  isAccessoryAvailableInBatteryCountry,
  getBatteryModuleType,
};
