import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, ButtonTheme, Icon, InfoBanner } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './CommunityMapError.component.scss';

interface Props {
  className?: string; // @TODO: Delete
  onClick: () => void;
}

export const CommunityMapError: React.FC<Props> = ({ className, onClick }) => (
  <div className={classNames('c-community-map-error', className)}>
    <InfoBanner
      icon={<Icon.Ufo/>}
      title={I18n.t(T.sonnenCommunity.errors.mapTitle)}
      subtitle={I18n.t(T.sonnenCommunity.errors.mapSubTitle)}
    />
    <Button
      label={I18n.t(T.sonnenCommunity.errors.mapButtonLabel)}
      onClick={onClick}
      size={ButtonSize.SECONDARY}
      theme={ButtonTheme.OUTLINE}
    />
  </div>
);
