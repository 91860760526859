import * as classNames from 'classnames';
import * as React from 'react';

import '../InfographicIcon.util.scss';

interface Props {
  x: number;
  y: number;
  isActive?: boolean;
  onClick: () => void;
}

/* tslint:disable:max-line-length */
export const InfographicIconBattery: React.FC<Props> = ({
  x,
  y,
  onClick,
  isActive,
}) => (
  <svg className={classNames('u-infographic-icon u-infographic-icon__hover', {
      'is-active': isActive,
    })}
    x={x}
    y={y}
    viewBox={'0 0 80 86'}
    width={'61'}
    height={'64'}
    onClick={onClick}
    data-test-id={'dashboard-infographic-icon-battery'}
  >
    <path
      className={'u-infographic-icon__fill'}
      d={'M72.3,5.6H9c-2,0-3.6,1.6-3.6,3.6v68.1c0,2,1.6,3.6,3.6,3.6h63.3c2,0,3.6-1.6,3.6-3.6V9.2C75.9,7.2,74.2,5.6,72.3,5.6z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M72.6,5.1H8.5c-2,0-3.8,1.6-3.8,3.8v69c0,2,1.6,3.8,3.8,3.8h64.1c2,0,3.8-1.6,3.8-3.8v-69C76.4,6.7,74.7,5.1,72.6,5.1z M73.9,77.7c0,0.7-0.5,1.3-1.3,1.3H8.5c-0.7,0-1.3-0.5-1.3-1.3v-69c0-0.7,0.5-1.3,1.3-1.3h64.1c0.7,0,1.3,0.5,1.3,1.3V77.7L73.9,77.7z M40.6,22.5c-4.8,0-8.5,3.8-8.5,8.5s3.9,8.5,8.5,8.5s8.5-3.8,8.5-8.5S45.4,22.5,40.6,22.5z M40.6,37.2c-3.4,0-6.4-2.8-6.4-6.2s2.8-6.2,6.4-6.2c3.4,0,6.4,2.8,6.4,6.2C47,34.4,44,37.2,40.6,37.2z M50.4,47.1H30.8c-0.7,0-1.3-0.5-1.3-1.1s0.5-1.3,1.3-1.3h19.7c0.7,0,1.1,0.5,1.3,1.1C51.8,46.4,51.1,47.1,50.4,47.1z'}
    />
  </svg>
);
