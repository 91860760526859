import { combineSagas } from '+utils/saga';
import { SagaIterator } from 'redux-saga';
import { put, takeEvery } from 'redux-saga/effects';
import { ROUTER_ACTIONS, RouterActions } from './router.actions';

function * routerIsReady(action: ReturnType<typeof RouterActions.locationChange>): SagaIterator {
  return yield put(RouterActions.isReady(action.type === ROUTER_ACTIONS.LOCATION_CHANGE));
}

export const sagas = combineSagas(
  takeEvery(ROUTER_ACTIONS.LOCATION_CHANGE, routerIsReady),
);
