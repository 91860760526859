import { getDistributionToPartnerStepStatus } from '+app/+guide/+discovery/store/+discovery.selectors';
import { GuideGridStretched, GuideInfoCard } from '+app/+guide/components';
import { GuideAccordion } from '+app/+guide/containers';
import { GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StepName } from '+app/+guide/helpers/steps.config';
import { getGuideLeadPartner } from '+app/+guide/store/+lead.selectors';
import { StoreState } from '+app/store/store.interface';

import { T } from '@sonnen/shared-i18n/customer';
import { Icon, Link } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './GuideDiscoveryStepDistribution.component.scss';

const mapStateToProps = (state: StoreState) => ({
  stepStatus: getDistributionToPartnerStepStatus(state),
  partner: getGuideLeadPartner(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & RouteComponentProps
  ;

export const GuideDiscoveryStepDistributionComponent: React.FC<Props> = ({
  stepStatus,
  partner,
}) => {
  const content = {
    [GuideStepStatus.ACTIVE]: {
      headline: I18n.t(T.customerGuidance.planning.step.distribution.active.headline),
      tag: `${I18n.t(T.customerGuidance.toDoBy, {name: 'sonnen'})}`,
      status: 'open',
    },
    [GuideStepStatus.DONE]: {
      headline: I18n.t(T.customerGuidance.planning.step.distribution.done.headline),
      tag: I18n.t(T.customerGuidance.tag.done),
      // @TODO Add date as soon as available
      description: I18n.t(T.customerGuidance.byName, {name: 'sonnen'}),
      status: 'done',
    },
  };

  const partnerWebsiteLink = partner?.website?.match(/^(http|https):\/\//)
    ? partner.website
    : `https://${partner?.website}`;

  return (
    <GuideAccordion
      status={stepStatus}
      anchor={StepName.DISTRIBUTION_TO_PARTNER}
      title={content[stepStatus].headline}
      tag={content[stepStatus].tag}
      tagTheme={content[stepStatus].status}
      description={content[stepStatus].description}
    >
      {{
        active: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.planning.step.distribution.information.headline)}
              headerIcon={<Icon.Info />}
            >
              {I18n.t(T.customerGuidance.planning.step.distribution.information.description.active)}
            </GuideInfoCard>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.planning.step.distribution.yourToDo.headline)}
              headerIcon={<Icon.Contract />}
              isIconBig={true}
            >
              {I18n.t(T.customerGuidance.planning.step.distribution.yourToDo.description.active)}
              <p className={'c-guide-discovery-step-distribution__link'}>
                <Link
                  href={'https://sonnen.de/wissen'}
                  label={I18n.t(T.customerGuidance.planning.step.distribution.link.readMore)}
                />
              </p>
            </GuideInfoCard>
          </GuideGridStretched>
        ),
        done: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.planning.step.distribution.information.headline)}
              headerIcon={<Icon.Info />}
            >
              {I18n.t(T.customerGuidance.planning.step.distribution.information.description.done)}
            </GuideInfoCard>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.planning.step.distribution.yourToDo.headline)}
              headerIcon={<Icon.Contract />}
              isIconBig={true}
            >
              {I18n.t(T.customerGuidance.planning.step.distribution.yourToDo.description.done)}
            </GuideInfoCard>
            {partner && <GuideInfoCard
              header={I18n.t(T.customerGuidance.planning.step.distribution.partner.headline)}
              headerIcon={<Icon.Account />}
            >
                <p className={'c-guide-discovery-step-distribution__company'}>
                  {partner.name}
                </p>
                {partner.employee &&
                  I18n.t(T.customerGuidance.planning.step.distribution.partner.employeeName,
                    {fullName: `${partner.employee.firstName} ${partner.employee.lastName}`})}
                <br />
                {partner.name &&
                  I18n.t(T.customerGuidance.planning.step.distribution.partner.phone, {phoneNumber: partner.phone})}
                <br />
                {partner.email &&
                  I18n.t(T.customerGuidance.planning.step.distribution.partner.email, {email: partner.email})}
                <br />
                {partner.website &&
                  <Link
                    href={partnerWebsiteLink}
                    label={partner.website}
                  />
                }
            </GuideInfoCard>}
          </GuideGridStretched>
        ),
      }}
    </GuideAccordion>
  );
};

export const GuideDiscoveryStepDistribution =
  withRouter(connect(mapStateToProps)(GuideDiscoveryStepDistributionComponent));
