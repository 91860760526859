import * as classNames from 'classnames';
import * as React from 'react';

import '../InfographicIcon.util.scss';

interface Props {
  x: number;
  y: number;
  isActive?: boolean;
  onClick: () => void;
}

/* tslint:disable:max-line-length */
export const InfographicIconBackupBox: React.FC<Props> = ({
  x,
  y,
  onClick,
  isActive,
}) => (
  <svg
    className={classNames('u-infographic-icon u-infographic-icon__hover', { 'is-active': isActive })}
    x={x}
    y={y}
    viewBox={'0 0 94 98'}
    width={'71'}
    height={'74'}
    onClick={onClick}
    data-test-id={'dashboard-infographic-icon-backup-box'}
  >
    <path
      className={'u-infographic-icon__fill'}
      d={'M86.9,49.2c-4.1,0-8.1-1.8-10.9-5V9.3c0-2.1-1.7-3.8-3.8-3.8H9c-2.1,0-3.8,1.7-3.8,3.8v67.5c0,2.1,1.7,3.8,3.8,3.8h38.4c4,5.7,9.8,9.9,16.4,11.8c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.3,0l1-0.3c13.3-3.7,22.6-15.8,22.6-29.5V50.5C88.2,49.8,87.6,49.2,86.9,49.2z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M87.4,49.2c-4.2,0-8.2-1.9-11-5.1V8.7c0-2.1-1.8-3.9-3.9-3.9H8.6c-2.1,0-3.9,1.7-3.9,3.9v68.5c0,2.1,1.7,3.9,3.9,3.9h38.9c4.1,5.8,10,10,16.6,12c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.3,0l1-0.3c13.5-3.7,22.9-16.1,22.9-30V50.5C88.7,49.8,88.1,49.2,87.4,49.2z M73.5,39.2c-0.1-0.2-0.2-0.5-0.2-0.8c-0.2-0.5-0.7-0.9-1.3-0.9H58.4c-0.6,0-1.1,0.4-1.3,0.9c-1.8,6.3-7.6,10.7-14.1,10.7c-0.7,0-1.3,0.6-1.3,1.3v12.8c0,5.2,1.3,10.4,3.9,14.9H8.6c-0.6,0-1-0.5-1-1V8.7c0-0.6,0.5-1,1-1h63.9c0.6,0,1,0.5,1,1V39.2z M44.4,51.7c3.3-0.3,6.5-1.5,9.1-3.6c2.7-2.1,4.7-4.9,5.8-8.1h11.7c1.1,3.2,3.1,6,5.8,8.1c2.7,2.1,5.8,3.3,9.2,3.6v10.9h0v0.2c0,12.8-8.6,24-21,27.5l-0.6,0.2c-2.9-0.9-5.6-2.3-8.1-4c-2.4-1.7-4.6-3.9-6.4-6.3c-1.8-2.4-3.2-5.1-4.1-7.9c-1-2.9-1.5-5.9-1.5-9L44.4,51.7L44.4,51.7z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M60.5,73.8c0.2,0.2,0.6,0.4,0.9,0.4c0.3,0,0.7-0.1,0.9-0.4l15.1-15.1c0.2-0.2,0.4-0.6,0.4-0.9c0-0.4-0.1-0.7-0.4-0.9c-0.2-0.2-0.6-0.4-0.9-0.4h0c-0.3,0-0.7,0.1-0.9,0.4L61.4,71l-6.5-6.5c-0.5-0.5-1.3-0.5-1.8,0c-0.2,0.2-0.4,0.6-0.4,0.9c0,0.4,0.1,0.7,0.4,0.9L60.5,73.8z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M40.5,22.1c-4.8,0-8.8,3.9-8.8,8.6c0,4.8,3.9,8.6,8.8,8.6c4.8,0,8.8-3.9,8.8-8.6C49.3,25.9,45.4,22.1,40.5,22.1z M46.7,30.7c0,1.6-0.6,3.1-1.8,4.2c-1.2,1.2-2.7,1.8-4.4,1.8c-3.4,0-6.2-2.7-6.2-6s2.8-6,6.2-6C43.9,24.7,46.7,27.4,46.7,30.7z'}
    />
  </svg>
);
