// TODO: Remove whole mapLegacy directory after rollback new version of sonnenCommunity
import communityMapSagas from './communityMap.sagas';
import CommunityMapContainer from './containers/CommunityMap/CommunityMap';

export * from './containers/withCommunityData';
export * from './communityMap.api';

export {
  CommunityMapContainer as CommunityMap,
  communityMapSagas,
};
