import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { Toast } from '+app/shared/components';
import { NotificationCategory } from '+app/shared/store/notification/types/notification.interface';

export enum AccountToastType {
  SUCCESS = 'success',
  FAILURE = 'failure',
}

interface AccountToastProps {
  type: AccountToastType;
  isVisible: boolean;
  messageOverride?: string;
}

const isSuccess = (type: AccountToastType) => type === AccountToastType.SUCCESS;

export const AccountToast: React.FC<AccountToastProps> = ({ type, messageOverride, isVisible = false }) => (
  <>
    {isVisible ? (
      <Toast
        type={isSuccess(type) ? NotificationCategory.SUCCESS : NotificationCategory.DANGER}
        icon={isSuccess(type) ? <Icon.Checkmark /> : <Icon.Warning />}
        spacing={'high'}
      >
        {messageOverride || (isSuccess(type)
          ? I18n.t(T.general.messages.savedSuccessfully)
          : I18n.t(T.general.messages.apiError))
        }
      </Toast>
    ) : null}
  </>
);
