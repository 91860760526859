import * as React from 'react';

import { AccountAboutMe } from '+app/+dashboard/+account';
import { NestedNavSlideContent } from '../../NestedNav/NestedNavSlideContent';

export const MobileSideNavNestedAccountAbout: React.FC = () => (
  <NestedNavSlideContent
    hasGap={true}
  >
    <AccountAboutMe />
  </NestedNavSlideContent>
);
