import { UtilityDataMoveHousesForm } from '../../containers';

export enum UtilityChangePeriod {
  NOT_MOVED = 'notMoved',
  MOVED_WITHIN_FOUR_WEEKS = 'movedWithinFourWeeks',
  WILL_MOVE_IN_SIX_MONTHS = 'willMoveInSixMonths',
}

export const configurationConsumptionFormInitial: UtilityDataMoveHousesForm = {
  isMovingHouses: UtilityChangePeriod.NOT_MOVED,
};
