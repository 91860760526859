import { T } from '@sonnen/shared-i18n/customer';
import { DS } from '@sonnen/shared-web';
import { ButtonStatus } from '@sonnen/shared-web/src/ds/components/Button/Button.component';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './ModalConfirmButtons.component.scss';

const { Button, ButtonType, ButtonStyle, ButtonSize } = DS;

export interface ButtonsProps {
  onAccept: () => void;
  onCancel: () => void;
  isPending: boolean;
}

export const ModalConfirmButtons = ({
  onAccept,
  onCancel,
  isPending,
}: ButtonsProps) => (
  <div className={'c-contract-termination-modal-buttons'}>
    <Button
      onClick={onCancel}
      label={I18n.t(T.yourFlat.terminateModal.confirmationButtonCancel)}
      size={ButtonSize.LARGE}
    />
    <Button
      onClick={onAccept}
      label={I18n.t(T.yourFlat.terminateModal.confirmationButtonAccept)}
      loading={isPending}
      loadingLabel={I18n.t(T.yourFlat.terminateModal.confirmationButtonAccept)}
      disabled={isPending}
      type={ButtonType.QUATERNARY}
      size={ButtonSize.LARGE}
      status={isPending ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
    />
  </div>
);
