import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import { Carousel, PageSubheadline } from '@sonnen/shared-web';
import { inRange } from 'lodash/fp';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import * as uuid from 'uuid';

import { formatNumber } from '+app/utils/number.util';
import { breakpointDown } from '+legacy/helpers/breakpoints';
import { getKiloPowerObj } from '+legacy/helpers/numbers';
import { AnalysisSlide } from '../';
import { slides } from './AnalysisSlider.helper';

interface Props {
  productionEnergy: number;
}

export const AnalysisSlider: React.FC<Props> = ({
  productionEnergy,
}) => {
  const {
    value: productionValue,
    unit: productionUnit,
  } = getKiloPowerObj(productionEnergy, 'Wh');

  return (
    <>
      <PageSubheadline noBorder={true}>
        <Translate
          value={T.analysis.headlines.carousel}
          totalProduction={`${formatNumber()(productionValue)} ${productionUnit}`}
          dangerousHTML={true}
        />
      </PageSubheadline>
      <div className={'c-analysis-slider'}>
        <Carousel
          arrowsOnDesktop={true}
          slidesToShow={3}
          slidesToScroll={3}
          speed={500}
          responsive={[
            {
              breakpoint: 1350,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: breakpointDown('SM'),
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 250,
              },
            },
          ]}
        >
          {slides
            .filter(slide => {
              const convertedProductionEnergy = Math.floor(productionEnergy / slide.energyConverterFactor);

              return inRange(1, 1000000)(convertedProductionEnergy);
            })
            .map(slide => {
              const IconComponent = Icon[slide.icon];

              return (
                <AnalysisSlide
                  key={uuid.v4()}
                  icon={<IconComponent />}
                  value={Math.floor(productionEnergy / slide.energyConverterFactor)}
                  unit={I18n.t(slide.unit)}
                  description={I18n.t(slide.description)}
                />
              );
            })
          }
        </Carousel>
      </div>
    </>
  );
};
