import { T } from '@sonnen/shared-i18n/customer';
import { BoldParagraph, DefaultParagraph, Modal, ModalLayout } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { definitions } from './GuidePurchaseFlatTermsModal.helper';

import './GuidePurchaseFlatTermsModal.component.scss';

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export const GuidePurchaseFlatTermsModal: React.FC<Props> = ({ isOpen, toggle }) => (
  <Modal
    isOpen={isOpen}
    onClose={toggle}
  >
    <ModalLayout
      title={I18n.t(T.customerGuidance.purchase.flatTermsModal.title)}
    >
      <div className={'c-guide-purchase-flat-terms-modal__content'}>
        {definitions.map((definition, index) => (
          <div
            key={`flat-terms-modal-${index}`}
            className={'c-guide-purchase-flat-terms-modal__section'}
          >
            <BoldParagraph className={'c-guide-purchase-flat-terms-modal__section-headline'}>
              {I18n.t(definition.headline)}
            </BoldParagraph>
            <DefaultParagraph>
              {I18n.t(definition.description)}
            </DefaultParagraph>
          </div>
        ))}
      </div>
    </ModalLayout>
  </Modal>
);
