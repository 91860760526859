import { T } from '@sonnen/shared-i18n/customer';
import classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { ContractStatus } from '../ContractCard/types/ContractCard.interface';

import './ContractCardStatus.component.scss';

interface Props {
  status: ContractStatus;
}

export const ContractCardStatus: React.FC<Props> = ({ status }) => (
  <div
    className={classNames('c-contract-card-status', {
      'c-contract-card-status--done': status === ContractStatus.DONE,
    })}
  >
    {status === ContractStatus.DONE
      ? I18n.t(T.yourFlat.sonnenFlatX.cardStatus.done)
      : I18n.t(T.yourFlat.sonnenFlatX.cardStatus.todo)}
  </div>
);
