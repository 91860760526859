import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './IconBadge.component.scss';

export type IconBadgeProps = {
  icon: React.ReactNode;
  label?: string;
  hasArrow?: boolean;
  isActive?: boolean;
};

export const IconBadge: React.FC<IconBadgeProps> = ({
  icon,
  label,
  hasArrow,
  isActive,
}) => (
  <div className={'c-icon-badge'}>
    {label && (
      <p className={'c-icon-badge__label'}>
        {label}
      </p>
    )}
    {hasArrow &&
      <span className={classNames('c-icon-badge__arrow', {
        'is-active': isActive,
      })}>
        <Icon.Angle />
      </span>
    }
    <span className={'c-icon-badge__icon'}>
      {icon}
    </span>
  </div>
);
