import { isMobile } from '+config/environment';
import { IntegrationActions, mobileLog, sendAction } from '+mobile';
import { arrayBufferToBase64 } from '+utils/binary.util';
import { Reporter } from '+utils/reporter.util';

export const saveDataAsFile = (data: ArrayBuffer | string | undefined, fileName: string, mimeType?: string): void => {
  if (!data) {
    Reporter.reportError({
      name: 'No data to save',
      message: `There was no data to save as ${fileName}`,
    });
    return;
  }

  if (isMobile) {
    const file = typeof data === 'string' ? btoa(data) : arrayBufferToBase64(data);
    sendAction(IntegrationActions.sendEncodedFile({ file, fileName }));

  } else {
    const blob = new Blob([data], mimeType ? { type: mimeType } : undefined);
    const link = URL.createObjectURL(blob);
    const a = document.createElement('a');

    a.setAttribute('download', fileName);
    a.setAttribute('href', link);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
