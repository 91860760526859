import { T } from '@sonnen/shared-i18n/customer';
import {
  DefaultParagraph,
  ExpandableSection,
  PageSubheadline,
} from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { CommunityGraphParagraphs } from '../';
import { FrequencyChart } from '../../../../features/frequency';

import './CommunityGraph.mobile.component.scss';

export const CommunityGraphMobile: React.SFC = () => (
  <>
    <div className={'m-c-community-graph__header'}>
      <PageSubheadline smallGap={true}>
        {I18n.t(T.sonnenCommunity.gridBalancing.title)}
      </PageSubheadline>
      <DefaultParagraph className={'m-c-community__paragraph'}>
        {I18n.t(T.sonnenCommunity.gridBalancing.subtitle)}
      </DefaultParagraph>
    </div>
    <FrequencyChart className={'m-c-community-graph__chart'} />
    <PageSubheadline
      classNames={'m-c-community-graph__subheadline'}
      noBorder={true}
      smallGap={true}
    >
      {I18n.t(T.sonnenCommunity.gridBalancing.description.title)}
    </PageSubheadline>
    <ExpandableSection
      className={'m-c-community-graph__expandable'}
      minHeight={40}
      maxHeight={1000}
      buttonLabel={I18n.t(T.general.labels.readMore)}
      buttonLabelWhenExpanded={I18n.t(T.general.labels.readLess)}
      buttonOnLeftSide={true}
    >
      <CommunityGraphParagraphs />
    </ExpandableSection>
  </>
);
