import * as React from 'react';
import { EnergyValue, ErrorStatus, Hertz } from '../../../shared/utils.types';
import { handleFrequencyResponse, setFrequencyState } from '../frequency.selectors';
import { FrequencyService } from '../frequency.service';

interface State {
  frequencies: Array<EnergyValue<Hertz>>;
  status: ErrorStatus;
  loading: boolean;
}

export const withFrequencyRoutine = <P extends object>(EnhancedComponent: React.ComponentType<P>) => (
  class FrequencyRoutineContainer extends React.PureComponent<P, State> {
    public state = {
      frequencies: [],
      status: ErrorStatus.NONE,
      loading: true,
    };

    private frequencyService = new FrequencyService();

    componentDidMount() {
      this.frequencyService.subscribe((data) => {
        const freqs = handleFrequencyResponse(data, null);
        this.setState(setFrequencyState(freqs));
      });
      this.frequencyService.start();
    }

    componentWillUnmount() {
      this.frequencyService.stop();
    }

    render() {
      return (
        <EnhancedComponent {...this.props} {...this.state} />
      );
    }
  }
);
