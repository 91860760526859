import { toggleOnlineState } from '+legacy/core/app/app.actions';
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { mapActions } from '../../utils/redux';

type HocProps = ReturnType<typeof mapDispatchToProps>;

const mapDispatchToProps = mapActions({
  toggleOnlineState,
});

export const withNetworkCheck = <P extends HocProps>(WrappedComponent: React.ComponentType<P>) => {

  const Component: React.FC<HocProps> = props => {
    useEffect(() => {
      const { toggleOnlineState } = props.actions;
      const onOnline = () => toggleOnlineState(true);
      const onOffline = () => toggleOnlineState(false);
      window.addEventListener('online', onOnline);
      window.addEventListener('offline', onOffline);

      return () => {
        window.removeEventListener('online', onOnline);
        window.removeEventListener('offline', onOffline);
      };
    }, []);

    return <WrappedComponent {...props as P} />;
  };

  return connect(undefined, mapDispatchToProps)(Component);
};
