import {
  AccessoriesChartSeries,
  BarChartSeries,
  EnergyFlowSeriesKey,
  PieChartSeries,
} from '@sonnen/shared-web';
import { TimeUnit } from '@sonnen/shared-web';
import * as moment from 'moment';

import { getResolutionForPeriod } from '+analysis/store/analysis.helpers';
import { SelectedDate } from '+analysis/store/types/selectedDate.interface';
import { SummerTimeChange } from '+app/shared/helpers/summerTime.helper';
import { MeasurementMethod } from '+shared/store/live/types/siteLiveData.interface';
import { Query } from '+shared/store/query/query.state';
import { AreaChartSeries } from './types/dataSeries.interface';
import { SiteForecastConsumption, SiteForecastProduction } from './types/forecast.interface';
import { SiteEvent } from './types/siteEvents.interface';
import { SiteMeasurements } from './types/siteMeasurements.interface';
import { StatisticsV1 } from './types/statisticsV1.interface';
import { StatisticsV2 } from './types/statisticsV2.interface';

export const GET_MEASUREMENTS_QUERY = 'getMeasurementsQuery';
export const GET_STATISTICS_QUERY = 'getStatisticsQuery';
export const GET_EVENTS_QUERY = 'getEventsQuery';
export const GET_FORECAST_PRODUCTION_QUERY = 'getForecastProductionQuery';
export const GET_FORECAST_CONSUMPTION_QUERY = 'getForecastConsumptionQuery';
export const DOWNLOAD_STATISTICS_CSV_QUERY = 'downloadStatisticsCsvQuery';
export const DOWNLOAD_MEASUREMENTS_CSV_QUERY = 'downloadMeasurementsCsvQuery';

export interface AnalysisState {
  measurements?: SiteMeasurements;
  forecastProduction?: SiteForecastProduction[];
  forecastConsumption?: SiteForecastConsumption[];
  latestMeasurementsFetchDate?: Date;
  previousMeasurementMethod: MeasurementMethod | undefined;
  measurementsCache: SiteMeasurements[];
  statistics?: StatisticsV2;
  statisticsCache: StatisticsV2[];
  events?: SiteEvent[];
  selectedDate: moment.Moment;
  selectedDataSeriesKeys: EnergyFlowSeriesKey[];
  areaChartSeries: AreaChartSeries;
  statisticsSelectedDate: SelectedDate;
  barChartSeries: BarChartSeries;
  pieChartSeries: PieChartSeries;
  accessoriesChartSeries: AccessoriesChartSeries;
  summerTimeChange: SummerTimeChange;
  [GET_MEASUREMENTS_QUERY]: Query<SiteMeasurements>;
  [GET_FORECAST_PRODUCTION_QUERY]: Query<SiteForecastProduction[]>;
  [GET_FORECAST_CONSUMPTION_QUERY]: Query<SiteForecastConsumption[]>;
  [GET_STATISTICS_QUERY]: Query<StatisticsV1>;
  [GET_EVENTS_QUERY]: Query<SiteEvent[]>;
  [DOWNLOAD_STATISTICS_CSV_QUERY]: Query;
  [DOWNLOAD_MEASUREMENTS_CSV_QUERY]: Query;
}

export const initialState: AnalysisState = {
  measurements: undefined,
  forecastProduction: [],
  forecastConsumption: [],
  latestMeasurementsFetchDate: undefined,
  previousMeasurementMethod: undefined,
  statistics: undefined,
  events: [],
  selectedDate: moment(),
  selectedDataSeriesKeys: [
    EnergyFlowSeriesKey.BATTERY_USOC,
    EnergyFlowSeriesKey.BATTERY_CHARGING,
    EnergyFlowSeriesKey.BATTERY_DISCHARGING,
    EnergyFlowSeriesKey.CONSUMPTION_POWER,
    EnergyFlowSeriesKey.DIRECT_USAGE_POWER,
    EnergyFlowSeriesKey.PRODUCTION_POWER,
    EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER,
    EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER,
    'batteryCare' as EnergyFlowSeriesKey,
  ],
  measurementsCache: [],
  statisticsCache: [],
  areaChartSeries: {
    consumptionPower: [],
    productionPower: [],
    directUsagePower: [],
    batteryUsoc: [],
    batteryCharging: [],
    batteryDischarging: [],
    gridFeedin: [],
    gridPurchase: [],
    forecastProductionPower: [],
    forecastConsumptionPower: [],
    batteryCare: [],
  },
  statisticsSelectedDate: {
    period: TimeUnit.DAY,
    date: new Date(),
    resolution: getResolutionForPeriod(TimeUnit.DAY),
  },
  barChartSeries: {
    consumedEnergy: [],
    producedEnergy: [],
  },
  pieChartSeries: {
    autonomy: {
      gridPurchaseEnergy: 0,
      consumedEnergy: 0,
    },
    selfConsumption: {
      gridFeedinEnergy: 0,
      producedEnergy: 0,
    },
  },
  accessoriesChartSeries: {
    backupBox: [],
    heaterPower: [],
    heaterWaterTemp: [],
  },
  summerTimeChange: {
    isSummerTimeStartDate: false,
    isSummerTimeEndDate: false,
  },
  [GET_MEASUREMENTS_QUERY]: {},
  [GET_STATISTICS_QUERY]: {},
  [GET_FORECAST_PRODUCTION_QUERY]: {},
  [GET_FORECAST_CONSUMPTION_QUERY]: {},
  [GET_EVENTS_QUERY]: {},
  [DOWNLOAD_STATISTICS_CSV_QUERY]: {},
  [DOWNLOAD_MEASUREMENTS_CSV_QUERY]: {},
};
