import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

import { GuideStepStatus } from '+guide/helpers/Guide.helper';
import { ProgressCardProgress, ProgressCardProps } from '+shared/components';
import { insertIf } from '+utils/array.util';
import { PhasesOverviewProps } from './PhasesOverview.component';

import discover from '+images/guide/discover.svg';
import enjoy from '+images/guide/enjoy.svg';
import setup from '+images/guide/setup.svg';
import shakeHands from '+images/guide/shake-hands.svg';

type PhaseList = ProgressCardProps;

const getProgress = (
  tile: string,
  previewPhases: string[], // @TODO: Create enum for this
  donePhases: string[], // @TODO: Create enum for this
) => {
  const finished = donePhases.includes(tile);
  const started = !previewPhases.includes(tile);
  if (finished) { return ProgressCardProgress.FINISHED; }
  if (!finished && started) { return ProgressCardProgress.STARTED; }
  if (!finished && started) { return ProgressCardProgress.READY; }

  return ProgressCardProgress.UNAVAILABLE;
};

const getDefaultProgressPercentage = (progress: ProgressCardProgress) => {
  switch (progress) {
    case ProgressCardProgress.STARTED:
      return 36;
    case ProgressCardProgress.FINISHED:
      return 100;
    default:
      return 0;
  }
};

export const factorizePhaseList = ({
  donePhases,
  previewPhases,
  partnerStepStatus,
  setupProgress,
  isOfferAvailable,
  isOfferAccepted,
  isSonnenLead,
  goToAcceptStep,
  goToPurchase,
  goToSetup,
  goToUsage,
  goToDiscovery,
}: PhasesOverviewProps): PhaseList[] => {
  const getPhaseProgress = (phase: string) => getProgress(phase, previewPhases, donePhases);
  const isOfferNotificationVisible = isOfferAvailable && !isOfferAccepted;

  const discoveryPhase = {
    onClick: goToDiscovery,
    icon: discover,
    title: I18n.t(T.customerGuidance.overview.discover.title),
    progress: getPhaseProgress('discovery'),
    progressPercentage: getDefaultProgressPercentage(getPhaseProgress('discovery')),
    isCtaVisible: partnerStepStatus === GuideStepStatus.ACTIVE,
    ctaLabel: I18n.t(T.customerGuidance.overview.nextStep.start),
  };

  return [
    ...insertIf(
      isSonnenLead,
      discoveryPhase,
    ),
    {
      onClick: isOfferNotificationVisible
        ? goToAcceptStep
        : goToPurchase,
      icon: shakeHands,
      title: I18n.t(T.customerGuidance.overview.shakeHands.title),
      description: (!donePhases.includes('purchase') && !isOfferNotificationVisible)
        ? I18n.t(T.customerGuidance.overview.shakeHands.description)
        : undefined,
      progress: getPhaseProgress('purchase'),
      progressPercentage: getDefaultProgressPercentage(getPhaseProgress('purchase')),
      isCtaVisible: isOfferNotificationVisible,
      ctaLabel: I18n.t(T.customerGuidance.overview.nextStep.acceptOfferTitle),
    },
    {
      onClick: goToSetup,
      icon: setup,
      title: I18n.t(T.customerGuidance.overview.setup.title),
      description: !donePhases.includes('setup')
        ? I18n.t(T.customerGuidance.overview.setup.description)
        : undefined,
      progress: getPhaseProgress('setup'),
      progressPercentage: setupProgress,
    },
    {
      onClick: goToUsage,
      icon: enjoy,
      title: I18n.t(T.customerGuidance.overview.enjoy.title),
      description: (!donePhases.includes('enjoy'))
        ? I18n.t(T.customerGuidance.overview.enjoy.description)
        : undefined,
      progress: getPhaseProgress('enjoy'),
      progressPercentage: getDefaultProgressPercentage(getPhaseProgress('enjoy')),
    },
  ];
};
