import * as React from 'react';

import './CheckList.component.scss';

interface Props {
  children?: JSX.Element[] | JSX.Element | any;
}

export const CheckList: React.FC<Props> = ({ children }) => (
  <ul className="c-check-list">
    {children}
  </ul>
);
