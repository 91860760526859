import { T } from '@sonnen/shared-i18n/customer';
import { BoldParagraph } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { HouseType } from '+app/+guide/store/types/energyProvider.interface';
import { FormInputSelect } from '+app/shared/components/FormInputSelect';
import { FormFieldObserver } from '+guide/components/FormFieldObserver';
import { FormInputDate } from '+shared/components';
import { formFields, UtilityDataForm } from '../../containers/GuideAcceptanceUtility';
import { calculateMinMovingDate } from './GuideAcceptanceUtilityMovingDetails.helper';

import './GuideAcceptanceUtilityMovingDetails.component.scss';

interface Props {
  form: FormikProps<UtilityDataForm>;
}

const onDateChange = (form: FormikProps<UtilityDataForm>) => () => {
  form.setFieldTouched(formFields.MOVING_DATE);
};

export const GuideAcceptanceUtilityMovingDetails = ({ form }: Props) => (
  <>
    <div className={'c-guide-acceptance-moving-details'}>
      <div className={'c-guide-acceptance-moving-details__text'}>
        <BoldParagraph>
          {I18n.t(T.customerGuidance.acceptance.utilityData.movingDetails.header)}
        </BoldParagraph>
        <FormFieldObserver<UtilityDataForm> onChange={onDateChange(form)}>
          <div className={'c-guide-acceptance-moving-details__date-input'}>
            <FormInputDate
              minDate={calculateMinMovingDate()}
              form={form}
              name={formFields.MOVING_DATE}
              id={'guide-acceptance-moving-date'}
            />
          </div>
        </FormFieldObserver>
        <FormInputSelect
          className={'c-guide-acceptance-moving-details__select'}
          placeholder={I18n.t(T.customerGuidance.acceptance.utilityData.movingDetails.typeOfHouse)}
          form={form}
          name={formFields.HOUSE_TYPE}
          collection={Object.values(HouseType)}
          mapper={key => I18n.t(T.customerGuidance.acceptance.utilityData.movingDetails.houseType[key])}
        />
      </div>
    </div>
  </>
);
