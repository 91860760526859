import { T } from '@sonnen/shared-i18n/customer';
import { Card, CardNotification, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { BatteryHelper } from '+battery/store/battery.helpers';
import { Battery } from '+battery/store/types/battery.interface';
import { SpecsItem } from '+components';
import { getFormattedDate } from '+legacy/helpers/dates';
import { getKiloPowerObj } from '+legacy/helpers/numbers';
import { fillEmptyValueWithDash } from '+legacy/helpers/strings';
import { BatteryOperatingMode, SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { BatteryCardGrid } from '../BatteryCardGrid';

import { formatNumber } from '+app/utils/number.util';
import batterieImage from '+images/sonnenbatterie.png';

interface Props {
  batteryData: Battery;
  liveData: SiteLive;
}

const renderNotification = (liveData: SiteLive) => {
  const { online, timestamp } = liveData;

  // TODO: Handle events, e.g. power outage etc.
  if (!timestamp) {
    return (
      <CardNotification icon={<Icon.QuestionMark />}>
        <Translate
          value={T.batterySystem.notifications.loadingData}
          dangerousHTML={true}
        />
      </CardNotification>
    );
  }
  if (!online) {
    return (
      <CardNotification 
        dataTestId={'sonnen-batterie-overview-system-details-offline'}
        icon={<Icon.Offline 
      />}>
        <Translate
          value={T.batterySystem.notifications.notOnline}
          date={getFormattedDate(timestamp, T.general.date.longWithTime)}
          dangerousHTML={true}
        />
      </CardNotification>
    );
  }
  return (
    <CardNotification
      dataTestId={'sonnen-batterie-overview-system-details-online'}
      type={'success'}
      icon={<Icon.Checkmark />}
    >
      <Translate
        value={T.batterySystem.notifications.everythingOk}
        dangerousHTML={true}
      />
    </CardNotification>
  );
};

const OperatingModesApiToLocalesMap: Record<BatteryOperatingMode, string> = {
  'Battery-Module-Extention': 'moduleExtension',
  'Energy Reserve': 'Energy Reserve',
  'Full-Charge': 'fullCharge',
  'Final-Inspection': 'finalInspection',
  'Intelligent-Mode': 'intelligentMode',
  'Manual': 'Manual',
  'Not-Defined': 'invalidMode',
  'Peak-Shaving': 'peakShaving',
  'PV-Simulation': 'PVSimulation',
  'Self-Consumption': 'Self-Consumption',
  'Time of Use': 'Time of Use',
  'time-of-use': 'Time of Use',
  'US-Automatic': 'automatic',
  'US-Backup-Power': 'backupPower',
  'US-Net-Zero-Pv': 'netZeroPV',
  'US-time-of-use': 'Time of Use',
  'US-Time-Of-Use': 'Time of Use',
};

const mapOperatingModeToTranslation = (mode: BatteryOperatingMode | undefined) => mode
  ? I18n.t(T.dashboard.operatingModes[OperatingModesApiToLocalesMap[mode]])
  : I18n.t(T.dashboard.operatingModes[OperatingModesApiToLocalesMap['Self-Consumption']]);

export const BatteryCardInfo: React.SFC<Props> = ({
  batteryData,
  liveData,
}) => {
  const batteryCapacity = getKiloPowerObj(batteryData.batteryCapacity, 'Wh');

  return (
    <Card
      title={I18n.t(T.batterySystem.overview.details.title)}
      footerContent={renderNotification(liveData)}
    >
      <BatteryCardGrid
        side={<img src={batterieImage} alt={'sonnenBatterie'} />}
        content={(
          <>
            <SpecsItem
              dataTestId={'sonnen-batterie-overview-system-details-model'}
              title={I18n.t(T.batterySystem.overview.details.model)}
              value={fillEmptyValueWithDash(BatteryHelper.getBatteryProductName(batteryData))}
            />
            <SpecsItem
              dataTestId={'sonnen-batterie-overview-system-details-capacity'}
              title={I18n.t(T.batterySystem.overview.details.capacity)}
              value={`
                ${formatNumber({ fixed: false, precision: 1 })(batteryCapacity.value)}
                ${' '}
                ${batteryCapacity.unit}
              `}
            />
            <SpecsItem
              dataTestId={'sonnen-batterie-overview-system-details-operating-mode'}
              title={I18n.t(T.batterySystem.overview.details.operatingMode)}
              value={I18n.t(fillEmptyValueWithDash(mapOperatingModeToTranslation(liveData.batteryOperatingMode)))}
            />
            <SpecsItem
              dataTestId={'sonnen-batterie-overview-system-serial-number'}
              title={I18n.t(T.batterySystem.overview.details.serialNumber)}
              value={fillEmptyValueWithDash(batteryData.serialNumber)}
            />
          </>
        )}
      />
    </Card>
  );
};
