import { StoreState } from '+app/store/store.interface';
import { createSelector } from 'reselect';

export const getCurrentRoute = (store: StoreState) => store.router.location;

export const getMobileState = (store: StoreState) => store.mobile;

export const getMobileAppVersionInfo = createSelector(
  getMobileState,
  mobile => ({
    versionName: mobile.versionName,
    flagConfig: mobile.flagConfig,
  }),
);

export const getMobileAppInstanceId = createSelector(
  getMobileState,
  mobile => mobile.instanceId,
);

export const getMobileAppState = createSelector(
  getMobileState,
  mobile => mobile.appState,
);
