import { isNil } from 'lodash/fp';

import { SiteHelper } from '+app/shared/store/site/site.helpers';
import { formatPercentage } from '+legacy/helpers/numbers';
import { Site, SiteOption } from '+shared/store/site/types/site.interface';
import { MeasurementMethod, SiteLive } from '../../store/live/types/siteLiveData.interface';

const isActive =
  (isAppOnline: boolean) =>
  (isLiveStateOnline: boolean | undefined) =>
  (site: Site | undefined) =>
  (predicate: boolean | undefined) => Boolean(
    isAppOnline
    && isLiveStateOnline
    && predicate
    && (site && SiteHelper.hasOption(site, SiteOption.READINGS)),
);

const isCommunityMember = (site: Site | undefined) =>
  !!site
  && !!site.sonnenCommunityMember
  && site.measurementMethod !== MeasurementMethod.METER_GRID_PV_FEED_IN;

const getProductionPower = (
  liveData: SiteLive | undefined,
) =>
  !!liveData
    && !isNil(liveData.gridFeedin)
    && !isNil(liveData.productionPower)
      ? liveData.productionPowerKw
      : undefined;

const getGridPower = (
  liveData: SiteLive | undefined,
) =>
  liveData
    && !isNil(liveData.gridPurchase)
    && !isNil(liveData.gridFeedin)
    && liveData.measurementMethod !== MeasurementMethod.METER_GRID_PV_FEED_IN
      ? Math.max(liveData.gridPurchaseKw || 0, liveData.gridFeedinKw || 0)
      : undefined;

const getConsumptionPower = (
  liveData: SiteLive | undefined,
) =>
  liveData
    && liveData.measurementMethod !== MeasurementMethod.METER_ERROR
    && !isNil(liveData.consumptionPower)
      ? liveData.consumptionPowerKw
      : undefined;

const getBatteryPower = (liveData: SiteLive | undefined) =>
  liveData && (liveData.batteryChargingKw || liveData.batteryDischargingKw || undefined);

const getBatteryUsoc = (liveData: SiteLive | undefined) =>
  formatPercentage(
    liveData && liveData.batteryUsoc
      ? liveData.batteryUsoc
      : 0,
  );

export const LiveEnergyFlowHelper = {
  isActive,
  isCommunityMember,
  getProductionPower,
  getGridPower,
  getConsumptionPower,
  getBatteryPower,
  getBatteryUsoc,
};
