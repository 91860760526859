import * as classNames from 'classnames';
import * as React from 'react';

import { InfoTooltip } from '../InfoTooltip';

import './SpecsItem.component.scss';

interface Props {
  title: string;
  value: React.ReactNode;
  infoTooltip?: React.ReactNode;
  isValueEmphasized?: boolean;
  dataTestId?: string;
}

export const SpecsItem: React.SFC<Props> = ({
  title,
  value,
  infoTooltip,
  isValueEmphasized,
  dataTestId,
}) => (
  <div className={'c-specs-item'}>
    <div 
      data-test-id={dataTestId + '-label'}
      className={'c-specs-item__label'}>
      {title}
      {infoTooltip && (
        <span className={'c-specs-item__info'}>
          <InfoTooltip>
            {infoTooltip}
          </InfoTooltip>
        </span>
      )}
    </div>
    <div
      data-test-id={dataTestId + '-value'} 
      className={classNames(
      'c-specs-item__label--bold',
      'c-specs-item__label--dotted', {
      'c-specs-item__label--em': isValueEmphasized,
    })}>
      {value}
    </div>
  </div>
);
