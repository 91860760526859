import * as React from 'react';

import { Routes } from '+app/router/router.types';
import { PlatformSelector } from '+components';
import { DemoNavMobile } from './DemoNav.mobile.component';
import { DemoNavWeb } from './DemoNav.web.component';

export type DemoNavProps = {
  routes: Routes;
};

export const DemoNav: React.FC<DemoNavProps> = (props) => (
  <PlatformSelector
    web={<DemoNavWeb {...props} />}
    mobile={<DemoNavMobile {...props} />}
  />
);
