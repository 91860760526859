import { T } from '@sonnen/shared-i18n/customer';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  CountryCode,
  DefaultParagraph,
  PageSubheadline,
} from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getPaths } from '+app/router/router.helper';
import { Page } from '+app/shared/containers/Page';
import { StoreState } from '+app/store/store.interface';
import { TableList } from '+components';
import { factorizeContractCustomerData } from '+contract/containers/ContractCustomerData';
import { getCurrentSiteContractData } from '+contract/store/contract.selectors';
import { history } from '+router/store/index';
import {
  getCurrentUserState,
  isUserCountry,
} from '+shared/store/user/user.selectors';

import './ContractCustomerData.component.scss';

const mapStateToProps = (state: StoreState) => {
  const user = getCurrentUserState(state);

  return {
    contract: getCurrentSiteContractData(state),
    customerNumber: user ? user.customerNumber : '',
    isSupportAccessible: !isUserCountry(CountryCode.IT)(state),
  };
};

type Props = ReturnType<typeof mapStateToProps>;

const ContractCustomerDataComponent: React.FC<Props> = ({
  contract,
  customerNumber,
  isSupportAccessible,
}) => {
  const redirectToHelpAndSupport = () => history.push(getPaths().HELP_AND_SUPPORT);

  return (
    <Page headerTestId={'contract-customer-data-header'}>
      {contract && (
        <div className={'o-grid'}>
          <section className={'o-grid__column o-grid__column--sm o-grid__column--no-gap-sm'}>
            <PageSubheadline mediumGap={true}>
              {I18n.t(T.yourTariff.headlines.billing)}
            </PageSubheadline>
            <div className={'c-contract-customer-data__section'}>
              <TableList list={factorizeContractCustomerData(contract, customerNumber)} />
            </div>
          </section>
          <section className={'o-grid__column o-grid__column--sm o-grid__column--no-gap'}>
            {isSupportAccessible && (
              <>
                <PageSubheadline mediumGap={true}>
                  {I18n.t(T.yourTariff.headlines.questions)}
                </PageSubheadline>
                <DefaultParagraph className={'c-contract-customer-data__questions-paragraph'}>
                  {I18n.t(T.yourTariff.questionsDescription)}
                </DefaultParagraph>
                <Button
                  dataTestId={'contract-customer-data-ask-a-question-button'}
                  label={I18n.t(T.yourTariff.labels.askQuestion)}
                  onClick={() => redirectToHelpAndSupport()}
                  size={ButtonSize.PRIMARY}
                  theme={ButtonTheme.OUTLINE}
                />
              </>
            )}
          </section>
        </div>
      )}
    </Page>
  );
};

export const ContractCustomerData = connect(mapStateToProps)(
  ContractCustomerDataComponent,
);
