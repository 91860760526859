import * as Highcharts from 'highcharts/highstock';
import * as React from 'react';

import { AnnualConsumptionChartProps as Props } from './AnnualConsumptionChart.types';
import { generateChartSettings } from './AnnualConsumptionChartSettings.component';

import './AnnualConsumptionChart.scss';

export const AnnualConsumptionChart: React.FC<Props> = (props) => {
  const [chart, setChart] = React.useState<Highcharts.ChartObject | undefined>();

  const setChartTimezoneOffset = () => {
    Highcharts.setOptions({
      global: {
        timezoneOffset: new Date().getTimezoneOffset(),
      },
    });
  };

  React.useEffect(() => {
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, []);

  React.useEffect(() => {
    if (!chart && props.consumptionByDay.length) {
      setChartTimezoneOffset();
      const annualConsumptionChart = new Highcharts.Chart(
        'annualConsumptionChart',
        generateChartSettings(props) as any,
      );
      setChart(annualConsumptionChart);
    }
  }, [props.consumptionByDay]);

  return (
    <div className={'c-annual-consumption-chart'}>
      <div
        id={'annualConsumptionChart'}
        className={'c-annual-consumption-chart__chart'}
      />
    </div>
  );
};
