import { GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { 
  getCessionFailed, 
  getCessionStatus, 
  getGuideAcceptedOffer, 
  getGuideAcceptedOrConfirmedOffer, 
  getGuideCommonState, 
  getImpactAnalysisList, 
} from '+app/+guide/store/+guide.selectors';
import { getLeadStatus, isPartnerAvailable } from '+app/+guide/store/+lead.selectors';
import { CessionStatus } from '+app/+guide/store/types';
import { LeadStatusName } from '+app/+guide/store/types/leadStatus.interface';
import { minBy } from 'lodash';
import { createSelector } from 'reselect';

export const getFlatOfferStatus = createSelector(
  getLeadStatus(LeadStatusName.FLAT_OFFER_SENT),
  status => status,
);

export const isFlatOfferAvailable = createSelector(
  getLeadStatus(LeadStatusName.FLAT_OFFER_SENT),
  status => status && status.isSet,
);

export const isImpactAnalysisAvailable = createSelector(
  getGuideCommonState,
  ({ impactAnalysisList }) => !!(impactAnalysisList && impactAnalysisList.length),
);

export const getOldestImpactAnalysis = createSelector(
  getGuideCommonState,
  ({ impactAnalysisList }) => impactAnalysisList && minBy(impactAnalysisList, e => e.updatedAt),
);

export const getImpactAnalysisDates = createSelector(
  getGuideCommonState,
  ({ impactAnalysisList }) => impactAnalysisList.map(ia => ia.updatedAt).sort((a, b) => {
    return +new Date(b) - +new Date(a);
  }),
);

export const isHardwareOfferAvailable = createSelector(
  getLeadStatus(LeadStatusName.HW_OFFER_SENT),
  status => status && status.isSet,
);

export const isFlatOfferAccepted = createSelector(
  getLeadStatus(LeadStatusName.FLAT_OFFER_ACCEPTED),
  status => status && status.isSet,
);

export const isHardwareOfferAccepted = createSelector(
  getLeadStatus(LeadStatusName.HW_OFFER_ACCEPTED),
  status => status && status.isSet,
);

export const getFlatOrderConfirmedStatus =  createSelector(
  getLeadStatus(LeadStatusName.FLAT_ORDER_CONFIRMED),
  status => status,
);

export const isFlatOrderConfirmed =  createSelector(
  getLeadStatus(LeadStatusName.FLAT_ORDER_CONFIRMED),
  status => status && status.isSet,
);

export const getHardwareOrderConfirmedStatus = createSelector(
  getLeadStatus(LeadStatusName.HW_ORDER_CONFIRMED),
  status => status,
);

export const isHardwareOrderConfirmed = createSelector(
  getLeadStatus(LeadStatusName.HW_ORDER_CONFIRMED),
  status => status && status.isSet,
);

/**
 * OFFER RECEIVED STEP
 */

export const getOfferReceivedStepStatus = createSelector(
  isFlatOfferAvailable,
  isPartnerAvailable,
  (offerAvailable, partnerAvailable) => {
    if (offerAvailable) {
      return GuideStepStatus.DONE;
    }

    if (partnerAvailable) {
      return GuideStepStatus.ACTIVE;
    }

    return GuideStepStatus.PREVIEW;
  },
);

/**
 * CESSION DOC STEP
 */

export const getCessionDocStepStatus = createSelector(
  getCessionStatus,
  getCessionFailed,
  (cessionStatus, cessionFailed) => {
    if (cessionFailed) {
      return GuideStepStatus.ERROR;
    }
    if (cessionStatus === CessionStatus.SIGNED) {
      return GuideStepStatus.DONE;
    }

    return GuideStepStatus.ACTIVE;
  },
);

/**
 * ACCEPT OFFER STEP
 */

export const getAcceptOfferStepStatus = createSelector(
  isFlatOfferAccepted,
  isFlatOfferAvailable,
  isImpactAnalysisAvailable,
  (offerAccepted, offerAvailable, impactAnalysisAvailable) => {
    if (offerAccepted) {
      return GuideStepStatus.DONE;
    }

    if (offerAvailable || impactAnalysisAvailable) {
      return GuideStepStatus.ACTIVE;
    }

    return GuideStepStatus.PREVIEW;
  },
);

export const getAcceptedImpactAnalysis = createSelector(
  getImpactAnalysisList,
  getGuideAcceptedOffer,
  (iaList, acceptedOffer) =>
    iaList.find(ia => ia.configurations.id === acceptedOffer?.configuration.id),
);

export const getAcceptedOrConfirmedOfferConfiguration = createSelector(
  getGuideAcceptedOrConfirmedOffer,
  offer => offer?.configuration,
);

/**
 * ORDER CONFIRMATION STEP
 */

export const getFlatOrderConfirmationDocumentStatus = createSelector(
  getGuideCommonState,
  common => common.orderConfirmationStatus,
);

export const getFlatOrderConfirmationStepStatus = createSelector(
  isFlatOrderConfirmed,
  isFlatOfferAccepted,
  (orderConfirmed, OfferAccepted) => {
    if (orderConfirmed) {
      return GuideStepStatus.DONE;
    }

    if (OfferAccepted) {
      return GuideStepStatus.ACTIVE;
    }

    return GuideStepStatus.PREVIEW;
  },
);

export const getHardwareOrderConfirmationStepStatus = createSelector(
  isHardwareOrderConfirmed,
  isHardwareOfferAccepted,
  (orderConfirmed, offerAccepted) => {
    if (orderConfirmed) {
      return GuideStepStatus.DONE;
    }

    if (offerAccepted) {
      return GuideStepStatus.ACTIVE;
    }

    return GuideStepStatus.PREVIEW;
  },
);
