import { ContractEnergyInvoice } from '+contract/store/types/energy.interface';
import { T } from '@sonnen/shared-i18n/customer';
import { ExpandableSection, Loader } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  ContractEnergyInvoicesHeader,
  ContractEnergyInvoicesList,
  ContractNotificationParagraph,
} from '../';

import './ContractEnergyInvoices.component.scss';

interface Props {
  invoices: ContractEnergyInvoice[] | undefined;
  hasContractInvoicesQueryFailed: boolean;
  isLoading: boolean;
}

const renderInvoicesList = (invoices: ContractEnergyInvoice[]) => (
  invoices.length >= 6 ? (
    <ExpandableSection
      buttonLabel={I18n.t(T.sonnenEnergy.documents.table.buttonLabel)}
      buttonLabelWhenExpanded={I18n.t(T.sonnenEnergy.documents.table.buttonLabelHide)}
      buttonOnLeftSide={true}
      minHeight={180}
      maxHeight={1000}
      themeFadingBottom={true}
    >
      <ContractEnergyInvoicesList invoices={invoices}/>
    </ExpandableSection>
  ) : (
    <ContractEnergyInvoicesList invoices={invoices}/>
  )
);

export const ContractEnergyInvoices: React.FC<Props> = ({ 
  invoices,
  hasContractInvoicesQueryFailed,
  isLoading,
}) => (
  <>
    {(!isNil(invoices) && invoices.length) ? (
      <div className={'c-contract-energy-invoices'}>
        <div className={'c-contract-energy-invoices__header'}>
          <ContractEnergyInvoicesHeader headers={[
            I18n.t(T.sonnenEnergy.documents.table.label),
            I18n.t(T.sonnenEnergy.documents.table.date),
            I18n.t(T.sonnenEnergy.documents.table.file),
          ]} />
        </div>
        {renderInvoicesList(invoices)}
      </div>
    ) : isLoading
      ? <div><Loader /></div>
      : (
        <ContractNotificationParagraph
          text={!hasContractInvoicesQueryFailed 
            ? I18n.t(T.sonnenEnergy.notifications.noInvoices) 
            : I18n.t(T.sonnenEnergy.notifications.fetchInvoicesError)
          }
          isThemeCard={true}
        />
      )}
  </>
);
