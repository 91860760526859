import { BoldParagraph, DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './GuideProductInfoSection.component.scss';

interface SectionProps {
  headline: string;
  description: string;
  image: string;
}

export const GuideProductInfoSection: React.FC<SectionProps> = ({
  headline,
  description,
  image,
}) => (
  <div className={'c-guide-product-info-section'}>
    <img src={image} className={'c-guide-product-info-section__image'} />
    <BoldParagraph className={'c-guide-product-info-section__headline'}>
      {I18n.t(headline)}
    </BoldParagraph>
    <DefaultParagraph>
      <span className={'c-guide-product-info-section__description'}>
        {I18n.t(description)}
      </span>
    </DefaultParagraph>
  </div>
);
