import { formatNumber, isValidNumber } from '+app/utils/number.util';
import { T } from '@sonnen/shared-i18n/customer';
import { NumberUnit } from '@sonnen/shared-web';
import { isNumber } from 'lodash/fp';
import numbro from 'numbro';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';

export const parseNumber = R.compose(
  Number,
  R.cond([
    [R.is(String), R.replace(',', '.')],
    [R.T, R.identity],
  ]),
);

export const getPowerValuePlaceholder = () => '-';

// Power
const formatPowerObj = (shouldBeKilo: boolean) =>
  (power: string | number = 0, unit = 'W', fractionDigitsAmount = 1) => {
    // Return power value as it is if NaN is passed e.g. '-'
    if (!isNumber(power)) {
      return { value: getPowerValuePlaceholder(), unit: '' };
    }

    if (power > 999 || shouldBeKilo) {
      return {
        value: formatNumber({
          precision: fractionDigitsAmount,
          numberUnit: NumberUnit.KILO,
        })(power),
        unit: ` k${unit}`,
      };
    }

    return {
      value: formatNumber({
        precision: fractionDigitsAmount,
      })(power),
      unit: ` ${unit}`,
    };
  };

export const getStandardPowerObj = formatPowerObj(false);
export const getKiloPowerObj = formatPowerObj(true);

// Money
export const getFormattedCentsAmount = (amount = 0, fractionName = T.general.currency.fraction) => {
  return `${amount} ${I18n.t(fractionName)}`;
};

export const getCurrencySymbol = () => (
  I18n.l(0, {
    style: 'currency',
    currency: I18n.t(T.general.currency.name),
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).replace(/\d/g, '').trim()
);

export const formatFormNumber = (
  value: string,
  decimalPlaces: number = 2,
) => isValidNumber(value)
    ? numbro(numbro.unformat(value)).format({
      thousandSeparated: true,
      mantissa: decimalPlaces,
    })
    : value;

export const formatPercentage = (value: number | string) =>
  `${value}%`;
