import { Bubble, ClickOutside } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import { IconBadge, IconBadgeProps } from '../IconBadge';

import './HeaderDropdown.component.scss';

interface Props extends IconBadgeProps {
  isOpen?: boolean;
  dataTestId?: string;
}

export const HeaderDropdown: React.FC<Props> = ({
  label,
  icon,
  hasArrow,
  isActive,
  isOpen,
  children,
  dataTestId,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(isOpen);

  React.useEffect(() => {
    setIsDropdownOpen(isOpen);
  }, [isOpen]);

  return (
    <ClickOutside onClick={() => setIsDropdownOpen(false)}>
      <div 
        data-test-id={dataTestId}
        className={'c-header-dropdown'}>
        {children ? (
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className={'c-header-dropdown__trigger'}
            type={'button'}
          >
            <IconBadge
              label={label}
              icon={icon}
              hasArrow={hasArrow}
              isActive={isActive || isDropdownOpen}
            />
          </button>
        ) : (
          <IconBadge
            label={label}
            icon={icon}
            hasArrow={hasArrow}
          />
        )}
        {children ? (
          <div className={classNames('c-header-dropdown__content', {
            'is-open': isDropdownOpen,
          })}>
            <Bubble
              side={'top-right'}
              isThemeDense={true}
            >
              {children}
            </Bubble>
          </div>
        ) : null}
      </div>
    </ClickOutside>
  );
};
