import { DataContainer } from '@kanva/charts';
import { LineChartView } from '@kanva/charts-react';
import { ImageScaleType, LayoutProps, View } from '@kanva/core';
import { ImageView, TextView, View as ViewComponent } from '@kanva/react';
import * as React from 'react';

import { layout, views } from './AnalysisLineChartLine.layout';
import { lineChartStyle, textPaint } from './AnalysisLineChartLine.styles';

interface Props {
  id?: string;
  layoutParams: LayoutProps;
  dataContainer: DataContainer<any>;
  dataSeries: string;
  iconSource: string;
  label: string;
  handleMount?: (view: View<any>) => void;
}

export const AnalysisLineChartLine: React.FC<Props> = ({
  id,
  layoutParams,
  dataContainer,
  dataSeries,
  iconSource,
  label,
  handleMount,
}) => (
  <ViewComponent id={id} layoutParams={{ ...layout.container, ...layoutParams }}>
    <LineChartView
      id={views.lineChart}
      layoutParams={layout.lineChartView}
      dataContainer={dataContainer}
      dataSeries={dataSeries}
      style={lineChartStyle}
      onMount={handleMount}
    />
    <ViewComponent id={views.contentWrapper} layoutParams={layout.contentWrapper}>
      <ImageView
        id={views.image}
        layoutParams={layout.imageView}
        source={iconSource}
        scaleType={ImageScaleType.CENTER_INSIDE}
      />
      <TextView
        layoutParams={layout.textView}
        text={label}
        textPaint={textPaint}
      />
    </ViewComponent>
  </ViewComponent>
);
