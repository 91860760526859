import { getPaths } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { Header, Layout, PageMetaTitle } from '+app/shared/components';
import { Footer } from '+app/shared/containers';
import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, ButtonTheme, Icon, InfoBanner } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

export const ErrorPageWeb: React.FC = () => (
  <Layout
    header={<Header redirectTo={RouteName.DASHBOARD} />}
    footer={<Footer />}
  >
    <PageMetaTitle value={I18n.t(T.general.pageTitles.dashboard.error)} />
    <div className={'c-error-page'}>
      <InfoBanner
        icon={<Icon.Ufo />}
        title={I18n.t(T.errorPage.title)}
        subtitle={(
          <Translate
            value={T.errorPage.details}
            dangerousHTML={true}
          />
        )}
      />
      <div className={'c-error-page__button'}>
        <Button
          size={ButtonSize.SECONDARY}
          theme={ButtonTheme.OUTLINE}
          label={I18n.t(T.errorPage.button)}
          onClick={() => location.href = `${location.origin}${getPaths().ROOT}`}
        />
      </div>
    </div>
  </Layout>
);
