import { ActionsUnion, createAction } from '+utils/redux';
import { BatteryRegistrationAttributes } from './types/batteryRegistration.interface';
import { CustomerNoRegistrationAttributes } from './types/customerNoRegistration.interface';
import { EmailRegistrationAttributes } from './types/emailRegistration.interface';
import { ResetPasswordAttributes } from './types/resetPassword.interface';
import { ResetPasswordRequestAttributes } from './types/resetPasswordRequest.interface';
import { User } from './types/user.interface';

export enum OnboardingState {
  CREATED = 'created',
  EXISTING = 'existing',
  PENDING = 'pending',
  PERSONAL_DETAILS = 'personalDetails',
  VERIFICATION = 'verification',
}

export enum USER_ACTIONS {
  GET_USER = '[User] GET_USER',
  SET_USER = '[User] SET_USER',
  SAVE_EMAIL_FOR_SIGN_UP = '[User] SAVE_EMAIL_FOR_SIGN_UP',
  SAVE_TERMS_VERSION_SIGN_UP = '[User] SAVE_TERMS_VERSION_SIGN_UP',
  SAVE_PRIVACY_VERSION_SIGN_UP = '[User] SAVE_PRIVACY_VERSION_SIGN_UP',
  SAVE_EMAIL_FOR_RESET_PASSWORD = '[User] SAVE_EMAIL_FOR_RESET_PASSWORD',
  SAVE_TOKEN_FOR_ACTION = '[User] SAVE_TOKEN_FOR_ACTION',
  REQUEST_RESET_PASSWORD = '[User] REQUEST_RESET_PASSWORD',
  CREATE_ACCOUNT = '[User] CREATE_ACCOUNT',
  STATE_CHANGE = '[User] STATE_CHANGE',
  IS_PASSWORD_SET = '[User] IS_PASSWORD_SET',
  VERIFY_EMAIL = '[User] VERIFY_EMAIL',
  RESET_PASSWORD = '[User] RESET_PASSWORD',
  REGISTER_BY_BATTERY_DATA = '[User] REGISTER_BY_BATTERY_DATA',
  REGISTER_BY_CUSTOMER_NO = '[User] REGISTER_BY_CUSTOMER_NO',
}

export const UserActions = {
  getUser: () => createAction(USER_ACTIONS.GET_USER),
  setUser: (user: User) => createAction(USER_ACTIONS.SET_USER, { user }),
  setOnboardingState: ({status, email}: { status: OnboardingState, email?: string }) => 
  createAction(USER_ACTIONS.STATE_CHANGE, { status, email }),
  setPasswordSetupState: (isPasswordSet: boolean) => createAction(USER_ACTIONS.IS_PASSWORD_SET, { isPasswordSet }),
  verifyEmail: (data: EmailRegistrationAttributes) => createAction(USER_ACTIONS.VERIFY_EMAIL, { data }),
  requestResetPassword: (data: ResetPasswordRequestAttributes) => 
    createAction(USER_ACTIONS.REQUEST_RESET_PASSWORD, { data }),
  resetPassword: (data: ResetPasswordAttributes) => 
    createAction(USER_ACTIONS.RESET_PASSWORD, { data }),
  registerByBatteryData: (data: BatteryRegistrationAttributes) =>
    createAction(USER_ACTIONS.REGISTER_BY_BATTERY_DATA, { data }),
  registerByCustomerNo: (data: CustomerNoRegistrationAttributes) =>
    createAction(USER_ACTIONS.REGISTER_BY_BATTERY_DATA, { data }),
};

export type UserActions = ActionsUnion<typeof UserActions>;
