import * as classNames from 'classnames';
import * as React from 'react';

import '../InfographicIcon.util.scss';

interface Props {
  x: number;
  y: number;
  isActive?: boolean;
  onClick: () => void;
}

/* tslint:disable:max-line-length */
export const InfographicIconPV: React.FC<Props> = ({
  x,
  y,
  onClick,
  isActive,
}) => (
  <svg
    className={classNames('u-infographic-icon u-infographic-icon__hover', { 'is-active': isActive})}
    x={x}
    y={y}
    viewBox={'0 0 144 56'}
    width={'140'}
    height={'45'}
    onClick={onClick}
    data-test-id={'dashboard-infographic-icon-pv'}
  >
    <polygon
      className={'u-infographic-icon__fill'}
      points={'8.6,49 38.1,4.7 139.8,4.7 139.8,49'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M36.7,4.5L5.8,50.9H140V4.5H36.7z M137.9,25.2h-25.3L114,6.6h23.9V25.2z M111,25.2H84.3l4.5-18.6h23.6L111,25.2z M82.6,25.2H54.3l8.5-18.6h24.2L82.6,25.2z M53.6,26.8h28.6l-5.3,21.9H43.6L53.6,26.8z M83.9,26.8h27l-1.6,21.9H78.6L83.9,26.8z M37.8,6.6H61l-8.5,18.6H25.4L37.8,6.6z M24.3,26.8h27.4l-10,21.9h-32L24.3,26.8z M110.9,48.7l1.6-21.9h25.4v21.9H110.9z'}
    />
  </svg>
);
