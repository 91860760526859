import { Card , Icon } from '@sonnen/shared-web';
import { IconComponent } from '@sonnen/shared-web/src/components/Icon/models';
import classNames from 'classnames';
import * as React from 'react';

import { ContractCardStatus } from '../ContractCardStatus';
import {
  ContractCardProps,
  ContractStatus,
} from './types/ContractCard.interface';

import '../ContractCard/ContractCard.component.scss';

export const ContractCard: React.FC<ContractCardProps> = ({
  title,
  subtitle,
  infoValue,
  infoAttribute,
  icon,
  status,
}) => {
  const CardIcon: IconComponent = Icon[icon];

  return (
    <Card themeGray={status === ContractStatus.TODO}>
      <div className={'c-contract-card__header'}>
        <CardIcon
          className={classNames('c-contract-card__icon', {
            'c-contract-card__icon c-contract-card__icon--active':
              status === ContractStatus.DONE,
          })}
        />
        <ContractCardStatus status={status} />
      </div>
      <div className={'c-contract-card__headline'}>
        {title}
      </div>
      {infoAttribute && (
        <div className={'c-contract-card__info'}>
          <span className={'c-contract-card__info-attribute'}>
            {infoAttribute}
          </span>
          <span className={'c-contract-card__info-value'}>
            {infoValue}
          </span>
        </div>
      )}
      <div className={'c-contract-card__paragraph'}>
        {subtitle}
      </div>
    </Card>
  );
};
