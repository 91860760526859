import { StatisticsResolution } from '@sonnen/shared-web';
import { SagaIterator } from 'redux-saga';
import { put, select, takeLatest } from 'redux-saga/effects';

import { getActiveSiteId } from '+app/shared/store/site/site.selectors';
import { changeHelpAndSupportSubmitSuccess } from '+legacy/core/app/app.actions';
import { QueryActions } from '+shared/store/query/query.actions';
import { Reporter } from '+utils/reporter.util';
import { combineSagas, dataGuard, processQuery } from '+utils/saga';
import { HELP_ACTIONS, HelpActions } from './help.actions';
import { HelpRepository } from './help.repository';
import { GET_TAXATION_DATA_QUERY, SEND_SUPPORT_MESSAGE_QUERY } from './help.state';
import { Taxation } from './types/taxation.interface';

function* checkAndGetTaxationMeasurements(
  action: ReturnType<typeof HelpActions.getTaxationData>,
): SagaIterator {
  const siteId = yield select(getActiveSiteId);
  const resolution = StatisticsResolution.TOTAL;

  if (siteId && action.startDate && action.endDate) {
    yield processQuery(
      GET_TAXATION_DATA_QUERY,
      HelpRepository.checkAndGetTaxationMeasurements, // TODO [refactor] use +analysis.repository.getSiteStatistics
      {
        onSuccess: (res) => dataGuard(HelpActions.setTaxationData)(res as Taxation),
        onFailure: (error) => put(QueryActions.failure(GET_TAXATION_DATA_QUERY, error as Error)),
      },
    )({
      id: siteId,
      filters: {
        start: action.startDate,
        end: action.endDate,
        resolution,
      },
    });
  }
}

function * sendSupportMessage(
  action: ReturnType<typeof HelpActions.sendSupportMessage>,
): SagaIterator {
  yield processQuery(
    SEND_SUPPORT_MESSAGE_QUERY,
    HelpRepository.sendSupportMessage,
    {
      onSuccess: () => put(changeHelpAndSupportSubmitSuccess(true)),
      onFailure: (error) => {
        Reporter.log('Help & Support Form Error Occurred');
        Reporter.reportError(error as Error);

        return put(QueryActions.failure(SEND_SUPPORT_MESSAGE_QUERY, error as Error));
      },
    },
  )({
    attributes: action.attributes,
    relationship: action.relationship,
  });
}

export const taxSagas = combineSagas(
  takeLatest(HELP_ACTIONS.GET_TAXATION_DATA, checkAndGetTaxationMeasurements),
  takeLatest(HELP_ACTIONS.SEND_SUPPORT_MESSAGE, sendSupportMessage),
);
