import { T } from '@sonnen/shared-i18n/customer';
import { isNil } from 'lodash';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';

import {
  createSonnenFlatDataProvider,
  isContractActive,
  isContractType,
} from '+contract/store/helpers/contract.helpers';
import { Contract, ContractTypes } from '+contract/store/types/contract.interface';
import { QueryData } from '+shared/store/query/query.state';
import { StatisticsData } from '+shared/types/statisticsData.interface';
import { InfographicCardConnectionStatusType, InfographicHelper } from '../../containers';
import { InfographicContractCardData, InfographicContractCardStatusType } from './InfographicContractCard.types';

const getCardData = (
  contract: Contract | undefined,
  sonnenFlatStatistics: StatisticsData | undefined,
): InfographicContractCardData => {
  if (!contract) { return {}; }

  return isContractType(contract, ContractTypes.SONNEN_FLAT_HOME) ||
    isContractType(contract, ContractTypes.SONNEN_FLAT_X)
    ? getFlatContractData(contract, sonnenFlatStatistics)
    : getEnergyTariffContractData(contract);
};

const getCardStatus = (
  contract: Contract | undefined,
  contractQuery: QueryData,
  isNoQuota: boolean,
  online: boolean,
): InfographicCardConnectionStatusType | InfographicContractCardStatusType => {
  const isSupplyState = contract
    && isContractType(contract, ContractTypes.SONNEN_FLAT_X)
    && moment(contract.deliveryStartAt) < moment()
    && (isNil(contract.usageAllowanceActivatedOn) || moment(contract.usageAllowanceActivatedOn) > moment());
  const isContractDataMissing = InfographicHelper.isDataError(contract, contractQuery) &&
    isContractActive(contract!) &&
    isNoQuota &&
    isContractType(contract, ContractTypes.SONNEN_FLAT_HOME);
  const isActive = contract && !isContractActive(contract);

  switch (true) {
    case isActive:
      return InfographicContractCardStatusType.PUCHARSE_COMPLETE;
    case isSupplyState:
      return InfographicContractCardStatusType.SUPPLY;
    case !online:
      return InfographicCardConnectionStatusType.OFFLINE;
    case isContractDataMissing:
      return InfographicContractCardStatusType.MISSING_CONTRACT_DATA;
    default:
      const isPurchaseStatus = contract
        && isContractType(contract, ContractTypes.SONNEN_FLAT_X)
        && !isContractActive(contract);

      return isPurchaseStatus
        ? InfographicContractCardStatusType.PUCHARSE_COMPLETE
        : InfographicCardConnectionStatusType.OK;
  }
};

const getFlatContractData = (
  contract: Contract | undefined,
  sonnenFlatStatistics: StatisticsData | undefined,
) => ({
  title: I18n.t(T.dashboard.infographic.contract.sonnenFlat.title),
  subTitle: I18n.t(T.dashboard.infographic.contract.sonnenFlat.headline),
  value: getPercentageUsage(contract, sonnenFlatStatistics),
  unit: I18n.t(T.dashboard.infographic.contract.sonnenFlat.valueLabel),
});

const getPercentageUsage = (
  contract: Contract | undefined,
  sonnenFlatStatistics: StatisticsData | undefined,
): number => {
  if (contract) {
    return createSonnenFlatDataProvider({ contract, sonnenFlatStatistics }).getPercentageUsage();
  }

  return 0;
};

const getEnergyTariffContractData = (
  contract: Contract | undefined,
) => {
  const daysSinceContractStarted = contract
    ? moment().diff(moment(contract.deliveryStartAt), 'days')
    : '-';
  return {
    title: I18n.t(T.dashboard.infographic.contract.sonnenStrom.title),
    subTitle: I18n.t(T.dashboard.infographic.contract.sonnenStrom.headline),
    value: daysSinceContractStarted,
    unit: I18n.t(T.dashboard.infographic.contract.sonnenStrom.valueLabel),
  };
};

export const InfographicContractCardHelper = {
  getCardData,
  getCardStatus,
};
