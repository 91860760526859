import { SagaIterator } from 'redux-saga';
import { call, CallEffect, race, take, TakeEffect } from 'redux-saga/effects';

import { QUERY_ACTIONS, QueryActions } from '../store/query/query.actions';

type ResolvedQuery = Record<'success' | 'failure', QueryActions>;

export const takeResolvedQuery: (expectedQuery: string) => CallEffect = expectedQuery =>
  call(function* (queryName: string): SagaIterator {
    while (true) {
      const res: ResolvedQuery | undefined = yield race<TakeEffect>({
        success: take(QUERY_ACTIONS.SUCCESS),
        failure: take(QUERY_ACTIONS.FAILURE),
      });

      if (!res) { return; }

      const { success, failure } = res as ResolvedQuery;
      if (success && success.queryName === queryName || failure && failure.queryName === queryName) {
        return res;
      }
    }
  }, expectedQuery);
