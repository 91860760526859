import { HttpResponse } from '@coolio/http';
import * as moment from 'moment';

import { CONFIG } from '+config';
import { ProductMigrationCampaignsData } from '+product-migration/store/types/productMigrationCampaigns.interface';
import { productMigrationHttpClient, productMigrationJsonApiClient } from '+shared/network';
import { ProductMigrationTermsAndConditionsForm } from '../components/ProductMigrationTermsAndConditions/ProductMigrationTermsAndConditions.helper';
import { DsoListData } from './types/dsoOperators.interface';
import { CreateProductConfiguration, ProductConfigurationData } from './types/productConfiguration.interface';
import { CessionContract, CessionContractData, CessionSignServiceDocData, CessionSignServiceStatusData } from './types/productMigrationCession.interface';
import { ProductMigrationStatusData } from './types/productMigrationStatus.interface';

const getStatus = () => productMigrationJsonApiClient
  .get<ProductMigrationStatusData>(`${CONFIG.API.PRODUCT_MIGRATION}/user/status`)
  .send()
  .then(response => response.element);

const getConfiguration = () => productMigrationJsonApiClient
  .get<ProductConfigurationData>(`${CONFIG.API.PRODUCT_MIGRATION}/configuration`)
  .send()
  .then(response => response.element);

const getDsoOperators = (address: {
  street: string,
  postalCode: string,
  city: string,
  countryCode: string,
}) => productMigrationHttpClient
  .post<DsoListData>(`${CONFIG.API.PRODUCT_MIGRATION}/configuration/dso-list`, {
    body: {
      data: {
        type: 'address',
        attributes: address,
      },
    },
  })
  .then((response: HttpResponse) => {
    return response.parsedBody();
  })
  // TODO: take care of this in coolio parser when final structure is ready
  .catch(async (response: HttpResponse) => {
    throw await (response as any)?.response.parsedBody();
  });

const createConfiguration = ({ data, locale }: { data: CreateProductConfiguration, locale: string }) => 
  productMigrationHttpClient
    .post<string>(`${CONFIG.API.PRODUCT_MIGRATION}/configuration`, {
      body: { data },
      headers: {
        'Accept-Language': locale,
      },
    })
    .then((response: HttpResponse) => response.parsedBody())
    .catch(async (response: HttpResponse) => {
      throw await (response as any)?.response.parsedBody();
    });

const createCession = ({
  customerNumber,
  contractNumber,
  systemKey,
  contractData,
}: {
  customerNumber: string,
  contractNumber: string,
  systemKey?: string,
  contractData: CessionContract,
}) => productMigrationJsonApiClient
  .post<CessionContractData>(`${CONFIG.API.PRODUCT_MIGRATION}/cession`)
  .ofType('cession')
  .withAttributes({
    customerNumber,
    contractNumber,
    systemKey,
    contractData,
  })
  .send();

const getCession = ({ customerNumber }: { customerNumber: string; }) => productMigrationHttpClient
  .get<CessionSignServiceDocData>(`${CONFIG.API.PRODUCT_MIGRATION}/cession/${customerNumber}`)
  .then((response: HttpResponse) => response.parsedBody())
  .catch(async (response: HttpResponse) => {
    throw await (response as any)?.response.parsedBody();
  });

const getCessionStatus = ({ customerNumber }: { customerNumber: string; }) => productMigrationHttpClient
  .get<CessionSignServiceStatusData>(`${CONFIG.API.PRODUCT_MIGRATION}/cession/status/${customerNumber}`)
  .then((response: HttpResponse) => response.parsedBody())
  .catch(async (response: HttpResponse) => {
    throw await (response as any)?.response.parsedBody();
  });

const acceptOffer = ({ contractNumber, tnC, contactId }: {
  contractNumber: string,
  contactId: string,
  tnC: ProductMigrationTermsAndConditionsForm,
}) => productMigrationHttpClient
  .post<string>(`${CONFIG.API.PRODUCT_MIGRATION}/offer`, {
    body: {
      oldContractId: contractNumber,
      signed_by: {
        contactId,
        date: moment().format('YYYY-MM-DD'),
      },
      tnc: {
        privacyTncAccepted: true,
        cancellationTncAccepted: tnC.cancellationTnCAccepted,
        productTncAccepted: tnC.productTnCAccepted,
        confirmPersonalData: tnC.confirmPersonalData,
        authorizeBankPayments: tnC.authorizeBankPayments,
      },
    },
  })
  .then((response: HttpResponse) => response.parsedBody());

const getActiveCampaigns = ({ date }: { date: string; }) => productMigrationJsonApiClient
  .get<ProductMigrationCampaignsData>(`${CONFIG.API.PRODUCT_MIGRATION}/gateway/active-campaigns?date=${date}`)
  .send()
  .then(response => response.element);

export const ProductMigrationRepository = {
  createConfiguration,
  getConfiguration,
  getStatus,
  getDsoOperators,
  createCession,
  getCession,
  getCessionStatus,
  acceptOffer,
  getActiveCampaigns,
};
