import { GuideProductInfoFooter, GuideProductInfoSection } from '+guide/+information/components';
import { T } from '@sonnen/shared-i18n/customer';
import { PageHeadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { sections } from './GuideProductInfo.helper';

export const GuideProductInfo: React.FC = () => (
  <>
    <PageHeadline>
      {I18n.t(T.customerGuidance.productInformations.headline)}
    </PageHeadline>
    {sections.map((section, index) =>
      <GuideProductInfoSection
        key={index}
        headline={section.headline}
        description={section.description}
        image={section.image}
      />,
    )}
    <GuideProductInfoFooter />
  </>
);
