import { useJsonApi } from '+app/shared/network';
import { storeProvider } from '+app/store/store.provider';
import { CONFIG } from '+config';
import { authFetchPostMethod } from '+legacy-helpers/fetch';
import { getRedirectUri } from './auth.helpers';
import { getRefreshToken } from './auth.selectors';
import { Tokens } from './auth.state';
import { ReverseChannelTokenData } from './types/reverseChannel.interface';

const getTokens = (
  { code, verifier }:
    { code: string, verifier: string },
) => authFetchPostMethod<Tokens>('oauth/token', {
  grant_type: 'authorization_code',
  code,
  client_id: CONFIG.AUTH.AUTH_CLIENT_ID,
  redirect_uri: getRedirectUri(),
  code_verifier: verifier,
});

const refreshAccessToken = (signal?: AbortSignal) =>
  authFetchPostMethod<Tokens>('oauth/token', {
    client_id: CONFIG.AUTH.AUTH_CLIENT_ID,
    refresh_token: getRefreshToken(storeProvider.getState()),
    grant_type: 'refresh_token',
  }, { signal });

const revokeToken = (token: string) =>
  authFetchPostMethod<{}>('oauth/revoke', {
    client_id: CONFIG.AUTH.AUTH_CLIENT_ID,
    token,
  });

const getReverseChannelToken = (batteryId: string) =>
  useJsonApi().get<ReverseChannelTokenData>(`${CONFIG.API.USER_DATA}/battery-systems/${batteryId}/generate-token`)
    .send()
    .then(response => response.element);

export const AuthRepository = {
  getTokens,
  getReverseChannelToken,
  refreshAccessToken,
  revokeToken,
};
