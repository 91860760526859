import classNames from 'classnames';
import * as React from 'react';

import './GuideGridStretched.component.scss';

interface Props {
  isThemeOneThird?: boolean;
}

export const GuideGridStretched: React.FC<Props> = ({
  isThemeOneThird,
  children,
}) => {
  const elements = React.Children.toArray(children);
  const elementCount = elements.length;
  
  return (
    <div className={'c-guide-grid-stretched'}>
      {elements.map((child, i) => (
        <div
          className={classNames('c-guide-grid-stretched__item', {
            'c-guide-grid-stretched__item--1/3': isThemeOneThird,
            'c-guide-grid-stretched__item--no-gap': i === elementCount - 1,
          })}
          key={`guide-grid-stretched-item-${i}`}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
