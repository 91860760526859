import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, ButtonTheme, Icon, MediaQuery } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { SpecsItem } from '+components';
import { Document } from '../../store/types/battery.interface';
import { BatteryDownloadsTableRow } from '../BatteryDownloadsTableRow';

import './BatteryDownloadsItem.component.scss';

interface Props {
  item: Document;
}

export const BatteryDownloadsItem: React.FC<Props> = ({ item }) =>
  <div className={'c-battery-downloads-item'}>
    <Media query={MediaQuery.UP_SM}>
      {(isDesktop: boolean) => isDesktop ? (
        <BatteryDownloadsTableRow
          dataTestId={item.id}
          content={item.title}
          secondaryContent={item.fileName}
          download={
            <Button
              dataTestId={`${item.id}-download-button`}
              label={I18n.t(T.general.labels.download)}
              size={ButtonSize.SECONDARY}
              theme={ButtonTheme.OUTLINE}
              onClick={() => window.open(item.url, '_blank')}
              isBlock={true}
            />
          }
        />
      ) : (
        <BatteryDownloadsTableRow
          content={
            <SpecsItem
              dataTestId={item.id}
              title={item.title}
              value={item.fileName}
            />
          }
          download={
            <button
              data-test-id={`${item.id}-download-button`}
              type={'button'}
              onClick={() => window.open(item.url, '_blank')}
            >
              <Icon.Download className={'c-battery-downloads-item__link-icon'}/>
            </button>
          }
        />
      )}
    </Media>
  </div>;
