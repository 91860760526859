import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { useNestedNav } from '../../NestedNav';
import { NestedNavList } from '../../NestedNav/NestedNavList';
import { NestedNavListItem } from '../../NestedNav/NestedNavListItem';
import { NestedNavSlideContent } from '../../NestedNav/NestedNavSlideContent';

export const MobileSideNavNestedAccountList: React.FC = () => {
  const { push } = useNestedNav();

  const goToDetails = () => push(1);

  const goToPassword = () => push(2);

  const goToSettings = () => push(3);

  return (
    <NestedNavSlideContent>
      <NestedNavList>
        <NestedNavListItem
          icon={'AboutMe'}
          title={I18n.t(T.general.navigation.aboutMe)}
          theme={'light'}
          onClick={goToDetails}
        />
        <NestedNavListItem
          icon={'Padlock'}
          title={I18n.t(T.general.navigation.changePassword)}
          theme={'light'}
          onClick={goToPassword}
        />
        <NestedNavListItem
          icon={'Settings'}
          title={I18n.t(T.general.navigation.settings)}
          theme={'light'}
          onClick={goToSettings}
        />
      </NestedNavList>
    </NestedNavSlideContent>
  );
};
