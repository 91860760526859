import * as moment from 'moment';
import { delay } from '../../helpers/async';
import { getFrequency } from './frequency.api';
import { FrequencyCallback, FrequencyItem } from './frequency.types';

const getFrequencyData = async (live: boolean = false, retry: number = 0): Promise<FrequencyItem> => {
  try {
    const freqs: FrequencyItem = await getFrequency(live);

    if (!Object.keys(freqs).length) {
      if (retry < 3) {
        throw new Error('Could not fetch data');
      }
      await delay(moment.duration(1, 'second').asMilliseconds());
      return await getFrequencyData(false, retry + 1);
    }
    return freqs;
  } catch (error) {
    return { error };
  }
};

export class FrequencyService {
  callbacks: Array<FrequencyCallback<FrequencyItem>> = [];
  active = false;
  isLive = true;

  constructor(getInitialData: boolean = true) {
    this.isLive = !getInitialData;
  }

  async start() {
    this.active = true;
    while (this.active) {
      /* eslint-disable no-await-in-loop */
      /* Enable await in loop for a constant stream implementation */
      const data = await getFrequencyData(this.isLive);
      this.callbacks.forEach(cb => cb(data));
      this.isLive = true;
      await delay(moment.duration(1, 'second').asMilliseconds());
      /* eslint-enable no-await-in-loop */
    }
  }

  stop() {
    this.active = false;
  }

  subscribe(callback: FrequencyCallback<FrequencyItem>) {
    this.callbacks.push(callback);
  }
}
