import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { RegexConstraint } from '+app/shared/helpers';
import { ValidationBadge } from '../ValidationBadge';

import './StringComplexityChecker.component.scss';

interface Props {
  rules: RegexConstraint[];
  subject: string | undefined;
}

export const StringComplexityChecker: React.FC<Props> = ({
  rules,
  subject = '',
}) => (
  <div className={'c-string-complexity-checker'}>
    {rules.map(rule => (
      <div
        className={'c-string-complexity-checker__item'}
        key={rule.label}
      >
        <ValidationBadge
          dataTestId={rule.dataTestId}
          label={I18n.t(rule.label)}
          isValid={rule.regex.test(subject)}
        />
      </div>
    ))}
  </div>
);
