import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';

enum CardNotificationTypes {
  WARNING = 'warning',
  SUCCESS = 'success',
}

enum CardNotificationIcons {
  WARNING = 'Warning',
  SUCCESS = 'Checkmark',
}

interface ExceedingProps {
  type: CardNotificationTypes;
  icon: React.ReactNode;
  children: React.ReactNode;
}

const getProps = (isQuotaExceeded?: boolean, isExceedingExpected?: boolean): ExceedingProps => {
  const WarningIcon = Icon[CardNotificationIcons.WARNING];
  const SuccessIcon = Icon[CardNotificationIcons.SUCCESS];

  if (isQuotaExceeded) {
    return {
      type: CardNotificationTypes.WARNING,
      icon: <WarningIcon />,
      children: <Translate value={T.yourFlat.expertView.cardNotifications.consumptionExceeded} />,
    };
  } else if (isExceedingExpected) {
    return {
      type: CardNotificationTypes.WARNING,
      icon: <WarningIcon />,
      children: <Translate value={T.yourFlat.expertView.cardNotifications.consumptionExceedingExpected} />,
    };
  }

  return {
    type: CardNotificationTypes.SUCCESS,
    icon: <SuccessIcon />,
    children: <Translate value={T.yourFlat.expertView.cardNotifications.consumptionCovered} />,
  };
};

export const withExceeding = (isQuotaExceeded?: boolean, isExceedingExpected?: boolean) =>
  <P extends {}>(Component: React.StatelessComponent<P>) => {
    const exceedingProps = getProps(isQuotaExceeded, isExceedingExpected);

    const EnhancedComponent: React.SFC<Partial<P & ExceedingProps>> = props =>
      <Component
        {...props as P}
        {...exceedingProps}
      />;
    EnhancedComponent.displayName = `WithExceeding(${Component.name})`;

    return EnhancedComponent;
  };
