import * as React from 'react';

interface Props {
  subtitle?: string;
  contentText?: string;
}

export const GuideInfoCardText: React.FC<Props> = ({ subtitle, contentText, children }) => (
  <>
    {subtitle}
    {children && (
      <div className={'c-guide-info-card-text__content-text'}>
        {children}
      </div>
    )}
    {!children && contentText && (
      <p className={'c-guide-info-card-text__content-text'}>
        {contentText}
      </p>
    )}
  </>
);
