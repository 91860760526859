import { Query, QueryState, QueryStatus } from '+shared/store/query/query.state';

const isPending = (query: Query) => query.status === QueryStatus.PENDING;
const isNotReady = (query: Query) => !query.status || query.status === QueryStatus.FAILURE;
const hasFailed = (query: Query) => query.status === QueryStatus.FAILURE;
const hasSucceeded = (query: Query) => query.status === QueryStatus.SUCCESS;
const hasBeenInterrupted = (query: Query) => query.status === QueryStatus.INTERRUPTED;

export const getStatus = <T extends any>(query: Query<T>): QueryState => ({
  success: hasSucceeded(query),
  error: hasFailed(query),
  pending: isPending(query),
  notReady: isNotReady(query),
  interrupted: hasBeenInterrupted(query),
});

export const isQueryPending = (queryState: QueryState) => queryState.pending;

export const isQuerySuccess = (queryState: QueryState) => queryState.success;

export const isQueryError = (queryState: QueryState) => queryState.error;

export const getCombinedStatus = (statuses: Array<ReturnType<typeof getStatus>>) => {
  if (statuses.some(status => status.error)) {
    return getStatus({ status: QueryStatus.FAILURE });
  }

  if (statuses.every(status => status.success)) {
    return getStatus({ status: QueryStatus.SUCCESS });
  }

  return getStatus({ status: QueryStatus.PENDING });
};
