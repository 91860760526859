import {
  Icon,
  PageWidthWrapper,
} from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import Media from 'react-media';

import { CONFIG } from '+config';
import { breakpointUp } from '+legacy/helpers/breakpoints';
import { NotificationCategory } from '+shared/store/notification/types/notification.interface';

import './Banner.component.scss';

type I18nLiteral = string | [string, Record<string, string>];

export interface BannerContent {
  description: React.ReactNode | [string, Record<string, string>];
  title: I18nLiteral;
  type: NotificationCategory;
  icon?: React.ReactNode;
  hasCloseButton?: boolean;
  isWarning?: boolean;
  actionButton?: {
    label: string,
    onClick?: () => void;
  };
}

interface Props {
  content: BannerContent;
  isOpen?: boolean; // @TODO: get rid off when the data delay is fixed
}

const renderBanner = (
  content: BannerContent,
  toggleBannerDescription?: () => void,
  isOpen?: boolean,
): React.ReactNode => {
  const { title, description, actionButton, isWarning } = content;

  return (
    <div className={'c-banner__content'}>
      <span className={classNames('c-banner__icon-wrapper', {
        'c-banner__icon-wrapper--warning': isWarning,
      })}>
        {isWarning
          ? <Icon.Warning className={'c-banner__icon--warning'} />
          : <Icon.Info className={'c-banner__icon'} />}
      </span>
      <div className={'c-banner__body'}>
        <div className={'c-banner__content-header'}>
          <div className={'c-banner__header'}>
            <p className={'c-banner__title'}>
              {title}
            </p>
            {!toggleBannerDescription && description && (
              <p className={'c-banner__description'}>
                &nbsp;{description}
              </p>
            )}
          </div>
          <div className={'c-banner__side'}>
            {actionButton &&
              <div className={classNames('c-banner__side-item', {
                'c-banner__side-item--spaced': toggleBannerDescription,
              })}>
                <button
                  type={'button'}
                  className={'c-banner__link'}
                  onClick={actionButton.onClick}
                >
                  {actionButton.label}
                </button>
              </div>
            }
            {toggleBannerDescription && !actionButton && description && (
              <button
                className={'c-banner__side-item'}
                onClick={toggleBannerDescription}
              >
                <Icon.Angle className={classNames('c-banner__arrow-icon', {
                  'c-banner__arrow-icon--rotated': CONFIG.IS_MOBILE ? !isOpen : isOpen,
                })} />
              </button>
            )}
          </div>
        </div>
        {toggleBannerDescription && description && (
          <div className={classNames('c-banner__description', {
            'c-banner__description--closed': !isOpen,
            'c-banner__description--m-closed': !isOpen && CONFIG.IS_MOBILE,
          })}>
            {description}
          </div>
        )}
      </div>
    </div>
  );
};

export const Banner = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(props.isOpen || !!props.content.isWarning);
  const { content } = props;
  const toggleBannerDescription = (): void => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classNames('c-banner', {
      'c-banner--warning': content.isWarning,
    })}>
      <PageWidthWrapper>
        <div className={'c-banner__inner'}>
          <Media query={{ minWidth: breakpointUp('LG') }}>
            {(isDesktop: boolean) => (isDesktop
              ? renderBanner(content)
              : renderBanner(content, toggleBannerDescription, isOpen)
            )}
          </Media>
        </div>
      </PageWidthWrapper>
    </div>
  );
};
