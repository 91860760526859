import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, DS } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

// TODO unify helpers
import { LiveStateHelper } from '+live-state/helpers/LiveState.helpers';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { Site } from '+shared/store/site/types/site.interface';
import { EFAnimations } from '../EFAnimations';
import { EFCircle } from '../EFCircle';
import { EFCircleExplanationTooltip } from '../EFCircleExplanationTooltip';
import { LiveEnergyFlowHelper } from '../LiveEnergyFlow.helper';

export interface EFCircleGridProps {
  liveData: SiteLive | undefined;
  isActive: (predicate: boolean | undefined) => boolean;
  isAppOnline: boolean;
  isExplanationActive: boolean | undefined;
  site: Site | undefined;
  isBatteryToGridActive: boolean | undefined;
  isGridToBatteryActive: boolean | undefined;
  isGridToConsumptionActive: boolean | undefined;
  isProductionToGridActive: boolean | undefined;
  isGridServiceActive: boolean | undefined;
  isGridInvalid: boolean | undefined;
}

export const EFCircleGrid: React.FC<EFCircleGridProps> = ({
  liveData,
  isActive,
  isAppOnline,
  isExplanationActive,
  site,
  isBatteryToGridActive,
  isGridToBatteryActive,
  isGridToConsumptionActive,
  isProductionToGridActive,
  isGridServiceActive,
  isGridInvalid,
}) => {
  const isMember = !!site && LiveEnergyFlowHelper.isCommunityMember(site);
  const isVpp = isGridServiceActive && isActive(isGridToBatteryActive || isBatteryToGridActive);
  const value = !!liveData ? Math.abs(LiveStateHelper.getGridValue(liveData)) : 0;

  return (
    <>
      <EFCircle
        dataTestId={'live-state-circle-grid'}
        type={'grid'}
        icon={isMember
          ? <DS.Icon.Community />
          : <DS.Icon.Grid />}
        label={I18n.t(isMember
          ? T.dashboard.energyFlowChart.sonnenCommunity
          : T.dashboard.energyFlowChart.grid)}
        key={value}
        value={value}
        hasValueErrorIcon={isGridInvalid}
        tooltip={isExplanationActive ? (
          <EFCircleExplanationTooltip dataTestId={'live-state-energy-flow-explanation-tooltip-grid'}>
            <DefaultParagraph>
              {(isVpp)
                ? I18n.t(T.dashboard.liveStateChart.battery.tooltipBatteryVpp)
                : (isMember
                    ? I18n.t(T.dashboard.liveStateChart.grid.tooltipGridCommunity)
                    : I18n.t(T.dashboard.liveStateChart.grid.tooltipGrid)
                )
              }
            </DefaultParagraph>
          </EFCircleExplanationTooltip>
        ) :
          null
        }
        isBlured={isExplanationActive}
        isTextBelow={true}
        isOnline={isAppOnline && liveData?.online}
        isActive={isActive(
          isGridToBatteryActive
          || isGridToConsumptionActive
          || isProductionToGridActive
          || isBatteryToGridActive,
        )}
        isAnimated={isVpp}
      />
      <EFAnimations isAnimationActive={isVpp} />
    </>
  );
};
