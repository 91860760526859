import { StoreState } from '+app/store/store.interface';
import { createSelector } from 'reselect';

export const getCurrentRoute = (store: StoreState) => store.router.location;

export const getAuthState = (store: StoreState) => store.auth;

export const getLastAccessedAuthUrl = createSelector(
  getAuthState,
  auth => auth.lastAccessedAuthUrl,
);

export const getAuthCode = createSelector(
  getAuthState,
  auth => auth.code,
);

export const getCodeVerifier = createSelector(
  getAuthState,
  auth => auth.verifier,
);

export const getAuthorizationHeader = createSelector(
  getAuthState,
  auth => auth.tokenType
    ? `${auth.tokenType} ${auth.accessToken}`
    : `${auth.accessToken}`,
);

export const getAuthorizationRetryCount = createSelector(
  getAuthState,
  auth => auth.retryCount,
);

export const getAccessToken = createSelector(
  getAuthState,
  auth => auth.accessToken,
);

export const getRefreshToken = createSelector(
  getAuthState,
  auth => auth.refreshToken,
);

export const hasLoggedInOnce = createSelector(
  getAuthState,
  auth => auth.hasLoggedInOnce,
);

export const isAuthenticated = createSelector(
  getAuthState,
  auth => !!auth.accessToken,
);

export const isImpersonated = createSelector(
  getAuthState,
  auth => !!auth.impersonatedUserId,
);

export const getImpersonatedUserId = createSelector(
  getAuthState,
  auth => auth.impersonatedUserId,
);

export const getTokenForAction = createSelector(
  getAuthState,
  auth => auth.tokenForAction,
);

export const getReverseChannelToken = createSelector(
  getAuthState,
  state => state.reverseChannelToken,
);
