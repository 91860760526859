import {
  AxisOrientation,
  DataContainer,
  DataContainerTooltipExtension,
  DataContainerTransformExtension,
  DataContainerTransformExtensionOptions,
  LineChartView,
  PointAccessor,
} from '@kanva/charts';
import { AxisView, ChartZoomView } from '@kanva/charts-react';
import { rgba, View } from '@kanva/core';
import { View as ViewComponent } from '@kanva/react';
import { T } from '@sonnen/shared-i18n/customer';
import {
  AccessoriesChartSeries,
  AccessoriesSeriesKey,
  BASE_TICK_COUNT,
  MediaQuery,
  SharedChartColors,
  TimeHelper,
} from '@sonnen/shared-web';
import { Moment } from 'moment';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';
import { DeepPartial } from 'redux';

import { FullWidthKanva } from '+components';
import backupBoxIcon from '+images/backup-box.svg';
import heaterPowerIcon from '+images/heater-power.svg';
import { getStatus } from '+utils/query.util';
import { analysisPointAccessor, mapDataSeries, resolveAreaXPosition } from '../../helpers/analysis.helper';
import {
  ACCESSORIES_CHARTS_WRAPPER_HEIGHT,
  ACCESSORIES_SINGLE_CHART_WRAPPER_HEIGHT,
} from '../AnalysisAccessoriesChart';
import { selectAreaZoomStyle, xAxisStyle } from '../AnalysisEnergyFlow/AnalysisEnergyFlow.styles';
import { AnalysisLineChartLine } from '../AnalysisLineChartLine';
import { Views } from './AnalysisLineChart.helper';
import { getLayout } from './AnalysisLineChart.layout';

interface Props {
  selectedDate: Moment;
  dataSeries: AccessoriesChartSeries;
  queryStatus: ReturnType<typeof getStatus>;
  tooltipExtension: DataContainerTooltipExtension | undefined;
  transformExtension: DataContainerTransformExtension | undefined;
  scaleOptions: DeepPartial<DataContainerTransformExtensionOptions>;
  hasBackupBox: boolean;
  hasHeater: boolean;
  isChartZoomed: boolean;
  onMount: (x: number) => (view: View<any>) => void;
}

export class AnalysisLineChart extends React.PureComponent<Props> {
  readonly defaultDataContainer = new DataContainer()
    .setXAxisParameters({
      labelAccessor: TimeHelper.formatUnixToMinutes,
      roundTo: 5,
      tickCount: BASE_TICK_COUNT,
    })
    .setXBoundsExtension([0, 24 * 60])
    .setPointAccessor(analysisPointAccessor as PointAccessor<unknown>);

  setDataSeries(dataSeries: AccessoriesChartSeries) {
    this.defaultDataContainer.setData(mapDataSeries({
      [AccessoriesSeriesKey.HEATER_POWER]: dataSeries[AccessoriesSeriesKey.HEATER_POWER],
      [AccessoriesSeriesKey.BACKUP_BOX]: dataSeries[AccessoriesSeriesKey.BACKUP_BOX],
      [AccessoriesSeriesKey.HEATER_TEMPERATURE]: dataSeries[AccessoriesSeriesKey.HEATER_TEMPERATURE],
    }));
  }

  handleMount = (view: View<any>) => {
    const { onMount, selectedDate } = this.props;
    const position =
      resolveAreaXPosition(AccessoriesSeriesKey.HEATER_POWER)(this.defaultDataContainer, selectedDate) || 1;
    const { absoluteX } = (view as LineChartView<any>).getCanvasPositionForPoint({ x: position, y: 0 });
    onMount(absoluteX)(view);
  };

  setTooltipExtension() {
    const { tooltipExtension } = this.props;

    if (tooltipExtension) {
      this.defaultDataContainer.addExtension(tooltipExtension);
    }
  }

  setTransformExtension() {
    const { transformExtension, scaleOptions } = this.props;

    if (transformExtension) {
      transformExtension.setOptions(scaleOptions);
      this.defaultDataContainer.addExtension(transformExtension);
    }
  }

  componentDidMount() {
    this.setDataSeries(this.props.dataSeries);
    this.setTooltipExtension();
    this.setTransformExtension();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { dataSeries, transformExtension, tooltipExtension } = this.props;
    if (prevProps.dataSeries !== dataSeries) {
      this.setDataSeries(dataSeries);
    }
    if (prevProps.transformExtension !== transformExtension) {
      this.setTransformExtension();
    }
    if (prevProps.tooltipExtension !== tooltipExtension) {
      this.setTooltipExtension();
    }
  }

  componentWillUnmount() {
    const {
      transformExtension,
      tooltipExtension,
    } = this.props;

    if (tooltipExtension) {
      this.defaultDataContainer.removeExtension(tooltipExtension);
    }

    if (transformExtension) {
      this.defaultDataContainer.removeExtension(transformExtension);
    }
  }

  render() {
    const { queryStatus, hasBackupBox, hasHeater, isChartZoomed } = this.props;
    const layout = getLayout(hasHeater, hasBackupBox);

    const desktopChart = {
      borders: { xAxis: 3, yAxis: 3 },
      layout,
    };

    const mobileChart = {
      borders: { xAxis: 1, yAxis: 0 },
      layout,
    };

    return(
      <Media query={MediaQuery.UP_SM}>
      {(isDesktop: boolean) => (
        <FullWidthKanva
          height={(hasHeater && hasBackupBox)
            ? ACCESSORIES_CHARTS_WRAPPER_HEIGHT
            : ACCESSORIES_SINGLE_CHART_WRAPPER_HEIGHT
          }
          isLoading={queryStatus.pending}
        >
          <ViewComponent
            layoutParams={layout.wrapper}
            border={{ left: isDesktop ? desktopChart.borders.xAxis : mobileChart.borders.xAxis }}
            borderColor={rgba(SharedChartColors.DARK, .1)}
          >
            {hasHeater && (
              <AnalysisLineChartLine
                id={Views.HEATER_CHART}
                layoutParams={layout.heaterPowerChart}
                dataContainer={this.defaultDataContainer}
                dataSeries={AccessoriesSeriesKey.HEATER_POWER}
                iconSource={heaterPowerIcon}
                label={I18n.t(T.analysis.accessoriesChart.sonnenHeater)}
                handleMount={this.handleMount}
              />
            )}
            {hasBackupBox && (
              <AnalysisLineChartLine
                id={Views.BACKUP_BOX_CHART}
                layoutParams={layout.backupBoxChart}
                dataContainer={this.defaultDataContainer}
                dataSeries={AccessoriesSeriesKey.BACKUP_BOX}
                iconSource={backupBoxIcon}
                label={I18n.t(T.analysis.accessoriesChart.sonnenBackupbox)}
              />
            )}
          </ViewComponent>
          <ChartZoomView
            dataContainer={this.defaultDataContainer}
            layoutParams={layout.wrapper}
            style={selectAreaZoomStyle}
          />
          <AxisView
            id={Views.X_AXIS}
            border={{ top: isDesktop ? desktopChart.borders.xAxis : mobileChart.borders.xAxis }}
            borderColor={rgba(SharedChartColors.DARK, .1)}
            layoutParams={layout.xAxis}
            dataContainer={this.defaultDataContainer}
            orientation={AxisOrientation.HORIZONTAL}
            style={{
              ...xAxisStyle,
              wrapLabelsOnEdge: !isChartZoomed,
            }}
          />
        </FullWidthKanva>
      )}
    </Media>
    );
  }
}
