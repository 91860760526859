import { createLayoutMap, MATCH_PARENT, PARENT_ID, WRAP_CONTENT } from '@kanva/core';

export const views = {
  contentWrapper: 'contentWrapper',
  lineChart: 'lineChart',
  image: 'image',
};

export const layout = createLayoutMap({
  container: {
    width: MATCH_PARENT,
    height: WRAP_CONTENT,
  },
  textView: {
    alignTop: views.image,
    alignBottom: views.image,
    toEndOf: views.image,
    margin: {
      left: 4,
    },
  },
  imageView: {
    width: 12,
    height: 12,
  },
  contentWrapper: {
    alignStart: views.lineChart,
    alignTop: views.lineChart,
    width: 200,
    margin: {
      top: 10,
      left: 10,
    },
  },
  lineChartView: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
});
