import { CONFIG } from '+config';
import { FrequencyItem } from './frequency.types';

const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

export const getFrequency = (isLive: boolean = false): Promise<FrequencyItem> =>
  fetch(`${CONFIG.API.FREQUENCY}/frequencies${isLive ? '/latest' : ''}`, {
    headers: DEFAULT_HEADERS,
  })
    .then(res => res.json());
