import { ProfileData } from '+account/store/types/profile.interface';
import { CONFIG } from '+config';
import { ResultGeom } from '+legacy/features/map/communityMap.types';
import { contentHubHttpClient, useJsonApi } from '+shared/network';
import { CommunityData } from './types/community.interface';

const getCommunityStatsData = () =>
  useJsonApi().get<CommunityData>(`${CONFIG.API.USER_DATA}/community-data`)
  .send()
  .then(response => response.element);

const getCommunityProfileData = (
  { mapPoint }:
  { mapPoint: ResultGeom },
) =>
  useJsonApi().get<ProfileData>(`${CONFIG.API.USER_DATA}/users/${mapPoint.userId}/profile`)
    .send()
    .then(response => response.element);

const getCommunityContentHubArticles = () => contentHubHttpClient
  .get(`${CONFIG.API.CONTENT_HUB}/content?_route=blog/archiv&_country=deu`)
  .then(res => res.parsedBody());

export const CommunityRepository = {
  getCommunityStatsData,
  getCommunityProfileData,
  getCommunityContentHubArticles,
};
