import * as React from 'react';

import { useRouterState } from '+app/router/RouterProvider.component';
import { getRoutesAccess } from '+app/router/routesAccess.config';
import { RouterBuilder } from '+router/factory';
import { getFirstAvailableSubRouteName, getPath, getSubRoutes } from '+router/router.helper';
import { RouteName } from '+router/router.types';

export const ContractFlat: React.FC = () => {
  const { availableFlatRoutes } = useRouterState();
  const routesAccess = getRoutesAccess();
  const redirectToRouteName = getFirstAvailableSubRouteName(RouteName.FLAT, routesAccess) || RouteName.FLAT_OVERVIEW;

  return (
    <RouterBuilder
      routes={getSubRoutes(RouteName.FLAT, availableFlatRoutes)}
      redirectTo={getPath(redirectToRouteName)}
    />
  );
};
