import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getSetupDsoMeterInstallationDate, getSetupDsoMeterInstallationStatus } from '+app/+guide/+setup/store/+setup.selectors';
import { GuideGridStretched, GuideInfoCard } from '+app/+guide/components';
import { GuideAccordion } from '+app/+guide/containers';
import { factorizeStepDescription, GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StepName } from '+app/+guide/helpers/steps.config';
import { getGuideLeadPartnerName } from '+app/+guide/store/+lead.selectors';
import { StoreState } from '+app/store/store.interface';
import { getFormattedDate } from '+legacy/helpers/dates';

const mapStateToProps = (state: StoreState) => ({
  stepStatus: getSetupDsoMeterInstallationStatus(state),
  stepDate: getSetupDsoMeterInstallationDate(state),
  partnerName: getGuideLeadPartnerName(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & RouteComponentProps
;

export const GuideSetupStepDsoMeterInstallationComponent: React.FC<Props> = ({
  stepStatus,
  stepDate,
  partnerName,
}) => {
  const partner = partnerName || I18n.t(T.customerGuidance.yourPartner);
  const date = stepDate && getFormattedDate(stepDate) || '';
  const content = {
    [GuideStepStatus.ACTIVE]: {
      headline: I18n.t(T.customerGuidance.setup.step.dsoMeterInstallation.active.headline),
      tag: I18n.t(T.customerGuidance.toDoBy, {name: partner}),
      status: 'open',
    },
    [GuideStepStatus.DONE]: {
      headline: I18n.t(T.customerGuidance.setup.step.dsoMeterInstallation.done.headline),
      tag: I18n.t(T.customerGuidance.tag.done),
      description: factorizeStepDescription(
        date,
        I18n.t(T.customerGuidance.byName, {name: partner}),
      ),
      status: 'done',
    },
  };

  return (
    <GuideAccordion
      status={stepStatus}
      anchor={StepName.DSO_METER_INSTALLATION}
      title={content[stepStatus].headline}
      tag={content[stepStatus].tag}
      tagTheme={content[stepStatus].status}
      date={content[stepStatus].date}
      responsibility={content[stepStatus].responsibility}
    >
      {{
        [GuideStepStatus.ACTIVE]: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.dsoMeterInstallation.active.information.headline)}
              headerIcon={<Icon.Info />}
            >
              {I18n.t(T.customerGuidance.setup.step.dsoMeterInstallation.active.information.description)}
            </GuideInfoCard>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.dsoMeterInstallation.active.yourToDo.headline)}
              headerIcon={<Icon.Contract />}
              isIconBig={true}
            >
              {I18n.t(T.customerGuidance.setup.step.dsoMeterInstallation.active.yourToDo.description)}
            </GuideInfoCard>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.dsoMeterInstallation.active.backgroundInformation.headline)}
              headerIcon={<Icon.Info />}
            >
              {I18n.t(T.customerGuidance.setup.step.dsoMeterInstallation.active.backgroundInformation.description)}
            </GuideInfoCard>
          </GuideGridStretched>
        ),
        [GuideStepStatus.DONE]: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.dsoMeterInstallation.done.information.headline)}
              headerIcon={<Icon.Info />}
            >
              {I18n.t(T.customerGuidance.setup.step.dsoMeterInstallation.done.information.description)}
            </GuideInfoCard>
          </GuideGridStretched>
        ),
      }}
    </GuideAccordion>
  );
};

export const GuideSetupStepDsoMeterInstallation =
  withRouter(connect(mapStateToProps)(GuideSetupStepDsoMeterInstallationComponent));
