import * as classNames from 'classnames';
import * as React from 'react';

import '../InfographicIcon.util.scss';

interface Props {
  x: number;
  y: number;
  isActive?: boolean;
  onClick: () => void;
}

/* tslint:disable:max-line-length */
export const InfographicIconContract: React.FC<Props> = ({
  x,
  y,
  onClick,
  isActive,
}) => (
  <svg
    className={classNames('u-infographic-icon u-infographic-icon__hover', { 'is-active': isActive })}
    x={x}
    y={y}
    viewBox={'0 0 52 64'}
    width={'41'}
    height={'54'}
    onClick={onClick}
    data-test-id={'dashboard-infographic-icon-contract'}
  >
    <path
      className={'u-infographic-icon__fill'}
      d={'M35.1,7.7c-0.2-0.2-0.5-0.3-0.8-0.3H8c-1.4,0-2.5,1.1-2.5,2.5v47.5c0,1.4,1.1,2.5,2.5,2.5h34.3c1.4,0,2.5-1.1,2.5-2.5V17.9c0-0.3-0.1-0.6-0.3-0.8L35.1,7.7z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M35.4,6.9c-0.2-0.2-0.5-0.3-0.8-0.3H7.6C6.1,6.6,5,7.7,5,9.2V58c0,1.4,1.2,2.6,2.6,2.6h35.2c1.4,0,2.6-1.2,2.6-2.6V17.5c0-0.3-0.1-0.6-0.3-0.8L35.4,6.9z M35.7,10.6l0.1,0.1l5.5,5.5h-5.4c-0.1,0-0.2-0.1-0.2-0.2V10.6z M43,58c0,0.1-0.1,0.2-0.2,0.2H7.6c-0.1,0-0.2-0.1-0.2-0.2V9.2c0-0.1,0.1-0.2,0.2-0.2h25.8v7.1c0,1.4,1.2,2.6,2.6,2.6H43V58z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M36,24.2H13.8c-0.5,0-1,0.4-1,1c0,0.5,0.4,1,1,1H36c0.5,0,1-0.4,1-1C37,24.6,36.5,24.2,36,24.2z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M36,31.8H13.8c-0.5,0-1,0.4-1,1s0.4,1,1,1H36c0.5,0,1-0.4,1-1S36.5,31.8,36,31.8z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M36,39.3H13.8c-0.5,0-1,0.4-1,1s0.4,1,1,1H36c0.5,0,1-0.4,1-1S36.5,39.3,36,39.3z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M25.3,47.1H13.8c-0.5,0-1,0.4-1,1s0.4,1,1,1h11.5c0.5,0,1-0.4,1-1S25.9,47.1,25.3,47.1z'}
    />
  </svg>
);
