import { Data, MergedData } from '@coolio/json-api';

export type MeterInstallationType =
  | 'meter-installations'
  ;

export enum VppCategory {
  VPP_FIT = 'vpp_fit',
  LIMITED_FLEX = 'limited_flex',
}

export enum MeteringConceptCapable {
  MISSING = 'zv_missing',
  FST_MISSING = 'fst_missing',
  BOTH_PREREQUISITES_FULFILLED = 'both_prerequisites_fulfilled',
  BOTH_PREREQUISITES_MISSING = 'both_prerequisites_missing',
}

export enum Z1DirectionType {
  ONE_DIRECTION = 'one_direction',
  TWO_DIRECTIONS = 'two_directions',
}

export enum ResultMeterChange {
  SUCCESSFUL = 'successful',
  ABORTED = 'aborted',
}

export enum MeteringConcept {
  MC0 = 'mc0',
  MC1 = 'mc1',
  MC2 = 'mc2',
  MC5 = 'mc5',
  OTHER = 'other',
}

export enum ProcessStatus {
  IN_PROCESS = 'in_process',
  METER_PLACES_PREPARED = 'meter_places_prepared',
  PARTIAL_SUCCESS = 'partial_success',
  FAILURE = 'failure',
  SUCCESS = 'success',
}

export type FstCheckSuccessful =
  | 'yes'
  | 'no'
  | 'fst_not_required'
  ;

export type MeterPreparationStatus =
  | 'not_needed'
  | 'missing'
  | 'submitted'
  | 'in_clearing'
  | 'checked'
  ;

export interface MeterInstallationAttributes {
  vppCategory: VppCategory;
  meteringConceptCapable: MeteringConceptCapable;
  z1DirectionType: Z1DirectionType;
  startWimProcess: boolean | null;
  wimProcessApprovedByDso: boolean | null;
  finalInstallationAt: string;
  resultMeterChange: ResultMeterChange;
  meteringConcept: MeteringConcept;
  gridMeterId: string;
  pvMeterId: string;
  processStatus: ProcessStatus;
  fstCheckSuccessful: FstCheckSuccessful;
  changeNotificationToDso: boolean | null;
  meterPreparationStatus: MeterPreparationStatus;
}

export type MeterInstallationData = Data<MeterInstallationAttributes>;
export type MeterInstallation = MergedData<MeterInstallationData>;
