import { ProfileFormAttributes, ProfileFormErrors, ProfileFormFields } from '+account/store/types/profile.interface';
import { T } from '@sonnen/shared-i18n/customer';
import { Input, OnInputChangeEvent, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './AccountCommunityDetails.component.scss';

interface Props {
  values: ProfileFormAttributes;
  errors: ProfileFormErrors;
  onChange: (event: OnInputChangeEvent) => void;
}

export const AccountCommunityDetails: React.FC<Props> = ({ errors, values, onChange }) => (
  <div className={'c-account-community-form'}>
    <PageSubheadline>
      {I18n.t(T.communityProfile.detailsSection.title)}
    </PageSubheadline>
    <Input
      name={ProfileFormFields.NICKNAME}
      type={'text'}
      value={values[ProfileFormFields.NICKNAME]}
      // TODO: Translations
      label={I18n.t(T.communityProfile.detailsSection.nickname)}
      error={errors[ProfileFormFields.NICKNAME]}
      onChange={onChange}
    />
    <Input
      name={ProfileFormFields.DESCRIPTION}
      type={'textarea'}
      value={values[ProfileFormFields.DESCRIPTION]}
      // TODO: Translations
      label={I18n.t(T.communityProfile.detailsSection.description)}
      placeholder={I18n.t(T.communityProfile.detailsSection.descriptionPlaceholder)}
      error={errors[ProfileFormFields.DESCRIPTION]}
      onChange={onChange}
    />
  </div>
);
