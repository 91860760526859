import { ContractTypes } from '+app/+dashboard/+contract/store/types/contract.interface';
import { ActionsUnion, createAction } from '+app/utils/redux';

import { Invoice } from './types/invoices.interface';
import { Opportunity, SystemDocument } from './types/opportunities.interface';

export enum DOCUMENTS_ACTIONS {
  FETCH_INVOICES = '[Documents] FETCH_INVOICES',
  FETCH_MORE_INVOICES = '[Documents] FETCH_MORE_INVOICES',
  SET_INVOICES = '[Documents] SET_INVOICES',
  SET_MORE_INVOICES = '[Documents] SET_MORE_INVOICES',
  DOWNLOAD_INVOICE = '[Documents] DOWNLOAD_INVOICE',
  CLEAR_INVOICES_STATE = '[Documents] CLEAR_INVOICES_STATE',
  FETCH_OPPORTUNITIES = '[Documents] FETCH_OPPORTUNITIES',
  SET_OPPORTUNITIES = '[Documents] SET_OPPORTUNITIES',
  FETCH_SYSTEM_DOCUMENTS = '[Documents] FETCH_SYSTEM_DOCUMENTS',
  SET_SYSTEM_DOCUMENTS = '[Documents] SET_SYSTEM_DOCUMENTS',
  DOWNLOAD_SYSTEM_DOCUMENT = '[Documents] DOWNLOAD_SYSTEM_DOCUMENT',
}

export const DocumentsActions = {
  fetchInvoices: (contractType: ContractTypes, contractId: string) =>
    createAction(DOCUMENTS_ACTIONS.FETCH_INVOICES, { contractType, contractId }),
  fetchMoreInvoices: (contractId: string, page: number) =>
    createAction(DOCUMENTS_ACTIONS.FETCH_MORE_INVOICES, { contractId, page }),
  setInvoices: (invoices: Invoice[]) =>
    createAction(DOCUMENTS_ACTIONS.SET_INVOICES, { invoices }),
  setMoreInvoices: (invoices: Invoice[]) =>
    createAction(DOCUMENTS_ACTIONS.SET_MORE_INVOICES, { invoices }),
  downloadInvoice: (contractId: string, contractType: ContractTypes, invoiceId: string, fileName: string) =>
    createAction(DOCUMENTS_ACTIONS.DOWNLOAD_INVOICE, { contractId, contractType, invoiceId, fileName }),
  clearInvoicesState: () => createAction(DOCUMENTS_ACTIONS.CLEAR_INVOICES_STATE),
  fetchOpportunities: () =>
    createAction(DOCUMENTS_ACTIONS.FETCH_OPPORTUNITIES),
  setOpportunities: (opportunities: Opportunity[]) =>
    createAction(DOCUMENTS_ACTIONS.SET_OPPORTUNITIES, { opportunities }),
  fetchSystemDocuments: (buildingDetailId: string) =>
    createAction(DOCUMENTS_ACTIONS.FETCH_SYSTEM_DOCUMENTS, { buildingDetailId }),
  setSystemDocuments: (systemDocuments: SystemDocument[]) =>
    createAction(DOCUMENTS_ACTIONS.SET_SYSTEM_DOCUMENTS, { systemDocuments }),
  downloadSystemDocument: (systemDocId: string, buildingDetailId: string, fileName: string, extension: string) =>
    createAction(DOCUMENTS_ACTIONS.DOWNLOAD_SYSTEM_DOCUMENT, { buildingDetailId, systemDocId, fileName, extension }),
};

export type DocumentsActions = ActionsUnion<typeof DocumentsActions>;
