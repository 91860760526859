import { SagaIterator } from 'redux-saga';
import { call, takeLatest, takeLeading } from 'redux-saga/effects';

import { ContractTypes } from '+app/+dashboard/+contract/store/types/contract.interface';
import { saveDataAsFile } from '+utils/file.util';
import { combineSagas, dataGuard, processQuery } from '+utils/saga';
import { DOCUMENTS_ACTIONS, DocumentsActions } from './documents.actions';
import { DocumentsRepository } from './documents.repository';
import {
  DOWNLOAD_INVOICE_QUERY,
  DOWNLOAD_SYSTEM_DOCUMENT_QUERY,
  FETCH_INVOICES_QUERY,
  FETCH_MORE_INVOICES_QUERY,
  FETCH_OPPORTUNITIES_QUERY,
  FETCH_SYSTEM_DOCUMENTS_QUERY,
} from './documents.state';

type FetchInvoicesAction = ReturnType<typeof DocumentsActions.fetchInvoices>;
type FetchSystemDocumentsAction = ReturnType<typeof DocumentsActions.fetchSystemDocuments>;

function * fetchInvoices({ contractType, contractId }: FetchInvoicesAction): SagaIterator {
  const isEnergyContract = 
    (contractType === ContractTypes.SONNEN_CONNECT_AU || contractType === ContractTypes.SONNEN_CONTROL);

  yield processQuery(
    FETCH_INVOICES_QUERY,
    isEnergyContract
      ? DocumentsRepository.getEnergyContractInvoices : DocumentsRepository.fetchInvoices,
    {
      onSuccess: res => dataGuard(DocumentsActions.setInvoices)(res?.elements),
    },
  )({
    contractId,
    page: 1,
  });
}

type FetchMoreInvoicesAction = ReturnType<typeof DocumentsActions.fetchMoreInvoices>;

function * fetchMoreInvoices({ contractId, page }: FetchMoreInvoicesAction): SagaIterator {
  yield processQuery(
    FETCH_MORE_INVOICES_QUERY,
    DocumentsRepository.fetchInvoices,
    {
      onSuccess: res => dataGuard(DocumentsActions.setMoreInvoices)(res?.elements),
    },
  )({
    contractId,
    page,
  });
}

type DownloadInvoiceAction = ReturnType<typeof DocumentsActions.downloadInvoice>;
type DownloadSystemDocumentAction = ReturnType<typeof DocumentsActions.downloadSystemDocument>;

function * downloadInvoice({ contractId, contractType, invoiceId, fileName }: DownloadInvoiceAction) {
  const isEnergyContract = 
    (contractType === ContractTypes.SONNEN_CONNECT_AU || contractType === ContractTypes.SONNEN_CONTROL);

  yield processQuery(
    DOWNLOAD_INVOICE_QUERY,
    isEnergyContract
      ? DocumentsRepository.downloadEnergyContractInvoice : DocumentsRepository.downloadInvoice,
    {
      onSuccess: res => call(saveDataAsFile, res, `${fileName}.pdf`, 'application/pdf'),
      // TODO add error handling
    },
  )({ contractId, invoiceId });
}

function * downloadSystemDocument({
  buildingDetailId,
  systemDocId,
  fileName,
  extension,
}: DownloadSystemDocumentAction) {
  yield processQuery(
    DOWNLOAD_SYSTEM_DOCUMENT_QUERY,
    DocumentsRepository.downloadSystemDocument,
    {
      onSuccess: res => call(saveDataAsFile, res, `${fileName}.${extension}`),
      // TODO add error handling
    },
  )({ buildingDetailId, systemDocId });
}

function * fetchOpportunities(): SagaIterator {
  yield processQuery(
    FETCH_OPPORTUNITIES_QUERY,
    DocumentsRepository.fetchOpportunities,
    {
      onSuccess: res => dataGuard(DocumentsActions.setOpportunities)(res?.elements),
    },
  )({});
}

function * fetchSystemDocuments({ buildingDetailId }: FetchSystemDocumentsAction): SagaIterator {
  yield processQuery(
    FETCH_SYSTEM_DOCUMENTS_QUERY,
    DocumentsRepository.fetchSystemDocuments,
    {
      onSuccess: res => dataGuard(DocumentsActions.setSystemDocuments)(res?.elements),
    },
  )({ buildingDetailId });
}

export const documentsSagas = combineSagas(
  takeLatest(DOCUMENTS_ACTIONS.FETCH_INVOICES, fetchInvoices),
  takeLatest(DOCUMENTS_ACTIONS.FETCH_MORE_INVOICES, fetchMoreInvoices),
  takeLeading(DOCUMENTS_ACTIONS.DOWNLOAD_INVOICE, downloadInvoice),
  takeLatest(DOCUMENTS_ACTIONS.FETCH_OPPORTUNITIES, fetchOpportunities),
  takeLatest(DOCUMENTS_ACTIONS.FETCH_SYSTEM_DOCUMENTS, fetchSystemDocuments),
  takeLatest(DOCUMENTS_ACTIONS.DOWNLOAD_SYSTEM_DOCUMENT, downloadSystemDocument),
);
