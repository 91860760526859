import { breakpointUp, useWindowSize } from '@sonnen/shared-web';
import * as React from 'react';

import { SocialMediaList } from '+shared/components';

interface Props {
  isOnboardingVariant?: boolean;
}

export const FooterSocial = ({ isOnboardingVariant }: Props) => {
  const isDesktop = useWindowSize().innerWidth >= breakpointUp('SM');

  return (
    <>
      {isOnboardingVariant ? (
        <SocialMediaList isThemeTransparent={isDesktop} />
      ) : (
        <SocialMediaList />
      )}
    </>
  );
};
