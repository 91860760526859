import { documentsSagas } from '+dashboard/+documents/store/documents.sagas';
import { i18nReducer, I18nState } from 'react-redux-i18n';
import { AnyAction, combineReducers, Reducer } from 'redux';
import { FormStateMap, reducer as formReducer } from 'redux-form';
import { all } from 'redux-saga/effects';

import { combineSagas } from '+utils/saga';

import { reducer as accountReducer } from '+account/store/account.reducer';
import { reducer as analysisReducer } from '+analysis/store/analysis.reducer';
import * as auth from '+auth/store/auth.reducer';
import * as battery from '+battery/store';
import * as community from '+community/store';
import * as contract from '+contract/store';
import * as dashboard from '+dashboard/store';
import { reducer as documentsReducer } from '+documents/store/documents.reducer';
import { reducer as guideReducer } from '+guide/store/+guide.reducer';
import { sagas as guideSagas } from '+guide/store/+guide.sagas';
import * as mobile from '+mobile';

// TODO: Remove this communityLegacy saga when new Community features will be rolled back
import * as communityLegacy from '+legacy/features/mapLegacy';
import * as router from '+router/store/index';
import * as legal from '+shared/store/legal/legal.reducer';
import * as live from '+shared/store/live/live.reducer';
import { reducer as meterReducer } from '+shared/store/meter/meter.reducer';
import * as newsChannel from '+shared/store/news-channel/newsChannel.reducer';
import * as notifications from '+shared/store/notification/notification.reducer';
import * as site from '+shared/store/site';
import * as sync from '+shared/store/sync/sync.reducer';
import * as user from '+shared/store/user';

import { accountSagas } from '+account/store/account.sagas';
import { analysisSagas } from '+analysis/store/analysis.sagas';
import { authSagas } from '+auth/store/auth.sagas';
import { batterySagas } from '+battery/store/battery.sagas';
import { productMigrationSagas } from '+product-migration/store/productMigration.sagas';
import { layoutSagas } from '+shared/store/layout/layout.sagas';
import { legalSagas } from '+shared/store/legal/legal.sagas';
import { liveSagas } from '+shared/store/live/live.sagas';
import { meterSagas } from '+shared/store/meter/meter.sagas';
import { notificationSagas } from '+shared/store/notification/notification.sagas';
import { syncSagas } from '+shared/store/sync/sync.sagas';

// @TODO: clean that legacy mess
import { contractSagas } from '+contract/store';
import { helpReducer } from '+help/store/help.reducer';
import { taxSagas } from '+help/store/help.sagas';
import { appReducer } from '+legacy/core/app';
import { mobileIntegrationReducerWrapper } from '+mobile/mobile.integration';
import { productMigrationReducer } from '+product-migration/store/productMigration.reducer';
import { layoutReducer } from '+shared/store/layout/layout.reducer';
import { StoreState } from './store.interface';

export const rootReducer: Reducer<StoreState, AnyAction> = (state, action) => combineReducers<StoreState>({
  live: live.reducer,
  legal: legal.reducer,
  sync: sync.reducer,
  auth: auth.reducer,
  analysis: analysisReducer,
  battery: battery.reducer,
  notifications: notifications.reducer,
  guide: guideReducer,
  meter: meterReducer,
  account: accountReducer,
  community: community.reducer,
  dashboard: dashboard.reducer,
  site: site.reducer,
  user: user.reducer,
  newsChannel: newsChannel.reducer,
  mobile: mobile.reducer,
  documents: documentsReducer,

  // third party reducers
  i18n: i18nReducer as any as Reducer<I18nState>,
  router: router.reducer,
  form: formReducer as Reducer<FormStateMap>,

  // legacy reducers
  app: appReducer,
  layout: layoutReducer,
  contract: contract.contractReducer,
  help: helpReducer,
  productMigration: productMigrationReducer,
})(mobileIntegrationReducerWrapper(state, action), action);

export const initialState = rootReducer({} as any, { type: '' }) as StoreState;

export const rootSaga = function * () {
  yield all(combineSagas(
    // TODO: standardize sagax exports / imports
    authSagas,
    batterySagas,
    syncSagas,
    liveSagas,
    notificationSagas,
    router.sagas,
    analysisSagas,
    community.sagas,
    mobile.mobileSagas,
    documentsSagas,
    // TODO: Remove this communityLegacy saga when new Community features will be rolled back
    communityLegacy.communityMapSagas,
    guideSagas,
    layoutSagas,
    legalSagas,
    accountSagas,
    contractSagas,
    site.siteSagas,
    user.userSagas,
    taxSagas,
    productMigrationSagas,
    meterSagas,
  ));
};
