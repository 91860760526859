import * as React from 'react';
import { debounce } from '../../../helpers/functions';

interface Props {
  className?: string;
}

interface State {
  width: number;
  height: number;
}

export const withResponsiveness =
  (EnhancedComponent: React.ComponentType<Props & State>) =>
  (class WithResponsiveness extends React.PureComponent<Props, State> {
    wrapperRef: Element | null = null;

    state: State = {
      width: 0,
      height: 0,
    };

    fitParentContainer = debounce(() => {
      const wrapper = this.wrapperRef;
      if (wrapper) {
        const {
          width: currentWidth,
          height: currentHeight,
        } = wrapper.getBoundingClientRect();

        const haswidthChanged = this.state.width !== currentWidth;
        const hasheightChanged = this.state.height !== currentHeight;

        if (haswidthChanged || hasheightChanged) {
          this.setState((state): State => ({
            ...state,
            width: currentWidth,
            height: currentHeight,
          }));
        }
      }
    }, 500);

    componentDidMount() {
      this.fitParentContainer();
      window.addEventListener('resize', this.fitParentContainer);
      if (this.wrapperRef) {
        this.wrapperRef.addEventListener('resize', this.fitParentContainer);
      }
    }

    componentDidUpdate(prevProps: Props) {
      if (prevProps.className !== this.props.className) {
        this.fitParentContainer();
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.fitParentContainer);
      if (this.wrapperRef) {
        this.wrapperRef.removeEventListener('resize', this.fitParentContainer);
      }
    }

    setWrapperRef = (ref: Element | null) => {
      this.wrapperRef = ref;
    };

    render() {
      return (
        <div ref={this.setWrapperRef} className={this.props.className}>
          {!!this.state.width &&
            (
              <EnhancedComponent
                {...this.props}
                {...this.state}
              />
            )}
        </div>
      );
    }
  });
