import { TooltipEvent } from '@kanva/charts';
import { View } from '@kanva/core';
import { SeriesKey } from '@sonnen/shared-web';
import * as React from 'react';

import { KanvaContainers, KanvaExtensions, KanvaTooltipValues } from '../../../kanva/kanva.types';

interface AnalysisBarChartContextProps {
  chartView?: View<any>;
  containers?: KanvaContainers;
  currentXPosition: number;
  extensions?: KanvaExtensions;
  primarySeries?: SeriesKey;
  tooltipEvent?: TooltipEvent;
  tooltipValues?: KanvaTooltipValues;
  handleMount: (view: View<any>) => void;
}

export const AnalysisBarChartContext = React.createContext<AnalysisBarChartContextProps>({
  currentXPosition: 0,
} as AnalysisBarChartContextProps);

export const useAnalysisBarChart = () => React.useContext(AnalysisBarChartContext);
