import * as React from 'react';

import { PlatformSelector } from '+components';
import { AnalysisBarChartMobile } from './AnalysisBarChart.mobile.component';
import { AnalysisBarChartWeb } from './AnalysisBarChart.web.component';

interface AnalysisBarChartProps {
  chartHeight: number;
}

export const AnalysisBarChart: React.FC<AnalysisBarChartProps> = ({
  chartHeight,
}) => (
  <PlatformSelector
    web={
      <AnalysisBarChartWeb
        chartHeight={chartHeight}
      />
    }
    mobile={
      <AnalysisBarChartMobile
        chartHeight={chartHeight}
      />
    }
  />
);
