import { isSpecificFlatProductInOffer } from '+app/+guide/+acceptance/store/+acceptance.helper';
import {
  GuideDocumentErrorModal,
  GuideTextWithTooltip,
} from '+app/+guide/components';
import {
  FlatDocumentType,
  ImpactAnalysis,
  Lead,
  Offer,
  OfferProductType,
} from '+app/+guide/store/types';
import { DownloadLink, Portal } from '+app/shared/components';
import { PortalExit } from '+app/shared/helpers';
import { T } from '@sonnen/shared-i18n/customer';
import { DocumentStatus, ModalDocumentDownload } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './GuidePurchaseDocumentDownload.scss';

interface Props {
  lead: Lead | undefined;
  impactAnalysis: ImpactAnalysis | undefined;
  isImpactAnalysisHidden?: boolean | Date | undefined;
  isExpired: boolean;
  offer: Offer;
  documentKey: string | undefined;
  documentUrl: string | undefined;
  documentStatusConfiguration: DocumentStatus | undefined;
  documentStatusImpactAnalysis: DocumentStatus | undefined;
  startDocumentPolling: (id: string, documentType: FlatDocumentType) => void;
  stopDocumentOfferPolling: () => void;
  regenerateDocument: (
    leadId: string,
    resourceId: string,
    resourceType: 'offer',
    documentType: FlatDocumentType,
  ) => void;
}

export const GuidePurchaseDocumentDownload: React.FC<Props> = ({
  lead,
  impactAnalysis,
  isImpactAnalysisHidden,
  isExpired,
  offer,
  documentKey,
  documentUrl,
  documentStatusConfiguration,
  documentStatusImpactAnalysis,
  startDocumentPolling,
  stopDocumentOfferPolling,
  regenerateDocument,
}) => {
  const [isDownloadModalOpen, toggleDownloadModal] = React.useState(false);
  const [activeDocumentType, setActiveDocumentType] = React.useState<
    FlatDocumentType | undefined
  >(undefined);

  const documentStatusMap: Record<
    FlatDocumentType,
    DocumentStatus | undefined
  > = {
    [FlatDocumentType.FLAT_CONFIGURATION]: documentStatusConfiguration,
    [FlatDocumentType.IMPACT_ANALYSIS]: documentStatusImpactAnalysis,
    [FlatDocumentType.FLAT_OFFER]: undefined,
    [FlatDocumentType.FLAT_ORDER_CONFIRMATION]: undefined,
    [FlatDocumentType.CESSION]: undefined,
  };

  const documentStatus =
    activeDocumentType && documentStatusMap[activeDocumentType];

  const onDownloadClick = (
    id: string,
    documentType: FlatDocumentType,
    documentStatus: DocumentStatus | undefined,
  ) => {
    setActiveDocumentType(documentType);
    toggleDownloadModal(true);

    if (documentStatus && documentStatus !== DocumentStatus.FAILED) {
      startDocumentPolling(id, documentType);
    }
  };

  const onDownloadModalClose = () => {
    stopDocumentOfferPolling();
    toggleDownloadModal(false);
  };

  const onRegenerateDocumentClick = () => {
    toggleDownloadModal(true);
    regenerateDocument(lead!.id, offer!.id, 'offer', activeDocumentType!);
  };

  return (
    <>
      <div className={'c-guide-purchase-flat-offer-documents__title'}>
        {I18n.t(T.customerGuidance.purchase.flatOffer.documents.title)}
      </div>

      {impactAnalysis && !isImpactAnalysisHidden && (
        <DownloadLink
          title={
            <GuideTextWithTooltip
              title={I18n.t(T.customerGuidance.purchase.tooltip.impactAnalysis.title)}
              content={I18n.t(T.customerGuidance.purchase.tooltip.impactAnalysis.description)}
            />
          }
          onDownload={
            !isExpired
              ? () =>
                  onDownloadClick(
                    impactAnalysis.id,
                    FlatDocumentType.IMPACT_ANALYSIS,
                    documentStatusImpactAnalysis,
                  )
              : undefined
          }
        />
      )}
      {documentKey && offer ? (
        <DownloadLink
          title={
            offer &&
            isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X)
              ? I18n.t(T.customerGuidance.purchase.flatOffer.document.name)
              : I18n.t(T.customerGuidance.purchase.flatOffer.document.nameFlatDirect)
          }
          onDownload={() =>
            onDownloadClick(
              offer.id,
              FlatDocumentType.FLAT_CONFIGURATION,
              documentStatusConfiguration,
            )
          }
        />
      ) : (
        <div className={'c-guide-purchase-flat-offer-documents__no-offer'}>
          {I18n.t(T.customerGuidance.purchase.flatOffer.documents.offerPrepared)}
        </div>
      )}
      {(impactAnalysis || documentKey) && (
        <Portal target={PortalExit.ROOT}>
          <ModalDocumentDownload
            isOpen={isDownloadModalOpen}
            status={documentStatus || DocumentStatus.PENDING}
            onModalClose={onDownloadModalClose}
            loadingText={I18n.t(T.customerGuidance.documents.statusMessage.pending)}
            errorText={
              <GuideDocumentErrorModal
                onRegenerateClick={onRegenerateDocumentClick}
              />
            }
            linkText={I18n.t(T.customerGuidance.documents.statusMessage.created.clickHere)}
            createdText={I18n.t(T.customerGuidance.documents.statusMessage.created.toOpenFlat)}
            fileUrl={documentUrl!}
          />
        </Portal>
      )}
    </>
  );
};
