import { Icon, Tooltip } from '@sonnen/shared-web';
import { IconType } from '@sonnen/shared-web/src/components/Icon/models';
import * as React from 'react';

import './TooltipIcon.component.scss';

export interface TooltipIconProps {
  icon: IconType;
}

export const TooltipIcon: React.FC<TooltipIconProps> = ({ icon, children }) => {
  const IconComponent = Icon[icon];

  return (
    <Tooltip trigger={<IconComponent className={'c-tooltip-icon__icon'} />} >
      {children}
    </Tooltip>
  );
};
