import { T } from '@sonnen/shared-i18n/customer';
import { MediaQuery } from '@sonnen/shared-web';
import classNames from 'classnames';
import { easeLinear } from 'd3-ease';
import { ScaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import 'd3-transition';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { formatNumber } from '+app/utils/number.util';
import { FrequencySpec } from '+legacy/features/frequency/frequency.types';
import { decodeCssIcons } from '+legacy/helpers/strings';
import {
  DESKTOP,
  MOBILE,
  Y_AXIS_MARGIN,
} from '../../../frequency.constants';

interface Props {
  className: string;
  icon: string;
  xScale: ScaleLinear<number, number>;
  x: number;
  yScale: ScaleLinear<number, number>;
  y: number;
  reposition: boolean;
}

export class Circle extends React.PureComponent<Props> {
  static defaultProps = {
    className: '',
    xScale: null,
    yScale: null,
    reposition: false,
  };

  groupRef: Element | null = null;

  componentDidMount() {
    select(this.groupRef)
      .select('text.c-frequency-chart__circle-icon')
      .text(() => decodeCssIcons(this.props.icon));

    this.updateGraph(0);
  }

  componentDidUpdate(prevProps: Props) {
    const { y: oldY, yScale: { domain: oldDomain } } = prevProps;
    const { yScale: { domain } } = this.props;
    const [minValue, maxValue] = domain();
    const [oldMinValue, oldMaxValue] = oldDomain();

    const domainChanged = (maxValue !== oldMaxValue) || (minValue !== oldMinValue);

    this.updateGraph(1500, domainChanged, oldY);
  }

  setGroupRef = (ref: Element | null) => {
    this.groupRef = ref;
  };

  updateGraph = (duration: number, domainChanged: boolean = false, oldY?: number) => {
    const {
      yScale,
      y,
      xScale,
      x,
      reposition,
    } = this.props;

    const changeY = reposition ? y : oldY || 0;

    const circleGroup = select(this.groupRef)
      .select('.c-frequency-chart__circle');

    const labelGroup = select(this.groupRef)
      .select('.c-frequency-chart__label');

    if (reposition) {
      select(this.groupRef)
        .transition()
        .duration(0)
        .attr('transform', `translate(-${xScale(x)}, ${Y_AXIS_MARGIN})`)
        .transition()
        .duration(1500)
        .ease(easeLinear)
        .attr('transform', `translate(0, ${Y_AXIS_MARGIN})`);
    }

    if (reposition || domainChanged) {
      circleGroup
        .transition()
        .duration(0)
        .attr('transform', `translate(${xScale(x)}, ${yScale(changeY)})`);

      labelGroup
        .transition()
        .duration(0)
        .attr('transform', `translate(${xScale(x)}, ${yScale(changeY)})`);
    }

    circleGroup
      .transition()
      .duration(domainChanged && oldY === y ? 500 : duration)
      .ease(easeLinear)
      .attr('transform', `translate(${xScale(x)}, ${yScale(y)})`);

    labelGroup
      .transition()
      .duration(domainChanged && oldY === y ? 500 : duration)
      .ease(easeLinear)
      .attr('transform', `translate(${xScale(x)}, ${yScale(y)})`);
  };

  render() {
    const { className, y } = this.props;
    const circleClass = classNames('c-frequency-chart__circle', className);

    return (
      <Media query={MediaQuery.DOWN_SM}>
        {(matches: boolean) => {
          const { LABEL: { DIMS, MARGIN, RADIUS }, CIRCLE } = matches ? MOBILE : DESKTOP;
          const [rectWidth, rectHeight] = DIMS;
          return (
            <g
              ref={this.setGroupRef}
              transform={`translate(0, ${Y_AXIS_MARGIN})`}
            >
              <g 
                className={circleClass}
                data-test={FrequencySpec.FREQUENCY_CIRCLE}
              >
                <circle
                  r={CIRCLE.RADIUS}
                  className="c-frequency-chart__circle-circle"
                />
                <text className="c-frequency-chart__circle-icon" />
              </g>
              <g className="c-frequency-chart__label">
                <rect
                  width={rectWidth}
                  height={rectHeight}
                  rx={RADIUS}
                  ry={RADIUS}
                  x={MARGIN}
                  y={-(rectHeight / 2)}
                  className="c-frequency-chart__label-border"
                />
                <text
                  data-test-id={'sonnen-community-frequency-chart-value'}
                  x={MARGIN + (rectWidth / 2)}
                  className="c-frequency-chart__label-text"
                >
                  {`${formatNumber({ precision: 3 })(y)} ${I18n.t(T.general.units.hertz)}`}
                </text>
              </g>
            </g>
          );
        }}
      </Media>
    );
  }
}
