import { UserChangePasswordAttributes } from '+app/+dashboard/+account/store/types/userChangePassword.interface';
import { UserAttributes } from '+app/shared/store/user/types/user.interface';
import { ActionsUnion, createAction } from '+utils/redux';
import { Profile, ProfileFormAttributes } from './types/profile.interface';

export enum ACCOUNT_ACTIONS {
  GET_ACCOUNT_PROFILE_DATA = '[Account] GET_ACCOUNT_PROFILE_DATA',
  SET_ACCOUNT_PROFILE_DATA = '[Account] SET_ACCOUNT_PROFILE_DATA',
  UPDATE_ACCOUNT_PROFILE_DATA = '[Account] UPDATE_ACCOUNT_PROFILE_DATA',
  SET_USER_DATA = '[Account] SET_USER_DATA',
  CHANGE_USER_PASSWORD = '[Account] CHANGE_USER_PASSWORD',
}

export const AccountActions = {
  getAccountProfileData: (userId?: string) =>
    createAction(ACCOUNT_ACTIONS.GET_ACCOUNT_PROFILE_DATA, { userId }),
  setAccountProfileData: (profile: Profile) =>
    createAction(ACCOUNT_ACTIONS.SET_ACCOUNT_PROFILE_DATA, { profile }),
  updateAccountProfileData: (attributes: ProfileFormAttributes, userId?: string) =>
    createAction(ACCOUNT_ACTIONS.UPDATE_ACCOUNT_PROFILE_DATA, { attributes, userId }),
  setUserData: (attributes: UserAttributes, userId?: string) =>
    createAction(ACCOUNT_ACTIONS.SET_USER_DATA, { attributes, userId }),
  changeUserPassword: (attributes: UserChangePasswordAttributes, userId?: string) =>
    createAction(ACCOUNT_ACTIONS.CHANGE_USER_PASSWORD, { attributes, userId }),
};

export type AccountActions = ActionsUnion<typeof AccountActions>;
