import { storeProvider } from '+app/store/store.provider';
import { CONFIG } from '+config';
import { getLanguageCode } from '+legacy/helpers/i18n.helper';
import { Crypto } from '+utils/crypto.util';
import { createQueryString, getHashParams, getQueryParams } from '+utils/urlParams.util';
import { isNil } from 'lodash';
import { getCodeVerifier, getImpersonatedUserId, isImpersonated } from './auth.selectors';
import { AuthContext, AuthParams, AuthSalesforceParams } from './auth.state';

export class DecodeStateError extends Error {
  constructor(details: string) {
    super(`State is not a valid base64 string or JSON inside it is malformed: ${details}`);
  }
}

export class InvalidVerifierCodeError extends Error {
  constructor() {
    super('State has invalid verifier code');
  }
}

export const getRedirectUri = () => `${window.location.origin}/`;

export const encodeState = (state: any, verifier: string): string =>
  btoa(JSON.stringify({ s: state, v: verifier }));

export const decodeState = <T extends any = any>(stateBase64: string, verifier?: string): T => {
  try {
    const stateJson = JSON.parse(atob(stateBase64));
    if (verifier && stateJson.v === verifier) {
      return stateJson.s;
    }
  } catch (error) {
    throw new DecodeStateError(error.message);
  }
  throw new InvalidVerifierCodeError();
};

export const createCodeVerifier = (): string =>
  Crypto.typedArrayToSafeBase64(Crypto.getRandomValues(new Uint8Array(96)));

export const createCodeChallenge = (codeVerifier: string): PromiseLike<string> => {
  return Crypto.digest('SHA-256', Crypto.stringToTypedArray(codeVerifier))
    .then(result => Crypto.typedArrayToSafeBase64(new Uint8Array(result)));
};

export const generateRandomNonceString = () => (
  Math.random().toString(36).substring(2)
);

export const toUrlEncoded = (obj: object) => {
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p) && !isNil(obj[p])) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }
  return str.join('&');
};

export const getUrlAuthParams = ({ search, hash }: Location): AuthParams | AuthSalesforceParams | {} => ({
  ...getQueryParams<AuthParams>(search),
  ...getHashParams<AuthSalesforceParams>(hash),
});

export const isDefaultAuthorizationContext =
  (params: any): params is AuthParams =>
    !!(params && params.code);

export const isSalesForceAuthorizationContext =
  (params: any): params is AuthSalesforceParams =>
    !!(params.accessToken && params.idToken);

export const isSalesForcePreAuthorizationContext =
  (params: any): params is AuthSalesforceParams =>
    !!(params.userId);

export const getAuthContext = (params: AuthParams | AuthSalesforceParams | {}): AuthContext | null => {
  if (isDefaultAuthorizationContext(params)) {
    return AuthContext.AUTH;
  }

  if (isSalesForcePreAuthorizationContext(params)) {
    return AuthContext.SALESFORCE_PREAUTH;
  }

  if (isSalesForceAuthorizationContext(params)) {
    return AuthContext.SALESFORCE_AUTH;
  }

  return null;
};

async function getSonnenLoginUrl(
  verifier: string,
  languageCode: string,
) {
  return `${CONFIG.AUTH.AUTH_URL}/oauth/authorize${createQueryString({
    client_id: CONFIG.AUTH.AUTH_CLIENT_ID,
    response_type: 'code',
    redirect_uri: getRedirectUri(),
    code_challenge: await createCodeChallenge(verifier),
    nonce: generateRandomNonceString(),
    code_challenge_method: 'S256',
    locale: languageCode.split('-')[0],
  })}`;
}

function getImpersonatedUserLoginUrl(impersonatedUserId: string) {
  return `${CONFIG.AUTH.SALESFORCE_TECH_URL}/services/oauth2/authorize${createQueryString({
    redirect_uri: getRedirectUri(),
    response_type: 'token id_token',
    client_id: CONFIG.AUTH.SALESFORCE_CLIENT_ID,
    scope: 'openid',
    nonce: generateRandomNonceString(),
    state: JSON.stringify({userId: impersonatedUserId, context: 'salesforce'}),
  })}`;
}

export const getLoginUrl = async (): Promise<string> => {
  const impersonatedUserId = getImpersonatedUserId(storeProvider.getState());
  const verifier = getCodeVerifier(storeProvider.getState()) || '';
  const queryParams = getQueryParams<AuthParams>(window.location.search);
  delete queryParams.code;
  delete queryParams.state;
  const languageCode = await getLanguageCode();

  return impersonatedUserId
    ? getImpersonatedUserLoginUrl(impersonatedUserId)
    : await getSonnenLoginUrl(verifier, languageCode);
};

export const getLogoutUrl = () => (
  isImpersonated(storeProvider.getState())
    ? `${CONFIG.AUTH.SALESFORCE_TECH_URL}/secur/logout.jsp?retUrl=${getRedirectUri()}`
    : `${CONFIG.AUTH.AUTH_URL}/users/sign_out?redirect_uri=${getRedirectUri()}`
);
