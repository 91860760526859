import { Contract } from '+contract/store/types/contract.interface';
import { trimString } from '+legacy/helpers/strings';
import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

const getContractingPartyName = (contract: Contract) =>
  (contract.contractingPartnerFirstname || contract.contractingPartnerLastname) 
  ? [
      contract.contractingPartySalutation, 
      contract.contractingPartyAcademicTitle, 
      contract.contractingPartnerFirstname, 
      contract.contractingPartnerLastname,
    ].filter(element => element !== undefined).join(' ').trim() 
  : '';

const getAccountOwnerName = (contract: Contract) => contract.bankFirstname && contract.bankLastname
  ? trimString`${contract.bankFirstname} ${contract.bankLastname}`
  : '';

const getBillingStreet = (contract: Contract) => contract && contract.billingStreet
  ? trimString`${contract.billingStreet} ${contract.billingStreetNumber || ''}`
  : '';

const getBillingPostalDetails = (contract: Contract) => contract
  ? trimString`${contract.billingPostalCode} ${contract.billingCity}`
  : '';

export const factorizeContractCustomerData = (contract: Contract, customerNumber: string) => [
  {
    label: I18n.t(T.yourTariff.labels.iban),
    value: contract.bankIban && `**** **** **** **** ${contract.bankIban}`,
    dataTestId: 'contract-customer-data-iban',
  },
  {
    label: I18n.t(T.yourTariff.labels.bic),
    value: contract.bankSwift,
    dataTestId: 'contract-customer-data-bank-swift',
  },
  {
    label: I18n.t(T.yourTariff.labels.accountOwner),
    value: getAccountOwnerName(contract),
    dataTestId: 'contract-customer-data-account-owner',
  },
  {
    label: I18n.t(T.yourTariff.labels.customerNumber),
    value: customerNumber,
    dataTestId: 'contract-customer-data-customer-number',
  },
  {
    label: I18n.t(T.yourTariff.headlines.email),
    value: contract.contractingPartyEmail,
    dataTestId: 'contract-customer-data-contracting-party-email',
  },
  {
    label: I18n.t(T.yourTariff.headlines.mailing),
    value: (
      <>
        <div 
          data-test-id={'contract-customer-data-contracting-party-name-value'}
        >
          {getContractingPartyName(contract)}
        </div>
        <div 
          data-test-id={'contract-customer-data-billing-street-value'}
        >
          {getBillingStreet(contract)}
        </div>
        <div 
          data-test-id={'contract-customer-data-postal-details-value'}
        >
          {getBillingPostalDetails(contract)}
        </div>
      </>
    ),
  },
];
