import { Icon, NavItem, NavListItem } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';

import { LayoutActions } from '+app/shared/store/layout/layout.actions';
import { Site } from '+app/shared/store/site/types/site.interface';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { formatSiteAddress } from '+legacy/helpers/strings';
import { getCurrentUserName } from '+shared/store/user/user.selectors';

import './SiteSwitcherButton.mobile.component.scss';

interface OwnProps {
  activeSite?: Site;
}

const mapStateToProps = (state: StoreState) => ({
  userName: getCurrentUserName(state),
});

const mapDispatchToProps = mapActions({
  toggleSiteSwitcher: LayoutActions.toggleSiteSwitcher,
});

type Props =
  & OwnProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const SiteSwitcherButtonComponent: React.SFC<Props> = ({
  activeSite,
  userName,
  actions: { toggleSiteSwitcher },
}) => {
  if (!activeSite) {
    return null;
  }

  return (
    <button
      onClick={() => toggleSiteSwitcher(true)}
      type={'button'}
      className={'m-c-site-switcher-button'}
    >
      <NavListItem isThemeUnderline={true}>
        <NavItem icon={<Icon.House />}>
          {userName}
        </NavItem>
        <div className={'m-c-site-switcher-button__content'}>
          <span className={'m-c-site-switcher-button__content-label'}>
            {formatSiteAddress(activeSite)}
          </span>
          <Icon.Angle className={'m-c-site-switcher-button__content-icon'}/>
        </div>
      </NavListItem>
    </button>
  );
};

// TODO: Refactor it to the dumb component!
export const SiteSwitcherButtonMobile = connect(mapStateToProps, mapDispatchToProps)(SiteSwitcherButtonComponent);
