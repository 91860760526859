import {
  Button,
  ButtonSize,
  InfoBanner,
  InfoBannerProps,
  Loader,
  Modal,
} from '@sonnen/shared-web';
import * as React from 'react';

import './GuideSetupModalConfirmation.component.scss';

interface Props extends InfoBannerProps {
  buttonLabel?: string;
  onButtonClick: () => void;
  isLoading?: boolean;
  isOpen: boolean;
}

export const GuideSetupModalConfirmation: React.FC<Props> = ({
  buttonLabel,
  icon,
  isLoading,
  isOpen,
  onButtonClick,
  subtitle,
  title,
}) => (
  <Modal
    isOpen={isOpen}
    size={'auto'}
  >
    <div className={'c-guide-setup-modal-confirmation'}>
      <div className={'c-guide-setup-modal-confirmation__content'}>
        <InfoBanner
          icon={icon}
          title={title}
          subtitle={subtitle}
        />
        {isLoading && (
          <div className={'c-guide-setup-modal-confirmation__loader'}>
            <Loader />
          </div>
        )}
      </div>
      {buttonLabel && onButtonClick && (
        <div className={'c-guide-setup-modal-confirmation__footer'}>
          <Button
            label={buttonLabel}
            onClick={onButtonClick}
            size={ButtonSize.NORMAL}
          />
        </div>
      )}
    </div>
  </Modal>
);
