import { useFeature } from '@sonnen/shared-web';
import { omitBy } from 'lodash';
import { mainNavMobileConfig } from './configs';
import { getPathsConfig, getRoutesConfig } from './router.config';
import {
  FlatRoutes,
  Route,
  RouteName,
  RouterAppNavType,
  RouterNavConfig,
  RouterNavNestedRoute,
  Routes,
  RouteType,
} from './router.types';
import { RoutesAccess } from './routesAccess.config';

export const ROUTER_APP_BOTTOM_NAV_ITEMS_AMOUNT = 4;

export enum RouteAuthType {
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export const isOnRoute = (routeName: RouteName, path: string) =>
  path.indexOf(getPath(routeName)) >= 0;

/**
 * Returns single route with nested subRoutes depends on access level (store & FF)
 */
export const getRoute = (
  routeName: RouteName,
  flatRoutes: FlatRoutes,
): Route | undefined => {
  const route = flatRoutes[routeName];

  return route
    ? getFactorizedRoute(route, flatRoutes)
    : undefined;
};

/**
 * Returns single route from passed path string, with nested subRoutes depends on access level (store & FF)
 */
export const getRouteFromPath = (
  path: string,
  flatRoutes: FlatRoutes,
) => {
  const factorizedPath = getFactorizedPath(path);
  const paths = getPaths();
  const currentRoute = Object.keys(paths).find(pathName => paths[pathName] === factorizedPath) as RouteName;

  return currentRoute
    ? getRoute(currentRoute, flatRoutes)
    : undefined;
};

export const getRouteNameFromPath = (path: string): RouteName | undefined => {
  const routesPaths = getPathsConfig();
  return Object.entries(routesPaths).find(([routeName, routePath]) => routePath === path)?.[0] as RouteName | undefined;
};

export const getMainRoutePath = (path: string): string => {
  const splittedPath = path.split('/');
  return `${splittedPath[0]}/${splittedPath[1]}`;
};

/**
 * Returns subRoutes array for particular Route. SubRoutes depends on access level (store & FF)
 */
export const getSubRoutes = (
  routeName: RouteName,
  flatRoutes: FlatRoutes,
) => {
  const route = getRoute(routeName, flatRoutes);

  return route
    ? route.subRoutes
    : [];
};

export const isActive = (route: Route) =>
  !!(route.shouldBeActiveFor && window.location.pathname === getPath(route.shouldBeActiveFor));

export const getFirstAvailableSubRouteName = (name: RouteName, routesAccess: RoutesAccess) => {
  const config = getRoutesConfig();
  const route = config[name];

  return route?.subRoutesRefs?.find(name => hasRouteAccess(name, routesAccess));
};

/**
 * Returns parent route to particular Route
 */
export const getParentRoute = (routes: Routes, trimmedCurrentPath: string) =>
  routes.find(route => route?.subRoutes?.find(subRoute => subRoute.path === trimmedCurrentPath));

export const getRoutes = (routeAuthType: RouteAuthType, routes: Route[]) => {
  switch (routeAuthType) {
    case RouteAuthType.AUTHORIZED:
      return getAuthorizedRoutes(routes);
    case RouteAuthType.UNAUTHORIZED:
      return getUnauthorizedRoutes(routes);
    default:
      return routes;
    }
};

export const isCurrentRoute = (route: string) => {
  return window.location.pathname === route;
};

export const isAuthorizedRoute = (path: string) => {
  const route = getRouteFromPath(path, getRoutesConfig());

  return route
    ? route.routeType === RouteType.PROTECTED || route.routeType === RouteType.PUBLIC
    : false;
};

export const isUnauthorizedRoute = (path: string) => {
  const route = getRouteFromPath(path, getRoutesConfig());

  return route
    ? route.routeType === RouteType.PUBLIC || route.routeType === RouteType.PUBLIC_ONLY
    : false;
};

/**
 * Returns boolean value about routeAccess (store based)
 */
export const hasRouteAccess = (routeName: RouteName, routesAccess: RoutesAccess) => {
  return routesAccess[routeName];
};

/**
 * Returns boolean value if route is main feature root
 */
export const isMainFeatureRoute = (route: Route) => route.isMainFeatureRoot;

/**
 * Returns flat config object filtered by FF & route access
 */
export const getAccessibleFlatRoutes = (flatRoutes: FlatRoutes, routesAccess: RoutesAccess) => {
  let accessibleFlatRoutes: FlatRoutes = {} as FlatRoutes;

  Object.entries(flatRoutes).forEach(([, route]) => {
    if (
      route
      && hasRouteAccess(route.name, routesAccess)
      && (route.featureFlag ? useFeature(route.featureFlag).isEnabled : true)
    ) {
      accessibleFlatRoutes = {
        ...accessibleFlatRoutes,
        [route.name]: route,
      };
    }
  });

  return accessibleFlatRoutes;
};

/**
 * Iterate on passed routes config, returns array of factorized, nested routes array.
 * Passed routes config 'flatRoutes' is already filtered by FF and access routes.
 */
export const getFactorizedRoutes = (flatRoutes: FlatRoutes): Route[] => {
  return Object.entries(flatRoutes)
    .map(([, route]) => {
      return route
        ? getFactorizedRoute(route, flatRoutes)
        : undefined;
    })
    .filter(route => route
      ? isMainFeatureRoute(route)
      : false) as Route[];
};

export const getNavRoutes = (config: RouterNavConfig, flatRoutes: FlatRoutes) => {
  return config.map((route) => {
    if (typeof route !== 'string') {
      const mainRoute: Route | undefined = flatRoutes[route.main];
      const subRoutes = getNavSubRoutes(route, flatRoutes);

      if (!mainRoute) { return undefined; }

      return {
        ...mainRoute,
        subRoutes,
      } as Route;
    }

    return flatRoutes[route];
  }).filter(route => !!route) as Route[];
};

/**
 * Returns array of routes for particular mobile navigation type (SIDE or BOTTOM).
 */
export const getMobileNavRoutes = (
  appNavigationType: RouterAppNavType,
  flatRoutes: FlatRoutes,
) => {

  const navRoutes = getNavRoutes(mainNavMobileConfig, flatRoutes);

  return appNavigationType === RouterAppNavType.BOTTOM
    ? navRoutes.slice(0, ROUTER_APP_BOTTOM_NAV_ITEMS_AMOUNT)
    : navRoutes.slice(ROUTER_APP_BOTTOM_NAV_ITEMS_AMOUNT, navRoutes.length);
};

/**
 * Returns all paths object
 */
export const getPaths = () => getPathsConfig();

/**
 * Returns path for particular route considering urlParams array
 * eg. getPath(RouteName.SIGNUP_RESEND, [token])
 */
export const getPath = (name: RouteName, urlParams?: string[]) => {
  const path = getPaths()[name];

  return urlParams
    ? getPathWithUrlParams(path, urlParams)
    : path;
};

export const getLastUrlFragment = (path: string) => {
  const pathParts = path.split('/');
  return pathParts[pathParts.length - 1];
};

/**
 * Filters out user reading routes
 * TODO: Refactor routing/navigation so that it's no longer needed (SON-16712)
 */
export const withoutMeterReadingsRoute = (routes: FlatRoutes): FlatRoutes =>  omitBy(
  routes,
  route =>
  route?.name === RouteName.FLAT_USER_READINGS
  || route?.name === RouteName.TARIFF_USER_READINGS,
);

const getFactorizedPath = (path: string) => {
  return path.lastIndexOf('/') === path.length - 1
    ? path.slice(0, path.length - 1)
    : path;
};

/**
 * Returns path including url params
 */
const getPathWithUrlParams = (path: string, urlParams: string[]) => {
  let urlParamIndex = 0;
  const factorizedPath: string[] = [];

  path.split('/')
    .forEach(pathPart => {
      if (pathPart.includes(':')) {
        pathPart = urlParams[urlParamIndex];
        urlParamIndex++;
      }
      factorizedPath.push(pathPart);
    });

  return factorizedPath.join('/');
};

/**
 * Returns factorized route with nested subroutes based on routes config passed as a second argument.
 * Config could be already filtered by FF and routes access
 */
const getFactorizedRoute = (route: Route, flatRoutes: FlatRoutes) => {
  if (route.subRoutesRefs) {
    const subRoutes = getDeepSubRoutes(route.subRoutesRefs, flatRoutes);

    return {
      ...route,
      subRoutes,
    };
  }
  return route;
};

/**
 * Iterate on single route 'subRoutesRefs' which are references
 * to routes objects from flat routes config passed in as a second argument
 * and build subRoutes depends on it.
 * Passed 'flatRoutes' should be already filtered by FF & routes access
 * Returns factorized subRoutes array
 */
const getDeepSubRoutes = (subRoutesRefs: RouteName[], flatRoutes: FlatRoutes): Route[] => {
  const subRoutes: Route[] = [];

  subRoutesRefs.forEach(ref => {
    let subRoute = flatRoutes[ref];

    if (!subRoute) { return; }
    if (subRoute.subRoutesRefs) {
      subRoute = {
        ...subRoute,
        subRoutes: getDeepSubRoutes(subRoute.subRoutesRefs, flatRoutes),
      };
    }
    subRoutes.push(subRoute);
  });
  return subRoutes;
};

const getProtectedRoutes = (routes: Route[]) => {
  return routes.filter(route => route.routeType === RouteType.PROTECTED);
};

const getPublicRoutes = (routes: Route[]) => {
  return routes.filter(route => route.routeType === RouteType.PUBLIC);
};

const getPublicOnlyRoutes = (routes: Route[]) => {
  return routes.filter(route => route.routeType === RouteType.PUBLIC_ONLY);
};

const getAuthorizedRoutes = (routes: Route[]) => {
  return ([
    ...getProtectedRoutes(routes),
    ...getPublicRoutes(routes),
  ]);
};

const getUnauthorizedRoutes = (routes: Route[]) => {
  return ([
    ...getPublicRoutes(routes),
    ...getPublicOnlyRoutes(routes),
  ]);
};

const getNavSubRoutes = (route: RouterNavNestedRoute, flatRoutes: FlatRoutes) => (
  route.subRoutes.map(route => flatRoutes[route]).filter(route => !!route) as any as Route[]
);
