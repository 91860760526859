import { RouteName, RouterNavConfig } from '../router.types';

export const accountNavConfig: RouterNavConfig = [
  {
    main: RouteName.MY_ACCOUNT,
    subRoutes: [
      RouteName.MY_ACCOUNT_ABOUT,
      RouteName.MY_ACCOUNT_PASSWORD,
      RouteName.MY_ACCOUNT_SETTINGS,
    ],
  },
];

export const accountNavMobileConfig: RouterNavConfig = [
  {
    main: RouteName.MY_ACCOUNT,
    subRoutes: [
      RouteName.MY_ACCOUNT_ABOUT,
      RouteName.MY_ACCOUNT_PASSWORD,
      RouteName.MY_ACCOUNT_SETTINGS,
      RouteName.LOGOUT,
    ],
  },
];
