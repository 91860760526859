import { CountryCode } from '@sonnen/shared-web';
import * as moment from 'moment';

import { getArrayFromEnum } from '+app/utils/array.util';
import { CountryHelper } from './country.helper';

export interface SummerTimeDate {
  summerTimeStarts: string;
  summerTimeEnds: string;
}

export interface SummerTimeChange {
  isSummerTimeStartDate: boolean;
  isSummerTimeEndDate: boolean;
}

export enum Month {
  JANUARY,
  FABRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER,
}

export enum Weekday {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

const summerTimeFormat = 'YYYY-MM-DD';

const getWeekdayOfFirstMonthDay = (year: number, month: Month) =>
  moment.utc([year, month, 1, 0, 0, 0, 0]).isoWeekday();

const getWeekdayOfLastMonthDay = (year: number, month: Month) =>
  moment.utc([year, month, 1, 0, 0, 0, 0]).endOf('month').isoWeekday();

const isSunday = (weekday: Weekday) => weekday === Weekday.SUNDAY;

const getXSunday = (
  year: number,
  month: Month,
  xSunday: number = 1,
) => {
  const weekday = getWeekdayOfFirstMonthDay(year, month);
  const daysToSunday = Weekday.SUNDAY - weekday;

  return isSunday(weekday)
    ? moment.utc([year, month, 1, 0, 0, 0, 0]).add('days', (xSunday - 1) * 7).format(summerTimeFormat)
    : moment.utc([year, month, 1, 0, 0, 0, 0]).add('days', ((xSunday - 1) * 7) + daysToSunday).format(summerTimeFormat);
};

const getLastSunday = (
  year: number,
  month: Month,
) => {
  const weekday = getWeekdayOfLastMonthDay(year, month);

  return isSunday(weekday)
    ? moment.utc([year, month, 1, 0, 0, 0, 0]).endOf('month').format(summerTimeFormat)
    : moment.utc([year, month, 1, 0, 0, 0, 0]).endOf('month').add('days', - weekday).format(summerTimeFormat);
};

const getSummerTimeDate = (countryCode: CountryCode, year: number): SummerTimeDate | undefined => {
  switch (true) {
    case CountryHelper.isEuropeanCountry(countryCode):
      return {
        summerTimeStarts: getLastSunday(year, Month.MARCH),
        summerTimeEnds: getLastSunday(year, Month.OCTOBER),
      };
    case countryCode === CountryCode.US:
      return {
        summerTimeStarts: getXSunday(year, Month.MARCH, 2),
        summerTimeEnds: getXSunday(year, Month.NOVEMBER),
      };
    case countryCode === CountryCode.AU:
      return {
        summerTimeStarts: getXSunday(year, Month.OCTOBER),
        summerTimeEnds: getXSunday(year, Month.APRIL),
      };
    default:
      return undefined;
  }
};

const getSummerTimeChange = (countryCode: CountryCode | undefined, date: string | undefined): SummerTimeChange => {
  const summerTimeNotMatch: SummerTimeChange = {
    isSummerTimeStartDate: false,
    isSummerTimeEndDate: false,
  };

  if (!countryCode || !getArrayFromEnum(CountryCode).includes(countryCode) || !date) {
    return summerTimeNotMatch;
  }

  const year = moment(date).year();
  const summerTime = getSummerTimeDate(countryCode, year);

  return summerTime
    ? {
      isSummerTimeStartDate: moment(date).format(summerTimeFormat) === summerTime.summerTimeStarts,
      isSummerTimeEndDate: moment(date).format(summerTimeFormat) === summerTime.summerTimeEnds,
    }
    : summerTimeNotMatch;
};

const isSummerTimeChangeDay = (summerTimeChange: SummerTimeChange) => {
  const { isSummerTimeStartDate, isSummerTimeEndDate } = summerTimeChange;
  return isSummerTimeStartDate || isSummerTimeEndDate;
};

export const SummerTimeHelper = {
  getSummerTimeChange,
  isSummerTimeChangeDay,
};
