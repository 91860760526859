import { Data, MergedData } from '@coolio/json-api';
import { DocumentStatus } from '@sonnen/shared-web';

export enum DocumentType {
  HARDWARE = 'hardware-offers',
  FLAT = 'documents',
}

export enum FlatDocumentType {
  FLAT_OFFER = 'flat-offers',
  FLAT_CONFIGURATION = 'flat-configurations',
  FLAT_ORDER_CONFIRMATION = 'flat-order-confirmation',
  IMPACT_ANALYSIS = 'impact-analysis',
  CESSION = 'cession',
}

export interface DocumentAttributes {
  fileName: string;
  createdAt: Date;
  status: DocumentStatus;
}

export type DocumentData = Data<DocumentAttributes>;
export type Document = MergedData<DocumentData>;
