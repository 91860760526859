import { isNil } from 'lodash';
import * as R from 'ramda';

import { Site } from '+app/shared/store/site/types/site.interface';

export const trimString = (strings: TemplateStringsArray, ...values: string[]) => {
  const notNullValues = values.map(value => value || '');

  const interpolation = strings.reduce((prev, current) => {
    return prev + current + (notNullValues.length ? notNullValues.shift() : '');
  }, '');

  return interpolation.trim();
};

export const isEmptyString = (str: string) => !str || (str && !str.trim());

export const fillEmptyValueWithDash = (value: string | number | undefined) =>
  !isNil(value) ? String(value) : '-';

export const formatSiteAddress = (site: Partial<Site>) => {
  let street = trimString`${site.street || ''} ${site.streetNumber || ''}`;
  street = street ? `${street}, ` : '';

  return site ? (
    trimString`${street} ${site.postalCode || ''} ${site.city || ''}`
  ) : '';
};

export const parseUrlStringToObject = (urlString = '') => {
  const pureUrlString = urlString.substring(1);
  const urlParamsObject = pureUrlString
    ? JSON.parse(
      `{"${pureUrlString.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
      (key, value) => (key === '' ? value : decodeURIComponent(value)),
    ) : {};

  return urlParamsObject;
};

export const decodeCssIcons = R.compose(
  str => String.fromCharCode(Number(`0x${str}`)),
  R.replace('\\', ''),
  R.replace(new RegExp(/"/, 'g'), ''),
);

export const removeDuplicatedChar = (char: string) =>
  (stringValue: string) => Array.from(new Set(stringValue.split(char))).join(char);

export const StringHelper = {
  trimString,
  isEmptyString,
  fillEmptyValueWithDash,
  formatSiteAddress,
  parseUrlStringToObject,
};
