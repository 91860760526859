import { isNil } from 'lodash/fp';
import { createSelector } from 'reselect';

import { StoreState } from '+app/store/store.interface';
import { getBatteryData } from '+battery/store/battery.selectors';
import { BatteryControllerType } from '+battery/store/types/battery.interface';
import { getStatus } from '+utils/query.util';
import { GET_CHARGER_LIVE_DATA_QUERY, GET_SITE_LIVE_DATA_QUERY, LiveDataPollingType } from './live.state';
import { CHARGER_ONLINE_STATUS, ChargerLiveDataCar, ChargerLiveDataStatus } from './types/chargerLiveData.interface';
import { SiteLiveStateType } from './types/siteLiveData.interface';

export const getLiveState = (store: StoreState) => store.live;

export const getSiteLiveData = createSelector(
  getLiveState,
  state => state.siteLiveData,
);

export const getChargerLiveData = createSelector(
  getLiveState,
  state => state.chargerLiveData,
);

export const getSiteLiveDataQueryStatus = createSelector(
  getLiveState,
  state => getStatus(state[GET_SITE_LIVE_DATA_QUERY]),
);

export const getSiteLiveDataQueryConsecutiveErrorCount = createSelector(
  getLiveState,
  state => state[GET_SITE_LIVE_DATA_QUERY].errorCount || 0,
);

export const getChargerLiveDataQueryStatus = createSelector(
  getLiveState,
  state => getStatus(state[GET_CHARGER_LIVE_DATA_QUERY]),
);

export const getChargerLiveDataQueryErrorCount = createSelector(
  getLiveState,
  state => state[GET_CHARGER_LIVE_DATA_QUERY].errorCount || 0,
);

export const getActivePollings = createSelector(
  getLiveState,
  state => state.activePollings,
);

export const isPollingActive = (pollingType?: LiveDataPollingType) => createSelector(
  getActivePollings,
  activePollings => !isNil(pollingType)
    ? activePollings.includes(pollingType)
    : !!activePollings.length,
);

export const getLiveStateStatus = createSelector(
  getLiveState,
  state => state.liveStateStatus,
);

export const getLiveStatePollingTickCount = createSelector(
  getLiveState,
  state => state.siteLiveDataPollingTickCount,
);

// SITE_LIVE_DATA

export const getSiteLiveDataTimestamp = createSelector(
  getSiteLiveData,
  liveData => liveData?.timestamp,
);

export const getSiteLiveDataMeasurementMethod = createSelector(
  getSiteLiveData,
  liveData => liveData?.measurementMethod,
);

export const isSiteLiveDataFailover = createSelector(
  getSiteLiveData,
  getBatteryData,
  (liveData, BatteryData) =>
    liveData?.online
    && BatteryData.controllerType === BatteryControllerType.SPREE
    && liveData.type === SiteLiveStateType.FAILOVER_LIVE_STATES,
);

// CHARGER_LIVE_DATA

export const getChargerDepartureDate = createSelector(
  getChargerLiveData,
  liveData => liveData?.departureAt,
);

export const getChargerChargingSpeed = createSelector(
  getChargerLiveData,
  liveData => liveData?.chargeSpeedKmh || 0,
);

export const getChargerDistanceCharged = createSelector(
  getChargerLiveData,
  liveData => (liveData?.transactionChargedKm) || 0,
);

export const isChargerOnline = createSelector(
  getChargerLiveData,
  liveData => !!liveData && CHARGER_ONLINE_STATUS.includes(liveData.status || ChargerLiveDataStatus.OFFLINE),
);

export const isChargerConnected = createSelector(
  [getChargerLiveData, isChargerOnline],
  (liveData, isOnline) => isOnline && !!liveData && liveData.car === ChargerLiveDataCar.CONNECTED,
);

export const isChargerCharging = createSelector(
  [getChargerLiveData, isChargerConnected],
  (liveData, isConnected) =>
    isConnected && !!liveData && liveData.status === ChargerLiveDataStatus.CHARGING,
);

export const isChargerInSmartMode = createSelector(
  getChargerLiveData,
  liveData => !!(liveData && liveData.smartmode),
);

export const isChargerInPowerMode = createSelector(
  getChargerLiveData,
  liveData => !(liveData && liveData.smartmode),
);

export const isChargerChargingInSmartMode = createSelector(
  [isChargerCharging, isChargerInSmartMode],
  (isCharging, isSmartMode) => isCharging && isSmartMode,
);

export const isChargerChargingInPowerMode = createSelector(
  [isChargerCharging, isChargerInSmartMode],
  (isCharging, isSmartMode) => isCharging && !isSmartMode,
);

export const isChargerReady = createSelector(
  [getChargerLiveData, isChargerOnline, isChargerConnected],
  (liveData, isOnline, isConnected) =>
    isOnline && !isConnected && !!liveData && liveData.status === ChargerLiveDataStatus.AVAILABLE,
);
