import { T } from '@sonnen/shared-i18n/customer';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  DefaultParagraph,
  Icon,
  MediaCard,
} from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getGuideLeadPartnerName } from '+app/+guide/store/+lead.selectors';
import { getPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { StoreState } from '+app/store/store.interface';
import { history } from '+router/store';

import './GuideAcceptancePersonalDetailsError.component.scss';

const GuideAcceptancePersonalDetailsErrorButtons: React.FC = () => (
  <div className={'c-guide-acceptance-personal-details-error__buttons'}>
    <div className={'c-guide-acceptance-personal-details-error__button'}>
      <Button
        label={I18n.t(T.customerGuidance.acceptance.personalDetailsError.button.contact)}
        size={ButtonSize.SECONDARY}
        theme={ButtonTheme.OUTLINE}
        onClick={() => history.push(getPath(RouteName.GUIDE_CONTACT))}
      />
    </div>
    <div className={'c-guide-acceptance-personal-details-error__button'}>
      <Button
        label={I18n.t(T.customerGuidance.acceptance.personalDetailsError.button.goBack)}
        size={ButtonSize.SECONDARY}
        theme={ButtonTheme.LIGHT}
        onClick={() => history.goBack()}
      />
    </div>
  </div>
);

const mapStateToProps = (state: StoreState) => ({
  partnerName: getGuideLeadPartnerName(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  ;

export const GuideAcceptancePersonalDetailsErrorComponent: React.FC<Props> = ({ partnerName }) => (
  <div className={'c-guide-acceptance-personal-details-error'}>
    <MediaCard
      footerContent={<GuideAcceptancePersonalDetailsErrorButtons />}
    >
      <Icon.Phone className={'c-guide-acceptance-personal-details-error__icon'}/>
      <p className={'c-guide-acceptance-personal-details-error__title'}>
        {I18n.t(T.customerGuidance.acceptance.personalDetailsError.title, { partnerName })}
      </p>
      <DefaultParagraph className={'c-guide-acceptance-personal-details-error__description'}>
        {I18n.t(T.customerGuidance.acceptance.personalDetailsError.description)}
      </DefaultParagraph>
    </MediaCard>
  </div>
);

export const GuideAcceptancePersonalDetailsError =
  connect(mapStateToProps)(GuideAcceptancePersonalDetailsErrorComponent);
