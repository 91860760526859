import * as React from 'react';
import GraphLoader from '../../../components/loaders/GraphLoader/GraphLoader';
import { withResponsiveness } from '../../../shared/graphs';
import { FrequencyChart as Chart } from '../components/FrequencyChart';
import { Frequency, FrequencyResponse } from './Frequency.component';

interface Props {
  width: number;
  height: number;
}

const FrequencyChartComponent: React.FC<Props> = ({ width, height }) => (
  <Frequency>
    {({ loading, data, invalid }: FrequencyResponse) => (
      <>
        {loading && (<GraphLoader />)}
        {invalid && (<span>Error!</span>)}
        {(data && !!data.length && (
          <Chart
            width={width}
            height={height}
            data={data}
          />
        ))}
      </>
    )}
  </Frequency>
);

export const FrequencyChart = withResponsiveness(FrequencyChartComponent);
