import { storeProvider } from '+app/store/store.provider';
import { Jwt } from '+app/utils/jwt.util';
import { NetworkCode, ResponseError } from '+shared/network/network.interface';
import { isAuthorizedDomain, isResponseError } from '+shared/network/network.util';
import {
  getHostname,
  HttpFetch,
  HttpInterceptor,
  HttpResponse,
  NormalizedHttpOptions,
} from '@coolio/http';
import { last, trim } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { GuideActions } from '../../+guide/store/+guide.actions';
import { GuideRepository } from '../../+guide/store/+guide.repository';
import { authorizationTokenProvider } from './authorizationTokenProvider';

const isUnauthorizedError = (error: ResponseError) => error.response.status === NetworkCode.UNAUTHORIZED;
const isAuthRequest = ({ url }: NormalizedHttpOptions) => last(url.split('/')) === 'verify-token';

export const createIdentityVerifierInterceptor = (): {
  saga: (action: ReturnType<typeof GuideActions.verifyIdentity>) => SagaIterator;
  interceptor: HttpInterceptor
} => {
  const pendingRequests: Array<() => void> = [];
  let isAuthInProgress = false;
  let isRequestInProgress = false;

  const deferRequest = <T extends any>(promise: () => Promise<T>): Promise<T> =>
    new Promise((resolve, reject) => {
      pendingRequests.push(() => {
        promise().then(resolve, reject);
      });
    });

  const requestAuth = async () => {
    isAuthInProgress = true;
    storeProvider.dispatch(GuideActions.requireIdentityVerification());
  };

  const resume = () => {
    if (isRequestInProgress) {
      return;
    }
    const pendingRequest = pendingRequests.shift();
    if (pendingRequest) {
      pendingRequest();
    }
  };

  const processRequest = async <T extends any>(
    promise: HttpFetch<T>,
    options: NormalizedHttpOptions,
  ): Promise<HttpResponse<T>> => {
    if (
      (isRequestInProgress || isAuthInProgress)
      && !isAuthRequest(options)
    ) {
      return deferRequest(() => processRequest(promise, options));
    }

    isRequestInProgress = true;
    try {
      if (isAuthorizedDomain(getHostname(options.url)) && options.headers) {
        const authToken = authorizationTokenProvider.getToken();

        if (authToken) {
          options.headers.Authorization = `Bearer ${authToken}`;
        }
      }

      const response = await promise();
      isRequestInProgress = false;
      resume();
      return response;
    } catch (error) {
      isRequestInProgress = false;
      throw error;
    }
  };

  return {
    saga: function * verifyIdentitySaga({
      zipCode,
      persisted,
    }: ReturnType<typeof GuideActions.verifyIdentity>): SagaIterator {
      try {
        const response = yield call(
          GuideRepository.verifyToken,
          trim(zipCode),
        );
        const { leadId } = Jwt.decode<{ leadId: string }>(response.token);

        yield put(persisted
          ? GuideActions.setVerifiedAuthToken(response.token, leadId)
          : GuideActions.setVerifiedAuthToken(response.token));
        isAuthInProgress = false;

        resume();
        yield put(GuideActions.identityVerified());
      } catch (e) {
        yield put(GuideActions.verifyIdentityError(e));
      }
    },
    interceptor: <T extends any>(
      promise: HttpFetch<T>,
      options: NormalizedHttpOptions,
    ): HttpFetch<T> => async () => {
      if (!isAuthInProgress) {
        try {
          return await processRequest(promise, options);
        } catch (error) {
          if (!isResponseError(error) || !isUnauthorizedError(error)) {
            resume();
            throw error;
          }
          await requestAuth();
        }
      }
      return await processRequest(promise, options);
    },
  };
};
