import { HttpFetch, HttpInterceptor, NormalizedHttpOptions } from '@coolio/http';

import { storeProvider } from '+app/store/store.provider';
import { getAuthorizationHeader, getImpersonatedUserId } from '+auth/store/auth.selectors';
import { getCurrentUserId } from '../store/user/user.selectors';

export const createProductMigrationAuthInterceptor = (): HttpInterceptor => {
  const getToken = () => getAuthorizationHeader(storeProvider.getState());
  const getUser = () => getCurrentUserId(storeProvider.getState());
  const getImpersonateHeader = () => getImpersonatedUserId(storeProvider.getState());

  return <T extends any>(
    promise: HttpFetch<T>,
    options: NormalizedHttpOptions,
  ): HttpFetch<T> => () => {
    const impersonateHeader = getImpersonateHeader();
    options.headers.Authorization = getToken();
    options.headers['x-user'] = getUser() || '';

    if (!!impersonateHeader) {
      options.headers['X-Impersonate-User'] = impersonateHeader;
    }

    return promise();
  };
};
