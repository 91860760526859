import { Page } from '+app/shared/containers/Page';
import { StoreState } from '+app/store/store.interface';
import {
  ContractEnergyDocumentsList,
  ContractEnergyInvoices,
} from '+dashboard/+contract/components';
import { ContractActions } from '+dashboard/+contract/store';
import {
  getAllContracts,
  getContractFilesQueryStatus,
  getContractFilesRequest,
  getContractInvoicesQueryStatus,
  getContractInvoicesRequest,
  getCurrentSiteContractData,
} from '+dashboard/+contract/store/contract.selectors';
import { Contract, ContractStatus, ContractTypes, FinancialServiceProvider } from '+dashboard/+contract/store/types/contract.interface';
import { FiltersSection } from '+dashboard/+documents/containers/DocumentsInvoices/FiltersSection.component';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/customer';
import { PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  getContractEnergyFileItems,
  getContractEnergyInvoiceItems,
  getFilteredContracts,
  mapContractsToSelectOptions,
} from './ContractEnergyDocuments.helper';

const mapStateToProps = (state: StoreState) => ({
  contractFilesRequestData: getContractFilesRequest(state),
  contractInvoicesRequestData: getContractInvoicesRequest(state),
  contract: getCurrentSiteContractData(state),
  contracts: getAllContracts(state),
  contractFilesQueryStatus: getContractFilesQueryStatus(state),
  contractInvoicesQueryStatus: getContractInvoicesQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  getContractFilesRequestData: ContractActions.getContractFilesRequestData,
  getContractInvoicesRequestData: ContractActions.getContractInvoicesRequestData,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
;

const ContractEnergyDocumentsComponent: React.FC<Props> = ({
  actions,
  contract,
  contracts,
  contractFilesRequestData,
  contractInvoicesRequestData,
  contractFilesQueryStatus,
  contractInvoicesQueryStatus,
}) => {
  const refreshRequestDataIntervalId = React.useRef(0);

  const filteredContracts = getFilteredContracts(contracts);
  const initialContractId = filteredContracts.length ? filteredContracts[0].id : undefined;

  const [selectedContractId, setSelectedContractId] = React.useState<string | undefined>(initialContractId);
  const updateSelectedContract = (contractId: string) => {
    setSelectedContractId(contractId);

    if (!contractFilesRequestData[contractId]) {
      actions.getContractFilesRequestData(contractId);
    }
    if (!contractInvoicesRequestData[contractId]) {
      actions.getContractInvoicesRequestData(contractId);
    }
  };

  React.useEffect(() => {
    if (selectedContractId) {
      actions.getContractFilesRequestData(selectedContractId);
      actions.getContractInvoicesRequestData(selectedContractId);
    }
    refreshRequestDataIntervalId.current = window.setInterval(() => {
      if (selectedContractId) {
        actions.getContractFilesRequestData(selectedContractId);
        actions.getContractInvoicesRequestData(selectedContractId);
      }
    }, 29 * 60 * 1000); // NOTE: 29 min., because the token in the request data is valid for 30 min.

    return () => {
      clearInterval(refreshRequestDataIntervalId.current);
    };
  }, []);

  const selectedInvoices = selectedContractId ? contractInvoicesRequestData[selectedContractId] : [];
  const selectedFiles = selectedContractId ? contractFilesRequestData[selectedContractId] : [];

  const dropdownOptions = mapContractsToSelectOptions(filteredContracts);

  return (
    <Page>
      {!!dropdownOptions.length && (
        <FiltersSection
          options={dropdownOptions}
          selectedContractId={selectedContractId}
          updateSelectedContract={updateSelectedContract}
          numberOfContracts={filteredContracts.length}
        />
      )}
      <div className={'o-grid'}>
        <section className={'o-grid__column o-grid__column--sm-6 o-grid__column--no-gap-sm'}>
          <PageSubheadline mediumGap={true}>
            {I18n.t(T.sonnenEnergy.documents.mainSectionTitle)}
          </PageSubheadline>
          <ContractEnergyInvoices
            invoices={selectedInvoices && getContractEnergyInvoiceItems(selectedInvoices)}
            hasContractInvoicesQueryFailed={contractInvoicesQueryStatus.error}
            isLoading={contractInvoicesQueryStatus.pending}
          />
        </section>
        <section
          className={'o-grid__column o-grid__column--sm-6 o-grid__column--no-gap'}
        >
          <ContractEnergyDocumentsList
            files={selectedFiles && getContractEnergyFileItems(selectedFiles)}
            hasContractFilesQueryFailed={contractFilesQueryStatus.error}
            isLoading={contractFilesQueryStatus.pending}
          />
        </section>
      </div>
    </Page>
  );
};

export const ContractEnergyDocuments = connect(mapStateToProps, mapDispatchToProps)(ContractEnergyDocumentsComponent);
