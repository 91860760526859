import { ImpactAnalysis, Offer, Proposal } from '+app/+guide/store/types';
import { findIndex } from 'lodash';

export const prepareProposal = (offers: Offer[], impactAnalysis: ImpactAnalysis[]): Proposal[] => {

  const iaList: ImpactAnalysis[] = [...impactAnalysis];

  return offers.map(offer => {
      const iaIndex = findIndex(iaList, ia => ia.configurations.id === offer.configuration.id);
      const ia = iaIndex >= 0 ? iaList.splice(iaIndex, 1)[0] : undefined;

      return {
        ...offer.configuration,
        offer,
        ia,
      };
    },
  );
};
