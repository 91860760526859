import 'd3-transition';
import * as React from 'react';
import { Y_AXIS_MARGIN } from '../../../../frequency.constants';

interface Props {
  clipId: string;
  y: number;
  width: number;
  height: number;
}

export class EnergyRayClip extends React.PureComponent<Props> {
  static defaultProps = {
    y: 0,
    width: 0,
    height: 0,
    reposition: false,
  };

  clipRef: Element | null = null;

  setClipRef = (ref: Element | null) => {
    this.clipRef = ref;
  };

  render() {
    const {
      clipId,
      width,
      height,
      y,
    } = this.props;

    return (
      <defs>
        <clipPath
          id={clipId}
          ref={this.setClipRef}
        >
          <rect
            className="clip-rect"
            height={height - y - Y_AXIS_MARGIN - 35}
            width={width}
            transform={`translate(-12, ${y + Y_AXIS_MARGIN})`}
          />
        </clipPath>
      </defs>
    );
  }
}
