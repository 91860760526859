import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

import { MeasurementsV2FilterResolution } from '+analysis/store/types/measurementsV2.interface';
import { Resolution } from '+analysis/store/types/resolution.interface';
import { StatisticsV2FilterResolution } from '+analysis/store/types/statisticsV2.interface';
import { DateUtil } from '+utils/date.util';

export enum TimePeriod {
  LAST_30_DAYS = 'last-30-days',
  CURRENT_YEAR = 'current-year',
  YEAR = 'year',
}

export type CSVModalForm = {
  timePeriod: TimePeriod;
  energyDataInWattHours: boolean;
  powerDataInWatt: boolean;
};

export const getStatisticsResolution = (timePeriod: TimePeriod): StatisticsV2FilterResolution => {
  switch (timePeriod) {
    case TimePeriod.LAST_30_DAYS:
      return Resolution.RES_1_HOUR;
    case TimePeriod.CURRENT_YEAR:
    case TimePeriod.YEAR:
    default:
      return Resolution.RES_1_DAY;
  }
};

export const getMeasurementsResolution = (timePeriod: TimePeriod): MeasurementsV2FilterResolution => {
  switch (timePeriod) {
    case TimePeriod.LAST_30_DAYS:
      return Resolution.RES_1_HOUR;
    case TimePeriod.CURRENT_YEAR:
    case TimePeriod.YEAR:
    default:
      return Resolution.RES_3_HOURS;
  }
};

export const getStartEndDates = (timePeriod: TimePeriod, year: number, batteryTimezone: string | null | undefined) => {
  switch (timePeriod) {
    case TimePeriod.LAST_30_DAYS:
      return {
        start: DateUtil.getDateInTimezone(undefined, batteryTimezone).subtract(30, 'days').startOf('day').toISOString(),
        end: DateUtil.getDateInTimezone(undefined, batteryTimezone).startOf('day').toISOString(),
      };
    case TimePeriod.CURRENT_YEAR:
      return {
        start: DateUtil.getDateInTimezone(undefined, batteryTimezone).startOf('year').toISOString(),
        end: DateUtil.getDateInTimezone(undefined, batteryTimezone).endOf('year').toISOString(),
      };
    default:
      return {
        start: DateUtil.getDateInTimezone(year.toString(), batteryTimezone).startOf('year').toISOString(),
        end: DateUtil.getDateInTimezone(year.toString(), batteryTimezone).endOf('year').toISOString(),
      };
  }
};

export const getResolutionText = (resolution: Resolution) => {
  switch (resolution) {
    case Resolution.RES_1_DAY:
      return I18n.t(T.analysis.csvModal.resolution1Day);
    case Resolution.RES_3_HOURS:
      return I18n.t(T.analysis.csvModal.resolution3Hours);
    default:
      return I18n.t(T.analysis.csvModal.resolution1Hour);
  }
};
