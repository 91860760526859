import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';

import { Profile, ProfileError } from '+account/store/types/profile.interface';
import { QueryActions } from '+shared/store/query/query.actions';
import { combineSagas, dataGuard, processQuery } from '+utils/saga';
import { COMMUNITY_ACTIONS, CommunityActions } from './community.actions';
import { CommunityRepository } from './community.repository';
import {
  GET_COMMUNITY_CONTENT_HUB_ARTICLES_QUERY,
  GET_COMMUNITY_PROFILE_QUERY,
  GET_COMMUNITY_STATS_QUERY,
} from './community.state';
import { CommunityAttributes, CommunityContentHubArticle, CommunityContentHubAttributes } from './types/community.interface';

export const sagas = combineSagas(
  takeLatest(COMMUNITY_ACTIONS.GET_COMMUNITY_STATS_DATA, getCommunityStatsData),
  takeLatest(COMMUNITY_ACTIONS.SELECT_COMMUNITY_PROFILE, getCommunityProfileData),
  takeLatest(COMMUNITY_ACTIONS.GET_COMMUNITY_CONTENT_HUB_ARTICLES, getCommunityContentHubArticles),
);

function * getCommunityStatsData(): SagaIterator {
  yield processQuery(
    GET_COMMUNITY_STATS_QUERY,
    CommunityRepository.getCommunityStatsData,
    {
      onSuccess: res => dataGuard(CommunityActions.setCommunityStatsData)(res as CommunityAttributes),
      onFailure: (error: any) => put(QueryActions.failure(GET_COMMUNITY_STATS_QUERY, error as Error)),
    },
  )({});
}

function * getCommunityProfileData(
  action: ReturnType<typeof CommunityActions.selectCommunityProfile>,
): SagaIterator {
  yield processQuery(
    GET_COMMUNITY_PROFILE_QUERY,
    CommunityRepository.getCommunityProfileData,
    {
      onSuccess: res => put(CommunityActions.setCommunityProfileData(res as Profile)),
      onFailure: (error: ProfileError) => put(QueryActions.failure(GET_COMMUNITY_PROFILE_QUERY, error as Error)),
    },
  )({
    mapPoint: action.mapPoint,
  });
}

function* getCommunityContentHubArticles(): SagaIterator {
  yield processQuery(
    GET_COMMUNITY_CONTENT_HUB_ARTICLES_QUERY,
    CommunityRepository.getCommunityContentHubArticles,
    {
      onSuccess: res => call(setCommunityContentHubArticles, res!),
      onFailure: (error: ProfileError) =>
        put(QueryActions.failure(GET_COMMUNITY_CONTENT_HUB_ARTICLES_QUERY, error as Error)),
    },
  )({});
}

function* setCommunityContentHubArticles(res: CommunityContentHubAttributes): SagaIterator {
  const articles: CommunityContentHubArticle[] = res.content.components[0].articles
    .slice(0, 18)
    .map(article => ({
      title: article.headline.heading,
      description: article.paragraph.content,
      date: article.publishingDate,
      imageSrc: article.image.image.file.src,
      imageAlt: article.image.image.file.alt,
      link: article.url,
    }),
  );

  yield put(CommunityActions.setCommunityContentHubArticles(articles));
}
