import {
  DataContainerTransformExtensionOptions,
} from '@kanva/charts';
import { Visibility } from '@kanva/core';
import { EnergyFlowSeriesKey } from '@sonnen/shared-web';
import * as React from 'react';

import { PlatformSelector } from '+components';
import { DeepPartial } from 'redux';
import { AnalysisAreaChartMobile } from './AnalysisAreaChart.mobile.component';
import { AnalysisAreaChartWeb } from './AnalysisAreaChart.web.component';

import { useAnalysisDayChart } from '../../containers/AnalysisKanvaProvider/AnalysisDayChartProvider';
import { useAnalysis } from '../../containers/AnalysisProvider/AnalysisProvider.context';

interface AnalysisAreaChartProps {
  chartHeight: number;
  scaleOptions: DeepPartial<DataContainerTransformExtensionOptions>;
}

// TODO: Introduce memo
export const AnalysisAreaChart: React.FC<AnalysisAreaChartProps> = (props) => {
  const { extensions } = useAnalysisDayChart();
  const {
    commonData: {
      selectedDataSeriesKeys,
    },
    query: {
      measurementsQueryStatus,
    },
  } = useAnalysis();

  const isVisible = (key: EnergyFlowSeriesKey) =>
    selectedDataSeriesKeys.includes(key)
      ? Visibility.VISIBLE
      : Visibility.INVISIBLE;

  React.useEffect(() => {
    const transformExtension = extensions?.transform;
    if (transformExtension) {
      transformExtension.setOptions(props.scaleOptions);
    }
  }, [extensions?.transform]);

  // TODO: introduce react memo
  return (
    <>
      <PlatformSelector
        web={
          <AnalysisAreaChartWeb
            queryStatus={measurementsQueryStatus}
            chartHeight={props.chartHeight}
            isVisible={isVisible}
          />
        }
        mobile={
          <AnalysisAreaChartMobile
            queryStatus={measurementsQueryStatus}
            chartHeight={props.chartHeight}
            isVisible={isVisible}
          />
        }
      />
    </>
  );
};
