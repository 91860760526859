import { StoreState } from '+app/store/store.interface';
import { SiteSwitcherButtonShared } from '+shared/containers/SiteSwitcherButton';
import { getAllSitesOrdered } from '+shared/store/site/site.selectors';
import { Site } from '+shared/store/site/types/site.interface';
import * as React from 'react';
import * as FlipMove from 'react-flip-move';
import { connect } from 'react-redux';

import './SiteSwitcherList.component.scss';

const mapStateToProps = (state: StoreState) => ({
  allSites: getAllSitesOrdered(state),
});

interface Props {
  allSites: Site[];
}

const SiteSwitcherListComponent: React.FC<Props> = ({ allSites }) => (
  <FlipMove
    typeName={'ul'}
    duration={700}
    easing={'ease'}
    staggerDurationBy={15}
    className={'c-site-switcher-list'}
  >
    {allSites.map((site) => site ? (
      <li
        className={'c-site-switcher-list__item'}
        key={site.id}
        data-test-id={`user-sites-dropdown-site-${site.id}`}
      >
        <SiteSwitcherButtonShared site={site} />
      </li>
    ) : undefined)}
  </FlipMove>
);

// TODO: Go the hell with this connect!
export const SiteSwitcherList = connect(mapStateToProps)(SiteSwitcherListComponent);
