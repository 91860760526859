import { CONFIG } from '+config';
import { useHttp, useJsonApi } from '+shared/network';
import { HttpResponse } from '@coolio/http';
import { BatteryData, BatteryStatusData, BatteryStatusHistoryFilters, BatteryUnitData, DocumentData } from './types';

const getBattery = (id: string) =>
  useJsonApi()
    .getList<BatteryData>(`${CONFIG.API.USER_DATA}/sites/${id}/battery-systems?include=product`)
    .resolveIncluded()
    .send()
    .then(({ elements }) => elements);

const getBatteryElectricUnits = (id: string) =>
  useJsonApi()
    .getList<BatteryUnitData>(`${CONFIG.API.USER_DATA}/battery-systems/${id}/electric-units`)
    .send()
    .then(res => res);

const getBatteryStatuses = ({ id , filters }: { id: string, filters: BatteryStatusHistoryFilters }) =>
  useJsonApi()
    .getList<BatteryStatusData>(`${CONFIG.API.USER_DATA}/battery-systems/${id}/statuses`)
    .filter('start_at', filters.startAt)
    .filter('end_at', filters.endAt)
    .filter('key', filters.key)
    .send()
    .then(({ elements }) => elements);

const getDocuments = ({ articleNumber, language }: { articleNumber: string; language: string }) =>
  useJsonApi()
    .getList<DocumentData>(`${CONFIG.API.DOCUMENTS}/documents`)
    .filter('devices_article_number_eq', articleNumber)
    .filter('language_contains_array', language)
    .filter('target_group_in', 'customer')
    .send();

const setBackupBuffer = ({
  id,
  value,
}: {
  id: string,
  value: string,
}) =>
  useHttp()
    .post<{errors: any[]}>(
      `${CONFIG.API.USER_DATA}/battery-systems/${id}/backup-power-buffer`,
      {
        body: {
          value,
        },
      },
    ).then((res: HttpResponse) => res.parsedBody());

export const BatteryRepository = {
  getBattery,
  getDocuments,
  getBatteryElectricUnits,
  getBatteryStatuses,
  setBackupBuffer,
};
