import {
  TransformIcon,
  TransformIconProps,
} from '@sonnen/shared-web';
import * as React from 'react';

import './NavToggle.component.scss';

type Props = TransformIconProps;

export const NavToggle: React.FC<Props> = ({
  type,
  onClick,
}) => (
    <div className={'c-nav-toggle'}>
      <TransformIcon
        type={type}
        onClick={onClick}
      />
    </div>
);
