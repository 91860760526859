import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import { IconComponent, IconType } from '@sonnen/shared-web/src/components/Icon/models';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './DemoInfoBanner.component.scss';

interface Props {
  icon: IconType;
}

export const DemoInfoBanner: React.FC<Props> = ({ icon }) => {
  const BannerIcon: IconComponent = Icon[icon];

  return (
    <div className={'c-demo-info-banner'}>
      <BannerIcon className={'c-demo-info-banner__icon'} />
      <div
        data-test-id={'demo-info-banner-title'} 
        className={'c-demo-info-banner__title'}
      >
        {I18n.t(T.demo.welcome.title)}
      </div>
      <div className={'c-demo-info-banner__description'}>
        {I18n.t(T.demo.welcome.description)}
      </div>
    </div>
  );
};
