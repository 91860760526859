import { DefaultParagraph } from '@sonnen/shared-web';
import { IconType } from '@sonnen/shared-web/src/components/Icon/models';
import * as React from 'react';

import { TooltipIcon } from '+app/shared/components';

import './AccountSettingsCardTitle.component.scss';

export interface AccountSettingsCardTitleProps {
  title: string;
  icon?: IconType;
  tooltipText?: string;
}

export const AccountSettingsCardTitle: React.FC<AccountSettingsCardTitleProps> = ({ title, icon, tooltipText }) => (
  <div className={'c-account-settings-card-title'}>
    {title}
    {icon && tooltipText && (
      <TooltipIcon
        icon={icon}
      >
        <DefaultParagraph>
          {tooltipText}
        </DefaultParagraph>
      </TooltipIcon>
    )}
  </div>
);
