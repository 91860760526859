import { isContractFreePeriodActive } from '+contract/store/helpers/contract.helpers';
import { Contract } from '+contract/store/types/contract.interface';
import { getDeliveryStatus, getFormattedDate } from '+legacy/helpers/dates';
import { getFormattedCentsAmount } from '+legacy/helpers/numbers';
import { trimString } from '+legacy/helpers/strings';
import { T } from '@sonnen/shared-i18n/customer';
import { isNil } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

const renderDeliveryAddress = (contract: Contract) => contract ? (
  <>
    <div data-test-id={'contract-tariff-overview-delivery-street-value'}>{trimString`${contract.shippingStreet || ''} ${contract.shippingStreetNumber || ''}`}</div>
    <div data-test-id={'contract-tariff-overview-delivery-postal-code-details-value'}>{trimString`${contract.shippingPostalCode} ${contract.shippingCity}`}</div>
  </>
) : null;

const isSonnenStromSolar = (contract: Contract) => (
  contract &&
  contract.contractType.toLowerCase() === 'sonnenstrom' &&
  contract.tariffType?.toLowerCase() === 'solar'
);

export const factorizeContractTariffOverview = (contract: Contract) => [
  {
    label: I18n.t(T.yourTariff.labels.product),
    value: contract.contractType === 'athenaEnergy' ? 'sonnenStrom NEU' : contract.name,
    dataTestId: 'contract-tariff-overview-product-name',
  },
  {
    label: I18n.t(T.yourTariff.labels.contractNumber),
    value: contract.contractNumber,
    dataTestId: 'contract-tariff-overview-contract-number',
  },
  {
    label: I18n.t(T.yourTariff.labels.rate),
    value: contract.appointedEnergyRate &&
      I18n.t(T.yourTariff.contractRate, { price: getFormattedCentsAmount(Number(contract.appointedEnergyRate)) }),
      dataTestId: 'contract-tariff-overview-contract-rate',
  },
  {
    label: I18n.t(T.yourTariff.labels.solarBaseFee),
    value: isSonnenStromSolar(contract) && I18n.t(T.yourTariff.solarBaseFee),
    dataTestId: 'contract-tariff-overview-solar-base-fee',
  },
  {
    label: I18n.t(T.yourTariff.labels.hardware),
    value: isSonnenStromSolar(contract) && I18n.t(T.yourTariff.solarHardware),
    dataTestId: 'contract-tariff-overview-hardware',
  },
  {
    label: I18n.t(T.yourTariff.labels.status),
    value: contract.deliveryStartAt &&
      getDeliveryStatus(
        contract.deliveryStartAt,
        T.yourTariff.deliveryStatus.active,
        T.yourTariff.deliveryStatus.inactive,
        T.yourTariff.deliveryStatus.notDelivered,
      ),
      dataTestId: 'contract-tariff-overview-delivery-status',
  },
  {
    label: I18n.t(T.yourTariff.labels.orderStatus),
    value: contract.orderedAt && getFormattedDate(contract.orderedAt),
    dataTestId: 'contract-tariff-overview-order-status',

  },
  {
    label: I18n.t(T.yourTariff.labels.deliveryAddress),
    value: renderDeliveryAddress(contract),
    dataTestId: 'contract-tariff-overview-delivery-address',
  },
  // TODO - fix the meterId key, as it's deprecated in /contract endpoint
  // {
  //   label: I18n.t(T.yourTariff.labels.gridpointMeter),
  //   value: (contract.meterId && !!contract.meterId.length) && contract.meterId[0],
  // },
  // {
  //   label: I18n.t(T.yourTariff.labels.productionMeter),
  //   value: (contract.meterId && contract.meterId.length > 1) && contract.meterId[1],
  // },
  {
    label: I18n.t(T.yourTariff.labels.communityMembershipFee),
    value: isSonnenStromSolar(contract) && I18n.t(
      !isNil(contract.orderedAt)
      && isContractFreePeriodActive(contract.orderedAt)
        ? T.yourTariff.communityMembershipFee
        : T.yourTariff.communityMembershipFee2,
    ),
    dataTestId: 'contract-tariff-overview-community-membership-fee',
  },
  {
    label: I18n.t(T.yourTariff.labels.cancellationPeriod),
    value: I18n.t(T.yourTariff.cancellationPeriod.monthly),
    dataTestId: 'contract-tariff-overview-cancelation-peroid',
  },
];
