import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { FormCheckbox } from '+app/shared/components/FormCheckbox';
import { Form, Formik } from 'formik';
import { GuideAcceptanceFlatTerm } from '../../components/GuideAcceptanceFlatTerm';
import { DocumentAcceptance, DocumentAcceptanceLinks } from '../../store';
import { DocumentAcceptanceForm, DocumentAcceptanceInitialValues, DocumentAcceptanceSchema } from './GuideAcceptanceDocuments.helper';

import './GuideAcceptanceDocuments.scss';

interface ComponentProps {
  handleSubmit: (formValues: DocumentAcceptanceForm) => void;
  isPostEeg: boolean;
  isPending: boolean;
  isError: boolean;
  providedLinks: DocumentAcceptanceLinks;
  acceptedMarketingMailing: boolean;
  isFlatXOffer: boolean;
}

type Props =
  & ComponentProps;

export const GuideAcceptanceDocuments: React.FC<Props> = ({
  handleSubmit,
  isPending,
  isError,
  acceptedMarketingMailing,
  isPostEeg,
  providedLinks,
  isFlatXOffer,
}) => {
  const initialFormValues = DocumentAcceptanceInitialValues(acceptedMarketingMailing);
  const isMarketingMailingAccepted = (document: DocumentAcceptance) =>
    document === DocumentAcceptance.MARKETING_MAILING && Boolean(initialFormValues[document]);

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validationSchema={DocumentAcceptanceSchema(isPostEeg)}
      validateOnBlur={false}
      validateOnChange={true}
      render={(form) => (
        <div className={'c-guide-acceptance-documents'}>
          <Form>
            {Object.values(DocumentAcceptance)
              .filter(document => (isPostEeg && document !== DocumentAcceptance.ASSIGNMENT_NOTICE) || !isPostEeg)
              .filter(document => document !== DocumentAcceptance.MARKETING_MAILING
                || !isMarketingMailingAccepted(document))
              .map((document, index) => (
                <div className={'c-guide-acceptance-documents__checkbox'}>
                  <FormCheckbox
                    name={document}
                    label={(<GuideAcceptanceFlatTerm providedLinks={providedLinks} name={document} />)}
                    form={form}
                    key={`checkbox-${index}`}
                  />
                </div>
              ))}
            {isError &&
              <p className={'c-guide-acceptance-documents__error'}>{I18n.t(T.general.messages.apiError)}</p>
            }
            <div className={'c-guide-acceptance-documents__submit'}>
              <Button
                label={isFlatXOffer
                  ? I18n.t(T.customerGuidance.acceptance.accept)
                  : I18n.t(T.customerGuidance.acceptance.acceptFlatDirect)
                }
                type={'submit'}
                size={ButtonSize.SECONDARY}
                isLoading={isPending}
              />
            </div>
          </Form>
        </div>
      )}
    />
  );
};
