import { createSelector } from 'reselect';

import {
  getEventsQueryStatus,
  getMeasurementsQueryStatus,
  getStatisticsQueryStatus,
} from '+analysis/store/analysis.selectors';
import { StoreState } from '+app/store/store.interface';
import { getBatteryDataQuery, hasInstalledBattery } from '+battery/store/battery.selectors';
import {
  hasContractDataQueryFailed,
  hasContractStatisticsQueryFailed,
  isDeliveryStartAndUsageAllowancePast,
} from '+dashboard/+contract/store/contract.selectors';
import { isOnline } from '+legacy/core/app/app.selectors';
import { getSiteLiveData } from '../live/live.selectors';
import { QueryStatus } from '../query/query.state';
import { getAllSites, isBatteryDataMissing, isSiteWithContract } from '../site/site.selectors';
import { isLoading } from '../sync/sync.selectors';

export const getNotificationState = (store: StoreState) => store.notifications;

export const getNotifications = createSelector(
  getNotificationState,
  state => state.notifications,
);

export const isBatteryDataMissingNotification = createSelector(
  [getAllSites, isLoading, isBatteryDataMissing, hasInstalledBattery, isSiteWithContract],
  (sites, isLoading, isBatteryDataMissing, hasBattery, isSiteWithContract) =>
    !isLoading
    && !!sites.length
    && isBatteryDataMissing
    && !hasBattery
    && !isSiteWithContract,
  );

export const isBatteryApiErrorNotification = createSelector(
  [isOnline, getBatteryDataQuery],
  (isOnline, batteryQuery) => isOnline && batteryQuery.status === QueryStatus.FAILURE,
);

export const isContractDataApiErrorNotification = createSelector(
  [isOnline, hasContractDataQueryFailed],
  (isOnline, hasContractDataQueryFailed) => isOnline && hasContractDataQueryFailed,
);

export const isContractStatisticsApiErrorNotification = createSelector(
  [isOnline, hasContractStatisticsQueryFailed, hasInstalledBattery, isDeliveryStartAndUsageAllowancePast],
  (isOnline, hasContractStatisticsQueryFailed, hasBattery, isDeliveryStartAndUsageAllowancePast) =>
    isOnline
    && hasContractStatisticsQueryFailed
    && hasBattery
    && isDeliveryStartAndUsageAllowancePast,
);

export const isAnalysisMeasurementsApiErrorNotification = createSelector(
  [isOnline, getMeasurementsQueryStatus, getEventsQueryStatus],
  (isOnline, measurementsQueryStatus, eventsQueryStatus) => isOnline
    && !measurementsQueryStatus.pending
    && (measurementsQueryStatus.error || eventsQueryStatus.error),
);

export const isAnalysisStatisticsApiErrorNotification = createSelector(
  [isOnline, getStatisticsQueryStatus],
  (isOnline, statisticsQueryStatus) => isOnline && statisticsQueryStatus.error,
);

export const isBatteryOfflineNotification = createSelector(
  [isOnline, getSiteLiveData, hasInstalledBattery],
  (isOnline, liveState, hasBattery) => isOnline && hasBattery && liveState && !liveState.online,
);

export const getSystemComponentNotifications = createSelector(
  getNotificationState,
  state => state.systemNotifications.componentNotifications,
);

export const getSystemCaseNotifications = createSelector(
  getNotificationState,
  state => state.systemNotifications.caseNotifications,
);
