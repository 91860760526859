import { Data, MergedData } from '@coolio/json-api';

export const GET_SYSTEM_NOTIFICATIONS_QUERY = 'getSystemNotificationsQuery';
export const UPDATE_SYSTEM_NOTIFICATION_QUERY = 'updateSystemNotificationQuery';

export enum SystemNotificationType {
  CASE = 'case',
  COMPONENT = 'component',
}

export interface SystemNotificationsState {
  componentNotifications: SystemNotifications | [];
  caseNotifications: SystemNotifications | [];
}

export enum SystemNotificationStatus {
  IN_PROGRESS = 'inProgress',
  METER_PLACES_PREPARED = 'meterPlacesPrepared',
  PARTIAL_SUCCESS = 'partialSuccess',
  FAILURE = 'failure',
  SUCCESS = 'success',
}

export type SystemNotificationAttributes = Partial<{
  id: string;
  type: SystemNotificationType,
  self: string;
  notifiable: string;
  read: boolean;
  previousStatus: SystemNotificationStatus | null;
  cretedAt: string;
  updatedAt: string;
}>;

export type SystemNotificationData = Data<SystemNotificationAttributes>;

export type SystemNotificationsData = Data<SystemNotificationAttributes>;

export type SystemNotification = MergedData<SystemNotificationData>;

export type SystemNotifications = MergedData<SystemNotificationsData[]>;
