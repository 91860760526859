import { T } from '@sonnen/shared-i18n/customer';
import { 
  AnalysisDatePicker as DatePicker,
  AnalysisDatePickerProps as DatePickerProps,
} from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { PlatformSelector } from '+components';

export const AnalysisDatePicker: React.FC<DatePickerProps> = (props) => (
  <PlatformSelector
    web={<DatePicker {...props} />}
    mobile={<DatePicker
      {...props}
      isThemeOverlay={true}
      themeOverlayDatePickerHeader={I18n.t(T.mobileApp.analysis.dateLabels.placeholder)}
    />}
  />
);
