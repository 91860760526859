import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { useInfographic } from '+dashboard/+infographic';
import { InfographicCardLayout } from '../InfographicCardLayout/InfographicCardLayout.component';
import { InfographicChargerCardStatus } from './InfographicChargerCardStatus.component';
import { InfographicChargerCardValue } from './InfographicChargerValue.component';
interface Props {
  isLoading: boolean;
}

export const InfographicChargerCard: React.FC<Props> = ({ isLoading = false }) => {
  const { data: { chargerData, query } } = useInfographic();

  return (
    <InfographicCardLayout
      headerDataTestId={'dashboard-infographic-card-charger-header'}
      title={I18n.t(T.dashboard.infographic.charger.title)}
      isLoading={isLoading}
      notifications={chargerData && chargerData.hasCharger
        ? <InfographicChargerCardStatus
            chargerData={chargerData}
            query={query.chargerLiveQuery}
          />
        : null
      }
    >
      <InfographicChargerCardValue chargerData={chargerData} />
    </InfographicCardLayout>
  );
};
