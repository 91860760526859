import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import {
  GuideGridStretched,
  GuideInfoCard,
} from '+guide/components';

export const GuideSetupStepBNetzARegistrationDone: React.FC = () => (
  <GuideGridStretched>
    <GuideInfoCard
      header={I18n.t(T.customerGuidance.setup.step.BNetzARegistration.done.information.headline)}
      headerIcon={<Icon.Info />}
    >
      {I18n.t(T.customerGuidance.setup.step.BNetzARegistration.done.information.description)}
    </GuideInfoCard>
  </GuideGridStretched>
);
