import * as React from 'react';

import './GuidePurchaseProduct.component.scss';

interface Props {
  title?: React.ReactNode;
  icon?: React.ReactNode;
  subtitle?: React.ReactNode;
}

export const GuidePurchaseProduct: React.FC<Props> = ({
  title,
  icon,
  subtitle,
}) => (
  <div className={'c-guide-purchase-product'}>
    {icon && (
      <div className={'c-guide-purchase-product__icon'}>{icon}</div>
    )}
    {title && (
      <div className={'c-guide-purchase-product__title'}>{title}</div>
    )}
    <div className={'c-guide-purchase-product__subtitle'}>{subtitle}</div>
  </div>
);
