import { Bubble, BubbleSideProps, LinkButton } from '@sonnen/shared-web';
import { IconComponent } from '@sonnen/shared-web/src/components/Icon/models';
import * as classNames from 'classnames';
import * as React from 'react';

import './DemoBubble.component.scss';

// @TODO: Refactor this component so that it's easier to control visibility from the outside.

interface Props {
  icon?: IconComponent;
  headline?: string;
  side?: BubbleSideProps;
  buttonLabel?: string;
  dataTestId?: string;
}

export const DemoBubble: React.FC<Props> = ({
  icon,
  headline,
  buttonLabel,
  side,
  children,
  dataTestId,
}) => {
  const [isOpen, setBubbleState] = React.useState(true);
  const toggleBubble = () => setBubbleState(!isOpen);
  const Icon = icon;

  return (
    <div 
      data-test-id={dataTestId} 
      className={classNames('c-demo-bubble', {
      'is-open': isOpen,
      })}
    >
      <Bubble
        side={side}
        isThemeBordered={true}
      >
        {(Icon || headline) && (
          <p className={'c-demo-bubble__headline'}>
            {Icon && (
              <span className={'c-demo-bubble__icon'}>
                {<Icon />}
              </span>
            )}
            {headline}
          </p>
        )}
        <div className={'c-demo-bubble__content'}>
          {children}
        </div>
        {buttonLabel && (
          <div className={'c-demo-bubble__button'}>
            <LinkButton
              dataTestId={dataTestId + '-close-button'}
              type={'button'}
              onClick={toggleBubble}
            >
              {buttonLabel}
            </LinkButton>
          </div>
        )}
      </Bubble>
    </div>
  );
};
