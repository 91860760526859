import { breakpointUp, useWindowSize } from '@sonnen/shared-web';
import * as React from 'react';

import { useInfographic } from '+dashboard/+infographic';
import { InfographicCardElement } from '+dashboard/+infographic';
import { InfographicFirstPlan } from '../InfographicFirstPlan';
import { InfographicIconBackupBox } from '../InfographicIconBackupBox';
import { InfographicIconBattery } from '../InfographicIconBattery';
import { InfographicIconCharger } from '../InfographicIconCharger';
import { InfographicIconCommunity } from '../InfographicIconCommunity';
import { InfographicIconConsumption } from '../InfographicIconConsumption';
import { InfographicIconContract } from '../InfographicIconContract';
import { InfographicIconHeater } from '../InfographicIconHeater';
import { InfographicIconHouse } from '../InfographicIconHouse';
import { InfographicIconPV } from '../InfographicIconPV';
import { InfographicSecondPlan } from '../InfographicSecondPlan';
import { INFOGRAPHIC_CONSTANTS } from './InfographicPainting.constants';

import './InfographicPainting.component.scss';

export const InfographicPainting: React.FC = () => {
  const { setActiveCard, isActive, isAvailable } = useInfographic();
  const windowSize = useWindowSize();
  const constants = windowSize.innerWidth >= breakpointUp('SM')
    ? INFOGRAPHIC_CONSTANTS.desktop
    : INFOGRAPHIC_CONSTANTS.mobile;

  return (
    <div className={'c-infographic-painting'}>
      <svg
        className={'c-infographic-painting__svg'}
        viewBox={constants.container.viewbox}
        width={constants.container.width}
        height={constants.container.height}
      >
        <g className={'c-infographic-painting__plan c-infographic-painting__plan--second'}>
          <InfographicSecondPlan
            width={constants.background.width}
            height={constants.background.height}
            viewbox={constants.background.viewbox}
          >
            <InfographicIconCommunity
              x={constants.community.x}
              y={constants.community.y}
              onClick={() => setActiveCard(InfographicCardElement.COMMUNITY)}
              isActive={isActive(InfographicCardElement.COMMUNITY)}
            />
          </InfographicSecondPlan>
        </g>
        <g className={'c-infographic-painting__plan c-infographic-painting__plan--first'}>
          <InfographicFirstPlan
            width={constants.background.width}
            height={constants.background.height}
            viewbox={constants.background.viewbox}
          >
            <InfographicIconHouse
              x={constants.house.x}
              y={constants.house.y}
            />
            <InfographicIconPV
              x={constants.pv.x}
              y={constants.pv.y}
              onClick={() => setActiveCard(InfographicCardElement.PV)}
              isActive={isActive(InfographicCardElement.PV)}
            />
            <InfographicIconConsumption
              x={constants.consumption.x}
              y={constants.consumption.y}
              onClick={() => setActiveCard(InfographicCardElement.CONSUMPTION)}
              isActive={isActive(InfographicCardElement.CONSUMPTION)}
            />
            {isAvailable(InfographicCardElement.CHARGER) && (
              <InfographicIconCharger
                cable={{
                  x: constants.chargerCable.x,
                  y: constants.chargerCable.y,
                }}
                station={{
                  x: constants.charger.x,
                  y: constants.charger.y,
                }}
                car={{
                  x: constants.car.x,
                  y: constants.car.y,
                }}
                onClick={() => setActiveCard(InfographicCardElement.CHARGER)}
                isActive={isActive(InfographicCardElement.CHARGER)}
              />
            )}
            {isAvailable(InfographicCardElement.CONTRACT) && (
              <InfographicIconContract
                x={constants.contract.xWhenHeaterIsConnected}
                y={constants.contract.yWhenHeaterIsConnected}
                onClick={() => setActiveCard(InfographicCardElement.CONTRACT)}
                isActive={isActive(InfographicCardElement.CONTRACT)}
              />
            )}
            {isAvailable(InfographicCardElement.BATTERY) && (
              <InfographicIconBattery
                x={constants.battery.x}
                y={constants.battery.y}
                onClick={() => setActiveCard(InfographicCardElement.BATTERY)}
                isActive={isActive(InfographicCardElement.BATTERY)}
              />
            )}
            {isAvailable(InfographicCardElement.BACKUP) && (
              <InfographicIconBackupBox
                x={constants.backupBox.x}
                y={constants.backupBox.y}
                onClick={() => setActiveCard(InfographicCardElement.BACKUP)}
                isActive={isActive(InfographicCardElement.BACKUP)}
              />
            )}
            {isAvailable(InfographicCardElement.HEATER) && (
              <InfographicIconHeater
                x={constants.heater.x}
                y={constants.heater.y}
                onClick={() => setActiveCard(InfographicCardElement.HEATER)}
                isActive={isActive(InfographicCardElement.HEATER)}
              />
            )}
          </InfographicFirstPlan>
        </g>
      </svg>
    </div>
  );
};
