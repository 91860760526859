import { Query } from '+app/shared/store/query/query.state';

export const SET_SETUP_STEP_QUERY = 'setSetupStepQuery';

export enum GuideSetupModalType {
  CHECKED = 'checked',
  UNCHECKED = 'unchecked',
}

export interface GuideSetupState {
  activeModal: GuideSetupModalType | null;
  isSetupModalOpen: boolean;
  [SET_SETUP_STEP_QUERY]: Query;
}

export const initialState: GuideSetupState = {
  activeModal: null,
  isSetupModalOpen: false,
  [SET_SETUP_STEP_QUERY]: {},
};
