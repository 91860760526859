import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { DemoBubble } from '+app/+demo/components';
import { DemoHelper } from '+app/+demo/helpers/demo.helper';
import { CommunityMapSectionWeb } from '+legacy/pages/Community/containers';

import './DemoCommunityMapSectionWeb.component.scss';

export const DemoCommunityMapSectionWeb: React.FC = () => (
  <div className={'c-demo-community-map-section-web'}>
    <CommunityMapSectionWeb
      userName={DemoHelper.getDemoUserName()}
      userGeolocation={DemoHelper.getDemoUserGeolocation()}
      recentMembersCount={DemoHelper.getRecentMembersCount()}
    />
    <div className={'c-demo-community-map-section-web__bubble'}>
      <DemoBubble
        dataTestId={'demo-sonnen-community-bubble'}
        side={'bottom'}
        icon={Icon.Community}
        headline={I18n.t(T.demo.community.bubble.headline)}
        buttonLabel={I18n.t(T.demo.community.bubble.buttonLabel)}
      >
        {I18n.t(T.demo.community.bubble.text)}
      </DemoBubble>
    </div>
  </div>
);
