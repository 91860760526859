import { MobileAppState } from '+app/+mobile';
import { INTEGRATION_ACTIONS, IntegrationActions } from '+mobile/store/mobile.actions';
import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { LIVE_ACTIONS, LiveActions } from './live.actions';
import { GET_CHARGER_LIVE_DATA_QUERY, GET_SITE_LIVE_DATA_QUERY, initialState, LiveState, LiveStateStatus } from './live.state';

export const reducer = createReducer<LiveState, LiveActions | IntegrationActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case INTEGRATION_ACTIONS.SET_APP_STATE:
        const isAppReopened = action.state === MobileAppState.ACTIVE;
        return {
          ...state,
          liveStateStatus: isAppReopened ? LiveStateStatus.TRANSITIONING : LiveStateStatus.INACTIVE,
        };
      case LIVE_ACTIONS.SET_SITE_LIVE_DATA:
        const isAppTransitioning = state.liveStateStatus === LiveStateStatus.TRANSITIONING;
        return {
          ...state,
          siteLiveData: action.liveData,
          siteLiveDataPollingTickCount: state.siteLiveDataPollingTickCount + 1,
          liveStateStatus: isAppTransitioning ? LiveStateStatus.ACTIVE : state.liveStateStatus,
        };
      case LIVE_ACTIONS.SET_CHARGER_LIVE_DATA:
        return {
          ...state,
          chargerLiveData: action.liveData,
        };
      case LIVE_ACTIONS.START_LIVE_DATA_POLLING:
        return {
          ...state,
          activePollings: !state.activePollings.includes(action.pollingType)
            ? [...state.activePollings, action.pollingType]
            : state.activePollings,
        };
      case LIVE_ACTIONS.STOP_LIVE_DATA_POLLING:
        return {
          ...state,
          activePollings: [],
          siteLiveDataPollingTickCount: initialState.siteLiveDataPollingTickCount,
        };
      case LIVE_ACTIONS.CLEAR_LIVE_DATA:
        return {
          ...initialState,
        };
      default:
        return queryReducer(state, action, [
          GET_SITE_LIVE_DATA_QUERY,
          GET_CHARGER_LIVE_DATA_QUERY,
        ]);
    }
  },
);
