import { T } from '@sonnen/shared-i18n/customer';
import { PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { DemoLiveEnergyFlow } from '../DemoLiveEnergyFlow';

export const DemoLiveState: React.FC = () => (
  <>
    <PageSubheadline dataTestId={'demo-live-state-header'}>
      {I18n.t(T.demo.liveFlow.headline)}
    </PageSubheadline>
    <DemoLiveEnergyFlow />
  </>
);
