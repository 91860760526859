import { PieChartViewStyle } from '@kanva/charts';
import { T } from '@sonnen/shared-i18n/customer';
import { getAggregatedEnergy, mapSeriesForLegend, StatisticsSeriesKey } from '@sonnen/shared-web';
import { I18n } from 'react-redux-i18n';

import { formatNumber } from '+app/utils/number.util';
import { toLinearlyScaleDown } from '+utils/linearFontSize.util';

export const getValueLength = (value: number) => `${value}`.split('.')[0].length;

export const getFormattingPrecision = (
  value: number,
  maxChars: number,
  minPrecision: number,
  maxPrecision: number,
) => getValueLength(value) >= maxChars ? minPrecision : maxPrecision;

export const getPieChartValueProperties = (
  consumption: Record<string, number>,
  production: Record<string, number>,
) => {
  const consumptionValue = getAggregatedEnergy(production);
  const productionValue = getAggregatedEnergy(consumption);
  const biggerValue = getValueLength(consumptionValue) >= getValueLength(productionValue)
    ? consumptionValue
    : productionValue;
  const precision = getFormattingPrecision(biggerValue, 4, 0, 1);
  const fontSize = toLinearlyScaleDown({
    text: formatNumber({ precision })(biggerValue),
    baseFontSize: 23,
    multiplier: 2.5,
    maxBaseSizeTextLength: 4,
  });

  return {
    fontSize,
    consumption: formatNumber({ precision })(consumptionValue),
    production: formatNumber({ precision })(productionValue),
  };
};

const getSeriesKeyTranslations = () => ({
  [StatisticsSeriesKey.CONSUMED_ENERGY]: I18n.t(T.statistics.pieCharts.legendLabels.independence),
  [StatisticsSeriesKey.GRID_PURCHASE_ENERGY]: I18n.t(T.statistics.pieCharts.legendLabels.gridPurchase),
  [StatisticsSeriesKey.PRODUCED_ENERGY]: I18n.t(T.statistics.pieCharts.legendLabels.selfConsumption),
  [StatisticsSeriesKey.GRID_FEEDIN_ENERGY]: I18n.t(T.statistics.pieCharts.legendLabels.gridFeedIn),
});

export const getPieChartLegendItems = (serie: Record<string, number> , style: PieChartViewStyle) => {
  const seriesKeyTranslations = getSeriesKeyTranslations();

  return mapSeriesForLegend(serie, style, seriesKeyTranslations);
};
