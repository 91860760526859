import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { AccountActions } from '+account/store/account.actions';
import {
  getProfilePrivacy,
  isSetProfileError,
  isSetProfilePending,
  isSetProfileSuccess,
  isSetUserDataError,
  isSetUserDataPending,
  isSetUserDataSuccess,
} from '+account/store/account.selectors';
import { UPDATE_ACCOUNT_PROFILE_QUERY } from '+account/store/account.state';
import { PlatformSelector } from '+app/shared/components';
import { withPage } from '+app/shared/containers/Page';
import { UserAttributes } from '+app/shared/store/user/types/user.interface';
import { StoreState } from '+app/store/store.interface';
import { QueryActions } from '+query/query.actions';
import { getCurrentUserId, hasCurrentUserServicePartnerPermission } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';
import { AccountSettingsCard } from '../../components/AccountSettingsCard';
import { AccountSettingsCardTitle } from '../../components/AccountSettingsCardTitle';
import { getToggledPrivacy, hasFullProfilePrivacy } from './AccountSettings.helper';

import './AccountSettings.component.scss';

const mapStateToProps = (store: StoreState) => ({
  userId: getCurrentUserId(store),
  profilePrivacy: getProfilePrivacy(store),
  isSetProfilePending: isSetProfilePending(store),
  isSetProfileFailure: isSetProfileError(store),
  isSetProfileSuccess: isSetProfileSuccess(store),
  hasCurrentUserServicePartnerPermission: hasCurrentUserServicePartnerPermission(store),
  isSetUserDataSuccess: isSetUserDataSuccess(store),
  isSetUserDataPending: isSetUserDataPending(store),
  isSetUserDataFailure: isSetUserDataError(store),
});

const mapDispatchToProps = mapActions({
  updateProfile: AccountActions.updateAccountProfileData,
  updateUser: AccountActions.setUserData,
  resetUpdateProfileQuery: QueryActions.init,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

interface State {
  isPrivacySwitcherOn: boolean;
  isPermissionSwitcherOn: boolean;
}

class AccountSettingsComponent extends React.PureComponent<Props, State> {
  readonly state = {
    isPrivacySwitcherOn: true,
    isPermissionSwitcherOn: true,
  };

  componentDidMount() {
    const { profilePrivacy, hasCurrentUserServicePartnerPermission } = this.props;
    const hasFullPrivacy = hasFullProfilePrivacy(profilePrivacy);

    this.setPrivacySwitcherState(hasFullPrivacy);
    this.setPermissionSwitcherState(hasCurrentUserServicePartnerPermission);
  }

  componentDidUpdate(prevProps: Props) {
    const { profilePrivacy, isSetProfileFailure, isSetProfileSuccess } = this.props;
    const hasFullPrivacy = hasFullProfilePrivacy(profilePrivacy);
    const isProfileUpdated = profilePrivacy !== prevProps.profilePrivacy;
    const isPrivacySwitcherUpToDate = hasFullPrivacy === this.state.isPrivacySwitcherOn;

    if (isProfileUpdated || !isPrivacySwitcherUpToDate || isSetProfileFailure) {
      this.setPrivacySwitcherState(hasFullPrivacy);
    }

    if (isSetProfileSuccess) {
      this.props.actions.resetUpdateProfileQuery(UPDATE_ACCOUNT_PROFILE_QUERY);
    }
  }

  setPrivacySwitcherState = (isPrivacySwitcherOn: boolean) => this.setState({ isPrivacySwitcherOn });

  setPermissionSwitcherState = (isPermissionSwitcherOn: boolean) => this.setState({ isPermissionSwitcherOn });

  handleTooglePrivacy = () => {
    this.setPrivacySwitcherState(!this.state.isPrivacySwitcherOn);
    this.props.actions.updateProfile({
      privacy: getToggledPrivacy(this.props.profilePrivacy),
    });
  };

  handleTooglePermission = () => {
    const { userId } = this.props;
    this.setPermissionSwitcherState(!this.state.isPermissionSwitcherOn);
    const attributes = {
      partnerMonitoringAccepted: !this.state.isPermissionSwitcherOn,
    } as UserAttributes;

    this.props.actions.updateUser(attributes, userId);
  };

  render() {
    const {
      isSetProfilePending,
      isSetProfileFailure,
      isSetUserDataFailure,
      isSetUserDataPending,
    } = this.props;
    return (
      <div className={'c-account-settings'}>
        <div className={'c-account-settings__description'}>
          <DefaultParagraph>
            {I18n.t(T.settings.description)}
          </DefaultParagraph>
        </div>
        <div className={'o-grid'}>
          <div className={'o-grid__column o-grid__column--sm-6 o-grid__column--no-gap'}>
            {/* TODO: UNCOMMENT WHEN COMMUNITY MAP PRIVACY IS NEEDED */}
            {/* <AccountSettingsCard
              name={'privacy'}
              isChecked={this.state.isPrivacySwitcherOn}
              isDisabled={isSetProfilePending}
              isError={isSetProfileFailure}
              onToggle={this.handleTooglePrivacy}
              title={I18n.t(T.settings.community.title)}
              subtitle={I18n.t(T.settings.community.profileVisibilityTitle)}
              description={I18n.t(T.settings.community.profileVisibilityDescription)}
              icon={<Icon.Community className={'c-account-settings-card__icon'} />}
              isThemeReverse={true}
            /> */}
            <AccountSettingsCard
              name={'permission'}
              isChecked={this.state.isPermissionSwitcherOn}
              isDisabled={isSetUserDataPending}
              isError={isSetUserDataFailure}
              onToggle={this.handleTooglePermission}
              title={(
                <AccountSettingsCardTitle
                  title={I18n.t(T.settings.privacyPermissions.title)}
                  icon={'QuestionMark'}
                  tooltipText={I18n.t(T.settings.privacyPermissions.tooltipText)}
                />
              )}
              subtitle={I18n.t(T.settings.privacyPermissions.subtitle)}
              description={I18n.t(T.settings.privacyPermissions.description)}
              icon={<Icon.Guarantee className={'c-account-settings-card__icon'} />}
              isThemeReverse={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

const MappedAccountSettingsComponent = connect(mapStateToProps, mapDispatchToProps)(AccountSettingsComponent);

export const AccountSettings: React.FC = () => (
  <PlatformSelector
    web={withPage(MappedAccountSettingsComponent, { options: { isHeadlineSmallGap: true }})}
    mobile={<MappedAccountSettingsComponent />}
  />
);
