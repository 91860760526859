import { Data, MergedData } from '@coolio/json-api';

import { MimeTypes } from '+shared/types/mimeTypes.interface';

export enum InvoiceSourceType {
  ENERGEST = 'contractinvoice',
  ZUORA = 'zuora_invoices',
  CONTENT_DOCUMENT = 'content_documents',
}

export enum InvoiceType {
  CANCELLATION_OF_MONTHLY_INVOICE= 'cancellation of monthly invoice',
  DAMAGE_PAYMENT = 'Entschadigung',
  CREDIT_ON_DAMAGES = 'Gutschrift wegen Schadensersatz',
  CORRECTIVE_INVOICE = 'Korrekturrechnung',
  MONTHLY_INVOICE = 'Monatsrechnung',
  MONTHLY_INVOICE_AT_THE_END_OF_THE_CONTRACT = 'Monatsrechnung zum Vertragsende',
  FINANCIAL_STATEMENT = 'Schlussrechnung',
  CANCELLATION_INVOICE = 'Stornorechnung',
  CONSUMPTION_INVOICE = 'Verbrauchsrechnung',
  CONSUMPTION_INVOICE_AT_THE_END_OF_THE_CONTRACT = 'Verbrauchsrechnung zum Vertragsende',
}

interface ContentDocumentAttributes {
  id: string;
  type: InvoiceSourceType.CONTENT_DOCUMENT;
  createdDate: string;
  extension: 'pdf';
  lastModifiedDate: string;
  name: string;
}

interface ZuoraInvoiceAttributes {
  contractNumber: string;
  invoiceType: InvoiceType | string;
  invoiceDate: string | null; // example: 2022-01-26
  billingPeriodStart: string | null; // example: 2022-01-26
  billingPeriodEnd: string | null; // example: 2022-01-26
}

enum EnergestInvoiceOperation {
  INVOICE = 'invoice',
  AMENDMENT = 'amendment',
}

interface EnergestInvoiceAttributes {
  operation: EnergestInvoiceOperation;
  number: string;
  prefix: string;
  month: string; // Maximum invoiced month in MMYYYY format
  // tslint:disable-next-line:max-line-length
  url: string; // example: https://functions.enersis.it/api/sonnenV1/149406/contract_files/T108850-CA1a4a888939dcc3ef2eee2e4be514c084/?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NTg2MjM4NjQsImp0aSI6IjAwMDAwMDAwLTAwMDAtMDAwMC0wMDAwLTAwMDAwMDAwMDAwMCIsInJlc2VsbGVyX2lkIjoxNDk0MDYsInBvZF9pZCI6bnVsbCwidXBkYXRlZF9EYXRlIjowLCJjb250cmFjdF9pZCI6MCwiZmlsZV9pZCI6IlQxMDg4NTAtQ0ExYTRhODg4OTM5ZGNjM2VmMmVlZTJlNGJlNTE0YzA4NCJ9.g-IgAts3NgvNZM0TJd420CSIQ7lKdJH-6pz2ZWl5BLs
  // The download URL. Expires after 30 minutes
  mime: MimeTypes.APPLICATION_PDF;
  filesize: number; // Size in bytes
  created: string; // example: 2017-08-08
  updated: string; // example: 2019-05-22
}

export type ZuoraInvoiceData = Data<ZuoraInvoiceAttributes>;
export type ZuoraInvoice = MergedData<ZuoraInvoiceData>;

export type EnergestInvoiceData = Data<EnergestInvoiceAttributes>;
export type EnergestInvoice = MergedData<EnergestInvoiceData>;

export type ContentDocumentData = Data<ContentDocumentAttributes>;
export type ContentDocument = MergedData<ContentDocumentData>;

export type InvoiceData = ZuoraInvoiceData | EnergestInvoiceData | ContentDocumentData;
export type Invoice = ZuoraInvoice | EnergestInvoice | ContentDocument;
