import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

interface Props {
  value?: string;
}

const setTitle = (value?: string) => {
  if (value) {
    document.title = `${value} | ${I18n.t(T.general.pageTitles.default)}`;
    
    return;
  }

  document.title = I18n.t(T.general.pageTitles.default);
};

export const PageMetaTitle = ({ value }: Props) => {
  setTitle(value);

  return null;
};
