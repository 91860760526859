import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';

import { getNotifications } from '+app/+guide/+notifications/store/+notifications.selectors';
import { getGuideLeadPartner, getGuideSyncQueryStatus } from '+app/+guide/store/+lead.selectors';
import { StoreState } from '+app/store/store.interface';
import { NotificationFeed } from '+shared/components';
import { mapActions } from '+utils/redux';
import { factorizeNotifications } from './GuideNotifications.helper';

const mapStateToProps = (state: StoreState) => ({
  partner: getGuideLeadPartner(state),
  syncQueryStatus: getGuideSyncQueryStatus(state),
  notificationList: getNotifications(state),
});

const mapDispatchToProps = mapActions({
  push,
});

export type GuideNotificationsActionProps = ReturnType<typeof mapDispatchToProps>;

type Props =
  & GuideNotificationsActionProps
  & ReturnType<typeof mapStateToProps>
  ;

export const GuideNotificationsComponent: React.FC<Props> = ({
  actions,
  partner,
  notificationList,
  syncQueryStatus,
}) => {
  const notifications = factorizeNotifications(notificationList, actions, partner);

  return (
    <NotificationFeed
      notificationsList={notifications}
      isLoading={syncQueryStatus.pending}
    />
  );
};

export const GuideNotifications = connect(mapStateToProps, mapDispatchToProps)(GuideNotificationsComponent);
