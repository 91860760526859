// TODO: Move rest of utils to sharedweb
import { EnergyUnit } from '@sonnen/shared-web';
import { formatNumber } from './number.util';

export enum PeakPowerUnit {
  KWP = 'kWp',
  WP = 'Wp',
}

export enum CurrencyUnit {
  EUR = 'EUR',
}

export enum OtherUnit {
  PERCENT = 'percent',
  DEGREE = 'degree',
  DATE = 'date',
  GEOMETRY = 'geometry',
}

export enum PrimitiveUnit {
  BOOLEAN = 'boolean',
  STRING = 'string',
}

const formatEnergy = (whValue: number, unit: EnergyUnit = EnergyUnit.WH, precision = 1) =>
  formatNumber({ precision })(
    unit === EnergyUnit.KWH ? whValue / 1000 : whValue,
  )
  + ' '
  + (unit === EnergyUnit.KWH ? 'kWh' : 'Wh');

const formatPower = (powerW: number, unit: EnergyUnit = EnergyUnit.W, precision = 0) =>
  formatNumber({ precision })(
    unit === EnergyUnit.KW ? powerW / 1000 : powerW,
  )
  + ' '
  + (unit === EnergyUnit.KW ? 'kW' : 'W');

const formatPeakPower = (wpValue: number, unit: PeakPowerUnit = PeakPowerUnit.WP, precision = 1) =>
  formatNumber({ precision })(
    unit === PeakPowerUnit.KWP ? wpValue / 1000 : wpValue,
  )
  + ' '
  + (unit === PeakPowerUnit.KWP ? 'kWp' : 'Wp');

const formatCurrency = (
  value: number | string,
  currency: CurrencyUnit,
  { isMinorUnit } = { isMinorUnit: false },
): string => {
  const formattedValue = formatNumber({ precision: 2 })(Number(value));

  switch (currency) {
    case CurrencyUnit.EUR:
      if (isMinorUnit) {
        return `${formattedValue} ct`;
      }
      return `${formattedValue} €`;
    default:
      return String(value);
  }
};

export const UnitUtil = {
  formatEnergy,
  formatPower,
  formatPeakPower,
  formatCurrency,
};
