import { T } from '@sonnen/shared-i18n/customer';
import { Icon, MediaQuery } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { LiveEnergyFlow } from '+app/shared/components';
import { DemoBubble } from '../../../components/DemoBubble';
import { getMockedProps } from '../DemoLiveState/DemoLiveState.mock';

import './DemoLiveEnergyFlow.component.scss';

export const DemoLiveEnergyFlow: React.FC = () => {
  const [props, setProps] = React.useState(getMockedProps());
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setProps(getMockedProps());
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={'c-demo-live-energy-flow'}>
      <LiveEnergyFlow {...props} />
      <Media query={MediaQuery.DOWN_SM}>
        {(isMobile: boolean) => isMobile ? (
          <div className={'c-demo-live-energy-flow__bubble c-demo-live-energy-flow__bubble--mobile'}>
            <DemoBubble
              icon={Icon.LiveState}
              headline={I18n.t(T.demo.liveFlow.bubble.headline)}
              side={'top'}
              buttonLabel={I18n.t(T.demo.liveFlow.bubble.buttonLabel)}
            >
              {I18n.t(T.demo.liveFlow.bubble.text)}
            </DemoBubble>
          </div>
        ) : (
          <div className={'c-demo-live-energy-flow__bubble c-demo-live-energy-flow__bubble--desktop'}>
            <DemoBubble
              dataTestId={'demo-live-state-bubble'}
              icon={Icon.LiveState}
              headline={I18n.t(T.demo.liveFlow.bubble.headline)}
              side={'left-bottom'}
              buttonLabel={I18n.t(T.demo.liveFlow.bubble.buttonLabel)}
            >
              {I18n.t(T.demo.liveFlow.bubble.text)}
            </DemoBubble>
          </div>
        )}
      </Media>
    </div>
  );
};
