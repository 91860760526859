import { T } from '@sonnen/shared-i18n/customer';
import { MediaQuery } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { NavHorizontal } from '+app/shared/components/NavHorizontal';
import { DefaultNav } from '+shared/components';
import { DemoBubble } from '../DemoBubble';
import { DemoNavProps } from './DemoNav.component';

import './DemoNav.web.component.scss';

export const DemoNavWeb: React.FC<DemoNavProps> = ({ routes }) => {
  const [isBubbleOpen, setBubbleState] = React.useState(true);
  const removeBubble = () => setBubbleState(false);
  
  return (
    <div className={'c-demo-nav'}>
      <Media query={MediaQuery.UP_NAV}>
        {(isDesktop: boolean) => isDesktop ? (
          // @TODO: Change name to NavVertical
          <>
            <div onClick={removeBubble}>
              <DefaultNav routes={routes} />
            </div>
            {isBubbleOpen && (
              <div className={'c-demo-nav__bubble'}>
                <DemoBubble
                  dataTestId={'demo-nav-bubble'}
                  side={'left-top'}
                  buttonLabel={I18n.t(T.demo.welcome.bubble.buttonLabel)}
                >
                  {I18n.t(T.demo.welcome.bubble.text)}
                </DemoBubble>
              </div>
            )}
          </>
        ) : (
          <>
            <div onClick={removeBubble}>
              <NavHorizontal routes={routes} />
            </div>
            {isBubbleOpen && (
              <div className={'c-demo-nav__bubble'}>
                <DemoBubble
                  side={'top'}
                  buttonLabel={I18n.t(T.demo.welcome.bubble.buttonLabel)}
                >
                  {I18n.t(T.demo.welcome.bubble.text)}
                </DemoBubble>
              </div>
            )}
          </>
        )}
      </Media>
    </div>
  );
};
