import { T } from '@sonnen/shared-i18n/customer';
import { Icon, InfoBanner } from '@sonnen/shared-web';
import { useFeature } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { isOnRoute } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { getRandomHint } from '+app/shared/store/sync/sync.helpers';
import { Hint } from '+app/shared/store/sync/types/hint.interface';
import { FeatureName } from '+config/featureFlags';
import { DashboardHeader } from '+dashboard/containers';
import { GuideActivationBanner } from '+guide/components/GuideActivationBanner';
import {
  Layout,
  Notifications,
} from '+shared/components';
import {
  Footer,
  MainNav,
} from '+shared/containers';
import { DashboardLayoutProps } from './';

export const DashboardLayoutWeb: React.FC<DashboardLayoutProps> = ({
  isLoading,
  isLoggedIn,
  guideSyncQuery,
  guideSyncQueryStatus,
  isVerifyingIdentityInGuide,
  path,
  children,
}) => {
  const isUserOnGuideRoute: boolean = isOnRoute(RouteName.GUIDE, path);
  const isUserInGuide: boolean = !isLoggedIn && isUserOnGuideRoute;
  const isReadyToRender = !(isUserInGuide && isVerifyingIdentityInGuide);
  const isOnOfferAcceptanceRoute: boolean = isOnRoute(RouteName.GUIDE_ACCEPTANCE, path);
  const shouldRenderSideElements: boolean = isReadyToRender && !isOnOfferAcceptanceRoute;
  const shouldRenderActivationBanner: boolean =
    isUserInGuide
    && !isOnOfferAcceptanceRoute
    && useFeature(FeatureName.GUIDE_PAGES_IN_CP).isEnabled;

  const guideLoaderHint: Hint = {
    icon: 'icofont-contract',
    headline: I18n.t(T.customerGuidance.loader.headline),
    description: I18n.t(T.customerGuidance.loader.description),
  };
  const loaderHint: Hint = getRandomHint();

  return (
    <>
      <Layout
        header={<DashboardHeader />}
        // TODO remove custom banner after Notifications get final banner UI.
        // Use `createGuideGoToActivationBanner` from notification.factory
        contentTop={isReadyToRender && (
          <>
            {shouldRenderActivationBanner && <GuideActivationBanner />}
            <Notifications />
          </>
        )}
        side={shouldRenderSideElements && <MainNav />}
        footer={<Footer />}
        loader={{
          isLoading: !isUserInGuide ? isLoading : guideSyncQueryStatus.pending,
          loaderHint: !isUserOnGuideRoute ? loaderHint : guideLoaderHint,
        }}
      >
        {/* @TODO: This could be simplified once guide pages load asynchronously in CP */}
        {!isUserInGuide ? (
          <>
            {children}
          </>
        ) : (
          <>
            {guideSyncQueryStatus.success ? (
              <>
                {children}
              </>
            ) : (
              <InfoBanner
                icon={<Icon.BrokenBattery />}
                title={guideSyncQuery.error && guideSyncQuery.error.name}
                subtitle={guideSyncQuery.error && guideSyncQuery.error.message}
              />
            )}
          </>
        )}
      </Layout>
    </>
  );
};
