import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import MapPopover from './MapPopover';
import LocateUserControl from './mapContent';

import './CommunityMap.scss';

class CommunityMap extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      popupVisible: true,
    };
  }

  componentDidMount() {
    this.props.onMarkerClick(() => this.setState(state => ({
      ...state,
      popupVisible: !state.popupVisible,
    })));
    this.props.recenterMapAtUser();
  }

  render() {
    const { popupVisible } = this.state;
    const {
      popupHeadline,
      popupContent,
      markerPosition,
      recenterMapAtUser,
      mapSize,
    } = this.props;

    return (
      <Fragment>
        <LocateUserControl
          onClick={recenterMapAtUser}
        />
        <MapPopover
          position={markerPosition}
          headline={popupHeadline}
          visible={popupVisible}
          mapSize={mapSize}
        >
          {popupContent}
        </MapPopover>
      </Fragment>
    );
  }
}

CommunityMap.propTypes = {
  popupHeadline: PropTypes.string,
  popupContent: PropTypes.element,
  recenterMapAtUser: PropTypes.func.isRequired,
  onMarkerClick: PropTypes.func.isRequired,
  markerPosition: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  mapSize: PropTypes.shape({
    mapWidth: PropTypes.number,
    mapHeight: PropTypes.number,
  }),
};

CommunityMap.defaultProps = {
  markerPosition: { x: 0, y: 0 },
  mapSize: { mapWidth: null, mapHeight: null },
  popupHeadline: '',
  popupContent: null,
};

export default CommunityMap;
