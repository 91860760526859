import { DataContainer, DataContainerTooltipExtension, DataContainerTransformExtension, XYPoint } from '@kanva/charts';

export enum KanvaExtensionType {
  TOOLTIP = 'tooltip',
  TRANSFORM = 'transform',
}

export enum KanvaContainerType {
  DEFAULT = 'default',
  PERCENTAGE = 'percentage',
}

export interface KanvaContainers {
  [KanvaContainerType.DEFAULT]: DataContainer<XYPoint<any>>;
  [KanvaContainerType.PERCENTAGE]?: DataContainer<XYPoint<any>>;
}

export interface KanvaExtensions {
  [KanvaExtensionType.TOOLTIP]?: DataContainerTooltipExtension;
  [KanvaExtensionType.TRANSFORM]?: DataContainerTransformExtension;
}

export type KanvaSetParametersFn = (dataContainer: DataContainer<XYPoint<any>>) => void;

export type KanvaTooltipValues = Record<string, XYPoint<number>>;
