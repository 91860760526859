import { CONFIG } from '+config';
import { mobileStorage } from '+mobile/mobile.integration';
import { WebStorage } from 'redux-persist';
import * as uuid from 'uuid';

export class TemporaryStorage implements WebStorage {
  private data: any = {};

  async setItem(id: string, val: string) {
    this.data[id] = String(val);
    return this.data[id];
  }

  async getItem(id: string) {
    return this.data[id];
  }

  async removeItem(id: string) {
    delete this.data[id];
  }

  async clear() {
    this.data = {};
  }
}

class AsyncStorage implements WebStorage {
  constructor(private storage: Storage) {
  }

  async getItem(key: string): Promise<string> {
    return this.storage.getItem(key)!;
  }

  async removeItem(key: string): Promise<void> {
    return this.storage.removeItem(key);
  }

  async setItem(key: string, item: string): Promise<string> {
    this.storage.setItem(key, item);
    return item;
  }
}

const checkIfStorageIsAvailable = (storageType: string): WebStorage => {
  if (CONFIG.IS_MOBILE) {
    return mobileStorage;
  }

  try {
    const storage: Storage = window[storageType];
    const key = `__check__${uuid.v4()}__`;
    storage.setItem(key, key);
    storage.removeItem(key);
    return new AsyncStorage(storage);
  } catch {
    return new TemporaryStorage();
  }
};

export const localStorage = checkIfStorageIsAvailable('localStorage');
export const sessionStorage = checkIfStorageIsAvailable('sessionStorage');
