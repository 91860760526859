export enum EventCategories {
  BANNER = 'Banner',
  MODAL = 'Modal',
}

export enum EventActions {
  BANNER_DISPLAYED = 'Banner Displayed',
  BANNER_EXPANDED = 'Banner Expanded',
  MODAL_ACCEPT = 'Modal Accepted',
  MODAL_CLOSE = 'Modal Closed',
}

export enum EventLabels {
  LIVE_DELAY = 'LiveState-Delay',
  DATA_QUALITY_MODAL = 'DataQualityModal',
}
