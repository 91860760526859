import * as React from 'react';
import { connect } from 'react-redux';

import { isAuthenticated } from '+app/+auth/store/auth.selectors';
import { getElectricUnits } from '+app/+dashboard/+battery/store/battery.selectors';
import { isVerifyingIdentity } from '+app/+guide/store/+guide.selectors';
import { getGuideSyncQuery, getGuideSyncQueryStatus } from '+app/+guide/store/+lead.selectors';
import { getRouterLocationPath } from '+app/router/store/router.selectors';
import { PlatformSelector } from '+app/shared/components';
import { LayoutActions } from '+app/shared/store/layout/layout.actions';
import { isMobileNavOpen } from '+app/shared/store/layout/layout.selectors';
import { isLoading } from '+app/shared/store/sync/sync.selectors';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { isOnRoute } from '+router/router.helper';
import { RouteName } from '+router/router.types';
import { LegalActions } from '+shared/store/legal/legal.actions';
import { DashboardModalBackupBuffer } from '../DashboardModalBackupBuffer';
import { DashboardModalUnitExchange } from '../DashboardModalUnitExchange';
import { DashboardLayoutMobile } from './DashboardLayout.mobile.component';
import { DashboardLayoutWeb } from './DashboardLayout.web.component';

const mapStateToProps = (state: StoreState) => ({
  batteryElectricUnits: getElectricUnits(state),
  isLoading: isLoading(state),
  isMobileNavOpen: isMobileNavOpen(state),
  isLoggedIn: isAuthenticated(state),
  guideSyncQuery: getGuideSyncQuery(state),
  guideSyncQueryStatus: getGuideSyncQueryStatus(state),
  isVerifyingIdentityInGuide: isVerifyingIdentity(state),
  path: getRouterLocationPath(state),
});

const mapDispatchToProps = mapActions({
  toggleMobileNav: LayoutActions.toggleMobileNav,
  getLegalDocuments: LegalActions.getLegalDocuments,
});

export type DashboardLayoutProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const DashboardLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)((props: DashboardLayoutProps) => {
  const { actions, isLoggedIn, path } = props;

  React.useEffect(() => {
    if (isLoggedIn && !isOnRoute(RouteName.GUIDE, path)) {
      actions.getLegalDocuments();
    }
  }, [isLoggedIn]);

  return (
    <>
      <DashboardModalUnitExchange />
      <DashboardModalBackupBuffer />
      <PlatformSelector
        web={<DashboardLayoutWeb {...props} />}
        mobile={<DashboardLayoutMobile {...props} />}
      />
    </>
  );
});
