import { AccessoriesSeriesKey, EnergyFlowSeriesKey, TimeUnit } from '@sonnen/shared-web';
import * as moment from 'moment';

import { QueryState } from '+shared/store/query/query.state';
import { MockHelper } from './helpers/mock.helper';

import { AnalysisProviderActions, AnalysisProviderProps } from '+analysis/containers/AnalysisProvider/AnalysisProvider.types';
import { getResolutionForPeriod } from '+analysis/store/analysis.helpers';
import { MeasurementMethod } from '+app/shared/store/live/types/siteLiveData.interface';
import { BATTERY_DATA_MOCK, LIVE_DATA_MOCK } from '.';
import * as accessoriesChartBackupBoxSerie from './json/analysisBackupBoxSerie.mock.json';
import * as areaChartBatteryUsocSerie from './json/analysisBatteryUsocSerie.mock.json';
import * as areaChartConsumptionSerie from './json/analysisConsumptionSerie.mock.json';
import * as areaChartDirectUsageSerie from './json/analysisDirectUsageSerie.mock.json';
import * as areaChartForecastConsumptionSerie from './json/analysisForecastConsumptionSerie.mock.json';
import * as areaChartForecastProductionSerie from './json/analysisForecastProductionSerie.mock.json';
import * as areaChartGridFeedInSerie from './json/analysisGridFeedInSerie.mock.json';
import * as areaChartGridPurchaseSerie from './json/analysisGridPurchaseSerie.mock.json';
import * as accessoriesChartHeaterPowerSerie from './json/analysisHeaterPowerSerie.mock.json';
import * as accessoriesChartHeaterWaterTempSerie from './json/analysisHeaterWaterTempSerie.mock.json';
import * as areaChartProductionSerie from './json/analysisProductionSerie.mock.json';
import * as barChartSeries from './json/barChartSeries.mock.json';

const selectedDataSeriesKeys = [
  EnergyFlowSeriesKey.CONSUMPTION_POWER,
  EnergyFlowSeriesKey.PRODUCTION_POWER,
  EnergyFlowSeriesKey.BATTERY_USOC,
  EnergyFlowSeriesKey.DIRECT_USAGE_POWER,
];

const mockedQueryStatus: QueryState = {
  success: true,
  error: false,
  notReady: false,
  pending: false,
  interrupted: false,
};

const millisecondsInADay = 24 * 60 * 60 * 1000;

export const ANALYSIS_PROVIDER: AnalysisProviderProps = {
  commonData: {
    accessoriesChartSeries: {
      [AccessoriesSeriesKey.BACKUP_BOX]: MockHelper.getSerie(
        accessoriesChartBackupBoxSerie, AccessoriesSeriesKey.BACKUP_BOX),
      [AccessoriesSeriesKey.HEATER_POWER]: MockHelper.getSerie(
        accessoriesChartHeaterPowerSerie, AccessoriesSeriesKey.HEATER_POWER),
      [AccessoriesSeriesKey.HEATER_TEMPERATURE]: MockHelper.getSerie(
        accessoriesChartHeaterWaterTempSerie, AccessoriesSeriesKey.HEATER_TEMPERATURE),
    },
    areaChartSeries: {
      [EnergyFlowSeriesKey.PRODUCTION_POWER]: MockHelper.getSerie(
        areaChartProductionSerie, EnergyFlowSeriesKey.PRODUCTION_POWER),
      [EnergyFlowSeriesKey.CONSUMPTION_POWER]: MockHelper.getSerie(
        areaChartConsumptionSerie, EnergyFlowSeriesKey.CONSUMPTION_POWER),
      [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: MockHelper.getSerie(
        areaChartForecastProductionSerie, EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER),
      [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: MockHelper.getSerie(
        areaChartForecastConsumptionSerie, EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER),
      [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: MockHelper.getSerie(
        areaChartDirectUsageSerie, EnergyFlowSeriesKey.DIRECT_USAGE_POWER),
      [EnergyFlowSeriesKey.BATTERY_USOC]: MockHelper.getSerie(
        areaChartBatteryUsocSerie, EnergyFlowSeriesKey.BATTERY_USOC),
      [EnergyFlowSeriesKey.BATTERY_CHARGING]: MockHelper.getSerie(
        areaChartGridPurchaseSerie, EnergyFlowSeriesKey.BATTERY_CHARGING),
      [EnergyFlowSeriesKey.BATTERY_DISCHARGING]: MockHelper.getSerie(
        areaChartGridPurchaseSerie, EnergyFlowSeriesKey.BATTERY_DISCHARGING),
      [EnergyFlowSeriesKey.GRID_FEEDIN]: MockHelper.getSerie(
        areaChartGridFeedInSerie, EnergyFlowSeriesKey.GRID_FEEDIN),
      [EnergyFlowSeriesKey.GRID_PURCHASE]: MockHelper.getSerie(
        areaChartGridPurchaseSerie, EnergyFlowSeriesKey.GRID_PURCHASE),
      batteryCare: MockHelper.getSerie(
        areaChartProductionSerie, EnergyFlowSeriesKey.PRODUCTION_POWER)
        .map(point => ({ ...point, y: Math.round(Math.random()) })),
    },
    barChartSeries: barChartSeries.default,
    batteryData: BATTERY_DATA_MOCK,
    batteryCareHistory: [],
    hasBackupBox: true,
    hasBattery: true,
    hasHeater: true,
    hasMeasurements: true,
    hasStatistics: true,
    hasSites: true,
    latestMeasurementsFetchDate: undefined,
    maxDate: new Date(),
    measurementsMethod: MeasurementMethod.BATTERY,
    pieChartSeries: {
      autonomy: {
        consumedEnergy: 2608.223,
        gridPurchaseEnergy: 460.275,
      },
      selfConsumption: {
        producedEnergy: 2371.116,
        gridFeedinEnergy: 2777.789,
      },
    },
    selectedDate: moment().subtract(1, 'day'),
    selectedDataSeriesKeys,
    shouldShowForecast: false,
    shouldStatisticsAppear: true,
    siteId: '123',
    siteLiveData: LIVE_DATA_MOCK,
    statisticsSelectedDate: {
      period: TimeUnit.DAY,
      date: new Date(Date.now() - millisecondsInADay),
      resolution: getResolutionForPeriod(TimeUnit.DAY),
    },
    summerTimeChange: {
      isSummerTimeStartDate: false,
      isSummerTimeEndDate: false,
    },
    timestamp: `${new Date().getTime()}`,
  },
  query: {
    eventsQueryStatus: mockedQueryStatus,
    measurementsQueryStatus: mockedQueryStatus,
    getSiteLiveDataQueryStatus: mockedQueryStatus,
    statisticsQueryStatus: mockedQueryStatus,
    getBatteryCellCareHistoryQueryStatus: mockedQueryStatus,
  },
  actions: {} as AnalysisProviderActions,
};
