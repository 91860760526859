export enum ContractFlatXStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  CANCELED = 'canceled',
  DONE = 'done',
  OPEN = 'open',
  PENDING = 'pending',
  TERMINATION_PENDING = 'terminationPending',
}

export type ContractFlatXData = {
  attribute: string,
  value: string | null,
};
