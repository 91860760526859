import { Card } from '@sonnen/shared-web';
import * as React from 'react';

import { AnalysisHistory } from '+app/+dashboard/+analysis/components';
import { DemoAnalysisHistoryHeader } from '../DemoAnalysisHistoryHeader';

import './DemoAnalysisHistoryCard.component.scss';

interface Props {
  isHeaderVisible?: boolean;
}

export const DemoAnalysisHistoryCard: React.FC<Props> = ({
  isHeaderVisible,
}) => (
  <Card header={isHeaderVisible ? <DemoAnalysisHistoryHeader/> : null}>
    <div className={'c-demo-analysis-history-card__content'}>
      <AnalysisHistory /> 
    </div>
  </Card>
);
