import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

const seed = Math.random();

export const getUserGreeting = (name?: string) => {
  const greetings = Object.values(I18n.t(T.dashboard.greetings, { name }));
  const greetingsNumber = Math.floor(seed * greetings.length);

  return name
    ? greetings[greetingsNumber]
    : I18n.t(T.dashboard.defaultGreeting);
};
