import { scaleLinear } from 'd3-scale';
import { X_AXIS_MARGIN, Y_AXIS_MARGIN, Y_CANVAS_MARGIN, Y_DOMAIN } from './frequency.constants';

export const getScales = (dimensions: number[], data: number[]) => {
  const [width, height] = dimensions;

  const xScale = scaleLinear()
    .domain([0, data.length])
    .range([0, width - X_AXIS_MARGIN]);

  const yScale = scaleLinear()
    .domain(Y_DOMAIN) // Static domain defined in constants
    .range([height - Y_CANVAS_MARGIN - Y_AXIS_MARGIN, Y_AXIS_MARGIN * 1.2]);

  return { xScale, yScale };
};
