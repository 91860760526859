import { CONFIG } from '+config';
import { useJsonApi } from '+shared/network';
import { ContractData, ContractDocumentsData, ContractDocumentUrlData, ContractRelationships } from './types/contract.interface';
import { ContractFileRequestData } from './types/contractFilesRequestData.interface';
import { ContractInvoiceRequestData } from './types/contractInvoicesRequestData.interface';

export const getContracts = ({ siteId }: { siteId: string }) => useJsonApi()
  .getList<ContractData>(`${CONFIG.API.USER_DATA}/sites/${siteId}/contracts`)
  .resolveIncluded(true)
  .send()
  .then(res => res.elements.map((element, index) => {
    const currentRelationships = res.raw.data[index].relationships as ContractRelationships;
    const meterLink = currentRelationships.meterInstallation.links?.related;
    const meterInstallationId = meterLink?.split('/').pop() || null;

    return {
      ...element,
      meterInstallationId,
    };
  }));

export const getContractInvoices = ({ id }: { id: string }) => useJsonApi()
  .getList<ContractInvoiceRequestData>(`${CONFIG.API.USER_DATA}/contracts/${id}/invoices`)
  .send()
  .then(response => response.elements);

export const getContractFiles = ({ id }: { id: string }) => useJsonApi()
  .getList<ContractFileRequestData>(`${CONFIG.API.USER_DATA}/contracts/${id}/files`)
  .send()
  .then(response => response.elements);

export const getContractDocuments = (id: string) => useJsonApi()
  .getList<ContractDocumentsData>(`${CONFIG.API.USER_DATA}/contracts/${id}/documents`)
  .send()
  .then(response => response.elements);

export const getContractDocument = ({documentId, contractId}: {documentId: string, contractId: string}) => useJsonApi()
  .get<ContractDocumentUrlData>(`${CONFIG.API.USER_DATA}/contracts/${contractId}/documents/${documentId}`)
  .send()
  .then(response => response.element);

export const getEnergyContractDocuments = (id: string) => useJsonApi()
  .getList<ContractDocumentsData>(`${CONFIG.API.USER_DATA}/energy_contracts/${id}/documents`)
  .send()
  .then(response => response.elements);

export const getEnergyContractDocument = (
  { documentId, contractId }: { documentId: string, contractId: string },
) => useJsonApi()
  .get<ContractDocumentUrlData>(`${CONFIG.API.USER_DATA}/energy_contracts/${contractId}/documents/${documentId}`)
  .send()
  .then(response => response.element);

export const ContractRepository = {
  getContracts,
  getContractInvoices,
  getContractFiles,
  getContractDocuments,
  getContractDocument,
  getEnergyContractDocuments,
  getEnergyContractDocument,
};
