import { GuideActions } from '+app/+guide/store/+guide.actions';
import { getPhaseActiveTab } from '+app/+guide/store/+guide.selectors';
import { getPaths } from '+app/router/router.helper';
import { StoreState } from '+app/store/store.interface';
import { PhaseContext } from '+guide/components';
import { getStepsFromPhase, getTabFromStep, PHASES, TABS } from '+guide/helpers/steps.config';
import { mapActions } from '+utils/redux';
import { getActiveDiscoveryModal } from '../../store/+discovery.selectors';

import { T } from '@sonnen/shared-i18n/customer';
import {
  breakpointUp,
  DefaultParagraph,
  Tabs,
  useWindowSize,
} from '@sonnen/shared-web';
import { push, replace } from 'connected-react-router';
import { isNil } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import './GuideDiscoveryActive.component.scss';

const mapStateToProps = (state: StoreState) => ({
  phaseActiveTab: getPhaseActiveTab(state),
  activeModal: getActiveDiscoveryModal(state),
});

const mapDispatchToProps = mapActions({
  goTo: replace,
  goToPurchase: () => push(getPaths().GUIDE_PURCHASE),
  setActiveTab: GuideActions.setPhaseActiveTab,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const GuideDiscoveryActiveComponent: React.FC<Props> = ({
  actions,
  phaseActiveTab,
}) => {
  React.useEffect(() => {
    const phaseActiveTab = getTabFromStep(window.location.pathname);
    if (!isNil(phaseActiveTab)) {
      actions.setActiveTab(phaseActiveTab);
    }

    return () => {
      actions.setActiveTab(0);
    };
  }, []);

  React.useEffect(() => {
    const phaseActiveTab = getTabFromStep(window.location.pathname);
    if (!isNil(phaseActiveTab)) {
      actions.setActiveTab(phaseActiveTab);
    }
  }, [window.location.pathname]);

  const phaseStepPath = React.useContext(PhaseContext);
  const stepsYourTodo = getStepsFromPhase(PHASES.DISCOVERY, TABS.YOUR_TODO);
  const stepsOurTodo = getStepsFromPhase(PHASES.DISCOVERY, TABS.OUR_TODO);
  
  return (
    <>
      <div className={'c-guide-discovery-active__subheadline'}>
        <DefaultParagraph>
          {I18n.t(T.customerGuidance.planning.active.description)}
        </DefaultParagraph>
      </div>
      <div className={'c-guide-discovery-active__tabs'}>
        <Tabs
          triggers={[
            {
              label: I18n.t(T.customerGuidance.setup.active.tabs.myTodo),
              onClick: () => {
                actions.setActiveTab(0);
                actions.goTo(phaseStepPath(), { preserveScroll: true });
              },
            },
            {
              label: I18n.t(T.customerGuidance.setup.active.tabs.OurTodo),
              onClick: () => {
                actions.setActiveTab(1);
                actions.goTo(phaseStepPath(), { preserveScroll: true });
              },
            },
          ]}
          activeTab={phaseActiveTab}
          isThemeInline={useWindowSize().innerWidth >= breakpointUp('SM') ? true : false}
        >
          <div className={'c-guide-discovery-active__tab'}>
            <p className={'c-guide-discovery-active__description'}>
              <DefaultParagraph>
                {I18n.t(T.customerGuidance.planning.active.tabs.headlines.myTodo)}
              </DefaultParagraph>
            </p>
            {
              Object.keys(stepsYourTodo).map(key => {
                const step = stepsYourTodo[key];
                const StepComponent = step.component;
                return <StepComponent key={`${step.phase}-${step.tab}-${key}`}/>;
              })
            }
          </div>
          <div className={'c-guide-discovery-active__tab'}>
            <p className={'c-guide-discovery-active__description'}>
              <DefaultParagraph>
                {I18n.t(T.customerGuidance.planning.active.tabs.headlines.ourTodo)}
              </DefaultParagraph>
            </p>
            {
              Object.keys(stepsOurTodo).map(key => {
                const step = stepsOurTodo[key];
                const StepComponent = step.component;
                return <StepComponent key={`${step.phase}-${step.tab}-${key}`}/>;
              })
            }
          </div>
        </Tabs>
      </div>
    </>
  );
};

export const GuideDiscoveryActive =
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GuideDiscoveryActiveComponent));
