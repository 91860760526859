import { PlatformSelector } from '+app/shared/components';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router-dom';
import { AuthActions } from '../../store/auth.actions';
import { AuthLogInMobile } from './AuthLogIn.mobile.component';
import { AuthLogInWeb } from './AuthLogIn.web.component';

const mapDispatchToProps = {
  redirectToAuth: AuthActions.redirectToAuth,
};

export type Props = typeof mapDispatchToProps & RouteProps;

export const AuthLogIn = connect(undefined, mapDispatchToProps)((props: Props) => (
  <PlatformSelector
    mobile={<AuthLogInMobile {...props} />}
    web={<AuthLogInWeb {...props} />}
  />
));
