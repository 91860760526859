import {
  BarChartSeries,
  StatisticsSeriesKey,
} from '@sonnen/shared-web';

import { mapDataSeries } from '+analysis/helpers/analysis.helper';
import { KanvaContainerType } from '../../../kanva/kanva.types';

const prepareDataSeries = (
  dataSeries: BarChartSeries,
) => {
  const mappedDataSeries = {
    [KanvaContainerType.DEFAULT]: mapDataSeries({
      [StatisticsSeriesKey.PRODUCED_ENERGY]: dataSeries[StatisticsSeriesKey.PRODUCED_ENERGY],
      [StatisticsSeriesKey.CONSUMED_ENERGY]: dataSeries[StatisticsSeriesKey.CONSUMED_ENERGY],
    }),
  };

  return mappedDataSeries;
};

export const AnalysisBarChartProviderHelper = {
  prepareDataSeries,
};
