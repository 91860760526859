import { AccessoriesSeriesKey, EnergyFlowSeriesKey, Point } from '@sonnen/shared-web';

export type SerieJson = Record<'default', Record<EnergyFlowSeriesKey | AccessoriesSeriesKey, Point[]>>;

const getSerie = (
  json: SerieJson,
  serieName: EnergyFlowSeriesKey | AccessoriesSeriesKey,
): Point[] => json.default && json.default[serieName] || [];

export const MockHelper = {
  getSerie,
};
