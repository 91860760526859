import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { useInfographic } from '+dashboard/+infographic';
import { InfographicCardLayout } from '../InfographicCardLayout/InfographicCardLayout.component';
import { InfographicBatteryCardHelper } from './InfographicBatteryCard.helpers';
import { InfographicBatteryCardStatus } from './InfographicBatteryCardStatus.component';
import { InfographicBatteryCardValue } from './InfographicBatteryCardValue.component';

import './InfographicBatteryCard.component.scss';

interface Props {
  isLoading: boolean;
}

export const InfographicBatteryCard: React.FC<Props> = ({ isLoading = false }) => {
  const { data: { batteryData, siteLiveData, query }, actions } = useInfographic();

  const batteryStatus = InfographicBatteryCardHelper.getCardStatus(siteLiveData, query.siteLiveQuery, batteryData);
  const backupBoxStatus = InfographicBatteryCardHelper.getBackupBoxStatus(siteLiveData, batteryData);

  return (
    <div className={'c-infographic-battery-card'}>
      <InfographicCardLayout
        headerDataTestId={'dashboard-infographic-card-battery-header'}
        title={I18n.t(T.dashboard.infographic.battery.title)}
        notifications={<InfographicBatteryCardStatus
          batteryStatus={batteryStatus}
          backupBoxStatus={backupBoxStatus}
          timestamp={siteLiveData?.timestamp}
          toggleModal={actions?.toggleModalBackupBuffer}
        />}
        isLoading={isLoading}
      >
        <InfographicBatteryCardValue
          batteryStatus={batteryStatus}
          batteryUSOC={siteLiveData?.batteryUsoc || 0}
        />
      </InfographicCardLayout>
    </div>
  );
};
