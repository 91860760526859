import {
  getFlatOrderConfirmationStepStatus,
  isFlatOfferAccepted,
  isFlatOfferAvailable,
  isHardwareOfferAvailable,
  isHardwareOrderConfirmed,
} from '+app/+guide/+purchase/store/+purchase.selectors';
import { getSetupProgress } from '+app/+guide/+setup/store/+setup.selectors';
import { GuidePhaseNames, GuidePhaseStatus } from '+app/+guide/components/GuidePhase/GuidePhase.helper';
import { GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StoreState } from '+app/store/store.interface';
import { createSelector } from 'reselect';

export const getDiscoveryPhaseStatus = createSelector(
  isFlatOfferAvailable,
  isHardwareOfferAvailable,
  (flatOfferAvailable, hardwareOfferAvailable) =>
    (flatOfferAvailable || hardwareOfferAvailable)
      ? GuidePhaseStatus.DONE
      : GuidePhaseStatus.ACTIVE,
);

export const getPurchasePhaseStatus = createSelector(
  getFlatOrderConfirmationStepStatus,
  isFlatOfferAvailable,
  isHardwareOfferAvailable,
  (confirmationStatus, flatOfferAvailable, hardwareOfferAvailable) => {
    if (confirmationStatus === GuideStepStatus.DONE) {
      return GuidePhaseStatus.DONE;
    }

    if (flatOfferAvailable || hardwareOfferAvailable) {
      return GuidePhaseStatus.ACTIVE;
    }

    return GuidePhaseStatus.PREVIEW;
  },
);

// @TODO Add condition for DONE status as soon as setup steps are available 
// @TODO change condition for ACTIVE when order confirmation is done rather than accepting an offer is done

export const getSetupPhaseStatus = createSelector(
  isFlatOfferAccepted,
  isHardwareOrderConfirmed,
  getSetupProgress,
  (flatOfferAccepted, hardwareOrderConfirmed, setupProgress) => {
    if (setupProgress === 100) {
      return GuidePhaseStatus.DONE;
    }
    if (hardwareOrderConfirmed || flatOfferAccepted) {
      return GuidePhaseStatus.ACTIVE;
    }
    
    return GuidePhaseStatus.PREVIEW;
  },
);

export const getEnjoyPhaseStatus = (state: StoreState) => GuidePhaseStatus.PREVIEW;

export const getPreviewPhases = createSelector(
  getPurchasePhaseStatus,
  getSetupPhaseStatus,
  getEnjoyPhaseStatus,
  (purchasePhase, setupPhase, enjoyPhase) => {
    if (purchasePhase === GuidePhaseStatus.PREVIEW) {
      return [GuidePhaseNames.PURCHASE, GuidePhaseNames.SETUP, GuidePhaseNames.ENJOY];
    }

    if (setupPhase === GuidePhaseStatus.PREVIEW) {
      return [GuidePhaseNames.SETUP, GuidePhaseNames.ENJOY];
    }

    return [GuidePhaseNames.ENJOY];
  },
);

export const getActivePhase = createSelector(
  getDiscoveryPhaseStatus,
  getPurchasePhaseStatus,
  (discoveryPhase, purchasePhase) => {
    if (discoveryPhase === GuidePhaseStatus.ACTIVE) {
      return GuidePhaseNames.DISCOVERY;
    }

    if (purchasePhase === GuidePhaseStatus.ACTIVE) {
      return GuidePhaseNames.PURCHASE;
    }

    return GuidePhaseNames.SETUP;
  },
);

// @TODO add setupPhase condition as soon as DONE status for setup is available
export const getDonePhases = createSelector(
  getDiscoveryPhaseStatus,
  getPurchasePhaseStatus,
  getSetupPhaseStatus,
  getEnjoyPhaseStatus,
  (discoveryPhase, purchasePhase, setupPhase, enjoyPhase) => {
    const donePhases = [];
    
    if (enjoyPhase === GuidePhaseStatus.DONE) {
      donePhases.push(GuidePhaseNames.ENJOY);
    }
    if (setupPhase === GuidePhaseStatus.DONE) {
      donePhases.push(GuidePhaseNames.SETUP);
    }
    if (purchasePhase === GuidePhaseStatus.DONE) {
      donePhases.push(GuidePhaseNames.PURCHASE);
    }
    if (discoveryPhase === GuidePhaseStatus.DONE) {
      donePhases.push(GuidePhaseNames.DISCOVERY);
    }

    return donePhases;
  },
);
