import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import { NestedNavHelper } from '../NestedNav.helper';
import { NestedNavSideContentType, NestedNavTheme } from '../NestedNav.types';

import './NestedNavSideContent.component.scss';

interface NestedNavSideContentProps {
  contentType?: NestedNavSideContentType;
  theme?: NestedNavTheme;
  isActive?: boolean;
}

const renderButtonContent = (
  contentType: NestedNavSideContentType | undefined,
  theme?: NestedNavTheme,
  isActive?: boolean,
) => (
  contentType === 'radial' ? (
    <div className={classNames('c-nested-nav-side-content__radial', {
      'c-nested-nav-side-content__radial--active': isActive,
    })}>
      {isActive && (
        <Icon.Checkmark className={'c-nested-nav-side-content__radial-checkmark'} />
      )}
    </div>
  ) : (
    <Icon.Angle className={classNames('c-nested-nav-side-content__icon', {
      'c-nested-nav-side-content__icon--theme-dark': NestedNavHelper.isDarkTheme(theme),
    })}/>
  )
);

export const NestedNavSideContent: React.FC<NestedNavSideContentProps> = ({
  contentType,
  theme,
  isActive,
}) => renderButtonContent(contentType, theme, isActive);
