import { QueryState } from '+query/query.state';
import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { isContractType } from '+contract/store/helpers/contract.helpers';
import { ContractTypes } from '+contract/store/types/contract.interface';
import { useInfographic } from '+dashboard/+infographic';
import { InfographicCardLayout } from '../InfographicCardLayout/InfographicCardLayout.component';
import { InfographicContractCardHelper } from './InfographicContractCard.helper';
import { InfographicContractCardStatus } from './InfographicContractCardStatus.component';
import { InfographicContractCardValue } from './InfographicContractCardValue.component';

interface Props {
  isLoading: boolean;
}

export const InfographicContractCard: React.FC<Props> = ({ isLoading = false }) => {
  const { data: { contractData, siteLiveData, query: { contractQuery } } } = useInfographic();
  const { contract, sonnenFlatStatistics, isNoQuota } = contractData;

  const isSonnenFlatContract = isContractType(contract, ContractTypes.SONNEN_FLAT_HOME);
  const cardData = InfographicContractCardHelper.getCardData(contract, sonnenFlatStatistics);
  const cardStatus = InfographicContractCardHelper.getCardStatus(
    contract,
    contractQuery,
    isNoQuota,
    !!(siteLiveData?.online),
  );

  return (
    <InfographicCardLayout
      headerDataTestId={'dashboard-infographic-card-contract-header'}
      title={cardData.title || ''}
      notifications={<InfographicContractCardStatus status={cardStatus}/>}
      isLoading={isLoading}
      tooltipTitle={isSonnenFlatContract ? I18n.t(T.dashboard.infographic.contract.popupTitle) : undefined}
      tooltipContent={isSonnenFlatContract && (
        <DefaultParagraph>
          <Translate
            value={T.dashboard.infographic.contract.popupText}
            dangerousHTML={true}
          />
        </DefaultParagraph>
      )}
    >
      <InfographicContractCardValue
        contract={contract}
        cardData={cardData}
        status={cardStatus}
      />
    </InfographicCardLayout>
  );
};
