import { AxisOrientation, GridLines } from '@kanva/charts';
import { AxisView, BarChartView as BarChartViewComponent, ChartGridView } from '@kanva/charts-react';
import { rgba, TextAlign } from '@kanva/core';
import { View as ViewComponent } from '@kanva/react';
import { 
  barChartGridPaint,
  barChartStyle,
  barChartxAxisStyle,
  barChartyAxisLabelPaint,
  CHART_FONT,
  SharedChartColors,
  Views,
} from '@sonnen/shared-web';
import * as React from 'react';

import { FullWidthKanva } from '+components';
import { useAnalysisBarChart } from '../../containers/AnalysisKanvaProvider/AnalysisBarChartProvider';
import { useAnalysis } from '../../containers/AnalysisProvider/AnalysisProvider.context';
import { CHART_HEIGHT } from '../../helpers/analysis.helper';
import { layout } from './AnalysisBarChart.mobile.layout';

interface AnalysisBarChartMobileProps {
  chartHeight: number;
}

export const AnalysisBarChartMobile: React.FC<AnalysisBarChartMobileProps> = ({
  chartHeight,
}) => {
  const { query } = useAnalysis();
  const { containers, handleMount } = useAnalysisBarChart();
  const yLabel = barChartyAxisLabelPaint.setFont({
    ...CHART_FONT,
    fontSize: 8,
  });
  const isLoading = query.statisticsQueryStatus.pending;

  return (
    <>
      <FullWidthKanva
        height={chartHeight || CHART_HEIGHT}
        isLoading={isLoading}
      >
        <ViewComponent
          layoutParams={layout.chartWrapper}
          border={{ bottom: 1 }}
          borderColor={rgba(SharedChartColors.DARK, .1)}
        >
          <ChartGridView
            layoutParams={layout.grid}
            dataContainer={containers?.default}
            gridLines={GridLines.HORIZONTAL}
            style={{ paint: barChartGridPaint }}
          />
          <BarChartViewComponent
            layoutParams={layout.barChart}
            dataContainer={containers?.default}
            style={barChartStyle}
            onMount={handleMount}
          />
        </ViewComponent>
        <AxisView
          id={Views.X_AXIS}
          layoutParams={layout.xAxis}
          dataContainer={containers?.default}
          orientation={AxisOrientation.HORIZONTAL}
          style={barChartxAxisStyle}
        />
        <AxisView
          id={Views.Y_AXIS}
          layoutParams={layout.yAxis}
          dataContainer={containers?.default}
          orientation={AxisOrientation.VERTICAL}
          style={{
            labelPaint: yLabel.clone()
              .setTextAlign(TextAlign.LEFT),
          }}
        />
      </FullWidthKanva>
    </>
  );
};
