import * as classNames from 'classnames';
import * as React from 'react';
import { ArrowRendererHandler, HandlerRendererResult } from 'react-select';

import './ArrowRenderer.scss';

export const ArrowRenderer: ArrowRendererHandler = ({ isOpen }): HandlerRendererResult => (
  <div className={classNames('c-select-arrow', {
    'is-open': isOpen,
  })} />
);
