import {
  isContractType,
  isExceedingExpected,
  isFreeUsageAllowanceValueMissing,
  isQuotaExceeded,
  SonnenFlatDataProvider,
} from '+contract/store/helpers/contract.helpers';
import { ContractTypes } from '+contract/store/types/contract.interface';
import { QueryState } from '+query/query.state';
import { DateUtil } from '+utils/date.util';
import * as React from 'react';

import { ContractFlatStatuses } from '+contract/containers/ContractFlatStatuses';
import {
  ContractEnergyMeterSection,
  ContractFlatExpertView,
  ContractNoQuotaInfo,
} from '../';
import { ContractBillingSection } from '../ContractBillingSection';

import './ContractFlatStatistics.component.scss';

export type ContractFlatStatisticsProps = {
  batteryTimezone: string;
  contractQueryStatus: QueryState;
  dataProvider: SonnenFlatDataProvider;
  expertViewLegendGreenLabel: string;
  isContractStatusDone: boolean;
  isSiteWithBattery: boolean;
};

export const ContractFlatStatistics: React.FC<ContractFlatStatisticsProps> = ({
  batteryTimezone,
  contractQueryStatus,
  dataProvider,
  expertViewLegendGreenLabel,
  isContractStatusDone,
  isSiteWithBattery,
}) => {
  const billingPeriod = dataProvider.getBillingPeriod();
  const expectedUsage = dataProvider.getExpectedUsage();
  const freeUsageAllowance = dataProvider.getFreeUsageAllowance();

  const isSonnenFlatHomeContract = isContractType(dataProvider.contract, ContractTypes.SONNEN_FLAT_HOME);
  const isFreeUsageAllowanceAvailable = (freeUsageAllowance: number | null): freeUsageAllowance is number =>
    !isSonnenFlatHomeContract || !isFreeUsageAllowanceValueMissing(
      freeUsageAllowance,
      isSiteWithBattery,
      contractQueryStatus,
    );

  if (!isFreeUsageAllowanceAvailable(freeUsageAllowance)) {
    return (
      <div className={'c-contract-flat-statistics'}>
        <div className={'c-contract-flat-statistics__banner'}>
          <ContractNoQuotaInfo />
        </div>
      </div>
    );
  }

  const shouldRenderContent = isSonnenFlatHomeContract || isContractStatusDone;

  const isContractExceedingExpected = isExceedingExpected(expectedUsage, freeUsageAllowance);
  const isContractQuotaExceeded = isQuotaExceeded(dataProvider.getEnergyUsageDaily(), freeUsageAllowance);
  const billingPeriodFormattedInBatteryTimezone = {
    start: billingPeriod.start.tz(batteryTimezone).format(DateUtil.DateFormat.NUMERIC_FULL_DATE),
    end: billingPeriod.end.tz(batteryTimezone).format(DateUtil.DateFormat.NUMERIC_FULL_DATE),
  };

  return (
    <div className={'c-contract-flat-statistics'}>
      <>
        {shouldRenderContent ? (
          <>
            <ContractBillingSection billingPeriod={billingPeriod} />
            <ContractEnergyMeterSection
              freeUsageAllowance={freeUsageAllowance}
              percentage={dataProvider.getPercentageUsage()}
            />
            {isSonnenFlatHomeContract && <ContractFlatExpertView
              billingPeriod={billingPeriodFormattedInBatteryTimezone}
              consumptionByDay={dataProvider.getContractUsageDaily()}
              legendGreenLabel={expertViewLegendGreenLabel}
              expectedConsumption={dataProvider.getExpectedUsage()}
              freeUsageAllowance={freeUsageAllowance}
              isExceedingExpected={isContractExceedingExpected}
              isQuotaExceeded={isContractQuotaExceeded}
            />}
          </>
        ) : (
          <ContractFlatStatuses />
        )}
      </>
    </div>
  );
};
