import { isSpecificFlatProductInOffer } from '+app/+guide/+acceptance/store/+acceptance.helper';
import { GuideTextWithTooltip, GuideVatInfo } from '+app/+guide/components';
import { OfferProductType, OfferTaxRegulation, Proposal } from '+app/+guide/store/types';
import { ConfigurationHelper } from '+app/shared/helpers/configuration.helper';
import { T } from '@sonnen/shared-i18n/customer';
import { Switch } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { 
  factorizeConfBasicInfo, 
  factorizeExpectedCashbackInfo, 
  factorizeMonthlyPrepaymentInfo,
  factorizeVatOnUsedUsageAllowance, 
  factorizeVppBonusInfo, 
} from '../../containers/GuidePurchaseFlatOffer/GuidePurchaseFlatOffer.helper';
import { ConfigurationLine } from '../ConfigurationLine/ConfigurationLine';

import './GuidePurchaseConfigurationDetails.scss';

interface Props {
  proposal: Proposal;
}

export const GuidePurchaseConfigurationDetails: React.FC<Props> = ({ proposal }) => {
  const [isDetailedViewOpen, setIsDetailedViewOpen] = React.useState<boolean>(false);
  const offerTaxRegulation = proposal.offer?.taxRegulation ?? OfferTaxRegulation.STANDARD;
  const isVppBonusGranted = !!proposal.powerPlant.guaranteedVppBonusGranted;

  return (
    <>
      <div className="c-guide-purchase-configuration-details__detailed-view">
        <p className="c-guide-purchase-configuration-details__detailed-view-title">
          {I18n.t(T.customerGuidance.purchase.flatOffer.detailedView)}
        </p>
        <Switch 
          name={`switch-${proposal.offer?.id}`}
          isChecked={isDetailedViewOpen}
          onToggle={() => setIsDetailedViewOpen((prev) => !prev)}
        />
      </div>
      <div className="c-guide-purchase-configuration-details__header">
        <GuideTextWithTooltip
          title={proposal.offer && isSpecificFlatProductInOffer(proposal.offer, OfferProductType.FLAT_X)
            ? I18n.t(T.customerGuidance.purchase.tooltip.sonnenFlat.title)
            : I18n.t(T.customerGuidance.purchase.tooltip.sonnenFlatDirect.title)}
              content={I18n.t(T.customerGuidance.purchase.tooltip.sonnenFlat.description)}
          />
      </div>
      <div className="c-guide-purchase-configuration-details__section">
        {factorizeConfBasicInfo(proposal).map(({ title, value }, index) =>
          <ConfigurationLine
            title={title}
            value={value}
            key={`offer-line-${index}`}
          />,
        )}
      </div>

      <div className={'c-guide-purchase-configuration-details__product-divider'} />

      <div className="c-guide-purchase-configuration-details__section">
        {factorizeMonthlyPrepaymentInfo(proposal, offerTaxRegulation, isDetailedViewOpen).map(
          ({ title, value, helperLabel }, index) =>
            <ConfigurationLine
              title={title}
              value={value}
              helperLabel={helperLabel}
              key={`offer-line-${index}`}
            />,
          )}
      </div>

      <div className={'c-guide-purchase-configuration-details__product-divider'} />

      <div className="c-guide-purchase-configuration-details__section">
        {factorizeExpectedCashbackInfo(proposal,  offerTaxRegulation, isDetailedViewOpen).map(
          ({ title, value, helperLabel }, index) =>
            <ConfigurationLine
              title={title}
              value={value}
              helperLabel={helperLabel}
              key={`offer-line-${index}`}
            />,
          )}
      </div>

      {offerTaxRegulation === OfferTaxRegulation.SMALL_ENTERPRISE && (
         <> 
          <div className={'c-guide-purchase-configuration-details__product-divider'} />
          <div className="c-guide-purchase-configuration-details__section">
            {factorizeVatOnUsedUsageAllowance(proposal, isDetailedViewOpen).map(
              ({ title, value }, index) =>
                <ConfigurationLine
                  title={title}
                  value={value}
                  key={`offer-line-${index}`}
                />,
            )}
          </div>
        </>
      )}

      {isVppBonusGranted && (
        <>
          <div className={'c-guide-purchase-configuration-details__product-divider'} />

          <div className="c-guide-purchase-configuration-details__section">
            {factorizeVppBonusInfo(proposal, offerTaxRegulation, isDetailedViewOpen).map(
              ({ title, value, helperLabel }, index) =>
                <ConfigurationLine
                  title={title}
                  value={value}
                  helperLabel={helperLabel}
                  key={`offer-line-${index}`}
                />,
            )}
          </div>
        </>
      )}

      {offerTaxRegulation === OfferTaxRegulation.STANDARD && (
        <>
          <div className={'c-guide-purchase-configuration-details__product-divider'} />

          <div className="c-guide-purchase-configuration-details__section">
            <GuideVatInfo
              rate={ConfigurationHelper.formatTaxRate(proposal.valueAddedTax)}
            />
          </div>       
        </>
      )}

    </>
  );
};
