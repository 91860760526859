import './setup/polyfills';

import '@sonnen/shared-web/_fonts.scss';
import '@sonnen/shared-web/_styles.scss';

import { configureStore } from '+app/store/store.config';
import { rootReducer, rootSaga } from '+app/store/store.root';
import { history } from '+router/store/index';
import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { App } from './app';

const { store, persistor } = configureStore(rootReducer, rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      {(bootstrapped: boolean) => bootstrapped && (
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      )}
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
