import { ActionsUnion, createAction } from '+utils/redux';
import { RouterActionType } from 'connected-react-router';
import { Location } from 'history';

export enum ROUTER_ACTIONS {
  LOCATION_CHANGE = '@@router/LOCATION_CHANGE',
  IS_READY = '[Router] IS_READY',
}

export const RouterActions = {
  locationChange: (action: RouterActionType, location: Location) => createAction(
    ROUTER_ACTIONS.LOCATION_CHANGE,
    ({ payload: { action, location } }),
  ),
  isReady: (navigated: boolean) => createAction(ROUTER_ACTIONS.IS_READY, ({ navigated })),
};

export type RouterActions = ActionsUnion<typeof RouterActions>;
