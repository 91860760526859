import { BatteryInverterState } from '@sonnen/shared-web';

import {
  BatteryInverterStatus,
  MeasurementMethod,
  SiteLive,
} from '+app/shared/store/live/types/siteLiveData.interface';

export const LIVE_DATA_MOCK: SiteLive = {
  backupActive: true,
  batteryCharging: 0,
  batteryChargingKw: 0.0,
  batteryDischarging: 0,
  batteryDischargingKw: 0,
  batteryInverterState: BatteryInverterState.STANDBY,
  batteryInverterStatus: BatteryInverterStatus.ONGRID,
  batteryOperatingMode: 'Self-Consumption',
  batterySerialNumber: '108431',
  batteryUsoc: 100,
  consumptionPower: 5133,
  consumptionPowerKw: 5.1,
  gridFeedin: 110,
  gridFeedinKw: 0.1,
  gridPurchase: 0,
  gridPurchaseKw: 0,
  gridServiceActive: false,
  heaterPower: 1500,
  heaterWaterTemp: 73,
  independent: true,
  online: true,
  measurementMethod: MeasurementMethod.BATTERY,
  productionPower: 5196,
  productionPowerKw: 5.2,
  pvReduction: null,
  timestamp: new Date().toISOString(),
  self: 'live-state',
  id: '111',
  type: 'live-states',
};
