import { Profile } from '+account/store/types/profile.interface';
import { ResultGeom } from '+legacy/features/map/communityMap.types';
import { ActionsUnion, createAction } from '+utils/redux';
import {
  CommunityAttributes,
  CommunityContentHubArticle,
  CommunityMapCardTypes,
} from './types/community.interface';

export enum COMMUNITY_ACTIONS {
  GET_COMMUNITY_STATS_DATA = '[Community] GET_COMMUNITY_STATS_DATA',
  SET_COMMUNITY_STATS_DATA = '[Community] SET_COMMUNITY_STATS_DATA',
  SET_COMMUNITY_PROFILE_DATA = '[Community] SET_COMMUNITY_PROFILE_DATA',
  SET_ACTIVE_CARD_TYPE = '[Community] SET_ACTIVE_CARD_TYPE',
  TOGGLE_CARD = '[Community] TOGGLE_CARD',
  SELECT_COMMUNITY_PROFILE = '[Community] SELECT_COMMUNITY_PROFILE',
  GET_COMMUNITY_CONTENT_HUB_ARTICLES = '[Community] GET_COMMUNITY_CONTENT_HUB_ARTICLES',
  SET_COMMUNITY_CONTENT_HUB_ARTICLES = '[Community] SET_COMMUNITY_CONTENT_HUB_ARTICLES',
}

export const CommunityActions = {
  getCommunityStatsData: () => createAction(COMMUNITY_ACTIONS.GET_COMMUNITY_STATS_DATA),
  setCommunityStatsData: (stats: CommunityAttributes) =>
    createAction(COMMUNITY_ACTIONS.SET_COMMUNITY_STATS_DATA, { stats }),
  setCommunityProfileData: (profile: Profile) =>
    createAction(COMMUNITY_ACTIONS.SET_COMMUNITY_PROFILE_DATA, { profile }),
  setActiveCardType: (cardType: CommunityMapCardTypes) =>
    createAction(COMMUNITY_ACTIONS.SET_ACTIVE_CARD_TYPE, { cardType }),
  toggleCard: (isVisible?: boolean) =>
    createAction(COMMUNITY_ACTIONS.TOGGLE_CARD, { isVisible }),
  selectCommunityProfile: (mapPoint: ResultGeom) =>
    createAction(COMMUNITY_ACTIONS.SELECT_COMMUNITY_PROFILE, { mapPoint }),
  getCommunityContentHubArticles: () => createAction(COMMUNITY_ACTIONS.GET_COMMUNITY_CONTENT_HUB_ARTICLES),
  setCommunityContentHubArticles: (articles: CommunityContentHubArticle[]) =>
    createAction(COMMUNITY_ACTIONS.SET_COMMUNITY_CONTENT_HUB_ARTICLES, { articles }),
};

export type CommunityActions = ActionsUnion<typeof CommunityActions>;
