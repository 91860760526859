import { AxisViewStyle, ChartZoomViewStyle } from '@kanva/charts';
import { Paint, Rect, rgba, TextAlign, TextBaseline } from '@kanva/core';
import { CHART_FONT, SharedChartColors } from '@sonnen/shared-web';

const AREA_SELECT_BORDER_WIDTH = 1;

export const xAxisStyle: AxisViewStyle = {
  labelPaint: new Paint()
    .setFillStyle(rgba(SharedChartColors.DARK, .5))
    .setTextAlign(TextAlign.CENTER)
    .setTextBaseline(TextBaseline.MIDDLE)
    .setFont(CHART_FONT),
};

export const selectAreaZoomStyle: ChartZoomViewStyle = {
  paint: new Paint()
    .setFillStyle(rgba(SharedChartColors.AREA_SELECT, .3))
    .setStrokeStyle(SharedChartColors.AREA_SELECT),
  borders: new Rect({left: AREA_SELECT_BORDER_WIDTH, right: AREA_SELECT_BORDER_WIDTH, top: 0, bottom: 0 }),
};
