import { getPaths } from '+app/router/router.helper';
import { LayoutContext } from '+app/shared/components/Layout/Layout.context';
import { GuidePreviewNav, GuidePreviewStep } from '+guide/components';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph } from '@sonnen/shared-web';
import { PageHeadline } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './GuideUsage.component.scss';

import community from '+images/guide/community.svg';
import piggybank from '+images/guide/piggybank.svg';
import wave from '+images/guide/waved-line.svg';

const mapDispatchToProps = mapActions({
  goToSetup: () => push(getPaths().GUIDE_SETUP),
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  ;

export const GuideUsageComponent: React.FC<Props> = ({ actions }) =>  {
  const layout = React.useContext(LayoutContext);

  React.useEffect(() => {
    layout.setGrayBackground(true);

    return () => {
      layout.setGrayBackground(false);
    };
  }, []);

  return (
    <div className={'c-guide-usage'}>
      <PageHeadline smallGap={true}>
        {I18n.t(T.customerGuidance.usage.headline)}
      </PageHeadline>
      <div className={'c-guide-usage__subheadline'}>
        <DefaultParagraph>
          {I18n.t(T.customerGuidance.usage.preview.description)}
        </DefaultParagraph>
      </div>
      <div className={'c-guide-usage__preview-steps'} >
        <GuidePreviewStep
          icon={community}
          title={I18n.t(T.customerGuidance.usage.preview.community)}
          subtitle={I18n.t(T.customerGuidance.usage.preview.communityDescription)}
        />
        <img src={wave} className={'c-guide-usage__wave'} />
        <GuidePreviewStep
          icon={piggybank}
          title={I18n.t(T.customerGuidance.usage.preview.enjoy)}
          subtitle={I18n.t(T.customerGuidance.usage.preview.enjoyDescription)}
        />
      </div>
      <GuidePreviewNav
        prevPhase={actions.goToSetup}
      />
    </div>
  );
};

export const GuideUsage = connect(null, mapDispatchToProps)(GuideUsageComponent);
