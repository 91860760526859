import { T } from '@sonnen/shared-i18n/customer';
import { Select } from '@sonnen/shared-web';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';

import './FilterSection.component.scss';

export interface SelectContractOption {
  label: string;
  value: string;
}

interface Props {
  options: SelectContractOption[];
  selectedContractId?: string;
  updateSelectedContract: (value: string) => void;
  numberOfContracts: number;
}

const AvailableContracts = (props: {numberOfContracts: number}) => (
  <Translate
    value={props.numberOfContracts === 1
      ? T.documents.invoices.numberOfContracts.singular
      : T.documents.invoices.numberOfContracts.plural
    }
    numberOfContracts={props.numberOfContracts}
  />
);

export const FiltersSection = ({
  options,
  selectedContractId,
  updateSelectedContract,
  numberOfContracts,
}: Props) => (
  <div className={'c-filter-section'}>
    <div
      data-test-id={'filter-section-available-contracts-title'}
      className={'c-filter-section__available-contracts'}
    >
      <AvailableContracts numberOfContracts={numberOfContracts} />
    </div>
    <div
      data-test-id={'filter-section-select'}
      className={'c-filter-section__dropdown-container'}>
      <Select<SelectContractOption>
        id={'contract'}
        itemsSelected={options.find(option => option.value === selectedContractId)}
        itemFactory={option => option.label}
        items={options}
        onSelect={({value}) => updateSelectedContract(value)}
      />
    </div>
  </div>
);
