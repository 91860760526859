import { AnalysisSpec, DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';

import './AnalysisSlide.component.scss';

interface Props {
  icon: React.ReactNode;
  value: number;
  unit: string;
  description: string;
}

export const AnalysisSlide: React.FC<Props> = ({
  icon,
  value,
  unit,
  description,
}) => (
  <div className={'c-analysis-slide'}>
    <div 
      className={'c-analysis-slide__inner'}
      data-test={AnalysisSpec.ANALYSIS_SLIDE_ITEM}
    >
      <div className={'c-analysis-slide__content'}>
        <div className={'c-analysis-slide__icon'}>
          {icon}
        </div>
        <div className={'c-analysis-slide__text'}>
          <p className={'c-analysis-slide__value'}>
            {value}
          </p>
          <p className={'c-analysis-slide__unit'}>
            {unit}
          </p>
          <DefaultParagraph>
            <Translate
              value={description}
              dangerousHTML={true}
            />
          </DefaultParagraph>
        </div>
      </div>
    </div>
  </div>
);
