import * as React from 'react';

import './Notification.component.scss';

interface Props {
  title: string;
  action: () => void;
  icon?: React.ReactNode;
  subtitle?: string;
  type?: string;
  date: Date | string;
}

export const Notification: React.FC<Props> = ({
  action,
  icon,
  subtitle,
  title,
  type,
  date,
}) => (
  <button
    className={'c-notification'}
    type={'button'}
    onClick={action}
  >
    <div className={'c-notification__header'}>
      <div className={'c-notification__type'}>
        <span className={'c-notification__icon'}>
          {icon}
        </span>
        <span className={'c-notification__type-title'}>
          {type}
        </span>
      </div>
      <div className={'c-notification__date'}>
        {date}
      </div>
    </div>
    <p className={'c-notification__title'}>
      {title}
    </p>
    <p className={'c-notification__subtitle'}>
      {subtitle}
    </p>
  </button>
);
