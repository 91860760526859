import { ThenArg, Unpacked } from '../shared/utils.types';

export type FromPromise<T extends () => any> = ThenArg<ReturnType<T>>;
export type FromPromiseAll<T extends () => any> = Unpacked<FromPromise<T>>;

export const delay = (duration = 500) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
};
