import { queryReducer } from '+query/query.reducer';
import { createReducer } from '+utils/redux';
import * as moment from 'moment';
import { CONTRACT_ACTIONS, ContractActions } from './contract.actions';
import {
  ContractState,
  GET_CONTRACT_DATA_QUERY,
  GET_CONTRACT_DOCUMENT_URL_QUERY,
  GET_CONTRACT_DOCUMENTS_QUERY,
  GET_CONTRACT_FILES_REQUEST_DATA_QUERY,
  GET_CONTRACT_INVOICES_REQUEST_DATA_QUERY,
  GET_STATISTICS_DATA_QUERY,
  initialState,
  TERMINATE_CONTRACT_QUERY,
} from './contract.state';

export const contractReducer = createReducer<ContractState, ContractActions>(
  (state = initialState, action): ContractState => {
    switch (action.type) {
      case CONTRACT_ACTIONS.SET_CONTRACT_DATA: {
        return {
          ...state,
          contracts: action.contracts,
        };
      }
      case CONTRACT_ACTIONS.SET_SONNENFLAT_STATISTICS_DAILY: {
        return {
          ...state,
          flatContractStatistics: {
            ...state.flatContractStatistics,
            timestamp: moment().toString(),
            daily: action.daily,
          },
        };
      }
      case CONTRACT_ACTIONS.SET_SONNENFLAT_STATISTICS_TOTAL: {
        return {
          ...state,
          flatContractStatistics: {
            ...state.flatContractStatistics,
            timestamp: moment().toString(),
            total: action.total,
          },
        };
      }
      case CONTRACT_ACTIONS.SET_SONNENFLAT_STATISTICS_FROM_LAST_YEAR: {
        return {
          ...state,
          flatContractStatistics: {
            ...state.flatContractStatistics,
            lastYear: action.lastYear,
          },
        };
      }
      case CONTRACT_ACTIONS.SET_CONTRACT_FILES_REQUEST_DATA: {
        return {
          ...state,
          energyDocuments: {
            ...state.energyDocuments,
            contractFilesRequestData: {
              ...state.energyDocuments.contractFilesRequestData,
              [action.contractId]: action.contractFilesRequestData,
            },
          },
        };
      }
      case CONTRACT_ACTIONS.SET_CONTRACT_INVOICES_REQUEST_DATA: {
        return {
          ...state,
          energyDocuments: {
            ...state.energyDocuments,
            contractInvoicesRequestData: {
              ...state.energyDocuments.contractInvoicesRequestData,
              [action.contractId]: action.contractInvoicesRequestData,
            },
          },
        };
      }
      case CONTRACT_ACTIONS.CLEAR_CONTRACT:
        return {
          ...initialState,
        };
      case CONTRACT_ACTIONS.SET_CONTRACT_DOCUMENTS:
        return {
          ...state,
          documents: action.documents,
        };
      case CONTRACT_ACTIONS.SET_CONTRACT_DOCUMENT_URL:
        return {
          ...state,
          documentUrl: action.documentUrl,
        };
      case CONTRACT_ACTIONS.GET_CONTRACT_DOCUMENT_URL:
        return {
          ...state,
          selectedDocumentId: action.documentId,
        };
      case CONTRACT_ACTIONS.CLEAR_SELECTED_DOCUMENT_DATA:
        return {
          ...state,
          documentUrl: undefined,
          selectedDocumentId: undefined,
        };
      default:
        return queryReducer(state, action, [
          GET_CONTRACT_DATA_QUERY,
          GET_STATISTICS_DATA_QUERY,
          GET_CONTRACT_FILES_REQUEST_DATA_QUERY,
          GET_CONTRACT_INVOICES_REQUEST_DATA_QUERY,
          TERMINATE_CONTRACT_QUERY,
          GET_CONTRACT_DOCUMENTS_QUERY,
          GET_CONTRACT_DOCUMENT_URL_QUERY,
        ]);
    }
  },
  {
    persist: {
      key: 'contract',
      blacklist: ['energyDocuments'],
    },
  },
);
