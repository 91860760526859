import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';

import { StateAlert } from '+shared/components/StateAlert';
import { InfographicCardConnectionStatusType } from '../../containers';
import { InfographicContractCardStatusType } from '../InfographicContractCard/InfographicContractCard.types';

interface Props {
  status?: InfographicCardConnectionStatusType | InfographicContractCardStatusType;
}

export const InfographicContractCardStatus: React.SFC<Props> = ({ status }) => {
  switch (status) {
    case InfographicContractCardStatusType.PUCHARSE_COMPLETE:
      return (
        <StateAlert
          icon={<Icon.Checkmark />}
          small={true}
        >
          <Translate value={T.dashboard.infographic.statuses.purchaseComplete} />
        </StateAlert>
      );

    case InfographicContractCardStatusType.SUPPLY:
      return (
        <StateAlert
          icon={<Icon.Checkmark />}
          small={true}
        >
          <Translate value={T.dashboard.infographic.statuses.purchaseComplete} />
        </StateAlert>
      );

    case InfographicCardConnectionStatusType.OFFLINE:
      return (
        <StateAlert
          icon={<Icon.Offline />}
          variant={'warning'}
          small={true}
        >
          <Translate value={T.dashboard.infographic.statuses.batteryOffline} />
        </StateAlert>
      );

    case InfographicContractCardStatusType.MISSING_CONTRACT_DATA:
      return (
        <StateAlert
          icon={<Icon.Offline />}
          variant={'warning'}
          small={true}
        >
          <Translate value={T.dashboard.infographic.statuses.dataMissing} />
        </StateAlert>
      );

    case InfographicCardConnectionStatusType.OK:
      return (
        <StateAlert
          icon={<Icon.Checkmark />}
          small={true}
        >
          <Translate value={T.dashboard.infographic.statuses.contractActive} />
        </StateAlert>
      );
    default:
      return null;
  }
};
