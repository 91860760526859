import { GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { getGuideLeadPartner, getLeadStatus } from '+app/+guide/store/+lead.selectors';
import { LeadStatusName } from '+app/+guide/store/types';
import { PartnerStatus } from '+app/+guide/store/types/partner.interface';
import { StoreState } from '+app/store/store.interface';

import { createSelector } from 'reselect';

export const hasOnSiteVisitHappened = createSelector(
  getLeadStatus(LeadStatusName.ON_SITE_VISIT_DONE),
  status => status && status.isSet,
);

export const getOnSiteVisitStepStatus = createSelector(
  hasOnSiteVisitHappened,
  onSiteVisithappened => onSiteVisithappened
    ? GuideStepStatus.DONE
    : GuideStepStatus.ACTIVE,
);

export const getOnSiteVisitStepDate = createSelector(
  getLeadStatus(LeadStatusName.ON_SITE_VISIT_DONE),
  state => state && state.createdAt,
);

export const getDistributionToPartnerStepStatus = createSelector(
  getGuideLeadPartner,
  partner => {
    if (!partner) {
      return GuideStepStatus.ACTIVE;
    }

    switch (partner.status) {
      case PartnerStatus.ACCEPTED:
        return GuideStepStatus.DONE;
      case PartnerStatus.INBOX:
      case PartnerStatus.DECLINED:
      default:
        return GuideStepStatus.ACTIVE;
    }
  },
);

export const getActiveDiscoveryModal = (state: StoreState) => state.guide.discovery.activeModal;
