import { createAction } from '+utils/redux';
import { OnboardingErrorCodes } from './app.types';

export enum APP_ACTIONS {
  TOGGLE_ONLINE_STATE = 'APP/TOGGLE_ONLINE_STATE',
  TOGGLE_PULL_TO_REFRESH = 'APP/TOGGLE_PULL_TO_REFRESH',
  TOGGLE_MODAL_STATUS = 'APP/TOGGLE_MODAL_STATUS',
  // TODO: Remove these actions to proper places
  FORGOT_PASSWORD_SUCCESS = 'APP/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILED = 'APP/FORGOT_PASSWORD_FAILED',
  RESET_PASSWORD_SUCCESS = 'APP/RESET_PASSWORD_SUCCESS',
  REDIRECT_SUCCESS = 'APP/REDIRECT_SUCCESS',
  HELP_AND_SUPPORT_SUBMIT_SUCCESS = 'APP/HELP_AND_SUPPORT_SUBMIT_SUCCESS',
  SET_IS_READY = 'APP/SET_IS_READY',
}

const createStatusAction = <K extends string>(name: K) => (status: boolean) => createAction(name, { status });

export type SetIsReady = ReturnType<typeof setIsReady>;
export const setIsReady = () => createAction(APP_ACTIONS.SET_IS_READY);

export type ToggleOnlineState = ReturnType<typeof toggleOnlineState>;
export const toggleOnlineState = createStatusAction(APP_ACTIONS.TOGGLE_ONLINE_STATE);

export type ToggleModalStatus = ReturnType<typeof toggleModalStatus>;
export const toggleModalStatus = (modalId: string, isOpen: boolean) =>
  createAction(APP_ACTIONS.TOGGLE_MODAL_STATUS, { modalId, isOpen });

export type ChangeForgotPasswordSuccess = ReturnType<typeof changeForgotPasswordSuccess>;
export const changeForgotPasswordSuccess = createStatusAction(APP_ACTIONS.FORGOT_PASSWORD_SUCCESS);

export type ChangeForgotPasswordFailed = ReturnType<typeof changeForgotPasswordFailed>;
export const changeForgotPasswordFailed = (code: string = OnboardingErrorCodes.GENERAL) =>
  createAction(APP_ACTIONS.FORGOT_PASSWORD_FAILED, { code });

export type ChangeResetPasswordSuccess = ReturnType<typeof changeResetPasswordSuccess>;
export const changeResetPasswordSuccess = createStatusAction(APP_ACTIONS.RESET_PASSWORD_SUCCESS);

export type ChangeResendSuccess = ReturnType<typeof changeResendSuccess>;
export const changeResendSuccess = createStatusAction(APP_ACTIONS.REDIRECT_SUCCESS);

export type ChangeHelpAndSupportSubmitSuccess = ReturnType<typeof changeHelpAndSupportSubmitSuccess>;
export const changeHelpAndSupportSubmitSuccess = createStatusAction(APP_ACTIONS.HELP_AND_SUPPORT_SUBMIT_SUCCESS);

export type TogglePullToRefresh = ReturnType<typeof togglePullToRefresh>;
export const togglePullToRefresh = (isEnabled?: boolean) =>
  createAction(APP_ACTIONS.TOGGLE_PULL_TO_REFRESH, { isEnabled });

export type AppAction =
  | ToggleOnlineState
  | ToggleModalStatus
  | TogglePullToRefresh
  | ChangeForgotPasswordSuccess
  | ChangeForgotPasswordFailed
  | ChangeResetPasswordSuccess
  | ChangeResendSuccess
  | ChangeHelpAndSupportSubmitSuccess
  | SetIsReady
  ;
