import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';

interface SocialMediaLink {
  href: string;
  icon: React.ReactNode;
  modifier: string;
}

export const socialMediaLinks: SocialMediaLink[] = [
  {
    href: T.general.socialProfiles.twitterLink,
    icon: <Icon.Twitter className={'c-social-media-list__icon'} />,
    modifier: 'tw',
  },
  {
    href: T.general.socialProfiles.facebookLink,
    icon: <Icon.Facebook className={'c-social-media-list__icon'} />,
    modifier: 'fb',
  },
  {
    href: T.general.socialProfiles.youtubeLink,
    icon: <Icon.Play className={'c-social-media-list__icon'} />,
    modifier: 'yt',
  },
];
