import { CONFIG } from '+config';
import { forecastHttpClient, useHttp, useJsonApi } from '+shared/network';
import { HttpResponse } from '@coolio/http';
import * as moment from 'moment';

import { MeasurementsV2Filters } from '+analysis/store/types/measurementsV2.interface';
import {
  getMeasurementsDefaultDateRange,
  MeasurementsFilters,
} from './analysis.helpers';
import { SiteForecastConsumptionResponse, SiteForecastProductionResponse } from './types/forecast.interface';
import { SiteEventData } from './types/siteEvents.interface';
import { SiteMeasurementsData } from './types/siteMeasurements.interface';
import { StatisticsV1Data, StatisticsV1Filters } from './types/statisticsV1.interface';
import { StatisticsV2Data, StatisticsV2Filters } from './types/statisticsV2.interface';

const getSiteForecastProduction = (
  { token }: { token: string },
) => forecastHttpClient.get(CONFIG.API.PRODUCTION_FORECAST, {
  query: {
    jwt: token,
  },
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then((res: HttpResponse<SiteForecastProductionResponse>) => res.parsedBody());

const getSiteForecastConsumption = (
  { token }: { token: string },
) => forecastHttpClient.get(CONFIG.API.CONSUMPTION_FORECAST, {
  query: {
    jwt: token,
  },
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then((res: HttpResponse<SiteForecastConsumptionResponse>) => res.parsedBody());

const getSiteMeasurements = (
  { id, filters }:
  {id?: string, filters?: MeasurementsFilters },
) => {
  const { start, end } = filters || getMeasurementsDefaultDateRange();

  return useJsonApi().get<SiteMeasurementsData>(`${CONFIG.API.USER_DATA}/sites/${id}/measurements`)
  .filter('start', start)
  .filter('end', end)
  .send()
  .then(response => response.element);
};

const getStatisticsV2 = ({ id, filters }: { id: string, filters: StatisticsV2Filters }) => useJsonApi()
  .get<StatisticsV2Data>(`${CONFIG.API.USER_DATA_V2}/sites/${id}/statistics`)
    .filter('start_at', filters.start)
    .filter('end_at', filters.end)
    .filter('resolution', filters.resolution)
    .send()
    .then(response => response.element);

const getStatisticsV1 = ({ id, filters }: { id: string, filters: StatisticsV1Filters }) => useJsonApi()
  .get<StatisticsV1Data>(`${CONFIG.API.USER_DATA}/sites/${id}/statistics`)
  .filter('start', filters.start)
  .filter('end', filters.end)
  .filter('resolution', filters.resolution)
  .send()
  .then(response => response.element);

const getSiteEvents = (
  { id, date }:
  { id: string, date: Date },
) => {
  const momentDate = moment(date);
  return useJsonApi().getList<SiteEventData>(`${CONFIG.API.USER_DATA}/sites/${id}/events`)
  .filter('start', momentDate.toISOString())
  .filter('end', momentDate.add(1, 'days').toISOString())
  .send()
  .then(response => response.elements);
};

const factorizeQueryFilters = (filters: Record<string, string>) => Object.keys(filters)
  .reduce<Record<string, string>>((acc, name) => ({
    ...acc,
    [`filter[${name}]`]: filters[name],
  }), {});

const getSiteMeasurementsCSV = ({ id, filters }: { id: string, filters: MeasurementsV2Filters }) =>
  useHttp()
    .get<string>(`${CONFIG.API.USER_DATA_V2}/sites/${id}/measurements.csv`, {
      query: factorizeQueryFilters({
        'start_at': filters.start,
        'end_at': filters.end,
        resolution: filters.resolution,
      }),
    })
    .then(x => x.parsedBody());

const getSiteStatisticsCSV = ({ id, filters }: { id: string, filters: StatisticsV2Filters }) =>
  useHttp()
    .get<string>(`${CONFIG.API.USER_DATA_V2}/sites/${id}/statistics.csv`, {
      query: factorizeQueryFilters({
        'start_at': filters.start,
        'end_at': filters.end,
        resolution: filters.resolution,
        fields: [
          'produced_energy',
          'consumed_energy',
          'battery_charged_energy',
          'battery_discharged_energy',
          'grid_feedin_energy',
          'grid_purchase_energy',
        ].join(','),
      }),
    })
    .then(x => x.parsedBody());

export const AnalysisRepository = {
  getSiteMeasurements,
  getSiteMeasurementsCSV,
  getSiteStatisticsCSV,
  getSiteForecastProduction,
  getSiteForecastConsumption,
  getStatisticsV2,
  getStatisticsV1,
  getSiteEvents,
};
