import * as Cleave from 'cleave.js/react';
import { FieldProps } from 'formik';
import { isEmpty } from 'lodash';
import * as React from 'react';

import { formatFieldValue } from '+shared/helpers/form.helper';
import { useInputEvent } from '+shared/hooks/useInputEvent';
import { FieldCustomProps } from '../FormInput.types';

const parseInputProps = ({
  valueFormatter,
  ...props
}: Omit<FieldCustomProps, 'mask'>): Omit<FieldCustomProps, 'mask'> => {
  if (props.type === 'date') {
    return {
      ...props,
      type: 'text',
      style: {
        ...props.style,
        caretColor: 'transparent',
      },
    };
  }

  return props;
};

const parseInputField = (props: Omit<FieldCustomProps, 'mask'>, field: any): object => {
  field.value = formatFieldValue(field.value, props.valueFormatter);

  if (props.type === 'date') {
    return {
      ...field,
      onChange: () => undefined, // @NOTE: it's handeled manually in FormInputDate.component
    };
  }

  return field;
};

export const ObservableInputElement: React.FC<FieldProps & FieldCustomProps> = ({ form, field, mask, ...props }) => {
  const { onBlur, onChange } = useInputEvent(field);

  return !isEmpty(mask) ? (
    <Cleave
      {...parseInputProps(props)}
      {...parseInputField(props, field)}
      onBlur={onBlur}
      onChange={e => { e.target.value = e.target.rawValue; onChange(e); }}
      options={mask}
    />
  ) : (
    <input
      {...parseInputProps(props)}
      {...parseInputField(props, field)}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};
