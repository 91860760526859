import { PlatformSelector } from '+shared/components';
import { Hint } from '+shared/store/sync/types/hint.interface';
import * as React from 'react';
import { AppLoaderMobile } from './AppLoader.mobile.component';
import { AppLoaderWeb } from './AppLoader.web.component';

export interface AppLoaderProps {
  hint: Hint;
}

export const AppLoader = (props: AppLoaderProps) => (
  <PlatformSelector
    mobile={<AppLoaderMobile {...props} />}
    web={<AppLoaderWeb {...props} />}
  />
);
