const crypto = window.crypto || (window as any).msCrypto;

const isOldIE = !window.crypto && !!(window as any).msCrypto;

// Old Safari support
if (crypto && !crypto.subtle && (crypto as any).webkitSubtle) {
  (crypto as any).subtle = (crypto as any).webkitSubtle;
}

const getRandomValues = crypto && crypto.getRandomValues.bind(crypto);

// Promise wrapper for IE11's CryptoOperation 🤦
const ieDigest: typeof crypto.subtle.digest = (algorithm, data) => new Promise((resolve, reject) => {
  if (!data || !data.byteLength) {
    return reject(new Error('Invalid data passed.'));
  }
  const cryptoOperation: any = crypto.subtle.digest(algorithm, data);
  cryptoOperation.onerror = (event: any) => reject(new Error(event.toString()));
  cryptoOperation.oncomplete = (event: any) => resolve(event.target.result);
});

const digest: typeof crypto.subtle.digest = crypto && (isOldIE ? ieDigest : crypto.subtle.digest.bind(crypto.subtle));

const typedArrayToString = (array: Uint8Array): string => {
  let data = '';
  for (let i = 0, l = array.length; i < l; i++) {
    data += String.fromCharCode(array[i]);
  }
  return data;
};

const stringToTypedArray = (str: string): Uint8Array => {
  const array = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    array[i] = str.charCodeAt(i);
  }
  return array;
};

const typedArrayToSafeBase64 = (array: Uint8Array): string =>
  btoa(typedArrayToString(array))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');

export const Crypto = {
  getRandomValues,
  digest,
  typedArrayToString,
  stringToTypedArray,
  typedArrayToSafeBase64,
};
