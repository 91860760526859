import { GuideGridStretched, GuideInfoCard, GuideInfoCardList } from '+app/+guide/components';
import { GuideAccordion } from '+app/+guide/containers';
import { GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StepName } from '+app/+guide/helpers/steps.config';

import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type Props =
  & RouteComponentProps
  ;

export const GuideDiscoveryStepPrepareForVisitComponent: React.FC<Props> = () => {
  const stepStatus = GuideStepStatus.ACTIVE;
  
  const content = {
    [GuideStepStatus.ACTIVE]: {
      headline: `${I18n.t(T.customerGuidance.planning.step.partnerVisitPreparation.active.headline)}`,
      tag: `${I18n.t(T.customerGuidance.tag.text)}`,
      status: 'open',
    },
  };

  return (
    <GuideAccordion
      status={stepStatus}
      anchor={StepName.PREPARE_FOR_VISIT}
      title={content[stepStatus].headline}
      tag={content[stepStatus].tag}
      tagTheme={content[stepStatus].status}
    >
      {{
        [GuideStepStatus.ACTIVE]: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.planning.step.partnerVisitPreparation.active.information.headline)}
              headerIcon={<Icon.Info />}
            >
              {I18n.t(T.customerGuidance.planning.step.partnerVisitPreparation.active.information.description)}
            </GuideInfoCard>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.planning.step.partnerVisitPreparation.active.yourToDo.headline)}
              headerIcon={<Icon.Contract />}
              isIconBig={true}
            >
              <GuideInfoCardList>
                {/* tslint:disable-next-line: max-line-length */}
                {I18n.t(T.customerGuidance.planning.step.partnerVisitPreparation.active.yourToDo.description.electricityBillsNote)}
                {/* tslint:disable-next-line: max-line-length */}
                {I18n.t(T.customerGuidance.planning.step.partnerVisitPreparation.active.yourToDo.description.constructionPlansNote)}
                {I18n.t(T.customerGuidance.planning.step.partnerVisitPreparation.active.yourToDo.description.meterNote)}
              </GuideInfoCardList>
            </GuideInfoCard>
          </GuideGridStretched>
        ),
      }}
    </GuideAccordion>
  );
};

export const GuideDiscoveryStepPrepareForVisit =
  withRouter(GuideDiscoveryStepPrepareForVisitComponent);
