import classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { socialMediaLinks } from './SocialMediaList.helper';

import './SocialMediaList.component.scss';

interface Props {
  isThemeTransparent?: boolean;
}

export const SocialMediaList: React.FC<Props> = ({
  isThemeTransparent,
}) => (
  <ul className={'c-social-media-list'}>
    {socialMediaLinks.map(item => (
      <li
        className={'c-social-media-list__list-item'}
        key={item.href}
      >
        <a
          className={classNames('c-social-media-list__item', {
            'c-social-media-list__item--shadow': isThemeTransparent,
            [`c-social-media-list__item--${item.modifier}`]: item.modifier,
          })}
          href={I18n.t(item.href)}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          {item.icon}
        </a>
      </li>
    ))}
  </ul>
);
