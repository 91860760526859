import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import { get } from 'lodash/fp';
import * as React from 'react';

import { validateField } from '+shared/helpers/form.helper';

import './FormInputRadio.component.scss';

interface Props<T, U> {
  name: string;
  label: string;
  value: U;
  form: FormikProps<T>;
  dataTestId?: string;
}

export const FormInputRadio = <T extends any = any, U extends any = any>({
  form,
  name,
  label,
  value,
  dataTestId,
}: Props<T, U>) => {
  const id = name + '-' + value;
  const validation = validateField(name, form);

  // Class names come from the shared web file: src/styles/abstracts/mixins/_radio.scss  

  return (
    <div className={'c-form-input__radio'}>
      <input
        id={id}
        className={'c-form-input__radio-radio'}
        type={'radio'}
        checked={get(name)(form.values) === value}
        onChange={() => {
          form.setFieldValue(name, value);
          form.setFieldTouched(name);
        }}
        data-test-id={dataTestId}
      />
      <label
        className={classNames('c-form-input__radio-label', {
          'has-error': validation.hasError,
        })}
        htmlFor={id}
      >
        <span className={classNames('c-form-input__radio-circle', {
          'has-error': validation.hasError,
        })} />
        {label}
      </label>
    </div>
  );
};
