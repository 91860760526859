import { StoreState } from '+app/store/store.interface';
import { QueryParamsHelper, RouterHelper } from '@sonnen/shared-web';
import { findLastIndex } from 'lodash';
import { createSelector } from 'reselect';

export const getRouterState = (state: StoreState) => state.router;

export const getRouterLocation = createSelector(
  getRouterState,
  state => state.location,
);

export const getRouterLocationSearch = createSelector(
  getRouterLocation,
  location => location.search,
);

export const getRouterLocationPath = createSelector(
  getRouterLocation,
  location => location.pathname,
);

export const getRouteQueryParams = createSelector(
  getRouterLocationSearch,
  (search) => QueryParamsHelper.getQueryParams(search),
);

export const getRouterHistory = createSelector(
  getRouterState,
  state => state.history,
);

export const getRouterAction = createSelector(
  getRouterState,
  state => state.action,
);

export const isGoBackAction = createSelector(
  getRouterAction,
  action => action === 'POP',
);

export const getLastLocationIndex = (routePath: string | string[]) => createSelector(
  getRouterHistory,
  history => {
    const index = findLastIndex(history, location => RouterHelper.matchPath(routePath)(location.pathname));
    return index < 0 ? 0 : index - (history.length - 1);
  },
);

export const getRouterLocationHash = createSelector(
  getRouterLocation,
  location => location.hash,
);
