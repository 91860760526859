import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import * as uuid from 'uuid';

import './CommunityGraphParagraphs.component.scss';

interface Props {
  paragraphs?: Record<string, string>;
}

export const CommunityGraphParagraphs: React.FC<Props> = ({
  paragraphs = I18n.t(T.sonnenCommunity.gridBalancing.description.paragraphs),
}) => (
  <>
    {Object.values(paragraphs)
      .map((paragraph: string) => (
        <DefaultParagraph
          key={uuid.v4()}
          className={'c-community-graph-paragraphs'}
        >
          {paragraph}
        </DefaultParagraph>
      ))
    }
  </>
);
