import { breakpointUp, useWindowSize } from '@sonnen/shared-web';
import classNames from 'classnames';
import * as React from 'react';

import './GuideInfoCard.component.scss';

interface Props {
  header: string;
  headerIcon?: React.ReactNode;
  isIconBig?: boolean;
  isMaxHeightOnDesktop?: boolean;
  children: React.ReactNode;
}

export const GuideInfoCard: React.FC<Props> = ({
  header,
  headerIcon,
  isIconBig,
  isMaxHeightOnDesktop = true,
  children,
}) => (
  <div className={'c-guide-info-card'}>
    <div className={'c-guide-info-card__header'}>
      {headerIcon && (
        <div className={classNames('c-guide-info-card__header-icon', {
          'c-guide-info-card__header-icon--big': isIconBig,
        })}>
          {headerIcon}
        </div>
      )}
      <div className={'c-guide-info-card__header-title'}>
        {header}
      </div>
    </div>
    <div className={classNames('c-guide-info-card__content', {
      'c-guide-info-card__content--scrollable': useWindowSize().innerWidth >= breakpointUp('SM')
        && isMaxHeightOnDesktop,
    })}>
      {children}
    </div>
  </div>
);
