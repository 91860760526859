import * as React from 'react';
import { CommunityMembers } from '../';
import { CommunityMap as CommunityWorldMap, MapData } from '../../../../features/mapLegacy';

import './CommunityMap.component.scss';

interface Props {
  userName?: string;
  userGeolocation: Array<number | null>;
  mapData?: MapData;
  recentMembersCount?: number | null;
}

export const CommunityMap: React.FC<Props> = ({
  userName,
  userGeolocation,
  mapData,
  recentMembersCount,
}) => (
  <>
      <CommunityWorldMap
        worldMapData={mapData && mapData.world}
        communityMapData={mapData && mapData.batteries}
        pinGeometry={userGeolocation}
        popupHeadline={userName}
      />
      {(!!recentMembersCount) && (
        <div className={'c-community-map__hovercard'}>
          <CommunityMembers 
            dataTestId={'sonnen-community-map-members-amount'}
            recentMembersCount={recentMembersCount} />
        </div>
      )}
    </>
);
