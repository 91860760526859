import * as React from 'react';

import { PlatformSelector } from '+components';
import { Props } from '+contract/components/ContractFlatHomeOverview';
import { CONTRACT_FLAT_OVERVIEW_PROPS_MOCK } from '+mocks/contract.mock';
import { DemoContractFlatOverviewMobile } from './DemoContractFlatOverview.mobile.component';
import { DemoContractFlatOverviewWeb } from './DemoContractFlatOverview.web.component';

export const DemoContractFlatOverview: React.FC<Props> = () => (
  <PlatformSelector
    mobile={<DemoContractFlatOverviewMobile {...CONTRACT_FLAT_OVERVIEW_PROPS_MOCK} />}
    web={<DemoContractFlatOverviewWeb {...CONTRACT_FLAT_OVERVIEW_PROPS_MOCK} />}
  />
);
