import { isEmpty, lowerCase } from 'lodash/fp';
import { createSelector } from 'reselect';

import { StoreState } from '+app/store/store.interface';
import { isBackupDeviceInstalled, isHeaterInstalled } from '+battery/store/battery.selectors';
import { QueryStatus } from '+query/query.state';
import { getArrayFromEnum } from '+utils/array.util';
import { MeasurementMethod } from '../live/types/siteLiveData.interface';
import { SiteHelper } from './site.helpers';
import { GET_SITES_QUERY, SiteState } from './site.state';
import { SiteElement, SiteOption } from './types/site.interface';

export const getSitesState = (state: StoreState) => state.site;

export const getAllSites = createSelector(
  getSitesState,
  (state: SiteState) => state.sitesList,
);

export const getActiveSite = createSelector(
  getSitesState,
  (state: SiteState) => state.sitesList.find(site => site.id === state.activeSiteId),
);

export const getActiveSiteId = createSelector(
  getSitesState,
  (state: SiteState) => state.activeSiteId,
);

export const getAllSitesOrdered = createSelector(
  [getAllSites, getActiveSite],
  (sitesList = [], activeSite) => SiteHelper.getOrderedSites(activeSite, sitesList),
);

export const getActiveSiteOptions = createSelector(
  getActiveSite,
  site => site && site.options,
);

export const getSiteAvailableElements = createSelector([
    getActiveSite,
    isBackupDeviceInstalled,
    isHeaterInstalled,
  ], (site, hasBackupBox, hasHeater) =>
    getArrayFromEnum(SiteElement)
      .map(lowerCase)
      .map(e => e as SiteElement)
      .filter(e => e === SiteElement.BATTERY ? SiteHelper.hasOption(site, SiteOption.BATTERIES) : true)
      .filter(e => e === SiteElement.CONTRACT ? SiteHelper.hasOption(site, SiteOption.CONTRACTS) : true)
      .filter(e => e === SiteElement.CHARGER ? SiteHelper.hasOption(site, SiteOption.CHARGERS) : true)
      .filter(e => e === SiteElement.BACKUP ? hasBackupBox : true)
      .filter(e => e === SiteElement.HEATER ? hasHeater : true),
);

export const isSiteWithBattery = createSelector(
  getActiveSite,
  site => SiteHelper.hasOption(site, SiteOption.BATTERIES),
);

export const isSiteWithSmartmeter = createSelector(
  getActiveSite,
  site => SiteHelper.hasOption(site, SiteOption.SMARTMETERS),
);

export const isSiteWithContract = createSelector(
  getActiveSite,
  site => SiteHelper.hasOption(site, SiteOption.CONTRACTS),
);

export const isSiteWithReadings = createSelector(
  getActiveSite,
  site => SiteHelper.hasOption(site, SiteOption.READINGS),
);

export const isSiteWithCharger = createSelector(
  getActiveSite,
  site => SiteHelper.hasOption(site, SiteOption.CHARGERS),
);

export const getSiteMeasurementMethod = createSelector(
  getActiveSite,
  site => site && site.measurementMethod,
);

export const getChargerId = createSelector(
  getActiveSite,
  site => {
    if (site && SiteHelper.hasOption(site, SiteOption.CHARGERS)) {
      return site.chargers[0];
    }

    return null;
  },
);

export const isBatteryDataMissing = createSelector(
  getSiteAvailableElements,
  getSiteMeasurementMethod,
  (elements, measurementMethod) =>
    (measurementMethod === MeasurementMethod.BATTERY || isEmpty(measurementMethod))
    && !elements.includes(SiteElement.BATTERY),
);

export const isOldSitesDataStructure = createSelector(
  getAllSites,
  sites => SiteHelper.hasSites(sites) && sites[0].hasOwnProperty('attributes'),
);

export const isSitesQueryPending = createSelector(
  getSitesState,
  sites => sites[GET_SITES_QUERY].status === QueryStatus.PENDING,
);
