import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { StateAlert } from '+shared/components';
import { useLocaleDateFormat } from '+shared/hooks/useLocaleDateFormat';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { QueryData } from '+shared/store/query/query.state';
import { InfographicCardConnectionStatusType } from '../../containers/Infographic/Infographic.types';
import { InfographicPVCardHelper } from './InfographicPVCard.helper';

type Props = {
  siteLiveData: SiteLive | undefined,
  siteLiveQuery: QueryData,
};

export const InfographicPVCardStatus: React.FC<Props> = ({
  siteLiveData,
  siteLiveQuery,
}) => {
  const timestamp = siteLiveData?.timestamp;
  const measurementsTime = timestamp ? useLocaleDateFormat(timestamp) : null;
  const status = InfographicPVCardHelper.getCardStatus(siteLiveData, siteLiveQuery);

  switch (status) {
    case InfographicCardConnectionStatusType.DATA_MISSING:
      return (
        <StateAlert
          small={true}
          icon={<Icon.Warning />}
          variant={'warning'}
        >
          {I18n.t(T.dashboard.infographic.statuses.cannotFetchData)}
        </StateAlert>
      );
    case InfographicCardConnectionStatusType.OFFLINE:
      return (
        <StateAlert
          variant={'warning'}
          small={true}
          icon={<Icon.Offline />}
        >
          {I18n.t(T.dashboard.infographic.statuses.offline,
            { date: measurementsTime },
          )}
        </StateAlert>
      );
    default:
      return (
        <StateAlert
            small={true}
            icon={<Icon.Checkmark />}
          >
            {I18n.t(T.dashboard.infographic.statuses.everythingOk)}
        </StateAlert>
      );

    /* @INFO: Commented out as API `pvReduction` is not working yet */
    /* case [add custom InfographicPVCardStatusType here]:
      return (
        <StateAlert
          variant={"warning"}
          icon={<Icon.Warning />}
          small={true}
        >
          {I18n.t(T.dashboard.infographic.statuses.powerLimitation)}
        </StateAlert>
      ) */
  }
};
