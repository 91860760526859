import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';

import { FormInputRadio } from '+shared/components';
import { validateField } from '+shared/helpers/form.helper';

import './FormInputRadioGroup.component.scss';

interface RadioInput {
  label: string;
  value: unknown;
}

interface Props<T> {
  name: string;
  title?: React.ReactNode;
  form: FormikProps<T>;
  collection: RadioInput[];
  tooltipText?: string;
  row?: boolean;
}

export const FormInputRadioGroup = <T extends any = any>({
  form,
  name,
  title,
  collection,
  row,
}: Props<T>) => {

  const validation = validateField(name, form);

  return (
    <div className={'c-form-input-radio-group'}>
      <div>
        {title}
      </div>
      <div className={classNames('c-form-input-radio-group', { 'c-form-input-radio-group--row': row })}>
        {collection.map(radioButton => (
          <div key={radioButton.label} className={'c-form-input-radio-group__input'}>
            <FormInputRadio
              form={form}
              label={radioButton.label}
              name={name}
              value={radioButton.value}
            />
          </div>
        ))}
      </div>
      {validation.hasError && (
        <div className={'c-form-input-radio-group__error-message'}>
          {validation.error}
        </div>
      )}
    </div>
  );
};
