import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { isAuthorizedRoute, isUnauthorizedRoute } from '+app/router/router.helper';
import { storeProvider } from '+app/store/store.provider';
import { Reporter } from '+app/utils/reporter.util';
import { getBatteryId } from '+battery/store/battery.selectors';
import { CONFIG } from '+config';
import { setIsReady } from '+legacy/core/app/app.actions';
import { INTEGRATION_ACTIONS, IntegrationActions } from '+mobile';
import { combineSagas } from '+utils/saga/combineSagas.util';
import { dataGuard } from '+utils/saga/dataGuard.util';
import { processQuery } from '+utils/saga/processQuery.util';
import { AUTH_ACTIONS, AuthActions } from './auth.actions';
import {
  getAuthContext,
  getLoginUrl,
  getLogoutUrl,
  getUrlAuthParams,
} from './auth.helpers';
import { AuthRepository } from './auth.repository';
import {
  getAuthorizationRetryCount,
  getAuthState,
  getCodeVerifier,
  getCurrentRoute,
  // getLastAccessedAuthUrl,
  getRefreshToken,
  hasLoggedInOnce,
  isAuthenticated,
} from './auth.selectors';
import {
  AuthContext,
  AuthParams,
  AuthSalesforceParams,
  GENERATE_REVERSE_CHANNEL_TOKEN_QUERY,
  GET_TOKENS_QUERY,
} from './auth.state';

const MAX_RETRY_COUNT = 3;

function* redirectToAuth({ isRetried }: AnyAction): SagaIterator {
  const loginUrl = yield call(getLoginUrl);
  Reporter.log('redirect to auth service', {
    isRetried,
  });
  yield call(storeProvider.flush);
  window.location.assign(loginUrl);
}

function* getAuthTokens({ code }: AnyAction): SagaIterator {
  const verifier: string = yield select(getCodeVerifier);

  if (!code || !verifier) {
    Reporter.reportError(
      new Error(`${!code && 'code'} ${!verifier && ', code_verifier'} not found`),
      { code, verifier },
    );
    return call(logOut);
  }

  yield processQuery(
    GET_TOKENS_QUERY,
    AuthRepository.getTokens,
    {
      onSuccess: res => put(AuthActions.authenticate(
        res && res.accessToken,
        res && res.refreshToken,
        res && res.tokenType,
      )),
      onFailure: (error) => {
        if (error) {
          Reporter.reportError(error, { code, verifier });
        }

        return call(logOut);
      },
    },
  )({
    code,
    verifier,
  });
}

function* rehydrated(): SagaIterator {
  const location: Location = yield select(getCurrentRoute);
  const isLoggedIn = yield select(isAuthenticated);
  const shouldRedirectToLogin = isAuthorizedRoute(location.pathname) && (yield select(hasLoggedInOnce))
    && !isUnauthorizedRoute(location.pathname);

  const authParams = getUrlAuthParams(location);
  const context = getAuthContext(authParams);
  switch (context) {
    case AuthContext.AUTH: {
      try {
        const { code } = authParams as AuthParams;
        // TODO: Uncomment and test when we want linking working
        // const redirectUrl = yield select(getLastAccessedAuthUrl);
        // if (redirectUrl) {
        //   yield put(replace(redirectUrl));
        // }
        yield put(AuthActions.logIn(code));
      } catch (error) {
        const retryCount = yield select(getAuthorizationRetryCount);
        Reporter.log(`Auth retry count: ${retryCount} out of ${MAX_RETRY_COUNT}`);
        Reporter.reportError(error, authParams);
        if (retryCount > MAX_RETRY_COUNT) {
          return yield call(logOut);
        }
        yield put(AuthActions.authenticateRetry());
        yield put(AuthActions.redirectToAuth(true));
      }
      break;
    }
    case AuthContext.SALESFORCE_PREAUTH: {
      const { userId } = authParams as AuthSalesforceParams;
      yield put(AuthActions.redirectToSalesforce(userId));
      break;
    }
    case AuthContext.SALESFORCE_AUTH: {
      try {
        const { idToken, tokenType, state } = authParams as AuthSalesforceParams;
        yield put(AuthActions.authenticateImpersonator(idToken, tokenType, JSON.parse(state).userId));
      } catch (error) {
        Reporter.reportError(error);
        yield put(AuthActions.redirectToAuth());
      }
      break;
    }
    default:
      yield put(AuthActions.authenticateStart());
      if (!isLoggedIn && shouldRedirectToLogin) {
        Reporter.reportError(new Error('Unknown AuthContext'), authParams);
        yield put(AuthActions.redirectToAuth());
      } else {
        yield put(setIsReady());
      }
      break;
  }
}

function* setReady(): SagaIterator {
  yield put(setIsReady());
}

function* logOut(): SagaIterator {
  try {
    const refreshToken = yield select(getRefreshToken);

    if (refreshToken) {
      yield call(AuthRepository.revokeToken, refreshToken);
    }
  } catch (error) {
    Reporter.reportError(error);
  } finally {
    const authStore = yield select(getAuthState);
    yield put(AuthActions.clearStore({ hasLoggedInOnce: authStore.hasLoggedInOnce }));
    yield call(storeProvider.flush);
    if (CONFIG.IS_MOBILE) {
      yield put(IntegrationActions.waitUntilNotified());
      yield race({
        task: take(INTEGRATION_ACTIONS.NOTIFIED),
        cancel: delay(5000),
      });
    }

    setTimeout(() => {
      window.location.assign(getLogoutUrl());
    }, 0);
  }
}

function* forceRefresh(): SagaIterator {
  const authStore = yield select(getAuthState);

  yield put(AuthActions.clearStore(authStore));
  yield call(storeProvider.flush);

  window.location.assign(getLogoutUrl());
}

export function* generateReverseChannelToken(): SagaIterator {
 const batteryId = yield select(getBatteryId);
 if (!batteryId) {
   return;
 }

 yield processQuery(
  GENERATE_REVERSE_CHANNEL_TOKEN_QUERY,
  AuthRepository.getReverseChannelToken,
  {
    onSuccess: (res => dataGuard(AuthActions.saveReverseChannelToken)(res && {
      token: res.token,
      expireAt: res.expireAt,
    })),
  },
 )(batteryId);
}

export const authSagas = combineSagas(
  takeEvery(AUTH_ACTIONS.REDIRECT_TO_AUTH, redirectToAuth),
  takeEvery(AUTH_ACTIONS.REDIRECT_TO_SALESFORCE, redirectToAuth),
  takeEvery(AUTH_ACTIONS.LOGIN, getAuthTokens),
  takeEvery(AUTH_ACTIONS.AUTHENTICATE, setReady),
  takeEvery(AUTH_ACTIONS.AUTHENTICATE_IMPERSONATOR, setReady),
  takeLatest(AUTH_ACTIONS.REHYDRATED, rehydrated),
  takeEvery(AUTH_ACTIONS.LOGOUT, logOut),
  takeEvery(AUTH_ACTIONS.GENERATE_REVERSE_CHANNEL_TOKEN, generateReverseChannelToken),
  takeEvery(AUTH_ACTIONS.FORCE_REFRESH, forceRefresh),
);
