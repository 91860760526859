import { ActionsUnion, createAction } from '+utils/redux';

export enum SYNC_ACTIONS {
  SYNC_START = '[Sync] SYNC_START',
  SYNC_END = '[Sync] SYNC_END',
}

export const SyncActions = {
  syncStart: () => createAction(SYNC_ACTIONS.SYNC_START),
  syncEnd: () => createAction(SYNC_ACTIONS.SYNC_END),
};

export type SyncActions = ActionsUnion<typeof SyncActions>;
