import { T } from '@sonnen/shared-i18n/customer';
import { Icon, LinkButton } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { StateAlert } from '+shared/components';
import { useLocaleDateFormat } from '+shared/hooks/useLocaleDateFormat';
import { InfographicActions, InfographicCardConnectionStatusType } from '../../containers';
import { BackupBoxStatus, InfographicBatteryCardStatusType } from './InfographicBatteryCard.helpers';

type Props = {
  batteryStatus: InfographicCardConnectionStatusType | InfographicBatteryCardStatusType,
  backupBoxStatus: BackupBoxStatus | undefined,
  timestamp: string | undefined,
  toggleModal: InfographicActions['toggleModalBackupBuffer'] | undefined,
};

const renderBatteryStatus = (
  status: InfographicCardConnectionStatusType | InfographicBatteryCardStatusType,
  timestamp: string | undefined,
) => {
  const measurementsTime = useLocaleDateFormat(timestamp);

  switch (status) {
    case InfographicCardConnectionStatusType.DATA_MISSING:
      return (
        <StateAlert
          variant={'warning'}
          small={true}
          icon={<Icon.Warning />}
        >
          {I18n.t(T.dashboard.infographic.statuses.cannotFetchData)}
        </StateAlert>
      );
    case InfographicCardConnectionStatusType.OFFLINE:
      return (
        <StateAlert
          variant={'warning'}
          small={true}
          icon={<Icon.Offline />}
        >
          {I18n.t(T.dashboard.infographic.statuses.offline,
            { date: measurementsTime },
          )}
        </StateAlert>
      );
    default:
      return (
        <StateAlert
          icon={<Icon.Checkmark />}
          small={true}
        >
          {I18n.t(T.dashboard.infographic.statuses.everythingOk)}
        </StateAlert>
      );
  }
};

const renderBackupBoxStatus = (
  status: BackupBoxStatus | undefined,
  toggleModal: InfographicActions['toggleModalBackupBuffer'] | undefined,
) => {
  switch (status) {
    case BackupBoxStatus.ACTIVE:
      return (
        <StateAlert
          icon={<Icon.Checkmark />}
          small={true}
        >
          {I18n.t(T.dashboard.infographic.statuses.backupModeActive)}
        </StateAlert>
      );
    case BackupBoxStatus.CONNECTED:
      return (
        <StateAlert
          icon={<Icon.Checkmark />}
          small={true}
        >
          <span>
            {I18n.t(T.dashboard.infographic.statuses.backupReady)}
            {!!toggleModal && (
              <>
                &nbsp;
                <LinkButton
                  dataTestId={'dashboard-battery-card-setup-backup-link'}
                  onClick={toggleModal}
                >
                  {I18n.t(T.dashboard.infographic.statuses.backupButtonLabel)}
                </LinkButton>
              </>
            )}
          </span>
        </StateAlert>
      );
    default:
      return null;
  }
};

export const InfographicBatteryCardStatus: React.FC<Props> = ({
  batteryStatus,
  backupBoxStatus,
  timestamp,
  toggleModal,
}) => {
  const measurementsTime = useLocaleDateFormat(timestamp);
  const isDelayed = batteryStatus === InfographicBatteryCardStatusType.DELAYED;
  const isOnline = batteryStatus === InfographicCardConnectionStatusType.OK || isDelayed;

  const shouldRenderBackupBox = !!backupBoxStatus && isOnline;

  return (
    <>
      {renderBatteryStatus(batteryStatus, timestamp)}
      {shouldRenderBackupBox && renderBackupBoxStatus(backupBoxStatus, toggleModal)}
      {isDelayed && measurementsTime && (
        <StateAlert
          icon={<Icon.Clock />}
          small={true}
          variant={'info'}
        >
          {I18n.t(T.dashboard.infographic.battery.delayMessage,
            { time: measurementsTime },
          )}
        </StateAlert>
      )}
    </>
  );
};
