import { T } from '@sonnen/shared-i18n/customer';
import { EnergyUnit, TimeUnit } from '@sonnen/shared-web';
import { forEach } from 'lodash';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';

import { StatisticsV1 } from '+analysis/store/types/statisticsV1.interface';
import { BatteryHelper } from '+app/+dashboard/+battery/store/battery.helpers';
import { Battery, BatteryControllerType } from '+app/+dashboard/+battery/store/types/battery.interface';
import { Contract } from '+app/+dashboard/+contract/store/types/contract.interface';
import { PMErrorCodes, PMPropertyValidationError } from '+app/shared/network/productMigration.interface';
import { VppCategory } from '+app/shared/store/meter/types/meterInstallation.interface';
import { Site } from '+app/shared/store/site/types/site.interface';
import { CurrencyUnit, OtherUnit, PeakPowerUnit, PrimitiveUnit } from '+app/utils/unit.util';
import { CreateConfigurationFormValues } from '../components/ProductMigrationCollectingDataForm/ProductMigrationCollectingDataForm.helper';
import { ProductMigrationHelper } from '../containers/ProductMigration/ProductMigration.helper';
import { CreateProductConfiguration } from '../store/types/productConfiguration.interface';
import { ValueSource } from '../utils/productMigration.util';
import {
  ProductMigrationAvailableCampaigns,
  ProductMigrationCampaignsInfo,
} from './types/productMigrationCampaigns.interface';
import { CessionContract, CessionContractAddress, CessionSignedBy } from './types/productMigrationCession.interface';

export const ECO_8_BATTERY_CONFIG = [
  { net: 2, gross: 2.1 },
  { net: 2.25, gross: 2.5 },
  { net: 4, gross: 4.2 },
  { net: 4.5, gross: 5 },
  { net: 6, gross: 6.3 },
  { net: 6.75, gross: 7.5 },
  { net: 8, gross: 8.4 },
  { net: 9, gross: 10 },
  { net: 10, gross: 10.5 },
  { net: 11.25, gross: 12.5 },
  { net: 12, gross: 12.6 },
  { net: 13.5, gross: 15 },
  { net: 14, gross: 14.7 },
  { net: 16, gross: 16.8 },
];

export const getClosestValue = (arr: number[], value: number) => (
  arr.reduce((prev, curr) => (
    Math.abs(prev - value) > Math.abs(curr - value) ? curr : prev
  ))
);

export const getClosestEco8Capacity = (capacity: number, cellType?: string | null) =>
  getClosestValue(
    ECO_8_BATTERY_CONFIG.map(battery => BatteryHelper.isCellTypeSonnenModule2500(cellType)
    ? battery.gross
    : battery.net),
  capacity);

const getBatteryCapacity = (battery: Battery) => {
  const batteryCapacity = battery.batteryCapacity || 0;
  const batteryCapacityKwh = batteryCapacity / 1000;
  const batteryCapacityKey = BatteryHelper.isCellTypeSonnenModule2500(battery.cellType) ? 'capacityGross' : 'capacityNet';
  const mappedBatteryCapacity = getClosestEco8Capacity(batteryCapacityKwh, battery.cellType);

  return {
    [batteryCapacityKey]: {
      value: mappedBatteryCapacity,
      source: ValueSource.USER,
      unit: EnergyUnit.KWH,
    },
  };
};

const mapApiKeysToKeyLabel = (key: string): [string, string] => {
  switch (key) {
    case 'dso_id.value':
      return ['dso_operator', I18n.t(T.productMigration.collectingData.form.field.dso.label)];
    case 'commissioning_date.value':
      return ['pv_or_feedin_value', I18n.t(T.productMigration.collectingData.form.field.pvOrFeedin.label)];
    default:
      return [key, key];
  }
};

export const mapFormErrorToKeyValuePairs = (message: string): Array<[string, string]> => {
  const [key] = message.split(' ', 1);
  const [mappedKey, mappedLabel] = mapApiKeysToKeyLabel(key);
  return [[mappedKey, message.replace(key, mappedLabel)]];
};

const mapPMValidationErrorCodeToMessage = (code: string, fallback: string) => {
  switch (code) {
    case PMErrorCodes.COMISSIONING_DATE_RANGE:
      return I18n.t(T.productMigration.errors.commissioningDateRange);
    default:
      return fallback;
  }
};

export const mapPMValidationErrorToKeyValuePairs = (errors: PMPropertyValidationError[]) =>
  errors.reduce((acc, { property, constraints }) => ([
    ...acc,
    ...constraints.map(({ message, errorCode }): [string, string] =>
      errorCode
        ? [property, mapPMValidationErrorCodeToMessage(errorCode, message)]
        : [property, message],
    ),
  ]), [] as Array<[string, string]>);

export const isVppCapable = (
  controllerType: BatteryControllerType | undefined,
  vppCategory: VppCategory | undefined,
): boolean => !!controllerType && !!vppCategory
  && controllerType === BatteryControllerType.SPREE
  && vppCategory !== VppCategory.LIMITED_FLEX;

export const factorizeCreateConfigurationData = (
  formValues: CreateConfigurationFormValues,
  battery: Battery | undefined,
  site: Site | undefined,
  yearlyContractStatistics: StatisticsV1 | undefined,
  vppCategory: VppCategory | undefined,
  campaigns: ProductMigrationAvailableCampaigns | undefined,
): CreateProductConfiguration => {

  const yearlyKwhConsumption = formValues.yearlyConsumption && formValues.yearlyConsumption !== 0
    ? formValues.yearlyConsumption
    : yearlyContractStatistics
      ? ProductMigrationHelper.getKwhSummedUpStatistics(yearlyContractStatistics!.consumedEnergy as number[])
      : undefined;
  const yearlyKwhProduction = formValues.yearlyProduction && formValues.yearlyProduction !== 0
    ? formValues.yearlyProduction
    : yearlyContractStatistics
      ? ProductMigrationHelper.getKwhSummedUpStatistics(yearlyContractStatistics!.producedEnergy as number[])
      : undefined;
  const specificYieldPerYear = yearlyKwhProduction && formValues.pvPeakPowerKwp
    ? Number((yearlyKwhProduction / formValues.pvPeakPowerKwp).toFixed(3))
    : undefined;
  const vppBonusValue = '65.00';
  const batteryCapacity = battery && getBatteryCapacity(battery);

  const campaignsInfo: ProductMigrationCampaignsInfo[] | undefined = campaigns?.map(campaign => ({
    value: campaign.benefit.campaign,
    unit: 'string',
    source: ValueSource.USER,
  }));

  const vppCapable = isVppCapable(battery?.controllerType, vppCategory);

  return {
      type: 'product_configuration',
      attributes: {
        powerPlant: {
          dsoId: {
            value: formValues.dsoOperator?.value || '',
            unit: PrimitiveUnit.STRING,
            source: ValueSource.USER,
          },
          vppMeteringCapable: {
            value: vppCapable,
            unit: PrimitiveUnit.BOOLEAN,
            source: ValueSource.USER,
          },
          guaranteedVppBonusGranted: vppCapable ? {
            value: true,
            unit: PrimitiveUnit.BOOLEAN,
            source: ValueSource.USER,
          } : undefined,
          guaranteedVppBonusNetPerYear: vppCapable ? {
            value: vppBonusValue,
            currency: CurrencyUnit.EUR,
            source: ValueSource.USER,
          } : undefined,
          vppBonusGuaranteeDuration: vppCapable ? {
            value: 5,
            unit: TimeUnit.YEAR,
            source: ValueSource.USER,
          } : undefined,
        },
        battery: {
          ...batteryCapacity,
          modelName: {
            value: 'eco 8',
            source: ValueSource.USER,
            unit: PrimitiveUnit.STRING,
          },
        },
        deliveryAddress: {
          city: site?.city || '',
          street: site?.street || '',
          postalCode: site?.postalCode || '',
          countryCode: site?.countryCode || '',
        },
        expectedElectricityConsumption: {
          totalConsumptionPerYear: yearlyKwhConsumption ? {
            value: Number(yearlyKwhConsumption),
            unit: EnergyUnit.KWH,
            source: ValueSource.USER,
          } : undefined,
          heatPump: formValues.heatPump ? {
            totalConsumptionPerYear: {
              value: 0,
              unit: EnergyUnit.KWH,
              source: ValueSource.USER,
            },
          } : undefined,
          electricCar: formValues.electricCar ? {
            totalConsumptionPerYear: {
              value: 0,
              unit: EnergyUnit.KWH,
              source: ValueSource.USER,
            },
          } : undefined,
        },
        photovoltaicSystem: {
          commissioningDate: formValues.pvOrFeedinValue ? {
            value: formValues.pvOrFeedinValue,
            unit: OtherUnit.DATE,
            source: ValueSource.USER,
          } : undefined,
          systemKey: formValues.withoutSystemKey ? undefined : {
            value: formValues.systemKey || '',
            unit: PrimitiveUnit.STRING,
            source: ValueSource.USER,
          },
          specificYieldPerYear: specificYieldPerYear ? {
            value: specificYieldPerYear,
            unit: EnergyUnit.KWH_PER_KWP,
            source: ValueSource.USER,
          } : undefined,
          peakPower: {
            value: Number(formValues.pvPeakPowerKwp.toFixed(3)),
            unit: PeakPowerUnit.KWP,
            source: ValueSource.USER,
          },
        },
        ...(campaignsInfo && { campaigns: campaignsInfo }),
      },
    };
};

type DeepPartial<T> = T extends object ? {
  [Property in keyof T]: undefined | null | DeepPartial<T[Property]>;
} : T;

type MaybeCessionContract = DeepPartial<CessionContract>;

export const isContractDataValid = (cessionContract: MaybeCessionContract): cessionContract is CessionContract => {
  if (
    !!cessionContract.batteryAsset
    && !!cessionContract.existingContractNumber
    && !!cessionContract?.signedBy?.contactId
    && !!cessionContract.supply?.data?.meterId
    && !!cessionContract.supply.address?.street
    && !!cessionContract.supply.address.postalCode
    && !!cessionContract.supply.address.city
    && !!cessionContract.supply.address.countryCode
    && !!cessionContract.billing?.address?.street
    && !!cessionContract.billing.address.postalCode
    && !!cessionContract.billing.address.city
    && !!cessionContract.billing.address.countryCode
  ) {
    return true;
  }

  return false;
};

export const getContractDataValidationErrors = (cessionContract: MaybeCessionContract): string[] => {
  const errors: string[] = [];

  const flatData = {
    batteryAsset: cessionContract.batteryAsset,
    existingContractNumber: cessionContract.existingContractNumber,
    contactId: cessionContract.signedBy?.contactId,
    meterId: cessionContract?.supply?.data?.meterId,
    supplyStreet: cessionContract?.supply?.address?.street,
    supplyPostalCode: cessionContract?.supply?.address?.postalCode,
    supplyCity: cessionContract?.supply?.address?.city,
    supplyCountryCode: cessionContract?.supply?.address?.countryCode,
    billingStreet: cessionContract?.billing?.address?.street,
    billingPostalCode: cessionContract?.billing?.address?.postalCode,
    billingCity: cessionContract?.billing?.address?.city,
    billingCountryCode: cessionContract?.billing?.address?.countryCode,
  };

  forEach(flatData, (val, key) => {
    if (!(typeof val === 'string' ? val.trim() : val)) {
      errors.push(`${key} is missing from contractData`);
    }
  });

  return errors;
};

export const getContractSupplyAddress = (contract: Contract): CessionContractAddress => ({
  street: contract.shippingStreet ? `${contract.shippingStreet} ${contract.shippingStreetNumber}` : '',
  postalCode: contract.shippingPostalCode,
  city: contract.shippingCity,
  countryCode: contract.shippingCountryCode || contract.billingCountryCode,
});

export const getContractBillingAddress = (contract: Contract): CessionContractAddress => ({
  street: contract.billingStreet ? `${contract.billingStreet} ${contract.billingStreetNumber}` : '',
  postalCode: contract.billingPostalCode,
  city: contract.billingCity,
  countryCode: contract.billingCountryCode,
});

export const getContactSignedBy = (contactId: string): CessionSignedBy => ({
  contactId,
  date: moment().format('YYYY-MM-DD'),
});
