import { LayoutContext } from '+app/shared/components/Layout/Layout.context';
import { GuidePreviewStep } from '+guide/components';
import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './GuideSetupPreview.component.scss';

import bulb from '+images/guide/bulb.svg';
import installation from '+images/guide/installation.svg';
import register from '+images/guide/register.svg';
import wave from '+images/guide/waved-line.svg';

export const GuideSetupPreview: React.FC = () => {
  const layout = React.useContext(LayoutContext);

  React.useEffect(() => {
    layout.setGrayBackground(true);

    return () => {
      layout.setGrayBackground(false);
    };
  }, []);

  return (
    <>
      <div className={'c-guide-setup-preview__subheadline'}>
        <DefaultParagraph>
          {I18n.t(T.customerGuidance.setup.preview.description)}
        </DefaultParagraph>
      </div>
      <div className={'c-guide-setup-preview__steps'} >
        <GuidePreviewStep
          icon={register}
          title={I18n.t(T.customerGuidance.setup.preview.registration)}
          subtitle={I18n.t(T.customerGuidance.setup.preview.registrationDescription)}
        />
        <img src={wave} className={'c-guide-setup-preview__wave'} />
        <GuidePreviewStep
          icon={installation}
          title={I18n.t(T.customerGuidance.setup.preview.installation)}
          subtitle={I18n.t(T.customerGuidance.setup.preview.installationDescription)}
        />
        <img src={wave} className={'c-guide-setup-preview__wave c-guide-setup-preview__wave--reverse@md'} />
        <GuidePreviewStep
          icon={bulb}
          title={I18n.t(T.customerGuidance.setup.preview.energy)}
          subtitle={I18n.t(T.customerGuidance.setup.preview.energyDescription)}
        />
      </div>
    </>
  );
};
