import { LegalDocuments, LegalDocumentType } from '+shared/store/legal/types/legalDocuments.interface';
import { CountryCode } from '@sonnen/shared-web';

const filterDocumentsByLang = (docs: LegalDocuments, lang: string | undefined): LegalDocuments =>
  docs.filter(document => lang?.includes(document.language));

const filterDocumentsByCountry = (docs: LegalDocuments, country: CountryCode | undefined): LegalDocuments =>
  docs.filter(document => country?.includes(document.country));

const getBestMatchingDocument = (
  legalDocuments: LegalDocuments,
  lang: string | undefined,
  country: CountryCode | undefined,
) => {
  const matchingTranslation = filterDocumentsByLang(legalDocuments, lang);

  if (matchingTranslation.length) {
    return matchingTranslation[0];
  }

  const matchingCountry = filterDocumentsByCountry(legalDocuments, country);

  if (matchingCountry.length) {
    return matchingCountry[0];
  }

  return undefined;
};

const filterPrivacyPolicy = (legalDocuments: LegalDocuments) => {
  return legalDocuments && legalDocuments.filter(({ kind }) => kind === LegalDocumentType.PRIVACY_POLICY);
};

const getPrivacyPolicy = (
  legalDocuments: LegalDocuments | undefined,
  lang: string | undefined,
  country: CountryCode | undefined,
) => legalDocuments && getBestMatchingDocument(
  filterPrivacyPolicy(legalDocuments),
  lang,
  country,
);

const filterTermsAndConditions = (legalDocuments: LegalDocuments) => {
  return legalDocuments && legalDocuments.filter(({ kind }) => kind === LegalDocumentType.CP_TERMS);
};

const getTermsAndConditions  = (
  legalDocuments: LegalDocuments | undefined,
  lang: string | undefined,
  country: CountryCode | undefined,
) => legalDocuments && getBestMatchingDocument(
  filterTermsAndConditions(legalDocuments),
  lang,
  country,
);

export const LegalDocumentsHelper = {
  getPrivacyPolicy,
  getTermsAndConditions,
};
