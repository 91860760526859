import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

import { Hint } from './types/hint.interface';

export const getRandomHint = (seed?: number): Hint => {
  const hintSeed = seed || Math.random();
  const hints: Hint[] = Object.values(I18n.t(T.hints._all)) as any;
  const randomIndex = Math.floor(hintSeed * hints.length);

  return hints.length
    ? hints[randomIndex]
    : {
      icon: '',
      headline: '',
      description: '',
    };
};
