import { getActivePhase, getDonePhases } from '+app/+guide/+overview/store/+overview.selectors';
import { getPaths } from '+app/router/router.helper';
import { StoreState } from '+app/store/store.interface';
import { upperFistLetter } from '+guide/helpers/Guide.helper';
import { getRouterLocationPath } from '+router/store/router.selectors';
import { mapActions } from '+utils/redux';
import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { push } from 'connected-react-router';
import { last } from 'lodash';
import { values } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';

import './GuideStepper.component.scss';

const mapStateToProps = (state: StoreState) => ({
  activePhase: getActivePhase(state),
  donePhases: getDonePhases(state),
  pathname: getRouterLocationPath(state),
});

const mapDispatchToProps = mapActions({
  goToPurchase: () => push(getPaths().GUIDE_PURCHASE),
  goToSetup: () => push(getPaths().GUIDE_SETUP),
  goToUsage: () => push(getPaths().GUIDE_USAGE),
});

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  ;

export const GuideStepperComponent: React.FC<Props> = ({
  pathname,
  actions,
  activePhase,
  donePhases,
}) => {
  const links = values(actions);
  const phases = [
    getPaths().GUIDE_PURCHASE,
    getPaths().GUIDE_SETUP,
    getPaths().GUIDE_USAGE,
  ];

  return (
    <div className={'c-guide-stepper'}>
      {phases.map((phase, index) => {
        const phaseName = last(phase.split('/')) as string;
        const isPhaseActive = (phaseName === activePhase);
        const isPhaseDone = (donePhases.includes(phaseName));

        return (
          <div
            className={classNames('c-guide-stepper__phase', {
              'is-active': isPhaseActive,
              'is-done': isPhaseDone,
            })}
            key={`${phase}_${index}`}
          >
            <button
              className={'c-guide-stepper__button'}
              onClick={links[index]}
            >
              <div className={classNames('c-guide-stepper__icon-wrapper', {
                'c-guide-stepper__icon-wrapper--active': isPhaseActive,
                'c-guide-stepper__icon-wrapper--with-icon': isPhaseDone,
              })}>
                {isPhaseDone
                  ? <Icon.Checkmark className={'c-guide-stepper__icon'} />
                  : ''
                }
              </div>
              <div className={classNames('c-guide-stepper__phase-name', {
                'is-active': pathname.indexOf(phases[index]) > -1,
              })}>
                {upperFistLetter(phaseName)}
              </div>
            </button>
            {index === phases.length
              ? ''
              : (
                <div className={classNames('c-guide-stepper__progress-bar', {
                  'c-guide-stepper__progress-bar--active': isPhaseDone,
                })} />
              )
            }
          </div>
        );
      })}
    </div>
  );
};

export const GuideStepper = connect(mapStateToProps, mapDispatchToProps)(GuideStepperComponent);
