import { getPrimaryFlatOfferDocumentStatus } from '+app/+guide/+purchase/store/+offer.selectors';
import { GuideActions } from '+app/+guide/store/+guide.actions';
import { getDocumentUrl } from '+app/+guide/store/+guide.selectors';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { DownloadLink } from '+components';
import { Document, DocumentType } from '+guide/store/types';
import { T } from '@sonnen/shared-i18n/customer';
import { ModalDocumentDownload } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './GuidePurchaseHardwareOfferFooter.component.scss';

const mapStateToProps = (state: StoreState, { offerId }: ComponentProps) => ({
  documentUrl: getDocumentUrl(state),
  documentStatus: getPrimaryFlatOfferDocumentStatus(state)({ offerId }),
});

const mapDispatchToProps = mapActions({
  getDocumentFile: GuideActions.getHardwareOfferFile,
  clearDocumentUrl: GuideActions.clearDocumentUrl,
});

interface ComponentProps {
  offerId: string;
  hardwareOffers: Document[];
}

type Props =
  & ComponentProps
  & ReturnType<typeof mapDispatchToProps>
  & ReturnType<typeof mapStateToProps>
  ;

const GuidePurchaseHardwareOfferFooterComponent: React.FC<Props> = ({
  offerId,
  hardwareOffers,
  actions,
  documentStatus,
  documentUrl,
}) => {
  const [isDownloadModalOpen, toggleDownloadModal] = React.useState(false);

  const onDownloadClick = ( hwOfferId: string ) => {
    toggleDownloadModal(true);
    actions.getDocumentFile(
      offerId,
      hwOfferId,
    );
  };

  const onDownloadModalClose = () => {
    actions.clearDocumentUrl();
    toggleDownloadModal(false);
  };

  return (
    <div className={'c-guide-purchase-hardware-offer-footer'}>
      <div>
        {hardwareOffers.map(hwOffer => (
          <>
            <DownloadLink
              key={`hwo-${hwOffer.id}`}
              title={hwOffer.fileName}
              onDownload={() => onDownloadClick(hwOffer.id)}
            />
            <ModalDocumentDownload
              isOpen={isDownloadModalOpen}
              status={documentStatus!}
              onModalClose={onDownloadModalClose}
              loadingText={I18n.t(T.customerGuidance.documents.statusMessage.pending)}
              errorText={I18n.t(T.customerGuidance.documents.statusMessage.failed)}
              linkText={I18n.t(T.customerGuidance.documents.statusMessage.created.clickHere)}
              createdText={'to open and download hardware offer document'} // @TODO i18n
              fileUrl={documentUrl!}
            />
          </>
        ))}
      </div>
    </div>
  );
};

export const GuidePurchaseHardwareOfferFooter =
  connect(mapStateToProps, mapDispatchToProps)(GuidePurchaseHardwareOfferFooterComponent);
