import {
  AnalysisChartCrosshair,
  AnalysisLiveBubbles,
  AnalysisTooltip,
  AnalysisTooltipMeasurementItem, EnergyFlowSeriesKey,
  factorizeTooltipDate,
  prepareTooltipData,
} from '@sonnen/shared-web';
import * as React from 'react';

import { LINE_CHART_HEIGHT } from '+analysis/components/AnalysisAreaChart/AnalysisAreaChart.mobile.layout';
import { BatteryHelper } from '+app/+dashboard/+battery/store/battery.helpers';
import { MeasurementMethod } from '+app/shared/store/live/types/siteLiveData.interface';
import { AnalysisAreaChart, AnalysisBarChart } from '../';
import { useAnalysisBarChart } from '../../containers/AnalysisKanvaProvider/AnalysisBarChartProvider';
import { useAnalysisDayChart } from '../../containers/AnalysisKanvaProvider/AnalysisDayChartProvider';
import { useAnalysis } from '../../containers/AnalysisProvider/AnalysisProvider.context';
import { CHART_HEIGHT, getVisibleLiveBubblesData, mobileScaleOptions } from '../../helpers/analysis.helper';
import { chartTooltipStyles, getVisibleDataSeriesKeys, seriesKeyTranslationMap } from '../../helpers/tooltip.helper';
import { KanvaHelper } from '../../kanva/kanva.helper';
import { areLiveBubblesDisabled, isPeriodDay, visibleStatisticsSeriesKeys } from '../../store/analysis.helpers';
import { AnalysisTooltipChartWrapper } from '../AnalysisTooltipChartWrapper';

import './AnalysisEnergyFlowChart.mobile.component.scss';

export const AnalysisEnergyFlowChartMobile: React.FC = () => {
  const {
    commonData: {
      areaChartSeries,
      batteryData,
      measurementsMethod,
      selectedDate,
      selectedDataSeriesKeys,
      siteLiveData,
      statisticsSelectedDate,
      summerTimeChange,
    },
    query: {
      measurementsQueryStatus,
      statisticsQueryStatus,
    },
  } = useAnalysis();
  const {
    chartView,
    currentXPosition: dayChartCurrentXPosition,
    extensions: dayChartExtensions,
    lastXYSeriesPosition,
    primarySeries: dayChartPrimarySeries,
    tooltipEvent: dayChartTooltipEvent,
    tooltipValues: dayChartTooltipValues,
  } = useAnalysisDayChart();
  const {
    currentXPosition: barChartCurrentXPosition,
    extensions: barChartExtensions,
    primarySeries: barChartPrimarySeries,
    tooltipEvent: barChartTooltipEvent,
    tooltipValues: barChartTooltipValues,
  } = useAnalysisBarChart();

  const isDayPeriodSelected = isPeriodDay(statisticsSelectedDate.period);
  const tooltipEvent = isDayPeriodSelected
    ? dayChartTooltipEvent
    : barChartTooltipEvent;
  const tooltipValues = isDayPeriodSelected
    ? dayChartTooltipValues
    : barChartTooltipValues;
  const tooltipExtension = isDayPeriodSelected
    ? dayChartExtensions?.tooltip
    : barChartExtensions?.tooltip;
  const currentXPosition = isDayPeriodSelected
    ? dayChartCurrentXPosition
    : barChartCurrentXPosition;
  const primarySeries = isDayPeriodSelected
    ? dayChartPrimarySeries
    : barChartPrimarySeries;

  const isEaton = BatteryHelper.isEaton(batteryData);
  const isMK1 = measurementsMethod === MeasurementMethod.METER_GRID;
  const chartEnhancementsVisibility = isPeriodDay
    ? measurementsQueryStatus.success
    : statisticsQueryStatus.success;
  const visibleDataSeriesKeys = getVisibleDataSeriesKeys(
    selectedDate,
    tooltipValues,
    selectedDataSeriesKeys,
  );
  const liveBubblesData = getVisibleLiveBubblesData(
    lastXYSeriesPosition,
    siteLiveData,
    selectedDate,
    isEaton,
    isMK1,
    measurementsQueryStatus.pending,
    chartView,
  );
  const mobileAreaChartHeight = visibleDataSeriesKeys.includes('batteryCare' as EnergyFlowSeriesKey)
    ? CHART_HEIGHT + LINE_CHART_HEIGHT
    : CHART_HEIGHT;

  return (
    <AnalysisTooltipChartWrapper
      className={'m-c-analysis-energy-flow-chart'}
      tooltipEvent={tooltipEvent}
      chartView={chartView}
      tooltipExtension={tooltipExtension}
      isNoDataDisplayed={!visibleDataSeriesKeys.length}
    >
      <div className={'m-c-analysis-energy-flow-chart__inner'}>
        <AnalysisTooltip
          isVisible={chartEnhancementsVisibility}
          header={factorizeTooltipDate(
            statisticsSelectedDate.period,
            KanvaHelper.getSeriesXFromTooltipValues(primarySeries, tooltipValues),
            selectedDate,
            statisticsSelectedDate.date,
          )}
          type={'horizontal'}
        >
          {prepareTooltipData(
            tooltipValues,
            seriesKeyTranslationMap,
            isDayPeriodSelected
              ? visibleDataSeriesKeys
              : visibleStatisticsSeriesKeys,
            chartTooltipStyles,
          ).map(props => (
            <AnalysisTooltipMeasurementItem
              key={props.seriesKey}
              {...props}
            />
          ))}
        </AnalysisTooltip>
        {isDayPeriodSelected ? (
          <>
            <AnalysisAreaChart
              chartHeight={mobileAreaChartHeight}
              scaleOptions={mobileScaleOptions}
            />
            {!areLiveBubblesDisabled(areaChartSeries, summerTimeChange) && (
              <AnalysisLiveBubbles
                data={liveBubblesData}
              />
            )}
          </>
        ) : (
          <AnalysisBarChart
            chartHeight={CHART_HEIGHT}
          />
        )}
        <AnalysisChartCrosshair
          crosshairPosition={currentXPosition}
          height={mobileAreaChartHeight}
          isVisible={chartEnhancementsVisibility}
        />
      </div>
    </AnalysisTooltipChartWrapper>
  );
};
