import { InfoBanner, InfoBannerProps } from '@sonnen/shared-web';
import * as React from 'react';

import './GuidePurchaseInfoBanner.component.scss';

type Props = InfoBannerProps;

export const GuidePurchaseInfoBanner: React.FC<Props> = ({
  icon,
  title,
  subtitle,
  isNoIconGap,
  isThemeCard,
}) => (
  <div className={'c-guide-purchase-info-banner'}>
    <InfoBanner
      icon={icon}
      title={title}
      subtitle={subtitle}
      isNoIconGap={isNoIconGap}
      isThemeCard={isThemeCard}
    />
  </div>
);
