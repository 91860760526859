import {
  BodyParser,
  ContentTypeMap,
  getBoundaryFromContentTypeHeader,
  getCaseConverter,
  HttpResponse,
  HttpResponseError,
  processMultipartBody,
  RawHttpResponse,
  switchContentType,
  urlDecode,
} from '@coolio/http';
import { isArray, isPlainObject } from 'lodash';
import * as moment from 'moment';

import { CONFIG } from '+config';
import { ResponseError } from './network.interface';
import { PMValidationError } from './productMigration.interface';

export const isResponseError = (error: any): error is ResponseError => !!error?.response;
// TODO remove the above when not used anymore.
export const isCoolioResponseError = (error: any): error is HttpResponseError => !!error.response;
export const isAuthorizedDomain = (host: string) => CONFIG.AUTHORIZED_DOMAINS.some((domain) => host.endsWith(domain));
export const isPMValidationError = (err: PMValidationError | any): err is PMValidationError => err.source === 'ValidationFilter';

// NOTE: this is implementation of coolio's regular bodyParser but it bypasses keys that are valid dates
export const splitWords = (text: string): string[] => {
  const words = (
    text.toUpperCase() === text
      ? text.split(/(?:[ _-]+)/)
      : text.split(/(?:[ _-]+)|(?=[A-Z]+)/)
  ).filter(Boolean);
  // If the text
  return words.length === 0 ? [text] : words;
};

export const deepKeyMap = (object: any, mapper: (key: string) => string): any => {
  if (isArray(object)) {
    return object.map(value => deepKeyMap(value, mapper));
  }
  if (isPlainObject(object)) {
    const result = {};
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        result[mapper(key)] = deepKeyMap(object[key], mapper);
      }
    }
    return result;
  }
  return object;
};

const toCamelCase = (object: any) => deepKeyMap(object, key => moment(key).isValid() ? key : splitWords(key)
  .map((word, index) => index > 0
    ? word[0].toUpperCase() + word.slice(1).toLowerCase()
    : word.toLowerCase())
  .join(''));

const getContentType = (rawResponse: RawHttpResponse) => (rawResponse.headers.get('content-type') || '')
  .split(';')
  .map(part => part.trim().toLowerCase())
  .filter(Boolean);

const bodyParsers: ContentTypeMap<BodyParserImplementation> = {
  TEXT: raw => raw.text(),
  JSON: raw => raw.text()
    .then(body => body ? toCamelCase(JSON.parse(body)) : null)
    .catch((err) => {
      throw new Error(`Response body that was passed to bodyParser is invalid. ${err}`);
    }),
  MULTIPART: raw => raw.text()
    .then(body => processMultipartBody(body, getBoundaryFromContentTypeHeader(getContentType(raw)))),
  URL_ENCODED: raw => raw.text()
    .then(urlDecode)
    .then(getCaseConverter),
};

type BodyParserImplementation = (rawResponse: RawHttpResponse) => Promise<any>;

export const customBodyParser: BodyParser<any> = (rawResponse: RawHttpResponse): HttpResponse => {
  const contentType = getContentType(rawResponse)[0];
  const parsedBody = switchContentType(contentType, bodyParsers, raw => raw.arrayBuffer());

  return {
    ...rawResponse,
    parsedBody: () => parsedBody(rawResponse),
  };
};
