import { useResizeObserver } from '@sonnen/shared-web';
import * as React from 'react';

export const useCollapsibleContent = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [contentHeight, setHeight] = React.useState('0');
  const contentRef = React.useRef<HTMLDivElement>(null);
  const resizeObserver = useResizeObserver<HTMLDivElement>();

  React.useEffect(() => {
    if (contentRef.current) {
      const getComputedStyle = window.getComputedStyle(contentRef.current);
      const height = isOpen ? getComputedStyle.height : '0';

      setHeight(height);
    }

  }, [isOpen, resizeObserver.width]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return {
    contentHeight,
    contentRef,
    isOpen,
    resizeObserver,
    toggle,
  };
};
