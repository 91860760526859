import { queryReducer } from '+app/shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { GUIDE_SETUP_ACTIONS, GuideSetupActions } from './+setup.actions';
import {
  GuideSetupModalType,
  GuideSetupState,
  initialState,
  SET_SETUP_STEP_QUERY,
} from './+setup.state';

export const reducer = createReducer<GuideSetupState, GuideSetupActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case GUIDE_SETUP_ACTIONS.TOGGLE_MODAL:
        return {
          ...state,
          activeModal: action.checked ? GuideSetupModalType.UNCHECKED : GuideSetupModalType.CHECKED,
        };
      case GUIDE_SETUP_ACTIONS.CLOSE_MODAL:
        return {
          ...state,
          isSetupModalOpen: false,
        };
      case GUIDE_SETUP_ACTIONS.CHECK_TAX_OFFICE_REGISTRATION:
        return {
          ...state,
          isSetupModalOpen: true,
        };
      case GUIDE_SETUP_ACTIONS.CHECK_BNETZA_REGISTRATION:
        return {
          ...state,
          isSetupModalOpen: true,
        };
      default:
        return queryReducer(state, action, [
          SET_SETUP_STEP_QUERY,
        ]);
    }
  },
  {
    persist: {
      key: 'setup',
      blacklist: [SET_SETUP_STEP_QUERY],
    },
  },
);
