import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, ButtonTheme, Link, Loader, MediaCard } from '@sonnen/shared-web';
import { WizardContext } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { CessionStatus } from '+app/+guide/store/types';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { GuideActions } from '+guide/store/+guide.actions';
import {
  getCessionDocUrl,
  getCessionDocUrlQueryStatus,
  getCessionFailed,
  getCessionStatus,
  getCessionTimestamps,
} from '+guide/store/+guide.selectors';
import { CessionAccordionList, CessionDocument, CessionImageTutorial, CessionInfo } from '+shared/components';
import { GuideAcceptanceBackButton } from '../../components';

import './GuideAcceptanceCessionSign.component.scss';

const mapStateToProps = (state: StoreState) => ({
  cessionDocUrlQueryStatus: getCessionDocUrlQueryStatus(state),
  cessionDocUrl: getCessionDocUrl(state),
  cessionStatus: getCessionStatus(state),
  cessionFailed: getCessionFailed(state),
  cessionTimestamps: getCessionTimestamps(state),
});

const mapDispatchToProps = mapActions({
  startCessionDocumentPolling: GuideActions.startCessionDocumentPolling,
  stopCessionDocumentPolling: GuideActions.stopCessionDocumentPolling,
  startCessionStatusPolling: GuideActions.startCessionStatusPolling,
  stopCessionStatusPolling: GuideActions.stopCessionStatusPolling,
  getCessionStatus: GuideActions.getCessionStatus,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

export const GuideAcceptanceCessionSignComponent: React.FC<Props> = ({
  cessionDocUrl,
  cessionStatus,
  cessionDocUrlQueryStatus,
  actions,
  cessionFailed,
  cessionTimestamps,
}) => {
  const wizard = React.useContext(WizardContext);
  const cessionSignedDate = moment(cessionTimestamps.signed, 'yyyy-mm-dd HH:mm:ss');
  // we assume cession was previously signed if its timestamp with status `signed` is older than 30 min from now
  const isCessionAlreadySigned = moment().diff(cessionSignedDate, 'minutes') > 30;

  React.useEffect(() => {
    actions.getCessionStatus();

    return () => {
      actions.stopCessionDocumentPolling();
      actions.stopCessionStatusPolling();
    };
  }, []);

  React.useEffect(() => {
    if (cessionStatus === CessionStatus.PENDING) {
      actions.startCessionDocumentPolling();
    }
    if (cessionStatus === CessionStatus.NEW && wizard.next) {
      wizard.next();
    }
  }, [cessionStatus]);

  React.useEffect(() => {
    if (cessionDocUrl) {
      actions.stopCessionDocumentPolling();
      actions.startCessionStatusPolling();
    }
  }, [cessionDocUrl]);

  React.useEffect(() => {
    if (cessionStatus && [CessionStatus.SIGNED, CessionStatus.REJECTED].includes(cessionStatus)) {
      actions.stopCessionStatusPolling();
    }
  }, [cessionStatus]);

  React.useEffect(() => {
    if ((cessionFailed || isCessionAlreadySigned) && wizard.next) {
      wizard.next();
    }
  }, [cessionFailed, isCessionAlreadySigned]);

  const isCessionContentReady = cessionDocUrlQueryStatus.success && cessionStatus && cessionDocUrl;

  // @TODO: Remove customerGuidance.acceptance.cessionDoc.infoHeader translation
  // @TODO: Remove customerGuidance.acceptance.cessionDoc.infoText translation

  return (
    <div>
      <MediaCard
        footerContent={
          <div className={'c-guide-cession-sign__footer'}>
            <Button
              label={I18n.t(T.customerGuidance.acceptance.cessionDoc.buttonCta)}
              size={ButtonSize.SECONDARY}
              theme={ButtonTheme.PRIMARY}
              onClick={() => wizard.next && wizard.next()}
              isDisabled={cessionStatus === CessionStatus.PENDING || isNil(cessionStatus)}
            />
          </div>
        }
      >
        {isCessionContentReady ? (
          <div className={'c-guide-cession-sign'}>
            <div className={'o-grid'}>
              <div className={'o-grid__column o-grid__column--md-6 o-grid__column--no-gap'}>
                <p className={'c-guide-cession-sign__headline'}>
                  {I18n.t(T.customerGuidance.acceptance.cessionDoc.section1.headline)}
                </p>
                <p className={'c-guide-cession-sign__text'}>
                  {I18n.t(T.customerGuidance.acceptance.cessionDoc.section1.copy)}
                </p>
                <p className={'c-guide-cession-sign__headline c-guide-cession-sign__headline--small'}>
                  {I18n.t(T.customerGuidance.acceptance.cessionDoc.section2.headline)}
                </p>
                <p className={'c-guide-cession-sign__text'}>
                  {I18n.t(T.customerGuidance.acceptance.cessionDoc.section2.copy)}
                </p>
              </div>
              {cessionDocUrl && (
                <div className={'o-grid__column o-grid__column--md-6 o-grid__column--no-gap'}>
                  <aside className={'c-guide-cession-sign__help'}>
                    <CessionInfo url={cessionDocUrl} />
                  </aside>
                </div>
              )}
            </div>
            <div className={'o-grid'}>
              <div className={'o-grid__column o-grid__column--md-6 o-grid__column--no-gap'}>
                <div className={'c-guide-cession-sign__tutorial'}>
                  <CessionImageTutorial />
                </div>
              </div>
            </div>
            {cessionDocUrl && (
              <div className={'c-guide-cession-sign__document'}>
                <CessionDocument url={cessionDocUrl} />
              </div>
            )}
            <div className={'o-grid'}>
              <div className={'o-grid__column o-grid__column--md-7 o-grid__column--no-gap'}>
                <CessionAccordionList />
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </MediaCard>
      <GuideAcceptanceBackButton />
    </div>
  );
};

export const GuideAcceptanceCessionSign = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuideAcceptanceCessionSignComponent);
