import { RouterHelper } from '@sonnen/shared-web';
import { connectRouter, LocationChangeAction } from 'connected-react-router';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { findLastIndex } from 'lodash';
import { initialState, RouterState } from './router.state';

const HISTORY_SIZE_LIMIT = 100;
const POP_ACTION = 'POP';

export const history = process.env.NODE_ENV === 'test'
  ? createMemoryHistory({ initialEntries: ['/'] })
  : createBrowserHistory();

const routerReducer = connectRouter(history);

const getInitialState = (): RouterState => ({
  ...routerReducer(undefined, { type: '' } as any),
  ...initialState,
});

export const reducer = (state: RouterState = getInitialState(), action: LocationChangeAction) => {
  if (action && action.payload && action.payload.action === POP_ACTION && state.history.length === 0) {
    action.payload.action = 'PUSH';
  }

  const newState: RouterState = routerReducer(state, action) as RouterState;
  if (
    newState !== state &&
    (!state.history.length || !RouterHelper.locationEquals(newState.location, state.location))
  ) {
    const popIndex = action.payload.action === POP_ACTION
      ? findLastIndex(newState.history, location => RouterHelper.locationEquals(location, newState.location))
      : -1;
    const lastIndex = popIndex < 0 ? state.history.length : popIndex;
    const firstIndex = Math.max(0, lastIndex + 1 - HISTORY_SIZE_LIMIT);
    newState.history = state.history.slice(firstIndex, lastIndex);
    newState.history.push(newState.location);
    newState.previousAction = state.action;
  }
  return newState;
};
