const RESET_PASSWORD_FORM = 'reset_password';
const RESEND_INVITATION_FORM = 'resendInvitation';
const ENERGY_TAX_FORM = 'ENERGY_TAX_FORM';
const ONBOARDING_PERSONAL_DETAILS_FORM = 'onboardingPersonalDetailsForm';
const REQUEST_RESET_PASSWORD_FORM = 'requestResetPasswordForm';
const ONBOARDING_VERIFICATION_FORM = 'onboardingVerificationForm';
const CHANGE_PASSWORD_FORM = 'changePasswordForm';
const SIGNUP_FORM = 'signupForm';

export const FormNames = {
  RESET_PASSWORD_FORM,
  RESEND_INVITATION_FORM,
  ENERGY_TAX_FORM,
  ONBOARDING_PERSONAL_DETAILS_FORM,
  REQUEST_RESET_PASSWORD_FORM,
  ONBOARDING_VERIFICATION_FORM,
  CHANGE_PASSWORD_FORM,
  SIGNUP_FORM,
};
