import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, EnergyUnit } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { InfographicCardConnectionStatusType } from '../../containers/Infographic/Infographic.types';
import { InfographicValue } from '../InfographicValue';

type Props = {
  status: InfographicCardConnectionStatusType,
  isMK1: boolean;
  consumptionValue: number | '-',
};

export const InfographicConsumptionCardValue: React.FC<Props> = ({
  status,
  isMK1,
  consumptionValue,
}) => {
  const isOffline = status === InfographicCardConnectionStatusType.OFFLINE;
  const isNoDataError = status === InfographicCardConnectionStatusType.DATA_MISSING;

  return (
    <>
      {isOffline ? (
        <div className={'c-infographic-battery-card__text'}>
          <DefaultParagraph>
            {I18n.t(T.dashboard.infographic.battery.offlineMessage)}
          </DefaultParagraph>
        </div>
      ) : (
        <InfographicValue
          title={isMK1
            ? I18n.t(T.dashboard.infographic.consumption.headlineMk1)
            : I18n.t(T.dashboard.infographic.consumption.headline)
          }
          icon={'House'}
          value={consumptionValue}
          unit={EnergyUnit.KW}
          isThemeInactive={isNoDataError}
        />
      )}
    </>
  );
};
