import { SagaIterator } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';

import { combineSagas, dataGuard, processQuery } from '+utils/saga';
import { LEGAL_ACTIONS, LegalActions } from './legal.actions';
import { LegalRepository } from './legal.repository';
import { GET_LEGAL_DOCUMENTS_QUERY } from './legal.state';

export function* getLegalDocuments(): SagaIterator {
  yield processQuery(
    GET_LEGAL_DOCUMENTS_QUERY,
    LegalRepository.getLegalDocuments,
    {
      onSuccess: res => dataGuard(LegalActions.setLegalDocumentsData)(res?.elements),
    },
  )({});
}

export const legalSagas = combineSagas([
  takeLatest(LEGAL_ACTIONS.GET_LEGAL_DOCUMENTS, getLegalDocuments),
]);
