import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { useInfographic } from '+dashboard/+infographic';
import { InfographicCardLayout } from '../InfographicCardLayout/InfographicCardLayout.component';
import { InfographicConsumptionCardHelper } from './InfographicConsumptionCard.helpers';
import { InfographicConsumptionCardStatus } from './InfographicConsumptionCardStatus.component';
import { InfographicConsumptionCardValue } from './InfographicConsumptionCardValue.component';

interface Props {
  isLoading: boolean;
}

export const InfographicConsumptionCard: React.FC<Props> = ({ isLoading = false }) => {
  const { data: { siteLiveData, query } } = useInfographic();

  const isMK1 = !!(siteLiveData && !siteLiveData.hasOwnProperty('consumptionPowerKw'));
  const status = InfographicConsumptionCardHelper.getCardStatus(siteLiveData, query.siteLiveQuery);

  return (
    <InfographicCardLayout
      headerDataTestId={'dashboard-infographic-card-consumption-header'}
      title={isMK1
        ? I18n.t(T.dashboard.infographic.consumption.titleMk1)
        : I18n.t(T.dashboard.infographic.consumption.title)
      }
      notifications={(
        <InfographicConsumptionCardStatus
          status={status}
          timestamp={siteLiveData?.timestamp}
        />
      )}
      isLoading={isLoading}
    >
      <InfographicConsumptionCardValue
        status={status}
        isMK1={isMK1}
        consumptionValue={InfographicConsumptionCardHelper.getValue(siteLiveData, isMK1)}
      />
    </InfographicCardLayout>
  );
};
