import { ClickOutside } from '@sonnen/shared-web';
import { useState } from 'react';
import * as React from 'react';

import { EnergyFlowState } from '+live-state/helpers/state-map';
import { LiveEnergyFlowHelper } from '+shared/components/LiveEnergyFlow/LiveEnergyFlow.helper';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { Site } from '+shared/store/site/types/site.interface';
import { EFCircleBattery, EFCircleBatteryProps } from './EFCircleBattery';
import { EFCircleConsumption, EFCircleConsumptionProps } from './EFCircleConsumption';
import { EFCircleGrid, EFCircleGridProps } from './EFCircleGrid';
import { EFCircleProduction, EFCircleProductionProps } from './EFCircleProduction';
import { EFLine } from './EFLine';
import { LiveEnergyFlowExplanation } from './LiveEnergyFlow.interface';

import './LiveEnergyFlow.component.scss';

export interface LiveEnergyFlowProps {
  isAppOnline: boolean;
  hasExplanations: boolean;
  liveData: SiteLive | undefined;
  site: Site | undefined;
  energyFlows: EnergyFlowState['ui'];
}

export const LiveEnergyFlow: React.FC<LiveEnergyFlowProps> = (props) => {
  const {
    hasExplanations,
    isAppOnline,
    liveData,
    site,
    energyFlows,
  } = props;
  const [explanation, setExplanation] = useState('');
  const setExplanationActiveType = (explanation: LiveEnergyFlowExplanation) => setExplanation(explanation);
  const hasExplanationActiveType = (element: LiveEnergyFlowExplanation) => Boolean(element === explanation);
  const closeExplanationTooltip = () => setExplanation('');
  const isActive = LiveEnergyFlowHelper.isActive(isAppOnline)(liveData?.online)(site);
  const isGridServiceActive = !!liveData?.gridServiceActive;

  const {
    isBatteryToConsumptionActive,
    isBatteryToGridActive,
    isBatteryInvalid,
    isProductionToBatteryActive,
    isProductionToConsumptionActive,
    isProductionInvalid,
    isProductionToGridActive,
    isGridToConsumptionActive,
    isGridToBatteryActive,
    isGridInvalid,
  } = energyFlows;

  type EFCircleCommonProps = Omit<
    | EFCircleProductionProps
    | EFCircleBatteryProps
    | EFCircleGridProps
    | EFCircleConsumptionProps
  , 'isExplanationActive'
  >;

  const commonProps: EFCircleCommonProps = {
    isActive,
    isAppOnline,
    liveData,
  };

  return (
    <div className={'c-live-energy-flow'}>
      <div className={'c-live-energy-flow__content'}>
        <div className={'c-live-energy-flow__lines-container'}>
          <EFLine
            type={'battery-consumption'}
            isActive={isActive(isBatteryToConsumptionActive)}
          />
          <EFLine
            type={'production-consumption'}
            isActive={isActive(isProductionToConsumptionActive)}
          />
          <EFLine
            type={'production-grid'}
            isActive={isActive(isProductionToGridActive)}
          />
          <EFLine
            type={'production-battery'}
            isActive={isActive(isProductionToBatteryActive)}
          />
          <EFLine
            type={'grid-consumption'}
            isActive={isActive(isGridToConsumptionActive)}
          />
          <EFLine
            type={'battery-grid'}
            isActive={isActive(isBatteryToGridActive) || isActive(isGridToBatteryActive)}
            isReverse={isGridToBatteryActive}
          />
        </div>
        <div className={'c-live-energy-flow__circles-container'}>
          <ClickOutside onClick={closeExplanationTooltip}>
            <div
              onClick={() => setExplanationActiveType(LiveEnergyFlowExplanation.PRODUCTION)}
            >
              <EFCircleProduction
                isExplanationActive={hasExplanations && hasExplanationActiveType(LiveEnergyFlowExplanation.PRODUCTION)}
                site={site}
                isProductionToBatteryActive={isProductionToBatteryActive}
                isProductionToConsumptionActive={isProductionToConsumptionActive}
                isProductionToGridActive={isProductionToGridActive}
                isProductionInvalid={isProductionInvalid}
                {...commonProps}
              />
            </div>
          </ClickOutside>
          <ClickOutside onClick={closeExplanationTooltip}>
            <div
              onClick={() => setExplanationActiveType(LiveEnergyFlowExplanation.BATTERY)}
            >
              <EFCircleBattery
                isExplanationActive={hasExplanations && hasExplanationActiveType(LiveEnergyFlowExplanation.BATTERY)}
                isGridServiceActive={isGridServiceActive}
                isBatteryToConsumptionActive={isBatteryToConsumptionActive}
                isBatteryToGridActive={isBatteryToGridActive}
                isGridToBatteryActive={isGridToBatteryActive}
                isGridToConsumptionActive={isGridToConsumptionActive}
                isProductionToBatteryActive={isProductionToBatteryActive}
                isBatteryInvalid={isBatteryInvalid}
                {...commonProps}
              />
            </div>
          </ClickOutside>
          <ClickOutside onClick={closeExplanationTooltip}>
            <div
              onClick={() => setExplanationActiveType(LiveEnergyFlowExplanation.GRID)}
            >
              <EFCircleGrid
                isExplanationActive={hasExplanations && hasExplanationActiveType(LiveEnergyFlowExplanation.GRID)}
                isGridServiceActive={isGridServiceActive}
                site={site}
                isBatteryToGridActive={isBatteryToGridActive}
                isGridToBatteryActive={isGridToBatteryActive}
                isGridToConsumptionActive={isGridToConsumptionActive}
                isProductionToGridActive={isProductionToGridActive}
                isGridInvalid={isGridInvalid}
                {...commonProps}
              />
            </div>
          </ClickOutside>
          <ClickOutside onClick={closeExplanationTooltip}>
            <div
              onClick={() => setExplanationActiveType(LiveEnergyFlowExplanation.CONSUMPTION)}
            >
              <EFCircleConsumption
                isExplanationActive={hasExplanations && hasExplanationActiveType(LiveEnergyFlowExplanation.CONSUMPTION)}
                site={site}
                isBatteryToConsumptionActive={isBatteryToConsumptionActive}
                isGridToConsumptionActive={isGridToConsumptionActive}
                isProductionToConsumptionActive={isProductionToConsumptionActive}
                {...commonProps}
              />
            </div>
          </ClickOutside>
        </div>
      </div>
    </div>
  );
};
