import { T } from '@sonnen/shared-i18n/customer';
import { PageHeadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { PageMetaTitle } from '+app/shared/components';
import { CommunityGraph, CommunityHeader } from '+legacy/pages/Community/components';
import { DemoCommunityMapSectionWeb } from '../';

export const DemoCommunityWeb: React.FC = () => (
  <>
    <PageMetaTitle value={I18n.t(T.general.pageTitles.default)} />
    <PageHeadline dataTestId={'demo-sonnen-community-header'}>
      {I18n.t(T.sonnenCommunity.title)}
    </PageHeadline>
    <CommunityHeader />
    <DemoCommunityMapSectionWeb />
    <CommunityGraph />
  </>
);
