import { Data, MergedData, ResolvedRelationship } from '@coolio/json-api';
import { ConfigurationData } from './configuration.interface';
import { Document } from './document.interface';

export enum OfferStatus {
  BLOCKED = 'blocked',
  DRAFT = 'draft',
  SEND = 'sent',
  BINDINGLY_ACCEPTED = 'bindingly-accepted',
}

export enum OfferProductStatus {
  UNKNOWN = 'unknown',
  DRAFT = 'draft',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  CONFIRMED = 'confirmed',
  DECLINED = 'declined',
  SENT_EXPIRED = 'sent-expired',
  DRAFT_EXPIRED = 'draft-expired',
}

export enum OfferProductType {
  FLAT_X = 'flatx',
  FLAT_DIRECT = 'flatdirect',
  BATTERY = 'battery',
}

export interface OfferProduct {
  productId: string;
  productType: OfferProductType;
  status: OfferProductStatus;
  draftExpiresAt?: Date;
  sentExpiresAt?: Date;
  recalculatedAt?: Date;
}

export interface OfferAcceptance {
  type: string;
  date: string;
  identifier: string | null;
}

export enum OfferTaxRegulation {
  SMALL_ENTERPRISE = 'small_enterprise',
  STANDARD = 'standard',
}

export interface OfferAttributes {
  status: OfferStatus;
  createdAt: string;
  updatedAt: string;
  bankAccountHolder?: { firstName: string; lastName: string };
  bankBic?: string;
  bankIban?: string;
  bankPayment?: string;
  flatOffers?: Document[];
  flatConfigurations?: Document[];
  accepted?: OfferAcceptance[];
  products: OfferProduct[];
  lead: string;
  taxRegulation?: OfferTaxRegulation;
}

export type OfferRelationships = {
  configuration: ResolvedRelationship<ConfigurationData>;
};

export type OfferData = Data<OfferAttributes, OfferRelationships>;
export type Offer = MergedData<OfferData>;
