import * as React from 'react';

export const upperFistLetter = (phrase: string) =>
  phrase.charAt(0).toUpperCase() + phrase.slice(1);

export const factorizeStepDescription = (textA: string, textB: string) =>
  textA + ' • ' + textB;

export type GuideStepOwner = 'customer' | 'partner' | 'sonnen';

export enum GuideStepStatus {
  PREVIEW = 'preview',
  ACTIVE = 'active',
  DONE = 'done',
  ERROR = 'error',
}

export enum GuideStepResponsibility {
  CUSTOMER = 'customer',
  PARTNER = 'partner',
  SONNEN = 'sonnen',
}

export interface GuideStepChildren {
  preview: React.ReactNode;
  active: React.ReactNode;
  done: React.ReactNode;
  error: React.ReactNode;
}
