import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, DS } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { Site } from '+shared/store/site/types/site.interface';
import { EFCircle } from '../EFCircle';
import { EFCircleExplanationTooltip } from '../EFCircleExplanationTooltip';
import { LiveEnergyFlowHelper } from '../LiveEnergyFlow.helper';

export type EFCircleProductionProps = {
  liveData: SiteLive | undefined;
  isActive: (predicate: boolean | undefined) => boolean;
  isAppOnline: boolean;
  site: Site | undefined;
  isExplanationActive: boolean | undefined;
  isProductionToBatteryActive: boolean | undefined,
  isProductionToConsumptionActive: boolean | undefined,
  isProductionToGridActive: boolean | undefined,
  isProductionInvalid: boolean | undefined,
};

export const EFCircleProduction: React.FC<EFCircleProductionProps> = ({
  liveData,
  isActive,
  isAppOnline,
  site,
  isExplanationActive,
  isProductionToBatteryActive,
  isProductionToConsumptionActive,
  isProductionToGridActive,
  isProductionInvalid,
}) => {
  const isMember = !!site && LiveEnergyFlowHelper.isCommunityMember(site);
  const value = liveData?.productionPower || 0;

  return (
    <EFCircle
      dataTestId={'live-state-circle-production'}
      type={'production'}
      icon={<DS.Icon.PV />}
      hasValueErrorIcon={isProductionInvalid}
      label={I18n.t(T.dashboard.energyFlowChart.production)}
      key={value}
      value={value}
      isActive={isActive(isProductionToBatteryActive || isProductionToConsumptionActive || isProductionToGridActive)}
      isOnline={isAppOnline && liveData?.online}
      isBlured={isExplanationActive}
      tooltip={isExplanationActive ? (
        <EFCircleExplanationTooltip dataTestId={'live-state-energy-flow-explanation-tooltip-production'} >
          <DefaultParagraph>
            {isMember
              ? I18n.t(T.dashboard.liveStateChart.production.tooltipProductionCommunity)
              : I18n.t(T.dashboard.liveStateChart.production.tooltipProductionGrid)
            }
          </DefaultParagraph>
        </EFCircleExplanationTooltip>
      ) :
        null
      }
    />);
};
