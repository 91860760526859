import { T } from '@sonnen/shared-i18n/customer';
import { DS } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { StoreState } from '+app/store/store.interface';
import { getFormattedDate } from '+legacy/helpers/dates';
import { ContractFlatXCard, ContractFlatXStatus } from '../../components/ContractFlatXCard';
import { getContractOrderedAtDate, getTerminationRequested } from '../../store/contract.selectors';

const mapStateToProps = (state: StoreState) => ({
  orderedAt: getContractOrderedAtDate(state),
  isTerminationPending: getTerminationRequested(state),
});

type Props = ReturnType<typeof mapStateToProps>;

export const ContractFlatXCardEnergyContractComponent: React.FC<Props> = ({
  orderedAt,
  isTerminationPending,
}) => {
  const formattedDate = getFormattedDate(orderedAt);
  const infoValue = formattedDate || I18n.t(T.yourFlat.sonnenFlatX.orderDateInProgress);
  const data = [{
    attribute: I18n.t(T.yourFlat.sonnenFlatX.energyContractDate),
    value: infoValue,
  }];

  return (
    <ContractFlatXCard
      title={I18n.t(T.yourFlat.sonnenFlatX.energyContractCard.title)}
      subtitle={I18n.t(T.yourFlat.sonnenFlatX.energyContractCard.subtitle)}
      data={data}
      icon={DS.Icon.Contract}
      status={isTerminationPending ? ContractFlatXStatus.TERMINATION_PENDING : ContractFlatXStatus.DONE}
      dataTestId={'contract-flat-x-energy-contract-card'}
    />
  );
};

export const ContractFlatXCardEnergyContract = connect(mapStateToProps)(ContractFlatXCardEnergyContractComponent);
