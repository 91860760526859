import { Data, MergedData } from '@coolio/json-api';
import { LeadStatus } from './leadStatus.interface';

export enum LeadCountry {
  AU = 'AU',
  DE = 'DE',
  GB = 'GB',
  IT = 'IT',
  US = 'US',
  AT = 'AT',
  CH = 'CH',
}

export enum LeadCustomerType {
  PRIVATE = 'private',
  BUSINESS = 'business',
}

export enum LeadCustomerSalutation {
  MR = 'mr',
  MS = 'ms',
}

export enum LeadSource {
  PARTNER = 'partner',
  SONNEN = 'sonnen',
  UNKNOWN = 'unknown',
}

interface LeadCustomerCompany {
  name: string;
}

interface LeadAddress {
  street: string;
  zipCode: string;
  city: string;
  country: LeadCountry;
}

export interface LeadAttributes {
  customerType: LeadCustomerType;
  customerNumber: string;
  salutation: LeadCustomerSalutation;
  title: string | null;
  company?: LeadCustomerCompany;
  firstName: string;
  lastName: string;
  email: string;
  phone: string | null;
  mobilePhone: string | null;
  deliveryAddress: LeadAddress;
  invoiceAddress?: LeadAddress;
  agreedToGdpr: boolean;
  optedOutOfMarketingMailing: boolean;
  status: LeadStatus;
  source: LeadSource;
}

export type LeadData = Data<LeadAttributes>;
export type Lead = MergedData<LeadData>;

export type LeadCollection = MergedData<LeadData[]>;
