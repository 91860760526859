import { T } from '@sonnen/shared-i18n/customer';
import { BoldParagraph, Loader } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import { get, uniqBy } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { FormInputSelect } from '+app/shared/components/FormInputSelect';
import { StoreState } from '+app/store/store.interface';
import { searchByKey } from '+app/utils/array.util';
import { mapActions } from '+app/utils/redux';
import { useDebounce } from '+shared/hooks/useDebounce';
import { GuideAcceptancePrefilledLine } from '../../components';
import { GuideAcceptanceActions } from '../../store/+acceptance.actions';
import {
  getEnergyProviders,
  getFetchedUtilityDataQueryStatus,
  getUtilityData,
} from '../../store/+acceptance.selectors';
import { formFields, UtilityDataForm } from '../GuideAcceptanceUtility';

import './GuideAcceptanceOldProvider.component.scss';

const mapDispatchToProps = mapActions({
  getEnergyProviders: GuideAcceptanceActions.getEnergyProviders,
});

const mapStateToProps = (state: StoreState) => ({
  energyProviders: getEnergyProviders(state),
  utilityData: getUtilityData(state),
  fetchedUtilityDataQueryStatus: getFetchedUtilityDataQueryStatus(state),
});

interface UtilityFormProps {
  form: FormikProps<UtilityDataForm>;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & UtilityFormProps
  ;

const GuideAcceptanceOldProviderComponent: React.FC<Props> = ({
  actions,
  energyProviders,
  form,
  utilityData,
  fetchedUtilityDataQueryStatus,
}) => {
  const [isOldProviderEditMode, setOldProviderEditMode] = React.useState<boolean>(true);
  const [searchValue, setSearchValue] = React.useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  React.useEffect(() => {
    form.setFieldValue(formFields.PROVIDER_ID, get(utilityData, 'energyProvider', ''));
    setOldProviderEditMode(!get(utilityData, 'energyProvider', ''));
  }, [utilityData]);

  React.useEffect(() => {
    actions.getEnergyProviders(debouncedSearchValue);
  }, [debouncedSearchValue]);

  return (
    <div className={'c-guide-acceptance-old-provider'}>
      <div className={'c-guide-acceptance-old-provider__text-wrapper'}>
        <BoldParagraph>
          {I18n.t(T.customerGuidance.acceptance.utilityData.oldProvider.header)}
        </BoldParagraph>
      </div>

      {fetchedUtilityDataQueryStatus.pending
        ? (
          <Loader className={'c-guide-acceptance-old-provider__loader'} />
        )
        : isOldProviderEditMode ?
          (
            <FormInputSelect
              className={'c-guide-acceptance-old-provider__select'}
              placeholder={I18n.t(T.customerGuidance.acceptance.utilityData.oldProvider.placeholder)}
              form={form}
              name={formFields.PROVIDER_ID}
              collection={uniqBy(energyProviders, 'id').map(item => item.id)}
              mapper={key => searchByKey('id', key, energyProviders, 'name')}
              onInputChange={val => setSearchValue(val)}
            />
          ) : (
            <GuideAcceptancePrefilledLine
              onClick={() => setOldProviderEditMode(true)}
              value={searchByKey('id', get(utilityData, 'energyProvider', ''), energyProviders, 'name')}
            />
          )
      }
    </div>
  );
};

export const GuideAcceptanceOldProvider =
  connect(mapStateToProps, mapDispatchToProps)(GuideAcceptanceOldProviderComponent);
