import { RouteName, RouterNavConfig } from '../router.types';

export const demoNavConfig: RouterNavConfig = [
  RouteName.DEMO_DASHBOARD,
  RouteName.DEMO_LIVE_STATE,
  RouteName.DEMO_ANALYSIS,
  RouteName.DEMO_COMMUNITY,
  RouteName.DEMO_FLAT,
  RouteName.DEMO_BATTERY,
];

export const demoAppNavConfig: RouterNavConfig = [
  RouteName.DEMO_LIVE_STATE,
  RouteName.DEMO_ANALYSIS,
  RouteName.DEMO_COMMUNITY,
  RouteName.DEMO_FLAT,
  RouteName.DEMO_BATTERY,
];
