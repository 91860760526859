import { XYPoint } from '@kanva/charts';
import * as React from 'react';

import { getFormattedTemperature } from '+legacy/helpers/temperature';
import { AccessoriesSeriesKey, AnalysisTooltip, AnalysisTooltipProps } from '@sonnen/shared-web';
import { AnalysisAccessoriesTooltipItem } from '../';
import { AccessoriesRanges } from '../../store/types/activityRange.interface';
import { factorizeActivityRange } from './AnalysisAccessoriesTooltipHorizontal.helper';

interface Props extends AnalysisTooltipProps {
  activityRanges: AccessoriesRanges | undefined;
  data?: Record<string, XYPoint>;
  rawTimestamp: number;
  hasBackupBox: boolean;
  hasHeater: boolean;
}

export const AnalysisAccessoriesTooltipHorizontal: React.FC<Props> = ({
  activityRanges,
  data,
  rawTimestamp,
  hasBackupBox,
  hasHeater,
  header,
  x = 0,
  isVisible,
}) => (
  <AnalysisTooltip
    type={'horizontal'}
    isVisible={isVisible}
    header={header}
    x={x}
  >
    {hasHeater && (
      <AnalysisAccessoriesTooltipItem
        icon={'Heater'}
        isActive={!!(
          data 
          && data[AccessoriesSeriesKey.HEATER_POWER]
          && data[AccessoriesSeriesKey.HEATER_POWER].y > 0
        )}
        temperature={data && getFormattedTemperature(
          data[AccessoriesSeriesKey.HEATER_TEMPERATURE]
          && data[AccessoriesSeriesKey.HEATER_TEMPERATURE].y
          || 0,
        )}
        activityRange={activityRanges && factorizeActivityRange(activityRanges.heaterPower, rawTimestamp)}
      />
    )}
    {hasBackupBox && (
      <AnalysisAccessoriesTooltipItem
        icon={'BackupBox'}
        isActive={!!(
          data
          && data[AccessoriesSeriesKey.BACKUP_BOX]
          && data[AccessoriesSeriesKey.BACKUP_BOX].y > 0
        )}
        activityRange={activityRanges && factorizeActivityRange(activityRanges.backupBox, rawTimestamp)}
      />
    )}
  </AnalysisTooltip>
);
