import { localStorage } from '+legacy/helpers/storage';
import { useEffect, useState } from 'react';

export const usePersistedState = (defaultValue: any, storageKey: string) => {
  const [state, setState] = useState(defaultValue);

  useEffect(() => {
    localStorage.getItem(storageKey).then(persistedValue => {
      if (!persistedValue) { return; }
      const persistedState = JSON.parse(persistedValue);
      if (persistedState && persistedState !== state) {
        setState(persistedState);
      }
    });
  }, []);
  useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(state));
  }, [storageKey, state]);

  return [state, setState];
};
