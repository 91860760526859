import { T } from '@sonnen/shared-i18n/customer';
import { Card , Icon, LinkButton, LinkButtonIconPosition } from '@sonnen/shared-web';
import { IconComponent } from '@sonnen/shared-web/src/components/Icon/models';

import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { Translate } from 'react-redux-i18n';

import { useCollapsibleContent } from '+shared/hooks/useCollapsibleContent';
import { getContractFlatXStatusTranslations } from './helpers';
import { ContractFlatXData, ContractFlatXStatus } from './types';

import './ContractFlatXCard.component.scss';

type ContractFlatXCardProps = {
  title: string;
  subtitle?: string;
  icon: IconComponent;
  status: ContractFlatXStatus;
  additionalInfo?: string;
  data: ContractFlatXData[];
  dataTestId?: string;
};

export const ContractFlatXCard: React.FC<ContractFlatXCardProps> = ({
  title,
  subtitle,
  icon,
  status,
  additionalInfo,
  data,
  dataTestId,
}) => {
  const { contentHeight, contentRef, isOpen, toggle, resizeObserver } = useCollapsibleContent();
  const CardIcon = icon;
  const collapseButtonText = isOpen ? I18n.t(T.yourFlat.sonnenFlatX.showLess) : I18n.t(T.yourFlat.sonnenFlatX.showMore);

  return (
    <div
      ref={resizeObserver.ref}
      className={'c-contract-flat-x-card'}
      style={{ '--c-contract-flat-x-card-content-height': contentHeight } as React.CSSProperties}
    >
      <div className={'c-contract-flat-x-card__card-wrapper'}>
        <Card>
          <div className={'c-contract-flat-x-card__card-inner'}>
            <div className={'c-contract-flat-x-card__header'}>
              <div
                className={`c-contract-flat-x-card__icon c-contract-flat-x-card__icon--${status.toLowerCase()}`}
              >
                <CardIcon />
              </div>
              <div
                data-test-id={`${dataTestId}-status`}
                className={`c-contract-flat-x-card__status c-contract-flat-x-card__status--${status.toLowerCase()}`}
              >
                {getContractFlatXStatusTranslations(status)}
              </div>
            </div>
            <div className={'c-contract-flat-x-card__headline'}>
              {title}
            </div>
            <div className={'c-contract-flat-x-card__info'}>
              {data.map((item: any) => (
                <div key={item.attribute} className={'c-contract-flat-x-card__info-wrapper'}>
                  <span className={'c-contract-flat-x-card__info-attribute'}>
                    {item.attribute}
                  </span>
                  <span data-test-id={`${dataTestId}-value`}
                    className={'c-contract-flat-x-card__info-value'}
                  >
                    {item.value}
                  </span>
                </div>
              ))}
            </div>
            {subtitle && <div className={'c-contract-flat-x-card__paragraph'}>
              {subtitle}
            </div>}
          </div>
          {additionalInfo && (
            <div className={'c-contract-flat-x-card__collapse-button-wrapper'}>
              <LinkButton
                dataTestId={`${dataTestId}-more-info-button`}
                className={'c-contract-flat-x-card__collapse-button'}
                icon={<Icon.Angle />}
                iconClass={classNames('c-contract-flat-x-card__collapse-button-icon', {
                  'is-rotated': isOpen,
                })}
                iconPosition={LinkButtonIconPosition.AFTER}
                onClick={toggle}
              >
                {collapseButtonText}
              </LinkButton>
            </div>
          )}
        </Card>
      </div>
      <div className={'c-contract-flat-x-card__content-wrapper'}>
        <div
          data-test-id={`${dataTestId}-content`}
          ref={contentRef}
          className={'c-contract-flat-x-card__content'}
        >
          {additionalInfo && (
            <Translate
              dangerousHTML={true}
              value={additionalInfo}
            />
          )}
        </div>
      </div>
    </div>
  );
};
