import { getRouteQueryParams, getRouterLocationPath } from '+router/store/router.selectors';
import { RouterHelper } from '@sonnen/shared-web';
import { flatten, tail } from 'lodash/fp';
import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';

export const NO_MATCHING_PATH = 'NO_MATCHING_PATH';

function * pathsMatcher(paths: Array<string | string[]>): SagaIterator {
  const pathsToMatch = flatten(paths);
  const matcher = RouterHelper.matchPath(pathsToMatch);
  const path: string = yield select(getRouterLocationPath);

  if (!matcher(path)) {
    return NO_MATCHING_PATH;
  }

  const params = RouterHelper.getPathParams(pathsToMatch)(path);

  if (!params) {
    return;
  }

  return tail(params as NonNullable<typeof params>);
}

function * queryPathsMatcher(paths: Array<string | string[]>): SagaIterator {
  const pathsToMatch = flatten(paths);
  const matcher = RouterHelper.matchPath(pathsToMatch);
  const path: string = yield select(getRouterLocationPath);

  if (!matcher(path)) {
    return NO_MATCHING_PATH;
  }

  return (yield select(getRouteQueryParams)) as Record<string, string>;
}

export const mapPathToParams = (...pathsToMatch: Array<string | string[]>) =>
  call(pathsMatcher, pathsToMatch);

export const mapPathToQueryParams = (...pathsToMatch: Array<string | string[]>) =>
  call(queryPathsMatcher, pathsToMatch);
