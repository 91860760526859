import { SummerTimeChange } from '+app/shared/helpers/summerTime.helper';
import { AnalysisSummerTimeNotficationType } from './AnalysisSummerTimeNotfication.component';

export const getSummerTimeNotificationType = (summerTimeChange: SummerTimeChange) => {
  const { isSummerTimeStartDate, isSummerTimeEndDate } = summerTimeChange;

  switch (true) {
    case isSummerTimeStartDate:
      return AnalysisSummerTimeNotficationType.START;
    case isSummerTimeEndDate:
      return AnalysisSummerTimeNotficationType.END;
    default:
      return AnalysisSummerTimeNotficationType.NONE;
  }
};
