import { Route } from '+app/router/router.types';
import { Icon, Nav, NavItem, NavListItem } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { DefaultNavItem } from '../';
import { DefaultNavTag } from '../DefaultNavTag';

import './DefaultNavSubnav.component.scss';

interface Props {
  rootRoute: Route;
  routes: Route[];
  isExpanded: boolean;
  isAlwaysExpanded?: boolean;
  isThemeNoIcon?: boolean;
  currentShallowPath?: string;
  toggleNavItem: (navItem: DefaultNavItem) => void;
}

const getSubrouteTag = (routes: Route[]) => {
  const route = routes.find(route => !!route.tag);
  return route && route.tag;
};

const isTagVisibleForMainRoute = (
  subrouteTag: string | undefined,
  isExpanded: boolean,
) => !isExpanded || (isExpanded && !subrouteTag);

export const DefaultNavSubnav: React.FC<Props> = ({
  rootRoute,
  routes,
  isExpanded,
  isAlwaysExpanded,
  isThemeNoIcon,
  toggleNavItem,
  currentShallowPath,
}) => {
  const subrouteTag = getSubrouteTag(routes);

  return (
    <>
      {isAlwaysExpanded ? (
        <NavItem 
          dataTestId={rootRoute.navigationDataTestId} 
          icon={!isThemeNoIcon && rootRoute.icon && <rootRoute.icon />}
        >
          <div className={'c-default-nav-subnav__content'}>
            {I18n.t(rootRoute.label.web || '')}
            <DefaultNavTag tag={rootRoute.tag} />
          </div>
        </NavItem>
      ) : (
        <NavItem
          dataTestId={rootRoute.navigationDataTestId}
          action={() => toggleNavItem({ path: rootRoute.path, isExpanded: !isExpanded})}
          icon={!isThemeNoIcon && rootRoute.icon && <rootRoute.icon />}
          sideContent={(
            <span className={classNames('c-default-nav-subnav__icon', {
              'is-rotated': isExpanded,
            })}>
              <Icon.Angle />
            </span>
          )}
        >
          <div className={'c-default-nav-subnav__content'}>
            {I18n.t(rootRoute.label.web || '')}
            {isTagVisibleForMainRoute(subrouteTag, isExpanded) && (
              <DefaultNavTag tag={subrouteTag || rootRoute.tag} />
            )}
          </div>
        </NavItem>
      )}
      <Nav
        isExpanded={isExpanded || isAlwaysExpanded}
        isNested={true}
      >
        {routes.map((subRoute: Route) => (
          <NavListItem key={`default-nav-subnav-${subRoute.name}`}>
            <NavItem
              dataTestId={subRoute.navigationDataTestId}
              isActive={currentShallowPath === subRoute.path}
              action={subRoute.path}
              isThemeDense={true}
              isThemeFaded={true}
            >
              <div className={'c-default-nav-subnav__content'}>
                {I18n.t(subRoute.label.web || '')}
                <DefaultNavTag tag={subRoute.tag} />
              </div>
            </NavItem>
          </NavListItem>
        ))}
      </Nav>
    </>
  );
};
