export interface QueryOptions {
  page?: number;
  limit?: number;
  ungrouped?: boolean;
}

// @TODO: Get rid of that when switching to new community
export interface QueryOptionsLegacy {
  page?: number;
  count?: number;
  searchWindow?: string;
}

// @TODO: Switch top QueryOptions when switching to new community
export const factorizeMapQueryParams = (opts: QueryOptionsLegacy = {}) => {
  const searchwindow = opts.searchWindow || 'searchwindow=-90.00,-180.00|90.00,180.00';
  const count = opts.count || 5000;
  const page = opts.page || 1;

  // @TODO: Uncomment when switching to new community
  // const limit = opts.limit || 5000;
  // const ungrouped = opts.ungrouped || true;

  return `?${searchwindow}&per=${count}&page=${page}`;

  // @TODO: Uncomment when switching to new community
  // return `?page=${page}&limit=${limit}&ungrouped=${ungrouped}`;
};
