import { BatteryHelper } from '+dashboard/+battery/store/battery.helpers';
import { Battery } from '+dashboard/+battery/store/types/battery.interface';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { QueryData } from '+shared/store/query/query.state';
import { InfographicHelper } from '../../containers/Infographic/Infographic.helper';
import { InfographicCardConnectionStatusType } from '../../containers/Infographic/Infographic.types';

export enum InfographicBatteryCardStatusType {
  DELAYED = 'delayed',
}

export enum BackupBoxStatus {
  CONNECTED = 'connected',
  ACTIVE = 'active',
}

const getCardStatus = (
  siteLiveData: SiteLive | undefined,
  siteLiveQuery: QueryData,
  batteryData: Battery | undefined,
): InfographicCardConnectionStatusType | InfographicBatteryCardStatusType => {
  const online = !!(siteLiveData?.online);
  const timestamp = siteLiveData?.timestamp;
  const batteryType = batteryData?.controllerType;

  const isDataDelayed =
    online
    && !!timestamp
    && BatteryHelper.isDataDelayed(new Date(timestamp), batteryType);

  switch (true) {
    case !online:
      return InfographicCardConnectionStatusType.OFFLINE;
    case InfographicHelper.isDataError(siteLiveData, siteLiveQuery):
      return InfographicCardConnectionStatusType.DATA_MISSING;
    case isDataDelayed:
      return InfographicBatteryCardStatusType.DELAYED;
    default:
      return InfographicCardConnectionStatusType.OK;
  }
};

const getBackupBoxStatus = (
  siteLiveData: SiteLive | undefined,
  batteryData: Battery | undefined,
): BackupBoxStatus | undefined => {

  switch (true) {
    case batteryData && BatteryHelper.isBackupBoxConnected(batteryData):
      return BackupBoxStatus.CONNECTED;
    case !!(siteLiveData?.backupActive):
      return BackupBoxStatus.ACTIVE;
    default:
      return undefined;
  }
};

export const InfographicBatteryCardHelper = {
  getCardStatus,
  getBackupBoxStatus,
};
