import { useRouterState } from '+app/router/RouterProvider.component';
import { RouterBuilder } from '+router/factory';
import { getPath, getSubRoutes } from '+router/router.helper';
import { RouteName } from '+router/router.types';
import * as React from 'react';

export const ContractEnergy: React.FC = () => {
  const { availableFlatRoutes } = useRouterState();

  return (
    <RouterBuilder
      routes={getSubRoutes(RouteName.ENERGY, availableFlatRoutes)}
      redirectTo={getPath(RouteName.ENERGY_OVERVIEW)}
    />
  );
};
