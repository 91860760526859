import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { NavBottom } from '+components';
import { DemoBubble } from '../DemoBubble';
import { DemoNavProps } from './DemoNav.component';

import './DemoNav.mobile.component.scss';

export const DemoNavMobile: React.FC<DemoNavProps> = ({ routes }) => {
  const [isBubbleOpen, setBubbleState] = React.useState(true);
  const removeBubble = () => setBubbleState(false);
  
  return (
    <div className={'m-c-demo-nav'}>
      <div onClick={removeBubble}>
        <NavBottom routes={routes}/>
      </div>
      {isBubbleOpen && (
        <div className={'m-c-demo-nav__wrapper'}>
          <div className={'m-c-demo-nav__bubble'}>
            <DemoBubble
              side={'bottom'}
              buttonLabel={I18n.t(T.demo.welcome.bubble.buttonLabel)}
            >
              {I18n.t(T.demo.welcome.bubble.text)}
            </DemoBubble>
          </div>
        </div>
      )}
    </div>
  );
};
