import { isEqual } from 'lodash';
import * as ReactGA from 'react-ga';

export class GoogleAnalytics {
  private static initializedOptions?: ReactGA.InitializeOptions;

  static init(gaTrackingID: string, options?: ReactGA.InitializeOptions) {
    if (!isEqual(this.initializedOptions, options)) {
      ReactGA.initialize(gaTrackingID, options);
      this.initializedOptions = options;
    }
  }
}

export const GA = ReactGA;

export enum GTMEventCategory {
  VIRTUAL_PAGE_VIEW = 'VirtualPageView',
  DOCUMENTS = 'Documents',
  PRODUCT_MIGRATION = 'ProductMigration',
  LIVE_STATE = 'LiveState',
  ANALYSIS = 'Analysis',
}

interface GTMExtraEventData {
  [key: string]: string | number | undefined;
}
export interface GTMEventData extends GTMExtraEventData {
  event: string;
}

export type SendEvent = (event: string, extra?: GTMExtraEventData) => void;
export type SendPageView = () => void;

export class GTMTrackerManager {
  private dataLayer: GTMEventData[];
  private constructor(dataLayerName: string) {
    if (typeof window[dataLayerName] === 'undefined') {
      window[dataLayerName] = [];
    }

    this.dataLayer = window[dataLayerName];
  }

  static create (dataLayerName: string) {
    return new GTMTrackerManager(dataLayerName);
  }

  sendEvent: SendEvent = (event, extra) => {
    this.dataLayer.push({
      event,
      ...extra,
    });
  };
}
