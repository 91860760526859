import { ResponseError } from '+shared/network/network.interface';
import { HttpFetch } from '@coolio/http';

export const errorInterceptor = (request: HttpFetch<any>): HttpFetch<any> => async () => {
  const response = await request();
  if (response.ok) {
    return response;
  }
  // TODO: improve RawHttpResponse interface in @coolio so that we don't need to cast here
  const errorBody = await response.parsedBody();
  throw new ResponseError(response as unknown as Response, errorBody);
};
