import { Layout } from '+app/shared/components';
import { IntegrationActions, sendAction } from '+mobile';
import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, ButtonTheme, Icon, InfoBanner } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

export const ErrorPageMobile: React.FC = () => (
  <Layout>
    <div className={'c-error-page'}>
      <InfoBanner
        icon={<Icon.Ufo />}
        title={I18n.t(T.errorPage.title)}
        subtitle={<Translate value={T.errorPage.details} dangerousHTML={true} />}
      />
      <div className={'c-error-page__button'}>
        <Button
          size={ButtonSize.SECONDARY}
          theme={ButtonTheme.OUTLINE}
          label={I18n.t(T.errorPage.button)}
          onClick={() => sendAction(IntegrationActions.reload())}
        />
      </div>
    </div>
  </Layout>
);
