import { Query } from '+shared/store/query/query.state';
import { Battery, BatteryStatusHistory, BatteryUnits, Document } from './types/battery.interface';

export const GET_BATTERY_DOCUMENTS_QUERY = 'getBatteryDocumentsQuery';
export const GET_BATTERY_DATA_QUERY = 'getBatteryDataQuery';
export const GET_BATTERY_ELECTRIC_UNITS_QUERY = 'getBatteryElectricUnitsQuery';
export const GET_BATTERY_CELL_CARE_HISTORY_QUERY = 'getBatteryCellCareHistoryQuery';
export const SET_BACKUP_BUFFER_QUERY = 'setBackupBufferQuery';

export interface BackupBufferProcessing {
  processingValue: number;
  processingStartAt: Date;
}

// NOTE: BackupBuffer is a set of batteries id (key) and its processing state object
export type BackupBuffer = Record<string, BackupBufferProcessing>;

export interface BatteryState {
  backupBuffer: BackupBuffer;
  data: Battery;
  documents: Document[];
  documentsLanguage?: string;
  electricUnits: BatteryUnits;
  cellCareHistory: BatteryStatusHistory[];
  [GET_BATTERY_DATA_QUERY]: Query<Battery>;
  [GET_BATTERY_DOCUMENTS_QUERY]: Query<Document[]>;
  [GET_BATTERY_ELECTRIC_UNITS_QUERY]: Query<BatteryUnits>;
  [GET_BATTERY_CELL_CARE_HISTORY_QUERY]: Query<BatteryStatusHistory>;
  [SET_BACKUP_BUFFER_QUERY]: Query<any>;
}

export const initialState: BatteryState = {
  backupBuffer: {},
  data: {
    id: '',
    type: '',
    self: '',
  },
  documents: [],
  electricUnits: [],
  cellCareHistory: [],
  [GET_BATTERY_DATA_QUERY]: {},
  [GET_BATTERY_DOCUMENTS_QUERY]: {},
  [GET_BATTERY_ELECTRIC_UNITS_QUERY]: {},
  [GET_BATTERY_CELL_CARE_HISTORY_QUERY]: {},
  [SET_BACKUP_BUFFER_QUERY]: {},
};
