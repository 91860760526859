import * as moment from 'moment-timezone';

export type TimePeriod = { start: moment.Moment, end: moment.Moment };

enum DayTimeUnits {
  SECOND = 1000,
  MINUTE = 60,
  HOUR = 60,
  DAY = 24,
}

enum YearDaysAmount {
  NORMAL = 365,
  LEAP = 366,
}

enum DateFormat {
  NUMERIC_FULL_DATE = 'YYYY-MM-DD',
  NUMERIC_YEAR = 'YYYY',
}

const getOneSecondInMiliseconds = () => DayTimeUnits.SECOND;

const getOneMinuteInMiliseconds = () => getOneSecondInMiliseconds() * DayTimeUnits.MINUTE;

const getOneHourInMiliseconds = () => getOneMinuteInMiliseconds() * DayTimeUnits.HOUR;

const getOneDayInMiliseconds = () => getOneHourInMiliseconds() * DayTimeUnits.DAY;

const getYearDaysAmount = (date: string) => isLeapYear(date) ? YearDaysAmount.LEAP : YearDaysAmount.NORMAL;

const getNumberOfDaysInDateRange = (start: moment.MomentInput, end: moment.MomentInput): number => Math.ceil(
  (parseInt(moment(end).format('x'), 10) - parseInt(moment(start).format('x'), 10) + 1)
  / DateUtil.getOneDayInMiliseconds(),
);

const getNewerDate = <T extends moment.MomentInput, U extends moment.MomentInput>(date1: T, date2: U): T | U =>
  moment(date1).isBefore(moment(date2))
    ? date2
    : date1
;

const isLeapYear = (date: string): boolean => moment(date).isLeapYear();

const isSameDay = (fistDate: Date, secondDate: Date): boolean =>
  fistDate.getDate() === secondDate.getDate()
  && fistDate.getMonth() === secondDate.getMonth()
  && fistDate.getFullYear() === secondDate.getFullYear()
;

const isDateWithinThisYear = (date: moment.MomentInput): boolean =>
  moment(date).isAfter(moment().startOf('year'))
  && moment(date).isBefore(moment().endOf('year'))
;

const getDateInTimezone = (
  selectedDate: moment.MomentInput | undefined,
  batteryTimezone: string | null | undefined,
) => {
  const guardedBatteryTimezone = batteryTimezone ?? moment.tz.guess();

  return moment.tz(
    selectedDate,
    guardedBatteryTimezone,
  );
};

export const DateUtil = {
  DateFormat,
  DayTimeUnits,
  YearDaysAmount,
  isLeapYear,
  isSameDay,
  isDateWithinThisYear,
  getDateInTimezone,
  getNewerDate,
  getNumberOfDaysInDateRange,
  getOneSecondInMiliseconds,
  getOneMinuteInMiliseconds,
  getOneHourInMiliseconds,
  getOneDayInMiliseconds,
  getYearDaysAmount,
};
