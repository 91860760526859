import {
  ChartView,
  DataContainer,
  DataContainerTooltipExtension,
  DataContainerTransformExtension,
  DataSeries,
  SimpleOnScaleListenerArgs,
  TooltipEvent,
  XYPoint,
} from '@kanva/charts';
import { BASE_TICK_COUNT, SeriesKey } from '@sonnen/shared-web';
import { isNil } from 'lodash';

import { View } from '@kanva/core';
import { KanvaTooltipValues } from './kanva.types';

/**
 * Private: handleTooltipEvent. Needed for tooltip extension creation
 */
const handleTooltipEvent = (
  currentEvent: TooltipEvent | undefined,
  incommingEvent: TooltipEvent,
  setTooltipEvent: React.Dispatch<React.SetStateAction<TooltipEvent | undefined>>,
): void => {
  if (currentEvent === incommingEvent) {
    return;
  }

  // TODO: check
  if (!Object.values(incommingEvent.match.values).every(point => point && isNil(point.y))) {
    setTooltipEvent(incommingEvent);
  }
};

/**
 * Private: handleScale. Needed for transform extension creation
 */
const handleScale = (
  scaleX: number,
  dataContainers: Array<DataContainer<any>>,
  currentScale: { x: number, y: number },
  setZoomState: (isZoomed: boolean) => void,
  setScale: ({x, y}: {x: number, y: number}) => void,
) => {
  const scale = Math.floor(Math.log2(scaleX));

  setZoomState(scaleX > 1);

  if (currentScale.x === scale) {
    return;
  }

  dataContainers.forEach(container => {
    const axisParams = container.getXAxisParameters();

    axisParams.tickCount = 1 + (BASE_TICK_COUNT - 1) * Math.pow(2, scale);
    container.setXAxisParameters(axisParams);
  });

  setScale({x: scale, y: 1});
};

const createTooltipExtension = (
  currentEvent: TooltipEvent | undefined,
  setTooltipEvent: React.Dispatch<React.SetStateAction<TooltipEvent | undefined>>,
) => {
  return new DataContainerTooltipExtension({
    onTooltipEvent: (e: TooltipEvent) => handleTooltipEvent(currentEvent, e, setTooltipEvent),
  });
};

const createTransformExtension = (
  currentScale: { x: number, y: number},
  setZoomState: (isZoomed: boolean) => void,
  setScale: ({x, y}: {x: number, y: number}) => void,
) => (
  new DataContainerTransformExtension({
    scale: {
      limit: {
        x: [1, 10],
      },
      listener: ({ scaleX, dataContainers}: SimpleOnScaleListenerArgs) => handleScale(
        scaleX,
        dataContainers,
        currentScale,
        setZoomState,
        setScale,
      ),
      listenerThreshold: 0.001,
    },
  })
);

const handleTooltipPositionChange = (x: number) => (
  view: View<any> | undefined,
  tooltipExtension: DataContainerTooltipExtension | undefined,
) => {
  if (tooltipExtension && view) {
    tooltipExtension.simulateAbsoluteCanvasPosition(view as ChartView<any, any>, { x, y: 0 });
  }
};

const getLastSeriesValues = (container: DataContainer<XYPoint>) => {
  let lastSeriesValues: KanvaTooltipValues | undefined;

  container.getAllDataSeries().forEach(serie => {
    const filteredSerie = serie.data.filter(dataItem => !(isNil(dataItem.y)));
    
    lastSeriesValues = {
      ...lastSeriesValues,
      [serie.name]: filteredSerie[filteredSerie.length - 1],
    };
  });

  return lastSeriesValues;
};

// TODO: To remove and replace with getTooltipValues
const handleTooltipValues = (
  container: DataContainer<XYPoint>,
  tooltipEvent: TooltipEvent | undefined,
  setTooltipValues: (values: KanvaTooltipValues | undefined) => void,
) => {
  const values = tooltipEvent?.match.values && Boolean(tooltipEvent?.match.primary.x)
    ? tooltipEvent?.match.values
    : KanvaHelper.getLastSeriesValues(container);

  setTooltipValues(values);
};

const getTooltipValues = (
  container: DataContainer<XYPoint>,
  tooltipEvent: TooltipEvent | undefined,
) => {
  const tooltipEventValues = tooltipEvent?.match.values;
  if (tooltipEventValues) {
    const hasYValues = Object.keys(tooltipEventValues).every(serieKey => !isNil(tooltipEventValues[serieKey]?.y));
    return hasYValues
      ? tooltipEventValues
      : KanvaHelper.getLastSeriesValues(container);
  }
  return KanvaHelper.getLastSeriesValues(container);
};

const getSeriesXFromTooltipValues = (seriesKey: SeriesKey | undefined, values: KanvaTooltipValues | undefined) => {
  if (values && seriesKey) {
    const positiveSeriesKeys = Object.keys(values).filter(serieKey => Boolean(values[serieKey] && values[serieKey].x));
    return positiveSeriesKeys.length >= 1 && values[positiveSeriesKeys[0]]
      ? values[positiveSeriesKeys[0]].x
      : 0;
  }
  return 0;
};

export const KanvaHelper = {
  createTooltipExtension,
  createTransformExtension,
  getLastSeriesValues,
  getSeriesXFromTooltipValues,
  getTooltipValues,
  handleTooltipPositionChange,
  handleTooltipValues,
};
