import { pipe } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';

import { isAuthenticated } from '+app/+auth/store/auth.selectors';
import {
  getBatteryControllerType,
  getBatteryCountryCode,
  isBackupBoxInstalled,
  isBatteryQueryPending,
  isHeaterInstalled,
  isProtectBoxInstalled,
} from '+app/+dashboard/+battery/store/battery.selectors';
import { getCurrentSiteContractType, getCurrentSiteTariffType, isContractDataQueryPending } from '+app/+dashboard/+contract/store/contract.selectors';
import { getRouterLocationPath } from '+app/router/store/router.selectors';
import { getActiveSiteOptions, isSitesQueryPending } from '+app/shared/store/site/site.selectors';
import { isLoading as isSyncLoading } from '+app/shared/store/sync/sync.selectors';
import { getUserCountryCode } from '+app/shared/store/user/user.selectors';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { Locale } from '+legacy/helpers/i18n.helper';
import { NewsChannelActions } from '+shared/store/news-channel/newsChannel.actions';
import { News } from '+shared/store/news-channel/types/news.interface';
import { NewsProviderHelper } from './NewsProvider.helper';

const mapStateToProps = (state: StoreState) => ({
  isAuthenticated: isAuthenticated(state),
  countryCode: getBatteryCountryCode(state) || getUserCountryCode(state),
  siteOptions: getActiveSiteOptions(state),
  currentPath: getRouterLocationPath(state),
  lang: state.i18n.locale as Locale,
  controllerType: getBatteryControllerType(state),
  isBackupBoxInstalled: isBackupBoxInstalled(state),
  isProtectBoxInstalled: isProtectBoxInstalled(state),
  isHeaterInstalled: isHeaterInstalled(state),
  contractType: getCurrentSiteContractType(state),
  isContractDataQueryPending: isContractDataQueryPending(state),
  isBatteryQueryPending: isBatteryQueryPending(state),
  isSitesQueryPending: isSitesQueryPending(state),
  tariffType: getCurrentSiteTariffType(state),
  isSyncLoading: isSyncLoading(state),
});

const mapDispatchToProps = mapActions({
  setNewsList: NewsChannelActions.setNewsList,
});

type Props =
  & { allNews: News[] }
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

const NewsProviderComponent: React.FC<Props> = ({
  actions: { setNewsList },
  allNews,
  controllerType,
  contractType,
  countryCode,
  isBackupBoxInstalled,
  isProtectBoxInstalled,
  isHeaterInstalled,
  isContractDataQueryPending,
  isSyncLoading,
  isBatteryQueryPending,
  isSitesQueryPending,
  siteOptions,
  tariffType,
}) => {
  React.useEffect(() => {
    // TODO: Think of a cleaner solution for handling data loading
    const batteryAccessories = NewsProviderHelper
      .getSiteBatteryAccessories(isBackupBoxInstalled, isProtectBoxInstalled, isHeaterInstalled);
    const isContractDataPresent = !!(contractType && tariffType) || !isContractDataQueryPending;
    const isBatteryDataPresent = !!(controllerType && batteryAccessories) || !isBatteryQueryPending;
    const isSitesDataPresent = !!siteOptions || !isSitesQueryPending;
    
    if (isContractDataPresent && isBatteryDataPresent && isSitesDataPresent && !isSyncLoading) {
      const availableNewsList: News[] = pipe([
        NewsProviderHelper.filterValidNews,
        NewsProviderHelper.filterNewsByCountry(countryCode),
        NewsProviderHelper.filterNewsByOptions(siteOptions),
        NewsProviderHelper.filterNewsByAccessories(batteryAccessories),
        NewsProviderHelper.filterNewsByBatteryControllerType(controllerType),
        NewsProviderHelper.filterNewsByContractType(contractType),
        NewsProviderHelper.filterNewsByTariffType(tariffType),
      ])(allNews);

      setNewsList(availableNewsList);
    }
  }, [
    allNews,
    countryCode,
    siteOptions,
    controllerType,
    contractType,
    tariffType,
    isContractDataQueryPending,
    isSyncLoading,
    isSitesQueryPending,
    isBatteryQueryPending,
  ]);

  return null;
};

export const NewsProvider = connect(mapStateToProps, mapDispatchToProps)(NewsProviderComponent);
