import { T } from '@sonnen/shared-i18n/customer';
import { PageHeadline } from '@sonnen/shared-web';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getGuideSyncQueryStatus } from '+app/+guide/store/+lead.selectors';
import { getPath, getPaths } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { AppLoader } from '+app/shared/components';
import { Hint } from '+app/shared/store/sync/types/hint.interface';
import { StoreState } from '+app/store/store.interface';
import { getSetupPhaseStatus } from '+guide/+overview/store/+overview.selectors';
import { GuideSetupActive, GuideSetupPreview } from '+guide/+setup/components';
import { GuidePhase, GuidePreviewNav } from '+guide/components';
import { mapActions } from '+utils/redux';

const mapStateToProps = (state: StoreState) => ({
  setupPhaseStatus: getSetupPhaseStatus(state),
  guideSyncQueryStatus: getGuideSyncQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  push,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;
  
export const GuideSetupComponent: React.FC<Props> = ({ actions, setupPhaseStatus, guideSyncQueryStatus }) => {
  const guideLoaderHint: Hint = {
    icon: 'icofont-contract',
    headline: I18n.t(T.customerGuidance.loader.headline),
    description: I18n.t(T.customerGuidance.loader.description),
  };

  return (
    <>
      <PageHeadline
        smallGap={true}
      >
        {I18n.t(T.customerGuidance.setup.headline)}
      </PageHeadline>
      {guideSyncQueryStatus.success ? (
        <>
          <GuidePhase
            pathFactory={(step?: string) => step
              ? getPath(RouteName.GUIDE_SETUP_STEP, [step])
              : getPath(RouteName.GUIDE_SETUP)
            }
            status={setupPhaseStatus}
          >
            {{
              active: <GuideSetupActive />,
              preview: <GuideSetupPreview />,
              done: <GuideSetupActive />,
            }}
          </GuidePhase>
          <GuidePreviewNav
            prevPhase={() => actions.push(getPaths().GUIDE_PURCHASE)}
            nextPhase={() => actions.push(getPaths().GUIDE_USAGE)}
          />
        </>
      ) : (
        <AppLoader hint={guideLoaderHint}/>
      )}
    </>
  );
};

export const GuideSetup = connect(mapStateToProps, mapDispatchToProps)(GuideSetupComponent);
