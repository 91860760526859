import { AxisOrientation, GridLines } from '@kanva/charts';
import { AxisView, BarChartView as BarChartViewComponent, ChartGridView } from '@kanva/charts-react';
import { rgba, TextAlign } from '@kanva/core';
import { View as ViewComponent } from '@kanva/react';
import { 
  barChartGridPaint,
  barChartStyle,
  barChartxAxisStyle,
  barChartyAxisLabelPaint,
  CHART_FONT,
  SharedChartColors,
  useWindowSize,
  Views,
} from '@sonnen/shared-web';
import { breakpointUp } from '@sonnen/shared-web/src/utils/breakpoints';
import * as React from 'react';

import { FullWidthKanva } from '+components';
import { useAnalysisBarChart } from '../../containers/AnalysisKanvaProvider/AnalysisBarChartProvider/AnalysisBarChartProvider.context';
import { useAnalysis } from '../../containers/AnalysisProvider/AnalysisProvider.context';
import { CHART_HEIGHT } from '../../helpers/analysis.helper';
import { layout as mobileLayout } from './AnalysisBarChart.mobile.layout';
import { layout as webLayout } from './AnalysisBarChart.web.layout';

interface AnalysisBarChartWebProps {
  chartHeight: number;
}

export const AnalysisBarChartWeb: React.FC<AnalysisBarChartWebProps> = ({
  chartHeight,
}) => {
  const { query } = useAnalysis();
  const { containers, handleMount } = useAnalysisBarChart();
  const chart = useWindowSize().innerWidth >= breakpointUp('SM')
    ? {
      borders: { xAxis: 3, yAxis: 3 },
      layout: webLayout,
      yLabel: barChartyAxisLabelPaint.clone(),
    }
    : {
      borders: { xAxis: 1, yAxis: 0 },
      layout: mobileLayout,
      yLabel: barChartyAxisLabelPaint.clone()
        .setFont({
          ...CHART_FONT,
          fontSize: 8,
        }),
    };

  const { borders, layout, yLabel } = chart;
  const isLoading = query.statisticsQueryStatus.pending;

  return (
    <>
      <FullWidthKanva
        height={chartHeight || CHART_HEIGHT}
        isLoading={isLoading}
      >
        <ViewComponent
          layoutParams={layout.chartWrapper}
          borderColor={rgba(SharedChartColors.BLACK, .1)}
        >
          <ChartGridView
            layoutParams={layout.grid}
            dataContainer={containers?.default}
            gridLines={GridLines.HORIZONTAL}
            style={{ paint: barChartGridPaint }}
          />
          <BarChartViewComponent
            layoutParams={layout.barChart}
            dataContainer={containers?.default}
            style={barChartStyle}
            onMount={handleMount}
          />
        </ViewComponent>
        <AxisView
          id={Views.X_AXIS}
          border={{ top: borders.xAxis }}
          borderColor={rgba(SharedChartColors.DARK, .1)}
          layoutParams={layout.xAxis}
          dataContainer={containers?.default}
          orientation={AxisOrientation.HORIZONTAL}
          style={barChartxAxisStyle}
        />
        <AxisView
          id={Views.Y_AXIS}
          border={{ left: borders.xAxis }}
          borderColor={rgba(SharedChartColors.DARK, .1)}
          layoutParams={layout.yAxis}
          dataContainer={containers?.default}
          orientation={AxisOrientation.VERTICAL}
          style={{
            labelPaint: yLabel.clone()
              .setTextAlign(TextAlign.LEFT),
          }}
        />
      </FullWidthKanva>
    </>
  );
};
