import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

import { isFlatDirectConfiguration } from '+app/+guide/+acceptance/store/+acceptance.helper';
import { insertIf } from '+app/utils/array.util';
import { Configuration, OfferTaxRegulation, Proposal } from '+guide/store/types';
import { ConfigurationHelper } from '+shared/helpers/configuration.helper';
import { ConfigurationLineProps } from '../../components/ConfigurationLine/ConfigurationLine';

export const factorizeConfBasicInfo = (configuration: Configuration) => {
  const currency = ConfigurationHelper.getCurrencyForMarket(configuration.market);

  return [
    // Usage Allowance per year
    {
      title: I18n.t(T.customerGuidance.purchase.usageAllowance),
      value: `${ConfigurationHelper.formatMeasurementEnergy(configuration.usageAllowancePerYear)}/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
    },
    // Basic fee
    {
      title: isFlatDirectConfiguration(configuration) ?
      `${I18n.t(T.customerGuidance.purchase.flatOffer.monthlyFee)}:`
      : `${I18n.t(T.customerGuidance.purchase.flatOffer.basicFee)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice({ value: configuration.baseFeeGrossPerMonth?.value }, currency)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.month)}`,
    },
    // @TODO add Montly prepayment for 12 months: 'expected_bill_amount_gross_per_year' from PE
  ];
};

export const factorizeMonthlyPrepaymentInfo = (  
  conf: Proposal, 
  offerTaxRegulation: OfferTaxRegulation,
  detailedView: boolean,
): ConfigurationLineProps[] => {
  const currency = ConfigurationHelper.getCurrencyForMarket(conf.market);

  const smallEnterPriseTaxation = [
    // Monthly pre-payment
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.monthlyPrepayment)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice(conf.initialAdvancePaymentGrossPerMonth, currency)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.month)}`,
    },
    // Excess energy
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.excessEnergy)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(conf.powerPlant!.expectedExcessEnergyPerYear)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
    },
    // Excess tariff
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.excessTariff)}:`,
      value: ConfigurationHelper.formatPricePerKwh(conf.excessTariffGrossPerKwh, currency, { asMinorUnit: true }),
      helperLabel: I18n.t(T.customerGuidance.purchase.flatOffer.excessTariffHelper, {
        price: ConfigurationHelper.formatPricePerKwh(conf.valueAddedTaxPerKwh, currency, { asMinorUnit: true }),
        vat: ConfigurationHelper.formatTaxRate(conf.valueAddedTax),
      }),
    },
  ];

  const standardTaxation = [
    // Monthly pre-payment
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.monthlyPrepayment)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice(conf.initialAdvancePaymentGrossPerMonth, currency)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.month)}`,
    },
    // Excess energy
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.excessEnergy)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(conf.powerPlant!.expectedExcessEnergyPerYear)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
    },
    // Excess tariff
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.excessTariff)}:`,
      value: ConfigurationHelper.formatPricePerKwh(conf.excessTariffGrossPerKwh, currency, { asMinorUnit: true }),
    },
  ];

  if (!detailedView) {
    return [
      // Monthly pre-payment
      {
        title: `${I18n.t(T.customerGuidance.purchase.flatOffer.monthlyPrepayment)}:`,
        value: `${ConfigurationHelper.formatMeasurementPrice(conf.initialAdvancePaymentGrossPerMonth, currency)}`
          + `/${I18n.t(T.customerGuidance.purchase.flatOffer.month)}`,
      },
    ];
  }

  return offerTaxRegulation === OfferTaxRegulation.SMALL_ENTERPRISE 
    ? smallEnterPriseTaxation
    : standardTaxation;
};

export const factorizeExpectedCashbackInfo = (
  conf: Proposal, 
  offerTaxRegulation: OfferTaxRegulation,
  detailedView: boolean,
): ConfigurationLineProps[] => {
  const currency = ConfigurationHelper.getCurrencyForMarket(conf.market);

  const expectedCashback = offerTaxRegulation === OfferTaxRegulation.SMALL_ENTERPRISE
    ? conf.powerPlant!.expectedCashbackNetPerYear
    : conf.powerPlant!.expectedCashbackGrossPerYear;

  const smallTaxation = [
    // Expected cashback
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedCashback)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice(conf.powerPlant!.expectedCashbackNetPerYear, currency)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
    },
    // Expected unused free usage allowance
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedUnusedFreeUsageAllowance)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(conf.powerPlant!.expectedUnusedUsageAllowancePerYear)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
    },
    // Cashback rate
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.cashbackRate)}:`,
      value: ConfigurationHelper.formatPricePerKwh(conf.cashbackTariffNetPerKwh, currency, { asMinorUnit: true }),
      helperLabel: I18n.t(T.customerGuidance.purchase.flatOffer.cashbackRateHelper),
    },
  ];

  const standardTaxation = [
    // Expected cashback
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedCashback)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice(conf.powerPlant!.expectedCashbackGrossPerYear, currency)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
    },
    // Expected unused free usage allowance
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedUnusedFreeUsageAllowance)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(conf.powerPlant!.expectedUnusedUsageAllowancePerYear)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
    },
    // Cashback rate
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.cashbackRate)}:`,
      value: ConfigurationHelper.formatPricePerKwh(conf.cashbackTariffGrossPerKwh, currency, { asMinorUnit: true }),
    },
  ];

  if (!detailedView) {
    return [
      // Expected cashback
      {
        title: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedCashback)}:`,
        value: `${ConfigurationHelper.formatMeasurementPrice(expectedCashback, currency)}`
          + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
      },
    ];
  }

  return offerTaxRegulation === OfferTaxRegulation.SMALL_ENTERPRISE
    ? smallTaxation
    : standardTaxation;
};

export const factorizeVatOnUsedUsageAllowance = (conf: Configuration, isDetailedViewOpen: boolean) => {
  const currency = ConfigurationHelper.getCurrencyForMarket(conf.market);

  if (!isDetailedViewOpen) {
    return [
      // vat total on used usage allowance
      {
        title: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedVatOnUsedUsageAllowance)}:`,
        value: `${ConfigurationHelper.formatMeasurementPrice(conf.powerPlant!.expectedVatOnUsedUsageAllowancePerYear, currency)}`
          + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
      },
    ];
  }

  return [
    // vat total on used usage allowance
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedVatOnUsedUsageAllowance)}:`,
      value: `${ConfigurationHelper.formatMeasurementPrice(conf.powerPlant!.expectedVatOnUsedUsageAllowancePerYear, currency)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
    },
    // prognosed used usage allowance
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.expectedUsedUsageAllowance)}:`,
      value: `${ConfigurationHelper.formatMeasurementEnergy(conf.powerPlant!.expectedUsedUsageAllowancePerYear)}`
        + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`,
    },
    // vat in ct/kWh on used usage allowance
    {
      title: `${I18n.t(T.customerGuidance.purchase.flatOffer.vatPerKwhOnUsedUsageAllowance)}:`,
      value: ConfigurationHelper.formatPricePerKwh(conf.valueAddedTaxPerKwh, currency, { asMinorUnit: true }),
    },
  ];
};

export const factorizeVppBonusInfo = (
  conf: Configuration, 
  offerTaxRegulation: OfferTaxRegulation, 
  isDetailedViewOpen: boolean,
): ConfigurationLineProps[] => {
  const currency = ConfigurationHelper.getCurrencyForMarket(conf.market);

  const vppBonusStandardTaxation = 
    conf.powerPlant.guaranteedVppBonusGranted && conf.powerPlant.guaranteedVppBonusGrossPerYear
      ? `${I18n.t(T.customerGuidance.purchase.flatOffer.minimum)} `
      + `${ConfigurationHelper.formatMeasurementPrice(conf.powerPlant.guaranteedVppBonusGrossPerYear, currency)}`
      + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`
      : '';
  const vppBonusSmallEnterpriseTaxation = 
    conf.powerPlant.guaranteedVppBonusGranted && conf.powerPlant.guaranteedVppBonusNetPerYear
      ? `${I18n.t(T.customerGuidance.purchase.flatOffer.minimum)} `
      + `${ConfigurationHelper.formatMeasurementPrice(conf.powerPlant.guaranteedVppBonusNetPerYear, currency)}`
      + `/${I18n.t(T.customerGuidance.purchase.flatOffer.year)}`
      : '';

  const vppBonus = offerTaxRegulation === OfferTaxRegulation.STANDARD
    ? vppBonusStandardTaxation
    : vppBonusSmallEnterpriseTaxation;

  if (!isDetailedViewOpen) {
    return [
      // VPP bonus
      {
        title: I18n.t(T.customerGuidance.purchase.flatOffer.vppBonus),
        value: vppBonus,
      },
    ];
  }

  return [
    // VPP bonus
    {
      title: I18n.t(T.customerGuidance.purchase.flatOffer.vppBonus),
      value: vppBonus,
      helperLabel: offerTaxRegulation === OfferTaxRegulation.SMALL_ENTERPRISE 
        ? I18n.t(T.customerGuidance.purchase.flatOffer.zeroPercentVatHelper)
        : undefined,
    },
  ];
};
