import { PageHeadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { getRouteFromPath } from '+app/router/router.helper';
import { useRouterState } from '+app/router/RouterProvider.component';
import { PageMetaTitle } from '+app/shared/components';
import { PageProps } from './Page.component';

import './Page.web.component.scss';

export const PageWeb: React.FC<PageProps> = ({
  children,
  meta,
  title,
  options,
  headerTestId,
}) => {
  const { availableFlatRoutes } = useRouterState();
  const route = getRouteFromPath(window.location.pathname, availableFlatRoutes);
  const pageTitle = route?.title?.web || title;
  const pageMeta = route?.meta || meta || pageTitle;

  return (
    <div className={'c-page'}>
      {pageMeta && (
        <PageMetaTitle value={I18n.t(pageMeta)} />
      )}
      {pageTitle && (
        <PageHeadline
          dataTestId={headerTestId}
          smallGap={Boolean(options?.isHeadlineSmallGap)}
          sideContent={options?.isHeadlineSideContent || null}
        >
          <Translate
            value={pageTitle || ''}
            dangerousHTML={true}
          />
        </PageHeadline>
      )}
      {children}
    </div>
  );
};
