import { T } from '@sonnen/shared-i18n/customer';
import { ExpandableSection } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { formatDate } from '+app/utils/format.util';
import { BatteryUnit, BatteryUnits } from '+battery/store/types/battery.interface';
import { SpecsItem } from '+shared/components';

import './BatteryUpdateHistory.scss';

type Props = {
  data: BatteryUnits;
};

const UNEXTENDABLE_ROWS = 5;

const renderHistoryRow = ({ serialNumber, activationDate }: BatteryUnit) => (
  <div key={serialNumber}>
    <div className={'c-battery-update-history__grid'}>
      <section className={'c-battery-update-history__grid-column'}>
        <SpecsItem
          dataTestId={'sonnen-batterie-update-history-serial-number'}
          title={''}
          value={serialNumber}
        />
      </section>
      <section className={'c-battery-update-history__grid-column'}>
        <SpecsItem
          dataTestId={'sonnen-batterie-update-history-activation-date'}
          title={''}
          value={formatDate(activationDate, I18n.t(T.general.date.default))}
        />
      </section>
    </div>
    <div className={'c-battery-update-history__divider'} />
  </div>
);

export const BatteryUpdateHistory: React.FC<Props> = ({ data }) => (
  <div className={'o-grid'}>
    <section className={'o-grid__column o-grid__column--sm o-grid__column--no-gap-sm'}>
      <div className={'c-battery-update-history__grid'}>
        <section className={'c-battery-update-history__grid-column'}>
          <SpecsItem
            title={I18n.t(T.general.labels.serialNumber)}
            value={null}
          />
        </section>
        <section className={'c-battery-update-history__grid-column'}>
          <SpecsItem
            title={I18n.t(T.batterySystem.details.sections.history.parts.exchangeDate)}
            value={null}
          />
        </section>
      </div>
      {data.slice(0, UNEXTENDABLE_ROWS).map(unit => renderHistoryRow(unit))}
      {data.length >= UNEXTENDABLE_ROWS && (
        <ExpandableSection
          buttonLabel={I18n.t(T.batterySystem.details.sections.history.buttonLabel)}
          buttonOnLeftSide={true}
        >
          {data.slice(UNEXTENDABLE_ROWS).map(unit => renderHistoryRow(unit))}
        </ExpandableSection>
      )}
    </section>
    <section className={'o-grid__column o-grid__column--sm o-grid__column--no-gap-sm'}>
      {/** fake column to keep the first one half width */}
    </section>
  </div>
);
