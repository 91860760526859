import { getPaths } from '+app/router/router.helper';
import { Offer, OfferProduct, OfferProductType } from './types/offer.interface';

// NOTE: It was needed to pass tests
const paths = getPaths && getPaths() || {};

// @TODO: Get list from router.config instead of duplicating it
export const GUIDE_ROUTES = [
  paths.GUIDE,
  paths.GUIDE_CONTACT,
  paths.GUIDE_PRODUCT_INFO,
  paths.GUIDE_DOCUMENTS,
  paths.GUIDE_DISCOVERY,
  paths.GUIDE_DISCOVERY_STEP,
  paths.GUIDE_PURCHASE,
  paths.GUIDE_PURCHASE_STEP,
  paths.GUIDE_ACCEPTANCE,
  paths.GUIDE_ACCEPTANCE_ID,
  paths.GUIDE_ACCEPTANCE_ID_HARDWARE,
  paths.GUIDE_ACCEPTANCE_ID_WRONG_DETAILS,
  paths.GUIDE_SETUP,
  paths.GUIDE_SETUP_STEP,
  paths.GUIDE_USAGE,
  paths.GUIDE_LOGOUT,
  paths.GUIDE_OVERVIEW,
  paths.GUIDE_PHASES,
  paths.GUIDE_MY_ACCOUNT,
];

export const isFlatProduct = (product: OfferProduct) =>
  [OfferProductType.FLAT_X, OfferProductType.FLAT_DIRECT].includes(product.productType);

export const getOfferFlatProduct = (offer: Offer) => offer.products.find(isFlatProduct);
