import { QueryData } from '+shared/store/query/query.state';

export const QUERY_DATA_MOCK: QueryData = {
  status: {
    success: true,
    error: false,
    notReady: false,
    pending: false,
    interrupted: false,
  },
  errorCount: 0,
};
