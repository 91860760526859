export * from './lead.interface';
export * from './leadStatus.interface';
export * from './configuration.interface';
export * from './offer.interface';
export * from './document.interface';
export * from './bankData.interface';
export * from './impactAnalysis.interface';
export * from './energyProvider.interface';
export * from './utilityData.interface';
export * from './proposal.interface';
export * from './cessionDocument.interface';
