import { Loadable } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import { PortalExit } from '+app/shared/helpers';
import { Hint } from '+app/shared/store/sync/types/hint.interface';
import { AppLoader } from '..';
import { LayoutContext } from './Layout.context';

import './Layout.component.scss';

export interface LayoutLoader {
  isLoading: boolean;
  loaderHint?: Hint;
  customLoader?: React.ReactNode;
}

interface Props {
  loader?: LayoutLoader;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  contentTop?: React.ReactNode;
  contentBottom?: React.ReactNode;
  side?: React.ReactNode;
  isScrollDisabled?: boolean;
  isThemeMainNoTopGap?: boolean;
}

export const getLoader = (loader: LayoutLoader) => {
  return loader && loader.customLoader
    ? loader.customLoader
    : loader.loaderHint
      ? <AppLoader hint={loader.loaderHint} />
      : null;
};

export const Layout: React.FC<Props> = ({
  loader,
  header,
  footer,
  contentTop,
  contentBottom,
  side,
  isScrollDisabled,
  isThemeMainNoTopGap,
  children,
}) => {
  const [grayBackground, setGrayBackground] = React.useState(false);

  return(
    <LayoutContext.Provider value={{
      setGrayBackground,
    }}>
      <div className={classNames('c-layout', {
        'c-layout--gray': grayBackground,
        'c-layout--no-side': !side,
        // @TODO: Move to body tag to prevent page jumping to the top?
        'is-disabled': isScrollDisabled,
      })}>
        <div className={'c-layout__header'}>
          {header}
        </div>
        <div
          id={PortalExit.LAYOUT_CONTENT_TOP}
          className={'c-layout__portal'}
        />
        <div className={'c-layout__content-top'}>
          {contentTop}
        </div>
        {side && (
          <div className={'c-layout__side'}>
            <div className={'c-layout__side-inner'}>
              {side}
              {/* @TODO: This should not be layout responsibility */}
              {(loader && loader.isLoading) && (
                <span className={'c-layout__side-overlay'} />
              )}
            </div>
          </div>
        )}
        <div className={'c-layout__main'}>
          <div className={classNames('c-layout__main-inner', {
            'c-layout__main-inner--no-top-gap': isThemeMainNoTopGap,
          })}>
            {loader ? (
              <Loadable
                transition={'fade'}
                predicate={loader.isLoading}
                loader={getLoader(loader)}
                isCentered={true}
              >
                {children}
              </Loadable>
            ) : (
              children
            )}
          </div>
        </div>
        <div className={'c-layout__content-bottom'}>
          {contentBottom}
        </div>
        <div className={'c-layout__footer'}>
          {footer}
        </div>
      </div>
      <div id={PortalExit.ROOT} />
    </LayoutContext.Provider>
  );
};
