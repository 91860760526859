import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GuideGridStretched, GuideInfoCard, GuideInfoCardText } from '+app/+guide/components';
import { GuideAccordion } from '+app/+guide/containers';
import { GuideStepResponsibility, GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StepName } from '+app/+guide/helpers/steps.config';

type Props =
  & RouteComponentProps
  ;

const GuidePurchaseStepOrderConfirmationCommonComponent: React.FC<Props> = () => {
  const content = {
    [GuideStepStatus.PREVIEW]: {
      headline: I18n.t(T.customerGuidance.purchase.step.flatOrderConfirmation.headline.active),
      tag: I18n.t(T.customerGuidance.toDoBy, {name: GuideStepResponsibility.SONNEN}),
      status: 'open',
    },
  };

  return (
    <GuideAccordion
      status={GuideStepStatus.PREVIEW}
      anchor={StepName.COMMON_ORDER_CONFIRMATION}
      title={I18n.t(T.customerGuidance.purchase.step.orderConfirmation.headline)}
      tag={content[GuideStepStatus.PREVIEW].tag}
      tagTheme={content[GuideStepStatus.PREVIEW].status}
    >
      {{
        [GuideStepStatus.PREVIEW]: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.purchase.step.orderConfirmation.information.headline)}
              headerIcon={<Icon.Info />}
            >
              <GuideInfoCardText
                subtitle={I18n.t(T.customerGuidance.purchase.step.orderConfirmation.information.title)}
                contentText={I18n.t(T.customerGuidance.purchase.step.orderConfirmation.information.description)}
              />
            </GuideInfoCard>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.purchase.step.orderConfirmation.whatHappensNext.headline)}
              headerIcon={<Icon.Contract />}
              isIconBig={true}
            >
              <GuideInfoCardText
                subtitle={I18n.t(T.customerGuidance.purchase.step.orderConfirmation.whatHappensNext.title)}
                contentText={I18n.t(T.customerGuidance.purchase.step.orderConfirmation.whatHappensNext.description)}
              />
            </GuideInfoCard>
          </GuideGridStretched>
        ),
      }}
    </GuideAccordion>
  );
};

export const GuidePurchaseStepOrderConfirmationCommon =
  withRouter(GuidePurchaseStepOrderConfirmationCommonComponent);
