import { createLayoutMap, MATCH_PARENT, PARENT_ID } from '@kanva/core';

import { ACCESSORIES_CHART_CONTAINER_HEIGHT } from '../';
import { X_AXIS_MARGIN } from '../../helpers/analysis.helper';
import { Views } from './AnalysisLineChart.helper';

const commonLayout = {
  wrapper: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
    alignTop: PARENT_ID,
    above: Views.X_AXIS,
    alignEnd: PARENT_ID,
  },
  xAxis: {
    width: MATCH_PARENT,
    height: 30,
    alignStart: PARENT_ID,
    alignEnd: PARENT_ID,
    alignBottom: PARENT_ID,
    minHeight: X_AXIS_MARGIN.VERTICAL + 20,
  },
};

const singleLineChartLayout = {
  width: MATCH_PARENT,
  height: ACCESSORIES_CHART_CONTAINER_HEIGHT,
  alignBottom: PARENT_ID,
};

export const getLayout = (hasHeater: boolean, hasBackupBox: boolean) =>
  hasHeater && hasBackupBox
    ? createLayoutMap({
        ...commonLayout,
        heaterPowerChart: {
          width: MATCH_PARENT,
          height: ACCESSORIES_CHART_CONTAINER_HEIGHT,
          above: hasBackupBox ? Views.BACKUP_BOX_CHART : undefined,
          alignBottom: hasBackupBox ? undefined : PARENT_ID,
        },
        backupBoxChart: {
          width: MATCH_PARENT,
          height: ACCESSORIES_CHART_CONTAINER_HEIGHT,
          alignBottom: PARENT_ID,
        },
      })
    : createLayoutMap({
        ...commonLayout,
        heaterPowerChart: singleLineChartLayout,
        backupBoxChart: singleLineChartLayout,
      });
