import * as R from 'ramda';
import * as packageJson from '../../package.json';
import { Env, isMobile } from './environment';

export interface AppConfiguration {
  APP_NAME: string;
  VERSION: string;
  SENTRY_DSN: string;
  DEVELOPMENT_MODE: boolean;
  AUTHORIZED_DOMAINS: string[];
  IS_MOBILE: boolean;
}

export const getAppConfig = (env: Env): AppConfiguration => ({
  APP_NAME: 'CustomerPortal',
  VERSION: packageJson.version,
  SENTRY_DSN: 'https://8222a024ea46dc16606717dda0de2e0d@sentry.sonnenbatterie.de/83',
  DEVELOPMENT_MODE: R.includes(window.location.hostname, ['localhost', '0.0.0.0']),
  AUTHORIZED_DOMAINS: ['sonnen.de', 'sonnenbatterie.de', 'sonnen.it',
    ...(env === Env.DEVELOPMENT ? ['execute-api.eu-central-1.amazonaws.com'] : []),
    ...(env === Env.LOCAL ? ['localhost:4000'] : []),
  ],
  IS_MOBILE: isMobile,
});
