import { storeProvider } from '+app/store/store.provider';
import { AuthActions } from '+auth/store/auth.actions';
import { AuthRepository } from '+auth/store/auth.repository';
import {
  getAuthorizationHeader,
  getImpersonatedUserId,
  isAuthenticated,
} from '+auth/store/auth.selectors';
import { NetworkCode, ResponseError } from '+shared/network/network.interface';
import { isAuthorizedDomain, isResponseError } from '+shared/network/network.util';
import { Reporter } from '+utils/reporter.util';
import {
  getHostname,
  HttpFetch,
  HttpInterceptor,
  HttpResponse,
  NormalizedHttpOptions,
} from '@coolio/http';

const isUnauthorizedError = (error: ResponseError) => error.response.status === NetworkCode.UNAUTHORIZED;
const PROMISE_CANCELLED = 'Promise Cancelled';
type CancelPendingTokenRefresh = () => void | null;

export const createAuthInterceptor = (): HttpInterceptor => {
  const pendingRequests: Array<() => void> = [];
  let isAuthInProgress = false;
  let isRequestInProgress = false;
  let cancelPendingTokenRefresh: CancelPendingTokenRefresh = () => null;

  const forceLogout = () => {
    pendingRequests.splice(0, pendingRequests.length);
    isAuthInProgress = isRequestInProgress = false;
    storeProvider.dispatch(AuthActions.logOut());
  };

  const deferRequest = <T extends any>(promise: () => Promise<T>): Promise<T> =>
    new Promise((resolve, reject) => {
      pendingRequests.push(() => {
        promise().then(resolve, reject);
      });
    });

  const cancellableRefreshToken: typeof AuthRepository.refreshAccessToken = () => new Promise((resolve, reject) => {
    cancelPendingTokenRefresh();
    let abortController: AbortController | undefined;
    if (AbortController) {
      abortController = new AbortController();
    }
    cancelPendingTokenRefresh = () => { reject(PROMISE_CANCELLED); abortController?.abort(); };
    return AuthRepository.refreshAccessToken(abortController?.signal)
      .then(response => resolve(response))
      .catch(error => reject(error));
  });

  const requestAuth = async () => {
    try {
      isAuthInProgress = true;
      const { tokenType, refreshToken, accessToken } = await cancellableRefreshToken();
      storeProvider.dispatch(AuthActions.refreshToken(accessToken, refreshToken, tokenType));
      isAuthInProgress = false;
    } catch (err) {
      if (err === PROMISE_CANCELLED) {
        return;
      }
      Reporter.log('Failed to refresh tokens');
      Reporter.reportError(err, {
        auth: storeProvider.getState().auth,
      });

      forceLogout();
      throw err;
    }
  };

  const resume = () => {
    if (isRequestInProgress) {
      return;
    }
    const pendingRequest = pendingRequests.shift();
    if (pendingRequest) {
      pendingRequest();
    }
  };

  const processRequest = async <T extends any>(
    promise: HttpFetch<T>,
    options: NormalizedHttpOptions,
  ): Promise<HttpResponse<T>> => {
    if (isAuthInProgress || isRequestInProgress) {
      return deferRequest(() => processRequest(promise, options));
    }
    isRequestInProgress = true;
    try {
      if (isAuthorizedDomain(getHostname(options.url)) && options.headers) {
        const authorizationHeader = isAuthenticated(storeProvider.getState()) &&
          getAuthorizationHeader(storeProvider.getState());
        const impersonateHeader = isAuthenticated(storeProvider.getState()) &&
          getImpersonatedUserId(storeProvider.getState());
        if (authorizationHeader) {
          options.headers.Authorization = authorizationHeader;
        }
        if (impersonateHeader) {
          options.headers['X-Impersonate-User'] = impersonateHeader;
        }
      }
      const response = await promise();

      // Resume pending requests
      isRequestInProgress = false;
      resume();
      return response;
    } catch (error) {
      // Mark request not in progress on failure
      isRequestInProgress = false;
      throw error;
    }
  };

  return <T extends any>(
    promise: HttpFetch<T>,
    options: NormalizedHttpOptions,
  ): HttpFetch<T> => async () => {
    try {
      return await processRequest(promise, options);
    } catch (error) {
      if (!isResponseError(error) || !isUnauthorizedError(error)) {
        resume();
        throw error;
      }
      await requestAuth();
    }
    return await processRequest(promise, options);
  };
};
