import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { GeoJsonModel } from '../../communityMap.api';
import Map from './Map';
import { batteryStyles, worldMapStyles } from './mapStyles';
import { MapLoader } from '../../../../components/loaders/MapLoader/MapLoader';

const WithMap = (EnhancedComponent) => {
  class Mapped extends PureComponent {
    constructor(props) {
      super(props);

      this.markerHandlers = [];

      this.state = {
        markerPosition: {},
        popupVisible: true,
      };

      this.map = null;
      this.communityMapDataLayer = null;
      this.worldMapDataLayer = null;
      this.userMarkerLayer = null;

      this.mapCreator = Map({
        markerClass: 'c-community-map__icon icofont-user',
        markerLocationClass: 'c-community-map__icon-location',
      });

      this.setMapRef = (element) => {
        this.mapRef = element;
      };
    }

    componentDidMount() {
      this.map = this.mapCreator.createMap(this.mapRef, [])
        .on('move', this.setMarkerPosition);
      this.mapCreator.offSetMap(this.map);
    }

    componentDidUpdate(prevProps) {
      const {
        map,
        props: { pinGeometry, communityMapData, worldMapData },
      } = this;

      const worldDataUpdateRequired = worldMapData !== prevProps.worldMapData ||
        (worldMapData && !this.worldMapDataLayer);
      const batteryDataUpdateRequired = communityMapData !== prevProps.communityMapData ||
        (communityMapData && !this.communityMapDataLayer);
      const pinUpdateRequired = pinGeometry !== prevProps.pinGeometry ||
        (pinGeometry && !this.userMarkerLayer);

      if (pinUpdateRequired) {
        if (this.userMarkerLayer) {
          this.userMarkerLayer.removeFrom(map);
          this.userMarkerLayer = null;
        }
        if (pinGeometry) {
          this.userMarkerLayer = this.mapCreator.createMarker(pinGeometry)
            .on('click', () => this.setState(state => ({ ...state, popupVisible: !state.popupVisible })));
          this.userMarkerLayer.addTo(map);
          this.markerHandlers.forEach(cb => this.onMarkerClick(cb, false));
        }
        this.setMarkerPosition();
      }

      if (worldDataUpdateRequired) {
        if (this.worldMapDataLayer) {
          this.worldMapDataLayer.removeFrom(map);
          this.worldMapDataLayer = null;
        }
        if (worldMapData) {
          this.worldMapDataLayer = this.mapCreator.createVectorLayer(worldMapData, worldMapStyles)
            .setZIndex(0);
          this.worldMapDataLayer.addTo(map);
        }
      }

      if (batteryDataUpdateRequired) {
        if (this.communityMapDataLayer) {
          this.communityMapDataLayer.removeFrom(map);
          this.communityMapDataLayer = null;
        }
        if (communityMapData) {
          this.communityMapDataLayer = this.mapCreator.createVectorLayer(communityMapData, batteryStyles)
            .setZIndex(1);
          this.communityMapDataLayer.addTo(map);
        }
      }
    }

    componentWillUnmount() {
      if (this.map) {
        this.map.remove();
      }
    }

    onMarkerClick = (eventHandler, initialize = true) => {
      if (initialize) {
        this.markerHandlers.push(eventHandler);
      }
      if (this.userMarkerLayer) {
        this.userMarkerLayer.on('click', eventHandler);
      }
    };

    setMarkerPosition = () => {
      this.setState(() => ({
        markerPosition: this.getMarkerPosition(this.map, this.userMarkerLayer),
      }));
    };

    getMarkerPosition = (map, userMarker) => {
      return map && userMarker
        ? map.latLngToContainerPoint(userMarker.getLatLng())
        : {};
    };

    recenterMapAtUser = () => {
      const { pinGeometry } = this.props;
      this.mapCreator.recenterMapAtUser(this.map, pinGeometry);
    };

    render() {
      const {
        badge, worldMapData, communityMapData, ...props
      } = this.props;
      const isReady = !!communityMapData && !!worldMapData;
      const mapHeight = this.mapRef ? this.mapRef.clientHeight : null;
      const mapWidth = this.mapRef ? this.mapRef.clientWidth : null;

      return (
        <div className="c-community-map">
          <div
            data-test-id={'sonnen-community-map-js'}
            className="c-community-map__map"
            ref={this.setMapRef}
          >
            {!!this.userMarkerLayer && (
              <EnhancedComponent
                {...this.state}
                {...props}
                recenterMapAtUser={this.recenterMapAtUser}
                onMarkerClick={this.onMarkerClick}
                mapSize={{ mapHeight, mapWidth }}
              />
            )}
          </div>
          {badge && (
            <div className="c-community-map__badge-wrapper">
              {badge}
            </div>
          )}
          {!isReady && (
            <div className="c-community-map__loader">
              <MapLoader />
            </div>
          )}
        </div>
      );
    }
  }

  Mapped.propTypes = {
    worldMapData: GeoJsonModel,
    communityMapData: GeoJsonModel,
    pinGeometry: PropTypes.arrayOf(PropTypes.number),
    badge: PropTypes.element,
  };

  Mapped.defaultProps = {
    worldMapData: null,
    communityMapData: null,
    pinGeometry: [],
    badge: null,
  };

  return Mapped;
};

export default WithMap;
