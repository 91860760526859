import { Query } from '+shared/store/query/query.state';
import { Site } from './types/site.interface';

export const GET_SITES_QUERY = 'getSitesQuery';

export interface SiteState {
  activeSiteId: string | undefined;
  sitesList: Site[];
  [GET_SITES_QUERY]: Query<Site>;
}

export const initialState: SiteState = {
  activeSiteId: undefined,
  sitesList: [],
  [GET_SITES_QUERY]: {},
};
