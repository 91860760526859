import { StoreState } from '+app/store/store.interface';
import { createSelector } from 'reselect';

export const getCommunityState = (state: StoreState) => state.community;

export const getCommunityProfile = createSelector(
  getCommunityState,
  state => state.profile,
);

export const getRecentMembersCount = createSelector(
  getCommunityState,
  state => state.stats.numberOfRecentNewMembers,
);

export const isCardVisible = createSelector(
  getCommunityState,
  state => state.isCardVisible,
);

export const getActiveCardType = createSelector(
  getCommunityState,
  state => state.activeMapCardType,
);

export const getCommunityContentHubArticles = createSelector(
  getCommunityState,
  state => state.contentHubNews,
);
