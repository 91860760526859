import * as classNames from 'classnames';
import * as React from 'react';

import './HeaderSpacingContainer.component.scss';

export const HeaderSpacingContainer: React.FC = ({ children }) => {
  const isMultipleChildren = React.Children.toArray(children).length > 1;

  return isMultipleChildren ? (
    <div className={'c-header-spacing-container'}>
      {React.Children.map(children, (child: React.ReactNode, i) => (
        <div className={classNames('c-header-spacing-container__item', {
          'c-header-spacing-container__item--spaced': i !== 0,
        })}>
          {child}
        </div>
      ))}
    </div>
  ) : (
    <>
      {children}
    </>
  );
};
