import { ActionsUnion, createAction } from '+utils/redux';
import { SerializableData } from './mobile.interface';
import { MobileAppState } from './mobile.state';

export enum INTEGRATION_ACTIONS {
  READY = '[Integration] READY',
  RELOAD = '[Integration] RELOAD',
  CONSOLE = '[Integration] CONSOLE',
  SET_STORAGE_ITEM = '[Integration] SET_STORAGE_ITEM',
  REMOVE_STORAGE_ITEM = '[Integration] REMOVE_STORAGE_ITEM',
  SET_STORAGE = '[Integration] SET_STORAGE',
  HISTORY_EVENT = '[Integration] HISTORY_EVENT',
  WAIT_UNTIL_NOTIFIED = '[Integration] WAIT_UNTIL_NOTIFIED',
  NOTIFIED = '[Integration] NOTIFIED',
  SET_APP_STATE = '[Integration] SET_APP_STATE',
  SEND_DATA_TO_CACHE = '[Integration] SEND_DATA_TO_CACHE',
  SEND_CACHED_DATA = '[Integration] SEND_CACHED_DATA',
  OVERRIDE_MOBILE_URL = '[Integration] OVERRIDE_MOBILE_URL',
  FORWARD_VERSION_INFO = '[Integration] FORWARD_VERSION_INFO',
  SEND_ENCODED_FILE = '[Integration] SEND_ENCODED_FILE',
}

export const IntegrationActions = {
  ready: () => createAction(INTEGRATION_ACTIONS.READY),
  reload: () => createAction(INTEGRATION_ACTIONS.RELOAD),
  console: (method: 'log' | 'warn' | 'error', args: any[]) =>
    createAction(INTEGRATION_ACTIONS.CONSOLE, { method, args }),
  setStorageItem: (key: string, value: string) => createAction(INTEGRATION_ACTIONS.SET_STORAGE_ITEM, { key, value }),
  removeStorageItem: (key: string) => createAction(INTEGRATION_ACTIONS.REMOVE_STORAGE_ITEM, { key }),
  historyEvent: (method: string, url?: string) => createAction(INTEGRATION_ACTIONS.HISTORY_EVENT, { method, url }),
  waitUntilNotified: () => createAction(INTEGRATION_ACTIONS.WAIT_UNTIL_NOTIFIED),
  setAppState: (state: MobileAppState) => createAction(INTEGRATION_ACTIONS.SET_APP_STATE, { state }),
  sendDataToCache: (name: string, data: SerializableData) =>
    createAction(INTEGRATION_ACTIONS.SEND_DATA_TO_CACHE, { name, data }),
  sendCachedData: (data: string) =>
    createAction(INTEGRATION_ACTIONS.SEND_CACHED_DATA, { data }),
  overrideMobileUrl: (url: string) =>
    createAction(INTEGRATION_ACTIONS.OVERRIDE_MOBILE_URL, { url }),
  forwardVersionInfo: (versionName: string, flagConfig: string, instanceId: string) =>
    createAction(INTEGRATION_ACTIONS.FORWARD_VERSION_INFO, { versionName, flagConfig, instanceId }),
  sendEncodedFile: (payload: { file: string, fileName: string }) =>
    createAction(INTEGRATION_ACTIONS.SEND_ENCODED_FILE, payload),
};

export type IntegrationActions = ActionsUnion<typeof IntegrationActions>;
