import { AreaChartView, DataContainer, DataContainerTransformExtensionOptions, PointAccessor, XYPoint } from '@kanva/charts';
import { View } from '@kanva/core';
import { T } from '@sonnen/shared-i18n/customer';
import {
  AccessoriesChartSeries,
  BarChartSeries,
  EnergyFlowSeriesKey,
  Point,
  SharedChartColors,
  TimeHelper,
} from '@sonnen/shared-web';
import { isEmpty, isNil, last } from 'lodash';
import * as moment from 'moment';
import { MomentInput } from 'moment';
import { I18n } from 'react-redux-i18n';
import { DeepPartial } from 'redux';

import { BatteryHelper } from '+app/+dashboard/+battery/store/battery.helpers';
import { Battery } from '+app/+dashboard/+battery/store/types/battery.interface';
import { LiveStateHelper } from '+app/+dashboard/+live-state/helpers/LiveState.helpers';
import { SiteLive } from '+app/shared/store/live/types/siteLiveData.interface';
import { CONFIG } from '+config';
import { DateHelper } from '+legacy/helpers/dates';
import { AnalysisDayChartLastXYSeriesPosition } from '../containers/AnalysisKanvaProvider/AnalysisDayChartProvider';
import {
  AreaChartSeries,
} from '../store/types/dataSeries.interface';

const hasLastXYSeriePosition = (lastXYSeriePosition: XYPoint<number> | undefined) =>
  lastXYSeriePosition && lastXYSeriePosition!.x !== 0 && lastXYSeriePosition!.y !== 0;

const DATE_FORMATS = {
  day: T.general.date.default,
  month: T.general.date.month,
  year: T.general.date.year,
};

export const getDateFormatForPeriod = (period: 'day' | 'month' | 'year') => {
  return I18n.t(DATE_FORMATS[period]);
};

export const CHART_HEIGHT = 250; // Note: bar chart and day chart
export const TOP_DISTANCE = 25;
export const X_AXIS_PADDING = 4;
export const Y_AXIS_PADDING = 4;
export const HORIZONTAL_DISTANCE = 25;
export const X_AXIS_MARGIN = {
  VERTICAL: 10,
};

export const formatDate = (date: moment.MomentInput, format?: string) => {
  if (TimeHelper.isToday(date)) {
    return I18n.t(T.mobileApp.analysis.dateLabels.today);
  }
  if (TimeHelper.isYesterday(date)) {
    return I18n.t(T.mobileApp.analysis.dateLabels.yesterday);
  }

  return String(DateHelper.getFormattedDate(date, format));
};

export const isSiteChanged = (prevSiteId: string | undefined, siteId: string | undefined) =>
  (prevSiteId !== siteId) && !!siteId;

export const shouldSetLatestMeasurements = (
  latestMeasurementsFetchDate: Date | undefined,
  siteLiveData: SiteLive | undefined,
  batteryData: Battery,
  isLiveQueryChanged: boolean,
) => {
  if (!latestMeasurementsFetchDate) { return; }

  // TODO refactor to use momentjs + directly siteLiveData.timestamp
  const latestLiveDate: string | undefined = siteLiveData
    ? siteLiveData.timestamp
    : undefined;
  const latestLiveDateTimestamp: number = latestLiveDate
    ? new Date(latestLiveDate).getTime()
    : 0;
  const latestMeasurementsDateTimestamp: number = latestMeasurementsFetchDate
    ? new Date(latestMeasurementsFetchDate).getTime()
    : 0;

  const isLiveDelayed = LiveStateHelper.isLiveDataDelayed(latestLiveDate); // TODO use moment and remove this helper
  const areMeasurementsUpdated = latestMeasurementsDateTimestamp > latestLiveDateTimestamp;

  return isLiveQueryChanged &&
    isLiveDelayed &&
    !areMeasurementsUpdated &&
    !BatteryHelper.isEaton(batteryData);
};

export const shouldShowForecast = (
  siteLiveData: SiteLive | undefined,
  hasForecast: boolean,
  selectedDate: moment.Moment,
): boolean =>
  !!(siteLiveData && siteLiveData.online) && hasForecast && selectedDate.isSameOrAfter(new Date(), 'day');

export const analysisPointAccessor: PointAccessor<Point> = ({ x, y }, _, all) =>
  ({y: y!, x: (x! - all[0].x!) / 60 });

export const mapDataSeries = (dataSeries: Partial<AccessoriesChartSeries | AreaChartSeries | BarChartSeries>) =>
  Object.keys(dataSeries).map(key => ({
    data: dataSeries[key],
    name: key,
  }));

export const resolveAreaXPosition = (dataSeriesKey: string) =>
  (dataContainer: DataContainer<any>, selectedDate: MomentInput) => {
    const series = dataContainer.getDataSeries(dataSeriesKey);
    if (isNil(series)) {
      return 0;
    }

    const data = series!.data.filter(point => !isNil(point.y));
    if (isEmpty(data)) {
      return 0;
    }
    const isChartFullyFilled = series.data.length === 24 * 60;

    return TimeHelper.isToday(selectedDate) && !isChartFullyFilled
      ? last(data)!.x
      : data[Math.floor(data.length / 2)].x;
  };

export const getXYFromAreaChartSeries = (
  chartView: View<any>,
  dataSeries: AreaChartSeries,
  serieKey: EnergyFlowSeriesKey,
) => {
  const serie = dataSeries[serieKey];

  if (
    !chartView
    || !serie
    || !last(serie)
    || !TimeHelper.isToday(TimeHelper.getDateFromUnixTime(serie[0].x || 0))
  ) { return; }

  const x = last(serie)!.x || 0;
  const y = last(serie)!.y || 0;
  const lastXYPoint = analysisPointAccessor({x, y}, serie.length, serie);

  const { absoluteX, absoluteY } = (chartView as AreaChartView).getCanvasPositionForPoint(lastXYPoint);

  // TODO: Consider another way to calculate Y position
  const offset = CONFIG.IS_MOBILE ? 75 : 19;

  return ({
    x: absoluteX,
    y: !absoluteY || isNaN(absoluteY) ? 0 : absoluteY + offset,
  });
};

const normalizeXYPositions = (position: { x?: number, y?: number } = {}, chartView: View | undefined) => {
  const { x = 0, y = 0 } = position;
  const maxHeight = chartView?.innerHeight || 0;
  const maxWidth = chartView?.innerWidth || window.innerWidth;
  // We have to ensure half of the dot on a chart won't overflow at it's peak value
  const paddedMaxWidth = maxWidth - 7;

  return {
    x: Math.min(x, paddedMaxWidth),
    y: Math.min(y, maxHeight),
  };
};

export const getVisibleLiveBubblesData = (
  lastXYSeriePosition: AnalysisDayChartLastXYSeriesPosition,
  siteLiveData: SiteLive | undefined,
  date: MomentInput,
  isEaton: boolean,
  isMK1: boolean,
  isLoading: boolean,
  chartView: View | undefined,
) => {
  const latestLiveDate: string | undefined = siteLiveData
    ? siteLiveData.timestamp
    : undefined;
  // NOTE: 120s is set because of IoT change
  const isLiveDelayed = LiveStateHelper.isLiveDataDelayed(latestLiveDate, 120000);

  if (isLiveDelayed || isEaton || isMK1 || isLoading || !TimeHelper.isToday(date)) { return []; }

  const visibleBubblesData = [];
  const lastXYSeriePositionForProduction = lastXYSeriePosition[EnergyFlowSeriesKey.PRODUCTION_POWER];
  const lastXYSeriePositionForConsumption = lastXYSeriePosition[EnergyFlowSeriesKey.CONSUMPTION_POWER];
  const tooltipTagWidth = 100;

  const isReversed = (chartView || window).innerWidth - (lastXYSeriePositionForConsumption?.x || 0) < tooltipTagWidth;

  if (hasLastXYSeriePosition(lastXYSeriePositionForProduction)) {
    visibleBubblesData.push({
      color: SharedChartColors.PRODUCTION,
      value: siteLiveData && siteLiveData.productionPower || '-',
      position: normalizeXYPositions(lastXYSeriePositionForProduction, chartView),
      isReversed,
    });
  }

  if (hasLastXYSeriePosition(lastXYSeriePositionForConsumption)) {
    visibleBubblesData.push({
      color: SharedChartColors.CONSUMPTION,
      value: siteLiveData && siteLiveData.consumptionPower || '-',
      position: normalizeXYPositions(lastXYSeriePositionForConsumption, chartView),
      isReversed,
    });
  }

  return visibleBubblesData;
};

export const mobileScaleOptions: DeepPartial<DataContainerTransformExtensionOptions> = {
  scale: {
    drag: true,
    scroll: false,
    selectArea: false,
  },
};

export const desktopScaleOptions: DeepPartial<DataContainerTransformExtensionOptions> = {
  scale: {
    drag: false,
    scroll: false,
    selectArea: true,
    minSelectedAreaThreshold: { x: 50, y: 0 },
  },
};
