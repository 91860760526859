import { T } from '@sonnen/shared-i18n/customer';
import { getPasswordStrength } from '@sonnen/shared-web';

import { I18n } from 'react-redux-i18n';

import { errorBuilder } from '+legacy/helpers/error';
import { isPasswordValid } from '+shared/helpers';
import { AccountPasswordChangeFormValues } from './AccountPasswordChange.types';

const validate = (values: AccountPasswordChangeFormValues) => {
  const errors = {} as Record<string, any>;
  const emptyPasswordError = errorBuilder(I18n.t(T.general.errors.password.empty));
  const passwordToWeakError = errorBuilder(I18n.t(T.general.errors.password.criteriaNotMatched));

  if (!values.previousPassword) {
    errors.previousPassword = emptyPasswordError;
  }

  if (!isPasswordValid(values.password)) {
    errors.password = passwordToWeakError;
  }

  if (!values.password) {
    errors.password = emptyPasswordError;
  }

  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = emptyPasswordError;
  }

  if (values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = errorBuilder(I18n.t(T.general.errors.password.doNotMatch));
  }

  return errors;
};

const warn = (values: Record<string, any>) => {
  const warnings: any = {};

  if (values.password) {
    warnings.password = getPasswordStrength(values.password);
  }

  return warnings;
};

export const AccountPasswordChangeHelper = {
  validate,
  warn,
};
