import { CONFIG } from '+config';
import { useJsonApi } from '+shared/network';
import { LegalDocumentsData } from './types/legalDocuments.interface';

export const getLegalDocuments = () => useJsonApi()
  .getList<LegalDocumentsData>(`${CONFIG.API.USER_DATA}/legal/documents/accepted`)
  .send();

export const LegalRepository = {
  getLegalDocuments,
};
