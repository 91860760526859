import { Data, MergedData } from '@coolio/json-api';

export enum ContractFileType {
  CALL = 'call',
  CONTRACT = 'contract',
}

export enum FileMimeType {
  PDF = 'application/pdf',
  MP3 = 'application/mp3',
  MP4 = 'application/mp4',
  ZIP = 'application/zip',
}

export interface ContractFileRequestDataAttributes {
  type: ContractFileType;
  url: string;
  mime: FileMimeType;
  filesize: number;
  length: number; // time length of the mp3/mp4 files
  created: string; // date
  updated: string; // date
}

export type ContractFileRequestData = Data<ContractFileRequestDataAttributes>;

export type ContractFileRequest = MergedData<ContractFileRequestData>;
