import { T } from '@sonnen/shared-i18n/customer';
import { Button, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './GuideDocumentErrorModal.component.scss';

interface GuideDocumentErrorModalProps {
  onRegenerateClick: () => void;
}

export const GuideDocumentErrorModal: React.SFC<GuideDocumentErrorModalProps> = ({
  onRegenerateClick,
}) => {
  return (
    <div className={'c-guide-document-error-modal'}>
      <Icon.PaperPlaneError className={'c-guide-document-error-modal__icon'} />
      <p className={'c-guide-document-error-modal__headline'}>
        {I18n.t(T.customerGuidance.documents.documentErrorModal.heading)}
      </p>
      <div className={'c-guide-document-error-modal__text'}>
        {I18n.t(T.customerGuidance.documents.documentErrorModal.content)}
      </div>
      <Button onClick={onRegenerateClick} label={I18n.t(T.action.retry)} />
    </div>
  );
};
