import { createSelector } from 'reselect';

import { GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { getGuideCommonState, getGuideState } from '+app/+guide/store/+guide.selectors';
import { StoreState } from '+app/store/store.interface';
import { SET_SETUP_STEP_QUERY } from './+setup.state';

export const getGuideSetupState = createSelector(
  getGuideState,
  state => state.setup,
);

export const getGuideLead = createSelector(
  getGuideCommonState,
  state => state.lead,
);

export const getGuideLeadStatus = createSelector(
  getGuideLead,
  lead => lead ? lead.status : undefined,
);

export const getGuideLeadStatusSummary = createSelector(
  getGuideLeadStatus,
  status => status ? status.summary : undefined,
);

// @TODO: Implement when applicable.
export const getSetupWarrantyAcceptanceStatus = (state: StoreState) => GuideStepStatus.ACTIVE;

// DSO Registration

export const getSetupDsoRegistration = createSelector(
  getGuideLeadStatusSummary,
  state => state ? state.dsoRegistrationCompleted : undefined,
);

export const getSetupDsoRegistrationStatus = createSelector(
  getSetupDsoRegistration,
  state => state && state.isSet
    ? GuideStepStatus.DONE
    : GuideStepStatus.ACTIVE,
);

export const getSetupDsoRegistrationDate = createSelector(
  getSetupDsoRegistration,
  state => state && state.createdAt,
);

// DSO meter installation

export const getSetupDsoMeterInstallation = createSelector(
  getGuideLeadStatusSummary,
  state => state ? state.dsoMeterInstalled : undefined,
);

export const getSetupDsoMeterInstallationStatus = createSelector(
  getSetupDsoMeterInstallation,
  state => state && state.isSet
    ? GuideStepStatus.DONE
    : GuideStepStatus.ACTIVE,
);

export const getSetupDsoMeterInstallationDate = createSelector(
  getSetupDsoMeterInstallation,
  state => state && state.createdAt,
);

// Hardwave commisioning

export const getSetupHardwareCommissioning = createSelector(
  getGuideLeadStatusSummary,
  state => state ? state.hardwareCommissioningCompleted : undefined,
);

export const getSetupHardwareCommissioningStatus = createSelector(
  getSetupHardwareCommissioning,
  state => state && state.isSet
    ? GuideStepStatus.DONE
    : GuideStepStatus.ACTIVE,
);

export const getSetupHardwareCommissioningDate = createSelector(
  getSetupHardwareCommissioning,
  state => state && state.createdAt,
);

// BNetzA Registration

export const getSetupBNetzARegistration = createSelector(
  getGuideLeadStatusSummary,
  state => state ? state.bNetzARegistrationCompleted : undefined,
);

export const getSetupBNetzARegistrationStatus = createSelector(
  getSetupBNetzARegistration,
  state => state && state.isSet
    ? GuideStepStatus.DONE
    : GuideStepStatus.ACTIVE,
);

export const getSetupBNetzARegistrationDate = createSelector(
  getSetupBNetzARegistration,
  state => state && state.createdAt,
);

// Tax office registration

export const getSetupTaxOfficeRegistration = createSelector(
  getGuideLeadStatusSummary,
  state => state ? state.taxOfficeRegistrationCompleted : undefined,
);

export const getSetupTaxOfficeRegistrationStatus = createSelector(
  getSetupTaxOfficeRegistration,
  state => state && state.isSet
    ? GuideStepStatus.DONE
    : GuideStepStatus.ACTIVE,
);

export const getSetupTaxOfficeRegistrationDate = createSelector(
  getSetupTaxOfficeRegistration,
  state => state && state.createdAt,
);

export const getSetupStepQuery = createSelector(
  getGuideSetupState,
  state => state && state[SET_SETUP_STEP_QUERY].status,
);

export const getSetupProgress = (state: StoreState) => {
  const stepsStatuses = [
    getSetupTaxOfficeRegistrationStatus(state),
    getSetupBNetzARegistrationStatus(state),
    getSetupDsoMeterInstallationStatus(state),
    // getSetupWarrantyAcceptanceStatus(state),
    getSetupHardwareCommissioningStatus(state),
    getSetupDsoRegistrationStatus(state),
  ];

  return Math.floor(
    stepsStatuses
      .map(status => status === GuideStepStatus.ACTIVE ? 0 : 1 as number)
      .reduce((sum, current) => (sum + current), 0) / stepsStatuses.length * 100,
  );
};

export const getActiveSetupModal = (state: StoreState) => state.guide.setup.activeModal;

export const getSetupModalState = (state: StoreState) => state.guide.setup.isSetupModalOpen;
