import * as React from 'react';

import { ContractEnergyInfo, ContractEnergyMeter } from '../';

import './ContractEnergyMeterSection.component.scss';

interface Props {
  freeUsageAllowance: number;
  percentage: number;
}

const QUOTA_ALMOST_EXCEEDED_PERCENTAGE = 95;

export const ContractEnergyMeterSection: React.FC<Props> = ({
  freeUsageAllowance,
  percentage,
}) => {
  const shouldRenderInfo = percentage >= QUOTA_ALMOST_EXCEEDED_PERCENTAGE;

  return (
    <div className={'c-contract-energy-meter-section'}>
      {!shouldRenderInfo ? (
        <ContractEnergyMeter
          percentage={percentage}
          maxEnergyAmount={freeUsageAllowance}
        />
      ) : (
        <div className={'o-grid'}>
          <div className={'o-grid__column o-grid__column--sm-7 o-grid__column--no-gap-sm'}>
            <ContractEnergyMeter
              percentage={percentage}
              maxEnergyAmount={freeUsageAllowance}
            />
          </div>
          <div className={'o-grid__column o-grid__column--sm-5 o-grid__column--no-gap'}>
            <ContractEnergyInfo />
          </div>
        </div>
      )}
    </div>
  );
};
