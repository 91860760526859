import { T } from '@sonnen/shared-i18n/customer';
import { Feature } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { InfoBanner } from '+app/shared/components';
import { FeatureName } from '+config/featureFlags';

export const DashboardInfoBannerInaccurateMeasurements: React.FC = () => (
  <Feature name={FeatureName.INACCURATE_MEASUREMENTS_BANNER}>
    <InfoBanner
      message={I18n.t(T.general.messages.inaccurateMeasurementsBannerTitle)}
      longMessage={I18n.t(T.general.messages.inaccurateMeasurementsBannerDescription)}
      isWarning={true}
      seeMoreText={I18n.t(T.newsChannel.seeMoreText)}
      seeLessText={I18n.t(T.newsChannel.seeLessText)}
    />
  </Feature>
);
