import { IconComponent } from '@sonnen/shared-web/src/components/Icon/models';
import * as React from 'react';

import './MobileHeadline.component.scss';

interface Props {
  icon: IconComponent;
  label: React.ReactNode;
}

export const MobileHeadline: React.FC<Props> = ({ icon, label }) => {
  const HeadlineIcon = icon;

  return(
    <div className={'c-mobile-headline'}>
      <HeadlineIcon className={'c-mobile-headline__icon'}/>
      {label}
    </div>
  );
};
