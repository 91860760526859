import { createSelector } from 'reselect';

import { StoreState } from '+app/store/store.interface';

export const getLegalState = (store: StoreState) => store.legal;

export const getLegalDocuments = createSelector(
  getLegalState,
  state => state.legalDocuments,
);
