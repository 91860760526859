import * as React from 'react';

/**
 * @TODO: Maybe improve with a capturing RegExp group
 * in order to enable 'I wanna interpolate %{thing} because %{reason}'
 * links={{ thing: <AllTheStringsWeHave />, reason: <ICan /> }}
 */

export const LinkedTranslation: React.FC<{
  sentence: string,
  links: React.ReactNode[],
}> = ({ sentence, links }) => {
  const terms = sentence.split(/(?:%{[a-zA-Z0-9]+})/g);

  return (
    <>
      {terms.map((term, i) => i < terms.length - 1
        ? <span key={`${term}_${i}`}>{term}{links[i]}</span>
        : <span key={`${term}_${i}`}>{term}</span>,
      )}
    </>
  );
};
