import { T } from '@sonnen/shared-i18n/dist/customer.scope';
import { Icon } from '@sonnen/shared-web';
import * as moment from 'moment';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { StateAlert } from '+components';

import './AnalysisStatusDelayed.component.scss';

interface Props {
  timestamp?: string;
}

export const AnalysisStatusDelayed: React.FC<Props> = ({ timestamp }) => (
  <StateAlert
    icon={<Icon.TimePast />}
    small={true}
    variant={'info'}
  >
    {I18n.t(T.dashboard.infographic.battery.delayMessage,
      { time: moment(timestamp).format('LT') },
    )}
    {'. '}
    {I18n.t(T.analysis.headlines.delayMessage)}
  </StateAlert>
);
