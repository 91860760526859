export const INFOGRAPHIC_CONSTANTS = {
  mobile: {
    container: {
      viewbox: '0 0 750 400',
      width: undefined,
      height: undefined,
    },
    background: {
      viewbox: '0 260 750 400',
      width: undefined,
      height: undefined,
    },
    backupBox: {
      x: 585,
      y: 505,
    },
    battery: {
      x: 585,
      y: 505,
    },
    car: {
      x: 150,
      y: 540,
    },
    charger: {
      x: 323,
      y: 538,
    },
    chargerCable: {
      x: 294,
      y: 570,
    },
    cloud: {
      x: 140,
      y: 70,
    },
    community: {
      x: 110,
      y: 435,
    },
    consumption: {
      x: 520,
      y: 430,
    },
    contract: {
      x: 450,
      y: 500,
      xWhenHeaterIsConnected: 520,
      yWhenHeaterIsConnected: 510,
    },
    heater: {
      x: 425,
      y: 480,
    },
    house: {
      x: 140,
      y: 360,
    },
    pv: {
      x: 400,
      y: 380,
    },
    tree: {
      x: 380,
      y: 262,
    },
  },
  desktop: {
    container: {
      viewbox: '0 50 770 828',
      width: '770',
      height: '928',
    },
    background: {
      viewbox: '0 50 770 828',
      width: '770',
      height: '928',
    },
    backupBox: {
      x: 585,
      y: 505,
    },
    battery: {
      x: 585,
      y: 505,
    },
    car: {
      x: 150,
      y: 540,
    },
    charger: {
      x: 323,
      y: 538,
    },
    chargerCable: {
      x: 294,
      y: 570,
    },
    cloud: {
      x: 225,
      y: 80,
    },
    community: {
      x: 110,
      y: 435,
    },
    consumption: {
      x: 520,
      y: 430,
    },
    contract: {
      x: 450,
      y: 500,
      xWhenHeaterIsConnected: 520,
      yWhenHeaterIsConnected: 510,
    },
    heater: {
      x: 425,
      y: 480,
    },
    house: {
      x: 140,
      y: 360,
    },
    pv: {
      x: 400,
      y: 380,
    },
    tree: {
      x: 580,
      y: 230,
    },
  },
};

// the order of attributes matters
// TODO: REMOVE?
export const INFOGRAPHIC_ELEMENTS = {
  battery: 'battery',
  community: 'community',
  charger: 'charger',
  heater: 'heater',
  pv: 'pv',
  contract: 'contract',
  consumption: 'consumption',
};
