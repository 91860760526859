import * as moment from 'moment-timezone';

import { StatisticsV1FilterResolution } from '+analysis/store/types/statisticsV1.interface';
import { MeasurementMethod } from '+app/shared/store/live/types/siteLiveData.interface';
import { BATTERY_TIMEZONE } from '+mocks/battery.mock';
import { StatisticsData } from '+shared/types/statisticsData.interface';

const STATISTICS_TOTAL = {
  id: '',
  type: '',
  self: '',
  measurementMethod: MeasurementMethod.BATTERY,
  start: moment().startOf('year').format(),
  end: moment().format(),
  resolution: StatisticsV1FilterResolution.TOTAL,
  producedEnergy: [119579],
  consumedEnergy: [569358],
  batteryChargedEnergy: [46151],
  batteryDischargedEnergy: [33131],
  gridFeedinEnergy: [964],
  gridPurchaseEnergy: [463764],
  directUsageEnergy: [100013],
};

const STATISTICS_DAILY = {
  id: '',
  type: '',
  self: '',
  measurementMethod: MeasurementMethod.BATTERY,
  start: moment.tz(BATTERY_TIMEZONE).startOf('year').format(),
  end: moment.tz(BATTERY_TIMEZONE).format(),
  resolution: StatisticsV1FilterResolution.DAY,
  producedEnergy: [
    11952,
    2899,
    3635,
    3014,
    2932,
    627,
    3932,
    1362,
    11718,
    17824,
    20970,
    3099,
    158,
    0,
    8,
    18,
    0,
    0,
    0,
    19,
    134,
    2838,
    740,
    1074,
    585,
    386,
    340,
    1061,
    3766,
    7316,
    4335,
    7825,
    5012,
  ],
  consumedEnergy: [
    19937,
    22095,
    20312,
    23779,
    18371,
    17858,
    17250,
    18279,
    21138,
    17861,
    20785,
    14792,
    16068,
    15452,
    15418,
    16331,
    14260,
    16807,
    13911,
    17447,
    14753,
    15489,
    15867,
    18418,
    19677,
    17381,
    22596,
    15796,
    17090,
    12206,
    14596,
    21809,
    5527,
  ],
  batteryChargedEnergy: [
    79,
    258,
    468,
    626,
    616,
    604,
    475,
    628,
    594,
    5421,
    5969,
    50,
    613,
    616,
    616,
    629,
    629,
    626,
    635,
    815,
    648,
    668,
    652,
    658,
    644,
    650,
    824,
    845,
    1255,
    14081,
    572,
    742,
    2946,
  ],
  batteryDischargedEnergy: [
    7345,
    2146,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    641,
    3511,
    4396,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    259,
    462,
    3348,
    10149,
    874,
    0,
  ],
  gridFeedinEnergy: [
    16,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    3,
    310,
    181,
    12,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
    122,
    50,
    62,
    80,
    25,
    102,
  ],
  gridPurchaseEnergy: [
    735,
    17308,
    17145,
    21391,
    16054,
    17835,
    13793,
    17545,
    10017,
    5127,
    2455,
    7358,
    16522,
    16069,
    16026,
    16943,
    14889,
    17433,
    14546,
    18244,
    15268,
    13319,
    15779,
    18003,
    19736,
    17644,
    23079,
    15443,
    14168,
    15686,
    764,
    13878,
    3562,
  ],
  directUsageEnergy: [
    11934,
    2898,
    3633,
    3012,
    2932,
    627,
    3930,
    1362,
    11694,
    12593,
    15019,
    3099,
    158,
    0,
    8,
    18,
    0,
    0,
    0,
    19,
    134,
    2836,
    740,
    1074,
    585,
    386,
    340,
    1061,
    2751,
    3968,
    3728,
    7271,
    2201,
  ],
};

export const STATISTICS_FLATX_MOCK = {
  timestamp: moment.tz(BATTERY_TIMEZONE).format(),
  total: STATISTICS_TOTAL,
  daily: STATISTICS_DAILY,
} as StatisticsData;
