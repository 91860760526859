export enum ErrorStatus {
  ERROR_401 = '401',
  SEARCH_EMPTY_ERROR = 'SearchEmpty',
  GENERAL_ERROR = 'error',
  NONE = '',
}

export type Watt = 'W';
export type KiloWatt = 'kW';
export type KiloWattHour = 'kWh';
export type Hertz = 'Hz';

export type EnergyValue<Unit> = {
  value: number,
  timestamp: number,
  unit: Unit,
};

export type ThenArg<T> = T extends Promise<infer U> ? U : T;
export type Unpacked<T> = T extends Array<infer U> ? U : T;
