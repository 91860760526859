import { LiveActions } from '+shared/store/live/live.actions';
import { combineSagas } from '+utils/saga';
import { put, takeLatest } from 'redux-saga/effects';
import { INTEGRATION_ACTIONS, IntegrationActions } from './mobile.actions';
import { MobileAppState } from './mobile.state';

type SetAppStateAction = ReturnType<typeof IntegrationActions.setAppState>;

function* handleAppStateChange({ state }: SetAppStateAction) {
  if (state === MobileAppState.INACTIVE) {
    yield put(LiveActions.stopLiveDataPolling());
  }
}

export const mobileSagas = combineSagas(
  takeLatest(INTEGRATION_ACTIONS.SET_APP_STATE, handleAppStateChange),
);
