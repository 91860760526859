import * as React from 'react';

import { PlatformSelector } from '+app/shared/components';
import { DemoAnalysisHistoryMobile } from './DemoAnalysisHistory.mobile.component';
import { DemoAnalysisHistoryWeb } from './DemoAnalysisHistory.web.component';

export const DemoAnalysisHistory: React.FC = () => (
  <PlatformSelector 
    web={<DemoAnalysisHistoryWeb />}
    mobile={<DemoAnalysisHistoryMobile />}
  />
);
