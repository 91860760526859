import { FeatureName } from '+config/featureFlags';
import { IconComponent } from '@sonnen/shared-web/src/components/Icon/models';
import { RouteComponentProps, StaticContext } from 'react-router';

export enum RouteName {
  ROOT = 'ROOT',
  // ==========================================================================
  //
  //  Protected Routes Names
  //
  // ==========================================================================
  LOGOUT = 'LOGOUT',

  DASHBOARD = 'DASHBOARD',

  ANALYSIS = 'ANALYSIS',

  LIVE_STATE = 'LIVE_STATE',

  COMMUNITY = 'COMMUNITY',

  FLAT = 'FLAT',
  FLAT_CUSTOMER = 'FLAT_CUSTOMER',
  FLAT_OVERVIEW = 'FLAT_OVERVIEW',
  FLAT_USER_READINGS = 'FLAT_USER_READINGS',
  FLAT_NEW_CONTRACT = 'FLAT_NEW_CONTRACT',
  FLAT_MIGRATION = 'FLAT_MIGRATION',

  TARIFF = 'TARIFF',
  TARIFF_CUSTOMER = 'TARIFF_CUSTOMER',
  TARIFF_OVERVIEW = 'TARIFF_OVERVIEW',
  TARIFF_USER_READINGS = 'TARIFF_USER_READINGS',

  ENERGY = 'ENERGY',
  ENERGY_CUSTOMER = 'ENERGY_CUSTOMER',
  ENERGY_OVERVIEW = 'ENERGY_OVERVIEW',
  ENERGY_DOCUMENTS = 'ENERGY_DOCUMENTS',

  BATTERY = 'BATTERY',
  BATTERY_OVERVIEW = 'BATTERY_OVERVIEW',
  BATTERY_DETAILS = 'BATTERY_DETAILS',
  BATTERY_DOWNLOADS = 'BATTERY_DOWNLOADS',

  DOCUMENTS = 'DOCUMENTS',
  DOCUMENTS_CONTRACTS = 'DOCUMENTS_CONTRACTS',
  DOCUMENTS_INVOICES = 'DOCUMENTS_INVOICES',
  DOCUMENTS_SYSTEM_DOCS = 'DOCUMENTS_SYSTEM_DOCS',

  RECRUIT_FRIENDS = 'RECRUIT_FRIENDS',

  MY_ACCOUNT = 'MY_ACCOUNT',
  MY_ACCOUNT_ABOUT = 'MY_ACCOUNT_ABOUT',
  MY_ACCOUNT_PASSWORD = 'MY_ACCOUNT_PASSWORD',
  MY_ACCOUNT_SETTINGS = 'MY_ACCOUNT_SETTINGS',

  HELP_AND_SUPPORT = 'HELP_AND_SUPPORT',
  HELP_AND_SUPPORT_OVERVIEW = 'HELP_AND_SUPPORT_OVERVIEW',
  HELP_AND_SUPPORT_CONFIRMATION = 'HELP_AND_SUPPORT_CONFIRMATION',
  HELP_AND_SUPPORT_TAX = 'HELP_AND_SUPPORT_TAX',

  FEEDBACK = 'FEEDBACK',

  DEVELOPER = 'DEVELOPER',
  // ==========================================================================
  //
  //  Public Routes Names
  //
  // ==========================================================================
  LOGIN = 'LOGIN',

  GUIDE = 'GUIDE',
  GUIDE_OVERVIEW = 'GUIDE_OVERVIEW',
  GUIDE_PHASES = 'GUIDE_PHASES',
  GUIDE_PRODUCT_INFO = 'GUIDE_PRODUCT_INFO',
  GUIDE_DOCUMENTS = 'GUIDE_DOCUMENTS',
  GUIDE_CONTACT = 'GUIDE_CONTACT',
  GUIDE_DISCOVERY = 'GUIDE_DISCOVERY',
  GUIDE_DISCOVERY_STEP = 'GUIDE_DISCOVERY_STEP',
  GUIDE_PURCHASE = 'GUIDE_PURCHASE',
  GUIDE_PURCHASE_STEP = 'GUIDE_PURCHASE_STEP',
  GUIDE_ACCEPTANCE = 'GUIDE_ACCEPTANCE',
  GUIDE_ACCEPTANCE_ID = 'GUIDE_ACCEPTANCE_ID',
  GUIDE_ACCEPTANCE_ID_HARDWARE = 'GUIDE_ACCEPTANCE_ID_HARDWARE',
  GUIDE_ACCEPTANCE_ID_WRONG_DETAILS = 'GUIDE_ACCEPTANCE_ID_WRONG_DETAILS',
  GUIDE_SETUP = 'GUIDE_SETUP',
  GUIDE_SETUP_STEP = 'GUIDE_SETUP_STEP',
  GUIDE_USAGE = 'GUIDE_USAGE',
  GUIDE_MY_ACCOUNT = 'GUIDE_MY_ACCOUNT',
  GUIDE_LOGOUT = 'GUIDE_LOGOUT',

  DEMO = 'DEMO',
  DEMO_BATTERY = 'DEMO_BATTERY',
  DEMO_HELLO = 'DEMO_HELLO',
  DEMO_DASHBOARD = 'DEMO_DASHBOARD',
  DEMO_LIVE_STATE = 'DEMO_LIVE_STATE',
  DEMO_COMMUNITY = 'DEMO_COMMUNITY',
  DEMO_ANALYSIS = 'DEMO_ANALYSIS',
  DEMO_FLAT = 'DEMO_FLAT',
}

export enum RouteType {
  ALL = 'ALL',
  PUBLIC_ONLY = 'PUBLIC_ONLY', // @NOTE: Only accessible if unathorized
  PUBLIC = 'PUBLIC', // @NOTE: Accessible for both authorized and unauthorized users
  PROTECTED = 'PROTECTED',
}

export type FlatRoutes = {
  [K in RouteName]?: Route;
};

export type Routes = Route[];

export interface Route {
  component: RouteComponent;
  icon?: IconComponent;
  featureFlag?: FeatureName; // TODO
  isExactPath?: boolean;
  isMainFeatureRoot?: boolean;
  label: RoutePlatformValue<string>;
  meta?: string;
  name: RouteName;
  path: string;
  preventScrollToTop?: boolean;
  routeType: RouteType;
  subRoutes?: Route[];
  subRoutesRefs?: RouteName[];
  tag?: string;
  title?: RoutePlatformValue<string>;
  shouldBeActiveFor?: RouteName;
  navigationDataTestId?: string;
}

export interface RoutePlatformValue<T> {
  web?: T;
  mobile?: T;
}

export type RouteComponent = React.ComponentClass<any, any> |
  React.FunctionComponent<any> |
  React.ComponentClass<RouteComponentProps<any, StaticContext, any>, any> |
  React.FunctionComponent<RouteComponentProps<any, StaticContext, any>> |
  undefined;

export interface RouterGuideAcceptanceRouteParams {
  offerId: string;
}

export interface RouterNavNestedRoute {
  main: RouteName;
  subRoutes: RouteName[];
}

export type RouterNavRoute = RouteName | RouterNavNestedRoute;

export type RouterNavConfig = RouterNavRoute[];

export enum RouterAppNavType {
  BOTTOM = 'BOTTOM',
  SIDE = 'SIDE',
}
