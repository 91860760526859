import * as React from 'react';

import { PlatformSelector } from '+components';
import { Site } from '+shared/store/site/types/site.interface';
import { SiteSwitcherButtonMobile } from './SiteSwitcherButton.mobile.component';
import { SiteSwitcherButtonShared } from './SiteSwitcherButton.shared.component';

interface Props {
  site?: Site;
  isHighlighted?: boolean;
}

export const SiteSwitcherButton: React.FC<Props> = ({
  site,
}: Props) => (
  <PlatformSelector
    web={
      <SiteSwitcherButtonShared
        site={site}
        isHighlighted={true}
      />
    }
    mobile={
      <SiteSwitcherButtonMobile
        site={site}
      />
    }
  />
);
