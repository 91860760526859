import { LayoutActions } from '+app/shared/store/layout/layout.actions';
import { isSiteSwitcherOpen } from '+app/shared/store/layout/layout.selectors';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { formatSiteAddress } from '+legacy/helpers/strings';
import { SiteActions } from '+shared/store/site';
import { Site } from '+shared/store/site/types/site.interface';
import { Icon } from '@sonnen/shared-web';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';

import './SiteSwitcherButton.shared.component.scss';

interface OwnProps {
  site?: Site;
  isHighlighted?: boolean;
}

const mapDispatchToProps = mapActions({
  switchSite: SiteActions.switchSite,
  toggleSiteSwitcher: LayoutActions.toggleSiteSwitcher,
});

const mapStateToProps = (state: StoreState) => ({
  isMobileSiteSwitcherOpen: isSiteSwitcherOpen(state),
});

type Props =
  & OwnProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const SiteSwitcherButtonComponent: React.SFC<Props> = ({
  site,
  isHighlighted = false,
  isMobileSiteSwitcherOpen = false,
  actions: {
    switchSite,
    toggleSiteSwitcher,
  },
}) => {
  if (!site) {
    return null;
  }

  const buttonClasses = classNames('c-site-switcher-button', {
    'c-site-switcher-button--highlighted': isHighlighted,
  });

  const switchSiteHandler = () => {
    if (!isHighlighted) {
      switchSite(site && site.id);

      setTimeout(() => {
        toggleSiteSwitcher(!isMobileSiteSwitcherOpen);
      }, 1000);
    } else {
      toggleSiteSwitcher(!isMobileSiteSwitcherOpen);
    }
  };

  return (
    <button
      className={buttonClasses}
      type={'button'}
      onClick={switchSiteHandler}
    >
      <span className={'c-site-switcher-button__content'}>
        {formatSiteAddress(site)}
      </span>
      {isHighlighted &&
        <Icon.Angle className={'c-site-switcher-button__icon'}/>
      }
    </button>
  );
};

// TODO: Refactor it to the dumb component!
// Programmer was crying when he writing this connect
export const SiteSwitcherButtonShared =  connect(mapStateToProps, mapDispatchToProps)(SiteSwitcherButtonComponent);
