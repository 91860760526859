import { T } from '@sonnen/shared-i18n/customer';
import { isNil } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { StoreState } from '+app/store/store.interface';
import { getFormattedDate } from '+legacy/helpers/dates';
import { ContractCard } from '../../components';
import { getContractStatus } from '../../components/ContractCard/helpers/ContractCard.helpers';
import { getUsageAllowanceActivationDate } from '../../store/contract.selectors';

import '../../components/ContractCard/ContractCard.component.scss';

const mapStateToProps = (store: StoreState) => ({
  usageAllowanceActivationDate: getUsageAllowanceActivationDate(store),
});

type Props = ReturnType<typeof mapStateToProps>;

export const SonnenFlatXEnergyActivated: React.FC<Props> = ({
  usageAllowanceActivationDate,
}) => (
  <ContractCard
    title={I18n.t(T.yourFlat.sonnenFlatX.energyActivated.title)}
    subtitle={I18n.t(T.yourFlat.sonnenFlatX.energyActivated.subtitle)}
    infoAttribute={I18n.t(T.yourFlat.sonnenFlatX.energyActivated.infoAttribute)}
    infoValue={
      isNil(usageAllowanceActivationDate)
        ? I18n.t(T.yourFlat.sonnenFlatX.energyActivated.notknown)
        : getFormattedDate(usageAllowanceActivationDate)
    }
    icon={'Contract'}
    status={getContractStatus(usageAllowanceActivationDate)}
  />
);

export const ContractCardSonnenFlatXEnergyActivated = connect(mapStateToProps)(
  SonnenFlatXEnergyActivated,
);
