import { Loadable, Loader } from '@sonnen/shared-web';
import * as React from 'react';

import './AsyncRouteComponent.component.scss';

type Props = Record<string, any>;

interface State {
  component: React.ComponentType | undefined;
}

export const getAsyncRouteComponent = (componentProvider: any, componentName: string) => {
  class AsyncComponent extends React.Component<Props, State> {
    readonly state: State = {
      component: undefined,
    };

    constructor(props: Props) {
      super(props);
    }

    async componentDidMount() {
      const component = await componentProvider();

      this.setState({
        component: component[componentName],
      });
    }

    render() {
      const C = this.state.component;

      return (
        <Loadable
          transition={'fade'}
          transitionTimeout={100}
          predicate={!C}
          loader={(
            <div className={'c-async-route-component'}>
              <Loader />
            </div>
          )}
        >
          {C && (
            <C {...this.props} />
          )}
        </Loadable>
      );
    }
  }

  return AsyncComponent;
};
