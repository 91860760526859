import { T } from '@sonnen/shared-i18n/customer';
import { BoldParagraph, Icon, LinkButton, Modal, ModalLayout } from '@sonnen/shared-web';
import { CleaveOptions } from 'cleave.js/options';
import { FormikProps } from 'formik';
import * as React from 'react';

import { Configuration } from '+app/+guide/store/types';
import { FormInput } from '+shared/components';
import { I18n } from 'react-redux-i18n';
import { formFields, UtilityDataForm } from '../../containers';
import { getPvSystemCommissioningDate, getPvSystemName, isCommissioningDateOptional, SystemKeyNumber } from './GuideAcceptanceUtilityPvSystemKeys.helper';

import './GuideAcceptanceUtilityPvSystemKeys.component.scss';

interface Props {
  form: FormikProps<UtilityDataForm>;
  configuration: Configuration;
  isMultiplePv: boolean;
}

const renderQnA = (question: string, answer: string) => (
  <>
    <p className={'c-guide-acceptance-utility-pv-system-keys__modal-question'}>
      {question}
    </p>
    <p className={'c-guide-acceptance-utility-pv-system-keys__modal-answer'}>
      {answer}
    </p>
  </>
);

const renderModal = (isModalVisible: boolean, setModalVisibility: (arg: boolean) => void) => (
  <Modal
    size={'small'}
    isOpen={isModalVisible}
    onClose={() => {
      setModalVisibility(false);
    }}
  >
    <ModalLayout title={I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.modalName)}>
      {renderQnA(
        I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.modalQuestionOne),
        I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.modalAnswerOne),
      )}
      {renderQnA(
        I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.modalQuestionTwo),
        I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.modalAnswerTwo),
      )}
      {renderQnA(
        I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.modalQuestionThree),
        I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.modalAnswerThree),
      )}
    </ModalLayout>
  </Modal>
);

export const GuideAcceptanceUtilityPvSystemKeys = ({ form, configuration, isMultiplePv }: Props) => {
  const [isModalVisible, setModalVisibility] = React.useState<boolean>(false);
  const placeholder = 'E 1234 1234 1234 1234 1234 1234 1234 1234';
  const mask: CleaveOptions = {
    prefix: 'E',
    noImmediatePrefix: true,
    rawValueTrimPrefix: true,
    uppercase: true,
    blocks: [1, 4, 4, 4, 4, 4, 4, 4, 4],
  };

  const showOptionalInfo = (systemKeyNumber: SystemKeyNumber) =>
    isCommissioningDateOptional(configuration, getPvSystemCommissioningDate(systemKeyNumber, configuration))
      ? ` - ${I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.optional)}` : '';

  const getFormLabel = (configuration: Configuration, systemKeyNumber: SystemKeyNumber) => {
    const label = configuration.photovoltaicSystem.subSystems
      ? I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.label, {
        pvSystemName: getPvSystemName(systemKeyNumber, configuration),
      })
      : I18n.t(T.customerGuidance.acceptance.utilityData.singlePvSystemKey.label);

    return `${label}${showOptionalInfo(systemKeyNumber)}`;
  };

  return (
    <div className={'c-guide-acceptance-utility-pv-system-keys'}>
      <div className={'c-guide-acceptance-utility-pv-system-keys__text'}>
        <BoldParagraph>
          {I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.title)}
        </BoldParagraph>
      </div>

      <div className={'c-guide-acceptance-utility-pv-system-keys__link-wrapper'}>
        <p className={'c-guide-acceptance-utility-pv-system-keys__link-question'}>
          {I18n.t(T.customerGuidance.acceptance.utilityData.pvSystemKey.question)}
        </p>
        <LinkButton
          icon={<Icon.Info />}
          iconClass={'c-guide-acceptance-utility-pv-system-keys__link-icon'}
          onClick={() => setModalVisibility(true)}>
          {I18n.t(T.customerGuidance.acceptance.utilityData.meterId.modal.meterInstruction)}
        </LinkButton>
      </div>

      <div className={'c-guide-acceptance-utility-pv-system-keys__input'}>
        <div className={'c-guide-acceptance-utility-pv-system-keys__first-pv'}>
          <FormInput
            form={form}
            label={getFormLabel(configuration, SystemKeyNumber.PV_SYSTEM_ONE)}
            placeholder={placeholder}
            name={formFields.PV_SYSTEM_KEY_ONE}
            type={'text'}
            hasNoGap={true}
            mask={mask}
          />
        </div>
        {isMultiplePv &&
          <FormInput
            form={form}
            label={getFormLabel(configuration, SystemKeyNumber.PV_SYSTEM_TWO)}
            placeholder={placeholder}
            name={formFields.PV_SYSTEM_KEY_TWO}
            type={'text'}
            hasNoGap={true}
            mask={mask}
          />
        }
      </div>

      {renderModal(isModalVisible, setModalVisibility)}
    </div>
  );
};
