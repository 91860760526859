import * as React from 'react';

import './TableList.component.scss';

interface TableListProps {
  label: string;
  value: React.ReactNode;
  dataTestId?: string;
}

interface Props {
  list: TableListProps[] | undefined;
}

export const TableList: React.FC<Props> = ({ list }) => (
  <ul className={'c-table-list'}>
    {list && list.map(({ label, value, dataTestId }, index) => (
      value && (
        <li className={'c-table-list__item'} key={`${label}-${index}`}>
          <p
            data-test-id={`${dataTestId}-label`} 
            className={'c-table-list__title'}
          >
            {label}
          </p>
          <div 
            data-test-id={`${dataTestId}-value`} 
            className={'c-table-list__value'}
          >
            {value === 'null' ? '-' : value}
          </div>
        </li>
      )
    ))}
  </ul>
);
