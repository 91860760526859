import { T } from '@sonnen/shared-i18n/customer';
import { isNil } from 'lodash';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { SiteLive } from '+app/shared/store/live/types/siteLiveData.interface';
import { useInfographic } from '+dashboard/+infographic';
import { InfographicCardLayout } from '../InfographicCardLayout/InfographicCardLayout.component';
import { InfographicPVCardHelper } from './InfographicPVCard.helper';
import { InfographicPVCardStatus } from './InfographicPVCardStatus.component';
import { InfographicPVCardValue } from './InfographicPVCardValue.component';

interface Props {
  isLoading: boolean;
}

const getValue = (siteLiveData: SiteLive | undefined, isMK1: boolean) => {
  if (siteLiveData) {
    const {
      gridFeedinKw: gridFeedin,
      productionPowerKw: productionPower,
    } = siteLiveData;

    return isMK1
      ? isNil(gridFeedin) ? '-' : gridFeedin
      : isNil(productionPower) ? '-' : productionPower;
  }

  return '-';
};

export const InfographicPVCard: React.FC<Props> = ({ isLoading = false }) => {
  const { data: { siteLiveData, query } } = useInfographic();

  const isMK1 = !!(siteLiveData && !siteLiveData.hasOwnProperty('productionPowerKw'));
  const status = InfographicPVCardHelper.getCardStatus(siteLiveData, query.siteLiveQuery);

  return (
    <InfographicCardLayout
      headerDataTestId={'dashboard-infographic-card-pv-header'}
      title={isMK1
        ? I18n.t(T.dashboard.infographic.pv.titleMk1)
        : I18n.t(T.dashboard.infographic.pv.title)
      }
      notifications={<InfographicPVCardStatus
        siteLiveData={siteLiveData}
        siteLiveQuery={query.siteLiveQuery}
      />}
      isLoading={isLoading}
    >
      <InfographicPVCardValue
        status={status}
        isMK1={isMK1}
        productionValue={getValue(siteLiveData, isMK1)}
      />
    </InfographicCardLayout>
  );
};
