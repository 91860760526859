import { T } from '@sonnen/shared-i18n/customer';
import { PageHeadline } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps } from 'react-router';
import NavigationPrompt from 'react-router-navigation-prompt';
import { compose } from 'redux';

import { getOfferById, getOfferConfiguration } from '+app/+guide/+purchase/store/+offer.selectors';
import { OfferProductType } from '+app/+guide/store/types/offer.interface';
import { getPaths } from '+app/router/router.helper';
import { RouterGuideAcceptanceRouteParams } from '+app/router/router.types';
import { getRouterLocationHash, getRouterLocationPath } from '+app/router/store/router.selectors';
import { StoreState } from '+app/store/store.interface';
import { GuideAcceptanceConfirmationModal } from '+guide/+acceptance/components';
import { isFlatDirectConfiguration, isSpecificFlatProductInOffer } from '../../store/+acceptance.helper';
import { getGuideAcceptanceWizard } from './GuideAcceptanceStepper.helper';

import './GuideAcceptanceStepper.component.scss';

const mapStateToProps = (
  state: StoreState,
  { match: { params } }: RouteComponentProps<RouterGuideAcceptanceRouteParams>,
) => ({
  path: getRouterLocationPath(state),
  routerLocationHash: getRouterLocationHash(state),
  configuration: compose(getOfferConfiguration, getOfferById(state))(params),
  offer: getOfferById(state)(params),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  ;

const GuideAcceptanceStepperComponent: React.FC<Props> = ({
  path,
  routerLocationHash,
  configuration,
  offer,
}) => {
  const paths = getPaths();
  const isAcceptanceView = path.indexOf(paths.GUIDE_ACCEPTANCE) >= 0;
  const isOneOfLastAcceptanceSteps =
    encodeURIComponent(I18n.t(T.customerGuidance.acceptance.stepper.conclude)) ===
    encodeURIComponent(routerLocationHash.substring(1))
    || encodeURIComponent(I18n.t(T.customerGuidance.acceptance.stepper.cessionDoc)) ===
    encodeURIComponent(routerLocationHash.substring(1));
  const isPostEeg = isFlatDirectConfiguration(configuration);
  const isFlatXOffer = offer && isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X);

  return (
    <>
      <PageHeadline>
        {offer && isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X)
          ? I18n.t(T.customerGuidance.acceptance.headline)
          : I18n.t(T.customerGuidance.acceptance.headlineFlatDirect)
        }
      </PageHeadline>
      <div className={'c-guide-acceptance-stepper'}>
        {configuration &&
          getGuideAcceptanceWizard(isPostEeg, isFlatXOffer)
        }
        <NavigationPrompt
          when={(_, targetLocation) =>
            isAcceptanceView
            && !isOneOfLastAcceptanceSteps
            && !!targetLocation && targetLocation.pathname.indexOf(paths.GUIDE_ACCEPTANCE) < 0
          }
        >
          {({ onConfirm, onCancel }) => (
            <GuideAcceptanceConfirmationModal
              onConfirm={onConfirm}
              onCancel={onCancel}
            />
          )}
        </NavigationPrompt>
      </div>
    </>
  );
};

export const GuideAcceptanceStepper = connect(mapStateToProps)(GuideAcceptanceStepperComponent);
