import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, EnergyUnit } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { InfographicCardConnectionStatusType } from '../../containers';
import { InfographicValue } from '../InfographicValue';

type Props = {
  status: InfographicCardConnectionStatusType,
  isMK1: boolean,
  productionValue: number | '-',
};

export const InfographicPVCardValue: React.FC<Props> = ({
  status,
  isMK1,
  productionValue,
}) => {
  const isOffline = status === InfographicCardConnectionStatusType.OFFLINE;
  const isNoDataError = status === InfographicCardConnectionStatusType.DATA_MISSING;

  return (
    <>
      {isOffline ? (
        <div className={'c-infographic-battery-card__text'}>
          <DefaultParagraph>
            {I18n.t(T.dashboard.infographic.battery.offlineMessage)}
          </DefaultParagraph>
        </div>
      ) : (
        <InfographicValue
        title={isMK1
          ? I18n.t(T.dashboard.infographic.pv.headlineMk1)
          : I18n.t(T.dashboard.infographic.pv.headline)
        }
        icon={'PV'}
        value={productionValue}
        unit={EnergyUnit.KW}
        isThemeInactive={isNoDataError}
      />
      )}
    </>
  );
};
