import { OfferStatus, Proposal } from '+app/+guide/store/types';
import { getFormattedDate } from '+legacy/helpers/dates';
import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { createExpirationTag } from './GuidePurchaseFlatOfferDates.helper';

import './GuidePurchaseFlatOfferDates.component.scss';

interface Props {
  proposal: Proposal;
  sentOfferExpiresAt: Date | undefined;
  recalculatedAt: Date | undefined;
}

export const GuidePurchaseFlatOfferDates: React.FC<Props> = ({
  proposal,
  sentOfferExpiresAt,
  recalculatedAt,
}) => {

  const offer = proposal.offer;
  const isOfferAccepted = offer?.status === OfferStatus.BINDINGLY_ACCEPTED;

  const creationDateTitle = recalculatedAt 
    ? T.customerGuidance.purchase.flatOffer.recalculatedAt 
    : T.customerGuidance.purchase.flatOffer.createdAt;
  const expiresAtTitle = T.customerGuidance.purchase.flatOffer.expiresAt;

  const creationDate = getFormattedDate(proposal.createdAt);
  const expirationDate = getFormattedDate(sentOfferExpiresAt);

  return (
    <>
      <div className={'c-guide-purchase-flat-offer-dates'}>
        {!!sentOfferExpiresAt && !isOfferAccepted ? (
          `${I18n.t(creationDateTitle)} ${creationDate} - ${I18n.t(expiresAtTitle)} ${expirationDate}`
        ) : (
          `${I18n.t(creationDateTitle)} ${creationDate}`
        )}
      </div>
      {!!sentOfferExpiresAt && !isOfferAccepted && (
        createExpirationTag(sentOfferExpiresAt)
      )}
    </>
  );
};
