import { T } from '@sonnen/shared-i18n/customer';
import { Alert, asAlertButton, Button, ButtonSize, ButtonTheme, Icon } from '@sonnen/shared-web';
import classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { ResponseError } from '+shared/network/network.interface';
import './AccountFormAlert.component.scss';

interface Props {
  isDisabled?: boolean;
  isSubmitting: boolean;
  isSuccess: boolean;
  isFailure: boolean;
  isVisible: boolean;
  error?: ResponseError;
  handleReset: () => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const getAlertMessage = (isSubmitting: boolean, isFailure: boolean, isSuccess: boolean, error?: ResponseError) => {
  switch (true) {
    case isFailure:
      return getErrorMessage(error);
    case isSubmitting:
      return I18n.t(T.general.messages.savingChanges);
    case isSuccess:
      return I18n.t(T.general.messages.savedSuccessfully);
    default:
      return I18n.t(T.general.messages.unsavedChanges);
  }
};

const getErrorMessage = (error?: ResponseError) => error?.extra?.errors?.length && 
  error.extra.errors.some(({ code }: { code: string }) => code === 'invalid') 
    ? I18n.t(T.general.errors.password.invalidCurrentPassword)
    : I18n.t(T.general.messages.apiError);

const getSaveButtonLabel = (isFailure: boolean) => (
  isFailure
    ? I18n.t(T.general.buttons.retry)
    : I18n.t(T.general.buttons.saveChanges)
);

export const AccountFormAlert: React.FC<Props> = props => {
  const AlertButton = asAlertButton(Button);
  const {
    isDisabled,
    isFailure,
    isSubmitting,
    isSuccess,
    isVisible,
    handleReset,
    error,
    handleSubmit,
  } = props;

  return (
    <div className={'c-account-form-alert'}>
      <Alert
        isOpen={isVisible}
        message={getAlertMessage(isSubmitting, isFailure, isSuccess, error)}
      >
        {isSuccess ? (
          <Icon.Checkmark className={'c-account-form-alert__success-icon'} />
        ) : (
          <>
            <AlertButton
              dataTestId={'account-password-change-form-alert-undo-button'}
              className={classNames({
                'c-account-form-alert__button--invisible': isSubmitting,
              })}
              label={(
                <>
                  <Icon.Close className={'c-account-form-alert__button-icon'} />
                  {I18n.t(T.general.buttons.undoChanges)}
                </>
              )}
              onClick={handleReset}
              isBlock={true}
              isNotElevatedOnHover={true}
              size={ButtonSize.NORMAL}
              theme={ButtonTheme.TRANSPARENT_LIGHT}
            />
            <AlertButton
              dataTestId={'account-password-change-form-alert-save-button'}
              label={(
                <>
                  <Icon.Checkmark className={'c-account-form-alert__button-icon'} />
                  {getSaveButtonLabel(isFailure)}
                </>
              )}
              onClick={handleSubmit}
              isBlock={true}
              isDisabled={isDisabled}
              isLoading={isSubmitting}
              isNotElevatedOnHover={true}
              type={'submit'}
              size={ButtonSize.NORMAL}
              theme={ButtonTheme.TRANSPARENT_LIGHT}
            />
          </>
        )}
      </Alert>
    </div>
  );
};
