import { AxisOrientation, GridLines } from '@kanva/charts';
import {
  AreaChartView,
  AxisView,
  ChartGridView,
  ChartZoomView,
} from '@kanva/charts-react';
import { rgba, TextAlign, View, Visibility } from '@kanva/core';
import { View as ViewComponent } from '@kanva/react';
import {
  CHART_FONT,
  EnergyFlowSeriesKey,
  SharedChartColors,
  useWindowSize,
} from '@sonnen/shared-web';
import * as React from 'react';

import { QueryState } from '+app/shared/store/query/query.state';
import { FullWidthKanva } from '+components';
import { breakpointUp } from '+legacy/helpers/breakpoints';
import { useAnalysisDayChart } from '../../containers/AnalysisKanvaProvider/AnalysisDayChartProvider';
import { selectAreaZoomStyle } from '../AnalysisEnergyFlow/AnalysisEnergyFlow.styles';
import { AreaSeriesStyle, Views } from './AnalysisAreaChart.helper';
import { layout as mobileLayout } from './AnalysisAreaChart.mobile.layout';
import { gridPaint, xAxisLabelPaint, yAxisLabelPaint } from './AnalysisAreaChart.styles';
import { createWebChartLayout } from './AnalysisAreaChart.web.layout';

import './AnalysisAreaChart.web.component.scss';

interface AnalysisAreaChartWeb {
  chartHeight: number;
  queryStatus: QueryState;
  isVisible: (key: EnergyFlowSeriesKey) => Visibility;
}

export const AnalysisAreaChartWeb: React.FC<AnalysisAreaChartWeb> = ({
  chartHeight,
  queryStatus,
  isVisible,
}) => {
  const { containers, isChartZoomed, handleMount } = useAnalysisDayChart();

  const [batteryCareTooltipLeftPosition, setBatteryCareTooltipLeftPosition] = React.useState(0);
  const batteryCareVisibility = isVisible('batteryCare' as EnergyFlowSeriesKey);
  const visibleLineSeries = [batteryCareVisibility].filter(lineVisibility => lineVisibility === Visibility.VISIBLE);

  const chart = useWindowSize().innerWidth >= breakpointUp('SM')
    ? {
        borders: { xAxis: 3, yAxis: 3 },
        layout: createWebChartLayout(visibleLineSeries),
        yLabel: yAxisLabelPaint.clone(),
      }
    : {
        borders: { xAxis: 1, yAxis: 0 },
        layout: mobileLayout,
        yLabel: yAxisLabelPaint.clone()
          .setFont({
            ...CHART_FONT,
            fontSize: 8,
          }),
      };

  const { borders, layout, yLabel } = chart;
  const isLoading = queryStatus.pending;

  const batteryCareTooltipPosition = {
    left: `${batteryCareTooltipLeftPosition}px`,
    bottom: useWindowSize().innerWidth >= breakpointUp('SM')
      ? '24px'
      : '45px',
  };

  const setBatteryCareRef = (event: { view?: View, [key: string]: any} | null) => {
    if (event?.view?.innerWidth) {
      setBatteryCareTooltipLeftPosition(event?.view?.innerWidth);
    }
  };

  return (
    <div className={'c-analysis-area-chart'}>
      <FullWidthKanva
        height={chartHeight}
        isLoading={isLoading}
      >
        <ViewComponent layoutParams={layout.wrapper}>
          <ChartGridView
            layoutParams={layout.grid}
            dataContainer={containers?.default}
            gridLines={GridLines.HORIZONTAL}
            style={{ paint: gridPaint }}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.PRODUCTION_POWER}
            visibility={isVisible(EnergyFlowSeriesKey.PRODUCTION_POWER)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.PRODUCTION_POWER]}
            onMount={handleMount}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.CONSUMPTION_POWER}
            visibility={isVisible(EnergyFlowSeriesKey.CONSUMPTION_POWER)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.CONSUMPTION_POWER]}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.DIRECT_USAGE_POWER}
            visibility={isVisible(EnergyFlowSeriesKey.DIRECT_USAGE_POWER)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.DIRECT_USAGE_POWER]}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.GRID_FEEDIN}
            visibility={isVisible(EnergyFlowSeriesKey.GRID_FEEDIN)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.GRID_FEEDIN]}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.GRID_PURCHASE}
            visibility={isVisible(EnergyFlowSeriesKey.GRID_PURCHASE)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.GRID_PURCHASE]}
            onMount={handleMount}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.percentage}
            dataSeries={EnergyFlowSeriesKey.BATTERY_USOC}
            visibility={isVisible(EnergyFlowSeriesKey.BATTERY_USOC)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.BATTERY_USOC]}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER}
            visibility={isVisible(EnergyFlowSeriesKey.PRODUCTION_POWER)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]}
          />
          <AreaChartView
            layoutParams={layout.chart}
            dataContainer={containers?.default}
            dataSeries={EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER}
            visibility={isVisible(EnergyFlowSeriesKey.CONSUMPTION_POWER)}
            style={AreaSeriesStyle[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]}
          />
        </ViewComponent>

        <ViewComponent
          layoutParams={layout.lineWrapper}
          id={Views.LINE_WRAPPER}
          // https://sonnen2020.atlassian.net/browse/CP-228
          // visibility={batteryCareVisibility}
          visibility={2}
        >
          {/* <LineChartView
            id={Views.BATTERY_CARE_LINE}
            layoutParams={layout.batteryCare}
            dataContainer={containers?.default}
            dataSeries={'batteryCare'}
            visibility={Visibility.VISIBLE}
            style={lineSeriesStyle}
          > */}
            {/* <TextView
              layoutParams={layout.lineLabel}
              ref={setBatteryCareRef}
              text={I18n.t(T.yourDay.chart.labels.batteryCare)}
              textPaint={labelPaint}
            /> */}
          {/* </LineChartView> */}
        </ViewComponent>

        <ChartZoomView
          dataContainer={containers?.default}
          layoutParams={layout.wrapper}
          style={selectAreaZoomStyle}
        />
        <AxisView
          id={Views.X_AXIS}
          border={{ top: borders.xAxis }}
          borderColor={rgba(SharedChartColors.DARK, .1)}
          layoutParams={layout.xAxis}
          dataContainer={containers?.default}
          orientation={AxisOrientation.HORIZONTAL}
          style={{
            labelPaint: xAxisLabelPaint,
            wrapLabelsOnEdge: !isChartZoomed,
          }}
        />
        <AxisView
          id={Views.Y_AXIS_LEFT}
          border={{ left: borders.yAxis }}
          borderColor={rgba(SharedChartColors.DARK, .1)}
          layoutParams={layout.yAxisLeft}
          dataContainer={containers?.default}
          orientation={AxisOrientation.VERTICAL}
          style={{
            labelPaint: yLabel.clone()
              .setTextAlign(TextAlign.LEFT),
          }}
        />
        <AxisView
          id={Views.Y_AXIS_RIGHT}
          layoutParams={layout.yAxisRight}
          dataContainer={containers?.percentage}
          orientation={AxisOrientation.VERTICAL}
          style={{
            labelPaint: yLabel.clone()
              .setTextAlign(TextAlign.RIGHT),
          }}
          visibility={containers?.percentage && containers?.percentage.getTotal() !== 0
            ? Visibility.VISIBLE
            : Visibility.INVISIBLE
          }
        />
      </FullWidthKanva>

      {/* {batteryCareVisibility === Visibility.VISIBLE && (
        <div
          className={'c-analysis-area-chart__line-chart-tooltip-wrapper'}
          style={{ ...batteryCareTooltipPosition }}
        >
          <AnalysisLineChartTooltip
            tooltip={(
              <DefaultParagraph>
                {I18n.t(T.analysis.dayChart.batteryCare.infoBubble.explanation)}
                <a
                  className={'c-analysis-area-chart__line-chart-tooltip-link'}
                  href={I18n.t(T.analysis.dayChart.batteryCare.infoBubble.linkUrl)}
                  target={'_blank'}
                >
                  {I18n.t(T.analysis.dayChart.batteryCare.infoBubble.linkLabel)}
                </a>
                {'.'}
              </DefaultParagraph>
            )}
          />
        </div>
      )} */}
    </div>
  );
};
