import { formatNumber } from '+app/utils/number.util';
import { T } from '@sonnen/shared-i18n/customer';
import { select } from 'd3-selection';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import {
  AXIS_DOMAIN_MARGIN,
  X_AXIS_MARGIN,
  Y_AXIS_MARGIN,
  Y_DOMAIN,
} from '../../../frequency.constants';

interface Props {
  height: number;
  domain: number[];
  range: number[];
}
export class AxisY extends React.PureComponent<Props> {
  static defaultProps = {
    height: 0,
    domain: Y_DOMAIN,
    range: [],
  };

  groupRef: Element | null = null;

  componentDidMount() {
    this.updateGraph();
  }

  componentDidUpdate() {
    this.updateGraph();
  }

  updateGraph = () => {
    const { height } = this.props;

    const g = select(this.groupRef);

    g.attr('transform', `translate(${X_AXIS_MARGIN}, ${Y_AXIS_MARGIN})`)
      .select('line')
      .attr('x1', AXIS_DOMAIN_MARGIN)
      .attr('x2', AXIS_DOMAIN_MARGIN)
      .attr('y1', 0)
      .attr('y2', height - Y_AXIS_MARGIN);
  };

  setGroupRef = (ref: Element | null) => {
    this.groupRef = ref;
  };

  render() {
    const [min, max] = this.props.domain;
    const [minHeight, maxHeight] = this.props.range;

    return (
      <g
        className="c-frequency-chart__axis"
        ref={this.setGroupRef}
      >
        <line className="c-frequency-chart__axis-line" />
        <text
          y={0}
          x={10}
          textAnchor="start"
          className="c-frequency-chart__axis-label c-frequency-chart__axis-label--bold"
        >
          {I18n.t(T.sonnenCommunity.gridBalancing.chart.yAxisLabel)}
        </text>
        <text
          x={10}
          y={maxHeight}
          textAnchor="start"
          className="c-frequency-chart__axis-label"
        >
          {`${formatNumber()(max)} ${I18n.t(T.general.units.hertz)}`}
        </text>
        <text
          x={10}
          y={minHeight - AXIS_DOMAIN_MARGIN}
          textAnchor="start"
          className="c-frequency-chart__axis-label"
        >
          {`${formatNumber()(min)} ${I18n.t(T.general.units.hertz)}`}
        </text>
      </g>
    );
  }
}
