import * as React from 'react';
import { FormInputEventType, FormInputSubscriptionContext } from './FormInputSubscription.context';

export const useDispatchInputEvent = () => {
  const dispatchEvent = React.useContext(FormInputSubscriptionContext);

  const createHandler = <T extends FormInputEventType>(handlerName: T) => (name: string, value: any) => {
    if (!dispatchEvent) { return; }

    dispatchEvent({
      type: handlerName,
      payload: { name, value },
    });
  };

  const dispatchOnBlur = createHandler(FormInputEventType.ON_BLUR);
  const dispatchOnChange = createHandler(FormInputEventType.ON_CHANGE);

  return {
    onBlur: dispatchOnBlur,
    onChange: dispatchOnChange,
  };
};
