import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { GuideSetupModalType } from '+app/+guide/+setup/store';
import { QueryStatus } from '+app/shared/store/query/query.state';

interface GuideSetupModalContent {
  title?: string;
  subtitle?: string;
  icon?: React.ReactNode;
  buttonLabel?: string;
}

export const guideSetupModalContent = (status: QueryStatus, type: GuideSetupModalType | null) => {  
  let modalContent: GuideSetupModalContent = {};
  
  switch (status) {
    case QueryStatus.PENDING:
      modalContent = {
        title: I18n.t(T.customerGuidance.setup.active.modal.pending.title),
      };
      break;
    case QueryStatus.SUCCESS:
      if (type === GuideSetupModalType.CHECKED) {
        modalContent = {
          icon: <Icon.Confetti />,
          title: I18n.t(T.customerGuidance.setup.active.modal.check.title),
          subtitle: I18n.t(T.customerGuidance.setup.active.modal.check.subtitle),
          buttonLabel: I18n.t(T.customerGuidance.setup.active.modal.check.button),
        };
      } else {
        modalContent = {
          icon: <Icon.Info />,
          title: I18n.t(T.customerGuidance.setup.active.modal.uncheck.title),
          buttonLabel: I18n.t(T.customerGuidance.setup.active.modal.uncheck.button),
        };
      }
      break;
    case QueryStatus.FAILURE:
        modalContent = {
          icon: <Icon.Error />,
          title: I18n.t(T.customerGuidance.setup.active.modal.failure.title),
          subtitle: I18n.t(T.customerGuidance.setup.active.modal.failure.subtitle),
          buttonLabel: I18n.t(T.customerGuidance.setup.active.modal.failure.button),
        };
        break;
    default:
      break;
  }

  return modalContent;
};
