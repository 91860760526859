import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

import { DocumentAcceptance } from '../../store';

export type DocumentAcceptanceForm = Record<DocumentAcceptance, boolean>;

export const DocumentAcceptanceInitialValues =
  (acceptedMarketingMailing: boolean): Record<DocumentAcceptance, boolean> => ({
    [DocumentAcceptance.PRIVACY_POLICY]: false,
    [DocumentAcceptance.TERMS_AND_CONDITIONS_AND_CANCELLATION]: false,
    [DocumentAcceptance.MARKETING_MAILING]: acceptedMarketingMailing,
    [DocumentAcceptance.ASSIGNMENT_NOTICE]: false,
  });

export const DocumentAcceptanceSchema = (isPostEeg: boolean) => isPostEeg ?
  Yup.object().shape({
    [DocumentAcceptance.PRIVACY_POLICY]: Yup
      .boolean()
      .oneOf([true], I18n.t(T.customerGuidance.acceptance.requiredField)),
    [DocumentAcceptance.TERMS_AND_CONDITIONS_AND_CANCELLATION]: Yup
      .boolean()
      .oneOf([true], I18n.t(T.customerGuidance.acceptance.requiredField)),
    [DocumentAcceptance.MARKETING_MAILING]: Yup
      .boolean(),
  })
  :
  Yup.object().shape({
    [DocumentAcceptance.PRIVACY_POLICY]: Yup
      .boolean()
      .oneOf([true], I18n.t(T.customerGuidance.acceptance.requiredField)),
    [DocumentAcceptance.TERMS_AND_CONDITIONS_AND_CANCELLATION]: Yup
      .boolean()
      .oneOf([true], I18n.t(T.customerGuidance.acceptance.requiredField)),
    [DocumentAcceptance.MARKETING_MAILING]: Yup
      .boolean(),
    [DocumentAcceptance.ASSIGNMENT_NOTICE]: Yup
      .boolean()
      .oneOf([true], I18n.t(T.customerGuidance.acceptance.requiredField)),
  });
