import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, DefaultParagraph, MediaCard, WizardContext } from '@sonnen/shared-web';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getOfferById } from '+app/+guide/+purchase/store/+offer.selectors';
import { RouterGuideAcceptanceRouteParams } from '+app/router/router.types';
import { StoreState } from '+app/store/store.interface';
import { getGuideLead } from '+guide/store/+lead.selectors';
import { BankData, OfferProductType } from '+guide/store/types';
import { QueryActions } from '+shared/store/query/query.actions';
import { mapActions } from '+utils/redux';
import {
  GuideAcceptanceBackButton,
  GuideAcceptanceCheckbox,
  GuideAcceptanceInputField,
} from '../../components';
import { GuideAcceptanceActions } from '../../store/+acceptance.actions';
import { isSpecificFlatProductInOffer } from '../../store/+acceptance.helper';
import { getPaymentDataStatus } from '../../store/+acceptance.selectors';
import { SET_PAYMENT_DATA_QUERY } from '../../store/+acceptance.state';
import { acceptanceSchema, initialValues } from './GuideAcceptanceInput.helper';

import './GuideAcceptanceInput.scss';

const mapDispatchToProps = mapActions({
  setPaymentData: GuideAcceptanceActions.setPaymentData,
  clearStepStatus: () => QueryActions.init(SET_PAYMENT_DATA_QUERY),
});

const mapStateToProps = (
  state: StoreState,
  { match: { params } }: RouteComponentProps<RouterGuideAcceptanceRouteParams>,
) => ({
  lead: getGuideLead(state),
  paymentDataStatus: getPaymentDataStatus(state),
  offer: getOfferById(state)(params),
});

interface SelectInterface {
  title: string;
  value: string;
}

type Props =
  & RouteComponentProps<RouterGuideAcceptanceRouteParams>
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const items: SelectInterface[] = [
  {
    title: T.customerGuidance.acceptance.bankData.taxSelect.default,
    value: '',
  },
  {
    title: T.customerGuidance.acceptance.bankData.taxSelect.smallBusiness,
    value: 'small-business',
  },
  {
    title: T.customerGuidance.acceptance.bankData.taxSelect.business,
    value: 'business',
  },
];

const GuideAcceptanceInputComponent: React.FC<Props> =
  ({
    lead,
    actions,
    paymentDataStatus,
    offer,
  }) => {
    const wizard = React.useContext(WizardContext);

    const onSubmit = (data: BankData) => {
      actions.setPaymentData(data);
    };

    React.useEffect(() => {
      if (wizard.next && paymentDataStatus.success) {
        wizard.next();
        actions.clearStepStatus();
      }
    }, [paymentDataStatus]);

    const vatRequired = !!(lead && lead.company);

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={() => acceptanceSchema(vatRequired)}
        render={(form) => (
          <Form>
            <div className={'c-guide-acceptance-input'}>
              <MediaCard
                footerContent={(
                  <div className={'c-guide-acceptance-input__button'}>
                    <Button
                      label={I18n.t(T.customerGuidance.acceptance.bankData.buttonCta)}
                      size={ButtonSize.SECONDARY}
                      type={'submit'}
                      isDisabled={!form.isValid}
                    />
                  </div>
                )}
              >
                <DefaultParagraph>
                  {offer && isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X)
                    ? I18n.t(T.customerGuidance.acceptance.bankData.description)
                    : I18n.t(T.customerGuidance.acceptance.bankData.descriptionFlatDirect)
                  }
                </DefaultParagraph>
                <div className={'o-grid'}>
                  <div className={'o-grid__column o-grid__column--sm-6'}>
                    <div className={'c-guide-acceptance-input__title'}>
                      {I18n.t(T.customerGuidance.acceptance.bankData.bankData)}
                    </div>
                    <div className={'c-guide-acceptance-input__fields'}>
                      <GuideAcceptanceInputField
                        form={form}
                        name={'firstName'}
                        type={'text'}
                        placeholder={I18n.t(T.customerGuidance.acceptance.bankData.firstName)}
                      />
                      <GuideAcceptanceInputField
                        form={form}
                        name={'lastName'}
                        type={'text'}
                        placeholder={I18n.t(T.customerGuidance.acceptance.bankData.lastName)}
                      />
                      <GuideAcceptanceInputField
                        form={form}
                        name={'iban'}
                        type={'text'}
                        placeholder={I18n.t(T.customerGuidance.acceptance.bankData.iban)}
                      />
                      <GuideAcceptanceInputField
                        form={form}
                        name={'bic'}
                        type={'text'}
                        placeholder={I18n.t(T.customerGuidance.acceptance.bankData.bic)}
                      />
                      <GuideAcceptanceCheckbox
                        form={form}
                        name={'sepa'}
                        label={I18n.t(T.customerGuidance.acceptance.bankData.sepaLabel)}
                      />
                    </div>
                  </div>
                  {/* @TODO: TEMPORARY DISABLED */}
                  {/* <div className={'o-grid__column o-grid__column--sm-6'}>
                    <div className={'c-guide-acceptance-input__title'}>
                      {I18n.t(T.customerGuidance.acceptance.bankData.taxData)}
                    </div>
                    <div className={'c-guide-acceptance-input__fields'}>
                      <GuideAcceptanceInputField
                        form={form}
                        name={'vat'}
                        type={'text'}
                        placeholder={I18n.t(T.customerGuidance.acceptance.bankData.vat)}
                      />
                      <GuideAcceptanceSelect
                        name={'taxStatus'}
                        form={form}
                        items={items}
                        initialValue={items[0]}
                      />
                    </div>
                  </div> */}
                </div>
              </MediaCard>
              <GuideAcceptanceBackButton />
            </div>
          </Form>
        )}
      />
    );
  };

export const GuideAcceptanceInput = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GuideAcceptanceInputComponent),
);
