
import { BarChartView, DataContainer, TooltipEvent, XYPoint } from '@kanva/charts';
import { View } from '@kanva/core';
import {
  barChartTimeLabelAccessor,
  labelAccessorEvery,
  resolveBarXPosition,
  SeriesKey,
  StatisticsResolution,
  StatisticsSeriesKey,
  TimeUnit,
} from '@sonnen/shared-web';
import { compose } from 'lodash/fp';
import * as React from 'react';

import { KanvaHelper } from '../../../kanva/kanva.helper';
import {
  KanvaContainers,
  KanvaContainerType,
  KanvaExtensions,
  KanvaExtensionType,
  KanvaTooltipValues,
} from '../../../kanva/kanva.types';
import { AnalysisBarChartContext } from './AnalysisBarChartProvider.context';
import { AnalysisBarChartProviderHelper } from './AnalysisBarChartProvider.helper';
import { AnalysisBarChartProviderProps } from './AnalysisBarChartProvider.types';

export const AnalysisBarChartProvider: React.FC<AnalysisBarChartProviderProps> = ({
  children,
  barChartSeries,
  statisticsSelectedDate,
}) => {
  const [chartView, setChartView] = React.useState<View<any>>();
  const [containers, setContainers] = React.useState<KanvaContainers>();
  const [currentXPosition, setCurrentXPosition] = React.useState(0);
  const [extensions, setExtensions] = React.useState<KanvaExtensions>({});
  const [primarySeries, setPrimarySeries] = React.useState<SeriesKey>(StatisticsSeriesKey.PRODUCED_ENERGY);
  const [tooltipEvent, setTooltipEvent] = React.useState<TooltipEvent | undefined>();
  const [tooltipValues, setTooltipValues] = React.useState<KanvaTooltipValues>();

  const handleTooltipPosition = () => {
    const defaultContainer = containers?.default;
    const tooltipExtension = extensions?.tooltip;

    if (!defaultContainer || !chartView || !tooltipExtension || !primarySeries) { return; }

    const position = resolveBarXPosition(defaultContainer, {
      date: statisticsSelectedDate.date,
      period: statisticsSelectedDate.period,
      // NOTE: resolveBarXPosition uses `date` and `period` which has compatible types, `resolution` is not used
      resolution: statisticsSelectedDate.resolution as unknown as StatisticsResolution,
    });
    const { absoluteX } = (chartView as BarChartView).getCanvasPositionForPoint({ x: position, y: 0 });
    const values = KanvaHelper.getLastSeriesValues(defaultContainer);

    KanvaHelper.handleTooltipPositionChange(absoluteX)(chartView, tooltipExtension);
    setTooltipValues(values);
    setCurrentXPosition(absoluteX);
  };

  const handleMount = (view: View<any>) => {
    if (view && !chartView) {
      setChartView(view);
    }
  };

  React.useEffect(() => {
    const tooltipExtension = KanvaHelper.createTooltipExtension(tooltipEvent, setTooltipEvent);
    const mappedDataSeries = AnalysisBarChartProviderHelper.prepareDataSeries(barChartSeries);
    const dataContainers = {
      [KanvaContainerType.DEFAULT]: new DataContainer<XYPoint>()
        .setYAxisParameters({
          tickCount: 5,
          roundTo: 1000,
          labelAccessor: (value: number) => (value / 1000) + ' kWh',
        }),
    };

    dataContainers.default.addExtension(tooltipExtension);
    dataContainers.default.setData(mappedDataSeries.default);

    setExtensions({
      [KanvaExtensionType.TOOLTIP]: tooltipExtension,
    });
    setContainers(dataContainers);

    return () => {
      if (containers && extensions.tooltip) {
        containers.default.removeExtension(extensions.tooltip);
      }
    };
  }, []);

  React.useEffect(() => {
    if (!containers?.default) { return; }
    const mappedDataSeries = AnalysisBarChartProviderHelper.prepareDataSeries(barChartSeries);
    const period = statisticsSelectedDate.period;

    containers.default.setData(mappedDataSeries.default);
    containers.default
      .setXAxisParameters({
        isGrouped: true,
        labelAccessor: compose(
          barChartTimeLabelAccessor(period),
          labelAccessorEvery(
            period === TimeUnit.MONTH || period === TimeUnit.DAY
              ? 3
              : 1,
          ),
        ),
      });
    setTimeout(() => {
      handleTooltipPosition();
    }, 300);
  }, [barChartSeries]);

  React.useEffect(() => {
    setTimeout(() => {
      handleTooltipPosition();
    }, 500);
  }, [chartView]);

  React.useEffect(() => {
    const defaultContainer = containers?.default;
    if (!defaultContainer || !tooltipEvent || !Boolean(tooltipEvent?.snap.x)) { return; }

    const values = KanvaHelper.getTooltipValues(defaultContainer, tooltipEvent);
    setTooltipValues(values);
    setCurrentXPosition(tooltipEvent.snap.x);
  }, [tooltipEvent]);

  return (
    <AnalysisBarChartContext.Provider value={{
      chartView,
      containers,
      currentXPosition,
      primarySeries,
      tooltipEvent,
      tooltipValues,
      extensions,
      handleMount,
    }}>
      {children}
    </AnalysisBarChartContext.Provider>
  );
};
