import { BatteryAssetStatus, BatteryStatus } from '+app/+dashboard/+battery/store/types/battery.interface';
import { Site, SiteOption } from './types/site.interface';

const getOrderedSites = (activeSite: Site | undefined, sitesList: Site[]): Site[] => activeSite
  ? [activeSite, ...sitesList.filter(site => site.id !== activeSite.id)]
  : sitesList;

const hasOption = (site: Site | undefined, option: SiteOption): boolean =>
  Boolean(site && site.options && site.options.includes(option));

const hasSites = (allSites: Site[] | undefined): boolean => Boolean(allSites && allSites.length);

const hasMultipleSites = (allSites: Site[] | undefined): boolean => Boolean(allSites && allSites.length > 1);

const getSiteBatteryStatus = (batteryId: string, batteryList: BatteryAssetStatus[]) =>
  batteryList.find(battery => battery.id === batteryId)?.attributes.assetStatus;

const filterOutSitesWithOnlyDiscardedBatteries = (
  allSites: Site[],
  batteryList: BatteryAssetStatus[] | undefined,
  onlySiteOptionsToCheck: SiteOption[],
): Site[] => {

  if (!batteryList || !batteryList.length) { return allSites; }

  return allSites?.filter(
    site => site.options?.every(option => onlySiteOptionsToCheck.includes(option))
      ? site.batterySystems.some(
        batteryId => SiteHelper.getSiteBatteryStatus(batteryId, batteryList) === BatteryStatus.INSTALLED)
      : true,
  );
};

export const SiteHelper = {
  getOrderedSites,
  getSiteBatteryStatus,
  hasOption,
  hasSites,
  hasMultipleSites,
  filterOutSitesWithOnlyDiscardedBatteries,
};
