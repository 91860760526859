import { useCountryFeature } from '@sonnen/shared-web';
import { isEmpty } from 'lodash';

import { DemoHelper } from '+app/+demo/helpers/demo.helper';
import { storeProvider } from '+app/store/store.provider';
import { CountryFeatureName } from '+config/countryFlags';
import { Locale } from '+legacy/helpers/i18n.helper';
import { QueryData } from '+shared/store/query/query.state';
import { isSiteWithCharger } from '+shared/store/site/site.selectors';
import { SiteElement } from '+shared/store/site/types/site.interface';
import { getValuesArrayFromEnum } from '+utils/array.util';
import { InfographicCardElement } from './Infographic.types';

const MAX_FAILED_REQUESTS = 5;

const getBasicCards = (): InfographicCardElement[] => {
  const storeState = storeProvider.getState();
  const isChargerCardAccessible = isSiteWithCharger(storeState)
    || useCountryFeature(CountryFeatureName.DASHBOARD_CHARGER).isEnabled
    || DemoHelper.isDemoUserCountry(Locale.DE)(storeState);

  const elements = {
    [InfographicCardElement.PV]: true,
    [InfographicCardElement.CONSUMPTION]: true,
    [InfographicCardElement.CHARGER]: isChargerCardAccessible,
    [InfographicCardElement.COMMUNITY]: true,
  };

  return getValuesArrayFromEnum(InfographicCardElement).filter(element => elements[element]);
};

const getCardsFromSiteElements = (siteElements: SiteElement[]): InfographicCardElement[] =>
  getValuesArrayFromEnum(InfographicCardElement)
    .filter(element => siteElements.includes(element))
    .filter(element =>
      element === InfographicCardElement.BATTERY && siteElements.includes(SiteElement.BACKUP)
        ? false
        : true);

const getAvailableCards = (siteElements: SiteElement[]): InfographicCardElement[] => ([
  ...getBasicCards(),
  ...getCardsFromSiteElements(siteElements),
].reduce((acc: InfographicCardElement[], curr) => acc.includes(curr)
    ? acc
    : [...acc, curr],
  [])
);

const isDataError = (
  data: Record<any, any> | undefined,
  query: QueryData,
) =>
  isEmpty(data) &&
  query.errorCount >= MAX_FAILED_REQUESTS;

const isDataLoading = (
  data: Record<any, any> | undefined,
  query: QueryData,
) => !isDataError(data, query) && isEmpty(data);

export const InfographicHelper = {
  getAvailableCards,
  isDataError,
  isDataLoading,
};
