import { Point } from '@kanva/core/dist/types';
import { EnergyFlowSeriesKey } from '@sonnen/shared-web';
import { createSelector } from 'reselect';

import { StoreState } from '+app/store/store.interface';
import { getBatteryTimezone, hasInstalledBattery } from '+battery/store/battery.selectors';
import { checkNested } from '+legacy/helpers/objects';
import { getSiteLiveData } from '+shared/store/live/live.selectors';
import { getActiveSiteId } from '+shared/store/site/site.selectors';
import { DateUtil } from '+utils/date.util';
import { getStatus } from '+utils/query.util';
import {
  getMaxForecastsDate,
  hasMeasurements as hasMeasurementsHelper,
  hasStatistics as hasStatisticsHelper,
  isMKMeterUser,
} from './analysis.helpers';
import {
  DOWNLOAD_MEASUREMENTS_CSV_QUERY,
  DOWNLOAD_STATISTICS_CSV_QUERY,
  GET_EVENTS_QUERY,
  GET_FORECAST_CONSUMPTION_QUERY,
  GET_FORECAST_PRODUCTION_QUERY,
  GET_MEASUREMENTS_QUERY,
  GET_STATISTICS_QUERY,
} from './analysis.state';

export const getAnalysisState = (state: StoreState) => state.analysis;

export const getMeasurementsState = createSelector(
  getAnalysisState,
  state => state.measurements,
);

export const getPreviousMeasurementMethod = createSelector(
  getAnalysisState,
  state => state.previousMeasurementMethod,
);

export const getLatestMeasurementsFetchDate = createSelector(
  getAnalysisState,
  state => state.latestMeasurementsFetchDate,
);

export const getEventsState = createSelector(
  getAnalysisState,
  state => state.events,
);

export const hasMeasurements = createSelector(
  getMeasurementsState,
  state => hasMeasurementsHelper(state),
);

export const getStatisticsState = createSelector(
  getAnalysisState,
  state => state.statistics,
);

export const hasStatistics = createSelector(
  getStatisticsState,
  state => hasStatisticsHelper(state),
);

export const getSelectedDate = createSelector(
  getAnalysisState,
  getBatteryTimezone,
  (state, timezone) => DateUtil.getDateInTimezone(state.selectedDate, timezone),
);

export const getSelectedDataSeriesKeys = createSelector(
  getAnalysisState,
  (analysis) => analysis.selectedDataSeriesKeys,
);

export const getAreaChartSeries = createSelector(
  getAnalysisState,
  state => state.areaChartSeries,
);

export const getAreaChartProduction = createSelector(
  getAnalysisState,
  state => state.areaChartSeries[EnergyFlowSeriesKey.PRODUCTION_POWER] as Point[],
);

export const getAreaChartConsumption = createSelector(
  getAnalysisState,
  state => state.areaChartSeries[EnergyFlowSeriesKey.CONSUMPTION_POWER] as Point[],
);

export const getEnergyFlowMeasurementsCache = createSelector(
  getAnalysisState,
  state => state.measurementsCache,
);

export const getStatisticsCache = createSelector(
  getAnalysisState,
  state => state.statisticsCache,
);

export const getAccessoriesChartSeries = createSelector(
  getAnalysisState,
  state => state.accessoriesChartSeries,
);

export const getStatisticsSelectedDate = createSelector(
  getAnalysisState,
  state => state.statisticsSelectedDate,
);

export const getBarChartSeries = createSelector(
  getAnalysisState,
  state => state.barChartSeries,
);

export const getSummerTimeChange = createSelector(
  getAnalysisState,
  state => state.summerTimeChange,
);

export const getMeasurementsQueryStatus = createSelector(
  getAnalysisState,
  state => getStatus(state[GET_MEASUREMENTS_QUERY]),
);

export const getEventsQueryStatus = createSelector(
  getAnalysisState,
  state => getStatus(state[GET_EVENTS_QUERY]),
);

export const getStatisticsQueryStatus = createSelector(
  getAnalysisState,
  state => getStatus(state[GET_STATISTICS_QUERY]),
);

export const getDownloadMeasurementsCsvQueryStatus = createSelector(
  getAnalysisState,
  state => getStatus(state[DOWNLOAD_MEASUREMENTS_CSV_QUERY]),
);

export const getDownloadStatisticsCsvQueryStatus = createSelector(
  getAnalysisState,
  state => getStatus(state[DOWNLOAD_STATISTICS_CSV_QUERY]),
);

export const getForecastProductionQueryStatus = createSelector(
  getAnalysisState,
  state => getStatus(state[GET_FORECAST_PRODUCTION_QUERY]),
);

export const getForecastConsumptionQueryStatus = createSelector(
  getAnalysisState,
  state => getStatus(state[GET_FORECAST_CONSUMPTION_QUERY]),
);

export const getPieChartSeries = createSelector(
  getAnalysisState,
  state => state.pieChartSeries,
);

export const getSiteEvents = (startDate: Date) =>
  createSelector(
    getActiveSiteId,
    getEventsState,
    (siteId, events) => checkNested(events, [siteId, startDate], true, undefined),
  );

export const getMeasurementsMethod = createSelector(
  getAnalysisState,
  state => state.measurements && state.measurements.measurementMethod,
);

export const shouldStatisticsAppear = createSelector(
  getAnalysisState,
  state => !!(state.measurements && !isMKMeterUser(state.measurements.measurementMethod)),
);

export const getForecastProductionState = createSelector(
  getAnalysisState,
  state => state.forecastProduction,
);

export const getForecastConsumptionState = createSelector(
  getAnalysisState,
  state => state.forecastConsumption,
);

export const hasForecast = createSelector(
  getForecastConsumptionState,
  getForecastProductionState,
  (consumption, production): boolean => Boolean(consumption && consumption.length || production && production.length),
);

export const isForecastAvailable = createSelector(
  hasInstalledBattery,
  getSiteLiveData,
  getSelectedDate,
  (hasBattery, siteLiveData, selectedDate): boolean =>
    hasBattery
    && !!(siteLiveData && siteLiveData.online)
    && selectedDate.isSameOrAfter(new Date(), 'day'),
);

export const getDatepickerMaxDate = createSelector(
  getForecastProductionState,
  getForecastConsumptionState,
  (production, consumption): Date => getMaxForecastsDate(production, consumption),
);
