import { Icon } from '@sonnen/shared-web';
import { IconType } from '@sonnen/shared-web/src/components/Icon/models';
import * as React from 'react';

import './NestedNavSlideHeader.component.scss';

interface NestedNavSlideHeaderProps {
  icon: IconType;
  title: string;
  goBack: () => void;
}

export const NestedNavSlideHeader: React.FC<NestedNavSlideHeaderProps> = ({ icon, title, goBack }) => {  
  const TitleIcon = Icon[icon];

  return (
    <div className={'c-nested-nav-slide-header'}>
      <button
        className={'c-nested-nav-slide-header__button'}
        onClick={goBack}
      >
        <Icon.Angle className={'c-nested-nav-slide-header__icon c-nested-nav-slide-header__icon--back'} />
      </button>
      <div className={'c-nested-nav-slide-header__title'}>
        <TitleIcon className={'c-nested-nav-slide-header__icon c-nested-nav-slide-header__icon--title'} />
        {title}
      </div>
    </div>
  );
};
