import { ActionsUnion, createAction } from '+utils/redux';
import { MeterInstallation } from './types/meterInstallation.interface';
import { MeterReadingsPayload } from './types/meterReadings.interface';

export enum METER_ACTIONS {
  GET_METER_INSTALLATION_DATA = '[Meter] GET_METER_INSTALLATION_DATA',
  SET_METER_INSTALLATION_DATA = '[Meter] SET_METER_INSTALLATION_DATA',
  SEND_CONSUMPTION_USER_READINGS = '[Meter] SEND_CONSUMPTION_USER_READINGS',
  SEND_FEEDIN_USER_READINGS = '[Meter] SEND_FEEDIN_USER_READINGS',
  CLEAR_METER_DATA = '[Meter] CLEAR_METER_DATA',
}

export const MeterActions = {
  getMeterInstallationData: () =>
    createAction(METER_ACTIONS.GET_METER_INSTALLATION_DATA),
  setMeterInstallationData: (meterInstallation: MeterInstallation) =>
    createAction(METER_ACTIONS.SET_METER_INSTALLATION_DATA, {
      meterInstallation,
    }),

  sendConsumptionUserReadings: (userReadings: MeterReadingsPayload) =>
    createAction(METER_ACTIONS.SEND_CONSUMPTION_USER_READINGS, {
      userReadings,
    }),

  sendFeedInUserReadings: (userReadings: MeterReadingsPayload) =>
    createAction(METER_ACTIONS.SEND_FEEDIN_USER_READINGS, { userReadings }),

  clearMeterData: () => createAction(METER_ACTIONS.CLEAR_METER_DATA),
};

export type MeterActions = ActionsUnion<typeof MeterActions>;
