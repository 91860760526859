import { createLayoutMap, MATCH_PARENT, PARENT_ID, WRAP_CONTENT } from '@kanva/core';

import {
  TOP_DISTANCE,
  X_AXIS_MARGIN,
  X_AXIS_PADDING,
  Y_AXIS_PADDING,
} from '../../helpers/analysis.helper';
import { Views } from './AnalysisAreaChart.helper';

export const LINE_CHART_HEIGHT = 60;

export const layout = createLayoutMap({
  wrapper: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
    alignTop: PARENT_ID,
    alignEnd: PARENT_ID,
    above: Views.X_AXIS,
    margin: {
      top: TOP_DISTANCE,
    },
  },
  grid: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  chart: {
    width: MATCH_PARENT,
    height: MATCH_PARENT,
  },
  xAxis: {
    width: MATCH_PARENT,
    height: WRAP_CONTENT,
    alignStart: PARENT_ID,
    alignEnd: PARENT_ID,
    above: Views.LINE_WRAPPER,
    minHeight: X_AXIS_MARGIN.VERTICAL + 20,
    padding: {
      top: X_AXIS_PADDING,
    },
  },
  yAxisLeft: {
    width: WRAP_CONTENT,
    height: MATCH_PARENT,
    alignTop: PARENT_ID,
    alignStart: PARENT_ID,
    alignBottom: PARENT_ID,
    above: Views.X_AXIS,
    padding: {
      top: TOP_DISTANCE,
      left: Y_AXIS_PADDING,
    },
  },
  yAxisRight: {
    width: WRAP_CONTENT,
    height: MATCH_PARENT,
    alignTop: PARENT_ID,
    alignEnd: PARENT_ID,
    above: Views.X_AXIS,
    padding: {
      top: TOP_DISTANCE,
      right: Y_AXIS_PADDING,
    },
  },
  lineWrapper: {
    width: MATCH_PARENT,
    height: LINE_CHART_HEIGHT,
    alignStart: PARENT_ID,
    alignEnd: PARENT_ID,
    alignBottom: PARENT_ID,
  },
  // batteryCare: {
  //   width: MATCH_PARENT,
  //   height: LINE_CHART_HEIGHT,
  // },
  lineLabel: {
    centerVertical: true,
    padding: {
      top: 25,
    },
  },
});
