import { GuideStepOwner } from '+app/+guide/helpers/Guide.helper';

export interface GuideDiscoveryStepDetails {
  checked: boolean;
  owner?: GuideStepOwner;
  dateOfChecking?: string | null;
}

export const initialGuideDiscoveryStepDetails: GuideDiscoveryStepDetails = {
  owner: undefined,
  dateOfChecking: null,
  checked: false,
};

export enum GuideDiscoveryModalType {
  CHECKED = 'checked',
  UNCHECKED = 'unchecked',
}

export interface GuideDiscoveryState {
  activeModal: GuideDiscoveryModalType | null;
}

export const initialState: GuideDiscoveryState = {
  activeModal: null,
};
