import { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';

import { combineSagas } from '+utils/saga';
import { LAYOUT_ACTIONS, LayoutActions } from './layout.actions';

function* toggleLayoutScrolling({status}: ReturnType<typeof LayoutActions.toggleLayoutScrolling>): SagaIterator {
  document.body.classList[status ? 'add' : 'remove']('c-body--disabled');
}

export const layoutSagas = combineSagas(
  takeEvery(LAYOUT_ACTIONS.TOGGLE_LAYOUT_SCROLLING, toggleLayoutScrolling),
);
