import { createSelector } from 'reselect';

import { StoreState } from '+app/store/store.interface';
import { DashboardState } from './dashboard.state';

export const getDashboardState = (state: StoreState): DashboardState => state.dashboard;

export const isModalBackupBufferVisible = createSelector(
  getDashboardState,
  state => state.isModalBackupBufferVisible,
);
