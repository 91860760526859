import { query } from '+shared/store/query/query.saga';
import { call, Effect } from 'redux-saga/effects';

export type RepositoryFunction<Input, Result> = (input: Input) => Promise<Result>;

export type ExtraProcessQueryParams<Result> = {
  onSuccess?: (res?: Result) => Effect;
  // TODO: fix this assumption that a request will fail with a native Error response.
  onFailure?: <T extends Error>(err?: T) => Effect;
};

export const processQuery = <Input, Response>(
  queryName: string,
  queryFunction: RepositoryFunction<Input, Response>,
  { onSuccess, onFailure }: ExtraProcessQueryParams<Response> = {},
) => (input: Input) =>
// TODO: fix TS(2769) error to get rid of ts-ignore
// @ts-ignore
  call(query, { queryName, queryFunction, extraParams: { onSuccess, onFailure }, input });
