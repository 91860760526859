import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getSetupWarrantyAcceptanceStatus } from '+app/+guide/+setup/store/+setup.selectors';
import { GuideGridStretched, GuideInfoCard, GuideInfoCardList } from '+app/+guide/components';
import { GuideAccordion } from '+app/+guide/containers';
import { GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StoreState } from '+app/store/store.interface';

const mapStateToProps = (state: StoreState) => ({
  stepStatus: getSetupWarrantyAcceptanceStatus(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & RouteComponentProps
  ;

export const GuideSetupStepWarrantyAcceptanceComponent: React.FC<Props> = ({ stepStatus }) => {

  const content = {
    [GuideStepStatus.ACTIVE]: {
      headline: I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.headline),
      tag: I18n.t(T.customerGuidance.tag.text),
      status: 'open',
    },
    [GuideStepStatus.DONE]: {
      headline: I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.headline),
      tag: I18n.t(T.customerGuidance.tag.done),
      date: '20.20.2020',
      status: 'done',
    },
  };

  return (
    <GuideAccordion
      anchor={'warranty-acceptance'}
      status={stepStatus}
      title={content[stepStatus].headline}
      tag={content[stepStatus].tag}
      tagTheme={content[stepStatus].status}
      date={content[stepStatus].date}
    >
      {{
        [GuideStepStatus.ACTIVE]: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.information.headline)}
              headerIcon={<Icon.Info />}
            >
              {I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.information.description)}
            </GuideInfoCard>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.yourToDo.headline)}
              headerIcon={<Icon.Contract />}
              isIconBig={true}
            >
              <GuideInfoCardList>
                {I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.yourToDo.description.email)}
                {I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.yourToDo.description.redirect)}
                {I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.yourToDo.description.notRecived)}
              </GuideInfoCardList>
            </GuideInfoCard>
          </GuideGridStretched>
        ),
        [GuideStepStatus.DONE]: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.information.headline)}
              headerIcon={<Icon.Info />}
            >
              {I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.information.description)}
            </GuideInfoCard>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.yourToDo.headline)}
              headerIcon={<Icon.Contract />}
              isIconBig={true}
            >
              <GuideInfoCardList>
                {I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.yourToDo.description.email)}
                {I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.yourToDo.description.redirect)}
                {I18n.t(T.customerGuidance.setup.step.warrantyAcceptance.active.yourToDo.description.notRecived)}
              </GuideInfoCardList>
            </GuideInfoCard>
          </GuideGridStretched>
        ),
      }}
    </GuideAccordion>
  );
};

export const GuideSetupStepWarrantyAcceptance =
  withRouter(connect(mapStateToProps)(GuideSetupStepWarrantyAcceptanceComponent));
