import * as classNames from 'classnames';
import * as React from 'react';

import { InfographicCard } from '../InfographicCard/InfographicCard.component';
import { InfographicPainting } from '../InfographicPainting/InfographicPainting.component';

import './InfographicWrapper.component.scss';

export const InfographicWrapper: React.FC = () => (
  <div className={'c-infographic-wrapper'}>
    <div className={classNames(
        'c-infographic-wrapper__painting-cover', {
        'c-infographic-wrapper__painting-cover--enabled': false, // TODO batteryDataMissing
      })}
    />
    <InfographicPainting />
    <div className={'c-infographic-wrapper__content'}>
      <InfographicCard />
    </div>
  </div>
);
