import { StatisticsData } from '+app/shared/types/statisticsData.interface';
import { Query } from '+query/query.state';
import { Moment } from 'moment';
import { Contract, ContractAttributes, ContractDocuments, ContractDocumentUrl } from './types/contract.interface';
import { ContractFileRequest } from './types/contractFilesRequestData.interface';
import { ContractInvoiceRequest } from './types/contractInvoicesRequestData.interface';

export const GET_CONTRACT_DATA_QUERY = 'getContractDataQuery';
export const GET_STATISTICS_DATA_QUERY = 'getStatisticsDataQuery';
export const GET_CONTRACT_FILES_REQUEST_DATA_QUERY = 'getContractFilesRequestDataQuery';
export const GET_CONTRACT_INVOICES_REQUEST_DATA_QUERY = 'getContractInvoicesRequestDataQuery';
export const TERMINATE_CONTRACT_QUERY = 'terminateContractRequestDataQuery';
export const GET_CONTRACT_DOCUMENTS_QUERY = 'getContractDocumentsQuery';
export const GET_CONTRACT_DOCUMENT_URL_QUERY = 'getContractDocumentUrlQuery';

// Note: now we don't store fetched data for each site, but each time user
// changes site the data is fetched, that is how it works in new app in
// different subpages
export interface ContractState {
  contracts: Contract[];
  flatContractStatistics?: StatisticsData;
  energyDocuments: {
    contractFilesRequestData: Record<string, ContractFileRequest[]>;
    contractInvoicesRequestData: Record<string, ContractInvoiceRequest[]>;
  };
  documents: ContractDocuments[];
  documentUrl?: string;
  selectedDocumentId?: string;
  // TODO: Make that query names better
  [GET_CONTRACT_DATA_QUERY]: Query<ContractAttributes>;
  [GET_STATISTICS_DATA_QUERY]: Query<StatisticsData>;
  [GET_CONTRACT_FILES_REQUEST_DATA_QUERY]: Query<ContractFileRequest[]>;
  [GET_CONTRACT_INVOICES_REQUEST_DATA_QUERY]: Query<ContractInvoiceRequest[]>;
  [TERMINATE_CONTRACT_QUERY]: Query<ContractInvoiceRequest[]>;
  [GET_CONTRACT_DOCUMENTS_QUERY]: Query<ContractDocuments>;
  [GET_CONTRACT_DOCUMENT_URL_QUERY]: Query<ContractDocumentUrl>;
}

export const initialState: ContractState = {
  contracts: [],
  flatContractStatistics: undefined,
  energyDocuments: {
    contractFilesRequestData: {},
    contractInvoicesRequestData: {},
  },
  documents: [],
  documentUrl: undefined,
  selectedDocumentId: undefined,
  [GET_CONTRACT_DATA_QUERY]: {},
  [GET_STATISTICS_DATA_QUERY]: {},
  [GET_CONTRACT_FILES_REQUEST_DATA_QUERY]: {},
  [GET_CONTRACT_INVOICES_REQUEST_DATA_QUERY]: {},
  [TERMINATE_CONTRACT_QUERY]: {},
  [GET_CONTRACT_DOCUMENTS_QUERY]: {},
  [GET_CONTRACT_DOCUMENT_URL_QUERY]: {},
};
