import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { Contract } from '+contract/store/types/contract.interface';
import { getFormattedDate } from '+legacy/helpers/dates';
import { InfographicCardConnectionStatusType } from '../../containers/Infographic/Infographic.types';
import {
  InfographicContractCardData,
  InfographicContractCardStatusType,
} from '../InfographicContractCard/InfographicContractCard.types';
import { InfographicValue } from '../InfographicValue/InfographicValue.component';

interface Props {
  contract: Contract | undefined;
  cardData: InfographicContractCardData;
  status?: InfographicCardConnectionStatusType | InfographicContractCardStatusType;
}

const renderPurchaseCompleteValue = (contract: Contract) => {
  const notification = contract.deliveryStartAt
    ? T.yourFlat.notifications.wrongDeliveryDate
    : T.yourFlat.notifications.deliveryDateNotPresent;
  const orderedAt = contract.orderedAt;

  return (
    <InfographicValue
      title={I18n.t(T.dashboard.infographic.contract.inProgress.headline)}
      description={
        <DefaultParagraph className={'c-infographic__default-headline'}>
          <>
            {I18n.t(notification.title)}
            <br />
            {
            !isNil(orderedAt) || !isNil(contract.deliveryStartAt) &&
              <Translate
                value={notification.subtitle}
                date={getFormattedDate(
                  contract.deliveryStartAt || contract.orderedAt || undefined,
                  T.general.date.long,
                )}
                dangerousHTML={true}
              />
            }
          </>
        </DefaultParagraph>
      }
    />
  );
};

const renderSupplyValue = (contract: Contract) => {
  const date = getFormattedDate(contract.deliveryStartAt);
  return (
    <InfographicValue
      title={I18n.t(T.dashboard.infographic.contract.inProgress.headline)}
      description={
        <DefaultParagraph className={'c-infographic__default-headline'}>
          <>{I18n.t(T.yourFlat.notifications.supply, { date })}</>
        </DefaultParagraph>
      }
    />
  );
};

const renderMissingDataValue = () => (
  <InfographicValue
    title={I18n.t(T.dashboard.infographic.contract.sonnenFlat.headline)}
    icon={'Contract'}
    value={'-'}
    isThemeInactive={true}
  />
);

const renderContractDataValue = (cardData: InfographicContractCardData) => (
  <InfographicValue
    title={cardData.subTitle}
    icon={'Contract'}
    value={cardData.value || '-'}
    unit={cardData.unit}
    description={cardData.description}
  />
);

const renderValue = (
  contract: Contract,
  cardData: InfographicContractCardData,
  status?: InfographicCardConnectionStatusType | InfographicContractCardStatusType,
) => {
  switch (status) {
    case InfographicContractCardStatusType.PUCHARSE_COMPLETE:
      return renderPurchaseCompleteValue(contract);

    case InfographicContractCardStatusType.SUPPLY:
      return renderSupplyValue(contract);

    case InfographicContractCardStatusType.MISSING_CONTRACT_DATA:
      return renderMissingDataValue();

    default:
      return renderContractDataValue(cardData);
  }
};

export const InfographicContractCardValue: React.FC<Props> = ({ contract, cardData, status }) =>
  contract
    ? renderValue(contract, cardData, status)
    : renderMissingDataValue();
