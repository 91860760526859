import { isNil } from 'lodash';

import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { COMMUNITY_ACTIONS, CommunityActions } from './community.actions';
import { CommunityState, GET_COMMUNITY_STATS_QUERY, initialState } from './community.state';

export const reducer = createReducer<CommunityState, CommunityActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case COMMUNITY_ACTIONS.SET_COMMUNITY_STATS_DATA:
        return {
          ...state,
          stats: action.stats,
        };
      case COMMUNITY_ACTIONS.SET_COMMUNITY_PROFILE_DATA:
        return {
          ...state,
          profile: action.profile,
        };
      case COMMUNITY_ACTIONS.SET_ACTIVE_CARD_TYPE:
        return {
          ...state,
          activeMapCardType: action.cardType,
          isCardVisible: true,
        };
      case COMMUNITY_ACTIONS.TOGGLE_CARD:
        return {
          ...state,
          isCardVisible: !isNil(action.isVisible)
            ? action.isVisible
            : !state.isCardVisible,
        };
      case COMMUNITY_ACTIONS.SELECT_COMMUNITY_PROFILE:
        return {
          ...state,
          isCardVisible: true,
        };
      case COMMUNITY_ACTIONS.SET_COMMUNITY_CONTENT_HUB_ARTICLES:
        return {
          ...state,
          contentHubNews: action.articles,
        };
      default:
        return queryReducer(state, action, [
          GET_COMMUNITY_STATS_QUERY,
        ]);
    }
  },
  {
    persist: {
      key: 'community',
      blacklist: [],
    },
  },
);
