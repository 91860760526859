import { select, put, takeEvery } from 'redux-saga/effects';
import { createRoutine } from 'redux-saga-routines';
import { selectWorld, selectBatteries } from './communityMap.selector';
import { getWorldMapBase, getBatteriesGeom } from './communityMap.api';
import { combineSagas } from '+utils/saga';

const MAP_GET_WORLD_DATA = 'MAP/GET_WORLD_DATA';
const MAP_GET_BATTERIES_DATA = 'MAP/GET_BATTERIES_DATA';

export const worldMapRoutine = createRoutine(MAP_GET_WORLD_DATA);
export const batteryMapRoutine = createRoutine(MAP_GET_BATTERIES_DATA);

function* getWorldMapData() {
  const worldMap = yield select(selectWorld);

  if (!worldMap) {
    try {
      const worldMapData = yield getWorldMapBase();
      yield put(worldMapRoutine.success(worldMapData));
    } catch (error) {
      yield put(worldMapRoutine.failure(error));
    }
  }
}

function* getBatteryMapData() {
  const batteryMap = yield select(selectBatteries);

  if (!batteryMap) {
    try {
      const batteryMapData = yield getBatteriesGeom();
      yield put(batteryMapRoutine.success(batteryMapData));
    } catch (error) {
      yield put(batteryMapRoutine.failure(error));
    }
  }
}

export default combineSagas(
  takeEvery(worldMapRoutine.TRIGGER, getWorldMapData),
  takeEvery(batteryMapRoutine.TRIGGER, getBatteryMapData),
);
