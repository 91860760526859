import { deepMap } from './object.util';

export const FILTER_KEYWORD = '[Filtered]';
const PRIVATE_KEYWORDS: string[] = [
  'password',
  'secret',
  'card',
  'billing',
  'shipping',
  'contracting_part',
  'bank',
  'street',
  'postal_code',
  'phone',
  'mobile',
  'first_name',
  'last_name',
  'city',
];

const shuffleString = (str: string) => typeof str === 'string' 
  ? str.split('').sort(() => .5 - Math.random()).join('') 
  : FILTER_KEYWORD;

export const filterPrivateData = (data: any) => deepMap(
  data, 
  (val: string, key: string) => {
    if (PRIVATE_KEYWORDS.find(keyword => String(key).includes(keyword))) {
      return FILTER_KEYWORD;
    } else if (String(key).includes('Token')) {
      return shuffleString(val); 
    } else {
      return val;
    } 
  },
);
