import * as React from 'react';

import './GuideInfoCardList.component.scss';

export const GuideInfoCardList: React.FC = ({ children }) => (
  <ul className={'c-guide-info-card-list'}>
    {React.Children.map(children, child => (
      <li className={'c-guide-info-card-list__item'}>
        {child}
      </li>
    ))}
  </ul>
);
