import * as React from 'react';
import * as uuid from 'uuid';
import { EnergyRay } from './EnergyRay.component';
import { EnergyRayClip } from './EnergyRayClip.component';
import { Munchkins } from './Munchkins.component';

interface Props {
  x: number;
  y: number;
  balanceValue: number;
  range: number[];
}

export class Community extends React.PureComponent<Props> {
  clipId = `clip-${uuid.v4()}`;

  render() {
    return (
      <g transform={`translate(${this.props.x}, 0)`}>
        <EnergyRayClip
          {...this.props}
          clipId={this.clipId}
        />
        <EnergyRay
          {...this.props}
          clipId={this.clipId}
        />
        <Munchkins {...this.props} />
      </g>
    );
  }
}
