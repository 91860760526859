import { createReducer } from '+utils/redux';
import { SYNC_ACTIONS, SyncActions } from './sync.actions';
import { initialState, SyncState } from './sync.state';

export const reducer = createReducer<SyncState, SyncActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case SYNC_ACTIONS.SYNC_START:
        return {
          ...state,
          isLoading: true,
        };
      case SYNC_ACTIONS.SYNC_END:
        return {
          ...state,
          isLoading: false,
        };
      default:
        return state;
    }
  },
);
