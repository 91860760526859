import { CommunityActions } from '+community/store';
import { QueryActions } from '+query/query.actions';
import { UserError } from '+shared/store/user/types/user.interface';
import { UserActions } from '+shared/store/user/user.actions';
import { getCurrentUserId } from '+shared/store/user/user.selectors';
import { combineSagas, dataGuard, processQuery } from '+utils/saga';
import { isNil } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { ACCOUNT_ACTIONS, AccountActions } from './account.actions';
import { AccountRepository } from './account.repository';
import {
  CHANGE_USER_PASSWORD_QUERY,
  GET_ACCOUNT_PROFILE_QUERY,
  SET_USER_DATA_QUERY,
  UPDATE_ACCOUNT_PROFILE_QUERY,
} from './account.state';
import { Profile, ProfileError } from './types/profile.interface';

export const accountSagas = combineSagas(
  takeLatest(ACCOUNT_ACTIONS.GET_ACCOUNT_PROFILE_DATA, getAccountProfile),
  takeLatest(ACCOUNT_ACTIONS.UPDATE_ACCOUNT_PROFILE_DATA, updateAccountProfile),
  takeLatest(ACCOUNT_ACTIONS.SET_USER_DATA, setUserData),
  takeLatest(ACCOUNT_ACTIONS.CHANGE_USER_PASSWORD, changeUserPasswordData),
);

function * getAccountProfile(
  action: ReturnType<typeof AccountActions.getAccountProfileData>,
): SagaIterator {
  const userId: string = action.userId || (yield select(getCurrentUserId));

  if (isNil(userId)) {
    return;
  }

  yield processQuery(
    GET_ACCOUNT_PROFILE_QUERY,
    AccountRepository.getAccountProfileData,
    {
      onSuccess: res => all([
        dataGuard(AccountActions.setAccountProfileData)(res as Profile),
        dataGuard(CommunityActions.setCommunityProfileData)(res as Profile),
      ]),
      onFailure: (error: ProfileError) => put(QueryActions.failure(GET_ACCOUNT_PROFILE_QUERY, error as Error)),
    },
  )(userId);
}

function * updateAccountProfile(
  action: ReturnType<typeof AccountActions.updateAccountProfileData>,
): SagaIterator {
  const userId: string = action.userId || (yield select(getCurrentUserId));

  if (isNil(userId)) {
    return;
  }
  yield processQuery(
    UPDATE_ACCOUNT_PROFILE_QUERY,
    AccountRepository.setAccountProfileData,
    {
      onSuccess: () => put(AccountActions.getAccountProfileData(userId)),
      onFailure: (error: ProfileError) => put(QueryActions.failure(UPDATE_ACCOUNT_PROFILE_QUERY, error as Error)),
    },
  )({
    id: userId,
    attributes: action.attributes,
  });
}

function * setUserData(
  action: ReturnType<typeof AccountActions.setUserData>,
): SagaIterator {
  const userId: string = action.userId || (yield select(getCurrentUserId));

  if (isNil(userId)) {
    return;
  }

  yield processQuery(
    SET_USER_DATA_QUERY,
    AccountRepository.setUserData,
    {
    // TODO: Use proper save action when user reducer will be refactored + add proper type / dataGuard
      onSuccess: (res: any) => put(UserActions.setUser(res)),
      onFailure: (error: UserError) => put(QueryActions.failure(SET_USER_DATA_QUERY, error as Error)),
    },
  )({
    id: userId,
    attributes: action.attributes,
  });
}

function * changeUserPasswordData(
  action: ReturnType<typeof AccountActions.changeUserPassword>,
): SagaIterator {
  const userId: string = action.userId || (yield select(getCurrentUserId));

  if (isNil(userId)) {
    return;
  }
  yield processQuery(
    CHANGE_USER_PASSWORD_QUERY,
    AccountRepository.changeUserPassword,
    {
      onFailure: (error: UserError) => put(QueryActions.failure(CHANGE_USER_PASSWORD_QUERY, error as Error)),
    },
  )({
    id: userId,
    attributes: action.attributes,
  });
}
