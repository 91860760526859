import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { InfographicHelper } from '+dashboard/+infographic';
import { StateAlert } from '+shared/components/StateAlert';
import { LiveChargerHoCDataProps } from '+shared/store/live/liveCharger.hoc';
import { QueryData } from '+shared/store/query/query.state';

interface Props {
  chargerData: LiveChargerHoCDataProps;
  query: QueryData;
}

export const InfographicChargerCardStatus: React.FC<Props> = ({ chargerData, query }) => {
  const {
    isOnline,
    isCarConnected,
    isSmartmodeCharging,
    isPowermodeCharging,
    isReadyForCharging,
  } = chargerData;
  const isNoDataError = InfographicHelper.isDataError(chargerData, query);

  return (
    <>
      {isCarConnected && (
        <StateAlert
          icon={<Icon.Checkmark />}
          small={true}
        >
          {I18n.t(T.dashboard.infographic.statuses.carConnected)}
        </StateAlert>
      )}
      {isSmartmodeCharging && (
        <StateAlert
          icon={<Icon.Checkmark />}
          small={true}
        >
          {I18n.t(T.dashboard.infographic.statuses.smartModeCharging)}
        </StateAlert>
      )}
      {isPowermodeCharging && (
        <StateAlert
          icon={<Icon.Checkmark />}
          small={true}
        >
          {I18n.t(T.dashboard.infographic.statuses.powerModeCharging)}
        </StateAlert>
      )}
      {isReadyForCharging && (
        <StateAlert
          icon={<Icon.Checkmark />}
          small={true}
        >
          {I18n.t(T.dashboard.infographic.statuses.readyForCharging)}
        </StateAlert>
      )}
      {isNoDataError && (
        <StateAlert
          variant={'warning'}
          icon={<Icon.Warning />}
          small={true}
        >
          {I18n.t(T.dashboard.infographic.statuses.cannotFetchData)}
        </StateAlert>
      )}
      {!isNoDataError && !isOnline && (
        <StateAlert
          variant={'warning'}
          icon={<Icon.Offline />}
          small={true}
        >
          {I18n.t(T.dashboard.infographic.statuses.chargerOffline)}
        </StateAlert>
      )}
    </>
  );
};
