import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { ANALYSIS_ACTIONS, AnalysisActions } from './analysis.actions';
import {
  AnalysisState,
  DOWNLOAD_MEASUREMENTS_CSV_QUERY,
  DOWNLOAD_STATISTICS_CSV_QUERY,
  GET_EVENTS_QUERY,
  GET_MEASUREMENTS_QUERY,
  GET_STATISTICS_QUERY,
  initialState,
} from './analysis.state';

export const reducer = createReducer<AnalysisState, AnalysisActions>(
  (state = initialState, action): AnalysisState => {
    switch (action.type) {
      case ANALYSIS_ACTIONS.SET_MEASUREMENTS:
        return {
          ...state,
          previousMeasurementMethod: state.measurements
            ? state.measurements.measurementMethod
            : undefined,
          latestMeasurementsFetchDate: new Date(),
          measurements: action.measurements,
        };
      case ANALYSIS_ACTIONS.SET_MEASUREMENTS_CACHE:
        return {
          ...state,
          measurementsCache: action.measurementsCache,
        };
      case ANALYSIS_ACTIONS.SET_STATISTICS:
        return {
          ...state,
          statistics: action.statistics,
        };
      case ANALYSIS_ACTIONS.SET_STATISTICS_CACHE:
        return {
          ...state,
          statisticsCache: action.statisticsCache,
        };
      case ANALYSIS_ACTIONS.SET_AREA_CHART_SERIES:
        return {
          ...state,
          areaChartSeries: {
            ...state.areaChartSeries,
            ...action.areaChartSeries,
          },
        };
      case ANALYSIS_ACTIONS.SET_SELECTED_DATE:
        return {
          ...state,
          selectedDate: action.date,
        };
      case ANALYSIS_ACTIONS.TOGGLE_AREA_CHART_SERIES:
        return {
          ...state,
          selectedDataSeriesKeys:
            state.selectedDataSeriesKeys.includes(action.dataSeriesKey)
              ? state.selectedDataSeriesKeys.filter(dataSerie => dataSerie !== action.dataSeriesKey)
              : [...state.selectedDataSeriesKeys, action.dataSeriesKey],
        };
      case ANALYSIS_ACTIONS.SET_DATA_SERIES_KEYS:
        return {
          ...state,
          selectedDataSeriesKeys: action.dataSeriesKeys,
        };
      case ANALYSIS_ACTIONS.SET_STATISTICS_SELECTED_DATE:
        return {
          ...state,
          statisticsSelectedDate: action.statisticsSelectedDate,
        };
      case ANALYSIS_ACTIONS.SET_BAR_CHART_SERIES:
        return {
          ...state,
          barChartSeries: action.barChartSeries,
        };
      case ANALYSIS_ACTIONS.SET_PIE_CHART_SERIES:
        return {
          ...state,
          pieChartSeries: action.pieChartSeries,
        };
      case ANALYSIS_ACTIONS.SET_EVENTS:
        return {
          ...state,
          events: action.events,
        };
      case ANALYSIS_ACTIONS.SET_BACKUP_BOX_SERIES:
        return {
          ...state,
          accessoriesChartSeries: {
            ...state.accessoriesChartSeries,
            ...action.backupBoxSeries,
          },
        };
      case ANALYSIS_ACTIONS.SET_HEATER_POWER_SERIES:
        return {
          ...state,
          accessoriesChartSeries: {
            ...state.accessoriesChartSeries,
            ...action.heaterPowerSeries,
          },
        };
      case ANALYSIS_ACTIONS.SET_SUMMER_TIME:
        return {
          ...state,
          summerTimeChange: action.summerTimeChange,
        };
      case ANALYSIS_ACTIONS.SET_FORECAST_PRODUCTION:
        return {
          ...state,
          forecastProduction: action.forecastProduction,
        };
      case ANALYSIS_ACTIONS.SET_FORECAST_CONSUMPTION:
        return {
          ...state,
          forecastConsumption: action.forecastConsumption,
        };
      case ANALYSIS_ACTIONS.CLEAR_FORECAST_DATA:
        return {
          ...state,
          forecastProduction: [],
          forecastConsumption: [],
          areaChartSeries: {
            ...state.areaChartSeries,
            forecastConsumptionPower: [],
            forecastProductionPower: [],
          },
        };
      case ANALYSIS_ACTIONS.CLEAR_ANALYSIS:
        return {
          ...initialState,
        };
      default:
        return queryReducer(state, action, [
          GET_MEASUREMENTS_QUERY,
          GET_STATISTICS_QUERY,
          GET_EVENTS_QUERY,
          DOWNLOAD_STATISTICS_CSV_QUERY,
          DOWNLOAD_MEASUREMENTS_CSV_QUERY,
        ]);
    }
  },
);
