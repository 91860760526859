import * as React from 'react';

interface Props {
  width?: number;
  height?: number;
  children: React.ReactNode;
}

export const TransitionChart = ({
  width,
  height,
  children,
}: Props) => (
  <svg
    version="1.1"
    xmlSpace="preserve"
    width={width}
    height={height}
  >
    {React.Children.map(children, child => (
      child && React.cloneElement(child as React.ReactElement<any>, {
        width,
        height,
      })
    ))}
  </svg>
);
