import { queryReducer } from '+query/query.reducer';
import { createReducer } from '+utils/redux';
import { PRODUCT_MIGRATION_ACTIONS, ProductMigrationActions } from './productMigration.actions';
import {
  ACCEPT_OFFER_QUERY,
  CREATE_CESSION_QUERY,
  CREATE_PRODUCT_CONFIGURATION_QUERY,
  GET_ACTIVE_CAMPAIGNS_QUERY,
  GET_CESSION_QUERY,
  GET_CESSION_STATUS_QUERY,
  GET_DSO_LIST_QUERY,
  GET_PRODUCT_CONFIGURATION_QUERY,
  GET_PRODUCT_MIGRATION_STATUS_QUERY,
  initialState,
  ProductMigrationState,
  UPDATE_PRODUCT_MIGRATION_STATUS_QUERY,
} from './productMigration.state';

export const productMigrationReducer = createReducer<ProductMigrationState, ProductMigrationActions>(
  (state = initialState, action): ProductMigrationState => {
    switch (action.type) {
      case PRODUCT_MIGRATION_ACTIONS.SET_STATUS:
        return {
          ...state,
          status: action.status,
        };
      case PRODUCT_MIGRATION_ACTIONS.SET_CONFIGURATION:
        return {
          ...state,
          configuration: action.configuration,
        };
      case PRODUCT_MIGRATION_ACTIONS.SET_CONFIGURATION_ID:
        return {
          ...state,
          configurationId: action.configurationId,
        };
      case PRODUCT_MIGRATION_ACTIONS.SET_DSO_OPERATORS:
        return {
          ...state,
          dsoOperators: action.dsoOperators,
        };
      case PRODUCT_MIGRATION_ACTIONS.SET_CESSION:
        return {
          ...state,
          cessionUrl: action.cessionUrl,
        };
      case PRODUCT_MIGRATION_ACTIONS.SET_CESSION_STATUS:
        return {
          ...state,
          cessionStatus: action.cessionStatus,
        };
      case PRODUCT_MIGRATION_ACTIONS.SET_CREATE_CESSION_ERROR_STATUS:
        return {
          ...state,
          errors: {
            ...state.errors,
            createCessionError: action.status,
          },
        };
      case PRODUCT_MIGRATION_ACTIONS.SET_ACTIVE_CAMPAIGNS:
        return {
          ...state,
          campaigns: action.campaigns,
        };
      case PRODUCT_MIGRATION_ACTIONS.CLEAR_PRODUCT_MIGRATION:
        return {
          ...initialState,
        };
      case PRODUCT_MIGRATION_ACTIONS.SET_GLOBAL_STATUS_RESET:
          return {
            ...state,
            globalStatusReset: action.globalStatusReset,
          };
        case PRODUCT_MIGRATION_ACTIONS.CLEAR_GLOBAL_STATUS_RESET:
          return {
            ...state,
            globalStatusReset: initialState.globalStatusReset,
          };
      default:
        return queryReducer(state, action, [
          ACCEPT_OFFER_QUERY,
          CREATE_CESSION_QUERY,
          GET_CESSION_STATUS_QUERY,
          GET_CESSION_QUERY,
          GET_DSO_LIST_QUERY,
          GET_PRODUCT_CONFIGURATION_QUERY,
          GET_PRODUCT_MIGRATION_STATUS_QUERY,
          CREATE_PRODUCT_CONFIGURATION_QUERY,
          UPDATE_PRODUCT_MIGRATION_STATUS_QUERY,
          GET_ACTIVE_CAMPAIGNS_QUERY,
        ]);
    }
  },
  // TODO decide weather to persist the productMigration state or not
  // {
  //   persist: {
  //     key: 'productMigration',
  //   },
  // },
);
