import { createReducer } from '+utils/redux';
import { queryReducer } from '../query/query.reducer';
import { USER_ACTIONS, UserActions } from './user.actions';
import { 
  GET_USER_QUERY,
  initialState,
  REGISTER_BY_BATTERY_DATA_QUERY,
  REGISTER_BY_CUSTOMER_NO_QUERY,
  REGISTER_BY_EMAIL_QUERY,
  REQUEST_PASSWORD_RESET_QUERY,
  RESET_PASSWORD_QUERY,
  UserState,
} from './user.state';

export const reducer = createReducer<UserState, UserActions>(
  (state = initialState, action): UserState => {
    switch (action.type) {
      case USER_ACTIONS.STATE_CHANGE:
        return {
          ...state,
          onboarding: {
            state: action.status,
            email: action.email || state.onboarding.email,
          },
        };
      case USER_ACTIONS.IS_PASSWORD_SET:
        return {
          ...state,
          isPasswordSetupSuccessful: action.isPasswordSet,
        };
      case USER_ACTIONS.SET_USER:
        return {
          ...state,
          currentUser: action.user,
        };
      default:
        return queryReducer(state, action, [
          GET_USER_QUERY,
          REGISTER_BY_EMAIL_QUERY,
          REQUEST_PASSWORD_RESET_QUERY,
          RESET_PASSWORD_QUERY,
          REGISTER_BY_BATTERY_DATA_QUERY,
          REGISTER_BY_CUSTOMER_NO_QUERY,
        ]);
    }
  },
  {
    persist: {
      key: 'user',
      blacklist: [
        'personalDetails',
        'isPasswordSetupSuccessful',
        'onboarding',
        GET_USER_QUERY,
        REGISTER_BY_EMAIL_QUERY,
        REQUEST_PASSWORD_RESET_QUERY,
        RESET_PASSWORD_QUERY,
        REGISTER_BY_BATTERY_DATA_QUERY,
        REGISTER_BY_CUSTOMER_NO_QUERY,
      ],
    },
  },
);
