import * as React from 'react';

import { DashboardLayout } from '+app/+dashboard/containers';
import { NotificationProvider } from '+app/shared/containers';
import { RouterBuilder } from '+router/factory';
import { getRoutes, RouteAuthType } from '+router/router.helper';
import { getPath } from '+router/router.helper';
import { RouteName } from '+router/router.types';
import { useRouterState } from '+router/RouterProvider.component';

interface Props {
  userLoggedIn: boolean;
}

export const AuthorizedRouting: React.FC<Props> = ({ userLoggedIn }) => {
  const { routes } = useRouterState();
  const authorizedRoutes = getRoutes(RouteAuthType.AUTHORIZED, routes);

  return (
    <NotificationProvider>
      <DashboardLayout>
        <RouterBuilder
          routes={authorizedRoutes}
          redirectTo={userLoggedIn
            ? getPath(RouteName.DASHBOARD)
            : getPath(RouteName.LOGIN)
          }
        />
      </DashboardLayout>
    </NotificationProvider>
  );
};
