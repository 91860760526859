import * as classNames from 'classnames';
import * as React from 'react';

import './NestedNavSlideContent.component.scss';

interface NestedNavSlideContentProps {
  hasGap?: boolean;
}

export const NestedNavSlideContent: React.FC<NestedNavSlideContentProps> = ({ hasGap, children }) => (
  <div className={classNames('c-nested-nav-slide-content', {
    'c-nested-nav-slide-content--gap': hasGap,
  })}>
    {children}
  </div>
);
