import { MediaCard } from '@sonnen/shared-web';
import { WizardContext } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';

import { FlatDocumentType, OfferProductType, OfferTaxRegulation } from '+app/+guide/store/types';
import { StoreState } from '+app/store/store.interface';
import { getAcceptDocumentsQueryStatus, getAcceptOfferStatus } from '+guide/+acceptance/store/+acceptance.selectors';
import {
  getOfferById,
  getOfferConfiguration,
  getPrimaryFlatOfferDocumentKey,
  getPrimaryFlatOfferDocumentStatus,
} from '+guide/+purchase/store/+offer.selectors';
import { GuideActions } from '+guide/store/+guide.actions';
import { getDocumentUrl } from '+guide/store/+guide.selectors';
import { getGuideLead } from '+guide/store/+lead.selectors';
import { RouterGuideAcceptanceRouteParams } from '+router/router.types';
import {
  GuideAcceptanceBackButton,
  GuideAcceptanceFlatOfferDownload,
  GuideAcceptanceFlatOverview,
} from '../../components';
import { DocumentAcceptance } from '../../store';
import { GuideAcceptanceActions } from '../../store/+acceptance.actions';
import { isFlatDirectConfiguration, isSpecificFlatProductInOffer } from '../../store/+acceptance.helper';
import { GuideAcceptanceDocuments } from '../GuideAcceptanceDocuments';
import { DocumentAcceptanceForm } from '../GuideAcceptanceDocuments/GuideAcceptanceDocuments.helper';

const mapStateToProps = (
  state: StoreState,
  { match: { params } }: RouteComponentProps<RouterGuideAcceptanceRouteParams>,
) => ({
  lead: getGuideLead(state),
  offer: getOfferById(state)(params),
  documentId: getPrimaryFlatOfferDocumentKey(state)(params),
  documentUrl: getDocumentUrl(state),
  documentStatus: getPrimaryFlatOfferDocumentStatus(state)(params),
  configuration: compose(getOfferConfiguration, getOfferById(state))(params),
  acceptOfferStatus: getAcceptOfferStatus(state),
  acceptDocumentsQueryStatus: getAcceptDocumentsQueryStatus(state),
});

const mapDispatchToProps = {
  acceptDocuments: GuideAcceptanceActions.acceptDocuments,
  openFlatOfferDocument: GuideAcceptanceActions.openFlatOfferDocument,
  stopFlatOfferPolling: GuideActions.stopDocumentPolling,
  regenerateFlatOfferDocument: GuideActions.regenerateDocument,
};

type Props =
  & ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps;

export const GuideAcceptanceFlatOfferComponent: React.FC<Props> = ({
  documentId,
  documentUrl,
  documentStatus,
  configuration,
  lead,
  offer,
  acceptDocuments,
  openFlatOfferDocument,
  acceptOfferStatus,
  acceptDocumentsQueryStatus,
  stopFlatOfferPolling,
  regenerateFlatOfferDocument,
}) => {
  const wizard = React.useContext(WizardContext);
  const handleSubmit = (values: DocumentAcceptanceForm) => acceptDocuments(values);

  React.useEffect(() => {
    if (wizard.next && acceptOfferStatus.success) {
      wizard.next();
    }
  }, [acceptOfferStatus]);

  const handleRegenerateDocument = () => {
    regenerateFlatOfferDocument(lead!.id, offer!.id, 'offer', FlatDocumentType.FLAT_OFFER);
  };
  const isPostEeg = isFlatDirectConfiguration(configuration);

  const offerTaxRegulation = offer?.taxRegulation ?? OfferTaxRegulation.STANDARD;

  return offer ? (
    <div>
      <MediaCard
        footerContent={configuration && (
          <GuideAcceptanceDocuments
            handleSubmit={handleSubmit}
            isPostEeg={isPostEeg}
            providedLinks={{
              [DocumentAcceptance.TERMS_AND_CONDITIONS_AND_CANCELLATION]: {
                termsAndConditions: configuration.termsAndConditions,
              },
            }}
            isPending={acceptDocumentsQueryStatus.pending || acceptOfferStatus.pending}
            isError={acceptDocumentsQueryStatus.error || acceptOfferStatus.error}
            acceptedMarketingMailing={!lead?.optedOutOfMarketingMailing}
            isFlatXOffer={isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X)}
          />
        )}
      >
        <GuideAcceptanceFlatOverview
          configuration={configuration}
          isFlatXOffer={isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X)}
          offerTaxRegulation={offerTaxRegulation}
        />
        <GuideAcceptanceFlatOfferDownload
          documentId={documentId}
          documentUrl={documentUrl}
          documentStatus={documentStatus!}
          openFlatOfferDocument={openFlatOfferDocument}
          stopFlatOfferPolling={stopFlatOfferPolling}
          regenerateFlatOfferDocument={handleRegenerateDocument}
          isFlatXOffer={isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X)}
        />
      </MediaCard>
      <GuideAcceptanceBackButton />
    </div>
  ) : null;
};

export const GuideAcceptanceFlatOffer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuideAcceptanceFlatOfferComponent);
