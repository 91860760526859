import { GuideActions } from '+app/+guide/store/+guide.actions';
import { getGuideSyncQueryStatus } from '+app/+guide/store/+lead.selectors';
import { getPath, getPaths } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { AppLoader } from '+app/shared/components';
import { Hint } from '+app/shared/store/sync/types/hint.interface';
import { StoreState } from '+app/store/store.interface';
import { GuideDiscoveryActive } from '+guide/+discovery/containers';
import { GuidePhase, GuidePhaseStatus, GuidePreviewNav } from '+guide/components';
import { mapActions } from '+utils/redux';
import { GuideDiscoveryActions } from '../../store';
import { getActiveDiscoveryModal } from '../../store/+discovery.selectors';

import { T } from '@sonnen/shared-i18n/customer';
import { PageHeadline } from '@sonnen/shared-web';
import { push, replace } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './GuideDiscovery.component.scss';

const mapStateToProps = (state: StoreState) => ({
  activeModal: getActiveDiscoveryModal(state),
  guideSyncQueryStatus: getGuideSyncQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  goTo: replace,
  goToPurchase: () => push(getPaths().GUIDE_PURCHASE),
  closeModal: GuideDiscoveryActions.closeModal,
  setActiveTab: GuideActions.setPhaseActiveTab,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

export const GuideDiscoveryComponent: React.FC<Props> = ({
  actions,
  guideSyncQueryStatus,
}) => {
  const guideLoaderHint: Hint = {
    icon: 'icofont-contract',
    headline: I18n.t(T.customerGuidance.loader.headline),
    description: I18n.t(T.customerGuidance.loader.description),
  };

  return (
    <>
      <PageHeadline smallGap={true}>
        {I18n.t(T.customerGuidance.planning.headline)}
      </PageHeadline>
      {guideSyncQueryStatus.success ? (
        <>
          <GuidePhase
            pathFactory={(step?: string) => step
              ? getPath(RouteName.GUIDE_DISCOVERY_STEP, [step])
              : getPath(RouteName.GUIDE_DISCOVERY)
            }
            status={GuidePhaseStatus.ACTIVE}
          >
            <GuideDiscoveryActive />
          </GuidePhase>
          <GuidePreviewNav
            nextPhase={actions.goToPurchase}
          />
        </>
      ) : (
        <AppLoader hint={guideLoaderHint}/>
      )}
    </>
  );
};

export const GuideDiscovery = connect(mapStateToProps, mapDispatchToProps)(GuideDiscoveryComponent);
