import { DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';

import './ArticleSlide.component.scss';

type SlideContent = {
  date?: string;
  description?: string;
  title: string;
};

type SlideBasic = SlideContent & {
  imageSrc?: undefined;
  imageAlt?: undefined;
  linkLabel?: undefined;
  linkSrc?: undefined;
};

type SlideWithImage = SlideContent & {
  imageSrc: string;
  imageAlt: string;
  linkLabel?: undefined;
  linkSrc?: undefined;
};

type SlideWithLink = SlideContent & {
  imageSrc?: undefined;
  imageAlt?: undefined;
  linkLabel: string;
  linkSrc: string;
};

type SlideFull = SlideContent & {
  imageSrc: string;
  imageAlt: string;
  linkLabel: string;
  linkSrc: string;
};

type ArticleSlideProps =
  | SlideBasic
  | SlideWithLink
  | SlideWithImage
  | SlideFull
  ;

export const ArticleSlide: React.FC<ArticleSlideProps> = ({
  date,
  description,
  imageAlt,
  imageSrc,
  linkLabel,
  linkSrc,
  title,
}) => (
  <div className={'c-article-slide'}>
    <div className={'c-article-slide__inner'}>
      {(imageAlt && imageSrc) && (
        <div className={'c-article-slide__image-container'}>
          <img
            src={imageSrc}
            alt={imageAlt}
            className={'c-article-slide__image'}
          />
        </div>
      )}
      <div className={'c-article-slide__content'}>
        <div className={'c-article-slide__content-fixed-box'}>
          {date && (
            <p className={'c-article-slide__meta'}>
              {date}
            </p>
          )}
          <p className={'c-article-slide__title'}>
            {title}
          </p>
          {description && (
            <DefaultParagraph>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </DefaultParagraph>
          )}
        </div>
        {(linkLabel && linkSrc) && (
          <a
            className={'c-article-slide__link'}
            href={linkSrc}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            {linkLabel}
          </a>
        )}
      </div>
    </div>
  </div>
);
