export enum MobileAppState {
  ACTIVE = 'active',
  BACKGROUND = 'background',
  INACTIVE = 'inactive',
}

export interface MobileState {
  appState: MobileAppState;
  versionName: string;
  flagConfig: string;
  instanceId: string;
}

export const initialState: MobileState = {
  appState: MobileAppState.ACTIVE,
  versionName: '',
  flagConfig: '',
  instanceId: '',
};
