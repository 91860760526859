import { breakpointDown, CountryCode, TransformIconType, useFeature } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';

import { isAuthenticated } from '+app/+auth/store/auth.selectors';
import { GuideNotifications } from '+app/+guide/+notifications/containers/GuideNotifications';
import { isVerifyingIdentity } from '+app/+guide/store/+guide.selectors';
import { getGuideLead, getGuideLeadId } from '+app/+guide/store/+lead.selectors';
import { isOnRoute } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { useRouterState } from '+app/router/RouterProvider.component';
import { getRouterLocationPath } from '+app/router/store/router.selectors';
import { UserSites } from '+app/shared/components';
import { LayoutActions } from '+app/shared/store/layout/layout.actions';
import { isMobileNavOpen } from '+app/shared/store/layout/layout.selectors';
import { getActiveSite, getAllSitesOrdered } from '+app/shared/store/site/site.selectors';
import { getCurrentUserState, isUserCountry } from '+app/shared/store/user/user.selectors';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { Header, HeaderSpacingContainer, NavToggle } from '+components';
import { FeatureName } from '+config/featureFlags';
import { isFlatOfferAccepted } from '+guide/+purchase/store/+purchase.selectors';
import { GuideActions } from '+guide/store/+guide.actions';
import { DashboardUserDetails } from '../../components/DashboardUserDetails';
import { factorizeUserDetails, getUserDetailsRoutes } from './DashboardHeader.helper';

const mapStateToProps = (state: StoreState) => ({
  activeSite: getActiveSite(state),
  allSites: getAllSitesOrdered(state),
  currentUser: getCurrentUserState(state),
  hasLeadId: Boolean(getGuideLeadId(state)),
  isGermanUser: Boolean(isUserCountry(CountryCode.DE)(state)),
  isLoggedIn: isAuthenticated(state),
  isMobileNavOpen: isMobileNavOpen(state),
  isOfferAccepted: Boolean(isFlatOfferAccepted(state)),
  isVerifyingIdentityInGuide: isVerifyingIdentity(state),
  lead: getGuideLead(state),
  path: getRouterLocationPath(state),
});

const mapDispatchToProps = mapActions({
  getGuideData: GuideActions.getGuideData,
  toggleMobileNav: LayoutActions.toggleMobileNav,
  toggleSiteSwitcher: LayoutActions.toggleSiteSwitcher,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

const DashboardHeaderComponent: React.FC<Props> = ({
  actions,
  activeSite,
  allSites,
  currentUser,
  hasLeadId,
  isGermanUser,
  isLoggedIn,
  isMobileNavOpen,
  isOfferAccepted,
  isVerifyingIdentityInGuide,
  lead,
  path,
}) => {
  const { availableFlatRoutes } = useRouterState();
  const isUserInGuide: boolean = !isLoggedIn && isOnRoute(RouteName.GUIDE, path);
  const shouldRenderHeaderSide: boolean = !isVerifyingIdentityInGuide;
  const shouldRenderNavigation: boolean =
    !(isUserInGuide && isVerifyingIdentityInGuide) && !isOnRoute(RouteName.GUIDE_ACCEPTANCE, path);
  const showNotificationsInCpPredicate = hasLeadId
    && isOfferAccepted
    && isGermanUser
    && useFeature(FeatureName.GUIDE_PAGES_IN_CP).isEnabled;
  const shouldNotRenderNotifications: boolean = isLoggedIn && !showNotificationsInCpPredicate;
  const shouldRenderUserSites: boolean = Boolean(activeSite && allSites && !isUserInGuide);
  const toggleNav = () => actions.toggleMobileNav(!isMobileNavOpen);

  React.useEffect(() => {
    if (hasLeadId) {
      actions.getGuideData();
    }
  }, [hasLeadId]);

  return (
    <Header redirectTo={!isUserInGuide
      ? RouteName.DASHBOARD
      : RouteName.GUIDE
    }>
      {shouldRenderHeaderSide && (
        <Media query={{ minWidth: breakpointDown('NAV') }}>
          {(isDesktop: boolean) => isDesktop ? (
            <HeaderSpacingContainer>
              {shouldRenderUserSites && (
                <UserSites
                  activeSite={activeSite}
                  allSites={allSites}
                />
              )}
              <DashboardUserDetails
                details={factorizeUserDetails(isUserInGuide ? lead : currentUser)}
                routes={getUserDetailsRoutes(availableFlatRoutes, isUserInGuide)}
              />
              {!shouldNotRenderNotifications && (
                <GuideNotifications />
              )}
            </HeaderSpacingContainer>
          ) : (
            <HeaderSpacingContainer>
              {!shouldNotRenderNotifications && (
                <GuideNotifications />
              )}
              {shouldRenderNavigation && (
                <NavToggle
                  type={isMobileNavOpen
                    ? TransformIconType.X
                    : TransformIconType.MENU
                  }
                  onClick={toggleNav}
                />
              )}
            </HeaderSpacingContainer>
          )}
        </Media>
      )}
    </Header>
  );
};

export const DashboardHeader = connect(mapStateToProps, mapDispatchToProps)(DashboardHeaderComponent);
