import { LanguageSelect } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { LANGUAGES, setLanguageCode } from '+legacy/helpers/i18n.helper';
import { FooterSpec } from '+shared/spec/footerSpec.types';
import { FooterNavElement } from '../../containers/Footer/Footer.helper';

import './FooterNav.component.scss';

interface Props {
  lang: string;
  list: FooterNavElement[];
}

export const FooterNav: React.FC<Props> = ({
  lang,
  list,
}) => {
  const currentLanguage = LANGUAGES.find(l => l.locale === lang) || LANGUAGES[0];

  const changeLanguage = async (languageCode: string) => {
    await setLanguageCode(languageCode);
    window.location.reload();
  };

  return (
    <ul className={'c-footer-nav'}>
      <li
        className={'c-footer-nav__list-item'}
        data-test-id={FooterSpec.SELECT_LANGUAGE}
      >
        <LanguageSelect
          langs={LANGUAGES}
          selected={currentLanguage}
          onChange={changeLanguage}
        />
      </li>
      {list.map(item => (
        <li
          className={'c-footer-nav__list-item'}
          key={`${item.label}-${item.path}`}
        >
          <a
            className={'c-footer-nav__item'}
            href={I18n.t(item.path)}
            target={item.isExternal ? '_blank' : undefined}
            rel={item.isExternal ? 'noopener noreferrer' : undefined}
          >
            {I18n.t(item.label)}
          </a>
        </li>
      ))}
    </ul>
  );
};
