import { T } from '@sonnen/shared-i18n/customer';
import { Dictionary, fromPairs, isNil } from 'lodash';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { SagaIterator } from 'redux-saga';
import { all, call, put, retry, select, takeLatest, takeLeading } from 'redux-saga/effects';

import { isPMValidationError } from '+app/shared/network/network.util';
import { getMeterGridId, getVppCategory } from '+app/shared/store/meter/meter.selectors';
import { FirebaseClient } from '+app/shared/store/news-channel/firebase.client';
import { getCurrentLanguage, getCurrentUserSalesforceContactId, getCurrentUserState } from '+app/shared/store/user/user.selectors';
import { Reporter } from '+app/utils/reporter.util';
import { combineSagas, dataGuard, processQuery } from '+app/utils/saga';
import { getBatteryData } from '+dashboard/+battery/store/battery.selectors';
import { getCurrentSiteContractData, getLastYearSonnenFlatStatistics } from '+dashboard/+contract/store/contract.selectors';
import { QueryActions } from '+query/query.actions';
import { getActiveSite } from '+shared/store/site/site.selectors';
import { PRODUCT_MIGRATION_ACTIONS, ProductMigrationActions } from './productMigration.actions';
import {
  factorizeCreateConfigurationData,
  getContactSignedBy,
  getContractBillingAddress,
  getContractDataValidationErrors,
  getContractSupplyAddress,
  isContractDataValid,
  mapFormErrorToKeyValuePairs,
  mapPMValidationErrorToKeyValuePairs,
} from './productMigration.helper';
import { ProductMigrationRepository } from './productMigration.repository';
import {
  getActiveCampaignsData,
  getConfigurationSystemKey,
  getProductConfigurationId,
} from './productMigration.selectors';
import {
  ACCEPT_OFFER_QUERY,
  CREATE_CESSION_QUERY,
  CREATE_PRODUCT_CONFIGURATION_QUERY, GET_ACTIVE_CAMPAIGNS_QUERY,
  GET_CESSION_QUERY,
  GET_CESSION_STATUS_QUERY,
  GET_DSO_LIST_QUERY,
  GET_PRODUCT_CONFIGURATION_QUERY,
  GET_PRODUCT_MIGRATION_STATUS_QUERY,
} from './productMigration.state';
import { ProductMigrationGlobalStatusReset } from './types/productConfiguration.interface';
import { CessionSignServiceStatusType } from './types/productMigrationCession.interface';

function* getStatus(): SagaIterator {
  yield processQuery(
    GET_PRODUCT_MIGRATION_STATUS_QUERY,
    ProductMigrationRepository.getStatus,
    {
      onSuccess: res => dataGuard(ProductMigrationActions.setStatus)(res?.status),
    },
  )({});
}

function* getConfiguration(): SagaIterator {
  const id = yield select(getProductConfigurationId);

  yield processQuery(
    GET_PRODUCT_CONFIGURATION_QUERY,
    ProductMigrationRepository.getConfiguration,
    {
      onSuccess: res => dataGuard(ProductMigrationActions.setConfiguration)(res),
    },
  )({ id });
}

function* getDsoOperators(): SagaIterator {
  const site = yield select(getActiveSite);

  yield processQuery(
    GET_DSO_LIST_QUERY,
    ProductMigrationRepository.getDsoOperators,
    {
      onSuccess: ({ data }) => dataGuard(ProductMigrationActions.setDsoOperators)(data),
    },
  )({
    street: site.street,
    postalCode: site.postalCode,
    city: site.city,
    countryCode: site.countryCode,
  });
}

type CreateConfigurationAction = ReturnType<typeof ProductMigrationActions.createConfiguration>;

function* createConfiguration({ formValues, actions }: CreateConfigurationAction): SagaIterator {
  const battery = getBatteryData(yield select());
  const site = getActiveSite(yield select());
  const yearlyContractStatistics = getLastYearSonnenFlatStatistics(yield select());
  const vppCategory = getVppCategory(yield select());
  const campaigns = getActiveCampaignsData(yield select());
  const { locale } = getCurrentLanguage(yield select());

  yield processQuery(
    CREATE_PRODUCT_CONFIGURATION_QUERY,
    ProductMigrationRepository.createConfiguration,
    {
      onSuccess: ({ data: { id, meta: { status } } }) => {
        actions.setSubmitting(false);

        return all([
          dataGuard(ProductMigrationActions.setConfigurationId)(id),
          dataGuard(ProductMigrationActions.setStatus)(status),
        ]);
      },
      onFailure: (error?: { [key: string]: any }) => {
        actions.setSubmitting(false);
        let errors: Dictionary<string> = {};

        if (isPMValidationError(error)) {
          errors = {
            ...errors,
            ...fromPairs(mapPMValidationErrorToKeyValuePairs(error.errors)),
          };
        }

        if (error && Array.isArray(error.message) && error.message.length) {
          errors = {
            ...errors,
            ...fromPairs(error.message.map(mapFormErrorToKeyValuePairs)),
          };
        }

        if (error?.statusCode === 409) {
          errors = {
            ...errors,
            conflict: I18n.t(T.productMigration.errors.offerAlreadyAccepted),
          };
        }

        actions.setErrors(errors);
        return put(QueryActions.failure(CREATE_PRODUCT_CONFIGURATION_QUERY, error as Error));
      },
    },
  )({
    data: factorizeCreateConfigurationData(
      formValues,
      battery,
      site,
      yearlyContractStatistics,
      vppCategory,
      campaigns,
    ),
    locale,
  });
}

function* createCession(): SagaIterator {
  const contract = getCurrentSiteContractData(yield select());
  const contractNumber = contract?.contractNumber;
  const contactId = getCurrentUserSalesforceContactId(yield select());
  const meterId = getMeterGridId(yield select());
  const systemKey = getConfigurationSystemKey(yield select());

  const customerNumber = getCurrentUserState(yield select())?.customerNumber;
  const { serialNumber: batterySerialNumber } = getBatteryData(yield select());

  const supplyAddress = contract && getContractSupplyAddress(contract);
  const billingAddress = contract && getContractBillingAddress(contract);
  const signedBy = contactId ? getContactSignedBy(contactId) : undefined;

  const cessionContractData = {
    signedBy,
    existingContractNumber: contractNumber,
    batteryAsset: batterySerialNumber,
    tnc: {
      privacyTncAccepted: true,
      cancellationTncAccepted: true,
      productTncAccepted: true,
    },
    supply: {
      address: supplyAddress,
      data: {
        meterId,
      },
    },
    billing: {
      address: billingAddress,
    },
  };

  if (!isContractDataValid(cessionContractData) || !customerNumber || !contractNumber) {
    const validationErrors = getContractDataValidationErrors(cessionContractData);

    yield put(ProductMigrationActions.setCreateCessionErrorStatus(true));

    Reporter.reportError(
      new Error('Contract Data invalid when creating cession'),
      { validationErrors, contractNumber, customerNumber },
    );
    return;
  }

  yield put(ProductMigrationActions.setCreateCessionErrorStatus(false));

  yield processQuery(
    CREATE_CESSION_QUERY,
    ProductMigrationRepository.createCession,
    {
      onSuccess: () => all([
        put(ProductMigrationActions.getCession()),
        put(ProductMigrationActions.getCessionStatus()),
      ]),
      onFailure: (error) => all([
        put(QueryActions.failure(CREATE_CESSION_QUERY, error as Error)),
        put(ProductMigrationActions.setCreateCessionErrorStatus(true)),
        error && Reporter.reportError(error, {
          customerNumber,
          contractNumber,
          cessionContractData,
        }),
      ]),
    },
    )({
      contractNumber,
      customerNumber,
      systemKey,
      contractData: cessionContractData,
    });
}

function* getCession(): SagaIterator {
  const { customerNumber } = yield select(getCurrentUserState);

  yield put(QueryActions.pending(GET_CESSION_QUERY));
  try {
    const { data } = yield retry(10, 5000, ProductMigrationRepository.getCession, { customerNumber });
    yield put(ProductMigrationActions.setCession(data.attributes.url));
    yield put(QueryActions.success(GET_CESSION_QUERY));
  } catch (error) {
    yield put(QueryActions.failure(GET_CESSION_QUERY, error));
  }
}

function* getCessionStatus(): SagaIterator {
  const { customerNumber } = yield select(getCurrentUserState);

  yield processQuery(
    GET_CESSION_STATUS_QUERY,
    ProductMigrationRepository.getCessionStatus,
    {
      onSuccess: ({ data }) => dataGuard(ProductMigrationActions.setCessionStatus)(data.attributes.status),
      onFailure: error => (error as any)?.statusCode === 404
        ? dataGuard(ProductMigrationActions.setCessionStatus)(CessionSignServiceStatusType.NEW)
        : put(QueryActions.failure(GET_CESSION_STATUS_QUERY, error as Error)),
    },
  )({ customerNumber });
}

type AcceptOfferAction = ReturnType<typeof ProductMigrationActions.acceptOffer>;

function* acceptOffer({ offerTnC }: AcceptOfferAction): SagaIterator {
  const { contractNumber } = yield select(getCurrentSiteContractData);
  const contactId = yield select(getCurrentUserSalesforceContactId);

  yield processQuery(
    ACCEPT_OFFER_QUERY,
    ProductMigrationRepository.acceptOffer,
    {
      onSuccess: ({ data: { meta: { status } } }) => dataGuard(ProductMigrationActions.setStatus)(status),
    },
  )({ contractNumber, contactId, tnC: offerTnC });
}

function* getActiveCampaigns(): SagaIterator {
  yield processQuery(
    GET_ACTIVE_CAMPAIGNS_QUERY,
    ProductMigrationRepository.getActiveCampaigns,
    {
      onSuccess: (res) => dataGuard(ProductMigrationActions.setActiveCampaigns)(res),
      // TODO implement onFailure
    },
  )({
    date: moment().format('YYYY-MM-DD'),
  });
}

function* getGlobalStatusReset () {
  const firestore = FirebaseClient.firebase.firestore;
  const docRef = firestore.doc('productMigration/globalStatusReset');

  const getGlobalStatusResetDate = async () => {
    const docSnap = await docRef.get();
    const docData = docSnap.exists && docSnap.data();

    if (!docData) {
      throw new Error('firestore - Missing globalStatusReset document');
    }
    if (isNil(docData.isActive) || isNil(docData.date?.seconds)) {
      throw new Error('firestore - globalStatusReset document - Missing date or isActive field');
    }

    return {
      timestamp: docData.date.seconds * 1000, // to miliseconds
      isActive: docData.isActive,
    };
  };

  try {
    const globalStatusReset: ProductMigrationGlobalStatusReset = yield call(getGlobalStatusResetDate);
    yield put(ProductMigrationActions.setGlobalStatusReset(globalStatusReset));
  } catch (err) {
    Reporter.reportError(err);
    yield put(ProductMigrationActions.clearGlobalStatusReset());
  }
}

export const productMigrationSagas = combineSagas(
  takeLatest(PRODUCT_MIGRATION_ACTIONS.CREATE_CONFIGURATION, createConfiguration),
  takeLatest(PRODUCT_MIGRATION_ACTIONS.GET_CONFIGURATION, getConfiguration),
  takeLatest(PRODUCT_MIGRATION_ACTIONS.GET_STATUS, getStatus),
  takeLatest(PRODUCT_MIGRATION_ACTIONS.GET_DSO_OPERATORS, getDsoOperators),
  takeLeading(PRODUCT_MIGRATION_ACTIONS.CREATE_CESSION, createCession),
  takeLatest(PRODUCT_MIGRATION_ACTIONS.GET_CESSION, getCession),
  takeLatest(PRODUCT_MIGRATION_ACTIONS.GET_CESSION_STATUS, getCessionStatus),
  takeLatest(PRODUCT_MIGRATION_ACTIONS.ACCEPT_OFFER, acceptOffer),
  takeLatest(PRODUCT_MIGRATION_ACTIONS.GET_ACTIVE_CAMPAIGNS, getActiveCampaigns),
  takeLatest(PRODUCT_MIGRATION_ACTIONS.GET_GLOBAL_STATUS_RESET, getGlobalStatusReset),
);
