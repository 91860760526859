import {
  Icon,
  LinkButton,
} from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import { EventActions, EventCategories, GA } from '+shared/helpers/analytics';
import './InfoBanner.component.scss';

interface Props {
  additionalMessage?: string;
  btnText?: string;
  isVisible?: boolean;
  isWarning?: boolean;
  message: string;
  longMessage: string;
  seeMoreText?: string;
  seeLessText?: string;
  waitForCollapse?: boolean;
  action?: (...args: any[]) => void;
  trackingLabel?: string;
}

export const InfoBanner: React.FC<Props> = ({
  additionalMessage,
  btnText,
  isVisible = true,
  isWarning,
  message,
  longMessage,
  seeMoreText,
  seeLessText,
  waitForCollapse,
  action,
  trackingLabel,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [displayBanner, setDisplayBanner] = React.useState(isVisible);

  React.useEffect(() => {
    if (!waitForCollapse || !isExpanded || isVisible) {
      setDisplayBanner(isVisible);
    }

    if (trackingLabel && isVisible) {
      GA.event({
        category: EventCategories.BANNER,
        action: EventActions.BANNER_DISPLAYED,
        label: trackingLabel,
      });
    }
  }, [isVisible]);

  const toggleExpansion = () => {
    if (trackingLabel && !isExpanded) {
      GA.event({
        category: EventCategories.BANNER,
        action: EventActions.BANNER_EXPANDED,
        label: trackingLabel,
      });
    }
    if (!isVisible && isExpanded) {
      setDisplayBanner(false);
    }
    setIsExpanded(!isExpanded);
  };

  return displayBanner ? (
    <div role={'status'} className={classNames('c-info-banner', {
      'c-info-banner--warning': isWarning,
    })}>
      {!isWarning && <div className={'c-info-banner__border'} />}
      <div className={'c-info-banner__content'}>
        <div className={classNames('c-info-banner__icon-wrapper', {
          'c-info-banner__icon-wrapper--warning': isWarning,
        })}>
          {isWarning
            ? <Icon.Warning className={'c-info-banner__icon c-info-banner__icon--warning'} />
            : <Icon.Info className={'c-info-banner__icon'} />}
        </div>
        {longMessage ? (
          <>
            <div className={'c-info-banner__container'}>
              <div className={'c-info-banner__messages-container'}>
                <strong className={'c-info-banner__message'}>{message}</strong>
                <span className={'c-info-banner__additional-message'}>{additionalMessage}</span>
              </div>
              {isExpanded && (
                <div className={'c-info-banner__description'} dangerouslySetInnerHTML={{ __html: longMessage}} />
              )}
            </div>
            <LinkButton
              className={'c-info-banner__link-button'}
              onClick={toggleExpansion}
            >
              <span className={'c-info-banner__button-label'}>{!isExpanded ? seeMoreText : seeLessText}</span>
              <Icon.Angle className={classNames('c-info-banner__arrow-icon', {
                'c-info-banner__arrow-icon--rotated': isExpanded,
              })} />
            </LinkButton>
          </>
        ) : (
          <strong className={'c-info-banner__message'}>{message}</strong>
        )}
        {action && btnText && (
          <LinkButton
            className={'c-info-banner__link-button'}
            onClick={action}
          >
            {btnText}
          </LinkButton>
        )}
      </div>
    </div>
  ) : null;
};
