import { T } from '@sonnen/shared-i18n/customer';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';

import { formatNumber } from '+app/utils/number.util';
import {
  calculateSonnenFlatSavings,
} from '+contract/store/helpers/contract.helpers';
import { AnnualConsumptionChartProps as Props, Consumption } from './AnnualConsumptionChart.types';
import { STYLES } from './AnnualConsumptionChartConstants';

function generateFlagDataPoint(this: any) {// TODO general refactor
  const { x, point: { value } } = this;
  const { dataMin, dataMax } = this.series.xAxis;
  const datapointFractionOfTotal = (x - dataMin) / (dataMax - dataMin);
  const tooltipPositionSectionOnChart = Math.ceil(datapointFractionOfTotal * 12);
  let additionalTooltipClass = '';

  switch (tooltipPositionSectionOnChart) {
    case 0:
    case 1:
      additionalTooltipClass = 'c-annual-consumption-chart__tooltip--extreme-left';
      break;

    case 2:
    case 3:
      additionalTooltipClass = 'c-annual-consumption-chart__tooltip--left';
      break;

    case 10:
    case 11:
      additionalTooltipClass = 'c-annual-consumption-chart__tooltip--right';
      break;

    case 12:
    case 13:
      additionalTooltipClass = 'c-annual-consumption-chart__tooltip--extreme-right';
      break;
    default:
      break;
  }

  return `
    <div class="c-annual-consumption-chart__tooltip ${additionalTooltipClass}">
      <span class="c-annual-consumption-chart__tooltip-date">
        ${moment(x).subtract({ days: 1 }).format(I18n.t(T.general.date.default))}
      </span>
      <div class="c-annual-consumption-chart__tooltip-text">
        ${value}
      </div>
    </div>
  `;
}

function generatePlotLineLabel(freeUsageAllowance: number) {
  return `
    <div class="c-annual-consumption-chart__plotline-label">
      <p class="c-annual-consumption-chart__plotline-paragraph c-annual-consumption-chart__plotline-paragraph--thick">
        kWh
      </p>
      <p class="c-annual-consumption-chart__plotline-paragraph">${freeUsageAllowance}</p>
    </div>
  `;
}

const generateDailyConsumptionPoints = (consumptionByDay: Consumption) => consumptionByDay.map(
  point => [moment(point.timestamp).subtract({ days: 1 }).valueOf(), point.value],
);

const generateFlagDataPointDescription = (consumption: any, freeUsageAllowance: number) => {
  const [, currentConsumption] = [...consumption].pop();
  const savingDays = consumption.length - 1;

  const moneySaved = calculateSonnenFlatSavings(currentConsumption, freeUsageAllowance);

  const moneyValue = formatNumber({ precision: 2 })(moneySaved);
  return I18n.t(T.yourFlat.expertView.chart.tooltipLabel, {
    moneyValue: I18n.t(T.numbers.currency, { value: moneyValue }),
    daysAmount: savingDays,
  });
};

const getChartMaximum = (
  {
    consumptionByDay,
    expectedConsumption,
    freeUsageAllowance,
    isExceedingExpected,
    isQuotaExceeded,
  }: Props,
  multiplier = 1.25,
) => {
  if (isQuotaExceeded) {
    return consumptionByDay.slice(-1)[0].value * multiplier;
  }
  if (isExceedingExpected) {
    return expectedConsumption[1].value * multiplier;
  }

  return freeUsageAllowance * multiplier;
};

// TODO general refactor
export { STYLES };
export const generateChartSettings = (props: Props) => {
  const {
    billingPeriod,
    consumptionByDay,
    expectedConsumption,
    freeUsageAllowance,
    isExceedingExpected,
    isQuotaExceeded,
  } = props;
  const start = moment.utc(billingPeriod.start).valueOf();
  const end = moment.utc(billingPeriod.end).valueOf();
  const consumptionPoints = generateDailyConsumptionPoints(consumptionByDay);
  const plotLineLabel = generatePlotLineLabel(freeUsageAllowance);
  const flagDataPointDescription = generateFlagDataPointDescription(consumptionPoints, freeUsageAllowance);

  return {
    chart: {
      height: 300,
      zoomType: false,
      margin: [0, 5, 20, 1],
    },
    credits: false,
    legend: false,
    title: false,
    xAxis: {
      type: 'datetime',
      tickWidth: 0,
      lineWidth: 1,
      minPadding: 0,
      maxPadding: 0,
      lineColor: STYLES.colors.grid,
      tickPositions: [start, end],
      offset: false,
      endOnTick: true,
      labels: {
        format: `{value:${I18n.t(T.general.date.dayWithYearHighcharts)}}`,
      },
    },
    yAxis: {
      labels: false,
      title: false,
      tickAmount: 0,
      min: 0,
      max: getChartMaximum(props),
      gridLineWidth: 0,
      lineWidth: 1,
      lineColor: STYLES.colors.grid,
      plotLines: [
        {
          color: STYLES.colors.grid,
          dashStyle: 'dash',
          value: freeUsageAllowance,
          width: 2,
          label: {
            useHTML: true,
            text: plotLineLabel,
            y: -15,
            x: 3,
          },
        },
      ],
    },
    series: [
      // expected consumption line
      {
        type: 'line',
        color: isQuotaExceeded || isExceedingExpected
          ? STYLES.colors.expectedConsumptionExceededLine
          : STYLES.colors.expectedConsumptionLine,
        enableMouseTracking: false,
        marker: { enabled: true },
        data: [
          [moment(expectedConsumption[0].timestamp).valueOf(), expectedConsumption[0].value],
          [moment(expectedConsumption[1].timestamp).valueOf(), expectedConsumption[1].value],
        ],
      },
      // daily consumption spline
      {
        type: 'areaspline',
        color: STYLES.colors.consumedFlatQuotaLine,
        fillColor: STYLES.colors.consumedFlatQuotaArea,
        enableMouseTracking: false,
        marker: { enabled: false },
        data: consumptionPoints,
      },
      // horizontal line with bubble, icon and tooltip
      {
        type: 'flags',
        color: STYLES.colors.consumedFlatQuotaLine,
        enableMouseTracking: false,
        shape: 'circlepin',
        useHTML: true,
        allowOverlapX: true,
        lineWidth: 2,
        y: -230,
        dataLabels: {
          enabled: true,
          crop: false,
          overflow: 'none',
          align: 'left',
          x: -20,
          y: -290,
          useHTML: true,
          formatter: generateFlagDataPoint,
        },
        data: [{
          x: consumptionPoints.slice(-1)[0][0],
          y: 0,
          value: flagDataPointDescription,
          title: '<span class="icofont-house icofont-xlg c-annual-consumption-chart__flag"></span>',
        }],
      },
    ],
  };
};
