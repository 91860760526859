import { RouterBuilder } from '+app/router/factory';
import { getSubRoutes } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { useRouterState } from '+app/router/RouterProvider.component';
import { ScrollToTop } from '+app/shared/components';
import * as React from 'react';

export const GuideAcceptance: React.FC = () => {
  const { availableFlatRoutes } = useRouterState();

  return (
    <>
      <ScrollToTop />
      <RouterBuilder routes={getSubRoutes(RouteName.GUIDE_ACCEPTANCE, availableFlatRoutes)} />
    </>
  );
};
