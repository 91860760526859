import { EnergyUnit } from '@sonnen/shared-web';

import { InfographicCardConnectionStatusType } from '../../containers/Infographic/Infographic.types';

export type InfographicContractCardUnit = EnergyUnit | string;

export interface InfographicContractCardData {
  title?: string;
  subTitle?: string;
  value?: number | string;
  unit?: InfographicContractCardUnit;
  description?: string;
}

export enum InfographicContractCardStatusType {
  PUCHARSE_COMPLETE = 'purchaseComplete',
  SUPPLY = 'supply',
  MISSING_CONTRACT_DATA = 'missingContractData',
}
