import { T } from '@sonnen/shared-i18n/customer';
import { PageHeadline, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { PageMetaTitle } from '+app/shared/components';
import {
  BatteryCardAccessories,
  BatteryCardBackupBox,
  BatteryCardInfo,
} from '+dashboard/+battery/components';
import { DemoBatteryCardStatus } from '../DemoBatteryCardStatus';
import { DemoBatteryOverviewProps } from './DemoBatteryOverview.component';

export const DemoBatteryOverviewMobile: React.FC<DemoBatteryOverviewProps> = ({
  batteryData,
  liveData,
}) => (
  <>
    <PageMetaTitle value={I18n.t(T.general.pageTitles.batterySystem.overview)} />
    <PageHeadline dataTestId={'demo-sonnen-batterie-header'}>
      {I18n.t(T.demo.battery.title)}
    </PageHeadline>
    <div className={'o-grid'}>
      <section className={'o-grid__column o-grid__column--sm-6'}>
        <BatteryCardInfo
          batteryData={batteryData}
          liveData={liveData}
        />
      </section>
      <section className={'o-grid__column o-grid__column--sm-6'}>
        <DemoBatteryCardStatus
          batteryData={batteryData}
          liveData={liveData}
        />
      </section>
    </div>
    <PageSubheadline>
      {I18n.t(T.demo.battery.accessoriesTitle)}
    </PageSubheadline>
    <div className={'o-grid'}>
      <section className={'o-grid__column o-grid__column--sm-4 o-grid__column--no-gap-sm'}>
        <BatteryCardBackupBox
          liveData={liveData}
          batteryData={batteryData}
        />
      </section>
      <section className={'o-grid__column o-grid__column--sm-4 o-grid__column--no-gap'}>
        <BatteryCardAccessories />
      </section>
    </div>
  </>
);
