import * as React from 'react';

import './BatteryCardGrid.component.scss';

interface Props {
  side?: React.ReactNode;
  content: React.ReactNode;
}

export const BatteryCardGrid: React.SFC<Props> = ({ side, content }) => (
  <div className={'c-battery-card-grid'}>
    {side && (
      <div className={'c-battery-card-grid__item c-battery-card-grid__item--side'}>
        {side}
      </div>
    )}
    <div className={'c-battery-card-grid__item'}>
      {content}
    </div>
  </div>
);
