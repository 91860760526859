import { ActionsUnion, createAction } from '+utils/redux';
import { LiveDataPollingType } from './live.state';
import { ChargerLive } from './types/chargerLiveData.interface';
import { SiteLive } from './types/siteLiveData.interface';

export enum LIVE_ACTIONS {
  // LIVE_DATA
  GET_SITE_LIVE_DATA = '[Live] GET_SITE_LIVE_DATA',
  SET_SITE_LIVE_DATA = '[Live] SET_SITE_LIVE_DATA',

  // CHARGER_LIVE_DATA
  GET_CHARGER_LIVE_DATA = '[Live] GET_CHARGER_LIVE_DATA',
  SET_CHARGER_LIVE_DATA = '[Live] SET_CHARGER_LIVE_DATA',

  // GENERAL
  START_LIVE_DATA_POLLING = '[Live] START_LIVE_DATA_POLLING',
  STOP_LIVE_DATA_POLLING = '[Live] STOP_LIVE_DATA_POLLING',
  CLEAR_LIVE_DATA = '[Live] CLEAR_LIVE_DATA',
}

export const LiveActions = {
  // SITE LIVE DATA
  getSiteLiveData: () => createAction(LIVE_ACTIONS.GET_SITE_LIVE_DATA),
  setSiteLiveData: (liveData: SiteLive) => createAction(LIVE_ACTIONS.SET_SITE_LIVE_DATA, { liveData }),

  // CHARGER LIVE DATA
  getChargerLiveData: () => createAction(LIVE_ACTIONS.GET_CHARGER_LIVE_DATA),
  setChargerLiveData: (liveData: ChargerLive) => createAction(LIVE_ACTIONS.SET_CHARGER_LIVE_DATA, { liveData }),

  // GENERAL
  startLiveDataPolling: (pollingType: LiveDataPollingType) =>
    createAction(LIVE_ACTIONS.START_LIVE_DATA_POLLING, { pollingType }),
  stopLiveDataPolling: () => createAction(LIVE_ACTIONS.STOP_LIVE_DATA_POLLING),
  clearLiveData: () => createAction(LIVE_ACTIONS.CLEAR_LIVE_DATA),
};

export type LiveActions = ActionsUnion<typeof LiveActions>;
