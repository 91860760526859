import * as React from 'react';

import './ContractListRow.component.scss';

export const ContractListRow: React.FC = ({ children }) => (
  <div className={'c-contract-list-row'}>
    {React.Children.map(children, (child: React.ReactNode) => (
      <div className={'c-contract-list-row__item'}>
        {child}
      </div>
    ))}
  </div>
);
