import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import { IconComponent, IconType } from '@sonnen/shared-web/src/components/Icon/models';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './AnalysisAccessoriesTooltipItem.component.scss';

interface Props {
  icon: IconType;
  isActive: boolean;
  temperature?: string;
  activityRange?: React.ReactNode;
}

export const AnalysisAccessoriesTooltipItem: React.FC<Props> = ({
  icon,
  isActive,
  temperature,
  activityRange,
}) => {
  const TooltipIcon: IconComponent = Icon[icon];

  return (
    <div className={'c-analysis-accessories-tooltip-item'}>
      <div className={'c-analysis-accessories-tooltip-item__header'}>
        <TooltipIcon className={'c-analysis-accessories-tooltip-item__icon'} />
        <div className={classNames('c-analysis-accessories-tooltip-item__symbol', {
          'c-analysis-accessories-tooltip-item__symbol--active': isActive,
        })} />
        {isActive
          ? I18n.t(T.analysis.accessoriesChart.tooltip.onlineState)
          : I18n.t(T.analysis.accessoriesChart.tooltip.offlineState)
        }
        {temperature && (
          <>
            <span className={'c-analysis-accessories-tooltip-item__small-label'}>
              {I18n.t(T.analysis.accessoriesChart.tooltip.tempLabel)}
            </span>
            ({temperature})
          </>
        )}
      </div>
      <div className={'c-analysis-accessories-tooltip-item__footer'}>
        {activityRange ? (
          <>
            {activityRange}
          </>
        ) : (
          <>
            &nbsp;
          </>
        )}
      </div>
    </div>
  );
};
