import * as moment from 'moment';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { AnalysisRepository } from '+analysis/store/analysis.repository';
import { StatisticsV1FilterResolution } from '+analysis/store/types/statisticsV1.interface';
import { getActiveSite, getActiveSiteId, isSiteWithContract } from '+app/shared/store/site/site.selectors';
import { Site } from '+app/shared/store/site/types/site.interface';
import { getBatteryTimezone } from '+battery/store/battery.selectors';
import { getBillingPeriodForContractTypeSonnenFlatHome } from '+contract/store/helpers/contractFlatHome.helpers';
import { getBillingPeriodForContractTypeSonnenFlatX } from '+contract/store/helpers/contractFlatX.helpers';
import { HelpRepository } from '+help/store/help.repository';
import { QueryActions } from '+query/query.actions';
import { combineSagas, dataGuard, processQuery } from '+utils/saga';
import { CONTRACT_ACTIONS, ContractActions } from './contract.actions';
import { ContractRepository } from './contract.repository';
import {
  getCurrentSiteContractData,
  getCurrentSiteContractId,
} from './contract.selectors';
import {
  GET_CONTRACT_DATA_QUERY,
  GET_CONTRACT_DOCUMENT_URL_QUERY,
  GET_CONTRACT_DOCUMENTS_QUERY,
  GET_CONTRACT_FILES_REQUEST_DATA_QUERY,
  GET_CONTRACT_INVOICES_REQUEST_DATA_QUERY,
  GET_STATISTICS_DATA_QUERY,
  TERMINATE_CONTRACT_QUERY,
} from './contract.state';
import { isContractType } from './helpers/contract.helpers';
import { Contract, ContractTypes } from './types/contract.interface';

export function* getContractData(): SagaIterator {
  const activeSite: Site | undefined = yield select(getActiveSite);
  const hasContracts = yield select(isSiteWithContract);

  if (!activeSite) { return; }

  if (hasContracts) {
    yield processQuery(
      GET_CONTRACT_DATA_QUERY,
      ContractRepository.getContracts,
      {
        onSuccess: res => dataGuard(ContractActions.setContractData)(res),
      },
    )({
      siteId: activeSite.id,
    });
  }
}

export function* startContractPolling() {
  yield call(getContractData);

  while (true) {
    const { stopped } = yield race({
      wait: delay(3000),
      stopped: take(CONTRACT_ACTIONS.STOP_CONTRACTS_POLLING),
    });

    const contract = getCurrentSiteContractData(yield select());

    if (stopped || contract?.contractPreviousNumber) {
      break;
    } else {
      yield call(getContractData);
    }
  }
}

export function* getSonnenFlatStatistics(): SagaIterator {
  const [activeSiteId, contract, batteryTimezone]: [string, Contract, string] = yield all([
    select(getActiveSiteId),
    select(getCurrentSiteContractData),
    select(getBatteryTimezone),
  ]);

  if (!activeSiteId) {
    return null;
  }

  const { start, end } = isContractType(contract, ContractTypes.SONNEN_FLAT_X)
    ? getBillingPeriodForContractTypeSonnenFlatX(batteryTimezone, contract)
    : getBillingPeriodForContractTypeSonnenFlatHome(batteryTimezone, contract)
  ;

  const formattedStartDate = start.format();
  const formattedEndDate = end.format();

  yield processQuery(GET_STATISTICS_DATA_QUERY, AnalysisRepository.getStatisticsV1, {
    onSuccess: res => dataGuard(ContractActions.setSonnenFlatStatisticsDaily)(res),
  })({
    id: activeSiteId,
    filters: {
      start: formattedStartDate,
      end: formattedEndDate,
      resolution: StatisticsV1FilterResolution.DAY,
    },
  });

  yield processQuery(GET_STATISTICS_DATA_QUERY, AnalysisRepository.getStatisticsV1, {
    onSuccess: res => dataGuard(ContractActions.setSonnenFlatStatisticsTotal)(res),
  })({
    id: activeSiteId,
    filters: {
      start: formattedStartDate,
      end: formattedEndDate,
      resolution: StatisticsV1FilterResolution.TOTAL,
    },
  });
}

export function* getSonnenFlatStatisticsFromLastYear(): SagaIterator {
  const activeSite: Site | undefined = yield select(getActiveSite);
  const contract = yield select(getCurrentSiteContractData);
  const now = moment().startOf('day').format('YYYY-MM-DD');
  const yearAgo = moment().subtract(11, 'months').startOf('day').format('YYYY-MM-DD');

  if (
    !activeSite ||
    !contract ||
    (!isContractType(contract, ContractTypes.SONNEN_FLAT_HOME))
  ) {
    return null;
  }

  yield processQuery(GET_STATISTICS_DATA_QUERY, AnalysisRepository.getStatisticsV1, {
    onSuccess: res => dataGuard(ContractActions.setSonnenFlatStatisticsFromLastYear)(res),
  })({
    id: activeSite.id,
    filters: {
      start: yearAgo,
      end: now,
      resolution: StatisticsV1FilterResolution.MONTH,
    },
  });
}

export function* getContractFilesRequestData(
  { contractId }: ReturnType<typeof ContractActions.getContractFilesRequestData>,
): SagaIterator {
  yield processQuery(
    GET_CONTRACT_FILES_REQUEST_DATA_QUERY,
    ContractRepository.getContractFiles,
    {
      onSuccess: res =>
        dataGuard(ContractActions.setContractFilesRequestData)(res && { contractId, contractFilesRequestData: res }),
      onFailure: (error?: Error) =>
        put(
          QueryActions.failure(
            GET_CONTRACT_FILES_REQUEST_DATA_QUERY,
            error as Error,
          ),
        ),
    },
  )({
    id: contractId,
  });
}

export function* getContractInvoicesRequestData(
  { contractId }: ReturnType<typeof ContractActions.getContractInvoicesRequestData>,
): SagaIterator {
  yield processQuery(
    GET_CONTRACT_INVOICES_REQUEST_DATA_QUERY,
    ContractRepository.getContractInvoices,
    {
      onSuccess: res =>
        dataGuard(ContractActions.setContractInvoicesRequestData)(
          res && { contractId, contractInvoicesRequestData: res },
        ),
      onFailure: (error?: Error) =>
        put(
          QueryActions.failure(
            GET_CONTRACT_INVOICES_REQUEST_DATA_QUERY,
            error as Error,
          ),
        ),
    },
  )({
    id: contractId,
  });
}

export function* terminateContract(action: ReturnType<typeof ContractActions.terminateContract>): SagaIterator {
  yield processQuery(
    TERMINATE_CONTRACT_QUERY,
    HelpRepository.sendSupportMessage,
    {
      onSuccess: () => put(ContractActions.getContractData()),
      onFailure: (error) => put(QueryActions.failure(TERMINATE_CONTRACT_QUERY, error as Error)),
    },
  )({
    attributes: {
      description: 'Termination by customer',
      category: 'Termination',
      requestType: 'Request',
      subject: 'Termination by customer',
    },
    relationship: {
      name: 'contract',
      type: 'contracts',
      uuid: action.contractId,
    },
  });
}

function* getContractDocuments(
  { contractId }: ReturnType<typeof ContractActions.getContractDocuments>,
): SagaIterator {

  yield processQuery(
    GET_CONTRACT_DOCUMENTS_QUERY,
    ContractRepository.getContractDocuments,
    {
      onSuccess: res => dataGuard(ContractActions.setContractDocuments)(res),
    },
  )(contractId);
}

type GetContractDocumentAction = ReturnType<typeof ContractActions.getContractDocumentUrl>;

function* getContractDocumentUrl({ documentId, contractId }: GetContractDocumentAction): SagaIterator {

  yield processQuery(
    GET_CONTRACT_DOCUMENT_URL_QUERY,
    ContractRepository.getContractDocument,
    {
      onSuccess: res => dataGuard(ContractActions.setContractDocumentUrl)(res?.url),
    },
  )({ documentId, contractId });
}

export const contractSagas = combineSagas(
  takeLatest(CONTRACT_ACTIONS.GET_CONTRACT_DATA, getContractData),
  takeLatest(CONTRACT_ACTIONS.START_CONTRACTS_POLLING, startContractPolling),
  takeLatest(CONTRACT_ACTIONS.GET_SONNENFLAT_STATISTICS, getSonnenFlatStatistics),
  takeLatest(CONTRACT_ACTIONS.GET_SONNENFLAT_STATISTICS_FROM_LAST_YEAR, getSonnenFlatStatisticsFromLastYear),
  takeEvery(CONTRACT_ACTIONS.GET_CONTRACT_FILES_REQUEST_DATA, getContractFilesRequestData),
  takeEvery(CONTRACT_ACTIONS.GET_CONTRACT_INVOICES_REQUEST_DATA, getContractInvoicesRequestData),
  takeEvery(CONTRACT_ACTIONS.TERMINATE_CONTRACT, terminateContract),
  takeLatest(CONTRACT_ACTIONS.GET_CONTRACT_DOCUMENTS, getContractDocuments),
  takeLatest(CONTRACT_ACTIONS.GET_CONTRACT_DOCUMENT_URL, getContractDocumentUrl),
);
