import { CONFIG } from '+config';
import * as PropTypes from 'prop-types';
import { feature as topoJsonFeature } from 'topojson-client';
import { GeometryCollection } from 'topojson-specification';
import { FromPromiseAll } from '../../helpers/async';
import { createGeoJson, geoJsonPointCreator } from '../../helpers/map';
import { factorizeMapQueryParams, QueryOptions } from '../../helpers/queryFactory';
import { localStorage } from '../../helpers/storage';
import { CommunityCoreResponse, CommunityData } from './communityMap.types';

const COMMUNITY_MAP_KEYS = {
  worldMapBaseStorageKey: 'customerPortal--worldMapBase',
  batteriesGeomStorageKey: 'customerPortal--batteriesGeom',
  ambassadorsGeomStorageKey: 'customerPortal--ambassadorsGeom',
};

const DEFAULT_HEADERS = { Accept: 'application/vnd.sonnenbatterie.api.core.v3+json' };

// Temporary map and sonnen globe API calls for map data
export const getWorldMapBase = (): Promise<ReturnType<typeof topoJsonFeature>> =>
  fetch('/world.topo.json')
    .then(res => res.json())
    .then((topoJSON) => {
      return topoJsonFeature(topoJSON, topoJSON.objects.custom as GeometryCollection);
    });

export const getBatteriesGeom = async (opts?: QueryOptions): Promise<CommunityCoreResponse> => {
  const queryParams = factorizeMapQueryParams(opts);

  return fetch(`${CONFIG.API.CORE}/sonnen_community/batteries${queryParams}`, {
    headers: DEFAULT_HEADERS,
  }).then(res => res.json());
};

export const getAmbassadorsGeom = async (opts?: QueryOptions): Promise<CommunityCoreResponse> => {
  const queryParams = factorizeMapQueryParams(opts);

  return fetch(`${CONFIG.API.CORE}/sonnen_community/ambassadors${queryParams}`, {
    headers: DEFAULT_HEADERS,
  }).then(res => res.json());
};

const fetchAllResults = (fetchFunction: (opts?: QueryOptions) => Promise<CommunityCoreResponse>) =>
  async () => {
    const firstPageResult = await fetchFunction({ page: 1 });
    const totalPages = firstPageResult.total_pages;
    const pendingPageResults: Array<Promise<any>> = [];

    if (totalPages > 1) {
      for (let page = 2; page <= totalPages; page++) {
        pendingPageResults.push(fetchFunction({ page }));
      }

      const pageResults = await Promise.all(pendingPageResults);
      const combinedResults = pageResults.reduce(
        (prev, curr) => [...prev, ...curr.results],
        [...firstPageResult.results],
      );

      return createGeoJson(geoJsonPointCreator)(combinedResults);
    }

    return createGeoJson(geoJsonPointCreator)(firstPageResult.results);
  };

async function checkAndGetGeom<T extends any>(key: string, request: () => Promise<T>) {
  if (CONFIG.IS_MOBILE) {
    return await request();
  }

  const savedGeom = await localStorage.getItem(key);
  if (savedGeom) {
    return JSON.parse(savedGeom);
  }
  const data = await request();
  
  try {
    await localStorage.setItem(key, JSON.stringify(data));
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(error);
    }
  }
  return data;
}

export interface MapData {
  world: FromPromiseAll<typeof getCommunityGeoData>;
  batteries: FromPromiseAll<typeof getCommunityGeoData>;
  ambassadors: FromPromiseAll<typeof getCommunityGeoData>;
}

export const getCommunityGeoData = () => Promise.all([
  checkAndGetGeom(COMMUNITY_MAP_KEYS.worldMapBaseStorageKey, getWorldMapBase),
  checkAndGetGeom(COMMUNITY_MAP_KEYS.batteriesGeomStorageKey, fetchAllResults(getBatteriesGeom)),
  checkAndGetGeom(COMMUNITY_MAP_KEYS.ambassadorsGeomStorageKey, fetchAllResults(getAmbassadorsGeom)),
]);

/*
  Data models
*/

export const GeoFeatureModel = PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.string,
  properties: PropTypes.object,
  geometry: PropTypes.object,
});

export const GeoJsonModel = PropTypes.shape({
  type: PropTypes.string,
  features: PropTypes.arrayOf(GeoFeatureModel),
});
