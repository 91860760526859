import { T } from '@sonnen/shared-i18n/customer';
import * as PullToRefreshJS from 'pulltorefreshjs';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './PullToRefresh.component.scss';

interface Props {
  isEnabled: boolean;
  onRefresh: () => void;
}

export class PullToRefresh extends React.PureComponent<Props> {
  private pullToRefreshInstance: any;

  componentDidMount() {
    this.init();
  }

  componentWillUnmount() {
    this.destroy();
  }

  init() {
    // `setTimeout` is required because `I18n` is initialized asynchronously
    setTimeout(() => {
      this.pullToRefreshInstance = PullToRefreshJS.init({
        mainElement: 'body',
        refreshTimeout: 250,
        instructionsPullToRefresh: I18n.t(T.pullToRefresh.pull),
        instructionsReleaseToRefresh: I18n.t(T.pullToRefresh.release),
        instructionsRefreshing: I18n.t(T.pullToRefresh.refreshing),
        shouldPullToRefresh: () => this.props.isEnabled && !window.scrollY,
        onRefresh: () => { this.props.onRefresh(); },
      });
    }, 0);
  }

  destroy() {
    if (this.pullToRefreshInstance) {
      this.pullToRefreshInstance.destroy();
    }
  }

  render() {
    return (
      <div className={'c-pull-to-refresh'}>
        {this.props.children}
      </div>
    );
  }
}
