import { T } from '@sonnen/shared-i18n/customer';
import { Icon, Loader } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { RouterAction } from 'connected-react-router';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './ProgressCard.component.scss';

export enum ProgressCardProgress {
  UNAVAILABLE = 'unavailable',
  READY = 'ready',
  STARTED = 'started',
  FINISHED = 'finished',
}

export type ProgressCardProps = {
  onClick: () => RouterAction;
  icon: string;
  title: string;
  description?: string;
  progress?: ProgressCardProgress;
  progressPercentage: number;
  isCtaVisible?: boolean;
  ctaLabel?: string;
  isLoading?: boolean;
};

export const ProgressCard: React.FC<ProgressCardProps> = ({
  onClick,
  icon,
  title,
  description,
  progress,
  isCtaVisible,
  progressPercentage,
  ctaLabel,
  isLoading,
}) => {
  const isThemeFaded = progress === ProgressCardProgress.UNAVAILABLE;
  const renderProgressElement = (progress: ProgressCardProgress) => {
    switch (progress) {
      case ProgressCardProgress.UNAVAILABLE:
        return (
          <p className={'c-progress-card__description'}>
            {description}
          </p>
        );
      case ProgressCardProgress.STARTED:
        return isCtaVisible ? (
            <div className={'c-progress-card__cta'}>
              {ctaLabel}
            </div>
          ) : (
            <p className={'c-progress-card__in-progress'}>
              {I18n.t(T.customerGuidance.overview.inProgressLabel)}
            </p>
          );
      case ProgressCardProgress.FINISHED:
        return (
          <div className={'c-progress-card__checkmark'}>
            <Icon.Checkmark />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div
      className={classNames('c-progress-card', {
        'c-progress-card--theme-faded': isThemeFaded,
      })}
      style={{
        '--progress-card-progress': progressPercentage,
      } as React.CSSProperties}
    >
      {!isLoading ? (
        <button
          className={'c-progress-card__button'}
          onClick={onClick}
          type={'button'}
        >
          <div>
            <div className={'c-progress-card__image-wrapper'}>
              <img
                className={'c-progress-card__image'}
                src={icon}
                alt={''}
              />
            </div>
            <p className={'c-progress-card__title'}>
              {title}
            </p>
          </div>
          <div>
            {progress && renderProgressElement(progress)}
            {progress && (
              <div className={'c-progress-card__progress-bar'}>
                <div className={'c-progress-card__progress-bar-inner'} />
              </div>
            )}
          </div>
        </button>
      ) : (
        <Loader />
      )}
    </div>
  );
};
