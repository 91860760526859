import { T } from '@sonnen/shared-i18n/customer';
import {
  AnalysisChartCrosshair,
  AnalysisPieChart,
  AnalysisTooltip,
  AnalysisTooltipMeasurementItem,
  autonomyStyle,
  EnergyUnit,
  factorizeTooltipDate,
  prepareTooltipData,
  selfConsumptionStyle,
  SharedChartColors,
  StatisticsSeriesKey,
  TimeUnit,
  useCountryFeature,
} from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { CountryFeatureName } from '+config/countryFlags';
import { DashboardInfoBannerInaccurateMeasurements } from '+dashboard/components';
import { breakpointUp } from '+legacy/helpers/breakpoints';
import { AnalysisBarChart } from '../';
import { useAnalysisBarChart } from '../../containers/AnalysisKanvaProvider/AnalysisBarChartProvider/AnalysisBarChartProvider.context';
import { useAnalysis } from '../../containers/AnalysisProvider/AnalysisProvider.context';
import { CHART_HEIGHT } from '../../helpers/analysis.helper';
import { chartTooltipStyles, seriesKeyTranslationMap } from '../../helpers/tooltip.helper';
import { KanvaHelper } from '../../kanva/kanva.helper';
import { visibleStatisticsSeriesKeys } from '../../store/analysis.helpers';
import {
  getPieChartLegendItems,
  getPieChartValueProperties,
} from './AnalysisHistory.helper';

import './AnalysisHistory.web.component.scss';

interface PieChartValues {
  fontSize: number;
  consumptionValue: string;
  productionValue: string;
}

export const AnalysisHistoryWeb: React.FC = () => {
  const {
    commonData: {
      pieChartSeries,
      statisticsSelectedDate,
    },
    query: {
      statisticsQueryStatus,
    },
  } = useAnalysis();
  const {
    currentXPosition,
    primarySeries,
    tooltipValues,
  } = useAnalysisBarChart();
  const [pieChartValues, setPieChartValues] = React.useState<PieChartValues>({
    fontSize: 23,
    consumptionValue: '0',
    productionValue: '0',
  });
  const [tooltipVisibility, setTooltipVisibility] = React.useState<boolean>(false);

  const desktopChartHeight = CHART_HEIGHT + 100;
  const shouldDisplayMeasurementsBanner = useCountryFeature(CountryFeatureName.MEASUREMENTS_BANNER).isEnabled;
  const autonomyLegendItems = getPieChartLegendItems(pieChartSeries.autonomy, autonomyStyle);
  const selfConsumptionLegendItems = getPieChartLegendItems(pieChartSeries.selfConsumption, selfConsumptionStyle);

  const setPieChartValueProperties = () => {
    const { autonomy, selfConsumption } = pieChartSeries;
    const pieChartValues = getPieChartValueProperties(selfConsumption, autonomy);

    setPieChartValues({
      fontSize: pieChartValues.fontSize,
      consumptionValue: pieChartValues.consumption,
      productionValue: pieChartValues.production,
    });
  };

  React.useEffect(() => {
    setPieChartValueProperties();
  }, [pieChartSeries]);

  return (
    <section className={'c-analysis-history'}>
      <p className={'c-analysis-history__headline'} >
        {{
          [TimeUnit.DAY]: I18n.t(T.history.titleDay),
          [TimeUnit.MONTH]: I18n.t(T.history.titleMonth),
          [TimeUnit.YEAR]: I18n.t(T.history.titleYear),
        }[statisticsSelectedDate.period]}
      </p>
      {shouldDisplayMeasurementsBanner && (
        <div className={'c-analysis-history__banner-container'} >
          <DashboardInfoBannerInaccurateMeasurements />
        </div>
      )}
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--md-8'}>
          <div
            onPointerOver={() => setTooltipVisibility(true)}
            onPointerOut={() => setTooltipVisibility(false)}
          >
            <Media query={`(min-width: ${breakpointUp('SM')}px)`}>
              {(isDesktop: boolean) => isDesktop ? (
                <div className={'c-analysis-history__bar-chart'}>
                  <AnalysisTooltip
                    type={'vertical'}
                    isVisible={tooltipVisibility}
                    header={factorizeTooltipDate(
                      statisticsSelectedDate.period,
                      KanvaHelper.getSeriesXFromTooltipValues(primarySeries, tooltipValues),
                      undefined,
                      statisticsSelectedDate.date,
                    )}
                    x={currentXPosition || 0}
                  >
                    {prepareTooltipData(
                      tooltipValues,
                      seriesKeyTranslationMap,
                      visibleStatisticsSeriesKeys,
                      chartTooltipStyles,
                    ).map(props => (
                      <AnalysisTooltipMeasurementItem
                        key={props.seriesKey}
                        inline={true}
                        {...props}
                      />
                    ))}
                  </AnalysisTooltip>
                  <AnalysisBarChart
                    chartHeight={desktopChartHeight}
                  />
                  <AnalysisChartCrosshair
                    crosshairPosition={currentXPosition}
                    height={desktopChartHeight}
                    isVisible={tooltipVisibility}
                  />
                </div>
              ) : (
                <div>
                  <div className={'c-analysis-history__tooltip'}>
                    <AnalysisTooltip
                      type={'horizontal'}
                      isVisible={statisticsQueryStatus.success}
                      header={factorizeTooltipDate(
                        statisticsSelectedDate.period,
                        KanvaHelper.getSeriesXFromTooltipValues(primarySeries, tooltipValues),
                        undefined,
                        statisticsSelectedDate.date,
                      )}
                    >
                      {prepareTooltipData(
                        tooltipValues,
                        seriesKeyTranslationMap,
                        visibleStatisticsSeriesKeys,
                        chartTooltipStyles,
                      ).map(props => (
                        <AnalysisTooltipMeasurementItem
                          key={props.seriesKey}
                          {...props}
                        />
                      ))}
                    </AnalysisTooltip>
                  </div>
                  <div  className={'c-analysis-history__bar-chart'}>
                    <AnalysisBarChart
                      chartHeight={CHART_HEIGHT}
                    />
                    <AnalysisChartCrosshair
                      crosshairPosition={currentXPosition}
                      height={CHART_HEIGHT}
                      isVisible={statisticsQueryStatus.success}
                    />
                  </div>
                </div>
              )}
            </Media>
          </div>
        </div>
        <div className={'o-grid__column o-grid__column--md-4 c-analysis-history__pie-charts-container'}>
          <div className={'c-analysis-history__pie-chart'}>
            <AnalysisPieChart
              title={I18n.t(T.history.chart.labels.consumption)}
              unit={EnergyUnit.KWH}
              displayedSeriesKey={StatisticsSeriesKey.CONSUMED_ENERGY}
              series={pieChartSeries.autonomy}
              style={autonomyStyle}
              isPending={statisticsQueryStatus.pending}
              value={pieChartValues.consumptionValue}
              valueColor={SharedChartColors.CONSUMPTION}
              valueFontSize={pieChartValues.fontSize}
              legendItems={autonomyLegendItems}
            />
          </div>
          <div className={'c-analysis-history__pie-chart'}>
            <AnalysisPieChart
              title={I18n.t(T.history.chart.labels.production)}
              unit={EnergyUnit.KWH}
              displayedSeriesKey={StatisticsSeriesKey.PRODUCED_ENERGY}
              series={pieChartSeries.selfConsumption}
              style={selfConsumptionStyle}
              isPending={statisticsQueryStatus.pending}
              value={pieChartValues.productionValue}
              valueColor={SharedChartColors.GOLD}
              valueFontSize={pieChartValues.fontSize}
              legendItems={selfConsumptionLegendItems}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
