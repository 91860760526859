import { ActionsUnion, createAction } from '+utils/redux';
import { Site } from './types/site.interface';

export enum SITE_ACTIONS {
  GET_SITES = '[Site] GET_SITES',
  SET_SITES = '[Site] SET_SITES',
  SWITCH_SITE = '[Site] SWITCH_SITE',
  SET_ACTIVE_SITE = '[Site] SET_ACTIVE_SITE',
}

export const SiteActions = {
  getSites: () =>
    createAction(SITE_ACTIONS.GET_SITES),
  setSites: (sites: Site[]) =>
    createAction(SITE_ACTIONS.SET_SITES, { sites }),
  switchSite: (siteId: string) =>
    createAction(SITE_ACTIONS.SWITCH_SITE, { siteId }),
  setActiveSite: (siteId: string) =>
    createAction(SITE_ACTIONS.SET_ACTIVE_SITE, { siteId }),
};

export type SiteActions = ActionsUnion<typeof SiteActions>;
