import { Icon } from '@sonnen/shared-web';
import * as React from 'react';

import './AccountTooltipError.component.scss';

export const AccountTooltipError: React.FC = ({ children }) => (
  <div className={'c-account-tooltip-error'}>
    <div className={'c-account-tooltip-error__content'}>
      <Icon.Warning className={'c-account-tooltip-error__icon'} />
      <div className={'c-account-tooltip-error__message '}>
        {children}
      </div>
    </div>
  </div>
);
