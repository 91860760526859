import { T } from '@sonnen/shared-i18n/customer';
import { PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { NewsSection } from '+shared/containers/NewsSection';
import { ContractFlatXCardEnergyContract, ContractFlatXCardEnergyDelivery, ContractFlatXCardUsageAllowance } from '../../containers';
import { getContractStepsCount, getContractTotalStepsCount } from '../../store/helpers/contract.helpers';
import { isContractType, SonnenFlatDataProvider } from '../../store/helpers/contract.helpers';
import { ContractTypes } from '../../store/types/contract.interface';
import { ContractBillingSection } from '../ContractBillingSection';
import { ContractEnergyMeterSection } from '../ContractEnergyMeterSection';
import { ContractFlatStepsCounter } from '../ContractFlatStepsCounter';

import './ContractFlatXOverview.component.scss';

export type ContractFlatXOverviewProps = {
  contractDataProvider: SonnenFlatDataProvider;
  isContractStatusDone: boolean,
};

export const ContractFlatXOverview: React.FC<ContractFlatXOverviewProps> = ({
  contractDataProvider,
  isContractStatusDone,
}) => {
  const { contract } = contractDataProvider;
  const billingPeriod = contractDataProvider.getBillingPeriod();
  const freeUsageAllowance = contractDataProvider.getFreeUsageAllowance();
  const isSonnenFlatHomeContract = isContractType(contract, ContractTypes.SONNEN_FLAT_HOME);
  const stepsCount = getContractStepsCount(contract, isContractStatusDone);
  const totalStepsCount = getContractTotalStepsCount(contract);
  const isFlatGraphVisible = Boolean(
    (isSonnenFlatHomeContract || isContractStatusDone)
    && contract.usageAllowanceActivatedOn
    && contract.deliveryStartAt
    && contract.amountOfFreeEnergy,
  );

  return (
    <div className={'c-contract-flat-x-overview'}>
      <NewsSection />
      <p 
        data-test-id={'contract-flat-x-overview-contract-number'}
        className={'c-contract-flat-x-overview__contract-number'}
      >
        {I18n.t(T.yourFlat.sonnenFlatX.orderNumber)}
        {contract.contractNumber}
      </p>
      {isFlatGraphVisible && (
        <div className={'c-contract-flat-x-overview__usage-allowance'}>
          <PageSubheadline>
            {I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceHeader)}
          </PageSubheadline>
          <ContractBillingSection billingPeriod={billingPeriod} />
          <ContractEnergyMeterSection
            // TODO: use typeguard when it becomes supported in tsx
            freeUsageAllowance={freeUsageAllowance || 0}
            percentage={contractDataProvider.getPercentageUsage()}
          />
        </div>
      )}
      <div className={'c-contract-flat-x-overview__order-status'}>
        <PageSubheadline>
          {I18n.t(T.yourFlat.sonnenFlatX.statusOfYourOrder)}
        </PageSubheadline>
        <ContractFlatStepsCounter
          dataTestId={'contract-flat-x-overview-steps'} 
          max={totalStepsCount} 
          count={stepsCount} 
        />
        <div className={'c-contract-flat-x-overview__cards'}>
          <ContractFlatXCardEnergyContract />
          <ContractFlatXCardEnergyDelivery />
          <ContractFlatXCardUsageAllowance />
        </div>
      </div>
    </div>
  );
};
