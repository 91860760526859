import { createSelector } from 'reselect';

import { StoreState } from '+app/store/store.interface';
import { getStatus } from '+app/utils/query.util';
import { GET_OFFERS_QUERY, GUIDE_SYNC_QUERY, PATCH_RECALCULATION_OFFER_QUERY } from './+guide.state';
import { LeadSource } from './types';
import { LeadStatusName } from './types/leadStatus.interface';

export const guideFlatAcceptanceForm = 'guideFlatAcceptance';

export const getGuideState = (state: StoreState) => state.guide;

export const getGuideCommonState = createSelector(
  getGuideState,
  state => state.common,
);

export const getGuideSyncQuery = createSelector(
  getGuideCommonState,
  state => state[GUIDE_SYNC_QUERY],
);

export const getGuideSyncQueryStatus = createSelector(
  getGuideSyncQuery,
  query => getStatus(query),
);

export const getRecalculationQuery = createSelector(
  getGuideCommonState,
  state => state[PATCH_RECALCULATION_OFFER_QUERY],
);

export const getRecalculationQueryStatus = createSelector(
  getRecalculationQuery,
  query => getStatus(query),
);

export const getGuideLead = createSelector(
  getGuideCommonState,
  state => state.lead,
);

export const getGuideOffersQuery = createSelector(
  getGuideCommonState,
  state => state[GET_OFFERS_QUERY],
);

export const getGuideOffersQueryStatus = createSelector(
  getGuideOffersQuery,
  query => getStatus(query),
);

export const isPartnerAvailable = createSelector(
  getGuideCommonState,
  state => !!state.partner,
);

export const getGuideLeadPartner = createSelector(
  getGuideCommonState,
  state => state.partner,
);

export const getGuideLeadId = createSelector(
  getGuideLead,
  lead => lead ? lead.id : undefined,
);

export const getGuideLeadFullName = createSelector(
  getGuideLead,
  lead => lead ? `${lead.firstName} ${lead.lastName}` : undefined,
);

export const getGuideLeadPartnerName = createSelector(
  getGuideLeadPartner,
  partner => partner && partner.name,
);

export const getGuideLeadPartnerStatus = createSelector(
  getGuideLeadPartner,
  partner => partner && partner.status,
);

export const getLeadStatus = (name: LeadStatusName) =>
  createSelector(
    getGuideLead,
    lead => lead && lead.status?.summary[name] || undefined,
  );

export const getLeadSource = createSelector(
  getGuideLead,
  lead => lead && lead.source,
);

export const isSonnenLead = createSelector(
  getLeadSource,
  leadSource => leadSource === LeadSource.SONNEN,
);
