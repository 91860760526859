import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';
import { Tooltip } from 'react-tippy';

interface Props {
  passwordStrength: number;
  disabled?: boolean;
}

export const PasswordStrengthTooltip: React.SFC<Props> =
  ({ children, passwordStrength, disabled }) => (
    <Tooltip
      position="right"
      disabled={disabled}
      trigger="click"
      interactive={true}
      duration="0"
      offset="0"
      distance="0"
      html={(
        <div className="dropdown dropdown--active">
          <div className="dropdown__content c-password-input__dropdown-content">
            <div className="c-password-input__dropdown-item">
              <p className="c-password-input__dropdown-headline">
                <Translate
                  value={T.general.tooltips.passwordStrength[`strength${passwordStrength}`].firstLine}
                />
              </p>
              <div className={`c-password-input__strength-check level-${passwordStrength}`} />
              <DefaultParagraph>
                <Translate value={T.general.tooltips.passwordStrengthSecondLine} />
              </DefaultParagraph>
            </div>
          </div>
        </div>
      )}
    >
      {children}
    </Tooltip>
  );

PasswordStrengthTooltip.defaultProps = {
  disabled: false,
};
