import * as classNames from 'classnames';
import * as React from 'react';

interface Props {
  className?: ClassValue;
}

export const Group: React.SFC<Props> = ({ children, className = '' }) => (
  <div className={classNames('c-form__group', className)}>
    {children}
  </div>
);
