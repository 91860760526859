import { Icon } from '@sonnen/shared-web';
import classNames from 'classnames';
import * as React from 'react';

import './ContractNotificationParagraph.component.scss';

interface Props {
  icon?: React.ReactNode;
  text: string;
  isThemeCard?: boolean;
}

export const ContractNotificationParagraph: React.FC<Props> = ({
  icon,
  text,
  isThemeCard,
}) => (
  <div className={classNames('c-contract-notification-paragraph', {
    'c-contract-notification-paragraph--theme-card': isThemeCard,
  })}>
    <div className={'c-contract-notification-paragraph__icon'}>
      {icon ? (
        {icon}
      ) : (
        <Icon.Info />
      )}
    </div>
    <p className={'c-contract-notification-paragraph__text'}>
      {text}
    </p>
  </div>
);
