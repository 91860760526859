import { TooltipEvent, XYPoint } from '@kanva/charts';
import { View } from '@kanva/core';
import { EnergyFlowSeriesKey, SeriesKey } from '@sonnen/shared-web';
import * as React from 'react';

import { KanvaContainers, KanvaExtensions, KanvaTooltipValues } from '../../../kanva/kanva.types';

export interface AnalysisDayChartLastXYSeriesPosition {
  [EnergyFlowSeriesKey.CONSUMPTION_POWER]: XYPoint | undefined;
  [EnergyFlowSeriesKey.PRODUCTION_POWER]: XYPoint | undefined;
}

interface AnalysisDayChartContextProps {
  chartView?: View<any>;
  containers?: KanvaContainers;
  currentXPosition: number;
  extensions?: KanvaExtensions;
  isChartZoomed: boolean;
  lastXYSeriesPosition: AnalysisDayChartLastXYSeriesPosition;
  tooltipEvent?: TooltipEvent;
  tooltipValues?: KanvaTooltipValues;
  primarySeries?: SeriesKey;
  scale: { x: number, y: number};
  handleMount: (view: View<any>) => void;
}

export const AnalysisDayChartContext = React.createContext<AnalysisDayChartContextProps>({
  currentXPosition: 0,
  lastXYSeriesPosition: {
    [EnergyFlowSeriesKey.CONSUMPTION_POWER]: {
      x: 0,
      y: 0,
    },
    [EnergyFlowSeriesKey.PRODUCTION_POWER]: {
      x: 0,
      y: 0,
    },
  },
  scale: { x: 1, y: 1},
} as AnalysisDayChartContextProps);

export const useAnalysisDayChart = () => React.useContext(AnalysisDayChartContext);
