import { Paint, rgba, TextAlign, TextBaseline } from '@kanva/core';
import { CHART_FONT, SharedChartColors } from '@sonnen/shared-web';

export const labelPaint = new Paint()
  .setFillStyle(rgba(SharedChartColors.DARK, .5))
  .setFont(CHART_FONT);

export const gridPaint = new Paint()
  .setStrokeStyle(rgba(SharedChartColors.DARK, .05))
  .setLineWidth(1.5);

export const xAxisLabelPaint = labelPaint.clone()
  .setTextAlign(TextAlign.CENTER)
  .setTextBaseline(TextBaseline.MIDDLE);

export const yAxisLabelPaint = labelPaint.clone()
  .setTextBaseline(TextBaseline.BOTTOM);

export const defaultLineSeriesStyle = {
  foreground: {
    width: 8,
    radius: 8,
  },
  background: {
    paint: new Paint().setFillStyle(rgba(SharedChartColors.DARK, .05)),
    width: 4,
    radius: 4,
  },
  minChunkLength: { domain: .1, px: 1 },
};
