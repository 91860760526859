import * as classNames from 'classnames';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';

import { getMainRoutePath, getRouteFromPath } from '+app/router/router.helper';
import { RouterAppNavType } from '+app/router/router.types';
import { useRouterState } from '+app/router/RouterProvider.component';
import { NavHorizontal } from '+app/shared/components';
import { PageProps } from './Page.component';

import './Page.mobile.component.scss';

export const PageMobile: React.FC<PageProps> = ({
  children,
  title,
  options,
}) => {
  const { availableFlatRoutes } = useRouterState();
  const mainRoutePath = getMainRoutePath(window.location.pathname);
  const mainRoute = getRouteFromPath(mainRoutePath, availableFlatRoutes);
  const pageTitle = mainRoute?.title?.mobile || title || '';

  return (
    <div className={'m-c-page'}>
      <div className={'m-c-page__header'}>
        <div className={classNames('m-c-page__headline', {
          'm-c-page__headline--bordered': options?.isHeadlineBorderOnMobile,
        })}>
          <Translate
            value={pageTitle}
            dangerousHTML={true}
          />
          {options?.isHeadlineSideContent && (
            <div className={'m-c-page__side'}>
              {options.isHeadlineSideContent}
            </div>
          )}
        </div>
        {!isEmpty(mainRoute?.subRoutes) && (
          <div className={'m-c-page__nav'}>
            <NavHorizontal
              hasIcon={false}
              labelType={'mobile'} // TODO: Remove labelType
              routes={mainRoute?.subRoutes!}
            />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
