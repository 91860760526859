import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './DownloadLink.component.scss';

interface Props {
  title: React.ReactNode;
  onDownload?: () => void;
}

export const DownloadLink: React.FC<Props> = ({
  title,
  onDownload,
}) => {

  return (
    <div className={'c-download-link'} >
      <div className={'c-download-link__label'} >
        {title}
      </div>
      <button
        className={'c-download-link__button'}
        onClick={onDownload}
      >
        <Icon.Eye
          className={classNames('c-download-link__icon', {
            'c-download-link__icon--disabled': !onDownload,
          })}
        />
      </button>
    </div>
  );
};
