export interface InputProps {
    production: number;
    consumption: number;
    batteryDischarge: number;
    gridOutput: number;
    vppActive?: boolean;
}

/*
  P - PV production (`+` means pv is producing energy)
  C - house consumption (`-` means house is consuming energy)
  B - battery energy flow (`+` means discharging, `-` means charging)
  G - grid energy flow (`+` means purchase, `-` means feedin)
  Vpp - virtual power plant, means the grid_service is active
  Excess - production > consumption
  Deficit - production < consumption
 */
export enum EnergyFlowStateNames {
    'P+C-' = 'P+C-',
    'P+B-' = 'P+B-',
    'P+G-' = 'P+G-',
    'P+C-B-' = 'P+C-B-',
    'P+C-G-' = 'P+C-G-',
    'P+B-G-' = 'P+B-G-',
    'P+C-B-G-' = 'P+C-B-G-',
    'P+C-B+' = 'P+C-B+',
    'P+B+G-Vpp' = 'P+B+G-Vpp',
    'P+B+G-' = 'P+B+G-', // invalid

    'P+C-B+G-ExcessVpp' = 'P+C-B+G-ExcessVpp',
    'P+C-B+G-Excess' = 'P+C-B+G-Excess', // invalid
    'P+C-B+G-EqualVpp' = 'P+C-B+G-EqualVpp',
    'P+C-B+G-Equal' = 'P+C-B+G-Equal', // invalid
    'P+C-B+G-DeficitVpp' = 'P+C-B+G-DeficitVpp',
    'P+C-B+G-Deficit' = 'P+C-B+G-Deficit', // invalid

    'B+C-' = 'B+C-',
    'B+G-Vpp' = 'B+G-Vpp',
    'B+G-' = 'B+G-', // invalid
    'B+C-G-Vpp' = 'B+C-G-Vpp',
    'B+C-G-' = 'B+C-G-', // invalid
    'C-G+' = 'C-G+',
    'B-G+Vpp' = 'B-G+Vpp',
    'B-G+' = 'B-G+', // invalid
    'C-B-G+Vpp' = 'C-B-G+Vpp',
    'C-B-G+' = 'C-B-G+', // invalid
    'C-B+G+' = 'C-B+G+',
    'P+C-B+G+' = 'P+C-B+G+',
    'P+C-G+' = 'P+C-G+',
    'P+B-G+Vpp' = 'P+B-G+Vpp',
    'P+B-G+' = 'P+B-G+', // invalid

    'P+C-B-G+ExcessVpp' = 'P+C-B-G+ExcessVpp',
    'P+C-B-G+Excess' = 'P+C-B-G+Excess', // invalid
    'P+C-B-G+EqualVpp' = 'P+C-B-G+EqualVpp',
    'P+C-B-G+Equal' = 'P+C-B-G+Equal', // invalid
    'P+C-B-G+DeficitVpp' = 'P+C-B-G+DeficitVpp',
    'P+C-B-G+Deficit' = 'P+C-B-G+Deficit', // invalid

    'EMPTY' = 'Empty',
}

export const INVALID_STATES_NAMES: EnergyFlowStateNames[] = [
  EnergyFlowStateNames['P+B+G-'],
  EnergyFlowStateNames['P+C-B+G-Excess'],
  EnergyFlowStateNames['P+C-B+G-Equal'],
  EnergyFlowStateNames['P+C-B+G-Deficit'],
  EnergyFlowStateNames['B+G-'],
  EnergyFlowStateNames['B+C-G-'],
  EnergyFlowStateNames['B-G+'],
  EnergyFlowStateNames['C-B-G+'],
  EnergyFlowStateNames['P+B-G+'],
  EnergyFlowStateNames['P+C-B-G+Excess'],
  EnergyFlowStateNames['P+C-B-G+Equal'],
  EnergyFlowStateNames['P+C-B-G+Deficit'],
];

type NonEmptyArray<T> =
  & { 0: T }
  & T[]
;

export interface EnergyFlowState {
    name: EnergyFlowStateNames;
    conditions: NonEmptyArray<(input: InputProps) => boolean>;
    ui: {
        isBatteryToConsumptionActive?: boolean;
        isBatteryToGridActive?: boolean;
        isProductionToBatteryActive?: boolean;
        isProductionToConsumptionActive?: boolean;
        isProductionToGridActive?: boolean;
        isProductionInvalid?: boolean;
        isGridToConsumptionActive?: boolean;
        isGridToBatteryActive?: boolean;
        isBatteryInvalid?: boolean;
        isGridInvalid?: boolean;
    };
}

export const pvProducing = ({ production }: InputProps) => production > 0;
export const pvNeutral = ({ production }: InputProps) => production === 0;

export const houseConsuming = ({ consumption }: InputProps) => consumption > 0;
export const houseNeutral = ({ consumption }: InputProps) => consumption === 0;

export const batteryNeutral = ({ batteryDischarge }: InputProps) => batteryDischarge === 0;
export const batteryCharging = ({ batteryDischarge }: InputProps) => batteryDischarge < 0;
export const batteryDischarging = ({ batteryDischarge }: InputProps) => batteryDischarge > 0;

export const gridNeutral = ({ gridOutput }: InputProps) => gridOutput === 0;
export const gridOutputting = ({ gridOutput }: InputProps) => gridOutput < 0;
export const gridIntaking = ({ gridOutput }: InputProps) => gridOutput > 0;

export const vppActive = ({ vppActive }: InputProps) => !!vppActive;
export const vppInactive = ({ vppActive }: InputProps) => !vppActive;

export const productionGreaterThenConsumption = ({ production, consumption }: InputProps) => production > consumption;
export const productionLessThenConsumption = ({ production, consumption }: InputProps) => production < consumption;
export const productionEqualsConsumption = ({ production, consumption }: InputProps) => production === consumption;

export const STATE_LIST: EnergyFlowState[] = [
  {
    name: EnergyFlowStateNames['P+C-'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryNeutral,
      gridNeutral,
    ],
    ui: {
      isProductionToConsumptionActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+B-'],
    conditions: [
      pvProducing,
      houseNeutral,
      batteryCharging,
      gridNeutral,
    ],
    ui: {
      isProductionToBatteryActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+G-'],
    conditions: [
      pvProducing,
      houseNeutral,
      batteryNeutral,
      gridIntaking,
    ],
    ui: {
      isProductionToGridActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B-'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryCharging,
      gridNeutral,
    ],
    ui: {
      isProductionToBatteryActive: true,
      isProductionToConsumptionActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-G-'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryNeutral,
      gridIntaking,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isProductionToGridActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+B-G-'],
    conditions: [
      pvProducing,
      houseNeutral,
      batteryCharging,
      gridIntaking,
    ],
    ui: {
      isProductionToBatteryActive: true,
      isProductionToGridActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B-G-'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryCharging,
      gridIntaking,
    ],
    ui: {
      isProductionToBatteryActive: true,
      isProductionToConsumptionActive: true,
      isProductionToGridActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B+'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryDischarging,
      gridNeutral,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isBatteryToConsumptionActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+B+G-Vpp'],
    conditions: [
      pvProducing,
      houseNeutral,
      batteryDischarging,
      gridIntaking,
      vppActive,
    ],
    ui: {
      isProductionToGridActive: true,
      isBatteryToGridActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+B+G-'],
    conditions: [
      pvProducing,
      houseNeutral,
      batteryDischarging,
      gridIntaking,
      vppInactive,
    ],
    ui: {
      isProductionToGridActive: true,
      isBatteryInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B+G-ExcessVpp'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryDischarging,
      gridIntaking,
      productionGreaterThenConsumption,
      vppActive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isProductionToGridActive: true,
      isBatteryToGridActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B+G-Excess'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryDischarging,
      gridIntaking,
      productionGreaterThenConsumption,
      vppInactive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isProductionToGridActive: true,
      isBatteryInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B+G-EqualVpp'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryDischarging,
      gridIntaking,
      productionEqualsConsumption,
      vppActive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isBatteryToGridActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B+G-Equal'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryDischarging,
      gridIntaking,
      productionEqualsConsumption,
      vppInactive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isBatteryInvalid: true,
      isGridInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B+G-DeficitVpp'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryDischarging,
      gridIntaking,
      productionLessThenConsumption,
      vppActive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isBatteryToConsumptionActive: true,
      isBatteryToGridActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B+G-Deficit'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryDischarging,
      gridIntaking,
      productionLessThenConsumption,
      vppInactive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isBatteryToConsumptionActive: true,
      isGridInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames['B+C-'],
    conditions: [
      pvNeutral,
      houseConsuming,
      batteryDischarging,
      gridNeutral,
    ],
    ui: {
      isBatteryToConsumptionActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['B+G-Vpp'],
    conditions: [
      pvNeutral,
      houseNeutral,
      batteryDischarging,
      gridIntaking,
      vppActive,
    ],
    ui: {
      isBatteryToGridActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['B+G-'],
    conditions: [
      pvNeutral,
      houseNeutral,
      batteryDischarging,
      gridIntaking,
      vppInactive,
    ],
    ui: {
      isBatteryInvalid: true,
      isGridInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames['B+C-G-Vpp'],
    conditions: [
      pvNeutral,
      houseConsuming,
      batteryDischarging,
      gridIntaking,
      vppActive,
    ],
    ui: {
      isBatteryToConsumptionActive: true,
      isBatteryToGridActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['B+C-G-'],
    conditions: [
      pvNeutral,
      houseConsuming,
      batteryDischarging,
      gridIntaking,
      vppInactive,
    ],
    ui: {
      isBatteryToConsumptionActive: true,
      isGridInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames['C-G+'],
    conditions: [
      pvNeutral,
      houseConsuming,
      batteryNeutral,
      gridOutputting,
    ],
    ui: {
      isGridToConsumptionActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['B-G+Vpp'],
    conditions: [
      pvNeutral,
      houseNeutral,
      batteryCharging,
      gridOutputting,
      vppActive,
    ],
    ui: {
      isGridToBatteryActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['B-G+'],
    conditions: [
      pvNeutral,
      houseNeutral,
      batteryCharging,
      gridOutputting,
      vppInactive,
    ],
    ui: {
      isBatteryInvalid: true,
      isGridInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames['C-B-G+Vpp'],
    conditions: [
      pvNeutral,
      houseConsuming,
      batteryCharging,
      gridOutputting,
      vppActive,
    ],
    ui: {
      isGridToConsumptionActive: true,
      isGridToBatteryActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['C-B-G+'],
    conditions: [
      pvNeutral,
      houseConsuming,
      batteryCharging,
      gridOutputting,
      vppInactive,
    ],
    ui: {
      isGridToConsumptionActive: true,
      isBatteryInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames['C-B+G+'],
    conditions: [
      pvNeutral,
      houseConsuming,
      batteryDischarging,
      gridOutputting,
    ],
    ui: {
      isBatteryToConsumptionActive: true,
      isGridToConsumptionActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B+G+'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryDischarging,
      gridOutputting,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isBatteryToConsumptionActive: true,
      isGridToConsumptionActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-G+'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryNeutral,
      gridOutputting,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isGridToConsumptionActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+B-G+Vpp'],
    conditions: [
      pvProducing,
      houseNeutral,
      batteryCharging,
      gridOutputting,
      vppActive,
    ],
    ui: {
      isProductionToBatteryActive: true,
      isGridToBatteryActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+B-G+'],
    conditions: [
      pvProducing,
      houseNeutral,
      batteryCharging,
      gridOutputting,
      vppInactive,
    ],
    ui: {
      isProductionToBatteryActive: true,
      isGridInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B-G+ExcessVpp'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryCharging,
      gridOutputting,
      productionGreaterThenConsumption,
      vppActive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isProductionToBatteryActive: true,
      isGridToBatteryActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B-G+Excess'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryCharging,
      gridOutputting,
      productionGreaterThenConsumption,
      vppInactive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isProductionToBatteryActive: true,
      isGridInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B-G+EqualVpp'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryCharging,
      gridOutputting,
      productionEqualsConsumption,
      vppActive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isGridToBatteryActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B-G+Equal'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryCharging,
      gridOutputting,
      productionEqualsConsumption,
      vppInactive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isBatteryInvalid: true,
      isGridInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B-G+DeficitVpp'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryCharging,
      gridOutputting,
      productionLessThenConsumption,
      vppActive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isGridToConsumptionActive: true,
      isGridToBatteryActive: true,
    },
  },
  {
    name: EnergyFlowStateNames['P+C-B-G+Deficit'],
    conditions: [
      pvProducing,
      houseConsuming,
      batteryCharging,
      gridOutputting,
      productionLessThenConsumption,
      vppInactive,
    ],
    ui: {
      isProductionToConsumptionActive: true,
      isBatteryInvalid: true,
      isGridInvalid: true,
    },
  },
  {
    name: EnergyFlowStateNames.EMPTY,
    conditions: [
      pvNeutral,
      houseNeutral,
      batteryNeutral,
      gridNeutral,
    ],
    ui: {},
  },
];
