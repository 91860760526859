import { CONFIG } from '+config';
import { initializeApp } from 'firebase';
import { firestore } from 'firebase/app';

export const createFirebaseClient = () => {
  initializeApp(CONFIG.FIREBASE);
  const firestoreInstance = firestore();

  const subscribeCollection = (collectionName: string) => firestoreInstance.collection(collectionName);

  return {
    firestore: firestoreInstance,
    subscribeCollection,
  };
};

export abstract class FirebaseClient {
  static readonly firebase = createFirebaseClient();
}
