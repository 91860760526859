import { Routes } from '+router/router.types';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { isCurrentRoute } from '../router.helper';

interface Props {
  routes: Routes | undefined;
  redirectTo?: string;
  redirectState?: object;
}

export const RouterBuilder: React.FC<Props> = ({ children, routes, redirectTo, redirectState }) => (
  <Switch>
    {children}
    {routes && routes.length ?
      (
        routes.map(({ path, isExactPath, component }) => (
          <Route
            key={path}
            exact={isExactPath}
            path={path}
            component={component}
          />
        ))
      ) : (
        null
      )
    }
    {redirectTo && !isCurrentRoute(redirectTo) && (
      <Redirect to={{
        pathname: redirectTo,
        state: redirectState,
      }} />
    )}
  </Switch>
);
