import { Data, MergedData } from '@coolio/json-api';

export enum LeadStage {
  OPEN = 'open',
  ON_SITE_VISIT_DONE = 'onSiteVisitDone',
  OFFER_SENT = 'offerSent',
  OFFER_ACCEPTED = 'offerAccepted',
  ORDER_CONFIRMED = 'orderConfirmed',
  CLOSED_DEAD = 'closedDead',
  RESUBMISSION = 'resubmission',
  COMPLAINT = 'complaint',
  CANCELLATION = 'cancellation',
  IN_SETUP = 'inSetup',
  DSO_REGISTRATION_COMPLETED = 'dsoRegistrationCompleted',
  DSO_COMMISSIONING_COMPLETED = 'dsoCommissioningCompleted',
  BNETZA_REGISTRATION_COMPLETED = 'bNetzARegistrationCompleted',
  TAX_OFFICE_REGISTRATION_COMPLETED = 'taxOfficeRegistrationCompleted',
  HW_COMMISSIONING_COMPLETED = 'hardwareCommissioningCompleted',
  DSO_METER_INSTALLED = 'dsoMeterInstalled',
  GUARANTEE_TERMS_AND_CONDITIONS_CONFIRMED = 'guaranteeTermsAndConditionsConfirmed',
  VPP_PREPARATION_DONE = 'vppPreparationDone',
  START_OF_DELIVERY_FIXED = 'startOfDeliveryFixed',
}

export enum LeadStatusName {
  OPEN = 'open',
  ON_SITE_VISIT_DONE = 'onSiteVisitDone',
  HW_OFFER_SENT = 'hardwareOfferSent',
  FLAT_OFFER_SENT = 'flatOfferSent',
  HW_OFFER_ACCEPTED = 'hardwareOfferAccepted',
  FLAT_OFFER_ACCEPTED = 'flatOfferAccepted',
  HW_ORDER_CONFIRMED = 'hardwareOrderConfirmed',
  FLAT_ORDER_CONFIRMED = 'flatOrderConfirmed',
  CLOSED_DEAD = 'closedDead',
  RESUBMISSION = 'resubmission',
  COMPLAINT = 'complaint',
  CANCELLATION = 'cancellation',
  DSO_REGISTRATION_COMPLETED = 'dsoRegistrationCompleted',
  DSO_COMMISSIONING_COMPLETED = 'dsoCommissioningCompleted',
  BNETZA_REGISTRATION_COMPLETED = 'bNetzARegistrationCompleted',
  TAX_OFFICE_REGISTRATION_COMPLETED = 'taxOfficeRegistrationCompleted',
  HW_COMMISSIONING_COMPLETED = 'hardwareCommissioningCompleted',
  DSO_METER_INSTALLED = 'dsoMeterInstalled',
  GUARANTEE_TERMS_AND_CONDITIONS_CONFIRMED = 'guaranteeTermsAndConditionsConfirmed',
  VPP_PREPARATION_DONE = 'vppPreparationDone',
  START_OF_DELIVERY_FIXED = 'startOfDeliveryFixed',
}

export interface StatusUpdate<S extends LeadStage, N extends LeadStatusName> {
  stage: S;
  name: N;
  isSet: boolean;
  createdAt: Date;
  reason?: string;
  description?: string;
}

export type Open = StatusUpdate<LeadStage.OPEN, LeadStatusName.OPEN>;
export type OnSiteVisitDone = StatusUpdate<LeadStage.ON_SITE_VISIT_DONE, LeadStatusName.ON_SITE_VISIT_DONE>;
export type HardwareOfferSent = StatusUpdate<LeadStage.OFFER_SENT, LeadStatusName.HW_OFFER_SENT>;
export type FlatOfferSent = StatusUpdate<LeadStage.OFFER_SENT, LeadStatusName.FLAT_OFFER_SENT>;
export type HardwareOfferAccepted = StatusUpdate<LeadStage.OFFER_ACCEPTED, LeadStatusName.HW_OFFER_ACCEPTED>;
export type FlatOfferAccepted = StatusUpdate<LeadStage.OFFER_ACCEPTED, LeadStatusName.FLAT_OFFER_ACCEPTED>;
export type HardwareOrderConfirmed = StatusUpdate<LeadStage.ORDER_CONFIRMED, LeadStatusName.HW_ORDER_CONFIRMED>;
export type FlatOrderConfirmed = StatusUpdate<LeadStage.ORDER_CONFIRMED, LeadStatusName.FLAT_ORDER_CONFIRMED>;
export type ClosedDead = StatusUpdate<LeadStage.CLOSED_DEAD, LeadStatusName.CLOSED_DEAD>;
export type Resubmission = StatusUpdate<LeadStage.RESUBMISSION, LeadStatusName.RESUBMISSION>;
export type Complaint = StatusUpdate<LeadStage.COMPLAINT, LeadStatusName.COMPLAINT>;
export type Cancellation = StatusUpdate<LeadStage.CANCELLATION, LeadStatusName.CANCELLATION>;
export type DsoRegistrationCompleted = StatusUpdate<
  LeadStage.DSO_COMMISSIONING_COMPLETED, LeadStatusName.DSO_COMMISSIONING_COMPLETED
>;
export type DsoCommisioningCompleted = StatusUpdate<
  LeadStage.DSO_COMMISSIONING_COMPLETED, LeadStatusName.DSO_COMMISSIONING_COMPLETED
>;
export type BNetzARegistrationCompleted = StatusUpdate<
  LeadStage.BNETZA_REGISTRATION_COMPLETED, LeadStatusName.BNETZA_REGISTRATION_COMPLETED
>;
export type TaxOfficeRegistrationCompleted = StatusUpdate<
  LeadStage.IN_SETUP, LeadStatusName.TAX_OFFICE_REGISTRATION_COMPLETED
>;
export type HwCommisioningCompleted = StatusUpdate<
  LeadStage.HW_COMMISSIONING_COMPLETED, LeadStatusName.HW_COMMISSIONING_COMPLETED
>;
export type DsoMeterInstalled = StatusUpdate<
  LeadStage.DSO_METER_INSTALLED, LeadStatusName.DSO_METER_INSTALLED
>;
export type GuaranteeTermsAndConditionsConfirmed = StatusUpdate<
  LeadStage.GUARANTEE_TERMS_AND_CONDITIONS_CONFIRMED, LeadStatusName.GUARANTEE_TERMS_AND_CONDITIONS_CONFIRMED
>;
export type VppPreparationDone = StatusUpdate<
  LeadStage.VPP_PREPARATION_DONE, LeadStatusName.VPP_PREPARATION_DONE
>;
export type StartOfDeliveryFixed = StatusUpdate<
  LeadStage.START_OF_DELIVERY_FIXED, LeadStatusName.START_OF_DELIVERY_FIXED
>;

export type CurrentStatusUpdate =
  | Open
  | OnSiteVisitDone
  | HardwareOfferSent
  | FlatOfferSent
  | HardwareOfferAccepted
  | FlatOfferAccepted
  | HardwareOrderConfirmed
  | FlatOrderConfirmed
  | ClosedDead
  | Resubmission
  | Complaint
  | Cancellation
  | DsoRegistrationCompleted
  | DsoCommisioningCompleted
  | BNetzARegistrationCompleted
  | TaxOfficeRegistrationCompleted
  | HwCommisioningCompleted
  | DsoMeterInstalled
  | GuaranteeTermsAndConditionsConfirmed
  | VppPreparationDone
  | StartOfDeliveryFixed
  ;

export type TaxOfficeRegistrationCompletedData = Data<TaxOfficeRegistrationCompleted>;
// @TODO: Rename to TaxOfficeRegistrationCompleted.
export type TaxOfficeRegistrationCompletedMerged = MergedData<TaxOfficeRegistrationCompletedData>;

export type BNetzARegistrationCompletedData = Data<BNetzARegistrationCompleted>;
// @TODO: Rename to BNetzARegistrationCompleted.
export type BNetzARegistrationCompletedMerged = MergedData<BNetzARegistrationCompletedData>;

export interface LeadStatus {
  currentStatus: CurrentStatusUpdate;
  summary: {
    [LeadStatusName.OPEN]: Open;
    [LeadStatusName.ON_SITE_VISIT_DONE]: OnSiteVisitDone | null;
    [LeadStatusName.HW_OFFER_SENT]: HardwareOfferSent | null;
    [LeadStatusName.FLAT_OFFER_SENT]: FlatOfferSent | null;
    [LeadStatusName.HW_OFFER_ACCEPTED]: HardwareOfferAccepted | null;
    [LeadStatusName.FLAT_OFFER_ACCEPTED]: FlatOfferAccepted | null;
    [LeadStatusName.HW_ORDER_CONFIRMED]: HardwareOrderConfirmed | null;
    [LeadStatusName.FLAT_ORDER_CONFIRMED]: FlatOrderConfirmed | null;
    [LeadStatusName.CLOSED_DEAD]: ClosedDead | null;
    [LeadStatusName.RESUBMISSION]: Resubmission | null;
    [LeadStatusName.COMPLAINT]: Complaint | null;
    [LeadStatusName.CANCELLATION]: Cancellation | null;
    [LeadStatusName.DSO_REGISTRATION_COMPLETED]: DsoRegistrationCompleted | null;
    [LeadStatusName.DSO_COMMISSIONING_COMPLETED]: DsoCommisioningCompleted | null;
    [LeadStatusName.BNETZA_REGISTRATION_COMPLETED]: BNetzARegistrationCompleted | null;
    [LeadStatusName.TAX_OFFICE_REGISTRATION_COMPLETED]: TaxOfficeRegistrationCompleted | null;
    [LeadStatusName.HW_COMMISSIONING_COMPLETED]: HwCommisioningCompleted | null;
    [LeadStatusName.DSO_METER_INSTALLED]: DsoMeterInstalled | null;
    [LeadStatusName.GUARANTEE_TERMS_AND_CONDITIONS_CONFIRMED]: GuaranteeTermsAndConditionsConfirmed | null;
    [LeadStatusName.VPP_PREPARATION_DONE]: VppPreparationDone | null;
    [LeadStatusName.START_OF_DELIVERY_FIXED]: StartOfDeliveryFixed | null;
  };
}
