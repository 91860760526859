import {
  getProductForStatusChange,
  isSpecificFlatProductInOffer,
} from '+app/+guide/+acceptance/store/+acceptance.helper';
import {
  getPrimaryFlatConfigurationDocumentKey,
  getPrimaryFlatConfigurationDocumentStatus,
  getPrimaryImpactAnalysisDocumentStatus,
} from '+app/+guide/+purchase/store/+offer.selectors';
import { GuideActions } from '+app/+guide/store/+guide.actions';
import { getOfferFlatProduct } from '+app/+guide/store/+guide.helper';
import { getDocumentUrl, getImpactAnalysisList } from '+app/+guide/store/+guide.selectors';
import { getGuideLead, getRecalculationQueryStatus } from '+app/+guide/store/+lead.selectors';
import { TooltipHoverable } from '+app/shared/components';
import { StoreState } from '+app/store/store.interface';
import { FeatureName } from '+config/featureFlags';
import { GuidePurchaseFlatOfferDates } from '+guide/+purchase/components';
import {
  ImpactAnalysis,
  Offer,
  OfferProductStatus,
  OfferProductType,
  Proposal,
} from '+guide/store/types';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, ButtonTheme, useFeature } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { GuidePurchaseConfigurationDetails } from '../../components/GuidePurchaseConfigurationDetails/GuidePurchaseConfigurationDetails';
import { GuidePurchaseDocumentDownload } from '../../components/GuidePurchaseDocumentDownload/GuidePurchaseDocumentDownload';

import './GuidePurchaseFlatOffer.component.scss';

const mapStateToProps = (state: StoreState, { proposal: { offer, ia } }: ComponentProps) => ({
  lead: getGuideLead(state),
  documentKey: offer && getPrimaryFlatConfigurationDocumentKey(state)({ offerId: offer.id }),
  documentUrl: getDocumentUrl(state),
  documentStatusConfiguration: offer && getPrimaryFlatConfigurationDocumentStatus(state)({ offerId: offer.id }),
  documentStatusImpactAnalysis: ia && getPrimaryImpactAnalysisDocumentStatus(state)({ impactAnalysisId: ia.id }),
  impactAnalysisList: getImpactAnalysisList(state),
  recalculationQueryStatus: getRecalculationQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  startDocumentPolling: GuideActions.startDocumentPolling,
  stopDocumentOfferPolling: GuideActions.stopDocumentPolling,
  regenerateDocument: GuideActions.regenerateDocument,
  recalculateOffer: GuideActions.recalculateOffer,
});

interface ComponentProps {
  handleToggleTermsModal: () => void;
  onCheckOffer?: () => void;
  proposal: Proposal;
}

export type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & ComponentProps
  ;

const GuidePurchaseFlatOfferComponent: React.FC<Props> = ({
  lead,
  proposal,
  actions,
  documentUrl,
  documentKey,
  documentStatusConfiguration,
  documentStatusImpactAnalysis,
  handleToggleTermsModal,
  onCheckOffer,
  recalculationQueryStatus,
}) => {
  const impactAnalysis: ImpactAnalysis | undefined = proposal.ia;
  const offer: Offer = proposal.offer;
  const flatProduct = getProductForStatusChange(offer);
  const isExpired = flatProduct?.status === OfferProductStatus.SENT_EXPIRED;
  const sentOfferExpiresAt = flatProduct?.sentExpiresAt;
  const recalculatedAt = flatProduct?.recalculatedAt; 

  const status: OfferProductStatus | undefined = 
    proposal.offer ? getOfferFlatProduct(proposal.offer)!.status : undefined;
  const footerVisible: boolean = 
    status !== OfferProductStatus.ACCEPTED && status !== OfferProductStatus.SENT_EXPIRED; 

  const onRecalculationClick = () => {
    actions.recalculateOffer(offer.lead, offer.configuration.id);
  };

  const isImpactAnalysisHidden = useFeature(FeatureName.HIDE_IMPACT_ANALYSIS).isEnabled && recalculatedAt;

  /*
    @TODO Remove
    T.customerGuidance.purchase.details
    T.customerGuidance.purchase.flatOffer.product
    T.customerGuidance.purchase.flatOffer.label
    T.customerGuidance.purchase.flatOffer.documents.impactAnalysis.name
  */
  return (
    <TooltipHoverable
      label={I18n.t(T.customerGuidance.purchase.flatOffer.expiredTooltip)}
      hoveringDisabled={!isExpired}
    >
      <div className={'c-guide-purchase-flat-offer'}>
        <div className={'c-guide-purchase-flat-offer__product'}>
          <div className="c-guide-purchase-flat-offer__header">
            {offer && isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X)
              ? I18n.t(T.customerGuidance.purchase.flatOffer.header)
              : I18n.t(T.customerGuidance.purchase.flatOffer.headerFlatDirect)
            }
          </div>
          <div className="c-guide-purchase-flat-offer__section c-guide-purchase-flat-offer__section-dates">
            <GuidePurchaseFlatOfferDates
              proposal={proposal}
              sentOfferExpiresAt={sentOfferExpiresAt}
              recalculatedAt={recalculatedAt}
            />
          </div>
            
          <div className={classNames('c-guide-purchase-flat-offer__section c-guide-purchase-flat-offer__section-documents', {
            'c-guide-purchase-flat-offer__section--expired': isExpired,
          })}>
            <GuidePurchaseDocumentDownload
              lead={lead}
              impactAnalysis={impactAnalysis}
              isImpactAnalysisHidden={isImpactAnalysisHidden}
              isExpired={isExpired}
              offer={offer}
              documentKey={documentKey}
              documentUrl={documentUrl}
              documentStatusConfiguration={documentStatusConfiguration}
              documentStatusImpactAnalysis={documentStatusImpactAnalysis}
              startDocumentPolling={actions.startDocumentPolling}
              stopDocumentOfferPolling={actions.stopDocumentOfferPolling}
              regenerateDocument={actions.regenerateDocument}
            />
          </div>
          <div className={classNames('c-guide-purchase-flat-offer__section c-guide-purchase-flat-offer__section-configuration-details', {
            'c-guide-purchase-flat-offer__section--expired': isExpired,
          })}>
            <GuidePurchaseConfigurationDetails 
              proposal={proposal}
            />
          </div>
          
          <button
            onClick={handleToggleTermsModal}
            className={'c-guide-purchase-flat-offer__button'}
            type={'button'}
            disabled={isExpired}
          >
            {I18n.t(T.customerGuidance.purchase.flatOffer.moreInfo)}
          </button>
          {
            isExpired && (
              <div className={'c-guide-purchase-flat-offer__footer'}>
                <Button
                  onClick={onRecalculationClick}
                  type={'button'}
                  theme={ButtonTheme.PRIMARY}
                  size={ButtonSize.PRIMARY}
                  label={I18n.t(T.customerGuidance.purchase.flatOffer.recalculationCTA)}
                />
              </div>
            )
          }
          {
            footerVisible && (
              <div className={'c-guide-purchase-flat-offer__footer'}>
                <Button
                  onClick={onCheckOffer}
                  type={'button'}
                  theme={ButtonTheme.PRIMARY}
                  size={ButtonSize.PRIMARY}
                  label={I18n.t(T.customerGuidance.purchase.step.acceptOffer.offer.cta)}
                />
              </div>
            )
          }
        </div>
      </div>
    </TooltipHoverable>
  );
};

export const GuidePurchaseFlatOffer = connect(mapStateToProps, mapDispatchToProps)(GuidePurchaseFlatOfferComponent);
