import { AnyAction, Dispatch } from 'redux';
import { Persistor } from 'redux-persist/es/types';
import { StateAccessor } from './store.interface';

class StoreProvider {
  dispatch!: Dispatch<AnyAction>;
  private directGetState?: StateAccessor;
  private persistor?: Persistor;

  getState: StateAccessor = () => this.directGetState
    ? this.directGetState()
    : {} as any;

  initialize = (store: { dispatch: Dispatch<AnyAction>, getState: StateAccessor }) => {
    this.directGetState = () => store.getState();
    this.dispatch = action => store.dispatch(action);
  };

  setPersistor = (persistor: Persistor) => this.persistor = persistor;

  flush = () => this.persistor ? this.persistor.flush() : Promise.resolve();
}

export const storeProvider = new StoreProvider();
