import { ContentType, ResponseError } from '+app/shared/network/network.interface';
import { toUrlEncoded } from '+auth/store/auth.helpers';
import { CONFIG } from '+config';
import { customerPortalHeadersProvider } from '+shared/network/network.client';
import { toCamelCase } from '+utils/toCamelCase.util';
import { isNil } from 'lodash';

const sanitizeHeaders = (headers: Record<string, any>): Record<string, string> => {
  for (const key in headers) {
    if (!headers.hasOwnProperty(key)) {
      continue;
    }
    if (isNil(headers[key])) {
      delete headers[key];
    } else {
      headers[key] = headers[key].toString();
    }
  }
  return headers;
};

export const authFetchPostMethod = async <T extends any = any>(
  url: string,
  data?: object,
  options?: RequestInit,
): Promise<T> => {
  const headers = sanitizeHeaders({
    ...customerPortalHeadersProvider(new URL(CONFIG.AUTH.AUTH_URL).hostname),
    'Content-Type': ContentType.URL_ENCODED,
    'Authorization': undefined,
  });
  const body = data && toUrlEncoded(data);
  return fetch(`${CONFIG.AUTH.AUTH_URL}/${url}`, {
    method: 'POST',
    headers,
    body,
    redirect: 'error',
    ...options,
  })
    .then(async res => {
      if (res.ok) {
        return res;
      }
      const resBody = await res.text();
      throw new ResponseError(res, { body, headers, options, resBody });
    })
    .then(res => res.json())
    .then(toCamelCase);
};
