import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { Field } from 'redux-form';

import { AccountAboutMeForm } from '+account/containers/AccountAboutMeForm/AccountAboutMeForm.component';
import { countryOptions } from '+app/constants/countryOptions.constant';
import { PlatformSelector } from '+app/shared/components';
import { withPage } from '+app/shared/containers';
import { Form } from '+legacy/components/Form';
import { FormItemProps, FormSelectProps } from '+legacy/components/Form/elements';

export const AccountAboutMeComponent: React.FC = () => (
  <AccountAboutMeForm pageName={'aboutMe'}>
    <div className={'o-grid'}>
      <div className={'o-grid__column o-grid__column--sm-6 o-grid__column--no-gap'}>
        <Form.Group>
          <Field<FormItemProps>
            dataTestId={'account-about-me-first-name-value'}
            name={'firstName'}
            component={Form.Item}
            label={I18n.t(T.general.labels.firstName)}
            floatingLabel={true}
            disabled={true}
          />
          <Field<FormItemProps>
            dataTestId={'account-about-me-last-name-value'}
            name={'lastName'}
            component={Form.Item}
            label={I18n.t(T.general.labels.lastName)}
            floatingLabel={true}
            disabled={true}
          />
        </Form.Group>
        <Form.Group>
          <Field<FormItemProps>
            dataTestId={'account-about-me-email-value'}
            name={'email'}
            component={Form.Item}
            label={I18n.t(T.general.labels.email)}
            floatingLabel={true}
            disabled={true}
          />
          <Field<FormItemProps>
            dataTestId={'account-about-me-mobile-phone-number-value'}
            name={'mobile'}
            component={Form.Item}
            type={'tel'}
            label={I18n.t(T.general.labels.mobilePhone)}
            floatingLabel={true}
            disabled={true}
          />
        </Form.Group>
        <Form.Group>
          <Field<FormItemProps>
            dataTestId={'account-about-me-street-value'}
            name={'street'}
            component={Form.Item}
            label={I18n.t(T.general.labels.street)}
            floatingLabel={true}
            disabled={true}
          />
          <div className={'c-form__cols'}>
            <div className={'c-form__col-narrow'}>
              <Field<FormItemProps>
              dataTestId={'account-about-me-postal-code-value'}
                name={'postalCode'}
                component={Form.Item}
                label={I18n.t(T.general.labels.postalCode)}
                floatingLabel={true}
                disabled={true}
              />
            </div>
            <div className={'c-form__col-wide'}>
              <Field<FormItemProps>
                dataTestId={'account-about-me-city-value'}
                name={'city'}
                component={Form.Item}
                label={I18n.t(T.general.labels.city)}
                floatingLabel={true}
                disabled={true}
              />
            </div>
          </div>
          <Field<FormSelectProps>
            dataTestId={'account-about-me-country-code'}
            name={'countryCode'}
            component={Form.Select}
            label={I18n.t(T.general.labels.country)}
            placeholder={I18n.t(T.general.placeholders.country)}
            options={countryOptions}
            disabled={true}
          />
        </Form.Group>
      </div>
    </div>
  </AccountAboutMeForm>
);

export const AccountAboutMe: React.FC = () => (
  <PlatformSelector
    web={withPage(AccountAboutMeComponent, { options: { isHeadlineSmallGap: true }})}
    mobile={<AccountAboutMeComponent />}
  />
);
