import { ActionsUnion, createAction } from '+utils/redux';
import { AuthState } from './auth.state';
import { ReverseChannelTokenAttributes } from './types/reverseChannel.interface';

export enum AUTH_ACTIONS {
  REHYDRATED = '[Auth] REHYDRATED',
  REDIRECT_TO_AUTH = '[Auth] REDIRECT_TO_AUTH',
  REDIRECT_TO_SALESFORCE = '[Auth] REDIRECT_TO_SALEFORCE',
  LOGIN = '[Auth] LOGIN',
  AUTHENTICATE = '[Auth] AUTHENTICATE',
  AUTHENTICATE_IMPERSONATOR = '[Auth] AUTHENTICATE_IMPERSONATOR',
  AUTHENTICATE_START = '[Auth] AUTHENTICATE_START',
  AUTHENTICATE_RETRY = '[Auth] AUTHENTICATE_RETRY',
  REFRESH_TOKEN = '[Auth] REFRESH_TOKEN',
  LOGOUT = '[Auth] LOGOUT',
  CLEAR_STORE = '[Auth] CLEAR_STORE',
  REPLACE_ACCESS_TOKEN = '[Auth] REPLACE_ACCESS_TOKEN',
  REPLACE_REFRESH_TOKEN = '[Auth] REPLACE_REFRESH_TOKEN',
  GENERATE_REVERSE_CHANNEL_TOKEN = '[Auth] GENERATE_REVERSE_CHANNEL_TOKEN',
  SAVE_REVERSE_CHANNEL_TOKEN = '[Auth] SAVE_REVERSE_CHANNEL_TOKEN',
  CLEAR_REVERSE_CHANNEL_TOKEN = '[Auth] CLEAR_REVERSE_CHANNEL_TOKEN',
  SAVE_TOKEN_FOR_ACTION = '[Auth] SAVE_TOKEN_FOR_ACTION',
  FORCE_REFRESH = '[Auth] FORCE_REFRESH',
  SET_LAST_ACCESSED_AUTH_URL = '[Auth] SET_LAST_ACCESSED_AUTH_URL',
}

export const AuthActions = {
  rehydrated: () => createAction(AUTH_ACTIONS.REHYDRATED),
  redirectToAuth: (isRetried?: boolean) => createAction(AUTH_ACTIONS.REDIRECT_TO_AUTH, { isRetried }),
  redirectToSalesforce: (userId?: string) =>
    createAction(AUTH_ACTIONS.REDIRECT_TO_SALESFORCE, { userId }),
  logIn: (code: string) =>
    createAction(AUTH_ACTIONS.LOGIN, { code }),
  authenticate: (accessToken: string | undefined, refreshToken: string | undefined, tokenType: string | undefined) =>
    createAction(AUTH_ACTIONS.AUTHENTICATE, { accessToken, refreshToken, tokenType }),
  authenticateStart: () => createAction(AUTH_ACTIONS.AUTHENTICATE_START),
  authenticateRetry: () => createAction(AUTH_ACTIONS.AUTHENTICATE_RETRY),
  authenticateImpersonator: (accessToken: string, tokenType: string, impersonatedUserId: string) =>
    createAction(AUTH_ACTIONS.AUTHENTICATE_IMPERSONATOR, { accessToken, tokenType, impersonatedUserId }),
  refreshToken: (accessToken: string | undefined, refreshToken: string | undefined, tokenType: string | undefined) =>
    createAction(AUTH_ACTIONS.REFRESH_TOKEN, { accessToken, refreshToken, tokenType }),
  logOut: () => createAction(AUTH_ACTIONS.LOGOUT),
  clearStore: (snapshot?: Partial<AuthState>) => createAction(AUTH_ACTIONS.CLEAR_STORE, { snapshot }),
  replaceAccessToken: (accessToken: string) => createAction(AUTH_ACTIONS.REPLACE_ACCESS_TOKEN, { accessToken }),
  replaceRefreshToken: (refreshToken: string) => createAction(AUTH_ACTIONS.REPLACE_REFRESH_TOKEN, { refreshToken }),
  generateReverseChannelToken: () => createAction(AUTH_ACTIONS.GENERATE_REVERSE_CHANNEL_TOKEN),
  clearReverseChannelToken: () => createAction(AUTH_ACTIONS.CLEAR_REVERSE_CHANNEL_TOKEN),
  saveReverseChannelToken: (reverseChannelToken: ReverseChannelTokenAttributes) =>
    createAction(AUTH_ACTIONS.SAVE_REVERSE_CHANNEL_TOKEN, { reverseChannelToken }),
  saveTokenForAction: (token: string) => createAction(AUTH_ACTIONS.SAVE_TOKEN_FOR_ACTION, { token }),
  forceRefresh: () => createAction(AUTH_ACTIONS.FORCE_REFRESH),
  setLastAccessedAuthUrl: (url: string) => createAction(AUTH_ACTIONS.SET_LAST_ACCESSED_AUTH_URL, ({ url })),
};

export type AuthActions = ActionsUnion<typeof AuthActions>;
