import { omit } from 'lodash';
import { Action, AnyAction, Dispatch, Middleware } from 'redux';
import localStorage from 'redux-persist/lib/storage';

import { StoreState } from '+app/store/store.interface';
import { storeProvider } from '+app/store/store.provider';
import { CONFIG } from '+config';
import { MobileStorage } from './mobile.storage';
import { INTEGRATION_ACTIONS, IntegrationActions } from './store/mobile.actions';
import { MobileAppState } from './store/mobile.state';

const Bridge = (window as any).ReactNativeWebView;
export const mobileStorage = Bridge ? new MobileStorage() : localStorage;

export const sendAction = !CONFIG.IS_MOBILE || !Bridge
  ? () => false
  : (action: Action<any>): boolean => {
    Bridge.postMessage(JSON.stringify(action));
    return true;
  };

export const mobileLog = (...args: any) => sendAction(IntegrationActions.console('log', args));

export const shouldRecreate = () => location.search.includes('recreate=true');

const receiveAction = (event: any) => {
  if (!event || !event.data || typeof event.data !== 'string') {
    return;
  }

  let action: any = {};

  try {
    action = JSON.parse(event.data);
  } catch (error) {
    console.log(`An error occurred while parsing event data to an action: ${error.message}, ${event}`);
  }

  if (!action.type) {
    return;
  }

  switch (action.type) {
    case INTEGRATION_ACTIONS.SET_STORAGE:
      (mobileStorage as MobileStorage).setStorage(action.storage);
      break;
    case INTEGRATION_ACTIONS.SEND_CACHED_DATA:
      const { reduxForm: _, ...cacheToStore } = action.data;
      (window as any).webviewCache = cacheToStore;
      storeProvider.dispatch(action as AnyAction);
      break;
    default:
      storeProvider.dispatch(action as AnyAction);
      break;
  }
};

type Transformer = (action: any) => any;
export const noTransform: Transformer = action => action;

export const mobileIntegrationMiddleware = (actionTransformers: Record<string, Transformer>): Middleware =>
  CONFIG.IS_MOBILE
    ? () => (next: Dispatch<AnyAction>) => (action: any) => {
      const transformer = actionTransformers[action.type];
      if (transformer) {
        sendAction(transformer(action));
      }
      return next(action);
    }
    : () => (next: Dispatch<AnyAction>) => (action: any) => next(action);

export const initializeMobileIntegration = () => {
  if (!CONFIG.IS_MOBILE) {
    return;
  }

  if (!Bridge) {
    console.warn('Not running in mobile app.');
    return;
  }

  // Receive events from RN app
  window.addEventListener('message', receiveAction);
  document.addEventListener('message', receiveAction);

  sendAction(IntegrationActions.ready());
};

const deepMergeStates = (...objects: StoreState[]) => objects.reduce((prev, next) => {
  for (const key in next) {
    if (next.hasOwnProperty(key)) {
      prev[key] = Object.assign({}, prev[key], next[key]);
    }
  }
  return prev;
}, {}) as StoreState;

export const mobileIntegrationReducerWrapper = (state: StoreState | undefined, action: AnyAction) => {
  if (!CONFIG.IS_MOBILE) {
    return state;
  }

  if (action.type === INTEGRATION_ACTIONS.SEND_CACHED_DATA && 'reduxStore' in (action as any).data) {
    if ((action.data.reduxStore as StoreState) && state) {
      return deepMergeStates(state, action.data.reduxStore);
    }
  } else if (action.type === INTEGRATION_ACTIONS.SET_APP_STATE && (action as any).state === MobileAppState.INACTIVE) {
    const reducersToOmit = ['sync', 'dashboard', 'i18n'];
    sendAction(IntegrationActions.sendDataToCache('reduxStore', Object.assign({}, omit(state, reducersToOmit) as any)));
  }

  return state;
};
