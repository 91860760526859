import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, PageHeadline } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { isDirty, reduxForm, reset, submit } from 'redux-form';

import { AccountFormAlert } from '+account/components';
import { AccountActions } from '+account/store/account.actions';
import {
  isSetUserDataError,
  isSetUserDataPending,
  isSetUserDataSuccess,
} from '+account/store/account.selectors';
import { SET_USER_DATA_QUERY } from '+account/store/account.state';
import { StoreState } from '+app/store/store.interface';
import { Form } from '+legacy/components/Form';
import { Portal } from '+shared/components';
import { PortalExit } from '+shared/helpers';
import { QueryActions } from '+shared/store/query/query.actions';
import { UserAttributes } from '+shared/store/user/types/user.interface';
import {
  getCurrentUserId,
  getCurrentUserState,
} from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';

const FORM_NAME = 'my-account-form';

const mapStateToProps = (store: StoreState) => ({
  userId: getCurrentUserId(store),
  initialValues: getCurrentUserState(store),
  isFormSubmitting: isSetUserDataPending(store),
  isFormSuccess: isSetUserDataSuccess(store),
  isFormFailure: isSetUserDataError(store),
  isDirty: isDirty(FORM_NAME)(store),
});

const mapDispatchToProps = mapActions({
  setUserData: AccountActions.setUserData,
  resetSetUserDataQuery: QueryActions.init,
  submit,
  reset,
});

type AccountAboutMeFormProps = {
  pageName: string;
  handleSubmit?: () => void;
};

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & AccountAboutMeFormProps
;

interface State {
  isFormAlertVisible: boolean;
}

const saveUserDataHandler = (formData: Record<string, any>, dispatch: () => void, props: Props) => {
  const attributes = {
    ...formData,
  } as UserAttributes;

  props.actions.setUserData(attributes, props.userId);
};

class AccountAboutMeFormComponent extends React.PureComponent<Props, State> {
  hideAlertTimeout?: number;
  switchAlertButtonsDelayTimeout?: number;

  constructor(props: Props) {
    super(props);

    this.state = {
      isFormAlertVisible: false,
    };
  }

  componentDidMount() {
    this.props.actions.resetSetUserDataQuery(SET_USER_DATA_QUERY);
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.isFormSuccess !== prevProps.isFormSuccess) {
      this.hideFormAlert();
    }
  }

  clearTimeouts = () => {
    clearTimeout(this.hideAlertTimeout);
    clearTimeout(this.switchAlertButtonsDelayTimeout);
  };

  handleFormReset = (): void => {
    this.props.actions.reset(FORM_NAME);
  };

  handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.setState({ isFormAlertVisible: true });
    this.props.actions.submit(FORM_NAME);
  };

  hideFormAlert = () => {
    if (!this.props.isFormSuccess) {
      return;
    }

    this.hideAlertTimeout = window.setTimeout(() => {
      this.setState({
        isFormAlertVisible: false,
      });
    }, 1500);

    this.switchAlertButtonsDelayTimeout = window.setTimeout(() => {
      this.props.actions.resetSetUserDataQuery(SET_USER_DATA_QUERY);
    }, 2000);
  };

  render() {
    const { handleSubmit, pageName, children, isFormSubmitting, isFormSuccess, isFormFailure, isDirty } = this.props;
    const { isFormAlertVisible } = this.state;

    return (
      <Form onSubmit={handleSubmit}>
        <div className="o-grid">
          <div className="o-grid__column o-grid__column--lg-6">
            <DefaultParagraph>
              <Translate value={T.myAccountPages[pageName].description} />
            </DefaultParagraph>
          </div>
        </div>
        {children}
        <Portal target={PortalExit.LAYOUT_CONTENT_TOP}>
          {/* // Refactor Form Alert component into Form wrapper when changing to Formik */}
          <AccountFormAlert
            isSubmitting={isFormSubmitting}
            isSuccess={isFormSuccess}
            isFailure={isFormFailure}
            isVisible={isFormAlertVisible || isDirty}
            isDisabled={isFormSuccess}
            handleReset={this.handleFormReset}
            handleSubmit={this.handleFormSubmit}
          />
        </Portal>
      </Form>
    );
  }
}

export const AccountAboutMeForm = connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: FORM_NAME,
  onSubmit: saveUserDataHandler,
  enableReinitialize: true,
  destroyOnUnmount: false,
})(AccountAboutMeFormComponent));
