import { BatteryHelper } from '+battery/store/battery.helpers';
import { Battery } from '+battery/store/types/battery.interface';
import { SpecsItem } from '+components';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { T } from '@sonnen/shared-i18n/customer';
import { Card, CardNotification, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { BatteryIcon } from '../BatteryIcon';

interface Props {
  liveData: SiteLive;
  batteryData: Battery;
}

export const BatteryCardHeater: React.SFC<Props> = ({ liveData, batteryData }) => {
  // TODO: To selectors
  const isHeaterActive = liveData?.online && (liveData.heaterPower || 0) > 0;
  const heaterPower = BatteryHelper.getHeaterPower(liveData);
  const heaterWaterTemperature = BatteryHelper.getHeaterWaterTemperature(liveData);
  const heaterMaxTemperature = BatteryHelper.getHeaterMaxWaterTemperature(batteryData);

  return (
    <Card
      title={I18n.t(T.batterySystem.overview.heater.title)}
      smallTitleGap={true}
      footerContent={(
        <CardNotification
          type={'success'}
          icon={<Icon.Checkmark />}
        >
          {I18n.t(T.batterySystem.notifications.connected)}
        </CardNotification>
      )}
    >
      <SpecsItem
        dataTestId={'sonnen-batterie-overview-accessories-heater-status'}
        title={I18n.t(T.batterySystem.overview.heater.status)}
        value={(
          <BatteryIcon
            icon={<Icon.Heater />}
            label={isHeaterActive
              ? I18n.t(T.batterySystem.statuses.on)
              : I18n.t(T.batterySystem.statuses.off)
            }
            isActive={isHeaterActive}
          />
        )}
      />
      <SpecsItem
        dataTestId={'sonnen-batterie-overview-accessories-heater-power'}
        title={I18n.t(T.batterySystem.overview.heater.power)}
        value={heaterPower}
      />
      <SpecsItem
        dataTestId={'sonnen-batterie-overview-accessories-heater-water-temperature'}
        title={I18n.t(T.batterySystem.overview.heater.boilerTemperature)}
        value={heaterWaterTemperature}
      />
      <SpecsItem
        dataTestId={'sonnen-batterie-overview-accessories-heater-max-temperature'}
        title={I18n.t(T.batterySystem.overview.heater.setTemperature)}
        value={heaterMaxTemperature}
      />
    </Card>
  );
};
