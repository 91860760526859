export const removeElement = (index: number, arr: any[]) => index < 0
  ? arr
  : Array.prototype.concat(
    arr.slice(0, index),
    arr.slice(index + 1, arr.length),
  );

export const arraify = <T extends any>(maybeArray?: T | T[]): T[] =>
  maybeArray !== undefined && maybeArray !== null
    ? Array.isArray(maybeArray) ? maybeArray : [maybeArray]
    : [];

export const getArrayFromEnum = (E: object) =>
  Object.keys(E).filter(key => typeof E[key] !== 'number');

export const getValuesArrayFromEnum = (E: object) =>
  Object.entries(E).map(([key, value]) => value);

export const findElementById = <T extends { id: string }>(array: T[], id: string) =>
  array.find(e => e.id === id);

export const insertIf = <T extends any>(condition: boolean, ...elements: T[]) =>
  condition ? elements : [];

export const searchByKey = (key: string, value: any, inputArray: any, returnValue?: string) => {
  for (const item of inputArray) {
    if (item[key] === value) {
      return returnValue ? item[returnValue] : item;
    }
  }
};

export const filterByValue = <T extends any>(items: T[], key: string) => (value: string) =>
  items
    .filter((item: T) => !!item[key] && item[key].toLowerCase().indexOf(value.toLowerCase()) > -1);
