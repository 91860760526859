import { Icon } from '@sonnen/shared-web';
import * as React from 'react';

import './GuideAcceptancePrefilledLine.component.scss';

interface Props {
  onClick: () => void;
  value: string;
}

export const GuideAcceptancePrefilledLine: React.FC<Props> = ({ value, onClick }) => (
    <div className={'c-guide-acceptance-prefilled-line'}>
      <div>
        {value}
      </div>
      <button onClick={onClick}>
        <Icon.Pen className={'c-guide-acceptance-prefilled-line__edit-icon'} />
      </button>
    </div>
  );
