import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

const getMilesFromKilometres = (kilometres: number) => Math.round(kilometres * 0.62137119);

const getFormattedDistanceObj = (kilometres: number) => {
  const unitAbbreviation = I18n.t(T.general.distanceUnit.abbreviation);
  const miles = getMilesFromKilometres(kilometres);
  let unit = I18n.t(T.general.distanceUnit.plural);

  if (unitAbbreviation === 'km' && (kilometres >= 1 && kilometres < 2)) {
    unit = I18n.t(T.general.distanceUnit.singular);
  }

  if (unitAbbreviation === 'mi.' && (miles >= 1 && miles < 2)) {
    unit = I18n.t(T.general.distanceUnit.singular);
  }

  return (unitAbbreviation === 'km')
    ? { value: kilometres, unit }
    : { value: miles, unit };
};

export const DistanceHelper = {
  getFormattedDistanceObj,
};
