import { EnergyUnit } from '@sonnen/shared-web';

import { formatNumber } from '+app/utils/number.util';
import { CurrencyUnit, OtherUnit, PeakPowerUnit, UnitUtil } from '+app/utils/unit.util';
import { ConfigurationMarket, ConfigurationMeasurement } from '../types/configuration.interface';

const formatVppBonusValue = (vppBonusValue: string | number) => {
  const castedValue = 
    typeof vppBonusValue === 'string'
    ? parseFloat(vppBonusValue)
    : vppBonusValue;
  return formatNumber({ precision: 2 })(castedValue);
};

const formatMeasurementEnergy = (measurement: ConfigurationMeasurement<EnergyUnit>) =>
  UnitUtil.formatEnergy(
    Number(measurement.value) * (measurement.unit === EnergyUnit.KWH ? 1000 : 1),
    measurement.unit,
    0,
  );

const formatMeasurementPeakPower = (measurement: ConfigurationMeasurement<PeakPowerUnit>) =>
  UnitUtil.formatPeakPower(
    Number(measurement.value) * (measurement.unit === PeakPowerUnit.KWP ? 1000 : 1),
    measurement.unit,
    3,
  );

const formatMeasurementPrice = (
  measurement: ConfigurationMeasurement<CurrencyUnit>,
  currency: CurrencyUnit,
) => UnitUtil.formatCurrency(measurement.value, currency);

const formatPricePerKwh = (
  measurement: ConfigurationMeasurement<CurrencyUnit>,
  currency: CurrencyUnit,
  { asMinorUnit } = { asMinorUnit: false },
) => {
  const corrected = asMinorUnit
    ?
      typeof measurement.value === 'string'
      ?
        parseFloat(measurement.value) * 100
      : measurement.value * 100
    : measurement.value;
  return UnitUtil.formatCurrency(corrected, currency, { isMinorUnit: asMinorUnit }) + `/${EnergyUnit.KWH}`;
};

const getCurrencyForMarket = (market: ConfigurationMarket | undefined) => {
  switch (market) {
    case ConfigurationMarket.DE:
      return CurrencyUnit.EUR;
    default:
      return CurrencyUnit.EUR;
  }
};

const formatTaxRate = (rate: ConfigurationMeasurement<OtherUnit.PERCENT>): string => {
  return `${Math.round(Number(rate.value))}`;
};

export const ConfigurationHelper = {
  formatVppBonusValue,
  formatMeasurementEnergy,
  formatMeasurementPeakPower,
  formatMeasurementPrice,
  formatPricePerKwh,
  getCurrencyForMarket,
  formatTaxRate,
};
