import { T } from '@sonnen/shared-i18n/customer';
import { Button, ButtonSize, ButtonTheme, MediaCard } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { StepName } from '+app/+guide/helpers/steps.config';
import { getPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { history } from '+router/store';
import { InfoBox } from '+shared/components';

import './GuideAcceptanceSignFailure.component.scss';

const steps = [
  T.customerGuidance.acceptance.completion.step.first,
  T.customerGuidance.acceptance.completion.step.second,
  T.customerGuidance.acceptance.completion.step.third,
  T.customerGuidance.acceptance.completion.step.fourth,
];

const GuideAcceptanceSignFailureSteps: React.FC = () => (
  <>
    <p className={'c-guide-acceptance-sign-failure__steps-headline'}>
      {I18n.t(T.customerGuidance.acceptance.completion.subtitle)}
    </p>
    {steps.map((text, index) => (
      <div className={'c-guide-acceptance-sign-failure__step'} key={index}>
        <div className={'c-guide-acceptance-sign-failure__step-index'}>
          {`${index + 1}`}
        </div>
        <span className={'c-guide-acceptance-sign-failure__step-text'}>{I18n.t(text)}</span>
      </div>
    ))}
  </>
);

const GuideAcceptanceSignFailureButton: React.FC = () => (
  <div className={'c-guide-acceptance-sign-failure__button'}>
    <Button
      label={I18n.t(T.customerGuidance.acceptance.completion.buttonCta)}
      size={ButtonSize.SECONDARY}
      theme={ButtonTheme.PRIMARY}
      onClick={() => history.push(getPath(RouteName.GUIDE_PURCHASE_STEP, [StepName.FLAT_ORDER_CONFIRMATION]))}
    />
  </div>
);

export const GuideAcceptanceSignFailure: React.FC = () => (
  <div className={'c-guide-acceptance-sign-failure'}>
    <MediaCard
      footerContent={<GuideAcceptanceSignFailureButton />}
    >
      <GuideAcceptanceSignFailureSteps />
      <div className={'o-grid'}>
        <div className={'o-grid__column o-grid__column--md-6 o-grid__column--no-gap'}>
          <InfoBox
            description={I18n.t(T.customerGuidance.acceptance.completion.infobox)}
          />
        </div>
      </div>
    </MediaCard>
  </div>
);
