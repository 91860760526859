import { T } from '@sonnen/shared-i18n/customer';
import { convertTemperature, TemperatureUnit } from '@sonnen/shared-web';
import { round } from 'lodash';
import { isNumber } from 'lodash';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';

export const formatTemperature = (
  { temperature, unit = TemperatureUnit.K, precision = 0 }:
  { temperature: number, unit: TemperatureUnit, precision: number },
) => {
  const targetUnit = I18n.t(T.general.temperatureUnit) as TemperatureUnit;
  const normalizedTemperature = convertTemperature({
    temperature,
    from: unit,
    to: targetUnit,
  });
  return `${round(normalizedTemperature, precision)} °${targetUnit}`;
};

export const formatDate = (value: number | moment.MomentInput, customFormat?: string): string =>
  (isNumber(value) ? moment.unix(value) : moment(value)).format(customFormat || 'L');
