import { Query } from '+query/query.state';
import { Taxation } from './types/taxation.interface';

export const GET_TAXATION_DATA_QUERY = 'getTaxationDataQuery';
export const SEND_SUPPORT_MESSAGE_QUERY = 'sendSupportMessageQuery';

export interface HelpState {
  taxationData: Taxation;
  [GET_TAXATION_DATA_QUERY]: Query<boolean>;
  [SEND_SUPPORT_MESSAGE_QUERY]: Query<boolean>;
}

export const initialState = {
  taxationData: {
    id: '',
    type: '',
    self: '',
  },
  [GET_TAXATION_DATA_QUERY]: {},
  [SEND_SUPPORT_MESSAGE_QUERY]: {},
};
