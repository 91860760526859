import * as React from 'react';
import { AnalysisContext } from './AnalysisProvider.context';
import { AnalysisProviderHelper } from './AnalysisProvider.helper';
import { AnalysisProviderProps } from './AnalysisProvider.types';

export const AnalysisProvider: React.FC<AnalysisProviderProps> = (props) => {
  const [isDelayMessageVisible, setDelayMessageVisibility] = React.useState(false);

  React.useEffect(() => {
    const {
      batteryData,
      hasMeasurements,
      timestamp,
      selectedDate,
    } = props.commonData;
    const { measurementsQueryStatus } = props.query;

    const isDelay = AnalysisProviderHelper.isDelayMessageVisible({
      batteryData,
      hasMeasurements,
      timestamp,
      measurementsQueryStatus,
      selectedDate,
    });

    setDelayMessageVisibility(isDelay);
  }, [
    props.commonData.batteryData,
    props.commonData.hasMeasurements,
    props.commonData.timestamp,
    props.commonData.selectedDate,
    props.query.measurementsQueryStatus,
  ]);

  return (
    <AnalysisContext.Provider value={{
      ...props,
      commonData: {
        ...props.commonData,
        isDelayMessageVisible,
      },
    }}>
      {props.children}
    </AnalysisContext.Provider>
  );
};
