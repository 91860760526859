import { BackupDeviceType, Battery, BatteryControllerType } from '+app/+dashboard/+battery/store/types/battery.interface';

interface BatteryControllerTypeMock {
  spree: Battery;
  eaton: Battery;
}

export const BATTERY_TIMEZONE = 'Europe/Berlin';

export const BATTERY_DATA_MOCK: Battery = {
  assetStatus: 'instaled',
  id: 'fakeBatteryId',
  type: 'battery-systems',
  self: '',
  serialNumber: '12345',
  product: {
    id: 'fakeBatteryProductId',
    type: '',
    self: '',
    batteryWithDisplay: false,
    cellTechnology: '',
    color: '',
    family: '',
    hardwareGenaration: '',
    hardwareGeneration: '',
    hardwareVersion: '10.0',
    inverterType: '',
    name: '',
    productCode: '',
    productLine: '',
    quoteNameDe: 'sonnenBatterie',
    quoteNameEn: '',
    salesforceProductId: '',
  },
  installationStreet: 'SonnenStrom Plus',
  installationPostalCode: '12345',
  batteryCapacity: 33000,
  batteryModules: 6,
  batteryInverterDesignPower: 4600,
  batteryChargeCycles: 74,
  controllerType: BatteryControllerType.SPREE,
  backupPowerBuffer: 20.0,
  backupDeviceType: BackupDeviceType.PROTECT,
  heaterConnectionStatus: false,
  heaterMaxTemperature: 0,
  backupNominalPower: 4000,
  lastPowerOutageAt: new Date(2017, 6, 1).toISOString(),
  timeZone: BATTERY_TIMEZONE,
  cellType: null,
  pvGridFeedInLimit: 100,
  pvPeakPower: 7430,
  
};

export const BATTERY_CONTROLLER_TYPE_MOCK: BatteryControllerTypeMock = {
  spree: {
    ...BATTERY_DATA_MOCK,
    controllerType: BatteryControllerType.SPREE,
  },
  eaton: {
    ...BATTERY_DATA_MOCK,
    controllerType: BatteryControllerType.EATON,
  },
};
