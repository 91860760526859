import * as React from 'react';

import { Battery } from '+app/+dashboard/+battery/store/types/battery.interface';
import { DemoHelper } from '+app/+demo/helpers/demo.helper';
import { PlatformSelector } from '+app/shared/components';
import { SiteLive } from '+app/shared/store/live/types/siteLiveData.interface';
import { storeProvider } from '+app/store/store.provider';
import { Locale } from '+legacy/helpers/i18n.helper';
import { BATTERY_DATA_MOCK, LIVE_DATA_MOCK } from '+mocks/index';
import { DemoBatteryOverviewMobile } from './DemoBatteryOverview.mobile.component';
import { DemoBatteryOverviewWeb } from './DemoBatteryOverview.web.component';

export type DemoBatteryOverviewProps = {
  liveData: SiteLive;
  batteryData: Battery;
  isAccessoriesCardAvailable: boolean;
};

export const DemoBatteryOverview: React.FC = () => {
  const isAccessoriesCardAvailable = DemoHelper.isDemoUserCountry(Locale.DE)(storeProvider.getState());

  return (
    <PlatformSelector
      web={(
        <DemoBatteryOverviewWeb
          liveData={LIVE_DATA_MOCK}
          batteryData={BATTERY_DATA_MOCK}
          isAccessoriesCardAvailable={isAccessoriesCardAvailable}
        />
      )}
      mobile={(
        <DemoBatteryOverviewMobile
          liveData={LIVE_DATA_MOCK}
          batteryData={BATTERY_DATA_MOCK}
          isAccessoriesCardAvailable={isAccessoriesCardAvailable}
        />
      )}
    />
  );
};
