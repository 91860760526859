import { DefaultParagraph, EnergyUnit, Icon } from '@sonnen/shared-web';
import { IconType } from '@sonnen/shared-web/src/components/Icon/models';
import * as classNames from 'classnames';
import * as React from 'react';

import './InfographicValue.component.scss';

interface Props {
  title?: string;
  icon?: IconType;
  value?: number | string;
  unit?: EnergyUnit | string;
  description?: string | React.ReactNode;
  isThemeInactive?: boolean;
}

const getIcon = (icon: IconType | undefined) => icon
  ? Icon[icon]
  : null;

export const InfographicValue: React.FC<Props> = ({
  title,
  icon,
  value,
  unit,
  description,
  isThemeInactive,
 }) => {
  const InfographicIcon = getIcon(icon);

  return (
    <div className={'c-infographic-value'}>
      {title && (
        <p className={'c-infographic-value__title'}>
          {title}
        </p>
       )}
      <div className={'c-infographic-value__content'}>
        {InfographicIcon && (
          <InfographicIcon className={'c-infographic-value__icon'} />
        )}
        {value !== undefined && (
          <div className={classNames('c-infographic-value__value', {
            'c-infographic-value__value--theme-inactive': isThemeInactive,
          })}>
            {value}
          </div>
        )}
        {unit && (
          <div className={'c-infographic-value__unit'}>
            {unit}
          </div>
        )}
      </div>
      {description && (
        <div className={'c-infographic-value__description'}>
          <DefaultParagraph>
            {description}
          </DefaultParagraph>
        </div>
      )}
    </div>
  );
};
