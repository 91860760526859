import { Card } from '@sonnen/shared-web';
import * as React from 'react';

import { AnalysisEnergyFlow } from '+app/+dashboard/+analysis/components';
import { useAnalysis } from '+app/+dashboard/+analysis/containers/AnalysisProvider/AnalysisProvider.context';
import { DemoAnalysisEnergyFlowHeader } from '../DemoAnalysisEnergyFlowHeader';

import './DemoAnalysisEnergyFlowCard.component.scss';

interface Props {
  isHeaderVisible?: boolean;
}

export const DemoAnalysisEnergyFlowCard: React.FC<Props> = (props) => {
  const { commonData: { selectedDataSeriesKeys } } = useAnalysis();

  return (
    <Card
      header={props.isHeaderVisible &&
        <DemoAnalysisEnergyFlowHeader 
          selectedDataSeriesKeys={selectedDataSeriesKeys}
        />
      }
    >
      <div className={'c-demo-analysis-energy-flow-card'}>
        <AnalysisEnergyFlow />
      </div>
    </Card>
  );
};
