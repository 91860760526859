import { Configuration } from '+app/+guide/store/types/configuration.interface';
import { HouseType } from '+app/+guide/store/types/energyProvider.interface';
import * as Yup from 'yup';
import {
  desiredDeliveryFormInitial,
  UtilityDataDesiredDeliveryDateSchema,
} from '../../components/GuideAcceptanceDesiredDeliveryDate/GuideAcceptanceDesiredDeliveryDate.helper';
import {
  meterIdFormInitial,
  UtilityDataMeterIdDateSchema,
} from '../../components/GuideAcceptanceUtilityMeterId/GuideAcceptanceUtilityMeterId.helper';
import {
  configurationConsumptionFormInitial,
  UtilityChangePeriod,
} from '../../components/GuideAcceptanceUtilityMoveHouse/GuideAcceptanceUtilityMoveHouse.helper';
import {
  movingDetailsFormInitial,
  UtilityMovingDetailsSchema,
} from '../../components/GuideAcceptanceUtilityMovingDetails/GuideAcceptanceUtilityMovingDetails.helper';
import { pvSystemKeysFormInitial, UtilityDataPvSystemKeysSchema } from '../../components/GuideAcceptanceUtilityPvSystemKeys/GuideAcceptanceUtilityPvSystemKeys.helper';
import { terminationRightFormInitial } from '../../components/GuideAcceptanceUtilityTermination/GuideAcceptanceUtilityTermination.helper';
import {
  oldProviderInitial,
  UtilityOldProviderSchema,
} from '../../containers/GuideAcceptanceOldProvider/GuideAcceptanceOldProvider.helper';

export const formFields = {
  IS_MOVING_HOUSES: 'isMovingHouses',
  DESIRED_DELIVERY_START_CLOSEST: 'desiredDeliveryStartClosest',
  DESIRED_DELIVERY_START_DATE: 'desiredDeliveryStartDate',
  HAS_RIGHT_OF_TERMINATION: 'hasRightOfTermination',
  METER_ID: 'meterId',
  IS_METER_ID_NOT_KNOWN: 'isMeterIdNotKnown',
  PROVIDER_ID: 'providerId',
  MOVING_DATE: 'movingDate',
  HOUSE_TYPE: 'houseType',
  PV_SYSTEM_KEY_ONE: 'pvSystemKeyOne',
  PV_SYSTEM_KEY_TWO: 'pvSystemKeyTwo',
} as const;

export interface UtilityDataMoveHousesForm {
  isMovingHouses: UtilityChangePeriod;
}

export interface UtilityDataDesiredDeliveryForm {
  desiredDeliveryStartClosest: boolean;
  desiredDeliveryStartDate: Date | null;
  isDateChosen: boolean;
}

export interface UtilityDataMovingDetailsForm {
  movingDate: Date | undefined;
  houseType: HouseType | undefined;
}

export interface UtilityDataTerminationRightForm {
  hasRightOfTermination: boolean;
}

export interface UtilityDataOldProvider {
  providerId: string;
}

export interface UtilityDataMeterId {
  meterId: string;
  isMeterIdNotKnown: boolean;
}

export interface UtilityDataPvSystemKeys {
  pvSystemKeyOne: string;
  pvSystemKeyTwo: string;
}

export type UtilityDataForm =
  & UtilityDataMoveHousesForm
  & UtilityDataDesiredDeliveryForm
  & UtilityDataMovingDetailsForm
  & UtilityDataTerminationRightForm
  & UtilityDataOldProvider
  & UtilityDataMeterId
  & UtilityDataPvSystemKeys;

export const UtilityDataFormInitial: UtilityDataForm = {
  ...configurationConsumptionFormInitial,
  ...desiredDeliveryFormInitial,
  ...movingDetailsFormInitial,
  ...terminationRightFormInitial,
  ...oldProviderInitial,
  ...meterIdFormInitial,
  ...pvSystemKeysFormInitial,
};

export const UtilityDataSchema = (configuration: Configuration) => Yup.object()
  .concat(UtilityDataDesiredDeliveryDateSchema)
  .concat(UtilityDataMeterIdDateSchema)
  .concat(UtilityOldProviderSchema)
  .concat(UtilityMovingDetailsSchema)
  .concat(UtilityDataPvSystemKeysSchema(configuration));
