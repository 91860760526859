import { T } from '@sonnen/shared-i18n/customer';
import { EnergyFlowSeriesKey, Legend } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { getLegendDataSeries } from '+app/+dashboard/+analysis/store/analysis.helpers';
import { MeasurementMethod } from '+app/shared/store/live/types/siteLiveData.interface';

import './DemoAnalysisEnergyFlowHeader.component.scss';

interface Props {
  selectedDataSeriesKeys: EnergyFlowSeriesKey[];
}

export const DemoAnalysisEnergyFlowHeader: React.FC<Props> = ({ selectedDataSeriesKeys }) => (
  <div className={'c-demo-analysis-energy-flow-header'}>
    {I18n.t(T.demo.analysis.day.header)}
    <Legend dataSeries={getLegendDataSeries(
      selectedDataSeriesKeys,
      MeasurementMethod.BATTERY,
    )}/>
  </div>
);
