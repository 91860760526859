import { TimeHelper } from '@sonnen/shared-web';
import * as React from 'react';

import { getFormattedTemperature } from '+legacy/helpers/temperature';
import { ActivityRange } from '../../store/types/activityRange.interface';

export const factorizeActivityRange = (
  activityRanges: ActivityRange[],
  timestamp?: number,
) => {
  const range = activityRanges.find(range =>
    !!(timestamp && range.endTime && timestamp >= range.startTime && timestamp <= range.endTime));

  return range && range.endTime && (
    <>
      {TimeHelper.getTimeFromUnixDate(range.startTime)}
      {range.startTemperature && (
        <>
          {` (${getFormattedTemperature(range.startTemperature)})`}
        </>
      )}
      {range.endTime && (
        <>
          {` – ${TimeHelper.getTimeFromUnixDate(range.endTime)}`}
        </>
      )}
      {range.endTemperature && (
        <>
          {` (${getFormattedTemperature(range.endTemperature)})`}
        </>
      )}
    </>
  );
};
