import { StoreType } from '+app/store/store.interface';
import { storeProvider } from '+app/store/store.provider';
import { momentLocales } from '+app/utils/dynamicImport/moment.helper';
import { numbroLanguages } from '+app/utils/dynamicImport/numbro.helper';
import { history } from '+router/store/index';
import { locales } from '@sonnen/shared-i18n/customer';
import { LanguageSelectItem } from '@sonnen/shared-web';
import * as moment from 'moment';
import numbro from 'numbro';
import { I18n, loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import flagGermany from '../../images/flags/germany.svg';
import flagItaly from '../../images/flags/italy.svg';
import flagUK from '../../images/flags/uk.svg';
import flagUSA from '../../images/flags/usa.svg';
import { localStorage } from './storage';

export enum Locale {
  EN_GB = 'en-gb',
  EN_US = 'en-us',
  DE = 'de',
  IT = 'it',
}

const numbroLanguageTags = {
  [Locale.EN_GB]: 'en-GB',
  [Locale.EN_US]: 'en-US',
  [Locale.DE]: 'de-DE',
  [Locale.IT]: 'it-IT',
};

const germanLanguageSettings: numbro.NumbroLanguage = numbroLanguages[numbroLanguageTags[Locale.DE]]();
const italianLanguageSettings: numbro.NumbroLanguage = numbroLanguages[numbroLanguageTags[Locale.IT]]();

germanLanguageSettings.delimiters = {
  thousands: ' ',
  decimal: ',',
};

italianLanguageSettings.delimiters = {
  thousands: ' ',
  decimal: ',',
};

export const LANGUAGE_CODES = {
  'en-gb': {
    label: 'English (UK)',
    code: 'en-gb',
    icon: 'uk.svg',
    delimeters: {
      decimal: '.',
      thousands: ',',
    },
  },
  'en-us': {
    label: 'English (US)',
    code: 'en-us',
    icon: 'usa.svg',
    delimeters: {
      decimal: '.',
      thousands: ',',
    },
  },
  de: {
    label: 'Deutsch',
    code: 'de',
    icon: 'germany.svg',
    delimeters: {
      decimal: ',',
      thousands: '',
    },
  },
  it: {
    label: 'Italiano',
    code: 'it',
    icon: 'italy.svg',
    delimeters: {
      decimal: ',',
      thousands: '',
    },
  }};

export const LANGUAGES: LanguageSelectItem[] = [
  {
    locale: Locale.EN_GB,
    name: 'English (UK)',
    icon: flagUK,
  },
  {
    locale: Locale.EN_US,
    name: 'English (US)',
    icon: flagUSA,
  },
  {
    locale: Locale.DE,
    name: 'Deutsch',
    icon: flagGermany,
  },
  {
    locale: Locale.IT,
    name: 'Italiano',
    icon: flagItaly,
  },
];

export const setLanguageCode = async (languageCode: string): Promise<string> => {
  const query = new URLSearchParams(history.location.search);

  query.set('lang', languageCode);
  history.replace({ ...history.location, search: query.toString() });

  if (localStorage) {
    await localStorage.setItem('languageCode', languageCode);
  }

  return languageCode;
};

export const updateUrlLanguageQuery = async (lang?: string): Promise<void> => {
  const languageCode = lang || await localStorage?.getItem('languageCode');

  if (languageCode) {
    const query = new URLSearchParams(history.location.search);
    query.set('lang', languageCode);
    const url = document.location.origin + history.location.pathname + '?' + query.toString();
    window.history.replaceState({}, '', url);
  }
};

const getDefaultLang = () => {
  const navigatorLanguageCode = navigator.language;
  const usLanguageFallbackCountries = ['en-CA'];

  return usLanguageFallbackCountries.includes(navigatorLanguageCode)
    ? Locale.EN_US
    : Locale.EN_GB;
};

const getMatchingLanguage = (languageCode: string | undefined) => {
  if (!languageCode) {
    return getDefaultLang();
  }

  // NOTE: locales from i18n has different keys names, for instance German lang has 'de',
  // but English variations has for example 'en-us' or 'en-gb' etc.
  switch (true) {
    case !!locales[languageCode.toLocaleLowerCase()]:
      return languageCode.toLocaleLowerCase();
    case !!locales[languageCode.split('-')[0]]:
      return languageCode.split('-')[0];
    default:
      return getDefaultLang();
  }
};

export const getLanguageCode = async (): Promise<string> => {
  const hostname = window.location.hostname;
  const searchLanguageCode = new URLSearchParams(window.location.search).get('lang');
  const domainLanguageCode = !hostname.match(/localhost/gi)
    ? hostname.split('.').pop()
    : undefined;
  const storageLanguageCode = await localStorage.getItem('languageCode');
  // @NOTE: Desktop navigator language has different format than the one used on iOS
  // Desktop format (ISO 639-1): 'en', 'de', 'it'
  // Mobile format (ISO 639-1 + ISO 3166-1 alpha-2 code country tag): 'en-US', 'de-DE', 'it-IT'
  // This might require even more effort to handle properly in the future
  // with languages that have multiple dialects or specific regional settings like custom calendar type and such
  // e.g. 'az-Arab-IR', 'he-IL-u-ca-hebrew-tz-jeruslm' etc.
  // See https://en.wikipedia.org/wiki/IETF_language_tag for more info on that

  const languageCode =
    searchLanguageCode
    || storageLanguageCode
    || navigator.language
    || domainLanguageCode;

  return getMatchingLanguage(languageCode);
};

(I18n as any).setHandleMissingTranslation((key: string, replacements: any) => {
  try {
    const defaultLanguageTranslation = (I18n as any)._fetchTranslation(
      (I18n as any)._translations,
      `${getDefaultLang()}.${key}`,
      replacements.count,
    );
    return (I18n as any)._replace(defaultLanguageTranslation, replacements);
  } catch (_) {
    return key;
  }
});

export const provideLocale = () =>
  storeProvider.getState().i18n.locale;

export const initMomentLocale = async (languageCode: string) => {
  const lang = languageCode in momentLocales ? languageCode : Locale.EN_GB;
  const importMomentModule = momentLocales[lang];

  await importMomentModule();
  moment.locale(lang);
};

// This initializes everything, now we're ready to use the translations
// Locales contain arrays, hence we have to type-cast them to any
export const initializeI18n = async (store: StoreType) => {
  syncTranslationWithStore(store);
  loadTranslations(locales as any)(store.dispatch);
  const languageCode = await getLanguageCode();
  setLocale(languageCode)(store.dispatch);

  await initMomentLocale(languageCode);

  // number formatting for different languages
  const numbroLanguageTag = numbroLanguageTags[languageCode]
    ? numbroLanguageTags[languageCode]
    : numbroLanguageTags[Locale.EN_US];

  if (numbroLanguages[numbroLanguageTag]) {
    const numbroLanguage = await numbroLanguages[numbroLanguageTag]();
    numbro.registerLanguage(numbroLanguage);
  }

  numbro.setLanguage(numbroLanguageTag);
};
