import { T } from '@sonnen/shared-i18n/customer';
import { Carousel } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { CommunityActions } from '+app/+dashboard/+community/store';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { getCommunityContentHubArticles } from '+community/store/community.selectors';
import { CommunityContentHubArticle } from '+community/store/types/community.interface';
import { ArticleSlide } from '../../../../components/slides/ArticleSlide';
import { getCommunityContentHubArticleUrl } from './CommunityCarousel.helper';

import './CommunityCarousel.component.scss';

interface OwnProps {
  carouselSettings: Record<string, any>;
  hasFullWidth?: boolean;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  ;

const mapStateToProps = (state: StoreState) => ({
  articles: getCommunityContentHubArticles(state),
});

const mapDispatchToProps = mapActions({
  getArticles: CommunityActions.getCommunityContentHubArticles,
});

export const CommunityCarouselComponent: React.FC<Props> = ({
  actions,
  articles,
  carouselSettings,
  hasFullWidth,
}) => {
  React.useEffect(() => {
    actions.getArticles();
  }, []);

  return (
    <Carousel
      className={classNames({
        'community-carousel--full': hasFullWidth,
      })}
      { ...carouselSettings }
    >
      {articles.map((article: CommunityContentHubArticle) => (
        <ArticleSlide
          title={article.title}
          date={article.date}
          description={article.description}
          imageAlt={article.imageAlt}
          imageSrc={article.imageSrc}
          linkSrc={getCommunityContentHubArticleUrl(article.link)}
          linkLabel={I18n.t(T.sonnenCommunity.articleLinkLabel)}
          key={`${article.link}-${article.date}`}
        />
      ))}
    </Carousel>
  );
};

export const CommunityCarousel = connect(mapStateToProps, mapDispatchToProps)(CommunityCarouselComponent);
