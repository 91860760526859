export interface LayoutState {
  isMobileNavOpen: boolean;
  isSiteSwitcherOpen: boolean;
  isLayoutScrollingDisabled: boolean;
}

export const initialState: LayoutState = {
  isMobileNavOpen: false,
  isSiteSwitcherOpen: false,
  isLayoutScrollingDisabled: false,
};
