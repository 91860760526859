import { Configuration, ConfigurationAttributes, Offer, OfferProductType, ProductType } from '+app/+guide/store/types';
import { UtilityChangePeriod } from '+guide/+acceptance/components';
import { isEmpty } from 'lodash';
import { getPvSystemName, SystemKeyNumber } from '../components/GuideAcceptanceUtilityPvSystemKeys/GuideAcceptanceUtilityPvSystemKeys.helper';
import { UtilityDataForm } from '../containers';

export const mapUtilityAttributesToDto = ({
  isMovingHouses,
  desiredDeliveryStartDate,
  hasRightOfTermination,
  meterId,
  movingDate,
  houseType,
  desiredDeliveryStartClosest,
}: UtilityDataForm) =>
  isMovingHouses === UtilityChangePeriod.NOT_MOVED
    ? ({
      period: isMovingHouses,
      desired_delivery_start_date: desiredDeliveryStartDate || undefined,
      desiredDeliveryStartDateClosestPossible: desiredDeliveryStartClosest,
      immediate_termination_possible: hasRightOfTermination,
      meter_id: meterId,
    })
    : ({
      period: isMovingHouses,
      moving_date: movingDate,
      house_type: houseType,
      meter_id: meterId,
    });

export const mapOfferRelationshipToDto = (offerId: string) => ({
  type: 'offers',
  name: 'offer',
  uuid: offerId,
});

export const mapProviderAttributesToDto = ({ providerId }: UtilityDataForm) => ({
  type: 'energy-providers',
  name: 'energy_provider',
  uuid: providerId,
});

export const isFlatDirectConfiguration = (configuration: ConfigurationAttributes | undefined) =>
  !!configuration &&
  configuration.product === ProductType.FLATDIRECT;

export const getProductForStatusChange = (offer: Offer) => {
  const flatProduct = offer.products.find(product =>
    [OfferProductType.FLAT_X, OfferProductType.FLAT_DIRECT].includes(product.productType),
  );

  return flatProduct;
};

export const isSpecificFlatProductInOffer = (offer: Offer, productType: OfferProductType): boolean => {
  const flatProduct = offer.products.find(product =>
    productType === product.productType,
  );

  return !!flatProduct;
};

/**
 * NOTE: https://github.com/nosir/cleave.js/ currently does not support prefix manipulation
 *       in order to omit that issue, the pv system key input strips the "E" prefix to allow
 *       omitting the optional pv system key. This has to be fixed either by finding a suitable
 *       library for the problem or creating our own one which supports React & Formik.
 */
export const mapPvSystemsToDto = (
  { pvSystemKeyOne, pvSystemKeyTwo }: UtilityDataForm, configuration: Configuration) => ({
    photovoltaic_system: [
      {
        system_name: getPvSystemName(SystemKeyNumber.PV_SYSTEM_ONE, configuration),
        ...(!isEmpty(pvSystemKeyOne) && { system_key: `E${pvSystemKeyOne}` }),
      },
      {
        system_name: getPvSystemName(SystemKeyNumber.PV_SYSTEM_TWO, configuration),
        ...(!isEmpty(pvSystemKeyTwo) && { system_key: `E${pvSystemKeyTwo}` }),
      },
    ],
  });

export const mapSinglePvSystemToDto = ({ pvSystemKeyOne }: UtilityDataForm) => ({
  photovoltaic_system: {
    ...(!isEmpty(pvSystemKeyOne) && { system_key: `E${pvSystemKeyOne}` }),
  },
});
