import * as React from 'react';
import { EnergyValue, ErrorStatus, Hertz } from '../../../shared/utils.types';
import { withFrequencyRoutine } from './withFrequencyRoutine';

export interface FrequencyResponse {
  loading: boolean;
  data: Array<EnergyValue<Hertz>> | null;
  invalid: boolean;
}

interface Props {
  children: (response: FrequencyResponse) => React.ReactElement<any>;
  frequencies: Array<EnergyValue<Hertz>>;
  status: ErrorStatus;
  loading: boolean;
}

export const FrequencyComponent: React.SFC<Props> = ({
  children,
  status,
  frequencies,
  loading,
}) => {
  const data = !loading && !status ? frequencies : [];

  const response: FrequencyResponse = { loading: status ? false : loading, data, invalid: !!status };

  return children(response);
};

export const Frequency = withFrequencyRoutine<any>(FrequencyComponent);
