import * as classNames from 'classnames';
import * as React from 'react';

import './EFLine.component.scss';

type EFLineType =
  | 'battery-grid'
  | 'battery-consumption'
  | 'production-consumption'
  | 'production-grid'
  | 'production-battery'
  | 'grid-consumption'
  ;

interface Props {
  type: EFLineType;
  isReverse?: boolean;
  isActive?: boolean;
}

export const EFLine: React.FC<Props> = ({ type, isActive, isReverse }) => {
  const chartLineClasses = classNames('c-ef-line', `c-ef-line--${type}`, {
    'is-flowing-normal': isActive,
    'is-flowing-reverse-normal': isActive && isReverse,
  });

  return (
    <div className={chartLineClasses}>
      <span className={'c-ef-line__dot'} />
      <span className={'c-ef-line__dot'} />
      <span className={'c-ef-line__dot'} />
    </div>
  );
};
