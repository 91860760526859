import { ProfileAttributes, ProfileFormErrors, ProfileFormFields, ProfileRequestImage } from '+account/store/types/profile.interface';
import { validate } from '+shared/helpers';
import { forEach, isEmpty } from 'lodash';

export const validateField = (fieldName: string, fieldValue: string) => {
  let errorMessage: string | undefined;

  switch (fieldName) {
    case ProfileFormFields.NICKNAME:
      errorMessage = validate(fieldValue, {
        minLength: 3,
        maxLength: 30,
      });

      return errorMessage;
    case ProfileFormFields.DESCRIPTION:
      errorMessage = validate(fieldValue, {
        maxLength: 120,
      });

      return errorMessage;
    default: return undefined;
  }
};

export const isFormValid = (formErrors: ProfileFormErrors) => {
  const errors: string[] = [];

  forEach(formErrors, (fieldError) => {
    if (fieldError !== undefined) {
      errors.push(fieldError);
    }
  });

  return errors.length === 0;
};

export const isAccountProfileAvailable = (accountProfile: ProfileAttributes | undefined) => !isEmpty(accountProfile);

export const isAvatarChanged = (image: string | null, accountProfile: ProfileAttributes | undefined ) =>
  isAccountProfileAvailable(accountProfile) && (image !== accountProfile!.image);

export const getRequestReadyImage = (image: string | null): ProfileRequestImage | null =>
  image === null
    ? null
    : {
      encoded_image: getBase64WithoutFormatPrefix(image),
      image_format: 'png',
    };

export const getBase64WithoutFormatPrefix = (image: string) => image.replace('data:image/png;base64,', '""');
