import { T } from '@sonnen/shared-i18n/customer';
import {
  Badge,
  BadgeOutline,
  BadgeTheme,
  Drawer,
  Icon,
  InfoBanner,
  Loader,
  MediaQuery,
} from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { NotificationProps } from '+app/+guide/+notifications/containers/GuideNotifications/GuideNotifications.helper';
import {
  HeaderDropdown,
  IconBadge,
  Notification,
} from '+shared/components';

import './NotificationFeed.component.scss';

interface Props {
  notificationsList: NotificationProps[];
  isLoading: boolean;
}

const renderNotifications = (list: NotificationProps[], isLoading: boolean, onItemClick: () => void) =>
  !isLoading ? (
    list.length > 0 ? (
      list.map(({title, icon, label, description, onClick, date}) => (
        <Notification
          key={`notification-${title}`}
          icon={icon}
          type={label}
          title={title}
          subtitle={description}
          action={() => {
            onItemClick();
            onClick();
          }}
          date={date}
        />
      ))
    ) : (
      <div className={'c-notification-feed__info-banner'}>
        <div className={'c-notification-feed__info-banner-inner'}>
          <InfoBanner
            icon={<Icon.DocumentUfo />}
            title={I18n.t(T.customerGuidance.notifications.empty.title)}
            subtitle={I18n.t(T.customerGuidance.notifications.empty.subtitle)}
          />
        </div>
      </div>
    )
  ) : (
    <div className={'c-notification-feed__loader'}>
      <Loader />
    </div>
  );

const renderBadge = (count: number) => (
  <div className={'c-notification-feed__badge'}>
    <Icon.Bell />
    <Badge
      value={count}
      max={9}
      outline={BadgeOutline.DARK}
      theme={BadgeTheme.IMPORTANT}
    />
  </div>
);

export const NotificationFeed: React.FC<Props> = ({ notificationsList, isLoading }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div className={'c-notification-feed'}>
      <Media query={MediaQuery.UP_NAV}>
        {(isDesktop: boolean) => isDesktop ? (
          <HeaderDropdown
            icon={renderBadge(isLoading ? 0 : notificationsList.length)}
            isOpen={isOpen}
          >
            <div className={'c-notification-feed__content'}>
              <p className={'c-notification-feed__title'}>
                {I18n.t(T.customerGuidance.notifications.title)}
              </p>
              <div className={'c-notification-feed__scrollable-container'}>
                {renderNotifications(notificationsList, isLoading, () => setIsOpen(false))}
              </div>
            </div>
          </HeaderDropdown>
        ) : (
          <>
            <button
              onClick={() => setIsOpen(!isOpen)}
              type={'button'}
            >
              <IconBadge icon={renderBadge(isLoading ? 0 : notificationsList.length)} />
            </button>
            <Drawer
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              header={I18n.t(T.customerGuidance.notifications.title)}
            >
              {renderNotifications(notificationsList, isLoading, () => setIsOpen(false))}
            </Drawer>
          </>
        )}
      </Media>
    </div>
  );
};
