import * as React from 'react';

import { useInfographic } from '+dashboard/+infographic';
import { InfographicHelper } from '+dashboard/+infographic';
import { InfographicCardElement } from '+dashboard/+infographic';
import { InfographicBatteryCard } from '../InfographicBatteryCard/InfographicBatteryCard.component';
import { InfographicChargerCard } from '../InfographicChargerCard/InfographicChargerCard.component';
import { InfographicCommunityCard } from '../InfographicCommunityCard/InfographicCommunityCard.component';
import {
  InfographicConsumptionCard,
} from '../InfographicConsumptionCard/InfographicConsumptionCard.component';
import { InfographicContractCard } from '../InfographicContractCard/InfographicContractCard.component';
import { InfographicHeaterCard } from '../InfographicHeaterCard/InfographicHeaterCard.component';
import { InfographicPVCard } from '../InfographicPVCard/InfographicPVCard.component';

export const InfographicCardTabs: React.FC = () => {
  const { isActive, data: { query, siteLiveData, chargerData } } = useInfographic();
  const isSiteLiveDataLoading = InfographicHelper.isDataLoading(siteLiveData, query.siteLiveQuery);
  const isChargerLiveDataLoading = InfographicHelper.isDataLoading(chargerData, query.chargerLiveQuery);
  const isContractDataLoading = query.contractQuery.status.pending || query.contractStatisticsQuery.status.pending;

  return (
    <>
      {(isActive(InfographicCardElement.BATTERY) || isActive(InfographicCardElement.BACKUP)) && (
        <InfographicBatteryCard
          isLoading={isSiteLiveDataLoading}
        />
      )}
      {isActive(InfographicCardElement.CHARGER) && (
        <InfographicChargerCard
          isLoading={isChargerLiveDataLoading}
        />
      )}
      {isActive(InfographicCardElement.COMMUNITY) && (
        <InfographicCommunityCard
          isLoading={false}
        />
      )}
      {isActive(InfographicCardElement.PV) && (
        <InfographicPVCard
          isLoading={isSiteLiveDataLoading}
        />
      )}
      {isActive(InfographicCardElement.CONSUMPTION) && (
        <InfographicConsumptionCard
          isLoading={isSiteLiveDataLoading}
        />
      )}
      {isActive(InfographicCardElement.CONTRACT) && (
        <InfographicContractCard
          isLoading={isContractDataLoading}
        />
      )}
      {isActive(InfographicCardElement.HEATER) && (
        <InfographicHeaterCard
          isLoading={isSiteLiveDataLoading}
        />
      )}
    </>
  );
};
