import { AuthTransitionGate } from '+auth/components/AuthTransitionGate';
import * as React from 'react';
import { Props } from './AuthLogIn.component';

export class AuthLogInMobile extends React.PureComponent<Props> {
  componentDidMount() {
    setTimeout(this.props.redirectToAuth, 1000);
  }

  render() {
    return (
      <AuthTransitionGate />
    );
  }
}
