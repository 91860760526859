import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { ContractCard } from '../ContractCard';
import { ContractStatus } from '../ContractCard/types/ContractCard.interface';

import '../ContractCard/ContractCard.component.scss';

export const ContractCardOrdered: React.FC = () => (
  <ContractCard
    title={I18n.t(T.yourFlat.sonnenFlatX.cardOrder.title)}
    subtitle={I18n.t(T.yourFlat.sonnenFlatX.cardOrder.subtitle)}
    icon={'Done'}
    status={ContractStatus.DONE}
  />
);
