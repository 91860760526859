import { T } from '@sonnen/shared-i18n/customer';
import { isNil } from 'lodash';
import { I18n } from 'react-redux-i18n';

import {
  Contract,
  ContractFlatXCessionDsoClearingReason,
  ContractFlatXCessionStatus,
  ContractFlatXCessionStatusMarker,
  ContractUsageAllowanceFlatXConditions,
} from '+app/+dashboard/+contract/store/types/contract.interface';
import { ContractFlatXStatus } from '../types/ContractFlatXCard.interface';

export const getContractFlatXStatus = (date: string | undefined | null): ContractFlatXStatus => {
  if (date) {
    const today: number = new Date(Date.now()).getTime();
    const dateToCompare: number = new Date(date).getTime();

    if (today < dateToCompare) {
      return ContractFlatXStatus.PENDING;
    }

    if (today >= dateToCompare) {
      return ContractFlatXStatus.ACTIVE;
    }
  }

  return ContractFlatXStatus.OPEN;
   // missing info when we have blocked for Energy delivery
   // missing info when we have canceled for Energy contract
};

export const getContractFlatXStatusTranslations = (status: ContractFlatXStatus) => {
  switch (status) {
    case ContractFlatXStatus.ACTIVE:
      return I18n.t(T.yourFlat.sonnenFlatX.statues.active);
    case ContractFlatXStatus.BLOCKED:
      return I18n.t(T.yourFlat.sonnenFlatX.statues.blocked);
    case ContractFlatXStatus.CANCELED:
      return I18n.t(T.yourFlat.sonnenFlatX.statues.canceled);
    case ContractFlatXStatus.DONE:
      return I18n.t(T.yourFlat.sonnenFlatX.statues.done);
    case ContractFlatXStatus.PENDING:
      return I18n.t(T.yourFlat.sonnenFlatX.statues.pending);
    case ContractFlatXStatus.TERMINATION_PENDING:
      return I18n.t(T.yourFlat.sonnenFlatX.statues.terminationPending);
    case ContractFlatXStatus.OPEN:
    default:
      return I18n.t(T.yourFlat.sonnenFlatX.statues.open);
  }
};

export const getUsageAllowanceFlatXConditions = (
  contract: Contract,
  dsoId: string | null | undefined,
): ContractUsageAllowanceFlatXConditions =>  {
  const {
    cessionStatus: status,
    cessionStatusMarker: marker,
    cessionDsoClearingReason: clearingReason,
  } = contract;

  const hasActiveStatusWithDSOClearingMarker = status === ContractFlatXCessionStatus.ACTIVATED
    && marker === ContractFlatXCessionStatusMarker.DSO_CLEARING;
  const hasReceivedSignedDeclaration = status === ContractFlatXCessionStatus.ACTIVATED
    && marker === ContractFlatXCessionStatusMarker.SIGNED_BY_CUSTOMER
    && isNil(clearingReason);
  const hasLEWDSO = hasReceivedSignedDeclaration && dsoId === '9900027000002';
  const hasDeclarationBeenSentToDistribution = status === ContractFlatXCessionStatus.ACTIVATED
    && marker === ContractFlatXCessionStatusMarker.SENT_TO_DSO
    && isNil(clearingReason);
  const cantAllocatePVSystem = hasActiveStatusWithDSOClearingMarker
    && clearingReason === ContractFlatXCessionDsoClearingReason.PLANT_CANT_BE_IDENTIFIED;
  const hasMissingCustomerInformation = hasActiveStatusWithDSOClearingMarker
    && clearingReason === ContractFlatXCessionDsoClearingReason.MISSING_CUSTOMER_INFORMATION;
  const hasInvalidEsignature = hasActiveStatusWithDSOClearingMarker
    && (
      clearingReason === ContractFlatXCessionDsoClearingReason.OTHER_CUSTOMER_RECORD_TYPE
      || clearingReason === ContractFlatXCessionDsoClearingReason.DIFFERENT_PLANT_OPERATOR
      || clearingReason === ContractFlatXCessionDsoClearingReason.MORE_PLANT_OPERATORS
      || clearingReason === ContractFlatXCessionDsoClearingReason.WRONG_DSO
      || clearingReason === ContractFlatXCessionDsoClearingReason.NO_VALID_ESIGNATURE
    );
  const hasRejectedDeclarations = hasActiveStatusWithDSOClearingMarker
    && clearingReason === ContractFlatXCessionDsoClearingReason.GENERAL_REJECTION;
  const cantAcceptAssignment = hasActiveStatusWithDSOClearingMarker
    && (
      clearingReason === ContractFlatXCessionDsoClearingReason.THIRD_PARTY_CESSION
      || clearingReason === ContractFlatXCessionDsoClearingReason.OTHER_REASON
      || isNil(clearingReason)
    );
  const isAssignmentProcessed = hasActiveStatusWithDSOClearingMarker
    && clearingReason === ContractFlatXCessionDsoClearingReason.HAS_NOT_BEEN_COMMERCIALLY_BUILT_UP_YET;
  const isConfirmedByDso = status === ContractFlatXCessionStatus.ACTIVATED
    && marker === ContractFlatXCessionStatusMarker.CONFIRMED_BY_DSO
    && isNil(clearingReason);
  const hasPaymentRunning = status === ContractFlatXCessionStatus.ACTIVATED
    && marker === ContractFlatXCessionStatusMarker.PAYMENTS_RUNNING
    && isNil(clearingReason);
  const hasDeactivatedStatusChangedToDirectMarketing =
    status === ContractFlatXCessionStatus.DEACTIVATED_CHANGED_TO_DIRECT_MARKETING
    && isNil(marker)
    && isNil(clearingReason);

  return {
    hasReceivedSignedDeclaration,
    hasLEWDSO,
    hasDeclarationBeenSentToDistribution,
    cantAllocatePVSystem,
    hasMissingCustomerInformation,
    hasInvalidEsignature,
    hasRejectedDeclarations,
    cantAcceptAssignment,
    isAssignmentProcessed,
    isConfirmedByDso,
    hasPaymentRunning,
    hasDeactivatedStatusChangedToDirectMarketing,
  };
};

export const getUsageAllowanceFlatXStatus = (usageAllowanceConditions: ContractUsageAllowanceFlatXConditions) =>  {
  const {
    hasReceivedSignedDeclaration,
    hasLEWDSO,
    hasDeclarationBeenSentToDistribution,
    cantAllocatePVSystem,
    hasMissingCustomerInformation,
    hasInvalidEsignature,
    hasRejectedDeclarations,
    cantAcceptAssignment,
    isAssignmentProcessed,
    isConfirmedByDso,
    hasPaymentRunning,
    hasDeactivatedStatusChangedToDirectMarketing,
  } = usageAllowanceConditions;

  if (hasReceivedSignedDeclaration
    || hasLEWDSO
    || hasDeclarationBeenSentToDistribution
    || cantAllocatePVSystem
    || isAssignmentProcessed
  ) {
    return ContractFlatXStatus.PENDING;
  }

  if (hasMissingCustomerInformation || hasInvalidEsignature || hasRejectedDeclarations || cantAcceptAssignment) {
    return ContractFlatXStatus.BLOCKED;
  }

  if (isConfirmedByDso || hasPaymentRunning || hasDeactivatedStatusChangedToDirectMarketing) {
    return ContractFlatXStatus.ACTIVE;
  }

  return ContractFlatXStatus.OPEN;
};

export const getUsageAllowanceFlatXSubtitle = (
  usageAllowanceConditions: ContractUsageAllowanceFlatXConditions,
  usageAllowanceActivatedOn: string | null,
) =>  {
  const {
    hasReceivedSignedDeclaration,
    hasLEWDSO,
    hasDeclarationBeenSentToDistribution,
    cantAllocatePVSystem,
    hasMissingCustomerInformation,
    hasInvalidEsignature,
    hasRejectedDeclarations,
    cantAcceptAssignment,
    isAssignmentProcessed,
    isConfirmedByDso,
    hasPaymentRunning,
    hasDeactivatedStatusChangedToDirectMarketing,
  } = usageAllowanceConditions;

  switch (true) {
    case hasLEWDSO:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.subtitle.lewDso);
    case hasReceivedSignedDeclaration:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.subtitle.receivedDeclaration);
    case hasDeclarationBeenSentToDistribution:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.subtitle.declarationSentToDistribution);
    case cantAllocatePVSystem:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.subtitle.operatorCantAllocatePV);
    case hasMissingCustomerInformation:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.subtitle.contactYourDistribution);
    case hasInvalidEsignature:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.subtitle.renewedEsignature);
    case hasRejectedDeclarations:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.subtitle.operatorRejectsDeclarations);
    case cantAcceptAssignment:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.subtitle.operatorCantAcceptAssignment);
    case isAssignmentProcessed:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.subtitle.assignmentBeingProcessed);
    case isConfirmedByDso:
      return usageAllowanceActivatedOn
        ? ''
        : I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.subtitle.freeAmountWillBeActivated);
    case (hasPaymentRunning || hasDeactivatedStatusChangedToDirectMarketing):
      return '';
    default:
      return '';
  }
};

export const getUsageAllowanceFlatXAdditionalInfo = (
  usageAllowanceConditions: ContractUsageAllowanceFlatXConditions,
  usageAllowanceActivatedOn: string | null,
) =>  {
  const {
    hasReceivedSignedDeclaration,
    hasLEWDSO,
    hasDeclarationBeenSentToDistribution,
    cantAllocatePVSystem,
    hasMissingCustomerInformation,
    hasInvalidEsignature,
    hasRejectedDeclarations,
    cantAcceptAssignment,
    isAssignmentProcessed,
    isConfirmedByDso,
    hasPaymentRunning,
    hasDeactivatedStatusChangedToDirectMarketing,
  } = usageAllowanceConditions;

  switch (true) {
    case hasLEWDSO:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.additionalInfo.lewDso);
    case hasReceivedSignedDeclaration:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.additionalInfo.receivedDeclaration);
    case hasDeclarationBeenSentToDistribution:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.additionalInfo.declarationSentToDistribution);
    case cantAllocatePVSystem:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.additionalInfo.operatorCantAllocatePV);
    case hasMissingCustomerInformation:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.additionalInfo.contactYourDistribution);
    case hasInvalidEsignature:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.additionalInfo.renewedEsignature);
    case hasRejectedDeclarations:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.additionalInfo.operatorRejectsDeclarations);
    case cantAcceptAssignment:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.additionalInfo.operatorCantAcceptAssignment);
    case isAssignmentProcessed:
      return I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.additionalInfo.assignmentBeingProcessed);
    case isConfirmedByDso:
      return usageAllowanceActivatedOn
        ? ''
        : I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceCard.additionalInfo.freeAmountWillBeActivated);
    case (hasPaymentRunning || hasDeactivatedStatusChangedToDirectMarketing):
      return '';
    default:
      return '';
  }
};

export const getUsageAllowanceFlatXData = (contract: Contract, dsoId: string | null | undefined) =>  {
  const conditions = getUsageAllowanceFlatXConditions(contract, dsoId);
  const subtitle = contract.usageAllowanceActivatedOn
    ? ''
    : getUsageAllowanceFlatXSubtitle(conditions, contract.usageAllowanceActivatedOn);
  const additionalInfo = contract.usageAllowanceActivatedOn
    ? ''
    : getUsageAllowanceFlatXAdditionalInfo(conditions, contract.usageAllowanceActivatedOn);
  const status = contract.usageAllowanceActivatedOn
    ? ContractFlatXStatus.ACTIVE
    : getUsageAllowanceFlatXStatus(conditions);

  return {
    additionalInfo,
    status,
    subtitle,
  };
};
