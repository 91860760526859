import { Bubble, DefaultParagraph, Icon } from '@sonnen/shared-web';
import * as React from 'react';

import './GuideTextWithTooltip.component.scss';

interface Props {
  title: string;
  content: string;
}

export const GuideTextWithTooltip: React.FC<Props> = ({ title, content }) => (
  <div className={'c-guide-text-with-tooltip'}>
    {title}
    <div className={'c-guide-text-with-tooltip__content'}>
      <div className={'c-guide-text-with-tooltip__trigger'}>
        <Icon.Info />
      </div>
      <div className={'c-guide-text-with-tooltip__bubble'}>
        <Bubble side={'top-right'}>
          <DefaultParagraph>
            {content}
          </DefaultParagraph>
        </Bubble>
      </div>
    </div>
  </div>
);
