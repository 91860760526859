import { ActionsUnion, createAction } from '+app/utils/redux';
import { News } from './types/news.interface';

export enum NEWS_CHANNEL_ACTIONS {
  SET_NEWS_LIST = '[NewsChannel] SET_NEWS_LIST',
}

export const NewsChannelActions = {
  setNewsList: (newsList: News[]) => createAction(NEWS_CHANNEL_ACTIONS.SET_NEWS_LIST, { newsList }),
};

export type NewsChannelActions = ActionsUnion<typeof NewsChannelActions>;
