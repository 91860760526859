import { T } from '@sonnen/shared-i18n/customer';
import {
  AnalysisToolbar,
  DateShift,
  DateSwitcher,
  getMinimalMeasurementDate,
  Icon,
  shiftDate,
  TimeHelper,
  TimeUnit,
} from '@sonnen/shared-web';
import * as moment from 'moment';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { getResolutionForPeriod } from '+analysis/store/analysis.helpers';
import { provideLocale } from '+legacy/helpers/i18n.helper';
import { AnalysisDatePicker } from '../../components';
import { useAnalysis } from '../../containers/AnalysisProvider/AnalysisProvider.context';
import { formatDate, getDateFormatForPeriod } from '../../helpers/analysis.helper';

import './AnalysisHistoryToolbar.component.scss';

export const AnalysisHistoryToolbar: React.FC = () => {
  const [isDatePickerOpen, setDatePickerState] = React.useState<boolean>(false);

  const {
    commonData: {
      batteryData,
      statisticsSelectedDate,
    },
    actions,
  } = useAnalysis();

  const { period, date } = statisticsSelectedDate;
  const minimalDate = getMinimalMeasurementDate(batteryData?.installationDate);

  const toggleDatePicker = () => setDatePickerState(!isDatePickerOpen);

  const hideDatePicker = () => setDatePickerState(false);

  const changeDate = (selectedDatePeriod: TimeUnit) => (selectedDate: Date) => {
    actions.setStatisticsSelectedDate({
      period: selectedDatePeriod,
      date: selectedDate,
      resolution: getResolutionForPeriod(selectedDatePeriod),
    });
    actions.getStatistics();
    hideDatePicker();
  };

  const shiftSelectedDate = (shift: DateShift) => {
    const { period, date } = statisticsSelectedDate;
    changeDate(statisticsSelectedDate.period)(shiftDate(date, period, shift));
  };

  return (
    <div className={'c-analysis-history-toolbar'}>
      <AnalysisToolbar
        // @TODO: Change to proper translation
        dateSwitcherTitle={I18n.t(T.analysis.datepickerLabel)}
        datePicker={(
          <AnalysisDatePicker
            isActive={isDatePickerOpen}
            date={date}
            minDate={minimalDate}
            onDayClick={changeDate(TimeUnit.DAY)}
            onMonthClick={changeDate(TimeUnit.MONTH)}
            onYearClick={changeDate(TimeUnit.YEAR)}
            activeDatePicker={period}
            tabsTriggers={[
              { label: I18n.t(T.mobileApp.analysis.dateLabels.day) },
              { label: I18n.t(T.mobileApp.analysis.dateLabels.month) },
              { label: I18n.t(T.mobileApp.analysis.dateLabels.year) },
            ]}
            locale={provideLocale()}
          />
        )}
        dateSwitcher={(
          <DateSwitcher
            icon={<Icon.Calendar className={'c-analysis-history-toolbar__date-switcher-icon'}/>}
            date={date}
            dateFormatter={() => formatDate(date, getDateFormatForPeriod(period))}
            canGoPrev={moment(date).isAfter(minimalDate)}
            canGoNext={!TimeHelper.isCurrent(period)(date)}
            onPrevSelected={() => shiftSelectedDate(DateShift.BACK)}
            onNextSelected={() => shiftSelectedDate(DateShift.FORWARD)}
            onDateSelected={toggleDatePicker}
          />
        )}
        onDatePickerOutsideClick={hideDatePicker}
      />
    </div>
  );
};
