import * as React from 'react';

import { PlatformSelector } from '+app/shared/components';
import { DemoDashboardMobile } from './DemoDashboard.mobile.component';
import { DemoDashboardWeb } from './DemoDashboard.web.component';

export const DemoDashboard: React.FC = props => {
  return (
    <PlatformSelector
      web={<DemoDashboardWeb {...props} />}
      mobile={<DemoDashboardMobile {...props} />}
    />
  );
};
