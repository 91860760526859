import { T } from '@sonnen/shared-i18n/customer';
import { PageWidthWrapper } from '@sonnen/shared-web';
import classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { isAuthenticated } from '+app/+auth/store/auth.selectors';
import { getRouterLocationPath } from '+app/router/store/router.selectors';
import { StoreState } from '+app/store/store.interface';
import { isVerifyingIdentity } from '+guide/store/+guide.selectors';
import { FooterNav, FooterSocial } from '+shared/components';
import { getLegalDocuments } from '+shared/store/legal/legal.selectors';
import { getUserCountryCode } from '+shared/store/user/user.selectors';
import { getFooterNav } from './Footer.helper';

import './Footer.component.scss';

const mapStateToProps = (state: StoreState) => ({
  isLoggedIn: isAuthenticated(state),
  isVerifyingIdentityInGuide: isVerifyingIdentity(state),
  lang: state.i18n.locale,
  path: getRouterLocationPath(state),
  legalDocuments: getLegalDocuments(state),
  country: getUserCountryCode(state),
});

interface OwnProps {
  isOnboardingVariant?: boolean;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & OwnProps
  ;

const FooterComponent: React.FC<Props> = ({
  isLoggedIn,
  isOnboardingVariant,
  isVerifyingIdentityInGuide,
  lang,
  country,
  path,
  legalDocuments,
}) => (
  <footer className={classNames('c-footer', {
    'c-footer--onboard-page': isOnboardingVariant,
  })}>
    <PageWidthWrapper>
      <div className={'c-footer__content'}>
        <div className={'c-footer__social'}>
          <FooterSocial isOnboardingVariant={isOnboardingVariant} />
        </div>
        <div className={'c-footer__nav'}>
          <FooterNav
            lang={lang}
            list={getFooterNav(isLoggedIn, isVerifyingIdentityInGuide, lang, country, legalDocuments, path)}
          />
        </div>
        <p className={'c-footer__cpr'}>
          &copy;&nbsp;{I18n.t(T.general.copyrights)}
        </p>
      </div>
    </PageWidthWrapper>
  </footer>
);

export const Footer = connect(mapStateToProps)(FooterComponent);
