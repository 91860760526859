import { storeProvider } from '+app/store/store.provider';
import {
  getAuthorizationHeader,
  getImpersonatedUserId,
  isAuthenticated,
  isImpersonated,
} from '+auth/store/auth.selectors';
import { errorInterceptor } from '+shared/network/errorInterceptor';
import { ContentType } from '+shared/network/network.interface';
import { customBodyParser, isAuthorizedDomain } from '+shared/network/network.util';
import { BodyCasing, bodyParser, bodySerializer, HttpClient } from '@coolio/http';
import { fetchRequestHandler } from '@coolio/http/request-handlers/fetch';
import { JsonApiClient } from '@coolio/json-api';
import { createAuthInterceptor } from './authInterceptor';
import { createIdentityVerifierInterceptor } from './identityVerifierInterceptor';
import { createProductMigrationAuthInterceptor } from './productMigrationAuthInterceptor';

/**
 * Customer Portal json:api client
 */

export const customerPortalHeadersProvider = (host: string = '') => ({
  'Accept': `${ContentType.JSON},${ContentType.VND_JSON}`,
  'Authorization': (isAuthorizedDomain(host) && isAuthenticated(storeProvider.getState()))
    ? getAuthorizationHeader(storeProvider.getState())
    : undefined,
  'X-Impersonate-User': (isAuthorizedDomain(host) && isImpersonated(storeProvider.getState()))
    ? getImpersonatedUserId(storeProvider.getState())
    : undefined,
  'Content-Type': ContentType.JSON,
});

export  const customerPortalHeaders = {
  'Accept': `${ContentType.JSON},${ContentType.VND_JSON}`,
  'Content-Type': ContentType.JSON,
};

const productMigrationHeaders = {
  'Accept': `${ContentType.JSON},${ContentType.VND_JSON}`,
  'Content-Type': ContentType.VND_JSON,
};

const customerPortalHttpClient = new HttpClient({
  requestHandler: fetchRequestHandler({
    defaultRequestOptions: {
      mode: 'cors',
    },
  }),
  bodyParser: customBodyParser,
  bodySerializer: bodySerializer({
    bodyCasing: BodyCasing.SNAKE_CASE,
  }),
  headers: customerPortalHeaders,
})
  .addInterceptor(errorInterceptor)
  .addInterceptor(createAuthInterceptor());

const customerPortalJsonApiClient = new JsonApiClient(customerPortalHttpClient);

/**
 * Customer Guidance json:api client
 */

export const identityVerifierInterceptor = createIdentityVerifierInterceptor();

const customerGuidanceHeaders = {
  'Accept': `${ContentType.JSON},${ContentType.VND_JSON}`,
  'Content-Type': ContentType.JSON,
};

export const customerGuidanceHttpClient = new HttpClient({
  requestHandler: fetchRequestHandler({
    defaultRequestOptions: {
      mode: 'cors',
    },
  }),
  bodyParser: bodyParser({
    bodyCasing: BodyCasing.CAMEL_CASE,
  }),
  bodySerializer: bodySerializer({
    bodyCasing: BodyCasing.SNAKE_CASE,
  }),
  headers: customerGuidanceHeaders,
}).addInterceptor(errorInterceptor)
  .addInterceptor(identityVerifierInterceptor.interceptor);

export const customerGuidanceJsonApiClient = new JsonApiClient(customerGuidanceHttpClient);

/**
 * Common json:api client
 *
 * @NOTE: I've tried to manipulate interceptors in order to merge
 *        these two monsters together, but I failed, leaving it as
 *        a TO-DO for later to try to really merge them into one client
 *        refactoring this will be really easy
 */

export const useHttp = () => isAuthenticated(storeProvider.getState())
  ? customerPortalHttpClient
  : customerGuidanceHttpClient;

export const useJsonApi = () => isAuthenticated(storeProvider.getState())
  ? customerPortalJsonApiClient
  : customerGuidanceJsonApiClient;

/**
 * Reverse channel http client
 */

export const reverseChannelHttpClient = new HttpClient({
  requestHandler: fetchRequestHandler({
    defaultRequestOptions: {
      mode: 'cors',
    },
  }),
  bodyParser: bodyParser({
    bodyCasing: BodyCasing.CAMEL_CASE,
  }),
  bodySerializer: bodySerializer({
    bodyCasing: BodyCasing.SNAKE_CASE,
  }),
  headers: customerPortalHeaders,
})
  // .addInterceptor(createRetryInterceptor({ retries: 5 }))
  .addInterceptor(errorInterceptor)
  .addInterceptor(createAuthInterceptor());

/**
 * Forecast http client
 */
export const forecastHttpClient = new HttpClient({
  requestHandler: fetchRequestHandler(),
  bodyParser: bodyParser({
    bodyCasing: BodyCasing.CAMEL_CASE,
  }),
  bodySerializer: bodySerializer({
    bodyCasing: BodyCasing.SNAKE_CASE,
  }),
  headers: customerPortalHeaders,
})
  .addInterceptor(errorInterceptor)
  .addInterceptor(createAuthInterceptor());

/**
 * Product Migration http client
 */
export const productMigrationHttpClient = new HttpClient({
  requestHandler: fetchRequestHandler(),
  bodyParser: bodyParser({
    bodyCasing: BodyCasing.CAMEL_CASE,
  }),
  bodySerializer: bodySerializer({
    bodyCasing: BodyCasing.CAMEL_CASE,
  }),
  headers: productMigrationHeaders,
})
.addInterceptor(errorInterceptor)
.addInterceptor(createProductMigrationAuthInterceptor());

export const productMigrationJsonApiClient = new JsonApiClient(productMigrationHttpClient);

/**
 * My Contract json:api client
 */
export const myContractHttpClient = new HttpClient({
  requestHandler: fetchRequestHandler({
    defaultRequestOptions: {
      mode: 'cors',
    },
  }),
  bodyParser: bodyParser({
    bodyCasing: BodyCasing.CAMEL_CASE,
  }),
  bodySerializer: bodySerializer({
    bodyCasing: BodyCasing.SNAKE_CASE,
  }),
  headers: customerPortalHeaders,
}).addInterceptor(errorInterceptor)
  .addInterceptor(createAuthInterceptor());

export const myContractJsonApiClient = new JsonApiClient(myContractHttpClient);

/**
 * Content Hub http client
 */
export const contentHubHttpClient = new HttpClient({
  requestHandler: fetchRequestHandler(),
  bodyParser: bodyParser({
    bodyCasing: BodyCasing.CAMEL_CASE,
  }),
  bodySerializer: bodySerializer({
    bodyCasing: BodyCasing.CAMEL_CASE,
  }),
})
.addInterceptor(errorInterceptor);
