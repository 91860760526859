import { BankData, Configuration, EnergyProvider, UtilityData } from '+guide/store/types';
import { ActionsUnion, createAction } from '+utils/redux';
import { UtilityDataForm } from '../containers';
import { DocumentAcceptanceForm } from '../containers/GuideAcceptanceDocuments/GuideAcceptanceDocuments.helper';

export enum GUIDE_ACCEPTANCE_ACTIONS {
  SET_PAYMENT_DATA = '[+Acceptance] SET_PAYMENT_DATA',
  SET_UTILITY_DATA = '[+Acceptance] SET_UTILITY_DATA',
  SET_UTILITY_DATA_SUCCESS = '[+Acceptance] SET_UTILITY_DATA_SUCCESS',
  GET_ENERGY_PROVIDERS = '[+Acceptance] GET_ENERGY_PROVIDERS',
  SET_ENERGY_PROVIDERS = '[+Acceptance] SET_ENERGY_PROVIDERS',
  ACCEPT_OFFER = '[+Acceptance] ACCEPT_OFFER',
  ACCEPT_DOCUMENTS = '[+Acceptance] ACCEPT_DOCUMENTS',
  OPEN_FLAT_OFFER_DOCUMENT = '[+Acceptance] OPEN_FLAT_OFFER_DOCUMENT',
  GET_UTILITY_DATA = '[+Acceptance] GET_UTILITY_DATA',
  SET_FETCHED_UTILITY_DATA = '[+Acceptance] SET_FETCHED_UTILITY_DATA',
  SET_PV_SYSTEM_KEYS = '[+Acceptance] SET_PV_SYSTEM_KEYS',
  SET_PV_SYSTEM_KEYS_SUCCESS = '[+Acceptance] SET_PV_SYSTEM_KEYS_SUCCESS',
}

export const GuideAcceptanceActions = {
  setPaymentData: (paymentData: BankData) => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.SET_PAYMENT_DATA,
    { paymentData },
  ),
  setUtilityData: (values: UtilityDataForm) => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.SET_UTILITY_DATA,
    { values },
  ),
  setUtilityDataSuccess: () => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.SET_UTILITY_DATA_SUCCESS,
  ),
  getEnergyProviders: (searchQuery?: string) => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.GET_ENERGY_PROVIDERS,
    { searchQuery },
  ),
  setEnergyProviders: (energyProviders: EnergyProvider[]) => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.SET_ENERGY_PROVIDERS,
    { energyProviders },
  ),
  acceptDocuments: (formValues: DocumentAcceptanceForm) => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.ACCEPT_DOCUMENTS,
    { formValues },
  ),
  acceptOffer: () => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.ACCEPT_OFFER,
  ),
  openFlatOfferDocument: () => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.OPEN_FLAT_OFFER_DOCUMENT,
  ),
  getUtilityData: () => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.GET_UTILITY_DATA,
  ),
  setFetchedUtilityData: (utilityData: UtilityData) => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.SET_FETCHED_UTILITY_DATA,
    { utilityData },
  ),
  setPvSystemKeys: (values: UtilityDataForm, configuration: Configuration) => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.SET_PV_SYSTEM_KEYS,
    { values, configuration },
  ),
  setPvSystemKeysSuccess: () => createAction(
    GUIDE_ACCEPTANCE_ACTIONS.SET_PV_SYSTEM_KEYS_SUCCESS,
  ),
};

export type GuideAcceptanceActions = ActionsUnion<typeof GuideAcceptanceActions>;
