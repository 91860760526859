import { getMobileAppInstanceId, getMobileAppVersionInfo } from '+app/+mobile/store/mobile.selectors';
import { Route, Routes } from '+app/router/router.types';
import { MobileSideNavNestedAccount } from '+app/shared/components/MobileSideNavNestedAccount';
import { MobileSideNavNestedSites } from '+app/shared/components/MobileSideNavNestedSites';
import { SonnenLogo } from '+app/shared/components/SonnenLogo/SonnenLogo';
import { LayoutActions } from '+app/shared/store/layout/layout.actions';
import { isMobileNavOpen } from '+app/shared/store/layout/layout.selectors';
import { SiteActions } from '+app/shared/store/site';
import { SiteHelper } from '+app/shared/store/site/site.helpers';
import { getCurrentUserName } from '+app/shared/store/user/user.selectors';
import { StoreState } from '+app/store/store.interface';
import { CONFIG } from '+config';
import { getPaths } from '+router/router.helper';
import { LegalDocumentsHelper } from '+shared/helpers/legalDocuments.helper';
import { getLegalDocuments } from '+shared/store/legal/legal.selectors';
import {
  LegalDocuments,
} from '+shared/store/legal/types/legalDocuments.interface';
import { getActiveSite, getAllSites } from '+shared/store/site/site.selectors';
import { getUserCountryCode } from '+shared/store/user/user.selectors';
import { mapActions } from '+utils/redux';
import { T } from '@sonnen/shared-i18n/customer';
import { CountryCode, Icon, Nav, NavItem, NavListItem } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import './MobileSideNav.component.scss';

const mapStateToProps = (state: StoreState) => ({
  allSites: getAllSites(state),
  activeSite: getActiveSite(state),
  isMobileNavOpen: isMobileNavOpen(state),
  userName: getCurrentUserName(state),
  legalDocuments: getLegalDocuments(state),
  country: getUserCountryCode(state),
  lang: state.i18n.locale,
  versionInfo: getMobileAppVersionInfo(state),
  instanceId: getMobileAppInstanceId(state),
});

const mapDispatchToProps = mapActions({
  changeActiveSite: SiteActions.switchSite,
  toggleMobileNav: LayoutActions.toggleMobileNav,
});

interface OwnProps {
  routes: Route[];
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps;

const renderViewsNavList = (routes: Routes) => routes.map((route, i) => (
  <NavListItem
    isThemeUnderline={i === routes.length - 1}
    key={`mobile-side-nav-${route.name}`}
  >
    <NavItem
      action={route.path}
      icon={route.icon && <route.icon />}
    >
      {I18n.t(route.label.mobile || route.label.web || '')}
    </NavItem>
  </NavListItem>
));

const getLegalDocumentsNavList = (legalDocuments?: LegalDocuments, lang?: string, country?: CountryCode) => {
  const privacyPolicy = LegalDocumentsHelper.getPrivacyPolicy(legalDocuments, lang, country);
  const termsOfUse = LegalDocumentsHelper.getTermsAndConditions(legalDocuments, lang, country);

  return [
    {
      label: T.general.documents.privacyPolicy.label,
      url: privacyPolicy?.url || I18n.t(T.general.documents.privacyPolicy.link),
    },
    {
      label: T.general.documents.termsOfUse.label,
      url: termsOfUse?.url || I18n.t(T.general.documents.termsOfUse.link),
    },
  ];
};

export const MobileSideNavComponent: React.FC<Props> = ({
  allSites,
  activeSite,
  isMobileNavOpen,
  country,
  lang,
  legalDocuments,
  userName,
  routes,
  versionInfo,
  instanceId,
  actions: { changeActiveSite, toggleMobileNav },
}) => {
  const areMultipleSites = SiteHelper.hasMultipleSites(allSites);

  return (
    <div className={classNames('c-mobile-side-nav', {
      'is-open': isMobileNavOpen,
    })}>
      <nav className={'c-mobile-side-nav__nav'}>
        <div className={'c-mobile-side-nav__header'}>
          <div className={'c-mobile-side-nav__logo'}>
            <SonnenLogo />
          </div>
          <MobileSideNavNestedAccount username={userName} />
          {areMultipleSites && (
            <MobileSideNavNestedSites
              activeSite={activeSite}
              allSites={allSites}
              setActiveSite={changeActiveSite}
            />
          )}
        </div>
        <Nav>
          {renderViewsNavList(routes)}
          {/* @NOTE: External links */}
          <NavListItem>
            <NavItem
              icon={<Icon.QuestionMark />}
              isThemeFaded={true}
            >
              {I18n.t(T.mobileApp.nav.aboutUs)}
            </NavItem>
          </NavListItem>
          {getLegalDocumentsNavList(legalDocuments, lang, country).map(({ label, url }) => (
            <NavListItem>
              <NavItem
                action={url}
                isThemeIndented={true}
              >
                {I18n.t(label)}
              </NavItem>
            </NavListItem>
          ))}
          <NavListItem isThemeUnderline={true}>
            <NavItem
              action={I18n.t(T.general.documents.imprint.link)}
              isThemeIndented={true}
            >
              {I18n.t(T.general.documents.imprint.label)}
            </NavItem>
          </NavListItem>
          {/* @NOTE: Logout */}
          <NavListItem isThemeUnderline={true}>
            <NavItem
              action={getPaths().LOGOUT}
              icon={<Icon.Logout />}
            >
              {I18n.t(T.mobileApp.nav.logOut)}
            </NavItem>
          </NavListItem>
          {/* @NOTE: Developer options */}
          {CONFIG.IS_DEV && (
            <NavListItem isThemeUnderline={true}>
              <NavItem action={getPaths().DEVELOPER}>
                Developer Options
              </NavItem>
            </NavListItem>
          )}
        </Nav>
        {(versionInfo.versionName || versionInfo.flagConfig || instanceId) && (
          <div className={'c-mobile-side-nav__app-info'}>
            <div className={'c-mobile-side-nav__app-version'}>
              {'Version:'}<br/>{`${versionInfo.versionName} - ${versionInfo.flagConfig}`}
            </div>
            <div>
              {'App ID:'}<br/>{instanceId}
            </div>
          </div>
        )}
      </nav>
      <div
        className={'c-mobile-side-nav__app-overlay'}
        onClick={() => toggleMobileNav(false)}
      />
    </div>
  );
};

export const MobileSideNav = connect(mapStateToProps, mapDispatchToProps)(MobileSideNavComponent);
