import { T } from '@sonnen/shared-i18n/customer';
import { isEmpty } from 'lodash';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

import { UtilityDataMeterId } from '../../containers/GuideAcceptanceUtility/GuideAcceptanceUtility.helper';
import { UtilityChangePeriod } from '../GuideAcceptanceUtilityMoveHouse/GuideAcceptanceUtilityMoveHouse.helper';

export const meterIdFormInitial: UtilityDataMeterId = {
  meterId: '',
  isMeterIdNotKnown: false,
};

export const UtilityDataMeterIdDateSchema = Yup.object().shape({
  meterId: Yup
    .string()
    .when('isMeterIdNotKnown', {
      is: false,
      then: Yup.string()
        .matches(/^([a-zA-Z0-9\-]+)$/, () => I18n.t(T.customerGuidance.acceptance.utilityData.meterId.error))
        .required(() => I18n.t(T.customerGuidance.acceptance.requiredField)),
      otherwise: Yup.string(),
    }),
  isMeterIdNotKnown: Yup
    .boolean()
    .when('isMovingHouses', {
      is: UtilityChangePeriod.NOT_MOVED,
      otherwise: Yup.boolean()
        .when('meterId', {
          is: meterId => isEmpty(meterId),
          then: Yup.boolean().oneOf([true]),
        }),
    }),
},
);
