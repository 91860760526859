import { T } from '@sonnen/shared-i18n/customer';
import { BoldParagraph, Icon } from '@sonnen/shared-web';
import { FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { FormInputRadio } from '+app/shared/components';
import { formFields, UtilityDataForm } from '../../containers';

import './GuideAcceptanceUtilityTermination.component.scss';

interface Props {
  form: FormikProps<UtilityDataForm>;
}

export const GuideAcceptanceUtilityTermination = ({ form }: Props) => (
  <div className={'c-guide-acceptance-utility-termination'}>
    <div className={'c-guide-acceptance-utility-termination__text'}>
      <BoldParagraph>
        {I18n.t(T.customerGuidance.acceptance.utilityData.termination.question)}
      </BoldParagraph>
    </div>

    <div className={'c-guide-acceptance-utility-termination__radio-wrapper'}>
      <div className={'c-guide-acceptance-utility-termination__radio-btn'}>
        <FormInputRadio
          form={form}
          label={I18n.t(T.customerGuidance.acceptance.utilityData.termination.yes)}
          name={formFields.HAS_RIGHT_OF_TERMINATION}
          value={true}
        />
      </div>
      <div className={'c-guide-acceptance-utility-termination__radio-btn'}>
        <FormInputRadio
          form={form}
          label={I18n.t(T.customerGuidance.acceptance.utilityData.termination.no)}
          name={formFields.HAS_RIGHT_OF_TERMINATION}
          value={false}
        />
      </div>
    </div>

    <div className={'c-guide-acceptance-utility-termination__warning'}>
      {form.values.hasRightOfTermination ?
        <>
          <Icon.Warning className={'c-guide-acceptance-utility-termination__icon-warning'} />
          <span>
            {I18n.t(T.customerGuidance.acceptance.utilityData.termination.diy)}
          </span>
        </>
        : <>
          <Icon.Info className={'c-guide-acceptance-utility-termination__icon-info'} />
          <span>
            {I18n.t(T.customerGuidance.acceptance.utilityData.termination.sonnenWillTerminate)}
          </span>
        </>
      }
    </div>
  </div>
);
