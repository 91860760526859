import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';

import { DemoAnalysis } from '+app/+demo/+analysis';
import { DemoBatteryOverview } from '+app/+demo/+battery';
import { DemoCommunity } from '+app/+demo/+community';
import { DemoContractFlatOverview } from '+app/+demo/+contract';
import { DemoDashboard } from '+app/+demo/+dashboard';
import { DemoHello } from '+app/+demo/+hello';
import { DemoLiveState } from '+app/+demo/+live-state';
import { GuideAcceptanceStepper } from '+app/+guide/+acceptance/components/GuideAcceptanceStepper';
import { App } from '+app/App.component';
import { AuthLogIn } from '+auth/containers/AuthLogIn';
import { GuideAcceptance, GuideAcceptancePersonalDetailsError } from '+guide/+acceptance';
import { GuideAcceptanceHardwareOffer } from '+guide/+acceptance/components';
import { GuideDiscovery } from '+guide/+discovery/containers/GuideDiscovery';
import { GuideDocuments } from '+guide/+documents';
import { GuideProductInfo } from '+guide/+information/containers';
import { GuideLogout } from '+guide/+logout';
import { GuideOverview } from '+guide/+overview';
import { GuidePurchase } from '+guide/+purchase/containers/GuidePurchase';
import { GuideSetup } from '+guide/+setup/containers/GuideSetup';
import { Guide, GuideContact } from '+guide/containers';
import { GuideUsage } from '+guide/containers/GuideUsage';
import { ROUTER_ASYNC_COMPONENTS } from './router.constants';
import { FlatRoutes, Route, RouteName, RouteType } from './router.types';

const PATHS: Record<RouteName, string> = {
  [RouteName.ROOT]: '/',

  [RouteName.LOGOUT]: '/logout',

  [RouteName.DASHBOARD]: '/dashboard',

  [RouteName.ANALYSIS]: '/analysis',

  [RouteName.LIVE_STATE]: '/live-state',

  [RouteName.COMMUNITY]: '/sonnencommunity',

  [RouteName.FLAT]: '/sonnenflat',
  [RouteName.FLAT_CUSTOMER]: '/sonnenflat/customer',
  [RouteName.FLAT_OVERVIEW]: '/sonnenflat/overview',
  [RouteName.FLAT_USER_READINGS]: '/sonnenflat/meter-readings',
  [RouteName.FLAT_NEW_CONTRACT]: '/sonnenflat/new-contract',
  [RouteName.FLAT_MIGRATION]: '/sonnenflat/migration',

  [RouteName.TARIFF]: '/energy-tariff',
  [RouteName.TARIFF_CUSTOMER]: '/energy-tariff/customer',
  [RouteName.TARIFF_OVERVIEW]: '/energy-tariff/overview',
  [RouteName.TARIFF_USER_READINGS]: '/energy-tariff/meter-readings',

  [RouteName.ENERGY]: '/sonnenenergy',
  [RouteName.ENERGY_CUSTOMER]: '/sonnenenergy/customer',
  [RouteName.ENERGY_OVERVIEW]: '/sonnenenergy/overview',
  [RouteName.ENERGY_DOCUMENTS]: '/sonnenenergy/documents',

  [RouteName.BATTERY]: '/battery',
  [RouteName.BATTERY_OVERVIEW]: '/battery/overview',
  [RouteName.BATTERY_DETAILS]: '/battery/details',
  [RouteName.BATTERY_DOWNLOADS]: '/battery/downloads',

  [RouteName.DOCUMENTS]: '/documents',
  [RouteName.DOCUMENTS_CONTRACTS]: '/documents/contracts',
  [RouteName.DOCUMENTS_INVOICES]: '/documents/invoices',
  [RouteName.DOCUMENTS_SYSTEM_DOCS]: '/documents/system-documents',

  [RouteName.RECRUIT_FRIENDS]: '/recruit-friends',

  [RouteName.MY_ACCOUNT]: '/my-account',
  [RouteName.MY_ACCOUNT_ABOUT]: '/my-account/about',
  [RouteName.MY_ACCOUNT_PASSWORD]: '/my-account/password',
  [RouteName.MY_ACCOUNT_SETTINGS]: '/my-account/settings',

  [RouteName.HELP_AND_SUPPORT]: '/help-and-support',
  [RouteName.HELP_AND_SUPPORT_OVERVIEW]: '/help-and-support/overview',
  [RouteName.HELP_AND_SUPPORT_CONFIRMATION]: '/help-and-support/confirmation',
  [RouteName.HELP_AND_SUPPORT_TAX]: '/help-and-support/tax',

  [RouteName.FEEDBACK]: '/feedback',

  [RouteName.DEVELOPER]: '/developer',

  [RouteName.LOGIN]: '/login',

  [RouteName.GUIDE]: '/guide',
  [RouteName.GUIDE_OVERVIEW]: '/guide/overview',
  [RouteName.GUIDE_PRODUCT_INFO]: '/guide/product-info',
  [RouteName.GUIDE_DOCUMENTS]: '/guide/documents',
  [RouteName.GUIDE_PHASES]: '/guide/phases',
  [RouteName.GUIDE_CONTACT]: '/guide/contact',
  [RouteName.GUIDE_DISCOVERY]: '/guide/discovery',
  [RouteName.GUIDE_DISCOVERY_STEP]: '/guide/discovery/:step',
  [RouteName.GUIDE_PURCHASE]: '/guide/purchase',
  [RouteName.GUIDE_PURCHASE_STEP]: '/guide/purchase/:step',
  [RouteName.GUIDE_ACCEPTANCE]: '/guide/acceptance',
  [RouteName.GUIDE_ACCEPTANCE_ID]: '/guide/acceptance/:offerId',
  [RouteName.GUIDE_ACCEPTANCE_ID_HARDWARE]: '/guide/acceptance/:offerId/hardware',
  [RouteName.GUIDE_ACCEPTANCE_ID_WRONG_DETAILS]: '/guide/acceptance/:offerId/wrong-details',
  [RouteName.GUIDE_SETUP]: '/guide/setup',
  [RouteName.GUIDE_SETUP_STEP]: '/guide/setup/:step',
  [RouteName.GUIDE_USAGE]: '/guide/usage',
  [RouteName.GUIDE_MY_ACCOUNT]: '/guide/my-account',
  [RouteName.GUIDE_LOGOUT]: '/guide/logout',

  [RouteName.DEMO]: '/demo',
  [RouteName.DEMO_BATTERY]: '/demo/battery',
  [RouteName.DEMO_HELLO]: '/demo/hello',
  [RouteName.DEMO_DASHBOARD]: '/demo/dashboard',
  [RouteName.DEMO_LIVE_STATE]: '/demo/live-state',
  [RouteName.DEMO_COMMUNITY]: '/demo/community',
  [RouteName.DEMO_ANALYSIS]: '/demo/analysis',
  [RouteName.DEMO_FLAT]: '/demo/sonnenflat',
};

const USER_READINGS_SHARED_CONFIG: Route = {
  routeType: RouteType.PROTECTED,
  name: '' as RouteName,
  path: '',
  label: {
    web: T.general.navigation.meterReading,
    mobile: T.general.navigation.meterReading,
  },
  title: {
    web: T.contract.meterReadings.headline,
  },
  component: ROUTER_ASYNC_COMPONENTS.AsyncUserReadings,
  isMainFeatureRoot: false,
};

const ROUTES: FlatRoutes = {
  [RouteName.ROOT]: {
    routeType: RouteType.ALL,
    name: RouteName.ROOT,
    path: PATHS.ROOT,
    label: {},
    component: App,
    isMainFeatureRoot: true,
  },
  // ==================================================
  // PROTECTED
  // ==================================================
  [RouteName.LOGOUT]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.LOGOUT,
    path: PATHS.LOGOUT,
    label: {
      web: T.general.navigation.logOut,
      mobile: T.mobileApp.nav.logOut,
    },
    title: {
      web: T.general.navigation.logOut,
      mobile: T.mobileApp.nav.logOut,
    },
    icon: Icon.Logout,
    component: ROUTER_ASYNC_COMPONENTS.AsyncLogOut,
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-logout',
  },
  [RouteName.DASHBOARD]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.DASHBOARD,
    path: PATHS.DASHBOARD,
    isExactPath: true,
    label: {
      web: T.general.navigation.dashboard,
    },
    icon: Icon.HouseOnHill,
    component: ROUTER_ASYNC_COMPONENTS.AsyncOverview,
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-dashboard',
  },
  [RouteName.LIVE_STATE]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.LIVE_STATE,
    path: PATHS.LIVE_STATE,
    label: {
      web: T.general.navigation.liveState,
      mobile: T.mobileApp.nav.live,
    },
    title: {
      web: T.general.pageTitles.dashboard.liveState,
      mobile: T.general.pageTitles.dashboard.liveState,
    },
    icon: Icon.LiveState,
    component: ROUTER_ASYNC_COMPONENTS.AsyncLiveState,
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-live-state',
  },
  [RouteName.ANALYSIS]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.ANALYSIS,
    path: PATHS.ANALYSIS,
    label: {
      web: T.general.navigation.analysis,
      mobile: T.mobileApp.nav.analysis,
    },
    title: {
      web: T.general.pageTitles.dashboard.analysis,
      mobile: T.general.pageTitles.dashboard.analysis,
    },
    icon: Icon.Analysis,
    component: ROUTER_ASYNC_COMPONENTS.AsyncAnalysis,
    isMainFeatureRoot: true,
  },
  [RouteName.COMMUNITY]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.COMMUNITY,
    path: PATHS.COMMUNITY,
    label: {
      web: T.general.navigation.sonnenCommunity,
      mobile: T.mobileApp.nav.community,
    },
    title: {
      web: T.sonnenCommunity.title,
      mobile: T.sonnenCommunity.title,
    },
    icon: Icon.Community,
    component: ROUTER_ASYNC_COMPONENTS.AsyncCommunity,
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-sonnen-community',
  },
  [RouteName.FLAT]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.FLAT,
    path: PATHS.FLAT,
    label: {
      web: T.general.navigation.yourFlat,
      mobile: T.mobileApp.nav.sonnenFlat,
    },
    title: {
      mobile: T.general.navigation.yourFlat,
    },
    icon: Icon.Thunder,
    component: ROUTER_ASYNC_COMPONENTS.AsyncContractFlat,
    subRoutesRefs: [
      RouteName.FLAT_NEW_CONTRACT,
      RouteName.FLAT_OVERVIEW,
      RouteName.FLAT_CUSTOMER,
      RouteName.FLAT_USER_READINGS,
      RouteName.FLAT_MIGRATION,
    ],
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-sonnen-flat',
  },
  [RouteName.FLAT_OVERVIEW]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.FLAT_OVERVIEW,
    path: PATHS.FLAT_OVERVIEW,
    label: {
      web: T.general.navigation.yourFlatOverview,
      mobile: T.general.navigation.yourFlatOverview,
    },
    title: {
      web: T.yourFlat.title,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncContractFlatOverview,
    icon: Icon.Contract,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-sonnen-flat-overiew',
  },
  [RouteName.FLAT_CUSTOMER]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.FLAT_CUSTOMER,
    path: PATHS.FLAT_CUSTOMER,
    label: {
      web: T.general.navigation.yourFlatDetails,
      mobile: T.general.navigation.yourFlatDetails,
    },
    title: {
      web: T.general.navigation.energyTariffCustomerData,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncContractCustomerData,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-sonnen-flat-details',
  },
  [RouteName.FLAT_USER_READINGS]: {
    ...USER_READINGS_SHARED_CONFIG,
    name: RouteName.FLAT_USER_READINGS,
    path: PATHS.FLAT_USER_READINGS,
    navigationDataTestId: 'nav-link-sonnen-flat-meter-readings',
  },
  [RouteName.FLAT_NEW_CONTRACT]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.FLAT_NEW_CONTRACT,
    path: PATHS.FLAT_NEW_CONTRACT,
    label: {
      web: T.yourFlat.sonnenFlatXMigration.general.newContract,
      mobile: T.yourFlat.sonnenFlatXMigration.general.newContract,
    },
    title: {
      web: T.general.pageTitles.sonnenFlat.newContract,
    },
    tag: T.general.labels.new,
    component: ROUTER_ASYNC_COMPONENTS.AsyncProductMigrationNewContract,
    isMainFeatureRoot: false,
    shouldBeActiveFor: RouteName.FLAT_MIGRATION,
  },
  [RouteName.FLAT_MIGRATION]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.FLAT_MIGRATION,
    path: PATHS.FLAT_MIGRATION,
    label: {
      web: T.yourFlat.sonnenFlatXMigration.general.newContract,
    },
    title: {
      web: T.general.pageTitles.sonnenFlat.newContract,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncContractFlatMigration,
    isMainFeatureRoot: false,
  },
  [RouteName.TARIFF]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.TARIFF,
    path: PATHS.TARIFF,
    label: {
      web: T.general.navigation.energyTariff,
      mobile: T.general.navigation.energyTariff,
    },
    title: {
      mobile: T.general.navigation.energyTariff,
    },
    icon: Icon.Thunder,
    component: ROUTER_ASYNC_COMPONENTS.AsyncContractTariff,
    subRoutesRefs: [
      RouteName.TARIFF_OVERVIEW,
      RouteName.TARIFF_CUSTOMER,
      RouteName.TARIFF_USER_READINGS,
    ],
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-energy-tariff',
  },
  [RouteName.TARIFF_OVERVIEW]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.TARIFF_OVERVIEW,
    path: PATHS.TARIFF_OVERVIEW,
    label: {
      web: T.general.navigation.energyTariffOverview,
    },
    title: {
      web: T.general.pageTitles.tariff.overview,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncContractTariffOverview,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-energy-tariff-overview',
  },
  [RouteName.TARIFF_CUSTOMER]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.TARIFF_CUSTOMER,
    path: PATHS.TARIFF_CUSTOMER,
    label: {
      web: T.general.navigation.energyTariffCustomerData,
    },
    title: {
      web: T.general.pageTitles.tariff.customer,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncContractCustomerData,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-energy-tariff-customer-data',
  },
  [RouteName.TARIFF_USER_READINGS]: {
    ...USER_READINGS_SHARED_CONFIG,
    name: RouteName.TARIFF_USER_READINGS,
    path: PATHS.TARIFF_USER_READINGS,
    navigationDataTestId: 'nav-link-energy-tariff-meter-readings',
  },
  [RouteName.ENERGY]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.ENERGY,
    path: PATHS.ENERGY,
    label: {
      web: T.general.navigation.sonnenEnergy,
      mobile: T.general.navigation.sonnenEnergy,
    },
    icon: Icon.Contract,
    component: ROUTER_ASYNC_COMPONENTS.AsyncContractEnergy,
    subRoutesRefs: [
      RouteName.ENERGY_OVERVIEW,
      RouteName.ENERGY_CUSTOMER,
      RouteName.ENERGY_DOCUMENTS,
    ],
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-sonnen-energy',
  },
  [RouteName.ENERGY_OVERVIEW]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.ENERGY_OVERVIEW,
    path: PATHS.ENERGY_OVERVIEW,
    label: {
      web: T.general.navigation.sonnenEnergyOverview,
      mobile: T.general.navigation.sonnenEnergyOverview,
    },
    title: {
      web: T.general.pageTitles.dashboard.sonnenEnergy,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncContractEnergyOverview,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-sonnen-energy-overview',
  },
  [RouteName.ENERGY_CUSTOMER]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.ENERGY_CUSTOMER,
    path: PATHS.ENERGY_CUSTOMER,
    label: {
      web: T.general.navigation.sonnenEnergyCustomerData,
      mobile: T.general.navigation.sonnenEnergyCustomerData,
    },
    title: {
      web: T.general.pageTitles.dashboard.sonnenEnergy,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncContractCustomerData,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-sonnen-energy-customer-data',
  },
  [RouteName.ENERGY_DOCUMENTS]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.ENERGY_DOCUMENTS,
    path: PATHS.ENERGY_DOCUMENTS,
    label: {
      web: T.general.navigation.sonnenEnergyDocuments,
      mobile: T.general.navigation.sonnenEnergyDocuments,
    },
    title: {
      web: T.sonnenEnergy.documents.title,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncContractEnergyDocuments,
    isMainFeatureRoot: false,
  },
  [RouteName.BATTERY]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.BATTERY,
    path: PATHS.BATTERY,
    label: {
      web: T.general.navigation.batterySystem,
    },
    title: {
      web: T.batterySystem.overview.title,
      mobile: T.batterySystem.overview.title,
    },
    icon: Icon.SonnenBattery,
    component: ROUTER_ASYNC_COMPONENTS.AsyncBattery,
    subRoutesRefs: [
      RouteName.BATTERY_OVERVIEW,
      RouteName.BATTERY_DETAILS,
      RouteName.BATTERY_DOWNLOADS,
    ],
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-sonnen-batterie',
  },
  [RouteName.BATTERY_OVERVIEW]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.BATTERY_OVERVIEW,
    path: PATHS.BATTERY_OVERVIEW,
    label: {
      web: T.general.navigation.batterySystemOverview,
      mobile: T.general.navigation.batterySystemOverview,
    },
    title: {
      web: T.batterySystem.overview.title,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncBatteryOverview,
    icon: Icon.SonnenBattery,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-sonnen-batterie-overview',
  },
  [RouteName.BATTERY_DETAILS]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.BATTERY_DETAILS,
    path: PATHS.BATTERY_DETAILS,
    label: {
      web: T.general.navigation.batterySystemDetails,
      mobile: T.general.navigation.batterySystemDetails,
    },
    title: {
      web: T.batterySystem.details.title,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncBatteryDetails,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-sonnen-batterie-details',
  },
  [RouteName.BATTERY_DOWNLOADS]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.BATTERY_DOWNLOADS,
    path: PATHS.BATTERY_DOWNLOADS,
    label: {
      web: T.general.navigation.batteryDownloads,
      mobile: T.general.navigation.batteryDownloads,
    },
    title: {
      web: T.general.navigation.batteryDownloads,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncBatteryDownloads,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-sonnen-batterie-downloads',
  },
  [RouteName.DOCUMENTS]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.DOCUMENTS,
    path: PATHS.DOCUMENTS,
    label: {
      web: T.general.navigation.documents,
    },
    title: {
      web: T.general.navigation.documents,
      mobile: T.general.navigation.documents,
    },
    icon: Icon.Contract,
    component: ROUTER_ASYNC_COMPONENTS.AsyncDocuments,
    subRoutesRefs: [
      RouteName.DOCUMENTS_CONTRACTS,
      RouteName.DOCUMENTS_INVOICES,
      RouteName.DOCUMENTS_SYSTEM_DOCS,
    ],
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-documents',
  },
  [RouteName.DOCUMENTS_CONTRACTS]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.DOCUMENTS_CONTRACTS,
    path: PATHS.DOCUMENTS_CONTRACTS,
    label: {
      web: T.general.navigation.documentsContracts,
      mobile: T.general.navigation.documentsContracts,
    },
    title: {
      web: T.general.pageTitles.documents.contractDocuments,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncDocumentsContracts,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-documents-contracts',
  },
  [RouteName.DOCUMENTS_INVOICES]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.DOCUMENTS_INVOICES,
    path: PATHS.DOCUMENTS_INVOICES,
    label: {
      web: T.general.navigation.documentsInvoices,
      mobile: T.general.navigation.documentsInvoices,
    },
    title: {
      web: T.general.navigation.documentsInvoices,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncDocumentsInvoices,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-documents-invoices',
  },
  [RouteName.DOCUMENTS_SYSTEM_DOCS]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.DOCUMENTS_SYSTEM_DOCS,
    path: PATHS.DOCUMENTS_SYSTEM_DOCS,
    label: {
      web: T.general.navigation.documentsSystemDocuments,
      mobile: T.general.navigation.documentsSystemDocuments,
    },
    title: {
      web: T.general.navigation.documentsSystemDocuments,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncDocumentsSystemDocs,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-documents-system-docs',

  },
  [RouteName.RECRUIT_FRIENDS]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.RECRUIT_FRIENDS,
    path: PATHS.RECRUIT_FRIENDS,
    label: {
      web: T.general.navigation.recruitFriends,
    },
    title: {
      web: T.recruitFriends.title,
      mobile: T.recruitFriends.title,
    },
    meta: T.general.pageTitles.dashboard.recruitFriends,
    icon: Icon.Letter,
    component: ROUTER_ASYNC_COMPONENTS.AsyncRecruitFriends,
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-recruit-friends',
  },
  [RouteName.MY_ACCOUNT]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.MY_ACCOUNT,
    path: PATHS.MY_ACCOUNT,
    label: {
      web: T.general.navigation.myAccount,
    },
    title: {
      web: T.myAccountPages.aboutMe.title,
    },
    icon: Icon.Account,
    component: ROUTER_ASYNC_COMPONENTS.AsyncAccount,
    subRoutesRefs: [
      RouteName.MY_ACCOUNT_ABOUT,
      RouteName.MY_ACCOUNT_PASSWORD,
      RouteName.MY_ACCOUNT_SETTINGS,
    ],
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-my-account',
  },
  [RouteName.MY_ACCOUNT_ABOUT]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.MY_ACCOUNT_ABOUT,
    path: PATHS.MY_ACCOUNT_ABOUT,
    label: {
      web: T.general.navigation.aboutMe,
    },
    title: {
      web: T.myAccountPages.aboutMe.title,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncAccountAboutMe,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-my-account-about',
  },
  [RouteName.MY_ACCOUNT_PASSWORD]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.MY_ACCOUNT_PASSWORD,
    path: PATHS.MY_ACCOUNT_PASSWORD,
    label: {
      web: T.general.navigation.changePassword,
    },
    title: {
      web: T.myAccountPages.password.title,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncAccountPasswordChange,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-my-account-password',
  },
  [RouteName.MY_ACCOUNT_SETTINGS]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.MY_ACCOUNT_SETTINGS,
    path: PATHS.MY_ACCOUNT_SETTINGS,
    label: {
      web: T.general.navigation.settings,
    },
    title: {
      web: T.general.pageTitles.dashboard.settings,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncAccountSettings,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-my-account-settings',
  },
  [RouteName.HELP_AND_SUPPORT]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.HELP_AND_SUPPORT,
    path: PATHS.HELP_AND_SUPPORT,
    label: {
      web: T.general.navigation.helpAndSupport,
    },
    title: {
      mobile: T.general.navigation.helpAndSupport,
    },
    icon: Icon.QuestionMark,
    component: ROUTER_ASYNC_COMPONENTS.AsyncHelp,
    subRoutesRefs: [
      RouteName.HELP_AND_SUPPORT_OVERVIEW,
      RouteName.HELP_AND_SUPPORT_TAX,
      RouteName.HELP_AND_SUPPORT_CONFIRMATION,
    ],
    isMainFeatureRoot: true,
    navigationDataTestId: 'nav-link-help-and-support',
  },
  [RouteName.HELP_AND_SUPPORT_OVERVIEW]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.HELP_AND_SUPPORT_OVERVIEW,
    path: PATHS.HELP_AND_SUPPORT_OVERVIEW,
    label: {
      web: T.general.navigation.support,
    },
    title: {
      web: T.helpAndSupportPage.title,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncHelpSupport,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-support',
  },
  [RouteName.HELP_AND_SUPPORT_TAX]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.HELP_AND_SUPPORT_TAX,
    path: PATHS.HELP_AND_SUPPORT_TAX,
    label: {
      web: T.general.navigation.tax,
    },
    title: {
      web: T.tax.title,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncHelpTax,
    isMainFeatureRoot: false,
    navigationDataTestId: 'nav-link-taxation-help',
  },
  [RouteName.HELP_AND_SUPPORT_CONFIRMATION]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.HELP_AND_SUPPORT_CONFIRMATION,
    path: PATHS.HELP_AND_SUPPORT_CONFIRMATION,
    label: {
      web: T.helpAndSupportConfirmationPage.label,
      mobile: T.helpAndSupportConfirmationPage.label,
    },
    title: {
      web: T.helpAndSupportConfirmationPage.title,
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncHelpSupportConfirmation,
    isMainFeatureRoot: false,
  },
  [RouteName.FEEDBACK]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.FEEDBACK,
    path: PATHS.FEEDBACK,
    label: {
      mobile: T.mobileApp.nav.feedback,
    },
    title: {
      web: T.general.pageTitles.feedback,
      mobile: T.general.pageTitles.feedback,
    },
    icon: Icon.FeedbackStars,
    component: ROUTER_ASYNC_COMPONENTS.AsyncFeedback,
    isMainFeatureRoot: true,
  },
  [RouteName.DEVELOPER]: {
    routeType: RouteType.PROTECTED,
    name: RouteName.DEVELOPER,
    path: PATHS.DEVELOPER,
    label: {
      web: 'Developer',
    },
    component: ROUTER_ASYNC_COMPONENTS.AsyncDeveloper,
    isMainFeatureRoot: true,
  },
  // ==================================================
  // PUBLIC
  // ==================================================
  [RouteName.GUIDE]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE,
    path: PATHS.GUIDE,
    component: Guide,
    label: {
      web: T.customerGuidance.navigation.start,
    },
    icon: Icon.House,
    subRoutesRefs: [
      RouteName.GUIDE_OVERVIEW,
      RouteName.GUIDE_DOCUMENTS,
      RouteName.GUIDE_PRODUCT_INFO,
      RouteName.GUIDE_CONTACT,
      RouteName.GUIDE_DISCOVERY,
      RouteName.GUIDE_PURCHASE,
      RouteName.GUIDE_ACCEPTANCE,
      RouteName.GUIDE_SETUP,
      RouteName.GUIDE_USAGE,
      RouteName.GUIDE_LOGOUT,
    ],
    isMainFeatureRoot: true,
  },
  [RouteName.GUIDE_PHASES]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_PHASES,
    path: PATHS.GUIDE_PHASES,
    label: {
      web: T.customerGuidance.navigation.phases,
    },
    component: GuideOverview,
    icon: Icon.Phases,
    isExactPath: true,
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_CONTACT]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_CONTACT,
    path: PATHS.GUIDE_CONTACT,
    label: {
      web: T.customerGuidance.navigation.contact,
    },
    component: GuideContact,
    icon: Icon.Info,
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_DISCOVERY]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_DISCOVERY,
    path: PATHS.GUIDE_DISCOVERY,
    label: {
      web: T.customerGuidance.navigation.planning,
    },
    component: GuideDiscovery,
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_PURCHASE]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_PURCHASE,
    path: PATHS.GUIDE_PURCHASE,
    label: {
      web: T.customerGuidance.navigation.purchase,
    },
    component: GuidePurchase,
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_ACCEPTANCE]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_ACCEPTANCE,
    path: PATHS.GUIDE_ACCEPTANCE,
    label: {},
    component: GuideAcceptance,
    subRoutesRefs: [
      RouteName.GUIDE_ACCEPTANCE_ID_HARDWARE,
      RouteName.GUIDE_ACCEPTANCE_ID_WRONG_DETAILS,
      RouteName.GUIDE_ACCEPTANCE_ID,
    ],
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_ACCEPTANCE_ID_HARDWARE]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_ACCEPTANCE_ID_HARDWARE,
    path: PATHS.GUIDE_ACCEPTANCE_ID_HARDWARE,
    component: GuideAcceptanceHardwareOffer,
    label: {},
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_ACCEPTANCE_ID_WRONG_DETAILS]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_ACCEPTANCE_ID_WRONG_DETAILS,
    path: PATHS.GUIDE_ACCEPTANCE_ID_WRONG_DETAILS,
    component: GuideAcceptancePersonalDetailsError,
    label: {},
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_ACCEPTANCE_ID]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_ACCEPTANCE_ID,
    path: PATHS.GUIDE_ACCEPTANCE_ID,
    component: GuideAcceptanceStepper,
    label: {},
    isExactPath: true,
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_SETUP]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_SETUP,
    path: PATHS.GUIDE_SETUP,
    label: {
      web: T.customerGuidance.navigation.setup,
    },
    component: GuideSetup,
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_USAGE]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_USAGE,
    path: PATHS.GUIDE_USAGE,
    label: {
      web: T.customerGuidance.navigation.usage,
    },
    component: GuideUsage,
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_DOCUMENTS]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_DOCUMENTS,
    path: PATHS.GUIDE_DOCUMENTS,
    label: {
      web: T.customerGuidance.navigation.documents,
    },
    component: GuideDocuments,
    icon: Icon.Contract,
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_PRODUCT_INFO]: {
    routeType: RouteType.PUBLIC,
    name: RouteName.GUIDE_PRODUCT_INFO,
    path: PATHS.GUIDE_PRODUCT_INFO,
    label: {
      web: T.customerGuidance.navigation.sonnenFlat,
    },
    component: GuideProductInfo,
    icon: Icon.Sun,
  },
  // ==================================================
  // PUBLIC_ONLY
  // ==================================================
  // @TODO: For some reason PUBLIC_ONLY routes are still accessible for authorized users
  [RouteName.LOGIN]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.LOGIN,
    path: PATHS.LOGIN,
    label: {},
    component: AuthLogIn,
    isMainFeatureRoot: true,
  },
  // GUIDE (change route type to PUBLIC to make it available in CU as well)
  [RouteName.GUIDE_OVERVIEW]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.GUIDE_OVERVIEW,
    path: PATHS.GUIDE,
    label: {
      web: T.customerGuidance.navigation.guide,
    },
    component: GuideOverview,
    icon: Icon.HouseOnHill,
    isExactPath: true,
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_MY_ACCOUNT]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.GUIDE_MY_ACCOUNT,
    path: PATHS.GUIDE_MY_ACCOUNT,
    icon: Icon.Account,
    label: {
      web: T.general.navigation.myAccount,
    },
    component: GuideOverview, // @TODO: Use proper component when guide account page is implemented
    isMainFeatureRoot: false,
  },
  [RouteName.GUIDE_LOGOUT]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.GUIDE_LOGOUT,
    path: PATHS.GUIDE_LOGOUT,
    label: {
      web: T.customerGuidance.navigation.logout,
    },
    component: GuideLogout,
    icon: Icon.Logout,
    isMainFeatureRoot: false,
  },
  // DEMO
  [RouteName.DEMO]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.DEMO,
    path: PATHS.DEMO,
    component: ROUTER_ASYNC_COMPONENTS.AsyncDemo,
    label: {},
    subRoutesRefs: [
      RouteName.DEMO_HELLO,
      RouteName.DEMO_DASHBOARD,
      RouteName.DEMO_LIVE_STATE,
      RouteName.DEMO_ANALYSIS,
      RouteName.DEMO_FLAT,
      RouteName.DEMO_BATTERY,
      RouteName.DEMO_COMMUNITY,
    ],
    isMainFeatureRoot: true,
  },
  [RouteName.DEMO_HELLO]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.DEMO_HELLO,
    path: PATHS.DEMO_HELLO,
    label: {
      web: T.general.navigation.aboutMe, // TODO: Change label
    },
    icon: Icon.AboutMe, // TODO: Change icon
    component: DemoHello,
    isMainFeatureRoot: false,
  },
  [RouteName.DEMO_DASHBOARD]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.DEMO_DASHBOARD,
    path: PATHS.DEMO_DASHBOARD,
    label: {
      web: T.general.navigation.dashboard,
    },
    icon: Icon.HouseOnHill,
    component: DemoDashboard,
    isMainFeatureRoot: false,
    navigationDataTestId: 'demo-nav-link-dashboard',
  },
  [RouteName.DEMO_LIVE_STATE]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.DEMO_LIVE_STATE,
    path: PATHS.DEMO_LIVE_STATE,
    label: {
      web: T.general.navigation.liveState,
    },
    icon: Icon.LiveState,
    component: DemoLiveState,
    isMainFeatureRoot: false,
    navigationDataTestId: 'demo-nav-link-live-state',
  },
  [RouteName.DEMO_ANALYSIS]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.DEMO_ANALYSIS,
    path: PATHS.DEMO_ANALYSIS,
    label: {
      web: T.general.navigation.analysis,
    },
    icon: Icon.Analysis,
    component: DemoAnalysis,
    isMainFeatureRoot: false,
    navigationDataTestId: 'demo-nav-link-analysis',
  },
  [RouteName.DEMO_FLAT]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.DEMO_FLAT,
    path: PATHS.DEMO_FLAT,
    label: {
      web: T.general.navigation.yourFlat,
      mobile: T.mobileApp.nav.sonnenFlat,
    },
    icon: Icon.Contract,
    component: DemoContractFlatOverview,
    navigationDataTestId: 'demo-nav-link-sonnen-flat',
  },
  [RouteName.DEMO_BATTERY]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.DEMO_BATTERY,
    path: PATHS.DEMO_BATTERY,
    label: {
      web: T.general.navigation.batterySystem,
    },
    icon: Icon.SonnenBattery,
    component: DemoBatteryOverview,
    isMainFeatureRoot: false,
    navigationDataTestId: 'demo-nav-link-sonnen-batterie',
  },
  [RouteName.DEMO_COMMUNITY]: {
    routeType: RouteType.PUBLIC_ONLY,
    name: RouteName.DEMO_COMMUNITY,
    path: PATHS.DEMO_COMMUNITY,
    label: {
      web: T.general.navigation.sonnenCommunity,
    },
    icon: Icon.Community,
    component: DemoCommunity,
    isMainFeatureRoot: false,
    navigationDataTestId: 'demo-nav-link-sonnen-community',
  },
};

export const getPathsConfig = () => PATHS;

export const getRoutesConfig = () => ROUTES;
