import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { GuideSetupActions } from '+app/+guide/+setup/store';
import {
  getSetupBNetzARegistrationDate,
  getSetupBNetzARegistrationStatus,
} from '+app/+guide/+setup/store/+setup.selectors';
import { GuideAccordion } from '+app/+guide/containers';
import { GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StepName } from '+app/+guide/helpers/steps.config';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { getGuideLeadPartnerName } from '+guide/store/+lead.selectors';
import { getFormattedDate } from '+legacy/helpers/dates';
import {
  GuideSetupStepBNetzARegistrationActive,
  GuideSetupStepBNetzARegistrationDone,
} from '../../components';

const mapStateToProps = (state: StoreState) => ({
  stepStatus: getSetupBNetzARegistrationStatus(state),
  stepDate: getSetupBNetzARegistrationDate(state),
  partnerName: getGuideLeadPartnerName(state),
});

const mapDispatchToProps = mapActions({
  checkBNetzARegistration: GuideSetupActions.checkBNetzARegistration,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & RouteComponentProps
;

export const GuideSetupStepBNetzARegistrationComponent: React.FC<Props> = ({
  stepStatus,
  stepDate,
  partnerName,
  actions,
}) => {
  const isStepChecked = stepStatus === GuideStepStatus.DONE;
  // @TODO: Handle step owner when API is ready.
  const isStepCheckedByPartner = isStepChecked && false;
  const partner = partnerName || I18n.t(T.customerGuidance.yourPartner);
  const content = {
    [GuideStepStatus.ACTIVE]: {
      headline: I18n.t(T.customerGuidance.setup.step.BNetzARegistration.active.headline),
      tag: `${I18n.t(T.customerGuidance.tag.text)} ${I18n.t(T.customerGuidance.or)} ${I18n.t(T.customerGuidance.byName, {name: partner})}`,
      status: 'open',
    },
    [GuideStepStatus.DONE]: {
      headline: I18n.t(T.customerGuidance.setup.step.BNetzARegistration.done.headline),
      tag: I18n.t(T.customerGuidance.tag.done),
      description: stepDate && getFormattedDate(stepDate),
      status: 'done',
    },
  };

  return (
    <GuideAccordion
      status={stepStatus}
      anchor={StepName.BNETZA_REGISTRATION}
      title={content[stepStatus].headline}
      tag={content[stepStatus].tag}
      tagTheme={content[stepStatus].status}
      responsibility={content[stepStatus].responsibility}
      date={content[stepStatus].date}
      isDisabled={isStepCheckedByPartner}
      isChecked={isStepChecked}
      onCheckboxToggle={() => {
        actions.checkBNetzARegistration(isStepChecked);
      }}
    >
      {{
        [GuideStepStatus.ACTIVE]: (
          <GuideSetupStepBNetzARegistrationActive />
        ),
        [GuideStepStatus.DONE]: (
          <GuideSetupStepBNetzARegistrationDone />
        ),
      }}
    </GuideAccordion>
  );
};

export const GuideSetupStepBNetzARegistration =
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GuideSetupStepBNetzARegistrationComponent));
