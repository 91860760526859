import { FormCheckbox } from '@sonnen/shared-web';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './GuideAcceptanceCheckbox.component.scss';

interface Props<T> {
  form: FormikProps<T>;
  name: string;
  label: string;
}

export const GuideAcceptanceCheckbox = <T extends any = any>({
  form,
  name,
  label,
}: Props<T>) => (
  <div className={'c-guide-acceptance-checkbox'}>
    <Field name={name}>
      {({ field: { value } }: FieldProps) => (
        <FormCheckbox
          name={name}
          label={label}
          checked={value}
          onChange={isActive => {
            form.setFieldValue(name, isActive);
            form.setFieldTouched(name, true);
          }}
          isError={Boolean(!!form.errors[name] && form.touched[name])}
        />
      )}
    </Field>
    {!!form.errors[name] && form.touched[name] && (
      <p className={'c-guide-acceptance-checkbox__error-message'}>
        {I18n.t(form.errors[name] as string)}
      </p>
    )}
  </div>
);
