import { validateField } from '+shared/helpers/form.helper';
import { useInputEvent } from '+shared/hooks/useInputEvent';
import { Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { Field, FieldProps, FormikProps } from 'formik';
import * as React from 'react';

import './GuideControlledCheckbox.component.scss';

export interface FormInputProps<T> {
  name: string;
  label?: string;
  form: FormikProps<T>;
  disabled?: boolean;
  isConnected?: boolean;
  isConnectionActive?: boolean;
}

const ObservableInputElement = ({ form, field, ...props }: FieldProps) => {
  const { onChange } = useInputEvent(field);

  return (
    <input
      {...props}
      {...field}
      checked={form.values[field.name]}
      onChange={onChange}
    />
  );
};

export const GuideControlledCheckbox = <T extends any = any>({
  form,
  name,
  label,
  disabled = false,
  isConnected,
  isConnectionActive,
}: FormInputProps<T>) => {
  const validation = validateField(name, form);

  return (
    <div className={classNames('c-guide-controlled-checkbox', {
      'c-guide-controlled-checkbox--error': validation.hasError,
      'c-guide-controlled-checkbox--connected': isConnected,
      'c-guide-controlled-checkbox--connection-active': isConnected && isConnectionActive,
    })}>
      <Field
        component={ObservableInputElement}
        name={name}
        className={'c-guide-controlled-checkbox__input'}
        type={'checkbox'}
        disabled={disabled}
        id={name}
      />
      <label
        className={'c-guide-controlled-checkbox__label'}
        htmlFor={name}
      >
        <span className={'c-guide-controlled-checkbox__box'}>

          <Icon.Checkmark className={'c-guide-controlled-checkbox__box-icon'} />
        </span>
        <div className={'c-guide-controlled-checkbox__text'}>
          {label}
        </div>
      </label>
    </div>
  );
};
