import { CountryFlagProvider } from '@sonnen/shared-web';
import { CountryCode } from '@sonnen/shared-web';
import { SagaIterator } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import { AuthActions } from '+auth/store/auth.actions';
import { isAuthenticated } from '+auth/store/auth.selectors';
import { changeForgotPasswordFailed, changeForgotPasswordSuccess, changeResetPasswordSuccess } from '+legacy/core/app/app.actions';
import { ApiError, extractApiErrors } from '+legacy/helpers/error';
import { isCoolioResponseError } from '+shared/network/network.util';
import { Reporter } from '+utils/reporter.util';
import { combineSagas, dataGuard, processQuery } from '+utils/saga';
import { QueryActions } from '../query/query.actions';
import { User, UserError } from './types/user.interface';
import { OnboardingState, USER_ACTIONS, UserActions } from './user.actions';
import { UserRepository } from './user.repository';
import { getCurrentUserState, getErrorCode, isStatusVerification } from './user.selectors';
import {
  GET_USER_QUERY,
  REGISTER_BY_BATTERY_DATA_QUERY,
  REGISTER_BY_CUSTOMER_NO_QUERY,
  REGISTER_BY_EMAIL_QUERY,
  REQUEST_PASSWORD_RESET_QUERY,
  RESET_PASSWORD_QUERY,
} from './user.state';

type SetUserAction = ReturnType<typeof UserActions.setUser>;

export function * getUser(): SagaIterator {
  const isLoggedIn = yield select(isAuthenticated);
  if (!isLoggedIn) {
    return AuthActions.logOut();
  }
  const currentUser: User = yield select(getCurrentUserState);
  if (!currentUser) {
    yield processQuery(
      GET_USER_QUERY,
      UserRepository.getUser,
      {
        onSuccess: res => dataGuard(UserActions.setUser)(res!),
        onFailure: (error: UserError) => put(QueryActions.failure(GET_USER_QUERY, error as Error)),
      },
    )({});
  } else {
    // TODO think of a better solution when rehydrating store.
    // other solution - mnove CountryFlagProvider.config to store
    CountryFlagProvider.setUserCountry(currentUser.countryCode as CountryCode);
  }
}

export function * registerByEmail({ data }: ReturnType<typeof UserActions.verifyEmail>): SagaIterator {
  yield processQuery(
    REGISTER_BY_EMAIL_QUERY,
    UserRepository.registerByEmail,
    {
      onSuccess: res => dataGuard(UserActions.setOnboardingState)({ status: res!.status, email: res!.email }),
      onFailure: (error: UserError) => call(registerByEmailFailure, error),
    },
  )(data);
}

export function * registerByEmailFailure(error: UserError): SagaIterator {
  if (isCoolioResponseError(error)) {
    const errors: ApiError[] = yield call(extractApiErrors, error.response);

    // NOTE: There's no first name and last name verification from API in this step
    // TODO: We need to verify it in Verification.js page
    const shouldRedirectToVerification = !!errors.filter(isStatusVerification).length;

    if (!shouldRedirectToVerification) {
      return yield put(QueryActions.failure(REGISTER_BY_EMAIL_QUERY, error as Error));
    }
    return yield put(UserActions.setOnboardingState({ status: OnboardingState.VERIFICATION }));
  }
  yield put(QueryActions.failure(REGISTER_BY_EMAIL_QUERY, error as Error));
}

export function * requestResetPassword({ data }: ReturnType<typeof UserActions.requestResetPassword>): SagaIterator {
  yield processQuery(
    REQUEST_PASSWORD_RESET_QUERY,
    UserRepository.requestPasswordReset,
    {
      onSuccess: () => put(changeForgotPasswordSuccess(true)),
      onFailure: (error: UserError) => call(requestResetPasswordFailure, error),
    },
  )(data);
}

// TODO: handle errors when api call fails...checkout `handlePromise`
export function * requestResetPasswordFailure(error: UserError): SagaIterator {
  if (isCoolioResponseError(error)) {
    const errors: ApiError[] = yield call(extractApiErrors, error.response);
    const errorCode = getErrorCode(errors);

    yield put(changeForgotPasswordFailed(errorCode));
    return yield put(QueryActions.failure(REQUEST_PASSWORD_RESET_QUERY, error));
  }
  yield put(QueryActions.failure(REQUEST_PASSWORD_RESET_QUERY, error as Error));
}

export function * resetPassword(
  { data }: ReturnType<typeof UserActions.resetPassword>,
): SagaIterator {
  yield processQuery(
    RESET_PASSWORD_QUERY,
    UserRepository.resetPassword,
    {
      onSuccess: () => put(changeResetPasswordSuccess(true)),
      onFailure: (error: UserError) => put(QueryActions.failure(RESET_PASSWORD_QUERY, error as Error)),
    },
  )(data);
}

export function * registerByBatteryData(
  { data }: ReturnType<typeof UserActions.registerByBatteryData>,
): SagaIterator {
  yield processQuery(
    REGISTER_BY_BATTERY_DATA_QUERY,
    UserRepository.registerByBatteryData,
    {
      onSuccess: res => dataGuard(UserActions.setOnboardingState)({ status: res!.status, email: res!.email }),
      onFailure: (error: UserError) => put(QueryActions.failure(REGISTER_BY_BATTERY_DATA_QUERY, error as Error)),
    },
  )(data);
}

export function * registerByCustomerNo(
  { data }: ReturnType<typeof UserActions.registerByCustomerNo>,
): SagaIterator {
  yield processQuery(
    REGISTER_BY_CUSTOMER_NO_QUERY,
    UserRepository.registerByCustomerNo,
    {
      onSuccess: res => dataGuard(UserActions.setOnboardingState)({ status: res!.status, email: res!.email }),
      onFailure: (error: UserError) => put(QueryActions.failure(REGISTER_BY_CUSTOMER_NO_QUERY, error as Error)),
    },
  )(data);
}

const setUser = ({ user }: SetUserAction) => {
  if (user) {
    Reporter.setUserData({
      id: user.id,
      email: user.email,
    });
  }
};

const setCountryFlagProviderUser = ({ user: { countryCode } }: SetUserAction) => {
  if (!countryCode) { return; }

  CountryFlagProvider.setUserCountry(countryCode as CountryCode);
};

export const userSagas = combineSagas(
  takeEvery(USER_ACTIONS.VERIFY_EMAIL, registerByEmail),
  takeEvery(USER_ACTIONS.REQUEST_RESET_PASSWORD, requestResetPassword),
  takeEvery(USER_ACTIONS.GET_USER, getUser),
  takeEvery(USER_ACTIONS.SET_USER, setUser),
  takeEvery(USER_ACTIONS.SET_USER, setCountryFlagProviderUser),
  takeEvery(USER_ACTIONS.RESET_PASSWORD, resetPassword),
  takeEvery(USER_ACTIONS.REGISTER_BY_BATTERY_DATA, registerByBatteryData),
  takeEvery(USER_ACTIONS.REGISTER_BY_CUSTOMER_NO, registerByCustomerNo),
);
