import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { useInfographic } from '+dashboard/+infographic';
import { InfographicCardLayout } from '../InfographicCardLayout/InfographicCardLayout.component';
import { InfographicHeaterCardHelper } from './InfographicHeaterCard.helpers';
import { InfographicHeaterCardStatus } from './InfographicHeaterCardStatus.component';
import { InfographicHeaterCardValue } from './InfographicHeaterCardValue.component';

interface Props {
  isLoading: boolean;
}

export const InfographicHeaterCard: React.FC<Props> = ({ isLoading = false }) => {
  const { data: { siteLiveData, query } } = useInfographic();

  const status = InfographicHeaterCardHelper.getCardStatus(siteLiveData, query.siteLiveQuery);
  const heaterPowerValue = siteLiveData?.heaterPower;

  return (
    <InfographicCardLayout
      headerDataTestId={'dashboard-infographic-card-heater-header'}
      title={I18n.t(T.dashboard.infographic.heater.title)}
      isLoading={isLoading}
      notifications={(
        <InfographicHeaterCardStatus
          status={status}
          timestamp={siteLiveData?.timestamp}
        />
      )}
    >
      <InfographicHeaterCardValue
        status={status}
        heaterPowerValue={heaterPowerValue}
      />
    </InfographicCardLayout>
  );
};
