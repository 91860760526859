import * as React from 'react';

import './InfographicSecondPlan.component.scss';

interface Props {
  viewbox: string;
  width: number | string | undefined;
  height: number | string | undefined;
}

// tslint:disable:max-line-length
export const InfographicSecondPlan: React.FC<Props> = ({ children, viewbox, width, height }) => (
  <svg
    xmlSpace={'preserve'}
    viewBox={viewbox}
    width={width}
    height={height}
  >
    <g className={'c-infographic-scond-plan__painting-plan c-infographic-scond-plan__painting-plan--second'}>
      <defs>
        <clipPath id={'infographic-clip-path'}>
          <rect fill={'none'} x={'46.99'} y={'405.57'} width={'90.15'} height={'74.34'} />
        </clipPath>
        <clipPath id={'infographic-clip-path-2'}>
          <rect fill={'none'} x={'-431.5'} y={'40.76'} width={'1576'} height={'1735'} />
        </clipPath>
        <clipPath id={'infographic-clip-path-3'}>
          <rect fill={'none'} x={'51.5'} y={'409.76'} width={'65'} height={'69'} />
        </clipPath>
        <clipPath id={'infographic-clip-path-4'}>
          <path fill={'none'} d={'M109.44,434.75a34.91,34.91,0,0,0-5.63-4.94,2.25,2.25,0,0,0-.4-1.61,33.85,33.85,0,0,0,1.93-7.18c.92-5.51.7-10.13-.48-10.32s-2.89,4.12-3.81,9.63a34.17,34.17,0,0,0-.52,7.11,2.3,2.3,0,0,0-1.39,1.71v0a36.25,36.25,0,0,0-6.7,2.84c-5,2.66-8.55,5.67-8,6.71s5.07-.28,10-3a36.43,36.43,0,0,0,5.64-3.64l-2.54,39.24h7.74l-2.45-37.74a39.08,39.08,0,0,0,3.37,4c3.85,4.09,7.7,6.75,8.57,5.95s-1.53-4.78-5.4-8.86'} />
        </clipPath>
        <clipPath id={'infographic-clip-path-5'}>
          <rect fill={'none'} x={'52.5'} y={'409.76'} width={'85'} height={'71'} />
        </clipPath>
        <clipPath id={'infographic-clip-path-6'}>
          <path fill={'none'} d={'M57.59,447.25a29.71,29.71,0,0,1,4.69-4.16,2,2,0,0,1,.33-1.37,28.65,28.65,0,0,1-1.6-6.06c-.77-4.66-.59-8.56.39-8.72s2.42,3.48,3.18,8.13a29.85,29.85,0,0,1,.44,6,1.93,1.93,0,0,1,1.15,1.45h0a29.77,29.77,0,0,1,5.58,2.4c4.15,2.25,7.13,4.79,6.65,5.67s-4.23-.23-8.37-2.49a30.67,30.67,0,0,1-4.7-3.07l2.12,33.15H61l2-31.89a32,32,0,0,1-2.81,3.41c-3.21,3.45-6.41,5.7-7.14,5s1.28-4,4.5-7.49'} />
        </clipPath>
      </defs>
      <polygon
        className={'c-infographic-scond-plan__sky'}
        points={'0 0 770 0 770 640.4 0 640.4 0 0'}
      />
      <path
        className={'c-infographic-scond-plan__background-cloud'}
        d={'M296.7,330.6a17.15,17.15,0,0,0-14.9,8.7,10.07,10.07,0,0,0-5.7-1.7A10.31,10.31,0,0,0,265.8,348a10.4,10.4,0,0,0,0,20.8h48a10.4,10.4,0,0,0,0-20.8A17.16,17.16,0,0,0,296.7,330.6Z'}
      />
      <path
        className={'c-infographic-scond-plan__background-cloud'}
        d={'M194.9,379.2a10.5,10.5,0,0,1,10.5-10.5,10.39,10.39,0,0,1,9.1,5.3,6.06,6.06,0,0,1,3.5-1.1,6.27,6.27,0,0,1,6.3,6.3,6.3,6.3,0,0,1,0,12.6H194.8a6.27,6.27,0,0,1-6.3-6.3A6.42,6.42,0,0,1,194.9,379.2Z'}
      />
      <path
        className={'c-infographic-scond-plan__powerline-pole'}
        d={'M280.7,430.4l-13.9,3.4-.1-2.7c0-1.3-.4-1.9-1.1-1.9s-1.1.8-1.1,2.5l-.1,2.7-15,3.8a3.47,3.47,0,0,0-.8,1.5c-.2.8-.2,1.2,0,1.4l15.7-3.8L262.5,480h6.2l-1.8-43.3,12.8-3.1c.3-.1.6-.7.8-1.7C280.9,431,280.9,430.5,280.7,430.4Z'}
      />
      <path
        className={'c-infographic-scond-plan__powerline-cable'}
        d={'M185.17,460.06a175.55,175.55,0,0,1-40.3-5l.3-1.5c41.1,9.8,77.8,5,109.2-14.2l.4-.3.4.3c26.3,18.6,49.6,23.4,69.1,14.3l.6,1.4c-20,9.4-43.6,4.6-70.2-14.1C233.87,453.66,210.57,460.06,185.17,460.06Z'}
      />
      <path
        className={'c-infographic-scond-plan__powerline-cable'}
        d={'M212.37,462.16a108.87,108.87,0,0,1-13.2-.8c-20-2.2-35.9-9-37.2-11.2l1.3-.8-.6.4.6-.4c.9,1.3,16.6,8.9,39,10.8,20.7,1.8,50.2-1.1,73.4-25.1l.7-.7.5.8c11.5,18.7,28.4,25.4,50.3,19.9l.4,1.5c-22.2,5.6-39.5-1.1-51.4-19.8C256.27,456.86,232.27,462.16,212.37,462.16Z'}
      />
      <g>
        <path
          className={'c-infographic-scond-plan__city'}
          d={'M330.17,460.06v-9.4h-4.7v-8.1a1.1,1.1,0,0,0-2.2,0v8.1h-4.7v9.4h-2.5v35h16.5v-35Zm-4.7-7.6h2.2v2.2h-2.2Zm-4.4,0h2.2v2.2h-2.2Zm0,29.3h-2.2v-2.2h2.2Zm0-4.3h-2.2v-2.2h2.2Zm0-4.4h-2.2v-2.2h2.2Zm0-4.3h-2.2v-2.2h2.2Zm0-4.4h-2.2v-2.2h2.2Zm0-6.5h2.2v2.2h-2.2Zm4.4,23.9h-2.2v-2.2h2.2Zm0-4.3h-2.2v-2.2h2.2Zm0-4.4h-2.2v-2.2h2.2Zm0-4.3h-2.2v-2.2h2.2Zm0-4.4h-2.2v-2.2h2.2Zm0-6.5h2.2v2.2h-2.2Zm4.4,23.9h-2.2v-2.2h2.2Zm0-4.3h-2.2v-2.2h2.2Zm0-4.4h-2.2v-2.2h2.2Zm0-4.3h-2.2v-2.2h2.2Zm0-4.4h-2.2v-2.2h2.2Z'}
        />
        <path
          className={'c-infographic-scond-plan__city'}
          d={'M303.37,463.26V495h10v-31.7Zm4,21.4h-2v-2.1h2Zm0-4.2h-2v-2.1h2Zm0-4.3h-2v-2.1h2Zm0-4.3h-2v-2.1h2Zm0-4.3h-2v-2.1h2Zm4,17.1h-2v-2.1h2Zm0-4.2h-2v-2.1h2Zm0-4.3h-2v-2.1h2Zm0-4.3h-2v-2.1h2Zm0-4.3h-2v-2.1h2Z'}
        />
        <path
          className={'c-infographic-scond-plan__city'}
          d={'M338.17,467.76V495h11.5v-27.2Zm4.6,22.7h-2.3v-2.3h2.3Zm0-4.5h-2.3v-2.3h2.3Zm0-4.5h-2.3v-2.3h2.3Zm0-4.6h-2.3v-2.3h2.3Zm0-4.5h-2.3v-2.3h2.3Zm4.6,18.1h-2.3v-2.3h2.3Zm0-4.5h-2.3v-2.3h2.3Zm0-4.5h-2.3v-2.3h2.3Zm0-4.6h-2.3v-2.3h2.3Zm0-4.5h-2.3v-2.3h2.3Z'}
        />
      </g>
      {/*
        TODO: Weird. Make sure to get new windmills design
        before making them interactive.
      */}
      <g clipPath={'url(#infographic-clip-path)'}>
        <g clipPath={'url(#infographic-clip-path-2)'}>
          <g clipPath={'url(#infographic-clip-path-3)'}>
            <g clipPath={'url(#infographic-clip-path-4)'}>
              <g clipPath={'url(#infographic-clip-path-5)'}>
                <rect
                  className={'c-infographic-scond-plan__windmill'}
                  x={'79.4'}
                  y={'405.7'}
                  width={'40.62'}
                  height={'70.67'}
                />
              </g>
            </g>
            <g clipPath={'url(#infographic-clip-path-6)'}>
              <g clipPath={'url(#infographic-clip-path-5)'}>
                <rect
                  className={'c-infographic-scond-plan__windmill'}
                  x={'47.94'}
                  y={'421.93'}
                  width={'35.51'}
                  height={'61.27'}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <path
        className={'c-infographic-scond-plan__hill'}
        d={'M770,583.4v35.2L0,633.8V486.1c122-25.8,213.1-18.6,344.8,8.2,78.8,16,158.7,60.5,246.9,68.4C668.2,569.4,733.6,591.3,770,583.4Z'}
      />
      <path
        className={'c-infographic-scond-plan__road'}
        d={'M207.4,473.8c28.1,2.6,45.6,3.5,60.1,5.7,15.3,2.3,25.9,4.6,27.5,8.1.8,1.6,0,3.4-2.3,5.3-17.5,14.5-85.3,53.5,105.2,45.6,64.5-.9,149.1,17.1,156,34.1,1.9,4.7-6.5,11-25,19.1-12.3,5.3-58.5,3.2-63.2-3-17.6-23.2-40.1-34.9-67.7-34.9-110.3,0-147.4-1.9-147.4-32.3,0-19.5,30.2-27,32.6-32.6,1-2.3.9-4.3-11.8-6.7-4.8-.9-13.1-1.8-20.7-2.5-14.4-1.4-25.8-2.5-34.4-3.4C194.9,473.9,192,473.1,207.4,473.8Z'}
      />
      <path
        className={'c-infographic-scond-plan__shadow'}
        d={'M117.8,473.5c-14.6,1.5-16.4,4.1-16.4,4.1,7.2,6.6,23.1,10,47.9,10,37.1,0,58-3.9,53.3-7C188.6,471.5,132.4,472.1,117.8,473.5Z'}
      />
      {children}
    </g>
  </svg>
);
