import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '+app/store/store.interface';
import { formatSiteAddress } from '+legacy/helpers/strings';
import { getActiveSite } from '+shared/store/site/site.selectors';
import { Site } from '+shared/store/site/types/site.interface';

import './SiteSwitcherSingleSite.component.scss';

interface Props {
  activeSite?: Site;
}

export const SiteSwitcherSingleSiteComponent: React.SFC<Props> = ({ activeSite }) => {
  if (!activeSite) {
    return null;
  }

  return (
    <div className={'c-site-switcher-single-site'}>
      {formatSiteAddress(activeSite)}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  activeSite: getActiveSite(state),
});

// TODO: REFACTOR WHOLE SITE SWITCHER!
export const SiteSwitcherSingleSite = connect(mapStateToProps)(SiteSwitcherSingleSiteComponent);
