import {
  SupportMessageAttributes,
  SupportMessageRelationship,
} from '+help/store/types/supportMessage.interface';
import { Taxation } from '+help/store/types/taxation.interface';
import { ActionsUnion, createAction } from '+utils/redux';
import { Moment } from 'moment';

export enum HELP_ACTIONS {
  GET_TAXATION_DATA = '[Help] GET_TAXATION_DATA',
  SET_TAXATION_DATA = '[Help] SET_TAXATION_DATA',
  SEND_SUPPORT_MESSAGE = '[Help] SEND_SUPPORT_MESSAGE',
}

export const HelpActions = {
  getTaxationData: ({ startDate, endDate }: { startDate: Moment, endDate: Moment }) =>
    createAction(HELP_ACTIONS.GET_TAXATION_DATA, { startDate, endDate }),
  setTaxationData: (data: Taxation) =>
    createAction(HELP_ACTIONS.SET_TAXATION_DATA, { data }),
  sendSupportMessage: (attributes: SupportMessageAttributes, relationship: SupportMessageRelationship) =>
    createAction(HELP_ACTIONS.SEND_SUPPORT_MESSAGE, { attributes, relationship }),
};

export type HelpActions = ActionsUnion<typeof HelpActions>;
