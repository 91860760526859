import { Nav, NavItem, NavListItem } from '@sonnen/shared-web';
import { IconComponent } from '@sonnen/shared-web/src/components/Icon/models';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { Route } from '+app/router/router.types';

import './NavBottom.component.scss';

export type NavBottomButton = {
  label: string;
  icon?: IconComponent;
  action: () => void;
  isActive?: boolean;
  isIconRotated?: boolean;
};

interface Props {
  routes: Route[];
  buttons?: NavBottomButton[];
}

export const NavBottom: React.FC<Props> = ({ routes, buttons }) => (
  <>
    <div className={'c-nav-bottom'}>
      <nav className={'c-nav-bottom__nav'}>
        <Nav isHorizontal={true}>
          {routes.map((route) => (
            <NavListItem key={`nav-bottom-routes-${route.name}`}>
              <NavItem
                icon={route.icon && <route.icon />}
                action={route.path}
                isVertical={true}
                isThemeNoSpacing={true}
              >
                {I18n.t(route.label.mobile || route.label.web || '')}
              </NavItem>
            </NavListItem>
          ))}
          {buttons && buttons.map((button) => (
            <NavListItem key={`nav-bottom-buttons-${button.label}`}>
              <NavItem
                icon={button.icon && <button.icon />}
                action={button.action}
                isActive={button.isActive}
                isIconRotated={button.isIconRotated}
                isVertical={true}
                isThemeNoSpacing={true}
              >
                {I18n.t(button.label)}
              </NavItem>
            </NavListItem>
          ))}
        </Nav>
      </nav>
    </div>
    <span className={'c-nav-bottom__foundation'} />
  </>
);
