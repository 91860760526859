import * as React from 'react';
import * as uuid from 'uuid';
import { ContractListRow } from '../';

import './ContractEnergyInvoicesHeader.component.scss';

interface Props {
  headers: string[];
}

export const ContractEnergyInvoicesHeader: React.FC<Props> = ({ headers }) => (
  <ContractListRow>
    {headers.map(header => (
      <div
        key={uuid.v4()}
        className={'c-contract-energy-invoices-header__text'}
      >
        {header}
      </div>
    ))}
  </ContractListRow>
);
