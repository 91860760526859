import { isNil } from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { PortalExit } from '+shared/helpers';

interface Props {
  target: PortalExit;
}

export const Portal: React.FC<Props> = ({
  target,
  children,
}) => {
  const root = document.getElementById(target);

  return !isNil(root)
    ? ReactDOM.createPortal(
        <>
          {children}
        </>,
        root,
      )
    : (
      <>
        {children}
      </>
    );
};
