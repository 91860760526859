import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './BatteryCardAccessories.component.scss';

export const BatteryCardAccessories: React.FC = () => (
  <div className={'c-battery-card-accessories'}>
    <div className={'c-battery-card-accessories__content'}>
      <div className={'c-battery-card-accessories__icon'}>
        <Icon.Accessories />
      </div>
      <p className={'c-battery-card-accessories__title'}>
        {I18n.t(T.batterySystem.overview.addAccessory.title)}
      </p>
      <DefaultParagraph>
        {I18n.t(T.batterySystem.overview.addAccessory.description)}
        <div className={'c-battery-card-accessories__link'}>
          <a
            data-test-id={'sonnen-batterie-overview-accessories-link'}
            href={I18n.t(T.batterySystem.overview.addAccessory.buttonLink)}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            {I18n.t(T.batterySystem.overview.addAccessory.buttonLabel)}
          </a>
        </div>
      </DefaultParagraph>
    </div>
  </div>
);
