import { T } from '@sonnen/shared-i18n/customer';
import {
  Button,
  ButtonSize,
  ButtonTheme,
  Icon,
  MediaCard,
} from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router';

import { getOfferById } from '+app/+guide/+purchase/store/+offer.selectors';
import { StepName } from '+app/+guide/helpers/steps.config';
import { OfferProductType } from '+app/+guide/store/types/offer.interface';
import { getPath } from '+app/router/router.helper';
import { RouteName, RouterGuideAcceptanceRouteParams } from '+app/router/router.types';
import { StoreState } from '+app/store/store.interface';
import { history } from '+router/store';
import { isSpecificFlatProductInOffer } from '../../store/+acceptance.helper';

import './GuideAcceptanceCompletion.component.scss';

const mapStateToProps = (
  state: StoreState,
  { match: { params } }: RouteComponentProps<RouterGuideAcceptanceRouteParams>,
) => ({
  offer: getOfferById(state)(params),
});

type Props = ReturnType<typeof mapStateToProps>;

const GuideAcceptanceCompletionButton: React.FC = () => (
  <div className={'c-guide-acceptance-completion__button'}>
    <Button
      label={I18n.t(T.customerGuidance.acceptance.completion.buttonCta)}
      size={ButtonSize.SECONDARY}
      theme={ButtonTheme.PRIMARY}
      onClick={() => history.push(getPath(RouteName.GUIDE_PURCHASE_STEP, [StepName.FLAT_ORDER_CONFIRMATION]))}
    />
  </div>
);

const GuideAcceptanceCompletionComponent: React.FC<Props> = ({ offer }) => (
  <div className={'c-guide-acceptance-completion'}>
    <MediaCard>
      <Icon.Confetti className={'c-guide-acceptance-completion__icon'} />
      <div className={'c-guide-acceptance-completion__title'}>
        {offer && isSpecificFlatProductInOffer(offer, OfferProductType.FLAT_X)
          ? I18n.t(T.customerGuidance.acceptance.completion.title)
          : I18n.t(T.customerGuidance.acceptance.completion.titleFlatDirect)
        }
      </div>
      <GuideAcceptanceCompletionButton />
    </MediaCard>
  </div>
);

export const GuideAcceptanceCompletion = withRouter(connect(
  mapStateToProps,
)(GuideAcceptanceCompletionComponent));
