import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, PageHeadline } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getGuideLead } from '+app/+guide/store/+lead.selectors';
import { StoreState } from '+app/store/store.interface';

import { PhasesOverview } from '+app/shared/containers/PhasesOverview';

import './GuideOverview.component.scss';

const mapStateToProps = (state: StoreState) => ({
  lead: getGuideLead(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>;

export const GuideOverviewContainer: React.FC<Props> = ({ lead }) => {
  return (
    <>
      <PageHeadline smallGap={true}>
        {I18n.t(T.customerGuidance.overview.headline, {
          salutation: lead!.salutation,
          fullName: `${lead!.firstName} ${lead!.lastName}`,
        })}
      </PageHeadline>
      <div className={'c-guide-overview__subheadline'}>
        <DefaultParagraph>
          {I18n.t(T.customerGuidance.overview.subheadline)}
        </DefaultParagraph>
      </div>
      <PhasesOverview />
    </>
  );
};

export const GuideOverview = connect(mapStateToProps)(GuideOverviewContainer);
