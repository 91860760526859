import { Modal } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';

import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+utils/redux';
import { ContractActions } from '../../store';
import { getCurrentSiteContractId, getTerminateContractQueryStatus } from '../../store/contract.selectors';
import { ModalConfirm } from './ModalConfirm.component';
import { ModalSuccess } from './ModalSuccess.component';

const mapStateToProps = (state: StoreState) => ({
  contractId: getCurrentSiteContractId(state),
  terminateContractQueryStatus: getTerminateContractQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  terminateContract: ContractActions.terminateContract,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & {
    isOpen: boolean;
    closeModal: () => void;
  }
;

const ContractTerminationModalComponent = ({
  isOpen,
  closeModal,
  terminateContractQueryStatus,
  actions,
  contractId,
}: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={closeModal}
    size={'auto'}
  >
    {terminateContractQueryStatus.success
      ? <ModalSuccess />
      : (
        <ModalConfirm
          onAccept={() => actions.terminateContract(contractId)}
          isAcceptPending={terminateContractQueryStatus.pending}
          closeModal={closeModal}
        />
      )
    }
  </Modal>
);

export const ContractTerminationModal = connect(mapStateToProps, mapDispatchToProps)(ContractTerminationModalComponent);
