import { RouterActionType, RouterState as ConnectedRouterState } from 'connected-react-router';
import { Location } from 'history';

export type RouterState = ConnectedRouterState & {
  history: Location[];
  previousAction: RouterActionType | undefined;
};

export const initialState: RouterState = {
  history: [],
  previousAction: undefined,
} as any;
