import { delay, put, race, take } from 'redux-saga/effects';

type PollingOptions = {
  fetchAction: string,
  endAction: string | string[],
  interval: number,
};

export function* handleDataPolling(opts: PollingOptions) {
  yield put({ type: opts.fetchAction });

  while (true) {
    const { stopped } = yield race({
      wait: delay(opts.interval),
      stopped: take(opts.endAction),
    });

    if (!stopped) {
      yield put({ type: opts.fetchAction });
    } else {
      break;
    }
  }
}
