import * as React from 'react';

import { Toast } from '+components';
import { NotificationCategory } from '+shared/store/notification/types/notification.interface';

import './DemoNotification.component.scss';

interface Props {
  buttonLabel: React.ReactNode;
  onClick: () => void;
}

export const DemoNotification: React.FC<Props> = ({
  buttonLabel,
  onClick,
  children,
}) => (
  <div className={'c-demo-notification'}>
    <Toast
      type={NotificationCategory.INFO}
      button={{
        label: buttonLabel,
        onClick,
      }}
    >
      {children}
    </Toast>
  </div>
);
