import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { CountryHelper } from '+app/shared/helpers/country.helper';
import { Lead } from '+guide/store/types';
import { WarningText } from '@sonnen/shared-web';

import './GuideAcceptancePersonalDataTable.component.scss';

interface Props {
  lead: Lead;
}

export const GuideAcceptancePersonalDataTable: React.FC<Props> = ({ lead }) => {
  const houseNumberRegex = /\d+\s*[a-zA-Z]*\s*(?:[/-]\s*\d+\s*)*/;

  return (
    <>
      <table className={'c-guide-acceptance-personal-data-table'}>
        <tbody className={'c-guide-acceptance-personal-data-table__body'}>
          {lead && (
            <>
              {lead.company && lead.company.name && (
                <tr className={'c-guide-acceptance-personal-data-table__row'}>
                  <td className={'c-guide-acceptance-personal-data-table__key'}>
                    {`${I18n.t(T.customerGuidance.acceptance.personalDetails.companyName)}:`}
                  </td>
                  <td className={'c-guide-acceptance-personal-data-table__value'}>
                    {lead.company.name}
                  </td>
                </tr>
              )}
              {lead.salutation && (
                <tr className={'c-guide-acceptance-personal-data-table__row'}>
                  <td className={'c-guide-acceptance-personal-data-table__key'}>
                    {`${I18n.t(T.customerGuidance.acceptance.personalDetails.salutation)}:`}
                  </td>
                  <td className={'c-guide-acceptance-personal-data-table__value'}>
                    {I18n.t(T.general.salutation[lead.salutation])}
                  </td>
                </tr>
              )}
              {lead.title && (
                <tr className={'c-guide-acceptance-personal-data-table__row'}>
                  <td className={'c-guide-acceptance-personal-data-table__key'}>
                    {`${I18n.t(T.customerGuidance.acceptance.personalDetails.title)}:`}
                  </td>
                  <td className={'c-guide-acceptance-personal-data-table__value'}>
                    {lead.title}
                  </td>
                </tr>
              )}
              {lead.firstName && (
                <tr className={'c-guide-acceptance-personal-data-table__row'}>
                  <td className={'c-guide-acceptance-personal-data-table__key'}>
                    {`${I18n.t(T.customerGuidance.acceptance.personalDetails.firstName)}:`}
                  </td>
                  <td className={'c-guide-acceptance-personal-data-table__value'}>
                    {lead.firstName}
                  </td>
                </tr>
              )}
              {lead.lastName && (
                <tr className={'c-guide-acceptance-personal-data-table__row'}>
                  <td className={'c-guide-acceptance-personal-data-table__key'}>
                    {`${I18n.t(T.customerGuidance.acceptance.personalDetails.lastName)}:`}
                  </td>
                  <td className={'c-guide-acceptance-personal-data-table__value'}>
                    {lead.lastName}
                  </td>
                </tr>
              )}
              {lead.email && (
                <tr className={'c-guide-acceptance-personal-data-table__row'}>
                  <td className={'c-guide-acceptance-personal-data-table__key'}>
                    {`${I18n.t(T.customerGuidance.acceptance.personalDetails.email)}:`}
                  </td>
                  <td className={'c-guide-acceptance-personal-data-table__value'}>
                    {lead.email}
                  </td>
                </tr>
              )}
              {lead.phone && (
                <tr className={'c-guide-acceptance-personal-data-table__row'}>
                  <td className={'c-guide-acceptance-personal-data-table__key'}>
                    {`${I18n.t(T.customerGuidance.acceptance.personalDetails.phone)}:`}
                  </td>
                  <td className={'c-guide-acceptance-personal-data-table__value'}>
                    {lead.phone}
                  </td>
                </tr>
              )}
              {lead.mobilePhone && (
                <tr className={'c-guide-acceptance-personal-data-table__row'}>
                  <td className={'c-guide-acceptance-personal-data-table__key'}>
                    {`${I18n.t(T.customerGuidance.acceptance.personalDetails.mobilePhone)}:`}
                  </td>
                  <td className={'c-guide-acceptance-personal-data-table__value'}>
                    {lead.mobilePhone}
                  </td>
                </tr>
              )}
              {lead.deliveryAddress && (
                <tr className={'c-guide-acceptance-personal-data-table__row'}>
                  <td className={'c-guide-acceptance-personal-data-table__key'}>
                    {`${I18n.t(T.customerGuidance.acceptance.personalDetails.deliveryAddress)}:`}
                  </td>
                  <td className={'c-guide-acceptance-personal-data-table__value'}>
                    {lead.deliveryAddress.street}<br />
                    {lead.deliveryAddress.zipCode}{' '}
                    {lead.deliveryAddress.city}<br />
                    {CountryHelper.getCountryName(lead.deliveryAddress.country)}
                  </td>
                </tr>
              )}
              {lead.invoiceAddress && (
                <tr className={'c-guide-acceptance-personal-data-table__row'}>
                  <td className={'c-guide-acceptance-personal-data-table__key'}>
                    {`${I18n.t(T.customerGuidance.acceptance.personalDetails.billingAddress)}:`}
                  </td>
                  <td className={'c-guide-acceptance-personal-data-table__value'}>
                    {lead.invoiceAddress.street}<br />
                    {lead.invoiceAddress.zipCode}{' '}
                    {lead.invoiceAddress.city}<br />
                    {CountryHelper.getCountryName(lead.invoiceAddress.country)}
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
      {houseNumberRegex.test(lead.deliveryAddress.street) ? null :
        <div className={'c-guide-acceptance-personal-data-table__warning'}>
          <WarningText text={I18n.t(T.customerGuidance.acceptance.personalDetails.houseNumberWarning)} />
        </div>
      }
    </>
  );
};
