import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, DS, PageSubheadline } from '@sonnen/shared-web';
import {
  ButtonSize,
  ButtonStatus,
  ButtonStyle,
  ButtonType,
} from '@sonnen/shared-web/src/ds/components/Button/Button.component';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { ContractTerminationModal } from '../ContractTerminationModal';

import './ContractTermination.component.scss';

interface Props {
  isTerminationPending: boolean;
}

export const ContractTermination = ({ isTerminationPending }: Props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  return (
    <section className={'c-contract-termination'}>
      <PageSubheadline>
        {I18n.t(T.yourFlat.terminateSection.headline)}
      </PageSubheadline>
      <div className={'c-contract-termination__content'}>
        <DefaultParagraph>
          {I18n.t(T.yourFlat.terminateSection.description)}
        </DefaultParagraph>
        <div className={'c-contract-termination__button'}>
          <DS.Button
            label={I18n.t(T.yourFlat.terminateSection.button)}
            onClick={() => setIsModalOpen(true)}
            disabled={isTerminationPending}
            type={ButtonType.SECONDARY}
            status={isTerminationPending ? ButtonStatus.DISABLED : ButtonStatus.ENABLED}
          />
        </div>
      </div>
      <ContractTerminationModal
        closeModal={() => setIsModalOpen(false)}
        isOpen={isModalOpen}
      />
    </section>
  );
};
