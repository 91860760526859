import { PlatformSelector } from '+components';
import { T } from '@sonnen/shared-i18n/customer';
import { PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';
import { CommunityCarousel } from '../CommunityCarousel/CommunityCarousel.component';
import { mobileCarouselSettings, webCarouselSettings } from './CommunityNews.config';

export const CommunityNews: React.FC = () => (
  <>
    <PageSubheadline>
      <Translate value={T.sonnenCommunity.carouselHeadline} />
    </PageSubheadline>
    <PlatformSelector
      web={<CommunityCarousel carouselSettings={webCarouselSettings} />}
      mobile={<CommunityCarousel carouselSettings={mobileCarouselSettings} hasFullWidth={true} />}
    />
  </>
);
