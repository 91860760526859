import { Query } from '+shared/store/query/query.state';
import { ReverseChannelToken, ReverseChannelTokenAttributes } from './types/reverseChannel.interface';

export const GET_TOKENS_QUERY = 'getTokensQuery';
export const GENERATE_REVERSE_CHANNEL_TOKEN_QUERY = 'getReverseChannelTokenQuery';

export enum AuthContext {
  AUTH,
  SALESFORCE_PREAUTH,
  SALESFORCE_AUTH,
}

export interface Tokens {
  accessToken: string;
  refreshToken: string;
  tokenType: string;
  expiresIn: number;
}

export interface AuthSalesforceParams {
  accessToken: string;
  refreshToken?: string;
  idToken: string;
  tokenType: string;
  issuedAt?: string;
  state: string;
  userId?: string;
}

export interface AuthParams {
  code: string;
  state: string;
}

export interface AuthState {
  // Property determining if user should see landing page or not
  hasLoggedInOnce: boolean;
  // Holds the number of failed authorization redirections
  retryCount: number;
  // Code for fetching access token and refresh token for standard auth
  code?: string;
  // Usually "Bearer", sent with the access token header
  tokenType?: string;
  // Verifier for auth code challenge
  verifier?: string;
  accessToken?: string;
  refreshToken?: string;
  impersonatedUserId?: string;
  reverseChannelToken?: ReverseChannelTokenAttributes;
  tokenForAction: string;
  lastAccessedAuthUrl?: string;
  [GENERATE_REVERSE_CHANNEL_TOKEN_QUERY]: Query<ReverseChannelToken>;
  [GET_TOKENS_QUERY]: Query<Tokens>;
}

export const initialState: AuthState = {
  hasLoggedInOnce: false,
  retryCount: 0,
  code: undefined,
  verifier: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  impersonatedUserId: undefined,
  tokenType: undefined,
  tokenForAction: '',
  [GENERATE_REVERSE_CHANNEL_TOKEN_QUERY]: {},
  [GET_TOKENS_QUERY]: {},
};
