import { combineSagas, dataGuard, processQuery } from '+utils/saga';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { GuideActions } from '+app/+guide/store/+guide.actions';
import { getGuideLeadId } from '+app/+guide/store/+lead.selectors';
import { GUIDE_SETUP_ACTIONS, GuideSetupActions } from './+setup.actions';
import { GuideSetupRepository } from './+setup.repository';
import { SET_SETUP_STEP_QUERY } from './+setup.state';

function* setTaxOfficeRegistrationSaga({ checked }:
  ReturnType<typeof GuideSetupActions.checkTaxOfficeRegistration>) {
    const leadId: string = yield select(getGuideLeadId);
    
    yield processQuery(
      SET_SETUP_STEP_QUERY,
      () => GuideSetupRepository.setTaxOfficeRegistration(leadId, checked),
      {
        onSuccess: data => all([
          put(GuideSetupActions.toggleModal(checked)),
          dataGuard(GuideActions.setTaxOfficeRegistration)(data!.meta),
        ]),
        onFailure: () => { throw new Error('Could not save tax office registration step.'); },
      },
    )({});
}

function* setBNetzARegistrationSaga({ checked }:
  ReturnType<typeof GuideSetupActions.checkBNetzARegistration>) {
    const leadId: string = yield select(getGuideLeadId);
    
    yield processQuery(
      SET_SETUP_STEP_QUERY,
      () => GuideSetupRepository.setBNetzARegistration(leadId, checked),
      {
        onSuccess: data => all([
          put(GuideSetupActions.toggleModal(checked)),
          dataGuard(GuideActions.setBNetzARegistration)(data!.meta),
        ]),
        onFailure: () => { throw new Error('Could not save BNetzA registration step.'); },
      },
    )({});
}

export const sagas = combineSagas(
  takeEvery(GUIDE_SETUP_ACTIONS.CHECK_TAX_OFFICE_REGISTRATION, setTaxOfficeRegistrationSaga),
  takeEvery(GUIDE_SETUP_ACTIONS.CHECK_BNETZA_REGISTRATION, setBNetzARegistrationSaga),
);
