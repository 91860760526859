import * as React from 'react';
import { getCommunityGeoData, MapData } from '../communityMap.api';

interface State {
  mapData?: MapData;
  error?: Error | null;
}

interface InjectedProps {
  loadCommunityData?(): void;
}

export const withCommunityData =
  <P extends object = {}>
    (EnhancedComponent: React.ComponentType<P & State & InjectedProps>) => (
      class CommunityData extends React.PureComponent<P, State> {
        componentDidMount() {
          this.loadCommunityData();
        }

        loadCommunityData = () => {
          this.getMapData();
        };

        getMapData() {
          getCommunityGeoData()
            .then(([world, batteries, ambassadors]) => this.setState(state => ({
              ...state,
              mapData: { world, batteries, ambassadors },
              error: null,
            })))
            .catch(error => this.setState((state) => ({ ...state, error })));
        }

        render() {
          return (
            <EnhancedComponent
              {...this.props}
              {...this.state}
              loadCommunityData={this.loadCommunityData}
            />
          );
        }
      }
    );

interface RenderProps extends State, InjectedProps {
  loading: boolean;
}

interface Props extends State, InjectedProps {
  children(response: RenderProps): JSX.Element;
}

export const CommunityDataComponent: React.SFC<Props> = ({
  children,
  error,
  ...props
}) => children({ loading: false, error, ...props });

export const CommunityData = withCommunityData<Props>(CommunityDataComponent);
