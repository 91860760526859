import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { storeProvider } from '+app/store/store.provider';
import { NotificationContext } from '+shared/containers/NotificationProvider/NotificationContext';
import { NotificationActions } from '+shared/store/notification/notification.actions';
import { Notification, NotificationBannerContent } from '+shared/store/notification/types/notification.interface';
import { NotificationBannerWeb } from './NotificationBanner.web';

const onNotificationAction = (notification: Notification) => {
  const { id, content: { actionButton } } = notification;

  storeProvider.dispatch(NotificationActions.removeNotification(id));
  if (actionButton && actionButton.actions && actionButton.actions.length) {
    actionButton.actions.forEach(action => storeProvider.dispatch(action));
  }
};

const getNotificationButton = (notification: Notification) =>
  notification.content && notification.content.actionButton ? {
    ...notification.content.actionButton,
    label: I18n.t(notification.content.actionButton.label).toUpperCase(),
    onClick: () => onNotificationAction(notification),
  } : undefined;

const getTranslationWithOptions = (code: string, options: any) => {
  const translatedOptions = Object.keys(options).reduce((res, key) => ({
    ...res,
    [key]: I18n.t(options[key]),
  }), {});

  return I18n.t(code, translatedOptions);
};

const factorizeNotifications = ({ notifications }: NotificationContext) => {
  const visibleNotifications: Array<{
    content: NotificationBannerContent,
    id: string,
  }> = notifications.map(notification => {
    const { content: { description, title } } = notification;

    return {
      content: {
        ...notification.content,
        description: typeof description === 'string'
          ? <Translate value={description} dangerousHTML={true} />
          : getTranslationWithOptions(...description),
        title: typeof title === 'string'
          ? I18n.t(title)
          : getTranslationWithOptions(...title),
        actionButton: getNotificationButton(notification),
      },
      id: notification.id,
    };
  });

  return (
    <>
      {visibleNotifications.map(({content, id}) => <NotificationBannerWeb key={id} {...content} />)}
    </>
  );
};

export const Notifications = () => (
  <NotificationContext.Consumer>
    {(props: NotificationContext) => factorizeNotifications(props)}
  </NotificationContext.Consumer>
);
