import * as React from 'react';

import { AccountPasswordChange } from '+app/+dashboard/+account';
import { NestedNavSlideContent } from '../../NestedNav/NestedNavSlideContent';

export const MobileSideNavNestedAccountPassword: React.FC = () => (
  <NestedNavSlideContent
    hasGap={true}
  >
    <AccountPasswordChange />
  </NestedNavSlideContent>
);
