import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { GuideGridStretched, GuideInfoCard, GuideInfoCardText } from '+app/+guide/components';
import { GuideAccordion } from '+app/+guide/containers';
import { factorizeStepDescription, GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StepName } from '+app/+guide/helpers/steps.config';
import { getGuideLeadPartnerName, getLeadStatus } from '+app/+guide/store/+lead.selectors';
import { LeadStatusName } from '+app/+guide/store/types';
import { StoreState } from '+app/store/store.interface';
import { getFormattedDate } from '+legacy/helpers/dates';
import { getHardwareOrderConfirmationStepStatus } from '../../store/+purchase.selectors';

const mapStateToProps = (state: StoreState) => ({
  orderConfirmedStatus: getLeadStatus(LeadStatusName.HW_ORDER_CONFIRMED)(state),
  stepStatus: getHardwareOrderConfirmationStepStatus(state),
  partnerName: getGuideLeadPartnerName(state),
});

type Props =
  & RouteComponentProps
  & ReturnType<typeof mapStateToProps>
  ;

const GuidePurchaseStepHardwareOrderConfirmationComponent: React.FC<Props> = ({
  stepStatus,
  orderConfirmedStatus,
  partnerName,
}) => {
  const formattedDate = getFormattedDate(orderConfirmedStatus?.createdAt);
  const content = {
    [GuideStepStatus.DONE]: {
      headline: I18n.t(T.customerGuidance.purchase.step.hardwareOrderConfirmation.headline.active),
      tag: I18n.t(T.customerGuidance.tag.done),
      status: 'done',
      description: !isNil(formattedDate)
        ? factorizeStepDescription(
            formattedDate, I18n.t(T.customerGuidance.byName, {name: partnerName}),
          )
        : I18n.t(T.customerGuidance.byName, {name: partnerName}),
    },
  };
  const headlines = {
    done: I18n.t(T.customerGuidance.purchase.step.hardwareOrderConfirmation.headline.active),
  };

  return (
    <GuideAccordion
      status={stepStatus}
      anchor={StepName.HARDWARE_ORDER_CONFIRMATION}
      title={headlines[stepStatus]}
      tag={content[stepStatus].tag}
      tagTheme={content[stepStatus].status}
      responsibility={content[stepStatus].responsibility}
      date={content[stepStatus].date}
    >
      {{
        [GuideStepStatus.DONE]: (
          <GuideGridStretched>
            <GuideInfoCard
              header={I18n.t(T.customerGuidance.purchase.step.hardwareOrderConfirmation.done.headline)}
              headerIcon={<Icon.Confetti />}
            >
              <GuideInfoCardText
                subtitle={I18n.t(T.customerGuidance.purchase.step.hardwareOrderConfirmation.done.title)}
                contentText={I18n.t(T.customerGuidance.purchase.step.hardwareOrderConfirmation.done.description)}
              />
            </GuideInfoCard>
          </GuideGridStretched>
        ),
      }}
    </GuideAccordion>
  );
};

export const GuidePurchaseStepHardwareOrderConfirmation = compose(
  withRouter,
  connect(mapStateToProps),
)(GuidePurchaseStepHardwareOrderConfirmationComponent) as React.ComponentType<any>;
