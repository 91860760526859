import { T } from '@sonnen/shared-i18n/customer';
import {
  ClickOutside,
  DayPickerSelects,
  getMonthTranslations,
  getWeekDaysShortTranslations,
  getWeekDaysTranslations,
  Icon,
} from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { FormikProps } from 'formik';
import { get } from 'lodash/fp';
import * as moment from 'moment';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { DateHelper } from '+legacy/helpers/dates';
import { provideLocale } from '+legacy/helpers/i18n.helper';
import { FormInput } from '+shared/components/FormInput';
import { useDispatchInputEvent } from '+shared/hooks/useDispatchInputEvent';
import { DateUtil } from '+utils/date.util';
import { formatDate } from '+utils/format.util';

import './FormInputDate.component.scss';

const DEFAULT_MIN_DATE = new Date('1970-01-01');
const DEFAULT_MAX_DATE = moment().add(2, 'years').toDate();

interface Props<T> {
  name: string;
  label?: string;
  form: FormikProps<T>;
  minDate?: Date;
  maxDate?: Date;
  horizontalLabel?: boolean;
  isBottom?: boolean;
  isIconVisible?: boolean;
  className?: ClassValue;
  inputClassName?: ClassValue;
  id?: string;
  hasNoGap?: boolean;
  autocomplete?: string;
  labelClass?: string;
  inputClass?: string;
}

export const FormInputDate = <T extends any = any>({
  form,
  label,
  name,
  maxDate,
  minDate,
  className,
  horizontalLabel,
  isBottom,
  isIconVisible,
  inputClassName,
  hasNoGap = false,
  id,
  autocomplete,
  labelClass,
  inputClass,
}: Props<T>) => {
  const [isDatePickerActive, setDatePickerActive] = React.useState(false);
  const { onChange: onChangeDispatch } = useDispatchInputEvent();

  const onDateClick = (date: Date) => {
    if (minDate) {
      if (date > minDate || DateUtil.isSameDay(date, minDate)) {
        return onDateChange(date);
      }
    }
  };

  const onDateChange = (date: Date) => {
    const dateString = formatDate(date, 'YYYY-MM-DD');
    form.setFieldValue(name, dateString);
    form.setFieldTouched(name);
    onChangeDispatch(name, dateString);
    setDatePickerActive(false);
  };

  const onDatePickerOutsideClick = () =>
    setDatePickerActive(false);

  const onInputDateClick = () =>
    setDatePickerActive(!isDatePickerActive);

  return (
    <div className={classNames('c-form-input-date', className)}>
      <FormInput
        form={form}
        label={label}
        name={name}
        className={inputClassName}
        type={'date'}
        horizontalLabel={horizontalLabel}
        placeholder={I18n.t(T.general.placeholders.date)}
        id={id}
        onClick={onInputDateClick}
        maxDate={maxDate || DEFAULT_MAX_DATE}
        valueFormatter={(date: any) => DateHelper.getFormattedDate(date) || ''}
        autocomplete={autocomplete}
        hasNoGap={hasNoGap}
        labelClass={labelClass}
        inputClass={inputClass}
      >
        <ClickOutside onClick={onDatePickerOutsideClick}>
          <span className={'c-form-input-date__datepicker-container'}>
            {isIconVisible && (
              <span
                className={'c-form-input-date__datepicker-trigger'}
                onClick={onInputDateClick}
              >
                <Icon.Calendar />
              </span>
            )}
            {isDatePickerActive && (
              <div className={classNames('c-form-input-date__datepicker', {
                'c-form-input-date__datepicker--bottom': isBottom,
              })}>
                <div className={classNames('c-date-picker', { 'is-active': isDatePickerActive })}>
                  <div className={'c-date-picker__content'}>
                    <div className={'c-date-picker__day-picker'}>
                      <DayPickerSelects
                        locale={provideLocale()}
                        months={getMonthTranslations()}
                        weekdaysLong={getWeekDaysTranslations()}
                        weekdaysShort={getWeekDaysShortTranslations()}
                        minDate={minDate || DEFAULT_MIN_DATE}
                        maxDate={maxDate || DEFAULT_MAX_DATE}
                        selectedDays={new Date(get(name)(form.values))}
                        onDayClick={onDateClick}
                        isSelectionHighlighted={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </span>
        </ClickOutside>
      </FormInput>
    </div>
  );
};
