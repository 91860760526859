import { Profile } from '+account/store/types/profile.interface';
import { Query } from '+query/query.state';
import {
  CommunityAttributes,
  CommunityContentHubArticle,
  CommunityContentHubAttributes,
  CommunityMapCardTypes,
} from './types/community.interface';

export const GET_COMMUNITY_STATS_QUERY = 'getCommunityStatsQuery';
export const GET_COMMUNITY_PROFILE_QUERY = 'getCommunityProfileQuery';
export const GET_COMMUNITY_CONTENT_HUB_ARTICLES_QUERY = 'getCommunityContentHubArticlesQuery';

export interface CommunityState {
  stats: CommunityAttributes;
  profile: Profile;
  activeMapCardType: CommunityMapCardTypes;
  isCardVisible: boolean;
  contentHubNews: CommunityContentHubArticle[];
  [GET_COMMUNITY_STATS_QUERY]: Query<CommunityAttributes>;
  [GET_COMMUNITY_PROFILE_QUERY]: Query<Profile>;
  [GET_COMMUNITY_CONTENT_HUB_ARTICLES_QUERY]: Query<CommunityContentHubAttributes>;
}

export const initialState: CommunityState = {
  stats: {
    numberOfRecentNewMembers: 0,
  },
  profile: {
    self: '',
    id: '',
    type: '',
    nickname: '',
    description: '',
    image: null,
    tags: [],
    privacy: undefined,
    address: {
      city: '',
      postalCode: '',
      country: '',
    },
    products: [],
  },
  activeMapCardType: CommunityMapCardTypes.LOGGED_USER,
  isCardVisible: true,
  contentHubNews: [],
  [GET_COMMUNITY_STATS_QUERY]: {},
  [GET_COMMUNITY_PROFILE_QUERY]: {},
  [GET_COMMUNITY_CONTENT_HUB_ARTICLES_QUERY]: {},
};
