import { Icon } from '@sonnen/shared-web';
import * as React from 'react';

import './CheckListItem.component.scss';

interface Props {
  label: string;
  dataTestId?: string;
}

export const CheckListItem: React.SFC<Props> = ({ label, dataTestId }) => (
  <li className={'c-check-list-item'}>
    <Icon.Checkmark className="c-check-list-item__icon" />
    <p
      data-test-id={dataTestId} 
      className="c-check-list-item__title">
      {label}
    </p>
  </li>
);
