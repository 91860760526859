import { T } from '@sonnen/shared-i18n/customer';
import { DiscreteProgressBar, DS } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { ContractParagraph } from '../';

import './ContractEnergyMeter.component.scss';

interface Props {
  boxQuantity?: number;
  maxEnergyAmount: number;
  percentage: number;
  withBillingPeriod?: boolean;
}

export const ContractEnergyMeter: React.FC<Props> = ({
  boxQuantity = 20,
  maxEnergyAmount,
  percentage,
}) => (
  <div className={'c-contract-energy-meter'}>
    <DiscreteProgressBar
      percentage={percentage}
      boxQuantity={boxQuantity}
      label={
        <Translate
          value={T.yourFlat.meterLegendMessage}
          percentage={percentage}
          dangerousHTML={true}
        />
      }
      labelIcon={<DS.Icon.ThunderPlus />}
    />
    <div className={'c-contract-energy-meter__footer'}>
      <ContractParagraph dataTestId={'contract-energy-meter-free-energy'}>
        {I18n.t(T.yourFlat.meterBottomLabel, { amount: `${maxEnergyAmount} kWh` })}
      </ContractParagraph>
    </div>
  </div>
);
