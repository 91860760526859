import * as React from 'react';

export const useElementDimensions = () => {
  const elementRef = React.useRef<HTMLElement>(null);
  const [dimensionsState, setDimensions] = React.useState<any>();

  React.useEffect(() => {
    const element = elementRef && elementRef.current;
    if (element) {
      const dimensions = element.getBoundingClientRect();
      setDimensions(dimensions);
    }
  }, [elementRef]);

  return [elementRef, dimensionsState];
};
