import * as classNames from 'classnames';
import * as React from 'react';

import '../InfographicIcon.util.scss';

interface Props {
  x: number;
  y: number;
  isActive?: boolean;
}

/* tslint:disable:max-line-length */
export const InfographicIconChargerCable: React.FC<Props> = ({ x, y, isActive }) => (
  <svg
    className={classNames('u-infographic-icon u-infographic-icon__hover', { 'is-active': isActive })}
    x={x}
    y={y}
    viewBox={'0 0 76 46'}
    width={'47'}
    height={'28'}
  >
    <path
      className={'u-infographic-icon__contour'}
      d={'M22.7,24.6C18,17.7,13.9,12,3.7,12V9.8c11.4,0,16.1,6.9,20.8,13.5c5.2,7.6,10.8,15.3,25.9,15.3c4.3,0,7.9-1.4,10.7-4.4C69.3,25.6,69,7.8,69,7.6h2.1c0,0.6,0.4,18.8-8.5,28c-3.3,3.5-7.4,5.1-12.3,5.1C34.1,40.7,28,32,22.7,24.6z'}
    />
  </svg>
);
