import { T } from '@sonnen/shared-i18n/customer';
import { DS } from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { StoreState } from '+app/store/store.interface';
import { getFormattedDate } from '+legacy/helpers/dates';
import { ContractFlatXCard, ContractFlatXStatus } from '../../components/ContractFlatXCard';
import { getContractFlatXStatus } from '../../components/ContractFlatXCard/helpers';
import { checkDeliveryStartAt } from '../../store/contract.selectors';

const mapStateToProps = (state: StoreState) => ({
  deliveryStartAt: checkDeliveryStartAt(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const TRANSLATIONS = {
  title: T.yourFlat.sonnenFlatX.energyDeliveryCard.title,
  [ContractFlatXStatus.OPEN]: {
    subtitle: T.yourFlat.sonnenFlatX.energyDeliveryCard.open.subtitle,
    additionalInfo: T.yourFlat.sonnenFlatX.energyDeliveryCard.open.additionalInfo,
  },
  [ContractFlatXStatus.PENDING]: {
    subtitle: T.yourFlat.sonnenFlatX.energyDeliveryCard.pending.subtitle,
  },
  [ContractFlatXStatus.ACTIVE]: {
    subtitle: undefined,
  },
} as const;

export const ContractFlatXCardEnergyDeliveryComponent: React.FC<Props> = ({ deliveryStartAt }) => {
  const status = getContractFlatXStatus(deliveryStartAt);
  const formattedDate = getFormattedDate(deliveryStartAt);
  const infoValue = formattedDate || I18n.t(T.yourFlat.sonnenFlatX.orderDateInProgress);
  const data = [
    {
      attribute: I18n.t(T.yourFlat.sonnenFlatX.energyDeliveryDate),
      value: infoValue,
    },
  ];

  return (
    <ContractFlatXCard
      title={I18n.t(TRANSLATIONS.title)}
      subtitle={I18n.t(TRANSLATIONS[status].subtitle, { deliveryStartAt: formattedDate })}
      data={data}
      icon={DS.Icon.Bulb}
      status={status}
      additionalInfo={I18n.t(TRANSLATIONS[status].additionalInfo)}
      dataTestId={'contract-flat-x-card-energy-delivery'}
    />
  );
};

export const ContractFlatXCardEnergyDelivery = connect(mapStateToProps)(
  ContractFlatXCardEnergyDeliveryComponent,
);
