import { T } from '@sonnen/shared-i18n/customer';
import { Icon, MediaQuery, PageHeadline } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { DemoBubble } from '+app/+demo/components';
import { DemoAnalysisEnergyFlowCard } from '../DemoAnalysisEnergyFlowCard';

import './DemoAnalysisEnergyFlow.web.component.scss';

export const DemoAnalysisEnergyFlowWeb: React.FC = (props) => 
  <div className={'c-demo-analysis-energy-flow'}>
    <PageHeadline dataTestId={'demo-analysis-header'}>
      {I18n.t(T.analysis.title)}
    </PageHeadline>
    <div className={'c-demo-analysis-energy-flow__content'}>
      <Media query={MediaQuery.UP_SM}>
        {
          (isDesktop: boolean) => isDesktop ? (
            <>
              <div className={'c-demo-analysis-energy-flow__bubble c-demo-analysis-energy-flow__bubble--desktop'}>
                <DemoBubble
                  dataTestId={'demo-analysis-bubble'}
                  icon={Icon.Analysis}
                  headline={I18n.t(T.demo.analysis.day.bubble.headline)}
                  side={'bottom'}
                  buttonLabel={I18n.t(T.demo.analysis.day.bubble.buttonLabel)}
                >
                  {I18n.t(T.demo.analysis.day.bubble.text)}
                </DemoBubble>
              </div>
              <DemoAnalysisEnergyFlowCard
                isHeaderVisible={true}
              />
            </>
          ) : (
            <>
              <div className={'c-demo-analysis-energy-flow__bubble c-demo-analysis-energy-flow__bubble--mobile'}>
                <DemoBubble
                  icon={Icon.Analysis}
                  headline={I18n.t(T.demo.analysis.day.bubble.headline)}
                  side={'top'}
                  buttonLabel={I18n.t(T.demo.analysis.day.bubble.buttonLabel)}
                >
                  {I18n.t(T.demo.analysis.day.bubble.text)}
                </DemoBubble>
              </div>
              <DemoAnalysisEnergyFlowCard />
            </>
          )
        }
      </Media>
    </div>
  </div>;
