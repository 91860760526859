import { CommunityActions } from '+app/+dashboard/+community/store';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { getRecentMembersCount } from '+community/store/community.selectors';
import { PlatformSelector } from '+components';
import { getCurrentUserGeolocation, getCurrentUserNameWithCity } from '+shared/store/user/user.selectors';
import * as React from 'react';
import { connect } from 'react-redux';
import { CommunityMapSectionMobile } from './CommunityMapSection.mobile.component';
import { CommunityMapSectionWeb } from './CommunityMapSection.web.component';

export type CommunityMapSectionProps =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (store: StoreState) => ({
  userName: getCurrentUserNameWithCity(store),
  userGeolocation: getCurrentUserGeolocation(store),
  recentMembersCount: getRecentMembersCount(store),
});

const mapDispatchToProps = mapActions({
  getCommunityData: CommunityActions.getCommunityStatsData,
});

const CommunityMapSectionComponent: React.FC<CommunityMapSectionProps> = (props) => {
  React.useEffect(() => {
    props.actions.getCommunityData();
  });

  return (
    <PlatformSelector
      web={<CommunityMapSectionWeb {...props} />}
      mobile={<CommunityMapSectionMobile {...props} />}
    />
  );
};

export const CommunityMapSection = connect(mapStateToProps, mapDispatchToProps)(CommunityMapSectionComponent);
