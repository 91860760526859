import { DS } from '@sonnen/shared-web';
import * as React from 'react';

import './ContractTerminationIcon.component.scss';

export const ContractTerminationIcon = () => (
  <div className={'c-contract-termination__icon'}>
    <DS.Icon.Contract/>
    <div className={'c-contract-termination__icon-x'}>
      <DS.Icon.X/>
    </div>
  </div>
);
