import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './ContractFlatStepsCounter.component.scss';

type ContractFlatStepsCounterProps = {
  count: number;
  max: number;
  dataTestId?: string;
};

export const ContractFlatStepsCounter: React.FC<ContractFlatStepsCounterProps> = ({ count, max, dataTestId }) => (
  <div className={'c-contract-flat-steps-counter'}>
    <span className={'c-contract-flat-steps-counter__attribute'}>
      {I18n.t(T.yourFlat.sonnenFlatX.fullfiledSteps)}
    </span>
    <span 
      data-test-id={dataTestId}
      className={'c-contract-flat-steps-counter__value'}
      >
      {count} / {max}
    </span>
  </div>
);
