import { Page } from '+app/shared/containers/Page';
import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { AccountCommunityProfileForm } from '../AccountCommunityProfileForm';

import './AccountCommunityProfile.component.scss';

export class AccountCommunityProfile extends React.PureComponent {
  render() {
    return (
      <Page options={{
        isHeadlineSmallGap: true,
      }}>
        <div className={'c-account-community-profile'}>
          <div className={'c-account-community-profile__description'}>
            <DefaultParagraph>
              {I18n.t(T.communityProfile.description)}
            </DefaultParagraph>
          </div>
          <AccountCommunityProfileForm />
        </div>
      </Page>
    );
  }
}
