import * as React from 'react';

import { PlatformSelector } from '+app/shared/components';
import { DemoAnalysisEnergyFlowMobile } from './DemoAnalysisEnergyFlow.mobile.component';
import { DemoAnalysisEnergyFlowWeb } from './DemoAnalysisEnergyFlow.web.component';

export const DemoAnalysisEnergyFlow: React.FC = () => 
  <PlatformSelector
    web={<DemoAnalysisEnergyFlowWeb />}
    mobile={<DemoAnalysisEnergyFlowMobile />}
  />;
