import { T } from '@sonnen/shared-i18n/customer';
import { MediaQuery, ModalLayout } from '@sonnen/shared-web';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import { ContractTerminationIcon } from './ContractTerminationIcon.component';
import { ModalConfirmButtons } from './ModalConfirmButtons.component';

import './ModalConfirm.component.scss';

interface Props {
  closeModal: () => void;
  onAccept: () => void;
  isAcceptPending: boolean;
}

export const ModalConfirm = ({
  closeModal,
  onAccept,
  isAcceptPending,
}: Props) => {
  const [showFooter, setShowFooter] = React.useState(false);
  const handleScreenSizeChange = (isMobile: boolean) => {
    if (showFooter && isMobile) {
      setShowFooter(false);
    }
    if (!showFooter && !isMobile) {
      setShowFooter(true);
    }
  };

  return (
    <ModalLayout
      title={I18n.t(T.yourFlat.terminateModal.title)}
      subtitle={I18n.t(T.yourFlat.terminateModal.subtitle)}
      footer={showFooter && (
        <div className={'c-modal-confirm__footer'}>
          <ModalConfirmButtons
            onAccept={onAccept}
            onCancel={closeModal}
            isPending={isAcceptPending}
          />
        </div>
      )}
    >
      <div className={'c-modal-confirm__content'}>
        <ContractTerminationIcon />
        <div className={'c-modal-confirm__paragraph'}>
          {I18n.t(T.yourFlat.terminateModal.confirmationDescription)}
        </div>
        <Media query={MediaQuery.DOWN_SM} onChange={handleScreenSizeChange}>
          <ModalConfirmButtons
            onAccept={onAccept}
            onCancel={closeModal}
            isPending={isAcceptPending}
          />
        </Media>
      </div>
    </ModalLayout>
  );
};
