import { Data, MergedData } from '@coolio/json-api';

export enum ProductMigrationStatusType {
  COLLECTING_DATA = 'collecting data',
  CONFIGURATION = 'configuration',
  OFFER = 'offer',
  CESSION = 'cession',
  CONTRACT = 'contract',
}

export type ProductMigrationStatusAttributes = {
  status: ProductMigrationStatusType;
};

export type ProductMigrationStatusData = Data<ProductMigrationStatusAttributes>;

export type ProductMigrationStatus = MergedData<ProductMigrationStatusData>;
