import { GuideGridStretched, GuideInfoCard, GuideInfoCardText } from '+app/+guide/components';
import { GuideAccordion } from '+app/+guide/containers';
import { factorizeStepDescription, GuideStepStatus } from '+app/+guide/helpers/Guide.helper';
import { StepName } from '+app/+guide/helpers/steps.config';
import { getGuideLeadPartnerName, getGuideLeadPartnerStatus } from '+app/+guide/store/+lead.selectors';
import { PartnerStatus } from '+app/+guide/store/types/partner.interface';
import { StoreState } from '+app/store/store.interface';
import { getFormattedDate } from '+legacy/helpers/dates';
import { getOnSiteVisitStepDate, getOnSiteVisitStepStatus } from '../../store/+discovery.selectors';

import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const mapStateToProps = (state: StoreState) => ({
  partnerStatus: getGuideLeadPartnerStatus(state),
  partnerName: getGuideLeadPartnerName(state),
  stepStatus: getOnSiteVisitStepStatus(state),
  stepDate: getOnSiteVisitStepDate(state),
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & RouteComponentProps
  ;

export const GuideDiscoveryStepOnSiteVisitComponent: React.FC<Props> = ({
  stepStatus,
  stepDate,
  partnerName,
  partnerStatus,
}) => {
  const partner = partnerStatus === PartnerStatus.ACCEPTED ? partnerName : I18n.t(T.customerGuidance.yourPartner);
  const formattedDate = getFormattedDate(stepDate);
  const content = {
    [GuideStepStatus.ACTIVE]: {
      headline: I18n.t(T.customerGuidance.planning.step.onSiteVisit.active.headline),
      tag: `${I18n.t(T.customerGuidance.byName, {name: partner})}`,
      status: 'open',
    },
    [GuideStepStatus.DONE]: {
      headline: I18n.t(T.customerGuidance.planning.step.onSiteVisit.done.headline),
      tag: I18n.t(T.customerGuidance.tag.done),
      description: !isNil(formattedDate)
        ? factorizeStepDescription(formattedDate, I18n.t(T.customerGuidance.byName, {name: partner}))
        : I18n.t(T.customerGuidance.byName, {name: partner}),
      status: 'done',
    },
  };

  return (
    <GuideAccordion
      status={stepStatus}
      anchor={StepName.ON_SITE_VISIT}
      title={content[stepStatus].headline}
      tag={content[stepStatus].tag}
      tagTheme={content[stepStatus].status}
      date={content[stepStatus].date}
      responsibility={content[stepStatus].responsibility}
    >
      {{
        active: (
          <GuideGridStretched>
            <GuideInfoCard
              headerIcon={<Icon.Info />}
              header={I18n.t(T.customerGuidance.planning.step.onSiteVisit.active.information.headline)}
            >
              <GuideInfoCardText
                contentText={I18n.t(T.customerGuidance.planning.step.onSiteVisit.active.information.description)}
              />
            </GuideInfoCard>
          </GuideGridStretched>
        ),
        done: (
          <GuideGridStretched>
            <GuideInfoCard
              headerIcon={<Icon.Info />}
              header={I18n.t(T.customerGuidance.planning.step.onSiteVisit.active.information.headline)}
            >
              <GuideInfoCardText
                contentText={I18n.t(T.customerGuidance.planning.step.onSiteVisit.done.information.description)}
              />
            </GuideInfoCard>
            <GuideInfoCard
              headerIcon={<Icon.Contract />}
              isIconBig={true}
              header={I18n.t(T.customerGuidance.planning.step.onSiteVisit.done.yourToDo.headline)}
            >
              <GuideInfoCardText
                contentText={I18n.t(T.customerGuidance.planning.step.onSiteVisit.done.yourToDo.description)}
              />
            </GuideInfoCard>
          </GuideGridStretched>
      ),
    }}
    </GuideAccordion>
  );
};

export const GuideDiscoveryStepOnSiteVisit =
  withRouter(connect(mapStateToProps)(GuideDiscoveryStepOnSiteVisitComponent));
