import { Data, MergedData } from '@coolio/json-api';

export interface CessionDocumentAttributes {
  url: string;
}

export type CessionDocumentData = Data<CessionDocumentAttributes>;
export type CessionDocument = MergedData<CessionDocumentData>;

export enum CessionStatus {
  PENDING = 'pending',
  SIGNED = 'signed',
  REJECTED = 'rejected',
  NEW = 'new',
  UNSIGNED = 'unsigned',
}

export type CessionTimestamps = {
  [status in CessionStatus]?: string;
 };

export interface CessionDocumentStatusAttributes {
  status: CessionStatus;
  timestamps: CessionTimestamps;
}

export type CessionDocumentStatusData = Data<CessionDocumentStatusAttributes>;
export type CessionDocumentStatus = MergedData<CessionDocumentStatusData>;
