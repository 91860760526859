import { Accordion, FormCheckbox, RouterHelper } from '@sonnen/shared-web';
import classNames from 'classnames';
import { replace } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { TagTheme } from '+app/shared/components';
import { mapActions } from '+app/utils/redux';
import {
  GuideDetailsTag,
  GuideDetailsTagProps,
  PhaseContext,
} from '+guide/components';
import {
  GuideStepChildren,
  GuideStepStatus,
} from '../../helpers/Guide.helper';

import './GuideAccordion.component.scss';

const mapDispatchToProps = mapActions({
  goTo: replace,
});

interface ComponentProps {
  isAlwaysOpen?: boolean;
  isChecked: boolean;
  isDisabled?: boolean;
  onCheckboxToggle: () => void;
  anchor: string;
  status: GuideStepStatus;
  title: string;
  children: Partial<GuideStepChildren>; // @TODO: Delete?
}

type Props =
  & ReturnType<typeof mapDispatchToProps>
  & RouteComponentProps
  & GuideDetailsTagProps
  & ComponentProps
  ;

const renderHeader = (
  hasCheckbox: boolean,
  title: string,
  tag: string,
  tagTheme?: TagTheme,
  description?: string,
) => (
  <div className={'c-guide-accordion__header'}>
    <div className={classNames('c-guide-accordion__header-content', {
      'c-guide-accordion__header-content--spaced-left': hasCheckbox,
    })}>
      <p className={'c-guide-accordion__title'}>
        {title}
      </p>
      {tag && (
        <div className={'c-guide-accordion__details'}>
          <GuideDetailsTag
            tag={tag}
            tagTheme={tagTheme}
            description={description}
          />
        </div>
      )}
    </div>
  </div>
);

const GuideAccordionComponent: React.FC<Props> = ({
  isChecked,
  isDisabled,
  isAlwaysOpen,
  onCheckboxToggle,
  actions,
  anchor,
  location,
  status,
  title,
  tag,
  tagTheme,
  description,
  children,
}) => {
  const stepRef = React.useRef<HTMLDivElement>(null);
  const phaseStepPath = React.useContext(PhaseContext);
  const { pathname } = location;
  const isPathMatching = RouterHelper.matchPath(phaseStepPath(anchor))(pathname);

  const onToggle = () => isPathMatching
    ? actions.goTo(phaseStepPath(), { preserveScroll: true })
    : actions.goTo(phaseStepPath(anchor), { preserveScroll: true });

  const scrollToTop = () => {
    if (isPathMatching && stepRef && stepRef.current) {
      const offsetTop = stepRef.current.getBoundingClientRect().top + window.scrollY;
      window.scroll({ top: offsetTop, left: 0, behavior: 'smooth' });
    }
  };

  const hasCheckbox = Boolean(isChecked || isDisabled || onCheckboxToggle);

  // @TODO: Remove <li> tag

  return (
    <li className={'c-guide-accordion'}>
      <div
        id={anchor}
        ref={stepRef}
      >
        {hasCheckbox && (
          <div className={'c-guide-accordion__detached-side'}>
            <FormCheckbox
              name={`${anchor}-checkbox`}
              checked={isChecked}
              isDisabled={isDisabled}
              onClick={onCheckboxToggle}
            />
          </div>
        )}
        {!isAlwaysOpen ? (
          <Accordion
            header={renderHeader(hasCheckbox, title, tag, tagTheme, description)}
            isOpen={isPathMatching}
            onToggle={onToggle}
            onTransitionEnd={scrollToTop}
          >
            <div className={'c-guide-accordion__content'}>
              {children[status]}
            </div>
          </Accordion>
        ) : (
          <div className={'c-guide-accordion__main'}>
            <div className={'c-guide-accordion__header-wrapper'}>
              {renderHeader(hasCheckbox, title, tag, tagTheme, description)}
            </div>
            <div className={'c-guide-accordion__content'}>
              {children[status]}
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export const GuideAccordion = withRouter(connect(null, mapDispatchToProps)(GuideAccordionComponent));
