import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { StoreState } from '+app/store/store.interface';
import { getFormattedDate } from '+legacy/helpers/dates';
import { ContractCard } from '../../components';
import {
  getContractStatus,
  getContractStatusText,
} from '../../components/ContractCard/helpers/ContractCard.helpers';
import {
  checkDeliveryStartAt,
  getAppointedEnergyRate,
} from '../../store/contract.selectors';

import '../../components/ContractCard/ContractCard.component.scss';

const mapStateToProps = (store: StoreState) => ({
  appointedEnergyRate: getAppointedEnergyRate(store),
  deliveryStartAt: checkDeliveryStartAt(store),
});

type Props = ReturnType<typeof mapStateToProps>;

export const SupplyByEnergy: React.FC<Props> = ({
  deliveryStartAt,
  appointedEnergyRate,
}) => {
  const rate = Math.trunc(Number(appointedEnergyRate));
  return (
    <ContractCard
      title={I18n.t(T.yourFlat.sonnenFlatX.supplyEnergy.title)}
      subtitle={getContractStatusText(
        getContractStatus(deliveryStartAt),
        getFormattedDate(deliveryStartAt),
        rate.toString(),
      )}
      icon={'FlatSunny'}
      status={getContractStatus(deliveryStartAt)}
    />
  );
};

export const ContractCardSupplyByEnergy = connect(mapStateToProps)(
  SupplyByEnergy,
);
