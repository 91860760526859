import { CONFIG } from '+config';

export interface AppState {
  isPullToRefreshEnabled: boolean;
  isOnline: boolean;
  isReady: boolean;
  // TODO: Move modal to shared/store/layout
  modalsOpen: string[];
  // TODO: Move forgot / reset password / help and support state to proper place
  resendSuccess: boolean;
  isForgotPasswordSuccess: boolean;
  forgotPasswordStatus: string;
  isResetPasswordSuccess: boolean;
  isHelpAndSupportSubmitSuccess: boolean;
}

export const initialState: AppState = {
  isPullToRefreshEnabled: CONFIG.IS_MOBILE,
  isOnline: window.navigator.onLine,
  isReady: false,
  modalsOpen: [],
  resendSuccess: false,
  isForgotPasswordSuccess: false,
  forgotPasswordStatus: '',
  isResetPasswordSuccess: false,
  isHelpAndSupportSubmitSuccess: false,
};
