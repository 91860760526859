import { T } from '@sonnen/shared-i18n/customer';
import { TimeHelper } from '@sonnen/shared-web';
import { I18n } from 'react-redux-i18n';

import { Contract, ContractStatus, ContractTypes, FinancialServiceProvider } from '+dashboard/+contract/store/types/contract.interface';
import { 
  ContractFileRequest,
  ContractFileType,
} from '+dashboard/+contract/store/types/contractFilesRequestData.interface';
import { ContractInvoiceRequest } from '+dashboard/+contract/store/types/contractInvoicesRequestData.interface';
import { ContractEnergyDocument, ContractEnergyInvoice } from '+dashboard/+contract/store/types/energy.interface';

export const getContractPeriod = (contract: Contract | undefined) => 
  (contract && contract.startAt && contract.endAt)
    ? `(${contract.startAt} - ${contract.endAt})`
    : '';

export const getContractEnergyInvoiceItems = (
  contractInvoicesRequestData: ContractInvoiceRequest[],
): ContractEnergyInvoice[] => 
  contractInvoicesRequestData.map(contractInvoiceRequestData => {
    const creationDay = TimeHelper.getDayFromStringDate(
      contractInvoiceRequestData.created,
      I18n.t(T.general.date.default),
    );

    return {
      title: `${I18n.t(T.sonnenEnergy.documents.listItems.invoiceShortcut)}/${creationDay}`,
      date: creationDay,
      link: contractInvoiceRequestData.url,
    };
  });

export const getContractEnergyFileItems = (
  contractFilesRequestData: ContractFileRequest[],
): ContractEnergyDocument[] => 
  contractFilesRequestData.map(contractFileRequestData => {
    const creationDay = TimeHelper.getDayFromStringDate(
      contractFileRequestData.created,
      I18n.t(T.general.date.default),
    );

    // NOTE: this map needs to be created inside of this function in order I18n library to work properly
    const fileTypeMap = {
      [ContractFileType.CALL]: I18n.t(T.sonnenEnergy.documents.listItems.audio),
      [ContractFileType.CONTRACT]: I18n.t(T.sonnenEnergy.documents.listItems.pdf),
    };
    // NOTE: this map needs to be created inside of this function in order I18n library to work properly
    const fileTitleMap = {
      [ContractFileType.CALL]: I18n.t(T.sonnenEnergy.documents.listItems.contractRequest),
      [ContractFileType.CONTRACT]: I18n.t(T.sonnenEnergy.documents.listItems.contractDocument),
    };

    return {
      title: fileTitleMap[contractFileRequestData.type],
      link: contractFileRequestData.url,
      info: `${fileTypeMap[contractFileRequestData.type]}/${creationDay}`, 
    };
  });

const getContractOrderPriority = ({ status, contractType }: Contract) => {
  let priority = 0;
  if (status === ContractStatus.ACTIVATED) { priority += 2; }
  if (contractType === ContractTypes.SONNEN_FLAT_X) { priority += 1; }
  return priority;
};

export const getFilteredContracts = (contracts: Contract[]) => contracts
  .filter(({ contractType, financialServiceProvider }) =>
    contractType === ContractTypes.SONNEN_FLAT_X
    || financialServiceProvider === FinancialServiceProvider.ZUORA
    || financialServiceProvider === FinancialServiceProvider.ENERSIS,
  )
  .sort((a, b) => getContractOrderPriority(b) - getContractOrderPriority(a));

export const mapContractsToSelectOptions = (contracts: Contract[]) => contracts
  .map(contract => ({
    label: contract.contractNumber,
    value: contract.id,
  }));
