import { CONFIG } from '+config';
import { myContractJsonApiClient, useJsonApi } from '+shared/network';
import { MeterInstallationData } from './types/meterInstallation.interface';
import { MeterReadingsData, MeterReadingsPayload } from './types/meterReadings.interface';

export const getMeterInstallationData = (meterId: string) => useJsonApi()
  .get<MeterInstallationData>(`${CONFIG.API.USER_DATA}/meter-installations/${meterId}`)
  .send();

export const postMeterReading = (meterReadings: MeterReadingsPayload) => myContractJsonApiClient
  .post<MeterReadingsData>(`${CONFIG.API.MY_READING}/${meterReadings.contractNumber}`)
  .ofType('reading')
  .withAttributes(meterReadings)
  .send();

export const MeterRepository = {
  getMeterInstallationData,
  postMeterReading,
};
