import { Bubble, Icon } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './KeyValueRow.component.scss';

export enum KeyValueRowTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum KeyValueRowDirection {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

interface Props {
  title: string;
  value: string;
  details?: string;
  type?: KeyValueRowTheme;
  direction?: KeyValueRowDirection;
  boldText?: boolean;
}

const renderParagraphs = (text: string): React.ReactNode => {
  const paragraphs = text.split(/\n/);

  return paragraphs.map((paragraph, index) => (
    <p key={index} className={'c-key-value-row__tooltip-paragraph'}>{paragraph}</p>
  ));
};

export const KeyValueRow: React.FC<Props> = ({
  title,
  value,
  details,
  type,
  direction,
  boldText,
}) => {
  const isRowDistinct = (details: string | undefined): details is string => Boolean(details);

  return (
    <div
      className={classNames('c-key-value-row', {
        [`c-key-value-row--${type}`]: type,
        [`c-key-value-row--${direction}`]: direction,
      })}
    >
      <div className={'c-key-value-row__title-wrapper'}>
        <p className={classNames('c-key-value-row__title',
          {
            ['c-key-value-row__title--bold']: boldText,
            ['c-key-value-row__title--detailed']: details,
          })}>
          {title}
        </p>
        {isRowDistinct(details) && (
          <span className={'c-key-value-row__tooltip'}>
            <Icon.Info className={'c-key-value-row__tooltip-icon'} />
            <div className={'c-key-value-row__tooltip-content'}>
              <Bubble side={'top'}>
                {renderParagraphs(details)}
              </Bubble>
            </div>
          </span>
        )}
      </div>
      <p className={classNames('c-key-value-row__value', {
        ['c-key-value-row__value--bold']: boldText,
      })}>
        {value}
      </p>
    </div>
  );
};
