import { T } from '@sonnen/shared-i18n/customer';
import { isNil } from 'lodash/fp';
import { I18n } from 'react-redux-i18n';
import { ContractStatus } from '../types/ContractCard.interface';

export const getContractStatus = (date: string | null | undefined): ContractStatus => {
  if (isNil(date)) {
    return ContractStatus.TODO;
  }

  const todayDate: number = new Date().getTime();
  const dateToCompare: number = new Date(date).getTime();

  if (dateToCompare > todayDate) {
    return ContractStatus.ACTIVE;
  }
  if (dateToCompare <= todayDate) {
    return ContractStatus.DONE;
  }
  return ContractStatus.TODO;
};

export const getContractStatusText = (
  status: ContractStatus,
  deliveryStartAt: string | null,
  appointedEnergyRate: string,
): string => {
  if (status === ContractStatus.TODO) {
    return I18n.t(T.yourFlat.sonnenFlatX.supplyEnergy.subtitleTodo);
  }
  if (status === ContractStatus.ACTIVE) {
    return (I18n.t(T.yourFlat.sonnenFlatX.supplyEnergy.subtitleActive, { deliveryStartAt }));
  }
  if (status === ContractStatus.DONE) {
    return (I18n.t(T.yourFlat.sonnenFlatX.supplyEnergy.subtitleDone, { deliveryStartAt, appointedEnergyRate }));
  }
  return (I18n.t(T.yourFlat.sonnenFlatX.supplyEnergy.subtitleTodo));
};
