import { useJsonApi } from '+app/shared/network';
import { CONFIG } from '+config';
import {
  BankData,
  Configuration,
  ConfigurationData,
  EnergyProvidersData,
  LeadData,
  OfferProductStatus,
  UtilityDataData,
} from '+guide/store/types';
import { isEmpty } from 'lodash/fp';
import * as moment from 'moment';
import { UtilityDataForm } from '../containers';
import {
  mapOfferRelationshipToDto,
  mapProviderAttributesToDto,
  mapPvSystemsToDto,
  mapSinglePvSystemToDto,
  mapUtilityAttributesToDto,
} from './+acceptance.helper';

const BASE_URL = `${CONFIG.API.SALES}/leads`;
const ENERGY_PROVIDERS_URL = `${CONFIG.API.SALES}/energy-providers`;

const getNowInISO = () => new Date(moment().toDate()).toISOString();

const setPaymentData = ({
  leadId, offerId, paymentData,
}: {
  leadId: string;
  offerId: string;
  paymentData: BankData;
}) => {

  const tax = !isEmpty(paymentData.vat) || !isEmpty(paymentData.taxStatus) ? {
    vatNumber: !isEmpty(paymentData.vat)
      ? paymentData.vat
      : undefined,
    status: !isEmpty(paymentData.taxStatus)
      ? paymentData.taxStatus
      : undefined,
  } : undefined;

  return useJsonApi()
    .patch<LeadData>(`${BASE_URL}/${leadId}/offers/${offerId}`)
    .ofType('offers')
    .withAttributes({
      bank: {
        firstName: paymentData.firstName,
        lastName: paymentData.lastName,
        iban: paymentData.iban.replace(/\s/g, ''),
        bic: paymentData.bic,
      },
      tax,
    })
    .send();
};

const acceptDocuments = ({
  leadId, offerId, acceptedDocuments,
}: {
  leadId: string;
  offerId: string;
  acceptedDocuments: string[];
}) =>
  useJsonApi()
    .patch<LeadData>(`${BASE_URL}/${leadId}/offers/${offerId}`)
    .ofType('offers')
    .withAttributes({
      accepted:
        acceptedDocuments.map(doc => ({
          type: doc,
          date: getNowInISO(),
        }),
        ),
    })
    .send();

const patchLeadOfferProduct = ({
  leadId, offerId, productId, status,
}: { leadId: string, offerId: string, productId: string, status: OfferProductStatus }) =>
  useJsonApi()
    .patch(`${BASE_URL}/${leadId}/offers/${offerId}/products/${productId}`)
    .ofType('products')
    .withId(productId)
    .withAttributes({
      status,
    })
    .send();

const getEnergyProviders = (search: string) =>
  useJsonApi()
    .getList<EnergyProvidersData>(ENERGY_PROVIDERS_URL)
    .filter('name', search)
    .send();

type PatchUtilityChangePayload = { values: UtilityDataForm, leadId: string, offerId: string };

const patchUtilityChange = ({ values, leadId, offerId }: PatchUtilityChangePayload) => {
  const req = useJsonApi()
    .patch<LeadData>(`${BASE_URL}/${leadId}/utility-change`)
    .ofType('utility-change')
    .withAttributes(mapUtilityAttributesToDto(values))
    .withRelationship(mapOfferRelationshipToDto(offerId));

  return isEmpty(values.providerId)
    ? req
      .send()
    : req
      .withRelationship(mapProviderAttributesToDto(values))
      .send();
};

const getUtilityData = (leadId: string) =>
  useJsonApi()
    .get<UtilityDataData>(`${BASE_URL}/${leadId}/utility-change`)
    .send();

const patchPvSystemKeys = ({ values, leadId, configuration }:
  { values: UtilityDataForm, leadId: string, configuration: Configuration }) =>
  useJsonApi()
    .patch<ConfigurationData>(`${BASE_URL}/${leadId}/configurations/${configuration.id}`)
    .ofType('configurations')
    .withAttributes(configuration.photovoltaicSystem.subSystems?.length
      ? mapPvSystemsToDto(values, configuration)
      : mapSinglePvSystemToDto(values))
    .send();

export const GuideAcceptanceRepository = {
  setPaymentData,
  acceptDocuments,
  patchLeadOfferProduct,
  getEnergyProviders,
  patchUtilityChange,
  getUtilityData,
  patchPvSystemKeys,
};
