import * as React from 'react';

import './InfographicFirstPlan.component.scss';

interface Props {
  viewbox: string;
  width: number | string | undefined;
  height: number | string | undefined;
}

// tslint:disable:max-line-length
export const InfographicFirstPlan: React.FC<Props> = ({ children, width, height, viewbox }) => (
  <svg
    className={'c-infographic-first-plan'}
    xmlSpace={'preserve'}
    viewBox={viewbox}
    width={width}
    height={height}
  >
    <polygon
      className={'c-infographic-first-plan__grass'}
      points="0 574.6 85.06 581.4 111.37 578.7 152.82 580.5 215.75 580.5 244.58 578.1 250.92 578.7 284.15 579.5 483.32 579.5 502.64 577.9 545.39 578.5 601.61 578.5 770 571.09 770 928 0 928 0 574.6"
    />
    <path
      className={'c-infographic-first-plan__shadow'}
      d="M193.5,592.2q-66.3,4.8-33.8,11.9t427,7.3L674,598.1,449.5,588Z"
    />
    {children}
  </svg>
);
