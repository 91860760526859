import * as classNames from 'classnames';
import { Field, FormikProps } from 'formik';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './GuideAcceptanceInputField.scss';

interface Props<T> {
  form: FormikProps<T>;
  name: keyof T;
  type?: 'number' | 'text' | 'date' | 'password';
  placeholder: string;
}

export const GuideAcceptanceInputField = <T extends any = any>({
  form, name, type, placeholder,
}: Props<T>) => (
  <>
    <Field
      name={name}
      type={type}
      placeholder={placeholder}
      className={classNames('c-guide-acceptance-input-field__input', {
        'is-error': !!form.errors[name] && form.touched[name],
      })}
    />
    {!!form.errors[name] && form.touched[name] && (
      <p className={'c-guide-acceptance-input-field__error'}>
        {I18n.t(form.errors[name] as string)}
      </p>
    )}
  </>
);
