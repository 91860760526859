import * as React from 'react';

import { AnalysisKanvaProvider } from '+app/+dashboard/+analysis/containers/AnalysisKanvaProvider/AnalysisKanvaProvider.component';
import { AnalysisProvider } from '+app/+dashboard/+analysis/containers/AnalysisProvider/AnalysisProvider.component';
import { ANALYSIS_PROVIDER } from '+mocks/analysis.mock';
import { DemoAnalysisEnergyFlow, DemoAnalysisHistory } from '../../components';

import './DemoAnalysis.component.scss';

export const DemoAnalysis: React.FC = () => {
  const { commonData: {
    areaChartSeries,
    barChartSeries,
    measurementsMethod,
    selectedDate,
    statisticsSelectedDate,
  }} = ANALYSIS_PROVIDER;

  return (
    <AnalysisProvider
      {...ANALYSIS_PROVIDER}
    >
      <AnalysisKanvaProvider
        areaChartSeries={areaChartSeries}
        barChartSeries={barChartSeries}
        measurementsMethod={measurementsMethod}
        selectedDate={selectedDate}
        statisticsSelectedDate={statisticsSelectedDate}
      >
        <div className={'c-demo-analysis'}>
          <DemoAnalysisEnergyFlow />
          <DemoAnalysisHistory />
        </div>
      </AnalysisKanvaProvider>
    </AnalysisProvider>
  );
};
