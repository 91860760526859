import { T } from '@sonnen/shared-i18n/customer';
import { isEmpty, isNil } from 'lodash';
import { I18n } from 'react-redux-i18n';

import { StatisticsV1 } from '+analysis/store/types/statisticsV1.interface';
import { BatteryHelper } from '+app/+dashboard/+battery/store/battery.helpers';
import { Battery } from '+app/+dashboard/+battery/store/types/battery.interface';
import { Contract } from '+app/+dashboard/+contract/store/types/contract.interface';
import { User } from '+app/shared/store/user/types/user.interface';
import { DsoListData } from '../../store/types/dsoOperators.interface';
import { ProductConfiguration } from '../../store/types/productConfiguration.interface';
import { ProductMigrationStatusType } from '../../store/types/productMigrationStatus.interface';
import { ProductMigrationConfigurationSiteData, ProductMigrationPersonalData } from './ProductMigration.context';

const getStatusLabel = (status: ProductMigrationStatusType) => ({
  [ProductMigrationStatusType.COLLECTING_DATA]: I18n.t(T.productMigration.collectingData.status),
  [ProductMigrationStatusType.CONFIGURATION]: I18n.t(T.productMigration.configuration.status),
  [ProductMigrationStatusType.OFFER]: I18n.t(T.productMigration.personalData.status),
  [ProductMigrationStatusType.CESSION]: I18n.t(T.productMigration.cession.status),
  [ProductMigrationStatusType.CONTRACT]: I18n.t(T.productMigration.concludeOrder.status),
}[status]);

const getDsoNameById = (dsoId: string | number | undefined, dsoList: DsoListData) =>
  dsoList.find(dso => dso.attributes.dsoId === dsoId)?.attributes.dso;

const getConfigurationSiteData = (
  batteryData: Battery,
  configuration: ProductConfiguration | undefined,
  dsoOperators: DsoListData,
  yearlyStatistics: StatisticsV1 | undefined,
): ProductMigrationConfigurationSiteData => ({
  batteryCapacity: batteryData.batteryCapacity,
  cellType: batteryData.cellType,
  batteryProductName: BatteryHelper.getBatteryProductName(batteryData),
  yearlyConsumption: yearlyStatistics?.consumedEnergy as number[],
  yearlyProduction: yearlyStatistics?.producedEnergy as number[],
  pvPeakPower: batteryData.pvPeakPower,
  pvComissioningDate: configuration?.photovoltaicSystem?.commissioningDate?.value,
  dsoOperator: getDsoNameById(configuration?.powerPlant?.dsoId?.value, dsoOperators),
});

const getPersonalData = (
  user: User | undefined,
  contract: Contract | undefined,
): ProductMigrationPersonalData => ({
  firstName: user?.firstName,
  lastName: user?.lastName,
  email: user?.email,
  mobile: user?.mobile,
  billingStreet: contract?.billingStreet || '',
  billingPostalCode: contract?.billingPostalCode,
  billingCity: contract?.billingCity,
  billingCountryCode: contract?.billingCountryCode,
  shippingStreet: contract?.shippingStreet || '',
  shippingPostalCode: contract?.shippingPostalCode,
  shippingCity: contract?.shippingCity,
  shippingCountryCode: contract?.shippingCountryCode,
  bankFirstname: contract?.bankFirstname,
  bankLastname: contract?.bankLastname,
  bankIban: contract?.bankIban,
  bankSwift: contract?.bankSwift,
});

// TODO: fix empty array
const getKwhSummedUpStatistics = (statistics: number[]) => statistics && statistics.length
  ? Math.round(statistics.reduce((acc, curr) => acc + curr) / 1000)
  : 0;

const hasFullYearStatistics = (
  yearlyProduction: number[] | undefined,
  yearlyConsumption: number[] | undefined,
) => (!isNil(yearlyProduction) && !isEmpty(yearlyProduction) && !yearlyProduction.includes(0)) &&
  (!isNil(yearlyConsumption) && !isEmpty(yearlyConsumption) && !yearlyConsumption.includes(0));

export const ProductMigrationHelper = {
  getStatusLabel,
  getConfigurationSiteData,
  getDsoNameById,
  getPersonalData,
  getKwhSummedUpStatistics,
  hasFullYearStatistics,
};
