import { T } from '@sonnen/shared-i18n/customer';
import { Icon, ModalLayout } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './ModalSuccess.component.scss';

export const ModalSuccess = () => {
  return (
    <ModalLayout
      title={I18n.t(T.yourFlat.terminateModal.title)}
      subtitle={I18n.t(T.yourFlat.terminateModal.subtitle)}
    >
      <div className={'c-modal-confirm__content'}>
        <div className={'c-modal-success__icon'}>
          <Icon.PaperPlane />
        </div>
        <div className={'c-modal-success__paragraph'}>
          {I18n.t(T.yourFlat.terminateModal.successDescription)}
        </div>
      </div>
    </ModalLayout>
  );
};
