import * as React from 'react';

import {
  AnalyticsContext,
  GTMEventCategory,
  GTMTrackerManager,
  SendEvent,
  SendPageView,
  useUrlHistory,
} from '+shared/helpers/analytics';

interface Props {
  dataLayerName: string;
}

export const AnalyticsTracker: React.FC<Props> = ({ children, dataLayerName }) => {
  const gtmTrackerManager = GTMTrackerManager.create(dataLayerName);
  const getHistoryUrls = useUrlHistory();
  const sendEvent: SendEvent = (event, extra) => {
    const { previousUrl, currentUrl } = getHistoryUrls();

    gtmTrackerManager.sendEvent(event, {
      previousUrl,
      currentUrl,
      ...extra,
    });
  };

  const sendPageView: SendPageView = () => {
    const { previousUrl, currentUrl } = getHistoryUrls();
    const { pathname, hash, search } = new URL(currentUrl);

    sendEvent('ga-page-view', {
      previousUrl,
      currentUrl,
      category: GTMEventCategory.VIRTUAL_PAGE_VIEW,
      action: `${pathname}${hash}${search}`,
    });
  };

  return (
    <AnalyticsContext.Provider value={{
      sendEvent,
      sendPageView,
    }}>
      {children}
    </AnalyticsContext.Provider>
  );
};
