import * as React from 'react';
import { compose } from 'redux';

import { RouterBuilder } from '+router/factory';
import { getPath, getSubRoutes } from '+router/router.helper';
import { RouteName } from '+router/router.types';
import { useRouterState } from '+router/RouterProvider.component';
import { withContract } from '+shared/store/contract/withContract.hoc';

const ContractTariffComponent: React.FC = () => {
  const { availableFlatRoutes } = useRouterState();

  return (
    <RouterBuilder
      routes={getSubRoutes(RouteName.TARIFF, availableFlatRoutes)}
      redirectTo={getPath(RouteName.TARIFF_OVERVIEW)}
    />
  );
};

export const ContractTariff = compose(
  withContract,
)(ContractTariffComponent);
