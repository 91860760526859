import { isQueryPending } from '+utils/query.util';
import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { getBatteryData } from '+app/+dashboard/+battery/store/battery.selectors';
import { getContractDataQueryStatus, getCurrentSiteContractData } from '+app/+dashboard/+contract/store/contract.selectors';
import { StoreState } from '+app/store/store.interface';
import { getActiveSite } from '+shared/store/site/site.selectors';
import { getRoutesConfig } from './router.config';
import { getAccessibleFlatRoutes, getFactorizedRoutes } from './router.helper';
import { FlatRoutes, Route } from './router.types';
import { getRoutesAccess } from './routesAccess.config';

export const RouterContext = React.createContext<RouterState>({
  routes: [],
  availableFlatRoutes: {},
});

export type RouterProviderProps =
  & ReturnType<typeof mapStateToProps>
  ;

export interface RouterState {
  availableFlatRoutes: FlatRoutes;
  routes: Route[];
}

export const RouterProviderComponent: React.FC<RouterProviderProps> = (props) => {
  const [availableFlatRoutes, setAvailableFlatRoutes] = React.useState<FlatRoutes>({});
  const [routes, setRoutes] = React.useState<Route[]>([]);

  React.useEffect(() => {
    const routesAccess = getRoutesAccess();
    const initialConfig = getRoutesConfig();
    const accessibleRoutes = getAccessibleFlatRoutes(initialConfig, routesAccess);

    setAvailableFlatRoutes(accessibleRoutes);
  }, [props]);

  React.useEffect(() => {
    const routes = getFactorizedRoutes(availableFlatRoutes);

    setRoutes(routes);
  }, [availableFlatRoutes]);

  return (
    <RouterContext.Provider value={{
      availableFlatRoutes,
      routes,
    }}>
      {props.children}
    </RouterContext.Provider>
  );
};

const mapStateToProps = (state: StoreState) => ({
  // TODO: Consider get all needed data for routeAccess here, and pass
  // to routeAccess as a required props to avoid duplicate state connection
  // and re-render on all route access cases.
  activeSite: getActiveSite(state),
  contract: getCurrentSiteContractData(state),
  batteryData: getBatteryData(state),
  isContractQueryPending: getContractDataQueryStatus(state),
  guideLead: state.guide.common.lead,
  guideAuthToken: state.guide.auth.token,
  isSonnenLead: !!(state.guide.common.lead && state.guide.common.lead.source === 'sonnen'),
});

export const RouterProvider = connect(mapStateToProps)(RouterProviderComponent);

export const useRouterState = () => React.useContext(RouterContext);
