import { T } from '@sonnen/shared-i18n/customer';
import { I18n } from 'react-redux-i18n';

import { ImageTutorialItemProps } from '+shared/components';
import { GTMEventCategory, GTMEventData } from '+shared/helpers/analytics';

import img1 from './tutorial-item-1-x1.png';
import img1Retina from './tutorial-item-1-x2.png';
import img2 from './tutorial-item-2-x1.png';
import img2Retina from './tutorial-item-2-x2.png';
import img3 from './tutorial-item-3-x1.png';
import img3Retina from './tutorial-item-3-x2.png';
import img4 from './tutorial-item-4-x1.png';
import img4Retina from './tutorial-item-4-x2.png';

const factorizeCessionTutorial = (): ImageTutorialItemProps[] => ([
  {
    title: I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.step1.title),
    imageSrc: {
      default: img1,
      retina: img1Retina,
    },
    alt: I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.step1.imgAlt),
  },
  {
    title: I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.step2.title),
    imageSrc: {
      default: img2,
      retina: img2Retina,
    },
    alt: I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.step2.imgAlt),
  },
  {
    title: I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.step3.title),
    imageSrc: {
      default: img3,
      retina: img3Retina,
    },
    alt: I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.step3.imgAlt),
  },
  {
    title: I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.step4.title),
    imageSrc: {
      default: img4,
      retina: img4Retina,
    },
    alt: I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.step4.imgAlt),
  },
]);

const expandEventData: GTMEventData = {
  event: 'ga-product-migration--expand-tutorial-on-cession',
  category: GTMEventCategory.PRODUCT_MIGRATION,
  action: 'expand-tutorial--cession',
  label: I18n.t(T.customerGuidance.acceptance.cessionDoc.tutorial.showMore),
};

export const CessionImageTutorialHelper = {
  expandEventData,
  factorizeCessionTutorial,
};
