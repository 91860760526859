import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { AccordionList } from '+components';
import { GTMEventCategory, GTMEventData } from '+shared/helpers/analytics';
import { useToggleWithGtmEvent } from '+shared/hooks/useToggleWithGtmEvent';
import { CessionAccordionListHelper } from './CessionAccordionList.helper';

const faqSoftwareDownloadExpandEventData: GTMEventData = {
  event: 'ga-product-migration--expand-faq-1-on-cession',
  category: GTMEventCategory.PRODUCT_MIGRATION,
  action: 'expand-bottom-faq-1-cession',
  label: T.customerGuidance.acceptance.cessionDoc.faq.item1.title,
};

const faqSignLaterExpandEventData: GTMEventData = {
  event: 'ga-product-migration--expand-faq-2-on-cession',
  category: GTMEventCategory.PRODUCT_MIGRATION,
  action: 'expand-bottom-faq-2-cession',
  label: T.customerGuidance.acceptance.cessionDoc.faq.item2.title,
};

const faqDissimilarSignatureExpandEventData: GTMEventData = {
  event: 'ga-product-migration--expand-faq-3-on-cession',
  category: GTMEventCategory.PRODUCT_MIGRATION,
  action: 'expand-bottom-faq-3-cession',
  label: T.customerGuidance.acceptance.cessionDoc.faq.item3.title,
};

const withTranslatedLabel = (data: GTMEventData): GTMEventData => ({
  ...data,
  label: data.label && I18n.t(data.label as string),
});

export const CessionAccordionList: React.FC = () => {
  const faqSoftwareDownloadToggleHandler = useToggleWithGtmEvent({
    expandEventData: withTranslatedLabel(faqSoftwareDownloadExpandEventData),
  });
  const faqSignLaterToggleHandler = useToggleWithGtmEvent({
    expandEventData: withTranslatedLabel(faqSignLaterExpandEventData),
  });
  const faqDissimilarSignatureToggleHandler = useToggleWithGtmEvent({
    expandEventData: withTranslatedLabel(faqDissimilarSignatureExpandEventData),
  });

  return (
    <AccordionList
      list={CessionAccordionListHelper.factorizeCessionAccordionList({
        faqSoftwareDownloadToggleHandler,
        faqSignLaterToggleHandler,
        faqDissimilarSignatureToggleHandler,
      })}
    />
  );
};
