import { toCamelCase } from '+utils/toCamelCase.util';

export const createQueryString = (params: Record<string, string>): string => {
  const queryString = Object.entries(params)
    .filter(([_, value]) => !!value)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return queryString ? `?${queryString}` : '';
};

export const getQueryParams = <T extends object = Record<string, string>>(queryString: string): T => {
  const queryParams = {};

  if (!queryString.startsWith('?')) {
    return queryParams as T;
  }

  queryString.slice(1)
    .split('&')
    .map(param => param.split('='))
    .forEach(([key, value]) => {
      queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
    });

  return toCamelCase(queryParams);
};

const decodeParam = (object: object, hashParam: string) => {
  const [key, value] = hashParam.split('=');
  return { ...object, [key]: decodeURIComponent(value) };
};

export const getHashParams = <T extends object>(url: string): T | {} => {
  if (!url.includes('#')) { return {}; }

  const params = url.split('#')[1]
    .split('&')
    .reduce(decodeParam, {});

  return toCamelCase(params);
};
