import { T } from '@sonnen/shared-i18n/customer';
import { insertIf, Measurement, useFeature } from '@sonnen/shared-web/index';
import * as moment from 'moment-timezone';
import { I18n } from 'react-redux-i18n';

import { FeatureName } from '+config/featureFlags';
import {
  getBillingPeriodUTCTimestampsInTimezone,
  getEnergyProductDetailsItems,
  getUsageAllowancePeriod,
  mapStatisticsToEnergyUsage,
} from '+contract/store/helpers/contract.helpers';
import { Contract } from '+contract/store/types/contract.interface';
import { Consumption } from '+legacy/components/charts/AnnualConsumptionChart/AnnualConsumptionChart.types';
import { getDeliveryStatus, getFormattedDate } from '+legacy/helpers/dates';
import { StatisticsData } from '+shared/types/statisticsData.interface';
import { DateUtil, TimePeriod } from '+utils/date.util';
import { roundToNextMultipleOfFive } from '+utils/number.util';
import { toLowerCaseFirstLetter } from '+utils/toLowerCaseFirstLetter.util';

export const getBillingPeriodForContractTypeSonnenFlatX = (batteryTimezone: string, contract: Contract): TimePeriod => {
  const startOfYear = moment.tz(batteryTimezone).startOf('year');

  return {
    start: DateUtil.getNewerDate(moment.tz(contract.deliveryStartAt, batteryTimezone), startOfYear),
    end: moment.tz(batteryTimezone).endOf('year'),
  };
};

export function getUsageAllowanceForSonnenFlatX(
  contract: Contract,
  year: number,
): number;
export function getUsageAllowanceForSonnenFlatX(
  contract: Contract,
  year: null,
): null;
export function getUsageAllowanceForSonnenFlatX(
  contract: Contract,
  year: number | null,
): number | null;
export function getUsageAllowanceForSonnenFlatX(
  contract: Contract,
  year: number | null,
): number | null {
  if (useFeature(FeatureName.USAGE_ALLOWANCE_FROM_SALESFORCE).isEnabled) {
    return contract.amountOfFreeEnergy;
  }

  const usageAllowanceForFullYear = contract.amountOfFreeEnergy;

  if (!year || !usageAllowanceForFullYear) {
    return null;
  }

  const { start, end } = getUsageAllowancePeriod(contract, year);

  const numberOfContractDaysInCurrentPeriod = DateUtil.getNumberOfDaysInDateRange(start, end);
  const numberOfDaysThisYear = DateUtil.getYearDaysAmount(moment().format(DateUtil.DateFormat.NUMERIC_FULL_DATE));

  return Math.floor(usageAllowanceForFullYear / numberOfDaysThisYear * numberOfContractDaysInCurrentPeriod);
}

export const getPercentageUsageForContractTypeSonnenFlatX = (
  freeUsageAllowance: number | null,
  statistics: StatisticsData | undefined,
) => {
  if (!freeUsageAllowance) { return 0; }

  const gridPurchaseEnergy = statistics?.total?.gridPurchaseEnergy;
  const guardedGridPurchaseMeasurement = (gridPurchaseEnergy && gridPurchaseEnergy[0]) || 0;
  const totalGridPurchase = (guardedGridPurchaseMeasurement) / 1000;

  return roundToNextMultipleOfFive(Math.min(
    ((totalGridPurchase / freeUsageAllowance) * 100),
    100,
  ));
};

export const getProductDetailsListForContractTypeSonnenFlatX = (
  contract: Contract,
  freeUsageAllowanceFirstYear: number | null,
) => {
  const { end } = getUsageAllowancePeriod(contract, moment(contract.usageAllowanceActivatedOn).year());
  const usageAllowanceStartDate =
    getFormattedDate(contract.usageAllowanceActivatedOn) || I18n.t(T.yourFlat.tableValues.startDateUnknownValue);
  const usageAllowancePeriodLabel = `(${usageAllowanceStartDate} - ${getFormattedDate(end)})`;

  return [
    ...getEnergyProductDetailsItems(
      I18n.t(T.yourFlat.tableValues[contract.contractType]) || toLowerCaseFirstLetter(contract.contractType),
      contract.contractNumber,
      contract.orderedAt,
    ),
    {
      label: I18n.t(T.yourFlat.tableLabels.fifth),
      value: getDeliveryStatus(
        contract.deliveryStartAt,
        T.yourFlat.tableValues.active,
        T.yourFlat.tableValues.inactive,
        T.yourFlat.tableValues.notDelivered,
      ),
    },
    {
      label: `${I18n.t(T.yourFlat.tableLabels.second)} ${I18n.t(T.yourFlat.tableLabels.fullYear)}`,
      value: `${contract.amountOfFreeEnergy} kWh`, // TODO: format with getKiloPowerObj
    },
    ...insertIf(!!freeUsageAllowanceFirstYear, {
        label: `${I18n.t(T.yourFlat.tableLabels.second)} ${usageAllowancePeriodLabel}`,
        value: `${freeUsageAllowanceFirstYear} kWh`, // TODO: format with getKiloPowerObj
      },
    ),
    {
      label: I18n.t(T.yourFlat.tableLabels.usageAllowanceActivatedOn),
      value: `${getFormattedDate(contract.usageAllowanceActivatedOn || '')}`,
    },
  ];
};

export const getContractSonnenFlatXUsageByDay = (billingPeriod: TimePeriod, gridPurchase: Measurement): Consumption => {
  const { start } = getBillingPeriodUTCTimestampsInTimezone(billingPeriod);

  return mapStatisticsToEnergyUsage(gridPurchase, start);
};
