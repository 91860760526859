import { T } from '@sonnen/shared-i18n/customer';
import {
  breakpointUp,
  Button,
  ButtonSize,
  ButtonTheme,
  Drawer,
  Icon,
} from '@sonnen/shared-web';
import * as _ from 'lodash';
import * as React from 'react';
import Media from 'react-media';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Route, Switch } from 'react-router-dom';

import { isAuthenticated } from '+app/+auth/store/auth.selectors';
import { getPaths, isOnRoute } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { useRouterState } from '+app/router/RouterProvider.component';
import { getRouterLocationPath } from '+app/router/store/router.selectors';
import { DefaultNav } from '+app/shared/components';
import { LayoutActions } from '+app/shared/store/layout/layout.actions';
import { isMobileNavOpen } from '+app/shared/store/layout/layout.selectors';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { history } from '+router/store';
import { SiteSwitcher } from '..';
import { getRoutesByType, RoutesByType } from './MainNav.helper';

import './MainNav.component.scss';

const mapStateToProps = (state: StoreState) => ({
  isMobileNavOpen: isMobileNavOpen(state),
  isLoggedIn: isAuthenticated(state),
  path: getRouterLocationPath(state),
});

const mapDispatchToProps = mapActions({
  toggleMobileNav: LayoutActions.toggleMobileNav,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  ;

type OnMobileNavClose = () => void;

const renderWideScreenNav = (
  routesByType: RoutesByType,
  isUserInGuide?: boolean,
) => (
  <Switch>
    {isUserInGuide && (
      <Route
        path={getPaths().GUIDE}
        render={() => (
          <DefaultNav routes={routesByType.guide.desktop} />
        )}
      />
    )}
    <Route
      path={getPaths().MY_ACCOUNT}
      render={() => (
        <>
          <DefaultNav
            routes={routesByType.account.desktop}
            isAlwaysExpanded={true}
          />
          <div className={'c-main-nav__item'}>
            <Button
              label={
                <>
                  <Icon.Arrow className={'c-main-nav__icon'} />
                  {I18n.t(T.general.buttons.back)}
                </>
              }
              onClick={() => history.push(getPaths().DASHBOARD)}
              isBlock={true}
              size={ButtonSize.SECONDARY}
              theme={ButtonTheme.OUTLINE}
            />
          </div>
        </>
      )}
    />
    <Route
      path={'*'}
      render={() => (
        <DefaultNav routes={routesByType.dashboard.desktop} />
      )}
    />
  </Switch>
);

const renderNarrowScreenNav = (
  routesByType: RoutesByType,
  onMobileNavClose: OnMobileNavClose,
  isMobileNavOpen: boolean,
  isUserInGuide?: boolean,
) => (
  <Drawer
    isOpen={isMobileNavOpen}
    header={I18n.t(T.general.menu)}
    onClose={onMobileNavClose}
  >
    <Switch>
      {isUserInGuide && (
        <Route
          path={getPaths().GUIDE}
          render={() => (
            <DefaultNav routes={routesByType.guide.mobile} />
          )}
        />
      )}
      <Route
        path={'*'}
        render={() => (
          <>
            <SiteSwitcher />
            <DefaultNav routes={routesByType.dashboard.mobile} />
            <DefaultNav routes={routesByType.account.mobile} />
          </>
        )}
      />
    </Switch>
  </Drawer>
);

const MainNavComponent: React.FC<Props> = ({
  actions,
  isMobileNavOpen,
  isLoggedIn,
  path,
}) => {
  const { availableFlatRoutes } = useRouterState();
  const routesByType = getRoutesByType(availableFlatRoutes);

  const isUserInGuide: boolean = !isLoggedIn && isOnRoute(RouteName.GUIDE, path);
  const onMobileNavClose: OnMobileNavClose = () => actions.toggleMobileNav(false);

  return (
    <nav className={'c-main-nav'}>
      <Media query={{ minWidth: breakpointUp('NAV') }}>
        {(isDesktop: boolean) => isDesktop
          ? renderWideScreenNav(routesByType, isUserInGuide)
          : renderNarrowScreenNav(routesByType, onMobileNavClose, isMobileNavOpen, isUserInGuide)
        }
      </Media>
    </nav>
  );
};

export const MainNav = connect(mapStateToProps, mapDispatchToProps)(MainNavComponent);
