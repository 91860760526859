import { CountryFlagProvider } from '@sonnen/shared-web';
import * as moment from 'moment';
import { AnyAction } from 'redux';
import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';

import { getSelectedDate } from '+analysis/store/analysis.selectors';
import { AuthActions } from '+auth/store/auth.actions';
import { getReverseChannelToken } from '+auth/store/auth.selectors';
import { GENERATE_REVERSE_CHANNEL_TOKEN_QUERY } from '+auth/store/auth.state';
import { takeResolvedQuery } from '+shared/helpers/query.helper';
import { NotificationActions } from '+shared/store/notification/notification.actions';
import {
  createBackupBufferSetupFailedNotification,
  createBackupBufferSetupProcessingNotification,
  createSetBackupBufferFailedNotification,
} from '+shared/store/notification/notification.factory';
import { QueryActions } from '+shared/store/query/query.actions';
import { getActiveSite, isSiteWithBattery } from '+shared/store/site/site.selectors';
import { Site } from '+shared/store/site/types/site.interface';
import { combineSagas } from '+utils/saga/combineSagas.util';
import { dataGuard } from '+utils/saga/dataGuard.util';
import { processQuery } from '+utils/saga/processQuery.util';
import { BATTERY_ACTIONS, BatteryActions } from './battery.actions';
import { BatteryHelper } from './battery.helpers';
import { BatteryRepository } from './battery.repository';
import {
  getBackupBufferProcessing,
  getBatteryId,
  getBatteryState,
} from './battery.selectors';
import {
  BackupBufferProcessing,
  BatteryState,
  GET_BATTERY_CELL_CARE_HISTORY_QUERY,
  GET_BATTERY_DATA_QUERY,
  GET_BATTERY_DOCUMENTS_QUERY,
  GET_BATTERY_ELECTRIC_UNITS_QUERY,
  SET_BACKUP_BUFFER_QUERY,
} from './battery.state';
import { BatteryStatus, BatteryStatusKey, DocumentError } from './types/battery.interface';

export function * getBatterySystemData(): SagaIterator {
  const activeSite: Site | undefined = yield select(getActiveSite);
  const hasBattery = yield select(isSiteWithBattery);

  if (!activeSite || !hasBattery) {
    return;
  }

  return yield processQuery(
    GET_BATTERY_DATA_QUERY,
    BatteryRepository.getBattery,
    {
      onSuccess: res => dataGuard(BatteryActions.setData)(res!.find(el => el.assetStatus === BatteryStatus.INSTALLED)),
    },
  )(activeSite!.id);
}

export function* getDocuments({ articleNumber, language }: AnyAction): SagaIterator {
  yield processQuery(
    GET_BATTERY_DOCUMENTS_QUERY,
    BatteryRepository.getDocuments,
    {
      onSuccess: res => dataGuard(BatteryActions.setDocuments)(res!.elements),
      onFailure: (error: DocumentError) => put(QueryActions.failure(BATTERY_ACTIONS.GET_DOCUMENTS, error as Error)),
    },
  )({ articleNumber, language });
}

export function* getBatteryElectricUnits(): SagaIterator {
  yield call(getBatterySystemData);
  yield race({
    action: take(BATTERY_ACTIONS.SET_DATA),
    cancel: delay(5000),
  });

  const batteryId = yield select(getBatteryId);

  if (!batteryId) { return; }

  yield processQuery(
    GET_BATTERY_ELECTRIC_UNITS_QUERY,
    BatteryRepository.getBatteryElectricUnits,
    {
      onSuccess: res => dataGuard(BatteryActions.setBatteryElectricUnits)(res!.elements),
      onFailure: (error: any) => put(QueryActions.failure(BATTERY_ACTIONS.SET_BATTERY_ELECTRIC_UNITS, error as any)),
    },
  )(batteryId);
}

function * getBatteryCellCareHistory(): SagaIterator {
  const batteryId = yield select(getBatteryId);
  if (!batteryId) { return; }
  const selectedDate: moment.Moment = yield select(getSelectedDate);
  // const batteryTimezone = yield select(getBatteryTimezone);

  if (selectedDate.isAfter(moment(Date.now()), 'day')) { return; }
 
  if (!batteryId
    || !selectedDate
  ) {
    return;
  }

  // const { start, end } = BatteryHelper.getDateRangeInBatteryTimezone(selectedDate, batteryTimezone, { period });

  yield processQuery(
    GET_BATTERY_CELL_CARE_HISTORY_QUERY,
    BatteryRepository.getBatteryStatuses,
    {
      onSuccess: res => dataGuard(BatteryActions.setBatteryCellCareHistory)(res!),
    },
  )({
    id: batteryId,
    filters: {
      startAt: selectedDate.startOf('day').format(),
      endAt: selectedDate.endOf('day').format(),
      key: BatteryStatusKey.LATEST_BATTERY_SPECIAL_STATUS,
    },
  });
}

export function* watchBackupBuffer(): SagaIterator {
  const batteryId: string | undefined = yield select(getBatteryId);
  const backupBufferProcessing: BackupBufferProcessing | undefined = yield select(getBackupBufferProcessing);
  const processingStartAt = backupBufferProcessing?.processingStartAt;
  const processingValue = backupBufferProcessing?.processingValue;
  const timeStages = [15, 180, 300, 600];
  let statusCheckCount = 0;

  while (batteryId && !!backupBufferProcessing) {
    yield put(BatteryActions.getData());
    yield takeResolvedQuery(GET_BATTERY_DATA_QUERY);

    const batteryState: BatteryState | undefined = yield select(getBatteryState);
    if (!batteryState) { return; }
    const isBackupBufferSet = BatteryHelper.isBackupBufferSet(batteryId, batteryState);

    if (isBackupBufferSet) {
      yield put(BatteryActions.deleteBackupBufferProcessing(batteryId));
      break;
    } else if (BatteryHelper.hasProcessingTimeExceededDuration(processingStartAt, timeStages[timeStages.length - 1])) {
      yield put(NotificationActions.addNotification(createBackupBufferSetupFailedNotification([
        BatteryActions.setBackupBuffer(processingValue!),
        ])));

      yield put(BatteryActions.deleteBackupBufferProcessing(batteryId));
      break;
    }

    const nextStageDelay = statusCheckCount
      ? timeStages[statusCheckCount] - timeStages[statusCheckCount - 1]
      : timeStages[0];

    yield delay(nextStageDelay * 1000);
    statusCheckCount++;
  }
}

export function* authorizeReverseChannel(): SagaIterator {
  let reverseChannelAuth = yield select(getReverseChannelToken);
  const requestNewToken = !reverseChannelAuth || moment(reverseChannelAuth.expireAt).isBefore(moment());

  if (requestNewToken) {
    yield put(AuthActions.generateReverseChannelToken());
    yield takeResolvedQuery(GENERATE_REVERSE_CHANNEL_TOKEN_QUERY);

    reverseChannelAuth = yield select(getReverseChannelToken);
  }

  return reverseChannelAuth && reverseChannelAuth.token;
}

export function* setBackupBuffer({
  value,
}: ReturnType<typeof BatteryActions.setBackupBuffer>): SagaIterator {
  const id = yield select(getBatteryId);

  return yield processQuery(
    SET_BACKUP_BUFFER_QUERY,
    BatteryRepository.setBackupBuffer,
    {
      onSuccess: () => all([
        put(NotificationActions.addNotification(createBackupBufferSetupProcessingNotification())),
        put(BatteryActions.addBackupBufferProcessing(id, value)),
      ]),
      onFailure: () => put(NotificationActions.addNotification(
        createSetBackupBufferFailedNotification([BatteryActions.setBackupBuffer(value)]),
      )),
    },
  )({ id, value: String(value)});
}

const setCountryFlagProviderBattery = ({
  data: { installationCountryCode },
}: ReturnType<typeof BatteryActions.setData>) => {
  if (!installationCountryCode) { return; }

  CountryFlagProvider.setBatteryCountry(installationCountryCode);
};

export const batterySagas = combineSagas(
  takeEvery(BATTERY_ACTIONS.GET_DATA, getBatterySystemData),
  takeLatest(BATTERY_ACTIONS.GET_DOCUMENTS, getDocuments),
  takeLatest(BATTERY_ACTIONS.GET_BATTERY_ELECTRIC_UNITS, getBatteryElectricUnits),
  takeLatest(BATTERY_ACTIONS.GET_BATTERY_CELL_CARE_HISTORY, getBatteryCellCareHistory),
  takeLatest(BATTERY_ACTIONS.SET_BACKUP_BUFFER, setBackupBuffer),
  takeLatest(BATTERY_ACTIONS.ADD_BACKUP_BUFFER_PROCESSING, watchBackupBuffer),
  takeLatest(BATTERY_ACTIONS.WATCH_BACKUP_BUFFER, watchBackupBuffer),
  takeLatest(BATTERY_ACTIONS.SET_DATA, setCountryFlagProviderBattery),
);
