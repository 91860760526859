import { StepName, Steps } from './steps.config';
import { StepsAccess } from './stepsAccess.config';

/**
 * Returns boolean value based on steps access
 */
export const isStepAccessible = (stepName: StepName, stepsAccess: StepsAccess) => {
  return stepsAccess[stepName];
};

/**
 * Returns steps filtered by steps access
 */
export const getVisibleSteps = (steps: Steps, stepsAccess: StepsAccess) => {
  let visibleSteps: Steps = {} as Steps;

  Object.entries(steps).forEach(([, step]) => {
    if (
      step
      && isStepAccessible(step.name, stepsAccess)
    ) {
      visibleSteps = {
        ...visibleSteps,
        [step.name]: step,
      };
    }
  });

  return visibleSteps;
};
