import { CountryCode } from '@sonnen/shared-web';

import { ContractTypes, TariffTypes } from '+app/+dashboard/+contract/store/types/contract.interface';
import { insertIf } from '+app/utils/array.util';
import { BatteryControllerType } from '+battery/store/types/battery.interface';
import { News } from '+shared/store/news-channel/types/news.interface';
import { SiteElement, SiteOption } from '+shared/store/site/types/site.interface';

const getSiteBatteryAccessories = (hasBackup: boolean, hasProtect: boolean, hasHeater: boolean): SiteElement[] => [
  ...insertIf(hasBackup, SiteElement.BACKUP),
  ...insertIf(hasProtect, SiteElement.PROTECT),
  ...insertIf(hasHeater, SiteElement.HEATER),
];

const filterNewsByCountry = (countryCode: CountryCode | undefined) => (newsList: News[]): News[] =>
  countryCode
    ? newsList.filter(news => news.countries?.length
        ? news.countries.includes(countryCode)
        : true)
    : [];

const filterNewsByOptions = (siteOptions: SiteOption[] | undefined) => (newsList: News[]): News[] =>
  siteOptions
    ? newsList.filter(news => news.options?.length
      ? news.options.every(newsOption => siteOptions.includes(newsOption))
      : true)
    : [];

const filterNewsByAccessories = (batteryAccessories: SiteElement[] | undefined) => (newsList: News[]): News[] =>
  batteryAccessories
    ? newsList.filter(news => news.optionsDetails?.batteries?.accessories?.length
      ? news.optionsDetails.batteries.accessories.some(accessory => batteryAccessories.includes(accessory))
      : true)
    : newsList;

const filterNewsByBatteryControllerType = (controllerType: BatteryControllerType | undefined) =>
  (newsList: News[]): News[] =>
    controllerType
      ? newsList.filter(news => news.optionsDetails?.batteries?.controllerType?.length
        ? news.optionsDetails.batteries.controllerType.every(
          type => controllerType.toLowerCase() === type.toLowerCase())
        : true)
      : newsList;

const filterNewsByContractType = (contractType: ContractTypes | undefined) => (newsList: News[]): News[] =>
  contractType
    ? newsList.filter(news => news.optionsDetails?.contracts?.contractType?.length
      ? news.optionsDetails.contracts.contractType.some(type => contractType.toLowerCase() === type.toLowerCase())
      : true)
    : newsList;

const filterNewsByTariffType = (tariffType: TariffTypes | null | undefined) => (newsList: News[]): News[] =>
  newsList.filter(news => news.optionsDetails?.contracts?.tariffType?.length
    ? news.optionsDetails.contracts.tariffType.some(type => tariffType?.toLowerCase() === type.toLowerCase())
    : true);

const filterValidNews = (newsList: News[]): News[] => {
  // TODO remove this after NewsChannel is only used via admin panel
  const requiredKeys = ['messages', 'levelValue', 'expiryDateTimestamp'];

  return newsList.filter(news =>
    requiredKeys.every(key => news.hasOwnProperty(key)),
  );
};

export const NewsProviderHelper = {
  getSiteBatteryAccessories,
  filterNewsByOptions,
  filterNewsByCountry,
  filterNewsByBatteryControllerType,
  filterNewsByAccessories,
  filterNewsByContractType,
  filterNewsByTariffType,
  filterValidNews,
};
