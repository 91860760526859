import { AnyData, ListMetaData, RawListResponse } from '@coolio/json-api';

import { Query } from '+query/query.state';
import { Invoice, InvoiceData } from './types/invoices.interface';
import { Opportunity, OpportunityData, SystemDocument, SystemDocumentData } from './types/opportunities.interface';

export const FETCH_INVOICES_QUERY = 'fetchInvoicesQuery';
export const FETCH_MORE_INVOICES_QUERY = 'fetchMoreInvoicesQuery';
export const DOWNLOAD_INVOICE_QUERY = 'downloadInvoiceQuery';
export const FETCH_OPPORTUNITIES_QUERY = 'fetchOpportunitiesQuery';
export const FETCH_SYSTEM_DOCUMENTS_QUERY = 'fetchSystemDocumentsQuery';
export const DOWNLOAD_SYSTEM_DOCUMENT_QUERY = 'downloadSystemDocumentQuery';

type RawListResponseWithAllLinks<D extends AnyData, M extends ListMetaData = ListMetaData> =
  &  RawListResponse<D, M>
  & { links: { self: string } }
;

export interface DocumentsState {
  invoices: Invoice[];
  opportunities: Opportunity[];
  systemDocuments: SystemDocument[];
  [FETCH_INVOICES_QUERY]: Query<{ raw?: RawListResponseWithAllLinks<InvoiceData> }>;
  [FETCH_MORE_INVOICES_QUERY]: Query<{ raw?: RawListResponseWithAllLinks<InvoiceData> }>;
  [DOWNLOAD_INVOICE_QUERY]: Query;
  [FETCH_OPPORTUNITIES_QUERY]: Query<{ raw?: RawListResponse<OpportunityData> }>;
  [FETCH_SYSTEM_DOCUMENTS_QUERY]: Query<{ raw?: RawListResponse<SystemDocumentData> }>;
  [DOWNLOAD_SYSTEM_DOCUMENT_QUERY]: Query;
}

export const initialState: DocumentsState = {
  invoices: [],
  opportunities: [],
  systemDocuments: [],
  [FETCH_INVOICES_QUERY]: {},
  [FETCH_MORE_INVOICES_QUERY]: {},
  [DOWNLOAD_INVOICE_QUERY]: {},
  [FETCH_OPPORTUNITIES_QUERY]: {},
  [FETCH_SYSTEM_DOCUMENTS_QUERY]: {},
  [DOWNLOAD_SYSTEM_DOCUMENT_QUERY]: {},
};
