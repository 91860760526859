import * as React from 'react';

import { CONFIG } from '+config';
import { RouterBuilder } from '+router/factory';
import { getPath, getRoutes, isAuthorizedRoute, RouteAuthType } from '+router/router.helper';
import { RouteName } from '+router/router.types';
import { useRouterState } from '+router/RouterProvider.component';
import { Props as RouterComponentProps } from '../../Router.component';

interface OwnProps {
  userLoggedIn: boolean;
  userLoggedInOnce: boolean;
}

type Props = OwnProps & RouterComponentProps;

export const UnauthorizedRouting: React.FC<Props> = ({
  location,
  userLoggedIn,
  userLoggedInOnce,
  actions,
  lastAccessedAuthUrl,
}) => {
  const { routes } = useRouterState();
  const unauthorizedRoutes = getRoutes(RouteAuthType.UNAUTHORIZED, routes);

  if (isAuthorizedRoute(location.pathname) && lastAccessedAuthUrl !== location.pathname) {
    actions.setLastAccessedAuthUrl(location.pathname);
  }

  return (
    <>
      <RouterBuilder
        routes={unauthorizedRoutes}
        redirectTo={userLoggedIn
          ? getPath(RouteName.DASHBOARD)
          : getPath(RouteName.LOGIN)}
      />
    </>
  );
};
