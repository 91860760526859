import * as React from 'react';

import './Tag.component.scss';

export type TagTheme = 'done' | 'open' | 'error' | 'sent' | 'expired';

interface Props {
  icon?: React.ReactNode;
  text: string;
  theme: TagTheme;
}

export const Tag: React.FC<Props> = ({
  icon,
  text,
  theme,
}) => (
  <div className={`c-tag c-tag--${theme}`}>
    {icon && (
      <span className={'c-tag__icon'}>
        {icon}
      </span>
    )}
    {text}
  </div>
);
