import { DemoHelper } from '+app/+demo/helpers/demo.helper';
import { AUTH_ACTIONS } from '+auth/store/auth.actions';
import { CONFIG } from '+config';
import { localStorage, sessionStorage } from '+legacy/helpers/storage';
import { Omit } from 'react-redux';
import { Action, AnyAction, Reducer } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';

interface CreateReducerOptions {
  doNotClearOnLogout?: boolean;
  persist?: Omit<PersistConfig, 'storage'>;
}

export const createReducer = <S extends {}, A extends Action = AnyAction>(
  reducer: Reducer<S, A>,
  options: CreateReducerOptions = {},
): Reducer<S, AnyAction> => {
  const {
    doNotClearOnLogout = false,
    persist,
  } = options;
  // @TODO FIX - using `getPath(RouteNames.DEMO)` instead of '/demo' breaks the app and all tests.
  if (!doNotClearOnLogout) {
    const originalReducer = reducer;
    reducer = (state: S | undefined, action: A): S =>
      action.type === AUTH_ACTIONS.CLEAR_STORE
        ? originalReducer(undefined, action)
        : originalReducer(state, action);
  }

  if (persist) {
    reducer = persistReducer({
      ...persist as any,
      storage: DemoHelper.isDemo ? sessionStorage : localStorage,
      key: `${CONFIG.APP_NAME}--${DemoHelper.isDemo ? 'demo--' : ''}${persist.key}`,
      // @NOTE added alternative key for devices not supporting session storage
    }, reducer) as any;
  }

  return reducer as any;
};
