import { CONFIG } from '+config';
import { TaxationFilters } from '+help/store/help.helpers';
import {
  SupportMessageAttributes,
  SupportMessageErrorResponse,
  SupportMessageRelationship,
  SupportMessageResponse,
} from '+help/store/types/supportMessage.interface';
import { TaxationData } from '+help/store/types/taxation.interface';
import { useJsonApi } from '+shared/network';

// TODO [refactor] remove this, use +analysis/getSiteStatistics + move it to +shared/store/site.repository
const checkAndGetTaxationMeasurements = (
  { id, filters }:
  { id?: string, filters: TaxationFilters },
) => {
  const { start, end, resolution } = filters;

  return useJsonApi().get<TaxationData>(`${CONFIG.API.USER_DATA}/sites/${id}/statistics`)
    .filter('start', start.toISOString())
    .filter('end', end.toISOString())
    .filter('resolution', resolution)
    .send()
    .then(response => response.element);
};

const sendSupportMessage = (
  { attributes, relationship }:
  {
    attributes: SupportMessageAttributes,
    relationship: SupportMessageRelationship,
  },
) => useJsonApi().post(`${CONFIG.API.USER_DATA}/cases`)
    .ofType('cases')
    .withAttributes(attributes)
    .withRelationship(relationship)
    // NOTE: override the default coolio POST response, which is the same as GET response.
    .send() as unknown as Promise<SupportMessageResponse | SupportMessageErrorResponse>;

export const HelpRepository = {
  checkAndGetTaxationMeasurements,
  sendSupportMessage,
};
