import { createSelector } from 'reselect';

import { StoreState } from '+app/store/store.interface';
import { getStatus } from '+utils/query.util';
import {
  DOWNLOAD_INVOICE_QUERY,
  DOWNLOAD_SYSTEM_DOCUMENT_QUERY,
  FETCH_INVOICES_QUERY,
  FETCH_MORE_INVOICES_QUERY,
  FETCH_OPPORTUNITIES_QUERY,
  FETCH_SYSTEM_DOCUMENTS_QUERY,
} from './documents.state';
import { InvoiceSourceType } from './types/invoices.interface';

export const getDocuments = (store: StoreState) => store.documents;

const InvoiceTypesList = Object.values(InvoiceSourceType);

export const getInvoices = createSelector(
  getDocuments,
  documents => documents.invoices.filter(invoice => InvoiceTypesList.includes(invoice.type as InvoiceSourceType)),
);

export const getFetchInvoiceQuery = createSelector(
  getDocuments,
  documents => documents[FETCH_INVOICES_QUERY],
);

export const getFetchMoreInvoiceQuery = createSelector(
  getDocuments,
  documents => documents[FETCH_MORE_INVOICES_QUERY],
);

export const getFetchInvoicesQueryPaginationLinks = createSelector(
  getFetchInvoiceQuery,
  getFetchMoreInvoiceQuery,
  (
    fetchInvoicesQuery,
    fetchMoreInvoicesQuery,
  ) => {
    // NOTE the `fetchMoreInvoicesQuery` query always fires after the `fetchInvoicesQuery`
    const latestQueryResponse = fetchMoreInvoicesQuery.response ?? fetchInvoicesQuery.response;

    return latestQueryResponse?.raw?.links;
  },
);

export const fetchInvoicesQueryStatus = createSelector(
  getDocuments,
  documents => getStatus(documents[FETCH_INVOICES_QUERY]),
);

export const getFetchSystemDocumentsQueryStatus = createSelector(
  getDocuments,
  documents => getStatus(documents[FETCH_SYSTEM_DOCUMENTS_QUERY]),
);

export const fetchMoreInvoicesQueryStatus = createSelector(
  getDocuments,
  documents => getStatus(documents[FETCH_MORE_INVOICES_QUERY]),
);

export const downloadInvoiceQueryStatus = createSelector(
  getDocuments,
  documents => getStatus(documents[DOWNLOAD_INVOICE_QUERY]),
);

export const getOpportunities = createSelector(
  getDocuments,
  documents => documents.opportunities,
);

export const getOpportunitiesQueryStatus = createSelector(
  getDocuments,
  documents => getStatus(documents[FETCH_OPPORTUNITIES_QUERY]),
);

export const getSystemDocuments = createSelector(
  getDocuments,
  documents => documents.systemDocuments,
);

export const getDownloadSystemDocumentQueryStatus = createSelector(
  getDocuments,
  documents => getStatus(documents[DOWNLOAD_SYSTEM_DOCUMENT_QUERY]),
);
