import classNames from 'classnames';
import L from 'leaflet';
import 'leaflet.vectorgrid';
import { GestureHandling } from 'leaflet-gesture-handling';
import { I18n } from 'react-redux-i18n';
import { T } from '@sonnen/shared-i18n/customer';

const createMap = ({ defaultGeometry }) => (mapRef, layers) => {
  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
  return L.map(mapRef, {
    attributionControl: false,
    center: defaultGeometry,
    maxBounds: L.latLngBounds([-90.00, -180.00], [90.00, 180.00]),
    zoom: 4,
    minZoom: 1,
    maxZoom: 7,
    layers,
    gestureHandling: true,
    gestureHandlingOptions: {
      text: {
        touch: I18n.t(T.sonnenCommunity.map.gestureHandling.touch),
        scroll: I18n.t(T.sonnenCommunity.map.gestureHandling.scroll),
        scrollMac: I18n.t(T.sonnenCommunity.map.gestureHandling.scrollMac),
      },
      duration: 1500,
    },
  });
};

const recenterMapAtUser = ({ defaultGeometry }) => (map, coordinates, pan = false, offsetWidth = 100) => {
  const geom = coordinates.filter(g => g).length ? coordinates : defaultGeometry;
  const userLatlng = L.latLng(...geom);
  const userPoint = map.latLngToContainerPoint(userLatlng);
  const targetPoint = userPoint.subtract([offsetWidth, 0]);
  const targetLatLng = map.containerPointToLatLng(targetPoint);
  return pan ? map.panTo(targetLatLng) : map.flyTo(targetLatLng, 4);
};

const createVectorLayer = (geoJson, vectorTileLayerStyles) => (
  L.vectorGrid.slicer(geoJson, {
    rendererFactory: L.canvas.tile,
    vectorTileLayerStyles,
  })
);

const createMarker = ({
  markerClass,
  markerLocationClass,
  defaultGeometry,
}) => (coordinates) => {
  const coordinatesNotEmpty = coordinates.filter(g => g).length;
  const iconClasses = classNames(markerClass, {
    [markerLocationClass]: coordinatesNotEmpty,
  });

  return L.marker(coordinatesNotEmpty ? coordinates : defaultGeometry, {
    icon: L.divIcon({
      iconAnchor: [15, 45],
      iconSize: new L.Point(30, 30),
      className: iconClasses,
    }),
  });
};

const offSetMap = (map, offsetWidth = 100) => {
  const mapCenter = map.getSize().divideBy(2);
  const targetPoint = mapCenter.subtract([offsetWidth, 0]);
  const targetLatLng = map.containerPointToLatLng(targetPoint);

  map.panTo(targetLatLng);
};

export default ({ markerClass, markerLocationClass }) => {
  // Wildpoldsried geometry for map centering and default geometry
  const WILDPOLDSRIED_GEOM = [47.7664686, 10.4020433];
  const state = {
    defaultGeometry: WILDPOLDSRIED_GEOM,
    markerClass,
    markerLocationClass,
  };

  return {
    createMarker: createMarker(state),
    createMap: createMap(state),
    recenterMapAtUser: recenterMapAtUser(state),
    createVectorLayer,
    offSetMap,
  };
};
