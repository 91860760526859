import * as React from 'react';

import './MapLoader.scss';

export const MapLoader: React.FC = () => (
  <div className="c-map-loader">
    <svg
      width="80"
      height="80"
      viewBox="0 0 240 240"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        points="210 70 130 150 90 110 30 170"
        stroke="#caf1fa"
        fill="transparent"
        strokeWidth="10"
      />
      <polyline
        points="210 70 130 150 90 110 30 170"
        stroke="#00b5dd"
        fill="transparent"
        strokeDasharray="250"
        strokeDashoffset="0"
        strokeWidth="10"
      >
        <animate
          attributeType="SVG"
          attributeName="stroke-dashoffset"
          begin="0s"
          dur="2s"
          repeatCount="indefinite"
          from="0"
          to="500"
        />
      </polyline>
    </svg>
  </div>
);
