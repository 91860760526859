import { Data, MergedData } from '@coolio/json-api';

export enum ChargerLiveDataCar {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum ChargerLiveDataStatus {
  AVAILABLE = 'AVAILABLE',
  CHARGING = 'CHARGING',
  FAULTED = 'FAULTED',
  PREPARING = 'PREPARING',
  OFFLINE = 'OFFLINE',
  PAUSED = 'PAUSED',
  FINISHED = 'FINISHED',
}

interface ChargerLiveDataAttributes {
  measuredAt: string;
  activePower: number;
  current: number;
  chargeSpeedKmh: number;
  car: ChargerLiveDataCar;
  status: ChargerLiveDataStatus;
  maxChargeCurrent: number;
  smartmode: boolean;
  departureAt: string;
  transactionChargedKm: number;
  totalChargedKm: number;
}

export type ChargerLiveData = Data<ChargerLiveDataAttributes>;
export type ChargerLive = MergedData<ChargerLiveData>;

export const CHARGER_ONLINE_STATUS = [
  ChargerLiveDataStatus.AVAILABLE,
  ChargerLiveDataStatus.CHARGING,
  ChargerLiveDataStatus.PAUSED,
  ChargerLiveDataStatus.FINISHED,
  ChargerLiveDataStatus.PREPARING,
];
