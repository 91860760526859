import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { SITE_ACTIONS, SiteActions } from './site.actions';
import { GET_SITES_QUERY, initialState, SiteState } from './site.state';

export const reducer = createReducer<SiteState, SiteActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case SITE_ACTIONS.SET_SITES:
        return {
          ...state,
          sitesList: action.sites,
        };
      case SITE_ACTIONS.SET_ACTIVE_SITE:
        return {
          ...state,
          activeSiteId: action.siteId,
        };
      default:
        return queryReducer(state, action, [
          GET_SITES_QUERY,
        ]);
    }
  },
  {
    persist: {
      key: 'sites',
    },
  },
);
