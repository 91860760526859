import * as React from 'react';

import { useAnalysis } from '../../containers/AnalysisProvider/AnalysisProvider.context';
import { AnalysisEnergyFlowChart } from '../AnalysisEnergyFlowChart';
import { AnalysisSummerTimeNotfication, getSummerTimeNotificationType } from '../AnalysisSummerTimeNotfication';

import './AnalysisEnergyFlow.mobile.component.scss';

export const AnalysisEnergyFlowMobile: React.FC = () => {
  const { commonData: { summerTimeChange } } = useAnalysis();
  const summerTimeNotificationType = getSummerTimeNotificationType(summerTimeChange);

  return (
    <>
      <div className={'m-c-analysis-energy-flow--notification'}>
        <AnalysisSummerTimeNotfication type={summerTimeNotificationType} />
      </div>
      <AnalysisEnergyFlowChart />
    </>
  );
};
