import { T } from '@sonnen/shared-i18n/customer';
import { DS, getMinimalMeasurementDate, Modal, ModalLayout  } from '@sonnen/shared-web';
import { ButtonStatus } from '@sonnen/shared-web/src/ds';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import { AnalysisActions } from '+analysis/store/analysis.actions';
import {
  getDownloadMeasurementsCsvQueryStatus,
  getDownloadStatisticsCsvQueryStatus,
} from '+analysis/store/analysis.selectors';
import { getBatteryData } from '+app/+dashboard/+battery/store/battery.selectors';
import { CollapsibleArea, FormCheckbox, FormInputRadioGroup } from '+app/shared/components';
import { GTMEventCategory, SendEvent, useAnalytics } from '+app/shared/helpers/analytics';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { AnalysisYearPicker } from '../AnalysisYearPicker';
import {
  CSVModalForm,
  getMeasurementsResolution,
  getResolutionText,
  getStartEndDates,
  getStatisticsResolution,
  TimePeriod,
} from './AnalysisCSVModal.helper';

import './AnalysisCSVModal.component.scss';

const mapStateToProps = (state: StoreState) => ({
  batteryData: getBatteryData(state),
  downloadMeasurementsCsvQueryStatus: getDownloadMeasurementsCsvQueryStatus(state),
  downloadStatisticsCsvQueryStatus: getDownloadStatisticsCsvQueryStatus(state),
});

const mapDispatchToProps = mapActions({
  getSiteMeasurementsCSV: AnalysisActions.getSiteMeasurementsCSV,
  getSiteStatisticsCSV: AnalysisActions.getSiteStatisticsCSV,
});

type AnalysisCSVModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

type Props = AnalysisCSVModalProps
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
;

const sendDownloadCSV = (data: CSVModalForm, sendEvent: SendEvent) => {
  const eventAction = [`${data.timePeriod}-`];

  if (data.energyDataInWattHours) {
    const resolution = getStatisticsResolution(data.timePeriod);
    eventAction.push(`Energy-${resolution}`);
  }

  if (data.powerDataInWatt) {
    const resolution = getMeasurementsResolution(data.timePeriod);
    eventAction.push(`Power-${resolution}`);
  }

  sendEvent('ga-analysis-csv--download', {
    category: GTMEventCategory.ANALYSIS,
    label: I18n.t(T.analysis.csvModal.submitButton),
    action: eventAction.join('-'),
  });
};

export const AnalysisCSVModalComponent: React.VFC<Props> = ({
  isOpen,
  onClose,
  batteryData,
  downloadMeasurementsCsvQueryStatus,
  downloadStatisticsCsvQueryStatus,
  actions,
}) => {
  const [year, setYear] = React.useState(new Date().getFullYear());
  const { sendEvent } = useAnalytics();

  const minDate = getMinimalMeasurementDate(batteryData.installationDate);
  const initialValues = {
    timePeriod: TimePeriod.LAST_30_DAYS,
    energyDataInWattHours: false,
    powerDataInWatt: false,
  };

  const toggleExplanation = () => {
    sendEvent('ga-analysis-csv--toggle-explanation', {
      category: GTMEventCategory.ANALYSIS,
      label: I18n.t(T.analysis.csvModal.prompLabel),
    });
  };

  const downloadCSVData = (data: CSVModalForm) => {
    const { start, end } = getStartEndDates(data.timePeriod, year, batteryData.timeZone);

    if (data.energyDataInWattHours) {
      const resolution = getStatisticsResolution(data.timePeriod);
      actions.getSiteStatisticsCSV(start, end, resolution);
    }

    if (data.powerDataInWatt) {
      const resolution = getMeasurementsResolution(data.timePeriod);
      actions.getSiteMeasurementsCSV(start, end, resolution);
    }

    sendDownloadCSV(data, sendEvent);
  };

  const isDownloadingPending = downloadMeasurementsCsvQueryStatus.pending || downloadStatisticsCsvQueryStatus.pending;
  const isDownloadButtonDisabled = ({ values }: FormikProps<CSVModalForm>) =>
    (!values.energyDataInWattHours && !values.powerDataInWatt) || isDownloadingPending;

  return (
    <div className={'c-csv-modal'}>
      <Modal
        size={'auto'}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Formik<CSVModalForm>
          initialValues={initialValues}
          onSubmit={downloadCSVData}
        >
          {form => (
            <ModalLayout
              title={I18n.t(T.analysis.csvModal.title)}
              subtitle={I18n.t(T.analysis.csvModal.subtitle)}
              withFooterBorder={false}
              footer={(
                <div className={'c-csv-modal__footer'}>
                  <DS.Button
                    onClick={() => downloadCSVData(form.values)}
                    label={I18n.t(T.analysis.csvModal.submitButton)}
                    disabled={isDownloadButtonDisabled(form)}
                    status={isDownloadButtonDisabled(form)
                      ? ButtonStatus.DISABLED
                      : ButtonStatus.ENABLED}
                    loading={isDownloadingPending}
                    loadingLabel={I18n.t(T.analysis.csvModal.submitButton)}
                    />
                </div>
              )}
            >
              <FormInputRadioGroup
                name={'timePeriod'}
                form={form}
                collection={[
                  { label: I18n.t(T.analysis.csvModal.timePeriod30DaysOption), value: TimePeriod.LAST_30_DAYS},
                  { label: I18n.t(T.analysis.csvModal.timePeriodCurrentYearOption), value: TimePeriod.CURRENT_YEAR},
                  { label: I18n.t(T.analysis.csvModal.timePeriodChooseYearOption), value: TimePeriod.YEAR},
                ]}
                title={(
                  <p className={'c-csv-modal__form-label'}>
                    {I18n.t(T.analysis.csvModal.timePeriodLabel)}
                  </p>
                )}
              />
              {form.values.timePeriod === TimePeriod.YEAR && (
                <div className={'c-csv-modal__year-picker'}>
                  <AnalysisYearPicker
                    year={year}
                    onChange={setYear}
                    min={minDate.getFullYear()}
                  />
                </div>
              )}
              <p className={'c-csv-modal__form-label'}>
                {I18n.t(T.analysis.csvModal.dataTypeLabel)}
              </p>
              <FormCheckbox
                name={'energyDataInWattHours'}
                label={I18n.t(T.analysis.csvModal.dataTypeEnergyInWHOption)}
                form={form}
              />
              <p className={'c-csv-modal__checkbox-subtitle'}>
                <span className={'c-csv-modal__checkbox-subtitle-data'}>
                  {I18n.t(T.analysis.csvModal.dataTypeEnergyInWHSubtitle)}
                </span>
                <span className={'c-csv-modal__checkbox-subtitle-resolution'}>
                  {' '}
                  {getResolutionText(getStatisticsResolution(form.values.timePeriod))}
                </span>
              </p>
              <FormCheckbox
                name={'powerDataInWatt'}
                label={I18n.t(T.analysis.csvModal.dataTypePowerInWOption)}
                form={form}
              />
              <p className={'c-csv-modal__checkbox-subtitle'}>
                <span className={'c-csv-modal__checkbox-subtitle-data'}>
                  {I18n.t(T.analysis.csvModal.dataTypePowerInWSubtitle)}
                </span>
                <span className={'c-csv-modal__checkbox-subtitle-resolution'}>
                  {' '}
                  {getResolutionText(getMeasurementsResolution(form.values.timePeriod))}
                </span>
              </p>
              <div
                onClick={toggleExplanation}
                className={'c-csv-modal__checkbox-promp'}
              >
                <CollapsibleArea label={I18n.t(T.analysis.csvModal.prompLabel)}>
                  <Translate
                    dangerousHTML={true}
                    value={I18n.t(T.analysis.csvModal.prompText)}
                  />
                </CollapsibleArea>
              </div>
            </ModalLayout>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export const AnalysisCSVModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AnalysisCSVModalComponent);
