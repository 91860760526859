import * as classNames from 'classnames';
import * as React from 'react';
import { GraphicBannerSpec } from './GraphicBannerSpec.types';

import './GraphicBanner.component.scss';

interface Props {
  imageUrl: string;
  isContentOutsideOnLowRes?: boolean;
}

export const GraphicBanner: React.FC<Props> = ({
  imageUrl,
  isContentOutsideOnLowRes,
  children,
}) => (
  <div
    className={classNames('c-graphic-banner', {
      'c-graphic-banner--outside': isContentOutsideOnLowRes,
    })}
    data-test={GraphicBannerSpec.GRAPHIC_BANNER}
  >
    <div className={'c-graphic-banner__image-wrapper'}>
      <img
        className={'c-graphic-banner__image'}
        src={imageUrl}
      />
    </div>
    <div  
      className={'c-graphic-banner__content'}
      data-test={GraphicBannerSpec.GRAPHIC_BANNER_CONTENT}
    >
      {children}
    </div>
  </div>
);
