import { EnergyFlowSeriesKey } from '@sonnen/shared-web';

import { mapDataSeries } from '+analysis/helpers/analysis.helper';
import { AreaChartSeries } from '+analysis/store/types/dataSeries.interface';
import { MockHelper } from '+mocks/helpers/mock.helper';
import * as areaChartProductionSerie from '+mocks/json/analysisProductionSerie.mock.json';
import { KanvaContainerType } from '../../../kanva/kanva.types';

const prepareDataSeries = (
  dataSeries: AreaChartSeries,
) => ({
  [KanvaContainerType.DEFAULT]: mapDataSeries({
    [EnergyFlowSeriesKey.GRID_PURCHASE]: dataSeries[EnergyFlowSeriesKey.GRID_PURCHASE],
    [EnergyFlowSeriesKey.GRID_FEEDIN]: dataSeries[EnergyFlowSeriesKey.GRID_FEEDIN],
    [EnergyFlowSeriesKey.CONSUMPTION_POWER]: dataSeries[EnergyFlowSeriesKey.CONSUMPTION_POWER],
    [EnergyFlowSeriesKey.DIRECT_USAGE_POWER]: dataSeries[EnergyFlowSeriesKey.DIRECT_USAGE_POWER],
    [EnergyFlowSeriesKey.PRODUCTION_POWER]: dataSeries[EnergyFlowSeriesKey.PRODUCTION_POWER],
    [EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER]: dataSeries[EnergyFlowSeriesKey.FORECAST_PRODUCTION_POWER],
    [EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER]: dataSeries[EnergyFlowSeriesKey.FORECAST_CONSUMPTION_POWER],
    batteryCare: dataSeries.batteryCare,
  }),
  [KanvaContainerType.PERCENTAGE]: mapDataSeries({
    [EnergyFlowSeriesKey.BATTERY_USOC]: dataSeries[EnergyFlowSeriesKey.BATTERY_USOC],
  }),
});

export const AnalysisDayChartProviderHelper = {
  prepareDataSeries,
};
