import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { Translate } from 'react-redux-i18n';

import { getFormattedDate } from '+legacy/helpers/dates';
import { TimePeriod } from '+utils/date.util';
import { ContractParagraph } from '../';

export type Props = TimePeriod;

export const ContractBillingPeriod: React.FC<Props> = ({
  start,
  end,
}) => (
  <ContractParagraph>
    <Translate
      value={T.yourFlat.billingPeriodLabel}
      startDate={getFormattedDate(start.clone())}
      endDate={getFormattedDate(end.clone())}
      dangerousHTML={true}
    />
  </ContractParagraph>
);
