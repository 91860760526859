import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { Configuration, OfferTaxRegulation } from '+guide/store/types';
import { GuideAcceptanceOfferDetails } from '../GuideAcceptanceOfferDetails';

import './GuideAcceptanceFlatOverview.component.scss';

interface Props {
  configuration: Configuration | undefined;
  isFlatXOffer: boolean;
  offerTaxRegulation: OfferTaxRegulation;
}

export const GuideAcceptanceFlatOverview: React.FC<Props> = ({
  configuration,
  isFlatXOffer,
  offerTaxRegulation,
}) => (
  <div className={'c-guide-acceptance-flat-overview'}>
    <p className={'c-guide-acceptance-flat-overview__paragraph'}>
      {isFlatXOffer
        ? I18n.t(T.customerGuidance.acceptance.flatOverview.headline)
        : I18n.t(T.customerGuidance.acceptance.flatOverview.headlineFlatDirect)
      }
    </p>
    <DefaultParagraph className={'c-guide-acceptance-flat-overview__title'}>
      {`${isFlatXOffer
        ? I18n.t(T.customerGuidance.acceptance.flatOverview.title)
        : I18n.t(T.customerGuidance.acceptance.flatOverview.titleFlatDirect)
        }:`}
    </DefaultParagraph>
    <div className={'c-guide-acceptance-flat-overview__offer-name-wrapper'}>
      <Icon.Contract className={'c-guide-acceptance-flat-overview__offer-icon'} />
      <p className={'c-guide-acceptance-flat-overview__offer-name'}>
        {isFlatXOffer
          ? I18n.t(T.customerGuidance.acceptance.flatOverview.name)
          : I18n.t(T.customerGuidance.acceptance.flatOverview.nameFlatDirect)
        }
      </p>
    </div>
    <GuideAcceptanceOfferDetails 
      configuration={configuration} 
      isFlatXOffer={isFlatXOffer} 
      offerTaxRegulation={offerTaxRegulation} 
    />
  </div>
);
