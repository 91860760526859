import { Data, MergedData } from '@coolio/json-api';
import { Measurement } from '@sonnen/shared-web';

import { MeasurementMethod } from '+app/shared/store/live/types/siteLiveData.interface';

export enum StatisticsV1FilterResolution {
  HOUR = '1-hour',
  DAY = '1-day',
  MONTH = '1-month',
  TOTAL = 'total',
}

export interface StatisticsV1Attributes {
  measurementMethod: MeasurementMethod;
  start: string;
  end: string;
  resolution: StatisticsV1FilterResolution;
  producedEnergy: Measurement;
  consumedEnergy: Measurement;
  batteryChargedEnergy: Measurement;
  batteryDischargedEnergy: Measurement;
  gridFeedinEnergy: Measurement;
  gridPurchaseEnergy: Measurement;
  directUsageEnergy: Measurement;
  batteryChargedFromSunEnergy: Measurement;
  batteryChargedFromGridEnergy: Measurement;
  heaterEnergy: Measurement;
}

export type StatisticsV1Data = Data<StatisticsV1Attributes>;
export type StatisticsV1 = MergedData<StatisticsV1Data>;

/* PAYLOAD */
export interface StatisticsV1Filters {
  start: string;
  end: string;
  resolution: StatisticsV1FilterResolution;
}
