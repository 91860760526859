export interface ToLinearFontSize {
  text: string;
  baseFontSize: number;
  multiplier: number;
  maxBaseSizeTextLength: number;
}

export const toLinearlyScaleDown = ({text, baseFontSize, multiplier, maxBaseSizeTextLength}: ToLinearFontSize) => {
  return text.length <= maxBaseSizeTextLength
    ? baseFontSize
    : baseFontSize - ((text.length - maxBaseSizeTextLength) * multiplier);
};
