import { createReducer } from '+utils/redux';
import { GuideOverviewActions } from './+overview.actions';
import { initialState } from './+overview.state';
import { GuideOverviewState } from './+overview.state';

export const reducer = createReducer<GuideOverviewState, GuideOverviewActions>(
  (state = initialState, action) => {
    switch (action) {
      default:
        return state;
    }
  },
);
