import { Product, ProfileAddress } from '+account/store/types/profile.interface';
import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { AccountProfileCardAccessories } from '../AccountProfileCardAccessories';
import { AccountProfileCardLink } from '../AccountProfileCardLink';
import { AccountProfileCardTags } from '../AccountProfileCardTags';

import './AccountProfileCard.component.scss';

export interface AccountProfileCardLink {
  path: string;
  label: string;
}

interface Props {
  header: React.ReactNode;
  name?: string;
  location: ProfileAddress;
  description?: string;
  products?: Product[];
  tags?: string[] | undefined;
  link?: AccountProfileCardLink;
}

export const AccountProfileCard: React.FC<Props> = ({
  name,
  location,
  description,
  products,
  tags,
  header,
  link,
}) => {
  const getFormattedAddress = (address: ProfileAddress) => {
    if (address) {
      if (address.city && address.postalCode) {
        return `${address.city}, ${address.postalCode}`;
      }

      if (address.city && !address.postalCode) {
        return address.city;
      }

      if (!address.city && address.postalCode) {
        return address.postalCode;
      }
    }

    return '';
  };

  return (
    <div className={'c-account-profile-card'}>
      <div className={'c-account-profile-card__header'}>
        <div className={'c-account-profile-card__header-inner'}>
          {header}
        </div>
      </div>
      <div className={'c-account-profile-card__content'}>
        <div className={'c-account-profile-card__promo-text'}>
          <p className={'c-account-profile-card__headline'}>
            {name}
          </p>
          <p className={'c-account-profile-card__subheadline'}>
            {getFormattedAddress(location)}
          </p>
        </div>
        {products && !!products.length && (
          <>
            <p className={'c-account-profile-card__products-title'}>
              {I18n.t(T.communityProfile.profileCard.productsTitle)}
            </p>
            <div className={'c-account-profile-card__products'}>
              <AccountProfileCardAccessories accessoriesList={products} />
            </div>
          </>
        )}
        {/* TODO: Uncomment if tags will be available */}
        {/*{tags && (
        <>
          <hr className={'c-account-profile-card__separator'} />
          <div className={'c-account-profile-card__tags'}>
            <p className={'c-account-profile-card__tags-title'}>
              {I18n.t(T.communityProfile.profileCard.tagsTitle)}
            </p>
            <AccountProfileCardTags tags={tags} />
          </div>
        </>
      )} */}
        {description && (
          <>
            <hr className={'c-account-profile-card__separator'} />
            <DefaultParagraph>
              {description}
            </DefaultParagraph>
          </>
        )}
      </div>
      {link && (
        <div className={'c-account-profile-card__link'}>
          <AccountProfileCardLink path={link.path}>
            {I18n.t(link.label)}
          </AccountProfileCardLink>
        </div>
      )}
    </div>
  );
};
