export const Y_CANVAS_MARGIN = 0;
export const X_AXIS_MARGIN = 0;
export const Y_AXIS_MARGIN = 15;

export const AXIS_DOMAIN_MARGIN = 5;

export const Y_DOMAIN = [49.85, 50.05];

export const GRADIENT = [
  '#dcd9d9',
  '#a7a7a7',
];

export const MOBILE = {
  CIRCLE: {
    RADIUS: 20,
  },
  LABEL: {
    DIMS: [
      65,
      25,
    ],
    RADIUS: 5,
    MARGIN: 25,
  },
  POSITION: {
    CHART: [35, 45],
    CLIP: [35],
  },
};

export const DESKTOP = {
  CIRCLE: {
    RADIUS: 25,
  },
  LABEL: {
    DIMS: [
      100,
      35,
    ],
    RADIUS: 5,
    MARGIN: 35,
  },
  POSITION: {
    CHART: [45, 45],
    CLIP: [45],
  },
};
