import { createReducer } from '+utils/redux';
import { LAYOUT_ACTIONS, LayoutActions } from './layout.actions';
import { initialState, LayoutState } from './layout.state';

export const layoutReducer = createReducer<LayoutState, LayoutActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case LAYOUT_ACTIONS.TOGGLE_MOBILE_NAVIGATION:
        return {
          ...state,
          isMobileNavOpen: action.status,
          isSiteSwitcherOpen: false,
        };
      case LAYOUT_ACTIONS.TOGGLE_SITE_SWITCHER:
        return {
          ...state,
          isSiteSwitcherOpen: action.status,
        };
      case LAYOUT_ACTIONS.TOGGLE_LAYOUT_SCROLLING:
        return {
          ...state,
          isLayoutScrollingDisabled: action.status,
        };
      default:
        return state;
    }
  },
);
