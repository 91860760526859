import { createReducer } from '+utils/redux';
import { DASHBOARD_ACTIONS, DashboardActions } from './dashboard.actions';
import { DashboardState, initialState } from './dashboard.state';

export const reducer = createReducer<DashboardState, DashboardActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case DASHBOARD_ACTIONS.TOGGLE_MODAL_BACKUP_BUFFER:
        return {
          ...state,
          isModalBackupBufferVisible: !state.isModalBackupBufferVisible,
        };
      default:
        return state;
    }
  },
);
