import { T } from '@sonnen/shared-i18n/customer';
import { CountryCode, StatisticsResolution, useCountryFeature } from '@sonnen/shared-web';
import { hasIn } from 'lodash';
import { pipe } from 'lodash/fp';
import { Moment } from 'moment';
import numbro from 'numbro';
import * as R from 'ramda';
import { I18n } from 'react-redux-i18n';

import { ContractTypes } from '+app/+dashboard/+contract/store/types/contract.interface';
import { isValidNumber } from '+app/utils/number.util';
import { CountryFeatureName } from '+config/countryFlags';
import { Faq } from './types/faq.interface';
import {
  CalcValue,
  EnergyCalculatedValues,
  EnergyFormValues,
} from './types/taxation.interface';

export enum EnergyValuesKeys {
  GRID_PURCHASE = 'gridPurchaseEnergy',
  GRID_FEEDIN = 'gridFeedinEnergy',
  PRODUCTION = 'producedEnergy',
  CONSUMPTION = 'consumedEnergy',
  ENERGY_COSTS = 'energyCosts',
  VAT_VALUE = 'vatValue',
}

export const FetchedEnergyValues = [
  EnergyValuesKeys.GRID_PURCHASE,
  EnergyValuesKeys.GRID_FEEDIN,
  EnergyValuesKeys.PRODUCTION,
  EnergyValuesKeys.CONSUMPTION,
  EnergyValuesKeys.VAT_VALUE,
];

const decimalPlaces = {
  [EnergyValuesKeys.GRID_PURCHASE]: 1,
  [EnergyValuesKeys.GRID_FEEDIN]: 1,
  [EnergyValuesKeys.PRODUCTION]: 1,
  [EnergyValuesKeys.CONSUMPTION]: 1,
  [EnergyValuesKeys.ENERGY_COSTS]: 2,
};

export const defaultWattMantissa = 1;
export const defaultCurrencyMantissa = 2;

const defaultWattFormatting = {
  thousandSeparated: true,
  mantissa: defaultWattMantissa,
};

const energyFieldFormatting = {
  thousandSeparated: false,
  optionalMantissa: true,
  trimMantissa: false,
  mantissa: defaultWattMantissa,
};

const defaultCurrencyFormatting = {
  thousandSeparated: true,
  mantissa: defaultCurrencyMantissa,
};

export enum VatValues {
  VAT_16 = 16,
  VAT_19 = 19,
}

export type VatOption = {
  value: VatValues,
  label: string,
};

export const VatOptions = [
  {
    value: VatValues.VAT_16,
    label: '16%',
  },
  {
    value: VatValues.VAT_19,
    label: '19%',
  },
] as const;

// TODO [refactor] add proper generic typing here
export const insertFormValues = (values: any, setValues: (...args: any[]) => any) => R.compose(
  R.mapObjIndexed<number, string>((value, key) =>
    isValidNumber(value) ? setValues(key, numbro(value).format({
      thousandSeparated: true,
      mantissa: decimalPlaces[key],
    })) : ''),
  R.pick(Object.values(EnergyValuesKeys)),
)(values);

export const camelizeStrings = R.compose(
  R.join(''),
  R.addIndex(R.map)(
    (word: any, index: number) =>
      (index > 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word),
  ),
  R.split(/_|\//),
  R.toLower,
);

export const getTaxationCalculations = (taxValues: EnergyFormValues = {}): EnergyCalculatedValues => {

  let price: CalcValue[] = [];
  let vat: CalcValue[] = [];
  let selfConsumption: CalcValue[] = [];

  if (
    !R.isNil(taxValues.producedEnergy)
    && !R.isNil(taxValues.consumedEnergy)
    && !R.isNil(taxValues.gridFeedinEnergy)
    && !R.isNil(taxValues.gridPurchaseEnergy)
  ) {
    const production = numbro.unformat(taxValues.producedEnergy);
    const gridFeedin = numbro.unformat(taxValues.gridFeedinEnergy);
    const gridPurchase = numbro.unformat(taxValues.gridPurchaseEnergy);
    const selfConsumptionValue = production - gridFeedin;

    if (!R.isNil(taxValues.energyCosts)) {
      const energyCosts = numbro.unformat(taxValues.energyCosts);
      const energyPriceValue = energyCosts / gridPurchase;

      price = [{
        value: numbro(energyPriceValue).format(defaultCurrencyFormatting),
        label: T.tax.form.units.currencyPerEnergy,
      }];

      vat = [{
        value: numbro(selfConsumptionValue * energyPriceValue
          - selfConsumptionValue * energyPriceValue / 1.19).format(defaultCurrencyFormatting),
        label: T.tax.form.units.currency,
      }];
    }

    selfConsumption = [{
      value: numbro(selfConsumptionValue).format(defaultWattFormatting),
      label: T.tax.form.units.kwh,
    }, {
      value: numbro(Math.round((production - gridFeedin)
        * 100 / production)).format({
          thousandSeparated: true,
          mantissa: 0,
        }),
      label: T.tax.form.labels.period,
      secondary: true,
    }];
  }

  return {
    ...taxValues,
    selfConsumption,
    price,
    vat,
  };
};

const createFaqList = (ids: string): Faq[] => ids.split(',')
  // NOTE hacky check to filter non-existing translations. I18n.t() returns the key if translation not found.
  // the title is being checked because the EN-US holds context and country for all other languages.
  .filter(faqId => hasIn(T, `helpAndSupportPage.faqList.faqs.${faqId}`)
    && T.helpAndSupportPage.faqList.faqs[faqId].title !== I18n.t(T.helpAndSupportPage.faqList.faqs[faqId].title))
  .map((faqId: string) => ({
    title: I18n.t(T.helpAndSupportPage.faqList.faqs[faqId].title),
    content: I18n.t(T.helpAndSupportPage.faqList.faqs[faqId].content),
    context: I18n.t(T.helpAndSupportPage.faqList.faqs[faqId].context),
    country: I18n.t(T.helpAndSupportPage.faqList.faqs[faqId].country),
  }));

// @TODO unify faq filters to one function
export const filterFaqTopicsByContractType = (list: any[], contractType?: string) => {
  const contractCategories: string[] =
    [ContractTypes.SONNEN_FLAT_HOME, ContractTypes.SONNEN_FLAT_X].map(category => category.toLowerCase());

  return list.filter(({ value }: { value: string }) => {
    const topicName = value.toLowerCase();
    const isContractFaq = contractCategories.includes(topicName);

    return isContractFaq
      ? contractType?.toLowerCase() === topicName
      : true;
  });
};

const filterFaqByCountry = (countryCode: CountryCode) => (list: Faq[]) =>
  list.filter(({ country }: { country: string }) =>
    country && country.toLowerCase().includes(countryCode.toLowerCase()));

const filterFaqByContractType = (contractType?: string) => (list: Faq[]) => {
  const contractCategories: string[] =
    [ContractTypes.SONNEN_FLAT_HOME, ContractTypes.SONNEN_FLAT_X].map(category => category.toLowerCase());

  return list.filter(({ context }: { context: string }) => {
    const contextName = context.toLowerCase();
    const isContractFaq = contractCategories.includes(contextName);

    return isContractFaq
      ? contractType?.toLowerCase() === contextName
      : true;
  });
};

// TODO add memoization to this fn, as it's called multiple times on each app render.
export const getFilteredFaqList = (countryCode: CountryCode | undefined, contractType?: string) => {
  const faqAvailableIds = I18n.t(T.helpAndSupportPage.faqList.availableFaqs);

  if (!countryCode) {
    return createFaqList(faqAvailableIds);
  }

  return pipe([
    createFaqList,
    filterFaqByCountry(countryCode),
    filterFaqByContractType(contractType),
  ])(faqAvailableIds);
};

export const hasContactForm = () => useCountryFeature(CountryFeatureName.CONTACT_FORM).isEnabled;

export const hasFaqList = (code: CountryCode | undefined) => !!code && !!getFilteredFaqList(code).length;

export const supportMessageCaseType = 'customer';

export type TaxationFilters = {
  start: Moment,
  end: Moment,
  resolution: StatisticsResolution,
};

export const formatKWh = (val: string | number | undefined) => {
  const value = typeof val === 'string' ? numbro.unformat(val) : val;
  return isValidNumber(value) ? numbro(value).format(energyFieldFormatting) : val;
};

export const numberValueFormatter = (oldVal?: string) => (val: string) => {
  const separator = new RegExp(/\,|\./);
  if (!val || typeof val !== 'string') {
    return val;
  }

  return val.replace(/[^\d\.\,]/, '');

  // TODO: Make it work to keep only 1 separator in value at all times

  // if (!oldVal || oldVal.length >= out.length || !separator.test(oldVal)) {
  //     return out;
  // }

  // const diffIndex = out.split('').findIndex((char, i) => char !== oldVal[i]);
  // if (diffIndex < 0 || !separator.test(out[diffIndex])) {
  //   return out;
  // }

  // const firstIndex = out.split('').findIndex(x => separator.test(x));
  // if (firstIndex !== diffIndex) {
  //   out = out.substring(0, firstIndex) + out.substring(firstIndex + 1);
  //   return out;
  // }

  // const nextIndex = out.substring(diffIndex + 1).split('').findIndex(x => separator.test(x)) + diffIndex + 1;
  // if (nextIndex !== diffIndex) {
  //   out = out.substring(0, nextIndex) + out.substring(nextIndex + 1);
  // }

  // return out;
};

export const hasTechnicalSupport = (countryCode: CountryCode | null | undefined) => {
  const validCoutries = [
    CountryCode.DE,
    CountryCode.AT,
    CountryCode.CH,
    CountryCode.AU,
    CountryCode.IT,
    CountryCode.US,
    CountryCode.GB,
    CountryCode.IE,
    CountryCode.NZ,
    CountryCode.CA,
    CountryCode.PR,
    CountryCode.DO,
    CountryCode.BM,
    CountryCode.CO,
    CountryCode.VI,
    CountryCode.MX,
    CountryCode.CR,
  ];

  return !!countryCode && validCoutries.some(code => code === countryCode);
};
