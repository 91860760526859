import { SortOrder } from '@coolio/json-api';

import { CONFIG } from '+config';
import { useHttp, useJsonApi } from '+shared/network';
import { ContentDocumentData, InvoiceData } from './types/invoices.interface';
import { OpportunityData, SystemDocumentData } from './types/opportunities.interface';

const NUMBER_OF_INVOICES_PER_PAGE = 5;

const fetchInvoices = ({ contractId, page }: { contractId: string, page: number }) => useJsonApi()
  .getList<InvoiceData>(`${CONFIG.API.USER_DATA}/contracts/${contractId}/invoices?page[size]=${NUMBER_OF_INVOICES_PER_PAGE}`)
  .pageNumber(page)
  .sort('invoice_date', SortOrder.DESCENDING)
  .sort('billing_period_start', SortOrder.DESCENDING)
  .send();

const downloadInvoice = ({ invoiceId }: { invoiceId: string }) => useHttp()
  .get<ArrayBuffer>(`${CONFIG.API.USER_DATA}/invoices/${invoiceId}/document`)
  .then(res => res.parsedBody());

const fetchOpportunities = () => useJsonApi()
  .getList<OpportunityData>(`${CONFIG.API.USER_DATA}/opportunities`)
  .send();

const fetchSystemDocuments = ({ buildingDetailId }: {buildingDetailId: string}) => useJsonApi()
  .getList<SystemDocumentData>(`${CONFIG.API.USER_DATA}/documents/building_detail/${buildingDetailId}`)
  .send();

const downloadSystemDocument = ({ buildingDetailId, systemDocId }: { buildingDetailId: string, systemDocId: string }) =>
  useHttp()
  .get<ArrayBuffer>(`${CONFIG.API.USER_DATA}/documents/building_detail/${buildingDetailId}/download/${systemDocId}`)
  .then(res => res.parsedBody());

const getEnergyContractInvoices = ({ contractId }: { contractId: string, page: number }) => useJsonApi()
  .getList<ContentDocumentData>(`${CONFIG.API.USER_DATA}/documents/energy_contract/${contractId}`)
  .send();

const downloadEnergyContractInvoice = (
  { invoiceId, contractId }: { invoiceId: string, contractId: string },
) => useHttp()
  .get<ArrayBuffer>(`${CONFIG.API.USER_DATA}/documents/energy_contract/${contractId}/download/${invoiceId}`)
  .then(res => res.parsedBody());

export const DocumentsRepository = {
  fetchInvoices,
  downloadInvoice,
  fetchOpportunities,
  fetchSystemDocuments,
  downloadSystemDocument,
  getEnergyContractInvoices,
  downloadEnergyContractInvoice,
};
