import { Icon, ScrollableContainer } from '@sonnen/shared-web';
import * as React from 'react';

import { SiteSwitcherList } from '+app/shared/containers';
import { formatSiteAddress } from '+legacy/helpers/strings';
import { Site } from '+shared/store/site/types/site.interface';
import { HeaderDropdown } from '../../components';

import './UserSites.component.scss';

interface Props {
  activeSite?: Site;
  allSites: Site[];
}

export const UserSites: React.FC<Props> = ({
  activeSite,
  allSites = [],
}) => activeSite ? (
  <HeaderDropdown
    dataTestId={'user-sites-dropdown'}
    label={formatSiteAddress(activeSite)}
    icon={<Icon.House />}
    hasArrow={Boolean(allSites.length > 1)}
  >
    {(allSites.length > 1) && (
      <div className={'c-user-sites__overflow-container'}>
        {/* @TODO: Replace <ScrollableContainer /> with native scrollbar. */}
        {(allSites.length > 4) ? (
          <ScrollableContainer height={376}>
            <SiteSwitcherList />
          </ScrollableContainer>
        ) : (
          <SiteSwitcherList />
        )}
      </div>
    )}
  </HeaderDropdown>
) : null;
