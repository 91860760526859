import * as React from 'react';

import './Image.component.scss';

interface Props {
  alt?: string;
  src: string;
  srcDefault?: string;
  srcRetina?: string;
}

export const Image: React.FC<Props> = ({
  alt,
  src,
  srcDefault,
  srcRetina,
}) => (
  <img
    className={'c-image'}
    srcSet={srcRetina
      ? `${src}, ${srcRetina} 2x`
      : src
    }
    src={srcDefault ? srcDefault : src}
    alt={alt}
  />
);
