import { PlatformSelector } from '+components';
import * as React from 'react';
import { CommunityGraphMobile } from './CommunityGraph.mobile.component';
import { CommunityGraphWeb } from './CommunityGraph.web.component';

export const CommunityGraph: React.FC = () => (
  <PlatformSelector
    web={<CommunityGraphWeb />}
    mobile={<CommunityGraphMobile />}
  />
);
