import * as React from 'react';
import { InfographicIconChargerCable } from './InfographicIconChargerCable.component';
import { InfographicIconChargerCar } from './InfographicIconChargerCar.component';
import { InfographicIconChargerStation } from './InfographicIconChargerStation.component';

import '../InfographicIcon.util.scss';

type PointXY = {
  x: number;
  y: number;
};

interface Props {
  station: PointXY;
  cable: PointXY;
  car: PointXY;
  isActive: boolean;
  onClick: () => void;
}

export const InfographicIconCharger: React.FC<Props> = ({
  station,
  cable,
  car,
  onClick,
  isActive,
}) => (
  <g
    onClick={onClick}
    className={'u-infographic-icon__hover'}
    data-test-id={'dashboard-infographic-icon-charger'}
  >
    <InfographicIconChargerStation
      x={station.x}
      y={station.y}
      isActive={isActive}
    />
    <InfographicIconChargerCable
      x={cable.x}
      y={cable.y}
      isActive={isActive}
    />
    <InfographicIconChargerCar
      x={car.x}
      y={car.y}
      isActive={isActive}
    />
  </g>
);
