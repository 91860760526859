import * as React from 'react';

import { NestedNavContent, useNestedNav } from '../NestedNav.component';
import { NestedNavSlide } from '../NestedNavSlide';
import { NestedNavSpec } from '../spec/NestedNavSpec.types';

import './NestedNavSlider.component.scss';

const renderSlides = (slides: NestedNavContent[]) => (
  slides.map((slide, index) => (
    <div
      key={`${index}-${slide.title}`}
    >
      <NestedNavSlide
        slideIndex={index}
        slide={slide}
      />
    </div>
)));

export const NestedNavSlider: React.FC = () => {
  const { slides } = useNestedNav();

  return (
    <div
      className={'c-nested-nav-slider'}
      data-test={NestedNavSpec.NESTED_NAV_SLIDER}
    >
      <div className={'c-nested-nav-slider__slides'}>
        {renderSlides(slides)}
      </div>
    </div>
  );
};
