import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { QueryData } from '+shared/store/query/query.state';
import { InfographicHelper } from '../../containers';
import { InfographicCardConnectionStatusType } from '../../containers/Infographic/Infographic.types';

const getCardStatus = (
  siteLiveData: SiteLive | undefined,
  siteLiveQuery: QueryData,
): InfographicCardConnectionStatusType => {
  switch (true) {
    case (siteLiveData?.online === false):
      return InfographicCardConnectionStatusType.OFFLINE;
    case InfographicHelper.isDataError(siteLiveData, siteLiveQuery):
      return InfographicCardConnectionStatusType.DATA_MISSING;
    default:
      return InfographicCardConnectionStatusType.OK;
  }
};

export const InfographicPVCardHelper = {
  getCardStatus,
};
