import * as React from 'react';

import { useAnalytics } from '+shared/helpers/analytics/useAnalytics.hook';

export const useVirtualPageView = () => {
  const { sendPageView } = useAnalytics();

  React.useEffect(() => {
    sendPageView();
  }, []);
};
