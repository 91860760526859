import * as classNames from 'classnames';
import * as React from 'react';

import '../InfographicIcon.util.scss';

interface Props {
  x: number;
  y: number;
  isActive?: boolean;
  onClick: () => void;
}

/* tslint:disable:max-line-length */
export const InfographicIconCommunity: React.FC<Props> = ({
  x,
  y,
  onClick,
  isActive,
}) => (
  <svg
    className={classNames('u-infographic-icon u-infographic-icon__hover', { 'is-active': isActive })}
    version={'1.1'}
    xmlSpace={'preserve'}
    x={x}
    y={y}
    viewBox={'0 0 98 49'}
    width={'98'}
    height={'49'}
    onClick={onClick}
    data-test-id={'dashboard-infographic-icon-sonnen-community'}
  >
    <polygon
      className={'u-infographic-icon__fill'}
      points={'94.5,28.5 89.4,21.2 65.5,21.2 65.5,17.9 44.7,2.5 27.8,15 17.8,5.5 3.3,19.1 3.3,42 23.9,42 23.9,43.5 60.1,43.5 60.1,46.3 91.6,46.3 91.6,28.5'}
    />
    <g transform={'translate(33, 18)'}>
      <path
        className={'u-infographic-icon__contour'}
        d={'M8.4,7.5H1V1h7.3V7.5z M2.2,6.3H7V2.2H2.2V6.3z'}
      />
    </g>
    <g transform={'translate(33, 30)'}>
      <path
        className={'u-infographic-icon__contour'}
        d={'M8.4,6.5H1v-6.6h7.3V6.5z M2.2,5.3H7V1.2H2.2V5.3z'}
      />
    </g>
    <g transform={'translate(49, 18)'}>
      <path
        className={'u-infographic-icon__contour'}
        d={'M8.1,7.5H0.8V1h7.3V7.5z M2,6.3h4.9V2.2H2V6.3z'}
      />
    </g>
    <g transform={'translate(49, 30)'}>
      <path
        className={'u-infographic-icon__contour'}
        d={'M8.1,6.5H0.8v-6.6h7.3V6.5z M2,5.3h4.9V1.2H2V5.3z'}
      />
    </g>
    <path
      className={'u-infographic-icon__contour'}
      d={'M95.6,28.8c0.1-0.2,0.1-0.5,0-0.7l-0.3-0.4c-1.8-2.2-3.6-4.5-5.4-6.7c-0.3-0.3-0.6-0.5-1-0.5c-7.4,0-14.9,0-22.4,0v-3.2l-21.7-16L27.9,13.8L17.8,4.3L2.3,18.8v24.1h20.6v1.5h36.6v2.9h32.7V29.2h2.6h0.1C94.9,29.2,95.4,29.2,95.6,28.8z M22.9,20.1V41H4.2V20.1H22.9z M5.2,18.7L17.8,6.8l8.5,8.1l-3.3,2.5v1.3L5.2,18.7L5.2,18.7z M92.7,27.4H58.6c1.4-1.7,2.8-3.4,4.1-5.1c5.4,0,10.8,0,16.2,0h9.6c1.3,1.6,2.6,3.1,3.9,4.8L92.7,27.4z M64.6,20.6h-2c-0.4,0-0.8,0.2-1,0.5c-1.3,1.6-2.6,3.1-3.9,4.8l-1.5,1.9c-0.2,0.2-0.4,0.6-0.4,0.8c0,0.4,0.3,0.5,0.4,0.6c0.2,0,0.4,0,0.6,0h2.9v13.5H24.9V18.4L44.7,3.7l19.9,14.7V20.6z M61.2,45.6V29.1h29.1v16.4L61.2,45.6L61.2,45.6z'}
    />
    <circle cx={'50'} cy={'25'} r={'50'} fill={'#000000'} fillOpacity={'0'} />
  </svg>
);
