import { DateUtil } from '+utils/date.util';
import { T } from '@sonnen/shared-i18n/customer';
import {
  AnalysisToolbar,
  DateShift,
  DateSwitcher,
  EnergyFlowSeriesKey,
  getMinimalMeasurementDate,
  Icon,
  Legend,
  shiftDate,
  useFeature,
} from '@sonnen/shared-web';
import { TimeUnit } from '@sonnen/shared-web';
import * as moment from 'moment';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { useAnalysis } from '+analysis/containers/AnalysisProvider/AnalysisProvider.context';
import { FeatureName } from '+config/featureFlags';
import { provideLocale } from '+legacy/helpers/i18n.helper';
import { AnalysisDatePicker } from '../../components';
import { formatDate, getDateFormatForPeriod } from '../../helpers/analysis.helper';
import { getLegendDataSeries } from '../../store/analysis.helpers';

import './AnalysisDayToolbar.component.scss';

export const AnalysisDayToolbar: React.FC = () => {
  const [isDatePickerOpen, setDatePickerState] = React.useState<boolean>(false);

  const {
    commonData: {
      batteryData,
      maxDate,
      measurementsMethod,
      selectedDate,
      selectedDataSeriesKeys,
    },
    actions,
  } = useAnalysis();

  const minimalDate = getMinimalMeasurementDate(batteryData.installationDate);
  const maximumDate = useFeature(FeatureName.ANALYSIS_FORECAST).isEnabled ? maxDate : undefined;

  // TODO: Simplify this fn
  const createDatepickerOpenHandler = (isOpen?: boolean) => () => {
    setDatePickerState(typeof isOpen === 'boolean' ? isOpen : !isDatePickerOpen);
  };

  const changeDate = (date: Date) => {
    actions.setSelectedDate(DateUtil.getDateInTimezone(date, batteryData.timeZone).startOf('day'));
    actions.getMeasurements();
    actions.getBatteryCellCareHistory();
    actions.getEvents();
    createDatepickerOpenHandler(false)();
  };

  const shiftSelectedDate = (shift: DateShift) => {
    changeDate(shiftDate(
      selectedDate,
      TimeUnit.DAY,
      shift,
    ));
  };

  return (
    <div className={'c-analysis-day-toolbar'}>
      <AnalysisToolbar
        // @TODO: Change to proper translation
        dateSwitcherTitle={I18n.t(T.analysis.datepickerLabel)}
        datePicker={(
          <AnalysisDatePicker
            isActive={isDatePickerOpen}
            date={selectedDate}
            minDate={minimalDate}
            maxDate={maximumDate}
            onDayClick={changeDate}
            activeDatePicker={TimeUnit.DAY}
            tabsTriggers={[{ label: I18n.t(T.mobileApp.analysis.dateLabels.day) }]}
            locale={provideLocale()}
          />
        )}
        dateSwitcher={(
          <DateSwitcher
            icon={<Icon.Calendar className={'c-analysis-day-toolbar__date-switcher-icon'}/>}
            date={selectedDate.toDate()}
            dateFormatter={() => formatDate(selectedDate, getDateFormatForPeriod('day'))}
            canGoPrev={moment(selectedDate).isAfter(minimalDate)}
            canGoNext={moment(selectedDate).isBefore(moment(maximumDate).startOf('day'))}
            onPrevSelected={() => shiftSelectedDate(DateShift.BACK)}
            onNextSelected={() => shiftSelectedDate(DateShift.FORWARD)}
            onDateSelected={createDatepickerOpenHandler()}
          />
        )}
        legend={(
          <Legend
            onToggle={(key: string) => actions.toggleAreaChartSeries(key as EnergyFlowSeriesKey)}
            dataSeries={measurementsMethod ? getLegendDataSeries(selectedDataSeriesKeys, measurementsMethod) : []}
          />
        )}
        onDatePickerOutsideClick={createDatepickerOpenHandler(false)}
      />
    </div>
  );
};
