import { T } from '@sonnen/shared-i18n/customer';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { hasInstalledBattery } from '+app/+dashboard/+battery/store/battery.selectors';
import { StoreState } from '+app/store/store.interface';
import { ContractCard } from '../../components';
import { ContractStatus } from '../../components/ContractCard/types/ContractCard.interface';

import '../../components/ContractCard/ContractCard.component.scss';

const mapStateToProps = (store: StoreState) => ({
  isBatteryInstalled: hasInstalledBattery(store),
});

type Props = ReturnType<typeof mapStateToProps>;

const HardwareInstallation: React.FC<Props> = ({
  isBatteryInstalled,
}) => (
  <ContractCard
    title={I18n.t(T.yourFlat.sonnenFlatX.cardHardware.title)}
    subtitle={I18n.t(T.yourFlat.sonnenFlatX.cardHardware.subtitle)}
    icon={'Guarantee'}
    status={isBatteryInstalled ? ContractStatus.DONE : ContractStatus.TODO}
  />
);

export const ContractCardHardwareInstallation = connect(mapStateToProps)(
  HardwareInstallation,
);
