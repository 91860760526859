import { createSelector } from 'reselect';

import { SyncState } from './sync.state';

export const getSyncState = (store: any): SyncState => store.sync;

export const isLoading = createSelector(
  getSyncState,
  state => state.isLoading,
);
