import { createReducer } from '+utils/redux';
import { INTEGRATION_ACTIONS, IntegrationActions } from './mobile.actions';
import { initialState, MobileAppState, MobileState } from './mobile.state';

export const reducer = createReducer<MobileState, IntegrationActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case INTEGRATION_ACTIONS.SET_APP_STATE:
        if (action.state === MobileAppState.INACTIVE) {
          const appInactiveEvent = new CustomEvent('appInactiveEvent');
          document.dispatchEvent(appInactiveEvent);
        }
        return {
          ...state,
          appState: action.state,
        };
      case INTEGRATION_ACTIONS.FORWARD_VERSION_INFO:
        return {
          ...state,
          versionName: action.versionName,
          flagConfig: action.flagConfig,
          instanceId: action.instanceId,
        };
      default:
        return state;
    }
  },
);
