import * as React from 'react';
import { connect } from 'react-redux';

import { CessionStatus } from '+app/+guide/store/types';
import { StoreState } from '+app/store/store.interface';
import { GuideActions } from '+guide/store/+guide.actions';
import { getCessionFailed, getCessionStatus } from '+guide/store/+guide.selectors';
import {
  GuideAcceptanceCompletion,
  GuideAcceptanceSignFailure,
} from '../../components';

const mapStateToProps = (state: StoreState) => ({
  cessionStatus: getCessionStatus(state),
  cessionFailed: getCessionFailed(state),
});

const mapDispatchToProps = {
  getCessionStatus: GuideActions.getCessionStatus,
};

type Props =
  & ReturnType<typeof mapStateToProps>
  & typeof mapDispatchToProps;

export const GuideAcceptanceFinalStepComponent: React.FC<Props> = ({
  cessionStatus,
  getCessionStatus,
  cessionFailed,
}) => {

  React.useEffect(() => {
    getCessionStatus();
  }, []);

  const getCessionStatusFailure = () =>
    cessionStatus && [CessionStatus.REJECTED, CessionStatus.NEW].includes(cessionStatus);

  return (
    <>
      {cessionStatus === CessionStatus.SIGNED && <GuideAcceptanceCompletion />}
      {(getCessionStatusFailure() || cessionFailed) && (
        <GuideAcceptanceSignFailure />
      )}
    </>
  );
};

export const GuideAcceptanceFinalStep = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GuideAcceptanceFinalStepComponent);
