import * as React from 'react';

import './Product.component.scss';

interface Props {
  title: string;
  subtitle?: string;
  icon: React.ReactNode;
}

export const Product: React.FC<Props> = ({
  title,
  subtitle,
  icon,
}) => (
  <div className={'c-product'}>
    <div className={'c-product__side'}>
      {icon}
    </div>
    <div className={'c-product__content'}>
      <p className={'c-product__title'}>
        {title}
      </p>
      {subtitle && (
        <p className={'c-product__subtitle'}>
          {subtitle}
        </p>
      )}
    </div>
  </div>
);
