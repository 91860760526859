import { T } from '@sonnen/shared-i18n/customer';
import { Card, Switch } from '@sonnen/shared-web';
import { DefaultParagraph } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { AccountTooltipError } from '../AccountTooltipError';

import './AccountSettingsCard.component.scss';

interface Props {
  description: string;
  icon: React.ReactNode;
  isChecked: boolean;
  isDisabled?: boolean;
  isError?: boolean;
  isThemeReverse?: boolean;
  name: string;
  onToggle: () => void;
  subtitle: string;
  title: React.ReactNode;
}

export const AccountSettingsCard: React.FC<Props> = ({
  description,
  icon,
  isChecked,
  isDisabled,
  isError,
  name,
  onToggle,
  subtitle,
  title,
}) => (
  <div className={'c-account-settings-card'}>
    <Card title={title}>
      <div>
        <div className={'c-account-settings-card__action-bar'}>
          <span className={'c-account-settings-card__toggle-title'}>
            {icon}
            {subtitle}
          </span>
          <div className={'c-account-settings-card__switch-wrapper'}>
            {isError && (
              <AccountTooltipError>
                {I18n.t(T.settings.error)}
              </AccountTooltipError>
            )}
            <Switch
              dataTestId={'account-settings-settings-card-privacy-permission-switch'}
              name={name}
              isChecked={isChecked}
              isDisabled={isDisabled}
              onToggle={onToggle}
            />
          </div>
        </div>
        <div className={'c-account-settings-card__description'}>
          <DefaultParagraph>
            {description}
          </DefaultParagraph>
        </div>
      </div>
    </Card>
  </div>
);
