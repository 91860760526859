import * as React from 'react';

import { PlatformSelector } from '+components';
import { AnalysisHistoryMobile } from './AnalysisHistory.mobile.component';
import { AnalysisHistoryWeb } from './AnalysisHistory.web.component';

export const AnalysisHistory: React.FC = () => (
  <PlatformSelector
    web={<AnalysisHistoryWeb />}
    mobile={<AnalysisHistoryMobile />}
  />
);
