import { T } from '@sonnen/shared-i18n/customer';
import * as moment from 'moment';
import { I18n } from 'react-redux-i18n';

// @TODO: Make this helper only accept date as number
export const getFormattedDate = (
  date?: moment.MomentInput | null,
  format: string = T.general.date.default,
): string | null => date ? I18n.l(moment(date).unix() * 1000, { dateFormat: format }) : null;

export const dateToNow = (timestamp: string) => {
  const end = moment(timestamp);
  const now = moment();
  const duration = moment.duration(now.diff(end));
  const minutes = duration.asMinutes();
  const hours = duration.asHours();
  const days = duration.asDays();

  if (minutes < 60) {
    return I18n.t(T.general.date.sinceMinutes, { minutes: Math.floor(minutes) });
  }

  if (hours < 24) {
    return I18n.t(T.general.date.sinceHours, { hours: Math.floor(hours) });
  }

  if (days < 7) {
    return I18n.t(T.general.date.sinceDays, { days: Math.floor(days) });
  }

  return I18n.t(T.general.date.sinceDate, { date: getFormattedDate(timestamp) });
};

export const isDateInRange = (
  lastTimestamp: number | string | undefined,
  minThreshold: moment.Duration | number,
  maxThreshold?: moment.Duration | number,
): boolean => {
  if (!lastTimestamp) { return true; }

  const now = new Date(Date.now()).getTime();
  const timestamp = new Date(lastTimestamp).getTime();
  const timeDiff = now - timestamp;
  return minThreshold && maxThreshold
    ? timeDiff > minThreshold && timeDiff < maxThreshold
    : timeDiff > minThreshold;
};

export const getDeliveryStatus = (
  date: string | null,
  activeStatus: string,
  inactiveStatus: string,
  notDeliveredStatus: string,
) => {
  const formattedDeliveryDate = getFormattedDate(date);
  const deliveryDate = moment(date).valueOf();
  const now = moment().valueOf();

  if (!date) {
    return I18n.t(inactiveStatus);
  }
  if (now > deliveryDate) {
    return I18n.t(activeStatus, { start: formattedDeliveryDate });
  }

  return I18n.t(notDeliveredStatus, { start: formattedDeliveryDate });
};

export const DateHelper = {
  getFormattedDate,
};
