import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { LEGAL_ACTIONS, LegalActions } from './legal.actions';
import { GET_LEGAL_DOCUMENTS_QUERY, initialState, LegalState } from './legal.state';

export const reducer = createReducer<LegalState, LegalActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case LEGAL_ACTIONS.SET_LEGAL_DOCUMENTS_DATA:
        return {
          ...state,
          legalDocuments: action.legalDocuments,
        };
      default:
        return queryReducer(state, action, [
          GET_LEGAL_DOCUMENTS_QUERY,
        ]);
    }
  },
);
