import { T } from '@sonnen/shared-i18n/customer';
import { Icon, LinkButton, MediaQuery } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import Media from 'react-media';
import { I18n } from 'react-redux-i18n';

import {
  AnalysisAccessoriesChart,
  AnalysisEnergyFlowChart,
  AnalysisSummerTimeNotfication,
  getSummerTimeNotificationType,
} from '../';
import {
  useAnalysisDayChart,
} from '../../containers/AnalysisKanvaProvider/AnalysisDayChartProvider';
import { useAnalysis } from '../../containers/AnalysisProvider/AnalysisProvider.context';

import './AnalysisEnergyFlow.web.component.scss';

export const AnalysisEnergyFlowWeb: React.FC = () => {
  const {
    commonData: {
      accessoriesChartSeries,
      hasBackupBox,
      hasHeater,
      selectedDate,
      statisticsSelectedDate,
      summerTimeChange,
    },
    query,
} = useAnalysis();
  const { extensions, isChartZoomed } = useAnalysisDayChart();
  // NOTE: The same transform extension for Area and Accessories charts
  const transformExtension = extensions?.transform;
  const summerTimeNotificationType = getSummerTimeNotificationType(summerTimeChange);

  return (
    <>
      <AnalysisSummerTimeNotfication type={summerTimeNotificationType} />
      <Media query={MediaQuery.UP_SM}>
        {(isDesktop: boolean) => isDesktop ? (
          <>
            <div className={classNames('c-analysis-energy-flow__reset-wrapper', {
              'is-visible': isChartZoomed,
            })}>
              <LinkButton
                onClick={() => transformExtension && transformExtension.setScale({ x: 1, y: 1 })}
                icon={<Icon.Arrow />}
                iconClass={'c-analysis-energy-flow__reset-icon'}
              >
                {I18n.t(T.history.chart.resetZoom)}
              </LinkButton>
            </div>
          </>
        ) : null}
      </Media>
      <AnalysisEnergyFlowChart />
      {/* TODO: Refactor AnalysisAccessoriesChart to use useAnalysis hook itself */}
      <AnalysisAccessoriesChart
        hasBackupBox={hasBackupBox}
        hasHeater={hasHeater}
        selectedDate={selectedDate}
        statisticsSelectedDate={statisticsSelectedDate}
        queryStatus={hasBackupBox ? query.eventsQueryStatus : query.measurementsQueryStatus}
        isChartFullyLoaded={hasBackupBox
          ? query.eventsQueryStatus.success && query.measurementsQueryStatus.success
          : query.measurementsQueryStatus.success
        }
        chartSeries={accessoriesChartSeries}
        transformExtension={transformExtension}
        isChartZoomed={isChartZoomed}
      />
    </>
  );
};
