import { ActionsUnion, createAction } from '+utils/redux';

export enum GUIDE_SETUP_ACTIONS {
  TOGGLE_MODAL = '[+Setup] TOGGLE_MODAL',
  CLOSE_MODAL = '[+Setup] CLOSE_MODAL',
  CHECK_TAX_OFFICE_REGISTRATION = '[+Setup] CHECK_TAX_OFFICE_REGISTRATION',
  CHECK_BNETZA_REGISTRATION = '[+Setup] CHECK_BNETZA_REGISTRATION',
  CHECK_SETUP_STEP = '[+Setup] CHECK_SETUP_STEP',
}

export const GuideSetupActions = {
  toggleModal: (checked: boolean) => createAction(
    GUIDE_SETUP_ACTIONS.TOGGLE_MODAL,
    { checked },
  ),

  closeModal: () => createAction(
    GUIDE_SETUP_ACTIONS.CLOSE_MODAL,
  ),

  checkTaxOfficeRegistration: (checked: boolean) => createAction(
    GUIDE_SETUP_ACTIONS.CHECK_TAX_OFFICE_REGISTRATION,
    { checked },
  ),

  checkBNetzARegistration: (checked: boolean) => createAction(
    GUIDE_SETUP_ACTIONS.CHECK_BNETZA_REGISTRATION,
    { checked },
  ),
};

export type GuideSetupActions = ActionsUnion<typeof GuideSetupActions>;
