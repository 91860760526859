import { T } from '@sonnen/shared-i18n/customer';
import {
  Button,
  ButtonSize,
  Icon,
  InfoBanner,
  LinkButton,
  Modal,
  ModalLayout,
} from '@sonnen/shared-web';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { BatteryActions } from '+app/+dashboard/+battery/store';
import { getElectricUnits } from '+app/+dashboard/+battery/store/battery.selectors';
import { getPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { history } from '+app/router/store';
import { NotificationActions } from '+app/shared/store/notification/notification.actions';
import { NotificationHelper } from '+app/shared/store/notification/notification.helper';
import { getSystemComponentNotifications } from '+app/shared/store/notification/notification.selectors';
import { SystemNotificationType } from '+app/shared/store/notification/types/systemNotification';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { CONFIG } from '+config';

import './DashboardModalUnitExchange.component.scss';

const mapStateToProps = (state: StoreState) => {
  const electricUnits = getElectricUnits(state);
  const systemComponentNotifications = getSystemComponentNotifications(state);

  return {
    activeNotification: NotificationHelper.getActiveSystemComponentNotification(
      systemComponentNotifications,
      electricUnits,
    ),
  };
};

const mapDispatchToProps = mapActions({
  updateSystemNotification: NotificationActions.updateSystemNotification,
  getElectricUnits: BatteryActions.getBatteryElectricUnits,
});

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>;

const renderSubtitle = (handleLinkClick: () => void) => (
  <>
    {I18n.t(T.batterySystem.electricUnits.modalContent)}
    {!CONFIG.IS_MOBILE && (
      <>
        &nbsp;
        {I18n.t(T.batterySystem.electricUnits.modalContentWeb)}
        &nbsp;
        <LinkButton onClick={handleLinkClick}>
          {I18n.t(T.batterySystem.electricUnits.linkButton)}
        </LinkButton>
        {'.'}
      </>
    )}
  </>
);

const DashboardModalUnitExchangeComponent: React.FC<Props> = ({ activeNotification, actions }) => {
  const [isVisible, toggleVisibility] = React.useState(false);

  const closeModal = () => {
    actions.updateSystemNotification(SystemNotificationType.COMPONENT);
    toggleVisibility(false);
  };

  const redirectToDetails = () => {
    closeModal();
    history.push(getPath(RouteName.BATTERY_DETAILS));
  };

  React.useEffect(() => {
    toggleVisibility(!!activeNotification);

    if (activeNotification) { 
      actions.getElectricUnits();
    }
  }, [activeNotification]);

  return (
    <Modal
      size={'small'}
      isOpen={isVisible}
      onClose={closeModal}
    >
      <ModalLayout
        title={I18n.t(T.batterySystem.electricUnits.modalTitle)}
        footer={(
          <div className={'c-dashboard-modal-unit-exchange__footer'}>
            <Button
              label={I18n.t(T.batterySystem.electricUnits.modalButton)}
              size={ButtonSize.NORMAL}
              onClick={closeModal}
            />
          </div>
        )}
      >
        <div className={'c-dashboard-modal-unit-exchange__content'}>
          <InfoBanner
            icon={<Icon.Confetti />}
            title={I18n.t(T.batterySystem.electricUnits.modalSubHeadline)}
            subtitle={renderSubtitle(redirectToDetails)}
          />
        </div>
      </ModalLayout>
    </Modal>
  );
};

export const DashboardModalUnitExchange = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardModalUnitExchangeComponent);
