import { T } from '@sonnen/shared-i18n/customer';
import {
  BatteryCell,
  BatteryInverterState,
  Card,
  CardNotification,
  Icon,
  LinkButton,
  Switch,
} from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as moment from 'moment';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { InfographicActions } from '+app/+dashboard/+infographic';
import { BatteryHelper } from '+battery/store/battery.helpers';
import { Battery } from '+battery/store/types/battery.interface';
import { SpecsItem } from '+components';
import { StateAlert } from '+shared/components/StateAlert';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { BatteryActions } from '../../store/battery.actions';
import { BackupBufferProcessing } from '../../store/battery.state';
import { BatteryCardGrid } from '../BatteryCardGrid';

import './BatteryCardStatus.component.scss';

interface Actions {
  toggleModalBackupBuffer?: InfographicActions['toggleModalBackupBuffer'];
  setBackupBuffer?: typeof BatteryActions.setBackupBuffer;
}

interface Props {
  batteryData: Battery;
  liveData: SiteLive;
  backupBufferProcessing?: BackupBufferProcessing;
  isEmergencyModeActive?: boolean;
  isEmergencyModeAvailable?: boolean;
  actions?: Actions;
}

const getBackupBufferValue = (current: string, processing: number | undefined) => (
  isNil(processing)
  ? current
  : (
    <div className={'c-battery-card-status__backupbox-value'}>
      {current}
      <Icon.Arrow className={'c-battery-card-status__backupbox-value-icon'}/>
      {`${processing}%`}
    </div>
  )
);

export const BatteryCardStatus: React.FC<Props> = ({
  batteryData,
  backupBufferProcessing,
  isEmergencyModeActive,
  isEmergencyModeAvailable,
  liveData,
  actions,
}) => {
  const {
    backupPowerBuffer,
    batteryChargeCycles,
    controllerType,
    serialNumber,
  } = batteryData;

  const {
    batteryInverterState: batteryInverterState,
    batteryUsoc: batteryUsoc,
    timestamp,
    online,
  } = liveData;

  const isDataDelayed = timestamp &&
    BatteryHelper.isDataDelayed(new Date(timestamp), controllerType);

  const isBackupBoxConnected = batteryData && BatteryHelper.isBackupBoxConnected(batteryData);
  const isEditBackupBufferAvailable = actions && !!actions.toggleModalBackupBuffer
    && isBackupBoxConnected
    && online;
  const shouldShowEmergencyMode = isEmergencyModeAvailable
    && !!actions && !!actions.setBackupBuffer;

  const batteryChargeLevel = online ? batteryUsoc : 0;
  const batteryBackupLevel = backupPowerBuffer ? backupPowerBuffer : 0;
  const batteryStatus = (online && batteryInverterState)
    ? batteryInverterState
    : (!online
      ? undefined
      : BatteryInverterState.STANDBY);
  const batteryChargeLevelLabel = online ? `${batteryUsoc}%` : '-';
  const batteryBackupLevelLabel = online ? `${backupPowerBuffer}%` : '-';
  const batteryChargeCyclesCount = online ? batteryChargeCycles : '-';
  const backupBufferProcessingValue = backupBufferProcessing && backupBufferProcessing.processingValue;

  const handleToggleEmergencyMode = () => {
    // NOTE isEmergencyModeAvailable secures values to be always available here
    actions!.setBackupBuffer!(
      isEmergencyModeActive
        ? BatteryHelper.EMERGENCY_MODE_BACKUP_VALUES.default
        : BatteryHelper.EMERGENCY_MODE_BACKUP_VALUES.max,
    );
  };

  return (
    <div className={'c-battery-card-status'}>
      <Card
        title={I18n.t(T.batterySystem.overview.battery.title)}
        titleSide={!!(isEditBackupBufferAvailable) && (
          <LinkButton
            dataTestId={'sonnen-batterie-overview-backup-edit-link'}
            onClick={actions!.toggleModalBackupBuffer}
          >
            {I18n.t(T.batterySystem.overview.details.edit)}
          </LinkButton>
        )}
        footerContent={(
          <>
            {batteryStatus ? (
              <>
                <CardNotification 
                  dataTestId={'sonnen-batterie-overview-status-online'}
                  type={'success'}>
                  {I18n.t(T.batterySystem.notifications.online)}
                </CardNotification>
                {isDataDelayed && (
                  <div className={'c-battery-card-status__state-alert-wrapper'}>
                    <StateAlert
                      icon={<Icon.TimePast />}
                      small={true}
                      variant={'info'}
                    >
                      {I18n.t(T.dashboard.infographic.battery.delayMessage,
                        { time: moment(timestamp).format('Do MMMM, H:mm') },
                      )}
                    </StateAlert>
                  </div>

                )}
              </>
            ) : (
              <CardNotification 
                dataTestId={'sonnen-batterie-overview-status-offline'}
                type={'warning'}>
                {I18n.t(T.batterySystem.notifications.offline)}
              </CardNotification>
            )}
          </>
        )}
      >
        <BatteryCardGrid
          side={(
            <div className={'c-battery-card-status__battery-wrapper'}>
              <BatteryCell
                chargeLevel={batteryChargeLevel}
                hasBackupDevice={isBackupBoxConnected}
                backupLevel={batteryBackupLevel}
                status={batteryStatus}
                // TODO: Prepare easter egg.
                // enableEasterEgg
              />
            </div>
          )}
          content={(
            <>
              <SpecsItem
                dataTestId={'sonnen-batterie-overview-status-spec-charge-level'}
                title={I18n.t(T.batterySystem.overview.battery.headlines.top)}
                value={batteryChargeLevelLabel}
                isValueEmphasized={true}
              />
              {/* @TODO: Show 0 charge cycles as well when SB10 supports it. */}
              {!!batteryChargeCyclesCount && (
                <SpecsItem
                  dataTestId={'sonnen-batterie-overview-status-spec-cycles-count'}
                  title={I18n.t(T.batterySystem.overview.battery.headlines.middle)}
                  value={batteryChargeCyclesCount}
                />
              )}
              {isBackupBoxConnected && (
                <SpecsItem
                  dataTestId={'sonnen-batterie-overview-status-spec-backup'}
                  title={I18n.t(T.batterySystem.overview.battery.headlines.bottom)}
                  value={(
                    <div className={'c-battery-card-status__backupbox-value-wrapper'}>
                      {backupBufferProcessing
                        ? getBackupBufferValue(batteryBackupLevelLabel, backupBufferProcessingValue)
                        : batteryBackupLevelLabel
                      }
                      {backupBufferProcessing && (
                        <div className={'c-battery-card-status__backupbox-status'}>
                          <StateAlert
                            icon={<Icon.TimePast />}
                            small={true}
                            variant={'warning'}
                          >
                            {I18n.t(T.batterySystem.backupBuffer.pendingStatus)}
                          </StateAlert>
                        </div>
                      )}
                    </div>
                  )}
                />
              )}
              {shouldShowEmergencyMode && (
                <SpecsItem
                  dataTestId={'sonnen-batterie-overview-status-spec-emergency-mode'}
                  title={I18n.t(T.batterySystem.overview.battery.headlines.emergencyMode)}
                  infoTooltip={
                    <Translate
                      value={T.batterySystem.overview.battery.backupBuffer.infoTooltip}
                      dangerousHTML={true}
                    />
                  }
                  value={
                    <div className={'c-battery-card-status__switch-wrapper'}>
                      <Switch
                        dataTestId={'sonnen-batterie-overview-emergency-mode-switch'}
                        name={'emergencyMode'}
                        onToggle={handleToggleEmergencyMode}
                        isChecked={isEmergencyModeActive}
                        isDisabled={!isEditBackupBufferAvailable}
                      />
                    </div>
                  }
                />
              )}
            </>
          )}
        />
      </Card>
    </div>
  );
};
