import { T } from '@sonnen/shared-i18n/customer';
import 'd3-transition';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';
import { AXIS_DOMAIN_MARGIN, X_AXIS_MARGIN, Y_AXIS_MARGIN } from '../../../frequency.constants';

interface Props {
  value: number;
  width: number;
}

export class ExpectedValue extends React.PureComponent<Props> {
  static defaultProps = {
    value: 0,
    width: 0,
  };

  render() {
    const { value, width } = this.props;
    return (
      <g transform={`translate(0, ${Y_AXIS_MARGIN})`}>
        <text
          x={10}
          y={value - 5}
          className="c-frequency-chart__expected-line-text"
        >
          {I18n.t(T.sonnenCommunity.gridBalancing.chart.chartExpectedValue)}
        </text>
        <line
          x1={X_AXIS_MARGIN + AXIS_DOMAIN_MARGIN}
          x2={width}
          y1={value}
          y2={value}
          className="c-frequency-chart__expected-line"
        />
      </g>
    );
  }
}
