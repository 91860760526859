import { Nav, NavItem, NavListItem } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { getParentRoute } from '+app/router/router.helper';
import { Routes } from '+app/router/router.types';
import { DefaultNavSubnav } from './DefaultNavSubnav';
import { DefaultNavTag } from './DefaultNavTag';

export interface DefaultNavItem {
  path: string;
  isExpanded: boolean;
}

type DefaultLayoutState = Record<string, DefaultNavItem>;

interface OpenMenuPaths {
  mainPath: string;
  paths: string[];
}

interface Props {
  routes: Routes;
  isAlwaysExpanded?: boolean;
  isThemeNoIcon?: boolean;
  openMenuPaths?: OpenMenuPaths;
}

const isSubnavExpanded = (path: string, navState: Record<string, DefaultNavItem>) => {
  return navState[path]
    ? navState[path].isExpanded
    : false;
};

export const DefaultNav: React.FC<Props> = ({
  routes,
  isAlwaysExpanded,
  isThemeNoIcon,
}) => {
  const [navState, setNavState] = React.useState<DefaultLayoutState>({});
  const [currentShallowPath, setCurrentShallowPath] = React.useState<string>('');

  React.useEffect(() => {
    const trimmedCurrentPath = window.location.pathname.split('/', 3).join('/');
    const parentRoute = getParentRoute(routes, trimmedCurrentPath);
    setCurrentShallowPath(trimmedCurrentPath);
    
    if (parentRoute) {
      toggleNavItem({
        path: parentRoute.path,
        isExpanded: true,
      });
    }
  }, [window.location.pathname, routes.length]);

  const toggleNavItem = (navItem: DefaultNavItem) => {
    setNavState({
      ...navState,
      [navItem.path]: navItem,
    });
  };

  return (
    <Nav>
      {routes.map((route) => (
        <NavListItem
          isThemeUnderline={true}
          key={`default-nav-${route.name}`}
          data-test={`default-nav-${route.name}`}
        >
          {route.subRoutes ? (
            <DefaultNavSubnav
              currentShallowPath={currentShallowPath}
              rootRoute={route}
              routes={route.subRoutes}
              isExpanded={isSubnavExpanded(route.path, navState)}
              isAlwaysExpanded={isAlwaysExpanded}
              isThemeNoIcon={isThemeNoIcon}
              toggleNavItem={toggleNavItem}
            />
          ) : (
            <NavItem
              dataTestId={route.navigationDataTestId}
              action={route.path}
              icon={!isThemeNoIcon && route.icon && <route.icon />}
            >
              {I18n.t(route.label.web || '')}
              <DefaultNavTag tag={route.tag} />
            </NavItem>
          )}
        </NavListItem>
      ))}
    </Nav>
  );
};
