import * as React from 'react';

import { GTMEventData, useAnalytics } from '+shared/helpers/analytics';

interface UseToggleWithGtmEventArguments {
  expandEventData?: GTMEventData;
  collapseEventData?: GTMEventData;
}

export const useToggleWithGtmEvent = ({
  expandEventData,
  collapseEventData,
}: UseToggleWithGtmEventArguments): {
  isExpanded: boolean,
  toggle: () => void,
} => {
  const [isExpanded, setIsExpanded] = React.useState(false);
  const { sendEvent } = useAnalytics();

  const guardedSendEvent = (eventData?: GTMEventData) => {
    if (eventData) {
      const { event, ...extra } = eventData;
      sendEvent(event, extra);
    }
  };

  const toggle = () => {
    setIsExpanded(!isExpanded);

    if (isExpanded) {
      guardedSendEvent(collapseEventData);
    } else {
      guardedSendEvent(expandEventData);
    }
  };

  return {
    isExpanded,
    toggle,
  };
};
