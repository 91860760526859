import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { ACCOUNT_ACTIONS, AccountActions } from './account.actions';
import {
  AccountState,
  CHANGE_USER_PASSWORD_QUERY,
  GET_ACCOUNT_PROFILE_QUERY,
  initialState,
  SET_USER_DATA_QUERY,
  UPDATE_ACCOUNT_PROFILE_QUERY,
} from './account.state';

export const reducer = createReducer<AccountState, AccountActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case ACCOUNT_ACTIONS.SET_ACCOUNT_PROFILE_DATA:
        return {
          ...state,
          profile: action.profile,
        };
      default:
        return queryReducer(state, action, [
          GET_ACCOUNT_PROFILE_QUERY,
          UPDATE_ACCOUNT_PROFILE_QUERY,
          SET_USER_DATA_QUERY,
          CHANGE_USER_PASSWORD_QUERY,
        ]);
    }
  },
  {
    persist: {
      key: 'account',
      blacklist: [],
    },
  },
);
