import { Icon, NavItem, NavListItem } from '@sonnen/shared-web';
import { IconType } from '@sonnen/shared-web/src/components/Icon/models/Icon.model';
import * as classNames from 'classnames';
import * as React from 'react';

import { NestedNavHelper } from '../NestedNav.helper';
import { NestedNavSideContentType, NestedNavTheme } from '../NestedNav.types';
import { NestedNavSideContent } from '../NestedNavSideContent/NestedNavSideContent.component';

import './NestedNavListItem.component.scss';

interface NestedNavListItemProps {
  icon: IconType;
  title: string;
  subtitle?: string;
  sideContentType?: NestedNavSideContentType;
  theme: NestedNavTheme;
  isActive?: boolean;
  onClick: () => void;
  dataTestId?: string;
}

export const NestedNavListItem: React.FC<NestedNavListItemProps> = ({
  icon,
  title,
  subtitle,
  sideContentType,
  theme,
  isActive,
  onClick,
  dataTestId,
}) => {
  const ItemIcon = Icon[icon];

  return (
    <button
      className={classNames('c-nested-nav-list-item', {
        'c-nested-nav-list-item--theme-dark': NestedNavHelper.isDarkTheme(theme),
      })}
      onClick={onClick}
    >
      <NavListItem>
        <NavItem
          dataTestId={dataTestId}
          icon={<ItemIcon />}
          sideContent={<NestedNavSideContent
            contentType={sideContentType}
            theme={theme}
            isActive={isActive}
          />}
        >
          <div className={'c-nested-nav-list-item__content'}>
            {title}
            {subtitle && (
              <span className={'c-nested-nav-list-item__subtitle'}>
                {subtitle}
              </span>
              )}
            </div>
        </NavItem>
      </NavListItem>
    </button>
  );
};
