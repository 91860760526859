import { T } from '@sonnen/shared-i18n/customer';
import { PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { OverviewCommunity } from '+dashboard/+overview/components';
import { DemoInfographic } from '../../components/DemoInfographic';

export const DemoDashboardWeb: React.FC = () => {
  return (
    <>
      <PageSubheadline>
        {I18n.t(T.demo.dashboard.title)}
      </PageSubheadline>
      <DemoInfographic />
      <OverviewCommunity
        externalLink={'https://sonnengroup.com/sonnencommunity/'}
        buttonLabel={I18n.t(T.dashboard.community.button)}
      />
    </>
  );
};
