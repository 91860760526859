// NOTE: enums must not start with a number
export enum Resolution {
  RES_1_MINUTE = '1-minute',
  RES_5_MINUTES = '5-minutes',
  RES_15_MINUTES = '15-minutes',
  RES_1_HOUR = '1-hour',
  RES_3_HOURS = '3-hours',
  RES_1_DAY = '1-day',
  RES_1_MONTH = '1-month',
  RES_1_YEAR = '1-year',
}
