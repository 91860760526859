import { Data, MergedData } from '@coolio/json-api';

export enum PartnerStatus {
  INBOX = 'inbox',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export interface PartnerEmployeeAttributes {
  firstName: string;
  lastName: string;
}

export interface PartnerAttributes {
  name: string;
  zipCode: string;
  city: string;
  street: string;
  phone: string;
  fax: string;
  email: string;
  website: string;
  employee: PartnerEmployeeAttributes;
  status: PartnerStatus;
}

export type PartnerData = Data<PartnerAttributes>;
export type Partner = MergedData<PartnerData>;
