import { ContractEnergyInvoice } from '+contract/store/types/energy.interface';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import * as uuid from 'uuid';
import { ContractListRow } from '../';

import './ContractEnergyInvoicesList.component.scss';

interface Props {
  invoices: ContractEnergyInvoice[];
}

export const ContractEnergyInvoicesList: React.FC<Props> = ({ invoices }) => (
  <>
    {invoices.map(({ title, link, date }) => (
      <div
        key={link}
        className={'c-contract-energy-invoices-list__row'}
      >
        <ContractListRow>
          <div className={'c-contract-energy-invoices-list__text'}>
            {title}
          </div>
          <div className={'c-contract-energy-invoices-list__text'}>
            {date}
          </div>
          <a
            href={link}
            rel={'noopener noreferrer'}
          >
            <Icon.Download
              className={'c-contract-energy-invoices-list__icon'}
              hasHoverAnimation={true}
            />
          </a>
        </ContractListRow>
      </div>
    ))}
  </>
);
