import { Bubble } from '@sonnen/shared-web';
import * as React from 'react';

import './TooltipHoverable.component.scss';

interface Props {
  label: string;
  hoveringDisabled: boolean;
}

export const TooltipHoverable: React.FC<Props> = ({ children, label, hoveringDisabled }) => {
  const [isActive, setIsActive] = React.useState<boolean>(false);

  if (hoveringDisabled) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <div
      className={'c-tooltip-hoverable'}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
    >
      {children}
      {isActive && (
        <div className={'c-tooltip-hoverable__bubble'}>
          <Bubble side={'bottom-left'} isThemeBordered={true}>
            {label}
          </Bubble>
        </div>
      )}
    </div>
  );
};
