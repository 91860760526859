import { ActionsUnion, createAction } from '+utils/redux';

export enum QUERY_ACTIONS {
  INIT = '[Query] INIT',
  PENDING = '[Query] PENDING',
  SUCCESS = '[Query] SUCCESS',
  FAILURE = '[Query] FAILURE',
}

export const QueryActions = {
  init: (queryName: string) =>
    createAction(QUERY_ACTIONS.INIT, { queryName }),

  pending: (queryName: string) =>
    createAction(QUERY_ACTIONS.PENDING, { queryName }),

  success: (queryName: string, response?: any) =>
    createAction(QUERY_ACTIONS.SUCCESS, { queryName, response }),

  failure: (queryName: string, error: Error, extra?: object) =>
    createAction(QUERY_ACTIONS.FAILURE, { queryName, error, extra }),
};

export type QueryActions = ActionsUnion<typeof QueryActions>;
