import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MAP_WIDTH_MARGIN = 30;
const MAP_HEIGHT_MARGIN = 55;

class MapPopover extends PureComponent {
  constructor(props) {
    super(props);
    const { position: { x, y } } = props;
    this.state = {
      height: null,
      width: null,
      positionLeft: x,
      positionTop: y,
      arrowClass: '',
    };

    this.setWrapperRef = (element) => {
      this.wrapperRef = element;
    };
  }

  componentDidMount() {
    const { clientHeight: height, clientWidth: width } = this.wrapperRef;
    /* eslint-disable react/no-did-mount-set-state */
    /*
      Enabled setState in the componentDidMount of the need to get popup current dimensions
      for calculating its position
    */
    this.setState({ height, width });
    /* eslint-enable react/no-did-mount-set-state */
  }

  componentWillReceiveProps(nextProps) {
    const { height, width } = this.state;
    const { position: { x: currentX, y: currentY } } = this.props;
    const { mapSize: { mapHeight, mapWidth }, position: { x, y } } = nextProps;
    if (x !== currentX || y !== currentY) {
      let positionLeft = x;
      let positionTop = y;
      let arrowClass = 'c-community-map__popover--arrow';

      if ((x < 0 || x > mapWidth) || (y < 0 || y > mapHeight)) {
        return this.setState(state => ({ ...state, positionLeft: -999, positionTop: -999 }));
      }

      if (mapWidth - x > width) {
        positionLeft = x + MAP_WIDTH_MARGIN;
        arrowClass += '-left';
      } else {
        positionLeft = x - width - MAP_WIDTH_MARGIN;
        arrowClass += '-right';
      }

      if (mapHeight - y > height) {
        positionTop = y - MAP_HEIGHT_MARGIN;
        arrowClass += '-up';
      } else {
        positionTop = y - height;
        arrowClass += '-down';
      }

      return this.setState(state => ({
        ...state,
        positionLeft,
        positionTop,
        arrowClass,
      }));
    }
    return null;
  }

  render() {
    const {
      visible,
      children,
      headline,
    } = this.props;

    const { positionLeft, positionTop, arrowClass } = this.state;

    const popupStyles = {
      left: positionLeft,
      top: positionTop,
    };
    const popoverWrapperClasses = classNames(
      'c-community-map__popover',
      arrowClass,
    );

    return (
      <div
        className={popoverWrapperClasses}
        style={popupStyles}
        ref={this.setWrapperRef}
        hidden={!visible}
      >
        <div className="c-community-map__popover-title">
          <span data-test-id={'sonnen-community-map-user-pin'}>{headline}</span>
        </div>
        {children && (
          <div className="c-community-map__popover-content">
            {children}
          </div>
        )}
      </div>
    );
  }
}

MapPopover.propTypes = {
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  mapSize: PropTypes.shape({
    mapWidth: PropTypes.number,
    mapHeight: PropTypes.number,
  }),
  visible: PropTypes.bool,
  headline: PropTypes.string,
  children: PropTypes.node,
};

MapPopover.defaultProps = {
  position: { x: 0, y: 0 },
  mapSize: { mapWidth: null, mapHeight: null },
  visible: false,
  headline: '',
  children: null,
};

export default MapPopover;
