import { StatisticsV1FilterResolution } from '+analysis/store/types/statisticsV1.interface';
import { createSonnenFlatDataProvider } from '+app/+dashboard/+contract/store/helpers/contract.helpers';
import { MeasurementMethod } from '+app/shared/store/live/types/siteLiveData.interface';
import { StatisticsData } from '+app/shared/types/statisticsData.interface';
import { Props } from '+contract/components/ContractFlatHomeOverview';
import {
  Contract,
  ContractFlatXCessionStatus,
  ContractFlatXCessionStatusMarker,
  ContractStatus,
  ContractTypes,
  MeterChangeStatus,
  MeterInstallationPreparationTypes,
  TariffTypes,
  TariffVersions,
} from '+contract/store/types/contract.interface';
import { STATISTICS_FLATX_MOCK } from '+mocks/statistics.mock';
import { QueryData, QueryTypes } from '+query/query.state';
import { CountryCode } from '@sonnen/shared-web';

export type ContractDates = {
  activatedAt: string,
  deliveryStartAt: string,
  endAt: string,
  orderedAt: string,
  startAt: string,
  usageAllowanceActivatedOn: string,
};

export const CONTRACT_PAST_YEAR_DATES: ContractDates = {
  activatedAt: '2019-10-22',
  deliveryStartAt: '2019-06-02',
  endAt: '2019-12-31',
  orderedAt: '2019-01-01',
  startAt: '2019-01-01',
  usageAllowanceActivatedOn: '2019-07-01',
};

const CONTRACT_FLAT_X_MOCK: Contract = {
  ...{
    id: '',
    type: '',
    self: '',
  },
  name: 'energyGlobal ',
  contractNumber: '00275361',
  cessionStatusMarker: ContractFlatXCessionStatusMarker.SIGNED_BY_CUSTOMER,
  cessionStatus: ContractFlatXCessionStatus.ACTIVATED,
  cessionDsoClearingReason: null,
  contractPreviousNumber: null,
  salesforceContractId: '8009E000003hO7tQAE',
  contractType: ContractTypes.SONNEN_FLAT_X,
  tariffType: null,
  tariffVersion: TariffVersions.FLAT_X,
  orderedAt: '2020-11-30',
  startAt: '2021-02-01',
  activatedAt: '2021-12-09',
  deliveryStartAt: '2021-12-10',
  endAt: '2022-12-09',
  status: ContractStatus.ACTIVATED,
  annualConsumption: null,
  annualConsumptionTotal: null,
  amountOfFreeEnergy: 2000,
  appointedEnergyRate: '27.0',
  communityFee: null,
  meterProcessStatus: null,
  meterFstCheckSuccessful: 'no',
  billingStreet: '#36525',
  billingStreetNumber: '',
  billingPostalCode: '_Eco8',
  billingCity: '_DE+Heater+sonnenFlat X',
  billingState: '',
  billingCountryCode: 'DE',
  shippingStreet: '#36525',
  shippingStreetNumber: '',
  shippingPostalCode: '_Eco8',
  shippingCity: '_DE+Heater+sonnenFlat X',
  shippingState: '',
  shippingCountryCode: CountryCode.DE,
  contractingPartySalutation: null,
  contractingPartyAcademicTitle: null,
  contractingPartnerFirstname: 'Peter',
  contractingPartnerLastname: 'Hanschwurscht',
  contractingPartyEmail: null,
  contractingPartyPhone: null,
  contractingPartyBirthday: null,
  contractingPartyDatevNo: null,
  bankFirstname: 'Maßter',
  bankLastname: 'Account',
  bankIban: '2051',
  bankSwift: 'bankSwift',
  bankSepaDueAt: '2021-08-03',
  usageAllowanceActivatedOn: '2020-05-14',
  cessionFirCessionAccepted: null,
  pvAssetEnergyProductionVerification: 'successful',
  migrationViaCp: false,
  meterInstallation: {
    changeNotificationToDso: false,
    preparationStatus: null,
    resultMeterChange: null,
  },
  meterInstallationId: null,
  financialServiceProvider: null,
  terminationRequested: false,
};

export const CONTRACT_FLAT_HOME_MOCK: Contract = {
  amountOfFreeEnergy: 4250,
  annualConsumption: 1050,
  annualConsumptionTotal: 3500,
  appointedEnergyRate: 1,
  bankFirstname: '',
  bankIban: '',
  bankLastname: '',
  bankSepaDueAt: '',
  bankSwift: '',
  billingCity: '',
  billingCountryCode: '',
  billingPostalCode: '',
  billingState: '',
  billingStreet: '',
  billingStreetNumber: '',
  contractingPartnerFirstname: '',
  contractingPartnerLastname: '',
  contractingPartyAcademicTitle: '',
  contractingPartyBirthday: '',
  contractingPartyDatevNo: '',
  contractingPartyEmail: '',
  contractingPartyPhone: '',
  contractingPartySalutation: '',
  contractNumber: '00123456',
  contractPreviousNumber: '00123456',
  cessionStatusMarker: null,
  cessionStatus: null,
  cessionDsoClearingReason: null,
  contractType: ContractTypes.SONNEN_FLAT_HOME,
  communityFee: 19.99,
  cessionFirCessionAccepted: false,
  id: '__demoContractId__',
  name: 'sonnenFlat 4250',
  meterInstallation: {
    changeNotificationToDso: false,
    preparationStatus: MeterInstallationPreparationTypes.CHECKED,
    resultMeterChange: MeterChangeStatus.SUCCESSFUL,
  },
  meterInstallationId: null,
  meterProcessStatus: '',
  migrationViaCp: false,
  pvAssetEnergyProductionVerification: null,
  salesforceContractId: '',
  self: '',
  shippingCity: '',
  shippingCountryCode: CountryCode.DE,
  shippingPostalCode: '',
  shippingState: '',
  shippingStreet: '',
  shippingStreetNumber: '',
  status: ContractStatus.ACTIVATED,
  tariffType: TariffTypes.SONNEN_BATTERIE_HOME_DE,
  tariffVersion: TariffVersions.FLAT_HOME_1_0,
  type: 'contracts',
  financialServiceProvider: null,
  terminationRequested: false,
  ...CONTRACT_PAST_YEAR_DATES,
};

export const CONTRACT_FLAT_DATA_MOCK: StatisticsData = {
  timestamp: new Date().toISOString(),
  total: {
    id: '',
    type: '',
    self: '',
    batteryChargedEnergy: [],
    batteryDischargedEnergy: [],
    consumedEnergy: [2507000],
    directUsageEnergy: [],
    end: '',
    gridFeedinEnergy: [],
    gridPurchaseEnergy: [],
    measurementMethod: MeasurementMethod.BATTERY,
    producedEnergy: [],
    start: '',
    resolution: StatisticsV1FilterResolution.TOTAL,
    batteryChargedFromSunEnergy: [],
    batteryChargedFromGridEnergy: [],
    heaterEnergy: [],
  },
};

export const CONTRACT_FLAT_X_PAST_YEAR_MOCK: Contract = {
  ...CONTRACT_FLAT_X_MOCK,
  ...CONTRACT_PAST_YEAR_DATES,
};

const successQueryData: QueryData = {
  status: {
    error: false,
    pending: false,
    notReady: false,
    success: true,
    interrupted: true,
  },
  errorCount: 0,
};

export const CONTRACT_FLAT_OVERVIEW_PROPS_MOCK: Props = {
  batteryTimezone: 'Europe/Berlin',
  contract: CONTRACT_FLAT_X_MOCK,
  isSiteWithBattery: true,
  isContractStatusDone: true,
  [QueryTypes.CONTRACT_QUERY]: successQueryData,
  contractDataProvider: createSonnenFlatDataProvider({
    batteryTimezone: 'Europe/Berlin',
    contract: CONTRACT_FLAT_X_MOCK,
    sonnenFlatStatistics: STATISTICS_FLATX_MOCK,
  }),
  shouldDisplayMeasurementsBanner: false,
};
