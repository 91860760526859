import * as React from 'react';
import { GuidePhaseChildren, GuidePhaseStatus } from './GuidePhase.helper';

import './GuidePhase.component.scss';

interface Props {
  pathFactory: (step?: string) => string;
  children: React.ReactNode | GuidePhaseChildren;
  status?: GuidePhaseStatus;
}

export const PhaseContext = React.createContext((step?: string) => step!);

// @TODO: Place the stepper here
export const GuidePhase: React.FC<Props> = ({ children, pathFactory, status }) => (
  <ul className={'c-guide-phase'}>
    <PhaseContext.Provider value={pathFactory}>
      {children && (Object.values(GuidePhaseStatus).some(el => children.hasOwnProperty(el)))
        ? (status && children[status])
        : children
      }
    </PhaseContext.Provider>
  </ul>
);
