import { Data, MergedData } from '@coolio/json-api';
import { BatteryInverterState } from '@sonnen/shared-web';

export enum MeasurementMethod {
  BATTERY = 'battery',
  METER_GRID = 'meter-grid',
  METER_GRID_PV = 'meter-grid-pv',
  METER_GRID_PV_FEED_IN = 'meter-grid-pv-feed-in',
  METER_ERROR = 'meter-error',
}

export type BatteryOperatingMode =
  | 'Battery-Module-Extention'
  | 'Energy Reserve'
  | 'Final-Inspection'
  | 'Full-Charge'
  | 'Intelligent-Mode'
  | 'Manual'
  | 'Not-Defined'
  | 'Peak-Shaving'
  | 'PV-Simulation'
  | 'Self-Consumption'
  | 'Time of Use'
  | 'time-of-use'
  | 'US-Automatic'
  | 'US-Backup-Power'
  | 'US-Net-Zero-Pv'
  | 'US-time-of-use'
  | 'US-Time-Of-Use'
  ;

export enum BatteryInverterStatus {
  ONGRID = 'ongrid',
  OFFGRID = 'offgrid',
}

export type SiteLiveType =
  | 'live-states'
  | 'failover-live-states'
  ;

export enum SiteLiveStateType {
  LIVE_STATE = 'live-states',
  FAILOVER_LIVE_STATES = 'failover-live-states',
}

export interface SiteLiveAttributes {
  backupActive: boolean;
  batteryCharging: number;
  batteryChargingKw: number;
  batteryDischarging: number;
  batteryDischargingKw: number;
  batteryInverterState: BatteryInverterState;
  batteryInverterStatus: BatteryInverterStatus;
  batteryOperatingMode: BatteryOperatingMode;
  batterySerialNumber: string;
  batteryUsoc: number;
  consumptionPower: number;
  consumptionPowerKw: number;
  online: boolean;
  gridFeedin: number;
  gridFeedinKw: number;
  gridPurchase: number;
  gridPurchaseKw: number;
  gridServiceActive: boolean;
  heaterPower: number;
  heaterWaterTemp: number;
  independent: boolean;
  measurementMethod: MeasurementMethod;
  productionPower: number;
  productionPowerKw: number;
  pvReduction: boolean | null;
  timestamp: string;
}

export type SiteLiveData = Data<SiteLiveAttributes>;
export type SiteLive = MergedData<SiteLiveData>;
