import { AccessoriesChartSeries, AccessoriesSeriesKey, Point } from '@sonnen/shared-web';
import { ActivityRange } from '../../store/types/activityRange.interface';

export const ACCESSORIES_CHARTS_WRAPPER_HEIGHT = 200;
export const ACCESSORIES_SINGLE_CHART_WRAPPER_HEIGHT = 140;
export const ACCESSORIES_CHART_CONTAINER_HEIGHT = 60;

const calculateActivityRanges = (dataPoints: Point[], temperaturePoints?: Point[]) => {
  const activityRanges: ActivityRange[] = [];
  let activityRange: ActivityRange = {
    startTime: 0,
    startTemperature: undefined,
    endTime: 0,
  };
  let isPreparingRangeInProgress = false;

  if (dataPoints && dataPoints.length) {
    dataPoints.forEach((dataPoint, index) => {
      if ((dataPoint.hasOwnProperty('y') && dataPoint.y! > 0) && !isPreparingRangeInProgress) {
        isPreparingRangeInProgress = true;

        activityRange = {
          startTime: dataPoint.x,
          startTemperature: temperaturePoints ? temperaturePoints[index].y : undefined,
          endTime: 0,
        };
      } else if ((dataPoint.hasOwnProperty('y') && dataPoint.y === 0) && isPreparingRangeInProgress) {
        isPreparingRangeInProgress = false;

        activityRange = {
          ...activityRange,
          endTime: dataPoint.x,
          endTemperature: temperaturePoints ? temperaturePoints[index].y : undefined,
        };

        activityRanges.push(activityRange);
      }
    });
  }
  return activityRanges;
};

export const calculateAccessoriesActivityRanges = (accessoriesChartSeries: AccessoriesChartSeries) => {
  const heaterPowerPoints = accessoriesChartSeries[AccessoriesSeriesKey.HEATER_POWER];
  const waterTempData = accessoriesChartSeries[AccessoriesSeriesKey.HEATER_TEMPERATURE];
  const backupBoxPoints =  accessoriesChartSeries[AccessoriesSeriesKey.BACKUP_BOX];

  const heaterPowerActivityRanges = calculateActivityRanges(heaterPowerPoints, waterTempData);
  const backupBoxActivityRanges = calculateActivityRanges(backupBoxPoints);

  return {
    heaterPower: heaterPowerActivityRanges,
    backupBox: backupBoxActivityRanges,
  };
};

export const getRawTimestampFromX = (
  accessoriesChartSeries: AccessoriesChartSeries,
  x?: number,
  ) => {
    const heaterPowerData = accessoriesChartSeries[AccessoriesSeriesKey.HEATER_POWER];
    const startTimestamp = heaterPowerData.length ? heaterPowerData[0].x : 0;
    return x ? (x * 60 + startTimestamp) : 0;
  };
