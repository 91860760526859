import { ActionsUnion, createAction } from '+utils/redux';

export enum LAYOUT_ACTIONS {
  TOGGLE_MOBILE_NAVIGATION = '[Layout] TOGGLE_MOBILE_NAVIGATION',
  TOGGLE_SITE_SWITCHER = '[Layout] TOGGLE_SITE_SWITCHER',
  TOGGLE_LAYOUT_SCROLLING = '[Layout] TOGGLE_LAYOUT_SCROLLING',
}

export const LayoutActions = {
  toggleMobileNav: (status: boolean) =>
    createAction(LAYOUT_ACTIONS.TOGGLE_MOBILE_NAVIGATION, { status }),
  toggleSiteSwitcher: (status: boolean) =>
    createAction(LAYOUT_ACTIONS.TOGGLE_SITE_SWITCHER, { status }),
  toggleLayoutScrolling: (status: boolean) =>
    createAction(LAYOUT_ACTIONS.TOGGLE_LAYOUT_SCROLLING, { status }),
};

export type LayoutActions = ActionsUnion<typeof LayoutActions>;
