import { T } from '@sonnen/shared-i18n/customer';
import { BoldParagraph, Link } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './GuideProductInfoFooter.component.scss';

export const GuideProductInfoFooter = () => (
  <div className={'c-guide-product-info-footer'}>
    <BoldParagraph className={'c-guide-product-info-footer__headline'}>
      {I18n.t(T.customerGuidance.productInformations.question)}
    </BoldParagraph>
    <Link
      label={I18n.t(T.customerGuidance.productInformations.link)}
      href={'https://sonnen.de/info-sonnenflat/'}
    />
  </div>
);
