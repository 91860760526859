import { Icon, NumberUnit } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import { formatNumber } from '+utils/number.util';

import './EFCircle.component.scss';

type Props = {
  type: string,
  icon: React.ReactNode,
  label: string,
  value: number,
  hasValueErrorIcon?: boolean,
  valueSub?: string | number,
  isTextBelow?: boolean,
  isActive: boolean,
  isOnline?: boolean,
  isAnimated?: boolean,
  isBlured?: boolean,
  tooltip?: React.ReactNode,
  dataTestId?: string,
};

export const EFCircle: React.FC<Props> = ({
  icon,
  type,
  label,
  value,
  hasValueErrorIcon= false,
  valueSub,
  isTextBelow,
  isActive,
  isOnline,
  isAnimated,
  isBlured,
  tooltip,
  dataTestId,
}) => {
  const classes = {
    circle: classNames('c-ef-circle', `c-ef-circle--${type}`, {
      'is-online': isOnline,
      'is-active': isActive,
      'is-animated': isAnimated,
      'is-blured': isBlured,
    }),
    text: classNames({
      'c-ef-circle__above-content': !isTextBelow,
      'c-ef-circle__below-content': isTextBelow,
    }),
    ring: classNames('c-ef-circle__ring', {
      'c-ef-circle__ring--active': isAnimated,
      'c-ef-circle__ring--blur': isBlured,
    }),
  };

  const getPrecisionForValue = (value: number) => {
    switch (true) {
      case value === 0:
        return 1;
      case value > 100:
        return 1;
      case value > 10:
        return 2;
      default:
        return 3;
    }
  };

  const formattedValue = formatNumber({
    precision: getPrecisionForValue(value),
    numberUnit: NumberUnit.KILO,
  })(value);

  return (
    <div
      data-test-id={dataTestId}
      className={classes.circle}
    >
      {tooltip}
      {isAnimated && (
        <div className={classes.ring} />
      )}
      <div className={'c-ef-circle__content'}>
        <span className={'c-ef-circle__icon'}>{icon}</span>
        <div className={classes.text}>
          {isOnline && (
            <p className={'c-ef-circle__value'}>
              <span data-test-id={dataTestId + '-value'}>{`${formattedValue} kW`}</span>
              {hasValueErrorIcon && (
                <span
                  data-test-id={dataTestId + '-error-value-icon'}
                  className={'c-ef-circle__value-error-icon-wrapper'}
                >
                  <Icon.Warning className={'c-ef-circle__value-error-icon'}/>
                </span>
              )}
            </p>
          )}
          <p className={'c-ef-circle__title'}>
            {label}
          </p>
          {valueSub && (
            <p className={'c-ef-circle__sub-value'}>
              {valueSub}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
