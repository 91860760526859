import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';

import { getMobileNavRoutes } from '+app/router/router.helper';
import { RouterAppNavType } from '+app/router/router.types';
import { useRouterState } from '+app/router/RouterProvider.component';
import { getRandomHint } from '+app/shared/store/sync/sync.helpers';
import { Hint } from '+app/shared/store/sync/types/hint.interface';
import { Layout, NavBottom, NavBottomButton, Notifications } from '+shared/components';
import { MobileSideNav } from '+shared/containers';
import { DashboardLayoutProps } from './';

import './DashboardLayout.mobile.component.scss';

export const DashboardLayoutMobile: React.FC<DashboardLayoutProps> = ({
  isLoading,
  isMobileNavOpen,
  actions,
  children,
}) => {
  const availableFlatRoutes = useRouterState().availableFlatRoutes;
  const mobileAppBottomNavRoutes = getMobileNavRoutes(RouterAppNavType.BOTTOM, availableFlatRoutes);
  const mobileAppSideNavRoutes = getMobileNavRoutes(RouterAppNavType.SIDE, availableFlatRoutes);
  const isButtonActive = Boolean(isMobileNavOpen
    || mobileAppSideNavRoutes.find((appRoute) => {
      return appRoute.path === window.location.pathname;
    }),
  );
  const buttons: NavBottomButton[] = [
    {
      label: T.mobileApp.nav.more,
      icon: Icon.More,
      action: () => actions.toggleMobileNav(!isMobileNavOpen),
      isActive: isButtonActive,
      isIconRotated: isMobileNavOpen,
    },
  ];
  const loaderHint: Hint = getRandomHint();

  return (
    <div className={'m-c-dashboard-layout-mobile'}>
      <Layout
        isThemeMainNoTopGap={true}
        contentBottom={<Notifications />}
        footer={(
          <>
            <NavBottom
              routes={mobileAppBottomNavRoutes}
              buttons={buttons}
            />
            {/* TODO: Consider to move it to "side" instead of "footer" */}
            <MobileSideNav routes={mobileAppSideNavRoutes} />
          </>
        )}
        loader={{
          isLoading,
          loaderHint,
        }}
      >
        {children}
      </Layout>
    </div>
  );
};
