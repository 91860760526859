import { PlatformSelector } from '+components';
import { T } from '@sonnen/shared-i18n/customer';
import { DefaultParagraph, ExpandableSection, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import communityPlaceholderImg from '../../../../../images/community-header.svg';

import './CommunityHeader.component.scss';

interface Props {
  hasImage?: boolean;
  hasExpandable?: boolean;
  noBorder?: boolean;
}

const renderParagraph = () => (
  <DefaultParagraph>
    {I18n.t(T.sonnenCommunity.header.description)}
  </DefaultParagraph>
);

const CommunityComponent: React.FC<Props> = ({ hasImage, noBorder, hasExpandable }) => (
  <div className={'c-community-header'}>
    {hasImage &&
      <img
        className={'c-community-header__image'}
        src={communityPlaceholderImg}
        alt={T.sonnenCommunity.header.title}
      />
    }
    <div className={'c-community-header__content'}>
      <PageSubheadline
        classNames={'c-community-header__title'}
        noBorder={noBorder}
      >
        {I18n.t(T.sonnenCommunity.header.title)}
      </PageSubheadline>
      {hasExpandable ? (
        <ExpandableSection
          minHeight={40}
          maxHeight={1000}
          buttonLabel={I18n.t(T.general.labels.readMore)}
          buttonLabelWhenExpanded={I18n.t(T.general.labels.readLess)}
          buttonOnLeftSide={true}
        >
          {renderParagraph()}
        </ExpandableSection>
      ) : (
        renderParagraph()
      )}
    </div>
  </div>
);

export const CommunityHeader: React.FC = () => (
  <PlatformSelector
    web={<CommunityComponent hasImage={true} noBorder={true} />}
    mobile={<CommunityComponent hasExpandable={true} />}
  />
);
