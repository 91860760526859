export interface ExplanationInterface {
  isExplanationActive?: boolean;
  onClick?: () => void;
}

export enum LiveEnergyFlowExplanation {
  BATTERY = 'battery',
  GRID = 'grid',
  CONSUMPTION = 'consumption',
  PRODUCTION = 'production',
}
