import { T } from '@sonnen/shared-i18n/customer';
import { StatusBanner } from '@sonnen/shared-web';
import { isEmpty, pipe } from 'lodash/fp';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getRouterLocationPath } from '+app/router/store/router.selectors';
import { usePersistedState } from '+app/shared/hooks/usePersistedState';
import { News } from '+app/shared/store/news-channel/types/news.interface';
import { getCurrentUserCustomerNumber } from '+app/shared/store/user/user.selectors';
import { StoreState } from '+app/store/store.interface';
import { Locale } from '+legacy/helpers/i18n.helper';
import { getNewsChannelNewsList } from '+shared/store/news-channel/newsChannel.selectors';
import { NewsSectionHelper } from './NewsSection.helper';

import './NewsSection.component.scss';

const mapStateToProps = (state: StoreState) => ({
  newsList: getNewsChannelNewsList(state),
  currentPath: getRouterLocationPath(state),
  lang: state.i18n.locale as Locale,
  customerNumber: getCurrentUserCustomerNumber(state),
});

type Props = ReturnType<typeof mapStateToProps>;

const NewsSectionComponent: React.FC<Props> = ({
  newsList,
  currentPath,
  lang,
  customerNumber,
}) => {
  const hiddenNewsLocalStorageKey = `hiddenNewsIds-${customerNumber}`;
  const [hiddenNews, setHiddenNews] = usePersistedState([], hiddenNewsLocalStorageKey);

  const hideNotification = (newsId: string) => {
    setHiddenNews([...hiddenNews, newsId]);
  };

  const visibleNewsList: News[] = pipe([
    NewsSectionHelper.filterNewsByPath(currentPath),
    NewsSectionHelper.filterVisibleNews(hiddenNews),
  ])(newsList);

  if (isEmpty(visibleNewsList)) { return null; }

  return (
    <div className={'c-news-section'}>
      {
        visibleNewsList.map(({ id, messages, levelValue}) => (
          <div
            className={'c-news-section__item'}
            key={`news-${id}`}
          >
            <StatusBanner
              headline={NewsSectionHelper.getTranslatedHeadline(messages, lang)}
              content={NewsSectionHelper.getTranslatedContent(messages, lang)}
              statusLabel={NewsSectionHelper.getNewsStatusLabel(levelValue!)}
              type={NewsSectionHelper.getNewsStatusType(levelValue!)}
              onClose={() => hideNotification(id)}
              contentExpandButtonLabel={I18n.t(T.newsChannel.seeMoreText)}
              contentCollapseButtonLabel={I18n.t(T.newsChannel.seeLessText)}
            />
          </div>
        ))
      }
    </div>
  );
};

export const NewsSection = connect(mapStateToProps)(NewsSectionComponent);
