import { T } from '@sonnen/shared-i18n/customer';
import {
  Card,
  CardNotification,
  DefaultParagraph,
  PageSubheadline,
} from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { formatNumber } from '+app/utils/number.util';
import { AnnualConsumptionChart } from '+legacy/components/charts/AnnualConsumptionChart';
import { AnnualConsumptionChartProps } from '+legacy/components/charts/AnnualConsumptionChart/AnnualConsumptionChart.types';

import { isContractType } from '../../store/helpers/contract.helpers';
import { ContractTypes } from '../../store/types/contract.interface';
import { withExceeding } from './withExceeding.hoc';

import './ContractFlatExpertView.component.scss';

interface FlatRates {
  default: number;
  exceeded: number;
}

type Props =
  & AnnualConsumptionChartProps
  & {
    legendGreenLabel: string;
  }
;

export const ContractFlatExpertView: React.FC<Props> = ({
  children,
  legendGreenLabel,
  ...annualConsumptionChartProps
}) => {
  const {
    freeUsageAllowance,
    isQuotaExceeded,
    isExceedingExpected,
  } = annualConsumptionChartProps;
  const freeUsageAllowanceLabel = `${freeUsageAllowance} kWh`;
  const { default: defaultRate, exceeded } = I18n.t(T.yourFlat.values.rates._all) as any as FlatRates;
  const rate = formatNumber()(defaultRate);
  const additionalRate = formatNumber()(defaultRate - exceeded);

  const Notification = withExceeding(isQuotaExceeded, isExceedingExpected)(CardNotification);

  return (
    <div className={'c-contract-flat-expert-view__expandable-section'}>
      <PageSubheadline>
        {I18n.t(T.yourFlat.expertView.headline)}
      </PageSubheadline>
      <div className={'c-contract-flat-expert-view__card'}>
        <Card
          title={I18n.t(T.yourFlat.expertView.card.title)}
          smallTitleGap={true}
          footerContent={<Notification />}
        >
          <div className={'c-contract-flat-expert-view__chart'}>
            <div className={'c-contract-flat-expert-view__chart-legend'}>
              <p
                className={`c-contract-flat-expert-view__legend-item c-contract-flat-expert-view__legend-item--dashed`}
              >
                {I18n.t(T.yourFlat.expertView.chart.legend.dashed)}
                <span className={'c-contract-flat-expert-view__legend-item-label'}>
                  {freeUsageAllowanceLabel}
                </span>
              </p>
              <p className={classNames('c-contract-flat-expert-view__legend-item', {
                'c-contract-flat-expert-view__legend-item--warning': isQuotaExceeded || isExceedingExpected,
                'c-contract-flat-expert-view__legend-item--success': !isQuotaExceeded && !isExceedingExpected,
              })}>
                {legendGreenLabel}
              </p>
              <p className={`
                c-contract-flat-expert-view__legend-item
                c-contract-flat-expert-view__legend-item--blue
              `}>
                {I18n.t(T.yourFlat.expertView.chart.legend.blue)}
              </p>
            </div>
            <div className={'c-contract-flat-expert-view__chart-inner'}>
              <AnnualConsumptionChart {...annualConsumptionChartProps}/>
            </div>
            <div className={'c-contract-flat-expert-view__chart-description'}>
              <DefaultParagraph>
                {I18n.t(T.yourFlat.expertView.chart.description, {
                  defaultRate: rate,
                  additionalRate,
                })}
              </DefaultParagraph>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
