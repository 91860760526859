import { T } from '@sonnen/shared-i18n/customer';
import { PageHeadline, PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import {
  ContractFlatXCardEnergyContractComponent,
  ContractFlatXCardEnergyDeliveryComponent,
  ContractFlatXCardUsageAllowanceComponent,
} from '+app/+dashboard/+contract';
import { ContractBillingSection } from '+app/+dashboard/+contract/components/ContractBillingSection';
import { getContractStepsCount, getContractTotalStepsCount } from '+app/+dashboard/+contract/store/helpers/contract.helpers';
import { PageMetaTitle } from '+app/shared/components';
import { Page } from '+app/shared/containers/Page';
import { ContractEnergyMeterSection, ContractFlatStepsCounter } from '+contract/components';
import { Props } from '+contract/components/ContractFlatHomeOverview';
import { CURRENT_USER_MOCK } from '+mocks/currentUser.mock';

export const DemoContractFlatOverviewWeb: React.FC<Props> = ({
  contract,
  contractDataProvider,
}) => {
  const stepsCount = getContractStepsCount(contract, true);
  const totalStepsCount = getContractTotalStepsCount(contract);

  return (
    <Page headerTestId={'demo-contract-flat-overview-header'}>
      <PageMetaTitle value={I18n.t(T.general.pageTitles.sonnenFlat.overview)} />
      <PageHeadline
        dataTestId={'demo-contract-flat-x-header'}
      >
        {I18n.t(T.yourFlat.title)}
      </PageHeadline>
      <div className={'c-contract-flat-x-overview'}>
        <p
          data-test-id={'demo-contract-flat-x-overview-contract-number'}
          className={'c-contract-flat-x-overview__contract-number'}
        >
          {I18n.t(T.yourFlat.sonnenFlatX.orderNumber)}
          {contract.contractNumber}
        </p>
        <div className={'c-contract-flat-x-overview__usage-allowance'}>
          <PageSubheadline>
            {I18n.t(T.yourFlat.sonnenFlatX.usageAllowanceHeader)}
          </PageSubheadline>
          <ContractBillingSection billingPeriod={contractDataProvider.getBillingPeriod()} />
          <ContractEnergyMeterSection
            freeUsageAllowance={contractDataProvider.getFreeUsageAllowance() || 0}
            percentage={contractDataProvider.getPercentageUsage()}
          />
        </div>
        <div className={'c-contract-flat-x-overview__order-status'}>
          <PageSubheadline>
            {I18n.t(T.yourFlat.sonnenFlatX.statusOfYourOrder)}
          </PageSubheadline>
          <ContractFlatStepsCounter
            dataTestId={'contract-flat-x-overview-steps'}
            max={totalStepsCount}
            count={stepsCount}
          />
          <div className={'c-contract-flat-x-overview__cards'}>
            <ContractFlatXCardEnergyContractComponent
              orderedAt={contract.orderedAt}
              isTerminationPending={contract.terminationRequested}
            />
            <ContractFlatXCardEnergyDeliveryComponent
              deliveryStartAt={contract.deliveryStartAt}
            />
            <ContractFlatXCardUsageAllowanceComponent
              usageAllowanceActivationDate={contract.usageAllowanceActivatedOn}
              contract={contract}
              dsoId={CURRENT_USER_MOCK.accountDso}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};
