import { CONFIG } from '+config';
import { useJsonApi } from '+shared/network';
import { ChargerLiveData } from './types/chargerLiveData.interface';
import { SiteLiveData } from './types/siteLiveData.interface';

export const getSiteLiveData = (id: string) =>
  useJsonApi().get<SiteLiveData>(`${CONFIG.API.USER_DATA}/sites/${id}/live-state`)
    .send()
    .then(response => response.element);

export const getChargerLiveData = (id: string) =>
  useJsonApi().get<ChargerLiveData>(`${CONFIG.API.USER_DATA}/chargers/${id}/live-state`)
    .send()
    .then(response => response.element);

export const LiveRepository = {
  getSiteLiveData,
  getChargerLiveData,
};
