import {
  FormInputEventType,
  FormInputSubscriptionAction,
  FormInputSubscriptionContext,
  FormInputSubscriptionPayload,
} from '+shared/hooks/useDispatchInputEvent';
import * as React from 'react';

interface Props<T> {
  children: React.ReactNode;
  onChange?: (payload: FormInputSubscriptionPayload<T>) => void;
  onBlur?: (payload: FormInputSubscriptionPayload<T>) => void;
}

const createReducer = <T extends any>({ onBlur, onChange }: Props<T>) =>
  (state: null, action: FormInputSubscriptionAction<T>) => {
    switch (action.type) {
      case FormInputEventType.ON_BLUR:
        if (onBlur) { onBlur(action.payload); }
        return state;
      case FormInputEventType.ON_CHANGE:
        if (onChange) { onChange(action.payload); }
        return state;
      default:
        return state;
    }
  };

export const FormFieldObserver = <T extends any>(props: Props<T>) => {
  const [_, dispatch] = React.useReducer(createReducer<T>(props), null);

  return (
    <FormInputSubscriptionContext.Provider value={dispatch as any}>
      {props.children}
    </FormInputSubscriptionContext.Provider>
  );
};
