import { validateField } from '+shared/helpers/form.helper';
import { Select } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import { Field, FormikProps } from 'formik';
import { get } from 'lodash/fp';
import * as React from 'react';

import './FormInputSelect.component.scss';

interface Props<T, U> {
  name: string;
  collection: U[];
  form: FormikProps<T>;
  mapper?: (val: U) => string;
  label?: string;
  placeholder?: string;
  className?: ClassValue;
  onInputChange?: (val: string) => void;
  onSelect?: (val: U) => void;
  hasNoGap?: boolean;
  horizontalLabel?: boolean;
  id?: string;
  labelClass?: string;
  inputClass?: string;
  dataTestId?: string;
}

export const FormInputSelect = <T extends any = any, U extends any = string>({
  form,
  mapper,
  name,
  collection,
  label,
  horizontalLabel = false,
  placeholder,
  className,
  onInputChange,
  onSelect,
  hasNoGap = false,
  id,
  labelClass,
  inputClass,
  dataTestId,
}: Props<T, U>) => {
  const validation = validateField(name, form);
  const itemSelected = collection.find(t => JSON.stringify(t) === JSON.stringify(get(name)(form.values)));

  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    if (onInputChange && itemSelected) {
      const value = mapper ? mapper(itemSelected) : String(itemSelected);
      setValue(value);
      onInputChange(value);
    }
  }, [itemSelected]);

  return (
    <div 
      className={classNames('c-form-input-select', className, {
      'c-form-input-select--no-gap': hasNoGap && !validation.hasError,
      'c-form-input-select--horizontal': horizontalLabel,
    })}>
      {label && (
        <label className={classNames('c-form-input-select__label', labelClass, {
          'c-form-input-select__label--horizontal': horizontalLabel,
        })}>
          {label}
        </label>
      )}
      <div 
        data-test-id={dataTestId}
        className={inputClass}
      >
        <Field
          name={name}
          component={Select}
          className={classNames({ 'c-form-input-select__field--error': validation.hasError })}
          items={collection}
          placeholder={placeholder}
          itemsSelected={itemSelected}
          itemFactory={mapper || String}
          onSelect={(val: U) => {
            form.setFieldValue(name, val);
            form.setFieldTouched(name, true);
            if (onSelect) {
              onSelect(val);
            }
          }}
          searchProps={!!onInputChange ? {
            onInputChange(v: string) {
              form.setFieldValue(name, '');
              form.setFieldTouched(name, true);
              setValue(v);
              onInputChange(v);
            },
            inputValue: value,
          } : undefined}
          isControlled={true}
          hasBottomGap={true}
          id={id}
        />
        <div className={'c-form-input-select__error'}>
          {validation.hasError && (
            <div className={'c-form-input-select__error-message'}>
              {validation.error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
