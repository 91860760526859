import { getAsyncRouteComponent } from './components';

export const ROUTER_ASYNC_COMPONENTS = {
  AsyncLogOut: getAsyncRouteComponent(() =>
    import('+auth/containers/AuthLogOut/AuthLogOut.component'), 'AuthLogOut'),
  AsyncOverview: getAsyncRouteComponent(() =>
    import('+dashboard/+overview/containers/Overview/Overview.component'), 'Overview'),
  AsyncLiveState: getAsyncRouteComponent(() =>
    import('+dashboard/+live-state/containers/LiveState/LiveState.component'), 'LiveState'),
  AsyncUserReadings: getAsyncRouteComponent(() =>
    import('+user-readings/containers/UserReadings/UserReadings.component'), 'UserReadings'),
  AsyncAnalysis: getAsyncRouteComponent(() =>
    import('+dashboard/+analysis/containers/Analysis/Analysis.component'), 'Analysis'),
  AsyncCommunity: getAsyncRouteComponent(() =>
    import('+legacy/pages/Community/Community.component'), 'Community'),
  AsyncContractFlat: getAsyncRouteComponent(() =>
    import('+dashboard/+contract/containers/ContractFlat/ContractFlat.component'), 'ContractFlat'),
  AsyncContractFlatOverview: getAsyncRouteComponent(() =>
    import('+contract/containers/ContractFlatOverview/ContractFlatOverview.component'), 'ContractFlatOverview'),
  AsyncContractCustomerData: getAsyncRouteComponent(() =>
    import('+dashboard/+contract/containers/ContractCustomerData/ContractCustomerData.component'), 'ContractCustomerData'),
  AsyncProductMigrationNewContract: getAsyncRouteComponent(() =>
    import('+product-migration/containers/ProductMigrationNewContract/ProductMigrationNewContract.component'), 'ProductMigrationNewContract'),
  AsyncContractFlatMigration: getAsyncRouteComponent(() =>
    import('+product-migration/containers/ProductMigration/ProductMigration.component'), 'ProductMigration'),
  AsyncContractTariff: getAsyncRouteComponent(() =>
    import('+dashboard/+contract/containers/ContractTariff/ContractTariff.component'), 'ContractTariff'),
  AsyncContractTariffOverview: getAsyncRouteComponent(() =>
    import('+dashboard/+contract/containers/ContractTariffOverview/ContractTariffOverview.component'), 'ContractTariffOverview'),
  AsyncContractEnergy: getAsyncRouteComponent(() =>
    import('+dashboard/+contract/containers/ContractEnergy/ContractEnergy.component'), 'ContractEnergy'),
  AsyncContractEnergyOverview: getAsyncRouteComponent(() =>
    import('+dashboard/+contract/containers/ContractEnergyOverview/ContractEnergyOverview.component'), 'ContractEnergyOverview'),
  AsyncContractEnergyDocuments: getAsyncRouteComponent(() =>
    import('+dashboard/+contract/containers/ContractEnergyDocuments/ContractEnergyDocuments.component'), 'ContractEnergyDocuments'),
  AsyncBattery: getAsyncRouteComponent(() =>
    import('+dashboard/+battery/containers/Battery/Battery.component'), 'Battery'),
  AsyncBatteryOverview: getAsyncRouteComponent(() =>
    import('+dashboard/+battery/containers/BatteryOverview/BatteryOverview.component'), 'BatteryOverview'),
  AsyncBatteryDetails: getAsyncRouteComponent(() =>
    import('+dashboard/+battery/containers/BatteryDetails/BatteryDetails.component'), 'BatteryDetails'),
  AsyncBatteryDownloads: getAsyncRouteComponent(() =>
    import('+dashboard/+battery/containers/BatteryDownloads/BatteryDownloads.component'), 'BatteryDownloads'),
  AsyncDocuments: getAsyncRouteComponent(() =>
    import('+dashboard/+documents/containers/Documents/Documents.component'), 'Documents'),
  AsyncDocumentsContracts: getAsyncRouteComponent(() =>
    import('+dashboard/+documents/containers/DocumentsContracts/DocumentsContracts.component'), 'DocumentsContracts'),
  AsyncDocumentsInvoices: getAsyncRouteComponent(() =>
    import('+dashboard/+documents/containers/DocumentsInvoices/DocumentsInvoices.component'), 'DocumentsInvoices'),
  AsyncDocumentsSystemDocs: getAsyncRouteComponent(() =>
    import('+documents/containers/DocumentsSystemDocs/DocumentsSystemDocsPage.component'), 'DocumentsSystemDocsPage'),
  AsyncRecruitFriends: getAsyncRouteComponent(() =>
    import('+dashboard/+recruit-friends/containers/RecruitFriends/RecruitFriends.component'), 'RecruitFriends'),
  AsyncAccount: getAsyncRouteComponent(() =>
    import('+dashboard/+account/containers/Account/Account.component'), 'Account'),
  AsyncAccountAboutMe: getAsyncRouteComponent(() =>
    import('+dashboard/+account/containers/AccountAboutMe/AccountAboutMe.component'), 'AccountAboutMe'),
  AsyncAccountPasswordChange: getAsyncRouteComponent(() =>
    import('+dashboard/+account/containers/AccountPasswordChange/AccountPasswordChange.component'), 'AccountPasswordChange'),
  AsyncAccountSettings: getAsyncRouteComponent(() =>
    import('+dashboard/+account/containers/AccountSettings/AccountSettings.component'), 'AccountSettings'),
  AsyncHelp: getAsyncRouteComponent(() =>
    import('+dashboard/+help/containers/Help/Help.component'), 'Help'),
  AsyncHelpSupport: getAsyncRouteComponent(() =>
    import('+dashboard/+help/containers/HelpSupport/HelpSupport.component'), 'HelpSupport'),
  AsyncHelpTax: getAsyncRouteComponent(() =>
    import('+dashboard/+help/containers/HelpTax/HelpTax.component'), 'HelpTax'),
  AsyncHelpSupportConfirmation: getAsyncRouteComponent(() =>
    import('+dashboard/+help/containers/HelpSupportConfirmation/HelpSupportConfirmation.component'), 'HelpSupportConfirmation'),
  AsyncFeedback: getAsyncRouteComponent(() =>
    import('+app/+feedback/containers/Feedback/Feedback.component'), 'Feedback'),
  AsyncDeveloper: getAsyncRouteComponent(() =>
    import('+app/+developer/containers/Developer/Developer.component'), 'Developer'),
  AsyncDemo: getAsyncRouteComponent(() =>
    import('+app/+demo/containers/Demo/Demo.component'), 'Demo'),

  // PUBLIC ROUTES
  AsyncGuideDiscovery: getAsyncRouteComponent(() =>
    import('+guide/+discovery/containers/GuideDiscovery/GuideDiscovery.component'), 'GuideDiscovery'),
  AsyncGuideDiscoveryDistribution: getAsyncRouteComponent(() =>
    import('+guide/+purchase/containers/GuidePurchase/GuidePurchase.component'), 'GuideDiscoveryDistribution'),
  AsyncGuideDiscoveryOnSiteVisit: getAsyncRouteComponent(() =>
    import('+guide/+purchase/containers/GuidePurchase/GuidePurchase.component'), 'GuideDiscoveryOnSiteVisit'),
  AsyncGuidePurchase: getAsyncRouteComponent(() =>
    import('+guide/+purchase/containers/GuidePurchase/GuidePurchase.component'), 'GuidePurchase'),
  AsyncGuidePurchaseOfferReceived: getAsyncRouteComponent(() =>
    import('+guide/+purchase/containers/GuidePurchase/GuidePurchase.component'), 'GuidePurchaseOfferReceived'),
  AsyncGuidePurchaseAcceptOffer: getAsyncRouteComponent(() =>
    import('+guide/+purchase/containers/GuidePurchase/GuidePurchase.component'), 'GuidePurchaseAcceptOffer'),
  AsyncGuidePurchaseOrderConfirmationFlat: getAsyncRouteComponent(() =>
    import('+guide/+purchase/containers/GuidePurchase/GuidePurchase.component'), 'GuidePurchaseOrderConfirmationFlat'),
  AsyncGuidePurchaseOrderConfirmationHardware: getAsyncRouteComponent(() =>
    import('+guide/+purchase/containers/GuidePurchase/GuidePurchase.component'), 'GuidePurchaseOrderConfirmationHardware'),
  AsyncGuideAcceptance: getAsyncRouteComponent(() =>
    import('+guide/+acceptance/containers/GuideAcceptance/GuideAcceptance.component'), 'GuideAcceptance'),
  AsyncGuideAcceptanceHardwareOffer: getAsyncRouteComponent(() =>
    import('+guide/+acceptance/components/GuideAcceptanceHardwareOffer/GuideAcceptanceHardwareOffer.component'), 'GuideAcceptanceHardwareOffer'),
  AsyncGuideAcceptancePersonalDetailsError: getAsyncRouteComponent(() =>
    import('+guide/+acceptance/containers/GuideAcceptancePersonalDetailsError/GuideAcceptancePersonalDetailsError.component'), 'GuideAcceptancePersonalDetailsError'),
  AsyncGuideAcceptanceStepper: getAsyncRouteComponent(() =>
    import('+app/+guide/+acceptance/components/GuideAcceptanceStepper/GuideAcceptanceStepper.component'), 'GuideAcceptanceStepper'),
  AsyncGuideSetup: getAsyncRouteComponent(() =>
    import('+guide/+setup/containers/GuideSetup/GuideSetup.component'), 'GuideSetup'),
  AsyncGuideUsage: getAsyncRouteComponent(() =>
    import('+guide/containers/GuideUsage/GuideUsage.component'), 'GuideUsage'),
};
