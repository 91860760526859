import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { GUIDE_ACCEPTANCE_ACTIONS, GuideAcceptanceActions } from './+acceptance.actions';
import {
  ACCEPT_DOCUMENTS_QUERY,
  ACCEPT_OFFER_QUERY,
  GET_ENERGY_PROVIDERS_QUERY,
  GET_UTILITY_DATA_QUERY,
  GuideAcceptanceState,
  initialState,
  SET_PAYMENT_DATA_QUERY,
  SET_PV_SYSTEM_KEYS_QUERY,
  SET_UTILITY_DATA_QUERY,
} from './+acceptance.state';

export const reducer = createReducer<GuideAcceptanceState, GuideAcceptanceActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case GUIDE_ACCEPTANCE_ACTIONS.SET_ENERGY_PROVIDERS:
        return {
          ...state,
          energyProviders: action.energyProviders,
        };
      case GUIDE_ACCEPTANCE_ACTIONS.SET_FETCHED_UTILITY_DATA:
        return {
          ...state,
          utilityData: action.utilityData,
        };
      default:
        return queryReducer(state, action, [
          GET_ENERGY_PROVIDERS_QUERY,
          SET_PAYMENT_DATA_QUERY,
          ACCEPT_OFFER_QUERY,
          SET_UTILITY_DATA_QUERY,
          GET_UTILITY_DATA_QUERY,
          ACCEPT_DOCUMENTS_QUERY,
          SET_PV_SYSTEM_KEYS_QUERY,
        ]);
    }
  },
);
