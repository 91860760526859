import { T } from '@sonnen/shared-i18n/customer';
import {
  DocumentStatus,
  Icon,
  LinkButton,
  LinkButtonIconPosition,
  ModalDocumentDownload,
} from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { GuideDocumentErrorModal } from '+app/+guide/components';

import './GuideAcceptanceFlatOfferDownload.component.scss';

interface Props {
  documentId?: string;
  documentUrl?: string;
  documentStatus: DocumentStatus;
  isFlatXOffer: boolean;
  openFlatOfferDocument: () => void;
  stopFlatOfferPolling: () => void;
  regenerateFlatOfferDocument: () => void;
}
export const GuideAcceptanceFlatOfferDownload: React.FC<Props> = ({
  documentId,
  documentUrl,
  documentStatus,
  isFlatXOffer,
  openFlatOfferDocument,
  stopFlatOfferPolling,
  regenerateFlatOfferDocument,
}) => {
  const [isModalOpen, toggleModal] = React.useState(false);

  const onDownloadClick = () => {
    openFlatOfferDocument();
    toggleModal(true);
  };

  const onModalClose = () => {
    stopFlatOfferPolling();
    toggleModal(false);
  };

  return (
    <div className={'c-guide-acceptance-flat-offer-download__button'}>
      {documentId ? (
        <>
          <LinkButton
            iconPosition={LinkButtonIconPosition.BEFORE}
            onClick={onDownloadClick}
            iconClass={'c-guide-acceptance-flat-offer-download__button-icon'}
            icon={<Icon.Download width={18} />}
          >
            {isFlatXOffer
              ? I18n.t(T.customerGuidance.acceptance.flatOverview.openDocument)
              : I18n.t(T.customerGuidance.acceptance.flatOverview.openDocumentFlatDirect)
            }
          </LinkButton>
          <ModalDocumentDownload
            isOpen={isModalOpen}
            status={documentStatus!}
            onModalClose={onModalClose}
            loadingText={I18n.t(T.customerGuidance.documents.statusMessage.pending)}
            errorText={<GuideDocumentErrorModal onRegenerateClick={regenerateFlatOfferDocument} />}
            linkText={I18n.t(T.customerGuidance.documents.statusMessage.created.clickHere)}
            createdText={I18n.t(T.customerGuidance.documents.statusMessage.created.toOpenFlat)}
            fileUrl={documentUrl!}
          />
        </>
      ) : (
        <span>
          {I18n.t(T.customerGuidance.acceptance.flatOverview.documentNotAvailable)}
        </span>
      )}
    </div>
  );
};
