import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import './UserBadge.component.scss';

interface Props {
  avatar?: string;
  title?: string;
  subtitle?: string;
  customerNumber?: string;
}

export const UserBadge: React.FC<Props> = ({ avatar, title, subtitle, customerNumber }) => (
  <div className={'c-user-badge'}>
    <div className={'c-user-badge__inner'}>
      <div className={'c-user-badge__side'}>
        {avatar ? (
          <img
            className={'c-user-badge__image'}
            src={avatar}
            alt={I18n.t(T.general.header.avatarAlt)}
          />
        ) : (
          <Icon.Account className={'c-user-badge__icon'}/>
        )}
      </div>
      <div className={'c-user-badge__content'}>
        {title && (
          <p className={'c-user-badge__title'}>
            {title}
          </p>
        )}
        {subtitle && (
          <p className={'c-user-badge__subtitle'}>
            {subtitle}
          </p>
        )}
        {customerNumber && (
          <p className={'c-user-badge__subtitle'}>
            {I18n.t(T.general.header.customerNumber, { customerNumber })}
          </p>
        )}
      </div>
    </div>
  </div>
);
