import { queryReducer } from '+shared/store/query/query.reducer';
import { createReducer } from '+utils/redux';
import { AUTH_ACTIONS, AuthActions } from './auth.actions';
import { createCodeVerifier } from './auth.helpers';
import {
  AuthState,
  GENERATE_REVERSE_CHANNEL_TOKEN_QUERY,
  initialState,
} from './auth.state';

export const reducer = createReducer<AuthState, AuthActions>(
  (state = initialState, action) => {
    switch (action.type) {
      case AUTH_ACTIONS.SET_LAST_ACCESSED_AUTH_URL:
        return {
          ...state,
          lastAccessedAuthUrl: action.url,
        };
      case AUTH_ACTIONS.REDIRECT_TO_AUTH:
        return {
          ...state,
          verifier: createCodeVerifier(),
        };
      case AUTH_ACTIONS.REDIRECT_TO_SALESFORCE:
        return {
          ...state,
          impersonatedUserId: action.userId || initialState.impersonatedUserId,
          verifier: createCodeVerifier(),
        };
      case AUTH_ACTIONS.LOGIN:
        return {
          ...state,
          code: action.code || initialState.code,
        };
      case AUTH_ACTIONS.AUTHENTICATE:
        return {
          ...state,
          code: initialState.code,
          accessToken: action.accessToken,
          refreshToken: action.refreshToken || initialState.refreshToken,
          tokenType: action.tokenType,
          hasLoggedInOnce: true,
        };
      case AUTH_ACTIONS.REFRESH_TOKEN:
        return {
          ...state,
          accessToken: action.accessToken,
          refreshToken: action.refreshToken,
          tokenType: action.tokenType,
        };
      case AUTH_ACTIONS.AUTHENTICATE_IMPERSONATOR:
        return {
          ...state,
          accessToken: action.accessToken,
          tokenType: action.tokenType,
          hasLoggedInOnce: true,
          impersonatedUserId: action.impersonatedUserId,
        };
      case AUTH_ACTIONS.AUTHENTICATE_START:
        return {
          ...state,
          retryCount: 0,
        };
      case AUTH_ACTIONS.AUTHENTICATE_RETRY:
        return {
          ...state,
          retryCount: state.retryCount + 1,
        };
      case AUTH_ACTIONS.CLEAR_STORE:
        return {
          ...state,
          ...action.snapshot,
        };
      case AUTH_ACTIONS.REPLACE_ACCESS_TOKEN:
        return {
          ...state,
          accessToken: action.accessToken,
        };
      case AUTH_ACTIONS.REPLACE_REFRESH_TOKEN:
        return {
          ...state,
          refreshToken: action.refreshToken,
        };
      case AUTH_ACTIONS.SAVE_REVERSE_CHANNEL_TOKEN:
        return {
          ...state,
          reverseChannelToken: action.reverseChannelToken,
        };
      case AUTH_ACTIONS.CLEAR_REVERSE_CHANNEL_TOKEN:
        return {
          ...state,
          reverseChannelToken: initialState.reverseChannelToken,
        };
      case AUTH_ACTIONS.SAVE_TOKEN_FOR_ACTION:
        return {
          ...state,
          tokenForAction: action.token,
        };
      default:
        return queryReducer(state, action, [
          GENERATE_REVERSE_CHANNEL_TOKEN_QUERY,
        ]);
    }
  },
  {
    persist: {
      key: 'auth',
      blacklist: [],
    },
  },
);
