import { T } from '@sonnen/shared-i18n/customer';
import { PageSubheadline } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { TableList } from '+components';
import { ContractDetailsConfig } from '+contract/store/helpers/contract.helpers';

interface Props {
  contractDetailsList: ContractDetailsConfig[] | undefined;
}

export const ContractDetails: React.FC<Props> = ({ contractDetailsList }) => (
  <>
    <PageSubheadline mediumGap={true}>
      {I18n.t(T.yourFlat.secondaryHeadline)}
    </PageSubheadline>
    <TableList list={contractDetailsList} />
  </>
);
