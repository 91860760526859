import * as React from 'react';

import { AccountSettings } from '+app/+dashboard/+account';
import { NestedNavSlideContent } from '../../NestedNav/NestedNavSlideContent';

export const MobileSideNavNestedAccountSettings: React.FC = () => (
  <NestedNavSlideContent
    hasGap={true}
  >
    <AccountSettings />
  </NestedNavSlideContent>
);
