import * as classNames from 'classnames';
import * as React from 'react';

import { NESTED_NAV_ANIMATION_DURATION, NestedNavContent, useNestedNav } from '../NestedNav.component';
import { NestedNavSlideHeader } from '../NestedNavSlideHeader';

import './NestedNavSlide.component.scss';

interface NestedNavSlideProps {
  slideIndex: number;
  slide: NestedNavContent;
}

export const NestedNavSlide: React.FC<NestedNavSlideProps> = ({ slideIndex, slide }) => {
  const { stack, animating, goBack } = useNestedNav();
  const isOnStack = stack.includes(slideIndex);

  return (
    <div className={
      classNames('c-nested-nav-slide', {
        'c-nested-nav-slide--on-stack': isOnStack,
        'c-nested-nav-slide--slide-in': animating.slidingIn === slideIndex,
        'c-nested-nav-slide--slide-out': animating.slidingOut === slideIndex,
      })}
      style={{
        '--animation-duration': `${NESTED_NAV_ANIMATION_DURATION}ms`,
      } as React.CSSProperties}
    >
      <NestedNavSlideHeader
        icon={slide.icon}
        title={slide.title}
        goBack={goBack}
      />
      {slide.content}
    </div>
  );
};
