export enum DocumentAcceptance {
  TERMS_AND_CONDITIONS_AND_CANCELLATION = 'terms_and_conditions_and_cancellation',
  PRIVACY_POLICY = 'privacy_policy',
  MARKETING_MAILING = 'marketing_mailing',
  ASSIGNMENT_NOTICE = 'assignment_notice',
}

export interface DocumentAcceptanceLinks {
  [DocumentAcceptance.TERMS_AND_CONDITIONS_AND_CANCELLATION]: {
    termsAndConditions: string;
  };
}
