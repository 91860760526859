import { createSelector } from 'reselect';

import {
  getSetupDsoMeterInstallation,
  getSetupDsoRegistration,
  getSetupHardwareCommissioning,
} from '+app/+guide/+setup/store/+setup.selectors';
import { getGuideLeadPartnerStatus, isSonnenLead } from '+app/+guide/store/+lead.selectors';
import { PartnerStatus } from '+app/+guide/store/types/partner.interface';
import { getOfferDates } from '+guide/+purchase/store/+offer.selectors';
import {
  getAcceptedImpactAnalysis,
  getFlatOfferStatus,
  getFlatOrderConfirmedStatus,
  getHardwareOrderConfirmedStatus,
  getImpactAnalysisDates,
  getOldestImpactAnalysis,
  isImpactAnalysisAvailable,
} from '+guide/+purchase/store/+purchase.selectors';
import { getGuideAcceptedOffer } from '+guide/store/+guide.selectors';
import { NotificationType } from '../containers/GuideNotifications/GuideNotifications.helper';

interface Notification {
  type: NotificationType;
  date: string | Date;
  isFirstNotification?: boolean;
}

export const getDiscoveryNotifications = createSelector(
  getGuideLeadPartnerStatus,
  isSonnenLead,
  (
    guideLeadPartnerStatus,
    isSonnenLead,
  ) => {
    const returnArray: Notification[] = [];

    // @TODO Add date when available
    if (isSonnenLead && guideLeadPartnerStatus === PartnerStatus.ACCEPTED) {
      const partnerDistribution = {
        type: NotificationType.PARTNER_AVAILABLE,
        date: 'unknown',
      };
      returnArray.push(partnerDistribution);
    }
    return returnArray;
  },
);

export const getPurchaseNotifications = createSelector(
  getFlatOfferStatus,
  getOfferDates,
  isImpactAnalysisAvailable,
  getImpactAnalysisDates,
  getOldestImpactAnalysis,
  getHardwareOrderConfirmedStatus,
  getFlatOrderConfirmedStatus,
  getGuideAcceptedOffer,
  getAcceptedImpactAnalysis,
  (
    flatOfferAvailableStatus,
    flatOfferDates,
    impactAnalysisAvailableNotification,
    impactAnalysisDates,
    oldestImpactAnalysis,
    hardwareOrderStatus,
    flatOrderStatus,
    acceptedOffer,
    acceptedIa,
  ) => {
    const returnArray: Notification[] = [];

    if (acceptedOffer) {
      const flatOffer = {
        type: NotificationType.OFFER_AVAILABLE,
        date: acceptedOffer.createdAt,
        isFirstNotification: true,
      };
      returnArray.push(flatOffer);
    } else if (flatOfferAvailableStatus && flatOfferAvailableStatus.isSet) {
      flatOfferDates.map((date, index) => {
        const flatOffer = {
          type: NotificationType.OFFER_AVAILABLE,
          date,
          isFirstNotification: index === (flatOfferDates.length - 1),
        };
        returnArray.push(flatOffer);
      });
    }
    if (acceptedIa) {
      const impactAnalysis = {
        type: NotificationType.IA_AVAILABLE,
        date: acceptedIa.updatedAt,
        isFirstNotification: true,
      };
      returnArray.push(impactAnalysis);
    } else if (impactAnalysisAvailableNotification && oldestImpactAnalysis && !acceptedOffer) {
      impactAnalysisDates.map((date, index) => {
        const impactAnalysis = {
          type: NotificationType.IA_AVAILABLE,
          date,
          isFirstNotification: index === (impactAnalysisDates.length - 1),
        };
        returnArray.push(impactAnalysis);
      });
    }
    if (flatOrderStatus && flatOrderStatus.isSet) {
      const flatOrder = {
        type: NotificationType.FLAT_ORDER_CONFIRMED,
        date: flatOrderStatus.createdAt,
      };
      returnArray.push(flatOrder);
    }
    if (hardwareOrderStatus && hardwareOrderStatus.isSet) {
      const hardwareOrder = {
        type: NotificationType.HW_ORDER_CONFIRMED,
        date: hardwareOrderStatus.createdAt,
      };
      returnArray.push(hardwareOrder);
    }
    return returnArray;
  },
);

export const getSetupNotifications = createSelector(
  getSetupDsoRegistration,
  getSetupHardwareCommissioning,
  getSetupDsoMeterInstallation,
  (
    getSetupDsoRegistration,
    getSetupHardwareCommissioning,
    getSetupDsoMeterInstallation,
  ) => {
    const returnArray: Notification[] = [];

    if (getSetupDsoRegistration && getSetupDsoRegistration.isSet) {
      const dsoRegistrationStatus = {
        type: NotificationType.DSO_REGISTRATION_DONE,
        date: getSetupDsoRegistration.createdAt,
      };
      returnArray.push(dsoRegistrationStatus);
    }
    if (getSetupHardwareCommissioning && getSetupHardwareCommissioning.isSet) {
      const hardwareCommissioningStatus = {
        type: NotificationType.HARDWARE_COMMISSIONING_DONE,
        date: getSetupHardwareCommissioning.createdAt,
      };
      returnArray.push(hardwareCommissioningStatus);
    }
    if (getSetupDsoMeterInstallation && getSetupDsoMeterInstallation.isSet) {
      const dsoMeterInstallationStatus = {
        type: NotificationType.DSO_METER_INSTALLATION,
        date: getSetupDsoMeterInstallation.createdAt,
      };
      returnArray.push(dsoMeterInstallationStatus);
    }
    return returnArray;
  },
);

export const getNotifications = createSelector(
  getDiscoveryNotifications,
  getPurchaseNotifications,
  getSetupNotifications,
  (
    discoveryNotifications,
    purchaseNotifications,
    setupNotifications,
  ) => setupNotifications.concat(purchaseNotifications, discoveryNotifications),
);
