import * as classNames from 'classnames';
import * as React from 'react';

import './GuideAcceptanceDetailsLine.component.scss';

interface Props {
  isMainValue?: boolean;
  isLight?: boolean;
  name: string;
  value: string;
  helperLabel?: string;
}

export const GuideAcceptanceDetailsLine: React.FC<Props> = ({ isMainValue, isLight, name, value, helperLabel }) => (
  <div className={classNames('c-guide-acceptance-details', {
    'c-guide-acceptance-details--big': isMainValue,
  })}>
    <div className={classNames('c-guide-acceptance-details__line', {
      'c-guide-acceptance-details__line--big': isMainValue,
      'c-guide-acceptance-details__line--light': isLight,
    })}>
      <div className={'c-guide-acceptance-details__line-name'}>
        {name}
      </div>
      <div className={classNames('c-guide-acceptance-details__line-value', {
        'c-guide-acceptance-details__line-value--big': isMainValue,
      })}>
        {value}
      </div>
    </div>
    {helperLabel && (
      <div className={'c-guide-acceptance-details__helper'}>
        {helperLabel}
      </div>
    )}
  </div>
);
