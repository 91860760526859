import { T } from '@sonnen/shared-i18n/customer';
import { Icon, LinkButton, LinkButtonIconPosition } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { useInfographic } from '+dashboard/+infographic';
import { InfographicCardTabs } from '../InfographicCardTabs/InfographicCardTabs.component';

import './InfographicCard.component.scss';

export const InfographicCard: React.FC = () => {
  const { goToPrevCard, goToNextCard } = useInfographic();

  return (
    <div className={'c-infographic-card'}>
      <InfographicCardTabs />
      <div className={'c-infographic-card__nav-item c-infographic-card__nav-item--prev'}>
        <LinkButton
          dataTestId={'dashboard-infographic-card-previous-button'}
          icon={<Icon.Angle />}
          onClick={goToPrevCard}
          onlyIconOnMobile={true}
        >
          {I18n.t(T.general.buttons.previous).toLowerCase()}
        </LinkButton>
      </div>
      <div className={'c-infographic-card__nav-item c-infographic-card__nav-item--next'}>
        <LinkButton
          dataTestId={'dashboard-infographic-card-next-button'}
          icon={<Icon.Angle />}
          iconPosition={LinkButtonIconPosition.AFTER}
          onClick={goToNextCard}
          onlyIconOnMobile={true}
        >
          {I18n.t(T.general.buttons.next).toLowerCase()}
        </LinkButton>
      </div>
    </div>
  );
};
