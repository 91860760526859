import { removeElement } from '+utils/array.util';
import { createReducer } from '+utils/redux';
import { defaultTo } from 'lodash/fp';
import { APP_ACTIONS, AppAction } from './app.actions';
import { AppState, initialState } from './app.state';

export const appReducer = createReducer<AppState, AppAction>(
  (state = initialState, action): AppState => {
    switch (action.type) {
      case APP_ACTIONS.TOGGLE_ONLINE_STATE:
        return {
          ...state,
          isOnline: action.status,
        };
      case APP_ACTIONS.TOGGLE_MODAL_STATUS:
        const modalIndex = state.modalsOpen.indexOf(action.modalId);
        return {
          ...state,
          modalsOpen: action.isOpen
          ? (modalIndex === -1
            ? [ ...state.modalsOpen, action.modalId ]
            : [ ...state.modalsOpen ])
          : removeElement(modalIndex, state.modalsOpen),
        };
      case APP_ACTIONS.REDIRECT_SUCCESS:
        return {
          ...state,
          resendSuccess: action.status,
        };
      case APP_ACTIONS.FORGOT_PASSWORD_SUCCESS:
        return {
          ...state,
          isForgotPasswordSuccess: action.status,
          forgotPasswordStatus: '',
        };
      // TODO: remove this unnecessary part of reducer, 
      // instead use above FORGOT_PASSWORD_SUCCESS with arg set to false
      case APP_ACTIONS.FORGOT_PASSWORD_FAILED:
        return {
          ...state,
          isForgotPasswordSuccess: false,
          forgotPasswordStatus: action.code,
        };
      case APP_ACTIONS.RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          isResetPasswordSuccess: action.status,
        };
      case APP_ACTIONS.HELP_AND_SUPPORT_SUBMIT_SUCCESS:
        return {
          ...state,
          isHelpAndSupportSubmitSuccess: action.status,
        };
      case APP_ACTIONS.SET_IS_READY:
        return {
          ...state,
          isReady: true,
        };
      case APP_ACTIONS.TOGGLE_PULL_TO_REFRESH:
        return {
          ...state,
          isPullToRefreshEnabled: defaultTo(!state.isPullToRefreshEnabled)(action.isEnabled),
        };
      default:
        return state;
    }
  },
);
