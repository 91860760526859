import { T } from '@sonnen/shared-i18n/customer';
import * as moment from 'moment';
import * as React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import { LiveChargerHoCDataProps } from '+app/shared/store/live/liveCharger.hoc';
import { DistanceHelper } from '+app/utils/distance.util';
import { formatNumber } from '+app/utils/number.util';
import { InfographicValue } from '../InfographicValue/InfographicValue.component';

interface Props {
  chargerData?: LiveChargerHoCDataProps;
}

const renderDefaultValue = () => (
  <InfographicValue
    description={(
      <Translate
        value={T.dashboard.infographic.charger.messages.default}
        dangerousHTML={true}
      />
    )}
  />
);

const renderStateValue = (chargerData: LiveChargerHoCDataProps) => {
  const {
    isOnline,
    isCharging,
    isCarConnected,
    isSmartmodeCharging,
    isPowermodeCharging,
    isReadyForCharging,
    departureAt,
  } = chargerData;

  const chargingSpeed = DistanceHelper.getFormattedDistanceObj(chargerData.chargingSpeed);
  const distanceCharged = DistanceHelper.getFormattedDistanceObj(chargerData.distanceCharged);

  const isStatusSmartmode = isSmartmodeCharging && distanceCharged && departureAt;
  const isStatusPowermode = isPowermodeCharging && chargingSpeed && distanceCharged;
  const isStatusCarConnected = isCarConnected && !isCharging;
  const isStatusReadyForCharging = isReadyForCharging;
  const isStatusOffline = !isOnline;

  return (
    <>
      {isStatusSmartmode && (
        <InfographicValue
          title={I18n.t(T.dashboard.infographic.charger.headlines.readyAt)}
          icon={'Charger'}
          value={moment(departureAt).format('HH:mm')}
          description={(
            <Translate
              value={T.dashboard.infographic.charger.messages.distanceCharged}
              distance={`${formatNumber()(distanceCharged.value)} ${distanceCharged.unit}`}
              dangerousHTML={true}
            />
          )}
        />
      )}
      {isStatusPowermode && (
        <InfographicValue
          title={I18n.t(T.dashboard.infographic.charger.headlines.chargingSpeed)}
          icon={'Charger'}
          value={chargingSpeed.value}
          unit={`${chargingSpeed.unit}/h`}
          description={(
            <Translate
              value={T.dashboard.infographic.charger.messages.distanceCharged}
              distance={`${formatNumber()(distanceCharged.value)} ${distanceCharged.unit}`}
              dangerousHTML={true}
            />
          )}
        />
      )}
      {isStatusCarConnected && (
        <InfographicValue
          title={I18n.t(T.dashboard.infographic.charger.headlines.currentState)}
          description={I18n.t(T.dashboard.infographic.charger.messages.readyToGo)}
        />
      )}
      {isStatusReadyForCharging && (
        <InfographicValue
          title={I18n.t(T.dashboard.infographic.charger.headlines.currentState)}
          description={I18n.t(T.dashboard.infographic.charger.messages.cableConnection)}
        />
      )}
      {isStatusOffline && (
        <InfographicValue
          title={I18n.t(T.dashboard.infographic.charger.headlines.currentState)}
          description={I18n.t(T.dashboard.infographic.charger.messages.noInternet)}
        />
      )}
    </>
  );
};

export const InfographicChargerCardValue: React.FC<Props> = ({ chargerData }) =>
  !chargerData || !chargerData.hasCharger
    ? renderDefaultValue()
    : renderStateValue(chargerData);
