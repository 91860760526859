import * as React from 'react';
import Media from 'react-media';
import * as uuid from 'uuid';
import { TransitionChart } from '../../../../shared/graphs';
import { EnergyValue, Hertz } from '../../../../shared/utils.types';
import { DESKTOP, GRADIENT, MOBILE } from '../../frequency.constants';
import { getScales } from '../../frequency.helpers';
import { AxisX } from './elements/AxisX.component';
import { AxisY } from './elements/AxisY.component';
import { Circle } from './elements/Circle.component';
import { Clip } from './elements/Clip.component';
import { Community } from './elements/Community';
import { ExpectedValue } from './elements/ExpectedValue.component';
import { Line } from './elements/Line.component';
import { iconFrequency } from './FrequencyChart.component.scss';

interface Props {
  width: number;
  height: number;
  data: Array<EnergyValue<Hertz>>;
}

const clipId = `clip-${uuid.v4()}`;

export const FrequencyChart: React.SFC<Props> = ({ width, height, data }) => (
  <Media query="(max-width: 767px)">
    {(matches: boolean) => {
      const { POSITION: position } = matches ? MOBILE : DESKTOP;
      const values = data.map(({ value }) => value);
      const { xScale, yScale } = getScales([width, height], values);

      return (
        <TransitionChart
          width={width}
          height={height}
        >
          <Clip
            clipId={clipId}
            x={xScale(position.CLIP[0])}
          />
          <AxisY
            range={yScale.range()}
          />
          <AxisX />
          <ExpectedValue
            value={yScale(50)}
          />
          <Line
            xScale={xScale}
            yScale={yScale}
            data={values}
            gradient={GRADIENT}
            clipId={clipId}
          />
          <Community
            x={xScale(position.CHART[0])}
            y={yScale(values[position.CHART[1]])}
            balanceValue={yScale(50)}
            range={yScale.range()}
          />
          <Circle
            xScale={xScale}
            yScale={yScale}
            x={position.CHART[0]}
            y={values[position.CHART[1]]}
            icon={iconFrequency}
          />
        </TransitionChart>
      );
    }}
  </Media>
);
