import { MeasurementMethod } from '+app/shared/store/live/types/siteLiveData.interface';
import { Site, SiteOption } from '+app/shared/store/site/types/site.interface';

export const SITES_MOCK: Site[] = [
  {
    id: '12345',
    type: '',
    self: '',
    street: 'Test site',
    streetNumber: '123',
    postalCode: '45643',
    city: 'Berlin',
    state: '',
    countryCode: 'DE',
    deliveryStartAt: '2018-09-14',
    sonnenCommunityMember: true,
    options: [
      SiteOption.BATTERIES,
      SiteOption.CHARGERS,
      SiteOption.CONTRACTS,
      SiteOption.READINGS,
    ],
    measurementMethod: MeasurementMethod.BATTERY,
    chargers: ['chargerFakeId'],
    contracts: ['contractFakeId'],
    batterySystems: ['batteryFakeId'],
  },
  {
    id: '23456',
    type: '',
    self: '',
    street: 'Secondstreet',
    streetNumber: '11',
    postalCode: '45643',
    city: 'Berlin',
    state: '',
    countryCode: 'DE',
    deliveryStartAt: '2019-10-14',
    sonnenCommunityMember: true,
    options: [
      SiteOption.BATTERIES,
      SiteOption.CHARGERS,
      SiteOption.CONTRACTS,
    ],
    measurementMethod: MeasurementMethod.BATTERY,
    chargers: ['chargerFakeId'],
    contracts: ['contractFakeId'],
    batterySystems: ['batteryFakeId'],
  },
];
