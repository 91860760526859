import * as React from 'react';

import { Tag, TagTheme } from '+app/shared/components';

import './GuideDetailsTag.component.scss';

export type GuideDetailsTagProps = {
  tag: string;
  tagTheme?: TagTheme;
  description?: string;
};

export const GuideDetailsTag: React.FC<GuideDetailsTagProps> = ({
  tag,
  tagTheme = 'open',
  description,
}) => (
  <div className={'c-guide-details-tag'}>
    <Tag
      text={tag}
      theme={tagTheme}
    />
    <p className={'c-guide-details-tag__text'}>
      {description}
    </p>
  </div>
);
