import { Icon, Loader, PageSubheadline } from '@sonnen/shared-web';
import * as classNames from 'classnames';
import * as React from 'react';

import './InfographicCardLayout.component.scss';

interface InfographicCardLayoutProps {
  title: string;
  notifications?: React.ReactNode;
  isLoading?: boolean;
  tooltipTitle?: string;
  tooltipContent?: React.ReactNode;
  headerDataTestId?: string;
}

export const InfographicCardLayout: React.FC<InfographicCardLayoutProps> = ({
  title,
  notifications,
  isLoading,
  tooltipTitle,
  tooltipContent,
  children,
  headerDataTestId,

}) => {
  const [isTooltipActive, setTooltipState] = React.useState<boolean>(false);
  const toggleTooltip = () => setTooltipState(!isTooltipActive);
  const isTooltipAvailable = tooltipTitle || tooltipContent;

  return (
    <div className={'c-infographic-card-layout'}>
      <div className={'c-infographic-card-layout__header'}>
        <PageSubheadline
          dataTestId={headerDataTestId}
          smallGap={true}>
          {title}
        </PageSubheadline>
        {!isLoading && isTooltipAvailable && (
          <button
            className={'c-infographic-card-layout__header-button'}
            type={'button'}
            onClick={toggleTooltip}
          >
            <Icon.QuestionMark />
          </button>
        )}
      </div>
      {isTooltipAvailable && (
        <div className={classNames('c-infographic-card-layout__popup', {
          'is-active': isTooltipActive,
        })}>
          {tooltipTitle && (
            <>
              <PageSubheadline smallGap={true}>
                {tooltipTitle}
              </PageSubheadline>
              <button
                className={'c-infographic-card-layout__popup-button'}
                type={'button'}
                onClick={toggleTooltip}
              >
                <Icon.Close />
              </button>
            </>
          )}
          {tooltipContent && (
            <>
              {tooltipContent}
            </>
          )}
        </div>
      )}
      {!isLoading ? (
        <div className={'c-infographic-card-layout__content'}>
          <div className={'c-infographic-card-layout__notifications'}>
            {notifications
              ? notifications
              : <>&nbsp;</>
            }
          </div>
          {children}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
