import { Loader } from '@sonnen/shared-web';
import * as React from 'react';

import './CessionDocument.component.scss';

interface Props {
  url: string;
}

export const CessionDocument: React.FC<Props> = ({ url }) => (
  <div className={'c-cession-document'}>
    <iframe
      className={'c-cession-document__document'}
      src={url}
    />
  </div>
);
