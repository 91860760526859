import { T } from '@sonnen/shared-i18n/customer';
import { ButtonSize, ButtonTheme } from '@sonnen/shared-web';
import { uniq } from 'lodash';

import { getPath } from '+app/router/router.helper';
import { RouteName } from '+app/router/router.types';
import { history } from '+router/store';
import { GTMEventData, SendEvent } from '+shared/helpers/analytics';

export type ButtonToEventMap = {
  [P in MigrationBannerButtonKey]?: GTMEventData
};

interface MigrationBannerButtonProperties {
  label: string;
  size: ButtonSize;
  theme: ButtonTheme;
  onClick: () => void;
  redirectRoute?: RouteName;
}

export enum MigrationBannerButtonKey {
  MORE = 'more',
  CALCULATE = 'calculate',
}

export type MigrationBannerButtons = Record<MigrationBannerButtonKey, MigrationBannerButtonProperties>;

const BUTTONS: MigrationBannerButtons = {
  more: {
    label: T.yourFlat.sonnenFlatXMigration.banner.moreButton,
    theme: ButtonTheme.PRIMARY,
    size: ButtonSize.PRIMARY,
    onClick: () => history.push(getPath(RouteName.FLAT_NEW_CONTRACT)),
  },
  calculate: {
    label: T.yourFlat.sonnenFlatXMigration.banner.calculateButton,
    theme: ButtonTheme.OUTLINE,
    size: ButtonSize.PRIMARY,
    onClick: () => history.push(getPath(RouteName.FLAT_MIGRATION)),
  },
};

const getButtonWithGtmEvent =
  (sendEvent?: SendEvent, buttonToGtmEventMap?: ButtonToEventMap) =>
  (key: MigrationBannerButtonKey): MigrationBannerButtonProperties => {
    const button = BUTTONS[key];
    const onClick = () => {
      button.onClick();
      if (sendEvent && buttonToGtmEventMap && buttonToGtmEventMap[key]) {
        const { event, ...extras } = buttonToGtmEventMap[key]!;
        sendEvent(event, extras);
      }
    };

    return {
      ...button,
      onClick,
    };
  };

const getButtons = (buttonsKeys: MigrationBannerButtonKey[]) => uniq(buttonsKeys.map(key => BUTTONS[key]));

const getButtonsWithGtmEvents =
  (sendEvent?: SendEvent) =>
  (buttonKeys: MigrationBannerButtonKey[], buttonToGtmEventMap?: ButtonToEventMap) =>
    buttonKeys.map(getButtonWithGtmEvent(sendEvent, buttonToGtmEventMap));

export const MigrationBannerHelper = {
  getButtons,
  getButtonsWithGtmEvents,
};
