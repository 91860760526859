import * as classNames from 'classnames';
import * as React from 'react';

import '../InfographicIcon.util.scss';

interface Props {
  x: number;
  y: number;
  isActive?: boolean;
  onClick: () => void;
}

/* tslint:disable:max-line-length */
export const InfographicIconConsumption: React.FC<Props> = ({
  x,
  y,
  onClick,
  isActive,
}) => (
  <svg
    className={classNames('u-infographic-icon u-infographic-icon__hover', { 'is-active': isActive })}
    x={x}
    y={y}
    viewBox={'0 0 48 74'}
    width={'44'}
    height={'68'}
    onClick={onClick}
    data-test-id={'dashboard-infographic-icon-consumption'}
  >
    <path
      className={'u-infographic-icon__fill'}
      d={'M33.6,46c-0.3-1.3-0.7-2-1.4-3.1c-0.1-0.3-0.3-0.5-0.5-0.8c-0.2-0.3-0.4-0.7-0.6-1c-0.9-1.4-1.4-2.3-1.4-3.9l0-4.1c0-1-0.7-1.9-1.7-2c-0.4-1.9-1.4-3.1-3-3.5l-2,0c-1.6,0.4-2.6,1.6-2.9,3.5c-1,0.2-1.7,1-1.7,2l0,4.1c0,1.7-0.5,2.6-1.4,3.9c-0.2,0.3-0.4,0.6-0.6,1c-0.2,0.3-0.3,0.6-0.5,0.8c-0.7,1.1-1.1,1.9-1.4,3.1c-0.2,0.8-0.3,1.6-0.3,2.3c0,5.4,4.4,9.8,9.8,9.9c5.4,0,9.8-4.4,9.9-9.8C33.9,47.5,33.8,46.7,33.6,46z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M34.3,46.2c-0.3-1.4-0.8-2.2-1.5-3.4c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.4-0.4-0.7-0.7-1.1c-0.9-1.5-1.5-2.4-1.5-4.2l0-4.4c0-1.1-0.8-2-1.9-2.2c-0.4-2-1.5-3.4-3.2-3.8l0-22.8l-2.1,0l0,22.8c-1.7,0.4-2.8,1.7-3.2,3.7c-1.1,0.2-1.9,1.1-1.9,2.2l0,4.4c0,1.8-0.6,2.7-1.5,4.2c-0.2,0.3-0.4,0.7-0.7,1.1c-0.2,0.3-0.4,0.6-0.5,0.9c-0.7,1.2-1.2,2-1.5,3.4c-0.2,0.8-0.3,1.7-0.3,2.5c0,5.8,4.7,10.6,10.6,10.6c5.8,0,10.6-4.7,10.6-10.6C34.6,47.9,34.5,47,34.3,46.2z M24.1,28c0.5,0,1.8,0,2.3,2l-4.7,0C22.2,28,23.6,28,24.1,28z M19.8,32.2c0-0.2,0.1-0.4,0.4-0.4l7.7,0c0.2,0,0.4,0.2,0.4,0.4l0,1.4l-8.5,0L19.8,32.2z M19.8,35.5l8.5,0l0,1.1c0,0.2,0,0.4,0,0.6l-8.5,0c0-0.2,0-0.4,0-0.6L19.8,35.5L19.8,35.5z M23.9,57.4c-4.8,0-8.7-3.9-8.7-8.7c0.1-0.7,0.2-1.4,0.4-2.1c0.2-1,0.6-1.7,1.3-2.8c0.2-0.3,0.3-0.6,0.5-0.9c0.2-0.4,0.4-0.7,0.6-1c0.6-0.9,1.1-1.8,1.4-2.7l9.2,0c0.3,1,0.8,1.8,1.4,2.8c0.2,0.3,0.4,0.7,0.6,1c0.2,0.3,0.4,0.7,0.5,0.9c0.7,1.1,1,1.8,1.3,2.8c0.2,0.7,0.3,1.4,0.3,2.1C32.6,53.5,28.7,57.4,23.9,57.4z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M8.7,47.7l-3.9,0c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9l3.9,0c0.5,0,0.9-0.4,0.9-0.9C9.6,48.2,9.2,47.7,8.7,47.7z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M13.2,58.5c-0.2,0-0.5,0.1-0.7,0.3l-2.8,2.7c-0.4,0.4-0.4,0.9,0,1.3c0.4,0.4,0.9,0.4,1.3,0l2.8-2.7c0.4-0.4,0.4-0.9,0-1.3C13.7,58.6,13.4,58.5,13.2,58.5z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M24,63.1c-0.5,0-0.9,0.4-0.9,0.9l0,3.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9l0-3.9C24.9,63.5,24.5,63.1,24,63.1z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M35.5,58.9c-0.2-0.2-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.4,0.4-0.4,0.9,0,1.3l2.7,2.8c0.4,0.4,0.9,0.4,1.3,0c0.4-0.4,0.4-0.9,0-1.3L35.5,58.9z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M43.2,47.8l-3.9,0c-0.5,0-0.9,0.4-0.9,0.9s0.4,0.9,0.9,0.9l3.9,0c0.5,0,0.9-0.4,0.9-0.9C44.1,48.2,43.7,47.8,43.2,47.8z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M35.5,38.5l2.8-2.7c0.4-0.4,0.4-0.9,0-1.3c-0.2-0.2-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3l-2.8,2.7c-0.4,0.4-0.4,0.9,0,1.3C34.6,38.9,35.2,38.9,35.5,38.5z'}
    />
    <path
      className={'u-infographic-icon__contour'}
      d={'M12.6,38.5c0.4,0.4,0.9,0.4,1.3,0c0.4-0.4,0.4-0.9,0-1.3l-2.7-2.8c-0.2-0.2-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3c-0.4,0.4-0.4,0.9,0,1.3L12.6,38.5z'}
    />
    <circle cx={'25'} cy={'25'} r={'50'} fill={'#000000'} fillOpacity={'0'} />
  </svg>
);
