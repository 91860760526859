import { T } from '@sonnen/shared-i18n/customer';
import * as moment from 'moment';
import { AnyAction } from 'redux';

import { AnalysisActions } from '+analysis/store/analysis.actions';
import { AuthActions } from '+app/+auth/store/auth.actions';
import { RouteName } from '+app/router/router.types';
import { BatteryActions } from '+battery/store';
import { CommunityActions } from '+community/store';
import { CONFIG } from '+config';
import { ContractActions } from '+contract/store';
import { ConditionsProps } from '+shared/containers/NotificationProvider';
import { SiteActions } from '../site/site.actions';
import { NotificationHelper } from './notification.helper';
import { Notification } from './types/notification.interface';

type NotificationFactory = (actions?: AnyAction[], id?: string) => Notification;

export enum NotificationsIds {
  SITES_API_ERROR = 'SitesApiError',
  ANALYSIS_API_ERROR_MEASUREMENTS = 'AnalysisApiMeasurementsError',
  ANALYSIS_API_ERROR_STATISTICS = 'AnalysisApiStatisticsError',
  BACKUP_BUFFER_SETUP_PROCESSING = 'BackupBufferSetupProcessing',
  BACKUP_BUFFER_SETUP_FAILED = 'BackupBufferSetupFailed',
  BATTERY_API_ERROR = 'BatteryApiError',
  BATTERY_DATA_MISSING = 'BatteryDataMissing',
  BATTERY_INITIALIZATION = 'BatteryInitialization',
  COMMUNITY_API_ERROR = 'CommunityApiError',
  CONTRACT_API_ERROR_DATA = 'ContractDataApiError',
  CONTRACT_API_ERROR_STATISTICS = 'ContractStatisticsApiError',
  EMERGENCY_MODE = 'EmergencyMode',
  IMPERSONATE = 'Impersonate',
  LIVE_STATE_API_ERROR = 'LiveStateApiError',
  LIVE_STATE_FAILOVER = 'LiveStateFailover',
  OFFLINE = 'Offline',
  SET_BACKUP_BUFFER_VALUE_FAILED = 'SetBackupBufferValueFailed',
  BATTERY_OFFLINE = 'BatteryOffline',
  USER_READINGS_API_ERROR = 'UserReadingsApiError',
}

const defaultExcludedRoutes = [
  RouteName.DEMO,
  RouteName.FEEDBACK,
  RouteName.GUIDE,
  RouteName.HELP_AND_SUPPORT,
  RouteName.LOGIN,
  RouteName.LOGOUT,
  RouteName.MY_ACCOUNT,
  RouteName.RECRUIT_FRIENDS,
];

export const createSitesApiErrorNotification: NotificationFactory = () =>
  NotificationHelper.createWarningNotification(NotificationsIds.SITES_API_ERROR, {
    content: {
      title: T.general.messages.cannotFetchAccountData,
      description: '',
      actionButton: {
        label: T.action.retry,
        actions: [AuthActions.forceRefresh(), SiteActions.getSites()],
      },
    },
    conditionFunction: ({ isSitesApiErrorNotification }) => isSitesApiErrorNotification,
    isPermanent: true,
    excludeInRoutes: defaultExcludedRoutes,
  });

const createAnalysisMeasurementsErrorNotification: NotificationFactory = () =>
  NotificationHelper.createWarningNotification(NotificationsIds.ANALYSIS_API_ERROR_MEASUREMENTS, {
    content: {
      title: T.general.errors.api.statistics,
      description: '',
      actionButton: {
        label: T.action.retry,
        actions: CONFIG.IS_MOBILE
        ? [AuthActions.forceRefresh(), AnalysisActions.getMeasurements()]
        : [AuthActions.forceRefresh(), AnalysisActions.getMeasurements(), AnalysisActions.getEvents()],
      },
    },
    conditionFunction: ({ isAnalysisMeasurementsApiErrorNotification }) => isAnalysisMeasurementsApiErrorNotification,
    isPermanent: true,
    includeInRoutes: [
      RouteName.ANALYSIS,
      RouteName.BATTERY,
      RouteName.DASHBOARD,
    ],
  });

const createAnalysisStatisticsErrorNotification: NotificationFactory = () =>
  NotificationHelper.createWarningNotification(NotificationsIds.ANALYSIS_API_ERROR_STATISTICS, {
    content: {
      title: T.general.errors.api.statistics,
      description: '',
      actionButton: {
        label: T.action.retry,
        actions: [AuthActions.forceRefresh(), AnalysisActions.getStatistics()],
      },
    },
    conditionFunction: ({ isAnalysisStatisticsApiErrorNotification }) => isAnalysisStatisticsApiErrorNotification,
    isPermanent: true,
    includeInRoutes: [
      RouteName.ANALYSIS,
      RouteName.BATTERY,
      RouteName.DASHBOARD,
    ],
  });

export const createAnalysisApiErrorNotification: Record<string, () => Notification> = {
  measurements: createAnalysisMeasurementsErrorNotification,
  statistics: createAnalysisStatisticsErrorNotification,
};

export const createCommunityApiErrorNotification: NotificationFactory = () =>
  // @TODO update this config when new community implemented
  NotificationHelper.createWarningNotification(NotificationsIds.COMMUNITY_API_ERROR, {
    content: {
      title: T.general.errors.api.community,
      description: '',
      actionButton: {
        label: T.action.retry,
        actions: [AuthActions.forceRefresh(), CommunityActions.getCommunityStatsData()],
      },
    },
    isPermanent: true,
    includeInRoutes: [RouteName.COMMUNITY],
  });

export const createBatteryApiErrorNotification: NotificationFactory = () =>
  NotificationHelper.createWarningNotification(NotificationsIds.BATTERY_API_ERROR, {
    content: {
      title: T.general.errors.api.battery,
      description: '',
      actionButton: {
        label: T.action.retry,
        actions: [AuthActions.forceRefresh(), BatteryActions.getData()],
      },
    },
    conditionFunction: ({ isBatteryApiErrorNotification }) => isBatteryApiErrorNotification,
    isPermanent: true,
    includeInRoutes: [
      RouteName.ANALYSIS,
      RouteName.BATTERY,
      RouteName.DASHBOARD,
    ],
  });

export const createLiveStateApiErrorNotification: NotificationFactory = () =>
  NotificationHelper.createWarningNotification(NotificationsIds.LIVE_STATE_API_ERROR, {
    content: {
      title: T.general.errors.api.liveState,
      description: '',
      actionButton: {
        label: T.action.retry,
        actions: [AuthActions.forceRefresh()],
      },
    },
    conditionFunction: ({ liveDataErrorCount }) => liveDataErrorCount > 5,
    isPermanent: true,
    includeInRoutes: [
      RouteName.ANALYSIS,
      RouteName.BATTERY,
      RouteName.DASHBOARD,
      RouteName.LIVE_STATE,
    ],
  });

const createContractDataErrorNotification: NotificationFactory = () => {
  const conditionFunction = ({ isContractDataApiErrorNotification }: ConditionsProps) =>
    isContractDataApiErrorNotification;

  return NotificationHelper.createWarningNotification(
    NotificationsIds.CONTRACT_API_ERROR_DATA,
    {
      content: {
        title: T.general.errors.api.contract,
        description: '',
        actionButton: {
          label: T.action.retry,
          actions: [AuthActions.forceRefresh(), ContractActions.getContractData()],
        },
      },
      conditionFunction,
      isPermanent: true,
      includeInRoutes: [
        RouteName.DASHBOARD,
        RouteName.FLAT,
        RouteName.ENERGY,
        RouteName.TARIFF,
      ],
    },
  );
};

const createContractStatisticsErrorNotification: NotificationFactory = () => {
  const conditionFunction = ({ isContractStatisticsApiErrorNotification }: ConditionsProps) =>
    isContractStatisticsApiErrorNotification;

  return NotificationHelper.createWarningNotification(
    NotificationsIds.CONTRACT_API_ERROR_STATISTICS,
    {
      content: {
        title: T.general.errors.api.contract,
        description: '',
        actionButton: {
          label: T.action.retry,
          actions: [AuthActions.forceRefresh(), ContractActions.getSonnenFlatStatistics()],
        },
      },
      conditionFunction,
      isPermanent: true,
      includeInRoutes: [
        RouteName.ENERGY,
        RouteName.TARIFF,
      ],
    },
  );
};

export const createContractApiErrorNotification: Record<string, () => Notification> = {
  data: () => createContractDataErrorNotification(),
  statistics: () => createContractStatisticsErrorNotification(),
};

export const createOfflineNotification: NotificationFactory = () =>
  NotificationHelper.createWarningNotification(NotificationsIds.OFFLINE, {
    content: {
      title: T.general.messages.offline,
      description: '',
    },
    isPermanent: true,
    hideOnPlatform: 'web',
  });

export const createFailoverNotification: NotificationFactory = () =>
  NotificationHelper.createWarningNotification(NotificationsIds.LIVE_STATE_FAILOVER, {
    content: {
      title: T.general.messages.failoverBannerHeader,
      description: T.general.messages.failoverBannerDescription,
      isWarning: true,
    },
    conditionFunction: ({ isLiveDataFailover }) => isLiveDataFailover,
    isPermanent: true,
    includeInRoutes: [
      RouteName.ANALYSIS,
    ],
  });

export const createBatteryDataMissingNotification: NotificationFactory = () =>
  NotificationHelper.createWarningNotification(NotificationsIds.BATTERY_DATA_MISSING, {
    content: {
      title: T.general.messages.noBatteryDataBannerHeader,
      description: T.general.messages.noBatteryDataBannerDescription,
      actionButton: {
        label: T.action.retry,
        actions: [AuthActions.forceRefresh(), BatteryActions.getData()],
      },
    },
    conditionFunction: ({ isBatteryDataMissingNotification }) => isBatteryDataMissingNotification,
    isPermanent: true,
    excludeInRoutes: defaultExcludedRoutes,
  });

export const createImpersonateNotification: NotificationFactory = () =>
  NotificationHelper.createWarningNotification(NotificationsIds.IMPERSONATE, {
    content: {
      title: [
        T.general.messages.impersonateBannerHeader,
        { role: T.general.messages.adminRole },
      ],
      description: T.general.messages.impersonateBannerDescription,
    },
    conditionFunction: ({ isImpersonated }) => isImpersonated,
    isPermanent: true,
  });

export const createBatteryInitializationNotification: NotificationFactory = () =>
  NotificationHelper.createInfoNotification(NotificationsIds.BATTERY_INITIALIZATION, {
    content: {
      title: T.general.messages.batteryInitializationHeader,
      description: T.general.messages.batteryInitializationDescroption,
      hasCloseButton: true,
    },
    conditionFunction: ({ batteryInstallationDate }) => {
      if (!batteryInstallationDate) { return false; }

      const maxDiff = moment.duration(24, 'hours').asHours();
      const diff = moment.duration(moment().diff(batteryInstallationDate)).asHours();

      return diff <= maxDiff;
    },
    isPermanent: true,
    includeInRoutes: [
      RouteName.ANALYSIS,
      RouteName.BATTERY,
      RouteName.DASHBOARD,
      RouteName.LIVE_STATE,
    ],
  });

export const createSetBackupBufferFailedNotification: NotificationFactory = (actions) =>
  NotificationHelper.createWarningNotification(NotificationsIds.SET_BACKUP_BUFFER_VALUE_FAILED, {
    content: {
      title: T.general.messages.sendBatteryMessageFailedTitle,
      description: T.general.messages.sendBatteryMessageFailedDescription,
      actionButton: {
        label: T.action.retry,
        actions,
      },
    },
    isPermanent: true,
    excludeInRoutes: defaultExcludedRoutes,
  });

export const createBackupBufferSetupProcessingNotification: NotificationFactory = () =>
  NotificationHelper.createWarningNotification(NotificationsIds.BACKUP_BUFFER_SETUP_PROCESSING, {
    content: {
      title: T.general.messages.backupBufferSetupProcessingHeader,
      description: T.general.messages.backupBufferSetupProcessingDescription,
      hasCloseButton: true,
    },
    includeInRoutes: [
      RouteName.BATTERY_OVERVIEW,
      RouteName.DASHBOARD,
    ],
  });

export const createBackupBufferSetupFailedNotification: NotificationFactory = (actions) =>
  NotificationHelper.createDangerNotification(NotificationsIds.BACKUP_BUFFER_SETUP_FAILED, {
    content: {
      title: T.general.messages.backupBufferSetupFailedHeader,
      description: T.general.messages.backupBufferSetupFailedDescription,
      actionButton: {
        label: T.action.retry,
        actions,
      },
      hasCloseButton: true,
    },
    isPermanent: true,
    includeInRoutes: [
      RouteName.BATTERY_OVERVIEW,
      RouteName.DASHBOARD,
    ],
  });

export const createEmergencyModeOnNotification: NotificationFactory = () =>
  NotificationHelper.createInfoNotification(NotificationsIds.EMERGENCY_MODE, {
    content: {
      title: T.general.messages.emergencyModeActiveHeader,
      description: T.general.messages.emergencyModeActiveDescription,
    },
    conditionFunction: ({ isEmergencyModeActive }) => isEmergencyModeActive,
    isPermanent: true,
    includeInRoutes: [
      RouteName.BATTERY,
      RouteName.DASHBOARD,
      RouteName.LIVE_STATE,
    ],
  });

export const createBatteryOfflineNotification: NotificationFactory = () =>
  NotificationHelper.createWarningNotification(NotificationsIds.BATTERY_OFFLINE, {
    content: {
      title: T.general.messages.offlineBannerTitle,
      description: T.general.messages.offlineBannerDescription,
      isWarning: true,
    },
    conditionFunction: ({ isBatteryOfflineNotification }) => isBatteryOfflineNotification,
    isPermanent: true,
    includeInRoutes: [
      RouteName.ANALYSIS,
      RouteName.BATTERY,
      RouteName.DASHBOARD,
      RouteName.LIVE_STATE,
    ],
  });

export const createUserReadingsApiError: NotificationFactory = (actions) =>
  NotificationHelper.createWarningNotification(NotificationsIds.USER_READINGS_API_ERROR, {
    content: {
      title: T.general.errors.api.sendUserReadings,
      description: '',
      actionButton: {
        label: T.action.retry,
        actions,
      },
    },
    isPermanent: true,
    includeInRoutes: [
      RouteName.FLAT_USER_READINGS,
      RouteName.TARIFF_USER_READINGS,
    ],
  });
