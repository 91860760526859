import { T } from '@sonnen/shared-i18n/customer';
import { Icon } from '@sonnen/shared-web';
import * as React from 'react';
import { I18n } from 'react-redux-i18n';

import { AnalysisEnergyFlow } from '+app/+dashboard/+analysis/components';
import { DemoBubble } from '+app/+demo/components';

import './DemoAnalysisEnergyFlow.mobile.component.scss';

export const DemoAnalysisEnergyFlowMobile: React.FC = (props) => 
  <div className={'m-c-demo-analysis-energy-flow'}>
    <div className={'m-c-demo-analysis-energy-flow__bubble'}>
      <DemoBubble
        icon={Icon.Analysis}
        headline={I18n.t(T.demo.analysis.day.bubble.headline)}
        side={'top'}
        buttonLabel={I18n.t(T.demo.analysis.day.bubble.buttonLabel)}
      >
        {I18n.t(T.demo.analysis.day.bubble.text)}
      </DemoBubble>
    </div>
    <AnalysisEnergyFlow />
  </div>;
