import { Battery } from '+dashboard/+battery/store/types/battery.interface';
import { Contract } from '+dashboard/+contract/store/types/contract.interface';
import { DashboardActions } from '+dashboard/store/dashboard.actions';
import { SiteLive } from '+shared/store/live/types/siteLiveData.interface';
import { QueryData } from '+shared/store/query/query.state';
import { SiteElement } from '+shared/store/site/types/site.interface';
import { StatisticsData } from '+shared/types/statisticsData.interface';

export enum InfographicCardConnectionStatusType {
  OFFLINE = 'offline',
  DATA_MISSING = 'data_missing',
  OK = 'ok',
}

export enum InfographicCardElement {
  PV = 'pv',
  CONSUMPTION = 'consumption',
  BATTERY = 'battery',
  BACKUP = 'backup',
  CONTRACT = 'contract',
  HEATER = 'heater',
  CHARGER = 'charger',
  COMMUNITY = 'community',
  NONE = 'none',
}

export interface InfographicChargerData {
  id: string | null;
  chargingSpeed: number;
  distanceCharged: number;
  departureAt: string | undefined;
  isOnline: boolean;
  isCarConnected: boolean;
  isCharging: boolean;
  isSmartmodeAvailable: boolean;
  isSmartmodeCharging: boolean;
  isPowermodeCharging: boolean;
  isReadyForCharging: boolean;
  hasCharger: boolean;
}

export type InfographicActions = typeof DashboardActions; // NOTE: To type another actions add & typeof ActionName

export interface InfographicProps {
  siteLiveData: SiteLive | undefined;
  siteElements: SiteElement[];
  siteLiveQuery: QueryData;
  batteryData: Battery;
  batteryQuery: QueryData;
  contract: Contract | undefined;
  contractQuery: QueryData;
  sonnenFlatStatistics: StatisticsData | undefined;
  contractStatisticsQuery: QueryData;
  chargerData: InfographicChargerData;
  chargerLiveQuery: QueryData;
  actions?: InfographicActions;
}
