import { T } from '@sonnen/shared-i18n/customer';
import { Button, Icon, InfoBanner, Loader, Modal, ModalLayout, ModalProps } from '@sonnen/shared-web';
import { isNil } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { getCessionDocUrl, getCessionStatus } from '+app/+guide/store/+guide.selectors';
import { StoreState } from '+app/store/store.interface';
import { mapActions } from '+app/utils/redux';
import { GuideActions } from '+guide/store/+guide.actions';
import { CessionStatus } from '+guide/store/types';
import { CessionAccordionList, CessionDocument, CessionImageTutorial, CessionInfo, InfoBox } from '+shared/components';

import './GuidePurchaseStepCessionDocModal.component.scss';

const mapStateToProps = (state: StoreState) => ({
  cessionStatus: getCessionStatus(state),
  cessionDocUrl: getCessionDocUrl(state),
});

const mapDispatchToProps = mapActions({
  fetchCessionDocUrl: GuideActions.getCessionDocument,
  startCessionStatusPolling: GuideActions.startCessionStatusPolling,
  stopCessionStatusPolling: GuideActions.stopCessionStatusPolling,
});

interface OwnProps {
  isError: boolean;
}

type Props =
  & ReturnType<typeof mapStateToProps>
  & ReturnType<typeof mapDispatchToProps>
  & OwnProps
  & ModalProps
;

const GuidePurchaseStepCessionDocModalComponent: React.FC<Props> = ({
  cessionStatus,
  cessionDocUrl,
  isError,
  actions,
  ...modalProps
}) => {
  React.useEffect(() => {
    if (!cessionDocUrl && modalProps.isOpen) {
      actions.fetchCessionDocUrl();
    }
  }, [modalProps.isOpen]);

  React.useEffect(() => {
    if (cessionDocUrl && modalProps.isOpen) {
      actions.startCessionStatusPolling();
    } else {
      actions.stopCessionStatusPolling();
    }

    return () => {
      actions.stopCessionStatusPolling();
    };
  }, [cessionDocUrl, modalProps.isOpen]);

  React.useEffect(() => {
    if (cessionStatus && modalProps.isOpen && [CessionStatus.SIGNED, CessionStatus.REJECTED].includes(cessionStatus)) {
      actions.stopCessionStatusPolling();
    }
  }, [cessionStatus]);

  return (
    <Modal {...modalProps}>
      {!isError ? (
        <ModalLayout
          title={I18n.t(T.customerGuidance.purchase.step.resumeSigningCession.modal.headline)}
          footer={(
            <div className={'c-guide-purchase-step-cession-doc-modal__modal-footer'}>
              <Button
                label={I18n.t(T.customerGuidance.purchase.step.resumeSigningCession.modal.buttonLabel)}
                onClick={modalProps.onClose}
                isDisabled={cessionStatus === CessionStatus.PENDING || isNil(cessionStatus)}
              />
            </div>
          )}
        >
          {cessionDocUrl ? (
            <>
              <div className={'o-grid'}>
                <div className={'o-grid__column o-grid__column--md-6 o-grid__column--no-gap'}>
                  <div className={'c-guide-purchase-step-cession-doc-modal__info'}>
                    <CessionInfo url={cessionDocUrl} />
                  </div>
                </div>
                <div className={'o-grid__column o-grid__column--md-6 o-grid__column--no-gap'}>
                  <div className={'c-guide-purchase-step-cession-doc-modal__side-content'}>
                    <div className={'c-guide-purchase-step-cession-doc-modal__info'}>
                      <InfoBox
                        description={(
                          <>
                            <b>
                              {I18n.t(T.customerGuidance.purchase.step.resumeSigningCession.modal.infoBox.boldText)}
                            </b>
                            {` ${I18n.t(T.customerGuidance.purchase.step.resumeSigningCession.modal.infoBox.text)}`}
                          </>
                        )}
                        isIcon={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={'c-guide-purchase-step-cession-doc-modal__document'}>
                <CessionDocument url={cessionDocUrl} />
              </div>
              <div className={'o-grid'}>
                <div className={'o-grid__column o-grid__column--md-6 o-grid__column--no-gap'}>
                  <CessionImageTutorial />
                </div>
              </div>
              <div className={'o-grid'}>
                <div className={'o-grid__column o-grid__column--md-7 o-grid__column--no-gap'}>
                  <CessionAccordionList />
                </div>
              </div>
            </>
          ) : (
            <div className={'c-guide-purchase-step-cession-doc-modal__placeholder'}>
              <Loader />
            </div>
          )}
        </ModalLayout>
      ) : (
        <ModalLayout
          title={I18n.t(T.customerGuidance.purchase.step.resumeSigningCession.modal.headline)}
        >
          <div className={'c-guide-purchase-step-cession-doc-modal__placeholder'}>
            <InfoBanner
              icon={<Icon.BrokenBattery />}
              title={I18n.t(T.errorPage.title)}
              subtitle={
                <div className={'c-guide-purchase-step-cession-doc-modal__error'}>
                  {`${I18n.t(T.customerGuidance.purchase.step.resumeSigningCession.error)}
                  ${I18n.t(T.errorPage.instruction)}`}
                </div>
              }
            />
          </div>
        </ModalLayout>
      )}
    </Modal>
  );
};

export const GuidePurchaseStepCessionDocModal =
 connect(mapStateToProps, mapDispatchToProps)(GuidePurchaseStepCessionDocModalComponent);
